import React, {useState} from 'react'
import one from './pythonImages/tttImages/1.png';
import two from './pythonImages/tttImages/2.png';
import three from './pythonImages/tttImages/3.png';
import four from './pythonImages/tttImages/4.png';
import five from './pythonImages/tttImages/5.png';
import six from './pythonImages/tttImages/6.png';
import seven from './pythonImages/tttImages/7.png';
import eight from './pythonImages/tttImages/8.png';
import nine from './pythonImages/tttImages/9.png';
import ten from './pythonImages/tttImages/10.png';
import eleven from './pythonImages/tttImages/11.png';
import twelve from './pythonImages/tttImages/12.png';
import thirteen from './pythonImages/tttImages/13.png';
import fourteen from './pythonImages/tttImages/14.png';
import fifteen from './pythonImages/tttImages/15.png';
import sixteen from './pythonImages/tttImages/16.png';
import seventeen from './pythonImages/tttImages/17.png';
import eighteen from './pythonImages/tttImages/18.png';
import nineteen from './pythonImages/tttImages/19.png';
import twenty from './pythonImages/tttImages/20.png';
import twentyOne from './pythonImages/tttImages/21.png';
import twentyTwo from './pythonImages/tttImages/22.png';
import twentyThree from './pythonImages/tttImages/23.png';
import twentyFour from './pythonImages/tttImages/24.png';
import twentyFive from './pythonImages/tttImages/25.png';
import twentySix from './pythonImages/tttImages/26.png';
import twentySeven from './pythonImages/tttImages/27.png';
import twentyEight from './pythonImages/tttImages/28.png';
import twentyNine from './pythonImages/tttImages/29.png';
import thirty from './pythonImages/tttImages/30.png';
import thirtyOne from './pythonImages/tttImages/31.png';
import thirtyTwo from './pythonImages/tttImages/32.png';
import thirtyThree from './pythonImages/tttImages/33.png';
import thirtyFour from './pythonImages/tttImages/34.png';
import thirtyFive from './pythonImages/tttImages/35.png';
import thirtySix from './pythonImages/tttImages/36.png';
import thirtySeven from './pythonImages/tttImages/37.png';
import thirtyEight from './pythonImages/tttImages/38.png';
import thirtyNine from './pythonImages/tttImages/39.png';
import forty from './pythonImages/tttImages/40.png';
import fortyOne from './pythonImages/tttImages/41.png';
import fortyTwo from './pythonImages/tttImages/42.png';
import fortyThree from './pythonImages/tttImages/43.png';
import fortyFour from './pythonImages/tttImages/44.png';
import fortyFive from './pythonImages/tttImages/45.png';
import fortySix from './pythonImages/tttImages/46.png';
import fortySeven from './pythonImages/tttImages/47.png';
import fortyEight from './pythonImages/tttImages/48.png';
import fortyNine from './pythonImages/tttImages/49.png';
import fifty from './pythonImages/tttImages/50.png';
import fiftyOne from './pythonImages/tttImages/51.png';
import fiftyTwo from './pythonImages/tttImages/52.png';
import fiftyThree from './pythonImages/tttImages/53.png';
import fiftyFour from './pythonImages/tttImages/54.png';
import fiftyFive from './pythonImages/tttImages/55.png';
import fiftySix from './pythonImages/tttImages/56.png';
import fiftySeven from './pythonImages/tttImages/57.png';
import fiftyEight from './pythonImages/tttImages/58.png';
import fiftyNine from './pythonImages/tttImages/59.png';
import sixty from './pythonImages/tttImages/60.png';
import sixtyOne from './pythonImages/tttImages/61.png';
import sixtyTwo from './pythonImages/tttImages/62.png';
import sixtyThree from './pythonImages/tttImages/63.png';
import sixtyFour from './pythonImages/tttImages/64.png';
import sixtyFive from './pythonImages/tttImages/65.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import './tutorial.css';

const TicTacToe = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 63;

  return (
    <div className='pythonGames__container'>
        <h1>
        🎮 Welcome to our exciting Tic Tac Toe game using Pygame! 🎮
        </h1>
        <h3 className='intro'>
        In this tutorial, we will learn how to create a Tic Tac Toe game using Pygame. You'll learn how to draw the game board, draw the game pieces, check for a winner, and control the game flow. Are you ready to start? Let's go! ⭕❌
        </h3>

        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>


        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 1: Importing our Materials
            </h2>

            <p>
            Before we build anything, we need to ensure we have all our materials at hand. For this project, we're starting with two very important materials: pygame and sys.
            </p>

            <img src={one} className='demo__image' alt='img_one' />

            <p>
            What does this code do? 🤔
            </p>

            <p>
            These lines are essentially telling our Python program, "Hey, we need to use some tools and materials that aren't part of the basic set. Please fetch them for us."
            </p>

            <p>
            Why are we using this code? 🛠️
            </p>

            <ul>
                <li>
                pygame: This is a powerful set of Python modules designed for writing video games. It provides functionalities like creating windows, drawing shapes, capturing mouse events, and so much more!
                </li>

                <li>
                sys: This is a Python module that provides access to some Python interpreter variables and functions. We need it mainly to shut our game down when we decide to exit.
                </li>
            </ul>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                import: This keyword in Python is used to bring in libraries or modules. Think of it as our way of calling in the construction materials we need.
                </li>

                <li>
                pygame & sys: These are the names of the modules we're importing. Think of them as the brand names for our materials.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: In our construction analogy, you can think of import as the action of calling a supplier to deliver materials to our site. The materials (pygame & sys) are the essential tools and resources that help us build our project efficiently.<br/><br/>Congratulations on completing the first step! 🎉 The base materials are here. Next, we'll set up our construction site and start laying down the foundation for our game. Stay tuned for more steps, and happy building! 🏗️
            </h3>
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 2: Setting up the Construction Site
            </h2>

            <p>
            Now that we have our materials ready, it's time to set up our site. Just like before constructing a building, we ensure that the land is levelled and prepped, in coding, we initialize certain libraries or modules to ensure everything runs smoothly.
            </p>

            <img src={two} className='demo__image' alt='img_two' />

            <p>
            What does this code do? 🤔
            </p>

            <p>
            This line initializes all the modules in pygame. It's a bit like turning on the power at our construction site; without it, none of our fancy construction equipment (or in this case, game functions) would work!
            </p>

            <p>
            Why are we using this code? 🛠️
            </p>

            <p>
            Before we can use any features of pygame, it requires a bit of setup behind the scenes. pygame.init() takes care of all that, ensuring that the modules and features we'll use later are ready to go.
            </p>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                pygame: Refers to the set of tools and materials we imported in the first step.
                </li>

                <li>
                .init(): This is a function provided by pygame that initializes all its internal modules. The . notation signifies that we're using a function (or method) associated with pygame.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of pygame.init() as setting up your construction site. Before any work begins, you ensure all machinery is up and running, safety measures are in place, and all tools are at the ready. This initialization is similar – making sure our game-building tools are primed for action.<br/><br/>Great job, builder! 🌟 You've successfully set up your construction site. As we move forward, we'll be laying down the foundation, building walls, and setting up the interiors of our Tic Tac Toe skyscraper. Keep that enthusiasm rolling, and let's build something awesome! 🏢🎮🔧
            </h3>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 3: Defining the Blueprint 📐
            </h2>

            <p>
            Alright, ace builder! Before we can start erecting walls and placing tiles, we need a solid blueprint. This blueprint will guide us in constructing our Tic Tac Toe game, ensuring every block falls perfectly in place.
            </p>

            <img src={three} className='demo__image' alt='img_three' />

            <p>
            What does this code do? 🧐
            </p>


            <p>
            These lines define two variables: WIDTH and HEIGHT. Both are set to 300. Think of these as the dimensions for our game window—our Tic Tac Toe board will be a square of 300x300 pixels.
            </p>

            <p>
            Why are we using this code? 🖼️
            </p>

            <p>
            By setting these variables, we create a reference for the size of our game window. Instead of having to remember "300" every time we refer to the width or height, we can use WIDTH and HEIGHT. It makes our code cleaner and any future changes (like resizing the game) simpler. Just adjust the value here, and it'll reflect everywhere!
            </p>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                WIDTH & HEIGHT: These are variable names, but since we're using them as constants (values that don't change), by convention, we use all uppercase letters.
                </li>

                <li>
                =: This is the assignment operator. It assigns the value on its right (300) to the variable on its left (WIDTH or HEIGHT).
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Defining the WIDTH and HEIGHT is like marking the boundaries of our construction site. It ensures we know exactly where our structure begins and ends.<br/><br/>Fantastic work, foreman! 🎉 You've got the blueprint laid out and boundaries marked. Our construction site is buzzing with excitement. 🚜 Next, we'll start laying the bricks and seeing our game take shape! Onward to greatness! 🚀🧱
            </h3>
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 4: Selecting the Color Palette 🎨
            </h2>

            <p>
            Imagine building a beautiful house, but without deciding on the colors for the walls, floors, or exteriors. Colors breathe life into any construction! In our game, colors will make our Tic Tac Toe board vibrant and pleasing to the eye. Let's dive into our paint buckets:
            </p>

            <img src={four} className='demo__image' alt='img_four' />

            <p>
            What does this code do? 🌈
            </p>

            <p>
            Here, we are defining two colors: WHITE and LINE_COLOR. Colors in computer graphics often use the RGB format, where RGB stands for Red, Green, and Blue. Each of these can have values ranging from 0 to 255.
            </p>

            <ul>
                <li>
                WHITE is defined as (255, 255, 255), which means it has maximum values for Red, Green, and Blue—making it a bright white.
                </li>

                <li>
                LINE_COLOR is defined as (0, 0, 0), meaning it has no Red, Green, or Blue—giving us a pure black.
                </li>
            </ul>

            <p>
            Why are we using this code? 🖌️
            </p>

            <p>
            Colors enhance user experience. By defining these color variables:
            </p>

            <ol>
                <li>
                We can maintain consistency—ensuring the same shade of white or black is used throughout the game.
                </li>
                <li>
                It makes our code more readable. Instead of seeing mysterious RGB triplets scattered in the code, we see friendly names like WHITE or LINE_COLOR.
                </li>
                <li>
                If we ever decide to change the color scheme, we only need to update these variables, rather than hunting down every individual instance.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                WHITE & LINE_COLOR: These are variable names representing colors. Similar to WIDTH and HEIGHT, we use uppercase to indicate these are constants.
                </li>


                <li>
                =: This is our assignment operator, which we've seen before. It assigns the color value on its right to the variable on its left.
                </li>

                <li>
                (255, 255, 255) & (0, 0, 0): These are tuples (immutable ordered lists) containing three integers each, representing RGB values of colors.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Choosing our colors and defining them upfront is akin to a builder selecting and mixing paint before starting the painting process. It ensures consistency and makes future touch-ups much easier.<br/><br/>Well done, decorator! 🎉 Your choices will ensure our game is not only functional but also visually appealing. Next, we'll paint our board, add some lines, and see our game come to life! Keep those brushes ready! 🖼️🖍️🛠️
            </h3>
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 5: Expanding Our Color Palette & Setting the Line Width 🖍️
            </h2>

            <p>
            With the basic colors set, it's time to get a bit more artistic. Just like an architect selects different colors and materials for different parts of a building, we'll pick additional colors for our game elements. Additionally, we'll decide on the thickness of the lines that will make up our Tic Tac Toe grid.
            </p>
            <img src={five} className='demo__image' alt='img_five' />

            <p>
            What does this code do? 🎨
            </p>

            <ul>
                <li>
                CROSS_COLOR represents the color for the "X" symbol in our game. It's defined as (255, 0, 0), which in RGB means full Red and no Green or Blue, making it a bright red color.
                </li>

                <li>
                CIRCLE_COLOR is the color for the "O" symbol. With (0, 0, 255), it has no Red or Green but full Blue, resulting in a vibrant blue color.
                </li>

                <li>
                LINE_WIDTH sets the thickness of our game lines. Here, we've chosen a width of 10 pixels.
                </li>
            </ul>

            <p>
            Why are we using this code? 🏛️
            </p>

            <ul>
                <li>
                Visual Distinction: Different colors for "X" and "O" make it easy for players to distinguish between their moves.
                </li>

                <li>
                Aesthetics: A good line width ensures our Tic Tac Toe grid and symbols are clearly visible and aesthetically pleasing.
                </li>
            </ul>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                CROSS_COLOR, CIRCLE_COLOR & LINE_WIDTH: These are more constant variable names. By now, you're familiar with the drill—uppercase indicates that these values remain unchanged during the game.
                </li>

                <li>
                =: Our trusty assignment operator is at work again, setting values for our variables.
                </li>

                <li>
                (255, 0, 0), (0, 0, 255) & 10: The RGB tuples for our colors and an integer for the line width.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of CROSS_COLOR and CIRCLE_COLOR as the unique paints chosen for two different rooms in a house. They need to be distinct and harmonious. Meanwhile, LINE_WIDTH is like choosing the right brush size to ensure the paint goes on smooth and thick.<br/><br/>Fantastic, artist-builder! 🌟 Now our game will have a clear distinction between the two players, and our board will be bold and clear. Next, we’ll be setting up the canvas and sketching out our Tic Tac Toe grid. Exciting times ahead! 🎮🎨🔧
            </h3>
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 6: Preparing the Canvas 🖼️
            </h2>

            <p>
            Every great artist begins with a blank canvas. For our Tic Tac Toe game, this canvas is the screen where all the action happens! Time to roll it out and get it ready.
            </p>

            <img src={six} className='demo__image' alt='img_six' />

            <p>
            What does this code do? 🌟
            </p>

            <p>
            This line sets up our game window (or screen) using Pygame. We're determining its dimensions to be WIDTH x HEIGHT, which we previously defined as 300x300 pixels.
            </p>

            <p>
            Every game needs a stage, a place where everything is drawn and where players interact. This line ensures we have that stage set up with the right size for our game. Without it, we'd have nowhere to draw our Tic Tac Toe grid or the Xs and Os.
            </p>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                screen: This is a variable that will store our game window. Throughout our code, whenever we want to draw something on our window, we'll refer to this variable.
                </li>

                <li>
                pygame.display.set_mode(): This is a Pygame function that creates a game window and returns it. We can specify the window's dimensions by providing a tuple of (width, height).
                </li>

                <li>
                (WIDTH, HEIGHT): This tuple contains the width and height of our game window. We're using the WIDTH and HEIGHT constants we defined earlier to ensure our window matches our game's desired size.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Setting up the screen is like laying down the foundation for a building. It needs to be strong and of the right size because everything else we build will be on top of this!<br/><br/>Awesome work, game architect! 🏗️ With our canvas ready, we're all set to start sketching, painting, and bringing our game to life. Prepare your brushes and tools; the masterpiece is about to take shape! 🖌️🎮🎉
            </h3>
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 7: Naming Our Game Masterpiece 📜
            </h2>

            <p>
            When we build something special, we give it a name—a badge of honor, signifying its importance. For our Tic Tac Toe game, we too want a name to proudly display at the top.
            </p>

            <img src={seven} className='demo__image' alt='img_seven' />

            <p>
            What does this code do? 🌟
            </p>

            <p>
            This line sets the title of our game window to "Tic Tac Toe". When players open the game, they'll immediately see this title on the window's title bar.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Recognition: Players can quickly identify the game they're playing.
                </li>

                <li>
                Polish: Professional applications and games have proper titles; it adds to the user experience and looks polished.
                </li>

                <li>
                Practicality: If players have multiple windows open, the title helps them find the game window easily.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                pygame.display.set_caption(): This is a Pygame function specifically designed to set the title of the game window. It requires one argument: the title string.
                </li>

                <li>
                Tic Tac Toe": This is a string (a sequence of characters) that represents the title we want to assign to our game window.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Naming our game window is like putting a sign on a new building. It announces to the world what this special place is called and invites people inside.<br/><br/>Bravo, builder! 🏗️ With our game proudly wearing its name, it truly feels alive and welcoming. Up next, we'll be diving into the artistry of designing the game's visuals. Stay tuned! 🎨🔧🎉
            </h3>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 8: Priming Our Canvas 🎨
            </h2>

            <p>
            Before a painter begins their masterpiece, they often apply a base coat to the canvas. Similarly, we want to set a background color for our game's screen so that everything else we draw on it stands out nicely.
            </p>

            <img src={eight} className='demo__image' alt='img_eight' />

            <p>
            What does this code do? 🌟
            </p>


            <p>
            This line fills our entire game screen with the color WHITE. Remember, we defined WHITE earlier as the RGB tuple (255, 255, 255), which represents the color white.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Clear Canvas: Starting with a uniform background color ensures any game elements we draw later will be clearly visible and won't clash with a jumbled background.
                </li>

                <li>
                Aesthetics: White is a neutral color that offers a clean, minimalistic backdrop for our Tic Tac Toe grid and symbols.
                </li>

                <li>
                Readability: With a white background, colored game symbols (like our blue circles and red crosses) will pop out, making the game state easy for players to read.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:   
            </h3>

            <ul>
                <li>
                screen: This references the game window (or screen) we created earlier.
                </li>

                <li>
                .fill(): This is a method (a function attached to an object) provided by Pygame for filling the entire surface of a screen or image with a specific color.
                </li>

                <li>
                WHITE: Our trusty RGB tuple representing the color white. We're passing it as an argument to the .fill() method to specify the color we want.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of the screen.fill(WHITE) command as priming a wall before painting. The primer ensures the paint adheres well, and any imperfections in the wall don't show through the final paint job.<br/><br/>Nicely done, master painter! 🖌️ With our canvas primed and ready, we can confidently sketch, paint, and decorate our game screen. The creation of our gaming masterpiece is truly underway! 🎨🎮🌟
            </h3>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 9: Laying the Framework with Gridlines 📏🔧
            </h2>

            <p>
            Before we can start playing Tic Tac Toe, we need a clear grid. Think of it as the framework or skeleton of our game, providing structure and guidance for where players can make their moves.
            </p>

            <img src={nine} className='demo__image' alt='img_nine' />

            <p>
            This line begins the definition of a new function named draw_grid. A function is like a mini-program or a set of instructions that we can "call" (or use) repeatedly without rewriting the same code multiple times.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>
             
            <ol>
                <li>
                Organization: By creating a function, we keep our code neat and structured. It makes it easier for us (and others) to read and understand what each part of our code does.
                </li>

                <li>
                Reusability: If we want to redraw the grid at any point (e.g., after clearing a game), we can simply "call" this function rather than rewriting the grid-drawing code.
                </li>

                <li>
                Modularity: If we ever want to change how the grid looks or behaves, we only need to modify this function. It keeps changes localized and prevents unintended effects on other parts of our game.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                def: This is a keyword in Python that indicates we are about to define a new function.
                </li>

                <li>
                draw_grid: This is the name of our function. It's descriptive, indicating that the function's purpose is to draw the Tic Tac Toe grid.
                </li>

                <li>
                ():: The parentheses () are used to enclose any parameters (information or data) that the function might need to work correctly. Since our draw_grid function doesn't need any additional information to draw the grid, these parentheses are empty.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of defining a function like creating a blueprint for a part of a building. Once the blueprint is ready, you can use it to construct that part as many times as you need without drawing up new plans!<br/><br/>Awesome progress, architect! 📐 With our grid blueprint in place, we're set to map out the play area for our game. Let's continue building! 🏗️🎲🎉
            </h3>
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 10: Constructing the Gridlines - Part 1 📐🖋️
            </h2>

            <p>
            Building upon our framework, it's time to start crafting the actual grid lines of our Tic Tac Toe board. We'll draw vertical and horizontal lines to create the familiar 3x3 grid.
            </p>

            <img src={ten} className='demo__image' alt='img_ten' />
            <p>
            This line initiates a loop that will repeat its inner block of code twice. Specifically, it will repeat once with the value i set to 1 and once with i set to 2.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Repetition: Instead of drawing each gridline manually, this loop allows us to use the same piece of code to draw multiple lines, saving time and ensuring consistency.
                </li>

                <li>
                Flexibility: By using a loop, it's easier to make adjustments in the future. For instance, if we ever wanted to design a 5x5 Tic Tac Toe, we'd only need a small tweak here.
                </li>

                <li>
                Efficiency: It's generally good practice to use loops for repetitive tasks in coding. It keeps the code shorter, more readable, and easier to maintain.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                for: This is a keyword in Python that starts a loop, telling the program to execute a set of instructions multiple times.
                </li>

                <li>
                i: This is a variable that will take on different values each time through the loop. It acts as our loop counter.
                </li>

                <li>
                in: Another keyword, working with for, to indicate where i will get its values from.
                </li>

                <li>
                range(1, 3): This is a Python function that produces a sequence of numbers starting from the first argument (inclusive) and ending at the second argument (exclusive). Here, it gives us the sequence: 1, 2.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Imagine you're building a fence with evenly spaced posts. Instead of measuring the distance each time, you'd use a template or a guide to ensure consistent spacing. Our loop acts as that guide, ensuring our gridlines are drawn consistently.<br/><br/>Great job, builder! 🏗️ We've laid the foundation for our gridlines. With the loop set up, we're primed to sketch those lines and bring the play area to life. Keep those construction hats on; we're making impressive progress! 🎮🛠️🎉
            </h3>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 11: Drawing the Vertical Gridlines 📏✏️
            </h2>

            <p>
            Now that we've set up our loop, it's time to draw the first set of lines: the vertical gridlines. These lines will separate the columns of our Tic Tac Toe board.
            </p>

            <img src={eleven} className='demo__image' alt='img_eleven' />

            <p>
            What does this code do? 🌟
            </p>

            <p>
            This line of code uses Pygame's built-in function draw.line() to draw a straight vertical line on our game screen.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Structure: This code provides the vertical boundaries for the individual cells in which players will place their Xs and Os.
                </li>

                <li>
                Visibility: The lines help players identify where they can place their symbols and assist in visually tracking the game state.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                pygame.draw.line(): This is a method provided by Pygame to draw straight lines.
                </li>

                <li>
                screen: The surface on which we want to draw the line. In this case, it's our main game screen.
                </li>

                <li>
                LINE_COLOR: The color of the line, which we previously defined as black (RGB: (0, 0, 0)).
                </li>

                <li>
                (i * WIDTH // 3, 0): The starting point of our line. The X-coordinate is determined by our loop variable i. When i is 1, this point represents one third of the screen's width from the left, and when i is 2, it's two thirds. The Y-coordinate is 0, meaning the line starts from the very top of our screen.
                </li>

                <li>
                (i * WIDTH // 3, HEIGHT): The ending point of our line. Again, the X-coordinate is determined by i, but the Y-coordinate is now set to HEIGHT, meaning the line goes all the way to the bottom of our screen.
                </li>

                <li>
                LINE_WIDTH: The thickness of our line, which we previously defined as 10 pixels.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of drawing this line as placing a vertical beam in a building's framework. Just like how beams provide structure and definition to a building, our gridlines provide structure and clarity to our game board.<br/><br/>Excellent work, architect! 🏢 We've successfully added the first set of gridlines. Up next, we'll mirror this process for the horizontal lines to complete our Tic Tac Toe board's structure. Keep it up; our gaming skyscraper is taking shape! 🎮🔧🌇
            </h3>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 12: Crafting the Horizontal Gridlines 📐🖍️
            </h2>

            <p>
            Having etched the vertical divisions, we're now set to draw the horizontal ones. These lines will separate the rows of our Tic Tac Toe board, completing our 3x3 grid.
            </p>

            <img src={twelve} className='demo__image' alt='img_twelve' />

            <p>
            This line of code employs Pygame's draw.line() function once more, but this time to sketch a straight horizontal line across our game screen.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Completeness: This line, in conjunction with the previous vertical lines, completes the full Tic Tac Toe grid.
                </li>

                <li>
                Guidance: The horizontal lines guide players on where they can place their Xs and Os and help visually differentiate each row of the game.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                pygame.draw.line(): A Pygame method used to render straight lines.
                </li>

                <li>
                screen: Our game's canvas where we want the line to appear.
                </li>

                <li>
                LINE_COLOR: The color of our line, set to black (RGB: (0, 0, 0)).
                </li>

                <li>
                (0, i * HEIGHT // 3): This determines the line's starting point. The X-coordinate is set to 0, so our line begins from the leftmost edge. The Y-coordinate, controlled by our loop variable i, determines the vertical position. With i equaling 1, this point is a third of the screen's height from the top. When i is 2, it's two-thirds of the height.
                </li>

                <li>
                WIDTH, i * HEIGHT // 3): This is where our line concludes. The X-coordinate, WIDTH, ensures our line stretches across the entire width of the screen, while the Y-coordinate, like before, sets the vertical position based on i.
                </li>

                <li>
                LINE_WIDTH: Dictates the thickness of our line, previously defined as 10 pixels.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Consider this horizontal line as laying down a floor in a multi-story building. Just as floors segregate levels and offer clarity in a structure, our horizontal lines delineate rows, ensuring a clear gaming experience.<br/><br/>antastic progression, builder! 🏗️ With both vertical and horizontal gridlines in place, our Tic Tac Toe board is almost ready for gameplay. We've laid out the playground; next, we'll bring in the players and the fun! 🎮🎉🔍
            </h3>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 13: Designing the Symbols - Introduction 🎨🖌️
            </h2>

            <p>
            Before diving into the gameplay, we need a way to visualize the player's moves. Let's lay the groundwork for drawing the symbols – Xs and Os. First up, the function declaration!
            </p>

            <img src={thirteen} className='demo__image' alt='img_thirteen' />

            <p>
            This line begins the definition of a new function named draw_symbol. Functions are like self-contained mini-programs or tools we can use throughout our code. This particular function will be our go-to tool for drawing either an 'X' or an 'O' on our game board.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Organization: By encapsulating the symbol drawing code in its own function, we keep our main program tidy and better organized.
                </li>

                <li>
                Reusability: Whenever we need to draw a symbol, instead of rewriting or copying the same code multiple times, we simply call this function. It's like having a handy drawing stencil!
                </li>

                <li>
                Flexibility: If in the future we want to change how the symbols look or behave, we only need to adjust this function instead of multiple places in the code.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                def: A keyword in Python that denotes the start of a function definition.
                </li>

                <li>
                draw_symbol: The name we've chosen for our function. Naming is essential, as it gives a hint about the function's purpose.
                </li>

                <li>
                (x, y, player): These are parameters or inputs to our function. Think of them as choices we provide the function each time we use it:
                </li>

                <li>
                x & y: Determine where on the board we'll be drawing the symbol.
                </li>

                <li>
                player: Tells the function which symbol to draw – 'X' if the value is True, and 'O' if it's False.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Consider a function like a multi-tool in your toolbox. Instead of carrying separate tools (codes) for every task, a multi-tool (function) lets you perform various tasks using a single, compact device (code segment).<br/><br/>High five, designer! ✋ With this function in place, we're setting up our canvas for artistic expression – letting players stamp their mark (literally) on the game. Stay tuned as we delve into the art of crafting the symbols next! 🎮🎨🖼️
            </h3>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 14: Getting into Symbol Details 🎲👩‍🎨
            </h2>

            <p>
            After establishing the framework for our symbol-drawing function, let's now start the artistic process. First, we'll determine which symbol we're drawing - an 'X' or an 'O'. Here's our opening move:
            </p>

            <img src={fourteen} className='demo__image' alt='img_fourteen' />
            <p>
            This line checks the value of the player parameter. In Python, a standalone variable name in an if statement checks for its truthiness. If player is True, the subsequent indented code block (which we'll explore in later steps) will execute.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Decision-making: Games often require decisions based on conditions. Here, we're determining which symbol to draw based on the player's value.
                </li>

                <li>
                Dynamic drawing: Depending on whether the player is True or False, we'll either draw an 'X' or an 'O'.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                if: A keyword in Python used to start a conditional statement.
                </li>

                <li>
                player: This is our function's parameter. By evaluating its truthiness, we determine which set of commands (or code block) to execute next.
                </li>

                <li>
                If player is True, we're dealing with the first player, who we've chosen to represent with an 'X'.
                </li>

                <li>
                If player is False, it means it's the second player's turn, represented by an 'O'.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of this if statement as the foundation of a bifurcating pathway in our gaming construction site. Depending on the choice made (value of player), the path diverges, leading to two different outcomes.<br/><br/>Bravo, game artist! 🎉 We've set the stage for our artistry, sketching out the rules and conditions under which our game symbols come to life. As we move forward, we'll delve deeper into drawing these symbols, filling our canvas with vibrant gameplay moments! 🖍️🎮🎨
            </h3>
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 15: Drawing the First Half of "X" ✏️🖼️
            </h2>

            <p>
            With the groundwork set for the players, it's time to start sketching! We'll commence with the symbol for Player 1: the letter 'X'. The 'X' is composed of two diagonal lines, and we're about to craft the first one:
            </p>

            <img src={fifteen} className='demo__image' alt='img_fifteen' />

            <p>
            This line employs Pygame's draw.line() function to draw one of the two diagonal lines that together make up the 'X' symbol for Player 1.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Representation: Every player needs a symbol for representation. For Player 1, it's the iconic 'X'.
                </li>

                <li>
                Visualization: It's vital for players to visualize their moves. Drawing the 'X' on the screen makes gameplay more engaging and comprehensible.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                pygame.draw.line(): A Pygame method used to render straight lines on our screen.
                </li>

                <li>
                screen: Our game's canvas where we intend to draw the line.
                </li>

                <li>
                CROSS_COLOR: Represents the color of the 'X', which is red (RGB: (255, 0, 0)).
                </li>

                <li>
                Start Point ((x * WIDTH // 3 + 20, y * HEIGHT // 3 + 20)):
                <ul>
                    <li>
                    The x-coordinate is determined by taking the current grid cell's left boundary (x * WIDTH // 3) and adding a small margin of 20 pixels to move slightly inward.
                    </li>

                    <li>
                    The y-coordinate follows a similar logic, determining the top boundary of the current cell and adding a margin.
                    </li>
                </ul>
                </li>

                <li>
                End Point (((x + 1) * WIDTH // 3 - 20, (y + 1) * HEIGHT // 3 - 20)):
                <ul>
                    <li>
                    The x-coordinate represents the right boundary of the grid cell minus a margin of 20 pixels.
                    </li>

                    <li>
                    The y-coordinate corresponds to the cell's bottom boundary minus a margin.
                    </li>
                </ul>
                </li>

                <li>
                LINE_WIDTH: This defines the thickness of our line. Previously, we set it to 10 pixels.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Imagine you're drawing on a square tile. The start point is slightly inward from the top-left corner of the tile, and the end point is a bit inward from the bottom-right corner. This gives the perfect diagonal across our square!<br/><br/>Well done, architect! 🎨👩‍🎨 With one stroke down, our 'X' is halfway complete. Stay excited as we soon bring the entire symbol to life, making our Tic Tac Toe board vibrant and interactive! 🎉🖌️🎲
            </h3>
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 16: Crafting the Second Half of "X" 🎨🛠️
            </h2>

            <p>
            After setting the initial brushstroke for our 'X', let's finalize the masterpiece by drawing the second diagonal line:
            </p>

            <img src={sixteen} className='demo__image' alt='img_sixteen' />

            <p>
            This line of code completes our 'X' by drawing the second diagonal using Pygame's draw.line() function.
            </p>

            <ol>
                <li>
                Completeness: An 'X' is characterized by its two intersecting diagonal lines. This code ensures Player 1's symbol is clear and recognizable.
                </li>

                <li>
                Interactive Feedback: Providing a visual cue when a player makes a move enhances user experience and makes the game interactive.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                pygame.draw.line(): We're revisiting Pygame's handy method for drawing straight lines.
                </li>

                <li>
                screen: Our game's display where the line is drawn.
                </li>

                <li>
                CROSS_COLOR: The color of the 'X', a crisp red (RGB: (255, 0, 0)).
                </li>

                <li>
                Start Point (((x + 1) * WIDTH // 3 - 20, y * HEIGHT // 3 + 20)):
                <ul>
                    <li>
                    The x-coordinate pinpoints the right boundary of the current grid cell but moves slightly inward by 20 pixels.
                    </li>

                    <li>
                    The y-coordinate, like in the previous line, locates the top boundary of the cell and adds a margin.
                    </li>
                </ul>
                </li>

                <li>
                End Point ((x * WIDTH // 3 + 20, (y + 1) * HEIGHT // 3 - 20)):
                <ul>
                    <li>
                    For the x-coordinate, it takes the left boundary of the grid cell and adds a margin of 20 pixels.
                    </li>

                    <li>
                    The y-coordinate targets the bottom boundary of the cell, pulling back a tad with a 20-pixel margin.
                    </li>
                </ul>
                </li>

                <li>
                LINE_WIDTH: This is the width of the line being drawn, previously set at 10 pixels.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Continuing with our tile analogy from before, think of this line as starting slightly inward from the top-right corner and ending a bit inside the bottom-left corner. This diagonal combined with the previous one forms a perfect 'X' on our tile.<br/><br/>Magnificent job, artist! 🖌️✨ With both diagonals in place, the symbol 'X' stands proud on our Tic Tac Toe canvas. Our board's starting to fill with life and strategy, one move at a time! 🎉🎲🏆
            </h3>
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 17: Drawing the Circle for Player 2 🌐🎨
            </h2>

            <p>
            After crafting the iconic 'X' for Player 1, it's now Player 2's turn to shine. Here's the spotlight moment, drawing the circle for the 'O'!
            </p>

            <img src={eighteen} className='demo__image' alt='img_seventeen' />

            <p>
            This line uses Pygame's draw.circle() function to render a circle on the game screen, which represents the 'O' symbol for Player 2.
            </p>

            <ol>
                <li>
                Distinct Identity: To differentiate between the two players, Player 2 is represented with an 'O'. This circle ensures a clear distinction.
                </li>

                <li>
                User Experience: By visually representing the player's moves, the game becomes more interactive and engaging.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                pygame.draw.circle(): A Pygame method used for drawing circles.
                </li>

                <li>
                screen: This is the game's display, the canvas where the circle will appear.
                </li>

                <li>
                CIRCLE_COLOR: This specifies the color of the 'O', which we've chosen as blue (RGB: (0, 0, 255)).
                </li>

                <li>
                Center of the circle ((x * WIDTH // 3 + WIDTH // 6, y * HEIGHT // 3 + HEIGHT // 6)):

                <ul>
                    <li>
                    The x-coordinate finds the left boundary of the current grid cell and moves halfway into the cell using WIDTH // 6.
                    </li>

                    <li>
                    The y-coordinate follows a similar pattern, starting at the top boundary and moving halfway into the cell.
                    </li>
                </ul>
                </li>

                <li>
                Radius (WIDTH // 6 - 20): The circle's radius is half of one-third of our screen width (essentially one-sixth), reduced slightly by 20 pixels to ensure it fits snugly in the grid cell.
                </li>

                <li>
                LINE_WIDTH: Defines the circle's thickness. Like our other game elements, it's set to 10 pixels.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Visualize the center of our circle to be right in the middle of the cell. With a slightly adjusted radius, our circle will feel perfectly at home, neither too big nor too small.<br/><br/>Fantastic progress, builder! 🚧🎉 Now with the 'X' and 'O' in place, our game's identity is well-defined. Onward, as we infuse more magic into our Tic Tac Toe game! 🎲🔵✖️
            </h3>
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 18: Setting Up the Game Board 📋🎮
            </h2>

            <p>
            We've created our symbols, but now we must get the overarching game mechanics in place. Let's establish the initial game state:
            </p>

            <img src={nineteen} className='demo__image' alt='img_eighteen' />
            <p>
            This line sets up the initial game board by creating a 3x3 matrix (list of lists) where each entry represents a cell in our Tic Tac Toe grid. To start, each cell is set to None, indicating it's unclaimed by either player.
            </p>

            <ol>
                <li>
                Game Mechanics: This matrix will help track moves, determine wins, and manage game progress. It's essential for the game's logic.
                </li>

                <li>
                Memory: Instead of relying just on visuals, this data structure stores each player's moves and allows us to decide game outcomes.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                List Comprehension: The code uses a Python feature called list comprehension, which lets us generate lists in a concise manner.
                </li>

                <li>
                [None, None, None]: This list of three None values represents a single row in the Tic Tac Toe board. At the start, every cell in this row is empty or unclaimed.
                </li>

                <li>
                for _ in range(3): This loop creates three copies of our row (the 3x3 grid). The underscore (_) is a conventional placeholder in Python, suggesting we don't need the loop's index value for our purpose.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of game_state as the foundation of our Tic Tac Toe building. It holds everything together, ensuring stability and functionality. Each None in the matrix is like an empty plot, awaiting a future 'X' or 'O' to set up residence.<br/><br/>Well done, architect! 🏗️👷 With the foundation solidly in place, our Tic Tac Toe mansion is truly starting to take shape. Let's continue building it piece by piece, bringing fun and games to everyone who visits! 🎉🏡🎮
            </h3>
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 19: Whose Turn Is It Anyway? 🔄🎲
            </h2>

            <p>
            Time to set the stage for our players. Let's decide who gets the first move:
            </p>

            <img src={twenty} className='demo__image' alt='img_nineteen' />

            <p>
            This line initializes a variable called player_turn and sets it to True. Throughout our game, this variable will keep track of which player's turn it is. If player_turn is True, it's Player 1's turn (representing the 'X'). If it's False, then Player 2 (the 'O') takes the stage.
            </p>


            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Game Flow: Every game has rules, and Tic Tac Toe is no different. Players take turns, and this variable helps maintain that order.
                </li>

                <li>
                Dynamics: The back-and-forth nature is what makes games engaging. This little flag ensures that players are engaged, waiting for their turn, and strategizing their next move.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                player_turn: A straightforward variable name that clearly indicates its purpose – to track the player's turn.
                </li>

                <li>
                True: In the realm of Boolean values, True and False are opposites. By setting it to True, we're signifying Player 1 (the 'X') starts the game.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Establishing a game's turn sequence is like setting the direction for a one-way street. It ensures smooth traffic flow, avoids chaos, and adds an element of strategy.<br/><br/>Awesome job, master planner! 📜🖋️ Setting the turn ensures a fair game, where each player gets an equal chance. Ready to dive deeper into the Tic Tac Toe realm? Let's keep those builder helmets on and continue! 🚧🎮🎉
            </h3>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 20: Constructing the Win Condition Checker 🏆🕵️‍♂️
            </h2>

            <p>
            Winning feels fantastic, but how do we know when someone has won? Enter the mechanism to check for a win:
            </p>

            <img src={twentyOne} className='demo__image' alt='img_twenty' />

            <p>
            This line introduces the foundation of a new Python function named check_win. This function, when fully built out, will be responsible for determining if a player has won the game. By passing the current player's status (True for 'X' or False for 'O') to this function, it will check if that player's symbols align in the winning combinations on the board.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Game Dynamics: Winning is a primary goal in Tic Tac Toe. Having a reliable mechanism to check this is crucial to the game's flow.
                </li>

                <li>
                Feedback Loop: By consistently checking if a player has won after every move, we can provide immediate feedback and excitement to the players.
                </li>

                <li>
                Modularity: Packaging this logic into a separate function makes the code cleaner, more understandable, and easier to maintain.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                def: Short for "define", this keyword in Python indicates the start of a function definition.
                </li>

                <li>
                check_win: This is the name of our function. It's descriptive and tells us that this function's purpose is to check for a win.
                </li>

                <li>
                (player): These parentheses house the parameters the function requires. In this case, the function expects information about which player's turn it currently is.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of this function as a vigilant security guard at a fancy gala, always on the lookout for the VIP (Very Important Player) who aligns their symbols just right to claim victory. Once spotted, the guard (our function) raises the alarm – "We have a winner!"<br/><br/>Phenomenal progress, trailblazer! 🎉💼 Building a game involves ensuring every outcome gets addressed. With our win checker starting to form, we're one step closer to creating a full-fledged game that players will love. Let's press on! 🎲🚀👷‍♂️
            </h3>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 21: Introducing the Winning Symbol 🥇🎖️
            </h2>

            <p>
            To know who has won, we need to identify their respective symbols. Let's determine that:
            </p>

            <img src={twentyTwo} className='demo__image' alt='img_twentyOne' />

            <p>
            This line assigns a value to the variable symbol based on the status of the player parameter we introduced in the check_win function. If the player is True (indicating Player 1), the symbol 'X' is assigned. Otherwise, the symbol 'O' is chosen for Player 2.
            </p>

            <ol>
                <li>
                Identification: Knowing who has made the winning move is crucial. This line helps differentiate between the two players' symbols for the winning check.
                </li>

                <li>
                Simplification: Instead of repeatedly checking the player's status throughout the function, this line simplifies our logic by assigning the correct symbol just once.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                symbol: A variable that will store either 'X' or 'O', depending on the player's status.
                </li>

                <li>
                'X' if player else 'O': This is a concise way to use Python's conditional (ternary) expression. It reads as: "Assign 'X' if player is True, otherwise assign 'O'."
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of the symbol variable as a VIP badge at an exclusive event. If you're Player 1, you get the 'X' badge. If you're Player 2, you're handed the 'O' badge. This way, everyone immediately knows who you represent!<br/><br/>Excellent work, craftsman! 🎉🛠️ By identifying the players with their respective symbols, our win-checking mechanism is becoming smarter. Just like the foundation of a building, these little details ensure our game stands strong and functions seamlessly. Onward to more coding mastery! 🚀👷‍♂️🎮
            </h3>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 22: Setting the Stage for a Winning Row 🌐🏁
            </h2>

            <p>
            Games have rules, and Tic Tac Toe requires a player to align their symbols in a particular order to win. Let's explore the very first possibility of a win - when a player gets all their symbols in a horizontal line:
            </p>

            <img src={twentyThree} className='demo__image' alt='img_twentyTwo' />

            <p>
            This line begins a loop that iterates over each row in our game_state list. It sets the stage to check if all symbols in a row belong to the same player, indicating a horizontal win.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Systematic Check: It's vital to cover every possibility of a win. This line initiates the first step in that process by checking for a win across all rows.
                </li>

                <li>
                Efficiency: By iterating over the game's rows, we can check each potential winning row one by one without redundant checks.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                for: This keyword initiates a loop in Python, allowing us to perform repeated checks or operations.
                </li>

                <li>
                row: This is a temporary variable that takes on the value of the current row being examined in the game_state during each iteration.
                </li>

                <li>
                in game_state: The loop will iterate over each row present in our game_state list.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Picture this loop as a meticulous inspector, examining each floor of a building (in this case, rows in our game) to ensure all criteria are met. If he finds a floor (or row) where everything aligns perfectly, he declares it a win!<br/><br/>Fantastic progression, builder! 🎉🧱 Each step you take ensures our game follows its rules and provides the excitement players expect. Every detail matters. Stay focused, and let's keep this construction rolling! 🎮👷‍♂️🚀
            </h3>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 23: Checking the Row for a Winner 🥇🔍
            </h2>

            <p>
            Having set the stage to inspect each row, it's time to determine if a player's symbols dominate a row, signaling a victory:
            </p>

            <img src={twentyFour} className='demo__image' alt='img_twentyThree' />

            <p>
            This line checks if all the symbols (s) in the currently examined row match the symbol of the player in question. If every cell in the row contains the same player's symbol, it indicates a winning row for that player.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Victory Verification: We need to ascertain if a player's symbols align perfectly in a horizontal line. This code performs that critical check.
                </li>

                <li>
                Simplification & Efficiency: Using the all() function along with a generator expression helps streamline the logic, making the code concise while doing the job efficiently.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                all(): This is a built-in Python function that returns True if all elements in the given iterable are true. In our context, it checks if every symbol in the row matches the player's symbol.
                </li>

                <li>
                s == symbol for s in row: This is a generator expression. It goes through each symbol (s) in the row and checks if it matches the current player's symbol. It's like asking, "Does this cell's symbol match our player's symbol?" for each cell in the row.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Imagine this line as a security scanner at the entrance of an exclusive event. It ensures that everyone (in our case, every cell in the row) carries the right badge (the symbol) to enter. If even one person doesn't have the correct badge, access is denied. But if all have the correct badge, it's a win!<br/><br/>Remarkable diligence, architect! 🎉📐 Ensuring we have the right mechanisms to detect a win is like setting up fail-safes in a building. It guarantees a smooth experience and keeps things exciting. Keep those bricks laying, and let's continue shaping this game! 🏗️👷‍♂️🎮
            </h3>
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 24: Declaring a Winning Row! 🏆🎉
            </h2>

            <p>
            The moment we've been building towards has arrived! If all symbols in a row match the player's symbol, it's time to declare victory:
            </p>

            <img src={twentyFive} className='demo__image' alt='img_twentyFour' />

            <p>
            If the code reaches this line, it returns a True value, indicating that the player in question has secured a winning row.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Victory Signal: This line serves as the official signal that a win condition has been met in a row.
                </li>

                <li>
                Efficiency: By returning True immediately upon detection of a win, we can terminate the win-checking function early and avoid unnecessary additional checks.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                return: This keyword in Python is used within a function to send a value back to where the function was called. It also causes the function to exit immediately.
                </li>

                <li>
                True: A boolean value that, in this context, indicates a positive win condition.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of the return True as a green light on a construction site. As soon as it lights up, it signals that a specific condition (in our case, a win) has been met, and workers can move on to the next task without waiting!<br/><br/>Excellent job, site manager! 🎉🚧 Identifying a win swiftly and accurately is essential to any game, ensuring players get the satisfaction and thrill of victory. We're building not just a game, but an experience. Let's keep the momentum going! 🎮👷‍♂️🚀
            </h3>
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 25: Moving to Vertical Checks 📊🔝
            </h2>

            <p>
            With the horizontal row checks underway, it's time to transition to inspecting vertical columns for potential wins:
            </p>

            <img src={twentySix} className='demo__image' alt='img_twentyFive' />

            <p>
            This line commences a loop that cycles through each column in our game_state grid, setting the scene to investigate if all symbols in a column are the same and belong to the current player.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Systematic Column Checks: Tic Tac Toe doesn't just have horizontal win conditions; vertical wins (symbols aligned in a straight column) are equally valid. This line is the first step to verify those vertical victories.
                </li>

                <li>
                Loop Efficiency: Using the range(3) ensures that our loop checks all three columns systematically without redundancy.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                for: This keyword kicks off a loop in Python, which enables repeated actions or checks.
                </li>

                <li>
                col: A temporary variable, representing the current column index under inspection as we progress through the loop.
                </li>

                <li>
                range(3): This creates a sequence of numbers from 0 to 2 (inclusive). Here, it signifies the three columns of our Tic Tac Toe grid. The loop will iterate thrice: once for each column.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Picture this loop as an elevator traveling through a three-story building. On each floor (or column in our game), it performs a check. If it finds a column where everything lines up seamlessly, the alarm rings for a win!<br/><br/>Great progress, builder! 🎉🪜 As with constructing a sturdy building, games require keen attention to every dimension. Vertical checks are as vital as the horizontal ones, ensuring players get a full-rounded experience. Let's keep those beams and codes aligned! 🏢👷‍♂️🎮
            </h3>
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 26: Inspecting Vertical Wins 🚀🔎
            </h2>

            <p>
            Now that we're aboard the column-check elevator, let's start the inspection to find if any column boasts a vertical win:
            </p>

            <img src={twentySeven} className='demo__image' alt='img_twentySix' />

            <p>
            This line checks each cell in the current column (represented by col) to determine if all symbols match the player's symbol. If every cell in the column holds the player's symbol, it's a clear indication of a winning column.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Vertical Victory Check: Just as we scrutinized the rows, we need a way to validate vertical wins in Tic Tac Toe. This code is our column-inspector, ensuring that wins are accurately detected in any direction.
                </li>

                <li>
                Elegance & Efficiency: Utilizing the all() function combined with a generator expression makes the win-check straightforward and concise.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                all(): A Python function that returns True if all elements in the given iterable are true. In this scenario, it checks if each symbol in the column matches the player's symbol.
                </li>

                <li>
                game_state[row][col]: Represents the symbol in the cell at the current row and column. Our loop traverses each row within the specific column we're inspecting.
                </li>

                <li>
                == symbol: This checks if the cell's symbol matches the player's symbol.
                </li>

                <li>
                for row in range(3): A generator expression. It cycles through each row (from 0 to 2) for the current column and checks the symbol inside.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Envision this as the vertical counterpart to our horizontal scanner. Instead of scanning guests' badges in a horizontal line, we're now checking them as they stand in a vertical queue. Every guest (cell) in the line must have the correct badge (symbol) to score a win!<br/><br/>Fabulous work, foreman! 🎉🔍 Recognizing wins in every direction ensures our game is robust and fair. It's the attention to every axis, every detail that makes our game stand tall and true. Let's keep building towards that perfect gameplay experience! 🏗️👷‍♂️🎮
            </h3>
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 27: Declaring a Vertical Victory! 🎖️🚀
            </h2>

            <p>
            After diligently checking each cell in the column, it's time to sound the victory horn if a winning column is detected:
            </p>
            <img src={twentyEight} className='demo__image' alt='img_twentySeven' />

            <p>
            If the preceding column-check confirms that all symbols in the column match the player's symbol, this line returns a True value, indicating a vertical win!
            </p>

            <p>
            Why are we using this code? 🤔
            </p>


            <ol>
                <li>
                Victory Confirmation: This line is the final stamp of approval that a vertical win condition has been achieved.
                </li>

                <li>
                Efficient Exit: Once we identify a win, we want our function to finish its job and relay the good news immediately, without making any more unnecessary checks.
                </li>
            </ol>

            <h3>
            🔨 Construction Tip: Consider the return True as the ribbon-cutting moment on our construction site. Once the ribbon is cut (a win is confirmed), celebrations begin, and there's no more need to inspect the site further!<br/><br/>Bravo, builder! 🥳🎈 Identifying vertical victories is crucial, ensuring that our game captures all the excitement of Tic Tac Toe. We're piecing together an awesome game that's meticulous, exciting, and fair. Let's keep the construction crane moving! 🏗️🎮👷‍♂️
            </h3>
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 28: Checking for Diagonal Wins (Top-left to Bottom-right) 🎖️↘️
            </h2>

            <p>
            Now that we've addressed horizontal and vertical victories, there's another exciting way to win in Tic Tac Toe - the diagonals! Let's embark on our diagonal journey starting from the top-left corner:
            </p>

            <img src={twentyNine} className='demo__image' alt='img_twentyEight' />

            <p>
            This line checks the diagonal cells from the top-left corner (0,0) to the bottom-right corner (2,2) of our game_state grid. It inspects if all symbols on this diagonal path match the current player's symbol.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Diagonal Win Detection: Tic Tac Toe isn't just about vertical and horizontal lines; a winning streak can be carved diagonally across the board too. This code sets out to find wins along one of those diagonal routes.
                </li>

                <li>
                Streamlined Syntax: Like our row and column checks, the all() function with a generator expression ensures the check is concise and neat.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                all(): As previously discussed, this Python function returns True if all elements in the given iterable evaluate as true. Here, it's determining if each cell on the diagonal has the player's symbol.
                </li>

                <li>
                game_state[i][i]: This clever indexing method ensures we're checking diagonal cells. Since row and column indices are the same on this diagonal, the variable i plays a dual role, moving us from (0,0) to (2,2).
                </li>

                <li>
                == symbol: This checks if the symbol in the current diagonal cell matches the player's symbol.
                </li>

                <li>
                for i in range(3): A generator expression. It iterates through values 0, 1, and 2, allowing us to examine each cell along the top-left to bottom-right diagonal.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of this diagonal like the main ramp in a grand building, leading from the entrance on the ground floor to a balcony on the top floor. Every step (cell) on that ramp must be adorned with the right emblem (symbol) to herald a triumph!<br/><br/>Kudos, architect! 🎉📐 Checking diagonals is like adding ornate archways to our game structure, ensuring players have diverse and thrilling paths to victory. Keep that hard hat on; our Tic Tac Toe skyscraper is gaining some impressive floors! 🏢👷‍♂️🎮
            </h3>
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 29: Confirming the Win! 🎉🚀
            </h2>

            <p>
            After checking all potential winning combinations, we now address the outcome for the top-right to bottom-left diagonal win condition:
            </p>

            <img src={thirty} className='demo__image' alt='img_twentyNine' />

            <p>
            This line will return True if the preceding conditions (checking rows, columns, and both diagonals) have found a win for the current player.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Acknowledging Victory: If any of our earlier checks in the check_win function determine a win, this return True confirms that victory.
                </li>

                <li>
                Breaking Out: Once a return statement is executed, the function stops. Thus, if a win is found, there's no need to continue the remaining checks.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                return: A keyword in Python used to send a result back from a function.
                </li>

                <li>
                True: A boolean value representing truth in Python. It's like saying, "Yes, we have a winner!"
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Imagine you've been inspecting every floor of a building for a specific feature. Once you find it, there's no need to keep searching. You can confirm its presence right away. That's what this line does for our game!<br/><br/>Fantastic! 🎉 We've built an efficient mechanism to identify and declare a win. As we progress, you'll see how this all fits neatly into our grand game blueprint! Keep the momentum going! 🚧🏗️🎮
            </h3>
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 30: Scanning the Second Diagonal for a Win! 🏗️🔍
            </h2>

            <p>
            After examining the top-left to bottom-right diagonal, let's check the other diagonal direction for a win:
            </p>

            <img src={thirtyOne} className='demo__image' alt='img_thirty' />

            <p>
            This line inspects the diagonal cells from the top-right corner (0,2) to the bottom-left corner (2,0) of our game_state grid. It verifies if all the symbols on this diagonal match the current player's symbol.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Exploring All Win Conditions: Winning in Tic Tac Toe can be achieved through this second diagonal just as through the first. By inspecting this diagonal, we ensure that we cover all possible win scenarios.
                </li>

                <li>
                Pythonic Elegance: Using Python's list comprehension and the all() function, this line efficiently checks the diagonal in a concise way.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>
            
            <ul>
                <li>
                all(): A built-in Python function that returns True if all items in an iterable are true, otherwise it returns False.
                </li>

                <li>
                game_state[i][2 - i]: Targets each cell in the second diagonal.
                </li>

                <li>
                for i in range(3): Iterates three times (for the 3x3 grid) allowing us to check each cell on the diagonal.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of this diagonal check as inspecting the final structural beam of a building. It's one of the crucial pieces ensuring stability (or a win)!<br/><br/>Stay engaged! 🚧 You're really getting a handle on the intricate checks of the game. Up next, we'll look into scenarios that neither player achieves a win. Keep building! 🏗️🎮
            </h3>
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 31: Signaling a Win from the Second Diagonal! 🎉🔔
            </h2>

            <p>
            After thoroughly checking the second diagonal, we now deal with the outcome:
            </p>

            <img src={thirtyTwo} className='demo__image' alt='img_thirtyOne' />
            <p>
            Should the previous check (top-right to bottom-left diagonal) identify a win for the current player, this line confirms that victory by returning a True value.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Confirming Victory: If the top-right to bottom-left diagonal check finds a win, this return True statement affirms it.
                </li>

                <li>
                Ending the Function Early: In Python, once a return statement is executed, the function stops. Thus, if a win is detected, there's no need to proceed with the following code in the function.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                return: A keyword in Python used to provide a result from a function, signaling its end.
                </li>

                <li>
                True: A boolean value in Python, representing truth. It's akin to declaring, "Yes, we've got a winner here!"
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of this as placing a flag on a completed section of our game project. We've verified this part (the second diagonal) is sound and ready!<br/><br/>Great job sticking through! 🎉 We're taking every possible win condition into account to ensure our game works flawlessly. Up next, we will start addressing the scenario where there's no winner. Keep up the excellent work! 🚧🛠️🎮
            </h3>
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 32: What if There's No Winner on the Grid? 🤷‍♂️🎲
            </h2>

            <p>
            Let's transition to address the scenario when no diagonal, row, or column is dominated by a single player.
            </p>

            <img src={thirtyThree} className='demo__image' alt='img_thirtyTwo' />

            <p>
            This line sends back a value of False if, after all checks, no winning condition is met for the current player.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>


            <ol>
                <li>
                No Win Detected: If none of the previous win conditions (for rows, columns, or diagonals) have been met, we must convey that there's no winner (yet).
                </li>

                <li>
                Concluding the Function: Just as with the return True, the function concludes upon hitting a return statement. Here, it's communicating, "We've looked everywhere, and there's no win for the current player."
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                return: The keyword responsible for terminating the function and producing a result.
                </li>

                <li>
                False: A boolean value in Python, representing the opposite of truth. Here, it's our way of stating, "No winner found."
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Consider this like inspecting a building for specific criteria (like a win) and then announcing, "Nope, this structure doesn't meet those particular criteria." We're setting clear standards!<br/><br/>You're doing amazing! 🌟 Tic Tac Toe might seem simple on the surface, but as we're discovering, there's a lot going on behind the scenes to ensure a smooth game experience. Next, we'll delve further into game scenarios and mechanics. Keep constructing! 🏗️🎮
            </h3>
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>      
            <h2>
            Step 33: Checking for a Draw! 🤝⏳
            </h2>

            <p>
            We've tackled the win conditions, and now it's time to check for another possible outcome of the game—a draw.
            </p>
            <img src={thirtyFour} className='demo__image' alt='img_thirtyThree' />

            <p>
            This line declares a new function called check_draw. A function serves as a reusable chunk of code designed to perform a specific task—in this case, verifying if the game has ended in a draw.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Identifying Draws: Not every Tic Tac Toe game ends with a winner; sometimes, it's a tie. This function will help us detect those scenarios.
                </li>

                <li>
                Organizing Code: By defining functions for distinct tasks, we're making our code more modular and readable. It’s much simpler to locate and manage sections of our program.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                def: This keyword in Python indicates we're about to define a new function.
                </li>

                <li>
                check_draw(): This is the name of our function. The parentheses () are where we would include parameters if needed, but in this case, our function doesn’t require any.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of defining a function like setting up a new workstation in our construction site. Each station (or function) has a specific role, tools, and materials to get its job done. Here, the check_draw station is equipped to validate draws!<br/><br/>Fantastic! 🌠 Now that we've prepared our draw-checking station, in the upcoming steps, we'll populate it with the necessary tools and instructions. Ready to keep building? 🧱🔍🎮
            </h3>
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 34: Is Every Cell Occupied? 🧩🔍
            </h2>

            <p>
            Time to dive into the actual workings of our check_draw function. Let's start by evaluating the game board.
            </p>

            <img src={thirtyFive} className='demo__image' alt='img_thirtyFour' />

            <p>
            This line checks if every cell in our Tic Tac Toe grid (represented by the game_state list) is occupied. If every cell is filled (i.e., none are None), it means the game has reached a draw (given that no win conditions were met earlier).
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Determine a Stalemate: Draws in Tic Tac Toe occur when all cells are occupied, but no player has met the win conditions.
                </li>

                <li>
                Efficient Checking: Using the all() function twice, this code quickly sweeps through the entire grid to check for any unoccupied spots.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>
            <ul>
                <li>
                all(): A Python function that returns True only if every element in the given iterable is true (or, in simpler terms, if they all meet a specific condition).
                </li>

                <li>
                cell is not None for cell in row: This is a generator expression. For each cell in a given row, it checks if the cell is not None, indicating the cell has either an 'X' or an 'O'.
                </li>

                <li>
                for row in game_state: Another generator, this one moves through every row in our game_state list.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Picture the game board as a building with multiple rooms (cells). This function is like a quick inspection, swiftly moving from room to room, ensuring each one is occupied. If even a single room is empty, the whole check reports as false.<br/><br/>Excellent progress! 🚀 With this step, we've set up a rapid and efficient way to ascertain draws in our game. Ready to tackle the next game mechanic? Let's keep building! 🏢🎮
            </h3>
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 35: Rebuilding After the Game! 🔄🛠️
            </h2>

            <p>
            After a round of Tic Tac Toe ends, it's time to reset the board and play again. Let's lay the foundation for our reset mechanism:
            </p>

            <img src={thirtySix} className='demo__image' alt='img_thirtyFive' />

            <p>
            This line starts the definition of a new function, aptly named restart_game. This function's role will be to reset our game board and prepare it for a fresh game once called upon.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Fresh Starts: After each game, whether it ends in a win or a draw, we'll want to clear the board and play again without manually restarting the entire program.
                </li>

                <li>
                Code Organization: By bundling the restart process in a function, we keep our code clean, making it easier to identify and modify the game reset process if needed.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                def: This Python keyword indicates we're defining a new function.
                </li>

                <li>
                restart_game(): This is our function's name. The parentheses () are there to include parameters if necessary, but our function doesn’t need any for now.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Consider the restart_game function like the "reset" button on a machine. When pressed (or in our case, called), it restores everything to its initial state, ready for a new cycle!<br/><br/>Great! 🌈 Now that we have laid out our game's reset blueprint, subsequent steps will equip this function with the tools and actions needed to clear our board and get us ready for another exciting round. Ready to move on? 🏗️🎮
            </h3>
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 36: Setting the Stage Anew! 🔄🎭
            </h2>

            <p>
            We are further diving into the restart_game function. Let's get to the first action within this function:
            </p>

            <img src={thirtySeven} className='demo__image' alt='img_thirtySix' />

            <p>
            This line informs Python that, within our restart_game function, we want to work with the global variables game_state and player_turn, instead of creating new, function-local versions of them.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Manipulate Original Data: We're about to modify the original game state and the turn of the player in this reset function. Thus, we need to reference the global versions of these variables to ensure we're making changes that persist outside the function.
                </li>

                <li>
                Avoiding Local Shadows: Without declaring them as global, any assignment to these names would create local variables, overshadowing the global ones without actually modifying them.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                global: This Python keyword is used within a function to identify variables that exist outside the function, at a global scope.
                </li>

                <li>
                game_state, player_turn: These are the global variables we're declaring our intent to modify within this function.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of global as the key that unlocks and grants you access to the main control room (global scope) from a side room (local scope). With this key, you can make changes that impact the entire building (program) and not just the room you're currently in!<br/><br/>Amazing job! 🎉 By referencing our global variables correctly, we're ensuring that our restart_game function has the power to truly reset the game. Next, we'll see the exact steps it takes to get our board and turn logic back to the start. Ready to continue the construction? 🏗️🎲
            </h3>
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 37: Back to Square One! 🔄🏰
            </h2>

            <p>
            Taking another step in our restart_game function, we're preparing the board for a new game:
            </p>

            <img src={thirtyEight} className='demo__image' alt='img_thirtySeven' />

            <p>
            This line resets the game_state variable to its initial state: a 3x3 grid (represented as a list of lists) where all elements are set to None, indicating that no moves have been played on the board.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Board Initialization: The purpose of the restart_game function is to reset the game. One of the primary things to reset is the board itself, ensuring every slot is empty and ready for new moves.
                </li>

                <li>
                Clearing Past Moves: Overwriting the game_state with a new, empty board removes any traces of the previous game.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                [[None, None, None] for _ in range(3)]: This is a list comprehension, a compact way in Python to construct lists. It creates a list of three elements ([None, None, None]) three times, effectively creating our 3x3 grid.
                </li>

                <li>
                for _ in range(3): The loop iterates three times.
                </li>

                <li>
                _: The underscore is a convention used in Python to indicate that the loop variable isn't going to be used inside the loop.
                </li>

                <li>
                [None, None, None]: This constructs a list with three None values, representing an empty row in our Tic Tac Toe board.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Resetting game_state is like clearing the land before constructing a new building. You're ensuring a clean slate, so the new structure (or game) has a solid foundation without any remnants from the past.<br/><br/>Fantastic! 🎉 By clearing our game_state, we're ensuring that each new game begins with an empty board. It's all about building (or in this case, rebuilding) with precision. Let's keep moving forward with our game restart process! Ready to keep constructing? 🛠️🎯
            </h3>
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 38: Whose Turn is it Anyway? 🔄🎤
            </h2>

            <p>
            Advancing in our restart_game function, we now set the stage for the first move:
            </p>

            <img src={thirtyNine} className='demo__image' alt='img_thirtyEight' />

            <p>
            This line resets the player_turn variable to True, indicating that it's Player X's turn to make a move.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Setting Starting Player: As we know, in the game of Tic Tac Toe, Player X always goes first. This line ensures that after restarting the game, Player X gets the first move.
                </li>

                <li>
                Ensuring Game Consistency: By resetting the turn, we maintain the traditional flow of the game and provide consistency to the players.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                player_turn = True: This is a simple assignment statement. The variable player_turn is assigned the boolean value True.
                </li>
            </ul>  

            <h3>
            🔨 Construction Tip: Think of player_turn as the spotlight on a stage. By setting it to True, you're shining the spotlight on Player X, letting everyone know it's their time to perform (or in this case, make a move).<br/><br/>Superb work! 🎉 By setting the stage right, you're ensuring that the game always starts with Player X. This is like ensuring the opening act of a play is always consistent, setting the tone for the rest of the show. Ready to see what's next in our construction blueprint? 🏗️🔍
            </h3>
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 39: A Fresh Canvas! 🎨🖌️
            </h2>

            <p>
            Continuing with our restart_game function, we're now setting the visual scene for the new game:
            </p>

            <img src={forty} className='demo__image' alt='img_thirtyNine' />

            <p>
            This line clears the game screen, filling it entirely with the color white. It's like wiping a whiteboard clean before starting a new session.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Visual Cleanup: We need to visually reset the game, removing any drawings (Xs and Os) from the previous round.
                </li>

                <li>
                Preparation for New Game: Before we draw a fresh grid for the next game, we want a clean canvas to start with.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                screen: This is the main surface or canvas where we're going to draw our game elements.
                </li>

                <li>
                .fill(WHITE): This method is used to fill the entire screen with a specific color. In this case, the color is white, as specified by the constant WHITE.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Picture this step as cleaning and prepping a wall before painting a new mural. It ensures that the new design is clear, bright, and unobstructed by any old marks.<br/><br/>Well done! 🎉 By ensuring our game screen is fresh and clean, we're setting the stage for the next exciting round. Just like a painter with a new canvas, the possibilities are now endless! Ready to continue our gaming masterpiece? 🎮🌌
            </h3>
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 40: Drawing the Lines of Battle! 📐🎨
            </h2>

            <p>
            As we set the stage for the next round, it's time to draw our Tic Tac Toe grid once more:
            </p>

            <img src={fortyOne} className='demo__image' alt='img_forty' />

            <p>
            This line calls the draw_grid function, which draws the 3x3 grid onto our cleared screen. This grid is essential for our game as it divides the screen into nine cells where players place their Xs and Os.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Laying the Playground: In Tic Tac Toe, the 3x3 grid is the primary component where all the action happens. By drawing the grid, we set the boundaries for our game.
                </li>

                <li>
                Visual Guide for Players: The grid aids the players in identifying where they can make their next move.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                draw_grid(): This is a function call. By writing the function's name followed by (), we're telling Python to execute all the code inside the draw_grid function.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of draw_grid() as laying down the foundation for a building. Before we can have rooms (or in our case, game moves), we need to outline their boundaries. Without these divisions, our game would be a chaotic mess!<br/><br/>Fantastic! 🎉 With our grid in place, we have a clear and structured battlefield for our players to strategize and compete. The foundation is laid, and the stage is set. Are you pumped for the next line in our coding adventure? 🏗️🎲
            </h3>
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 41: Setting the Scene! 🎬🎥
            </h2>

            <p>
            As we gear up for the game to unfold, we need to make the initial layout to greet our players:
            </p>

            <img src={fortyTwo} className='demo__image' alt='img_fortyOne' />

            <p>
            This line calls our previously defined draw_grid function. Its role is to sketch the familiar 3x3 grid onto our game screen. By drawing this grid, players can easily distinguish the nine cells where they'll place their Xs and Os.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Creating the Arena: Just as a soccer or football game requires a field with clear markings, our Tic Tac Toe game needs a well-defined grid. This grid serves as the battleground where players take turns, aiming for the win.
                </li>

                <li>
                Visual Structure: A clear grid helps players visualize their potential moves and strategize accordingly.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                draw_grid(): This is what we call a function invocation. When Python encounters this line, it jumps to the draw_grid function and executes its content, which, in this case, draws our Tic Tac Toe grid.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Imagine you're setting up a board game. Before players can start, you'll need to set up the board, laying down all essential parts and boundaries. This step is precisely that—preparing our game board for the exciting rounds ahead.<br/><br/>Great job! 🚀 By sketching our grid, we've taken a crucial step in making the game visually understandable and interactive. The grid is where all the magic happens, after all! Ready to continue crafting this gaming experience? 🎮🕹️
            </h3>
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 42: Keep the Lights On! 💡🏃
            </h2>

            <p>
            After setting up the grid, we need our game to keep running and waiting for the players' input. Let's start the game loop:
            </p>
            <img src={fortyThree} className='demo__image' alt='img_fortyTwo' />

            <p>
            This line introduces a new variable named running and assigns it the value True. This variable will act as a switch to keep our game loop (the heart of our game) continuously running until we decide to stop it.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Infinite Loop Control: We want our game to keep running and listening for events (like mouse clicks, key presses, etc.) until the player decides to close the game. By using the running variable, we can control when this loop starts and stops.
                </li>

                <li>
                Exit Mechanism: When the time comes to stop the game, we'll change the value of running to False, signaling our game loop to conclude and the game to exit.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                running: This is a variable name, much like a label or name tag, helping us keep track of certain information—in this case, whether our game is currently running or not.
                </li>

                <li>
                =: The equal sign here is an assignment operator. It's how we tell Python to assign a particular value (on the right) to a variable (on the left).
                </li>

                <li>
                True: This is a Boolean value, one of the simplest types of values in Python. It can only be True or False.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of running = True as the main power switch to your game's amusement park. With this switch turned on (or set to True), all the rides (game events) can operate. If you flip the switch off (False), it signals it's time to shut everything down.<br/><br/>Way to go! 🎉 With our game's 'power switch' now in place, we are ready to dive into the heartbeats of our game—the game loop. Exciting moments lie ahead as we bring our game to life! 🎢🌌
            </h3>
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 43: The Heartbeat of the Game! ❤️🎮
            </h2>

            <p>
            Let's enter the loop that keeps our game alive and responsive:
            </p>

            <img src={fortyFour} className='demo__image' alt='img_fortyThree' />

            <p>
            This line starts a while loop that will continuously execute its block of code as long as the condition given (in this case, the variable running being True) remains true. This loop will be where most of the game's action occurs!
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Responsive Gameplay: The while loop ensures our game is constantly checking for player input or any other events. It keeps the game "alive" and responsive to players.
                </li>

                <li>
                Controlled Execution: Thanks to the running variable, we can control when this loop (and consequently, our game) continues or stops. If running becomes False, this loop will exit, leading us closer to ending the game session.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                while: This is a keyword in Python that starts a while loop. A while loop will keep repeating its block of code as long as its condition remains true.
                </li>

                <li>
                running: This is our variable (from the previous step) that holds a Boolean value (True or False). The loop will keep running as long as this is True.
                </li>

                <li>
                : The colon indicates the start of the block of code that belongs to the while loop. All the lines of code indented under this line will be part of the loop.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Imagine the while loop as a heart in our gaming world. As long as it's beating (running is True), our game remains alive and kicking. But once the heart stops (when running turns False), our game world concludes its session.<br/><br/>Great job! With the game loop initiated, we're about to dive into the heart of the action, handling all the interesting events that make our Tic Tac Toe game interactive and dynamic. Keep your hard hat on; it's going to be a thrilling ride! 🚀👷‍♂️🎡
            </h3>
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 44: Checking for Player Actions and Game Events 🕹️👾
            </h2>

            <p>
            Moving forward in our game loop:
            </p>

            <img src={fortyFive} className='demo__image' alt='img_fortyFour' />

            <p>
            This line of code initiates a for loop to iterate over a list of "events" that have happened since the last frame. An event, in the context of pygame, can be a variety of things: a mouse click, a keypress, the closing of the window, and so on.
            </p>
            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Responsiveness: The main purpose of this loop is to ensure that our game responds to player inputs and other events.
                </li>

                <li>
                Maintain Flow: If we didn't check for events, the game wouldn't know when, for example, the player clicks the mouse or closes the game window. The game would appear frozen or unresponsive.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                for: This is a keyword in Python used to initiate a for loop, which iterates over items in a sequence.
                </li>

                <li>
                event: A temporary variable that will hold each individual event as we loop through all available events.
                </li>

                <li>
                in: Another Python keyword, used in for loops, to indicate where we are getting our sequence of items from.
                </li>

                <li>
                pygame.event.get(): This is a method from the pygame library. It returns a list of all the events that have occurred since the last frame.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of the game as a vigilant security guard at a busy construction site (our game). The security guard checks everyone (each event) entering the site to see if they're allowed to be there and if they have any tasks to complete.<br/><br/>Well done! You've just ensured that your game is ready to interact with the player! Up next, we'll be defining how our game responds to these events. Keep your tools at the ready! 🎮🔧🎉
            </h3>
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 45: Handling Quit Event 🚪👋
            </h2>

            <p>
            Diving into the event loop, let's examine the next line:
            </p>

            <img src={fortySix} className='demo__image' alt='img_fortyFive' />

            <p>
            This line checks if the current event's type is a QUIT event. In the context of pygame, a QUIT event is generated when the user attempts to close the game window (like pressing the X button in the top corner of the window).
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Graceful Exit: This allows the game to end smoothly, freeing up any resources the game was using and ensuring that nothing continues to run in the background.
                </li>

                <li>
                User Experience: It ensures that the player can easily exit the game whenever they want, leading to a better overall user experience.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                if: A keyword in Python used to check a condition. If the condition is True, the subsequent indented block of code will run.
                </li>

                <li>
                event.type: Here, event represents the current event in our loop, and .type is an attribute of that event which indicates what kind of event it is.
                </li>

                <li>
                ==: This is a comparison operator in Python. It checks if the two values on either side are equal.
                </li>

                <li>
                pygame.QUIT: This is a constant in the pygame library that represents the event of the game window being closed.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Imagine you're in a building (our game), and there are many doors. Each door has a specific function. The line of code we're examining is like a security check for the main exit door. If someone approaches this door (i.e., tries to close the game), the security (our code) recognizes it and lets them out gracefully.<br/><br/>Fantastic! You've set up an essential part of your game loop to respond appropriately when the player wants to end their game session. Next up, we'll continue to inspect and respond to more events in the queue! Keep building! 🎮🏗️🎉
            </h3>
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 46: Stopping the Game Loop 🛑🔄
            </h2>

            <p>
            Moving on, we come across this line:
            </p>

            <img src={fortySeven} className='demo__image' alt='img_fortySix' />

            <p>
            When executed, this line of code sets the value of the variable running to False. Given the context of our game loop (while running:), setting running to False will stop the game loop, ultimately ending the game's main execution.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Control Flow: It helps us maintain control over our game loop. By setting running to False, we can gracefully exit the game loop, ensuring that all events and processes within the loop are concluded properly.
                </li>

                <li>
                Respond to User Input: Since this line is under the condition if event.type == pygame.QUIT:, it ensures that when the user wishes to exit the game (like pressing the close button), the main game loop stops, and the game finishes its run smoothly.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                running: This is our control variable for the main game loop. Its value determines whether the loop should continue running (True) or stop (False).
                </li>

                <li>
                =: This is the assignment operator in Python. It assigns the value on its right to the variable on its left.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Consider the game loop as a powered conveyor belt in a factory, and running is the main switch. When everything is running smoothly, and the belt is moving, the switch (running) is in the True position. However, when it's time to shut down for the day or for maintenance, we flip the switch to False, stopping the belt and allowing workers to safely wrap up their tasks.<br/><br/>Excellent! You've now set up the way for the game to understand when the player wants to exit. With each line, you're refining the player's experience and making sure everything runs like a well-oiled machine. Keep it up! 🎮🏆🔧
            </h3>
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 47: Handling Mouse Clicks 🖱️👆
            </h2>

            <p>
            Moving forward, let's delve into the next line of the script:
            </p>

            <img src={fortyEight} className='demo__image' alt='img_fortySeven' />

            <p>
            This line checks if the type of event that just occurred is a mouse button press, specifically when a player clicks a button on their mouse.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Player Interaction: The core of Tic Tac Toe involves players making their moves by clicking on the grid's cells. This line helps detect that action, setting the stage for processing the player's move.
                </li>

                <li>
                Event Filtering: Pygame handles numerous types of events - from keyboard presses to joystick movements. By checking the event.type against pygame.MOUSEBUTTONDOWN, we're specifically filtering out and acting upon the mouse click events.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                if: This is a conditional statement in Python that checks the truthiness of a condition. If the condition is True, the following indented block of code will execute.
                </li>

                <li>
                event.type: Here, event refers to the individual events fetched from pygame.event.get(), and type retrieves the type of event it is.
                </li>

                <li>
                ==: This is the equality operator in Python. It checks if the value on its left is equal to the value on its right.
                </li>

                <li>
                pygame.MOUSEBUTTONDOWN: This is a predefined constant in Pygame that represents the event of a mouse button being pressed down.
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of pygame.MOUSEBUTTONDOWN as the sensor at a construction site's entry gate. Whenever a vehicle (in our case, the mouse click event) passes through, the sensor detects it and signals the control room (our game script) that a vehicle (or a mouse click) has entered the premises.<br/><br/>Great job! 🎉 With this line, you've laid the foundation for players to interact with the game. Imagine being at the helm of a crane; every button press makes it swing or lift. In our game, every mouse click determines a player's move, shaping the outcome of the match. Exciting, isn't it? Onward! 🚧🕹️🏗️
            </h3>
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 48: Acquiring Mouse Position 🖱️🎯
            </h2>

            <p>
            We're progressing steadily through our construction site! Our next brick in the wall is:
            </p>

            <img src={fortyNine} className='demo__image' alt='img_fortyEight' />

            <p>
            This line captures the current position of the mouse when it is clicked. The x-coordinate is stored in the variable x, and the y-coordinate is stored in the variable y.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Locating the Click: To determine which cell of the Tic Tac Toe grid the player has clicked on, we need to know where on the screen the mouse was clicked.
                </li>

                <li>
                Interaction Feedback: By understanding the position of the mouse click, we can provide immediate feedback by placing an 'X' or 'O' on the respective cell.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                x, y =: This is an example of multiple assignment in Python, where two variables (x and y) are assigned values in a single line.
                </li>

                <li>
                pygame.mouse.get_pos(): This is a Pygame method that retrieves the current position of the mouse. The method returns a tuple of two values representing the x and y coordinates of the mouse cursor.
                <ul>
                    <li>
                    The first value in the tuple is the x-coordinate (horizontal position) of the mouse.
                    </li>

                    <li>
                    The second value is the y-coordinate (vertical position) of the mouse.
                    </li>
                </ul> 
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Imagine the game screen as a grid of tiles, and our mouse pointer is like a construction worker walking over them. When the worker (mouse) stops and raises their hand (clicks), our site manager (the game) needs to know exactly which tile they are standing on to carry out the next operation. This line of code is our way of finding that out!<br/><br/>Fantastic work, builder! 🏗️ By using this line, we've added a layer of interactivity to our game, ensuring that the player's actions have a direct impact on the game's state. Each click is a step closer to victory or defeat! Ready for the next piece of the puzzle? 🧩🔧🚀
            </h3>
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 49: Calculating the Grid Position of the Click 🖱️➡️🔲
            </h2>

            <p>
            Time to build the next level! Let's examine the following code:
            </p>

            <img src={fifty} className='demo__image' alt='img_fortyNine' />

            <p>
            This line translates the raw pixel coordinates of the mouse click (x and y) into grid coordinates (grid_x and grid_y) of our Tic Tac Toe game. In other words, it helps us determine in which of the 3x3 squares the player clicked.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Simplifying Coordinates: The game screen is a matrix of pixels, but our Tic Tac Toe game works on a 3x3 grid. By converting the exact pixel position into this grid format, we can easily determine where the player intended to place their symbol.
                </li>

                <li>
                Efficient Processing: This format is easier to work with when checking game status, determining wins, or draws, and updating the game's visual display.
                </li>
            </ol>

            <h3>
            Breaking down the syntax:
            </h3>

            <ul>
                <li>
                grid_x, grid_y =: Another instance of Python's multiple assignment. This assigns values to two variables in one shot.
                </li>

                <li>
                x // (WIDTH // 3), y // (HEIGHT // 3): This bit of math magic does the conversion.

                <ul>
                    <li>
                    //: This is the floor division operator. It divides the number to its left by the number to its right, rounding down to the nearest whole number.
                    </li>

                    <li>
                    WIDTH // 3 and HEIGHT // 3: Since our Tic Tac Toe grid is 3x3, this calculates the width and height of each cell in the grid.
                    </li>
                    
                    <li>
                    x // (WIDTH // 3): This computes the x-coordinate on our 3x3 grid. For example, if the x-coordinate of the mouse click is in the first third of the screen width, this will be 0, indicating the first column.
                    </li>

                    <li>
                    y // (HEIGHT // 3): Similarly, this determines the y-coordinate on our grid.
                    </li>
                </ul>
                </li>
            </ul>

            <h3>
            🔨 Construction Tip: Think of our screen as a large piece of land and our Tic Tac Toe grid as plots on that land. The exact position where we clicked (in pixels) is like a specific address on the land. However, for our construction (game) purposes, we don't need the exact address; we just need to know which plot it belongs to. This code helps us find that plot!<br/><br/>Brilliant job, construction master! 🏗️ With this step, our game now knows precisely where the player intended to make their mark on the grid. With every brick (line of code), our game structure gets stronger. Ready to continue building? 🧱🔍🎉
            </h3>
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 50: Checking the Validity of the Move 🔍✅
            </h2>

            <p>
            We're about to set some ground rules to ensure that players can't overwrite an already occupied cell.
            </p>

            <img src={fiftyOne} className='demo__image' alt='img_fifty' />

            <p>
            This line checks if the selected cell on the Tic Tac Toe grid (as determined by grid_x and grid_y) is empty, denoted by the value None in our game_state 2D list.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>


            <ol>
                <li>
                Ensuring Fair Play: This logic prevents players from placing their symbol in a cell that's already occupied, keeping gameplay orderly and just.
                </li>

                <li>
                Avoiding Chaos: Permitting symbol overwrites could mess up game logic and lead to unintended outcomes.
                </li>
            </ol>


            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                game_state[grid_y][grid_x]: This retrieves the current value of the cell, identified by grid_y (row) and grid_x (column), within our game board (game_state).
                </li>

                <li>
                is None: Here, we're using the identity operator is to ascertain that the selected cell is indeed unoccupied. If the value of the cell is None, it signifies that neither player has marked it yet.
                </li>
            </ul>

            <h3>
            🛠️ DIY Time: As a fun exercise, try modifying the game to use different indicators for empty cells, like an empty string "" or the number 0. This will require you to adjust several sections of the code, but it's a fun way to personalize your game and become more familiar with its mechanics.<br/><br/>Great job! We're ensuring players stick to the rules and play fairly. Ready to dive deeper? Let's keep the momentum going! 🎢🚀🎉
            </h3>
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 51: Marking the Game Board 🖍️🎲
            </h2>

            <p>
            Time to lay down our symbol on the game board! This is where the action happens.
            </p>

            <img src={fiftyTwo} className='demo__image' alt='img_fiftyOne' />

            <p>
            This line updates the game_state list to record the player's move. Depending on whose turn it is, it assigns either 'X' or 'O' to the selected cell in the grid.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Recording Moves: A game isn't much fun if moves aren't saved! This line ensures that each play is recorded in our game board's memory.
                </li>

                <li>
                Setting the Foundation for Win Checks: For us to determine who wins, we need an up-to-date record of all moves. By recording them, we can later check for any winning patterns.
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                game_state[grid_y][grid_x]: This targets the cell at position grid_y (row) and grid_x (column) on our game board (game_state).
                </li>

                <li>
                'X' if player_turn else 'O': This is a neat Pythonic way to quickly decide between two options. If player_turn is True, then 'X' is chosen. If player_turn is False, 'O' is chosen.
                </li>
            </ul>

            <h3>
            Alright, builder! We've made our mark. Up next, we'll see the visual representation of our move! Stay tuned! 🎨🖌️🎉
            </h3>
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 52: Visualizing the Move on Screen 🎨🖌️
            </h2>

            <p>
            Time to bring your game to life by visually drawing the player's move on the screen!
            </p>

            <img src={fiftyThree} className='demo__image' alt='img_fiftyTwo' />

            <p>
            This line calls the draw_symbol function, which draws either the 'X' or 'O' symbol on the screen, based on whose turn it is. The function uses the coordinates (grid_x and grid_y) to determine where on the game board the symbol should be drawn.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Visual Feedback: In games, it's crucial for players to see the result of their actions immediately. This line ensures that once a player makes a move, they instantly see their symbol on the board.
                </li>

                <li>
                Engaging Gameplay: Instant visual feedback adds to the immersive experience of playing the game. It's one thing to mentally know you made a move; it's another to actually see it!
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                draw_symbol: This is a function we defined earlier in our code. It's responsible for drawing the appropriate symbol ('X' or 'O') on the screen.
                </li>

                <li>
                grid_x, grid_y: These are the coordinates where the player clicked. They determine the exact cell in the game grid where the symbol will be drawn.
                </li>

                <li>
                player_turn: This Boolean value indicates whose turn it is. If it's True, an 'X' is drawn; if False, an 'O' is drawn.
                </li>
            </ul>

            <h3>
            With your game visually reacting to each move, it's truly coming alive! Next, we'll look into determining whether that move resulted in a win or not. 🏆🎲🤩
            </h3>
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 53: Checking for a Win 🎉🏆
            </h2>

            <p>
            Let's find out if someone's got the winning move!
            </p>

            <img src={fiftyFour} className='demo__image' alt='img_fiftyThree' />
            <p>
            This line checks if the current move made by the player results in a winning condition. We have already defined the check_win function earlier in our code to look for any winning patterns (3 in a row, column, or diagonal) based on the symbol of the current player. If the function returns True, that means we have a winner!
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Game Logic: The primary goal in Tic Tac Toe is to get three of your symbols in a row. After every move, the game should check if that move was a winning one.
                </li>

                <li>
                User Experience: Instantly declaring a win as soon as it occurs provides immediate satisfaction to the player and keeps the game dynamic and engaging.
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                check_win: This is a function we defined earlier. It's tasked with checking the game board to see if the current player's move results in a win.
                </li>

                <li>
                player_turn: This is passed to the check_win function to determine which player's win condition to check for ('X' or 'O').
                </li>

                <li>
                if ... :: This is an if statement in Python. The code within this conditional will only execute if the statement returns True.
                </li>
            </ul>

            <h3>
            🎈 Fun Fact: Did you know that if both players play optimally in Tic Tac Toe, the game will always end in a tie? However, most games among casual players don't always follow the optimal path, leading to wins and losses.<br/><br/>Now that we've set up the ability to check for a win, we'll explore what actions to take in the event of a win in the upcoming steps! Exciting times ahead! 🥳🕹️🎮
            </h3>
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 54: Declaring the Winner 🎉🎊
            </h2>

            <p>
            We've just checked for a win, and if there is one, let's celebrate the victor!
            </p>

            <img src={fiftyFive} className='demo__image' alt='img_fiftyFour' />

            <p>
            This line displays a message to the console indicating which player has won the game - 'X' or 'O'.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Feedback: One of the fundamental principles of user experience is feedback. When a player wins, they should be instantly aware of it. This line provides that feedback.
                </li>

                <li>
                Simplicity: Using the Python's print function is a straightforward way to send messages to the console.
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                print(...): The print function in Python displays whatever is inside the parentheses to the console. It's a basic tool in the coder's toolbox for quick and easy output.
                </li>

                <li>
                "X" if player_turn else "O": This is a ternary expression. It's a shorthand way of saying, "If player_turn is True, use 'X', otherwise use 'O'." In the context of our game, when player_turn is True, it means the current player is 'X'.
                </li>
            </ul>


            <h3>
            🎈 Did You Know?: The print function is one of the oldest commands in programming languages. Its main job is to display messages to the user, and it's often one of the first things new programmers learn!<br/><br/>Next up, we'll determine our game's next steps after announcing the winner. Stay tuned, as the game is heating up! 🔥🎲👾
            </h3>
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 55: A Little Pause for Drama 🕰️🎭
            </h2>

            <p>
            Before we proceed with resetting the game or transitioning to another scene, it's essential to give players a moment to acknowledge the win.
            </p>

            <img src={fiftySix} className='demo__image' alt='img_fiftyFive' />
            <p>
            This line tells our program to pause or 'sleep' for a specific amount of time. In this case, it's waiting for 2000 milliseconds, which is equivalent to 2 seconds.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Absorb the Result: By adding a delay, players have a moment to process the game's result before any further actions or transitions take place.
                </li>

                <li>
                Enhanced Experience: This small pause can greatly enhance the game's dramatic feel and overall user experience.
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                pygame.time.delay(...): This function comes from the pygame.time module, and it simply waits for a specified number of milliseconds before continuing with the next line of code.
                </li>

                <li>
                2000: Represents the number of milliseconds for which the program will pause. 1000 milliseconds = 1 second, so 2000 milliseconds is 2 seconds.
                </li>
            </ul>

            <h3>
            🐢 Fun Fact: In the world of gaming and animation, timing and pacing are crucial. Even a slight pause, like the one we're introducing here, can change the feel of an experience significantly.<br/><br/>Soon, we'll explore how to reset our game and give players a fresh start. Get ready; the gaming adventure continues! 🚀🎮🌌
            </h3>
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 56: Starting Fresh! 🌱🔄
            </h2>

            <p>
            Whenever a game concludes, whether it's a win or a draw, it's time to get the board ready for another round.
            </p>

            <img src={fiftySeven} className='demo__image' alt='img_fiftySix' />

            <p>
            This line calls the restart_game() function, which is responsible for resetting the game board and starting a new game.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Continuity: Rather than closing the game or sticking with the end result, we provide players with the opportunity to play again without manually restarting the game.
                </li>

                <li>
                Enhanced Experience: It maintains the flow of the game, ensuring players stay engaged and can easily enjoy multiple rounds without interruption.
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                restart_game(): This is a function call. By writing the name of the function followed by (), we're telling Python to execute the sequence of commands inside that function. In our code, the restart_game() function resets the game state, clears any Xs or Os from the board, and redraws the grid.
                </li>
            </ul>

            <h3>
            🔄 Pro Tip: Always think of ways to keep your players engaged in the game. Offering an easy way to restart or play another round is an excellent method to retain interest and provide replay value.<br/><br/>On to the next one! Keep those coding hard hats on; we're doing great! 🎉🔧🚧
            </h3>
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 57: Detecting a Draw 🎨✌
            </h2>

            <p>
            Alright, let's assess the following line:
            </p>

            <img src={fiftyEight} className='demo__image' alt='img_fiftySeven' />

            <p>
            This line checks if the game has ended in a draw by invoking the check_draw() function. If the function returns True, then the game has resulted in a draw.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Game Logic: In Tic Tac Toe, there are scenarios where neither player wins, and all cells on the board are filled. This is known as a draw or a tie. Our game needs a mechanism to detect such situations to provide feedback to the players.
                </li>

                <li>
                Conditional Execution: The elif keyword, short for "else if", is used here to create a conditional chain. Our game will first check for a win condition, then check for a draw. By structuring our conditions in this manner, we ensure that the game can't mistakenly identify a winning move as a draw.
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                elif: This stands for "else if". In a chain of if, elif, and else statements, Python will evaluate each condition in sequence until it finds one that is True or reaches an else. If a previous condition (like checking for a win) was true, this elif would be skipped.
                </li>

                <li>
                check_draw(): This is a function call. When Python encounters this, it will jump to the check_draw function, run all the commands inside, and then return a value. In this context, if the function returns True, then the code inside this elif block will execute.
                </li>
            </ul>

            <h3>
            🍀 Pro Tip: Always structure your conditional statements in a logical sequence. In games, this helps avoid situations where multiple conditions might overlap, causing unexpected behavior.<br/><br/>Now, let's continue laying down the bricks to complete our game masterpiece! 🚧🔨🎮
            </h3>
        </div>

        <div style={currentStep == 58 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 58: Informing Players of the Draw 📣🤝
            </h2>

            <p>
            After evaluating the draw condition, the next step is to inform the players about it. Here's the next line of code:
            </p>

            <img src={fiftyNine} className='demo__image' alt='img_fiftyEight' />

            <p>
            This line will display a message on the console indicating that the game has ended in a draw.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Player Feedback: It's crucial in games (or any user-facing application) to provide clear feedback. If the game has ended in a draw, players should know about it. This line ensures they are informed.
                </li>

                <li>
                Enhancing UX: Using emojis like 🤝 makes the game feel more friendly and engaging.
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                print(): A built-in Python function used for outputting text to the console. Whatever is placed between the parentheses will be displayed.
                </li>

                <li>
                "It's a draw! 🤝": A string of text placed inside the print function. The text, including the emoji, will be displayed to the players.
                </li>
            </ul>

            <h3>
            🚀 Next Steps: After informing players of the draw, the game might conclude or offer players the chance to restart. Depending on our game's structure, the following steps could involve cleaning up the game state, or prompting users if they'd like another round.<br/><br/>Ready to continue building? Let's keep the momentum going! 🎲🛠️👾
            </h3>
        </div>

        <div style={currentStep == 59 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 59: Adding a Short Pause 🕒💤
            </h2>

            <p>
            Here's the next line in our Tic Tac Toe game:
            </p>

            <img src={sixty} className='demo__image' alt='img_fiftyNine' />

            <p>
            This line adds a delay or pause in the game for 2000 milliseconds, which is equivalent to 2 seconds.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                User Experience: Pausing for a brief moment allows the player to process game results, such as when someone has won or if there's a draw. Without this delay, the game might refresh too quickly, making it difficult for the players to keep up.
                </li>

                <li>
                Visual Feedback: This pause provides an opportunity for the players to see the final state of the board before the game restarts or ends. It's a vital piece in ensuring that users understand the outcome of their moves.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                pygame.time: This is a module within Pygame used to handle time-based operations.
                </li>

                <li>
                delay(2000): The delay function causes the program to pause. The number inside the parentheses is the amount of time in milliseconds that the program will wait. In this case, 2000 milliseconds equals 2 seconds.
                </li>
            </ul>

            <h3>
            🔨 Builder's Tip: Always consider the user experience when determining how long a delay should be. Too short, and the user might miss something important. Too long, and they might become impatient. In our game, 2 seconds is a reasonable amount of time for players to process the end result before moving on.
            </h3>
        </div>

        <div style={currentStep == 60 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 60: Resetting for a New Game 🔄🎮
            </h2>

            <p>
            Now that we've given the players a moment to process the game's outcome, let's see the next line:
            </p>

            <img src={sixtyOne} className='demo__image' alt='img_sixty' />

            <p>
            This line calls the restart_game() function, which we've defined earlier in our code. The function's purpose is to reset the game state and prepare it for a new round.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Continuity: It allows players to start a new game immediately after finishing one without having to restart the entire program.
                </li>

                <li>
                Enhanced Experience: Offering a seamless transition between rounds keeps players engaged and makes the game more enjoyable.
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax 📖:
            </h3>

            <ul>
                <li>
                restart_game(): This is a function call. When Python sees the name of a function followed by (), it executes the commands within that function. In our case, restart_game() performs actions like clearing the board, resetting player turns, and redrawing the grid.
                </li>
            </ul>

            <h3>
            🛠️ Builder's Note: Creating a smooth and intuitive user experience is vital in game development. Allowing players to restart easily after a game concludes is a small but essential feature that enhances the overall enjoyment of your game.<br/><br/>Keep on constructing! The next steps might bring some exciting challenges! 🚧🛠️🎲
            </h3>
        </div>

        <div style={currentStep == 61 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 61: Alternating Player Turns 🔄🎮
            </h2>

            <p>
            In Tic Tac Toe, it's essential to switch player turns after each move. Here's the corrected line:
            </p>

            <img src={sixtyThree} className='demo__image' alt='img_sixtyOne' />

            <p>
            This line ensures that after each valid move (neither a win nor a draw), it switches the turn to the other player. If it was Player X's turn, it becomes Player O's turn, and vice versa.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Game Logic: It's a fundamental rule of Tic Tac Toe that players take turns. This line enforces that rule.
                </li>

                <li>
                Flow Control: By switching turns, we keep the game moving forward. Without this, the same player would keep making moves, and the game wouldn't progress.
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax 📖:
            </h3>

            <ul>
                <li>
                else:: This is part of an if-elif-else conditional structure. If none of the previous conditions were met (i.e., neither a win nor a draw), then this block of code is executed.
                </li>

                <li>
                player_turn = not player_turn: This is a clever way to toggle the player's turn. If player_turn is True, it becomes False, and if it's False, it becomes True. This ensures that the next player in line gets their turn.
                </li>
            </ul>

            <h3>
            🔄 Pro Tip: Efficiently managing player turns is essential in turn-based games like Tic Tac Toe. It ensures fairness and a smooth flow of the game.<br/><br/>Now, with the corrected code, our game's turn-based mechanism is in perfect working order. Let's keep building our game! 🚧🎮🎉
            </h3>
        </div>

        <div style={currentStep == 62 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 62: Updating the Game Display 🖥️🔄
            </h2>

            <p>
            To make sure players see the most recent game state, we need to update the game display. Here's the next line of code:
            </p>

            <img src={sixtyFour} className='demo__image' alt='img_sixtyTwo' />

            <p>
            This line tells Pygame to update the display to reflect any changes made since the last update. In our case, this ensures that any new Xs or Os are drawn, and the display is refreshed.
            </p>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Visual Feedback: Without updating the display, any new moves made by the players won't be visible. This line ensures that the game screen is always up to date.
                </li>

                <li>
                Real-Time Response: In a game, players expect to see the results of their actions immediately. Updating the display provides that real-time feedback.
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax 📖:
            </h3>

            <ul>
                <li>
                pygame.display.update(): This is a function call from the Pygame library. It instructs Pygame to refresh the game display.
                </li>
            </ul>

            <h3>
            🖼️ Visual Note: Think of this line as the moment when an artist steps back from their canvas to admire their work. It ensures that players can see and appreciate the game as it unfolds.<br/><br/>We're getting closer to the finish line! Keep up the great work! 🏁🎲🚀
            </h3>
        </div>

        <div style={currentStep == 63 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 63: Quitting the Game Gracefully 🚪❌
            </h2>

            <p>
            We'll handle the game's quit event properly with the following two lines:
            </p>

            <img src={sixtyFive} className='demo__image' alt='img_sixtyThree' />

            <p>
            What does this code do? 🌟
            </p>

            <ul>
                <li>
                pygame.quit(): This line is responsible for uninitializing all the Pygame modules that were previously initialized. It's good practice to clean up after running a Pygame application to release any resources.
                </li>

                <li>
                sys.exit(): This line exits the Python program. It's a clean way to end the program when the player decides to quit the game.
                </li>
            </ul>

            <p>
            Why are we using this code? 🤔
            </p>

            <ol>
                <li>
                Clean Exit: These lines ensure that all resources held by Pygame are released correctly, preventing potential issues or resource leaks.
                </li>

                <li>
                Graceful Exit: sys.exit() is used to exit the Python program gracefully. It's a standard way to close a program and return control to the operating system.
                </li>
            </ol>

            <h3>
            Syntax Breakdown 📖:
            </h3>
            <ul>
                <li>
                pygame.quit(): This is a function call from the Pygame library. It uninitializes all the Pygame modules that were initialized with pygame.init().
                </li>

                <li>
                sys.exit(): This is a function from the sys module, which is a part of Python's standard library. It exits the Python program and returns control to the operating system.
                </li>
            </ul>

            <h3>
            👋 Farewell, For Now: These lines mark the end of our Tic Tac Toe game when the player chooses to quit. They ensure that the game closes gracefully without any lingering processes.
            </h3>
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>        
    </div>
  )
}

export default TicTacToe