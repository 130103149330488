import React, {useState} from 'react';
import one from './scratchImages/bughuntImages/1.png';
import two from './scratchImages/bughuntImages/2.png';
import three from './scratchImages/bughuntImages/3.png';
import four from './scratchImages/bughuntImages/4.png';
import five from './scratchImages/bughuntImages/5.png';
import six from './scratchImages/bughuntImages/6.png';
import seven from './scratchImages/bughuntImages/7.png';
import eight from './scratchImages/bughuntImages/8.png';
import nine from './scratchImages/bughuntImages/9.png';
import ten from './scratchImages/bughuntImages/10.png';
import eleven from './scratchImages/bughuntImages/11.png';
import twelve from './scratchImages/bughuntImages/12.png';
import thirteen from './scratchImages/bughuntImages/13.png';
import fourteen from './scratchImages/bughuntImages/14.png';
import fifteen from './scratchImages/bughuntImages/15.png';
import sixteen from './scratchImages/bughuntImages/16.png';
import seventeen from './scratchImages/bughuntImages/17.png';
import eighteen from './scratchImages/bughuntImages/18.png';
import nineteen from './scratchImages/bughuntImages/19.png';
import twenty from './scratchImages/bughuntImages/20.png';
import twentyOne from './scratchImages/bughuntImages/21.png';
import twentyTwo from './scratchImages/bughuntImages/22.png';
import twentyThree from './scratchImages/bughuntImages/23.png';
import twentyfour from './scratchImages/bughuntImages/24.png';
import twentyfive from './scratchImages/bughuntImages/25.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import "./tutorial.css";

const BugHunter = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 23;


  return (
    <div className='scratchGames__container'>
        <h1>
        🐞🔍 "CodeQuest: The Bug Hunter's Odyssey!" 🔎🐛
        </h1>

        <h3 className='intro'>
        Embark on "CodeQuest: The Bug Hunter's Odyssey!" 🐞🔍 Dive deep into the intricate maze of code, where sneaky bugs lurk in the shadows, waiting to disrupt the digital realm. 🕵️‍♂️💡 So, gear up, the bugs don't stand a chance. 🐜🚫🔎🎉
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>





        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎉 Step 1 🎉</h2>

          <ul>
            <li>
              To start this project navigate to the Scratch website.
            </li>

            <li>
              Scratch: <a target="_blank" href="https://scratch.mit.edu/" >https://scratch.mit.edu/</a>
            </li>

            <li>
              Select the "create" tab at the top to open a new project.
            </li>
          </ul>
          
          <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌟 Step 2 🌟</h2>

          <ul>
            <li>
              Like our last coding adventure, our cat friend is no longer needed. Delete him!
            </li>

            <li>
              Click the "new sprite" button and look for the frog.
            </li>

            <li>
              This frog loves to eat bugs.
            </li>
          </ul>
          
          <img src={two} className='demo__image' alt='img_two' />
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚀 Step 3 🚀</h2>

          <ul>
            <li>
              Start coding with the first two necessary blocks. The "when flag clicked" and the "forever" block. By now you know that the "forever" block makes any code inside of it repeat forever!
            </li>
          </ul>
          
          <img src={three} className='demo__image' alt='img_three' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎈 Step 4 🎈</h2>

          <ul>
            <li>
              Now add the "go to mouse pointer" block, what will this do you ask?
            </li>

            <li>
              Since the "go to mouse pointer" block is inside of the "forever" block the frog will forever follow your "mouse" when you click the "flag" button.
            </li>
          </ul>

          <img src={four} className='demo__image' alt='img_four' />
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🔥 Step 5 🔥</h2>
          <ul>
            <li>
              Time for a tasty snack!
            </li>

            <li>
              Add the new bug sprite that you see in the image below.
            </li>

            <li>Once you select the bug, change the "size" box to make it bite sized!</li>
          </ul>
          
          <img src={five} className='demo__image' alt='img_five' />
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>💡 Step 6 💡</h2>

          <ul>
            <li>
              Let's get coding this bug!
            </li>

            <li>
              Fun fact: a "bug" in coding refers to an error or glitch in a program... but here our "bug" is literally a beetle!
            </li>

            <li>
              Add a "when flag clicked" block.
            </li>

            <li>
              Then create a "score" variable, in the "variables section".
            </li>

            <li>
              After creating the "score" variable add a block that reads: "set score to zero"
            </li>
          </ul>
          
          <img src={six} className='demo__image' alt='img_six' />
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍀 Step 7 🍀</h2>

          <ul>
            <li>
              Add a "forever" loop under our previous block!
            </li>
          </ul>
          
          <img src={seven} className='demo__image' alt='img_seven' />
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌈 Step 8 🌈</h2>
          <ul>
            <li>
              This upcoming set of code that we are creating will make the bug get eaten if the frog touches it.
            </li>

            <li>
              We need an "if block" to check if the frog is touching the bug.
            </li>

            <li>
              Add an "if block" and inside of it a "touching frog" statement, to check if the bug is touching the frog!
            </li>
          </ul>
          
          <img src={eight} className='demo__image' alt='img_eight' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎁 Step 9 🎁</h2>
          <ul>
            <li>
              What do we do if the bug is touching the frog?
            </li>

            <li>
              First, we need to increase the player's score by one by adding a "change score by 1" block.
            </li>

            <li>
              Second, we need a block to play the "pop" sound.
            </li>

            <li>
              Add the two new blocks that you see below.
            </li>
          </ul>
          
          <img src={nine} className='demo__image' alt='img_nine' />
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎶 Step 10 🎶</h2>

          <ul>
            <li>
              But there is another thing that needs to happen.... when the frog touches the bug it needs to respawn at a different (but random) location.
            </li>

            <li>
              To star this process, add fetch the following blocks: "go to X: Y:", and two green "pick random" blocks.
            </li>

            <li>
              The first "pick random" block should have the first bubble of "-200" and the second of "200"
            </li>

            <li>
              The second "pick random" block should have the first bubble of "-150" and the second of "150".
            </li>

            <li>
              We will soon piece everything together!
            </li>
          </ul>
          
          <img src={ten} className='demo__image' alt='img_ten' />
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌺 Step 11 🌺</h2>

          <ul>
            <li>
              Place the pick random with the "-200/200" in the X bubble.
            </li>

            <li>
              Place the pick random with the "-150/150" in the Y bubble.
            </li>

            <li>
              Place this sequence in the forever block.
            </li>

            <li>
              This sequence to put the bug at a random position!
            </li>
          </ul>
          
          <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍄 Step 12 🍄</h2>

          <ul>
            <li>
              The entire "if block" is complete; with the bug touching the frog, we add a point, play a sound, and respawn the bug at a random position.
            </li>

            <li>
              The only thing left is to drag and drop our "if block" into the "forever" block; now we can "forever" do these things in the game!
            </li>
          </ul>
          
          <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍪 Step 13 🍪</h2>

          <ul>
            <li>
              A new foe has arrived!
            </li>

            <li>
              Like the frog is a predator to the bug, the frog has an enemy of its own! The bat!
            </li>

            <li>
              The bat will chase the frog and end the game if he catches up.
            </li>

            <li>
              Find the "bat" sprite in the "new sprites" section and add it into the game!
            </li>
          </ul>
          
          <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐾 Step 14 🐾</h2>

          <ul>
            <li>
              Once you have added the bat, change the original size property of "100" to "70".
            </li>
          </ul>
          
          <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌊 Step 15 🌊</h2>
          <ul>
            <li>
              Let us code the bat to hunt the frog.
            </li>

            <li>
              Add a "when flag clicked" block.
            </li>

            <li>
              Add a "wait" block and change the time to "0.5" seconds.
            </li>
          </ul>
          
          <img src={fifteen} className='demo__image' alt='img_fifteen' />
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍉 Step 16 🍉</h2>
          <ul>
            <li>
              The next two blocks are seen below.
            </li>

            <li>
              A "forever" block is needed to make the bat chase the frog forever and ever!
            </li>

            <li>
              In the "forever" block, place a "point towards frog" block to make it face the frog.
            </li>
          </ul>
          
          <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎭 Step 17 🎭</h2>
          <ul>
            <li>
              Add a "move 4 steps" block, this is what will make the bat to fly at the frog.
            </li>

            <li>
              Then add an "if block". This "if block" will say what should happen if the bat touches the frog.
            </li>
          </ul>

          <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎨 Step 18 🎨</h2>

          <ul>
            <li>
              Create the statement "if touching frog". Then add the result "stop all" to stop and end the game. The frog has been caught by the evil bat!
            </li>
          </ul>
          
          <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚴 Step 19 🚴</h2>
          <ul>
            <li>
              Test your progress and click the "flag" button to see what you have so far!
            </li>
          </ul>
          
          <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍦 Step 20 🍦</h2>

          <ul>
            <li>
              We shall move onward, code the bat so that if can flap its wings as it fly's right at the frog.
            </li>

            <li>
              Place the two new blocks: "when flag clicked" and "forever".
            </li>
          </ul>
          
          <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌍 Step 21 🌍</h2>
          <ul>
            <li>
              The first block of code in the "forever" will is the "next costume" block; this will cycle the bat through all of its costume to make it look like it is flying.
            </li>

            <li>
              The following block sets the rate at which it flaps its wings, go ahead and add these to your canvas!
            </li>
          </ul>
          
          <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🕺 Step 22 🕺</h2>
          <ul>
            <li>
              A blank, white screen is no fun. We need a mysterious or ominous setting to play out game.
            </li>

            <li>
              This is where to "new background" button comes into play. Locate it at the bottom right corner of your screen and choose the background of your liking. Mine is a castle!
            </li>
          </ul>
          
          <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎤 Step 23 🎤</h2>
          <ul>
            <li>
              Time to wrap it up! Give your project a name that would make anyone want to play it and save your progress!
            </li>
          </ul>
          
          <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>


        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default BugHunter