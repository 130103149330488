import React from 'react';
import './teacherResources.css';
import Navbar from '../../components/navbar/navbar';
import axios from 'axios';
import api from '../../api/api';


const TeacherResources = () => {

  const ResourceTable = [
    ["scratch", "apple-catch"], // 0
    ["scratch", "bug-hunter"], // 1
    ["scratch", "dino-jump"], // 2
    ["scratch", "dragon-park"], // 3
    ["scratch", "pong"], // 4
    ["scratch", "underwater-adventures"], // 5
    ["scratch", "angry-sharks"], // 6
    ["scratch", "food-fight"], // 7

    ["python", "pong"], // 8
    ["python", "snake"], // 9
    ["python", "breakout"], // 10
    ["python", "space-invaders"], // 11
    ["python", "tic-tac-toe"], // 12
    ["python", "maze-runner"], // 13
    ["python", "hangman"], // 14
    ["python", "chicken-catch"], // 15
    ["python", "enemy-escape"], // 16
    ["python", "number-guesser"], // 17

    ["roblox", "jump-quest"], // 18
    ["roblox", "gem-hunter"], // 19
    ["roblox", "castle-conundrum"], // 20
    ["roblox", "maze-runner"], // 21
    ["roblox", "paintball-blitz"], // 22
    ["roblox", "cheese-chase"], // 23
    ["roblox", "soccer-showdown"], // 24
    ["roblox", "save-the-city"], // 25

    ["fullstack", "about-capybaras"], // 26
    ["fullstack", "pepes-travel-blog"], //27
    ["fullstack", "poison-dart-frog"], //28
    ["fullstack", "squidink-studios"], //29
    ["fullstack", "fin-fancy"], // 30
    ["fullstack", "sunny-side-forecast"], // 31
    ["fullstack", "build-a-ship"], // 32
    ["fullstack", "comfy-couch-co"], // 33
    ["fullstack", "top-five-books"], // 34
    ["fullstack", "cookie-clicker"], // 35
    ["fullstack", "color-changer"], // 36
    ["fullstack", "robux-calculator"], // 37
    ["fullstack", "rock-paper-scissors"], // 38
    ["fullstack", "onion-daycare"], // 39
    ["fullstack", "quiz-app"] // 40
  ]

  const fetchResource = async (no) => {
    var selectedResource = ResourceTable[no]

    const options = {};
    if (selectedResource[0] === "python" || selectedResource[0] === "roblox" || selectedResource[0] === "fullstack") {
        options.responseType = "arraybuffer";
    }
    
    await api.post(`${process.env.REACT_APP_API_URL}/teacher-resource`, {
        selectedResource
    }, options).then((res)=> {
        console.log(res)

        if (selectedResource[0] == "scratch") {
            window.open(res.data[1], "_blank");
        }

        if (selectedResource[0] == "python" || selectedResource[0] == "roblox" || selectedResource[0] == "fullstack") {
            const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/zip" }))
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${selectedResource[1]}.zip`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    }).catch((err)=> {
        console.log(err)
    })
  }

  return (
    <div className='teacher-resources'>
        <Navbar />

        <div className='project-menu'>

            <div className='curriculum'>
                <div className='option label'>
                    <h2>Scratch</h2>
                </div>

                <div onClick={()=>fetchResource(0)} className='option project'>
                    <p>Apple Catch</p>
                </div>

                <div onClick={()=>fetchResource(1)} className='option project'>
                    <p>Bug Hunter</p>
                </div>

                <div onClick={()=>fetchResource(2)} className='option project'>
                    <p>Dino Jump</p>
                </div>

                <div onClick={()=>fetchResource(3)} className='option project'>
                    <p>Dragon Park</p>
                </div>

                <div onClick={()=>fetchResource(4)} className='option project'>
                    <p>Pong</p>
                </div>

                <div onClick={()=>fetchResource(5)} className='option project'>
                    <p>Underwater Adventures</p>
                </div>

                <div onClick={()=>fetchResource(6)} className='option project'>
                    <p>Angry Sharks</p>
                </div>

                <div onClick={()=>fetchResource(7)} className='option project'>
                    <p>Food Fight</p>
                </div>
            </div>

            <div className='curriculum'>
                <div className='option label'>
                    <h2>Python</h2>
                </div>

                <div onClick={()=>fetchResource(8)} className='option project'>
                    <p>Pong</p>
                </div>

                <div onClick={()=>fetchResource(9)} className='option project'>
                    <p>Snake</p>
                </div>

                <div onClick={()=>fetchResource(10)} className='option project'>
                    <p>Breakout</p>
                </div>

                <div onClick={()=>fetchResource(11)} className='option project'>
                    <p>Space Invaders</p>
                </div>

                <div onClick={()=>fetchResource(12)} className='option project'>
                    <p>Tic Tac Toe</p>
                </div>

                <div onClick={()=>fetchResource(13)} className='option project'>
                    <p>Maze Runner</p>
                </div>

                <div onClick={()=>fetchResource(14)} className='option project'>
                    <p>Hangman</p>
                </div>

                <div onClick={()=>fetchResource(15)} className='option project'>
                    <p>Chicken Catch</p>
                </div>

                <div onClick={()=>fetchResource(16)} className='option project'>
                    <p>Enemy Escape</p>
                </div>

                <div onClick={()=>fetchResource(17)} className='option project'>
                    <p>Number Guesser</p>
                </div>
            </div>

            <div className='curriculum'>
                <div className='option label'>
                    <h2>Roblox</h2>
                </div>

                <div onClick={()=>fetchResource(18)} className='option project'>
                    <p>Jump Quest</p>
                </div>

                <div onClick={()=>fetchResource(19)} className='option project'>
                    <p>Gem Hunter</p>
                </div>

                <div onClick={()=>fetchResource(20)} className='option project'>
                    <p>Castle Conundrum</p>
                </div>

                <div onClick={()=>fetchResource(21)} className='option project'>
                    <p>Maze Runner</p>
                </div>

                <div onClick={()=>fetchResource(22)} className='option project'>
                    <p>Paintball Blitz</p>
                </div>

                <div onClick={()=>fetchResource(23)} className='option project'>
                    <p>Cheese Chase</p>
                </div>

                <div onClick={()=>fetchResource(24)} className='option project'>
                    <p>Soccer Showdown</p>
                </div>

                <div onClick={()=>fetchResource(25)} className='option project'>
                    <p>Save The City</p>
                </div>
            </div>

            <div className='curriculum'>
                <div className='option label'>
                    <h2>Fullstack Engineering</h2>
                </div>

                <div onClick={()=>fetchResource(26)} className='option project'>
                    <p>About Capybaras</p>
                </div>

                <div onClick={()=>fetchResource(27)} className='option project'>
                    <p>Pepe's Travel Blog</p>
                </div>

                <div onClick={()=>fetchResource(28)} className='option project'>
                    <p>Poison Dart Frog</p>
                </div>

                <div onClick={()=>fetchResource(29)} className='option project'>
                    <p>SquidInk Studios</p>
                </div>
                
                <div onClick={()=>fetchResource(30)} className='option project'>
                    <p>Fin Fancy</p>
                </div>

                <div onClick={()=>fetchResource(31)} className='option project'>
                    <p>Sunny Side Forecast</p>
                </div>

                <div onClick={()=>fetchResource(32)} className='option project'>
                    <p>Build A Ship</p>
                </div>

                <div onClick={()=>fetchResource(33)} className='option project'>
                    <p>Comfy Couch Co</p>
                </div>

                <div onClick={()=>fetchResource(34)} className='option project'>
                    <p>Top Five Books</p>
                </div>

                <div onClick={()=>fetchResource(35)} className='option project'>
                    <p>Cookie Clicker</p>
                </div>

                <div onClick={()=>fetchResource(36)} className='option project'>
                    <p>Color Changer</p>
                </div>

                <div onClick={()=>fetchResource(37)} className='option project'>
                    <p>Robux Calculator</p>
                </div>

                <div onClick={()=>fetchResource(38)} className='option project'>
                    <p>Rock Paper Scissors</p>
                </div>

                <div onClick={()=>fetchResource(39)} className='option project'>
                    <p>Onion Daycare</p>
                </div>

                <div onClick={()=>fetchResource(40)} className='option project'>
                    <p>Quiz App</p>
                </div>
            </div>


        </div>
    </div>
  )
}

export default TeacherResources