import React, {useState} from 'react'
import one from './pythonImages/enemyImages/1.png';
import two from './pythonImages/enemyImages/2.png';
import three from './pythonImages/enemyImages/3.png';
import four from './pythonImages/enemyImages/4.png';
import five from './pythonImages/enemyImages/5.png';
import six from './pythonImages/enemyImages/6.png';
import seven from './pythonImages/enemyImages/7.png';
import eight from './pythonImages/enemyImages/8.png';
import nine from './pythonImages/enemyImages/9.png';
import ten from './pythonImages/enemyImages/10.png';
import eleven from './pythonImages/enemyImages/11.png';
import twelve from './pythonImages/enemyImages/12.png';
import thirteen from './pythonImages/enemyImages/13.png';
import fourteen from './pythonImages/enemyImages/14.png';
import fifteen from './pythonImages/enemyImages/15.png';
import sixteen from './pythonImages/enemyImages/16.png';
import seventeen from './pythonImages/enemyImages/17.png';
import eighteen from './pythonImages/enemyImages/18.png';
import nineteen from './pythonImages/enemyImages/19.png';
import twenty from './pythonImages/enemyImages/20.png';
import twentyOne from './pythonImages/enemyImages/21.png';
import twentyTwo from './pythonImages/enemyImages/22.png';
import twentyThree from './pythonImages/enemyImages/23.png';
import twentyFour from './pythonImages/enemyImages/24.png';
import twentyFive from './pythonImages/enemyImages/25.png';
import twentySix from './pythonImages/enemyImages/26.png';
import twentySeven from './pythonImages/enemyImages/27.png';
import twentyEight from './pythonImages/enemyImages/28.png';
import twentyNine from './pythonImages/enemyImages/29.png';
import thirty from './pythonImages/enemyImages/30.png';
import thirtyOne from './pythonImages/enemyImages/31.png';
import thirtyTwo from './pythonImages/enemyImages/32.png';
import thirtyThree from './pythonImages/enemyImages/33.png';
import thirtyFour from './pythonImages/enemyImages/34.png';
import thirtyFive from './pythonImages/enemyImages/35.png';
import thirtySix from './pythonImages/enemyImages/36.png';
import thirtySeven from './pythonImages/enemyImages/37.png';
import thirtyEight from './pythonImages/enemyImages/38.png';
import thirtyNine from './pythonImages/enemyImages/39.png';
import forty from './pythonImages/enemyImages/40.png';
import fortyOne from './pythonImages/enemyImages/41.png';
import fortyTwo from './pythonImages/enemyImages/42.png';
import fortyThree from './pythonImages/enemyImages/43.png';
import fortyFour from './pythonImages/enemyImages/44.png';
import fortyFive from './pythonImages/enemyImages/45.png';
import fortySix from './pythonImages/enemyImages/46.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import './tutorial.css';

const Enemy = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 46;

  return (
    <div className='pythonGames__container'>
        <h1>
        🏃 Flee the enemies !! 🏃
        </h1>

        <h3 className='intro'>
        In this tutorial, we'll be building an exhilarating game called "Enemy Escape". 🎮 So, buckle up, and let's begin this amazing journey together as we dodge foes, team up with friends, and conquer worlds! 🌎
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>



        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 1: Readying the Environment🎨🖼️
            </h2>

            <p>
                Before we can start coding this Python project, we need to setup proper file structure within Visual Studio Code and download some necessary assets!
            </p>

            <img src={one} className='demo__image' alt='img_one' />

            <ol>
            <li>Create a "Enemy Escape" folder to contain your project.</li>

            <li>Open this folder with Visual Studio Code.</li>

            <li>Add a file and name it "enemy.py". "enemy" being the files name and ".py" letting Visual Studio Code that this is a Python file.</li>

            <li>Add a folder to contain the images that we will download a program for this project.</li>

            <li>Download the images by clicking the button below and storing them in your "images" folder.</li>
            </ol>

            {/*<div className="downloadImageButtonContainer">
            <Download
                src={friend}
                filename="friend.png"
            />

            <Download
                src={monster}
                filename="monster.png"
            />

            <Download
                src={player}
                filename="player.png"
            />

            </div>*/}
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 2: Kickstarting Our Adventure – Importing Essentials 🚀📚
            </h2>

            <p>
                Ready to dive into the thrilling world of 'Enemy Escape'? Our first step is to lay the foundation by importing the essential Python libraries. Think of it as gathering our tools before embarking on a crafting journey! 🛠️🌟
            </p>

            <img src={two} className='demo__image' alt='img_two' />

            <p>
            Let's understand what these imports mean for our game: 🧩🤔
            </p>

            <ol>
                <li>
                    <strong>import pygame</strong>: This line brings in the Pygame library.
                    <ul>
                        <li>Pygame is a set of Python modules designed for writing video games. It includes functionalities for graphics, sound, and handling events, providing the building blocks for our game.</li>
                        <li>By importing Pygame, we're equipping our game with the ability to create a window, draw objects, handle user inputs, and more – the essentials of game development.</li>
                    </ul>
                </li>
                <li>
                    <strong>import random</strong>: This line imports Python's 'random' module.
                    <ul>
                        <li>The 'random' module is used for generating random numbers. In 'Enemy Escape', we'll use this to add unpredictability, like the random placement of friends and enemies.</li>
                        <li>Utilizing randomness can make our game more dynamic and exciting, ensuring each playthrough feels unique.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Import Statements</strong>: In Python, the 'import' keyword is used to bring in external libraries or modules. These imports are essential to access additional functionalities that are not built into Python's core.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Pygame, built on top of SDL (Simple DirectMedia Layer), is a popular choice among game developers for creating 2D games in Python. It's known for its simplicity and flexibility, making it a great tool for both beginners and experienced developers. 🎮🐍
            </h3>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 3: Powering Up the Game Engine – Initializing Pygame 🎮🔌
            </h2>

            <p>
                Now that we have our tools ready, it's time to ignite the engine of our game. In this step, we're going to initialize Pygame, which is like starting the engine of a car before a road trip. 🚗💨
            </p>

            <img src={three} className='demo__image' alt='img_three' />

            <p>
                Let's explore the importance of this crucial line: 🛠️🌟
            </p>

            <ol>
                <li>
                    <strong>pygame.init()</strong>: This line starts the Pygame library.
                    <ul>
                        <li>The <code>pygame.init()</code> function is called to initialize all the Pygame modules. It's a necessary step to set up everything we need for game development.</li>
                        <li>This initialization process prepares Pygame to handle things like graphics, sound, and input events. It's essential for getting all the functionalities of Pygame up and running.</li>
                    </ul>
                    Just like a car needs to be started before it can move, Pygame needs to be initialized before we can create our game. It sets the stage for all the exciting functionalities we'll be using. 🎬🕹️
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Initialization Function</strong>: <code>pygame.init()</code> is a standard function call in Pygame that activates the library's components. It's a common first step in most Pygame scripts.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Initialization in programming often involves setting up the necessary conditions or parameters to start a process. In game development, this includes preparing the system for graphics rendering, sound playback, and handling user input. 🖥️🎶
            </h3>
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 4: Setting the Stage – Creating the Game Window 🖼️🎨
            </h2>

            <p>
                Every great game needs a stage, and in 'Enemy Escape', our stage is the game window. This step is all about setting up this window, defining where our game's action will unfold. It's like building a theater stage for a play! 🏗️🎭
            </p>

            <img src={four} className='demo__image' alt='img_four' />

            <p>
            Let's delve into the specifics of setting up our game window: 🖥️📏
            </p>

            <ol>
                <li>
                    <strong>WIDTH, HEIGHT = 800, 600</strong>: Determining the size of the game window.
                    <ul>
                        <li>Here, we're defining the dimensions of our game window. <code>WIDTH</code> and <code>HEIGHT</code> are set to 800 and 600 pixels, respectively, creating a reasonably sized area for our gameplay.</li>
                        <li>These dimensions serve as the canvas for our game, determining how much space we have to display graphics, animations, and other game elements.</li>
                    </ul>
                </li>
                <li>
                    <strong>screen = pygame.display.set_mode((WIDTH, HEIGHT))</strong>: Creating the game window.
                    <ul>
                        <li>This line creates the actual game window using Pygame's <code>display.set_mode()</code> function, with the dimensions we just defined.</li>
                        <li>The variable <code>screen</code> will be used as the main surface for drawing our game's graphical elements, like characters and backgrounds.</li>
                        <li>Setting up the display mode is crucial for rendering anything on the screen. It's the digital canvas where our game's visual story will be told.</li>
                    </ul>
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Game Window Dimensions</strong>: Setting the size of the game window is one of the first steps in game development, defining the visual boundaries of the game.
                </li>
                <li>
                    <strong>Creating the Display Surface</strong>: <code>pygame.display.set_mode()</code> initializes the main surface where all the drawing happens, essential for any graphical output in the game.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The size and aspect ratio of the game window can greatly affect the game's design and player experience. Developers carefully choose these dimensions to suit the game's style, platform, and audience preferences. 🌐👾
            </h3>
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 5: Naming Our Adventure – Setting the Game's Title 🏷️🎮
            </h2>

            <p>
                Just like a book needs a title, our game needs a name displayed on the window. This step is about giving our game window its title, 'Enemy Escape', making it recognizable and inviting. It's akin to putting a nameplate on the door of a thrilling adventure room! 🚪✨
            </p>

            <img src={five} className='demo__image' alt='img_five' />

            <p>
            Let's understand the significance of this line: 📛🔍
            </p>

            <ol>
                <li>
                    <strong>pygame.display.set_caption("Enemy Escape")</strong>: This line sets the title of our game window.
                    <ul>
                        <li>The <code>pygame.display.set_caption()</code> function is used to assign a title to the game window. Here, "Enemy Escape" becomes the label that appears in the window's title bar.</li>
                        <li>Setting a window caption helps to identify the game when it's running, especially when a player might have multiple windows open. It's a small detail that adds to the professionalism and polish of the game.</li>
                    </ul>
                    Naming the game window is a simple yet important step. It's the first thing players see and sets the tone for their experience. It's like the cover of a book, drawing players into the world we've created. 🖼️🌌
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Window Caption</strong>: The <code>set_caption()</code> function in Pygame allows developers to easily label their game window, enhancing user interface and experience.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The title of a game can be a crucial part of its branding and marketing. A compelling title can capture the essence of the game and pique the curiosity of potential players, making it an important aspect of game design. 🎲📖
            </h3>
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 6: Bringing the Hero to Life – Loading and Scaling the Player Image 🦸‍♂️🌟
            </h2>

            <p>
                Every epic tale needs a hero, and in 'Enemy Escape', our hero takes shape through the player character. This step is about loading the player's image and scaling it to the right size for our game. It's like choosing the perfect costume for the lead actor in a play! 🎭👕
            </p>

            <img src={six} className='demo__image' alt='img_six' />

            <p>
            Let's break down these actions and their roles in our game: 📸🔧
            </p>


            <ol>
                <li>
                    <strong>player_image = pygame.image.load("images/player.png")</strong>: Loading the player image.
                    <ul>
                        <li>This line uses Pygame's <code>image.load</code> function to load the image file for our player character. It's akin to casting the protagonist for our digital adventure.</li>
                        <li>The image file, located at "images/player.png", is accessed and readied for use in the game. This visual representation is crucial for player identification and immersion.</li>
                    </ul>
                </li>
                <li>
                    <strong>player_image = pygame.transform.scale(player_image, (100, 100))</strong>: Adjusting the image size.
                    <ul>
                        <li>After loading the image, it's often necessary to adjust its size to fit the game's design. This line scales the image to a width and height of 100 pixels each, ensuring it's neither too big nor too small for our game window.</li>
                        <li>Proper scaling is important for maintaining visual consistency and balance in the game's graphics. It ensures the player character is appropriately proportioned in relation to other elements.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Image Loading and Scaling</strong>: These steps demonstrate how to use Pygame's image processing capabilities to integrate visual elements into the game, vital for creating a visually appealing and engaging game environment.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The choice of visuals for game characters can significantly impact player engagement. A well-designed character not only enhances the aesthetic appeal but also helps players connect more deeply with the game, enhancing the overall experience. 🖼️🎮
            </h3>
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 7: Designing Allies – Loading and Scaling the Friend Image 🤝🌟
            </h2>

            <p>
                In the world of 'Enemy Escape', our hero isn't alone; they have allies! This step is about bringing these friends to life by loading and scaling their images. It's similar to selecting the supporting cast in a theater production, each character adding depth to the story. 🎭👥
            </p>

            <img src={seven} className='demo__image' alt='img_seven' />

            <p>
            Let's understand the importance of these steps: 🖼️🔍
            </p>

            <ol>
                <li>
                    <strong>friend_image = pygame.image.load("images/friend.png")</strong>: Loading the friend's image.
                    <ul>
                        <li>This line uses Pygame's <code>image.load</code> function to load the visual representation of the player's allies, the 'friends', from the given file path.</li>
                        <li>Having visual representations for allies helps players to easily distinguish them from enemies, enhancing the gameplay experience.</li>
                    </ul>
                </li>
                <li>
                    <strong>friend_image = pygame.transform.scale(friend_image, (100, 100))</strong>: Resizing the image for consistency.
                    <ul>
                        <li>After loading the image, we adjust its size to ensure it fits well within our game's visual scheme. Scaling the image to 100x100 pixels keeps the friend characters proportionate to the player and other elements.</li>
                        <li>Consistent sizing is key to a visually cohesive game world. It ensures that all characters appear balanced and part of the same universe.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Loading and Scaling Graphics</strong>: This process showcases how to effectively incorporate and standardize visual elements in a game, ensuring they contribute positively to the game's overall aesthetic and narrative.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The design and appearance of ally characters in games not only enrich the story but also influence player strategy and interaction. Well-crafted allies can add layers of complexity and enjoyment to the gameplay. 🕹️👫
            </h3>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 8: Introducing the Adversaries – Loading and Scaling the Monster Image 🐉🎭
            </h2>

            <p>
                Every hero's journey includes challenges, and in 'Enemy Escape', these come in the form of monsters! This step is about adding our adversaries to the game by loading and scaling their images. It's like crafting the villains in a story, essential for creating tension and excitement. 🦹‍♂️✨
            </p>

            <img src={eight} className='demo__image' alt='img_eight' />

            <p>
            Let's explore the impact of these lines in our game: 👾🔍
            </p>

            <ol>
                <li>
                    <strong>bad_friend_image = pygame.image.load("images/monster.png")</strong>: Loading the monster image.
                    <ul>
                        <li>Here, we're using Pygame's <code>image.load</code> function to load the image that represents the monsters – the antagonists in our game.</li>
                        <li>Visual distinction between friends and foes is crucial in games. The monster's image is selected to be visually different and recognizable as a challenge to the player.</li>
                    </ul>
                </li>
                <li>
                    <strong>bad_friend_image = pygame.transform.scale(bad_friend_image, (100, 100))</strong>: Resizing the image for gameplay.
                    <ul>
                        <li>Once loaded, we scale the monster image to the appropriate size of 100x100 pixels. This ensures that the monsters are neither too intimidating nor too insignificant compared to other game characters.</li>
                        <li>Scaling maintains visual balance in the game, ensuring that all characters, whether allies or enemies, fit harmoniously within the game's world.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Adding and Standardizing Enemies</strong>: This step highlights the process of integrating enemy characters into the game, balancing their visual impact with other elements for a cohesive experience.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The design of enemies in a game plays a significant role in defining its difficulty and appeal. Well-designed adversaries can transform a simple game into a compelling narrative, challenging the player's skills and enriching the gameplay. 🎮👹
            </h3>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 9: Preparing for Text Display – Initializing Pygame Fonts 📝🎨
            </h2>

            <p>
                As we craft our 'Enemy Escape' adventure, it's important to communicate with players through text. This step involves initializing Pygame's font module, essential for displaying text on the screen. It's like preparing the ink and quill for writing letters in a medieval tale. 🖋️📜
            </p>

            <img src={nine} className='demo__image' alt='img_nine' />

            <p>
            Let's delve into the purpose of initializing the font module: 🖊️🔍
            </p>

            <ol>
                <li>
                    <strong>pygame.font.init()</strong>: Starting up the font module.
                    <ul>
                        <li>This line activates Pygame's font system. The <code>font.init()</code> function is a necessary step to enable text rendering in the game.</li>
                        <li>With this initialization, we gain the ability to create text objects, which can be used to display scores, messages, and other vital game information.</li>
                        <li>Communicating effectively with players through text is crucial for instructions, feedback, and storytelling within the game.</li>
                    </ul>
                    Initializing the font module is a key step in game development. It unlocks the power of text, allowing us to create a more immersive and informative gaming experience. 📖🕹️
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Text Rendering Capability</strong>: By initializing the font module, we prepare Pygame to handle and render text, a fundamental aspect of user interface design in games.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The ability to display text in games has evolved significantly over the years. From simple scorekeeping to complex narratives, text in games plays a pivotal role in guiding, engaging, and immersing players in diverse gaming worlds. 🌐📚
            </h3>
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 10: Choosing Our Game's Font – Creating a Text Style 🖋️🌟
            </h2>

            <p>
                After initializing Pygame's font system, it's time to choose the style and size of text we'll use in 'Enemy Escape'. This step is about creating a font object that will give a distinct look to all the text in our game. It's like selecting the perfect font for a storybook, setting the tone for the narrative. 📚✒️
            </p>
            
            <img src={ten} className='demo__image' alt='img_ten' />

            <p>
            Let's explore the role of this font in our game: 📃🔍
            </p>

            <ol>
                <li>
                    <strong>font = pygame.font.Font(None, 36)</strong>: Defining the game's font.
                    <ul>
                        <li>This line creates a new Font object from Pygame's font module. The <code>Font(None, 36)</code> function call specifies the font style and size.</li>
                        <li>Using <code>None</code> as the first argument tells Pygame to use the default system font. This choice ensures compatibility and consistency across different platforms.</li>
                        <li>The second argument, <code>36</code>, sets the font size. This size makes the text large enough to be easily readable, which is essential for conveying game information clearly.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Font Creation</strong>: The <code>pygame.font.Font</code> function is versatile, allowing for both the use of system default fonts and custom fonts, providing flexibility in designing the game's text elements.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The choice of font in a game is more than just a design decision. It affects readability, player immersion, and the overall aesthetic. A well-chosen font can significantly enhance the player's experience and interaction with the game. 🎨🎮
            </h3>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 11: Crafting Our Hero – Defining the Player Class 🦸‍♂️🔧
            </h2>

            <p>
                In the heart of 'Enemy Escape' lies our main character: the player. This crucial step is about defining the Player class, the blueprint for our hero. It's like sketching out the main character in a story, outlining their capabilities and role. 📝🌟
            </p>

            <img src={eleven} className='demo__image' alt='img_eleven' />

            <p>
            Let's delve into the significance of this class in our game: 🚀👤
            </p>

            <ol>
                <li>
                    <strong>class Player:</strong> Beginning the player class definition.
                    <ul>
                        <li>This line marks the start of defining a new class named <code>Player</code>. In object-oriented programming, a class serves as a template for creating objects.</li>
                        <li>The Player class will encapsulate all the properties and methods (like movement and drawing) associated with the player character in the game.</li>
                        <li>By creating a class, we establish a structured and reusable code framework for our player, ensuring that our main character can be easily managed and modified within the game.</li>
                    </ul>
                    The creation of the Player class is a foundational step in building our game's interactivity and logic. It's the first stroke in painting the character who will lead the adventure in 'Enemy Escape'. 🖌️🎮
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Class Definition</strong>: In Python, the <code>class</code> keyword is used to define a new class, providing a means of bundling data and functionality together.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The concept of classes in programming allows for the creation of complex, interactive elements in games. Classes are like the building blocks of a game's code, each one representing a different part of the game world. 🏗️🌐
            </h3>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 12: Building the Hero – The Player's Initialization 🚀🧑‍🚀
            </h2>

            <p>
                With the Player class underway, it's time to define how our hero comes to life in the game. This step focuses on the constructor of the Player class, setting up the initial state of our main character. Think of it as outfitting our hero with the tools and traits they need for the adventure! 🛠️🎒
            </p>

            <img src={twelve} className='demo__image' alt='img_twelve' />

            <p>
            Let's break down what each part of this constructor does: 🗺️🛠️
            </p>

            <ol>
                <li>
                    <strong>def __init__(self, x, y, image):</strong> Defining the constructor.
                    <ul>
                        <li>This line declares the initialization method <code>__init__</code>, which is automatically called when a new instance of the Player class is created.</li>
                        <li>It takes parameters for the initial x and y coordinates, and the image of the player. These parameters help customize the player's position and appearance when they're created.</li>
                    </ul>
                </li>
                <li>
                    <strong>self.x, self.y, self.image:</strong> Setting the player's attributes.
                    <ul>
                        <li>These lines assign the provided x and y coordinates, and the image to the player instance. They define where the player will appear on the screen and what they will look like.</li>
                    </ul>
                </li>
                <li>
                    <strong>self.rect = self.image.get_rect()</strong>: Creating a rectangle for the player.
                    <ul>
                        <li>This line creates a rectangular area (or 'rect') around the player's image. Rects are used in Pygame for handling positions, sizes, and collision detection.</li>
                    </ul>
                </li>
                <li>
                    <strong>self.rect.topleft = (x, y)</strong>: Positioning the rectangle.
                    <ul>
                        <li>This sets the top-left corner of the player's rect to the given x and y coordinates. It ensures that the player's image and their rect are aligned on the screen.</li>
                    </ul>
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Initializing an Object</strong>: The <code>__init__</code> method is a fundamental concept in object-oriented programming, used to set up new objects with their initial state and data.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The rect object in Pygame is a powerful tool. It not only represents the spatial properties of game elements but also simplifies many operations like movement, collision detection, and rendering, making it an essential aspect of game development in Pygame. 📦👾
            </h3>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 13: Visualizing the Hero – The Player's Draw Method 🎨👾
            </h2>

            <p>
                After defining our hero's characteristics, it's time to bring them into view. In this step, we focus on the 'draw' method within the Player class, which is responsible for displaying the player on the game screen. It's like giving the spotlight to our main character on the stage! 🌟🎭
            </p>

            <img src={thirteen} className='demo__image' alt='img_thirteen' />

            <p>
            Let's understand the function of this vital method: 🖌️🖼️
            </p>

            <ol>
                <li>
                    <strong>def draw(self, screen):</strong> Creating the draw method.
                    <ul>
                        <li>This line defines the <code>draw</code> method for the Player class. It's tasked with rendering the player character on the screen.</li>
                        <li>The method takes one argument, <code>screen</code>, which refers to the game window where the player will be drawn.</li>
                    </ul>
                </li>
                <li>
                    <strong>screen.blit(self.image, (self.x, self.y))</strong>: Drawing the player.
                    <ul>
                        <li>The <code>blit</code> function is used to draw the player's image onto the screen. This function is a core part of Pygame's graphics handling.</li>
                        <li>The coordinates <code>(self.x, self.y)</code> determine where on the screen the player's image will appear. They correspond to the player's current position in the game world.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Rendering Graphics</strong>: The draw method and <code>blit</code> function are essential in Pygame for displaying images and sprites on the game screen, crucial for visual storytelling and gameplay.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The ability to visually render characters and objects is what brings a game to life. This visual representation is key to player engagement, making the virtual world tangible and immersive. 🌍🕹️
            </h3>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 14: Mobilizing Our Hero – The Player's Move Method 🏃‍♂️🌐
            </h2>

            <p>
                A hero's journey is defined by their actions and movements. In this step, we'll define the 'move' method in the Player class, enabling our character to navigate through the game world. It's like choreographing the steps of our hero in an epic dance of adventure. 💃🌍
            </p>

            <img src={fourteen} className='demo__image' alt='img_fourteen' />

            <p>
            Let's examine how this method propels our character through the game: 🕹️🔍
            </p>

            <ol>
                <li>
                    <strong>def move(self, dx, dy):</strong> Implementing the move method.
                    <ul>
                        <li>This line introduces the <code>move</code> method, which is responsible for changing the player's position on the screen.</li>
                        <li>The parameters <code>dx</code> and <code>dy</code> represent the change in position along the x-axis and y-axis, respectively.</li>
                    </ul>
                </li>
                <li>
                    <strong>self.x += dx</strong> and <strong>self.y += dy</strong>: Updating the player's coordinates.
                    <ul>
                        <li>These lines adjust the player's x and y coordinates based on the provided movement values, <code>dx</code> and <code>dy</code>.</li>
                        <li>This adjustment allows the player to move left or right (dx) and up or down (dy) within the game window.</li>
                    </ul>
                </li>
                <li>
                    <strong>self.rect.topleft = (self.x, self.y)</strong>: Synchronizing the rectangle's position.
                    <ul>
                        <li>After updating the coordinates, this line aligns the player's rectangle (used for collision detection) with the new position.</li>
                        <li>Ensuring the rectangle's position matches the player's image is crucial for accurate interactions with other game elements, like enemies and allies.</li>
                    </ul>
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Movement and Collision Handling</strong>: The move method is key in dynamic gameplay, allowing characters to navigate and interact within the game's environment.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The principles of character movement in video games are rooted in physics and human perception. Smooth and responsive movement not only makes a game more enjoyable but also more immersive, giving players a sense of control and realism. 🚀🕹️
            </h3>
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 15: Introducing the Allies – Creating the Friend Class 🤝🌟
            </h2>

            <p>
                In the 'Enemy Escape' saga, our hero is not alone; they are accompanied by friends. This step is about defining the Friend class, the blueprint for these ally characters. It's akin to casting supporting actors in a play, each bringing unique elements to the story. 🎭👫
            </p>

            <img src={fifteen} className='demo__image' alt='img_fifteen' />

            <p>
            Let's explore the components of the Friend class: 👥🔧
            </p>

            <ol>
                <li>
                    <strong>class Friend:</strong> Starting the Friend class definition.
                    <ul>
                        <li>This line introduces a new class named <code>Friend</code>. Classes in Python provide a means of bundling data and functionality together.</li>
                        <li>The Friend class will encapsulate the properties and behaviors specific to the allies in our game, such as their appearance and position.</li>
                    </ul>
                </li>
                <li>
                    <strong>def __init__(self, x, y, image):</strong> Defining the constructor.
                    <ul>
                        <li>This line declares the constructor method for the Friend class, which sets up each friend's initial state when an instance is created.</li>
                        <li>The parameters <code>x</code>, <code>y</code>, and <code>image</code> provide the initial position and visual representation for each friend character.</li>
                    </ul>
                </li>
                <li>
                    <strong>self.x, self.y, self.image:</strong> Setting the friend's attributes.
                    <ul>
                        <li>These lines assign the provided coordinates and image to the friend instance, defining where it will appear and what it will look like in the game.</li>
                    </ul>
                </li>
                <li>
                    <strong>self.rect = self.image.get_rect()</strong> and <strong>self.rect.topleft = (x, y)</strong>: Establishing the friend's rectangular area.
                    <ul>
                        <li>These lines create and position a rectangle (or 'rect') around the friend's image. This rect is used for handling positions, sizes, and potentially for collision detection.</li>
                    </ul>
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Building Character Classes</strong>: The Friend class is an example of how object-oriented programming allows for the creation of distinct, interactive game elements, each with their own set of properties and behaviors.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: In games, ally characters can add depth to the gameplay, offering assistance, challenges, or simply enriching the game's narrative. They play a vital role in the player's journey and can significantly enhance the gaming experience. 🎮👍
            </h3>
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 16: Illustrating the Allies – The Friend's Draw Method 🎨👬
            </h2>

            <p>
                After defining the attributes of our allies, it's time to make them visible in the game. In this step, we focus on the 'draw' method within the Friend class, essential for displaying these friendly characters on the screen. It's akin to giving life to the supporting cast on our digital stage. 🖌️🎭
            </p>
            
            <img src={sixteen} className='demo__image' alt='img_sixteen' />

            <p>
            Let's break down the functionality of this method: 📺✨
            </p>

            <ol>
                <li>
                    <strong>def draw(self, screen):</strong> Defining the draw method for allies.
                    <ul>
                        <li>This line introduces the <code>draw</code> method for the Friend class. It's responsible for rendering each friend character on the game screen.</li>
                        <li>The method takes one argument, <code>screen</code>, which is the game window where the friend characters will be displayed.</li>
                    </ul>
                </li>
                <li>
                    <strong>screen.blit(self.image, (self.x, self.y))</strong>: Displaying the friend characters.
                    <ul>
                        <li>This line uses the <code>blit</code> function to draw each friend's image onto the game screen. This is a key function in Pygame for graphics rendering.</li>
                        <li>The coordinates <code>(self.x, self.y)</code> determine the on-screen position where each friend character appears, based on their current location in the game world.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Graphical Representation</strong>: The draw method and the use of <code>blit</code> are central to Pygame for displaying images, crucial for bringing the visual aspects of the game to life.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The way characters are visually depicted in a game can greatly affect the player's perception and connection with them. Thoughtful design and rendering contribute to a richer and more immersive game narrative. 🌌🖼️
            </h3>
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 17: Guiding the Allies – The Friend's Movement Logic 🎮👣
            </h2>

            <p>
                In 'Enemy Escape', friends don't just stand still; they move towards our hero. This step is about programming our allies' movement logic, specifically their approach towards the player. It's like choreographing a dance where the supporting characters move in harmony with the lead. 💃🕺
            </p>

            <img src={seventeen} className='demo__image' alt='img_seventeen' />

            <p>
            Let's dissect the parts of this movement method: 🧭🔄
            </p>

            <ol>
                <li>
                    <strong>def move_towards_player(self, player, speed):</strong> Establishing the method for movement.
                    <ul>
                        <li>This line defines a method in the Friend class responsible for moving each friend towards the player.</li>
                        <li>The method takes two parameters: <code>player</code> (the player object) and <code>speed</code> (the movement speed of the friend).</li>
                    </ul>
                </li>
                <li>
                    <strong>dx = player.x - self.x</strong> and <strong>dy = player.y - self.y</strong>: Calculating the direction.
                    <ul>
                        <li>These lines calculate the horizontal (dx) and vertical (dy) distances between the friend and the player.</li>
                        <li><code>dx</code> and <code>dy</code> are the differences in x and y coordinates, determining the direction the friend needs to move to approach the player.</li>
                    </ul>
                </li>
                <li>
                    <strong>distance = max(abs(dx), abs(dy))</strong>: Determining the movement magnitude.
                    <ul>
                        <li>This line calculates the maximum distance to be covered in either the x or y direction, ensuring the movement is consistent and smooth.</li>
                        <li>Using the maximum of the absolute values of <code>dx</code> and <code>dy</code> helps in maintaining a uniform approach speed towards the player.</li>
                    </ul>
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Movement Calculation</strong>: This step shows the use of basic arithmetic and logic to define the movement behavior of ally characters in the game, adding depth to their interaction with the player.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The behavior of non-player characters (NPCs) like these allies is a crucial aspect of game design. Thoughtful movement patterns can make NPCs feel more lifelike and enhance the overall realism and engagement of the game. 🌍👥
            </h3>
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 18: Advancing the Allies – Completing the Movement Logic 🚶‍♂️🔀
            </h2>

            <p>
                Continuing from our previous step on guiding our friends, we now complete their movement logic. This part of the code ensures that the friends actively move towards the player in the game. It's like directing the supporting actors to follow and interact with the lead character on stage. 🎭👣
            </p>

            <img src={eighteen} className='demo__image' alt='img_eighteen' />

            <p>
            Let's examine the final steps of this movement method: 🧮🏃
            </p>

            <ol>
                <li>
                    <strong>if distance &gt; 0:</strong> Ensuring movement occurs.
                    <ul>
                        <li>This line checks if there is any distance to cover. Movement should only occur if the friend is not already at the player's location.</li>
                    </ul>
                </li>
                <li>
                    <strong>self.x += speed * dx / distance</strong> and <strong>self.y += speed * dy / distance</strong>: Calculating the new position.
                    <ul>
                        <li>These lines update the friend's x and y coordinates. The friend moves a fraction of the distance between its current position and the player's position, scaled by the speed.</li>
                        <li>This approach ensures a smooth and direct movement towards the player, creating a natural and realistic interaction.</li>
                    </ul>
                </li>
                <li>
                    <strong>self.rect.topleft = (self.x, self.y)</strong>: Aligning the collision rectangle.
                    <ul>
                        <li>After updating the coordinates, this line aligns the friend's rectangle (used for collision detection) with its new position.</li>
                        <li>This step is crucial for keeping the friend's visual representation and collision detection in sync, especially important for game mechanics involving interactions.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Dynamic Character Movement</strong>: This part of the code encapsulates how to make in-game characters move in a purposeful and realistic manner, an essential aspect of creating engaging gameplay.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The algorithms for NPC movement are a fascinating part of game AI. They balance complexity and performance to create movements that are both efficient and believable, contributing greatly to the game's overall feel and playability. 🤖🎲
            </h3>
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 19: Introducing the Challenge – Defining the Monster Class 🐉🚧
            </h2>

            <p>
                No adventure is complete without challenges, and in 'Enemy Escape', these challenges come in the form of monsters. In this step, we're defining the Monster class, creating the blueprint for our game's adversaries. It's akin to developing the antagonists in a story, each one adding an element of thrill and challenge. 🎭👹
            </p>

            <img src={nineteen} className='demo__image' alt='img_nineteen' />

            <p>
            Let's delve into the structure of the Monster class: 🧠👾
            </p>

            <ol>
                <li>
                    <strong>class Monster:</strong> Starting the Monster class definition.
                    <ul>
                        <li>This line introduces a new class named <code>Monster</code> in our game. Classes in Python are templates for creating objects with specific attributes and methods.</li>
                        <li>The Monster class encapsulates the properties and behaviors of the enemies in 'Enemy Escape', giving life to the game's challenges.</li>
                    </ul>
                </li>
                <li>
                    <strong>def __init__(self, x, y, image):</strong> Defining the constructor.
                    <ul>
                        <li>This line declares the initialization method for the Monster class, setting up each monster's initial state when an instance is created.</li>
                        <li>The constructor takes parameters for the initial x and y coordinates and the image, allowing customization of the monsters' position and appearance.</li>
                    </ul>
                </li>
                <li>
                    <strong>self.x, self.y, self.image:</strong> Setting the monster's attributes.
                    <ul>
                        <li>These lines assign the provided coordinates and image to the monster instance, defining its starting location and visual representation in the game.</li>
                    </ul>
                </li>
                <li>
                    <strong>self.rect = self.image.get_rect()</strong> and <strong>self.rect.topleft = (x, y)</strong>: Establishing the collision area.
                    <ul>
                        <li>These lines create and position a rectangle (or 'rect') around the monster's image, used for handling positions, sizes, and collision detection.</li>
                        <li>Aligning the rect with the monster's image ensures accurate and fair gameplay interactions, such as collisions with the player or other game elements.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Constructing Enemy Characters</strong>: The Monster class demonstrates how object-oriented programming is used to create distinct, interactive game elements with their own set of characteristics and behaviors.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: In games, the design and behavior of enemy characters are crucial for creating a balanced and engaging challenge. They drive the game's narrative forward and test the player's skills, making them an essential part of the gaming experience. 🎮🔥
            </h3>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 20: Visualizing the Challenges – The Monster's Draw Method 🖼️🐉
            </h2>

            <p>
                After defining our monstrous adversaries, it's time to bring them onto the stage of 'Enemy Escape'. In this step, we focus on the 'draw' method within the Monster class, essential for displaying these formidable foes on the game screen. It's like spotlighting the villains in our digital drama. 🎭👾
            </p>
            
            <img src={twenty} className='demo__image' alt='img_twenty' />

            <p>
                Let's explore the significance of this method: 🎨🔍
            </p>

            <ol>
                <li>
                    <strong>def draw(self, screen):</strong> Crafting the draw method for enemies.
                    <ul>
                        <li>This line introduces the <code>draw</code> method for the Monster class. It's tasked with rendering the enemy characters on the game screen.</li>
                        <li>The method takes one argument, <code>screen</code>, which is the game window where the monsters will be displayed.</li>
                    </ul>
                </li>
                <li>
                    <strong>screen.blit(self.image, (self.x, self.y))</strong>: Displaying the monsters.
                    <ul>
                        <li>This line uses Pygame's <code>blit</code> function to draw each monster's image onto the screen. The <code>blit</code> function is central to Pygame's graphics rendering.</li>
                        <li>The coordinates <code>(self.x, self.y)</code> specify the on-screen position for each monster, reflecting their current location in the game world.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Depicting Enemies</strong>: The draw method is a crucial component in Pygame for visual representation, especially for enemy characters, adding a tangible sense of challenge and interaction in the game.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The visual depiction of enemies in a game greatly influences the game's atmosphere and the player's emotional response. Well-designed enemies can enhance the sense of excitement, tension, and urgency in gameplay. 🎮💥
            </h3>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 21: Programming Enemy Pursuit – The Monster's Movement Method 🏃‍♂️🐉
            </h2>

            <p>
                In 'Enemy Escape', our monsters are not mere static obstacles; they actively pursue the player. This step involves programming the movement behavior of the Monster class, making these foes a moving challenge in the game. It's like choreographing a suspenseful chase scene in an action movie! 🎬🔥
            </p>

            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />

            <p>
            Let's break down this pursuit logic: 🕵️‍♂️🔄
            </p>

            <ol>
                <li>
                    <strong>def move_towards_player(self, player, speed):</strong> Establishing the method for enemy movement.
                    <ul>
                        <li>This line defines a method in the Monster class responsible for moving each monster towards the player.</li>
                        <li>The method takes two parameters: <code>player</code> (the player object) and <code>speed</code> (the movement speed of the monster).</li>
                    </ul>
                </li>
                <li>
                    <strong>dx = player.x - self.x</strong> and <strong>dy = player.y - self.y</strong>: Calculating the direction towards the player.
                    <ul>
                        <li>These lines calculate the horizontal (dx) and vertical (dy) distances between the monster and the player, determining the direction in which the monster needs to move.</li>
                    </ul>
                </li>
                <li>
                    <strong>distance = max(abs(dx), abs(dy))</strong>: Determining the magnitude of movement.
                    <ul>
                        <li>This line calculates the maximum distance to be covered in either the x or y direction, ensuring the movement towards the player is consistent and purposeful.</li>
                        <li>The use of <code>max(abs(dx), abs(dy))</code> helps maintain a uniform approach speed, adding to the realistic behavior of the monsters.</li>
                    </ul>
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Enemy Movement Logic</strong>: This step shows how basic calculations and conditions can be used to define the movement behavior of enemy characters, adding a dynamic and challenging element to the game.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The movement patterns of enemies in video games are a key aspect of game AI. They add depth to the gameplay, requiring players to strategize and react to changing situations, enhancing the overall engagement and challenge of the game. 🎮👾
            </h3>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
             
            <h2>
                Step 22: Enabling the Chase – Finalizing Monster Movement 🚀👣
            </h2>

            <p>
                Building on our previous step, we now complete the movement logic for the monsters in 'Enemy Escape'. This part of the code ensures that the monsters actively pursue the player in the game, adding tension and excitement to the gameplay. It's like giving a dynamic and purposeful stride to our virtual antagonists. 🐾🎮
            </p>

            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />

            <p>
            Let's delve into the final steps of this movement method: 🧮🐉
            </p>

            <ol>
                <li>
                    <strong>if distance &gt; 0:</strong> Checking for movement necessity.
                    <ul>
                        <li>This line ensures that movement only occurs if there is some distance between the monster and the player, avoiding unnecessary calculations when they're already at the same location.</li>
                    </ul>
                </li>
                <li>
                    <strong>self.x += speed * dx / distance</strong> and <strong>self.y += speed * dy / distance</strong>: Determining the new position.
                    <ul>
                        <li>These lines update the monster's x and y coordinates, moving them closer to the player. The amount of movement is proportional to the distance, ensuring a consistent speed.</li>
                        <li>This calculation allows for a direct and smooth pursuit of the player, adding to the challenge of the game.</li>
                    </ul>
                </li>
                <li>
                    <strong>self.rect.topleft = (self.x, self.y)</strong>: Aligning the collision rectangle.
                    <ul>
                        <li>After updating the coordinates, this line aligns the monster's rectangle (used for collision detection) with its new position.</li>
                        <li>Ensuring the collision rectangle accurately follows the monster's visual representation is crucial for fair and realistic gameplay interactions.</li>
                    </ul>
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Completing the Movement Logic</strong>: These lines of code encapsulate how to make in-game enemies move in a realistic and challenging manner, enhancing the dynamics of gameplay.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: In video games, the behavior of enemies greatly influences the game's difficulty and player engagement. Sophisticated movement patterns in enemies can make a game more challenging and rewarding, adding depth to the player's experience. 🎲🤖
            </h3>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 23: Spawning the Hero – Instantiating the Player 🦸‍♂️🌟
            </h2>

            <p>
                With our Player class ready, it's time to bring our hero into the game world. In this step, we create an instance of the Player class, effectively placing our main character into the digital realm of 'Enemy Escape'. It's like casting the lead actor onto the stage of our adventure. 🎭🎬
            </p>

            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />

            <p>
            Let's explore the creation of our player character: 🕹️👤
            </p>

            <ol>
                <li>
                    <strong>player = Player(WIDTH // 2, HEIGHT // 2, player_image)</strong>: Initializing the player instance.
                    <ul>
                        <li>This line creates an instance of the Player class, named <code>player</code>. This instance represents the main character that the game's players will control.</li>
                        <li>The coordinates <code>(WIDTH // 2, HEIGHT // 2)</code> place the player at the center of the game window, providing a balanced starting point in the game environment.</li>
                        <li>The <code>player_image</code> argument assigns the previously loaded and scaled image to the player, giving them their visual appearance.</li>
                    </ul>
                    Instantiating the player is a crucial moment in the game setup. It transforms the code from mere definitions into a living character within the game world, ready for action and interaction. 🚀🎮
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Character Instantiation</strong>: This step demonstrates the instantiation of a class in Python, a fundamental concept in object-oriented programming, bringing the player character to life in the game.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The position and appearance of the player character are key factors in player immersion. A well-placed and visually appealing character can significantly enhance the player's connection to the game and their overall experience. 🌐🕺
            </h3>
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 24: Summoning the Allies – Creating Friend Instances 🤖👫
            </h2>

            <p>
                After preparing our Friend class, it's time to populate the 'Enemy Escape' world with ally characters. This step involves creating multiple instances of the Friend class, each representing an ally in the game. It's like casting a group of supporting actors to enrich the narrative of our digital play. 🎭🌟
            </p>

            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />

            <p>
            Let's explore the creation of these friendly characters: 🎨🤖
            </p>

            <ol>
                <li>
                    <strong>friends = [...]</strong>: Generating a list of friend instances.
                    <ul>
                        <li>This line uses a list comprehension to create a list named <code>friends</code>, which contains multiple instances of the Friend class.</li>
                        <li>Each Friend is positioned randomly within the game window, using <code>random.randint()</code> to determine their x and y coordinates. This randomness adds variety and unpredictability to the game.</li>
                        <li>The loop <code>for _ in range(10)</code> indicates that ten Friend instances will be created, populating the game with a number of allies for the player to interact with.</li>
                        <li>The <code>friend_image</code> argument ensures that all friends share the same visual representation.</li>
                    </ul>
                    Creating multiple friend characters introduces dynamic elements to the game, adding to its richness and depth. It's like having a team of characters, each contributing to the game's story and challenges. 🌍👥
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>List Comprehension and Randomization</strong>: This step showcases the use of list comprehensions for efficient creation of multiple game entities, along with the use of randomization to enhance the game's diversity and unpredictability.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The inclusion of allies or friendly NPCs in games can significantly impact gameplay dynamics. They can offer help, create challenges, or add emotional depth to the game, enhancing player engagement and the overall narrative experience. 🎮❤️
            </h3>
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 25: Unleashing the Adversaries – Creating Monster Instances 🐉👾
            </h2>

            <p>
                As every hero needs a challenge, our game 'Enemy Escape' introduces monsters as the main adversaries. This step is about creating instances of the Monster class, populating our game world with these formidable foes. It's akin to introducing villains into our story, adding tension and excitement. 🎭🚨
            </p>

            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />

            <p>
            Let's delve into the assembly of these challenging entities: 🧩🔥
            </p>

            <ol>
                <li>
                    <strong>monsters = [...]</strong>: Generating a list of monster instances.
                    <ul>
                        <li>This line uses a list comprehension to create a list named <code>monsters</code>, which contains multiple instances of the Monster class.</li>
                        <li>Each Monster is given a random position within the game window, using <code>random.randint()</code> to generate their x and y coordinates, adding unpredictability and challenge to the game.</li>
                        <li>The iteration <code>for _ in range(10)</code> ensures the creation of ten Monster instances, providing a substantial challenge to the player.</li>
                        <li>All monsters share the same image, defined by the <code>bad_friend_image</code>, maintaining visual consistency among the enemies.</li>
                    </ul>
                    Introducing multiple monsters into the game heightens the excitement and challenge, making the gameplay more dynamic and engaging. It's like setting up intriguing obstacles in an adventure course. 🌌🏰
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Enemy Creation with Randomization</strong>: This step highlights the effective use of list comprehensions and randomization for creating diverse and unpredictable enemy characters in the game.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The presence of enemies in video games is not just about creating barriers for the player. They stimulate strategic thinking, quick reactions, and emotional responses, contributing significantly to the depth and enjoyment of the game experience. 🎮👁️‍🗨️
            </h3>
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 26: Setting the Foundations – Initializing Game Variables 🎲🕒
            </h2>

            <p>
                Before diving into the action of 'Enemy Escape', we need to establish some fundamental game variables. This step involves initializing the score, game clock, running state, and player speed. It's like setting the stage and rules for our digital adventure. 🏁🚦
            </p>

            <img src={twentySix} className='demo__image' alt='img_twentySix' />

            <p>
            Let's break down the importance of each of these variables: 📊🔧
            </p>

            <ol>
                <li>
                    <strong>score = 0:</strong> Starting the score.
                    <ul>
                        <li>This initializes the player's score to zero. The score will change as the player interacts with friends and monsters throughout the game.</li>
                        <li>Keeping track of the score is crucial for providing feedback and a sense of achievement to the player.</li>
                    </ul>
                </li>
                <li>
                    <strong>clock = pygame.time.Clock():</strong> Creating a game clock.
                    <ul>
                        <li>This sets up a clock to manage the game's frame rate, ensuring smooth and consistent gameplay.</li>
                        <li>The clock will be used to control how fast the game updates, balancing the game's speed and responsiveness.</li>
                    </ul>
                </li>
                <li>
                    <strong>running = True:</strong> Setting the game loop condition.
                    <ul>
                        <li>This boolean variable is used to control the main game loop. As long as <code>running</code> is True, the game will continue to run.</li>
                    </ul>
                </li>
                <li>
                    <strong>speed = 2:</strong> Establishing the player's speed.
                    <ul>
                        <li>This sets the speed at which the player character will move. The value of 2 strikes a balance between too slow and too fast, making the game challenging yet manageable.</li>
                    </ul>
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Game Variable Initialization</strong>: This step demonstrates the initialization of key variables that form the backbone of the game's mechanics and flow.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The setup of these foundational elements is a crucial part of game development. They define the gameplay experience, impacting everything from player interaction to the overall pacing of the game. 🌐🎮
            </h3>
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 27: Launching the Game Loop – Starting the Action 🚀🌌
            </h2>

            <p>
                Every game is driven by its core loop, a cycle that keeps the gameplay going. In 'Enemy Escape', we begin this essential process with a 'while' loop. This step marks the start of our main game loop, continuously running the game's logic and rendering. It's like setting the wheels of our digital adventure in motion. 🎡🔄
            </p>

            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />

            <p>
            Let's dive into the components of this game loop: 🕹️🎮
            </p>

            <ol>
                <li>
                    <strong>while running:</strong> Establishing the main game loop.
                    <ul>
                        <li>This line sets up a while loop that will continue as long as the <code>running</code> variable is True. It forms the backbone of the game, where all the action happens.</li>
                        <li>The loop will repeatedly execute its contents, updating game states, handling user inputs, and rendering graphics.</li>
                    </ul>
                </li>
                <li>
                    <strong>screen.fill((0, 0, 0)):</strong> Clearing the screen.
                    <ul>
                        <li>This line clears the screen at the beginning of each loop iteration by filling it with black (represented by <code>(0, 0, 0)</code> in RGB color values).</li>
                        <li>Clearing the screen is crucial for redrawing updated game states and avoiding visual artifacts from previous frames.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Starting the Game Loop</strong>: The implementation of the game loop is a fundamental concept in game development, enabling continuous and dynamic gameplay.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The game loop is what makes video games interactive and responsive. It continuously checks for player inputs, updates the game state, and renders the updated state to the screen, creating a seamless and engaging experience. 🎲🖥️
            </h3>
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 28: Handling Game Events – Responding to Player Actions 🕹️🚦
            </h2>

            <p>
                A vital part of any game is how it responds to player actions. In 'Enemy Escape', this step focuses on handling game events, particularly the action of closing the game. It's akin to giving players the controls to interact with our digital world. 🎮🛑
            </p>

            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />

            <p>
            Let's explore how these lines contribute to the game's interactivity: ⌨️🔍
            </p>

            <ol>
                <li>
                    <strong>for event in pygame.event.get():</strong> Capturing game events.
                    <ul>
                        <li>This line starts a loop that fetches the list of all events that have occurred. In Pygame, events are actions like key presses, mouse movements, or window actions.</li>
                        <li>It's important to regularly check for these events to make the game responsive to player inputs and actions.</li>
                    </ul>
                </li>
                <li>
                    <strong>if event.type == pygame.QUIT:</strong> Checking for the quit event.
                    <ul>
                        <li>This line checks if the event type is <code>QUIT</code>, which occurs when the player tries to close the game window.</li>
                        <li>Responding to the <code>QUIT</code> event is crucial for allowing players to exit the game smoothly and as intended.</li>
                    </ul>
                </li>
                <li>
                    <strong>running = False</strong>: Ending the game loop.
                    <ul>
                        <li>Setting <code>running</code> to False when the <code>QUIT</code> event is detected stops the main game loop. It's an essential part of gracefully concluding the game.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Event Handling</strong>: This step demonstrates how to listen for and respond to player actions, a key aspect of creating interactive and user-friendly games.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Effective event handling is critical in game design, as it allows players to feel in control of their actions within the game world. It enhances the gaming experience by making it more interactive and engaging. 🎮🌍
            </h3>
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 29: Capturing Player Inputs – Keyboard Event Handling 🎮⌨️
            </h2>

            <p>
                An integral part of interactive gaming is responding to player inputs. In 'Enemy Escape', this step is about capturing keyboard events. It involves checking which keys are pressed at any given moment, allowing us to control our hero's movements. It's like tuning into the player's commands in the game's symphony. 🎼🖱️
            </p>

            <img src={twentyNine} className='demo__image' alt='img_twentyNine' />

            <p>
            Let's understand the role of this keyboard input capture: 🔑🔍
            </p>

            <ol>
                <li>
                    <strong>keys = pygame.key.get_pressed():</strong> Getting the state of the keyboard.
                    <ul>
                        <li>This line fetches the current state of all keyboard keys. The <code>pygame.key.get_pressed()</code> function returns a sequence, with each key corresponding to a boolean value indicating whether it is pressed down at that moment.</li>
                        <li>By assigning this sequence to the variable <code>keys</code>, we can easily check if specific keys are pressed in subsequent parts of our game loop, enabling player control over the game's characters.</li>
                    </ul>
                    Capturing keyboard inputs is a fundamental aspect of game control. It directly translates player intentions into actions within the game world, making the experience interactive and responsive. 🚀🎲
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Keyboard Input Handling</strong>: This line of code illustrates the technique for detecting real-time keyboard inputs, a crucial component for creating interactive and engaging gameplay.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The way a game processes and responds to keyboard inputs can greatly affect its playability. Responsive and intuitive control systems are key to enjoyable gaming experiences, allowing players to feel more connected and immersed in the game. 🎮🌐
            </h3>
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 30: Setting Movement Defaults – Initializing Direction Variables 🚦🛤️
            </h2>

            <p>
                In 'Enemy Escape', controlling our hero's movement is key. This step involves initializing the direction variables for player movement. It sets the stage for how the player will navigate through the game world. Think of it as calibrating the controls of a vehicle before starting a journey. 🚗🔄
            </p>

            <img src={thirty} className='demo__image' alt='img_thirty' />

            <p>
            Let's explore the purpose of these variables: 🎮🔧
            </p>

            <ol>
                <li>
                    <strong>dx, dy = 0, 0:</strong> Initializing movement variables.
                    <ul>
                        <li>This line sets up two variables, <code>dx</code> (delta x) and <code>dy</code> (delta y), which will represent the change in the player's position along the x-axis and y-axis, respectively.</li>
                        <li>Initially setting them to zero indicates that, by default, the player is not moving. The values of <code>dx</code> and <code>dy</code> will change based on player inputs to trigger movement.</li>
                    </ul>
                    Initializing these movement variables is crucial for managing player movement. It provides a foundation for dynamic and responsive control over the player's character. 🎲👟
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Direction Variables Initialization</strong>: This step demonstrates the initialization of variables that are essential for handling player movement, a fundamental aspect of gameplay mechanics.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The way a game handles movement often defines the player's experience. Efficient movement control allows for more immersive gameplay, making the player feel more engaged and connected to the game world. 🕹️🌏
            </h3>
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 31: Responding to Player Controls – Implementing Movement Logic 🕹️⬆️⬇️⬅️➡️
            </h2>

            <p>
                A crucial aspect of gameplay in 'Enemy Escape' is how the game responds to player controls. This step involves programming the reaction to keyboard inputs for player movement. It's like connecting the controls of a spaceship to its engines, enabling navigation through the stars. 🚀🌌
            </p>

            <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />

            <p>
            Let's navigate through the logic of these movement controls: 🧭💻
            </p>

            <ol>
                <li>
                    <strong>if keys[pygame.K_UP]: dy = -speed</strong> and so on: Setting directional movement.
                    <ul>
                        <li>Each <code>if</code> statement checks whether a specific arrow key is pressed, using the <code>keys</code> array we obtained earlier.</li>
                        <li>For the up arrow key (<code>pygame.K_UP</code>), <code>dy</code> is set to <code>-speed</code>, indicating upward movement on the screen. Similarly, <code>pygame.K_DOWN</code> sets <code>dy</code> to <code>speed</code> for downward movement.</li>
                        <li>For left (<code>pygame.K_LEFT</code>) and right (<code>pygame.K_RIGHT</code>) arrow keys, <code>dx</code> is set to <code>-speed</code> and <code>speed</code> respectively, controlling horizontal movement.</li>
                    </ul>
                    These conditional checks translate keyboard inputs into movement directions for the player's character, enabling intuitive and responsive control within the game. 🎲👣
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Keyboard Input for Movement</strong>: This step demonstrates how to use keyboard inputs to control character movement, an essential element of interactive gameplay.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Effective response to player input is a cornerstone of engaging gameplay. It allows players to feel a direct connection with the game world and their character, enhancing the overall gaming experience. 🕹️🌍
            </h3>
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 32: Activating Player Movement – Applying the Move Function 🏃‍♂️💨
            </h2>

            <p>
                After setting up the movement controls, it's time to put them into action. In this step of 'Enemy Escape', we use the player's 'move' method to actualize the movement based on the player's inputs. It's like making the character dance to the rhythm of the player's keystrokes. 🕺🎹
            </p>

            <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />

            <p>
            Let's understand the execution of this movement: 🚚🔍
            </p>

            <ol>
                <li>
                    <strong>player.move(dx, dy):</strong> Implementing the movement.
                    <ul>
                        <li>This line calls the <code>move</code> method of the player object, passing in the variables <code>dx</code> and <code>dy</code> which we set based on keyboard inputs.</li>
                        <li>The <code>move</code> method then adjusts the player's position on the screen according to these values, creating actual movement.</li>
                        <li>This approach ensures that the player's character moves in response to the arrow keys, allowing for a responsive and interactive gaming experience.</li>
                    </ul>
                    The implementation of the move function is a crucial step in bringing the game to life. It bridges the gap between player input and on-screen action, making the game dynamic and engaging. 🎮🌐
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Applying Movement Logic</strong>: This line demonstrates the practical application of previously set variables to control character movement, a fundamental aspect of gameplay mechanics.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The fluidity and responsiveness of character movement are key factors in the player's enjoyment of a game. Smooth and intuitive movement controls can significantly enhance the player's sense of immersion and interaction with the game world. 🌍🎲
            </h3>
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 33: Mobilizing Allies – Moving Friends Towards the Player 🚶‍♂️🤝
            </h2>

            <p>
                In the interactive world of 'Enemy Escape', not only does the player move, but so do the allies. This step focuses on making the friendly characters move towards the player. It's like orchestrating a scene where the supporting characters gravitate towards the hero. 🧲👥
            </p>

            <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />

            <p>
            Let's examine the dynamics of this ally movement: 🔄👫
            </p>

            <ol>
                <li>
                    <strong>for friend in friends:</strong> Iterating through each friend.
                    <ul>
                        <li>This line initiates a loop that goes through each 'friend' instance in the <code>friends</code> list, addressing every ally character in the game.</li>
                    </ul>
                </li>
                <li>
                    <strong>friend.move_towards_player(player, speed / 2):</strong> Moving the friends.
                    <ul>
                        <li>Inside the loop, we call the <code>move_towards_player</code> method for each friend. This method is responsible for moving the friend towards the player's current position.</li>
                        <li>The speed at which the friends move is set to half of the player's speed (<code>speed / 2</code>). This relative speed ensures that the allies follow the player but don't match their pace exactly, adding to the game's realism and challenge.</li>
                    </ul>
                    By moving the friends towards the player, we create a dynamic game environment where the player must strategically navigate through a space filled with moving allies. 🌟👨‍👩‍👦
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Ally Movement Logic</strong>: This step illustrates how to program non-player characters to interact with the player, a key aspect of creating an engaging and interactive game world.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The behavior of NPCs like these friendly characters can greatly impact the gameplay experience. Their movement patterns can create opportunities for strategic play, making the game more immersive and challenging. 🕹️👾
            </h3>
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 34: Friend Interaction – Handling Collisions and Scoring 🤝💥
            </h2>

            <p>
                An exciting aspect of 'Enemy Escape' is the interaction between the player and allies. In this step, we handle what happens when the player collides with a friend. It’s like scripting a moment in a play where the protagonist meets a side character, leading to a pivotal scene. 🎭🌟
            </p>

            <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />

            <p>
            Let's delve into the mechanics of this collision detection and scoring: 🏆🚀
            </p>

            <ol>
                <li>
                    <strong>if player.rect.colliderect(friend.rect):</strong> Detecting a collision.
                    <ul>
                        <li>This line checks if the player's rectangle (<code>player.rect</code>) intersects with a friend's rectangle (<code>friend.rect</code>). This collision detection is crucial for determining when the player successfully interacts with a friend.</li>
                    </ul>
                </li>
                <li>
                    <strong>friends.remove(friend):</strong> Removing the collided friend.
                    <ul>
                        <li>Once a collision is detected, this line removes the corresponding friend from the <code>friends</code> list. It represents the friend character leaving the game scene after interacting with the player.</li>
                    </ul>
                </li>
                <li>
                    <strong>score += 1:</strong> Increasing the score.
                    <ul>
                        <li>After the friend is removed, the player's score is incremented by 1. This increment rewards the player for successfully meeting a friend, adding a goal and incentive to the gameplay.</li>
                    </ul>
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Collision Detection and Scoring Mechanics</strong>: This step demonstrates the implementation of collision detection and its impact on game scoring, a key element in many interactive games.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Collision detection not only adds a layer of challenge to the game but also provides a way to create meaningful interactions within the game world. It can signify everything from gaining points to character development. 🌍🎲
            </h3>
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 35: Reinforcing Allies – Adding New Friends Post-Collision 🔄🆕
            </h2>

            <p>
                In 'Enemy Escape', when a player meets an ally, it's not just an exit for the friend character but also an opportunity to introduce new dynamics. This step involves adding a new friend character to the game each time the player collides with an existing one. It's like continuously bringing new characters onto the stage to keep the story evolving. 🎭🔄
            </p>

            <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />

            <p>
            Let's explore the addition of new friends into the game: 🌟➕
            </p>


            <ol>
                <li>
                    <strong>friends.append(...):</strong> Introducing a new ally character.
                    <ul>
                        <li>This line adds a new instance of the Friend class to the <code>friends</code> list, replenishing the number of allies in the game after a collision.</li>
                        <li>The position of the new friend is determined randomly within the game window, using <code>random.randint()</code> for both x and y coordinates. This random placement ensures ongoing variability and freshness in gameplay.</li>
                        <li>The new friend is created with the same <code>friend_image</code>, maintaining visual consistency among the ally characters.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Dynamic Addition of Game Characters</strong>: This step demonstrates how to maintain a consistent number of NPCs in the game, dynamically adding new characters to keep the gameplay engaging and unpredictable.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The continuous introduction of new characters or elements in a game can enhance its replay value. It keeps the game environment dynamic, challenging, and interesting for players, encouraging them to stay engaged over longer periods. 🕹️🌍
            </h3>
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 36: Displaying the Allies – Drawing Friends on the Screen 🎨👫
            </h2>

            <p>
                In 'Enemy Escape', having allies move around the player is just one part of the equation. The other crucial aspect is visually representing them on the screen. This step focuses on drawing each friend character within the game window. It's akin to ensuring each supporting actor appears on stage during a performance. 🖼️🎭
            </p>

            <img src={thirtySix} className='demo__image' alt='img_thirtySix' />

            <p>
            Let's look into how the allies are brought to visual life: 🌟👁️
            </p>

            <ol>
                <li>
                    <strong>for friend in friends:</strong> Iterating through ally characters.
                    <ul>
                        <li>This line starts a loop that goes through each 'friend' instance in the <code>friends</code> list, addressing every ally character present in the game.</li>
                    </ul>
                </li>
                <li>
                    <strong>friend.draw(screen):</strong> Rendering the friends on screen.
                    <ul>
                        <li>Within the loop, the <code>draw</code> method of each friend is called. This method takes the game window (<code>screen</code>) as an argument and renders the friend's image at their current position.</li>
                        <li>This step is essential for making the friends visible to the player, allowing for visual interaction and enhancing the game's immersive quality.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Visual Representation of Characters</strong>: This step emphasizes the importance of rendering game characters on the screen, a key element in creating an engaging and visually rich game environment.
                </li>
            </ul>

            <h3>
            🎈 Did You Know?: The visual depiction of characters in a game greatly influences the player's experience. Seeing the allies move and appear dynamically on-screen adds depth to the gameplay, making the game world feel more alive and interactive. 🕹️🌌
            </h3>
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 37: Directing the Adversaries – Moving Monsters Towards the Player 🐉🎮
            </h2>

            <p>
                The essence of challenge in 'Enemy Escape' lies in the movement of the monsters. In this step, we focus on programming the monsters to pursue the player. This adds a layer of tension and strategy to the game, akin to moving chess pieces in a tactical board game. ♟️🔍
            </p>

            <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />

            <p>
            Let's delve into the mechanics of this adversarial pursuit: 🏃‍♂️💨
            </p>

            <ol>
                <li>
                    <strong>for monster in monsters:</strong> Iterating through each monster.
                    <ul>
                        <li>This line initiates a loop that goes through each instance in the <code>monsters</code> list, addressing every monster in the game.</li>
                    </ul>
                </li>
                <li>
                    <strong>monster.move_towards_player(player, speed / 7):</strong> Commanding the monsters to chase the player.
                    <ul>
                        <li>Inside the loop, the <code>move_towards_player</code> method is called for each monster. This method is designed to guide the monsters towards the player’s current position.</li>
                        <li>The speed at which the monsters move is set to a fraction (one-seventh) of the player's speed (<code>speed / 7</code>). This slower pace ensures that the monsters are a threat, yet give the player a fair chance to evade them, balancing the gameplay.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Enemy Movement Logic</strong>: This step illustrates the implementation of movement behavior for the enemy characters, adding a crucial element of challenge and interaction to the game.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The movement patterns of enemies in video games play a critical role in defining the game's difficulty. By carefully calibrating the speed and behavior of the monsters, developers can create a challenging yet enjoyable experience for players. 🎮👾
            </h3>
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 38: Consequences of Danger – Handling Player-Monster Collisions 🚨⚔️
            </h2>

            <p>
                In 'Enemy Escape', encountering monsters has its repercussions. This step deals with the interaction between the player and monsters, particularly what happens when they collide. It’s like scripting a critical moment in a story where the hero faces a setback. 🎭👾
            </p>

            <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
            <p>
            Let's analyze the impact of these monster encounters: 🛡️🔍
            </p>

            <ol>
                <li>
                    <strong>if player.rect.colliderect(monster.rect):</strong> Detecting a collision with a monster.
                    <ul>
                        <li>This line checks for a collision between the player and a monster. The method <code>colliderect</code> is used to determine if the rectangles of the player and monster overlap, indicating a collision.</li>
                    </ul>
                </li>
                <li>
                    <strong>monsters.remove(monster):</strong> Removing the collided monster.
                    <ul>
                        <li>Upon collision, this line removes the respective monster from the <code>monsters</code> list. It represents the monster leaving the game scene after the interaction.</li>
                    </ul>
                </li>
                <li>
                    <strong>score -= 5:</strong> Penalizing the score.
                    <ul>
                        <li>This line decrements the player's score by 5 points as a consequence of colliding with a monster. It adds a challenge to the game, encouraging players to avoid monsters.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Collision Detection and Scoring Impact</strong>: This step illustrates how collision detection can influence the game's scoring, adding a layer of challenge and strategy to the gameplay.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Handling collisions and their consequences in games is crucial for creating a balanced and engaging experience. It adds a sense of risk and urgency to the gameplay, making players more invested in the actions and decisions they make. 🕹️💥
            </h3>
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 39: Replenishing Allies – Adding New Friends Post-Monster Collision 🔄🆕
            </h2>

            <p>
                In 'Enemy Escape', when a player encounters a monster, it leads to more than just a score penalty. This step involves introducing a new friend character into the game following a collision with a monster. It's like adding a twist in the plot, where every challenge also brings a new opportunity. 🎭🌟
            </p>

            <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />

            <p>
            Let's explore the rationale behind adding new friends at this juncture: 🌱🔄
            </p>

            <ol>
                <li>
                    <strong>Adding a new Friend instance:</strong> Introducing fresh dynamics.
                    <ul>
                        <li>In response to the player colliding with a monster, this snippet of code dynamically adds a new Friend instance to the game.</li>
                        <li>The new friend character is positioned randomly within the game window, using <code>random.randint()</code> to generate both x and y coordinates. This randomness ensures ongoing variability and adds a new element to the game after each collision.</li>
                        <li>Creating a new friend with the <code>friend_image</code> maintains the visual consistency of the ally characters.</li>
                    </ul>
                    Introducing new friend characters following a collision with a monster serves as a game balance mechanism. It ensures that despite the challenge posed by monsters, the player still has opportunities to interact with allies and recover points. 🔄👥
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Dynamic Game Environment</strong>: This step demonstrates the concept of dynamically adding game elements in response to player actions, enhancing the game's interactivity and balance.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: In game design, balancing challenging elements with rewarding opportunities is crucial for player engagement. This approach keeps the gameplay interesting and prevents it from becoming overly frustrating or monotonous. 🎮🎲
            </h3>
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 40: Portraying the Adversaries – Drawing Monsters on Screen 🎨🐉
            </h2>

            <p>
                Just as it's crucial to see our allies, visualizing our adversaries in 'Enemy Escape' is equally important. This step focuses on rendering the monster characters on the game screen. It’s about bringing the challenges to visual life, making the threats in our digital world tangible to the player. 🖼️👾
            </p>

            <img src={forty} className='demo__image' alt='img_forty' />

            <p>
            Let's understand the importance of displaying these menacing foes: 👁️🔍
            </p>

            <ol>
                <li>
                    <strong>for monster in monsters:</strong> Iterating through each monster.
                    <ul>
                        <li>This line starts a loop that goes through each 'monster' instance in the <code>monsters</code> list, addressing every enemy character in the game.</li>
                    </ul>
                </li>
                <li>
                    <strong>monster.draw(screen):</strong> Rendering the monsters.
                    <ul>
                        <li>Within this loop, the <code>draw</code> method of each monster is called. This method takes the game window (<code>screen</code>) as an argument and renders the monster's image at its current position.</li>
                        <li>Displaying the monsters is crucial for the player to see and react to these challenges, adding to the game's intensity and engagement.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Visualizing Enemy Characters</strong>: This step emphasizes the importance of rendering enemy characters on the screen, a critical element for creating a challenging and visually engaging game environment.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The visual representation of enemies plays a significant role in a player's gaming experience. It not only contributes to the game's aesthetics but also to the player's ability to strategize and respond to in-game challenges. 🎮👀
            </h3>
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 41: Handling Defeat – Setting a Losing Condition 🚩👾
            </h2>

            <p>
                In the exciting journey of 'Enemy Escape', not every turn leads to victory. This step introduces a losing condition based on the player's score. It defines the scenario where the game ends in defeat, adding stakes and suspense to the gameplay. It's like setting a challenge in a story that the hero must overcome, or face the consequences. ⚔️😟
            </p>

            <img src={fortyOne} className='demo__image' alt='img_fortyOne' />

            <p>
            Let's explore the dynamics of this losing condition: 📉❌
            </p>

            <ol>
                <li>
                    <strong>if score &lt; -50:</strong> Checking the losing condition.
                    <ul>
                        <li>This line checks if the player's score falls below -50. A score this low signifies that the player has had too many negative encounters with monsters.</li>
                    </ul>
                </li>
                <li>
                    <strong>print("The enemies won..."):</strong> Displaying the defeat message.
                    <ul>
                        <li>When the losing condition is met, this line prints a message to the console, indicating that the enemies have won, providing feedback to the player about the game's outcome.</li>
                    </ul>
                </li>
                <li>
                    <strong>running = False:</strong> Stopping the game loop.
                    <ul>
                        <li>Setting <code>running</code> to False stops the main game loop, effectively ending the game. This is essential for concluding the game when the losing condition is met.</li>
                    </ul>
                </li>
                <li>
                    <strong>continue:</strong> Skipping to the next iteration.
                    <ul>
                        <li>This line ensures that the remaining code in the current loop iteration is skipped, moving directly to the next iteration for the final wrap-up of the game.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Losing Condition Logic</strong>: This step shows how to implement a losing condition in a game, an important aspect of creating engaging and challenging gameplay experiences.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Implementing a losing condition in games is crucial for adding tension and challenge. It encourages players to strategize and improve their skills, making the gameplay more rewarding and the victories more satisfying. 🏆💪
            </h3>
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 42: Achieving Victory – Setting a Winning Condition 🏆🎉
            </h2>

            <p>
                While facing challenges is essential, the thrill of victory is equally important in 'Enemy Escape'. This step defines a winning condition based on the player's score. It sets a goal that, when reached, signifies the player's triumph over the game's challenges. It's like writing the climax of a story where the hero emerges victorious. 🚀✨
            </p>

            
            <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />

            <p>
            Let's examine the elements of achieving victory in the game: 🏁🌟
            </p>

            <ol>
                <li>
                    <strong>if score &gt; 50:</strong> Checking the winning condition.
                    <ul>
                        <li>This line checks if the player's score exceeds 50. Reaching this score indicates that the player has successfully navigated the game, making positive interactions and avoiding too many negative ones.</li>
                    </ul>
                </li>
                <li>
                    <strong>print("You defeated the enemies!"):</strong> Announcing the victory.
                    <ul>
                        <li>Once the winning condition is met, this line prints a celebratory message, conveying to the player that they have successfully defeated the enemies, adding a sense of accomplishment and closure.</li>
                    </ul>
                </li>
                <li>
                    <strong>running = False:</strong> Ending the game loop.
                    <ul>
                        <li>Setting <code>running</code> to False stops the main game loop, signifying the end of the game. This step is crucial for concluding the gameplay after a victory.</li>
                    </ul>
                </li>
                <li>
                    <strong>continue:</strong> Proceeding to the next iteration.
                    <ul>
                        <li>This command moves the loop to its next iteration, ensuring that the remaining code in the current iteration is skipped, allowing for any final wrap-up actions before the game completely ends.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Winning Condition Logic</strong>: This step demonstrates how to implement a winning condition in a game, a key component in providing goals and motivation for players.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Setting clear objectives and victory conditions in games not only offers a sense of purpose but also enhances player satisfaction. Achieving these goals often leads to a rewarding experience, encouraging players to engage more deeply with the game. 🕹️🏅
            </h3>
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 43: Spotlight on the Hero – Rendering the Player on Screen 🌟👾
            </h2>

            <p>
                In 'Enemy Escape', the central character of our story is, without a doubt, the player. This step is about visually portraying the player character on the game screen. It's like shining the spotlight on the main actor in a play, ensuring they are visible and central to the audience. 🎭🔦
            </p>

            <img src={fortyThree} className='demo__image' alt='img_fortyThree' />

            <p>
            Let's explore the significance of this visual representation: 🎨🖥️
            </p>

            <ol>
                <li>
                    <strong>player.draw(screen):</strong> Displaying the player character.
                    <ul>
                        <li>This line calls the <code>draw</code> method of the player object, rendering the player's image on the game window (<code>screen</code>).</li>
                        <li>The visual representation of the player is crucial for gameplay, as it allows the player to see their character's position and orientation within the game world.</li>
                        <li>Ensuring the player character is always visible and clear on the screen is key to maintaining player engagement and orientation.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Character Rendering</strong>: This step highlights the importance of rendering the main character in a game, a fundamental aspect of creating an immersive and player-centric gaming experience.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The way a player character is portrayed in a game heavily influences the player's connection to the game world. A well-rendered and visually appealing character can significantly enhance the player's immersion and enjoyment. 🕹️👁️
            </h3>
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 44: Displaying the Score – Showcasing Player Progress 📈🏆
            </h2>

            <p>
                Keeping track of progress is vital in 'Enemy Escape'. This step is about displaying the player's score on the screen, providing real-time feedback on their performance. It's like having a scoreboard in a sports game, allowing players to gauge how well they're doing. 🎰🌟
            </p>

            <img src={fortyFour} className='demo__image' alt='img_fortyFour' />

            <p>
            Let's delve into the display of this crucial game metric: 🖥️🔢
            </p>

            <ol>
                <li>
                    <strong>score_text = font.render(f"Score: &#10100;score&#10101;", True, (255, 255, 255)):</strong> Creating the score display.
                    <ul>
                        <li>This line uses the <code>font.render</code> method to create an image of the text displaying the current score. The score is dynamically updated within the string.</li>
                        <li>The text is rendered in white color (<code>(255, 255, 255)</code>) for clear visibility against the game's background.</li>
                    </ul>
                </li>
                <li>
                    <strong>screen.blit(score_text, (10, 10)):</strong> Placing the score on the screen.
                    <ul>
                        <li>This line draws the score text onto the game window (<code>screen</code>) at the specified coordinates (10, 10), usually towards the top-left corner for easy viewing.</li>
                        <li>Displaying the score prominently helps players keep track of their achievements and challenges as they progress through the game.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Score Display Logic</strong>: This step demonstrates how to visually represent key game information, like the player's score, enhancing the user interface and gaming experience.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Providing visual feedback such as scores and progress indicators is crucial in gaming. It not only informs players about their current standing but also adds a motivational element, encouraging them to improve their performance. 🕹️📊
            </h3>
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 45: Refreshing the Game View – Updating the Display and Timing 🔄⏱️
            </h2>

            <p>
                In 'Enemy Escape', keeping the game's visuals and timing in sync is crucial. This final step of the main game loop updates the display and controls the game's frame rate. It's like the director calling 'cut' and 'action' in each scene of a movie, ensuring everything flows seamlessly. 🎬🕒
            </p>

            <img src={fortyFive} className='demo__image' alt='img_fortyFive' />

            <p>
            Let's understand the role of these two critical functions: 🖥️⌛
            </p>

            <ol>
                <li>
                    <strong>pygame.display.flip():</strong> Updating the game screen.
                    <ul>
                        <li>This line updates the entire display surface to the screen. It's akin to flipping the page in an animation book, showing the next frame of the game.</li>
                        <li>Using <code>display.flip()</code> ensures that all the changes made in the game loop (like moving characters, displaying scores) are rendered visibly on the screen.</li>
                    </ul>
                </li>
                <li>
                    <strong>clock.tick(60):</strong> Controlling the frame rate.
                    <ul>
                        <li>This line limits the game to 60 frames per second (fps). It's a way to control the speed at which the game updates, ensuring smooth and consistent gameplay.</li>
                        <li>Maintaining a steady frame rate is essential for keeping the game's timing and player experience uniform across different devices.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Display and Timing Management</strong>: This step highlights the importance of updating the display and managing the frame rate in a game, key aspects of maintaining a smooth and enjoyable gaming experience.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Consistent frame rates and timely screen updates are vital for creating an immersive gaming experience. They ensure that the game not only looks good but also feels responsive and interactive. 🌐🎮
            </h3>
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 46: Closing the Curtain – Exiting the Game 🚪🔚
            </h2>

            <p>
                Every game needs a proper conclusion, and 'Enemy Escape' is no exception. This final step in our tutorial is about gracefully exiting the game. It's akin to closing the curtain after a great performance, ensuring a smooth and respectful end to the experience. 🎭👋
            </p>

            <img src={fortySix} className='demo__image' alt='img_fortySix' />

            <p>
            Let's look at the significance of this game-ending command: 🛑💻
            </p>

            <ol>
                <li>
                    <strong>pygame.quit():</strong> Terminating Pygame.
                    <ul>
                        <li>This line is used to cleanly exit Pygame. It deactivates the Pygame library, freeing up resources and closing the game window.</li>
                        <li>It's important to include <code>pygame.quit()</code> at the end of your game script to ensure that the game stops running properly without leaving any processes hanging.</li>
                    </ul>
                    Implementing <code>pygame.quit()</code> is a best practice in game development. It ensures that the game concludes its operations respectfully, maintaining system integrity and providing a good user experience. 🖥️✨
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Proper Game Termination</strong>: This step emphasizes the importance of correctly exiting a game using Pygame, a crucial aspect of game development for resource management and user experience.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Just like starting a game smoothly is important, ending it correctly is equally essential. Proper termination of a game prevents potential issues like memory leaks and ensures a pleasant user experience, leaving a lasting positive impression. 🕹️👍
            </h3>
        </div>


        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>

    </div>
  )
}

export default Enemy