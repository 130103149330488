import React, {useState} from 'react';
import one from './fullstackImages/buildAShip/1.png';
import two from './fullstackImages/buildAShip/2.png';
import three from './fullstackImages/buildAShip/3.png';
import four from './fullstackImages/buildAShip/4.png';
import five from './fullstackImages/buildAShip/5.png';
import six from './fullstackImages/buildAShip/6.png';
import seven from './fullstackImages/buildAShip/7.png';
import eight from './fullstackImages/buildAShip/8.png';
import nine from './fullstackImages/buildAShip/9.png';
import ten from './fullstackImages/buildAShip/10.png';
import eleven from './fullstackImages/buildAShip/11.png';
import twelve from './fullstackImages/buildAShip/12.png';
import thirteen from './fullstackImages/buildAShip/13.png';
import fourteen from './fullstackImages/buildAShip/14.png';
import fifteen from './fullstackImages/buildAShip/15.png';
import sixteen from './fullstackImages/buildAShip/16.png';
import seventeen from './fullstackImages/buildAShip/17.png';
import eighteen from './fullstackImages/buildAShip/18.png';
import nineteen from './fullstackImages/buildAShip/19.png';
import twenty from './fullstackImages/buildAShip/20.png';
import twentyOne from './fullstackImages/buildAShip/21.png';
import twentyTwo from './fullstackImages/buildAShip/22.png';
import twentyThree from './fullstackImages/buildAShip/23.png';
import twentyFour from './fullstackImages/buildAShip/24.png';
import twentyFive from './fullstackImages/buildAShip/25.png';
import twentySix from './fullstackImages/buildAShip/26.png';
import twentySeven from './fullstackImages/buildAShip/27.png';
import twentyEight from './fullstackImages/buildAShip/28.png';
import twentyNine from './fullstackImages/buildAShip/29.png';
import thirty from './fullstackImages/buildAShip/30.png';
import thirtyOne from './fullstackImages/buildAShip/31.png';
import thirtyTwo from './fullstackImages/buildAShip/32.png';
import thirtyThree from './fullstackImages/buildAShip/33.png';
import thirtyFour from './fullstackImages/buildAShip/34.png';
import thirtyFive from './fullstackImages/buildAShip/35.png';
import thirtySix from './fullstackImages/buildAShip/36.png';
import thirtySeven from './fullstackImages/buildAShip/37.png';
import thirtyEight from './fullstackImages/buildAShip/38.png';
import thirtyNine from './fullstackImages/buildAShip/39.png';
import forty from './fullstackImages/buildAShip/40.png';
import fortyOne from './fullstackImages/buildAShip/41.png';
import fortyTwo from './fullstackImages/buildAShip/42.png';
import fortyThree from './fullstackImages/buildAShip/43.png';
import fortyFour from './fullstackImages/buildAShip/44.png';
import fortyFive from './fullstackImages/buildAShip/45.png';
import fortySix from './fullstackImages/buildAShip/46.png';
import fortySeven from './fullstackImages/buildAShip/47.png';
import fortyEight from './fullstackImages/buildAShip/48.png';
import fortyNine from './fullstackImages/buildAShip/49.png';
import fifty from './fullstackImages/buildAShip/50.png';
import fiftyOne from './fullstackImages/buildAShip/51.png';
import fiftyTwo from './fullstackImages/buildAShip/52.png';
import fiftyThree from './fullstackImages/buildAShip/53.png';
import fiftyFour from './fullstackImages/buildAShip/54.png';
import fiftyFive from './fullstackImages/buildAShip/55.png';
import fiftySix from './fullstackImages/buildAShip/56.png';
import fiftySeven from './fullstackImages/buildAShip/57.png';
import fiftyEight from './fullstackImages/buildAShip/58.png';
import fiftyNine from './fullstackImages/buildAShip/59.png';
import sixty from './fullstackImages/buildAShip/60.png';
import sixtyOne from './fullstackImages/buildAShip/61.png';
import sixtyTwo from './fullstackImages/buildAShip/62.png';
import sixtyThree from './fullstackImages/buildAShip/63.png';
import sixtyFour from './fullstackImages/buildAShip/64.png';
import sixtyFive from './fullstackImages/buildAShip/65.png';
import sixtySix from './fullstackImages/buildAShip/66.png';
import sixtySeven from './fullstackImages/buildAShip/67.png';
import CopyBox from './copyBox/copybox';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const BuildShip = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 67;

  return (
    <div className='development__container'>

        <h1>
        🚀 It's time to Build-A-Ship 🚀
        </h1>

        <h3 className='intro'>
        Ready for take off? It's time to journey among the stars in our your custom built rocket ship! Sure a fast car with racy colors is adventurous, but imagine the possibilities with your own custom space ship. That's exactly what we are going to build in this action-packed project; so buckle up and get ready for blast-off!
        </h3>

        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>


          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>


        </div>



        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Starting Your Space Adventure 🚀🌌</h2>

            <p>
            Welcome, young astronauts! Let's launch into our coding mission with a blast! Open up your code editor where we will create our space command center. 🧑‍🚀
            </p>
            <img src={one} className='demo__image' alt='img_one' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Inside your project folder, create a file and name it index.html. This will serve as the blueprint for our spacecraft dashboard!
                </li>

                <li>
                Open index.html in your favorite code editor. It might look like a blank space sky now, but soon it will be full of stars and ships!
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                File Organization: Keeping your space gear tidy is crucial! 🗂️ Just like astronauts organize their tools, we keep our code files neat.
                </li>

                <li>
                HTML Files: These files are like the structure of our spaceship. They will hold all the information we need to build our ship!
                </li>
            </ul>
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Adding the Space Garage 🛠️🚀</h2>

            <p>
            Great job, space explorers! Now, let's prepare the garage where we'll build our mighty rockets. This is where we keep all the visual elements, like the images of our ships!
            </p>
            <img src={two} className='demo__image' alt='img_two' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                In the BUILD-A-SHIP folder, create a new folder called images. This will be the garage for our rocket pictures.
                </li>

                <li>
                Can you see your index.html file? Make sure it's right next to your images folder, not inside it!
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Folders: Just like compartments in a spaceship, folders help us keep different types of files organized. 📁
                </li>
            </ul>

            <p>
            All systems go? Let's add some rockets to our garage in the next step! 🛸
            </p>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 3: Rocket Inventory Check 📋✅</h2>

            <p>
            Fantastic navigating, crew members! We have our garage ready, so let's fill it with our awesome rockets! 🚀
            </p>
            <img src={three} className='demo__image' alt='img_three' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Download some cool images and place them in the images folder. We will call them rocket1.png, rocket2.png, and rocket3.png. Don't forget a logo for our space mission, name it logo.png.
                </li>
            </ol>

            {/*<div className='download__buttons__container'>
                <Download src={logo} filename="logo.png" />

                <Download src={rocket1} filename="rocket1.png" />

                <Download src={rocket2} filename="rocket2.png" />

                <Download src={rocket3} filename="rocket3.png" />
            </div>*/}

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Image Files: These are the visuals of our project. They're like the stickers on our ship that make it ours! 🌈
                </li>

                <li>
                Naming Files: Just like astronauts need to know which button to press, we need to name our files correctly so our code knows what to show!
                </li>
            </ul>

            <p>
            That's it! You've got your rockets and logo ready for the big build. Let's start putting them together in our next exciting step! 🌟🔧"
            </p>
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: Crafting the Style Spacesuit 👩‍🚀🎨</h2>

            <p>
            Superb progress, space cadets! Just like a spacesuit protects astronauts, CSS styles protect our webpage from looking like a plain piece of rock. It's time to suit up our HTML with some stylish CSS!
            </p>
            <img src={four} className='demo__image' alt='img_four' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                In the BUILD-A-SHIP folder, create a new file called style.css. This is the tailor shop where we'll craft our spacesuit!
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                CSS (Cascading Style Sheets): This is what makes our webpage look out-of-this-world awesome! 🌟
                </li>
            </ul>
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Launch Sequence Initialization 🚀🔧</h2>

            <p>We're diving into the heart of our command center – the HTML document!</p>
            <img src={five} className='demo__image' alt='img_five' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                With the index.html file open, we'll begin by setting the scene with &lt;!DOCTYPE html&gt;. This tells the universe that we're using the latest HTML version.
                </li>

                <li>
                Next, enclose the content with &lt;html lang="en"&gt; at the start and &lt;/html&gt; at the end. This sets the language for our space travelers and wraps up our HTML document.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Doctype Declaration: It's like the launch code for our website, telling the browser, "Get ready for a journey with the latest HTML!"
                </li>

                <li>
                HTML Tags: These are the building blocks of our webpage. Without them, we're just floating in the vast void of space!
                </li>
            </ul>
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Initializing the Control Panel 🧠💻</h2>

            <p>
            Commanders-in-training, it’s time to communicate with our spaceship! Let’s set the language it understands.
            </p>
            <img src={six} className='demo__image' alt='img_six' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                In the index.html file, type in the &lt;head&gt; tag just below the opening &lt;html&gt; tag.
                </li>

                <li>
                Make sure to close it with a &lt;/head&gt; tag right after.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Head Element: This part of our HTML spaceship holds all the essential systems that control how the dashboard operates. Think of it like the unseen gears that make sure everything runs smoothly!
                </li>
            </ul>
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Powering Up the Control Panel 📡🔋</h2>

            <p>
            Now that we've got our control panel ready, it's time to power it up with some essential settings to make sure our spaceship can navigate through the cosmos successfully.
            </p>
            <img src={seven} className='demo__image' alt='img_seven' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Inside the &lt;head&gt; tag, set the character encoding for our webpage with &lt;meta charset="UTF-8"&gt;. This ensures our spaceship can understand all types of characters from around the universe.
                </li>

                <li>
                Help our site adjust to any screen in the cosmos with &lt;meta name="viewport" content="width=device-width, initial-scale=1.0"&gt;.
                </li>

                <li>
                Give a title to our mission control with the &lt;title&gt; tag. Let's call our webpage "Build-A-Ship".
                </li>

                <li>
                Link our CSS stylesheet, which is the design blueprint of our spaceship, with the tag &lt;link href="./style.css" rel="stylesheet" /&gt;.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Character Encoding: Just like astronauts need to understand alien languages, our webpage needs to understand all types of text and symbols.
                </li>

                <li>
                Viewport: This ensures that the dashboard (our webpage) is clearly visible on different screen sizes, from the smallest handheld devices to the largest monitors in mission control.
                </li>

                <li>
                Title Tag: It names our mission control tab in the browser, so our astronauts know which mission they are on.
                </li>

                <li>
                Linking CSS: This connects the design blueprint to our spaceship so that it looks sleek and ready for the stars!
                </li>
            </ul>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Opening the Airlock to Our Spacecraft's Body 🚪🌌</h2>

            <p>
            Alright, space cadets, it’s time to open the airlock and step into the body of our spacecraft! This is where our mission comes to life.
            </p>
            <img src={eight} className='demo__image' alt='img_eight' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Beneath the closing &lt;/head&gt; tag in your index.html, add the opening &lt;body&gt; tag.
                </li>

                <li>
                Right after, add the closing &lt;/body&gt; tag. This space between is where all the action will happen—think of it as the main cabin of our ship where we'll place all the control buttons, dials, and screens!
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Body Element: This is the primary container for all the visible parts of our HTML spaceship. It’s where all the content that our space explorers will interact with is stored!
                </li>
            </ul>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Installing the Command Bridge's Controls 🎮🖲️</h2>

            <p>
            Time to install the main control deck—our navigation bar! This is where we'll steer our ship through the stars.
            </p>
            <img src={nine} className='demo__image' alt='img_nine' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Inside the &lt;body&gt; of our index.html, create a division with the class of 'navbar' by typing &lt;div class="navbar"&gt;&lt;/div&gt;.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                div Element: Think of div like compartments or sections on our spaceship. They help us organize our control tools neatly.
                </li>

                <li>
                Class Attribute: This is like assigning a role to each compartment. By giving our div a class of 'navbar', we’re telling our spaceship, “This section is for navigation controls!”
                </li>
            </ul>
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Structuring the Navigation Bar 🧭🔧</h2>

            <p>
            Time to structure our spaceship's navigation bar! This is the control deck where we'll have all our main controls.
            </p>
            <img src={ten} className='demo__image' alt='img_ten' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Inside the navbar div, add two more div elements: one with the class nav-left and another with the class nav-right. These will serve as the two main sections of our navigation bar.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Divisions (div): In HTML, div tags are like compartments on our spaceship, helping us organize different sections neatly.
                </li>

                <li>
                Class Attribute: This is like assigning a job to each section. With the classes nav-left and nav-right, we’re making sure each part of our navigation bar has a specific role.
                </li>
            </ul>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: Adding Life to the Navigation Bar 🖼️👩‍🚀</h2>

            <p>
            Cadets, it's time to add life to our navigation bar – the heart of our spaceship's command center.
            </p>
            <img src={eleven} className='demo__image' alt='img_eleven' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Within the nav-left div, insert an &lt;img/&gt; tag and set the src attribute to point to our logo image. This is like placing our spaceship's badge for everyone to see!
                </li>

                <li>
                In the nav-right div, use a &lt;h3&gt; tag to create a section for 'Your Account'. This is where our astronauts will be able to access their space journey details.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Images in HTML: The &lt;img/&gt; tag is like a window on our spaceship. It allows us to display pictures of our journey, badges, and much more.
                </li>

                <li>
                Headings in HTML: The &lt;h3&gt; tag is used for subtitles on our dashboard, like labeling the controls or sections – important for easy navigation!
                </li>
            </ul>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Suiting Up Our HTML with Global Styles 🌐</h2>

            <p>
            Ready to suit up our HTML with some universal styles? These are like the standard-issue astronaut gear that keeps our site looking sharp and unified.
            </p>
            <img src={twelve} className='demo__image' alt='img_twelve' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Open your style.css file. We're about to set some global styles.
                </li>

                <li>
                Type in the * selector. This is the universal selector that applies to everything on our page.
                </li>

                <li>
                Set the margin and padding to 0. This clears away any default space around the elements, giving us a clean slate.
                </li>

                <li>
                Add box-sizing: border-box;. This makes our layout design easier to manage by including the padding and border in the element's total width and height.
                </li>

                <li>
                Finally, let's choose a font. Add font-family: sans-serif; to ensure our spaceship has a modern and readable control panel.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Universal Selector (*): This is like a regulation that applies to every astronaut, ensuring uniformity.
                </li>

                <li>
                Margin and Padding: These are like the personal space of elements on our page. We’re setting them to zero to make sure we start with a clean cabin.
                </li>

                <li>
                Box-sizing: This helps keep our spaceship dimensions in check, so everything fits nicely.
                </li>

                <li>
                Font-family: This ensures all the labels and readouts on our dashboard are clear and legible.
                </li>
            </ul>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: Flexing Our Navigation Bar 💪🧭</h2>

            <p>
            Let's give our navigation bar the flexibility to adapt to any screen size and display our menu items neatly.
            </p>
            <img src={thirteen} className='demo__image' alt='img_thirteen' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Below the universal styles, let's target our .navbar class.
                </li>

                <li>
                Set display: flex; to activate flexbox, which is like choosing an advanced autopilot for our layout. It gives us the power to align items horizontally with ease.
                </li>

                <li>
                Add flex-direction: row; to line up our controls side-by-side, just like the control buttons on a spaceship dashboard.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Flexbox: This layout mode is perfect for designing complex applications and webpages. It allows us to build a flexible and responsive layout with less effort.
                </li>

                <li>
                Display Property: This determines how elements are laid out on the screen. By setting it to flex, we're preparing our controls to work seamlessly across different screen sizes and dimensions.
                </li>
            </ul>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: Aligning the Stars in Our Navbar 🌟🧭</h2>

            <p>
            Now that we've got our navigation bar floating in space, let's align the stars—meaning, let's make sure everything in our navbar is perfectly aligned for a smooth space journey.
            </p>
            <img src={fourteen} className='demo__image' alt='img_fourteen' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                With our style.css open, we're going to add some stellar flex properties to our .navbar class.
                </li>

                <li>
                Add align-items: center; to make sure all items in our navbar are aligned in the center vertically. It's like ensuring all our controls are at the perfect height for our astronaut's comfort.
                </li>

                <li>
                Set justify-content: space-between; to give our logo and account information equal breathing room on either end of the navbar.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                align-items: This property is like the gravity inside our spaceship. It keeps everything from floating around and aligns them perfectly.
                </li>

                <li>
                justify-content: Think of this like the spacing between controls on our dashboard. It spreads items out evenly, making sure there’s no clutter on our control panel.
                </li>
            </ul>
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15: Expanding the Navbar to Full Width 📏✨</h2>

            <p>
            We're on to setting the dimensions of our navigation bar.
            </p>
            <img src={fifteen} className='demo__image' alt='img_fifteen' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Still in our style.css, it's time to expand our .navbar to span the full horizon of our screen.
                </li>

                <li>
                Add width: 100%; to make our navbar stretch across the full width of the screen.
                </li>

                <li>
                Define height: 78px; to give it a consistent height no matter the screen size.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Width and Height: In the vastness of space, it's important to have dimensions we can rely on. Setting width and height ensures our navigation bar is always just the right size to house all our essential controls.
                </li>
            </ul>
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 16: Adding a Splash of Cosmic Color 🎨🚀</h2>

            <p>
            Ready to add a splash of cosmic color to our navigation bar?
            </p>
            <img src={sixteen} className='demo__image' alt='img_sixteen' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                In your style.css, target the .navbar class which represents our navigation bar.
                </li>

                <li>
                    Add background-color: rgb(45, 45, 194); to it. This deep, rich blue will give our navigation bar a look that's out of this world!
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Background-Color: This property in CSS is like the paint on our spaceship. It changes the color of the background of an element, which in this case, is our navigation bar.
                </li>
            </ul>
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 17: Comfort and Padding in the Control Center 💺🌠</h2>

            <p>
            As space engineers, we know comfort is key during those long voyages through the web universe. Let's add some padding to our navigation bar for that extra coziness.
            </p>
            <img src={seventeen} className='demo__image' alt='img_seventeen' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Still focusing on the .navbar class in style.css, add padding-left: 26px; and padding-right: 26px;. This will give some space inside our navigation bar, so our logo and account information don't feel cramped.
                </li>

                <li>
                The padding ensures everything is aligned and has room to breathe, just like our astronauts need space in their cabins to move around comfortably.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Padding: This is like the cushioning inside our spaceship. It adds space inside an element, around the content, for a cleaner, more spacious design.
                </li>
            </ul>
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 18: Organizing the Navigation Deck 📐🛠️</h2>

            <p>
            Mission update: It's time to organize the left side of the navbar.
            </p>
            <img src={eighteen} className='demo__image' alt='img_eighteen' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Open the style.css file and target the .nav-left class within the .navbar. This class refers to the left section of our navigation bar.
                </li>

                <li>
                Apply display: flex; to introduce flexibility within this container.
                </li>

                <li>
                Set flex-direction: column; to stack the logo and any other content vertically.
                </li>

                <li>
                Center everything with align-items: center; and justify-content: center;.
                </li>

                <li>
                Assign width: 20%; to define how much space the left part of the navbar should take up.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Flexbox Container: With display: flex;, we're turning .nav-left into a flex container, allowing us to position child elements neatly.
                </li>

                <li>
                Flex Direction: By setting it to column, we're arranging our elements like stacked cards, one on top of the other.
                </li>

                <li>
                Alignment and Justification: These properties ensure that the items are centered, providing a balanced look.
                </li>
            </ul>
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 19: Fitting the Logo to the Control Deck 🌌🖼️</h2>

            <p>
            Time to fit our mission badge—our logo—into the control deck so it's visible across all galaxies.
            </p>
            <img src={nineteen} className='demo__image' alt='img_nineteen' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Still in style.css, target the img element inside the .nav-left class.
                </li>

                <li>
                Apply object-fit: contain; to ensure the logo scales properly within its container without distorting.
                </li>

                <li>
                Set width: 100%; to ensure the logo fills the width of its container for maximum visibility.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Object-fit: This CSS property is like the adjustable frame for our spaceship’s logo. It helps size and scale the image properly without losing aspect ratio.
                </li>

                <li>
                Width: Setting this to 100% ensures the image extends the full width of its parent container, making the logo prominent and clear.
                </li>
            </ul>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 20: Styling the Command Center's Sign-In 🖌️</h2>

            <p>
            This step will take us into the deep space of CSS customization.
            </p>
            <img src={twenty} className='demo__image' alt='img_twenty' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                In your style.css, target the .nav-right h3 selector. This is where we're styling the text in the 'Your Account' section of our navigation bar.
                </li>

                <li>
                Apply color: white; to make the text stand out against the deep space blue of our navbar—like stars shining against the night sky.
                </li>

                <li>
                Set cursor: pointer; to change the mouse icon when hovering over 'Your Account', signaling to our astronauts that it's a clickable link to their mission details.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Color: In CSS, the color property changes the text color, enhancing readability and style.
                </li>

                <li>
                Cursor: This property changes the appearance of the cursor, providing interactive feedback to the user—essential for navigating through the control panel.
                </li>
            </ul>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 21: Preparing for the Main Area 🛠️🎉</h2>

            <p>
            This is the main content area where all the mission activities will take place.
            </p>
            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Back in the index.html, right after the closing &lt;/div&gt; of the .navbar, create a new div with the class main. This will be the primary content area of our website.
                </li>

                <li>
                For now, this div will act as a placeholder for all the dynamic content we'll add later—like the control panels, status monitors, and the launch button.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Main Content Area: This is like the main deck of our spaceship, where all the action happens—from planning missions to monitoring space flights.
                </li>
            </ul>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 22: Dividing the Command Center 🌌🔲</h2>

            <p>
            Get ready, space builders, it’s time to divide our command center into two main areas for our spaceship controls and customization features!
            </p>
            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Inside the main class in our index.html, create two div elements: one with the class main-left and another with main-right. These are going to be our primary control sectors within the command center.
                </li>

                <li>
                The main-left will house the options for our spacecraft—think of it as the hangar where we keep all our different ship models.
                </li>

                <li>
                The main-right will serve as our customization workshop where we’ll later add tools to tweak and tune our chosen ship.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Dividing Content: Like separating the bridge from the engine room, we're organizing our web page into different sections for specific purposes.
                </li>

                <li>
                Classes for Styling: Each div has a class that will help us apply specific styles and keep our layout clean and organized.
                </li>
            </ul>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 23: Docking the Ships in the Hangar 🚀🔍</h2>

            <p>
            It's time to dock our first spaceship in the hangar! We're going to add an image of our ship that we can later select for our space adventures.
            </p>
            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Within the main-left division of our index.html, create a div and assign it the class ship-card. This will represent an individual ship in our hangar.
                </li>

                <li>
                Inside the ship-card div, insert an img tag and set the source to ./images/rocket1.png. This brings our spaceship to visual life on the webpage. 
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Classes for Components: By creating a ship-card class, we establish a blueprint that we can reuse for each ship we add to our hangar, keeping the design consistent.
                </li>
            </ul>
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 24: Detailing Our Rocket Selection 🚀✨</h2>

            <p>
            Space cadets, it's time to add details to our rockets!             
            </p>
            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Within the ship-card div for our first rocket in index.html, we're going to add a div with the class info. This will be our information panel for the rocket.
                </li>

                <li>
                Inside the info div, insert a paragraph &lt;p&gt; with a class that describes the rocket's color, such as class="red" for the Red Rocket.
                </li>

                <li>
                Right below the paragraph, place a &lt;button&gt; that the astronauts can press to select this rocket for their mission. Just type &lt;button&gt;Select Ship&lt;/button&gt;.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Information Div (info): This section will contain all the details about the rocket, such as its name and the button to select it.
                </li>

                <li>
                Paragraph Tag (&lt;p&gt;): This is used to display the name of the rocket. We'll color-code the name to match the rocket!
                </li>
            </ul>
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 25: Cloning Our Fleet 🚀👥</h2>

            <p>
            Brace for cloning, astronauts! We're going to replicate our rocket structure to showcase all the available spacecraft.
            </p>
            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Repeat the structure of the ship-card with the info div for each rocket in the main-left div of our index.html.
                </li>

                <li>
                For each ship-card, change the image source to the respective rocket image—rocket1.png, rocket2.png, and rocket3.png.
                </li>

                <li>
                Customize the class of the paragraph within each info div to reflect the rocket's color—red, pink, orange.
                </li>

                <li>
                Ensure each ship-card has a 'Select Ship' button so our space explorers can pick their preferred rocket.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Structure Replication: By duplicating the structure of the ship-card, we create a uniform look for our fleet, making it easy for users to compare options.
                </li>

                <li>
                Customization: Changing the class names for each paragraph allows us to apply different styles to each rocket name, such as color-coding them according to the rocket's design.
                </li>
            </ul>
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 26: Structuring the Mission Control Room 🖥️🌟</h2>

            <p>
            We're going to structure the Mission Control room (main content), making sure there's enough space for all the mission-critical controls and information.
            </p>
            <img src={twentySix} className='demo__image' alt='img_twentySix' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                In the style.css, find the .main class. This is the main control area of our webpage—our digital Mission Control room.
                </li>

                <li>
                We're setting display: flex; to introduce some CSS called Flexbox, which will help us align all control elements neatly.
                </li>

                <li>
                By defining flex-direction: row; we tell our controls to line up side by side, just like the control panels in a real spaceship.
                </li>

                <li>
                We use align-items: flex-start; to align our elements at the start of the cross-axis, ensuring a uniform top line.
                </li>

                <li>
                With justify-content: space-between;, we make sure there's even space between the main control sections—no crowding!
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Align Items and Justify Content: These are like the rules of gravity for our layout, controlling the alignment and spacing of our elements.
                </li>
            </ul>
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 27: Expanding the Mission Control Room 📏🛰️</h2>

            <p>
            Commanders, it's time to expand the Mission Control (main div) room to its full operational size.
            </p>
            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                In the .main class in our style.css, we're going to add width: 100%; to use all the available horizontal space—making sure we're utilizing every inch of our dashboard.
                </li>

                <li>
                Set height: 90vh; to scale the control div to 90% of the viewport height, giving us a tall and commanding view of all the mission controls.
                </li>
            </ol>
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 28: Enhancing Mission Control's Comfort 🛋️🌟</h2>

            <p>
            Commanders, it's crucial that our main div is not only functional but also comfortable.
            </p>
            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                In the style.css, we're going to add some padding to the .main class. This padding ensures that our content isn't pressed right up against the walls of the control room.
                </li>

                <li>
                Add padding-top: 72px; and padding-bottom: 72px; to give some breathing room.
                </li>

                <li>
                Set padding-left: 45px; and padding-right: 45px;
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Padding: This creates space within the element, between the content and its border. It’s like the cushioning on a pilot’s seat, making it comfortable for the journey.
                </li>
            </ul>
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 29: Setting Up the Command Deck 🕹️🚀</h2>

            <p>
                In this step, we will start styling the "main-left" div.
            </p>
            <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Within the .main class in the style.css, target the .main-left class. This is the section of the control room where most of our interactive elements will be.
                </li>

                <li>
                We're going to give this section width: 70%; which allocates most of the room to our interactive elements, ensuring they’re easily accessible and clearly displayed.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Flex Properties: We use the same flex properties (flex-direction, align-items, justify-content) here to maintain a consistent layout with the rest of the control room.
                </li>
            </ul>
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 30: Designing the Ship Display 🚀</h2>

            <p>
                It's time to make our ships look as good on screen as they do in the stars.
            </p>
            <img src={thirty} className='demo__image' alt='img_thirty' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Open up the style.css file and target the .main .main-left .ship-card selector. This is where we're styling the cards that display our ships.
                </li>

                <li>
                We've already told these cards to stand in line with display: flex; and to stack their content vertically with flex-direction: column;.
                </li>

            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Flexbox: It's helping us again by making sure the contents of the ship cards are organized and adaptable.
                </li>
            </ul>
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 31: Padding Out the Ship Display 🛸✨</h2>

            <p>Now, let's make sure our ships are presented with the grandeur they deserve.</p>
            <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                In the .main .main-left .ship-card selector, we're adding padding: 18px; to ensure each ship has a comfortable space around it, avoiding any visual clutter.
                </li>

                <li>
                Now, let's give each card a width: 32%; so that they take up just the right amount of space in the control room, not too much and not too little.
                </li>

                <li>
                We're also setting the height: 100%; to allow the ship card to fill the space within its container, making sure our ships are always fully in view.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Padding: It’s like the personal space for each element. It makes sure the content inside doesn’t touch the edges, which can be visually uncomfortable.
                </li>
            </ul>
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 32: Ship Card Shadow 🚀✨</h2>

            <p>
            Alright, let’s continue making our spaceship cards look stellar! 
            </p>
            <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Now, let's give our card some depth with a shadow! Add -webkit-box-shadow: 3px 1px 15px 5px rgba(0,0,0,0.2); and box-shadow: 3px 1px 15px 5px rgba(0,0,0,0.2); to apply a subtle shadow, making the cards pop out from the background.
                </li>
                
                <li>
                You can copy the shadow from the box below.
                </li>
            </ol>

            <CopyBox
            text={"-webkit-box-shadow: 3px 3px 15px 5px rgba(0,0,0,0.2); box-shadow: 3px 3px 15px 5px rgba(0,0,0,0.2);"}
            />

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Remember, these shadows might look different on various screens or browsers, but they'll always give your spaceship cards a cool 3D effect.
                </li>
            </ul>
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 33: Perfecting the Ship Image 🖼️</h2>

            <p>
            Now, to ensure that the spaceship images fit perfectly within their cards.
            </p>
            <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Still in your style.css file, find the .main .main-left .ship-card img class.
                </li>

                <li>
                Set object-fit: contain; to ensure that your spaceship images are fully visible within the card without being stretched or squished.
                </li>

                <li>
                Set width: 100%; to make the images use the full width of the card.
                </li>
            </ol>
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 34: Adding Space Above the Information Section 🚀</h2>

            <p>
            Great job so far! Now, we're going to create a little breathing room for our ship cards. It's like giving our rockets some space to blast off! 🌌
            </p>
            <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Go to your CSS file and target the .ship-card .info class.
                </li>

                <li>
                Add margin-top: 42px; to it. This adds a bit of margin above the information part of the ship card, so it doesn't sit too close to the image of the rocket.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Margins are like the personal space of an element. They define the space around elements and between them. When we set a margin-top, we're telling the browser how much space to leave above the element before any other content starts. It's super useful for making our designs look well-organized and less crowded.
                </li>
            </ul>
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 35: Styling Paragraphs Within the Information Section 🌟</h2>

            <p>
            It's time to give some style to the text that describes our rockets. Let's make the title of each rocket stand out!
            </p>
            <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                In the same .ship-card .info class, target the paragraph element with p.
                </li>

                <li>
                margin-bottom: 10px; to push anything below it down a little.
                </li>

                <li>
                font-weight: 600; to make the font a bit bolder and more readable.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                The font-weight property sets how thick or thin characters in text should be displayed. By setting it to 600, we're going for a semi-bold look which is enough to draw attention without being too in-your-face. And margin-bottom is like saying, "Hey, give me some room below!" It's how we ensure that elements below our paragraph don't get too cozy and crowd the text.
                </li>
            </ul>
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 36: Styling Our Rockets 🚀✨</h2>

            <p>
            It's time to add some color to our fleet! We're going to style each rocket's info to match its theme.
            </p>
            <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                Open your style.css file and locate the .ship-card .info classes.
                </li>

                <li>
                Add a new class for the color red to the .ship-card .info rule. This will change the text color for any element within .info that also has the class .red.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Color Coding: By using specific classes for colors, we can quickly apply a theme to each ship card to signify its unique identity.
                </li>
                <li>
                    CSS Class Chaining: Learn how multiple classes can be chained together to increase the specificity of CSS selectors.
                </li>
            </ul>
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 37: Highlighting with Colors 🌈🖌</h2>

            <p>
            Let’s continue to brighten our fleet's display by adding another vibrant color.
            </p>
            <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Stay in the style.css file and add another class for the color pink to the .ship-card .info rule.
                </li>
                <li>
                    This will set the text color to pink for any .info elements that also have the .pink class. Use this class to style the paragraph that indicates a rocket with pink accents.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Thematic Styling: Assigning color themes to different elements helps in creating a visual connection between the item and its representation on the interface.
                </li>
                <li>
                    CSS Maintenance: Organizing your CSS file with comments and clear class names makes future updates and maintenance more manageable.
                </li>
            </ul>
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 38: Oranging the Orbit 🍊🚀</h2>

            <p>
            Next, we'll bring a splash of orange to our spacecraft display, representing the fiery boosters of our rockets.
            </p>
            <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    In your CSS file, find the .ship-card .info classes section.
                </li>
                <li>
                    Add a new color class called .orange to apply the color orange to the text of the ship information that has this class.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                Visual Dynamics: Introducing different colors to represent various elements of the spacecraft enhances the visual appeal and user experience.
                </li>
                <li>
                CSS Class Creation: Understand how to create and apply new CSS classes to add variety and distinction to elements on the webpage.
                </li>
            </ul>
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 39: Button Up for Launch 🚀</h2>

            <p>
            As we prepare for countdown, let's ensure our astronauts can select their ship with ease by styling the selection buttons.
            </p>
            <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Stay in your CSS and scroll to and target the .main .main-left .ship-card .info button section.
                </li>
                <li>
                    Set the width to 100% and the height to 32px to give our 'Select Ship' button a uniform and accessible size.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Consistent Interactivity: Providing buttons of the same size creates a reliable and accessible way for users to interact with the page.
                </li>
                <li>
                    Button Styling: Learning to style buttons is essential for guiding users through the selection process and enhancing user engagement.
                </li>
            </ul>
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 40: More Button Design 🎨🖱️</h2>

            <p>
            Now we will give our 'Select Ship' buttons a sleek design to make them stand out and beckon to future astronauts.
            </p>
            <img src={forty} className='demo__image' alt='img_forty' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Define the background-color to a deep space blue using the RGB value (45, 45, 194), and set the text color to white, ensuring our buttons are both striking and readable.
                </li>
                <li>
                    Remove any border by setting it to 'none' to maintain a clean and modern look.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Visual Contrast: The use of contrasting colors can draw attention to interactive elements, making them more intuitive to use.
                </li>
                <li>
                    Clean Design: Removing unnecessary outlines or borders can result in a cleaner and more focused design aesthetic.
                </li>
            </ul>
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 41: Interactivity and Feedback 🚀🖱️</h2>

            <p>
            We'll now enhance the user experience by adding interactive feedback to our buttons. A button that responds to user actions invites further interaction.
            </p>
            <img src={fortyOne} className='demo__image' alt='img_fortyOne' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Add the cursor property set to pointer, which changes the cursor to a hand icon when hovering over the buttons, signaling that they can be clicked.
                </li>
                <li>
                    Implement a transition effect for all properties over 0.2 seconds with an ease-in timing function to provide a smooth visual feedback when interacting with the buttons.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Cursor Feedback: The pointer cursor is a universal indicator of an interactive element, guiding users toward a call to action.
                </li>
                <li>
                    Transition Effects: Smooth transitions can enhance the user experience by providing subtle and informative visual cues.
                </li>
            </ul>
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 42: Hovering into Hyperdrive ✨🚀</h2>

            <p>
            Add some interactive flair to our 'Select Ship' buttons. When users hover over a button, let's make it light up like a star about to go supernova!
            </p>
            <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Access the style.css file and find the button:hover section within .ship-card .info.
                </li>
                <li>
                    Set the text color to a light gray with the RGB value (218, 218, 218) and the background color to a deeper shade of blue using RGB (34, 34, 150). This will create a dynamic effect when hovering over the buttons.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Hover Effects: Interactive elements that respond to user actions like hovering can significantly enhance the user experience and visual appeal.
                </li>
                <li>
                    Color Transitions: Using color changes on interactive elements to indicate a state change, such as hover, is a common web design practice.
                </li>
            </ul>
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 43: Building the Build Menu 🛠📋</h2>

            <p>
            We're now setting up the stage for our spacecraft construction area. Here, astronauts can customize their ships to their liking.
            </p>
            <img src={fortyThree} className='demo__image' alt='img_fortyThree' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Go to the index.html file and locate the main-right div.
                </li>
                <li>
                    Inside the main-right div, create a new div with the class build-menu. This will be the container for our spacecraft customization options.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Layout Containers: Div elements are often used as containers to segment parts of a webpage for specific functionalities.
                </li>
                <li>
                    Semantic Structure: Organizing your HTML with clear, descriptive class names makes the structure understandable and maintains readability.
                </li>
            </ul>
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 44: Menu Headings and Launch Prep 🚀📝</h2>

            <p>
            Time to outline the journey! We’ll add headings to our build-menu to guide our astronauts through the ship customization process.
            </p>
            <img src={fortyFour} className='demo__image' alt='img_fortyFour' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    In the index.html file, within the build-menu div, create a structured list of actions: choosing a starter, customizing the interior, building the engine, and fueling up. Use h3 tags for each step, assigning them classes one through four respectively.
                </li>
                <li>
                    Include an h2 heading with the class title above the list to give a title to this section of our spacecraft builder.
                </li>
                <li>
                    Add a button at the bottom with the class launch-button to signal the final step - getting ready for launch.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    HTML Structure: Use headings and button elements to create a hierarchical and interactive navigation menu within the build section.
                </li>
                <li>
                    Class Naming: Assigning classes to elements for styling and potential scripting interactions.
                </li>
            </ul>
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 45: Flexing Our Layout Skills</h2>

            <p>
            Let's get our layout in order! We'll use the powers of Flexbox to align our build-menu content neatly in the center.
            </p>
            <img src={fortyFive} className='demo__image' alt='img_fortyFive' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Open the style.css file and target the .main .main-right selector.
                </li>
                <li>
                    Apply the display flex, set the flex-direction to column, and align-items to center. This will center the build-menu items vertically and horizontally in the main-right section.
                </li>
                <li>
                    Set the width of the main-right section to 25% of its parent container and add some padding for aesthetic spacing.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Flexbox: Understanding how to use Flexbox for responsive and adaptive layouts.
                </li>
                <li>
                    Layout Proportion: Manage width and padding to ensure the content is visually balanced within the overall page layout.
                </li>
            </ul>
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 46: Designing the Build Menu Interface 🎨🛠️</h2>

            <p>
            Our spaceship configurator is not just about function—it should also captivate with form. Let's enhance the build menu with some styling finesse that aligns with our interstellar theme.
            </p>
            <img src={fortySix} className='demo__image' alt='img_fortySix' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Access the .build-menu class in your style.css file. This class is the backbone of our spaceship configurator’s interface, where future astronauts will make their selections.
                </li>
                <li>
                    Apply the display property set to flex with a column direction. This will structure our menu items in a vertical line, making it easy to follow the spaceship building process step-by-step.
                </li>
                <li>
                    Set the width to 100%, ensuring that the menu uses the full width of its container for maximum engagement space.
                </li>
                <li>
                    Encapsulate the menu with a border by setting it to 1px solid with an RGB value of (102, 102, 230), creating a clear delineation of the menu area while adding a hint of cosmic color.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    CSS Flexbox: Mastering flexbox is key to creating responsive designs that adapt to various screen sizes and orientations.
                </li>
                <li>
                    Visual Hierarchy: Structured styling leads the user's eye through the selection process, ensuring an intuitive build experience.
                </li>
                <li>
                    Design Aesthetics: The use of borders and colors not only differentiates areas of the page but also ties into the overall theme, making the experience visually cohesive.
                </li>
            </ul>
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 47: Centering Titles with Flexbox 🌌🔍</h2>

            <p>
            Titles are the signposts of our content, and in the realm of space, clarity is paramount. We’ll employ the power of Flexbox to ensure that our titles are not only visually balanced but also command attention.
            </p>
            <img src={fortySeven} className='demo__image' alt='img_fortySeven' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Navigate to the .build-menu .title class in your CSS. This particular class targets the heading of our build menu, a pivotal point of interaction.
                </li>
                <li>
                    Set the display property to flex, which will enable us to manipulate the title’s position with Flexbox.
                </li>
                <li>
                    Use flex-direction: column; to stack any child elements vertically. In our case, it prepares us for any sub-titles or additional content we may want to add beneath the main title in the future.
                </li>
                <li>
                    Center the title horizontally within its container by setting align-items: center;. This property works alongside display: flex; to align child elements along the cross axis.
                </li>
                <li>
                    Apply justify-content: center; to center the title vertically. When used in a column flex-direction, justify-content manages the vertical alignment.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Flexbox Alignment: Diving deeper into the flexbox model reveals its capability to align content both horizontally and vertically, providing a powerful tool for designers.
                </li>
                <li>
                    Styling Titles: Ensuring that titles are prominently and centrally displayed reinforces their importance and helps guide the user’s journey.
                </li>
                <li>
                    Scalable Structure: By structuring our titles with room for expansion, we anticipate future growth and changes, embodying the principles of scalable and maintainable design.
                </li>
            </ul>
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 48: Embellishing the Build Menu Title 🌟🏷️</h2>

            <p>
            The title of our build menu isn't just a label; it's the beacon that draws future pilots in. We'll give it the prominence and attention it deserves with a vibrant background and crisp text for maximum readability and allure.
            </p>
            <img src={fortyEight} className='demo__image' alt='img_fortyEight' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Set the background color to a striking shade of green with RGB (45, 194, 45) to evoke the excitement of lush new planets awaiting discovery.
                </li>
                <li>
                    Choose a color of #ffffff for the text to ensure that the title stands out against the green backdrop, ensuring clarity and focus.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Color Theory in UI Design: The combination of green for energy and white for clarity follows best practices in user interface design, providing an inviting and comfortable viewing experience.
                </li>
            </ul>
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 49: Perfecting the Padding 📐✨</h2>

            <p>
            To give our title the space it deserves, we’ll add padding. Padding can be the difference between a cramped afterthought and a grand statement. It’s time to give our title some breathing room.
            </p>
            <img src={fortyNine} className='demo__image' alt='img_fortyNine' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Remain in the .build-menu .title class within your CSS file.
                </li>
                <li>
                    Introduce padding to the top and bottom of the title with 18px. This space insulates our title text from the edges of its green container, creating a comfortable reading space.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Spacing in Design: Proper use of padding can dramatically improve content legibility and aesthetic appeal by creating a visually pleasing hierarchy.
                </li>
                <li>
                    Balance and Proportion: Adequate padding contributes to the balance and proportion of elements on the page, which are fundamental principles of design.
                </li>
            </ul>
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 50: Unifying the Build Menu Design 🎨🖌️</h2>

            <p>
            With our build menu's individual elements stylishly crafted, it's time to unify them into a cohesive design that not only serves the functionality but also elevates the aesthetics of our spaceship configurator.
            </p>
            <img src={fifty} className='demo__image' alt='img_fifty' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Let's apply a border that echoes the design of the individual steps. Set a 1px solid border with an RGB color of (102, 102, 230), framing the title with a hint of celestial blue.
                </li>
            </ol>

         
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 51: Styling the Build Menu Steps 🚀🔧</h2>

            <p>
            Each step of the spaceship building process deserves its spotlight. By styling these steps, we're not just organizing the content; we're crafting an engaging narrative that leads our astronauts through their journey of creation.
            </p>
            <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />
            
            <h3>Here’s what to do:</h3>
            <ol>
                <li>
                    Stay in the style.css file, and this time, target the .build-menu h3 selector. These headings represent the individual steps in the build menu.
                </li>
                <li>
                    Apply padding to the top and bottom of 18px to ensure each step is visually distinct and comfortable to interact with.
                </li>
                <li>
                    Introduce a left padding of 8px to offset the text slightly from the edge, providing a cleaner alignment with the title above.
                </li>
                <li>
                    Just like the title, give each step a 1px solid border using the same RGB value (102, 102, 230) for visual harmony and connection between the elements.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Visual Rhythm: Uniform padding and borders across different elements establish a rhythm that makes the interface easier to navigate.
                </li>
            </ul>
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 52: Color-Coding the Build Steps 🎨👣</h2>

            <p>
            To guide our users through the spaceship customization process, we’ll introduce a color-coded system within our build menu. Each step will have a unique color, representing a different phase of the build, making the process intuitive and visually engaging.
            </p>
            <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />
            
            <h3>Here’s what to do:</h3>
            <ol>
                <li>
                    In your style.css file, target the classes .build-menu .one, .build-menu .two, .build-menu .three, and .build-menu .four. These classes correspond to each step of the spaceship building process.
                </li>
                <li>
                    Assign each class a distinct color: blue for step one, purple for step two, green for step three, and a vibrant yellow (RGB 206, 206, 0) for step four. These colors will not only beautify the interface but also subconsciously assist users in navigating the build process.
                </li>
            </ol>


        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 53: Interactivity with Buttons 🔲🖱️</h2>

            <p>
            Buttons are not just functional components; they are also the call to action that prompts user interaction. We'll make sure our buttons are not only noticeable but also inviting to clicks, encouraging users to take the next step in their spaceship building adventure.
            </p>
            <img src={fiftyThree} className='demo__image' alt='img_fiftyThree' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Direct your attention to the .build-menu button within the style.css. This button is the gateway to launching the spaceship, so it needs to stand out.
                </li>
                <li>
                    Apply a bold red background color to capture attention and convey the excitement of moving forward to the launch phase.
                </li>
                <li>
                    Contrast the red background with white text for maximum legibility and a classic, compelling aesthetic.
                </li>
                <li>
                    Enhance the button with CSS properties for font size and weight to make the text clear and commanding.
                </li>
                <li>
                    Finally, ensure the cursor changes to a pointer on hover to provide an immediate visual feedback that this is an interactive element.
                </li>
            </ol>
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 54: Crafting the Footer 🚀👟</h2>

            <p>
            Our journey through the stars almost complete. The footer will be the base of our page, providing grounding and information.
            </p>
            <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Navigate to the bottom of your index.html file, just before the closing body tag.
                </li>
                <li>
                    Insert a div element and assign it the class 'footer'. This section will house our credits, copyright, and additional information.
                </li>
            </ol>
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 55: Emphasizing Brand Identity 🛠️📛</h2>

            <p>
            As we set the foundation for our footer, it's essential to reinforce our brand identity. A logo is a potent symbol, encapsulating our mission and values in a single emblem.
            </p>
            <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Within the footer div you just created, add another div with the class 'logo-container'. This will be a dedicated space to showcase our brand logo.
                </li>

                <li>
                    Insert an img tag inside the logo-container div. Set the src attribute to the path of your logo image, which in this example is "./images/logo.png". Ensure the logo is appropriately sized and optimized for web display.
                </li>

                <li>
                    The logo not only acts as a stamp of authenticity but also serves as a visual anchor, a comforting signpost that the journey has been well-guided and supported.
                </li>
            </ol>

            
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 56: Establishing the Footer Framework 🛠️👣</h2>

            <p>
            The footer is our spacecraft's landing gear: essential, supportive, and full of vital info. It's time to lay out the structure that will hold our contact links.
            </p>
            <img src={fiftySix} className='demo__image' alt='img_fiftySix' />
            
            <h3>Here’s what to do:</h3>
            <ol>
                <li>
                    Add a div with the class 'logo-container' inside the footer. This div will specifically house our brand's logo, reinforcing our identity even on the last outpost of the page.
                </li>
                <li>
                    Insert an image element inside the logo-container div with the src attribute pointing to your logo file. Ensuring that the logo is the correct file path will establish consistent branding throughout the site.
                </li>
                <li>
                    Next, add another div alongside the logo-container with the class 'footer-menu'. This will act as a holder for our footer navigation, providing structure and order to our footer content.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            
            <ul>
                <li>
                    Footer Structure: The footer is an integral part of a webpage, offering users information and navigation options at the end of their journey on the page.
                </li>
                <li>
                    HTML Containers: Using divs to contain and separate different areas within the footer allows for better styling and organization within the HTML document.
                </li>
            </ul>
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 57: Populating the Footer Navigation 🧭✨</h2>

            <p>
            It's time to chart the options available in our footer's navigation.
            </p>
            <img src={fiftySeven} className='demo__image' alt='img_fiftySeven' />
            
            <h3>Here’s what to do:</h3>


            <ol>
                <li>
                    Inside the `footer-menu` div of your index.html file, create a `ul` element and assign it the class `resources-container`. This unordered list will be the scaffold upon which our navigation links will hang.
                </li>

                <li>
                    Add `li` elements within the `ul` to represent each navigation link. Include the text for Home Page, Contact Us, Support, and Travel to Mars. These will be our signposts to other pages or sections of the site.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Structuring Navigation: Organizing links in an unordered list is a clean, semantic way to structure navigation elements, enhancing both accessibility and SEO.
                </li>
                <li>
                    Footer as a Resource: The footer can be a comprehensive resource for visitors, providing them with links to navigate the website and discover additional content.
                </li>
            </ul>
        </div>

        <div style={currentStep == 58 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 58: Integrating Social Media into the Footer 🌐</h2>

            <p>
            In the age of interconnectivity, a spaceship is only as good as the network it's part of. Let's expand our communication channels by integrating social media links into our footer, connecting our users to our community across various planets.
            </p>
            <img src={fiftyEight} className='demo__image' alt='img_fiftyEight' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Below the resources-container in the footer-menu, create a new `ul` element with the class 'social-media-container'. This list will house the fictitious space themed social media platforms.
                </li>
                <li>
                    Populate the social-media-container with `li` elements for each social media platform your site is connected with. For instance, you could have "Rocketbook", "SpacedIn", and "InstaShip".
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    HTML Lists for Navigation: Using unordered lists to organize navigation links is a standard practice for a clean, accessible footer design.
                </li>
            </ul>
        </div>

        <div style={currentStep == 59 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 59: Flexing the Footer Layout 💪🔽</h2>

            <p>
            Our footer needs to be more than static, it should be a dynamic platform that presents our information neatly and responsively. By applying Flexbox, we'll create a footer that adapts and organizes content beautifully across devices.
            </p>
            <img src={fiftyNine} className='demo__image' alt='img_fiftyNine' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    In your style.css, target the .footer class to start applying the Flexbox properties.
                </li>
                <li>
                    Set the display to flex, which will enable the flexible box layout and provide you with a range of properties for aligning and distributing space among items in the container, even when their size is unknown or dynamic.
                </li>
                <li>
                    Choose a flex-direction of row to layout items horizontally. This is the initial setting and works perfectly for a row of items such as a traditional footer.
                </li>
                <li>
                    Set align-items to flex-start to align items to the start of the container, which ensures they're all positioned at the top of the footer, giving us a clean baseline.
                </li>
                <li>
                    Apply justify-content: space-between to distribute the available space evenly between the items, giving us a balanced, spread-out look within the footer.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Flexbox Layout: A modern CSS layout technique that makes it easy to design flexible responsive layout structures without float or positioning.
                </li>
                <li>
                    Responsive Design: Ensuring that our footer content is adaptable to different screen sizes for optimal viewing and interaction.
                </li>
            </ul>
        </div>

        <div style={currentStep == 60 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 60: Coloring and Sizing the Footer 🎨📏</h2>

            <p>
            Now let's infuse life into our footer with some color and ensure it has just the right amount of space. A splash of color will not only attract attention but also help in distinguishing the footer from the rest of the content, marking its significance.
            </p>
            <img src={sixty} className='demo__image' alt='img_sixty' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Still within the .footer class in your CSS file, set the background-color property. The color RGB(45, 45, 194) will give it a deep, space-like feel, complementing the overall theme of the site.
                </li>

                <li>
                    Define the height with a value that makes sense for your content. If you set it to 100%, it will take up the full height of its containing element, which might be useful if you want a large footer to contain more elements or information.
                </li>
                <li>
                    This styling step encapsulates the footer's visual boundary, making it a distinct section that comfortably houses all the elements it needs to, without spilling over or cramping up.
                </li>
            </ol>
        </div>

        <div style={currentStep == 61 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 61: Padding and Proportions for the Footer 📐👟</h2>

            <p>
            A well-padded footer is like a good pair of boots for our website: it adds comfort and style.
            </p>
            <img src={sixtyOne} className='demo__image' alt='img_sixtyOne' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Select the .footer class in your CSS file to begin adding padding to your footer.
                </li>
                <li>
                    Apply a padding-top of 62px to push the content down from the top edge, creating a spacious header-like effect within the footer itself.
                </li>
                <li>
                    Add padding-left and padding-right of 45px each to maintain a consistent space on both sides, ensuring the content doesn't stick to the edges, which enhances readability and aesthetics.
                </li>
                <li>
                    These padding values are not set in stone; adjust them based on your content and overall design to ensure visual balance and harmony.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Padding in CSS: Padding creates space around an element's content, inside of any defined borders, adding to the element's visual appeal and readability.
                </li>
                <li>
                    Visual Comfort: Adequate spacing around content ensures that the website doesn't feel cramped and allows for a more pleasant user experience.
                </li>
            </ul>
        </div>

        <div style={currentStep == 62 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 62: Structuring the Logo Container 🖼️🔲</h2>

            <p>
            Our logo is the flag of our digital territory. Let's give it the prominence it deserves by structuring its container with care and precision.
            </p>
            <img src={sixtyTwo} className='demo__image' alt='img_sixtyTwo' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    In your CSS file, target the .footer .logo-container class. This container will specifically hold and present our logo.
                </li>
                <li>
                    Set its display property to flex to take advantage of the Flexbox layout which is perfect for centering content.
                </li>
                <li>
                    Use flex-direction: column; which will stack any child elements vertically, centering them along the cross axis and distributing space around them.
                </li>
                <li>
                    With align-items and justify-content both set to center, the logo will be centered both horizontally and vertically within the container, creating a balanced and focused look.
                </li>
                <li>
                    Define the width of the container as 20% of its parent, giving the logo ample space without overwhelming the footer's real estate.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Flexbox for Centering: Flexbox makes it straightforward to center elements without the need for margins or calculations.
                </li>
            </ul>
        </div>

        <div style={currentStep == 63 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 63: Perfecting the Footer Logo Appearance 🖼️✨</h2>

            <p>
            Our brand's insignia deserves the spotlight in the footer, so we'll make sure it's displayed perfectly, irrespective of screen size or resolution. We'll ensure it scales beautifully and maintains its aspect ratio with some CSS fun.
            </p>
            <img src={sixtyThree} className='demo__image' alt='img_sixtyThree' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Address the .footer .logo-container img selector in your style.css. This targets the image within the logo container specifically.
                </li>
                <li>
                    Assign the width of the image to 100% to make sure the logo takes up the full width of its container, scaling responsively with the screen size.
                </li>
                <li>
                    Use the object-fit property set to contain to ensure that the logo maintains its aspect ratio without being stretched or squished. This property tells the browser to scale the image as large as possible within its container without cutting off any part of the image or distorting its natural proportions.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    Responsive Images: Making images responsive ensures they work well on devices with different screen sizes and resolutions.
                </li>
                <li>
                    CSS Object-Fit Property: A powerful property that controls how content-images or videos should be resized to fit their containers.
                </li>
            </ul>
        </div>

        <div style={currentStep == 64 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 64: Structuring the Footer Menu 📐🧭</h2>

            <p>
            A well-structured footer menu not only looks good but also navigates well. We'll apply the principles of Flexbox to our footer menu, ensuring that it is as functional as it is aesthetic.
            </p>
            <img src={sixtyFour} className='demo__image' alt='img_sixtyFour' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    In your CSS file, target the .footer .footer-menu class to apply styling to the navigation section of the footer.
                </li>
                <li>
                    Set its display property to flex, which allows you to use other flex properties to arrange the menu items.
                </li>
                <li>
                    Use flex-direction: row; to place the menu items side by side in a row.
                </li>
                <li>
                    Align the items to the start of the cross axis using align-items: flex-start; this ensures that all menu items are aligned at the top of their container.
                </li>
                <li>
                    With justify-content: space-between;, we space out the menu items evenly across the horizontal space of the footer, giving each link its own territory without crowding.
                </li>
                <li>
                    Set both the width and height to 20% and 100% respectively, providing a specific size to the footer menu for consistency and control over its appearance on different devices.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            
            <ul>
                <li>
                    Flexbox for Navigation Layout: Flexbox is ideal for laying out complex navigation structures in a flexible, responsive manner.
                </li>
                <li>
                    Component Sizing: Defining explicit dimensions for UI components ensures that they look good and function well across various screen sizes and resolutions.
                </li>
            </ul>
        </div>

        <div style={currentStep == 65 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 65: Refining the Footer Menu List 📝🚀</h2>

            <p>
            To ensure our footer menu's cleanliness and focus, we'll remove the default list styling and prepare it for a sleek, modern look that's all about clarity and ease of navigation.
            </p>
            <img src={sixtyFive} className='demo__image' alt='img_sixtyFive' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Access the .footer .footer-menu ul in your CSS file to target the unordered list within your footer menu.
                </li>

                <li>
                    Set list-style to none to remove the default list item markers. This is a common practice for cleaner navigation menus where the list markers can be visually distracting.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            <ul>
                <li>
                    List Styling: Customizing lists by removing default styles is a fundamental step in creating custom navigation menus.
                </li>
            </ul>
        </div>

        <div style={currentStep == 66 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 66: Styling the Footer Menu Items 🖌️👟</h2>

            <p>
            Each menu item is a stepping stone to further discovery. We'll style these links to entice clicks, with inviting colors and hover effects that beckon to the explorers in our users.
            </p>
            <img src={sixtySix} className='demo__image' alt='img_sixtySix' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    Continue in your CSS file, and now target the .footer .footer-menu ul li to style the individual list items.
                </li>
                <li>
                    Apply a color of #ffffff (white) to ensure the text stands out against the footer background.
                </li>
                <li>
                    Add a margin-bottom of 12px to create some vertical space between each item, which is particularly helpful for touch targets on mobile devices.
                </li>
                <li>
                    Adjust the font-size to 18px and the font-weight to 600, making the text large and bold enough to be easily readable on all devices.
                </li>
                <li>
                    Set the cursor to pointer to visually indicate that these items are clickable.
                </li>
                <li>
                    Finally, add a transition of 0.2s all ease-in for a smooth effect when these styles change, giving a polished feel to interactions.
                </li>
            </ol>

        </div>

        <div style={currentStep == 67 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 67: Creating Interactive Hover Effects for Footer Links ✨🖱️</h2>

            <p>
            A spaceship's control panel lights up when you engage with it, and so should our footer links. Let's make our footer menu items react when they’re hovered over, indicating interactivity and enhancing the user experience.
            </p>
            <img src={sixtySeven} className='demo__image' alt='img_sixtySeven' />
            
            <h3>Here’s what to do:</h3>

            <ol>
                <li>
                    In your stylesheet, target the list items within the footer-menu with the pseudo-class :hover to specify the style changes when the mouse pointer hovers over them.
                </li>

                <li>
                Change the color property to #c9c9c9, a lighter shade that will stand out against the footer’s background, signaling the user can interact with these elements.
                </li>
            </ol>

            <h3>Concepts to Learn:</h3>

            
            <ul>
                <li>
                    CSS Hover Selector: Pseudo-classes like :hover are used to define a special state of an element. In this case, it’s used to give visual feedback on mouse-over events.
                </li>
                <li>
                    User Interaction Feedback: Providing a visual response when users hover over clickable items is an essential aspect of intuitive interface design.
                </li>
            </ul>
        </div>




        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>



    </div>
  )
}

export default BuildShip