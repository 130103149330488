import React, {useState} from 'react'
import one from './pythonImages/spaceImages/1.png';
import two from './pythonImages/spaceImages/2.png';
import three from './pythonImages/spaceImages/3.png';
import four from './pythonImages/spaceImages/4.png';
import five from './pythonImages/spaceImages/5.png';
import six from './pythonImages/spaceImages/6.png';
import seven from './pythonImages/spaceImages/7.png';
import eight from './pythonImages/spaceImages/8.png';
import nine from './pythonImages/spaceImages/9.png';
import ten from './pythonImages/spaceImages/10.png';
import eleven from './pythonImages/spaceImages/11.png';
import twelve from './pythonImages/spaceImages/12.png';
import thirteen from './pythonImages/spaceImages/13.png';
import fourteen from './pythonImages/spaceImages/14.png';
import fifteen from './pythonImages/spaceImages/15.png';
import sixteen from './pythonImages/spaceImages/16.png';
import seventeen from './pythonImages/spaceImages/17.png';
import eighteen from './pythonImages/spaceImages/18.png';
import nineteen from './pythonImages/spaceImages/19.png';
import twenty from './pythonImages/spaceImages/20.png';
import twentyOne from './pythonImages/spaceImages/21.png';
import twentyTwo from './pythonImages/spaceImages/22.png';
import twentyThree from './pythonImages/spaceImages/23.png';
import twentyFour from './pythonImages/spaceImages/24.png';
import twentyFive from './pythonImages/spaceImages/25.png';
import twentySix from './pythonImages/spaceImages/26.png';
import twentySeven from './pythonImages/spaceImages/27.png';
import twentyEight from './pythonImages/spaceImages/28.png';
import twentyNine from './pythonImages/spaceImages/29.png';
import thirty from './pythonImages/spaceImages/30.png';
import thirtyOne from './pythonImages/spaceImages/31.png';
import thirtyTwo from './pythonImages/spaceImages/32.png';
import thirtyThree from './pythonImages/spaceImages/33.png';
import thirtyFour from './pythonImages/spaceImages/34.png';
import thirtyFive from './pythonImages/spaceImages/35.png';
import thirtySix from './pythonImages/spaceImages/36.png';
import thirtySeven from './pythonImages/spaceImages/37.png';
import thirtyEight from './pythonImages/spaceImages/38.png';
import thirtyNine from './pythonImages/spaceImages/39.png';
import forty from './pythonImages/spaceImages/40.png';
import fortyOne from './pythonImages/spaceImages/41.png';
import fortyTwo from './pythonImages/spaceImages/42.png';
import fortyThree from './pythonImages/spaceImages/43.png';
import fortyFour from './pythonImages/spaceImages/44.png';
import fortyFive from './pythonImages/spaceImages/45.png';
import fortySix from './pythonImages/spaceImages/46.png';
import fortySeven from './pythonImages/spaceImages/47.png';
import fortyEight from './pythonImages/spaceImages/48.png';
import fortyNine from './pythonImages/spaceImages/49.png';
import alien from './pythonImages/spaceImages/alien.png';
import player from './pythonImages/spaceImages/player.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import './tutorial.css';

const SpaceInvaders = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 48;

  return (
    <div className='pythonGames__container'>
        <h1>
        🚀 Welcome to our exciting Space Invaders tutorial! 🚀
        </h1>

        <h3 className='intro'>
        In this tutorial, we will create a fantastic Space Invaders game using Python and the Pygame library. You'll learn how to create cool game objects like aliens, players, and bullets! Are you ready? Let's go! 👾🎮
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>


        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 1: Gathering Our Space Tools 🛠️
            </h2>

            <p>
            Before we can set off on our space journey, we need the right tools. This code imports or "gathers" three essential tools (or libraries) we'll be using throughout our adventure:
            </p>
            <img src={one} className='demo__image' alt='img_one' />

            <ol>
                <li>
                pygame: This is our space simulator! It's a library designed for making video games.
                </li>

                <li>
                sys: This tool helps us interact with our spaceship's operating system (like exiting the game).
                </li>

                <li>
                random: Space is full of unpredictable surprises! We'll use this to add some randomness to our game.
                </li>
            </ol>

            <p>
            Why Are We Using It?
            </p>

            <p>
            Think of these imported tools like the gadgets on a spaceship's control panel. Without them, our ship can't operate! They provide us with handy functions and abilities that save us time and effort when coding our space adventure.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                import: This keyword tells Python to load in a library or module so we can use its features.
                </li>

                <li>
                pygame, sys, random: These are the names of the modules we are importing. Once imported, we can call functions from these libraries.
                </li>
            </ul>
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 2: Igniting The Engines 🚀
            </h2>

            <p>
            This line is equivalent to turning on the engines of our spaceship. It initializes all the modules within Pygame, preparing them for our cosmic journey.
            </p>
            <img src={two} className='demo__image' alt='img_two' />

            <p>
            Why Are We Using It?
            </p>

            <p>
            Before we can use Pygame's features, we have to make sure it's up and running. It's similar to how we need to start a car's engine before driving. This initialization ensures everything in Pygame is set up and ready to help us build our game.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>
            <ul>
                <li>
                pygame: This refers to the Pygame library we imported earlier.
                </li>

                <li>
                .init(): This is a function provided by Pygame. When called (with the parentheses ()), it gets everything in Pygame ready to go.
                </li>
            </ul>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 3: Building Our Spacecraft's Control Panel 🖥️✨
            </h2>

            <p>
            As we set our sights on the vastness of space, we first need a window to view our adventures. These lines define the size of our spacecraft's viewport (or game window) where all the action will take place. The screen_width is set to 800 units across, and the screen_height is set to 600 units tall.
            </p>

            <img src={three} className='demo__image' alt='img_three' />

            <p>
            Why Are We Using It?
            </p>

            <p>
            Every astronaut needs a clear view of space! By setting up the dimensions of our game window, we can determine how much of the space battlefield we'll see. Think of it as deciding the size of the telescope you want to use to gaze at the stars. It's crucial to have the right viewpoint for our upcoming space battles.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                screen_width & screen_height: These are variables. Imagine them as storage compartments in our spaceship where we can keep essential information. Here, they store the dimensions of our game window.
                </li>

                <li>
                =: This is the assignment operator. It's like telling a robot 🤖 on our spaceship to store a specific item in a particular compartment. In this case, the robot is storing the numbers 800 and 600 in the screen_width and screen_height compartments respectively.
                </li>
            </ul>

            <h3>
            With our spacecraft's control panel set up, we are one step closer to diving into the heart of the cosmos and defending our galaxy! 🌌🚀✨
            </h3>
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 4: Launching Our Spacecraft's Viewport 🚀🪟
            </h2>

            <p>
            Now that we have the dimensions of our viewport, it's time to create and launch it! The first line crafts our game window using the dimensions we set before, while the second line bestows our space mission with a name: "Space Invaders."
            </p>

            <img src={four} className='demo__image' alt='img_four' />



            <p>
            Why Are We Using It?
            </p>

            <p>
            Our game would be like an invisible spaceship 🛸 if we didn't have a window to witness the epic space battles. The viewport provides a stage for our space drama to unfold. And every epic space saga deserves a grand title, which is why we've named ours "Space Invaders."
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                screen: Another storage compartment (or variable) aboard our spacecraft. It will store the game window we create.
                </li>

                <li>
                pygame.display.set_mode(): Think of this as a space gadget 🛠️ that crafts our viewport. It takes in a pair of values, which are the width and height, respectively. These values determine the size of the window it produces.
                </li>

                <li>
                pygame.display.set_caption(): This function christens our game. It's like painting a name on the side of our spaceship. Here, we've chosen "Space Invaders" to capture the essence of our cosmic adventure.
                </li>
            </ul>

            <h3>
            And voila! 🎉 Our viewport is ready, and our spacecraft is proudly showcasing its mission name. The universe awaits as we gear up for our interstellar adventure! 🌠🌌🚀
            </h3>
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 5: Painting the Canvas of the Cosmos 🎨🌌
            </h2>

            <p>
            As artists of the astral plane, we need a palette of cosmic colors to paint our celestial scenes. This code provides us with four essential shades: the purity of starlight (WHITE), the verdancy of alien landscapes (GREEN), the fiery hue of danger and passion (RED), and the vast emptiness of the void (BLACK). Each color is represented as a trio of values, a mystical mix determining its appearance. 
            </p>
            <img src={five} className='demo__image' alt='img_five' />

            <p>
            Why Are We Using It?
            </p>
            <p>
            Space isn't just a black void. It's filled with dazzling colors, flashing nebulas, and radiant stars. By setting up our color palette, we ensure our space journey is as vibrant as the tales told by ancient stargazers. These pre-defined colors will help us illustrate everything from our ship to the invaders and the vastness of space around us.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                WHITE, GREEN, RED, BLACK: These are more storage compartments (variables) in our spacecraft, each dedicated to holding a particular shade. They store the colors we'll use to paint our interstellar opera.
                </li>

                <li>
                =: Our trusty assignment operator is back, instructing to store the right color mixtures in the designated compartments.
                </li>

                <li>
                (255, 255, 255): These are color codes known as RGB values (Red, Green, Blue). By adjusting these three numbers (ranging from 0 to 255), we can conjure almost any color imaginable! For example, (255, 255, 255) is the code for white, representing maximum brightness in all three channels.
                </li>
            </ul>

            <h3>
            With our palette in hand 🎨, we are poised to turn the blank canvas of space into a masterpiece of motion, color, and drama. Get ready to splash the cosmos with the tales of our epic encounters! 🌠🎨🚀
            </h3>
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 6: Setting the Pace of the Starscape ⏳🚀
            </h2>

            <p>
            In the weightlessness of space, movement becomes an art. These lines define how swiftly different elements of our intergalactic ballet move across the cosmic stage. The player_speed tells us how fast our spaceship dances through the stars. The bullet_speed indicates the rapidity of our defenses as they soar towards threats, while alien_speed reveals the unhurried advance of our extraterrestrial foes.
            </p>
            <img src={six} className='demo__image' alt='img_six' />


            <p>
            Why Are We Using It?
            </p>
            <p>
            Just as the planets each have their orbits and speeds, so too do the elements of our game. By setting the pace 🏃‍♂️ for our spaceship, bullets, and the aliens, we shape the rhythm and tempo of our space saga. It ensures that our game is balanced – challenging yet playable, testing the reflexes of any aspiring astronaut.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>
            <ul>
                <li>
                player_speed, bullet_speed, alien_speed: Yet more compartments (variables) within our spaceship's control system. Each one holds a value representing speed.
                </li>

                <li>
                =: Our steadfast assignment operator, ensuring that each component knows its pace.
                </li>

                <li>
                8, 15, 1: These are the speeds. The higher the number, the faster the movement. It's a bit like setting the throttle on a spaceship: the more you push, the faster you go!
                </li>
            </ul>

            <h3>
            With the pace set, our space ballet becomes a choreographed dance 🩰 of movement and strategy. The rhythm of the cosmos now has a beat, and it's up to us to move in harmony! 🌌🎵🚀
            </h3>
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'> 
            <h2>
            Step 7: Staging Our Cosmic Drama 🎬🌌
            </h2>

            <p>
            The universe is vast, but every cosmic story needs a setting, characters, and a plot. These lines lay the groundwork for our space adventure. We're deciding if our defense mechanism (the bullet) is active, setting the initial number of interstellar antagonists (num_aliens), initiating our journey at level one (level), determining how many challenges await us (max_levels), and starting with a blank scorecard (score).
            </p>
            <img src={seven} className='demo__image' alt='img_seven' />
            
            <p>
            Why Are We Using It?
            </p>

            <p>
            Every space mission has its parameters. By setting these, we're establishing the rules and goals of our space odyssey:
            </p>

            <ul>
                <li>
                Knowing whether a bullet is fired helps us manage our defenses.
                </li>

                <li>
                The number of aliens sets the scale of the challenge.
                </li>

                <li>
                The level indicates the stage of our journey, while max_levels gives us a glimpse of the ultimate challenge.
                </li>

                <li>
                Our score serves as a testament to our skill and bravery in the face of cosmic danger.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>
            <ul>
                <li>
                bullet_fired, num_aliens, level, max_levels, score: These variables are like the dials and switches on our spaceship's control panel, each indicating a crucial aspect of our space saga and storing unique data.
                </li>

                <li>
                =: Our trusty assignment operator is hard at work, configuring our spacecraft's systems according to the epic tale we're about to unfold.
                </li>

                <li>
                False, 10, 1, 3, 0: These values represent the initial settings. They are stored in the variable. They're like the opening scene of a play, setting the stage for the drama to come.
                </li>
            </ul>

            <h3>
            Imagine being at the helm of a spaceship, gazing at a star map 🌌🗺️ and plotting your journey. With these parameters set, our route through the galaxy becomes clearer, filled with challenges, milestones, and the promise of interstellar glory! 🌟🚀🛸
            </h3>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 8: Choosing Our Cosmic Typeface 🖋️🌠
            </h2>

            <p>
            Communication is key, even in the vastness of space. This line of code selects a cosmic typeface (or font) that we'll use to display vital information, like our score or level, on the game screen. In essence, we're choosing the style of the letters and numbers that will guide us on our journey, ensuring they're both legible and aesthetically fitting for our space adventure.
            </p>
            <img src={eight} className='demo__image' alt='img_eight' />

            <p>
            Why Are We Using It?   
            </p>
            <p>
            Even amidst swirling nebulas and meteor showers, we need clarity on our performance and objectives. By setting a font, we ensure that important information is displayed clearly and beautifully, enhancing both the functionality and aesthetics of our game. Think of it as choosing the right instrument panel for a spaceship: it's not just about getting readings but also about understanding them quickly and accurately.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                font: This is a variable, akin to a dedicated compartment on our ship, where we store the specific typeface we'll use throughout our game.
                </li>

                <li>
                pygame.font.Font(): This is the tool 🛠️ Pygame provides to select and use fonts. It's like a machine on our spaceship that crafts the visual design of our textual information.
                </li>

                <li>
                pygame.font.get_default_font(): Within the vast font libraries of Pygame, this function retrieves the standard, default font. It ensures that even if we haven't chosen a fancy alien script, we still have a reliable and legible font to fall back on.
                </li>

                <li>
                24: This is the size of the font in pixels. Like choosing the size of a poster in a room, it ensures our messages are neither too tiny to read nor so large they obstruct our view of the stars.
                </li>
            </ul>

            <h3>
            With our cosmic typeface chosen, our spaceship's dashboard is ready to guide us with clear, stylish information. As the stars zoom by, our chosen font will chronicle our achievements, challenges, and the unfolding story of our galactic journey! 🌌📜🚀
            </h3>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 9: Gearing Up with Galactic Imagery 📸🛸
            </h2>

            <p>
            The universe isn't just about stars and voids; it's also filled with mysterious creatures! This line of code fetches a detailed image of our extraterrestrial adversaries, the aliens. We're loading a visual representation of our alien foes from a file named 'alien.png', which is located in an 'images' folder. Once loaded, we ensure that the image maintains its transparency by converting any alpha (transparency) channels it might have. This means our alien won't have any awkward square backgrounds; they'll float seamlessly in space!
            </p>
            <img src={nine} className='demo__image' alt='img_nine' />

            <p>
            Why Are We Using It?
            </p>

            <p>
            Our space adventure isn't just about moving shapes and colors; it's a narrative filled with characters. By loading this alien image, we give identity to our opponents. This visual representation enhances immersion, making players feel like they're truly battling intergalactic creatures and not just abstract pixels.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>
            <ul>
                <li>
                alien_image: This variable is like a frame on the wall of our spaceship, ready to display the portrait of our extraterrestrial enemy.
                </li>

                <li>
                pygame.image.load(): Think of this as our spaceship's onboard scanner 🖨️. It fetches and processes images. In this case, it's reading the 'alien.png' file from the 'images' directory.
                </li>

                <li>
                'images/alien.png': This is the path to our alien's image. It tells our scanner exactly where to look.
                </li>

                <li>
                .convert_alpha(): Space is all about seamless beauty. By converting the alpha channel of our image, we ensure our alien integrates perfectly against the backdrop of stars, without any jarring square edges or backgrounds.
                </li>
            </ul>

            <h2>
            Download the image.
            </h2>
            <p>
                In order for this line of code to work, you need to download the image and store it in a folder named "images" within your project.
            </p>
            <br/><br/>
            <ol>
                <li>Add a "folder" to your current project.</li>

                <li>Rename the folder to "images".</li>

                <li>Click this <a href={alien} download >link</a> to download the alien image.</li>

                <li>Save the alien image to the "images" folder.</li>
            </ol>

            <h3>
            With this line executed, our spaceship's display will be able to showcase the very face of our cosmic adversaries. Get ready to meet the extraterrestrial challengers eye-to-eye! 👽🚀🌌
            </h3>
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 10: Rescaling the Galaxy 🌌🔍
            </h2>

            <p>
            In the grand expanse of the universe, size and perspective matter. With this line, we're adjusting the dimensions of our previously loaded alien image. We're telling our spaceship's software to resize the alien portrait to a 60 by 60 pixel square. Think of it like using a cosmic telescope 🔭 to zoom in or out on an object, making sure it fits perfectly into our galactic narrative. 
            </p>
            <img src={ten} className='demo__image' alt='img_ten' />

            <p>
            Why Are We Using It?
            </p>
            <p>
            Space battles can be chaotic, with stars, ships, and aliens all jostling for attention on our screen. By setting a consistent size for our aliens, we ensure that they're visible and recognizable, without overwhelming the player or dwarfing other game elements. This also ensures that our gameplay remains balanced: the aliens are neither too easy nor too challenging to target.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>
            <ul>
                <li>
                alien_image: Remember our frame with the alien portrait? We're updating it with a new, resized version of the same image.
                </li>

                <li>
                pygame.transform.scale(): This is our spaceship's resizing tool 🛠️, part of Pygame's transformation utilities. It alters images to our desired dimensions.
                </li>

                <li>
                (alien_image, (60, 60)): Inside the brackets, we provide two pieces of information to the resizing tool:

                <ol>
                    <li>
                    alien_image: This tells the tool which image to resize.
                    </li>

                    <li>
                    (60, 60): These numbers specify our desired dimensions. It's like setting the width and height for a picture frame in our spaceship's gallery.
                    </li>
                </ol>
                </li>

            </ul>

            <h3>
            With this line complete, our extraterrestrial adversaries are now perfectly sized for the cosmic dance ahead. As the galaxy swirls around, our aliens will fit right into the grand tapestry of our game, neither too tiny like distant stars nor too vast like colossal galaxies. Everything in its right place! 🛸🌌🔍
            </h3>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 11: The Hero of the Stars 🌟🚀
            </h2>

            <p>
            Every epic space saga needs a hero, and in our narrative, that hero is represented by our spaceship. This line of code loads the majestic image of our spacecraft from a file named 'player.png' nestled within the 'images' folder. After successfully loading the image, it ensures the spaceship floats seamlessly in the cosmic void by converting any alpha (transparency) channels it might have. Our spaceship, just like our previously loaded aliens, will appear without any jarring square backgrounds, making its journey across the stars a visual treat!
            </p>
            <img src={eleven} className='demo__image' alt='img_eleven' />

            <p>
            Why Are We Using It?
            </p>
            <p>
            While combating extraterrestrial threats is a crucial aspect of our game, the core of our story revolves around our spaceship, the beacon of hope in a vast, unpredictable universe. By loading a distinct and detailed image for our player's spaceship, we offer a visual anchor for our players. It's an embodiment of their presence and actions in this digital cosmos, enhancing immersion and player connection.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                player_image: This variable is our digital frame, showcasing the design of our game's central hero, the spaceship.
                </li>

                <li>
                pygame.image.load(): Once again, we're employing our spaceship's onboard scanner 🖨️, which reads and processes images. This time, it's fetching the 'player.png' file from the 'images' directory.
                </li>

                <li>
                'images/player.png': This path is a precise location that guides our scanner to the image of our heroic spaceship.
                </li>

                <li>
                .convert_alpha(): Just like before, this method ensures that our image appears without awkward edges or backgrounds. This conversion allows the spaceship to glide gracefully against the cosmic tapestry, unmarred by unwanted visual noise.
                </li>
            </ul>


            <h2>
                Download the image.
            </h2>
            <p>
            In order for this line of code to work, you need to download the image and store it in a folder named "images" within your project.
            </p>

            <ol>
                <li>Since you have already created the "images" folder when you downloaded the alien image, we can just download and save the player image.</li>

                <li>Click this <a href={player} download >link</a> to download the player image.</li>

                <li>Save the player image to the "images" folder.</li>
            </ol>
            <h3>
            With these two lines, our player's spaceship isn't just a presence; it's a perfectly sized entity, ready to weave through the challenges of the universe and stand tall against any extraterrestrial threat. Prepare to captain the most finely-tuned vessel in the cosmos! 🚀🌠🛠️
            </h3>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 12: Launching the Intergalactic Warrior 🌌🚀
            </h2>

            <p>
            In this crucial step, we're bringing our player's spaceship to life within the vast universe of our game! We define its location and dimensions, akin to positioning our spaceship at a specific launch pad within a cosmic spaceport.
            </p>
            <img src={twelve} className='demo__image' alt='img_twelve' />

            <p>
            Why Are We Using It?
            </p>
            <p>
            Every story needs a stage, and every hero needs an entrance. In our space odyssey, the player's spaceship is the hero. This line not only brings our ship into the gameplay but also carefully positions it for an optimal gaming experience. By placing our spaceship at the bottom center of the screen, we give players a clear view of the oncoming alien fleet, while also providing ample space to maneuver and engage in starry combat.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                player: This is our spaceship's digital identifier (variable). Think of it as the unique call sign for our vessel amidst the bustling traffic of the cosmic freeway.
                </li>

                <li>
                pygame.Rect(): The universe is vast and filled with wonder, but in the world of Pygame, we use Rect (short for Rectangle) to define the space our game entities occupy. It's like the invisible force field 🛡️ around our spaceship, defining its boundaries and position in the cosmos.
                </li>
            </ul>

            <p>
            Inside pygame.Rect(), we provide four vital pieces of information:
            </p>
            <ol>
                <li>
                screen_width // 2: Our universe (or game screen) has a certain width (screen_width). We want our ship centered, so we place its left edge at half this width. The // ensures that we get a whole number (an integer) for our position, making sure our ship doesn't accidentally end up in another dimension!
                </li>

                <li>
                screen_height - 70: This defines how high or low our spaceship hovers from the base of our game universe. We keep it 70 pixels above the bottom edge, ensuring it's perfectly poised for take-off but still grounded in action.
                </li>

                <li>
                50: The width of our spaceship, consistent with the size we set earlier.
                </li>

                <li>
                50: The height of our spaceship, forming a perfect square as it patrols the starlit expanse.
                </li>
            </ol>

            <p>
            With this line, our spaceship isn't just an image; it's an active entity in our universe, positioned strategically and ready to face challenges head-on. As the captain of this vessel, the player has the vantage point and the agility to dodge, shoot, and dance between the stars. Engage thrusters and commence the space ballet! 🚀🌌🎮 
            </p>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 13: Ready, Aim, Fire! 🔫🌠
            </h2>

            <p>
            In space combat, having a spaceship isn't enough! These lines equip our spacecraft with its main weapon: a powerful bullet to fend off alien invaders. Here's how it unfolds:
            </p>
            <img src={thirteen} className='demo__image' alt='img_thirteen' />
            <ol>
                <li>
                The first line defines our bullet's initial parameters: its position and size.
                </li>

                <li>
                The second line sets up a countdown mechanism, ensuring we're not firing bullets haphazardly into the void. It acts as a control, or a "cooldown" period, between successive bullet shots.
                </li>

                <li>
                    The reason that it is originally set to a value of zero is because after it is fired, we will increase the value. This increased value will countdown during the fire cooldown period.
                </li>
            </ol>

            <p>
            Why Are We Using It?
            </p>
            <ol>
                <li>
                Interactivity: A game without challenges or the means to face them can quickly become dull. By equipping our player's ship with bullets, we introduce an interactive element, allowing players to defend against alien threats.
                </li>

                <li>
                Controlled Shooting: The countdown ensures that players can't simply hold down the fire button and send an unending barrage of bullets. It adds a strategic layer to the game, asking players to time their shots carefully.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                bullet: This is our projectile's moniker, the shining beacon of hope against extraterrestrial foes.
                </li>

                <li>
                pygame.Rect(): Just as we defined our player's boundaries, we do the same for our bullet using the Rect function. It carves out a small slice of the cosmos for our bullet. Inside this cosmic slice, we detail:
                
                    <ul>
                        <li>
                        0: Initially, the bullet's horizontal position (X-axis) is set to the left edge of the screen. But don't worry! When we fire it, it will spawn from our spaceship's position.
                        </li>

                        <li>
                        0: Vertically, the bullet starts at the top edge of our game universe (Y-axis). Again, this will dynamically change as we fire the bullet.
                        </li>

                        <li>
                        5: The bullet's width. It's sleek and fast!
                        </li>

                        <li>
                        10: The bullet's height, making it elongated, optimal for interstellar battles.
                        </li>
                    </ul>
                </li>

                <li>
                bullet_countdown: In space lingo, think of this as our bullet's "recharge time." Initially set to zero, it will act as a timer, counting down to when we can fire the next bullet after shooting one.
                </li>
            </ul>

            <h3>
            These lines ensure that our spaceship is not just a passive observer in this galactic saga. Equipped with the means to defend and engage, players can now take an active role, strategizing, aiming, and firing back against the invaders. Lock and load, space voyager! The universe awaits your valor! 🌌🔫🚀🎖️
            </h3>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 14: Forming the Alien Armada 🛸🌌
            </h2>

            <p>
            In the vastness of space, our player isn't alone. This line introduces the intergalactic antagonists: the alien fleet. It generates an entire squadron of alien ships, each uniquely positioned in formation across our game's horizon, ready to dive and conquer.
            </p>
            <img src={fourteen} className='demo__image' alt='img_fourteen' />
            <img src={fifteen} className='demo__image' alt="img_fifteen" />
            <p>
            Why Are We Using It?
            </p>
            <p>
            Games thrive on challenges and obstacles, and in our space saga, what better challenge than a looming alien invasion? By lining up a fleet of alien ships, we establish the primary goal of our game: fend off these extraterrestrial foes to save our universe!
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                aliens: This is our collection of foes, a list filled with each individual alien ship, hovering menacingly in space.
                </li>

                <li>
                [ ]: These square brackets denote a list in Python. A list can hold multiple items. In our case, each item will be an alien ship, represented by the pygame.Rect function.
                </li>

                <li>
                List Comprehension: The entire line is written in a compact form known as a 'list comprehension'. It's a concise way to create lists in Python. Breaking down the comprehension:
            
                <ul>
                    <li>
                    for i in range(num_aliens): This loop mechanism creates a sequence of numbers from 0 up to (but not including) the value stored in num_aliens. For each of these numbers (represented by i), it creates a new alien ship.
                    </li>

                    <li>
                    pygame.Rect(...): This function carves out a piece of the cosmic canvas for each alien ship.
                    </li>

                    <li>
                    i * (screen_width // num_aliens): This clever formula positions each alien evenly across the screen's width. It multiplies the loop's current number (i) by the space allocated for each alien, ensuring they don't overlap but instead stand shoulder-to-shoulder in their cosmic formation.
                    </li>

                    <li>
                    20: The vertical position of the alien fleet. They start a bit down from the top edge of the screen, giving a clear view of their menacing formation.
                    </li>

                    <li>
                    60: The width of each alien ship, ensuring they're visible and intimidating.                
                    </li>

                    <li>
                    60: The height of each alien ship, maintaining a square shape, making them formidable opponents.
                    </li>
                </ul>
                </li>
            </ul>


            <h3>
            By the end of this line, a full-fledged armada of aliens, based on the number defined in num_aliens, is ready to descend and challenge our player. The stage is set, the adversaries are in place, and the battle for the cosmos is about to begin! Gear up, space warrior! 🌌🛸🔭🚀
            </h3>
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 15: Into the Game Vortex! 🎮
            </h2>

            <p>
            This line commences the eternal loop that keeps our game running. In simpler terms, think of it as a continuously spinning vortex that sucks the player into the exhilarating world of our game. Until an external force (like pressing the close button or meeting a game condition) intervenes, our game will remain inside this loop, creating a persistent, interactive environment.
            </p>
            <img src={sixteen} className='demo__image' alt='img_fifteen' />
            
            <p>
            Why Are We Using It?
            </p>
            <ol>
                <li>
                Game Cycle: In gaming, there's a continuous cycle of taking inputs (like button presses), processing game logic (like movement or scoring), and rendering visuals (like updating the screen). This loop ensures that cycle happens endlessly and smoothly.
                </li>

                <li>
                Interactivity: Games need to run continuously, checking for player inputs, updating positions of game elements, and redrawing everything. By keeping the game in this loop, we ensure a dynamic, interactive environment.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                while: This is a keyword in Python that introduces a loop. It continuously executes the indented code below it as long as a given condition is true. And the condition, in this case, is literally the word True.... therefore it is always true!
                </li>

                <li>
                True: This is a boolean value in Python. A boolean can only be True or False. By setting the condition to True, we're creating an infinite loop since True will always be true!
                </li>
            </ul>

            <p>
            After this line, all the indented code below it (up until a line is no longer indented to the same level) becomes part of the game loop. Each of these operations will be executed frame-by-frame, ensuring the dynamic experience of the game.
            </p>

            <h3>
            This while loop serves as the heartbeat of our game. Like a cosmic drum, it beats rhythmically, maintaining the lively tempo of our intergalactic adventure. Fasten your seatbelts, space explorer! The real journey starts now! 🌌🎮🌀🚀
            </h3>
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 16: Cosmic Event Handling 🌠🎛
            </h2>

            <p>
            This line is where the magic of player interactivity begins! Every button press, mouse movement, or any other action the player takes is detected and processed here. It's as if our game opens up its cosmic antennae, tuning in to the player's desires and whims.
            </p>
            <img src={seventeen} className='demo__image' alt='img_sixteen' />

            <p>
            Why Are We Using It?
            </p>

            <p>
            In our galactic showdown, swift and responsive action is crucial. Whether it's maneuvering the spaceship to dodge incoming threats or launching counter-attacks, our player needs to feel in control. This line ensures that the game is always listening and reacting to the player's commands.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                for: This is a keyword in Python that introduces a loop. The loop goes through each item in a collection, and for each item, it runs the indented code below. Here, it's traversing through each event captured by Pygame.
                </li>

                <li>
                event: This is a temporary name (or variable) given to each individual item as the for loop goes through the collection. Think of it as a placeholder. Each time the loop cycles, event will hold one specific event (like a button press) from the queue of events.
                </li>

                <li>
                pygame.event: This is a special module in Pygame dedicated to event handling. An "event" is any significant moment or action, like pressing a key or moving the mouse.
                </li>

                <li>
                .get(): This function fetches all those events that have occurred since it was last called. Picture it like a net catching all the stars (events) that have twinkled since the last sweep.
                </li>
            </ul>

            <h3>
            In essence, this line tells our game: "For every significant action the player has made, let's process it." As we delve deeper, we'll see how each of these actions is catered to, ensuring our player's commands in this celestial dance are flawlessly executed. Prepare to dive deep into the interactivity of the cosmos! 🌠🎮🎛🌌.
            </h3>
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 17: The Escape Hatch and Cosmic Goodbyes 🚪🌌💫
            </h2>

            <p>
            Even in the midst of the most thrilling space battle, there might come a time when our astronaut wishes to retreat, perhaps for a break or to regroup. These lines of code create an escape hatch from our game. Whenever the player wishes to exit, they can simply click on the close button (typically an "X" at the top corner of a window), and both the game and the program will close gracefully. Ensuring a smooth exit.
            </p>
            <img src={eighteen} className='demo__image' alt='img_seventeen' />

            <p>
            Why Are We Using It?
            </p>

            <ol>
                <li>
                User Friendliness: It's essential to provide players with a smooth way to exit the game whenever they wish. Forcing them to use unconventional methods to quit can be frustrating.
                </li>

                <li>
                Resource Management: Shutting down the game properly ensures that all the game's resources, like memory or sound channels, are correctly released. This prevents potential memory leaks or system crashes.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if: This is Python's way of checking a condition. If the condition inside is met (i.e., it evaluates to True), then the indented code below is executed.
                </li>

                <li>
                event.type: This peeks into the current event we're inspecting from our earlier loop and checks its type, or nature.
                </li>

                <li>
                ==: This is a comparison operator. It's Python's way of asking, "Is the thing on the left the same as the thing on the right?"
                </li>

                <li>
                pygame.QUIT: In Pygame's language, QUIT is the event type that's broadcasted when the player clicks the close button on the game window.
                </li>

                <li>
                pygame.quit(): This function is Pygame's way of tidying up. It stops all Pygame modules and ensures a graceful shutdown.
                </li>

                <li>
                sys.exit(): This is a direct call to the system, telling it to shut down the entire program. It's like saying, "Dear computer, our player wishes to rest. Kindly close down our space opera for now."
                </li>
            </ul>

            <h3>
            Together, these lines ensure that the player always has a clear, easily accessible exit route from the game. While we want them engrossed in our cosmic battle, it's always good space etiquette to let them depart with ease. Until next time, cosmic warrior! 🚪🌌💫🚀.
            </h3>
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 18: The Cosmic Eraser 🌌🖌✨
            </h2>

            <p>
            Imagine each frame of our game as a canvas. As the game progresses, our space scene is bustling with activity – ships moving, lasers firing, aliens descending! But with each new frame (or cycle of our game loop), we need a fresh canvas to redraw our updated scene. This line is our cosmic eraser. It wipes the slate clean, filling our entire game screen with the color black, readying it for a new depiction of our interstellar adventure.
            </p>
            <img src={eighteen} className='demo__image' alt='img_eighteen' />

            <p>
            Why Are We Using It?
            </p>
            <ol>
                <li>
                Continuous Refresh: For smooth gameplay, our game continuously updates – typically many times a second. By clearing the screen at the start of each loop, we ensure that old, outdated visuals don't linger and overlap with the new ones.
                </li>

                <li>
                Visual Clarity: By starting with a clean canvas, we ensure there's no visual clutter. Objects like spaceships and aliens will be clearly visible against the backdrop without ghost images of their past positions.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>
            <ul>
                <li>
                screen: This refers to our game display or the window in which our game plays. We set this up earlier as our canvas where all the action unfolds.
                </li>

                <li>
                .fill(): This is a method (or function) provided by Pygame. It does exactly what it sounds like – fills the entire screen with a specified color.
                </li>

                <li>
                BLACK: This is the color we've chosen for our game's backdrop. As you might recall, we defined this color earlier using a tuple of RGB values: (0, 0, 0), representing the absence of red, green, and blue light, which results in black.
                </li>
            </ul>

            <h3>
            With this line, our game ensures that the action on the screen remains crisp and clear. Like a maestro setting a dark stage before a grand space opera unfolds, our game prepares to dazzle the player with every loop. Lights out, and let the intergalactic drama recommence! 🌌🖌✨🚀.
            </h3>
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 19: Harnessing Intergalactic Energy 🌌🚀🔋
            </h2>

            <p>
            This line is our direct line to the astronaut's (player's) controls. We tap into the intergalactic energy currents, sensing if any keyboard keys are being activated. This code checks the status of all keyboard keys, and if a key is pressed down, it'll remember it!
            </p>
            <img src={twenty} className='demo__image' alt='img_nineteen' />

            <p>
            Why Are We Using It?
            </p>

            <ol>
                <li>
                Real-time Interaction: To navigate the vastness of space and dodge menacing aliens, our astronaut needs to be agile. This line ensures that every subtle command from the player, be it a nudge to the left or a burst to the right, is captured in real-time.
                </li>

                <li>
                Multi-Key Detection: What if our player wishes to move diagonally or perform multiple actions simultaneously? By checking the state of all keys, we're prepared to respond to such multitasking.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>
            <ul>
                <li>
                keys: This is the name we've given to store the current state of all keyboard keys. Once we run this line, keys will be like a map of the keyboard, with indicators for each key's state (pressed or not).
                </li>

                <li>
                pygame.key: This is a module in Pygame specially designed to handle keyboard input.
                </li>

                <li>
                .get_pressed(): This function dives into the cosmic currents and retrieves the state of all keys. For each key on the keyboard, it returns True if the key is pressed and False if it's not.
                </li>
            </ul>

            <h3>
            By executing this line, we have harnessed the energy of the universe (or at least, the energy from the keyboard) to steer our spaceship. The path of the cosmos is treacherous, but with the power of real-time controls, our astronaut is ready to face any challenge! 🌌🚀🔋🎮.
            </h3>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 20: The Spacecraft's Leftward Thrust 🌌🛸⬅️
            </h2>

            <p>
            The moment that the player gently taps or holds down the left arrow key, the spaceship feels the pull of the universe and begins to glide to the left, ensuring it doesn't venture beyond the starlit boundaries of our game screen.
            </p>
            <img src={twentyOne} className='demo__image' alt='img_twenty' />

            <p>
            Why Are We Using It?
            </p>

            <ol>
                <li>
                Responsive Controls: A space adventure is no fun if our spaceship doesn't dance to the tunes of the player. This line ensures the ship responds immediately to the player's command to move left.
                </li>

                <li>
                Safe Navigation: The cosmos is vast, but our game screen has limits. By ensuring our spaceship doesn't drift too far left (beyond the screen's boundary), we're making sure our astronaut always stays in the limelight of our intergalactic stage.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if: This keyword checks a condition. If that condition is true, the code inside the ensuing block (indented lines) will run.
                </li>

                <li>
                keys[pygame.K_LEFT]: This peeks into the map of the keyboard we captured earlier and checks if the left arrow key (pygame.K_LEFT) is pressed. If the player is pressing this key, this portion evaluates to True.
                </li>

                <li>
                and: This is a logical operator. For the entire if condition to be true, both conditions on either side of the and must be true. It's like the cosmos saying, "Both these stars must align for the magic to happen!"
                </li>

                <li>
                player.left: This checks the x-coordinate of the left edge of our spaceship (player's rectangular area) on the screen.
                </li>

                <li>
                &gt; 0: This ensures our spaceship doesn't drift too far left off the screen. The leftmost boundary of our game screen is 0, so as long as the spaceship's left edge is greater than 0, it's still visible on the screen.
                </li>
            </ul>

            <h3>
            So, the moment the universe feels the energy of the left key press and ensures there's still space to drift leftward without disappearing into the abyss, our spacecraft heeds the call and embarks on its leftward voyage. Adventure awaits, but always within the cosmic stage's boundaries! 🌌🛸⬅️🌟.
            </h3>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 21: The Dance of Cosmic Drift 🌠🕺💫
            </h2>

            <p>
            While our previous step sensed the desire of the spaceship to drift leftward, this line is where the magic happens! It propels our spaceship to the left, allowing it to dance gracefully through the cosmic winds at a speed defined by our astronaut (the player).
            </p>
            <img src={twentyTwo} className='demo__image' alt='img_twentyOne' />
            <p>
            Why Are We Using It?
            </p>
            <ol>
                <li>
                Smooth Movement: A space odyssey should feel fluid. By adjusting the position of our spaceship based on a defined speed, we ensure a consistent and smooth movement to the left.
                </li>

                <li>
                Player Agency: It's not just about moving; it's about how fast you move. This line respects the defined speed at which our astronaut wants to navigate the galaxy, providing a tailored experience.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>
            <ul>
                <li>
                player.left: This denotes the x-coordinate of the leftmost edge of our spaceship's position on the screen.
                </li>

                <li>
                -=: This is a compound assignment operator. It's a shorthand way of saying, "Take the current value of player.left and subtract something from it." It's like the galaxy saying, "Drift back, but just a bit!"
                </li>

                <li>
                player_speed: This variable, set up earlier in our code, represents the speed at which our spaceship moves. Think of it as the horsepower of our galactic cruiser. By subtracting this value from player.left, we're moving the spaceship to the left by this speed amount.
                </li>
            </ul>

            <h3>
            With this line, our astronaut's desires are manifesting into action. Each time through our game loop, if the left arrow key is pressed, the spaceship will dance a little more to the left, weaving its narrative in the vast tapestry of the cosmos! 🌠🕺💫🚀.
            </h3>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 22: The Cosmic Drift to the Right 🌠🚀➡️
            </h2>

            <p>
            Just as stars sometimes drift left in the vast expanse of the universe, they also have a yearning to venture rightward. These lines interpret the spaceship's yearning to drift rightward, ensuring that our cosmic cruiser stays within the observable confines of our game universe.
            </p>
            <img src={twentyThree} className='demo__image' alt='img_twentyTwo' />

            <p>
            Why Are We Using Them?
            </p>
            <ol>
                <li>
                Navigational Balance: Our astronaut shouldn't feel confined to just one direction. To balance the leftward drift, this code lets the spaceship navigate right, adding depth to our player's exploration.
                </li>

                <li>
                Safety in Exploration: Just like we ensured the spaceship doesn't vanish left into the unknown void, this code ensures that our spaceship doesn't drift too far right, out of our game screen's view.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if keys[pygame.K_RIGHT]: This taps into our keyboard map, checking if the right arrow key (pygame.K_RIGHT) is pressed. If the player gives the command to move right, this part evaluates to True.
                </li>

                <li>
                player.right: This represents the x-coordinate of the rightmost edge of our spaceship on the game screen.
                </li>

                <li>
                &lt; screen_width: This is our safeguard, ensuring that the rightmost edge of our spaceship doesn't surpass the total width of our game screen.
                </li>

                <li>
                player.left += player_speed: Within the confines of this condition, if all stars align (i.e., the right arrow key is pressed and there's space to move), we glide our spaceship to the right. Here:
                </li>

                <li>
                +=: It’s our cosmic nudge to the spaceship, signaling it to move forward. It's a shorthand of saying, "Add something to the current value of player.left
                </li>

                <li>
                player_speed: Dictates how fast our spaceship moves, ensuring its rightward journey is smooth and consistent.
                </li>
            </ul>

            <h3>
            So, whenever our astronaut wishes, with a gentle nudge to the right arrow key, our spaceship will venture rightward, embracing new horizons but always staying in the spotlight of our game universe! 🌠🚀➡️🌌.
            </h3>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 23: Firing into the Abyss 🌠🚀💥
            </h2>
        
            <p>
            This line sets the stage for an exciting event: firing a cosmic bullet from our spaceship! It checks if the astronaut (player) has sent the command to shoot a bullet and ensures that the spaceship is ready to fire again after a previous shot.
            </p>
            <img src={twentyFour} className='demo__image' alt='img_twentyThree' />

            <p>
            Why Are We Using It?
            </p>
            <ol>
                <li>
                Player Engagement: In our space adventure, firing bullets adds dynamic action and interactivity. This line allows the player to interact with potential threats in the game, creating thrilling moments in the gameplay.
                </li>

                <li>
                Bullet Management: We want our game to have a sense of realism and challenge. By adding a check for bullet_countdown, we prevent the spaceship from firing endlessly, creating a scenario where timing and accuracy matter.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if: This is a conditional check, ensuring certain conditions are met before executing the subsequent lines of code.
                </li>

                <li>
                keys[pygame.K_SPACE]: Here, we're tapping into the command center of our spaceship, checking if the 'space' button (represented by pygame.K_SPACE) is pressed. Think of it as the firing button inside our cosmic cruiser.
                </li>

                <li>
                and: This logical operator ensures that both conditions on its left and right are met. It's the interstellar gatekeeper, ensuring that both the desire to shoot and the readiness to shoot are present.
                </li>

                <li>
                bullet_countdown &lt;= 0: This condition ensures that the spaceship waits for a brief moment (based on the value of bullet_countdown) between firing bullets. The &lt;= 0 part ensures that our spaceship can fire only when it's ready. Think of it as the cooling period of our cosmic blaster. If it's 0 or negative, it's cooled down and ready to fire!
                </li>
            </ul>

            <h3>
            With this line, our space adventure becomes a thrilling ballet of navigation and action. The infinite cosmos now holds potential challenges that our astronaut can engage with, firing cosmic bullets and carving their path in the vast universe! 🌠🚀💥🪐.
            </h3>
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 24: Galactic Artillery Preparations 🌌🚀🔥
            </h2>

            <p>
            This line is where the spaceship confirms its intent to release a bullet into the vastness of space. By setting bullet_fired to True, the spaceship signals that a cosmic projectile is now on its journey.
            </p>
            <img src={twentyFive} className='demo__image' alt='img_twentyFour' />

            <p>
            Why Are We Using It?
            </p>
            <ol>
                <li>
                Projectile Management: Throughout our space odyssey, there will be moments when bullets are in-flight and moments of stillness. This line helps manage that by signaling when a bullet has been launched.
                </li>

                <li>
                Visual Feedback: For our astronaut (player) to truly feel the impact of their actions, they need to see the bullet shoot out from the spaceship. By activating bullet_fired, subsequent lines in the code will display this projectile, adding thrill to the gameplay.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                bullet_fired: This is a variable we introduced earlier in our cosmic saga. It's like the spaceship's internal status light for its artillery. Initially set to False, it means no bullets are active.
                </li>

                <li>
                =: This is the assignment operator. Like a command issued from the spaceship's control panel, it updates the status of the specified variable.
                </li>

                <li>
                True: In the world of code, there's a duality - True and False. Here, True represents a state of affirmation. When we set bullet_fired to True, we're telling our game, "Yes, a bullet has indeed been fired!"
                </li>
            </ul>

            <h3>
            With this affirmation, the spaceship is not just passively drifting through space anymore. It's actively engaging with its surroundings, ready to interact with any interstellar challenges that might arise. Our journey through the cosmos just got a lot more exciting! 🌌🚀🔥🌠. 
            </h3>
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 25: Locking on Target 🚀🎯💫
            </h2>

            <p>
            These lines determine the initial position of the bullet once it's fired. They ensure that the bullet appears right at the spaceship's front, centered and ready to journey upwards. Furthermore, we initiate a countdown to manage the time between consecutive bullet firings.
            </p>
            <img src={twentySix} className='demo__image' alt='img_twentyFive' />

            <p>
            Why Are We Using Them?
            </p>
            <ol>
                <li>
                Intuitive Bullet Positioning: To ensure that the gameplay feels natural, bullets should appear as if they're being fired from the center of the spaceship. This positioning helps in player immersion and the overall space battle experience.
                </li>

                <li>
                Bullet Cooldown: By introducing a countdown, we make sure that the player can't endlessly spam bullets. This adds a layer of strategy and realism to our interstellar adventure.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                bullet.left and bullet.top: These attributes set the horizontal (left) and vertical (top) positions of the bullet, respectively. bullet.left is where the left edge of the bullet will be, and bullet.top is where the top edge will be.
                </li>

                <li>
                player.left and player.top: These refer to the current horizontal and vertical positions of our spaceship (the player).
                </li>

                <li>
                player.width and bullet.width: These give us the width of the spaceship and the bullet, respectively.
                </li>

                <li>
                // 2: This operation divides the value by 2 and rounds down to the nearest whole number. It's used here to find the middle of the spaceship and the bullet.
                </li>

                <li>
                - bullet.width // 2: This ensures that the bullet is centered relative to the spaceship.
                </li>

                <li>
                bullet_countdown = 10: We're setting the countdown timer for the bullet to 10. This acts as a cooldown mechanism so that there's a small gap between each bullet being fired.
                </li>
            </ul>

            <p>
            Let's envision this process in the vast cosmos:
            </p>
            <p>
            When the spaceship's captain (our player) decides to fire, the artillery team onboard doesn't just randomly shoot. They carefully position the bullet at the spaceship's front, ensuring it's aligned with the center. As the bullet zooms upwards, a timer is set, ensuring that the spaceship's artillery has a moment to cool down before the next round.
            </p>

            <h3>
            Through these lines, our space adventure runs with dynamism, echoing the rhythms of a cosmic dance where precision meets action! 🚀🎯💫🌌.
            </h3>
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 26: The Bullet's Galactic Launch Condition 🌌
            </h2>

            <p>
            This line checks whether the bullet has been fired or not. It serves as a condition: if the bullet has been fired (bullet_fired is True), then the subsequent indented lines of code beneath this condition will be executed.
            </p>
            <img src={twentySeven} className='demo__image' alt='img_twentySix' />

            <p>
            Why Are We Using It?
            </p>

            <ol>
                <li>
                Efficiency in Gameplay: We only want the bullet to move if it has been fired. Without this check, we might inadvertently execute unnecessary operations, making our game less efficient and possibly causing unintended behavior.
                </li>

                <li>
                Player's Action Reflection: This condition reflects the player's decision in the game world. When they decide to fire, this condition helps translate that action into a visual movement on the screen.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if: This keyword introduces a conditional statement in Python. The code that's indented below the if statement will only run if the condition provided is True.
                </li>

                <li>
                bullet_fired: This is a Boolean variable. It can have only two values: True or False. In our game, it acts as a switch: True when the bullet is in motion and False when it's not.
                </li>
            </ul>

            <h3>
            Imagine the vastness of space where countless stars twinkle, and amidst this, our spaceship waits, its artillery loaded. The player, in the captain's seat, makes a choice - to fire or not. The moment they decide to unleash a bullet, this line of code listens. It hears the player's command and readies the game's universe to animate the bullet's journey. If the bullet isn't fired, the cosmos remains calm, but if it's set in motion, the bullet begins its fierce ascent, racing against starlight. 🌌🚀🌠.
            </h3>
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 27: Starship Bullet's Ascent 🚀✨
            </h2>

            <p>
            This line adjusts the vertical position of the bullet by subtracting the bullet_speed value from the bullet.top attribute. The result is that the bullet moves upwards on the screen.
            </p>
            <img src={twentyEight} className='demo__image' alt='img_twentySeven' />
            <p>
            Why Are We Using It?
            </p>

            <ol>
                <li>
                Motion in Space: Just as a real bullet would move upwards when fired from a gun, our spaceship's bullet in the game needs to ascend. This line ensures that the bullet rises, creating a realistic and engaging shooting action for the player.
                </li>

                <li>
                Chasing the Invaders: To fend off the space invaders, our bullet must reach them. Its upward motion ensures that it can potentially collide with the invaders, offering the player a chance to score and protect their territory.
                </li>

                <li>
                Creating Challenge: The faster the bullet, the easier it might be to hit targets, but if invaders are also fast, it creates a dynamic challenge. By controlling bullet_speed, the game's difficulty can be adjusted.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                bullet.top: Refers to the vertical position (specifically, the top edge) of our bullet in the game window. The topmost edge of the game window has a value of 0, and as you go down the screen, this value increases.
                </li>

                <li>
                -=: This is a compound assignment operator. It's shorthand for bullet.top = bullet.top - bullet_speed. It means "subtract the value on the right (bullet_speed) from the current value of the variable on the left (bullet.top) and then assign the result back to the variable on the left."
                </li>

                <li>
                bullet_speed: Represents how fast the bullet travels. The higher this value, the more distance the bullet covers in a single frame of the game.
                </li>
            </ul>

            <h3>
            In the vast expanse of space, our bullet surges upwards, blazing a trail like a shooting star. Powered by our game's engine (code), it seeks out the invaders, defending our virtual world from potential harm. And just like that, with this line of code, the chase is on! 🚀🌌👾.
            </h3>
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 28: Galactic Guardrails - Keeping Bullets in Check! 🌌🚫
            </h2>

            <p>
            These lines work together to check if the bullet has moved beyond the top boundary of the game screen and, if so, reset its status to indicate it's no longer in flight.
            </p>
            <img src={twentyNine} className='demo__image' alt='img_twentyEight' />

            <p>
            Why Are We Using Them?
            </p>
            <ol>
                <li>
                Guarding the Universe's Borders: In our game's universe, the screen's top is the boundary beyond which bullets shouldn't travel. This check ensures our bullet doesn't keep flying indefinitely into the cosmic abyss!
                </li>

                <li>
                Preparing for the Next Launch: By determining that the bullet is no longer active (once it goes off-screen), the game gets ready for the player to fire the next shot. It's a cycle of fire, check, reset - maintaining the game's rhythm and continuity.
                </li>

                <li>
                Efficient Game Mechanics: Continually tracking an object (like a bullet) that the player no longer sees or interacts with can be inefficient. By setting bullet_fired to False, the game's logic avoids unnecessary calculations related to that bullet, ensuring smoother gameplay.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if bullet.top &lt; 0: This is an if-statement, which checks a condition. If the condition is true, the code block that follows (indented under the if) will execute.
                </li>

                <li>
                bullet.top: Refers to the vertical position (the top edge) of our bullet. In screen coordinates, the top-most part has a value of 0.
                </li>

                <li>
                &lt;: This is the "less than" comparison operator. It checks if the value on the left (bullet.top) is smaller than the value on the right (0).
                </li>

                <li>
                bullet_fired = False: This line is within the if statement's code block, meaning it'll only run if the bullet's top has gone above the screen's top boundary. It sets the bullet_fired variable to False, indicating that the bullet is no longer active.
                </li>
            </ul>

            <h3>
            To visualize, think of our game screen as a cosmic fish tank, and the bullet as a fish. When the fish leaps above the water's surface, it must come back down. Similarly, once our bullet surpasses the screen's boundary, the game recognizes it needs to reset and get ready for the next vibrant leap (or shot) in our space aquarium! 🐟🌌🚀.
            </h3>
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 29: Alien Intrusion – Adjusting the Course! 👾🔄
            </h2>

            <p>
            These lines iterate through each alien in our aliens list, moving them horizontally across the screen based on the alien_speed.
            </p>
            <img src={thirty} className='demo__image' alt='img_twentyNine' />

            <p>
            Why Are We Using Them?
            </p>

            <ol>
                <li>
                Galactic Movement: Just as stars and planets move in space, our game's aliens need motion to make the gameplay challenging and engaging. This piece of code ensures every alien shifts position, creating the feeling of a dynamic, moving invasion.
                </li>

                <li>
                Strategy: The game becomes more complex as aliens move, requiring the player to time their shots and predict alien movement. By adjusting alien_speed, we can determine the pace and challenge of the game.
                </li>

                <li>
                Unpredictability: The speed and direction of the aliens' movement can change (as seen in subsequent lines of the code). The continuous adjustment of each alien's position, based on alien_speed, provides a dynamic, unpredictable pattern, essential for keeping the player engaged.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                for alien in aliens: This is a for loop, a fundamental programming construct that iterates over each item in a list. Here, it processes each alien object in the aliens list one by one.
                </li>

                <li>
                alien: This is a temporary variable that represents the current alien being processed in each loop cycle.
                </li>

                <li>
                aliens: This is our list containing all the alien objects currently in the game.
                </li>

                <li>
                alien.left += alien_speed: This line adjusts the horizontal position of the current alien.
                </li>

                <li>
                alien.left: Refers to the horizontal position (specifically, the left edge) of the current alien in the game window.
                </li>

                <li>
                +=: This compound assignment operator adds the value on its right (alien_speed) to the current value of the variable on its left (alien.left) and then assigns the result back to the variable on the left.
                </li>

                <li>
                alien_speed: It determines how fast the aliens travel horizontally. If it's positive, they move to the right; if negative, they move to the left.
                </li>
            </ul>

            <h3>
            Picture a cosmic dance in the vastness of space. Each alien, floating in the void, moves to the rhythm of the cosmic winds (or in our case, the alien_speed). As they glide gracefully across the screen, our player must strategically react, ensuring Earth's safety in this intergalactic ballet! 🪐💫👾.
            </h3>
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 30: Galactic Edge Detection – Changing Course! 🌌🔄
            </h2>

            <p>
            This line checks if the current alien (from the loop in the previous step) has hit the left or the right boundary of our game screen.
            </p>
            <img src={thirtyOne} className='demo__image' alt='img_thirty' />

            <p>
            Why Are We Using It?
            </p>

            <ol>
                <li>
                Edge Detection: In space, there are no walls. But on our game screen, we need to keep the aliens from wandering off into the digital void. This line checks if an alien has reached the edge and needs to change its direction.
                </li>

                <li>
                Dynamic Gameplay: It's more challenging and fun for the player if the aliens can bounce back from the screen's edge rather than disappearing. By detecting when the aliens hit an edge, we can make them change direction and keep the player on their toes.
                </li>

                <li>
                Preventing Screen Overflow: We want to ensure that game entities, like aliens, don't drift outside of the intended gameplay area. This adds polish to the game and ensures a consistent player experience.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if: The beginning of a conditional statement that checks the truthiness of the condition that follows it. If the condition is True, the code block (indented lines under the if) will be executed.
                </li>

                <li>
                alien.left &lt;= 0: This condition checks if the left side (alien.left) of the alien has moved beyond or is at the left edge of the screen (0).
                </li>

                <li>
                &lt;=: This is the "less than or equal to" comparison operator. It returns True if the value on the left is less than or equal to the value on the right.
                </li>

                <li>
                or: A logical operator that combines two conditions. If either of the conditions (or both) is True, then the whole combined condition becomes True.
                </li>

                <li>
                alien.right &gt;= screen_width: This condition checks if the right side (alien.right) of the alien has moved beyond or is at the right edge of the screen (screen_width).
                </li>

                <li>
                &gt;=: This is the "greater than or equal to" comparison operator. It returns True if the value on the left is greater than or equal to the value on the right.
                </li>
            </ul>

            <h3>
            Imagine space as an infinite canvas, but our game screen as a finite window into that canvas. Like birds in an aviary, the aliens glide to the edges, but instead of being confined, they can change direction and continue their cosmic dance, maintaining the balance and challenge of the game. 🌌🛸🔄.
            </h3>
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 31: The Intergalactic U-turn 🌌🔄
            </h2>

            <p>
            This line reverses the direction of movement for all the aliens by inverting the value of alien_speed.
            </p>
            <img src={thirtyTwo} className='demo__image' alt='img_thirtyOne' />

            <p>
            Why Are We Using It?
            </p>
            <ol>
                <li>
                Cosmic Course Correction: Once an alien hits the edge of the screen, instead of moving off-screen and disappearing, it's more engaging to have it (and its fellow invaders) turn around and continue their approach from the opposite direction. This line ensures that the alien horde shifts its direction seamlessly.
                </li>

                <li>
                Ratcheting Up the Tension: By making the aliens change direction when they hit the edge, the player is kept engaged, always needing to be alert to the aliens' movements. This ensures that the challenge remains consistent throughout gameplay.
                </li>

                <li>
                Economy of Code: Instead of writing extensive logic to move the aliens back to their original position or spawning new aliens, we can elegantly change the direction of all aliens by simply negating the speed value.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                alien_speed: This is our variable that determines the speed and direction in which the aliens move horizontally. A positive value moves them to the right, and a negative value moves them to the left.
                </li>

                <li>
                =: This is the assignment operator. It takes the value on its right and assigns (or updates) it to the variable on its left.
                </li>

                <li>
                -alien_speed: This operation inverts the current value of alien_speed. If it was positive (indicating movement to the right), it becomes negative (indicating movement to the left), and vice versa. Essentially, this toggles the direction of movement.
                </li>
            </ul>

            <h3>
            Imagine being in space and watching a squadron of UFOs 🛸 approaching Earth. Just as they seem to be veering off course and leaving the vicinity, they suddenly U-turn, regrouping and coming back even stronger! This line of code gives the aliens that unpredictable and persistent nature, ensuring the player never lets their guard down. 🌌🚀🔄.
            </h3>
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 32: Descend and Regroup! 🌌⬇️👾
            </h2>

            <p>
            This code moves each alien downward by 20 pixels when at least one alien hits either the left or the right edge of the screen. After adjusting the position of all aliens, it breaks out of the outer alien movement loop.
            </p>
            <img src={thirtyThree} className='demo__image' alt='img_thirtyTwo' />

            <p>
            Why Are We Using It?
            </p>

            <ol>
                <li>
                Intensify the Challenge: It's not just side-to-side movement that the player has to watch out for. As the aliens descend, they get closer to the player, increasing the game's tension and urgency.
                </li>

                <li>
                Classic Gameplay Mechanic: This behavior mimics classic "Space Invaders" gameplay, where aliens not only moved horizontally but also descended towards the player over time.
                </li>

                <li>
                Avoid Infinite Descent: By using a break statement, we make sure that once an alien triggers a descent, the rest of the horizontal movement checks are skipped for that frame. Without this, if multiple aliens hit the screen edge simultaneously, the descent would be triggered multiple times, causing erratic behavior.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                for a in aliens: This initiates a loop where each individual alien in the aliens list is temporarily referred to by the variable a. The subsequent indented code will be executed for each alien in the list.
                </li>

                <li>
                a.top += 20: This line shifts the vertical position (top attribute) of the current alien (a) downward by 20 pixels.
                </li>

                <li>
                +=: This is a shorthand assignment operator. It's equivalent to a.top = a.top + 20. It updates the value of a.top by adding 20 to it.
                </li>

                <li>
                break: This is a control statement that, when executed inside a loop, immediately stops the loop's current iteration and exits the loop.
                </li>
            </ul>


            <h3>
            By bringing this mechanic into our space game, we create moments of escalating tension. Just when players feel they've got the rhythm of dodging side-to-side movements, the aliens descend a step closer, forcing the player to recalibrate their strategy and defense. It's like watching a meteor shower 🌠 where the meteors suddenly change trajectory, keeping stargazers on their toes! 🌌👾⬇️.
            </h3>
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 33: Alien Onslaught - Collision Check! 💥👾
            </h2>

            <p>
            This block of code initiates a loop that checks each alien in the aliens list to see if the player's bullet intersects with it (i.e., the bullet and the alien are overlapping or colliding). This collision check only happens if a bullet has been fired (bullet_fired is True).
            </p>
            <img src={thirtyFour} className='demo__image' alt='img_thirtyThree' />

            <p>
            Why Are We Using It?
            </p>

            <ol>
                <li>
                Dynamic Interactions: The core gameplay mechanic in many shooting games, including "Space Invaders", is the ability to eliminate threats (in this case, aliens) by shooting them. This code facilitates that by detecting when a bullet hits an alien.
                </li>

                <li>
                Increasing Engagement: By giving feedback (in this case, detecting and responding to collisions), players get a sense of interaction and satisfaction, making the game more immersive and rewarding.
                </li>

                <li>
                Efficient Checks: By incorporating the bullet_fired condition, the code avoids unnecessary collision checks when there's no bullet on screen, thus optimizing the game loop's performance.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                for alien in aliens: This initiates a loop where each individual alien in the aliens list is temporarily referred to by the variable alien. The subsequent indented code will be executed for each alien in the list.
                </li>

                <li>
                if bullet.colliderect(alien): The colliderect method is a Pygame function that checks if two rectangles (in this case, our bullet and the current alien) are intersecting or overlapping. If they are, it returns True.
                </li>

                <li>
                and bullet_fired: The and keyword is a logical operator that ensures both conditions (on its left and right) are True. So, for the collision to be acknowledged, the bullet must be colliding with the alien and the bullet must have been fired (i.e., it's currently moving on the screen).
                </li>
            </ul>

            <h3>
            Imagine being the commander of a space fleet, watching as the pulsating energy bullets from your starfighters engage with the alien swarm. Every hit, every collision, needs to be detected to strategize the next move. This code does exactly that, acting as the sensors on our cosmic battlefield, ensuring that no hit goes unnoticed! 🚀💥👾.
            </h3>
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 34: Victory Points - Score the Hit! 🌌✨🎯
            </h2>

            <img src={thirtyFive} className='demo__image' alt='img_thirtyFour' />

            <p>
            Upon confirming a collision between a bullet and an alien, this code:  
            </p>

            <ol>
                <li>
                Removes the alien from the aliens list, essentially destroying it in the game.
                </li>

                <li>
                Changes the bullet_fired status to False, signifying that the bullet is no longer active on the screen.
                </li>

                <li>
                Increases the player's score by 10 points, rewarding them for their successful shot!
                </li>
            </ol>

            <p>
            Why Are We Using It?
            </p>


            <ol>
                <li>
                Immediate Feedback: Destroying the alien upon impact offers immediate feedback to the player, reinforcing their success and maintaining the flow of the game.
                </li>

                <li>
                Game Mechanics: Deactivating the bullet ensures that one bullet doesn't eliminate multiple aliens in one go (unless that's a feature you'd want). It's a way of keeping the game balanced and challenging.
                </li>

                <li>
                Rewards & Motivation: Keeping score is a crucial part of any game. By awarding points for hitting targets, players are motivated to improve their skills and reach higher scores.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                aliens.remove(alien): The remove method is applied to lists in Python and, as the name implies, it removes a specified item from the list. Here, the alien that was hit by the bullet is removed from the aliens list.
                </li>

                <li>
                bullet_fired = False: This resets the state of the bullet. The bullet is no longer considered as "fired" or "active" on the screen.
                </li>

                <li>
                score += 10: This is shorthand for score = score + 10. It adds 10 to the current value of the score. It's our way of saying, "Great shot! Here's your reward!"
                </li>
            </ul>

            <h3>
            Picture this: Amidst the backdrop of the cosmos, every alien ship hit results in a dazzling explosion. Not only does the battlefield clear up a bit, but the onboard computer also tallies up points, cheering the player on! It's these moments of victory, no matter how small, that keep the adrenaline pumping and fingers tapping! 🚀🎉🕹
            </h3>
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 35: Game Over? Player-Alien Collision! 🌌🚀💥
            </h2>

            <p>
            This portion of the code performs two significant checks for each alien:<br/><br/>It checks if an alien has collided with the player's ship.<br/><br/>It verifies if any alien has reached the bottom of the screen, right above the player.<br/><br/>If either of these conditions is true, it indicates a potentially game-ending scenario!
            </p>
            <img src={thirtySix} className='demo__image' alt='img_thirtyFive' />

            <p>
            Why Are We Using It?
            </p>

            <ol>
                <li>
                Game Mechanics: The player should avoid colliding with aliens. A collision typically represents a life loss or game over in many classic games.
                </li>

                <li>
                Increasing Difficulty: Aliens reaching the bottom of the screen can also be a condition for game over. This feature ensures players are actively trying to eliminate the aliens before they get too close. It ramps up the tension and requires the player to strategize their moves and shots.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                for alien in aliens: This is a loop that goes through each alien in the aliens list, allowing us to check individual aliens for specific conditions.
                </li>

                <li>
                alien.colliderect(player): The colliderect method checks if two rectangular shapes (like our player and aliens) overlap or collide in the Pygame environment. If they do, this method returns True.
                </li>

                <li>
                alien.top &gt;= screen_height - player.height: This condition checks the position of the top edge of the alien. If the position is greater than or equal to the height of the screen minus the height of the player, it means the alien is right above the player and almost at the bottom.
                </li>
            </ul>

            <h3>
            Imagine the suspense! As the player's spaceship weaves between falling asteroids and blasts away at alien ships, there's always the lurking danger of a direct collision or an alien sneaking past defenses to the planet below. These lines ensure that players remain on their toes, ever vigilant of both the skies and the ground beneath! 🌍🛸🔥.
            </h3>
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 36: The Ultimate Farewell - Game Over Mechanics! 🌌🚀🔚
            </h2>

            <p>
            These lines of code serve a dire purpose: when executed, they will terminate the game, effectively marking it as "Game Over" for the player.
            </p>
            <img src={thirtySeven} className='demo__image' alt='img_thirtySix' />

            <p>
            What Does This Code Do?
            </p>
            <ol>
                <li>
                pygame.quit(): This function is called to uninitialize all pygame modules that have been initialized. Essentially, it cleans up and prepares to close the game window.
                </li>

                <li>
                sys.exit(): This function, provided by the sys module, will exit the game entirely, stopping the Python script from running any further.
                </li>
            </ol>

            <p>
            Why Are We Using It?
            </p>

            <ol>
                <li>
                Game Flow: Just as games have starting points, they also need endpoints. This can be a consequence of the player running out of lives, an enemy reaching a critical area, or some other condition integral to the gameplay. When such a critical event occurs, it's essential for the game to have a mechanism to end and notify the player.
                </li>

                <li>
                Feedback and Consequences: By having tangible consequences for certain in-game actions (like collisions with enemies), players feel more engaged and the stakes of the gameplay feel more real.
                </li>

                <li>
                Resource Management: It's always good practice to release resources when they're no longer needed. The pygame.quit() ensures that any resources or modules initialized by pygame are properly closed.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                pygame.quit(): This is a built-in method from the pygame library. Once called, it'll deinitialize or "shut down" all pygame modules. Think of it like turning off the engines and systems of your spaceship before exiting.
                </li>

                <li>
                sys.exit(): The sys module provides access to some variables and functions used or maintained by the interpreter; one of them is the exit() function. When called, it raises an exception (SystemExit) that, unless caught, will prompt the Python script to stop its execution. Imagine this like the spaceship's self-destruct button - once pressed, there's no going back!
                </li>
            </ul>

            <h3>
            So, in the vastness of the cosmic battlefield, when these lines run, it's the universe's way of saying, "It's over, brave pilot." Perhaps the player's ship collided with one too many enemy crafts, or the aliens successfully breached Earth's last line of defense. Either way, the screen fades, and the game ends, awaiting the player's next attempt to conquer the stars! 🌌🎮🔚.
            </h3>
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 37: Reload and Restart! 🔄🌟
            </h2>

            <img src={thirtyEight} className='demo__image' alt='img_thirtySeven' />

            <p>
            This line checks if two conditions are met simultaneously:
            </p>
            <ol>
                <li>
                Whether the number of aliens (enemies) on the screen is zero. 
                </li>

                <li>
                Whether the current level the player is on is less than the maximum number of levels set in the game.
                </li>
            </ol>

            <p>
            In simpler terms, it's like asking: "Have all the aliens been defeated? And, is there another level to advance to?"
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                len(aliens) == 0:
                </li>

                <li>
                len(): This is a built-in Python function that returns the number of items in a container (like a list).
                </li>

                <li>
                aliens: This is our list containing all the alien enemy instances.
                </li>

                <li>
                and: This is a logical operator in Python. It's used to combine conditional statements. For the overall condition to be true, both conditions on either side of and must be true.
                </li>

                <li>
                level &lt; max_levels:
                </li>

                <li>
                level: This variable keeps track of the current level the player is on.
                </li>

                <li>
                &lt;: This is a comparison operator in Python. It returns True if the value on the left is less than the value on the right.
                </li>

                <li>
                max_levels: This variable is set to the maximum number of levels in the game. It acts as a cap to ensure the game doesn't progress beyond its designed content.
                </li>
            </ul>

            <h3>
            Combining it all, this line of code is like a cosmic gatekeeper, ensuring that the player only embarks to the next star system (or level) once they've successfully defended the current one from alien invaders. Should they clear all levels, they've successfully defended the universe... until they decide to play again! 🌌🚀🔓.
            </h3>
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 38: Leveling Up in the Galactic Battle! 🚀🆙
            </h2>

            <p>
            These two lines are the interstellar rewards for defending a sector of the universe! When a player successfully defeats all aliens in the current level:
            </p>
            <img src={thirtyNine} className='demo__image' alt='img_thirtyEight' />

            <ol>
                <li>
                level += 1: This boosts the player to the next level.
                </li>

                <li>
                num_aliens += 2: This increases the number of aliens by 2 in the subsequent level, making the game more challenging. 
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                level += 1:
                </li>

                <li>
                level: This variable keeps track of the current game level.
                </li>

                <li>
                +=: This is a shorthand operator in Python. It's a concise way of saying "take the current value of the variable on the left, add the number on the right to it, and then assign this new value back to the variable on the left."
                </li>

                <li>
                1: Represents the value to be added to the current level, advancing the player to the next level.
                </li>

                <li>
                num_aliens += 2:
                </li>

                <li>
                num_aliens: This variable maintains the number of aliens on screen.
                </li>

                <li>
                +=: Again, this shorthand operator tells Python to increase the current number of aliens.
                </li>

                <li>
                2: Indicates that two more aliens will join the invasion force in the next level.
                </li>
            </ul>

            <h3>
            In the vastness of space, as a player levels up, they will find that the universe is filled with more challenges than they might have initially thought. The increased number of aliens means the cosmos gets busier, and our brave defender must be ready for intensified cosmic battles. Keep your lasers charged and your reflexes sharp! 🌌👾
            </h3>
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 39: Assembling the New Alien Fleet! 🌌🛸
            </h2>

            <p>
            In the great expanse of the cosmos, a new wave of aliens is being summoned to challenge our player. This line of code creates a list of alien invaders. 
            </p>
            <img src={forty} className='demo__image' alt='img_thirtyNine' />

            <p>
            Here's what's happening:
            </p>
            <ul>
                <li>
                A list comprehension is used to generate num_aliens number of alien rectangles.
                </li>

                <li>
                Each alien rectangle (pygame.Rect) is positioned horizontally across the screen based on its index multiplied by the space allocated for each alien (screen_width // num_aliens).
                </li>

                <li>
                All aliens are initially positioned 20 pixels from the top of the screen.
                </li>

                <li>
                The dimensions of each alien are set to be 60 pixels by 60 pixels.
                </li>
            </ul>

            <p>
            Why Are We Using This Line?
            </p>
            
            <p>
            As our player advances in their galactic journey, newer challenges (or in this case, aliens) must be introduced. By creating a fresh batch of alien invaders every time a level is completed, the game provides continuity, a sense of progression, and a renewed challenge.
            </p>
            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                list comprehension: This is a concise way in Python to create lists. The format is [expression for item in iterable].
                </li>

                <li>
                pygame.Rect: A function in the pygame library that represents rectangular areas. It takes in four parameters:
                <ul>
                    <li>
                    x-coordinate (i * (screen_width // num_aliens)): This positions the alien's starting point horizontally. It ensures that the aliens are spread evenly across the screen width. By multiplying the index i with the width allocated for each alien, we get the starting x-coordinate for each alien.
                    </li>

                    <li>
                    y-coordinate (20): The vertical position of the alien. All aliens begin 20 pixels from the top.
                    </li>

                    <li>
                    width (60): The width of the alien rectangle.
                    </li>

                    <li>
                    height (60): The height of the alien rectangle.
                    </li>
                </ul>
                </li>

                <li>
                for i in range(num_aliens): This iterates over a sequence of numbers from 0 to num_aliens - 1. The total number of iterations is equal to the number of aliens we want, which was increased by 2 in the previous level.
                </li>
            </ul>


            <h3>
            So, as the battle continues and our defender successfully clears a wave of alien invaders, the universe responds by sending an even bigger fleet. It's the universe's way of saying, "Impressive, but can you handle this?" 🌠🚀
            </h3>
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 40: Resetting the Stage 🌌🚀
            </h2>

            <p>
            In this interstellar journey, every time our intrepid space defender conquers an alien fleet, they're given a brief respite to regroup. These lines ensure that as the new wave of aliens approaches:
            </p>
            <img src={fortyOne} className='demo__image' alt='img_forty' />

            <ol>
                <li>
                bullet_fired = False: The bullet, our primary defense mechanism, is reset to its unfired state. Think of this as the player's weapon recharging.
                </li>

                <li>
                bullet_countdown = 0: The countdown for the bullet, which restricts rapid-fire shooting, is reset, allowing the player to start fresh with the next alien wave.
                </li>
            </ol>

            <p>
            Why Are We Using These Lines?
            </p>

            <ol>
                <li>
                Safety First! 🛡️: By setting bullet_fired to False, we're ensuring that there isn't a bullet already whizzing through space when the next wave of aliens arrives. It's the game's way of saying, "Hold your fire and prepare for the next round!"
                </li>

                <li>
                Ready, Aim, Fire: The bullet_countdown ensures that the player can't shoot bullets continuously, making the game challenging. Resetting this countdown means the player is primed and ready to fire right away when the new threat appears.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                bullet_fired = False:
                </li>

                <li>
                bullet_fired: A variable we've encountered before. It keeps track of whether a bullet is currently active on the screen.
                </li>

                <li>
                False: This is a Boolean value, indicating a state of "No" or "Off". Here, it signals that no bullet is currently in motion.
                </li>

                <li>
                bullet_countdown = 0:
                </li>

                <li>
                bullet_countdown: A variable that acts as a timer. When the player fires a bullet, this countdown starts, preventing the player from firing again immediately.
                </li>

                <li>
                0: Resets the timer. This ensures that there's no waiting time for the next bullet after completing a level.
                </li>
            </ol>

            <h3>
            Together, these lines help maintain game balance. The player can't start a new level with bullets already flying, but they're immediately ready to defend against the new set of aliens. It's a momentary pause, a deep breath before plunging back into the exhilarating world of space combat. 🌠🚀👾
            </h3>
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 41: Drawing our Brave Space Defender 🚀✨
            </h2>

            <p>
            Amidst the vastness of space, our valiant spaceship stands ready to fend off the alien onslaught. This line of code is responsible for placing our player's spaceship (represented by the image) onto the game screen at the specified position. In short, it's like pinning a star onto our space canvas.
            </p>
            <img src={fortyTwo} className='demo__image' alt='img_fortyOne' />
            <p>
            Why Are We Using This Line?
            </p>
            <ol>
                <li>
                Visibility: Games are visual experiences. Our player needs to see their spaceship on the screen to navigate and strategize.
                </li>

                <li>
                Interaction: By drawing the player on the screen, they get a reference point for movement, shooting, and evading alien attacks. Think of it as setting the stage for our space opera.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                screen: The main surface where all game elements get displayed. Think of it as our cosmic canvas or the "stage" for our space drama.
                </li>

                <li>
                blit: A method in Pygame that stands for "Block Image Transfer". It's the brushstroke of our digital painting, responsible for drawing (or copying) one image onto another surface.
                </li>

                <li>
                The blit method needs two main arguments: the image we want to draw and where we want to draw it.
                </li>

                <li>
                player_image: Represents the visual aspect of our spaceship, an image loaded earlier in the code. This is the very essence of our spaceship, the avatar that the player sees and relates to in our space narrative.
                </li>

                <li>
                player: This is a Rect (rectangle) object, defining our player's position and dimensions. The image will be placed where this rectangle is located on the screen.
                </li>
            </ol>

            <h3>
            By using the blit function, we're pinning the player's spaceship onto our cosmic canvas, allowing the player to embark on their mission of defending the galaxy. It's as if the curtains have been lifted, and our protagonist has entered the stage, ready for action. 🎭🌌🚀
            </h3>
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 42: Charging the Lasers and Firing the Cannons! 🚀🔥🛸
            </h2>

            <p>
            Whenever our intrepid spaceship pilot taps into their courage and fires a laser to fend off alien intruders, these lines spring into action. They check if a bullet has been fired (bullet_fired is True), and if so, they draw a vivid RED laser bullet on our cosmic canvas.
            </p>
            <img src={fortyThree} className='demo__image' alt='img_fortyTwo' />

            <p>
            Why Are We Using These Lines?
            </p>

            <ol>
                <li>
                Feedback: The player should be able to see the lasers they fire. It not only offers a sense of control but also a pulse-pounding excitement every time they take a shot.
                </li>

                <li>
                Dynamics: Drawing the bullet dynamically based on player actions ensures the game feels interactive and responsive.
                </li>

                <li>
                Conflict: This line sets the stage for the ultimate showdown between our spaceship and the aliens, enabling epic space battles.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                if bullet_fired: An if statement is a foundational concept in programming. It allows the code to make decisions. Here, it checks if the bullet_fired variable is set to True, indicating a bullet is currently in flight. In essence, this line asks, "Hey, did our pilot shoot a laser?" If the answer is "Yes!", then the following block of code (indented under the if) is executed.
                </li>

                <li>
                pygame.draw.rect: This is a Pygame function that lets us draw rectangles (our laser bullets, in this case) on a surface. It's like using a stencil to draw specific shapes in our intergalactic art piece.
                </li>

                <li>
                screen: The main surface or "canvas" where we are placing our laser bullet.
                </li>

                <li>
                RED: A predefined tuple containing RGB values (255, 0, 0) representing the color red. This gives our laser its fiery appearance.
                </li>

                <li>
                bullet: A Rect (rectangle) object that specifies the position and dimensions of our bullet. This tells Pygame exactly where and how big to draw our laser.
                </li>
            </ol>

            <h3>
            Thanks to these lines, our space pilot isn't just drifting aimlessly in the abyss. They're locked, loaded, and ready for a laser showdown! It adds thrill and excitement to our space adventure, ensuring every laser shot feels powerful and meaningful. 🌌🔥🎮
            </h3>
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 43: Cosmic Invaders - Bring on the Aliens! 🌌👾🚀
            </h2>

            <p>
            This segment of our interstellar tale ensures that the extraterrestrial invaders, our game's adversaries, are consistently drawn onto our cosmic canvas. Each and every one of the alien invaders from the aliens list gets its own spotlight, making its appearance on the game screen.
            </p>
            <img src={fortyFour} className='demo__image' alt='img_fortyThree' />

            <p>
            Why Are We Using These Lines?
            </p>
            <ol>
                <li>
                Challenges & Adversaries: What's a space adventure without menacing alien invaders? These lines ensure that our player has challenges to overcome and enemies to face off against.
                </li>

                <li>
                Graphics Over Text: A picture is worth a thousand words. Rather than just representing aliens with mere text or symbols, we bring them to life visually, enhancing the gameplay experience.
                </li>

                <li>
                Dynamic Display: As the game progresses, the number and position of aliens might change. By drawing them in a loop, we ensure that their current state is always displayed.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                for alien in aliens: This for loop iterates over each alien in the aliens list. Think of it like roll-calling each alien to make its appearance on the stage. The loop mechanism allows us to process multiple items in a list with a consistent set of instructions. In this case, drawing each alien on the screen.
                </li>

                <li>
                screen.blit(): blit is a Pygame method that lets us draw one image onto another. It's the magic spell that makes our alien image appear on our main game screen. In the context of our space game, it's like teleporting each alien onto our cosmic battlefield.
                </li>

                <li>
                alien_image: This is the visual representation of our alien. It's preloaded before the game starts and contains the look of our invader.
                </li>

                <li>
                alien: Each alien from our aliens list is a Rect (rectangle) object that dictates the position and dimensions of an alien on the screen. When we blit, it tells Pygame where to place each invader.
                </li>
            </ol>

            <h3>
            Thanks to these lines, the vastness of space isn't so empty. It's filled with alien adversaries that the player must skillfully navigate and engage. This lays the foundation for epic space battles, strategic movements, and moments of triumphant victories or narrow escapes. Every starship pilot's journey is now packed with action, intrigue, and suspense. 🌌🚀🛸👾
            </h3>
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 44: Galactic Scoreboard - Tallying our Space Adventures! 🌠🔢✨
            </h2>

            <p>
            This interstellar segment is all about celebrating our player's achievements! The lines are responsible for creating a live scoreboard that displays the player's current score. They craft a visual message showcasing how well our brave pilot is doing in their battle against the alien invaders.
            </p>
            <img src={fortyFive} className='demo__image' alt='img_fortyFour' />

            <p>
            Why Are We Using These Lines?
            </p>
            <ol>
                <li>
                Recognition & Reward: By keeping and displaying a score, we acknowledge the player's efforts and achievements. Each successful action in the game contributes to this tally, offering a sense of accomplishment.
                </li>

                <li>
                Player Feedback: The score acts as a real-time feedback system. As the score goes up, the player knows they're making progress and playing effectively.
                </li>

                <li>
                Game Dynamics: Introducing a score adds another layer of depth to the game, motivating players to improve their strategies and reflexes to achieve a higher score.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                font.render(): This method is a part of Pygame's font system. It converts text and numbers into a visual format (image) that can be displayed on the screen.
                </li>

                <li>
                f"Score: &#10100;score&#10101;" is a formatted string that combines static text ("Score:") with the dynamic value of our score variable. This ensures the displayed score updates in real-time.
                </li>

                <li>
                True activates anti-aliasing, making the text smoother and more visually appealing.
                </li>

                <li>
                WHITE is the color in which the text will be rendered, ensuring that our scoreboard shines brightly against the dark backdrop of space.
                </li>

                <li>
                screen.blit(): As discussed in previous steps, the blit method lets us draw one image onto another. In this instance, it's used to draw our score text onto the main game screen.
                </li>

                <li>
                (10, 10) dictates where on the screen our score will be displayed. The coordinates ensure it appears at a top-left position, easily visible to our players.
                </li>
            </ol>

            <h3>
            The universe may be vast and endless, but our player's achievements are no longer lost in the void. Thanks to our galactic scoreboard, every victory, every saved planet, and every defeated alien contributes to a growing testament of our player's skill, bravery, and strategic acumen. As the score climbs, legends of their cosmic exploits will be sung across galaxies far and wide! 🌌🚀🎖️🔢✨
            </h3>
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 45: Space Chronicle - Highlighting the Player's Progression 🌌🚀📜
            </h2>

            <p>
            In our sprawling space saga, these lines give the player a cosmic compass, guiding them through their journey. They display the current level, indicating how far our player has progressed in their mission to fend off alien invaders and save the universe!
            </p>
            <img src={fortySix} className='demo__image' alt='img_fortyFive' />

            <p>
            Why Are We Using These Lines?
            </p>

            <ol>
                <li>
                Journey Mapping: By providing a level display, we offer players a roadmap. It gives them a sense of where they are in the game and how much they've accomplished.
                </li>

                <li>
                Motivation Boost: Seeing the level rise acts as a motivator, urging players to keep going and conquer more challenges. Every new level represents a new chapter in our space story.
                </li>

                <li>
                Difficulty Gauge: Typically in games, as the level increases, challenges might intensify. The level display subtly prepares players for what's to come.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                font.render(): Just like with our score, we're using the font.render() method to translate our game data into a visual format.
                </li>

                <li>
                f"Level: &#10100;level&#10101;" is another example of a formatted string. The word "Level:" remains constant, but the &#10100;level&#10101; portion dynamically updates to reflect the player's current game level.
                </li>

                <li>
                True ensures our text is anti-aliased, resulting in smoother edges.
                </li>

                <li>
                WHITE dictates that our level text will gleam in a radiant white, making it stand out in the cosmic backdrop.
                </li>

                <li>
                screen.blit(): This function, as previously discussed, paints one image onto another. Here, it's tasked with projecting our level text onto the vast canvas of our game screen.
                </li>

                <li>
                (10, 40) are coordinates specifying the text's position. By setting it slightly below our score, we create an organized and easy-to-read information panel for our players.
                </li>
            </ol>

            <h3>
            Every space hero's journey is marked by milestones, challenges overcome, and new frontiers explored. By highlighting the level, we don't just show a number; we chronicle the player's odyssey through the stars, creating an ever-evolving narrative of their valiant efforts in the vast expanse of space. Onward to more adventures, space cadet! 🌠📖🛸🌌
            </h3>
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 46: A Smooth Transition Through the Universe 🌌🖥️✨
            </h2>

            <p>
            The galaxy is vast, and every frame in our game is like a window to a moment in our interstellar adventure. This line ensures that the window is always clear and updated, providing our brave player with the latest view of their ongoing quest. In simpler terms, pygame.display.flip() refreshes our game screen, showing all the recent updates made during the current game loop iteration.
            </p>
            <img src={fortySeven} className='demo__image' alt='img_fortySix' />

            <p>
            Why Are We Using This Line?
            </p>
            <ol>
                <li>
                Refreshing the Scene: As our player, aliens, bullets, and other game elements move and interact, the screen needs to reflect these changes. This function ensures that everything drawn or modified in our code is accurately presented on the screen.
                </li>

                <li>
                Double Buffering: Pygame uses a technique called double buffering. This means there are two "buffers" or "canvases" behind the scenes: one that you're actively drawing on, and one that's currently being shown on the screen. When you call flip(), you swap these buffers, making the one you've drawn on become the one that's visible. This approach prevents screen flickering and provides smooth animation.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                pygame.display.flip():
                </li>

                <li>
                pygame.display: This references the display module in Pygame, which contains functions to create and manage the game window.
                </li>

                <li>
                flip(): This method updates the entire screen. It's like flipping a page in a book to reveal a new scene. As the game runs, this method is continuously called to display the most recent frame of the game, giving the illusion of movement and interactivity.
                </li>
            </ul>

            <h3>
            Journeying through the cosmos, we need our stargazing tool — the game screen — to be sharp, accurate, and up-to-date. As the vast universe unfolds with every tick of the game, this function ensures our space explorers never miss a beat, experiencing every thrill, challenge, and discovery in real-time. 🌠🖥️🌟
            </h3>
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 47: Keeping the Universe's Time Flow Consistent 🚀⌛✨
            </h2>

            <p>
            Imagine that our game is a spacecraft hurtling through the vastness of space. Without proper thrusters and control, it would speed up uncontrollably. This line acts as our game's "thruster," ensuring the spacecraft (our game) maintains a consistent pace and doesn't go too fast.
            </p>
            <img src={fortyEight} className='demo__image' alt='img_fortySeven' />

            <p>
            In more direct terms, pygame.time.delay(10) tells the program to pause or "sleep" for 10 milliseconds (0.01 seconds) before moving on to the next line of code. This delay ensures that the game loop doesn't run too quickly, making our game playable and keeping the animations smooth.
            </p>

            <p>
            Why Are We Using This Line?
            </p>

            <ol>
                <li>
                Consistent Gameplay Experience: Without this delay, the game could run at vastly different speeds on different systems. By introducing a small wait, we ensure a more consistent experience across various hardware.
                </li>

                <li>
                Preventing Overuse of System Resources: Without such delays, the game loop could run as fast as the computer allows, leading to unnecessary strain on system resources.
                </li>

                <li>
                Smooth Animations: By pacing out each loop iteration, we provide smoother transitions and movements for all game elements.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                pygame.time.delay(10):
                </li>

                <li>
                pygame.time: This references the time module in Pygame, responsible for handling time-based functionalities, like waiting or tracking elapsed time.
                </li>

                <li>
                delay(10): The delay function takes one argument, which represents the number of milliseconds to pause the program. Here, we're pausing for 10 milliseconds.
                </li>
            </ul>

            <h3>
            In the vast, cosmic dance of the universe, every celestial body follows its rhythm and pace. By introducing this delay, we ensure that our game, a microcosm of that universe, maintains its tempo, providing a stellar experience that is both smooth and consistent. Just like the consistent beat of a cosmic drum, this delay sets the rhythm for our interstellar journey. 🌌⏳🎮
            </h3>
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 48: Bullet Countdown Mechanism 🚀🔢🎮
            </h2>

            <p>
            Just as a spaceship has a cooldown period after firing a thruster, our game has a cooldown for firing bullets. These lines ensure that there's a short delay between the time a bullet is fired and when it can be fired again, preventing the player from shooting bullets continuously.
            </p>
            <img src={fortyNine} className='demo__image' alt='img_fortyEight' />

            <p>
            Why Are We Using These Lines?
            </p>
            <ol>
                <li>
                Gameplay Balance: By introducing a delay between bullet shots, the game prevents players from spamming bullets, which could make the game too easy or chaotic.
                </li>

                <li>
                Realistic Mechanics: The cooldown gives a sense of realism, as most space shooters have a recharge or cooldown mechanism for weapons.
                </li>

                <li>
                Controlling Game Pace: Ensuring there's a cooldown period can help control the pace of the game, ensuring that it isn't overwhelmingly fast-paced due to constant bullet firing.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if bullet_countdown &gt; 0:
                </li>

                <li>
                if: This is a conditional statement that checks whether the condition inside the parentheses is true. If it is, the code inside the subsequent block will be executed.
                </li>

                <li>
                bullet_countdown &gt; 0: This checks if the value of bullet_countdown is greater than 0.
                </li>

                <li>
                bullet_countdown -= 1:
                </li>

                <li>
                bullet_countdown: This is our variable keeping track of the bullet cooldown timer.
                </li>

                <li>
                -=: This is a shorthand operator in Python. It decreases the value of the variable on its left by the value on its right. In this case, it reduces bullet_countdown by 1.
                </li>
            </ul>

            <h3>
            By implementing this bullet countdown mechanism, our game has introduced an essential aspect of strategy and timing. In the vast reaches of space, timing can be the difference between victory and defeat. Players must now think carefully about when to fire and when to hold back, adding an extra layer of skill and strategy to our intergalactic adventure! 🌌⏲️
            </h3>
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default SpaceInvaders