import React, {useState} from 'react';
import one from './fullstackImages/finFancy/1.png';
import two from './fullstackImages/finFancy/2.png';
import three from './fullstackImages/finFancy/3.png';
import four from './fullstackImages/finFancy/4.png';
import five from './fullstackImages/finFancy/5.png';
import six from './fullstackImages/finFancy/6.png';
import seven from './fullstackImages/finFancy/7.png';
import eight from './fullstackImages/finFancy/8.png';
import nine from './fullstackImages/finFancy/9.png';
import ten from './fullstackImages/finFancy/10.png';
import eleven from './fullstackImages/finFancy/11.png';
import twelve from './fullstackImages/finFancy/12.png';
import thirteen from './fullstackImages/finFancy/13.png';
import fourteen from './fullstackImages/finFancy/14.png';
import fifteen from './fullstackImages/finFancy/15.png';
import sixteen from './fullstackImages/finFancy/16.png';
import seventeen from './fullstackImages/finFancy/17.png';
import eighteen from './fullstackImages/finFancy/18.png';
import nineteen from './fullstackImages/finFancy/19.png';
import twenty from './fullstackImages/finFancy/20.png';
import twentyOne from './fullstackImages/finFancy/21.png';
import twentyTwo from './fullstackImages/finFancy/22.png';
import twentyThree from './fullstackImages/finFancy/23.png';
import twentyFour from './fullstackImages/finFancy/24.png';
import twentyFive from './fullstackImages/finFancy/25.png';
import twentySix from './fullstackImages/finFancy/26.png';
import twentySeven from './fullstackImages/finFancy/27.png';
import twentyEight from './fullstackImages/finFancy/28.png';
import twentyNine from './fullstackImages/finFancy/28.png';
import thirty from './fullstackImages/finFancy/30.png';
import thirtyOne from './fullstackImages/finFancy/31.png';
import thirtyTwo from './fullstackImages/finFancy/32.png';
import thirtyThree from './fullstackImages/finFancy/33.png';
import thirtyFour from './fullstackImages/finFancy/34.png';
import thirtyFive from './fullstackImages/finFancy/35.png';
import thirtySix from './fullstackImages/finFancy/36.png';
import thirtySeven from './fullstackImages/finFancy/37.png';
import thirtyEight from './fullstackImages/finFancy/38.png';
import thirtyNine from './fullstackImages/finFancy/39.png';
import forty from './fullstackImages/finFancy/40.png';
import fortyOne from './fullstackImages/finFancy/41.png';
import fortyTwo from './fullstackImages/finFancy/42.png';
import fortyThree from './fullstackImages/finFancy/43.png';
import fortyFour from './fullstackImages/finFancy/44.png';
import fortyFive from './fullstackImages/finFancy/45.png';
import fortySix from './fullstackImages/finFancy/46.png';
import fortySeven from './fullstackImages/finFancy/47.png';
import fortyEight from './fullstackImages/finFancy/48.png';
import fortyNine from './fullstackImages/finFancy/49.png';
import fifty from './fullstackImages/finFancy/50.png';
import fiftyOne from './fullstackImages/finFancy/51.png';
import fiftyTwo from './fullstackImages/finFancy/52.png';
import fiftyThree from './fullstackImages/finFancy/53.png';
import fiftyFour from './fullstackImages/finFancy/54.png';
import fiftyFive from './fullstackImages/finFancy/55.png';
import fiftySix from './fullstackImages/finFancy/56.png';
import fiftySeven from './fullstackImages/finFancy/57.png';
import fiftyEight from './fullstackImages/finFancy/58.png';
import fiftyNine from './fullstackImages/finFancy/59.png';
import sixty from './fullstackImages/finFancy/60.png';
import sixtyOne from './fullstackImages/finFancy/61.png';
import sixtyTwo from './fullstackImages/finFancy/62.png';
import sixtyThree from './fullstackImages/finFancy/63.png';
import CopyBox from './copyBox/copybox';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const FinFancy = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 63;

  return (
    <div className='development__container'>
        <h1>
        🐠 Fin Fancy: Exotic Online Fish Store 🐠
        </h1>

        <h3 className='intro'>
        In this tutorial, we'll be swimming through the fundamentals of HTML (HyperText Markup Language) and CSS (Cascading Style Sheets), the bedrock of web development. By the end of this course, you will have not only learned the essential skills to construct and style web pages but also have the satisfaction of having built an engaging, interactive, and fin-tastic online store for "Fin Fancy."        
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>


        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Setting Up Your Aquarium 🐠</h2>

            <p>
            In this step, we're opening up our underwater world of coding with the creation of a new HTML file. 
            </p>
            <img src={one} className='demo__image' alt='img_one' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Open your code editor - it's your underwater castle!
                </li>

                <li>
                Create a new file by clicking on the "New File" icon or right-clicking in the sidebar area and selecting 'New File'.
                </li>

                <li>
                Name this new file index.html. It's the main display window of our aquarium (web page)!
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                HTML File: Just like fish need water, websites need HTML. It's the basic structure of your webpage!
                </li>

                <li>
                index.html: Think of it as the main gate to your fish store. It's the first page that users will see.
                </li>
            </ul>
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Building the Aquarium Decor 🪴</h2>
            
            <p>
            Now, we're going to set up different sections (or tanks) for our fish! These sections will help organize our fish and make it easy for visitors to see all the exotic creatures we have.
            </p>
            <img src={two} className='demo__image' alt='img_two' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                    Right next to your index.html file, add a folder and name it "images". It will contain all of the lovely images of the fish in the Fin Fancy webpage!
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Images Folder: This is like the back room of our store where we keep all the supplies – in this case, pictures of our fish!
                </li>
            </ul>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 3: Welcoming the Fish to Their New Home 🏠</h2>
            
            <p>
            We've got the tanks set up, and now it's time to bring in the fish. Each image of a fish is like a new finned friend joining our store!
            </p>
            <img src={three} className='demo__image' alt='img_three' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                In your images folder, you're going to place all the pictures of your fish - these are the PNG files named fish1.png, fish2.png, and so on.
                </li>

                <li>
                    Use the buttons below to download and save the images into the "images" folder.
                </li>
            </ol>

            {/*<div className='download__buttons__container'>
                <Download src={fish1} filename="fish1.png" />

                <Download src={fish2} filename="fish2.png" />

                <Download src={fish3} filename="fish3.png" />

                <Download src={fish4} filename="fish4.png" />

                <Download src={fish5} filename="fish5.png" />

                <Download src={fish6} filename="fish6.png" />

                <Download src={fish7} filename="fish8.png" />

                <Download src={fish9} filename="fish9.png" />
            </div>*/}
            
            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                PNG Files: These are snapshots of our fish. PNG format makes sure they look clear and vibrant on the screen, just like in a real tank!
                </li>
            </ul>

            <p>
            How does it feel to be creating your own digital ocean? Exciting, right? Let’s keep the current flowing! ⚡
            </p>
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: Laying the Foundation of Our Ocean 🌐</h2>
            
            <p>
            Every fish tank needs a solid base, and in the world of web design, our &lt;!DOCTYPE html&gt; is just that. It tells the browser we're speaking in HTML, the language of web pages.
            </p>
            <img src={four} className='demo__image' alt='img_four' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Start by typing &lt;!DOCTYPE html&gt; at the very top of your index.html file. It's like saying, "Hey, we're going to build a website here!"
                </li>

                <li>
                Next, open up the world where our fish will swim by adding &lt;html lang="en"&gt;. This sets the language for our sea creatures and makes sure they all swim in the same direction.
                </li>

                <li>
                Don’t forget to close the &lt;/html&gt; tag at the bottom, like putting the last piece in our aquarium puzzle.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                html Tag: Consider this the boundary of our website. Everything inside is part of our web world.
                </li>

                <li>
                lang Attribute: This is like choosing the common language for all our fishy friends and guests, in this case, english.
                </li>
            </ul>
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Setting Up the Control Room 🧠</h2>
            
            <p>
            Just as the control room manages the behind-the-scenes of an aquarium, the &lt;head&gt; section of our HTML file controls all the invisible settings that make our webpage work smoothly.
            </p>
            <img src={five} className='demo__image' alt='img_five' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                After the opening &lt;html&gt; tag, type in the &lt;head&gt; tag. Think of it as setting up the command center for your webpage.
                </li>

                <li>
                The &lt;head&gt; section won't be seen by visitors, but it’s super important! It holds the title, links to stylesheets, and more. For now, it’s an empty room waiting for gadgets and controls.
                </li>

                <li>
                Make sure you close the &lt;head&gt; section with a &lt;/head&gt; tag. It’s like making sure the door to our control room is properly installed.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Head Section: It's the brain of our webpage, where we put all the settings and instructions.
                </li>

                <li>
                Tags: These are like the labels on our control switches. Each one has a specific job to keep our page running.
                </li>
            </ul>
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Filling Our Control Room with Meta Tags! ✨</h2>
            
            <p>
            Now we're adding some metadata to our control room that makes our website look good on every device, and giving it a name that everyone will see at the top of their browser window!
            </p>
            <img src={six} className='demo__image' alt='img_six' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                First up, let's ensure everyone can read our language by adding &lt;meta charset="UTF-8"&gt;.
                </li>

                <li>
                Next, we want our fish store to look good on all devices, so type in &lt;meta name="viewport" content="width=device-width, initial-scale=1.0"&gt;. This code adjusts our store to fit all screen sizes, from small fish bowls to large ocean tanks!
                </li>

                <li>
                Lastly, let's put up a big, shiny sign with &lt;title&gt;Fin Fancy Exotic Fish Store&lt;/title&gt;. This is the name that will appear at the top of the browser, telling everyone what our amazing underwater adventure is called.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Charset UTF-8: This is the most popular character encoding method that includes a lot of different symbols so everyone can understand, no matter what language they speak.
                </li>

                <li>
                Viewport: This is like an adjustable window that makes sure our fish look great on mobile phones, tablets, and computers.
                </li>

                <li>
                Title Tag: Think of it as the name of our aquarium that appears on the web sea map (browser tab).
                </li>
            </ul>
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Crafting the Stylish Seaweed (CSS File) 🌿🎨</h2>
            
            <p>
            Our aquarium is functional, but it needs some style - that's where our CSS file comes into play. It's like choosing the color and texture of our plants, rocks, and sand to make everything look stunning!
            </p>
            <img src={seven} className='demo__image' alt='img_seven' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                In your explorer panel, find the “New File” button and click it to create a new file.
                </li>

                <li>
                Name this new file style.css
                </li>

                <li>
                It's a blank canvas now, but soon, you’ll fill it with colors and patterns that make our fish feel at home and our visitors say "Wow!"
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                CSS File: This is where we write the style rules that will determine how our HTML elements (our fish, plants, rocks) look on the page.
                </li>
            </ul>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Linking Our Aquarium's Style 🖇️🎨</h2>

            <p>
            You’ve crafted the CSS file, and now it's time to link it to your HTML file. This step is like connecting the decoration plans to the actual fish tank to bring our design to life!
            </p>
            <img src={eight} className='demo__image' alt='img_eight' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                In the head of your HTML, you’re going to add a link to your stylish file with &lt;link href="./style.css" rel="stylesheet" /&gt;.
                </li>

                <li>
                Make sure the href path matches the location of your CSS file. This is like telling the fish tank where to find the plants and ornaments.
                </li>

                <li>
                The rel="stylesheet" part is informing the HTML that the CSS is for styling!
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Linking CSS: It’s like giving directions to the decorators of our tank so they know where to put all the fancy seaweed and colorful coral.
                </li>

                <li>
                Path: This is the specific address where the CSS file is located!
                </li>
            </ul>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Creating the Living Space (Body Section) 🛠️</h2>

            <p>
            We’ve got the control room set up and our styles ready to go. Now, it's time to create the living space where our digital fish will swim. That's the body of our HTML!
            </p>
            <img src={nine} className='demo__image' alt='img_nine' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Type in the &lt;body&gt; tag after your head section.
                </li>

                <li>
                    This area is currently as empty as a new tank before we fill it with water, but not for long!
                </li>

                <li>
                Don’t forget to close the body with a closing &lt;/body&gt; tag.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Body Tag: The main stage of our aquarium where all the visible HTML content goes – text, images, buttons, and more.
                </li>
            </ul>

            <p>
            In our next step, we’ll start adding fish, plants, and all the colorful elements that make our aquarium a splash hit! 🎉🐠
            </p>
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Adding the Container 🎉</h2>

            <img src={ten} className='demo__image' alt='img_ten' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Start by creating a div with the class top__container. This div will contain the top part of the website, as the class is named such.
                </li>

                <li>
                For now, this div is an empty tank. But don't worry, we're about to fill it up with colorful content that makes our visitors go "Wow!"
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Div Element: This is a container in our webpage. We can fill it with all sorts of HTML elements, like texts and images.
                </li>

                <li>
                Class Attribute: It’s a way of naming our div so we can easily find it and style it later. It’s like tagging the welcome sign so the decorators know what theme to give it.
                </li>
            </ul>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: Filling Our Welcome Area with Exciting Info 📜</h2>

            <p>
            Now we’re going to fill our welcome sign with some inviting details. We want to capture our visitors’ attention the moment they swim into our site!
            </p>
            <img src={eleven} className='demo__image' alt='img_eleven' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Inside your top__container, type a big &lt;h1&gt; tag and write "Fin Fancy Exotic Fish." This is like the name of our aquarium in big, bold letters!
                </li>

                <li>
                Below that, add a &lt;p&gt; tag with a description of what we offer: "Discover the Ocean's Hidden Wonders: Rare & Exquisite Fish for Your Aquarium." It's like telling a short, exciting story about what they can find inside.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                H1 Tag: This is the largest heading tag and is often used for main titles. It’s like the headline of our story.
                </li>

                <li>
                Paragraph Tag: We use the &lt;p&gt; tag for descriptions and details. It's the informative text on our sign that tells visitors more about our world.
                </li>
            </ul>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Setting the Stage for Our Aquatic Display 🎭</h2>

            <p>
            We’ve welcomed our guests with a stunning sign, and now it’s time to build the stage – the main display area where all our fish will shine. This area is called the bottom__container.
            </p>
            <img src={twelve} className='demo__image' alt='img_twelve' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Below the top__container, create a new div with the class bottom__container. It's like laying out a series of tanks for each fishy friend we have.
                </li>

                <li>
                Right now, this div is just an empty space, but think of it as the floor plan of our aquarium. We will be filling it with more content soon!
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Div Element: A versatile container used to group other HTML elements and control their styling and layout.
                </li>
            </ul>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: Choosing the Right Font 🌈🎨</h2>
    
            <p>
            In the world of web design, this means importing the right font to give our text that extra flair.
            </p>
            <img src={thirteen} className='demo__image' alt='img_thirteen' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Go to your style.css file.
                </li>

                <li>
                At the top, add the font import to use a special front from the internet. Copy it from the box below and paste it.
                </li>

                <li>
                With this import, you’re bringing in the ‘Roboto’ font family in various weights and styles. It’s like selecting the right water plants and rocks to match the fish.
                </li>
            </ol>

            <CopyBox 
            text={"@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');"}
            />

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Fonts: The style of text on our webpage. The right font can make our content as pleasing as a beautifully arranged aquarium.
                </li>
            </ul>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: The Universal Selector – Casting a Wide Net 🌐</h2>

            <img src={fourteen} className='demo__image' alt='img_fourteen' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Open your style.css file – it's our toolbox for making things look great.                
                </li>

                <li>
                Type in the universal selector *. Inside the curly braces, we will place our common rules, but for now, they are like an empty tank waiting to be filled.
                </li>

                <li>
                This universal selector targets every single element on our webpage. Imagine it as a big net that can scoop up every single item in our aquarium.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Universal Selector (*): This targets all HTML elements on the page, ensuring no one is left out. It's an efficient way to apply a broad set of rules, like giving all our tanks the same starting conditions.
                </li>

                <li>
                Curly Braces: These are used in CSS to contain the styles that will apply to the selected elements. Everything inside the braces is part of the styling instruction.
                </li>
            </ul>
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15: Setting the Margins and Paddings 🚀</h2>

            <p>
            Now we'll use our universal selector to remove any default margins and paddings that browsers apply. It's like cleaning and prepping our tanks before adding water and fish to them, ensuring they're just right for their new inhabitants.
            </p>
            <img src={fifteen} className='demo__image' alt='img_fifteen' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Within the curly braces of your universal selector, add margin: 0;. This removes any default space outside of our elements. It clears the default space.
                </li>

                <li>
                On the next line, add padding: 0;. This clears any default space inside the elements, guaranteeing our design isn't affected by unexpected spacing.
                </li>

                <li>
                These two lines of code ensure that all elements start with the same zero margins and paddings, giving you full control over the spacing in your design.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Margin: It's the space outside an element. Imagine a frame around a painting; that's the margin.
                </li>

                <li>
                Padding: This is the space inside the boundary of an element, between the content and the edge. Think of it as the space between the water and the glass of our tanks.
                </li>

                <li>
                Zeroing Out: By setting margins and paddings to zero, we’re making sure that all elements start with no extra space that we haven’t explicitly given them. It’s like setting a baseline in a science experiment.
                </li>
            </ul>

            <p>
            Now that we've cleared the decks, we're all set to start building our aquatic wonderland with precision! 🛠️🐙
            </p>
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 16: Organizing – Box-Sizing 📦📐</h2>

            <p>
            We're continuing to set the stage for our underwater showcase. 
            </p>
            <img src={sixteen} className='demo__image' alt='img_sixteen' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Find your universal selector * in your style.css, which already has margin: 0; and padding: 0;.
                </li>

                <li>
                Add the line box-sizing: border-box; below them. This will make sure that padding and borders will be included in the total width and height of elements.
                </li>

                <li>
                Now every element on our page will behave more predictably as we style them, just like knowing exactly how much space we have in each tank for our fish and plants.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Box-Sizing: This CSS property changes how the width and height of elements are calculated. With border-box, the padding and border of an element are included inside its width and height instead of added to it.
                </li>

                <li>
                Predictable Layout: Using border-box makes it easier to plan your layout because elements won't unexpectedly grow in size when you add padding or borders.
                </li>
            </ul>
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 17: Setting a Universal Font – Consistent Readability 📖🔠</h2>

            <p>
            After ensuring our boxes are sized correctly, it's time to choose a font that all the visitors to our website will find easy to read. Consistency is key in design, and setting a universal font makes our website look uniform and professional.
            </p>
            <img src={seventeen} className='demo__image' alt='img_seventeen' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Stay within the universal selector * in your style.css.
                </li>

                <li>
                After box-sizing: border-box;, add a new line for the font family: font-family: 'Roboto', sans-serif;. This means every bit of text will start off with the Roboto font, unless we specify otherwise later on.
                </li>

                <li>
                Roboto is a modern, clean font that's easy on the eyes.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Font-Family: This defines what typeface text should use. By setting it universally, you make sure that every piece of text has the same style, promoting a cohesive look.
                </li>
            </ul>
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 18: Defining the Welcome Area's Structure 🏗️🌟</h2>

            <p>
            We're setting up the rules for our welcome area, known as .top__container in our CSS. This step is about creating a specific area in our CSS file for this element so we can start giving it some style and structure.
            </p>
            <img src={eighteen} className='demo__image' alt='img_eighteen' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Go to your style.css file, where you’ve already established some universal styles.
                </li>

                <li>
                Create a new section by typing .top__container. This is the CSS class selector that corresponds to the top__container class in your HTML.
                </li>

                <li>
                Inside the curly braces, you will soon add styles that apply specifically to this container.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Class Selector: A class selector in CSS starts with a dot followed by the class name, allowing you to apply styles to all HTML elements with this class.
                </li>

                <li>
                CSS Ruleset: Inside the curly braces, we will define our CSS rules that determine how the element with this class should be displayed.
                </li>
            </ul>
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 19: Applying Flexbox 🎨</h2>

            <p>
            Now that we have a designated spot for our welcome area's styling in our CSS, we're going to apply a powerful tool called Flexbox to make sure our content is laid out just the way we want it.
            </p>
            <img src={nineteen} className='demo__image' alt='img_nineteen' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Inside the .top__container ruleset in your style.css, add display: flex;. This one line of code unlocks the power of Flexbox for this container.
                </li>

                <li>
                With display: flex;, our .top__container is now a flex container, and its children will be laid out as flex items. This means we can easily control how they are aligned and distributed within the container.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Flex Container: By setting an element to display: flex;, it becomes a flex container and its immediate children become flex items.
                </li>

                <li>
                "display: flex": is the property we use to let the computer know that we will be using flex properties to control the spacing and orientation of whatever it is we are styling.
                </li>
            </ul>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 20: Laying Out Content Vertically – The Flex Direction 🎢📏
            </h2>

            <p>
            We're continuing to style the .top__container in our CSS, and now we're setting the direction in which the content inside will flow. Think of this as choosing whether to stack our fish tanks on top of each other or place them side by side.
            </p>
            <img src={twenty} className='demo__image' alt='img_twenty' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Inside the .top__container ruleset, you've already set the display to flex. Now, let’s add flex-direction: column; to it. This line tells all the content inside the .top__container to align vertically, like a column. This only works because we used "display: flex" earlier, thus enabling us to use "flex-direction".
                </li>

                <li>
                By default, flex items are laid out in the direction of a row. The flex-direction: column; overrides this and organizes our items in a top-to-bottom flow.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Flex Direction: This property defines the direction in which the flex items are placed in the flex container. A column will stack them vertically, which is useful when you want elements to follow a top-to-bottom sequence.
                </li>

                <li>
                Column Flow: When elements are arranged in a column, they start from the top and flow down.
                </li>
            </ul>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 21: Centering Content 🎯🖌️</h2>

            <p>
            Now that we've set up our flex container to organize items in a column, we want to ensure these items are centered perfectly within the container, both horizontally and vertically. This makes our content look polished and professional.
            </p>
            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                You're already inside the .top__container class in your style.css.
                </li>

                <li>
                Add align-items: center; to align our items along the cross-axis (horizontally when flex-direction is column).
                </li>

                <li>
                Then, add justify-content: center; to align our items along the main axis (vertically in this case).
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Align-Items: This aligns children items of a flex container on the cross axis. For a column, this means horizontally centering the content.
                </li>

                <li>
                Justify-Content: This aligns children items on the main axis. For a column, it centers them vertically. It’s like deciding the perfect spot for each fish in the tank.
                </li>

                <li>
                Centering in Flexbox: Using both align-items and justify-content together with the value center ensures that content is centered in both dimensions within the flex container.
                </li>
            </ul>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 22: Expanding the Welcome Area – Full Width 🌐✨</h2>

            <p>
            For our .top__container, which holds our welcome message, we're making sure it stretches across the full width of the screen. This gives it the prominence it deserves, like a grand entrance to our digital aquarium.
            </p>
            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                You're in the right place in your CSS file under .top__container.
                </li>

                <li>
                Add width: 100%; within the curly braces of .top__container. This ensures that the container will extend to the full width of the screen, making use of all the space available.
                </li>

                <li>
                This line is like ensuring that our welcome sign spans the entire entrance width, creating a strong visual impact as visitors swim into the site.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Width Property: The width CSS property sets an element's width. By using a percentage value, we're making the element responsive, which means it will adjust to different screen sizes.
                </li>

                <li>
                100% Width: This value ensures that no matter the size of the screen or browser window, our .top__container will always use the full width available to it.
                </li>
            </ul>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 23: Adding Spacing to the Welcome Area – Breathing Room 🌬️</h2>

            <p>
            To make sure our welcome area isn't crammed against the content above or below it, we're going to add some space.
            </p>
            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Continue working in the .top__container in your CSS file.
                </li>

                <li>
                Now, add margin-top: 48px; and margin-bottom: 48px;. These properties create space outside the container at the top and bottom, respectively.
                </li>

                <li>
                By doing this, we're ensuring our welcome message doesn't feel squeezed but has ample space to stand out, providing a clear and comfortable start for our visitors' journey.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Margin Property: This defines the space around elements. It’s like the water around a fish – it doesn't make them bigger, but it gives them room to swim.
                </li>

                <li>
                Pixels (px): A unit of measurement in CSS that's fixed and doesn't change based on the user's screen size. It’s a precise way to add spacing.
                </li>
            </ul>
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 24: Styling the Header 🎨</h2>

            <p>
            Now we’re giving special attention to the main title within our .top__container. The h1 tag holds the title of our page, and we want it to be big and eye-catching.
            </p>
            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Inside the style.css, just below the .top__container rules, target the h1 tag inside it by writing .top__container h1.
                </li>

                <li>
                For now, this is just a placeholder. We're preparing to set specific styles for our main heading.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Descendant Selector: .top__container h1 is a descendant selector in CSS. This means it targets h1 elements that are inside elements with the class .top__container.
                </li>

                <li>
                CSS Specificity: This rule is more specific than a single class or element selector, meaning it will take priority over less specific rules.
                </li>
            </ul>
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 25: Making the Title Stand Out – Font Size 📏</h2>

            <p>
            We want our main title to really stand out and set the theme for our digital aquarium. A larger font size will help capture the attention of our visitors.
            </p>
            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                In your .top__container h1 ruleset in the style.css, add font-size: 44px;. This style will apply to all h1 tags inside the .top__container, making them larger and more noticeable.
                </li>

                <li>
                The px unit ensures that the font size is consistent across all devices and screen sizes.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Font Size: This property controls how big or small text appears on the screen. Larger sizes are typically used for headings to make them stand out.
                </li>

                <li>
                Pixels (px): A unit of measurement in CSS that's based on screen resolution. It is one of the most common units for measuring font size, as well as spacing and positioning elements on a webpage.
                </li>
            </ul>
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 26: Creating Space Around the Title – Margins 📐</h2>

            <p>
            Now that we've got our main title in place, we want to ensure it has the space it needs to stand out. Adding a margin to the bottom of our h1 element does just that, like placing a spotlight above an exhibit to draw the eye.
            </p>
            <img src={twentySix} className='demo__image' alt='img_twentySix' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Inside the .top__container h1 block in your CSS, you've set the font size. Now, let’s create some breathing room.
                </li>

                <li>
                Add margin-bottom: 12px;. This will push any content below the h1 down, preventing it from feeling too crowded and making our title even more prominent.
                </li>

                <li>
                The px unit gives us precise control over the space, ensuring consistency across all devices.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Margin: This property creates extra space around an element. Unlike padding, margin is outside the border of the element.
                </li>

                <li>
                Margin-Bottom: Specifically alters the space at the bottom of an element, giving it room to breathe from the content below.
                </li>
            </ul>
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 27: Centering the Title Text – Alignment 🎯</h2>

            <p>
            A centered title often has a stronger impact, especially on a landing page. We're ensuring that the text of our main title is centered within the .top__container, making a balanced and professional look.
            </p>
            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                You're still working within the .top__container h1 in your CSS file.
                </li>

                <li>
                Add the line text-align: center;. This centers the text inside the h1 element horizontally within its container.
                </li>

                <li>
                Whether visitors are on a desktop or mobile, this alignment will make sure the title text is the focal point right in the middle of their view.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Text-Align: This property in CSS is used for horizontal alignment of text. When set to center, it ensures the text is equally spaced from both sides within its containing element.
                </li>

                <li>
                Center Alignment: It’s not just about aesthetics; centered text can also help readability and focus, particularly on devices where screen space is limited.
                </li>
            </ul>
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 28: Aligning the Text 📝</h2>

            <p>
            Just as we centered our main title, we want to ensure that the subtitle in our .top__container is also centered. It’s important to maintain alignment for a clean and harmonious look.
            </p>
            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                In your style.css file, you’ve already styled the h1 tag within .top__container. Now let’s give some attention to the p tag that serves as our subtitle.
                </li>

                <li>
                Under the .top__container h1 ruleset, target the paragraph within .top__container by writing .top__container p.
                </li>

                <li>
                Inside the curly braces, add text-align: center;. This will center-align the text of the paragraph, ensuring it matches the heading and creates a visually centered column of text.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Text Alignment: In CSS, text-align property is used to define how inline content like text is aligned within its containing block or box.
                </li>

                <li>
                Consistency in Design: Keeping elements like titles and subtitles aligned in the same way can be key to creating a clean and professional appearance.
                </li>
            </ul>
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 29: Reviewing your Work 🎯</h2>

            <p>
                Right now is the time to admire your work thus far, review what you have created, and ask any questions about things you need help with.
            </p>
            <img src={twentyNine} className='demo__image' alt='img_twentyNine' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                    Look over your work and find any errors. Did you locate any? If so, ask your teacher about them!
                </li>
            </ol>
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 30: Crafting Our Exhibit Spaces 🛠️</h2>

            <p>
            We're about to create individual "fish__card" divs that will act like little cards for each type of fish in our digital aquarium. Think of each fish__card as a mini-stage for showcasing a unique fish, complete with a picture, name, and price tag.
            </p>
            <img src={thirty} className='demo__image' alt='img_thirty' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Each fish__card is a dedicated space to showcase an individual fish, its name, price, and an inviting button to encourage interaction.
                </li>

                <li>
                Inside the bottom__container div in your HTML, add one div with the class fish__card.
                </li>

                <li>
                Inside the fish__card, we need another div to serve as our image__container. This will hold the image of the fish and help us keep it separate from the text, maintaining a clean layout.
                </li>

                <li>
                Within the image__container, insert an img tag. Set its src attribute to the path of your fish image and add an alt text for accessibility. The alt text helps screen readers describe the image to users who may not be able to see it.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                HTML Structure: Using div elements to build out the sections of our page, like the walls of an aquarium tank.
                </li>

                <li>
                Classes and Containers: The fish__card class defines the style and behavior of our fish card. Inside it, the image__container acts as a subsection dedicated to visual content.
                </li>

                <li>
                Images in Web Design: The img tag is how we add visual appeal to our card. Like choosing the right fish for the tank, we select images that will captivate and intrigue our visitors.
                </li>

                <li>
                Accessibility: Using alt tags with images ensures that our website is accessible to all users, including those who use assistive technologies.
                </li>
            </ul>
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 31: Assembling the Contents 🎨🐟🏷️</h2>

            <p>
            We're diving deeper into the fish__card, adding essential details that provide our visitors with information about our exotic fish. Just like a well-arranged exhibit, we want to present the fish's name and price clearly and attractively.
            </p>
            <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Within each fish__card in your HTML, after the image__container, add a new div with the class card__info. This is where the details about the fish will go.
                </li>

                <li>
                Inside card__info, insert a p element to display the price. Begin with $89, as this will be our placeholder for the fish price tag.
                </li>

                <li>
                Next, add an h3 tag for the fish name. Just type Fish Name as a placeholder; later, or give it a name of your own.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Structuring Content: We use HTML elements like div, p, and h3 to create a structure within our fish__card that is logical and easy to follow.
                </li>

                <li>
                Class Naming: The card__info class is part of our BEM naming strategy, indicating that this div is an element that belongs to the fish__card block.
                </li>

                <li>
                Semantic HTML: The p tag is commonly used for small blocks of text like the price, and h3 is an appropriate choice for subheadings like the fish names, which are less prominent than the main page title but still important.
                </li>
            </ul>
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 32: Adding Interactivity 🖱️</h2>

            <p>
            Our fish__card now needs a call to action. We want our visitors not only to admire our fish but to feel compelled to bring one home. That's where our "Order Now" button comes in—it's the invitation to engage further with our aquatic friends.
            </p>
            <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Inside the card__info div, right after the h3 tag, add a button element with the text "Order Now".
                </li>

                <li>
                This button will ultimately lead our visitors to purchase the fish or learn more about it. For now, it's just a static element, but later on, we can add functionality to it, such as opening a modal, linking to a purchase form, or another page.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Button Element: The button tag in HTML creates a clickable button, which is an essential element for user interaction on websites.
                </li>

                <li>
                Call to Action: The text "Order Now" is a clear call to action, a directive that encourages users to take an immediate response, in this case, to initiate a purchase or to express interest in a product.
                </li>
            </ul>
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 33: Styling the bottom__container 🎨📦</h2>

            <p>
            The bottom__container is where our fish__card elements will live. To ensure they're displayed neatly in a row and can wrap when there isn't enough space, we're turning to Flexbox, a powerful layout tool in CSS.
            </p>
            <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                In your style.css file, add a new rule for the .bottom__container.
                </li>

                <li>
                Use display: flex; to define a flex container.
                </li>

                <li>
                Add flex-direction: row; to lay out the fish__card elements in a horizontal line.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Flexbox: A layout model in CSS that allows items to be aligned and distributed within a container in an efficient way, handling both the main-axis (horizontal or vertical) and the cross-axis (perpendicular to the main-axis).
                </li>

                <li>
                Row Direction: By setting the flex-direction to row, we're specifying that the children of this container (our fish__card elements) should line up side by side, from left to right.
                </li>
            </ul>
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 34: Refining the Layout 🎨📐</h2>

            <p>
            The bottom__container is shaping up as our main display area for the fish__card divs. Now we're adding some CSS properties to space them out nicely and make sure they align at the start of the container.
            </p>
            <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                In your style.css file, you’ve already set up display: flex; and flex-direction: row; for the .bottom__container.
                </li>

                <li>
                Now, let's add align-items: flex-start; to align the cards to the top of the container.
                </li>

                <li>
                Include justify-content: space-between; so that there is an even space between each fish__card.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                align-items: This CSS property aligns flex items along the cross-axis of the current line of the flex container. flex-start aligns the items to the start of the container.
                </li>

                <li>
                justify-content: This property aligns the flex items along the main axis of the flex container. space-between creates an equal amount of spacing between each item.
                </li>
            </ul>
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 35: Ensuring Responsiveness 📱📏</h2>

            <p>
            We want our fish__card elements to maintain a good layout regardless of the screen size. That's why we will use flex-wrap to allow the cards to wrap onto multiple lines if needed, and add padding to the bottom__container for consistent spacing.
            </p>
            <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Keep the rules you already have in .bottom__container and add flex-wrap: wrap;. This allows the fish__card elements to drop down to a new line on smaller screens, instead of squeezing them all on one line.
                </li>

                <li>
                Apply padding by adding padding: 45px; which will give some space inside the bottom__container, making sure the cards don’t touch the edges of the screen or container.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                flex-wrap: A property that allows items to wrap as needed onto new lines, based on the available space in the container.
                </li>

                <li>
                Padding: Padding adds space within the container, providing breathing room between the content and the container's border.
                </li>
            </ul>
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 36: Styling Individual Fish Cards 🎨</h2>

            <p>
            Each fish__card is like a small stage for showcasing our fish. We want to style them in a way that makes each fish the star of its own show. 
            </p>
            <img src={thirtySix} className='demo__image' alt='img_thirtySix' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Go to your style.css file and create the .bottom__container .fish__card selector.
                </li>

                <li>
                For now, just open and close the curly braces. We're setting up the stage to begin styling, but we're not adding any styles just yet.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                CSS Selectors: We use selectors to target specific elements on our webpage. Here, .bottom__container .fish__card selects any element with the class fish__card that is inside an element with the class bottom__container.
                </li>

                <li>
                Scope and Specificity: By specifying .bottom__container .fish__card, we ensure that we only target .fish__card elements within the .bottom__container, not elsewhere on the page.
                </li>
            </ul>
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 37: Initiating Flexbox on Fish Cards 🏗️</h2>

            <p>
            Now that we've targeted the fish__card, let's lay the groundwork for its internal layout with Flexbox.
            </p>
            <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Inside the curly braces for .bottom__container .fish__card, add display: flex;.
                </li>

                <li>
                Then, add flex-direction: column; to stack the children elements (the image and the card info) vertically.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Flexbox: A method that gives you control over alignment, space distribution, and the responsive adjustment of elements in a container.
                </li>

                <li>
                Flex Direction: By default, flex items are laid out in the direction of the inline flow (row). flex-direction: column; changes that flow to vertical, which stacks the child elements on top of one another.
                </li>
            </ul>
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 38: Styling the Fish Cards 🐠</h2>

            <p>
            We're giving life to the fish__card by styling it within our bottom__container. This step is crucial for making the cards look good and function well.
            </p>
            <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Add align-items: center; to center the card contents horizontally.
                </li>

                <li>
                Insert justify-content: space-between; to distribute the spacing between the elements inside the card evenly.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Align Items: This property aligns items on the cross axis. Since our flex-direction is column, this means it will center items horizontally.
                </li>

                <li>
                Justify Content: This property defines how space is distributed between and around content items along the main axis (which, in this case, is vertical due to flex-direction: column;). space-between will place equal space between items, pushing the first and last items to the edges.
                </li>
            </ul>
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 39: Defining Card Dimensions 📏✂️</h2>

            <p>
            Now that the inner layout is sorted, we set the dimensions for the fish__card to ensure they are consistent and have the space they need.
            </p>
            <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Specify height: 380px; to give each card a fixed height.
                </li>

                <li>
                Set width: 30%; to allocate width relative to the container, allowing for responsive design as the screen size changes.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Dimensions: The width and height properties specify the width and height of an element, respectively.
                </li>

                <li>
                Percentage Units: Using percentages for width ensures the element's size will be relative to its parent container, aiding responsive design.
                </li>
            </ul>

            <p>
            This step is akin to framing a picture. We've already created our artwork—the fish__card—and now we're putting a frame around it to make sure it's displayed beautifully and consistently.
            </p>
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 40: Adding Cushion 🐠</h2>

            <p>
            Every element on your webpage likes to have a little space of its own, just like fish in an aquarium. In this step, we're going to give our fish cards some "personal space" by using padding and margins in CSS.            
            </p>
            <img src={forty} className='demo__image' alt='img_forty' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Inside this style block, add a padding property and set it to 18px. This will add a cushion inside each fish card around the content.
                </li>

                <li>
                Next, include a margin-bottom property and set it to 26px. This will ensure that each fish card has space below it before the next one starts.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Padding is the space inside the border of an element. It pushes the content away from the edges, like inflating a life vest so it doesn't hug too tight.
                </li>

                <li>
                Margin is the space outside the border. It's like the respect fish give each other by not swimming too close. It separates an element from its neighbors.
                </li>
            </ul>
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 41: Adding Depth with Box Shadows</h2>

            <p>
            Shadows are not just cool effects; they provide depth and help elements stand out on your page. 
            </p>
            <img src={fortyOne} className='demo__image' alt='img_fortyOne' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Stay in the .bottom_container .fish__card style rule in your CSS file.
                </li>

                <li>
                Add a new property called box-shadow.
                </li>

                <li>
                Set the value to 5px 5px 27px 6px rgba(0,0,0,0.44). Each of these values adds a specific aspect to the shadow:
                </li>

                <li>
                    You can copy and paste this long property from the box below.
                </li>
            </ol>

            <CopyBox
            text={"-webkit-box-shadow: 5px 5px 27px 6px rgba(0,0,0,0.44);box-shadow: 5px 5px 27px 6px rgba(0,0,0,0.44);"}
            />

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                The box-shadow property is used to add shadow effects around an element's frame. It can take multiple values to adjust the shadow's appearance.
                </li>

                <li>
                The rgba color format includes an Alpha Channel which controls the opacity of the color, allowing us to make the shadow more subtle or more pronounced.
                </li>

                <li>
                By adjusting the box-shadow properties, you can simulate various light conditions. In our case, we want to mimic a gentle shadow under water.
                </li>
            </ul>
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 42: Creating Our Style Target 🎯</h2>

            <p>
            In this step, we will define our style target to apply future properties effectively.
            </p>
            <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Target the .fish_card class, which is where our individual fish - or content blocks - will reside. Further inside, target the .image__container class. This container will hold the images within our fish cards, much like a clear tank within an aquarium setup.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                CSS Selectors: These are patterns used to select the element(s) you want to style. By using multiple class names separated by spaces, we're specifying a descendant selector. This means we're targeting an element that is nested within other specified elements.
                </li>
            </ul>
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 43: Sizing Our View 📏</h2>

            <p>
            Now that we have our target, it's time to ensure our fish have the perfect view in their tank. We'll do this by setting the width, height, and controlling what happens when the water gets too high (overflow) in our image container.
            </p>
            <img src={fortyThree} className='demo__image' alt='img_fortyThree' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Within the .bottom__container .fish_card .image__container selector that we created in Step 42, set the width property to 90%. This ensures our image container will take up 90% of its parent element, providing a spacious view without overcrowding.
                </li>

                <li>
                Set the height property to 200px to give a consistent height to all our image containers, thus causing the images to fit the size of the containers.
                </li>

                <li>
                Apply the overflow property with a value of hidden. This acts like a glass lid, ensuring that the image doesn't spill out over the container.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Width and Height: These properties define the size of an element. In the context of our project, they control the dimensions of the image container within the fish card.
                </li>

                <li>
                Overflow: This property specifies what should happen if content overflows an element's box. hidden means any content exceeding the boundaries will be clipped, and won't be visible.
                </li>
            </ul>
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 44: Perfectly Placing Our Fish Images 🖼️</h2>

            <p>
            Just like a framed picture of a fish would be centered on the wall of an aquarium, we want our fish images to be perfectly placed within the image_container. In this step, we're going to make sure the images of our fish stretch to cover the full space of the image_container but keep their proportions intact.
            </p>
            <img src={fortyFour} className='demo__image' alt='img_fortyFour' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Find the .bottom__container .fish_card .image_container img selector that you've set your sights on in your CSS file. This selector specifically targets the &lt;img/&gt; elements within our image_container.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                CSS Selectors: These are patterns used to select the element(s) you want to style. By using multiple class names separated by spaces, we're specifying a descendant selector. This means we're targeting an element that is nested within other specified elements.
                </li>
            </ul>
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 45: Ensuring Visual Harmony 🎨</h2>

            <p>
            Now that our fish images are correctly placed, we want to ensure that the viewing experience is harmonious and nothing feels out of place.
            </p>
            <img src={fortyFive} className='demo__image' alt='img_fortyFive' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Set the width property to 100%. This will ensure that our fish images are as wide as their tank, stretching edge to edge for a full view.
                </li>

                <li>
                Apply the height property also to 100%. Our fish will now fill the entire height of the tank without any empty space above or below.
                </li>

                <li>
                To maintain the aspect ratio and prevent our fish from looking stretched or squished, set the object-fit property to contain. This will keep the images' proportions correct, making sure our fish look as they should.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Width and Height: Setting these properties to 100% ensures that the content fills its container completely, both horizontally and vertically.
                </li>

                <li>
                Object-Fit: The contain value scales the content to be as large as possible within the container while keeping its aspect ratio and without cropping the image.
                </li>
            </ul>
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 46: Homing in on the Details 📝</h2>

            <p>
            Just like each fish has distinctive markings, our fish cards have specific sections for detailed information. In this step, we will target the area of our fish card where we want to display these details, known as .card_info.
            </p>
            <img src={fortySix} className='demo__image' alt='img_fortySix' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Now, it's time to target the .card_info class within the .fish_card within the .bottom__container. This specific class is where the details about our fish will swim into view.
                </li>

            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                CSS Class Selectors: Targeting elements with class selectors allows you to apply specific styles to different sections of your webpage.
                </li>
            </ul>
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 47: Structuring Our Card Information 🏗️</h2>

            <p>
            With our .card_info area targeted, it’s now time to structure it. We'll utilize CSS Flexbox to make the information in our fish cards as organized as the rows and columns in a fish identification chart. 
            </p>
            <img src={fortySeven} className='demo__image' alt='img_fortySeven' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Within the .bottom__container .fish_card .card_info selector, set the display property to flex. This will initiate the flexible box layout mode, which is a powerful tool for distributing space and aligning content in ways that web apps and complex webpages often need.
                </li>

                <li>
                Add the flex-direction property with the value column. This tells our flex container to arrange the fish card details in a vertical stack, from top to bottom like a list.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Flexbox: Flexbox is a one-dimensional layout method for laying out items in rows or columns. It’s a tool that allows for the efficient arrangement of items within a container, even when their size is unknown or dynamic.
                </li>

                <li>
                flex-direction: This property defines the direction of the main axis in the flex container. By setting it to column, you tell the browser to stack the flex items vertically.
                </li>
            </ul>
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 48: Spacing Out the Details 📊</h2>


            <img src={fortyEight} className='demo__image' alt='img_fortyEight' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                In your .bottom__container .fish_card .card_info CSS selector, maintain the display: flex and flex-direction: column you’ve already set. This will keep your items in a neat vertical line.
                </li>

                <li>
                Add the justify-content property with the value space-between. This will evenly distribute the information vertically within the card info section, giving space at the start, end, and between each piece of content.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Justify-Content: This property aligns items along the main axis of the current line of the flex container. space-between puts equal spacing between each item, but not between items and the container edges.
                </li>
            </ul>
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 49: Setting the Dimensions 📐</h2>

            <p>
            After arranging our card details neatly, it's time to give the .card_info section a specific size within the fish card. We'll do this by assigning width and height percentages, ensuring it takes up just the right amount of space within our fish card.
            </p>
            <img src={fortyNine} className='demo__image' alt='img_fortyNine' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Inside the .bottom__container .fish_card .card_info selector, specify width: 90%. This will ensure the content does not stretch across the entire width of the fish card, providing a visual margin around the text.
                </li>

                <li>
                Define height: 30% to allocate a third of the vertical space of the fish card to the details, keeping the rest for the image and any other elements you may include.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Width and Height: These properties control the size of elements. A percentage-based value scales the element in relation to its containing block.
                </li>

                <li>
                Responsive Design: By using percentages instead of fixed units like pixels, we ensure our design adapts to different screen sizes and resolutions, much like a fish's fins help it navigate through various environments.
                </li>
            </ul>
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 50: Diving into Typography for Details 📖</h2>

            <p>
            Fish come in all shapes and sizes, and so does text. In this step, we'll focus on the typography within our .card_info section, specifically targeting the paragraph text to ensure our fish details are not only informative but also pleasing to the eye.
            </p>
            <img src={fifty} className='demo__image' alt='img_fifty' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Within the .bottom__container .fish_card .card_info p selector in your CSS file (which you need to create), you’re going to specify the font-size property.
                </li>

                <li>
                Set the font-size to 26px to ensure that the text is large enough to be read easily but not so large that it overwhelms the design.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Typography: In web design, typography is critical for readability and accessibility. Larger font sizes can help highlight important information, while smaller sizes are suitable for less important details.
                </li>

                <li>
                Font-Size: This property specifies the size of the font. Large text can make a statement and is often used for the most important information or calls to action.
                </li>
            </ul>
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 51: Emphasizing Titles with Weight and Size 💪</h2>

            <p>
            Titles are like the names of fish; they need to stand out. This step is all about making sure the titles within our .card_info section grab attention by using size and weight for emphasis.
            </p>
            <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />


            <h3>How to do it:</h3>

            <ol>
                <li>
                Focus on the .bottom__container .fish_card .card_info h3 selector (which you should create right now). Here, we are targeting the headings within our card information, which act as the titles or main points.
                </li>

                <li>
                Set the font-size to 18px which is slightly smaller than the paragraph text but still prominent.
                </li>

                <li>
                Adjust the font-weight to 600, a semi-bold weight that makes these headings stand out more than normal text, but not as heavily as a full bold weight would.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Font-Weight: This property sets how thick or thin characters in text should be displayed. Heavier font weights are often used for titles or important information to draw attention.
                </li>

                <li>
                Hierarchy: In both aquariums and typography, hierarchy is important. It helps establish order and significance. In design, hierarchical differences in font size and weight can help differentiate between types of information.
                </li>
            </ul>
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 52: Hooking the Button Selector 🎣</h2>

            <p>
            We need to ensure that interactive elements like buttons are easy to find and use. In this step, we'll target the button within our fish card's .card_info section to prepare it for styling.
            </p>
            <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />


            <h3>How to do it:</h3>

            <ol>
                <li>
                Dive into your CSS and locate the .bottom__container .fish_card .card_info selector. This path leads us to the specific environment where our button lives.
                </li>

                <li>
                Now, within this section, we need to target the button element directly.
                </li>

                <li>
                Add a new CSS rule for the button element inside .card_info to make sure we're only styling buttons in this specific location.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Specificity: In the CSS ecosystem, specificity is the set of rules that determines which style declarations are applied to an element. By being more specific with our selectors, we can target exactly which elements to style.
                </li>
            </ul>
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 53: Beginning Button Styling 🖌️</h2>

            <p>
            With our button now squarely in our sights, it's time to give it the visual prominence it deserves. In this step, we'll start by setting the height of the button, ensuring it's large enough to be easily clicked by our webpage's visitors.
            </p>
            <img src={fiftyThree} className='demo__image' alt='img_fiftyThree' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Inside the button selector we identified in Step 52, set the height property. This will dictate how tall the button appears, affecting both its aesthetics and usability.
                </li>

                <li>
                Apply a height of 34px. This is a comfortable size for most users to click on without being too large as to disturb the visual flow of the card.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Height: This property is crucial for interactive elements like buttons. A button with adequate height is easier for users to interact with, especially on touch devices.
                </li>
            </ul>
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 54: Refining the Button's Look 🎨</h2>

            <p>
            Every button on our page is like a colorful pebble on the seabed; it should be distinctive and inviting. In this step, we will further refine our button's appearance by removing the default border and setting a soothing background color that reminds us of the ocean.
            </p>
            <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />


            <h3>How to do it:</h3>

            <ol>
                <li>
                Within your button styling, set the border property to none. This strips away the default styling that can often look clunky or out of place.
                </li>

                <li>
                Assign a background color with the property background-color and the value #4287f5, a blue reminiscent of the deep sea that not only looks great but also meets accessibility standards for visibility.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Border: By default, buttons come with a border. Removing it can often lead to a cleaner design, fitting seamlessly with the rest of the site's aesthetic.
                </li>

                <li>
                Background-Color: The choice of color for a button is important, as it needs to stand out but also feel integrated within the site's palette. The chosen blue is vibrant enough to attract clicks without being jarring to the eyes.
                </li>
            </ul>
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 55: Ensuring Readability on the Button ✒️</h2>

            <p>
            After setting the stage with color, we must ensure the text on our button is crystal clear. This step is all about text readability: choosing the right font color and size for the words on our button.
            </p>
            <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                In the CSS for the button, specify the text color property to #fff (white), which will contrast sharply with the blue background for clear legibility.
                </li>

                <li>
                Set the font-size to 18px, which ensures that the text is easily readable without overwhelming the button's visual space.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Color Contrast: Good contrast between the text and background color is crucial for accessibility; it makes sure that all users, including those with visual impairments, can read the button's label easily.
                </li>
            </ul>
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 56: Making the Button Interactive 🖱️</h2>

            <p>
            A button isn't just a part of the scenery; it's meant to be interacted with. In this step, we'll ensure that our users know they can click the button by changing the cursor to a pointer.
            </p>
            <img src={fiftySix} className='demo__image' alt='img_fiftyFive' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Continue styling the .bottom__container .fish_card .card_info button in your CSS.
                </li>

                <li>
                Add the property cursor with the value pointer. This changes the cursor to a hand icon when a user hovers over the button, universally signaling that the element is clickable.
                </li>

                <li>
                Implement a transition property set to 0.2s all ease-in. This smooth transition effect will apply to all changes within the button, adding a touch of finesse to the interaction.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Cursor: The cursor property in CSS controls the mouse cursor appearance on the element. pointer is typically used for clickable items, indicating an action can be taken.
                </li>

                <li>
                Transition: Transitions provide a way to control animation speed when changing CSS properties. They enhance user experience by smoothly guiding users’ visual attention.
                </li>
            </ul>
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 57: Adding a Hover Effect to the Button 🌊</h2>

            <p>
            Just like the changing tides, we want our button to respond to user interaction. Adding a hover effect not only looks cool but also provides immediate visual feedback, enhancing the user experience.
            </p>
            <img src={fiftySeven} className='demo__image' alt='img_fiftyFive' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Target the button with the :hover pseudo-class in your CSS: .bottom__container .fish_card .card_info button:hover.
                </li>

                <li>
                Change the background-color property within this hover selector. Choose a different shade, such as #1c51a7, which is a darker blue, to indicate that the button is being interacted with.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Hover Effect: Hover effects are a staple in web design, providing visual feedback when a user points to an interactive element with their cursor.
                </li>

                <li>
                Pseudo-classes: Pseudo-classes like :hover are used in CSS to define a special state of an element. In this case, it’s the state when a user hovers over the button.
                </li>
            </ul>
        </div>

        <div style={currentStep == 58 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 58: Adding a New Fish to Our Tank 🐟</h2>

            <p>
            Just like a vibrant, well-stocked aquarium, our webpage benefits from variety. In this step, we will duplicate the existing fish card to add a new fish to our collection. We’ll switch up the image to keep our tank diverse and engaging.
            </p>
            <img src={fiftyEight} className='demo__image' alt='img_fiftyFive' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Inside your HTML file, find the div element with the class fish_card. This is the container for our fish card, which we've styled previously.
                </li>

                <li>
                Copy the entire fish_card div to replicate the structure of the card.
                </li>

                <li>
                Paste it below the existing fish_card within the bottom_container to create a second card.
                </li>

                <li>
                Change the src attribute of the &lt;img/&gt; tag to point to a new image file, such as "./images/fish2.png". This should be an image of a different fish to add variety to your page.
                </li>

                <li>
                Update any text as necessary, such as the price or the fish name, to correspond with the new fish image.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                HTML Duplication: Copying and pasting existing HTML elements is a quick way to replicate components on a webpage. It ensures consistency in design and functionality.
                </li>

                <li>
                Content Variation: While the structure of the fish cards remains the same, changing the content (like images and text) is crucial for creating a dynamic and interesting user experience.
                </li>
            </ul>
        </div>

        <div style={currentStep == 59 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 59: Expanding the School 🐟🐟🐟</h2>

            <p>
            A single fish can make a splash, but a school of fish creates a spectacle! Let’s multiply our fish cards to fill our webpage with life. This step involves duplicating our fish card several times to have a total of nine unique cards.
            </p>
            <img src={fiftyNine} className='demo__image' alt='img_fiftyFive' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Inside your HTML file, locate the first div with the class fish_card—this is your template.
                </li>

                <li>
                Copy the entire fish_card div and paste it below the original card eight more times within the bottom_container. You should have a total of nine fish cards.
                </li>

                <li>
                For each new fish card, change the image source (src) to point to a different image file. You will use images like "./images/fish3.png", "./images/fish4.png", and so on, assuming you have these images ready in your project folder.
                </li>

                <li>
                Update any accompanying text, such as the price tag and the fish's name, to match the details of the new fish.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Reusability: In web development, creating reusable templates helps streamline content expansion. By using a consistent structure, we can quickly add new content with minimal effort.
                </li>
            </ul>
        </div>

        <div style={currentStep == 60 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 60: Responsively Sizing Our Aquarium 📏📲</h2>

            <p>
            We need to ensure it looks great not only on large screens but also on smaller devices like tablets and phones. This step introduces a media query to make our fish cards responsive, ensuring that our underwater world adapts to various environments, just like our aquatic friends.
            </p>
            <img src={sixty} className='demo__image' alt='img_fiftyFive' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Scroll down to the bottom of your CSS file to find the @media rule.
                </li>

                <li>
                Within the @media block, you'll write CSS rules that apply only when the browser window is 900 pixels wide or less.
                </li>

                <li>
                We will later start by setting rules within this media query that adjust the layout of .fish_card. For example, you might want to change the width of the fish cards to be 100% to allow each card to take up the full width of the screen on smaller devices.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Media Queries: Media queries are a CSS technique used to apply styling rules based on the conditions of the device's screen size or other features. They are the backbone of responsive design.
                </li>

                <li>
                Responsive Design: This approach aims to create web pages that look good and are usable on all devices, no matter their size or resolution.
                </li>
            </ul>
        </div>

        <div style={currentStep == 61 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 61: Arranging the Fish Cards in a Responsive Grid 🌐</h2>

            <p>
            When our aquatic habitat is viewed on smaller screens, we want to ensure that it remains navigable and visually appealing. In this step, we'll arrange our fish cards into a responsive grid that adapts to the screen size, making sure our digital aquarium is as flexible as a school of fish.
            </p>
            <img src={sixtyOne} className='demo__image' alt='img_fiftyFive' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Using the media query for a maximum width of 900px that you've already set up, target the .fish_card class inside .bottom_container.
                </li>

                <li>
                Set the width property to 48%. This allows two fish cards to fit side by side on the screen, utilizing the available space efficiently.
                </li>

                <li>
                    So now, when the user's screen is a size of 900px or less the .fish__cards will change from the original width to 48%.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Percentage Widths: Using percentages rather than fixed units for widths allows elements to resize fluidly relative to their parent container, providing a more responsive design.
                </li>
            </ul>
        </div>

        <div style={currentStep == 62 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 62: Tailoring for Mobile 📱</h2>

            <p>
            For our smallest screens, we’ll ensure that each fish card has the space to shine on its own. This step teaches how to adjust the fish cards to a full-width layout for an optimal viewing experience on mobile devices.
            </p>
            <img src={sixtyTwo} className='demo__image' alt='img_fiftyFive' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Add a new media query to your CSS for screens with a maximum width of 530px, which typically includes mobile phones in portrait mode.
                </li>

                <li>
                Now any CSS rules we write in here will take place when a user has a screen size of 530px or less.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Mobile-First Design: This is an approach where the design is crafted for the smallest screens first and then progressively enhanced for larger screens, ensuring the site is usable on any device.
                </li>
            </ul>
        </div>

        <div style={currentStep == 63 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 63: Mobile Fish Cards 📱</h2>

            <p>
            On mobile devices, space is at a premium, but that doesn't mean everything should be squeezed together.
            </p>
            <img src={sixtyThree} className='demo__image' alt='img_fiftyFive' />

            <h3>How to do it:</h3>

            <ol>
                <li>
                Go to your media query for a maximum width of 530px, where you will create the rule to set the fish cards to 98%.
                </li>

                <li>
                This is the CSS rule that takes place when the user's screen is 530px or less.
                </li>
            </ol>

            <h3>Concepts to learn:</h3>

            <ul>
                <li>
                Max-Width Media Query: This type of media query applies styles only to screens that are smaller than a specified width, which is essential for creating responsive designs that adapt to various devices.
                </li>
            </ul>
        </div>


        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>

    </div>
  )
}

export default FinFancy