import React from 'react';
import { fullstackProjects } from './data';
import ProjectCard from '../project-card/projectCard';

const FullstackProjectBoard = () => {
  return (
    <div className='projects-container'>
        {
            fullstackProjects?.map((project) => (
                <ProjectCard title={project[0]} coverImage={project[1]} projType={"fullstack"} linky={project[2]} />
            ))
        }
    </div>
  )
}

export default FullstackProjectBoard