import React, {useState} from 'react'
import one from './pythonImages/hangmanimages/1.png';
import two from './pythonImages/hangmanimages/2.png';
import three from './pythonImages/hangmanimages/3.png';
import four from './pythonImages/hangmanimages/4.png';
import five from './pythonImages/hangmanimages/5.png';
import six from './pythonImages/hangmanimages/6.png';
import seven from './pythonImages/hangmanimages/7.png';
import eight from './pythonImages/hangmanimages/8.png';
import nine from './pythonImages/hangmanimages/9.png';
import ten from './pythonImages/hangmanimages/10.png';
import eleven from './pythonImages/hangmanimages/11.png';
import twelve from './pythonImages/hangmanimages/12.png';
import thirteen from './pythonImages/hangmanimages/13.png';
import fourteen from './pythonImages/hangmanimages/14.png';
import fifteen from './pythonImages/hangmanimages/15.png';
import sixteen from './pythonImages/hangmanimages/16.png';
import seventeen from './pythonImages/hangmanimages/17.png';
import eighteen from './pythonImages/hangmanimages/18.png';
import nineteen from './pythonImages/hangmanimages/19.png';
import twenty from './pythonImages/hangmanimages/20.png';
import twentyOne from './pythonImages/hangmanimages/21.png';
import twentyTwo from './pythonImages/hangmanimages/22.png';
import twentyThree from './pythonImages/hangmanimages/23.png';
import twentyFour from './pythonImages/hangmanimages/24.png';
import twentyFive from './pythonImages/hangmanimages/25.png';
import twentySix from './pythonImages/hangmanimages/26.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import './tutorial.css';

const Hangman = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 26;

  return (
    <div className='pythonGames__container'>
        <h1>
        🌟 Welcome to the exciting world of Hangman using Python and Pygame! 🌟
        </h1>

        <h3 className='intro'>
        🌲In this game the stakes are high... as players line up to guess what letters are in a chosen word the fate of our imaginary friend sticky the stick man hangs on the edge. Hold on to your seats folks, we're in for a wild ride!🌲
        </h3>



        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>


        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 1: Readying the Environment🎨🖼️
            </h2>

            <p>
                Before we can start coding this Maze Game Python project, we need to setup proper file structure within Visual Studio Code and download some necessary assets!
            </p>

            <img src={one} className='demo__image' alt='img_one' />

            <ol>
            <li>Create a "Maze Runner" folder to contain your project.</li>

            <li>Open this folder with Visual Studio Code.</li>

            <li>Add a file and name it "maze.py". "maze" being the files name and ".py" letting Visual Studio Code that this is a Python file.</li>

            <li>Add a folder to contain the images that we will download a program for this project.</li>

            <li>Download the images by clicking the button below and storing them in your "images" folder.</li>
            </ol>

            {/*<div className="downloadImageButtonContainer">
                <Download
                    src={hangman0}
                    filename="hangman0.png"
                />

                <Download
                    src={hangman1}
                    filename="hangman1.png"
                />

                <Download
                    src={hangman2}
                    filename="hangman2.png"
                />

                <Download
                    src={hangman3}
                    filename="hangman3.png"
                />

                <Download
                    src={hangman4}
                    filename="hangman4.png"
                />

                <Download
                    src={hangman5}
                    filename="hangman5.png"
                />

                <Download
                    src={hangman6}
                    filename="hangman6.png"
                />
            </div>*/}
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 2: Importing What's Needed 🖥️
            </h2>

            <p>
                Welcome to the first step of creating our amazing Hangman Game using Python and Pygame! Here, we're going to start by laying the groundwork for our coding adventure. 🚀👩‍💻
            </p>

            <img src={two} className='demo__image' alt='img_two' />

            <p>
            What does this code do? 🌟
            </p>

            <ol>
                <li>
                    <strong>import pygame</strong>: Here we're importing Pygame, a set of Python modules designed for writing video games. Think of it as our magic toolbox 🧰✨ for creating graphics, sounds, and managing game dynamics.
                </li>
                <li>
                    <strong>import sys</strong>: This line brings in the 'sys' module, which provides access to some variables used or maintained by the Python interpreter and functions that interact strongly with the interpreter. It's essential for things like quitting the game. 🐍🗣️
                </li>
                <li>
                    <strong>import random</strong>: By importing the 'random' module, we're adding an element of unpredictability to our game. This is crucial for choosing random words in Hangman. 🎲🔀
                </li>
                <li>
                    <strong>from pygame.locals import *</strong>: This line imports specific constants and functions from Pygame, helping us manage game events and controls more easily.
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>import</strong>: The import statement in Python is used to bring in modules (a file containing Python definitions and statements) into the script. It's like saying, "Hey, I need these tools to build something cool!"
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The 'pygame.locals' provides convenient access to various Pygame constants, which can be super helpful for things like keypress events or screen dimensions. It's a bit like having a cheat sheet for your game's controls! 🎮📝
            </h3>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 3: Igniting the Pygame Engine 🚀🔥
            </h2>

            <p>
                Now that we've imported our essential tools, it's time to kick things into gear with Pygame. This next line is short but super important for our game development journey. 🌟🛠️
            </p>

            <img src={three} className='demo__image' alt='img_three' />

            <p>
                What does this line do? 🤔
            </p>

            <ol>
                <li>
                    <strong>pygame.init()</strong>: This is like turning on the engine of your car before you start driving. The <code>pygame.init()</code> function initializes all the Pygame modules we'll be using. It sets up the gaming environment so we can use graphics, sounds, and other cool Pygame features. Without this line, our Pygame car won't start! 🚗💨
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>pygame.init()</strong>: In Python, functions are pieces of code that perform a specific task. When we call a function like <code>pygame.init()</code>, we're telling Python to execute the tasks defined in that function. It's like giving a command to start the show! 🎬🎮
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Initializing Pygame prepares it to work seamlessly with different hardware and software components of your computer. It's a bit like doing a quick check-up before a performance to ensure everything works perfectly! 🎭🖥️
            </h3>
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 4: Setting Up the Stage 🎭🖥️
            </h2>

            <p>
                With our Pygame engine revving, it's time to create the stage where all the Hangman action will take place. Let's set up our game window! 🏗️🖼️
            </p>

            <img src={four} className='demo__image' alt='img_four' />

            <p>
                How do these lines shape our game? 🛠️🌟
            </p>

            <ol>
                <li>
                    <strong>WIDTH, HEIGHT = 800, 600</strong>: Here, we're defining the size of our game window. Think of WIDTH and HEIGHT as the dimensions of our digital canvas. We're setting it to 800 pixels wide and 600 pixels tall, creating a comfortable playing area for our Hangman game. 📏🖼️
                </li>
                <li>
                    <strong>win = pygame.display.set_mode((WIDTH, HEIGHT))</strong>: This line is where the magic happens! We're calling the <code>set_mode</code> function from Pygame's display module, passing in our WIDTH and HEIGHT as a tuple. This function creates a new window (our stage) with the specified dimensions. 'win' is a variable we're using to refer to this window. Every time we want to change something in our game's display, we'll use 'win'. 🪟✨
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Variable Assignment</strong>: In Python, we use the equals sign (<code>=</code>) to assign values to variables. Here, WIDTH and HEIGHT are variables that store the dimensions of our game window.
                </li>
                <li>
                    <strong>Pygame's set_mode Function</strong>: Functions in Python can also belong to modules or libraries. <code>set_mode</code> is a function from the Pygame library that creates a window with the given dimensions.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Choosing the right size for your game window can significantly affect the player's experience. It's like setting the right stage for a play; too small, and the audience might miss out on the details. Too large, and it might not fit everyone's screen! 🎭👀
            </h3>
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 5: Adding the Final Touches to Our Stage 🎭🕒
            </h2>

            <p>
                Great job setting up the window! Now, let's add some finishing touches to our game's display and timing mechanism. These little details are going to make our game more polished and professional. 🖌️⏰
            </p>

            <img src={five} className='demo__image' alt='img_five' />

            <p>
                Let's decode what these lines are doing: 🕵️‍♂️🔍
            </p>

            <ol>
                <li>
                    <strong>pygame.display.set_caption("Hangman")</strong>: This line sets the title of our game window. Just like a book needs a title, our game window needs one too! It will appear at the top and tells everyone, "This is Hangman!" 📖🏷️
                </li>
                <li>
                    <strong>clock = pygame.time.Clock()</strong>: Here, we're creating a 'clock' object using Pygame's time module. This clock will help us control the game's frame rate, ensuring that our game runs smoothly, not too fast or too slow. It's like having a metronome for our game! 🕰️🎶
                </li>
                <li>
                    <strong>FPS = 60</strong>: FPS stands for 'Frames Per Second'. We're setting it to 60, which is a standard for many games. It means our game will try to update its display 60 times every second. This gives us a smooth animation and responsive gameplay. 🖥️💫
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Setting the Window Caption</strong>: The <code>set_caption</code> function is used to set the title of the window, which is an essential part of game window customization.
                </li>
                <li>
                    <strong>Clock Object</strong>: In Pygame, the Clock object is crucial for controlling how fast the game runs. It's used to track and control the time between each frame.
                </li>
                <li>
                    <strong>Frames Per Second (FPS)</strong>: FPS is a common term in video games and animations. It defines how many individual frames (images) are shown in one second. Higher FPS generally means smoother motion in the game.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: While 60 FPS is a standard in gaming, some games go as high as 120 FPS or more for an even smoother experience. However, this requires more processing power and is more demanding on the hardware. 🚀🖥️
            </h3>
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 6: Adding Colors and Fonts to Our Game 🎨🖌️
            </h2>

            <p>
                Now it's time to add some color and style to our Hangman game. These next lines are all about defining the colors we'll use and setting up the font for our text. Let's make our game visually appealing! 🌈✨
            </p>

            <img src={six} className='demo__image' alt='img_six' />

            <p>
                What's happening in these lines? 🤓🔎
            </p>


            <ol>
                <li>
                    <strong>WHITE = (255, 255, 255)</strong> and <strong>BLACK = (0, 0, 0)</strong>: These lines define two colors, white and black, using RGB (Red, Green, Blue) color values. In RGB, colors are represented by a mix of red, green, and blue light. The highest value (255) means full intensity, so WHITE is all colors at full intensity, and BLACK is the absence of color. It's like having a digital palette for our game! 🖌️🎨
                </li>
                <li>
                    <strong>FONT_SIZE = 40</strong>: Here, we're setting the size of the text font we'll use in the game. The number 40 means the text will be fairly large, making it easy to read for our players. It's like choosing the right size brush for a painting! 🖋️🔍
                </li>
                <li>
                    <strong>font = pygame.font.Font(None, FONT_SIZE)</strong>: This line is creating a font object. We use Pygame's font module to create a new Font object with the specified size. By passing 'None' as the first argument, we're telling Pygame to use the default system font. It's like picking the perfect outfit for our game's text! 👔👗
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>RGB Color Values</strong>: RGB is a way to create colors through a combination of red, green, and blue light. Values range from 0 to 255, allowing for a wide range of possible colors.
                </li>
                <li>
                    <strong>Font Object</strong>: In Pygame, fonts are used to render text. A Font object defines the style and size of the text we want to display.
                </li>
            </ul>

            <h3>
            🎈 Did You Know?: The concept of RGB color mixing is used in various devices like computer screens and TVs. It's based on how human eyes perceive light, blending these primary colors to create a spectrum of hues! 🌈👀
            </h3>
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 7: Loading Images into Our Game 🖼️🔍
            </h2>

            <p>
                Every game needs some visuals, and our Hangman game is no exception. In this step, we'll start working on a function to load and scale images. This will help us bring in graphics to make our game more lively and engaging! 🎨👾
            </p>

            <img src={seven} className='demo__image' alt='img_seven' />

            <p>
            Let's break down these lines: 🧐💡
            </p>


            <ol>
                <li>
                    <strong>def load_scaled_image(path, scale):</strong> This line starts the definition of a new function named <code>load_scaled_image</code>. Functions in Python are like recipes; they're sets of instructions that perform a specific task. This particular function will take two parameters: <code>path</code> (the file path to the image we want to load) and <code>scale</code> (how much we want to scale the image). It's our custom tool for handling game graphics! 🛠️📸
                </li>
                <li>
                    <strong>img = pygame.image.load(path)</strong>: Inside our function, this line is the first step. It uses Pygame's image module to load an image from the given path. The image file located at the path is loaded into the variable <code>img</code>. It's like picking a picture from our digital album to put into our game! 📁🖼️
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Function Definition</strong>: The <code>def</code> keyword is used to define a function in Python. A function can take parameters, which are like inputs, and it performs actions based on those inputs.
                </li>
                <li>
                    <strong>Loading Images</strong>: Pygame provides a simple way to load images with the <code>pygame.image.load</code> function. This is crucial for adding visual elements to games.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: In game development, managing graphics efficiently is key to a smooth gaming experience. Functions like <code>load_scaled_image</code> help in optimizing the game's performance by controlling the size and quality of images. 🎮💻
            </h3>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 8: Scaling Images for Our Game 📏🎨
            </h2>

            <p>
                Continuing from where we left off in our image loading function, this step focuses on adjusting the size of our images. Scaling is important to ensure that the images fit well within our game's design and layout. Let's get our images to the perfect size! 🔍🖼️
            </p>

            <img src={eight} className='demo__image' alt='img_eight' />

            <p>
                What is happening in these lines? 🤔✂️
            </p>

            <ol>
                <li>
                    <strong>img_width, img_height = img.get_size()</strong>: This line retrieves the size of the loaded image. The <code>get_size()</code> function returns the width and height of the image, which we store in <code>img_width</code> and <code>img_height</code> variables. It's like measuring the dimensions of a picture frame! 📏🖼️
                </li>
                <li>
                    <strong>scaled_img = pygame.transform.scale(img, (img_width // scale, img_height // scale))</strong>: Here, we're scaling the image. The <code>pygame.transform.scale</code> function resizes the image to new dimensions, which we calculate by dividing the original dimensions by the scale factor. The <code>//</code> operator is integer division, ensuring we get whole numbers for our new dimensions. It's like resizing a photo to fit perfectly in an album! 📐🌌
                </li>
                <li>
                    <strong>return scaled_img</strong>: Finally, our function returns the scaled image. The <code>return</code> statement is used to send the scaled image back to wherever the function was called from. Think of it as delivering the resized picture back to our game's display! 📦🖼️
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Getting Image Size</strong>: The <code>get_size()</code> method is a way to find out the dimensions of an image in Pygame.
                </li>
                <li>
                    <strong>Image Scaling</strong>: Scaling an image is about changing its size. The <code>pygame.transform.scale</code> function is used for this purpose in Pygame, allowing us to fit images into our desired space.
                </li>
                <li>
                    <strong>Return Statement</strong>: In Python, <code>return</code> is used in a function to send back a result to the caller. This is how functions give back processed data.
                </li>
            </ul>

            <h3>
            🎈 Did You Know?: Image scaling is a common technique in game design, allowing developers to use the same graphics in different sizes across various parts of the game, making it both efficient and consistent! 🎮🖌️
            </h3>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 9: Preparing for Hangman Graphics 🎨📚
            </h2>

            <p>
                As we progress, it's time to set up a space for storing our Hangman images and decide on how much we'll scale them down. This step is about preparing for the visuals that will bring our game to life! 🌟👾
            </p>

            <img src={nine} className='demo__image' alt='img_nine' />

            <p>
                Let's explore what these lines do: 🧐👀
            </p>

            <ol>
                <li>
                    <strong>hangman_imgs = []</strong>: This line creates an empty list named <code>hangman_imgs</code>. Lists in Python are like containers where we can store multiple items. In our game, this list will hold all the images related to the hangman graphics – think of it as our digital gallery for hangman art! 🖼️🗂️
                </li>
                <li>
                    <strong>scale_factor = 4</strong>: Here, we define a variable named <code>scale_factor</code> and set it to 4. This number will be used to scale down our images. A scale factor of 4 means we'll reduce the size of our images to a quarter of their original size, making them fit nicely into our game's design. It's like picking the right zoom level for our pictures! 🔍📏
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Creating Lists</strong>: In Python, lists are used to store multiple items in a single variable. They are versatile and can hold various types of data, including images.
                </li>
                <li>
                    <strong>Variable Assignment</strong>: The <code>=</code> symbol is used to assign values to variables. Variables are like named storage locations where we can store data values.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Using lists to manage game assets like images helps keep our code organized and makes it easier to access and modify these assets as needed throughout the game. It's a bit like having a well-organized toolbox for our game development! 🧰🎮
            </h3>
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            
            <h2>
                Step 10: Populating Our Image Gallery 🖼️🔄
            </h2>

            <p>
                It's time to fill our previously created list with Hangman images! In this step, we'll use a loop to load and scale multiple images efficiently, making our game visually dynamic. 🔄👨‍🎨
            </p>

            <img src={ten} className='demo__image' alt='img_ten' />

            <p>
                Let's dive into what each line is accomplishing: 🔍🎯
            </p>

            <ol>
                <li>
                    <strong>for i in range(7):</strong> This line starts a loop that will run 7 times. In Python, <code>for</code> loops are great for performing repetitive tasks, like loading multiple images. Here, <code>i</code> will take on values from 0 to 6, representing each image we want to load. Think of it as going through a checklist, item by item! ✔️📋
                </li>
                <li>
                    <strong>img = load_scaled_image(f"images/hangman&#10100;i&#10101;.png", scale_factor)</strong>: Inside the loop, this line calls our <code>load_scaled_image</code> function for each image. The <code>f"images/hangman&#10100;i&#10101;.png"</code> is a formatted string that changes with each iteration of the loop, loading each hangman image in sequence. It's like picking up a series of pictures from an album, one after another! 🖼️🔄
                </li>
                <li>
                    <strong>hangman_imgs.append(img)</strong>: After scaling each image, we add it to the <code>hangman_imgs</code> list using the <code>append</code> method. This builds up our collection of hangman images, ready to be used in the game. It's like adding photos to our digital gallery, one by one! 📚🖼️
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>For Loops</strong>: In Python, a <code>for</code> loop is used to iterate over a sequence (like a list, a tuple, or a range) and perform actions for each item in that sequence.
                </li>
                <li>
                    <strong>Appending to Lists</strong>: The <code>append</code> method is used to add an item to the end of a list, expanding it with new elements.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Using loops for repetitive tasks not only makes our code more efficient but also helps keep it clean and easier to read. It's a fundamental technique in programming that saves time and effort! 🔄💻
            </h3>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 11: Choosing Our Words Wisely 📚🔤
            </h2>

            <p>
                Every Hangman game needs a collection of words to guess from. In this step, we're going to create a list of words that players will try to guess in our game. Let's build our word bank! 🏦📝
            </p>

            <img src={eleven} className='demo__image' alt='img_eleven' />

            <p>
                What's the significance of this line? 🤔💬
            </p>


            <ol>
                <li>
                    <strong>WORD_LIST = ["python", "pygame", "hangman", "programming", "language"]</strong>: This line creates a list named <code>WORD_LIST</code>. Lists in Python are versatile containers for storing a sequence of items. Here, we're storing words that will be used in our Hangman game. Each item in the list is a string (text) representing a different word that players might have to guess. This specific list is themed around programming and game development – a fun nod to what we're doing right now! 🎲🧠
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Lists and Strings</strong>: A list in Python is defined with square brackets (<code>[]</code>), and it can contain items of various types. In our case, it's strings, which are sequences of characters enclosed in quotes.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: In Python, lists are a powerful way to store and manipulate collections of items, like words in our game. They are an essential tool for many programming tasks, offering flexibility and a wide range of methods for managing data. 📚🔧
            </h3>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 12: Setting Up for the Guessing Game 🤔✨
            </h2>

            <p>
                Now that we have our list of words, let's set up for the main event - the guessing game! In this step, we'll choose a random word from our list and prepare to track the player's guesses. Get ready for some serious detective work! 🔍🕵️‍♂️
            </p>

            <img src={twelve} className='demo__image' alt='img_twelve' />

            <p>
                Let's unpack what these lines do: 📦🔎
            </p>

            <ol>
                <li>
                    <strong>word = random.choice(WORD_LIST)</strong>: This line picks a random word from our <code>WORD_LIST</code>. The <code>random.choice()</code> function, from the random module we imported earlier, does exactly that – it selects a random item from a list. It’s like drawing a lucky word out of a hat for each new game! 🎩🎟️
                </li>
                <li>
                    <strong>guessed_letters = []</strong>: Here, we create an empty list named <code>guessed_letters</code>. This list will keep track of all the letters that the player guesses throughout the game. It's like having a notepad to jot down all our guesses. 📝✏️
                </li>
                <li>
                    <strong>correct_guesses = []</strong>: Similarly, <code>correct_guesses</code> is another empty list. This one will store all the correct letters guessed by the player. It helps us keep track of how close the player is to solving the word. It's like marking off the right answers in a quiz! ✅📚
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Random Selection</strong>: The <code>random.choice()</code> function is a convenient way to pick a random item from a list, adding an element of unpredictability and fun to games.
                </li>
                <li>
                    <strong>Creating Lists</strong>: In Python, creating an empty list is as simple as setting a variable to <code>[]</code>. These lists are dynamic and can be added to as the game progresses.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Tracking guessed letters and correct answers separately helps in the logic of many word games. It allows the game to give feedback to the player on their progress and keeps the gameplay engaging and challenging! 🎮💡
            </h3>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 13: Drawing on the Canvas 🎨🖌️
            </h2>

            <p>
                With all the groundwork done, it's time to bring our game to life visually! In this step, we start building the 'draw' function, which will be responsible for displaying our game's graphics on the screen. Let's get creative! 🌈👩‍🎨
            </p>

            <img src={thirteen} className='demo__image' alt='img_thirteen' />

            <p>
            Let's explore the purpose of these lines: 🖼️🔍
            </p>

            <ol>
                <li>
                    <strong>def draw():</strong> This line defines a new function named <code>draw</code>. In programming, a function is like a mini-program that we can call whenever we need to perform its task. The <code>draw</code> function will handle everything related to drawing on our game window. It's like appointing an artist to paint our game scene! 🎨👨‍🎨
                </li>
                <li>
                    <strong>win.fill(WHITE)</strong>: Inside the function, this line fills the entire game window with a white background. We're using the <code>fill</code> method of our window object (win), which we created earlier. By filling it with white (defined by our RGB value), we clear the screen and provide a blank canvas for our game's graphics. It's like starting with a fresh page in a sketchbook! 📄🖌️
                </li>
                <li>
                    <strong>display_word = ""</strong>: Here, we start to create a variable named <code>display_word</code>. This variable will be used to store and display the word (or parts of it) that the player needs to guess. Right now, it's just an empty string, but we'll build upon it as the game progresses. It's like setting up a placeholder for our puzzle's solution! 🧩🔤
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Function Definition</strong>: In Python, <code>def</code> is used to define a function. Functions are reusable blocks of code that perform a specific task.
                </li>
                <li>
                    <strong>Filling the Screen</strong>: The <code>fill</code> method is used to fill the entire surface of a Pygame window with a single color, effectively clearing it or setting a background color.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Clearing the screen at the beginning of each frame and redrawing everything is a common technique in game development. It ensures that graphics are updated correctly and keeps the display looking clean and dynamic! 🖥️🔄
            </h3>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 14: Revealing the Puzzle, Letter by Letter 🧩🔡
            </h2>

            <p>
                Our Hangman game is all about guessing letters in a word. In this step, we'll work on how the game displays the word based on the player's correct guesses. It's like slowly unveiling a mystery! 🔍🎭
            </p>

            <img src={fourteen} className='demo__image' alt='img_fourteen' />

            <p>
            Let's decode what's happening here: 🕵️‍♂️📝
            </p>

            <ol>
                <li>
                    <strong>for letter in word:</strong> This line starts a loop that goes through each letter in the chosen word. Loops are great for when we need to check or perform an action on each item in a collection, like the letters in our word.
                </li>
                <li>
                    <strong>if letter in correct_guesses:</strong> Here, we check if the current letter is in the list of letters the player has guessed correctly. It's like checking off items on a treasure hunt list! 🗺️✅
                </li>
                <li>
                    <strong>display_word += letter + " "</strong>: If the player has guessed the letter correctly, we add that letter (plus a space for readability) to our <code>display_word</code>. It's like filling in the blanks on a crossword puzzle. 🧩🔤
                </li>
                <li>
                    <strong>else:</strong>: This part of the loop handles what happens if the letter hasn't been guessed yet.
                </li>
                <li>
                    <strong>display_word += "_ "</strong>: If the letter is not yet guessed, we add an underscore (representing an unknown letter) to our <code>display_word</code>. This keeps the mystery alive for the player, inviting more guesses! 🕵️‍♂️🤔
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Loops</strong>: The <code>for</code> loop in Python allows us to iterate over each element in a sequence, like the letters in our word.
                </li>
                <li>
                    <strong>Conditional Statements</strong>: The <code>if</code> statement lets us execute certain code only if a specific condition is true. In this case, we're checking if a letter is in the list of correct guesses.
                </li>
                <li>
                    <strong>String Concatenation</strong>: We use <code>+=</code> to add characters to our <code>display_word</code> string, either the actual letter or an underscore as a placeholder.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The use of underscores as placeholders for unknown letters is a classic element in Hangman games. It visually represents the challenge and progress in guessing the word! 🖋️🧐
            </h3>
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 15: Bringing Text to Life on Screen 📝🖥️
            </h2>

            <p>
                After preparing the word display with guessed letters and placeholders, it's time to render this text onto our game screen. In this step, we'll use Pygame's font capabilities to create a visual representation of our <code>display_word</code>. Let's make our words shine! ✨🔠
            </p>

            <img src={fifteen} className='demo__image' alt='img_fifteen' />

            <p>
                Understanding the magic behind this line: 🧙‍♂️📖
            </p>

            <ol>
                <li>
                    <strong>text = font.render(display_word.strip(), True, BLACK)</strong>: This line is where the text rendering happens. Let's break it down:
                    <ul>
                        <li><strong>font.render(…)</strong>: We're using the <code>font</code> object we created earlier. The <code>render</code> method of a font object in Pygame is used to create an image of the text with the specified font and size.</li>
                        <li><strong>display_word.strip()</strong>: <code>strip()</code> is a string method that removes any leading and trailing spaces from our word. This ensures our text looks neat when displayed.</li>
                        <li><strong>True</strong>: This argument in the <code>render</code> method is about anti-aliasing, which makes the edges of the text smoother and more readable.</li>
                        <li><strong>BLACK</strong>: Here, we specify the color of our text. We're using the BLACK color we defined earlier (0, 0, 0 in RGB).</li>
                    </ul>
                    Essentially, this line creates a graphical representation of our <code>display_word</code>, ready to be drawn on the screen. It's like turning our digital notes into a beautiful, readable format! 🎨📄
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Rendering Text</strong>: In Pygame, rendering text involves converting strings into images that can be displayed on the screen. The <code>render</code> method is key to this process.
                </li>
                <li>
                    <strong>Anti-aliasing</strong>: Anti-aliasing is a technique used in digital graphics to reduce the appearance of jagged edges in images and text, making them smoother and more visually appealing.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Text rendering in games is not just about displaying letters; it's about creating an interface that communicates with the player effectively. The right font, size, and color can significantly enhance the gaming experience! 🎮👁️‍🗨️
            </h3>
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 16: Displaying Text and Images on Screen 🎭📺
            </h2>

            <p>
                Now that we have our text ready, let's put it on the stage! In this step, we're going to display the text and the hangman image on our game window. This is where our graphics come to life. 🌟🖼️
            </p>

            <img src={sixteen} className='demo__image' alt='img_sixteen' />

            <p>
                Let's delve into the details of these lines: 🕵️‍♂️👨‍🎨
            </p>

            <ol>
                <li>
                    <strong>win.blit(text, (WIDTH // 2 - text.get_width() // 2, HEIGHT // 2))</strong>: This line places the rendered text onto our game window.
                    <ul>
                        <li><strong>win.blit(…)</strong>: The <code>blit</code> method is used to draw one image onto another. Here, we're drawing our text image onto the game window (win).</li>
                        <li><strong>(WIDTH // 2 - text.get_width() // 2, HEIGHT // 2)</strong>: This is the position where the text will be placed. We're calculating the x-coordinate so that the text is centered on the screen, taking into account the width of the text. The y-coordinate is set to half the window's height, placing the text vertically in the middle.</li>
                    </ul>
                    It's like hanging a painting perfectly in the center of a wall! 🖼️🎨
                </li>
                <li>
                    <strong>win.blit(hangman_imgs[len(guessed_letters)], (50, 50))</strong>: Here, we're displaying the current hangman image on the screen.
                    <ul>
                        <li>The <code>hangman_imgs[len(guessed_letters)]</code> selects the hangman image based on the number of guessed letters. As the number of incorrect guesses increases, a different image is displayed, showing the hangman's progression.</li>
                        <li>The coordinates <code>(50, 50)</code> set the position where the image will be displayed on the window.</li>
                    </ul>
                    This line brings visual feedback to the player's progress (or lack thereof) in the game! 🎲🚦
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>
            <ul>
                <li>
                    <strong>Drawing with blit</strong>: The <code>blit</code> function is fundamental in Pygame for drawing images (or text rendered as images) on the screen. It's how we paint our digital canvas.
                </li>
                <li>
                    <strong>Positioning Elements</strong>: Placing elements on the screen involves specifying x and y coordinates. Getting these right is key to a well-arranged game interface.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The art of positioning elements on a game screen is like setting the stage for a play. Each element needs to be in the right place for the story to unfold smoothly and engagingly! 🎭👾
            </h3>
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 17: Refreshing the Game Screen 🔄🖥️
            </h2>

            <p>
                With our text and images now placed on the screen, there's just one more crucial step to make them visible to the player. Let's update the game display! 🌟📺
            </p>

            <img src={seventeen} className='demo__image' alt='img_seventeen' />

            <p>
                Understanding the significance of this line: 🧐👀
            </p>

            <ol>
                <li>
                    <strong>pygame.display.update()</strong>: This line is essential in any Pygame program. It updates the entire surface of the display. In simpler terms, it refreshes our game window to reflect any changes made (like new text or images drawn). Until this function is called, all our blitting and drawing operations are not actually visible on the screen. It's like flipping the page in a book to reveal the next scene in our story! 📖➡️
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Updating the Display</strong>: The <code>pygame.display.update()</code> function is crucial for making sure that everything we've drawn or changed on the game window is actually displayed. Without it, our changes would remain invisible.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: In computer graphics, the process of updating the display is often referred to as 'refreshing' the screen. This is what makes animations and movements in games appear smooth and continuous. It's a pivotal part of creating an engaging and dynamic game experience! 🎮💻
            </h3>
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 18: Capturing Player Input 🎮👂
            </h2>

            <p>
                As our game takes shape, it's time to interact with our players. In this step, we'll start building a function to capture the player's letter guesses. This is where we bridge the gap between the player and the game! 🌉⌨️
            </p>

            <img src={eighteen} className='demo__image' alt='img_eighteen' />

            <p>
            Let's explore the purpose of these lines: 🕵️‍♀️🔍
            </p>

            <ol>
                <li>
                    <strong>def get_letter():</strong> This line defines a new function named <code>get_letter</code>. Functions are like tools we create to perform specific tasks. The <code>get_letter</code> function will be responsible for getting the letter guessed by the player. It's like setting up a mailbox to receive messages! 📬💌
                </li>
                <li>
                    <strong>while True:</strong> This line starts an infinite loop. In programming, a <code>while</code> loop keeps running as long as its condition is true. Here, <code>True</code> is always true, so the loop will run indefinitely. We use such loops when we want to keep doing something until a specific condition is met, like waiting for the player to make a guess. It's like having a reception desk that's always open! 🔄🏪
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Function Definition</strong>: In Python, we define a function using the <code>def</code> keyword, followed by the function name and parentheses. Functions can be called upon to execute their defined tasks.
                </li>
                <li>
                    <strong>Infinite Loops</strong>: An infinite loop runs continuously unless it's stopped by a break statement or other control flow. It's useful for constantly checking or waiting for an event, like a player's input.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Infinite loops are a common pattern in game development for continuously checking user input or game state. It's crucial, however, to manage them correctly to avoid freezing the game! 💻🎮
            </h3>
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 19: Detecting Key Presses and Reacting 🎮🔑
            </h2>

            <p>
                We're in the heart of interactivity now! In this step, our function continues to listen for player inputs. We'll detect when a key is pressed and react accordingly. It's like tuning in to a radio signal and waiting for a specific message! 📻🔊
            </p>

            <img src={nineteen} className='demo__image' alt='img_nineteen' />

            <p>
            Let's break down these lines: 🧩👨‍🏫
            </p>

            <ol>
                <li>
                    <strong>for event in pygame.event.get():</strong> This line starts a loop that checks for events. In Pygame, an event is any action or occurrence detected by the game, like a key press or a mouse click. The <code>pygame.event.get()</code> function grabs all the events that have happened since the last time it was called. It's like opening your mailbox to see if there's any new mail! 📬✉️
                </li>
                <li>
                    <strong>if event.type == KEYDOWN:</strong> Inside the loop, we're checking if any of the events are a 'key down' event, which means a keyboard key was pressed. The <code>if</code> statement filters out only the events where a key is pressed down. It's like scanning through the mail to find a letter from a friend! 💌🔍
                </li>
                <li>
                    <strong>return event.unicode</strong>: When we detect a key press, this line returns the character associated with the key that was pressed. The <code>unicode</code> attribute of the event object holds the character representation of the key. This is the function sending back the letter guessed by the player. It's like reading the letter and finding out what message your friend sent! 📖💬
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Event Loop</strong>: The event loop is a fundamental concept in many graphical applications and games. It continually checks for user interactions and handles them appropriately.
                </li>
                <li>
                    <strong>Checking for Key Presses</strong>: In Pygame, detecting key presses is done by checking the event type. The KEYDOWN event type signifies that a key on the keyboard has been pressed.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Handling user input correctly is crucial in creating an interactive and responsive gaming experience. It's what makes the game feel alive and responsive to the player! 🕹️👾
            </h3>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 20: Handling Game Exit 🚪🔚
            </h2>

            <p>
                Just as important as starting a game is knowing how to end it gracefully. In this step, we add the functionality to properly exit our game when the player chooses to. It's like having a neat and respectful way to say goodbye! 👋🏻🚪
            </p>

            <img src={twenty} className='demo__image' alt='img_twenty' />

            <p>
            Let's examine these lines: 🕵️🧐
            </p>

            <ol>
                <li>
                    <strong>elif event.type == QUIT:</strong> This line checks if the event type is QUIT. In Pygame, the QUIT event is triggered when the game window's close button is clicked. It's like detecting when someone is about to leave a room. 🚶‍♂️🚪
                </li>
                <li>
                    <strong>pygame.quit()</strong>: When a QUIT event is detected, this function is called. <code>pygame.quit()</code> deactivates the Pygame library. Think of it as turning off the lights and closing up shop. It's a way to cleanly shut down all Pygame modules. 💡🔒
                </li>
                <li>
                    <strong>sys.exit()</strong>: This line exits the program entirely. <code>sys.exit()</code> is a Python function that stops the script from running. After cleaning up with <code>pygame.quit()</code>, we use <code>sys.exit()</code> to leave the building, ensuring our program ends without lingering processes. 🏃‍♂️💨
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>QUIT Event</strong>: The QUIT event in Pygame is an important part of managing the game's lifecycle, especially for handling window closure events properly.
                </li>
                <li>
                    <strong>Cleanup and Exit</strong>: <code>pygame.quit()</code> and <code>sys.exit()</code> are used together to exit a Pygame application cleanly, ensuring all resources are freed and the application is closed properly.
                </li>
            </ul>

            <h3>
            🎈 Did You Know?: Properly closing a game or application is crucial for resource management in computing. It prevents issues like memory leaks and ensures the software behaves predictably. It's like leaving a room tidy for the next person! 🧹✨
            </h3>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            
            <h2>
                Step 21: The Game Loop - Keeping Our Game Alive 🔄🎮
            </h2>

            <p>
                Every game needs a heartbeat, a continuous cycle that keeps the action going. In this step, we establish our game loop, the core of our game that keeps everything running smoothly. It's like setting up the rhythm for a dance! 💃🕺
            </p>

            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />

            <p>
            Let's dive into these lines and their roles in the game: 🏊‍♂️🎲
            </p>

            <ol>
                <li>
                    <strong>while True:</strong> This line starts an infinite loop, the heart of our game. In this loop, we'll continuously update our game's state and redraw the screen. This is what keeps our game running until the player decides to quit. It's like the continuous ticking of a clock keeping time. ⏳🔄
                </li>
                <li>
                    <strong>clock.tick(FPS)</strong>: Here, we control the game's frame rate with the <code>tick</code> method of our clock object. By passing in the FPS (Frames Per Second) value, we tell Pygame how often to update the game. This makes sure our game runs smoothly and not too fast or too slow. It's like setting the tempo for a song! 🎵⏱️
                </li>
                <li>
                    <strong>draw()</strong>: Finally, this line calls the <code>draw</code> function we defined earlier. Each time through the loop, we redraw the screen with any updates to the game state. It's like repainting a picture or rewriting a story with every tick of the clock. 🎨📖
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Infinite Game Loop</strong>: The infinite loop is a common pattern in games to continuously process user input, update game state, and render graphics.
                </li>
                <li>
                    <strong>Frame Rate Control</strong>: Controlling the frame rate is essential in game design for ensuring consistent gameplay experience across different devices.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The game loop is a fundamental concept in game programming. It's what allows games to react to player actions, update their state, and redraw graphics in real-time. It's the pulse that keeps the game world alive and responsive! 🌍💓
            </h3>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 22: Getting the Player's Guess 🤔🔠
            </h2>

            <p>
                Our game loop is in motion, and now it's time to engage with our player. In this step, we'll capture the player's letter guess. It's like opening a line of communication between the player and the game! 📞✉️
            </p>

            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />

            <p>
            Let's understand the significance of this line: 🧐📚
            </p>

            <ol>
                <li>
                    <strong>guess = get_letter()</strong>: This line calls the <code>get_letter</code> function we defined earlier. The function waits for the player to press a key and returns the character associated with that key. Here, we store the returned character in a variable named <code>guess</code>. This variable now holds the player's current guess. It's like catching a thrown ball – the ball being the player's guess! 🏐👐
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Function Call</strong>: Calling a function in Python is done by writing the function's name followed by parentheses. If the function returns a value, it can be stored in a variable, as we do here with <code>guess</code>.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The ability to interact with the game through guessing letters is what makes Hangman engaging and interactive. It turns a simple game into a fun test of vocabulary and deduction! 🕵️‍♂️📖
            </h3>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 23: Processing the Guess and Updating the Game 🕵️‍♀️🔠
            </h2>

            <p>
                After receiving the player's guess, it's crucial to process it correctly. In this step, we'll evaluate the guess and update our game based on whether it's a new and valid letter. It's like solving a piece of the puzzle! 🧩🔍
            </p>

            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />

            <p>
            Exploring the logic behind these lines: 🧠🔬
            </p>

            <ol>
                <li>
                    <strong>if guess.isalpha() and guess not in guessed_letters and guess not in correct_guesses:</strong> This line checks several conditions:
                    <ul>
                        <li><strong>guess.isalpha()</strong>: Confirms if the guess is a letter (not a number or symbol).</li>
                        <li><strong>guess not in guessed_letters</strong>: Checks if the guess hasn't already been made as an incorrect guess.</li>
                        <li><strong>guess not in correct_guesses</strong>: Ensures the guess is also not already made as a correct guess.</li>
                    </ul>
                    It's like filtering out only the fresh and relevant guesses. 🌐🚫
                </li>
                <li>
                    <strong>if guess not in word:</strong> This nested condition checks if the guessed letter is not in the word to be guessed.
                </li>
                <li>
                    <strong>guessed_letters.append(guess)</strong>: If the guessed letter is not part of the word, it's added to the <code>guessed_letters</code> list. This way, we keep track of all incorrect guesses. It's like making a note of what doesn't work in a detective's notebook. 📓❌
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Checking Conditions</strong>: The <code>if</code> statement in Python is used to execute a block of code only if the specified condition is true.
                </li>
                <li>
                    <strong>Appending to a List</strong>: The <code>append</code> method adds a new item to the end of a list, allowing us to update our list of guessed letters.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Careful tracking of guessed letters is a key element in Hangman. It adds a strategic layer to the game, as players must remember their previous guesses and think critically about their next move. 🧠♟️
            </h3>
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 24: Handling a Loss in the Game 😔🚫
            </h2>

            <p>
                Not every game can be won, and in Hangman, incorrect guesses can lead to a loss. In this step, we'll handle the scenario where the player runs out of chances. It's about managing the end of the game when things don't go as hoped. 💔🏁
            </p>

            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />

            <p>
            Let's break down what these lines do: 🕵️‍♂️👨‍🏫
            </p>

            <ol>
                <li>
                    <strong>if len(guessed_letters) == len(hangman_imgs) - 1:</strong> This condition checks if the number of incorrect guesses equals the number of hangman images minus one. In our game, each incorrect guess reveals a new part of the hangman image. When they reach the maximum number of parts, it means the player has used up all their chances. It's like counting down to the last straw. ⏳🚫
                </li>
                <li>
                    <strong>print("You lost!")</strong>: If the condition is met, we inform the player that they have lost the game. It's a straightforward message indicating the game's end. 📢😢
                </li>
                <li>
                    <strong>pygame.quit()</strong> and <strong>sys.exit()</strong>: These lines close the Pygame window and exit the program, respectively. It's the way we gracefully end the game and the program when the player loses. 🚪💔
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>
            <ul>
                <li>
                    <strong>Conditional Statement</strong>: The <code>if</code> statement allows us to execute certain code only when a specific condition is true.
                </li>
                <li>
                    <strong>Game Over Handling</strong>: Handling the end of the game when a player loses is crucial for providing closure and a complete game experience.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: In game design, it's important to handle both winning and losing scenarios. This not only makes a game fair and challenging but also provides a complete experience, encouraging players to try again. 🎮🎲
            </h3>
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 25: Recording Correct Guesses 🎯✅
            </h2>

            <p>
                Every correct guess brings the player closer to victory. In this step, we'll focus on handling the situation when the player guesses a letter correctly. It's about acknowledging success and updating the game state accordingly. 🌟📝
            </p>

            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />

            <p>
                Understanding the purpose of these lines: 🧠💡
            </p>

            <ol>
                <li>
                    <strong>else:</strong> This line is part of the conditional statement we started earlier. It signifies the alternative path: if the guess is not an incorrect one (not in the word), then we assume it's a correct guess.
                </li>
                <li>
                    <strong>correct_guesses.append(guess)</strong>: Here, we add the correctly guessed letter to the <code>correct_guesses</code> list. This is like putting a checkmark next to a correctly answered question. It keeps track of all the letters the player has correctly guessed so far, helping to reveal the hidden word in the game. ✅🔠
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Appending to a List</strong>: The <code>append</code> method is used to add items to the end of a list. It's an essential way to dynamically update the content of a list in Python.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Tracking correct guesses is a fundamental part of word guessing games. It adds a layer of excitement and satisfaction, as players see their progress in unraveling the word with each correct guess! 🧩🎉
            </h3>
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 26: Celebrating a Victory 🎉🏆
            </h2>

            <p>
                Victory is sweet, especially in a game of Hangman! In this step, we'll handle the joyous occasion when the player correctly guesses all the letters in the word. It's about rewarding success and wrapping up the game on a high note. 🌟🎈
            </p>

            <img src={twentySix} className='demo__image' alt='img_twentySix' />

            <p>
            Let's delve into these lines and their impact on the game: 🕵️‍♀️🏁
            </p>

            <ol>
                <li>
                    <strong>if all(letter in correct_guesses for letter in word):</strong> This line checks if every letter in the word has been correctly guessed. The <code>all()</code> function in Python returns True if all elements in an iterable (like our loop here) are true. This is like completing a puzzle where every piece fits perfectly. 🧩✨
                </li>
                <li>
                    <strong>print("You won!")</strong>: When the condition is met, we celebrate the player's win with a congratulatory message. It's a simple yet effective way to acknowledge the player's achievement. 🏆🎊
                </li>
                <li>
                    <strong>pygame.quit()</strong> and <strong>sys.exit()</strong>: These lines gracefully end the game and the program. After a win, it's important to close everything properly, just like after a loss. This ensures a smooth and complete game experience, from start to finish. 🎬👋
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Checking All Conditions</strong>: The <code>all()</code> function is a powerful tool in Python for checking if all conditions in a sequence are met.
                </li>
                <li>
                    <strong>Game Completion</strong>: Handling game completion, whether in victory or defeat, is essential for providing a full and satisfying game experience.
                </li>
            </ul>

            <h3>
            🎈 Did You Know?: Celebrating victories, no matter how small, is a key aspect of game design that helps in keeping players engaged and motivated. It's these moments of triumph that often make games memorable and enjoyable! 🎮🌟
            </h3>
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>

    </div>
  )
}

export default Hangman