import React, {useState} from 'react';
import one from './robloxImages/JUMPQUEST/1.png';
import two from './robloxImages/JUMPQUEST/2.png';
import three from './robloxImages/JUMPQUEST/3.png';
import four from './robloxImages/JUMPQUEST/4.png';
import five from './robloxImages/JUMPQUEST/5.png';
import six from './robloxImages/JUMPQUEST/6.png';
import seven from './robloxImages/JUMPQUEST/7.png';
import eight from './robloxImages/JUMPQUEST/8.png';
import nine from './robloxImages/JUMPQUEST/9.png';
import ten from './robloxImages/JUMPQUEST/10.png';
import eleven from './robloxImages/JUMPQUEST/11.png';
import twelve from './robloxImages/JUMPQUEST/12.png';
import thirteen from './robloxImages/JUMPQUEST/13.png';
import fourteen from './robloxImages/JUMPQUEST/14.png';
import fifteen from './robloxImages/JUMPQUEST/15.png';
import sixteen from './robloxImages/JUMPQUEST/16.png';
import seventeen from './robloxImages/JUMPQUEST/17.png';
import eighteen from './robloxImages/JUMPQUEST/18.png';
import nineteen from './robloxImages/JUMPQUEST/19.png';
import twenty from './robloxImages/JUMPQUEST/20.png';
import twentyOne from './robloxImages/JUMPQUEST/21.png';
import twentyTwo from './robloxImages/JUMPQUEST/22.png';
import twentyThree from './robloxImages/JUMPQUEST/23.png';
import twentyFour from './robloxImages/JUMPQUEST/24.png';
import twentyFive from './robloxImages/JUMPQUEST/25.png';
import twentySix from './robloxImages/JUMPQUEST/26.png';
import twentySeven from './robloxImages/JUMPQUEST/27.png';
import twentyEight from './robloxImages/JUMPQUEST/28.png';
import twentyNine from './robloxImages/JUMPQUEST/29.png';
import thirty from './robloxImages/JUMPQUEST/30.png';
import thirtyOne from './robloxImages/JUMPQUEST/31.png';
import thirtyTwo from './robloxImages/JUMPQUEST/32.png';
import thirtyThree from './robloxImages/JUMPQUEST/33.png';
import thirtyFour from './robloxImages/JUMPQUEST/34.png';
import thirtyFive from './robloxImages/JUMPQUEST/35.png';
import thirtySix from './robloxImages/JUMPQUEST/36.png';
import thirtySeven from './robloxImages/JUMPQUEST/37.png';
import thirtyEight from './robloxImages/JUMPQUEST/38.png';
import thirtyNine from './robloxImages/JUMPQUEST/39.png';
import forty from './robloxImages/JUMPQUEST/40.png';
import fortyOne from './robloxImages/JUMPQUEST/41.png';
import fortyTwo from './robloxImages/JUMPQUEST/42.png';
import fortyThree from './robloxImages/JUMPQUEST/43.png';
import fortyFour from './robloxImages/JUMPQUEST/44.png';
import fortyFive from './robloxImages/JUMPQUEST/45.png';
import fortySix from './robloxImages/JUMPQUEST/46.png';
import fortySeven from './robloxImages/JUMPQUEST/47.png';
import fortyEight from './robloxImages/JUMPQUEST/48.png';
import fortyNine from './robloxImages/JUMPQUEST/49.png';
import fifty from './robloxImages/JUMPQUEST/50.png';
import fiftyOne from './robloxImages/JUMPQUEST/51.png';
import fiftyTwo from './robloxImages/JUMPQUEST/52.png';
import fiftyThree from './robloxImages/JUMPQUEST/53.png';
import fiftyFour from './robloxImages/JUMPQUEST/54.png';
import fiftyFive from './robloxImages/JUMPQUEST/55.png';
import fiftySix from './robloxImages/JUMPQUEST/56.png';
import fiftySeven from './robloxImages/JUMPQUEST/57.png';
import fiftyEight from './robloxImages/JUMPQUEST/58.png';
import fiftyNine from './robloxImages/JUMPQUEST/59.png';
import sixty from './robloxImages/JUMPQUEST/60.png';
import sixtyOne from './robloxImages/JUMPQUEST/61.png';
import sixtyTwo from './robloxImages/JUMPQUEST/62.png';
import sixtyThree from './robloxImages/JUMPQUEST/63.png';
import sixtyFour from './robloxImages/JUMPQUEST/64.png';
import sixtyFive from './robloxImages/JUMPQUEST/65.png';
import sixtySix from './robloxImages/JUMPQUEST/66.png';
import sixtySeven from './robloxImages/JUMPQUEST/67.png';
import sixtyEight from './robloxImages/JUMPQUEST/68.png';
import sixtyNine from './robloxImages/JUMPQUEST/69.png';
import seventy from './robloxImages/JUMPQUEST/70.png';
import seventyOne from './robloxImages/JUMPQUEST/71.png';
import seventyTwo from './robloxImages/JUMPQUEST/72.png';
import seventyThree from './robloxImages/JUMPQUEST/73.png';
import seventyFour from './robloxImages/JUMPQUEST/74.png';
import seventyFive from './robloxImages/JUMPQUEST/75.png';
import seventySix from './robloxImages/JUMPQUEST/76.png';
import seventySeven from './robloxImages/JUMPQUEST/77.png';
import seventyEight from './robloxImages/JUMPQUEST/78.png';
import seventyNine from './robloxImages/JUMPQUEST/79.png';
import eighty from './robloxImages/JUMPQUEST/80.png';
import eightyOne from './robloxImages/JUMPQUEST/81.png';
import eightyTwo from './robloxImages/JUMPQUEST/82.png';
import eightyThree from './robloxImages/JUMPQUEST/83.png';
import eightyFour from './robloxImages/JUMPQUEST/84.png';
import eightyFive from './robloxImages/JUMPQUEST/85.png';
import eightySix from './robloxImages/JUMPQUEST/86.png';
import eightySeven from './robloxImages/JUMPQUEST/87.png';
import eightyEight from './robloxImages/JUMPQUEST/88.png';
import eightyNine from './robloxImages/JUMPQUEST/89.png';
import ninety from './robloxImages/JUMPQUEST/90.png';
import ninetyOne from './robloxImages/JUMPQUEST/91.png';
import ninetyTwo from './robloxImages/JUMPQUEST/92.png';
import ninetyThree from './robloxImages/JUMPQUEST/93.png';
import ninetyFour from './robloxImages/JUMPQUEST/94.png';
import ninetyFive from './robloxImages/JUMPQUEST/95.png';
import ninetySix from './robloxImages/JUMPQUEST/96.png';
import ninetySeven from './robloxImages/JUMPQUEST/97.png';
import ninetyEight from './robloxImages/JUMPQUEST/98.png';
import ninetyNine from './robloxImages/JUMPQUEST/99.png';
import hundred from './robloxImages/JUMPQUEST/100.png';
import hundredOne from './robloxImages/JUMPQUEST/101.png';
import hundredTwo from './robloxImages/JUMPQUEST/102.png';
import hundredThree from './robloxImages/JUMPQUEST/103.png';
import hundredFour from './robloxImages/JUMPQUEST/104.png';
import hundredFive from './robloxImages/JUMPQUEST/105.png';
import hundredSix from './robloxImages/JUMPQUEST/106.png';
import hundredSeven from './robloxImages/JUMPQUEST/107.png';
import hundredEight from './robloxImages/JUMPQUEST/108.png';
import hundredNine from './robloxImages/JUMPQUEST/109.png';
import hundredTen from './robloxImages/JUMPQUEST/110.png';
import hundredEleven from './robloxImages/JUMPQUEST/111.png';
import hundredTwelve from './robloxImages/JUMPQUEST/112.png';
import hundredThirteen from './robloxImages/JUMPQUEST/113.png';
import hundredFourteen from './robloxImages/JUMPQUEST/114.png';
import hundredFifteen from './robloxImages/JUMPQUEST/115.png';
import hundredSixteen from './robloxImages/JUMPQUEST/116.png';
import hundredSeventeen from './robloxImages/JUMPQUEST/117.png';
import hundredEighteen from './robloxImages/JUMPQUEST/118.png';
import hundredNineteen from './robloxImages/JUMPQUEST/119.png';
import hundredTwenty from './robloxImages/JUMPQUEST/120.png';
import hundredTwentyOne from './robloxImages/JUMPQUEST/121.png';
import hundredTwentyTwo from './robloxImages/JUMPQUEST/122.png';
import hundredTwentyThree from './robloxImages/JUMPQUEST/123.png';
import hundredTwentyFour from './robloxImages/JUMPQUEST/124.png';
import hundredTwentyFive from './robloxImages/JUMPQUEST/125.png';
import hundredTwentySix from './robloxImages/JUMPQUEST/126.png';
import hundredTwentySeven from './robloxImages/JUMPQUEST/127.png';
import hundredTwentyEight from './robloxImages/JUMPQUEST/128.png';
import hundredTwentyNine from './robloxImages/JUMPQUEST/129.png';
import hundredThirty from './robloxImages/JUMPQUEST/130.png';
import hundredThirtyOne from './robloxImages/JUMPQUEST/131.png';
import hundredThirtyTwo from './robloxImages/JUMPQUEST/132.png';
import hundredThirtyThree from './robloxImages/JUMPQUEST/133.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const Obby = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 135;
  
  return (
    <div className='robloxGames__container'>
        <h1>
        🎉 JumpQuest: Creating Your First Roblox Obby with Unique Obstacles 🎮
        </h1>

        <h3 className='intro'>
        Ready to jump🦘, dive🥽, dip, and spring into some obby action? I know I am. From trampolines to lasers🟥 to evil zombies🧟, this obby is waiting to be defeated by one special player. 🥇
        </h3>

        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>


        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Downloading and Installing Roblox Studio🖥️
            </h2>
            <ul>
                <li>
                Visit the Roblox Studio website (<a href="https://www.roblox.com/create" >https://www.roblox.com/create</a>) 🌐
                </li>
                <li>
                Click the "Start Creating" button to download the installer🔽
                </li>
                <li>
                Run the installer and follow the on-screen instructions to install Roblox Studio🔧 (With these steps, you are setting up Roblox Studio on your computer, which is necessary to create and edit games.😃)
                </li>
            </ul>

            <h2>Step 1: Choosing Your Template 🌟</h2>
            <ul>
                <li>
                Open Roblox Studio from your desktop or Start menu🖱️
                </li>
                <li>
                Sign in with your Roblox account or create a new one🔑
                </li>
                <li>
                Click "New" to create a new project and select "Classic Baseplate" as the template🗺️<br/><br/>(By doing this, you're starting a fresh new game project with a simple baseplate to build your obby on.🛠️)
                </li>
            </ul>
            
            <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Basic Movement and Tools 🛠️</h2>

            <ul>
                <li>
                Once your baseplate is loaded, familiarize yourself with the basic movement controls: use the right-click to orbit around the workspace and the scroll wheel to zoom in and out.
                </li>

                <li>
                Familiarize yourself with the 'Select', 'Move', 'Scale', 'Rotate', and 'Transform' tools in the toolbar. These will help you manipulate objects in your game.
                </li>

                <li>
                    When you are ready add a spawn plate into the template for users to start at when they join the obby. You can locate the spawn plate button by following the red indicators below!
                </li>
            </ul>
            <img src={two} className='demo__image' alt='img_two' />
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 3: Adding a Part 📦</h2>
            <ul>
                <li>
                To start building your obby, click on the 'Part' button to add a new object to your baseplate.
                </li>

                <li>
                Once the part is placed, you can move it around using the 'Move' tool.
                </li>
            </ul>

            <img src={three} className='demo__image' alt='img_three' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: Moving and Scaling Your Part 📐</h2>
            <ul>
                <li>
                With your part selected, use the 'Move' tool to position it accurately on the baseplate.
                </li>

                <li>
                Change to the 'Scale' tool if you want to resize your part - make it longer, wider, or taller to fit your design!
                </li>
            </ul>

            <img src={four} className='demo__image' alt='img_four' />
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Coloring Your Obstacle 🎨</h2>
            <ul>
                <li>
                To make your obstacle stand out, click on the 'Color' tool in the 'Model' tab.
                </li>

                <li>
                Choose a bright color that pops, like yellow, and click on your building block to apply it.
                </li>

                <li>
                Now you have a colorful obstacle ready for players to jump over!
                </li>
            </ul>

            <img src={five} className='demo__image' alt='img_five' />
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Selecting the Material 🌟</h2>
            <ul>
                <li>
                Now that your block is in place and colored, let’s give it some texture to make it visually interesting. Click on the 'Material Manager' in the 'Model' tab.
                </li>

                <li>
                A wide range of materials will appear, from grass to metal to wood. Each material has its own look and can give your obstacle a unique feel. This enhances the visual appeal and can make each part of your obby distinct.
                </li>

                <li>
                Choose a material that matches the theme of your obstacle.
                </li>
            </ul>

            <img src={six} className='demo__image' alt='img_six' />
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Exploring the Explorer Panel 🗺️</h2>
            <ul>
                <li>
                To keep track of all the parts and objects in your game, you’ll need to use the 'Explorer' panel. If it’s not already open, you can find it by clicking on 'View' and then 'Explorer'.
                </li>

                <li>
                The 'Explorer' panel is essential because it lists all the elements in your game. It allows you to select, organize, and modify parts quickly. This is crucial as your obby gets more complex, helping you stay organized and efficient.
                </li>

                <li>
                Click on different objects in the 'Explorer' to see how they are highlighted in the workspace. This is how you can identify and select specific parts of your game.
                </li>
            </ul>
            <img src={seven} className='demo__image' alt='img_seven' />
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Keeping Your Workspace Tidy📁</h2>
            <ul>
                <li>
                As you add more parts, your 'Explorer' will start to get crowded. To make sure you know how to keep track of your items in the Explorer click on your block and notice how it highlights in blue on the Explorer Tab.
                </li>
            </ul>

            <img src={eight} className='demo__image' alt='img_eight' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Renaming Parts for Clarity ✏️</h2>
            <ul>
                <li>
                Right-click on a part in the 'Explorer' to rename it. Select the "rename" option after right-clicking. A clear naming convention is essential for identifying parts quickly, especially when scripting or when your game grows in complexity.
                </li>

                <li>
                Name this block something descriptive, like 'SpecialBricks', so you know exactly what it is.
                </li>
            </ul>

            <img src={nine} className='demo__image' alt='img_nine' />
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Understanding the Baseplate 📐</h2>
            <ul>
                <li>
                The 'Baseplate' is essentially the foundation of your Roblox obby. It acts as the ground or floor upon which all your gameplay elements will be placed. To see its properties, click on 'Baseplate' in the 'Explorer' panel.
                </li>

                <li>
                Why is this important? A well-sized baseplate ensures that players have enough room to navigate through your obstacles. It also sets the stage for the overall look and scale of your game. Think of it as the canvas for your Roblox masterpiece. Adjusting the baseplate's properties allows you to customize the starting point of your obby, ensuring that it fits the theme and difficulty level you're aiming for.
                </li>
            </ul>

            <img src={ten} className='demo__image' alt='img_ten' />
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: Removing the Baseplate 🚫</h2>
            <ul>
                <li>
                Now that you've got your platforms set up, it's time to remove the baseplate to create a more challenging environment. In the 'Explorer' panel, select the 'Baseplate' and press the delete key.
                </li>

                <li>
                Why remove the baseplate? In an obby, the thrill often comes from navigating through open space, where falling means starting over. Removing the baseplate increases the challenge and requires players to be more precise with their jumps.
                </li>

                <li>
                This step marks the transition from building the environment to fine-tuning the gameplay. With the baseplate gone, players have no safety net, which ramps up the excitement and stakes of the game!
                </li>
            </ul>

            <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Initiating the First Test 🎲</h2>
            <ul>
                <li>
                It's time to see how your obby plays out in action. Hit the 'Play' button in the 'Home' tab. This will spawn your character onto the start platform.
                </li>
            </ul>

            <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: Playing the First Test 🍪</h2>
            <ul>
                <li>
                As you test, notice how the absence of the baseplate affects gameplay. Are the platforms appropriately spaced? Is the challenge level suitable for your target audience?
                </li>

                <li>
                    Where did the yellow brick go? It is not there because it fell due to gravity. The convenience of this makes it so that we do not have to program gravity, but we certainly need to turn it off for blocks we want to stay in the air!
                </li>
            </ul>

            <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: Stabilizing Your Platforms ✅</h2>
            <ul>
                <li>
                After testing, it’s crucial to make sure all platforms are stable. Go back into the editor and select your platforms. Click the 'Anchor' tool in the 'Model' tab to secure them in place.
                </li>

                <li>
                Anchoring the platforms prevents them from being moved during gameplay, which could cause unexpected behavior and potentially frustrate players.
                </li>

                <li>
                Solid, reliable platforms are the backbone of any good obby. This step ensures that the gameplay is fair and that any failures come from player mistakes, not game design flaws.
                </li>
            </ul>

            <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15 Testing Player Movements 🏃</h2>
            <ul>
                <li>
                With the platforms anchored, it's time to test again. Click 'Play' to jump back into the game.
                </li>

                <li>
                Focus on how your avatar moves from one platform to the next. Is the flow of movement natural and enjoyable? Do the distances between platforms feel right?
                </li>

                <li>
                This step is all about refinement. The player's ability to predict their jumps and land them comfortably is key to an engaging obby. Take note of any parts that are too easy or too challenging and adjust accordingly.
                </li>
            </ul>
            <img src={fifteen} className='demo__image' alt='img_fifteen' />
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 16: Adding a New Wedge 🧩</h2>
            <ul>
                <li>
                Back in the editor, it's time to expand your obby. Click the 'Part' button in the 'Home' tab to add a new part into your workspace. This time click the arrow underneath the block to change the part shape to a "wedge".
                </li>

                <li>
                Consider the flow from one obstacle to the next and how each part can present a unique challenge to the player.
                </li>
            </ul>

            <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 17: Transforming Parts 🔺</h2>
            <ul>
                <li>
                With the new part selected, use the 'Scale' and 'Rotate' tools to change its shape and orientation. This is where you get to be creative with the design of your obby.
                </li>

                <li>
                Transforming parts is not just about aesthetics; it affects how players will interact with them. A rotated platform might require a different approach or timing to navigate, increasing the complexity of your obby.
                </li>

                <li>
                The purpose of modifying parts is to create a dynamic and interesting course. It encourages players to adapt their strategies and keeps them engaged with new types of challenges.
                </li>
            </ul>

            <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 18: Customizing with Color and Material 🎨</h2>
            <ul>
                <li>
                To make your new obstacle stand out, use the 'Color' and 'Material Manager' to give it a unique look. Select your new part and choose a color and material that fits the theme of your obby or highlights the new challenge.
                </li>

                <li>
                The visual customization helps players identify different obstacles and can be used to indicate difficulty or guide players on where to go next.
                </li>

                <li>
                Remember that the look of your obby is just as important as the gameplay. Color and texture add life to your game and can make your obstacles more visually appealing and memorable.
                </li>
            </ul>

            <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 19: Anchoring the Wedge 🛠️</h2>
            <ul>
                <li>
                With your wedge-shaped obstacle in place, it's essential to ensure it doesn't fall unexpectedly during gameplay. To do this, select the wedge and click the 'Anchor' button in the 'Model' tab.
                </li>

                <li>
                Anchoring objects is a critical step in Roblox Studio. It prevents your objects from falling out of place due to the physics engine, especially when players interact with them.
                </li>

                <li>
                The reason for anchoring is simple: it provides a consistent and reliable experience for players. An unanchored obstacle could lead to unpredictable gameplay, which can be frustrating and unfair to the player.
                </li>
            </ul>

            <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 20: Building Stepping Stones 🚶‍♂️</h2>
            <ul>
                <li>
                Next, we're going to create a series of stepping stones that players will jump across. In the 'Home' tab, click on 'Part' to create a new block, then use the 'Move' and 'Scale' tools to shape it into a stepping stone.
                </li>

                <li>
                You can create the first stepping stone and then the rest by "duplicating" the first. To duplicate the original stepping stone right-click it and select the "duplicate" option. Then you can drag the duplicate out from the inside of the original where is starts (the duplicate starts inside of the original make sure to pull it out).
                </li>

                <li>
                The purpose of these stepping stones is to add a rhythmic challenge to your obby. Each jump requires precision, and the spacing can increase the difficulty. The visual separation of the stones also guides players along the path, making it clear where they need to jump next.
                </li>
            </ul>

            <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>🌍 Step 21 🌍</h2>
            <ul>
                <li>
                Locate the "Anchor" button on the toolbar.
                </li>

                <li>
                Click on each part you want to secure in place.
                </li>

                <li>
                Ensure the anchor symbol appears next to the parts in the explorer area.
                </li>
            </ul>

            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 22: Review Your Obby Layout 🕵️‍♂️</h2>
            <ul>
                <li>
                Observe the checker-patterned platforms.
                </li>

                <li>
                Note the textures and colors of your obby parts.
                </li>

                <li>
                Confirm all parts are anchored and positioned as desired.
                </li>
            </ul>

            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 23: Add and Customize a New Block as a "Trampoline" 🌟</h2>
            <ul>
                <li>
                Insert a new part into your game.
                </li>

                <li>
                Rename the part to "trampoline" in the explorer area.
                </li>

                <li>
                Change its properties (color, material, etc.) to stand out.
                </li>
            </ul>

            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 24: Modify Trampoline Properties 🎨</h2>
            <ul>
                <li>
                Select the "trampoline" part. Ensure that you can see the properties of the trampoline block.
                </li>
            </ul>
            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 25: Configure Trampoline Physics ⚙️</h2>
            <ul>
                <li>
                Scroll to the "Assembly" section in the properties window.
                </li>

                <li>
                By default the "AssemblyLinearVelocity" property should be set to 0, 0, 0. We will change this.
                </li>
            </ul>

            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 26: Adjusting the Trampoline's Bounce 🚀</h2>
            <ul>
                <li>
                Select the "trampoline" part in your game.
                </li>

                <li>
                In the properties panel, find the "AssemblyLinearVelocity" setting.
                </li>

                <li>
                Set the Y value to 500 to create an upward force, which will make players bounce high when they jump on the trampoline. This simulates the physics of a real trampoline, giving players a fun and interactive experience.
                </li>

                <li>
                "AssemblyLinearVelocity" controls the X,Y,Z force applied to a player when they touch a block. So when we increase the Y value to 500 it will push they player up creating the "bounce" effect.
                </li>
            </ul>

            <img src={twentySix} className='demo__image' alt='img_twentySix' />
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 27: Testing Your Trampoline 🧪</h2>
            <ul>
                <li>
                Press the "Play" button on the top of the screen.
                </li>

                <li>
                Control your character to jump onto the trampoline.
                </li>

                <li>
                Observe the bounce effect. If your character jumps much higher than on regular parts, it means the trampoline's physics are working correctly. Testing in-play is crucial to ensure that the elements in your game work as intended for an enjoyable player experience.
                </li>
            </ul>

            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 28: Creating a Spinning Obstacle 🚀</h2>
            <ul>
                <li>
                Insert a new long part to act as a spinning beam obstacle.
                </li>

                <li>
                Rename this part to "spinningBeam" in the explorer area for easy identification.
                </li>

                <li>
                With the "spinningBeam" selected, click the "Anchor" button to lock it in place.
                </li>
            </ul>

            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 29: Adding a Script to the Beam 📜</h2>
            <ul>
                <li>
                    Hover the "spinningBeam" and click the "+" to add a script.
                </li>
            </ul>

            <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 30: Starting the Script 📜</h2>
            <ul>
                <li>
                This script will control the rotation of the beam, making it a moving obstacle. Scripting is a powerful tool that brings life to your game elements, creating an engaging and interactive environment for players.
                </li>

                <li>
                    Right now it has the default "Hello World" statement, you can delete this.
                </li>
            </ul>

            <img src={thirty} className='demo__image' alt='img_thirty' />
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 31: Initializing the Spinning Beam Object 🔄</h2>

            <ul>
                <li>
                Variables in Lua, declared with local, are essential for writing clean, reusable code. They serve as names for pieces of data that you can reference and manipulate throughout your script.
                </li>

                <li>
                    Variables are essentially containers of data "local" lets the computer know we are creating a variable. The word after local is what we are naming it and what ever we write after the "=" is the value of the variable.
                </li>

                <li>
                Start by creating a variable for the beam block with local beam = script.Parent. This line links your script to the beam part in the game, allowing the script to refer to the beam part directly.
                </li>

                <li>
                    script refers to the script we are currently coding and by appending ".Parent" we are referencing the parent of the script which is wherever the script is inside, currently that is the block.
                </li>
            </ul>

            <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 32: Commenting Your Code ✏️</h2>
            <ul>
                <li>
                Add a comment above the variable declaration: -- Creates a variable for the beam block.
                </li>

                <li>
                Comments are essential for explaining what sections of your code do, making it easier to understand and maintain.
                </li>

                <li>
                They are written with -- in Lua and do not affect the script's execution.
                </li>
            </ul>

            <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 33: Introducing a Rotation Speed Variable 🔄</h2>
            <ul>
                <li>
                Write local rotationSpeed = 5 beneath your beam variable.
                </li>

                <li>
                This sets up another variable that defines how fast your beam will rotate.
                </li>

                <li>
                Variables like rotationSpeed allow you to easily adjust your game's mechanics without searching through the code for numbers to change.
                </li>
            </ul>

            <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 34: Writing a Loop Structure 🔁</h2>
            <ul>
                <li>
                Add a while true do loop to your script.
                </li>

                <li>
                This loop creates an infinite loop, which is perfect for continuous behaviors like spinning an obstacle.
                </li>

                <li>
                The while true do construct will repeatedly execute any code inside it until the game stops or the script is otherwise terminated.
                </li>
            </ul>

            <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 35: Coding the Rotation Mechanism ⚙️</h2>
            <ul>
                <li>
                Inside the while loop, add beam.CFrame = beam.CFrame * CFrame.Angles(0, math.rad(rotationSpeed), 0).
                </li>

                <li>
                This line tells Roblox to continuously update the beam's CFrame, which determines its position and rotation in the game world.
                </li>

                <li>
                The CFrame.Angles function converts the rotationSpeed into radians (necessary for rotation in Lua) and applies it to the Y-axis, making the beam spin horizontally.
                </li>
            </ul>

            <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 36: Implementing Delay in the Script ⏲️</h2>
            <ul>
                <li>
                Add the wait() function at the end of the while loop.
                </li>

                <li>
                wait() is a Lua function that pauses the script for a short duration, which can help prevent the server from overworking and allows other processes to run smoothly.
                </li>

                <li>
                In this case, it helps control the speed of the spinning beam by giving a break in the loop before it spins again, making the obstacle manageable for players.
                </li>
            </ul>

            <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 37: Testing the Spinning Beam in Action 🎮</h2>
            <ul>
                <li>
                Run the game to see the spinning beam in action.
                </li>

                <li>
                Observe how the beam moves and check if the rotation speed and delay are appropriate for gameplay.
                </li>

                <li>
                Testing in the game environment is crucial to ensure that the rotation is noticeable and provides a challenge without being impossible to navigate.
                </li>
            </ul>

            <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 38: Creating a Moving Platform 🚧</h2>
            <ul>
                <li>
                Add a new part to your obby to act as a moving platform.
                </li>

                <li>
                In the explorer window, rename this part to "movingPlatform" for clarity.
                </li>

                <li>
                    Give it the color that you want and ensure that it is anchored.
                </li>

                <li>
                The purpose of this platform is to add dynamic movement to the obby, creating a timing challenge for players as they have to jump onto and off the platform at the right times.
                </li>
            </ul>

            <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 39: Adding a Script to the Moving Platform 📝</h2>
            <ul>
                <li>
                    Hover the "movingPlatform" block in the explorer tab and add a script. We will code this script to make the moving platform start at one point and transport players to the end point and back.
                </li>
            </ul>

            <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 40: Platform Variable 📊</h2>
            <ul>
                <li>
                Start the script by creating a variable for the platform.
                </li>

                <li>
                    Declare the variable with the word "local" thus telling the computer that we are creating a variable.
                </li>

                <li>
                    Give the variable a name, preferably along the lines of "platform".
                </li>

                <li>
                    Ensure that the value of the variable is "script.Parent". "script" referencing the current script we are coding and ".Parent" referencing what the script is stored inside of, which is the platform block.
                </li>
            </ul>

            <img src={forty} className='demo__image' alt='img_forty' />
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 41: Defining Platform Speed 🚀</h2>
            <ul>
                <li>
                What's Happening: We're setting the movement speed for our platform.
                </li>

                <li>
                The local keyword creates a variable in the script it's declared in. speed is the name of our variable, and 10 is the value assigned to it.
                </li>

                <li>
                The value 10 represents the speed at which the platform will move. If you want your platform to move slower or faster, simply change this value.
                </li>
            </ul>

            <img src={fortyOne} className='demo__image' alt='img_fortyOne' />
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 42: Initializing Start Point 🌐</h2>
            <ul>
                <li>
                We are declaring a variable to store the starting position of our platform.
                </li>

                <li>
                local startPoint = Vector3.new() sets up a new Vector3 object, which represents a point in 3D space with x, y, and z coordinates.
                </li>

                <li>
                Initializing this variable is necessary because we need a reference point for where our platform will begin its movement.
                </li>
            </ul>

            <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 43: Locating Platform Coordinates 🧭</h2>
            <ul>
                <li>
                What's Happening: We're identifying the exact position of the platform in the game world.
                </li>

                <li>
                In the Properties window, CFrame is a property that describes the position and rotation of a part in Roblox. The Position attribute specifically contains the x, y, and z coordinates of the part.
                </li>

                <li>
                Knowing the current position of the platform is crucial as it will be the starting point for all movement calculations.
                </li>
            </ul>

            <img src={fortyThree} className='demo__image' alt='img_fortyThree' />
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Assigning Start Point Values 📌</h2>
            <ul>
                <li>
                We're inputting the actual position values into our startPoint variable.
                </li>

                <li>
                Vector3.new(-32.165, 12.89, -103.49) replaces the placeholder Vector3.new() with the specific coordinates of the platform.
                </li>

                <li>
                Although the specific numbers of your platform's start point may be different.
                </li>

                <li>
                By assigning these values to startPoint, we establish a fixed location from which the platform will start moving.
                </li>
            </ul>

            <img src={fortyFour} className='demo__image' alt='img_fortyFour' />
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 45: Platform Instructions 🌠</h2>
            <ul>
                <li>
                Right now we are going to create a function for this platform script.
                </li>

                <li>
                    Think of functions as containers of code. They contain specific instructions for the computer to follow, in this case we will create a function (instructions) on how the platform should move. Follow the syntax below to create his function and note that like variables functions also have names. The name of this function is "movePlatform", function names are up to you; just make sure that they are only one work (no spaces)!
                </li>
            </ul>

            <img src={fortyFive} className='demo__image' alt='img_fortyFive' />
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 46: Setting the Start Position in the Movement Function 🚦</h2>
            <ul>
                <li>
                To begin moving our platform, we need to set a fixed start position within our movement function. This start position acts as a reference point for the platform to return to or start its movement from.
                </li>

                <li>
                We assign the startPoint value to a new variable within the function called startPosition. This helps to make our code more readable and ensures the function has its own scope of variables.
                </li>

                <li>
                local startPosition = startPoint: Here, local ensures startPosition is only used within movePlatform function. By setting it equal to startPoint, we're making sure the platform knows where to begin.
                </li>
            </ul>

            <img src={fortySix} className='demo__image' alt='img_fortySix' />
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 47: Creating an Endpoint for the Platform's Path 🔚</h2>
            <ul>
                <li>
                Just as we have a starting point, we need an endpoint. This is the destination where the platform will stop or turn around to go back to the start.
                </li>

                <li>
                Define another point in space using Vector3.new(-32.165, 12.89, -169.84) and assign it to local endPoint. These coordinates should reflect the final position you want your platform to reach on the baseplate. Not the exact number in this example, rather your own ending X,Y,Z values.
                </li>

                <li>
                Vector3.new(x, y, z): Constructs a new vector with the given x, y, and z components. It's crucial that these values are accurate to the Roblox world coordinates for your endpoint.
                </li>
            </ul>

            <img src={fortySeven} className='demo__image' alt='img_fortySeven' />
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 48: Assigning the End Position in the Movement Function 🎯</h2>
            <ul>
                <li>
                To use the endpoint within our movement function, we need to assign it to a variable that the function can access and manipulate.
                </li>

                <li>
                Inside the movePlatform function, we declare local endPosition = endPoint. This sets up another local variable within the function's scope that holds the endpoint's coordinates.
                </li>

                <li>
                local endPosition = endPoint: This line creates a new local variable endPosition and assigns it the value of the global endPoint variable.
                </li>
            </ul>

            <img src={fortyEight} className='demo__image' alt='img_fortyEight' />
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
             
            <h2>Step 49: Creating a Loop for Continuous Movement 🔄</h2>
            <ul>
                <li>
                For our platform to move back and forth continuously, we need a loop that will run the movement code repeatedly.
                </li>

                <li>
                We insert a while true do loop inside our function. This loop will run indefinitely because true never changes to false.
                </li>

                <li>
                while true do: This is a loop that starts with the keyword while followed by the condition true, which means the loop will run endlessly until we explicitly stop it with a break statement or the script is terminated.
                </li>
            </ul>

            <img src={fortyNine} className='demo__image' alt='img_fortyNine' />
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 50: Calculating the Distance to Move 📏</h2>
            <ul>
                <li>
                To move our platform, we need to know the distance between the start and end points. This distance will be used to determine how far and how fast the platform should move.
                </li>

                <li>
                Within the while loop, we calculate the distance with (endPosition - startPosition).Magnitude. This gives us the length of the vector between the two points, essentially the straight-line distance the platform will travel.
                </li>

                <li>
                local distance = (endPosition - startPosition).Magnitude: This line subtracts one vector from another to get the difference vector and then uses the .Magnitude property to get the length of that vector, which is our distance.
                </li>
            </ul>

            <img src={fifty} className='demo__image' alt='img_fifty' />
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 51: Calculating Movement Duration 🕒</h2>
            <ul>
                <li>
                We need to calculate how long it will take for the platform to travel the distance to the endpoint, which is crucial for a smooth and consistent movement.
                </li>

                <li>
                Inside the while true loop, after calculating the distance, we calculate the duration by dividing the distance by the speed. This will give us the time in seconds that the platform should take to travel from the start position to the end position.
                </li>

                <li>
                local duration = distance / speed: This line calculates the time it will take for the platform to cover the distance at the set speed. 
                </li>
            </ul>

            <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 52: Setting the Start Time of Movement ⏱️</h2>
            <ul>
                <li>
                To track the movement over time, we need a start time. This is the timestamp from which we'll measure the progress of the platform's movement.
                </li>

                <li>
                We assign local startTime = tick() right after calculating the duration. The tick() function returns the current time, so startTime holds the timestamp when the platform starts moving.
                </li>

                <li>
                local startTime = tick(): The tick() function is a standard Roblox function that returns the current time in seconds.
                </li>
            </ul>

            <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 53: Creating a Timing Loop for the Movement ⏲️</h2>
            <ul>
                <li>
                To make the platform move within the calculated duration, we need a nested loop that will execute as long as the current time hasn't exceeded the start time plus the duration.
                </li>

                <li>
                Inside the while true loop, we create another while loop that runs as long as tick() - startTime &lt; duration. This checks if the current time is less than the start time plus the duration.
                </li>

                <li>
                while tick() - startTime &lt; duration do: This is a loop that will run only for the amount of time specified by duration. It keeps checking the condition on each iteration until the condition is no longer true.
                </li>
            </ul>

            <img src={fiftyThree} className='demo__image' alt='img_fiftyThree' />
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 54: Tracking Movement Progress 🔄</h2>
            <ul>
                <li>
                We need to determine how far along the platform is in its journey from start to end. This progress is used to move the platform incrementally.
                </li>

                <li>
                Within the timing loop, we calculate local progress = (tick() - startTime) / duration. This represents the fraction of the journey completed at the current time.
                </li>

                <li>
                local progress = (tick() - startTime) / duration: Here, (tick() - startTime) calculates the elapsed time since the start of the movement. Dividing by the total duration gives us the progress percentage.
                </li>
            </ul>

            <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 55: Moving the Platform Along the Path 🛤️</h2>
            <ul>
                <li>
                Finally, we need to actually move the platform. We use the progress value to interpolate between the start and end positions, creating a smooth transition.
                </li>

                <li>
                We set the platform's position using platform.Position = startPosition:Lerp(endPosition, progress). The Lerp function (short for linear interpolation) calculates a position between the start and end points based on the provided progress ratio.
                </li>

                <li>
                platform.Position = startPosition:Lerp(endPosition, progress): This line sets the platform's position to a point between startPosition and endPosition. The Lerp function takes two positions and a progress value (between 0 and 1) and returns a position proportionally between them.
                </li>
            </ul>

            <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 56: Implementing a Pause in the Loop ⏸️</h2>
            <ul>
                <li>
                To prevent our code from running continuously without any pause, which can overwhelm the Roblox engine or cause issues with timing, we introduce a wait() function. This function pauses the loop for a short time, allowing other processes to run and making the movement look smoother.
                </li>

                <li>
                Write wait() at the end of the inner loop. This will pause the execution of the loop at each iteration for a default amount of time (usually 1/30th of a second, if not specified).
                </li>

                <li>
                wait(): This function temporarily halts the script, allowing Roblox's event loop to process other events. Using wait() is essential in loops that run indefinitely to avoid locking up the game.
                </li>
            </ul>

            <img src={fiftySix} className='demo__image' alt='img_fiftySix' />
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 57: Ensuring the Platform Reaches the Endpoint 🎯</h2>
            <ul>
                <li>
                We need to ensure that the platform actually reaches the endpoint. Due to time discrepancies or floating-point precision issues, the platform might not reach the exact endpoint coordinates. Setting the position explicitly to the endpoint after the loop ensures it gets there.
                </li>

                <li>
                Once the inner loop completes (meaning the duration has passed), we set the platform's position to the endpoint directly with platform.Position = endPosition.
                </li>

                <li>
                platform.Position = endPosition: After the movement duration has elapsed, this line guarantees the platform snaps to the exact end position, avoiding any small discrepancies that might occur during the Lerp calculations.
                </li>
            </ul>

            <img src={fiftySeven} className='demo__image' alt='img_fiftySeven' />
        </div>

        <div style={currentStep == 58 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 58: Swapping Start and End Positions for Continuous Looping 🔁</h2>
            <ul>
                <li>
                To create an ongoing back-and-forth movement, we need to swap the start and end positions after each complete traversal so that the platform can move in the reverse direction.
                </li>

                <li>
                After the platform reaches the end position, we swap the values of startPosition and endPosition with startPosition, endPosition = endPosition, startPosition.
                </li>

                <li>
                startPosition, endPosition = endPosition, startPosition: This line is a Lua shorthand for swapping the values of two variables. It effectively sets up our platform to move back to its original starting point on the next iteration of the loop.
                </li>
            </ul>

            <img src={fiftyEight} className='demo__image' alt='img_fiftyEight' />
        </div>

        <div style={currentStep == 59 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 59: Anchoring the Platform 🎣</h2>
            <ul>
                <li>
                The Anchored property, when set to true, keeps the platform in a fixed position in space. It's important to anchor the platform when not moving to prevent it from being affected by physics, such as gravity or collisions.
                </li>

                <li>
                Outside of the function, set the platform's Anchored property to true with platform.Anchored = true.
                </li>

                <li>
                platform.Anchored = true: This line ensures that the platform remains stationary until the movePlatform function starts. This is essential for the stability of the platform before any scripted movement occurs.
                </li>
            </ul>

            <img src={fiftyNine} className='demo__image' alt='img_fiftyNine' />
        </div>

        <div style={currentStep == 60 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 60: Invoking the Movement Function 🚀</h2>
            <ul>
                <li>
                With all our movement logic defined within the movePlatform function, we now need to trigger this function to start the movement.
                </li>

                <li>
                Call the movePlatform() function at the end of the script with movePlatform().
                </li>

                <li>
                movePlatform(): This line calls the movePlatform function, which starts the process we've defined for moving the platform. This invocation is necessary because simply defining a function does not execute it; the function must be explicitly called.
                </li>
            </ul>

            <img src={sixty} className='demo__image' alt='img_sixty' />
        </div>

        <div style={currentStep == 61 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 61: Adding and Preparing a New Platform Block 🛠️</h2>
            <ul>
                <li>
                Adding a new platform block to your Roblox game is the first step in creating a new element for players to interact with. This will be stable ground that players can jump on when exiting the moving platform.
                </li>

                <li>
                In the Roblox Studio, create a new part which will serve as your platform block. This can be done by selecting 'Part' from the Insert menu. Once the part is created, position it in the desired location within your game world.
                </li>

                <li>
                Customizing the color of your part helps in differentiating between various elements in your game, guiding the player, or just for aesthetic purposes.
                </li>

                <li>
                Anchor this block. This is crucial as it prevents the block from being affected by physics, like gravity or collisions.
                </li>
            </ul>

            <img src={sixtyOne} className='demo__image' alt='img_sixtyOne' />
        </div>

        <div style={currentStep == 62 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 62: Adding and Naming the Checkpoint 🔤</h2>
            <ul>
                <li>
                    he stable platform that we just created will act as the floor for the checkpoint that we will be creating.
                </li>

                <li>
                    So, add a new block on the floor. This will be the checkpoint.
                </li>

                <li>
                    Customize this checkpoint to so that it is green, neon, and anchored.
                </li>

                <li>
                    When you are ready rename the block to "checkpoint1" as we will later add more of these.
                </li>
            </ul>

            <img src={sixtyTwo} className='demo__image' alt='img_sixtyTwo' />
        </div>

        <div style={currentStep == 63 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 63: Scripting the Checkpoint 📜</h2>
            <ul>
                <li>
                To add a script to your newly named checkpoint, hover on it in the Explorer window and insert a new script. This script will be the place where you define how this checkpoint behaves when the game is running.
                </li>
            </ul>

            <img src={sixtyThree} className='demo__image' alt='img_sixtyThree' />
        </div>

        <div style={currentStep == 64 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 64: Initializing Game Services in Script 🌐</h2>
            <ul>
                <li>
                Scripts in Roblox have the ability to communicate with various services provided by the platform. Initializing these services within your script allows you to interact with core features of the game, such as managing players and storing game data.
                </li>

                <li>
                In the script, you will need to access the "Players" and "ServerStorage" services by using game:GetService("ServiceName"). Assign each service to a local variable for easy reference within your script.
                </li>

                <li>
                local playerService = game:GetService("Players"): This line gets a reference to the Players service, which is crucial for any functionality related to the game's players, like tracking their data or responding to their actions.
                </li>
            </ul>

            <img src={sixtyFour} className='demo__image' alt='img_sixtyFour' />
        </div>

        <div style={currentStep == 65 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 65: Initializing Server Storage 🌐</h2>
            <ul>
                <li>
                    To create this checkpoint we will also need the "ServerStorage" to save the data of the player and what specific checkpoint they are at.
                </li>

                <li>
                local storageService = game:GetService("ServerStorage"): This line gets a reference to the ServerStorage service, which is used to store data or objects that you don't want to be replicated to the client, keeping them secure and unmodified by the player's actions.
                </li>
            </ul>

            <img src={sixtyFive} className='demo__image' alt='img_sixtyFive' />
        </div>

        <div style={currentStep == 66 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 66: Linking Checkpoint Marker to Script 📍</h2>
            <ul>
                <li>
                By assigning the checkpoint marker to a variable within the script, you establish a direct link that allows you to manipulate the checkpoint through code. This is a foundational step in making the checkpoint interactive.
                </li>

                <li>
                Assign the checkpoint part to a local variable using local checkpointMarker = script.Parent, which effectively links the script to the part it's attached to (in this case, the checkpoint).
                </li>

                <li>
                local: This keyword is used to declare a variable that is only accessible within the script it is declared in, which is important for not cluttering the global namespace. 
                </li>

                <li>
                script.Parent: This refers to the parent object of the script, which is the checkpoint part in this context. It's a way to reference the part the script is attached to.
                </li>
            </ul>

            <img src={sixtySix} className='demo__image' alt='img_sixtySix' />
        </div>

        <div style={currentStep == 67 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 67: Setting Up a Touch Detection Function 👆</h2>
            <ul>
                <li>
                To create interactive gameplay, we need to detect when a player's character (or any part of it) touches the checkpoint. This is the first step in scripting a checkpoint system that saves the player's progress.
                </li>

                <li>
                Write a function named handleCheckpoint that will be called whenever the checkpoint is touched. Then, connect this function to the Touched event of the checkpoint marker.
                </li>

                <li>
                The Touched event is a Roblox event that fires when a part comes into contact with another part. By connecting our handleCheckpoint function to this event, we ensure that any interaction with the checkpoint will trigger our custom logic.
                </li>
            </ul>

            <img src={sixtySeven} className='demo__image' alt='img_sixtySeven' />
        </div>

        <div style={currentStep == 68 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 68: Filtering Touch Events for Valid Objects 🛂</h2>
            <ul>
                <li>
                Not all touch events are relevant to us; we only care about interactions with the player's character. We need to filter out any irrelevant touches.
                </li>

                <li>
                In the handleCheckpoint function, add an if statement to check if the hitObject (the object that touched the checkpoint) is valid and has a parent. This ensures we don't process null references or floating parts.
                </li>

                <li>
                This is a basic form of validation, ensuring that the object interacting with the checkpoint is part of the game world and not an undefined or disconnected object, which could lead to errors.
                </li>
            </ul>

            <img src={sixtyEight} className='demo__image' alt='img_sixtyEight' />
        </div>

        <div style={currentStep == 69 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 69: Identifying the Player's Character 🕵️</h2>
            <ul>
                <li>
                To respond correctly to the checkpoint touch, we need to confirm that it was indeed a player's character that touched it.
                </li>

                <li>
                Use the FindFirstChildOfClass method to check if the hitObject is or is part of a Humanoid, which is a Roblox class used to represent player characters and NPCs.
                </li>

                <li>
                This method is used because player characters (and NPCs) in Roblox are built around the Humanoid object. Detecting a Humanoid ensures that the touch event came from a player or an NPC, not just any part.
                </li>
            </ul>

            <img src={sixtyNine} className='demo__image' alt='img_sixtyNine' />
        </div>

        <div style={currentStep == 70 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 70: Completing the Touch Event Handler Function 🎉</h2>
            <ul>
                <li>
                We must complete the logic to handle what happens when a player touches the checkpoint. This could involve updating their spawn point or recording their progress.
                </li>

                <li>
                Inside the if statement that checks for the Humanoid, you would typically include the logic for what happens when a player reaches the checkpoint. This could be setting their respawn location or triggering a save state.
                </li>

                <li>
                The logic inside this if statement is crucial for the checkpoint to function as intended. Without it, touching the checkpoint would have no effect. This step is where you would put any code that makes the checkpoint a meaningful part of your game.
                </li>
            </ul>

            <img src={seventy} className='demo__image' alt='img_seventy' />
        </div>

        <div style={currentStep == 71 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 71: Identifying the Player 🎮</h2>
            <ul>
                <li>
                The line local player = playerService:GetPlayerFromCharacter(hitObject.Parent) is where the magic happens. It fetches the player object from the character that hit the checkpoint.
                </li>

                <li>
                    Think of character as the physical parts of the player you see on the screen and "player" as the invisible attributes dictating how the player behaves.
                </li>
            </ul>

            <img src={seventyOne} className='demo__image' alt='img_seventyOne' />
        </div>

        <div style={currentStep == 72 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 72: Setting up a Checkpoints Folder 📂</h2>
            <ul>
                <li>
                The same initial checks are performed to ensure we have a valid player character.
                </li>

                <li>
                Then, we look for a folder named "PlayerCheckpoints" to store the checkpoint data. The red underline highlights the line where we attempt to find this folder using storageService:FindFirstChild("PlayerCheckpoints").
                </li>
            </ul>

            <img src={seventyTwo} className='demo__image' alt='img_seventyTwo' />
        </div>

        <div style={currentStep == 73 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 73: Handling Non-existent Checkpoints Folder 🚫📂</h2>
            <ul>
                <li>
                We still have our player variable from the character.
                </li>

                <li>
                Now, the red bracket shows us an if not checkpointsFolder then condition. This means if the checkpoints folder doesn't exist (nil), we need to create it.
                </li>

                <li>
                That's what's happening inside the red bracket – it's the beginning of our condition to handle this situation.
                </li>
            </ul>

            <img src={seventyThree} className='demo__image' alt='img_seventyThree' />
        </div>

        <div style={currentStep == 74 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 74: Creating the Checkpoints Folder 🆕📂</h2>
            <ul>
                <li>
                Building on the previous step, if there is no checkpoints folder, we'll create one.
                </li>

                <li>
                We continue from the if not check. The red underline shows where we create a new folder using Instance.new("Folder").
                </li>

                <li>
                This line is crucial because it ensures that every player has a folder to store checkpoints, even if they didn't have one before.
                </li>
            </ul>

            <img src={seventyFour} className='demo__image' alt='img_seventyFour' />
        </div>

        <div style={currentStep == 75 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 75: Naming the Checkpoints Folder ✏️📂</h2>
            <ul>
                <li>
                After creating the folder, the next crucial step is to name it. The red underline shows us the code that sets the folder's name: checkpointsFolder.Name = "PlayerCheckpoints".
                </li>

                <li>
                This is essential for consistency and allows us to reference this folder specifically for storing checkpoint data.
                </li>
            </ul>

            <img src={seventyFive} className='demo__image' alt='img_seventyFive' />
        </div>

        <div style={currentStep == 76 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 76: Set the parent of the folder 🦉</h2>
            <ul>
                <li>
                Instance.new("Folder") is a Lua function call that creates a new instance of a Folder object. This is a generic container that can hold various other objects in the game's hierarchy.
                </li>

                <li>
                checkpointsFolder.Name = "PlayerCheckpoints" sets the name of the newly created Folder object to "PlayerCheckpoints". Naming objects is crucial for identifying and managing them later in the code, especially when the game gets complex and contains many elements.
                </li>

                <li>
                The new line marked by the red pointer, checkpointsFolder.Parent = storageService, is setting the parent of checkpointsFolder to storageService. In the Roblox engine, the Parent property determines the hierarchical relationship between objects. By setting the Parent to storageService, the checkpointsFolder is placed inside the storageService. This is important for organization and ensuring that the checkpointsFolder is stored in a proper service that is intended for data storage, which can be critical for persisting data across game sessions or server restarts.
                </li>
            </ul>

            <img src={seventySix} className='demo__image' alt='img_seventySix' />
        </div>

        <div style={currentStep == 77 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 77: Generating a Unique Checkpoint ID for Each Player</h2>
            <ul>
                <li>
                In this step, we are creating a unique identifier for each player's checkpoint. The line concatenates the string "Checkpoint_" with the player's UserId. The .. operator in Lua is used for string concatenation, which means it joins two strings together.
                </li>

                <li>
                The player.UserId is a unique identifier for each player in Roblox, which ensures that the checkpoint ID will be unique for every player. This is important because we want each player to have their own set of checkpoints that do not interfere with each other.
                </li>
            </ul>

            <img src={seventySeven} className='demo__image' alt='img_seventySeven' />
        </div>

        <div style={currentStep == 78 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 78: Searching for an Existing Checkpoint</h2>
            <ul>
                <li>
                Here, the code attempts to find a child within checkpointsFolder that has the name matching the playerCheckpointId.
                </li>

                <li>
                The FindFirstChild method searches for an object within the checkpointsFolder whose Name property matches the playerCheckpointId. If it exists, the variable playerCheckpoint will hold a reference to that object; otherwise, it will be nil. This step is essential to determine whether we need to create a new checkpoint for the player or if one already exists.
                </li>
            </ul>

            <img src={seventyEight} className='demo__image' alt='img_seventyEight' />
        </div>

        <div style={currentStep == 79 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 79: Checking for the Non-existence of the Player Checkpoint</h2>
            <ul>
                <li>
                This line is the beginning of an if statement that checks if playerCheckpoint does not exist (i.e., it is nil). If the checkpoint doesn't exist, the subsequent lines of code (which would be inside this if block) will handle the creation of a new checkpoint.
                </li>

                <li>
                This is a common way to prevent duplication and ensure that new game elements are created only when necessary.
                </li>
            </ul>

            <img src={seventyNine} className='demo__image' alt='img_seventyNine' />
        </div>

        <div style={currentStep == 80 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 80: Creating a New Checkpoint Object</h2>
            <ul>
                <li>
                We are creating a new ObjectValue instance, which is a type of object in Roblox used to store a reference to another object.
                </li>
                
                <li>
                We then set the Name of this new ObjectValue to playerCheckpointId, which we generated earlier, and we set its Parent to the checkpointsFolder.
                </li>

                <li>
                This step finalizes the creation of a checkpoint for the player and organizes it within the checkpointsFolder.
                </li>
            </ul>

            <img src={eighty} className='demo__image' alt='img_eighty' />
        </div>

        <div style={currentStep == 81 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 81: Connecting Character Spawning to Checkpoints 🎈</h2>
            <ul>
                <li>
                When a player’s character spawns in the game, we want to ensure they start at their last saved checkpoint. The line player.CharacterAdded:Connect() sets up an event connection to run a function each time the player’s character is added to the game, such as when they first join or respawn.
                </li>
            </ul>

            <img src={eightyOne} className='demo__image' alt='img_eightyOne' />
        </div>

        <div style={currentStep == 82 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 82: Setting Up the Respawn Function 🛠️</h2>
            <ul>
                <li>
                The code player.CharacterAdded:Connect(function(newCharacter) is establishing a new function that will be called whenever the player's character is added to the game, which includes spawning or respawning.
                </li>

                <li>
                This connection is crucial for triggering custom behavior like repositioning the character at the last checkpoint.
                </li>
            </ul>

            <img src={eightyTwo} className='demo__image' alt='img_eightyTwo' />
        </div>

        <div style={currentStep == 83 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 83: Pausing Execution and Fetching Checkpoint Data 🕗</h2>
            <ul>
                <li>
                wait(): This command is used to pause the function's execution. In Roblox, scripts execute very quickly, so if a character has just been added to the game, it might not have fully loaded yet. The wait() function ensures that the game has enough time to fully load the character before any operations that depend on the character being present are performed.
                </li>

                <li>
                local savedCheckpoint = checkpointsFolder[playerCheckpointId].Value: After the pause, we declare a local variable savedCheckpoint. This variable retrieves the saved checkpoint information from checkpointsFolder using the playerCheckpointId which is unique to each player. The .Value is a property of the ObjectValue where we store the actual checkpoint data. By accessing this property, we’re getting the data needed to know where to respawn the player's character.
                </li>
            </ul>

            <img src={eightyThree} className='demo__image' alt='img_eightyThree' />
        </div>

        <div style={currentStep == 84 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 84: Setting the Character’s Spawn Position 📍</h2>
            <ul>
                <li>
                newCharacter:SetPrimaryPartCFrame(CFrame.new(savedCheckpoint.Position)): This line is responsible for setting the position of the player's character when they spawn. SetPrimaryPartCFrame is a method used to set the position and orientation of a model based on its primary part's CFrame. Thus spawning the player on the checkpoint.
                </li>

                <li>
                CFrame.new(savedCheckpoint.Position) creates a new CFrame at the position stored in savedCheckpoint. This ensures that the player's character is placed exactly at the last checkpoint they reached.
                </li>

                <li>
                CFrame.new(math.random(-4, 4), 4, math.random(-4, 4)): To prevent characters from spawning on top of each other, this snippet adds a random offset to the character's spawn position. The math.random(-4, 4) generates a random number between -4 and 4, which is used to offset the position on the X and Z axes. The Y-axis is given a fixed offset of 4 units to ensure the character is spawned above the ground or the checkpoint platform. This randomized offset is important to prevent characters from becoming stuck inside each other or the geometry of the level when they respawn.
                </li>
            </ul>
            
            <img src={eightyFour} className='demo__image' alt='img_eightyFour' />
        </div>

        <div style={currentStep == 85 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 85: Saving the Checkpoint 🏁</h2>
            <ul>
                <li>
                CFrame.new(math.random(-4, 4), 4, math.random(-4, 4)): To prevent characters from spawning on top of each other, this snippet adds a random offset to the character's spawn position. The math.random(-4, 4) generates a random number between -4 and 4, which is used to offset the position on the X and Z axes. The Y-axis is given a fixed offset of 4 units to ensure the character is spawned above the ground or the checkpoint platform. This randomized offset is important to prevent characters from becoming stuck inside each other or the geometry of the level when they respawn.
                </li>
            </ul>

            <img src={eightyFive} className='demo__image' alt='img_eightyFive' />
        </div>

        <div style={currentStep == 86 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 86: Finishing the Checkpoint 🏁</h2>
            <ul>
                <li>
                    Now that you have finished the code for the checkpoint it is a good time to navigate back to your workspace and ensure that the checkpoint works by touching it and jumping off of the obby to make sure you respawn at the checkpoint.
                </li>
            </ul>

            <img src={eightySix} className='demo__image' alt='img_eightySix' />
        </div>

        <div style={currentStep == 87 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 87: Moving platform Continued 🟦</h2>
            <ul>
                <li>
                    After the checkpoint in the game we will be adding another moving platform!
                </li>

                <li>
                    Instead of building an entirely new moving platform, duplicate the original one and rename it to "movingPlatform2"
                </li>
            </ul>

            <img src={eightySeven} className='demo__image' alt='img_eightySeven' />
        </div>

        <div style={currentStep == 88 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 88: Start and End 📐</h2>
            <ul>
                <li>
                    Since we created a duplicate movingPlatform, this means the script was also duplicated. This may be a good thing but we need a new start and end point.
                </li>

                <li>
                    Navigate into the script and adjust the start and end point to reflect where you want "movingPlatform2" to start and end based on new X,Y,Z values.
                </li>
            </ul>

            <img src={eightyEight} className='demo__image' alt='img_eightyEight' />
        </div>

        <div style={currentStep == 89 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>  
            <h2>Step 89: Start the Beam 🦥</h2>
            <ul>
                <li>
                    Add a new block into the workspace, this will serve as our next obstacle, the spinning laser!
                </li>
            </ul>

            <img src={eightyNine} className='demo__image' alt='img_eightyNine' />
        </div>

        <div style={currentStep == 90 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 90: Designing the Spinning Laser 🟥</h2>
            <ul>
                <li>
                    Take the new block you just added and apply new properties to create a custom spinning laser design.
                </li>

                <li>
                    Ensure that the laser is anchored.
                </li>

                <li>
                    Make the laser longer.
                </li>

                <li>
                    Rename this part to "spinningLaser" in the explorer tab.
                </li>
            </ul>

            <img src={ninety} className='demo__image' alt='img_ninety' />
        </div>

        <div style={currentStep == 91 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 91: Linking the Script to the Spinning Laser 🧲</h2>
            <ul>
                <li>
                Locate the spinningLaser object in the Explorer panel.
                </li>

                <li>
                Add the Script into the spinningLaser to make it a child of the object.
                </li>
            </ul>

            <img src={ninetyOne} className='demo__image' alt='img_ninetyOne' />
        </div>

        <div style={currentStep == 92 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 92: Starting the Script 🖊️</h2>
            <ul>
                <li>
                Open the script attached to the spinningLaser.
                </li>

                <li>
                Declare a local variable beam and set it to script.Parent to reference the spinning laser.
                </li>

                <li>
                Add local rotationSpeed = 5 on the next line to set the speed at which the beam will rotate. Fast enough to challenge players, but not too fast! ⚡
                </li>
            </ul>

            <img src={ninetyTwo} className='demo__image' alt='img_ninetyTwo' />
        </div>

        <div style={currentStep == 93 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 93: Creating a Function for Collision 🤖</h2>
            <ul>
                <li>
                It's time to detect when a player bumps into the laser.
                </li>

                <li>
                Write function whenTouched(part) to create a new function that will be triggered upon collision.
                </li>

                <li>
                Leave the body of the function empty for now—we'll fill this in soon! 
                </li>
            </ul>

            <img src={ninetyThree} className='demo__image' alt='img_ninetyThree' />
        </div>

        <div style={currentStep == 94 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 94: Identifying the Player 👤</h2>
            <ul>
                <li>
                Inside the whenTouched function, add local human = part.Parent:FindFirstChild("Humanoid").
                </li>

                <li>
                This line of code checks if the part that touched the laser belongs to a player character. If it's a player, there's a "Humanoid" object present! 
                </li>
            </ul>

            <img src={ninetyFour} className='demo__image' alt='img_ninetyFour' />
        </div>

        <div style={currentStep == 95 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 95: Adding Conditional Logic 🐠</h2>
            <ul>
                <li>
                Now, let's make sure something happens when a player touches the laser.
                </li>

                <li>
                Type if human then to start an if-statement right after defining the human variable.
                </li>

                <li>
                Leave the inside of the if-statement blank for now—this is where we'll decide what the laser does to the player.
                </li>
            </ul>

            <img src={ninetyFive} className='demo__image' alt='img_ninetyFive' />
        </div>

        <div style={currentStep == 96 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 96: Making the Laser Lethal 🚨</h2>
            <ul>
                <li>
                When the function whenTouched is called, we first find a Humanoid object. This is a special object in Roblox that represents the player's character.
                </li>

                <li>
                If human is not nil (which means the laser has indeed touched a player), we set human.Health to 0. This effectively "defeats" the player, as their health drops to zero, indicating that the laser is lethal upon touch.
                </li>

                <li>
                This step is crucial for game mechanics, ensuring that the laser serves as an obstacle that players need to avoid.
                </li>
            </ul>

            <img src={ninetySix} className='demo__image' alt='img_ninetySix' />
        </div>

        <div style={currentStep == 97 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 97: Creating an Endless Loop for the Laser's Spin 🔄</h2>
            <ul>
                <li>
                Here, we're creating an endless loop using while true do. This will keep running the code inside it repeatedly.
                </li>

                <li>
                This is the part where we will later add code to make the laser spin continuously. The while true loop ensures that the laser keeps spinning throughout the game without stopping.
                </li>
            </ul>

            <img src={ninetySeven} className='demo__image' alt='img_ninetySeven' />
        </div>

        <div style={currentStep == 98 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 98: Rotating the Laser Continuously 🌪️</h2>
            <ul>
                <li>
                Inside the loop, we have beam.CFrame = beam.CFrame * CFrame.Angles(0, math.rad(rotationSpeed), 0).
                </li>

                <li>
                beam.CFrame refers to the laser's position and rotation in the game world.
                </li>

                <li>
                CFrame.Angles creates a new rotation CFrame. The math.rad(rotationSpeed) converts the rotation speed to radians, which is necessary because Roblox uses radians for rotations, not degrees.
                </li>

                <li>
                Multiplying the beam's current CFrame by this rotation CFrame rotates the beam by the given angles. Here, we're rotating it around the Y-axis, which is the vertical axis, to create the spinning effect.
                </li>
            </ul>

            <img src={ninetyEight} className='demo__image' alt='img_ninetyEight' />
        </div>

        <div style={currentStep == 99 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 99: Connecting the Touch Function to the Laser 🤝</h2>
            <ul>
                <li>
                To make the whenTouched function actually work when the laser is touched, we need to connect it to the laser's Touched event using beam.Touched:Connect(whenTouched).
                </li>

                <li>
                This line of code "listens" for when the laser is touched and then calls the whenTouched function.
                </li>

                <li>
                It's essential to put this line inside the while true do loop but outside of any other function to ensure it gets set up properly and runs continuously.
                </li>
            </ul>

            <img src={ninetyNine} className='demo__image' alt='img_ninetyNine' />
        </div>

        <div style={currentStep == 100 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 100: Adding a Wait to the Loop to Reduce Lag 🕒</h2>
            <ul>
                <li>
                Finally, we add wait() at the end of the loop. This tells the script to pause for a short default amount of time before running the loop again.
                </li>

                <li>
                Without this wait, the loop would run as fast as possible, which could cause high CPU usage and make the game laggy.
                </li>

                <li>
                Adding wait() helps to reduce the strain on the server, providing a smoother experience for the players.
                </li>
            </ul>

            <img src={hundred} className='demo__image' alt='img_hundred' />
        </div>

        <div style={currentStep == 101 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 101: Cloning the Spinning Laser 🔄</h2>
            <ul>
                <li>
                In your workspace, you'll want to create more spinning lasers to increase the difficulty of your game.
                </li>

                <li>
                    In our case we are putting spinning lasers in the path of the second moving platform so players have to jump over them as they are transported to the next checkpoint.
                </li>
            </ul>

            <img src={hundredOne} className='demo__image' alt='img_hundredOne' />
        </div>

        <div style={currentStep == 102 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 102: Replicating Checkpoints 🏁</h2>
            <ul>
                <li>
                Just like you did with the spinning lasers, duplicate your checkpoint. Create a landing area after where the players can jump off of the second moving platform. We will have another obstacle after this checkpoint. Make sure to number your checkpoints, ie "checkpoint1" and "checkpoint2".
                </li>
            </ul>
        
            <img src={hundredTwo} className='demo__image' alt='img_hundredTwo' />
        </div>

        <div style={currentStep == 103 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 103: Adding a Lava Block 🔥</h2>
            <ul>
                <li>
                    The next obstacle on the list is a lava pit! This requires both a block to code a damage script in and realistic looking lava. To start this process we need a new block.
                </li>

                <li>
                    Add a new "part" into the workspace and rename it to "lavaPart".
                </li>

                <li>
                    Place this part where you want the lava pit to go.
                </li>

                <li>
                    Anchor the part.
                </li>
            </ul>
            
            <img src={hundredThree} className='demo__image' alt='img_hundredThree' />
        </div>

        <div style={currentStep == 104 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 104: Getting the Terrain Editor 🌻</h2>
            <ul>
                <li>
                    To create realistic lava we need to use something called the "Terrain Editor".
                </li>

                <li>
                    To get the Terrain Editor select the "view" tab at the top of Roblox Studio. Then choose the mountain button to pull out the terrain editor. Follow the image below to find the correct buttons needed.
                </li>
            </ul>

            <img src={hundredFour} className='demo__image' alt='img_hundredFour' />
        </div>

        <div style={currentStep == 105 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 105: Select the Draw tool 🎨</h2>
            <ul>
                <li>
                    Once you have the Terrain Editor selected, click the "edit" tab. With the edit tab open, choose the "Draw" tool. We will use this in the next step!
                </li>
            </ul>

            <img src={hundredFive} className='demo__image' alt='img_hundredFive' />
        </div>

        <div style={currentStep == 106 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 106: Configure Terrain Editor Settings ⚙️</h2>
            <ul>
                <li>
                    To properly use the terrain editor and generate the lava we need to configure the setting first.
                </li>

                <li>
                    Look at the image below, the brush mode should be set to square with a base size and height of "2".
                </li>
            </ul>

            <img src={hundredSix} className='demo__image' alt='img_hundredSix' />
        </div>

        <div style={currentStep == 107 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>      
            <h2>Step 107: Lava!! 🌋</h2>
            <ul>
                <li>
                    Under "Material Settings" select the lava texture.
                </li>
            </ul>

            <img src={hundredSeven} className='demo__image' alt='img_hundredSeven' />
        </div>

        <div style={currentStep == 108 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 108: Generating the Lava Pit 🐅</h2>
            <ul>
                <li>
                    All of the setting in the Terrain Editor are now properly set.
                </li>

                <li>
                    Use the draw tool, which is now cube shaped, to create a lava pit around the lava block created earlier.
                </li>

                <li>
                    Click the draw tool on the block to generate the lava terrain.
                </li>
            </ul>
        
            <img src={hundredEight} className='demo__image' alt='img_hundredEight' />
        </div>

        <div style={currentStep == 109 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 109: Completing the Lava Part 🧱</h2>
            <ul>
                <li>
                    Right now the lava terrain has been generated around the "lavaPart", the terrain looks like real lava but does not harm the player. We need to add the code that harms the player into the block around the lava.
                </li>

                <li>
                    First, select this block and shape it to be slightly bigger than the lava as seen in the image below.
                </li>
            </ul>

            <img src={hundredNine} className='demo__image' alt='img_hundredNine' />
        </div>

        <div style={currentStep == 110 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 110: Transparency 🌍</h2>
            <ul>
                <li>
                    The "lavaPart" will contain the code that damages the player but we want to give the illusion that it is the lava terrain that is damaging the player. To accomplish this, let us make the block invisible.
                </li>

                <li>
                Make the block invisible by going to its properties and setting the "Transparency" property to "1".
                </li>
            </ul>

            <img src={hundredTen} className='demo__image' alt='img_hundredTen' />
        </div>

        <div style={currentStep == 111 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 111: Start Scripting 🪁</h2>
            <ul>
                <li>
                    Hover the "lavaPart" in the explorer tab and select the "+" to add a script.
                </li>
            </ul>

            <img src={hundredEleven} className='demo__image' alt='img_hundredEleven' />
        </div>

        <div style={currentStep == 112 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 112: whenTouched Function 🐧</h2>
            <ul>
                <li>
                    Start this script by creating a function named "whenTouched" the name of this function describes that it will start when it is touched. 
                </li>

                <li>
                    Write "part" in the parenthesis. This is a parameter, which is a piece of information that we are giving the function that it does not have access to by default. In our case, we are giving the function the "part" of the player that is touching the lavaPart; by which we will use to reference the player through the code when we are ready to do damage to the player for touching the lava.
                </li>

                <li>
                    Create a function call at the bottom of the script. This function call is saying under what condition to call the function (script.Parent.Touched), when the block is touched, and to actually call the function (:Connect(whenTouched)), calling the function is another word for starting the function.
                </li>
            </ul>

            <img src={hundredTwelve} className='demo__image' alt='img_hundredTwelve' />
        </div>

        <div style={currentStep == 113 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 113: Integrating Humanoid Detection in Script 🛠️👤</h2>
            <ul>
                <li>
                Within the whenTouched(part) function, create the line that defines a local variable, human, which uses the part.Parent:FindFirstChild("Humanoid") method. This line is crucial as it checks for the presence of a Humanoid object, which indicates a player has come into contact with the part.
                </li>
            </ul>

            <img src={hundredThirteen} className='demo__image' alt='img_hundredThirteen' />
        </div>

        <div style={currentStep == 114 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 114: Assigning Consequences to Player Interaction 💔🎮</h2>
            <ul>
                <li>
                To make your script more robust, insert a conditional statement right after defining human to ensure that subsequent code only runs if a Humanoid object was indeed found. This prevents any non-player objects from triggering the script.
                </li>

                <li>
                Inside the conditional block you've just created, add an action to be taken upon confirming a player's touch. For example, if you want the part to be lethal, set the Humanoid's health to zero with human.Health = 0. This effectively "defeats" the player, forcing a respawn.
                </li>

                <li>
                It's essential to wrap this action inside the if block to avoid script errors and unintended behavior in the game when other objects touch the part.
                </li>
            </ul>

            <img src={hundredFourteen} className='demo__image' alt='img_hundredFourteen' />
        </div>

        <div style={currentStep == 115 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>     
            <h2>Step 115: Navigating the Workspace 🧭🗺️</h2>
            <ul>
                <li>
                Shift your focus from scripting to level design within the Roblox Studio's main interface.
                </li>
            </ul>

            <img src={hundredFifteen} className='demo__image' alt='img_hundredFifteen' />
        </div>

        <div style={currentStep == 116 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 116: Expanding Your Checkpoint System ➕🏁</h2>
            <ul>
                <li>
                With the Explorer window open, find an existing checkpoint that you want to duplicate.
                </li>

                <li>
                Right-click on the checkpoint and select 'Duplicate' to create a copy. This will ensure consistency in design and functionality throughout your game.
                </li>

                <li>
                Rename the duplicated checkpoint appropriately (e.g., "Checkpoint3") to maintain an organized hierarchy and make future references to the object easier. This step is especially important for keeping track of progress and ensuring that checkpoints are triggered in the correct order.
                </li>
            </ul>

            <img src={hundredSixteen} className='demo__image' alt='img_hundredSixteen' />
        </div>

        <div style={currentStep == 117 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 117: Constructing Tightrope Challenges 🎢🔧</h2>
            <ul>
                <li>
                Now it's time to get creative with your level design. Focus on the area where you plan to introduce a tightrope or balance challenge for the players.
                </li>

                <li>
                Position the posts (circled in the screenshot) accurately where you intend to start and end the tightrope. This step involves careful placement to ensure the challenge is neither too easy nor impossible.
                </li>

                <li>
                The placement of these posts will define the path players must navigate carefully, adding an element of skill and precision to your game.
                </li>
            </ul>

            <img src={hundredSeventeen} className='demo__image' alt='img_hundredSeventeen' />
        </div>

        <div style={currentStep == 118 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 118: Adding a Base for the Tightrope 🏗️🎢</h2>
            <ul>
                <li>
                Click on 'Part' to insert a new part into your game. This will serve as the base of your tightrope. Ensure that it is the "cylinder" part you are adding.
                </li>

                <li>
                Adjust the new part's properties such as size and position to create a cylindrical base that will support the tightrope. The cylinder should be vertically aligned and placed precisely below where the tightrope will start or end.
                </li>
            </ul>

            <img src={hundredEighteen} className='demo__image' alt='img_hundredEighteen' />
        </div>

        <div style={currentStep == 119 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 119: Constructing the Tightrope 🛠️🧶</h2>
            <ul>
                <li>
                Select the thin rod-like part in your workspace, which will act as the tightrope.
                </li>

                <li>
                Use the move tool to stretch the tightrope across the gap between the two posts you set up previously.
                </li>

                <li>
                Carefully align the tightrope with the top of both posts to provide a path that players will have to balance across.
                </li>
            </ul>

            <img src={hundredNineteen} className='demo__image' alt='img_hundredNineteen' />
        </div>

        <div style={currentStep == 120 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 120: Inserting and Naming a Moving Platform 🚧🔄</h2>
            <ul>
                <li>
                In Roblox Studio, head to the 'Part' section again to insert another platform. This time, it will be a moving platform to increase the difficulty of your course.
                </li>

                <li>
                After placing the part, adjust its size and position so that it fits the intended area of your course. After the previous checkpoint.
                </li>

                <li>
                With the part selected, go to the Explorer window and rename the part to something descriptive like "treadmill" to indicate its purpose in the game.
                </li>

                <li>
                After renaming, use the anchor tool to ensure that the platform will remain in place. This is critical because unanchored parts can drift away or fall out of the game world, breaking the intended gameplay experience.
                </li>
            </ul>

            <img src={hundredTwenty} className='demo__image' alt='img_hundredTwenty' />
        </div>

        <div style={currentStep == 121 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 121: Preparing the Moving Platform 🏗️🔁</h2>
            <ul>
                <li>
                    Finalize the look of the moving platform with the "Material Manager" and "Color Wheel" to get the look that you want.
                </li>
            </ul>

            <img src={hundredTwentyOne} className='demo__image' alt='img_hundredTwentyOne' />
        </div>

        <div style={currentStep == 122 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 122: Configuring the Moving Platform 🔄⚙️</h2>
            <ul>
                <li>
                With the moving platform selected, open the Properties window.
                </li>

                <li>
                Locate the 'AssemblyLinearVelocity' property within the Assembly section.
                </li>

                <li>
                Set the 'AssemblyLinearVelocity' to a vector that will move the platform along the desired axis. For instance, setting it to 15, 0, 0 will move the platform along the X-axis at a steady pace.
                </li>

                <li>
                Ensure that the velocity is not too fast, making the game frustrating, or too slow, making it too easy.
                </li>
            </ul>
        
            <img src={hundredTwentyTwo} className='demo__image' alt='img_hundredTwentyTwo' />
        </div>

        <div style={currentStep == 123 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 123: Creating a Hazardous Obstacle 🚧🧱</h2>
            <ul>
                <li>
                In Roblox Studio, go to the 'Home' tab and select the 'Part' button to create a new part in your workspace. This part will become a 'killbrick', an obstacle that will be dangerous for players.
                </li>

                <li>
                Once the part appears in your workspace, use the scale tool to shape it into a thin, flat surface that players will have to cross. This will become the 'killbrick' that eliminates players on contact.
                </li>

                <li>
                Position the 'killbrick' part on the side of the treadmill to act as a railing.
                </li>

                <li>
                After placing the part, change its color to something noticeable like bright red to warn players of the danger.
                </li>
            </ul>

            <img src={hundredTwentyThree} className='demo__image' alt='img_hundredTwentyThree' />
        </div>

        <div style={currentStep == 124 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 124: Attaching the Script to the 'Killbrick' 📝💻</h2>
            <ul>
                <li>
                Click on the 'killbrick' part in your game's workspace to select it.
                </li>

                <li>
                Choose 'Script' from the list of objects to add a new Lua script to the 'killbrick'. This script will handle the interaction when a player touches the 'killbrick'.
                </li>

                <li>
                The newly added script will appear as a child of the 'killbrick' part in the Explorer panel.
                </li>
            </ul>

            <img src={hundredTwentyFour} className='demo__image' alt='img_hundredTwentyFour' />
        </div>

        <div style={currentStep == 125 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 125: Defining the Touch Function for the 'Killbrick' 🖥️🛠️</h2>
            <ul>
                <li>
                Start by writing a Lua function named whenTouched which will be invoked whenever a player's character touches the 'killbrick'. The function will take one argument, typically named part, which represents the part of the character model that made contact with the 'killbrick'.
                </li>

                <li>
                This whenTouched function will later contain the code to detect if the part that touched the 'killbrick' belongs to a player's character and, if so, apply the necessary effects, such as deducting health.
                </li>

                <li>
                For now, the function body is empty. You will fill it with the appropriate code to make the 'killbrick' functional in the next steps of the tutorial.
                </li>
            </ul>

            <img src={hundredTwentyFive} className='demo__image' alt='img_hundredTwentyFive' />
        </div>

        <div style={currentStep == 126 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 126: Declaring the Humanoid Variable 🧑‍💻</h2>
            <ul>
                <li>
                Inside the function, declare a local variable named human. This will store the Humanoid object of whatever touches the 'killbrick', which is necessary to determine if the object is a player.
                </li>
            </ul>

            <img src={hundredTwentySix} className='demo__image' alt='img_hundredTwentySix' />
        </div>

        <div style={currentStep == 127 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 127: Implementing the Health Reduction 📉</h2>
            <ul>
                <li>
                Continue writing the script within the whenTouched function.
                </li>

                <li>
                After defining the human variable, create an if statement to check whether the human variable is not nil, which would indicate that a Humanoid object was indeed found.
                </li>

                <li>
                If a Humanoid is found, set its health to 0, effectively "defeating" the player character. 
                </li>
            </ul>

            <img src={hundredTwentySeven} className='demo__image' alt='img_hundredTwentySeven' />
        </div>

        <div style={currentStep == 128 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 128: Connecting the Function to the Touch Event 🔄</h2>
            <ul>
                <li>
                To make sure your function runs when the 'killbrick' is touched, you must connect the function to the 'Touched' event of the part.
                </li>

                <li>
                Below the end of your whenTouched function, write the following line to create this connection.
                </li>

                <li>
                This line of code tells Roblox to run the whenTouched function whenever the 'killbrick' part is touched by any object.
                </li>
            </ul>

            <img src={hundredTwentyEight} className='demo__image' alt='img_hundredTwentyEight' />
        </div>

        <div style={currentStep == 129 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 129: Testing the Killbrick Functionality 🧪</h2>
            <ul>
                <li>
                Have the player character touch the 'killbrick' and observe the effect. If the script is working correctly, the player's character should "oof" and respawn.
                </li>

                <li>
                    If it is working as expected create a duplicate to go on the other side of the treadmill.
                </li>
            </ul>

            <img src={hundredTwentyNine} className='demo__image' alt='img_hundredTwentyEight' />
        </div>

        <div style={currentStep == 130 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 130: Constructing a Safe Landing Platform 🏝️</h2>
            <ul>
                <li>
                In Roblox Studio, select the 'Part' tool to create a new platform. This platform will serve as a safe landing area for players after they navigate the treadmill/killbrick obstacle.
                </li>

                <li>
                Place the new part at the end of the treadmill/killbrick obstacle where you expect players to land.
                </li>

                <li>
                Use the scale tool to adjust the size of the platform, ensuring it's large enough for players to land on comfortably but not too easy to make the obstacle trivial.
                </li>

                <li>
                Position the platform at an appropriate height so that players can reach it by jumping from the end of the moving treadmill/killbrick, but high enough to require precise timing and skill.
                </li>

                <li>
                Change the color of the platform to a friendly and distinct color, different from the killbrick, to visually indicate safety to the players.
                </li>
            </ul>

            <img src={hundredThirty} className='demo__image' alt='img_hundredTwentyEight' />
        </div>

        <div style={currentStep == 131 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 131: Building the Castle 🏰</h2>
            <ul>
                <li>
                On the platform you placed previously, begin constructing a castle structure using the 'Part' tool to create walls and towers. This castle will serve as the final obstacle in your obby.
                </li>

                <li>
                Use various shapes and sizes of parts to give the castle an authentic look, with attention to details such as battlements and an entryway.
                </li>

                <li>
                Apply textures and colors that resemble stone to enhance the medieval aesthetic of the castle.
                </li>

                <li>
                Place torches or other lighting elements, from the toolbox, around the castle to create an inviting yet mysterious ambiance.
                </li>

                <li>
                Ensure the castle is accessible from where the players will be coming from, with a clear entrance or a path leading inside.
                </li>
            </ul>

            <img src={hundredThirtyOne} className='demo__image' alt='img_hundredTwentyEight' />
        </div>

        <div style={currentStep == 132 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 132: Adding Zombies 🧟</h2>
            <ul>
                <li>
                Open the 'Toolbox' within Roblox Studio, which allows you to add pre-made objects and models to your game.
                </li>

                <li>
                Search for 'Zombie' models in the Toolbox search bar to find appropriate NPC (Non-Player Character) models for your castle.
                </li>

                <li>
                Select a zombie model that fits the theme of your castle and place several of them inside the castle. These zombies will act as moving hazards that players must avoid.
                </li>

                <li>
                Position the zombies strategically inside the castle, so players have to navigate through them to reach the end.
                </li>
            </ul>

            <img src={hundredThirtyTwo} className='demo__image' alt='img_hundredTwentyEight' />
        </div>

        <div style={currentStep == 133 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 133: Creating the Treasure Area 💎</h2>
            <ul>
                <li>
                At the top of your castle, create a special area that will serve as the treasure zone. This is where players will head to complete the obby.
                </li>

                <li>
                Use the 'Part' tool to create a flat surface atop the castle, which will hold the treasure.
                </li>

                <li>
                Insert a treasure chest model from the Toolbox, or create your own using parts and apply a shiny gold color to signify the reward.
                </li>

                <li>
                Place the treasure chest in a prominent location on the treasure area, making sure it's visible from the entrance of the castle to motivate players as they navigate the hazards.
                </li>

                <li>
                Optionally, add celebratory items like flags, banners, or confetti cannons around the treasure area to mark the end of the obby.
                </li>
            </ul>

            <img src={hundredThirtyThree} className='demo__image' alt='img_hundredTwentyEight' />
        </div>




        <div style={currentStep == 134 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 134: Testing and Publishing Your Obby🔬🌐</h2>

            <ul>
                <li>
                In Roblox Studio, click on the "Home" tab at the top
                </li>
                <li>
                Click the "Play" button to test your game locally
                </li>
                <li>
                You'll spawn on the starting platform and can play through your obby, ensuring that each obstacle works as intended
                </li>
                <li>
                If you find any issues, pause the game by clicking the "Stop" button, then make the necessary adjustments in your game and test again
                </li>
                <li>
                Testing your game locally helps identify any potential problems or bugs before sharing it with the public. This ensures a smooth gaming experience for everyone!👩‍💻🎮
                </li>
            </ul>
        </div>



        <div style={currentStep == 135 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 135: Publishing Your Game to Roblox🚀
            </h2>
            <ul>
                <li>
                Once you're satisfied with your obby, click on the "File" tab in the top left corner of Roblox Studio
                </li>
                <li>
                Select "Publish to Roblox" and choose "Create New Game" (or update an existing game if you've published it before)
                </li>
                <li>
                Fill in the required information, such as game name, description, and genre
                </li>
                <li>
                Set the game's thumbnail and icon to visually represent your game (optional)
                </li>
                <li>
                Click the "Publish" button to make your game live on the Roblox platform
                </li>
                <li>
                When you publish your game, you're sharing it with the entire Roblox community! This is your chance to showcase your creativity and see other players enjoy your obby.🌟🌍
                </li>
            </ul>

            <h3>
            Congratulations!🎉 You've successfully created, tested, and published your obby with unique obstacles in Roblox Studio. Now, watch as players from around the world enjoy the fun and challenging game you've created! Keep learning, experimenting, and developing your skills as a Roblox game creator. The possibilities are endless!🌈😁
            </h3>
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
        
    </div>
  )
}

export default Obby