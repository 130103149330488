import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Navbar from '../../components/navbar/navbar'
import Loader from '../../components/loader/loader'
import './availability.css'
import api from '../../api/api';

const Availability = () => {
  const _id = JSON.parse(localStorage.getItem("user"))?._id
  var [schedule, setSchedule] = useState(null);
  var [day, setDay] = useState("monday");
  var [timeZone, setTimeZone] = useState("");
  var [loading, setLoading] = useState(false);


  const fetchTeacherSchedule = async () => {
    await api.post(`${process.env.REACT_APP_API_URL}/fetch-teacher-schedule`, {
        teacherID: _id
    }).then((res) => {
        console.log(res.data)
        setSchedule(res.data)
        setTimeZone(res.data.timeZone)
    })
  }


  useEffect(() => {
    fetchTeacherSchedule()
  }, [])


  const updateTeacherSchedule = async (time, status) => {
    await api.post(`${process.env.REACT_APP_API_URL}/update-teacher-schedule`, {
        teacherID: _id, day, time, status
    }).then((res) => {
        fetchTeacherSchedule()
        toast.success("Update successful!")
    }).catch((err) => {
        toast.error("Error, contact admin!")
    })
  }

  return (
    <div className='availability-page'>
        <ToastContainer />
        <Navbar />

        {
        loading === true ?
            <div className='availability__loader'>
                <Loader />
            </div>
            :
            <div className='availability-container'>
                
                <div className='day-wrapper'>
                    <div className='top-container'>
                        <select className='day-selector' onChange={(e)=>setDay(e.target.value)} >
                            <option value={"monday"} >Monday</option>
                            <option value={"tuesday"} >Tuesday</option>
                            <option value={"wednesday"} >Wednesday</option>
                            <option value={"thursday"} >Thursday</option>
                            <option value={"friday"} >Friday</option>
                            <option value={"saturday"} >Saturday</option>
                        </select>

                        <h2>Schedule set for: {timeZone}</h2>
                    </div>


                    <div className='timeslot-container'>
                        {
                            schedule?.[day] && Object.entries(schedule[day]).slice(5).map(([timeSlot, availability]) => {
                                return (
                                    <div className={`slot ${availability === 'unavailable' ? 'unavailable' : availability === 'available' ? 'available' : 'booked'}`} key={timeSlot}>
                                        <p className='time'>{timeSlot}</p>

                                        {
                                            availability == "unavailable" &&
                                            <div onClick={()=>updateTeacherSchedule(timeSlot, "available")} className='notonschedule'>
                                                <p>Not on schedule</p>
                                            </div>
                                        }

                                        {
                                            availability == "available" &&
                                            <div onClick={()=>updateTeacherSchedule(timeSlot, "unavailable")} className='onschedule'>
                                                <p>On schedule</p>
                                            </div>
                                        }

                                        {
                                            (availability != "available" && availability != "unavailable") &&
                                            <div className='booked'>
                                                <h3>Booked</h3>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>
        }
    </div>
  )
}

export default Availability