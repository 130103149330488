import React, { useState, useEffect } from 'react';
import "./progressCard.css"
import MetalBar from '../metal-bar/metalBar';

const ProgressCard = ({ subject, percentComplete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [progressWidth, setProgressWidth] = useState(0);

  const capitalizeFirstWord = (text) => {
    const words = text.split(" ");
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    return words.join(" ");
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setProgressWidth(percentComplete);
  }, [percentComplete]);

  return (
    <div className="progress-card">
        <div className="card-header">
        <h3 className="card-title">{capitalizeFirstWord(subject)}</h3>
        {/*<svg
            stroke-width="2"
            stroke="currentColor"
            viewBox="0 0 24 24"
            fill="none"
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            stroke-linejoin="round"
            stroke-linecap="round"
            ></path>
        </svg>*/}
        </div>
        <div className="card-content">
        {/*<p className="file-info">File: var/test/admin.php extracted..</p>*/}
        <div className="progress-bar-background">
            <div
            className="progress-bar animate-progress"
            style={{ width: `${progressWidth}%` }}>
          </div>
        </div>
        <div className="card-footer">
            <span className="progress-text">{percentComplete}% Complete</span>
            <button className="details-button" onClick={toggleDropdown}>Details</button>
        </div>
        </div>

        {isOpen && (
            <div className="dropdown-content">
                <div className='dropdown-content-left'>
                    <MetalBar projectStatus={true} projectName={"Gold = Done"} />

                    <MetalBar projectStatus={false} projectName={"Silver = Not Done"} />
                </div>

                {/*<div className='dropdown-content-right'>
                    <button>Download</button>
                </div>*/}
            </div>
        )}

    </div>
  )
}

export default ProgressCard