import React, { useState } from 'react'
import one from './fullstackImages/aboutCapybaras/1.png';
import two from './fullstackImages/aboutCapybaras/2.png';
import three from './fullstackImages/aboutCapybaras/3.png';
import four from './fullstackImages/aboutCapybaras/4.png';
import five from './fullstackImages/aboutCapybaras/5.png';
import six from './fullstackImages/aboutCapybaras/6.png';
import seven from './fullstackImages/aboutCapybaras/7.png';
import eight from './fullstackImages/aboutCapybaras/8.png';
import nine from './fullstackImages/aboutCapybaras/9.png';
import ten from './fullstackImages/aboutCapybaras/10.png';
import eleven from './fullstackImages/aboutCapybaras/11.png';
import twelve from './fullstackImages/aboutCapybaras/12.png';
import thirteen from './fullstackImages/aboutCapybaras/13.png';
import fourteen from './fullstackImages/aboutCapybaras/14.png';
import fifteen from './fullstackImages/aboutCapybaras/15.png';
import sixteen from './fullstackImages/aboutCapybaras/16.png';
import seventeen from './fullstackImages/aboutCapybaras/17.png';
import eighteen from './fullstackImages/aboutCapybaras/18.png';
import nineteen from './fullstackImages/aboutCapybaras/19.png';
import twenty from './fullstackImages/aboutCapybaras/20.png';
import twentyOne from './fullstackImages/aboutCapybaras/21.png';
import twentyTwo from './fullstackImages/aboutCapybaras/22.png';
import twentyThree from './fullstackImages/aboutCapybaras/23.png';
import twentyFour from './fullstackImages/aboutCapybaras/24.png';
import twentyFive from './fullstackImages/aboutCapybaras/25.png';
import twentySix from './fullstackImages/aboutCapybaras/26.png';
import twentySeven from './fullstackImages/aboutCapybaras/27.png';
import twentyEight from './fullstackImages/aboutCapybaras/28.png';
import CopyBox from './copyBox/copybox';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const AboutCapybaras = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 28;

  return (
    <div className='development__container'>
        <h1>
        🐁 Introduction To HTML: Creating an "About Capybaras" Website 🐁
        </h1>

        <h3 className='intro'>
            Welcome to your very first html project! In this course I will be guiding you through some basic HTML concepts to create your very own "About Capybaras" website to show off to your friends and family! Let's get started!
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>



        <div style={ currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 1: Opening Our Coding Adventure 🚀
          </h2>

          <p>
            Hello, young coder! Are you ready to dive into the amazing world of coding? In this first step, you need to open you code editor (it looks like a pro tool, doesn't it? 😎). This is where all the fun happens. Your project is called "About Capybaras" - sounds super cool already!
          </p>

          <img src={one} className='demo__image' alt='img_one' />

          <h3>What's Happening Here:</h3>

          <p>
          You're creating a new file named index.html. This file is like the treasure map that leads to your capybara kingdom online. The .html at the end tells your computer, "Hey, this is a webpage!"
          </p>

          <ul>
              <li>Create a folder on your computer to store this project.</li>

              <li>Open Visual Studio Code and from there open the folder that was previously created.</li>

              <li>In the VSC project, add a "new file" located at the top left area of the environment.</li>

              <li>Name this file "index.html"</li>

              <li>Make sure your index.html file is open (you can tell because it's got a little tab at the top with its name on it).</li>

              <li>Take a deep breath, and get ready to write your very first line of code in the next step!</li>
          </ul>
        </div>
        

        <div style={ currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 2: Organizing Our Coding Toy Box 📦✏️
          </h2>

          <p>
          In this step, let’s create a special box where we can keep all the awesome capybara pictures you’ll collect! This box is a folder named images in your coding playground.
          </p>

          <img src={two} className='demo__image' alt='img_two' />

          <h3>
          Your Mission Should You Choose to Accept It:
          </h3>

          <ul>
            <li>Make a new folder called images in your project if it’s not there already. This is like making a new drawer in your toy chest!</li>

            <li>This folder will hold the images to be used in this project.</li>

            <li>Imagine all the cool capybara pictures you’ll put in there. 🖼️ Get ready to fill it with capy fun!</li>
          </ul>
        </div>


        <div style={ currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 3: Filling the Toy Box with Capybara Treasures 🐾🏴‍☠️
          </h2>

          <p>
          It’s time to decorate your project with some capybara awesomeness. You will have some hilarious capybara memes that are just waiting to be shown off!
          </p>

          <img src={three} className='demo__image' alt='img_three' />

          <h3>Your Top-Secret Mission:</h3>

          <ul>
            <li>Click the buttons down below to download the capybara pictures.</li>

            <li>Once clicked be sure to save them into your "images" folder.</li>
          </ul>

          {/*<div className='download__buttons__container'>
            <Download src={newHairMeme} filename="new-hair-meme.png" />

            <Download src={swagCappy} filename="swag-cappy.png" />
          </div>*/}
        </div>


        <div style={ currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
            Step 4: Laying the Foundation 🏗️📄
          </h2>

          <p>
            In this exciting step, you're going to lay down the foundation of your web page. This is like the first block in your LEGO set; it's super important!
          </p>

          <img src={four} className='demo__image' alt='img_four' />

          <h3>What To Do:</h3>

          <ol>
            <li>Start by writing &lt;!DOCTYPE html&gt; at the very top. This is like telling your web page, "We're going to speak in HTML," which is the language of web pages!</li>
          
            <li>Under that, type &lt;html lang="en"&gt;. The lang="en" part is like saying, "We're going to chat in English here."</li>

            <li>Don’t forget to close your HTML with &lt;/html&gt; at the bottom. Every tag that opens must also close, just like saying "hello" and "goodbye!"</li>
          </ol>

          
          <h3>What You’re Learning:</h3>

          <ul>
            <li>&lt;!DOCTYPE html&gt; declares the document type and version of HTML.</li>

            <li>&lt;html&gt; is the root element of an HTML page.</li>

            <li>lang="en" specifies the language of the web page's content, in this case... english.</li>
          </ul>

          <p>
          Now, pat yourself on the back; you've just started building your very own web page! 🎉
          </p>
        </div>



        <div style={ currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 5: Making the Control Room 🎮🧠
          </h2>

          <p>
            The &lt;head&gt; section of your HTML is like the control room for your web page. It's where you put all the instructions that help set up your page, even though you won’t see most of them when you look at your web page.
          </p>

          <img src={five} className='demo__image' alt='img_five' />

          <h3>What To Do:</h3>

          <ol>
            <li>Right after the &lt;html lang="en"&gt; tag, add a &lt;head&gt; tag.</li>

            <li>Just like a sandwich, make sure to close your &lt;head&gt; with a &lt;/head&gt; tag.</li>

            <li>Remember, everything in the &lt;head&gt; is important, even if it's not seen, like the engine of a car. Vroom vroom!</li>
          </ol>

          

          <h3>What You’re Learning:</h3>

          <ul>
            <li>&lt;head&gt; contains metadata, which is information for the browser about your web page, rather than the content of the website that viewers will see.</li>

            <li>Metadata isn't displayed on the web page itself but is super important for setting up!</li>
          </ul>

          <p>
          Time for a little dance because you’re doing great! 💃
          </p>
        </div>

        <div style={ currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 6: The Brain of Your Web Page 🧠💡</h2>

          <p>
          Inside this control room, you're going to add some smart tags that help your web page think and be found!
          </p>

          <img src={six} className='demo__image' alt='img_six' />

          <h3>What To Do:</h3>

          <ol>
            <li>Inside the &lt;head&gt;, type &lt;meta charset="UTF-8"&gt;. This tells the web page to use the most common character set, which includes most characters and symbols from all the languages on Earth! Pretty awesome, right?</li>

            <li>Then, add &lt;meta name="viewport" content="width=device-width, initial-scale=1.0"&gt;. This mysterious line helps your page look good on phones and tablets by adjusting the size.</li>

            <li>Last, give your web page a title with &lt;title&gt;About Capybaras&lt;/title&gt;. This is the name that will show up in the browser tab!</li>
          </ol>

          
          <h3>What You’re Learning:</h3>

          <ul>
            <li>
              &lt;meta charset="UTF-8"&gt; lets your page display lots of different characters and emojis.
            </li>

            <li>
              &lt;meta name="viewport"&gt; is like glasses for your web page, helping it see better on different devices.
            </li>

            <li>
              &lt;title&gt; is the title of your web page, like the name of a book.
            </li>
          </ul>

          <p>
          Give a high five to the nearest person (or a teddy bear)! You're coding like a champion! 🙌🐻
          </p>
        </div>


        <div style={ currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 7: Creating Our Web Page's Body 🎨🖌️
          </h2>

          <p>
          Now that the control room is all set up, it's time to create the part of your web page that everyone will see—the body! This is where all your colorful content, stories, pictures, and fun elements live; items that will actually show up when visitors look at our epic cappy website.
          </p>

          <img src={seven} className='demo__image' alt='img_seven' />

          <h3>What To Do:</h3>

          <ol>
            <li>
            Add the &lt;body&gt; tag right after the closing &lt;/head&gt; tag. This tells the browser, "Here comes the part that people will see!"
            </li>

            <li>
            Leave some space between &lt;body&gt; and &lt;/body&gt; because that's where you'll put all the exciting stuff about capybaras.
            </li>
          </ol>

          

          <h3>What You’re Learning:</h3>

          <ul>
            <li>
            The &lt;body&gt; tag is where you put all the content of your web page like text, images, buttons, and more. Think of it like the pages inside a book or the canvas of a painting.
            </li>

            <li>
            Everything you put here will show up on your web page when someone visits it. It's the stage of a theater where all your content performs!
            </li>
          </ul>


          <p>
          Imagine the &lt;body&gt; as the playground where your capybaras can run, play, and show off their cool facts and styles!
          </p>
        </div>


        <div style={ currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 8: Shouting Out with Headers 📣✨</h2>

          <p>
          Headers in HTML are like the big, bold titles in a storybook. They help to tell your readers, "Hey, look here, this is important!"
          </p>

          <img src={eight} className='demo__image' alt='img_eight' />

          <h3>What To Do:</h3>
          <ol>
            <li>
            Inside the &lt;body&gt;, let's start with a big, bold header. Type &lt;h1&gt;About Capybaras (AKA CAPYS)&lt;/h1&gt;. This is your main title, the big sign above your playground that says what your web page is all about.
            </li>

            <li>
            The &lt;h1&gt; tag is the largest header, and it's super important for telling search engines and your readers what the main topic of your page is.
            </li>
          </ol>

          
          <h3>What You’re Learning:</h3>

          <ul>
            <li>Headers are used to structure your content, like titles and subtitles in a book.</li>

            <li>They range from &lt;h1&gt; to &lt;h6&gt;, with &lt;h1&gt; being the largest and most important, and &lt;h6&gt; being the smallest.</li>

            <li>Search engines like Google use these headers to understand what your page is about, which helps other people find your page when they're searching for capybaras!</li>
          </ul>


          <p>
          When you use headers, you're not only making your page easier to read, but you're also helping it to get noticed by people who are as excited about capybaras as you are!
          </p>

          <p>
          As you learn more about headers and HTML, you'll see how these building blocks come together to make an awesome web page. It's like a puzzle where each piece is important, and when you put them all together, they create a picture of your favorite animal—capybaras!
          </p>

          <p>
          Keep going, young explorer! Your journey in the land of code is shaping up to be an amazing adventure. 🌟🚀
          </p>
        </div>


        <div style={ currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 9: Expanding Our Content 🌐📚</h2>

          <p>
          After introducing our web page with a big, friendly "About Capybaras (AKA CAPYS)" header, let's dive deeper into the capy-world with another heading!
          </p>

          <img src={nine} className='demo__image' alt='img_nine' />

          <h3>What To Do:</h3>

          <ol>
            <li>Just below your first header, type in &lt;h2&gt;What are Capybaras?&lt;/h2&gt;. This smaller heading asks a question that might be buzzing in the readers' minds.</li>

            <li>The &lt;h2&gt; tag is like a chapter title in your favorite book, a bit smaller than &lt;h1&gt; but still very important!</li>
          </ol>

          
          <h3>What You’re Learning:</h3>

          <ul>
            <li>
            By using &lt;h2&gt;, you're organizing your content into sections that make sense. It's like when you use bigger text for the title of your school essay and smaller text for the section names.
            </li>

            <li>
            This not only helps your visitors to navigate your page but also tells search engines about the structure of your content. It's like leaving breadcrumbs on a trail—they help everyone follow along!
            </li>
          </ul>

          <p>
          Think of each &lt;h2&gt; as a mini stage spotlight for each capybara topic you're going to talk about. You're setting the stage for more exciting capy-facts! 🎭📖
          </p>
        </div>


        <div style={ currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 10: The Story of Capybaras 📖💡
          </h2>

          <p>
          Now, it's storytime! After asking "What are Capybaras?" let's give an answer that's fun to read.
          </p>

          <img src={ten} className='demo__image' alt='img_ten' />

          <h3>What To Do:</h3>
          <ol>
            <li>Below your &lt;h2&gt; tag, add a paragraph by typing &lt;p&gt; followed by your fun capybara fact, and don't forget to close it with &lt;/p&gt;.</li>
          
            <li>Your paragraph begins, "In the grand tapestry of the animal kingdom..." Here, you're painting a picture with words, describing the capybara in an almost storybook-like way.</li>
          
            <li>If you think the paragraph about capybaras is too long to type out, you can create the &lt;p&gt; tag and then use the box below to copy and paste the text into it!</li>
          </ol>

          
          <CopyBox 
          text={"In the grand tapestry of the animal kingdom, where beasts of feather and fur vie for the spotlight, there exists a creature of such unparalleled chill, it has become the unofficial ambassador of serenity: the capybara. Hailing from South America, this oversized guinea pig lookalike has cornered the market on laid-back vibes, making sloths look energetic by comparison."}
          />

          
          <h3>What You’re Learning:</h3>

          <ul>
            <li>
            The &lt;p&gt; tag is used for paragraphs. Just like in a story, it wraps around a bunch of sentences to keep them together.
            </li>

            <li>
            Paragraphs on a web page are like paragraphs in a book—they make your content easy to read by breaking it into chunks. Each &lt;p&gt; is a pause for breath in the conversation with your reader.
            </li>

            <li>
            Writing descriptive and engaging content inside &lt;p&gt; tags not only makes your website enjoyable to read but also can help your website show up in search results when people are looking for fun capybara facts.
            </li>
          </ul>


          <p>
          Every paragraph you add is like adding a page to your web storybook, each one full of adventures and wonders about the capybara world. So, keep weaving those words to keep your readers hooked! 🎨🖋️
          </p>

          <p>
          As you code, remember that your website is like an online book about capybaras, with each tag contributing to the story. The better the structure, the more enjoyable the adventure. And who doesn't love a good capybara tale? 📖🐾🎉
          </p>
        </div>

        <div style={ currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 11: Capy Appetite with More Headers 🌿🍽️</h2>

          <p>
          Look at that! We're adding another header to make our content tastier. This time we're curious about what these lovely capybaras munch on!
          </p>

          <img src={eleven} className='demo__image' alt='img_eleven' />

          <h3>What To Do:</h3>
          <ol>
            <li>
            Just below your first paragraph, type &lt;h2&gt;What do Capys like to eat?&lt;/h2&gt;. This header sparks curiosity and makes your readers hungry for knowledge (and maybe a little hungry for a salad too! 🥗).
            </li>

            <li>
            Remember to keep your headers descriptive—it helps your readers and the internet's helpers, search engines, know what scrumptious info is coming up.
            </li>
          </ol>

          
          <h3>What You’re Learning:</h3>

          <ul>
            <li>
            Using &lt;h2&gt; headers before introducing new sections gives your readers tasty breadcrumbs to follow through the different parts of your page.
            </li>

            <li>
            It's like when you see the menu at a restaurant, and you know something delicious is coming next—each header is a promise of yummy content to come!
            </li>
          </ul>

          <p>Think of your website as a menu at a capybara café, with each section revealing another delightful dish! 🐾👩‍🍳</p>
        </div>



        <div style={ currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 12: Serving Up the Main Course: Paragraphs! 📝🍲
          </h2>

          <p>
          And now, we deliver on that promise by filling in the details about capybara cuisine. Let's dish out the facts!
          </p>

          <img src={twelve} className='demo__image' alt='img_twelve' />

          <h3>What To Do:</h3>
          <ol>
            <li>
            After your tantalizing header about capy food, add a paragraph with &lt;p&gt; and share the gourmet details of a capybara diet.
            </li>

            <li>
            End your paragraph with &lt;/p&gt; to wrap up that thought neatly like a burrito. 🌯 Yum!
            </li>

            <li>
              If you do not know what capybaras eat or do not know what to write in the &lt;p&gt; tag, you can copy the text below and paste it into the &lt;p&gt; tags.
            </li>
          </ol>

          <CopyBox 
            text={"The capybara's diet is as unassuming as its personality, favoring a simple salad over anything the animal kingdom's equivalent of a Michelin star restaurant might offer. This herbivore chomps on grass with the enthusiasm of a gourmet savoring a fine dining experience, proving that happiness indeed comes from the simple things in life."}
          />

          
          <h3>
          What You’re Learning:
          </h3>

          <ul>
            <li>
            Paragraphs are the meat and potatoes of your content; they fill up your readers with satisfying facts and stories.
            </li>

            <li>
            Good paragraphs are like a well-balanced meal; they're satisfying and leave you feeling happy about learning something new. Just like a capybara enjoying a simple but delicious grassy meal!
            </li>
          </ul>

          <p>
          Every paragraph you write is a scoop of knowledge for your readers' minds, and who wouldn't want to feast on capybara facts? Keep dishing out those delightful details, and your readers will come back for seconds! 🥄🥗👀
          </p>

          <p>
          Your webpage is starting to look like a full-course meal at the capybara banquet. Keep it up, chef! You're cooking up a storm in the coding kitchen! 🧑‍🍳🔥
          </p>
        </div>


        <div style={ currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 13: Crafting a Capybara Lifestyle Section 🌿🏞️
          </h2>


          <p>
          Now we’re setting the stage to explore how these chill giants live. Let’s craft a section that talks about the capybara lifestyle!
          </p>

          <img src={thirteen} className='demo__image' alt='img_thirteen' />

          <h3>What To Do:</h3>

          <ol>
            <li>
            After talking about the capybara diet, add another &lt;h2&gt; tag with the text "Capybara lifestyle." This is like a new chapter in our capybara storybook, and it’s going to be all about their daily adventures!
            </li>
          </ol>

          
          <h3>What You’re Learning:</h3>

          <ul>
            <li>Creating sections with headers helps organize your content, making it easy and fun for your readers to follow along.</li>
          </ul>


          <p>
          Each new header is a signpost in our digital jungle, guiding your readers through the wonderful world of capybaras. So, let's continue our coding safari! 🦜🌴
          </p>
        </div>

        <div style={ currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 14: Punctuating Your Page with Line Breaks &lt;br/&gt; 📖✨
          </h2>


          <p>
          Line breaks in HTML are like the spaces between paragraphs (empty lines) in a book or the short pauses between ideas in a conversation. They help to create a visual break on your page, so everything doesn't appear all squished together—giving your readers' eyes a little rest. 🛌👀
          </p>

          <img src={fourteen} className='demo__image' alt='img_fourteen' />

          <h3>What To Do:</h3>
          <ol>
            <li>Look at where you placed &lt;br/&gt; tags under the "Capybara lifestyle" header. These tags are like saying, "Hey, let’s take a breath here before moving to more fun facts!" 🌬️🍃</li>

            <li>You can add one &lt;br/&gt; for a smaller gap or several to make a bigger space. Just remember, it's like seasoning—use the right amount to taste!</li>
          </ol>

          
          <h3>What You’re Learning:</h3>

          <ul>
            <li>
            The &lt;br/&gt; tag stands for 'break', and it's a single tag without a matching closing tag (that’s why it ends with a forward slash /). It's one of the few 'void elements' in HTML—special tags that don’t wrap around any content or have a closing tag.
            </li>

            <li>
            When to use them? Use &lt;br/&gt; tags when you want to create a new line without starting a new paragraph—for example, in an address or a poem.
            </li>

            <li>
            How to use them? Simply place &lt;br/&gt; where you want the break, and the next content will start on a new line below.
            </li>
          </ul>


          <p>
          Just like a pause in music or a new stanza in poetry, &lt;br/&gt; tags can add rhythm and structure to your webpage. They're small but mighty, helping you choreograph the dance of text on your site. 🎶🌟
          </p>
        </div>


        <div  style={ currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 15: Introducing Capybara Culture with Memes 🖼️😄
          </h2>

          <p>
          The capybaras are not just about their lifestyle; they're also stars of their own memes! Let's showcase their quirky humor.
          </p>

          <img src={fifteen} className='demo__image' alt='img_fifteen' />

          <h3>What To Do:</h3>

          <ol>
            <li>
            Add a new &lt;h2&gt; tag titled "Capy Memes". This acts like a billboard announcing a fun new exhibit in our capybara museum.
            </li>

            <li>
            Just beneath it, there's a space where we'll display our first meme. It’s time to bring in the visuals!
            </li>
          </ol>

          
          <h3>What You’re Learning:</h3>

          <ul>
            <li>
              &lt;h2&gt; tags not only introduce new text sections but they can also lead into different content types like images or videos, adding diversity to your page.
            </li>

            <li>
            Memes connect with readers on another level, often using humor or cute imagery to make the information memorable and shareable.
            </li>
          </ul>

          <p>
          The internet loves a good meme, and by adding them to your page, you're tapping into a playful way to engage with your audience. Who could scroll past a capybara meme without a smile? 😊👀
          </p>
        </div>


        <div style={ currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 16: Adding Pictures with the Image Tag &lt;img /&gt; 📸✨</h2>
          
          <p>
          Images are like the sparkles on a capybara's fur—they make your web page shine! To place a picture of a capybara on your page, we use a special tag called the &lt;img /&gt; tag. It's like telling your webpage, "Hey, show a picture here!"
          </p>

          <img src={sixteen} className='demo__image' alt='img_sixteen' />

          <h3>What To Do:</h3>

          <ol>
            <li>
            Start by typing &lt;img This is the beginning of the image tag.
            </li>

            <li>
            Then, add an attribute to it like this: src="./images/new-hair-meme.png". The src (which stands for "source") tells the browser where to find the image you want to show. Think of it like telling a friend where you keep your secret stash of snacks!
            </li>

            <li>
            Set the size of the image with the width attribute like this: width="200". This controls how wide the image will be on your page. It's like choosing the right-sized frame for a photo on your wall.
            </li>

            <li>
            Finish up with a self-closing tag by adding /&gt;. This means the tag is complete, and you don't need to add a closing tag like some other HTML elements.
            </li>
          </ol>

          
          <h3>
          What You’re Learning:
          </h3>

          <ul>
            <li>
            The &lt;img /&gt; tag is a self-closing tag, which means it doesn't need an ending tag. It’s like a command that doesn’t need a response.
            </li>

            <li>
            Attributes like src and width are like instructions you give to the tag. The src is the most important one because it tells the browser which image to display.
            </li>

            <li>
            The path in the src attribute is like the address for the image. The ./ part means "start where this HTML file is located," and then the rest of the path directs the browser to the image file itself.
            </li>

            <li>
            The width attribute lets you control how big the image appears. If you don't set it, the image will be as big as its original size, which could be huge!
            </li>
          </ul>

          <p>
          Adding images to your webpage can make it more engaging and fun to visit. Every picture adds a bit of character and color, making your site a place where visitors can not only read about capybaras but also see their adorable antics. So go ahead, add that capybara meme, and let's make the internet a cuter place, one capybara at a time! 🌟🐾
          </p>
        </div>

        <div style={ currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 17: Doubling the Fun with More Images 📸🐾
          </h2>


          <p>
          Adding more images to your page brings it to life. Each picture tells a part of the capybara's story. Now, you're going to add another capybara meme to double the fun!
          </p>

          <img src={seventeen} className='demo__image' alt='img_seventeen' />

          <h3>What To Do:</h3>

          <ol>
            <li>Just like before, start with the &lt;img /&gt; tag to add a new image.</li>

            <li>Use the src attribute to tell the browser where this new image is located. Write it like this: src="./images/swag-cappy.png". The dot and slash (./) mean "start here and look in the images folder."</li>

            <li>Set the image's width to "200" to keep the size consistent with your first meme. Consistency is key to a tidy-looking webpage!</li>
          </ol>

          
          <h3>What You’re Learning:</h3>
          <ul>
            <li>
            By using multiple &lt;img /&gt; tags, you can add as many images as you like to your webpage.
            </li>

            <li>
            Always check that your image file paths are correct in the src attribute. A typo here is like a missed turn on a road trip; it will lead you to the wrong place.
            </li>

            <li>
            Controlling the size of your images with the width attribute keeps your page layout balanced and harmonious.
            </li>
          </ul>

          <p>
          Think of your webpage as a photo album. Each image is a snapshot that adds character and context to your words. Now, your visitors can scroll and enjoy a visual feast of capybara coolness!
          </p>
        </div>



        <div style={ currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 18: Queueing Up a Capybara Video 🎥🌟</h2>

          <p>
          Videos can make your webpage even more engaging. They're like windows into the capybara's world that your visitors can peer through.
          </p>

          <img src={eighteen} className='demo__image' alt='img_eighteen' />

          <h3>What To Do:</h3>
          <ol>
            <li>
            After the memes, get ready to embed a video. Start with a header: &lt;h2&gt;Watch this Capy Video&lt;/h2&gt;. It’s like hanging a sign that says "Coming attractions!"
            </li>

            <li>
            Below that, you'll eventually add a video element that allows your visitors to click play and dive right into the action.
            </li>
          </ol>

          <p>
          In the upcoming step, you'll see exactly how to embed the video, creating a multimedia experience for anyone exploring your capybara page. Get ready to hit play on this exciting part of the journey! 🎬🐾
          </p>
        </div>

        <div style={ currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 19: Bringing Capybaras to Life with Video 🎥🐾
          </h2>

          <p>
          Videos make your webpage come alive by showing capybaras in action. The &lt;iframe&gt; tag is your tool for embedding videos from sites like YouTube directly into your webpage.
          </p>

          <img src={nineteen} className='demo__image' alt='img_nineteen' />

          <h3>What To Do:</h3>

          <ol>
            <li>
            Write the &lt;iframe&gt; tag in your HTML where you want your video to appear. The width and height attributes determine the size of the video on your page.          
            </li>

            <li>
            In the &lt;iframe&gt; tag, set width="560" and height="315" to ensure your video fits well on most screens without overpowering your text content.          
            </li>
          </ol>

          
          <h3>What You’re Learning:</h3>

          <ul>
            <li>
            The &lt;iframe&gt; tag creates a space on your page where content from another source can be displayed. It's like a picture frame for web content from another place.
            </li>

            <li>
            The width and height attributes help you control how the video fits into your page layout. Just like arranging furniture in a room, you're deciding how much space your video takes up.
            </li>
          </ul>
        </div>

        <div style={ currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 20: Embedding the Video 🌐
          </h2>

          <p>
          Now, let's make the video appear! You've got the frame set up with &lt;iframe&gt;, and it's time to show the actual capybara video.
          </p>

          <img src={twenty} className='demo__image' alt='img_twenty' />

          <h3>What To Do:</h3>
          <ol>
            <li>Add the src attribute to your &lt;iframe&gt; tag. This attribute should contain the URL of the video you want to show, which links to the video's location on the web, just like a bookmark.</li>

            <li>For YouTube videos, make sure to use the embed URL, which usually looks something like https://www.youtube.com/embed/VIDEO_ID.</li>

            <li>In this case, press the button below to copy the link of the video we will be using</li>
          </ol>
          
          <CopyBox 
            text={"https://www.youtube.com/embed/vOeliQfjUzA?si=nMhDduPOJzsZwWl"}
          />

          
          <h3>What You’re Learning:</h3>
          <ul>
            <li>
            The src attribute in the &lt;iframe&gt; tag is like giving your web page a direction to where the video lives on the internet.
            </li>

            <li>
            When you set the src to a video embed link, your webpage knows to fetch the video and play it right there in the frame you’ve provided.
            </li>

            <li>
            Remember, the correct embed URL is essential. Using the direct link to a YouTube page won’t work; it has to be the special embed URL provided by YouTube.
            </li>
          </ul>

 
          <p>
          You've just turned your page into a multimedia experience, blending text, images, and video to create an engaging and informative space all about capybaras!
          </p>
        </div>



        <div style={ currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 21: Making Space with Breaks (br)
          </h2>

          <p>
          After you've showcased the capybara memes and before introducing a captivating video, it's nice to give your viewers a visual breather. The &lt;br/&gt; tag is just what you need!
          </p>

          <img src={twentyOne} className='demo__image' alt='img_twentyOne' />

          <h3>What To Do:</h3>
          <ol>
            <li>
            Add a &lt;br/&gt; tag right after your video's &lt;iframe&gt; tag. This will insert a line break, which is a bit like hitting "Enter" on your keyboard while typing a document. It moves the content after the break down to the next line.
            </li>

            <li>
            But hey, let's give a bit more space. Add another &lt;br/&gt; tag! Now you have a double space, making for a clean and pleasant separation between your video and the next exciting section of your capybara adventure.
            </li>
          </ol>

          
          <h3>What You’re Learning:</h3>
          <ul>
            <li>
            The &lt;br /&gt; tag is a single line break. It's used to create space between lines of content without starting a new paragraph.
            </li>

            <li>
            It’s self-closing, meaning it doesn't need an ending tag. You write it just like this: &lt;br /&gt;
            </li>

            <li>
            Strategic use of &lt;br /&gt; tags can make your content easier to read and look more organized, like paragraphs in a storybook!
            </li>
          </ul>
        </div>


        <div style={ currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 22: Heading Towards Adoption 🐾🏠
          </h2>

          <p>
          Wow, your audience must be in love with capybaras by now! Why not suggest adopting one? This section could introduce the idea of capybara adoption.
          </p>


          <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />

          <h3>What To Do:</h3>
          <ol>
            <li>
            Create a new heading with &lt;h2&gt;Ready to adopt your own pet capybara?&lt;/h2&gt;. This signals a new section of content and stirs up excitement about the idea of a capybara pet!
            </li>

            <li>
            Under this heading, you might later list the steps to adopt, provide a contact form, or offer more fun facts about living with a capybara.
            </li>
          </ol>

          
          <h3>What You’re Learning:</h3>
          <ul>
            <li>
            The &lt;h2&gt; tag is used for subheadings, which introduce new sections and help break up the page into readable chunks.
            </li>

            <li>
            Each new heading starts a new line and stands out because it's bolder and larger than normal text, catching the reader's eye.
            </li>

            <li>
            Remember, good headings are like signposts—they guide your visitors through your content and let them know what each section is about.
            </li>
          </ul>


          <p>
          Your webpage is becoming a comprehensive guide to all things capybara. With each step, you're not only building a website but also a resource for fellow capybara enthusiasts!
          </p>
        </div>


        <div style={ currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 23: Subheading with Style 🌟
          </h2>

          <p>
          You've given your readers a lot of cool capybara content; let's keep the momentum going with a fun, lighthearted subheading about capybara names!
          </p>

          <img src={twentyThree} className='demo__image' alt='img_twentyThree' />

          <h3>What To Do:</h3>
          <ol>
            <li>
            Sprinkle in a little personality with the subheading &lt;h3&gt;Top 5 Capybara names&lt;/h3&gt; right under your adoption section. This subheading sets the stage for some charming capybara name suggestions!
            </li>

            <li>
            The &lt;h3&gt; tag is a lower-level heading that supports the main heading (&lt;h2&gt;) and adds additional layers of organization to your content.
            </li>
          </ol>

          <p>
          Headings create a clear, easy-to-follow structure for your page, making it more enjoyable to read. A touch of whimsy can go a long way—fun subheadings can make your content memorable and shareable.
          </p>
        </div>


        <div style={ currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 24: Listing with Order 📝
          </h2>

          <p>
          After drawing your readers in with the idea of adopting a capybara, what better way to engage them than to help them imagine naming their new friend?
          </p>

          <img src={twentyFour} className='demo__image' alt='img_twentyFour' />


          <h3>What To Do:</h3>

          <ol>
            <li>Use the &lt;ol&gt; tag to start an ordered list right after the "Top 5 Capybara names" subheading. This tells your readers that the list is ranked or has a specific sequence.</li>
          
            <li>
            Inside the &lt;ol&gt;, add list items with &lt;li&gt; tags for each name. For example, &lt;li&gt;Emily&lt;/li&gt; might be the first on the list, indicating it's a popular or suggested name.
            </li>

            <li>
            For an extra sprinkle of fun, throw in an emoji after each name to give it character, like &lt;li&gt;Emily 🌺&lt;/li&gt;. This can make your list more engaging and visually appealing.
            </li>
          </ol>

          
          <h3>What You’re Learning:</h3>
          <ul>
            <li>
              The &lt;ol&gt; tag stands for "ordered list," and it automatically numbers each item for you, which is perfect for a "Top 5" list format.
            </li>

            <li>
            List items (&lt;li&gt;) within an ordered list will be numbered in the order they're written in the HTML code.
            </li>

            <li>
            Lists help organize information on a webpage, making it digestible and easy to read. They can also make action items, like naming a pet, feel more approachable.
            </li>
          </ul>


          <p>
          Embrace the creativity as you build this guide. Each tag is a tool to make your website as welcoming and informative as the capybara community you're celebrating! 🎉
          </p>
        </div>


        <div style={ currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 25: Connecting with Your Audience 📧✨
          </h2>

          <p>
          Before we close off the body of our page, let's ensure our visitors know how to reach out for their very own capy companion.
          </p>

          <img src={twentyFive} className='demo__image' alt='img_twentyFive' />

          <h3>What To Do:</h3>
          <ol>
            <li>
            Add a concluding &lt;h3&gt; tag that serves as a call to action: &lt;h3&gt;Contact us to get a capy today!📧&lt;/h3&gt;. This stands out beneath the list and prompts readers to take action.
            </li>
          </ol>

          
          <h3>What You’re Learning:</h3>
          <ul>
            <li>
            The &lt;h3&gt; tag here is used for closing statements or calls to action, giving readers a clear next step.
            </li>

            <li>
            Finishing strong with a clear call to action and contact information can significantly increase visitor engagement and response rate.
            </li>
          </ul>
        </div>


        <div style={ currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 26: Final Touches with Contact Us 🎉✨
          </h2>

          <p>
          Part of the "Contact" section of the website is ensure our visitors have a clear email address that hey can send emails to and ask questions!
          </p>

          <img src={twentySix} className='demo__image' alt='img_twentySix' />

          <h3>What To Do:</h3>
          <ol>
            <li>
            Just beneath this heading, include your email address within a paragraph tag. This will look like &lt;p&gt;Email: gotcapyswithstyle@gmail.com💌&lt;/p&gt;. It’s crucial to provide a direct line of communication for potential capy adopters.
            </li>

            <li>
              Do not put a real email address in this line of code. Ensure private information stays private. Instead, provide a fun and silly example of what a good contact email would be for your imaginary capybara adoption center!
            </li>
          </ol>
          

          <h3>
          What You’re Learning:
          </h3>

          <ul>
            <li>Including contact information within a &lt;p&gt; tag makes it straightforward for readers to find how to reach out, ensuring a user-friendly experience.</li>
          </ul>

        </div>


        <div style={ currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 27: Strengthen Your Call to Action 💪📧
          </h2>

          <p>
          Your page is coming together beautifully! Now, let's emphasize the call to action to really grab your reader's attention.
          </p>

          <img src={twentySeven} className='demo__image' alt='img_twentySeven' />

          <h3>
          What To Do:
          </h3>
          <ol>
            <li>
            Use the &lt;strong&gt; tag to bold the word "Email" in your contact information. This tag is used to give importance to text, and in web browsers, it will appear bold, making it stand out to your readers.
            </li>

            <li>
            Remember, the &lt;strong&gt; tag not only changes the appearance but also implies that the text is of strong importance, which is perfect for your contact details.
            </li>
          </ol>
          
          <h3>
          What You’re Learning:
          </h3>
          <ul>
            <li>
            The &lt;strong&gt; tag semantically means that the text is of strong importance, and browsers will typically style this text in bold.
            </li>
          </ul>


          <p>
          By using semantic HTML tags like &lt;strong&gt;, you help screen readers and search engines understand the emphasis and importance of the text, which can aid in accessibility and SEO. The use of semantic markup is a best practice in web development—it helps your website communicate better with browsers and assistive technologies.
          </p>
        </div>


        <div style={ currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 28: Adding Contact Options 📞
          </h2>

          <p>
          A capybara enthusiast may prefer a quick chat over the phone. Let's give them that option by adding a phone number to the contact information.
          </p>

          <img src={twentyEight} className='demo__image' alt='img_twentyEight' />

          <h3>What To Do:</h3>
          <ol>
            <li>
            Create a new paragraph with the &lt;p&gt; tag and include the word "Phone" to indicate the kind of contact information provided.
            </li>

            <li>
            Use the &lt;strong&gt; tag to make the word "Phone" bold, just like you did with "Email." This maintains consistency in your design and helps users quickly find the contact method they prefer.
            </li>

            <li>
            Provide a sample phone number in a readable format. Even though it's a sample, formatting it like a real phone number (e.g., (123) 456-7890) can give your tutorial a touch of realism.
            </li>
          </ol>

          
          <h3>What You’re Learning:</h3>

          <ul>
            <li>Consistent formatting for contact information enhances usability and professional appearance.</li>

            <li>Remember to keep personal information private. Use placeholder information when sharing tutorials publicly.</li>

            <li>Always keep in mind the overall user experience. Each element should contribute to a seamless and enjoyable journey through your capybara-themed website.</li>
          </ul>
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>

    </div>
  )
}

export default AboutCapybaras