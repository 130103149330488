import React, {useState} from 'react';
import one from './fullstackImages/Robux-Calculator-IMAGES/1.png';
import two from './fullstackImages/Robux-Calculator-IMAGES/2.png';
import three from './fullstackImages/Robux-Calculator-IMAGES/3.png';
import four from './fullstackImages/Robux-Calculator-IMAGES/4.png';
import five from './fullstackImages/Robux-Calculator-IMAGES/5.png';
import six from './fullstackImages/Robux-Calculator-IMAGES/6.png';
import seven from './fullstackImages/Robux-Calculator-IMAGES/7.png';
import eight from './fullstackImages/Robux-Calculator-IMAGES/8.png';
import nine from './fullstackImages/Robux-Calculator-IMAGES/9.png';
import ten from './fullstackImages/Robux-Calculator-IMAGES/10.png';
import eleven from './fullstackImages/Robux-Calculator-IMAGES/11.png';
import twelve from './fullstackImages/Robux-Calculator-IMAGES/12.png';
import thirteen from './fullstackImages/Robux-Calculator-IMAGES/13.png';
import fourteen from './fullstackImages/Robux-Calculator-IMAGES/14.png';
import fifteen from './fullstackImages/Robux-Calculator-IMAGES/15.png';
import sixteen from './fullstackImages/Robux-Calculator-IMAGES/16.png';
import seventeen from './fullstackImages/Robux-Calculator-IMAGES/17.png';
import eightteen from './fullstackImages/Robux-Calculator-IMAGES/18.png';
import nineteen from './fullstackImages/Robux-Calculator-IMAGES/19.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const RobuxCalc = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 19;
    
  return (
    <div className='development__container'>
        <h1>
        💰🖩 Road to Riches: Calculate Your Robux 🖩💰
        </h1>

        <h3 className='intro'>
        Ready to dive into the world of virtual riches? 💰 In this fun tutorial, we're going to create a Robux Calculator that'll have you feeling like a digital tycoon! Whether you're looking to tally up your Robux stash or just want to impress your friends with some slick coding skills, this project is the perfect way to level up. So grab your keyboard, put on your coding hat, and let's turn those Robux dreams into reality—one line of JavaScript at a time! 🚀        
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>

          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>



        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Settup the Bloxy Project 🏗️</h2>

            <p>
            Hey there, young coders! Whether you love Roblox, math, or coding this project if for you! I am going to take you through a world of mathematics, coding, and robux! So gear up and download the project template to get started💸
            </p>
            <img src={one} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                Install the template for this project.
                </li>

                <li>
                Open the project in Visual Studio Code.
                </li>

                <li>
                Ensure that you have the following files: <strong>app.js</strong>, <strong>index.html</strong>, <strong>style.css</strong>, and an <strong>images</strong> folder.
                </li>

                <li>
                Make sure that your thinking cap is on🧠
                </li>
            </ol>
        </div>


        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Inject the JavaScript 💉</h2>

            <p>
              It is time to take out our digital needles so that we can inject this HTML file with the <strong>app.js</strong> JavaScript file🧪💉 Remember that any JavaScript that we write to code the HTML will not work unless we import the JavaScript file!
            </p>
            <img src={two} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                Open the <strong>index.html</strong> file.
                </li>

                <li>
                  Go to the bottom of the file, but right above the closing <strong>&lt;/body&gt;</strong> tag and write the following line of code to import the JavaScript file: <strong>&lt;script src="app.js"&gt;&lt;/script&gt;</strong>
                  <ul>
                    <li>
                      The <strong>script</strong> tag tells the HTML file we are trying to import a JavaScript script.
                    </li>

                    <li>
                      The <strong>src="app.js"</strong> is the attribute that says what file we are importing, in this case, <strong>app.js</strong>
                    </li>
                  </ul>
                </li>
            </ol>
        </div>


        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 3: Let's Get Calculating 🧮</h2>

            <p>
              While this fun project revolves around robux💸 We need to make turn our HTML interface into a working calculator! To do this, we need to start the JavaScript function.
            </p>
            <img src={three} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                  Navigate into the <strong>app.js</strong> file.
                </li>

                <li>
                  On line one, declare a new function and name it <strong>calculate</strong>
                </li>

                <li>
                  After you declare and name the function, add the parenthesis and curly brackets.
                </li>

                <li>
                  In this project you are going to learn one of the purposes for the parenthesis.
                  <ul>
                    <li>
                      Click inside of the parenthesis after you type them.
                    </li>

                    <li>
                      In the parenthesis type the word: <strong>operation</strong>
                    </li>

                    <li>
                      This is known as a parameter. A parameter is information that is given to the function from a different area of code. You will see how in the HTML the user will select either add, subtract, divide, or multiply. <strong>The operation</strong> parameter will be equal to which ever one the user selects, kind of like how a variable has a value; a parameter also has a value! 
                    </li>
                  </ul>
                </li>
            </ol>
        </div>





        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: Defining the Parameter 📖</h2>

            <p>
              In the last step we created the <strong>operation</strong> parameter. In this step we are going to learn how a parameter is defined and used!
            </p>
            <img src={four} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                  Go into the <strong>index.html</strong> file.
                </li>

                <li>
                  When we want to <strong>call</strong> a function (use the function). Remember what we need to do? Tell the HTML <em>hey html, when a user clicks this button... use this function</em> and the way that we do this is by typing in an <strong>onclick</strong> attribute with the function. So when a user clicks, the <strong>calculate</strong> function will be called📞
                </li>

                <li>
                  But if you look at the image above, there is something new... Inside of the parenthesis of the <strong>calculate</strong> function is the word <strong>add</strong> this is the value of the <strong>operation</strong> parameter that we made in the last step!
                </li>

                <li>
                  When a user clicks the add button, the <strong>calculate</strong> function will run and the value of the <strong>operation</strong> parameter will be <strong>add</strong>.
                </li>

                <li>
                  What you need to do:
                  <ul>
                    <li>Add the <strong>onclick</strong> attribute to the <strong>add</strong> button in the <strong>index.html</strong> file.</li>

                    <li>Make the value of the <strong>onclick</strong> attribute the <strong>calculate</strong> function.</li>

                    <li>Define the operation parameter to be <strong>add</strong></li>
                  </ul>
                </li>
            </ol>
        </div>




        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Diving Deeper to Parameters 🥽</h2>

            <p>
              The whole point✏️ of parameters is that they are dynamic, they can change and be different each time that you use them.🎭 Look at the image below and observe👀... each button has an <strong>onclick</strong> attribute and each instance of <strong>onclick</strong> calls☎️ the <strong>calculate</strong> function <em>RING RING!!</em> But look inside of the parenthesis of the <strong>calculate</strong> function calls. Each one has a different operation inside of it. This means that when you click the button it was call the <strong>calculate</strong> function but the <strong>operator</strong> parameter will have a value equal to what is written in the parenthesis of the <strong>calculate</strong> function call.
            </p>
            <img src={five} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                  Make each button's <strong>onclick</strong> <strong>calculate</strong> function call have a parameter equal to the operator that would be appropriate for each button.
                  <ul>
                    <li>
                      For the subtract button: Add an <strong>onclick</strong> attribute to call the <strong>calculate</strong> function with an operator parameter of <strong>subtract</strong>.
                    </li>

                    <li>
                      For the multiply button: Add an <strong>onclick</strong> attribute to call the <strong>calculate</strong> function with an operator parameter of <strong>multiply</strong>.
                    </li>

                    <li>
                      For the divide button: Add an <strong>onclick</strong> attribute to call the <strong>calculate</strong> function with an operator parameter of <strong>divide</strong>.
                    </li>
                  </ul>
                </li>

            </ol>
        </div>



        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Back to the Function 🏠🔙</h2>

            <p>
              Now when the calculate function runs🏃 it's code the <strong>operation</strong> parameter in the parenthesis of the function, as seen below, will be equal to what was written in the parenthesis of the function call. In this step, we will write the first line of code to execute in the function call. By creating a variable equal to the first number that the user enters into the robux calculator💵
            </p>
            <img src={six} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                  Go into the calculate function <strong>(between the curly brackets)</strong>.
                </li>

                <li>
                  Declare the variable with <strong>const</strong> and name it <strong>num1</strong> to represent the first number that the user enters into the calculator.
                </li>

                <li>
                How do we know what number the user entered? Check out the steps below:
                <ul>
                  <li>
                    The following code: <strong>document.getElementById('num1).value</strong> is a reference to what is typed in the <strong>input</strong> tag with an <strong>id</strong> of <strong>num1</strong> within the HTML file. 
                  </li>

                  <li>
                    The <strong>parseFloat()</strong> that you see in the code is a method that takes the number inside of its parenthesis and makes sure that it is a number and not a letter or word.
                  </li>
                </ul>
                </li>

                <li>
                  By now you should have the following line of code: <strong>const num1 = parseFloat(document.getElementById('num1').value)</strong>
                </li>
            </ol>
        </div>


        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Referencing the Input 🔢</h2>

            <p>
              The last step we wrote the following line of code to reference to the number that is entered into the first box: <strong>const num1 = parseFloat(document.getElementById('num1').value)</strong>. Let us look closer👀🔎 if we take apart the code we get the following: <strong>getElementById('num1').value</strong>, this is what goes into the HTML and finds the input box with a <strong>id</strong> equal to <strong>num1</strong>. So now, look at the image below and see how that <strong>input</strong> tag has the <strong>id</strong> of <strong>num1</strong>. You have the same <strong>id</strong> in your HTML file, go and examine it to get familiar with what we are doing here.
            </p>
            <img src={seven} className='demo__image' alt='img_one' />
            
        </div>


        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Back to the Function 🏠🔙</h2>

            <p>
              We need to implement the same process for the second number that the user enters into the calculator. The first <strong>input</strong> tag has an <strong>id</strong> of <strong>num1</strong>. The second <strong>input</strong> tag has an <strong>id</strong> of <strong>num2</strong> and should be referenced accordingly!
            </p>
            <img src={eight} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                  Under the <strong>num1</strong> variable, create the <strong>num2</strong> variable.
                </li>

                <li>
                  Declare the variable with <strong>const</strong> and name it <strong>num2</strong>
                </li>

                <li>
                  Give the variable the following value: <strong>parseFloat(document.getElementById('num2').value)</strong>
                  <ul>
                    <li>
                      The <strong>getElementById</strong> here points to the input with an <strong>id</strong> attribute of <strong>num2</strong>
                    </li>
                  </ul>
                </li>
            </ol>
        </div>


        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Reviewing ID's 🔙🏷️</h2>

            <p>
              Looking into the HTML of the JavaScript we just typed. If you look at in the HTML file there is an <strong>input</strong> tag with an <strong>id</strong> of <strong>num2</strong> this is what is being referenced when we created the variable: <strong>const num2 = parseFloat(document.getElementById('num2').value)</strong>
            </p>
            <img src={nine} className='demo__image' alt='img_one' />
        </div>


        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Initialize the Result 🏁</h2>

            <p>
              Before we make any calculations we need to create the result variable. But since we have not yet crunched any numbers, this <strong>result</strong> variable will not have a value.
            </p>
            <img src={ten} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>
            <ol>
              <li>
              Go to the <strong>app.js</strong> JavaScript file.
              </li>

              <li>
                Declare the variable with <strong>let</strong> and name it <strong>result</strong>, but do not give it a value.
              </li>
            </ol>
        </div>


        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: Determine the Operation 🤔</h2>

            <p>
              If we are going to crunch some numbers💥 We need to know what operation that the user of the calculator is trying to complete! Remember🧠 the <strong>operation</strong> parameter is equal to what it was defined as in the function call back in the HTML file. We need to take the <strong>operation</strong> parameter and check what the value of it is so that we can do the correct equation on the numbers.
            </p>
            <img src={eleven} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>
            <ol>
              <li>
              In this step we are going to create an <strong>if statement</strong>
              <ul>
                <li>An if statement, also known as a conditional, checks if something is <strong>true</strong> or <strong>false</strong> if it is <strong>true</strong> then it will run the code that we write between the <strong>curly brackets</strong>, but if it is <strong>false</strong> then it will not run the code between the <strong>curly brackets</strong>.</li>
              </ul>
              </li>

              <li>
                To make the <strong>if statement</strong>, start by typing the word <strong>if</strong> followed by a set of parenthesis.
              </li>

              <li>
                Inside of the parenthesis write the <strong>equation</strong> that you are checking is <strong>true</strong> or <strong>false</strong>. In this case the equation is checking if the operation parameter is add. So you would write <strong>operation == 'add'</strong> for the equation.
              </li>

              <li>
                After the equation type the <strong>curly brackets</strong> that will contian the code that runs if the equation evaluates to <strong>true</strong>.
              </li>
            </ol>
        </div>




        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Adding Up Robux ➕💸</h2>

            <p>
              If the selected <strong>operation</strong> is in fact <strong>add</strong> then we need to start adding the numbers to get the result. Think of having 10,000 robux then you make a successful Roblox game and get 100,000 more robux. Now you have 110,000 robux <em>CHA CHING!!!</em>💸💸💸
            </p>
            <img src={twelve} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>
            <ol>
              <li>
                Go inside of the <strong>curly brackets</strong> of the <strong>if statement</strong> this is where you write the code that should run if the <strong>operation</strong> is <strong>add</strong>.
              </li>

              <li>
                Change the value of the result variable by typing <strong>result =</strong>
              </li>

              <li>
                Set the value of the result variable by adding the first and second number: <strong>num1 + num2</strong>
              </li>

              <li>
                Now result is equal to the two numbers added together!
              </li>
            </ol>
        </div>


        
        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: else if operator ⁉️</h2>

            <p>
              What happens if the <strong>operation</strong> parameter is not <strong>add</strong>? We need to check if it is a different operation. To do this, we use <strong>else if</strong>. This will check for a different thing if the first <strong>if statement</strong> ended up being false. In our particular situation if the <strong>operation</strong> parameter is not <strong>add</strong> then we will check if it is <strong>subtract</strong>.
            </p>
            <img src={thirteen} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>
            <ol>
              <li>
              Start typing your <strong>else if statement</strong> at after the closing <strong>curly bracket</strong> of the <strong>if statement</strong>.
              </li>

              <li>
                Code out <strong>else if</strong> followed by a parenthesis to contain what we are checking for.
              </li>

              <li>
                In the parenthesis write the following code to check if the <strong>operation</strong> is <strong>subtract</strong>: <strong>operation === 'subtract'</strong>
              </li>

              <li>
                This will evaluate to <strong>true</strong> if the operation is <strong>subtract</strong> and run the code written in its corresponding <strong>curly brackets</strong>.
              </li>
            </ol>
        </div>



        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: Stolen Robux 🚨🦹</h2>

            <p>
              So your Roblox game took off and you are making some <em>CRAZY CASH</em>💸. But far off in the distance is a Roblox hacker. He sees all of your robux that you have earned and decides to take some from you. For this we need to create a <em>subtraction equation!!!</em>
            </p>
            <img src={fourteen} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>
            <ol>
              <li>
                If the <strong>operation</strong> value ends up being equal to <strong>subtract</strong>, the <strong>else if statement</strong> will run the code inside of its <strong>curly brakcets</strong>.
              </li>

              <li>
                Write the following line of code to set the <strong>result</strong> variable equal to the <strong>num1</strong> subtracted by <strong>num2</strong>: <strong>result = num1 - num2</strong>
              </li>
            </ol>
        </div>



        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15: else if continued... 🚂</h2>

            <p>
              You know what they say🦜 <em>TWO HEADS ARE BETTER THEN ONE!</em> So your career as a Roblox Developer has taken off, you wise up, and decide to work on a new project with another developer to increase your rate of work. This means you need to start multiplying all of your robux because of how much the games are making🎮
            </p>
            <img src={fifteen} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>
            <ol>
              <li>
                If you want to keep checking for other operations, in case the <strong>operation</strong> was not <strong>add</strong> or <strong>subtract</strong> then you need to create more <strong>else if statements</strong>. And this time it is to check if the <strong>operation</strong> is <strong>multiply</strong>.
              </li>

              <li>
                To accomplish this, start coding at the end of the your last <strong>else if</strong> and begin typing a new <strong>else if</strong> followed by a set of parenthesis.
              </li>

              <li>
                In these new parenthesis type the following equation to check if the <strong>operation</strong> is <strong>multiply</strong>: <strong>operation === "multiply"</strong>
              </li>

              <li>
                Do not forget to add a set of curly brackets at the end to contain the code that runs should the <strong>operation</strong> we equivalent to <strong>multiply</strong>.
              </li>
            </ol>
        </div>



        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 16: Robux Frenzy 🤑🤑🤑</h2>

            <p>
              It's time to <em>STACK YOUR CASH</em>💲 Go crazy and create your multiplication equation🔢
            </p>
            <img src={sixteen} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>
            <ol>
              <li>
                If the first <strong>if statement</strong> is not <strong>add</strong> and the second <strong>else if statement</strong> is not <strong>subtract</strong>, then the third <strong>else if statement</strong> might evalutate to the <strong>operation</strong> being <strong>multiply</strong>. If this is the case then you will need to multiply your robux.
              </li>

              <li>
                Create the multiplication equation within the <strong>curly brackets</strong>. Write the following code to set the <strong>result</strong> variable equal the numbers multiplied: <strong>result = num1 * num2</strong>
              </li>
            </ol>
        </div>


        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 17: The Final else if 🏁</h2>

            <p>
              The last operation that we will check for is <strong>divide</strong>. Since there are only four possible buttons to press in the HTML file we are left with these four operations to check for: <strong>add</strong>, <strong>subtract</strong>, <strong>multiply</strong>, and <strong>divide</strong>.
            </p>
            <img src={seventeen} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>
            <ol>
              <li>
              Add the final <strong>else if statement</strong> at the end of the previous one.
              </li>

              <li>
                Within the parenthesis of the <strong>else if statement</strong> write the following operation: <strong>operation === 'divide'</strong>
              </li>

              <li>
                Don't forget to add the curly brackets at the end of the statement.
              </li>
            </ol>
        </div>


        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 18: Divide up the Robux 🍰</h2>

            <p>
              Let's say your roblox development operation gets so big that you have to hire a bunch of other roblox developers. This means you will need to divide up the robux earnings with your team members. In this step we will write the division equation🙂💻
            </p>
            <img src={eightteen} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>
            <ol>
              <li>
                Go between the <strong>curly brackets</strong> of the divide <strong>else if statement</strong>.
              </li>

              <li>
                Set the <strong>result</strong> variable equal to the division operation by writting the following code: <strong>result = num1 / num2</strong>
              </li>
            </ol>
        </div>


        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 19: Setting the Final Result 🏭</h2>

            <p>
              You are left with what we like to call a <strong>conditional chain</strong>⛓️‍💥. A <strong>conditional chain</strong> is a sequence of <strong>if statements</strong> and <strong>else if statements</strong> to evaluate equations to either <strong>true</strong> or <strong>false</strong>. Now that you have this, you need to take the value of the <strong>result</strong> variable and put it into the HTML document to display it on the website📝
            </p>
            <img src={nineteen} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>
            <ol>
              <li>
                Type the following line of code to reference the HTML elemt with an <strong>id</strong> of <strong>result</strong> so that we can set it equal to the result variable: <strong>document.getElementById('result').textContent</strong>
              </li>

              <li>
                The full line of code will set the HTML element equal to the result variable: <strong>document.getElementById('result').textContent = result</strong>
              </li>
            </ol>
        </div>





        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default RobuxCalc