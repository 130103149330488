import React, {useState} from 'react';
import one from './fullstackImages/topFiveBooks/1.png';
import two from './fullstackImages/topFiveBooks/2.png';
import three from './fullstackImages/topFiveBooks/3.png';
import four from './fullstackImages/topFiveBooks/4.png';
import five from './fullstackImages/topFiveBooks/5.png';
import six from './fullstackImages/topFiveBooks/6.png';
import seven from './fullstackImages/topFiveBooks/7.png';
import eight from './fullstackImages/topFiveBooks/8.png';
import nine from './fullstackImages/topFiveBooks/9.png';
import ten from './fullstackImages/topFiveBooks/10.png';
import eleven from './fullstackImages/topFiveBooks/11.png';
import twelve from './fullstackImages/topFiveBooks/12.png';
import thirteen from './fullstackImages/topFiveBooks/13.png';
import fourteen from './fullstackImages/topFiveBooks/14.png';
import fifteen from './fullstackImages/topFiveBooks/15.png';
import sixteen from './fullstackImages/topFiveBooks/16.png';
import seventeen from './fullstackImages/topFiveBooks/17.png';
import eighteen from './fullstackImages/topFiveBooks/18.png';
import nineteen from './fullstackImages/topFiveBooks/19.png';
import twenty from './fullstackImages/topFiveBooks/20.png';
import twentyOne from './fullstackImages/topFiveBooks/21.png';
import twentyTwo from './fullstackImages/topFiveBooks/22.png';
import twentyThree from './fullstackImages/topFiveBooks/23.png';
import twentyFour from './fullstackImages/topFiveBooks/24.png';
import twentyFive from './fullstackImages/topFiveBooks/25.png';
import twentySix from './fullstackImages/topFiveBooks/26.png';
import twentySeven from './fullstackImages/topFiveBooks/27.png';
import twentyEight from './fullstackImages/topFiveBooks/28.png';
import twentyNine from './fullstackImages/topFiveBooks/29.png';
import thirty from './fullstackImages/topFiveBooks/30.png';
import thirtyOne from './fullstackImages/topFiveBooks/31.png';
import thirtyTwo from './fullstackImages/topFiveBooks/32.png';
import thirtyThree from './fullstackImages/topFiveBooks/33.png';
import thirtyFour from './fullstackImages/topFiveBooks/34.png';
import thirtyFive from './fullstackImages/topFiveBooks/35.png';
import thirtySix from './fullstackImages/topFiveBooks/36.png';
import thirtySeven from './fullstackImages/topFiveBooks/37.png';
import thirtyEight from './fullstackImages/topFiveBooks/38.png';
import thirtyNine from './fullstackImages/topFiveBooks/39.png';
import forty from './fullstackImages/topFiveBooks/40.png';
import fortyOne from './fullstackImages/topFiveBooks/41.png';
import fortyTwo from './fullstackImages/topFiveBooks/42.png';
import fortyThree from './fullstackImages/topFiveBooks/43.png';
import fortyFour from './fullstackImages/topFiveBooks/44.png';
import fortyFive from './fullstackImages/topFiveBooks/45.png';
import fortySix from './fullstackImages/topFiveBooks/46.png';
import fortySeven from './fullstackImages/topFiveBooks/47.png';
import fortyEight from './fullstackImages/topFiveBooks/48.png';
import CopyBox from './copyBox/copybox';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const TopFiveBooks = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 48;

  return (
    <div className='development__container'>

        <h1>
        📖 What are your Top-5-Books ? 📖 
        </h1>

        <h3 className='intro'>
        Hey book worms 🪱! Who doesn't love a good read? In this project you are going to get to make your very own blog to showcase to the world you most favorite books. Just think of your five very favorites... and if you do not have any... you can make them up!
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>

          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>

        </div>





        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Setting Up Your Adventure Space! 📚</h2>

            <p>
            Hey there, young coders! Ready to embark on an epic coding journey? Let’s start by creating book shelf for our project, which we’ll call 'TOP-FIVE-BOOKS'—think of it as the treasure chest where we’ll keep all our coding gems! 📚✨
            </p>
            <img src={one} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                Open your code editor.
                </li>

                <li>
                Create a new project or folder and name it 'TOP-FIVE-BOOKS'. This is where all the coding will happen.
                </li>

                <li>
                Inside this folder, conjure up a new file called index.html.
                </li>
            </ol>

            <p>
            You've just learned about directories (fancy name for folders) and files. Remember, keeping your code organized is key to being a great coding genius!
            </p>
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Crafting Your First Folder! 📄</h2>

            <p>
            Fantastic work on opening the gates to your coding kingdom! Now, let’s add a special room where we’ll store all the enticing pictures for our story. We’ll call this room the 'images' folder.
            </p>
            <img src={two} className='demo__image' alt='img_two' />
            
            <h3>Follow these steps:</h3>

            <ol>
                <li>
                Select 'New Folder' from the magical context menu that appears.
                </li>

                <li>
                Name this folder 'images'. This will be the gallery of your adventure!
                </li>
            </ol>

            <p>
            By doing this, you're learning about organization—a crucial skill for any coder. It keeps your project tidy!
            </p>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 3: Populating Your Image Gallery! 🖼️✨</h2>

            <p>
            Wow, you’re doing great! Now, it’s time to fill your 'images' folder with mystical creatures and wondrous landscapes. Gather all your images (files like .png or .jpg) and place them into the 'images' folder.
            </p>
            <img src={three} className='demo__image' alt='img_three' />
            
            <h3>Here’s how you can make your gallery come to life:</h3>

            <ol>
                <li>
                Use the download buttons down below to save your images into your newly created folder.
                </li>

                <li>
                    You should have a total of six images saved: airplane.png, books.png, lunaLantern.png, museum.png, penguin.png, and whisperingWoods.png
                </li>
            </ol>

            {/*<div className='download__buttons__container'>
                <Download src={airplane} filename="airplane.png" />

                <Download src={books} filename="books.png" />

                <Download src={lunaLantern} filename="lunaLantern.png" />

                <Download src={museum} filename="museum.png" />

                <Download src={penguin} filename="penguin.png" />

                <Download src={whisperingWoods} filename="whisperingWoods.png" />
            </div>*/}


            <p>
            Happy coding! And stay tuned for our next adventure step where we'll start writing the code to bring everything to life!
            </p>
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: Weaving the Web of Style! 🎨</h2>

            <p>
            Hey there, coding pals! 🌟 Ready to add some pizzazz to your project? Let's dress up your website by creating a story of style, known as style.css. This is where we'll mix our crazy colors and set the tone for our adventure!
            </p>
            <img src={four} className='demo__image' alt='img_four' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                In your 'TOP-FIVE-BOOKS' folder, create a new file called style.css. This is like choosing the fabric for your website's outfit.
                </li>

                <li>
                Make sure it's in the same directory as your index.html, index.html and style.css are best friends for life!
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                CSS (Cascading Style Sheets): Think of it as the fashion designer for your website. It decides how your HTML elements (like headers, paragraphs, images) will look on the screen.
                </li>
            </ul>
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Laying the Foundation Brick by Brick! 🧱🏗️</h2>

            <p>
            Coding knights and princesses, it's time to lay the foundation of our castle, the HTML document! 🏰✨ Start by opening the index.html parchment and writing the base code:
            </p>
            <img src={five} className='demo__image' alt='img_five' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Write &lt;!DOCTYPE html&gt; at the top. It's like telling the kingdom that you're speaking the language of HTML5.
                </li>

                <li>
                Add the &lt;html lang="en"&gt; tag. This is like opening the gates of your castle and saying, "English is spoken here!"
                </li>

                <li>
                Make sure to close the &lt;html&gt; tag at the end. It's like closing the gates after your guests have arrived.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                DOCTYPE: This tells your web browser which version of HTML you're using. In our case, HTML5 is the latest and greatest!
                </li>

                <li>
                HTML Tags: These are the basic building blocks of your webpage, like the LEGO bricks of the internet. The &lt;html&gt; tag is the starting point, holding everything together.
                </li>

                <li>
                Lang Attribute: This little bit of code specifies the primary language of your document. It's important for accessibility and search engines, kind of like the flag waving at the top of your castle!
                </li>
            </ul>

            <p>
            Stay curious, little coders, and remember, every great website starts with a single line of code. Keep stacking those HTML bricks, and soon you'll have a castle as grand as any storybook palace! 🏰💖 Happy coding!
            </p>
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Giving Your Page with a Title! 🌟📜</h2>

            <p>
            Let's start by adding a title and some commands that make your page visible and friendly to all the devices out there!
            </p>
            <img src={six} className='demo__image' alt='img_six' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                First, make sure your index.html is open and you have created the &lt;head&gt; section.
                </li>

                <li>
                Write the &lt;meta charset="UTF-8"&gt; tag to tell browsers to use the Universal Character Set, so every symbol is understood.
                </li>

                <li>
                Next, add &lt;meta name="viewport" content="width=device-width, initial-scale=1.0"&gt;. This command makes your page look splendid on devices of all sizes, from the smallest pixie phones to the largest dragon tablets.
                </li>

                <li>
                Conclude this part with the &lt;title&gt; tag. Between &lt;title&gt; and &lt;/title&gt;, type in your chosen title for the web page.
                </li>

                <li>
                    The last line of code in your &lt;head&gt; tag should be the link to the CSS file: &lt;link href="./style.css" rel="stylesheet" /&gt;
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Metadata: These are lines that aren't visible in the webpage but are very important for the website.
                </li>

                <li>
                Title: This is what shows up on the tab in a web browser and it’s often the first thing people see.
                </li>
            </ul>
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Starting the Navigation Bar! 🧭</h2>

            <p>
            Onward, noble coders! Let's craft the navigation bar, which will guide our visitors through the lands of our webpage.
            </p>
            <img src={seven} className='demo__image' alt='img_seven' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Go to the body of your index.html document.
                </li>

                <li>
                Use the div element and give it a class of navbar by typing &lt;div class="navbar"&gt;&lt;/div&gt;. This is like laying down the first stone in a path that leads to many adventures.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Div: The div is a box in which you can put anything. It’s very useful for organizing your page.
                </li>
            </ul>

            <p>
            Each line of code you write is a step further in your quest to build wondrous digital realms! 🌌💻
            </p>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Crafting the Navigation Portions 🚪🌐</h2>

            <p>
            Alright, brave explorers of the coding realms! It's time to carve out the paths in our forest by crafting the navigation bar where our fellow adventurers can choose their journey through our book treasury.
            </p>
            <img src={eight} className='demo__image' alt='img_eight' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Inside the div with the class navbar, cook up two more divs to separate the left and right side of our navigation bar.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Divisions in HTML (&lt;div&gt;): They're like the rooms in a castle, separating different areas for specific purposes.
                </li>
            </ul>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Infusing the Divs with Purpose 📜🖌</h2>

            <p>
            Splendid work! Now that our divs are in place, it’s time to fill them with life! 
            </p>
            <img src={nine} className='demo__image' alt='img_nine' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Within the nav-left div, let’s inscribe a title &lt;h2&gt;My Books&lt;/h2&gt;. This will be the banner under which all your stories gather.
                </li>

                <li>
                In the nav-right div, we'll write three guiding lights in the form of paragraphs: &lt;p&gt;Home&lt;/p&gt;, &lt;p&gt;More Articles&lt;/p&gt;, and &lt;p&gt;My Account&lt;/p&gt;. These will guide your visitors to different chapters of your site.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Headings (&lt;h2&gt;): Like the title of a book, it tells readers what’s important.
                </li>

                <li>
                Paragraphs (&lt;p&gt;): Used for text, but here they act as stepping stones to other realms of your site.
                </li>
            </ul>
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Weaving the Base Cloth of Your Web Tapestry 🧶🌌</h2>

            <p>
            Brace yourselves, young web crafters! We're about to weave the base cloth of our web tapestry. It's time to open the tome of style.css and inscribe some universal style that will affect every corner of our domain.
            </p>
            <img src={ten} className='demo__image' alt='img_ten' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                In your style.css, scribe the universal selector *—a powerful symbol that calls upon every element in the realm.
                </li>

                <li>
                Inside this rule, set margin and padding to zero, add the box-sizing declaration, and unify the font-family.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Universal Selector (*): This targets every single element on your webpage without exception.
                </li>

                <li>
                Margin and Padding: These are the spaces outside and inside the elements' borders, respectively.
                </li>

                <li>
                Box-Sizing: This determines how the overall size of elements is calculated.
                </li>

                <li>
                Font-Family: This defines the typeface that will be used throughout your webpage.
                </li>
            </ul>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: Structuring the Navigation 🛋</h2>

            <p>
            Let's now focus our attention on the navbar that will fly visitors through the different chapters of our storybook site.
            </p>
            <img src={eleven} className='demo__image' alt='img_eleven' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Target the class .navbar with a period (.)—this is how we refer to classes in the realm of CSS.
                </li>

                <li>
                Infuse it with display: flex;, transforming the navbar into a flexible row of content. It's like telling a group of adventurers to stand side by side.
                </li>

                <li>
                Align it at the center of the navbar with align-items: center;, ensuring they're in perfect harmony.
                </li>

                <li>
                Spread it out to balance the space with justify-content: space-between;, which is like giving each adventurer the personal space they deserve within the navbar.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Align Items: This property aligns items vertically in the current line of the flex container.
                </li>

                <li>
                Justify Content: This distributes space between and around content items along the main axis of their container.
                </li>
            </ul>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Expanding the Nav ✨</h2>

            <p>
            Now, let's expand the navbar—so it stretches wide across the top of our page and has enough space for all the links.
            </p>
            <img src={twelve} className='demo__image' alt='img_twelve' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Enhance its size by setting width: 100%;, making it as wide as the window to the browser.
                </li>

                <li>
                Give it the height of 72px, enough to let the links breathe but not so much that it overshadows the content below.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Width and Height: These properties set the size of your elements. Setting the width to 100% ensures it stretches across the full width of its container.
                </li>
            </ul>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: Cushioning Your Nav 🛋️</h2>

            <p>
            It's time to add some cushioning to ensure a comfortable journey for our web travelers.
            </p>
            <img src={thirteen} className='demo__image' alt='img_thirteen' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Within the .navbar rule in your style.css, let's add some padding on the left and right.
                </li>

                <li>
                Type padding-left: 28px; and padding-right: 28px;, creating a cozy space on either side of the navbar. This ensures that the navigation items aren't pressed up against the edge of the browser, giving them room to breathe.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Padding: Padding adds space inside of an element, between the content and its border. It's like the margin of safety for a boat in a bottle, ensuring it doesn't touch the glass.
                </li>
            </ul>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: Casting a Shadow of Depth 🌑</h2>

            <p>
            Coders, it's time to add a dash of depth to our navigation bar! 
            </p>
            <img src={fourteen} className='demo__image' alt='img_fourteen' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                In your style.css parchment, find the .navbar class where our previous rules are neatly inscribed.
                </li>

                <li>
                At the end of this class, chant the shadow code: box-shadow: 0px 3px 15px 5px rgba(0,0,0,0.2);. This will cast a subtle shadow beneath the navbar, making it pop out from the backdrop of your page.
                </li>

                <li>
                    You can use the box below to copy-paste this rule if you want.
                </li>
            </ol>

            <CopyBox
            text={"-webkit-box-shadow: 0px 3px 15px 5px rgba(0,0,0,0.2); box-shadow: 0px 3px 15px 5px rgba(0,0,0,0.2);"}
            />

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Box Shadow: This CSS property is like a coder's trick for creating depth. The values describe the shadow's horizontal and vertical offsets, its blur radius, spread radius, and color.
                </li>
            </ul>
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15: Aligning Your Navigational Runes 🧭</h2>

            <p>
            With the shadows cast, let's turn our focus to the right side within our navbar—the navigational items.
            </p>
            <img src={fifteen} className='demo__image' alt='img_fifteen' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                In the style.css file, after the .navbar class, add a new class targeting .navbar .nav-right.
                </li>

                <li>
                Write down the flex properties to align the navigation items properly.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Child Selector (.parent .child): This CSS pattern is used to specifically target child elements within a parent, ensuring that the styles only apply where intended.
                </li>
            </ul>
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 16: Giving Life to Navigation Links ✨</h2>

            <p>
            With some CSS, we'll make the links inviting and interactive.
            </p>
            <img src={sixteen} className='demo__image' alt='img_sixteen' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                In style.css, target the paragraphs within the .nav-right class by using .navbar .nav-right p.
                </li>

                <li>
                Give this target two declarations: margin-left: 18px; for space and cursor: pointer; to change the cursor upon hover.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Margin: This CSS property creates space around elements, separating them from their neighbors.
                </li>
            </ul>
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 17: Laying the Foundation of Your Content Kingdom 🏰</h2>

            <p>
            With the navigation links ready for travel, let's lay down the foundation of our content kingdom. This is where the tales and legends of your blog will reside.
            </p>
            <img src={seventeen} className='demo__image' alt='img_seventeen' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Within the &lt;body&gt; of your index.html, below the closing tag of your navbar, create a new div and grant it the class of blog-container.
                </li>

                <li>
                This div will act as the grand hall of your content kingdom, housing all the wisdom and stories you wish to share.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Content Container: In web design, containers are used to encapsulate sections of content, making them easier to style and manage.
                </li>

                <li>
                Class Naming: Assigning a class to a div is like giving it a purpose and an identity within your HTML structure.
                </li>
            </ul>

            <p>
            Behold! With every element you add and style, your site becomes more than a page—it becomes a story, a world for others to explore and marvel at. Continue with this blend of structure and style, and soon, you'll unveil a site that's as engaging as the tales it tells. 🌟📖
            </p>
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 18: Crowning Your Blog Container 👑📖</h2>

            <p>
            Let's crown your blog container with a grand title that proclaims the theme of your collection of tales and wisdom!
            </p>
            <img src={eighteen} className='demo__image' alt='img_eighteen' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Enter the blog-container within your index.html.
                </li>

                <li>
                Inside this container, create a &lt;h1&gt; tag and grant it the class of title. This is not just any heading; it’s the one that will echo throughout the kingdom as the name of your collection.
                </li>

                <li>
                Proclaim your page with the title: "Top Five 'Must Reads': A comprehensive book guide."
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Headings (&lt;h1&gt; - &lt;h6&gt;): These tags are used to define headings in an HTML document. &lt;h1&gt; is typically reserved for the main title due to its importance in the structure and SEO.
                </li>
            </ul>
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 19: Setting the Scene with an Introduction 🌟📝</h2>

            <p>
            Now, beneath the title, it’s time to set the scene with an introduction that beckons readers into the world of your blog.
            </p>
            <img src={nineteen} className='demo__image' alt='img_nineteen' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Directly after your &lt;h1&gt; tag within the blog-container, add the &lt;p&gt; tag.
                </li>

                <li>
                Give this tag with the class intro and fill it with your own introduction message or you can copy and use the one in the box below.
                </li>
            </ol>

            <CopyBox
            text={"Embarking on a literary journey offers an unparalleled adventure, a voyage that transcends time, space, and imagination. In the vast ocean of literature, where countless stories vie for attention, discovering those rare gems that resonate with our souls becomes a quest of its own. Top 5 Books is a curated collection designed to guide you through this quest, offering a window into worlds crafted by the deftest of hands. Each book selected stands as a beacon of inspiration, creativity, and insight, promising not only to entertain but to enlighten. Whether you're a voracious reader hungry for your next great read or a casual enthusiast looking to dip your toes into the literary waters, this blog aims to connect you with books that will leave a lasting imprint on your heart and mind. Join us as we unveil these masterpieces, each a testament to the power of storytelling and its ability to illuminate the darkest corners of the human experience."}
            />

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Paragraphs (&lt;p&gt;): These are the building blocks of content in HTML, used to hold and format blocks of text.
                </li>
            </ul>

            <p>
            Your blog is not just a site; it’s a gateway to knowledge and imagination, carefully crafted by your hands! 📚✨ Keep weaving this digital tapestry, and soon, you'll have a masterpiece to share with the world.
            </p>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 20: Illustrating Your Tale 🖼️📚</h2>

            <p>
            Every great tale needs an illustration to captivate the audience. Let's add an image to visually represent the literary journey ahead.
            </p>
            <img src={twenty} className='demo__image' alt='img_twenty' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Within the blog-container, just after the welcoming words of the intro, let's create a div that will hold our illustration. We'll call this the cover-image-container.
                </li>

                <li>
                Inside this div, add an &lt;img/&gt; tag, setting its src to "./images/books.png", allowing the image to be revealed on your page.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Image Element (&lt;img/&gt;): This is how you display images on a webpage. The src attribute is where you specify the path to the image file.
                </li>
            </ul>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 21: Centering Your Story 🎯📖</h2>

            <p>
            A well-centered story is like a well-centered mind: clear, focused, and engaging. Let's ensure our blog container is just that, a central piece to your webpage.
            </p>
            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Open your style.css and target the .blog-container class where we will create our next rule.
                </li>

                <li>
                Touch it with display: flex; to introduce flexibility to its layout.
                </li>

                <li>
                With flex-direction: column; we tell the tales within to stack vertically.
                </li>

                <li>
                align-items: center; ensures everything inside is aligned in the middle, providing balance and harmony.
                </li>

                <li>
                justify-content: flex-start; aligns the content to the top of the container, starting the story right at the beginning.
                </li>

                <li>
                And width: 100%; allows the container to expand across the full width of the viewport.
                </li>
            </ol>

            <p>
            And there you have it! With every tweak, you're one step closer to revealing a digital kingdom of stories, a blog that not only informs but also wows its visitors. Continue this journey, for you are almost ready to unveil your creation to the world. 🌐✍
            </p>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 22: Crafting Comfort for Your Stories 📚</h2>

            <p>
            It's time to make sure the tales within your blog-container have the comfort they deserve. Padding around the content will make it more appealing and readable to the kingdom's visitors.
            </p>
            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Within the .blog-container class in your style.css, it's time to add some cushioning. Think of padding like the plush seating in a grand library, inviting readers to stay awhile.
                </li>

                <li>
                Type up padding-top: 45px; to give some breathing room at the top.
                </li>

                <li>
                Add padding-left: 205px; and padding-right: 205px; to create space on the sides, setting the stage for the content and making sure it's not pressed up against the edges of the browser.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Padding: This CSS property is used to create space within an element, between the content and the border.
                </li>
            </ul>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 23: Emphasizing the Title of Your Epic 🏷️✨</h2>

            <p>
            A title is the banner of your tale, and it must stand out in size, weight, and position to capture the attention of wandering eyes.
            </p>
            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Target the title class within your .blog-container using .blog-container .title.
                </li>

                <li>
                Set the font-size: 52px; to make the title large and in charge, a true declaration of the journey ahead.
                </li>

                <li>
                Align the text to the center with text-align: center; so it sits like a crown atop your content.
                </li>

                <li>
                Apply a font-weight: 400; to give it the perfect balance of prominence and readability.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Font Size: This property controls how big or small text appears on the screen.
                </li>

                <li>
                Text Align: This determines the horizontal alignment of text within an element.
                </li>

                <li>
                Font Weight: This dictates the thickness of the text, affecting its boldness and visual impact.
                </li>
            </ul>

            <p>
            Your blog is almost ready to unfurl its banners and welcome readers from far and wide into its embrace. 📖🌍 Keep going, and soon you'll share your world with eager minds everywhere!
            </p>
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 24: Balancing the Introduction of Your Epic 📖⚖️</h2>

            <p>
            Before diving into the heart of your tales, it's important to balance the introduction so it welcomes readers warmly. Let's add some style to your intro class to set the stage for your blog's narrative journey.
            </p>
            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Target the .blog-container .intro selector to apply styles specifically to your introduction paragraph within the blog container.
                </li>

                <li>
                Center your text with text-align: center; to draw the eyes to the heart of the canvas.
                </li>

                <li>
                Add margin-top: 28px; and margin-bottom: 28px; to give the title and the content that follows enough room to breathe.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Text Align: Centers the text within its container, perfect for titles and introductory paragraphs.
                </li>

                <li>
                Margin: Adds space around elements, which can be used to separate text from surrounding content.
                </li>
            </ul>
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 25: Ensuring Your Cover Image Commands Attention 🌟🖼️</h2>

            <p>
            A cover image should capture the essence of your tales, and so it must be given prominence. We will ensure that the container for your cover image stretches across the entirety of the blog container.
            </p>
            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                With the .blog-container .cover-image-container selector in your style.css, we're defining the space where your story's visual journey begins.
                </li>

                <li>
                Bestow upon it width: 100%;, allowing your image to fill the container and create a striking visual impact.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Width: This property defines the width of an element's rendering box. Setting it to 100% ensures it will be as wide as its parent container allows.
                </li>
            </ul>
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 26: Perfecting the Presentation of Your Cover Image 🎨📸</h2>

            <p>
            Your cover image is the first impression visitors get of your blog's theme, so it's essential to ensure it's displayed correctly. Let's cast a spell to ensure the image fits perfectly within its container.
            </p>
            <img src={twentySix} className='demo__image' alt='img_twentySix' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Open your style.css file and target the .blog-container .cover-image-container img selector.
                </li>

                <li>
                Set the width to 100% to ensure the image expands to fill its container.
                </li>

                <li>
                Use object-fit: contain; to make sure the image maintains its aspect ratio without stretching, so it looks just as it should.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Width: This CSS property ensures the element spans the full width of its parent container.
                </li>

                <li>
                Object-Fit: This CSS property is used to specify how an &lt;img/&gt; should be resized to fit its container.
                </li>
            </ul>
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 27: Adding the Excerpt Container 📜🌟</h2>

            <p>
            Now, let's add structure to your blog by introducing an excerpt container. This container will hold a snippet from one of the "Top Five 'Must Reads'" to entice readers and give them a taste of what's inside.
            </p>
            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Stay within the blog-container in your index.html file.
                </li>

                <li>
                After the closing tag of the .cover-image-container, craft a new div and bestow upon it the class name excerpt.                
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Excerpt Container: This HTML &lt;div&gt; will serve as a dedicated space for a short preview of content, an "excerpt" that gives the reader a peek into what the full content has to offer.
                </li>
            </ul>
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 28: Introducing Your First Story 📖🌲</h2>

            <p>
            As each story in your collection deserves its own spotlight, let's frame the title of the first tale, "The Whispering Woods," so it stands out and invites readers into the narrative.            
            </p>
            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Within the excerpt container in your index.html, we need to place an &lt;h2&gt; tag with the title of the story.
                </li>

                <li>
                This tag not only serves a functional purpose but also an aesthetic one, acting as a signpost drawing the reader's eyes to the story's entry point.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Headings (&lt;h2&gt;): The &lt;h2&gt; tag is typically used for subheadings or titles of sections within your content, ranking just below &lt;h1&gt; tags in the hierarchy of importance.
                </li>
            </ul>
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 29: Adding Imagery to Your Story's Excerpt 📸✨</h2>

            <p>
            A picture speaks a thousand words, so let's add an image to complement the title of your excerpt. 
            </p>
            <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Under the title in the excerpt div, create a div with the class image-container. This will be the container for your image.
                </li>

                <li>
                Within this newly created div, add an &lt;img/&gt; element, ensuring that the src attribute is pointing to the correct file in your images directory.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Image Container: Creating a specific container for images allows for more control over the styling and positioning of the image within the layout.
                </li>

                <li>
                Image Element (&lt;img/&gt;): This tag is used to embed images in an HTML page and is crucial for adding visual elements to your storytelling.
                </li>
            </ul>
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 30: Adding Substance to Your Story's Snippet 📖✍️</h2>

            <p>
            Your story’s excerpt is the hook that draws readers into the depths of your tale. Let's give it the substance it deserves.
            </p>
            <img src={thirty} className='demo__image' alt='img_thirty' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                In your index.html, within the excerpt div, below the image container, breathe life into the story by adding a &lt;p&gt; tag.
                </li>

                <li>
                Pour the words of your excerpt into this paragraph, giving your readers a taste of the story and inviting them to read on. You can use the text in the copy box below.
                </li>
            </ol>

            <CopyBox
            text={"In The Whispering Woods, we journey with young Eli as he discovers a hidden path leading to a magical forest where trees speak in riddles and shadows harbor ancient secrets. One evening, under a sky twinkling with stars, Eli encounters the wise old oak, Alaric, who whispers, In the heart of the forest, lies a mystery only the bravest can unveil. But remember, courage isn't the absence of fear, but the determination to face it. This enchanting tale weaves lessons of bravery, friendship, and the importance of listening to the world around us."}
            />

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Paragraphs (&lt;p&gt;): These HTML elements are used to define blocks of text. They're the main tags for the written content on your page.
                </li>
            </ul>
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 31: Styling Your Story's Excerpt for Impact 🎨🖌️</h2>

            <p>
            The way your story's excerpt is presented can greatly affect its impact on the reader. It's important to style it so that it stands out and is comfortable to read.
            </p>
            <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Open your style.css file and target the .blog-container .excerpt selector.
                </li>

                <li>
                Adjust the margin-top and margin-bottom properties to give the title and content a well-defined space, ensuring the excerpt doesn't feel crammed.
                </li>
            </ol>
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 32: Setting the Stage with Headings 🎭</h2>

            <p>
                The title of each excerpt is like the name of a chapter in your blog's book; it needs to stand out. A properly sized heading invites the reader to dive deeper into your stories.
            </p>
            <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                In your style.css, target the .blog-container .excerpt h2 selector. This will apply styles directly to the headings within your excerpts.
                </li>

                <li>
                Modify the font-size property to 38px, perfect for making the heading prominent and readable.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Font Size: Adjusting the font size helps establish a visual hierarchy, making headings stand out from other text.
                </li>
            </ul>
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 33: Harmonizing the Body Text 🎶📝</h2>

            <p>
            Your excerpt's body text is where the essence of the story unfolds. It's important to ensure that this text is both comfortable to read and aesthetically pleasing.
            </p>
            <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Target the .blog-container .excerpt p selector in your style.css, you'll set the stage for the narrative.
                </li>

                <li>
                Apply a font-size of 24px for clarity and legibility.
                </li>

                <li>
                Set the line-height to 42px to ensure the lines of text have enough vertical space between them, making it easy on the eyes.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Line Height: This property defines the amount of space above and below inline elements. It's crucial for text readability and aesthetics.
                </li>
            </ul>
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 34: Ensuring Your Story's Images Fit Just Right 🖼️📐</h2>

            <p>
            To ensure that each story's visual representation is as captivating as its narrative, let's make sure the images within your excerpts are displayed properly.
            </p>
            <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                In your style.css, target the .blog-container .excerpt .image-container selector. This will apply styles directly to the image container within your excerpts.
                </li>

                <li>
                Set the width to 100%, so the images scale responsively with the width of their container, providing a seamless visual experience.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Width: This property defines the width of the element, allowing it to adjust fluidly within different screen sizes.
                </li>
            </ul>
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 35: Perfecting Image Display in Your Excerpts 🌟🌄</h2>

            <p>
            Just like setting the stage for a play, the way you present your images can dramatically affect how your stories are received.
            </p>
            <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Using the .blog-container .excerpt .image-container img selector in your style.css, you're specifying styles for the images within their containers in each excerpt.
                </li>

                <li>
                Assign width: 100% and height: 290px to make sure the images take up the correct amount of space.
                </li>

                <li>
                Use object-fit: cover; so that your images cover the area of the container without distorting, ensuring they always look their best.
                </li>

                <li>
                Add margin-top: 38px; and margin-bottom: 38px; to give each image its own space, preventing text or other elements from crowding it.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Object-Fit Cover: This CSS property ensures that the image covers the area of the box, cropping it if necessary, without distorting its aspect ratio.
                </li>
            </ul>
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 36: Adding Another Excerpt 📖</h2>

            <p>
            You're now adding a new excerpt to your blog. Just like before, it's a container for a story about "Luna's Lantern."
            </p>
            <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                In your index.html, create a new div element with the class excerpt after the previous one.
                </li>

                <li>
                Inside this div, you'll later add elements like headings and paragraphs to tell Luna's glowing tale.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                HTML Divisions (div): The div tag is like a blank canvas in HTML, allowing you to structure content without any default styling.
                </li>
            </ul>
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 37: Filling the New Excerpt with Luna's Story ✨📚</h2>

            <p>Bring the story of "Luna's Lantern" to life by filling the new excerpt with content.</p>
            <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Within the new excerpt div, insert an h2 element with the book title and author.
                </li>

                <li>
                Below that, add a div with the class image-container and include an img tag with the source pointing to "lunaLantern.png".
                </li>

                <li>
                Craft a descriptive paragraph p that gives readers a glimpse into the story. You can use the copy box below for the content of the p tag.
                </li>
            </ol>

            <CopyBox
            text={"Celeste Winters Luna's Lantern lights up the night with the story of Luna, a girl who receives a mysterious lantern that glows with a light of its own. On a quest to find its source, Luna learns, The true light isn't one that can be seen with eyes, but felt with the heart. Through Luna's eyes, readers explore themes of inner strength, the value of kindness, and the magic hidden in everyday life. This heartwarming adventure encourages children to find the light within themselves and to shine it brightly for all to see."}
            />

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Headings and Paragraphs: These elements are the building blocks of your content, giving structure and flow to your stories.
                </li>
            </ul>
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 38: Creating an Excerpt Block for Our Next Book</h2>

            <p>
            Let's add some code to our page with another book excerpt! 📚✨ Get your typing fingers ready!
            </p>
            <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Below the previous div with the class excerpt, add a new div element and give it the class excerpt as well.
                </li>

                <li>
                Inside this div, add an &lt;h2&gt; element with the book title and author's name. Let's type &lt;h2&gt;"The Adventures of Pippin the Penguin" by Oliver Green&lt;/h2&gt;.
                </li>

                <li>
                Now, let's add a div with the class image-container and insert an &lt;img/&gt; tag with the src attribute set to the path of your book cover image, which might look like ./images/penguin.png.
                </li>

                <li>
                Below the image, create a &lt;p&gt; tag and weave a summary of Pippin's story, encapsulating the heartwarming journey within a couple of sentences. You can copy the summary from the box below.
                </li>
            </ol>

            <CopyBox
            text={"Oliver Green's delightful tale, The Adventures of Pippin the Penguin, takes us to the icy landscapes of the Antarctic where Pippin, a small penguin with a big heart, dreams of flying. Amidst his adventures, Pippin learns from an albatross, To soar in the sky, you first must learn to swim through the seas of challenge and change. Pippins journey is a testament to the power of perseverance, the beauty of embracing ones uniqueness, and the endless possibilities that lie in believing in oneself."}
            />

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Divisions (&lt;div&gt;): These are containers that help us organize content and layout on the web.
                </li>
                
                <li>
                Classes: We use these to apply the same styling to different elements. Think of them like team jerseys!
                </li>
            </ul>
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 39: Creating a New Book Excerpt</h2>

            <p>
            For our latest book feature, we’ll introduce “Milo's Museum” to our readers. Let’s start:
            </p>
            <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                We’ll create a new excerpt section by adding a div with the class excerpt.
                </li>

                <li>
                Within this div, let's add an h2 for the title of the book and the author’s name like so: &lt;h2&gt;Milo's Museum by Hannah Lee&lt;/h2&gt;.
                </li>

                <li>
                Next, we’ll include a div with the class image-container and nest an img tag inside, which will hold the cover image of the book. Make sure the src attribute is set to the correct path, which might look something like ./images/museum.png.
                </li>

                <li>
                Lastly, we’ll add a p tag beneath the image container and fill it with a brief but engaging description of the book, encapsulating the essence of Milo's story. You can copy the message from the box below.
                </li>
            </ol>

            <CopyBox
            text={"In Milo's Museum, Hannah Lee introduces us to Milo, a curious cat who decides to create a museum filled with wonders from his neighborhood. As Milo curates his collection, he discovers, Every treasure tells a story, and every story connects us. This charming narrative celebrates curiosity, the joy of discovery, and the importance of preserving memories. Through Milos eyes, readers are encouraged to see the extraordinary in the ordinary and to appreciate the stories that bind us together."}
            />

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Excerpt Sections: These are used to feature a snippet or a brief overview of a book, article, or other content to entice readers.
                </li>
            </ul>

            <p>
            Remember to preview your webpage to see how it looks after adding the new content. Each step brings more life and personality to your book blog! 📖✨
            </p>
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 40: Adding a New Story Excerpt - "The Flight of the Paper Airplane" ✈️</h2>

            <p>
            Let's introduce another book to our audience with an excerpt:
            </p>
            <img src={forty} className='demo__image' alt='img_forty' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Begin by creating a div with the class excerpt to hold the section for the new book.
                </li>

                <li>
                Insert an h2 element to present the book's title and author: &lt;h2&gt;"The Flight of the Paper Airplane" by Marcus Finch&lt;/h2&gt;.
                </li>

                <li>
                Directly below the title, set up a div with the class image-container and place an img tag within it, sourcing the book's cover with &lt;img src="./images/airplane.png" /&gt;.
                </li>

                <li>
                Follow the image with a p tag to encapsulate a captivating synopsis of the story that draws readers into Jamie's adventure of dreams and paper airplanes. You can copy and paste the message from the box below.
                </li>
            </ol>

            <CopyBox
            text={"In The Flight of the Paper Airplane, Marcus Finch takes us on a soaring adventure with Jamie, a young boy who dreams of flying. Jamie discovers an old book on paper airplanes and decides to craft one that can fly further than anyone has ever imagined. As he folds his plane, his grandfather tells him, The strength of your flight lies not in the paper, but in the dreams that lift it into the sky. Jamie's journey is a poignant reminder of the power of imagination, the beauty of dreaming big, and the importance of persistence. Through his eyes, readers are encouraged to believe in their own potential to soar beyond the limits of what seems possible, finding joy in the simple pleasures that life offers."}
            />

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                Engaging Content: Crafting engaging summaries that capture a reader's interest and encourage them to explore the book further.
                </li>
            </ul>
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 41: Constructing the Footer 👟</h2>

            <p>
                Every comprehensive website has a footer at the bottom to encapsulate important information, let's make ours!
            </p>
            <img src={fortyOne} className='demo__image' alt='img_fortyOne' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                At the very bottom of your site, right above the &lt;body&gt; tag, create a new &lt;div&gt; with a class of footer.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                    Creating a footer, is important... it gives our site visitors and area to search for important information.
                </li>
            </ul>
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 41: Keep Constructing the Footer 🚧</h2>

            <p>Let's continue to construct our amazing footer!</p>
            <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                We will divide this footer into two portions. The first, a &lt;div&gt; with a class of footer-left and the second a &lt;div&gt; with a class of footer-right.
                </li>

                <li>
                    The footer-left should have an &lt;h3&gt; tag with the name of your book blog.
                </li>

                <li>
                    The footer-right should have an &lt;p&gt; with a prompt to encourage the readers to follow the site for more information and book fun!
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                    Organization is very important in web design, make sure that you always organize your website's components.
                </li>
            </ul>
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 43: Footer Orientation ↩️</h2>

            <p>Let's make sure our footer organizes the left and right side like a row (side-by-side) rather than a tower of information!</p>
            <img src={fortyThree} className='demo__image' alt='img_fortyThree' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Navigate to your style.css file and target the .footer class.
                </li>

                <li>
                    Enable flex properties for the footer and set the direction to a row.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                    Footers are always at the bottom of a website; with that being said, they are almost always in a horizontal orientation.
                </li>
            </ul>
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 44: Footer Spacing </h2>

            <p>
                Time to space out the items within the footer.
            </p>
            <img src={fortyFour} className='demo__image' alt='img_fortyFour' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                By setting align items to flex-end, the items in the footer will fall to the bottom of it.
                </li>

                <li>
                    Space between, will push the left side of the footer to the very left side of the screen and will push the right div to the very right side of the screen.
                </li>
            </ol>

        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 45: Footer Sizing 📏</h2>

            <p>
                Like any customized website component, let's assign a size to our footer that best fits it.
            </p>
            <img src={fortyFive} className='demo__image' alt='img_fortyFive' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Give the footer a padding of 24px to cushion the sides of it.
                </li>

                <li>
                    Set the footer to a static height of 200px.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                    Adjusting the size and padding it a small detail, but makes a world of difference for those who want to navigate through our footer.
                </li>
            </ul>
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 46: Adding Depth ⬛</h2>

            <p>
                In order to create a more dimensional and professional look to our website let's add a shadow into the footer!
            </p>
            <img src={fortySix} className='demo__image' alt='img_fortySix' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Copy the shadow from the box below and paste it into your .footer CSS rule to apply it.
                </li>
            </ol>

            <CopyBox
            text={"-webkit-box-shadow: 0px -3px 15px 5px rgba(0,0,0,0.2); box-shadow: 0px -3px 15px 5px rgba(0,0,0,0.2);"}
            />

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                    Shadows create a 3D look in websites, use them when you want certain items in the site to pop out.
                </li>
            </ul>
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 47: Footer H3 🧱</h2>

            <p>
                Setting the font weight for the text within the footer is almost as important as crafting the footer itself!
            </p>
            <img src={fortySeven} className='demo__image' alt='img_fortySeven' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Create a CSS target for the &lt;h3&gt; tag in the footer.
                </li>

                <li>
                    Set the font-weight for the &lt;h3&gt; tag to 800.
                </li>
            </ol>

        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 48: Finalizing the Footer 🖊️</h2>

            <p>
                It is finally time to write the last styling rule for the footer.
            </p>
            <img src={fortyEight} className='demo__image' alt='img_fortyEight' />
            
            <h3>Here’s your quest:</h3>

            <ol>
                <li>
                Just like we styled the &lt;h3&gt; tag in the footer, we need to style the &lt;p&gt; tag too!
                </li>

                <li>
                    Create a CSS rule to target the &lt;p&gt; tag in the footer-right div.
                </li>

                <li>
                    Set the font-size to 14px, the font-weight to 300 (thin), give it an underline, and set the cursor to pointer.
                </li>
            </ol>

            <h3>Concepts to Learn 📘:</h3>

            <ul>
                <li>
                    Attention to small detail shows our website visitors the love and care we put into making this website!
                </li>
            </ul>
        </div>




        
        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>

    </div>
  )
}

export default TopFiveBooks