import appleCatch from '../../project-cover-images/scratch/appleCatch.png';
import bugHunter from '../../project-cover-images/scratch/bugHunter.png';
import dinoJump from '../../project-cover-images/scratch/dinoJump.png';
import dragonPark from '../../project-cover-images/scratch/dragonPark.png';
import pong from '../../project-cover-images/scratch/pong.png';
import underwaterAdventure from '../../project-cover-images/scratch/underwaterAdventure.png';
import angrySharks from '../../project-cover-images/scratch/angrySharks.png';
import foodFight from '../../project-cover-images/scratch/foodFight.png';

import pypong from '../../project-cover-images/python/pypong.png';
import snake from '../../project-cover-images/python/snake.png';
import brick from '../../project-cover-images/python/brick.png';
import space from '../../project-cover-images/python/space.png';
import tac from '../../project-cover-images/python/tac.png';
import runner from '../../project-cover-images/python/runner.png';
import words from '../../project-cover-images/python/words.png';
import chickens from '../../project-cover-images/python/chickens.png';
import running from '../../project-cover-images/python/running.png';
import think from '../../project-cover-images/python/think.png';

import luaGettingStarted from '../../project-cover-images/roblox/luaGettingStarted.png';
import obby from '../../project-cover-images/roblox/obby.png';
import gems from '../../project-cover-images/roblox/gems.jpg';
import castle from '../../project-cover-images/roblox/castle.png';
import bees from '../../project-cover-images/roblox/bees.png';
import paintball from '../../project-cover-images/roblox/paintball.png';
import cheese from '../../project-cover-images/roblox/cheese.png';
import soccer from '../../project-cover-images/roblox/soccer.png';
import heros from '../../project-cover-images/roblox/heros.png';

import visualStudio from '../../project-cover-images/fullstack/visualStudio.png';
import cappyCover from '../../project-cover-images/fullstack/cappyCover.png';
import pepeTravel from '../../project-cover-images/fullstack/pepeTravel.png';
import froggy from '../../project-cover-images/fullstack/froggy.png';
import kraken from '../../project-cover-images/fullstack/kraken.png';
import fishFriends from '../../project-cover-images/fullstack/fishFriends.png';
import crazyWeather from '../../project-cover-images/fullstack/crazyWeather.png';
import buildShip from '../../project-cover-images/fullstack/buildShip.png';
import couchPotato from '../../project-cover-images/fullstack/couchPotato.png';
import topBook from '../../project-cover-images/fullstack/topBook.png';
import cookieClick from '../../project-cover-images/fullstack/cookieClick.png';
import discoDance from '../../project-cover-images/fullstack/discoDance.png';
import robuxCalc from '../../project-cover-images/fullstack/robuxCalc.png';
import rps from '../../project-cover-images/fullstack/rps.png';

export let scratchProjects = [
    ["Apple Catch", appleCatch, "/scratch-guide/apple-catch"],
    ["Bug Hunter", bugHunter, "/scratch-guide/bug-hunter"],
    ["Dino Jump", dinoJump, "/scratch-guide/dino-jump"],
    ["Dragon Park", dragonPark, "/scratch-guide/dragon-park"],
    ["Pong", pong, "/scratch-guide/pong"],
    ["Underwater Adventures", underwaterAdventure, "/scratch-guide/underwater-adventures"],
    ["Angry Sharks", angrySharks, "/scratch-guide/angry-sharks"],
    ["Food Fight", foodFight, "/scratch-guide/food-fight"],
]


export let pythonProjects = [
    ["Pong: Python Fundamentals", pypong, "/python-guide/pong"],
    ["Snake: Iteration & PyGame Methods", snake, "/python-guide/snake"],
    ["Breakout: Mastering Variables & Functions", brick, "/python-guide/breakout"],
    ["Space Invaders: Calculations & Conditionals", space, "/python-guide/spaceinvaders"],
    ["Tic-Tac-Toe: Learning Pragmatic Events", tac, "/python-guide/tictactoe"],
    ["Maze Runner: Rendering Graphics With Arrays", runner, "/python-guide/maze"],
    ["Hangman: String Concatenation", words, "/python-guide/hangman"],
    ["Chicken Catch: Understanding Dimensional Coordinates", chickens, "/python-guide/chickencatch"],
    ["Enemy Escape: Rendering custom graphics", running, "/python-guide/enemyescape"],
    ["Number Guesser: The Math Object & Taking User Input", think, "/python-guide/numpy"]
]


export let robloxProjects = [
    ["Getting Started: Roblox Studio", luaGettingStarted, "/roblox-guide/start"],
    ["Jump Quest: Begginner design principles & Lua Fundamentals", obby, "/roblox-guide/obby"],
    ["Gem Hunter: Understanding collisions and physics", gems, "/roblox-guide/gem-hunter"],
    ["Castle Conundrum: Variables, Conditionals, and Vector3 Properties", castle, "/roblox-guide/castle-conundrum"],
    ["Maze Runner: Mastering lua services and calculating movement", bees, "/roblox-guide/maze-runner"],
    ["Paintball Blitz: Pragmatically rendering modules", paintball, "/roblox-guide/paintball"],
    ["Cheese Chase: Understanding the properties and nested functions", cheese, "/roblox-guide/cheese-chase"],
    ["Soccer Showdown: Objects, Integers, and Data", soccer, "/roblox-guide/soccer"],
    ["Save The City: Tables and Iteration", heros, "/roblox-guide/save-the-city"]
]

export let fullstackProjects = [
    ["Getting Started: Visual Studio", visualStudio, "/fullstack-guide/start", "Installation Guide"],
    ["Introduction To HTML: About Capybaras", cappyCover, "/fullstack-guide/about-capybaras", "Intro HTML"],
    ["HTML + DIVS: Pepe's Travel Blog", pepeTravel, "/fullstack-guide/pepes-travel", "Intro HTML"],
    ["Intro to CSS: Poison Dart Frog", froggy, "/fullstack-guide/poison-dart-frog", "HTML + Intro CSS"],
    ["CSS Continued: SquidInk Studios", kraken, "/fullstack-guide/squidink-studios", "HTML + CSS"],
    ["Focusing on Flex-Box: Fin Fancy", fishFriends, "/fullstack-guide/finfancy", "HTML + CSS"],
    ["Sunny Side Forecast", crazyWeather, "/fullstack-guide/sunny-side-forecast", "Intermediate HTML + CSS"],
    ["Build-A-Ship", buildShip, "/fullstack-guide/build-a-ship", "Advanced HTML + CSS"],
    ["Comfy Couch Co", couchPotato, "/fullstack-guide/comfy-couch-co", "Advanced HTML + CSS"],
    ["Top Five Books", topBook, "/fullstack-guide/top-five-books", "Advanced HTML + CSS"],
    ["Crumbs of Glory: Cookie Clicker", cookieClick, "/fullstack-guide/cookie-clicker", "JavaScript"],
    ["Disco Dance: Color Changer", discoDance, "/fullstack-guide/color-changer", "JavaScript"],
    ["Road to Riches: Robux Calculator", robuxCalc, "/fullstack-guide/robux-calc", "JavaScript"],
    ["Rock Paper Scissors Showdown", rps, "/fullstack-guide/rock-paper-scissors", "JavaScript"]
]