import React, {useState} from 'react';
import one from './pythonImages/numberimages/1.png';
import two from './pythonImages/numberimages/2.png';
import three from './pythonImages/numberimages/3.png';
import four from './pythonImages/numberimages/4.png';
import five from './pythonImages/numberimages/5.png';
import six from './pythonImages/numberimages/6.png';
import seven from './pythonImages/numberimages/7.png';
import eight from './pythonImages/numberimages/8.png';
import nine from './pythonImages/numberimages/9.png';
import ten from './pythonImages/numberimages/10.png';
import eleven from './pythonImages/numberimages/11.png';
import twelve from './pythonImages/numberimages/12.png';
import thirteen from './pythonImages/numberimages/13.png';
import fourteen from './pythonImages/numberimages/14.png';
import fifteen from './pythonImages/numberimages/15.png';
import sixteen from './pythonImages/numberimages/16.png';
import seventeen from './pythonImages/numberimages/17.png';
import eighteen from './pythonImages/numberimages/18.png';
import nineteen from './pythonImages/numberimages/19.png';
import twenty from './pythonImages/numberimages/20.png';
import twentyOne from './pythonImages/numberimages/21.png';
import twentyTwo from './pythonImages/numberimages/22.png';
import twentyThree from './pythonImages/numberimages/23.png';
import twentyFour from './pythonImages/numberimages/24.png';
import twentyFive from './pythonImages/numberimages/25.png';
import twentySix from './pythonImages/numberimages/26.png';
import twentySeven from './pythonImages/numberimages/27.png';
import twentyEight from './pythonImages/numberimages/28.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import './tutorial.css';

const NumGuess = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 28;

  return (
      <div className='pythonGames__container'>
          <h1>
          🥇 Guess the Right Number and Win Eternal Glory ⚔️
          </h1>

          <h3 className='intro'>
          👋 Hi there! Today we're going to learn how to create a fun game called the "Number Guesser" using the Python programming language and Pygame library! 🎉
          </h3>
  
          <div className='step__number'>
            <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
            
            <input
              min={1}
              max={maxSteps}
              value={currentStep}
              onChange={(e) => {
                const value = e.target.value;
                const num = parseInt(value, 10);
  
                // Check if the parsed number is an actual number and within the allowed range
                if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                  setCurrentStep(num);
                } else if (value === '') {
                  // Allow the user to clear the input
                  setCurrentStep('');
                }
              }}
              onBlur={() => {
                // If the user leaves the input and it's not a valid number, reset to previous valid state
                if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                  setCurrentStep(1); // Or some other default value
                }
              }}
              type="number"
              placeholder={`Step No. ${currentStep}`}
            />
  
  
            <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
          </div>
  
          <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
              <h2>
                  Step 1: Create the File🎨🖼️
              </h2>
  
              <p>
                  Before we can start coding this Python project, we need to setup proper file structure within Visual Studio Code and download some necessary assets!
              </p>
  
              <img src={one} className='demo__image' alt='img_one' />
  
              <ol>
              <li>Create a "NumberGuesser" folder to contain your project.</li>
  
              <li>Open this folder with Visual Studio Code.</li>
  
              <li>Add a file and name it "number.py". "number" being the files name and ".py" letting Visual Studio Code that this is a Python file.</li>
  
              </ol>
          </div>
  
          <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
              <h2>
                  Step 2: Setting the Stage – Importing the Essentials 🎬📚
              </h2>
  
              <p>
                  Welcome to the world of game development with Python and Pygame! Our journey begins with 'Number Guesser', a fun and interactive game. The first step in creating our game is like gathering our tools and materials. Here, we're importing the necessary modules to build our game's foundation. 🧰🌟
              </p>
              <img src={two} className='demo__image' alt='img_two' />
  
              <p>
              Let's unpack what each of these imports brings to our game: 📦✨
              </p>
  
              <ol>
                  <li>
                      <strong>import pygame</strong>: This line brings in Pygame, a set of Python modules designed for writing video games. Think of Pygame as our magical toolbox, packed with functionalities like creating windows, drawing shapes, and handling user inputs. 🎨🕹️
                  </li>
  
                  <li>
                      <strong>import random</strong>: By importing the 'random' module, we're equipping our game with the ability to generate random numbers. This is perfect for games like 'Number Guesser' where unpredictability is a key element. 🎲🔮
                  </li>
  
                  <li>
                      <strong>from pygame.locals import *</strong>: This line imports specific constants from Pygame, like KEYUP and KEYDOWN. These constants help us handle specific events like keyboard presses more conveniently, making our code cleaner and more readable. ⌨️✨
                  </li>
              </ol>
  
              <h3>
              Breaking Down the Syntax:
              </h3>
  
              <ul>
                  <li>
                      <strong>Import Statements</strong>: In Python, import statements are used to bring in external modules (libraries of code) that provide additional functionality. It's like saying, "I need these tools to build my project!"
                  </li>
              </ul>
  
              <h3>
                  🎈 Did You Know?: The ability to import and use modules is a powerful feature of Python, allowing developers to leverage a wide range of pre-existing functionalities, speeding up the development process and enhancing the capabilities of their applications. 🌐🔧
              </h3>
          </div>
  
          <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
              <h2>
                  Step 3: Igniting the Engine – Initializing Pygame 🚀🔥
              </h2>
  
              <p>
                  With our essential tools in hand, it's time to start up the engine of our game. In this pivotal step, we're calling on Pygame to prepare itself for action. Think of it as turning the key in an ignition to start a car. This line is where the magic begins, setting the stage for our gaming adventure. 🗝️🌟
              </p>
  
              <img src={three} className='demo__image' alt='img_three' />
  
              <p>
              Let's dive into what this crucial line does: 🔍⚙️
              </p>
  
              <ol>
                  <li>
                      <strong>pygame.init():</strong> Starting up Pygame.
                      <ul>
                          <li>This simple yet powerful line of code is the initial call to the Pygame library, activating its functionalities.</li>
                          <li>It initializes all the modules included in Pygame, getting them ready to work. Without this step, most of Pygame's features wouldn't function correctly, as it sets up the internal workings required for game development.</li>
                          <li>It's like setting up the stage lights and sound system before a theater performance. Everything needs to be switched on and calibrated for the show to go on.</li>
                      </ul>
                  </li>
              </ol>
  
              <h3>
                  Breaking Down the Syntax:
              </h3>
  
              <ul>
                  <li>
                      <strong>Initialization Function</strong>: The <code>init()</code> function in many programming frameworks is used to prepare the environment. It ensures that all necessary settings and resources are correctly configured before the main functionality starts.
                  </li>
              </ul>
  
              <h3>
                  🎈 Did You Know?: In game development, initialization steps are crucial. They ensure that the game environment is ready and all resources are loaded correctly. This preparation helps prevent issues during gameplay and provides a smooth gaming experience. 🕹️🎲
              </h3>
          </div>
  
          <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
              <h2>
                  Step 4: Crafting the Canvas – Setting Up the Game Window 🖼️📏
              </h2>
  
              <p>
                  Every masterpiece starts with a canvas, and our 'Number Guesser' game is no exception. In this step, we’re defining the dimensions of our game window and creating it. It's like choosing the perfect canvas for a painting, providing the space where our game will come to life. 🎨🌌
              </p>
  
              <img src={four} className='demo__image' alt='img_four' />
  
              <p>
              Let's unfold the layers of these lines and understand their contribution to our game setup: 📐🖥️
              </p>
  
              <ol>
                  <li>
                      <strong>WIDTH = 800</strong> and <strong>HEIGHT = 600:</strong> Setting the dimensions.
                      <ul>
                          <li>These two lines declare variables <code>WIDTH</code> and <code>HEIGHT</code>, setting them to 800 and 600 pixels respectively. These values determine the size of our game window – its width and height.</li>
                          <li>Choosing the right size for the game window is crucial. It needs to be large enough to provide a clear view of the game's content while fitting comfortably on most screens.</li>
                      </ul>
                  </li>
                  <li>
                      <strong>screen = pygame.display.set_mode((WIDTH, HEIGHT)):</strong> Creating the game window.
                      <ul>
                          <li>This line uses the <code>set_mode</code> function from Pygame's display module to create the game window. The size of the window is set based on the <code>WIDTH</code> and <code>HEIGHT</code> variables we defined.</li>
                          <li>By assigning this to the variable <code>screen</code>, we establish a surface in Pygame where all our graphical elements will be drawn. It's like having an empty stage ready to be filled with our game's characters and objects.</li>
                      </ul>
                  </li>
              </ol>
  
              <h3>
                  Breaking Down the Syntax:
              </h3>
  
              <ul>
                  <li>
                      <strong>Window Configuration</strong>: These lines demonstrate the initial steps in setting up the visual aspect of a game, starting with defining the size and creating the main display surface.
                  </li>
              </ul>
  
              <h3>
              🎈 Did You Know?: The dimensions of a game window play a significant role in the player's experience. It affects not only the visual appeal but also how players interact with the game, making it an essential consideration in game design. 🖥️👀
              </h3>
          </div>
  
          <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 5: Naming the Adventure – Setting the Game Window Title 🏷️🎲
            </h2>
    
            <p>
                Every great game needs a title, and our 'Number Guesser' is no exception. In this step, we're giving our game window a name. This is like placing a sign over our digital world, letting players know what exciting adventure awaits them. 🌟📛
            </p>

            <img src={five} className='demo__image' alt='img_five' />

            <p>
              Let's explore the importance of this line in setting the stage for our game: 🖊️🖥️
            </p>
        
            <ol>
                <li>
                    <strong>pygame.display.set_caption('Number Guessing Game'):</strong> Titling the game window.
                    <ul>
                        <li>This line sets the title of the game window using Pygame's <code>set_caption</code> function. The title 'Number Guessing Game' is displayed in the window's title bar.</li>
                        <li>Setting a descriptive and engaging title is essential as it's often the first thing players see. It helps set expectations and draws players into the game's theme.</li>
                        <li>Think of it as the cover of a book. A good title piques curiosity and invites players to dive into the game world.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Window Title Setting</strong>: This step shows the simplicity yet significance of naming a game window, a subtle but important aspect of game presentation and player engagement.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: The title of a game is an integral part of its identity. It not only serves as a first impression but also plays a role in conveying the game's theme and essence to potential players. A well-chosen title can be a powerful draw for player interest. 🎮🌟
            </h3>
          </div>
  
          <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 6: Choosing the Palette – Defining Color Constants 🎨🌈
            </h2>
    
            <p>
                Every artist selects colors with care, and in our 'Number Guesser' game, we do the same. In this step, we define color constants that will paint our game world. Colors can set the mood, attract attention, and make the game visually appealing. It's like choosing the right hues for our digital canvas. 🖌️🎨
            </p>

            <img src={six} className='demo__image' alt='img_six' />

            <p>
              Let's understand the role of these colors in our game's design: 🌟🔵
            </p>
        
            <ol>
                <li>
                    <strong>WHITE = (255, 255, 255):</strong> Defining pure white.
                    <ul>
                        <li>This line sets the <code>WHITE</code> constant to the RGB color value of pure white. RGB stands for Red, Green, and Blue - the primary colors of light. Here, maximum values for all (255, 255, 255) result in white.</li>
                        <li>White is often used for text, providing a clear contrast against darker backgrounds, making it easy to read and visually accessible.</li>
                    </ul>
                </li>
                <li>
                    <strong>BLUE = (0, 0, 255):</strong> Defining a vibrant blue.
                    <ul>
                        <li>The <code>BLUE</code> constant is set to represent a bright shade of blue, with its RGB value (0, 0, 255). Here, blue is at its maximum intensity while red and green are absent.</li>
                        <li>Blue can be used to add a calming and professional touch to the game's interface, making it visually pleasing and engaging.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Color Definition in RGB</strong>: This step demonstrates how to define colors using the RGB color model, a fundamental technique in digital graphics and game design.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: The choice of colors in a game is not just about aesthetics. Colors can affect the player's emotions, draw attention to important elements, and enhance the overall user experience. Thoughtfully chosen colors can significantly elevate a game's design. 🌈👾
            </h3>
          </div>
  
          <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 7: Selecting the Typeface – Setting Up the Game Font 🖋️📝
            </h2>
    
            <p>
                In the realm of 'Number Guesser', communicating with players through text is key. For this, choosing the right font is crucial. This step is all about selecting a typeface that will display our game's text, like setting the font for the dialogue in a storybook. 📖✨
            </p>

            <img src={seven} className='demo__image' alt='img_seven' />

            <p>
              Let's delve into the importance of this font selection: 🌟🔠
            </p>
        
            <ol>
                <li>
                    <strong>font = pygame.font.Font(None, 36):</strong> Choosing a font style and size.
                    <ul>
                        <li>This line initializes a font object using Pygame's font module. The <code>Font</code> function is used to create a new font object from a specified font file and size.</li>
                        <li>The first argument <code>None</code> indicates that we're using the default Pygame font. It’s a solid choice for clarity and readability, crucial for displaying text in games.</li>
                        <li>The number <code>36</code> specifies the font size. This size is chosen to ensure that the text is easily readable on the screen, making the game user-friendly.</li>
                    </ul>
                    Selecting a suitable font and size is not just about aesthetics; it's about ensuring that players can comfortably read and understand the game’s text, which is vital for an engaging gaming experience. 📚👀
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Font Initialization</strong>: This step illustrates how to set up a font in Pygame, a key aspect of creating an accessible and visually appealing user interface in games.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: The font used in a game can greatly influence the player's experience. A good font not only conveys the game's information effectively but also contributes to the game's overall atmosphere and style. 🎨🖌️
            </h3>
          </div>
  
          <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 8: Bringing Words to Life – Creating a Text Display Function ✨📜
            </h2>
    
            <p>
                As we craft our 'Number Guesser' game, conveying information to players through text is essential. This step is about creating a function to display text on the screen. It's like writing a script that allows our game to talk to the player, making communication dynamic and versatile. 🗣️💬
            </p>
              
            <img src={eight} className='demo__image' alt='img_eight' />

            <p>
              Let's break down this function and its crucial first step: 🔍🖊️
            </p>
        
            <ol>
                <li>
                    <strong>def display_text(text, color, x, y):</strong> Defining the text display function.
                    <ul>
                        <li>This line begins the definition of a new function named <code>display_text</code>. The purpose of this function is to render and place text on the game screen.</li>
                        <li>It takes four parameters: <code>text</code> (the string to display), <code>color</code> (the color of the text), and <code>x</code>, <code>y</code> (the coordinates for the text position).</li>
                        <li>This function is like a versatile tool in our coding toolkit, allowing us to easily display various pieces of text throughout the game.</li>
                    </ul>
                </li>
                <li>
                    <strong>text_surface = font.render(text, True, color):</strong> Rendering the text.
                    <ul>
                        <li>Inside the function, this line creates a surface with the rendered text. The <code>font.render</code> method is used to turn the text string into a graphical representation (a surface) that can be displayed.</li>
                        <li>The <code>True</code> argument enables anti-aliasing, making the text smoother and more visually appealing.</li>
                        <li>The text is rendered in the specified <code>color</code>, allowing for customization according to different parts of the game.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
              <li>
                  <strong>Text Rendering Function</strong>: This step illustrates how to create a reusable function for text rendering, an important aspect of building an interactive and informative user interface in games.
              </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: Effective text rendering is crucial in game design. It not only conveys essential information but also contributes to the game's aesthetic. Clear, well-placed text can significantly enhance the player's understanding and enjoyment of the game. 🎮🔠
            </h3>
          </div>
  
          <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 9: Placing the Text – Positioning and Displaying the Message 📌📄
            </h2>
    
            <p>
                In our 'Number Guesser' game, not only do we create text, but we also need to position it precisely on the screen. This step completes our text display function by setting the location of the text and drawing it on the game window. It's like finding the perfect spot on a stage to place a prop for maximum visibility. 🎭🔍
            </p>

            <img src={nine} className='demo__image' alt='img_nine' />

            <p>
              Let's delve into each line to understand how they contribute to displaying text effectively: 🖊️🖼️
            </p>
        
            <ol>
                <li>
                    <strong>text_rect = text_surface.get_rect():</strong> Obtaining the text's rectangle.
                    <ul>
                        <li>This line retrieves a rectangle (rect) object that represents the size and position of the rendered text surface. Rectangles in Pygame are used for handling positions and areas of graphical elements.</li>
                        <li>By obtaining the <code>text_rect</code>, we can manipulate where on the screen the text will appear, essential for precise placement.</li>
                    </ul>
                </li>
                <li>
                    <strong>text_rect.topleft = (x, y):</strong> Setting the text position.
                    <ul>
                        <li>Here, we set the top-left corner of <code>text_rect</code> to the coordinates specified by <code>x</code> and <code>y</code>. This effectively places the text surface at the desired location on the screen.</li>
                        <li>The choice of where to position text can impact readability and overall aesthetics, making this step key to user interface design.</li>
                    </ul>
                </li>
                <li>
                    <strong>screen.blit(text_surface, text_rect):</strong> Drawing the text on the screen.
                    <ul>
                        <li>The <code>blit</code> method is used to draw one surface onto another. In this case, it draws the <code>text_surface</code> onto the <code>screen</code> surface at the position defined by <code>text_rect</code>.</li>
                        <li>This final action in our function makes the text visible to the player, an essential step in conveying information and enhancing the game's interactivity.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Text Positioning and Display</strong>: This step demonstrates the detailed process of positioning and displaying text on the screen, crucial for creating a functional and visually appealing user interface in games.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: The positioning of text in games is as important as its creation. Proper placement ensures that information is not only seen but also integrates well with the overall design, contributing to a coherent and engaging player experience. 🕹️👁️
            </h3>
          </div>
  
          <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 10: Setting the Bounds – Defining the Number Range 📐🔢
            </h2>
    
            <p>
                In 'Number Guesser', the heart of the game lies in guessing numbers within a specific range. This step involves setting the boundaries for our number-guessing adventure. It's like marking the field of play, defining where the action happens. 🏁🎯
            </p>

            <img src={ten} className='demo__image' alt='img_ten' />

            <p>
              Let's explore the significance of these number boundaries: 🌟📏
            </p>
        
            <ol>
                <li>
                    <strong>range_min = 1</strong> and <strong>range_max = 100:</strong> Establishing the guessing range.
                    <ul>
                        <li>These lines define the minimum (<code>range_min</code>) and maximum (<code>range_max</code>) limits of the number range for the game. In this case, the player will guess numbers between 1 and 100.</li>
                        <li>Setting a clear range for the game is essential. It provides players with necessary boundaries, making the guessing process manageable and enjoyable.</li>
                        <li>The choice of range can be adjusted based on the desired difficulty level. A wider range makes the game more challenging, while a narrower range is easier and quicker to play.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Range Definition</strong>: This step illustrates how to set parameters for the main gameplay mechanic, which in this case is the range within which players will guess numbers. Setting these parameters is crucial for structuring the gameplay experience.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: In games involving guessing or probability, defining a clear range is key to balancing challenge and playability. It helps in crafting an engaging experience where players can apply logic and intuition within defined limits. 🕹️🧠
            </h3>
          </div>
  
          <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 11: Generating Mystery – Creating the Target Number 🔮🎯
            </h2>
    
            <p>
                The essence of our 'Number Guesser' game lies in the mystery of the unknown number. In this step, we're invoking a bit of randomness to generate this secret number. It's like drawing a hidden treasure map for players to explore. The thrill of the game is in uncovering this concealed number. 🌌🔍
            </p>

            <img src={eleven} className='demo__image' alt='img_eleven' />

            <p>
              Let's unveil the magic behind generating this pivotal number: 🎲🌟
            </p>
        
            <ol>
                <li>
                    <strong>random_number = random.randint(range_min, range_max):</strong> Selecting the secret number.
                    <ul>
                        <li>This line of code calls the <code>randint</code> function from the <code>random</code> module, a tool for generating random numbers.</li>
                        <li>The function takes two arguments, <code>range_min</code> and <code>range_max</code>, which we defined earlier. It then randomly selects a number within this range, inclusive of both limits.</li>
                        <li>The number generated becomes the target for players to guess. This element of randomness ensures that each game is unique, keeping the gameplay fresh and challenging.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Random Number Generation</strong>: This step highlights the use of random number generation in games, a key feature in creating unpredictability and replayability in gameplay.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: The use of random elements in games, like generating a number to guess, adds a layer of excitement and unpredictability. It ensures that the game remains challenging and engaging, as players can't rely on memorization or patterns from previous plays. 🕹️🧩
            </h3>
          </div>
  
          <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 12: Starting Points – Initializing Game Variables 🌟🚦
            </h2>
    
            <p>
                As we delve deeper into the 'Number Guesser' game, initializing key game variables is our next crucial step. This is akin to setting the starting line in a race, preparing the players for the journey ahead. Here, we're setting up variables to track the player's current guess and their number of attempts. 🏁🔢
            </p>

            <img src={twelve} className='demo__image' alt='img_twelve' />

            <p>
              Let's explore the significance of these initializations: 🚀🔍
            </p>
        
            <ol>
                <li>
                    <strong>guess = None:</strong> Preparing for the first guess.
                    <ul>
                        <li>This line sets up a variable named <code>guess</code> and initializes it to <code>None</code>. It represents the player's current guess, which is undefined at the start of the game.</li>
                        <li>Using <code>None</code> is a way to indicate that the player has not made any guess yet. It's like having an empty slate ready to be filled with the player's input.</li>
                    </ul>
                </li>
                <li>
                    <strong>attempts = 0:</strong> Tracking the number of attempts.
                    <ul>
                        <li>Here, we initialize <code>attempts</code> to 0. This variable will keep count of how many guesses the player has made.</li>
                        <li>Tracking the number of attempts is important for the game's feedback system. It allows the game to inform the player how many tries they have taken, adding to the challenge and engagement.</li>
                    </ul>
                </li>
            </ol>

            <h3>
              Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Variable Initialization</strong>: This step demonstrates the initialization of key variables that are essential for tracking the player's progress and interaction in the game.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: Setting up variables at the beginning of a game is a common practice in programming. It helps in managing the game's state and ensures that the gameplay can react appropriately to the player's actions. 🕹️📊
            </h3>
          </div>
  
          <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 13: Launching the Game Loop – The Heartbeat of Our Game 💓🔄
            </h2>
    
            <p>
                Now we're at the core of our 'Number Guesser' game: initiating the game loop. This step is like setting the heart of our game in motion, a continuous cycle that keeps the gameplay alive and responsive. It's the pulsing rhythm that drives the entire gaming experience. 🎮❤️
            </p>

            <img src={thirteen} className='demo__image' alt='img_thirteen' />

            <p>
              Let's dive into the mechanics of this game loop and its initiation: ⚙️🔁
            </p>
        
            <ol>
                <li>
                    <strong>running = True:</strong> Setting the game loop condition.
                    <ul>
                        <li>This line creates a variable <code>running</code> and sets it to <code>True</code>. It acts as a control flag for our game loop, indicating whether the game should continue running.</li>
                        <li>Having a control variable like this allows for a clean and manageable way to stop the game when needed, such as when the player decides to quit.</li>
                    </ul>
                </li>
                <li>
                    <strong>while running:</strong> Beginning the game loop.
                    <ul>
                        <li>The <code>while</code> statement starts the game loop. As long as <code>running</code> remains <code>True</code>, the loop will continue to execute, repeatedly processing game events, updates, and rendering.</li>
                        <li>This loop is the heartbeat of our game, continuously updating the game's state, responding to player inputs, and refreshing the display. It's what makes the game interactive and dynamic.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Game Loop Implementation</strong>: This step demonstrates the fundamental concept of a game loop in programming, a crucial structure for creating games that are interactive and responsive to user input.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: The game loop is a foundational element in game development. It allows games to continuously check for events, update game states, and render graphics, creating a seamless interactive experience for players. 🕹️🌍
            </h3>
          </div>
  
          <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 14: Capturing Player Inputs – Listening to Keyboard Events ⌨️👂
            </h2>
    
            <p>
                An essential aspect of interactive gaming in 'Number Guesser' is how the game responds to player inputs. In this step, we're focusing on capturing keyboard events. This is like tuning our game's ears to listen to the player's commands, a crucial element for interaction. 🎧🔍
            </p>

            <img src={fourteen} className='demo__image' alt='img_fourteen' />

            <p>
              Let's understand the role of this key detection in our game: 🔑🔬
            </p>
        
            <ol>
                <li>
                    <strong>keys = pygame.key.get_pressed():</strong> Detecting keyboard states.
                    <ul>
                        <li>This line checks the current state of all keyboard keys using Pygame's <code>key.get_pressed()</code> function. The function returns a sequence, where each element corresponds to a key on the keyboard. The value is <code>True</code> if the key is currently pressed down, and <code>False</code> otherwise.</li>
                        <li>By assigning this sequence to the variable <code>keys</code>, we can easily check in our game loop if certain keys are pressed, enabling the game to react to player inputs. It's essential for making the game interactive and responsive.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Keyboard Input Handling</strong>: This step illustrates how to detect real-time keyboard inputs, a critical component for creating an interactive gaming experience where the player feels in control.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: Effective keyboard input handling is vital in game design. It not only makes the game more interactive but also significantly affects the player's experience and satisfaction. Responsive controls are key to engaging and enjoyable gameplay. 🕹️👾
            </h3>
          </div>
  
          <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 15: Processing Events – Handling Game Interactions 🎭🖱️
            </h2>
    
            <p>
                Interaction is the soul of our 'Number Guesser' game. This crucial step is about processing events, where we handle various interactions within the game. It's akin to a director attentively watching the actors and audience, ensuring every cue and reaction is noted and addressed. 🎬👀
            </p>

            <img src={fifteen} className='demo__image' alt='img_fifteen' />

            <p>
              Let's dive into the role of this event loop in our game: 🔄🎟️
            </p>
        
            <ol>
                <li>
                    <strong>for event in pygame.event.get():</strong> Initiating the event loop.
                    <ul>
                        <li>This line begins a loop that checks for all events currently queued in Pygame. An 'event' in Pygame is any action or occurrence that the game needs to recognize and possibly respond to, such as key presses, mouse movements, or system actions like closing the window.</li>
                        <li>The <code>pygame.event.get()</code> function fetches all these events, and the loop iterates through them, allowing us to examine and react to each one individually.</li>
                        <li>This process is crucial for making the game interactive; it enables the game to respond appropriately to the player's actions and system events, creating a dynamic and responsive gaming experience.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Event Handling Loop</strong>: This step highlights the importance of the event loop in Pygame programming, a fundamental concept for building responsive and interactive games.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: Effective event handling is key to creating immersive games. By responsively and accurately processing player inputs and system events, developers can create games that feel alive and react in real-time to the player’s actions. 🕹️🌐
            </h3>
          </div>
  
          <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 16: Reacting to Actions – Responding to Quit and Key Presses ⛔️🔑
            </h2>
    
            <p>
                In 'Number Guesser', understanding and reacting to player actions is crucial. This step delves into how our game responds to two specific types of events: quitting the game and pressing a key. It's like having a conversation where the game listens and then reacts thoughtfully to the player's inputs. 💬👂
            </p>

            <img src={sixteen} className='demo__image' alt='img_sixteen' />

            <p>
              Let's navigate through the purpose of these event handlers: 🎮🕹️
            </p>
        
            <ol>
                <li>
                    <strong>if event.type == QUIT:</strong> Handling the quit event.
                    <ul>
                        <li>This line checks if the event is a <code>QUIT</code> event, typically triggered by the player closing the game window.</li>
                        <li>Setting <code>running</code> to <code>False</code> when a <code>QUIT</code> event occurs is how we gracefully exit the game loop, ensuring a clean and proper closure of the game.</li>
                        <li>This response is essential for user-friendly gameplay, respecting the player's decision to end the game at their convenience.</li>
                    </ul>
                </li>
                <li>
                    <strong>elif event.type == KEYDOWN:</strong> Responding to key presses.
                    <ul>
                        <li>This line is the beginning of handling a <code>KEYDOWN</code> event, which occurs whenever the player presses a key.</li>
                        <li>Detecting and responding to key presses is fundamental for interactive games. It allows us to create a responsive experience where the game reacts to the player’s actions in real time.</li>
                    </ul>
                </li>
            </ol>

            <h3>
              Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Event Type Handling</strong>: This step demonstrates how to respond to specific types of events in Pygame, a crucial aspect of creating a responsive and player-centric game experience.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: The way a game responds to player inputs like quitting or key presses significantly affects the player's experience. Prompt and appropriate responses to these actions make the game more intuitive and enjoyable to play. 🎮👌
            </h3>
          </div>
  
          <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 17: Interpreting Numbers – Processing Numeric Inputs 🧮⌨️
            </h2>
    
            <p>
                A critical part of our 'Number Guesser' game is how it interprets the numbers input by the player. In this step, we're focusing on processing numeric key presses, turning them into guesses. It's like translating the language of keystrokes into meaningful numbers for the game. 🗝️🔢
            </p>

            <img src={seventeen} className='demo__image' alt='img_seventeen' />

            <p>
              Let's dissect each part of this numeric input handling: 🔍🖱️
            </p>
    
            <ol>
                <li>
                    <strong>if event.unicode.isdigit():</strong> Checking if the input is a digit.
                    <ul>
                        <li>This line verifies if the pressed key represents a digit. The <code>isdigit()</code> method checks whether the unicode character of the key press is a numerical digit.</li>
                        <li>This check ensures that the game only processes numeric inputs, ignoring other keystrokes like letters or symbols.</li>
                    </ul>
                </li>
                <li>
                    <strong>if guess is None:</strong> Starting a new guess.
                    <ul>
                        <li>When the player has not yet entered a guess, <code>guess</code> is <code>None</code>. This condition checks for that scenario.</li>
                        <li>If it’s the first digit of a new guess, the digit is directly converted to an integer and assigned to <code>guess</code>.</li>
                    </ul>
                </li>
                <li>
                    <strong>elif 0 &lt;= guess * 10 + int(event.unicode) &lt;= range_max:</strong> Building a multi-digit guess.
                    <ul>
                        <li>If the player is entering a multi-digit number, this line concatenates the new digit to the existing guess.</li>
                        <li>The guess is multiplied by 10 and the new digit is added. This effectively shifts the previous number leftward and appends the new digit.</li>
                        <li>The condition ensures that the resulting number is within the allowed range (<code>range_min</code> to <code>range_max</code>).</li>
                    </ul>
                </li>
            </ol>

            <h3>
              Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Numeric Input Processing</strong>: This step illustrates how to handle and validate numeric inputs in a game, a crucial aspect for games that involve number guessing or entry.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: Handling numeric inputs accurately is key to many interactive applications. It ensures that user inputs are correctly interpreted, leading to a seamless and error-free interaction. In games, this accuracy is vital for maintaining gameplay integrity and player satisfaction. 🎮🔍
            </h3>
          </div>
  
          <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 18: Confirming the Guess – Handling the Enter Key Press ⏎🔍
            </h2>
    
            <p>
                In 'Number Guesser', finalizing and evaluating a guess is a key part of the gameplay. This step focuses on what happens when the player presses the Enter key (Return key) to confirm their guess. It's like locking in an answer in a quiz show, bringing a moment of anticipation and decision. 🎲🔐
            </p>

            <img src={eighteen} className='demo__image' alt='img_eighteen' />

            <p>
              Let's analyze the functionality of this Enter key event handling: 🗝️📈
            </p>
        
            <ol>
                <li>
                    <strong>elif event.key == K_RETURN:</strong> Detecting the Enter key press.
                    <ul>
                        <li>This line checks if the key pressed is the Enter (Return) key. It's a common practice in games and applications to use the Enter key as a confirmation or submission button.</li>
                    </ul>
                </li>
                <li>
                    <strong>if guess is not None:</strong> Confirming a valid guess.
                    <ul>
                        <li>Before proceeding, the game checks if a guess has been made (i.e., <code>guess</code> is not <code>None</code>). This prevents processing when there is no input.</li>
                    </ul>
                </li>
                <li>
                    <strong>attempts += 1:</strong> Counting the attempt.
                    <ul>
                        <li>Once a guess is confirmed, the attempts counter is incremented. This tracks how many guesses the player has made, an important part of the game’s feedback and challenge system.</li>
                    </ul>
                </li>
                <li>
                    <strong>if guess != random_number:</strong> Resetting the guess if incorrect.
                    <ul>
                        <li>This condition checks if the guess is incorrect (not equal to the secret number). If so, the guess is reset to <code>None</code>, prompting the player to try again.</li>
                        <li>This mechanism of guessing, checking, and resetting forms the core loop of the gameplay, keeping players engaged as they try to deduce the correct number.</li>
                    </ul>
                </li>
            </ol>

            <h3>
              Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Enter Key Handling and Guess Evaluation</strong>: This step shows how to use key events to manage game actions, specifically confirming player guesses and advancing the gameplay based on those inputs.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: The Enter key is often used in games and software as a natural way for users to submit or confirm their choices. It provides an intuitive mechanism for progressing through interactions, enhancing the user experience. 🎮↩️
            </h3>
          </div>
  
          <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 19: Implementing a Do-Over – Handling Backspace for Guess Correction ⬅️✏️
            </h2>
    
            <p>
                A crucial aspect of our 'Number Guesser' game is allowing players to correct their guesses. In this step, we focus on using the Backspace key to modify the current guess. It's like giving players an eraser to tweak their answer, adding a layer of flexibility and forgiveness to the gameplay. 🧹🔙
            </p>

            <img src={nineteen} className='demo__image' alt='img_nineteen' />

            <p>
              Let's dive into how this backspace functionality enhances the game experience: 🚀🎲
            </p>
    
    
            <ol>
                <li>
                    <strong>elif event.key == K_BACKSPACE:</strong> Detecting the Backspace key press.
                    <ul>
                        <li>This line checks if the key pressed is the Backspace key. The Backspace key is universally understood as a tool for deletion or correction, making it intuitive for players to use when they want to alter their input.</li>
                    </ul>
                </li>
                <li>
                    <strong>if guess is not None:</strong> Ensuring there's a guess to modify.
                    <ul>
                        <li>Before performing any correction, the game verifies that there is an existing guess (<code>guess</code> is not <code>None</code>). This prevents errors when the Backspace key is pressed without any prior guess.</li>
                    </ul>
                </li>
                <li>
                    <strong>guess //= 10:</strong> Correcting the last digit of the guess.
                    <ul>
                        <li>This line effectively removes the last digit of the current guess. The <code>//= 10</code> operation is an integer division by 10, which truncates the guess to its preceding digit.</li>
                        <li>This feature allows players to easily correct mistakes in their input, making the game more user-friendly and less punishing for accidental key presses.</li>
                    </ul>
                </li>
            </ol>

            <h3>
              Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Backspace Functionality</strong>: This step illustrates the implementation of an undo feature for player input, a common practice in user interface design that enhances usability and player satisfaction.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: The ability to correct or modify inputs is an important aspect of user experience design. In games, providing players with the option to fix their inputs can lead to a more relaxed and enjoyable gameplay experience. 🕹️✨
            </h3>
          </div>
  
          <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 20: Refreshing the Canvas – Clearing the Screen for Updates 🎨🔄
            </h2>
    
            <p>
                As our 'Number Guesser' game progresses, refreshing the visual elements becomes essential. This step is about clearing the screen to update it with new information. Think of it as an artist gently wiping the canvas to create room for new strokes. It's a vital step in keeping the game display crisp and current. 🖌️🌟
            </p>

            <img src={twenty} className='demo__image' alt='img_twenty' />

            <p>
              Let's examine the role of this screen refresh in our game: 🧽💻
            </p>
        
            <ol>
                <li>
                    <strong>screen.fill(WHITE):</strong> Clearing the screen with a color.
                    <ul>
                        <li>This line uses the <code>fill</code> method of the <code>screen</code> surface to cover the entire screen with a single color, in this case, WHITE.</li>
                        <li>By filling the screen with a solid color, we effectively clear any previous drawings or text. This is crucial for updating the screen with new information without any visual clutter from the previous state.</li>
                        <li>The choice of white creates a clean and neutral background, ensuring that subsequent elements like text and graphics stand out clearly.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Screen Refresh</strong>: This step demonstrates the importance of clearing the screen in game loops, a common practice to prevent visual artifacts and ensure that updated game information is displayed correctly.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: In video games, refreshing the screen is akin to turning the page in an animation flipbook. Each new page (or screen refresh) presents a new moment in the game's ongoing story, keeping the visual narrative smooth and continuous. 🕹️📖
            </h3>
          </div>
  
          <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 21: Prompting the Player – Displaying the Game Instruction 📝💡
            </h2>
    
            <p>
                Communication is key in 'Number Guesser'. This step is about displaying the main instruction for the game on the screen, guiding the player on what to do. It's like a welcoming sign at the entrance of a maze, providing clear directions to enhance the player's experience. 🛤️🔆
            </p>

            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />

            <p>
              Let's explore how this instructional text plays a role in our game: 🗨️🌟
            </p>
        
            <ol>
                <li>
                    <strong>display_text(f"Guess the number between &#10100;range_min&#10101; and &#10100;range_max&#10101;.", BLUE, 10, 10):</strong> Presenting the game's objective.
                    <ul>
                        <li>This line uses our custom <code>display_text</code> function to put the main game instruction on the screen. It tells players to guess a number within the specified range, defined by <code>range_min</code> and <code>range_max</code>.</li>
                        <li>The instruction is displayed in blue (using the <code>BLUE</code> color we defined earlier), at coordinates (10, 10) on the screen, making it visible and clear against the white background.</li>
                        <li>Presenting this instruction prominently at the beginning of each game loop ensures that players are always aware of the game's goal, enhancing usability and engagement.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Clear Instructions</strong>: This step highlights the importance of displaying clear and concise instructions in a game, essential for guiding the player and setting the stage for the gameplay experience.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: In game design, providing clear instructions is vital for player engagement. It helps avoid confusion and ensures players understand how to interact with the game, creating a more enjoyable and intuitive experience. 🕹️📖
            </h3>
          </div>
  
          <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 22: Showcasing Player Input – Displaying the Current Guess 🖥️🎲
            </h2>
    
            <p>
                In the 'Number Guesser' game, it's important for players to see their current guess. This step involves displaying the player's latest input on the screen. It's akin to holding up a mirror in a game show, reflecting the player's choices back to them, adding clarity and interactivity to the gameplay. 🪞👀
            </p>

            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />

            <p>
              Let's delve into the significance of this feedback mechanism: 🔍💬
            </p>
        
            <ol>
                <li>
                    <strong>display_text(f"Your guess: &#10100;guess&#10101;", BLUE, 10, 50):</strong> Visualizing the player's guess.
                    <ul>
                        <li>This line uses our <code>display_text</code> function to show the player's current guess on the screen. The variable <code>guess</code> holds the number that the player has entered so far.</li>
                        <li>By displaying this text in blue, at a specific position (10 pixels from the left and 50 pixels from the top), it ensures that the player's guess is easily visible and distinct from other game information.</li>
                        <li>Providing this immediate visual feedback is crucial. It helps players keep track of their actions, understand the game’s response to their inputs, and plan their next move.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Immediate Feedback Display</strong>: This step emphasizes the importance of showing real-time feedback in a game, a key aspect of enhancing player engagement and ensuring a clear understanding of the game's state.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: Displaying real-time feedback, like a player's current guess, is a fundamental principle in game design. It provides players with a sense of control and involvement, making the gameplay more immersive and interactive. 🎮🔄
            </h3>
          </div>
  
          <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 23: Tracking Progress – Displaying the Attempt Count 🔄🔢
            </h2>
    
            <p>
                In 'Number Guesser', keeping the player informed about their progress is vital. This step focuses on displaying the number of attempts the player has made. It's like a scoreboard in a game, providing players with a clear understanding of how many tries they've taken. 📊🎯
            </p>

            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />

            <p>
              Let's explore the role of this progress indicator in the gameplay: 🧮🌟
            </p>
        
            <ol>
                <li>
                    <strong>display_text(f"Number of attempts: &#10100;attempts&#10101;", BLUE, 10, 90):</strong> Highlighting the attempts made.
                    <ul>
                        <li>This line uses the <code>display_text</code> function to show the number of attempts the player has made on the screen. The <code>attempts</code> variable is updated each time the player makes a guess.</li>
                        <li>Displaying this information at coordinates (10, 90) ensures that it is distinct and easily noticeable, helping players to gauge their performance and strategy.</li>
                        <li>Keeping track of attempts is crucial in games like 'Number Guesser'. It adds to the challenge, encourages players to improve their guessing strategy, and provides a sense of progress.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Progress Indicator</strong>: This step illustrates the importance of displaying key gameplay metrics, such as attempt count, enhancing the player's awareness of their performance and the game's challenge level.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: Tracking and displaying gameplay metrics like attempt counts is a common practice in game design. It not only adds a competitive edge but also helps players in self-assessment and strategy development. The clearer the feedback, the more engaged the player. 🕹️📈
            </h3>
          </div>
  
          <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
              
            <h2>
                Step 24: Responding to Guesses – Handling Player Inputs 🤔🎮
            </h2>
    
            <p>
                A key aspect of 'Number Guesser' is how the game reacts to the player's guesses. This step introduces a conditional check to respond appropriately when the player has made a guess. It's like a game host deciding what to do next after a contestant's response. 🎙️🧐
            </p>

            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />

            <p>
              Let's dive into the importance of this conditional check in our game's logic: 🕵️‍♂️🔄
            </p>
        
            <ol>
                <li>
                    <strong>if guess is not None:</strong> Checking for a valid guess.
                    <ul>
                        <li>This line checks whether the player has made a guess. The condition <code>guess is not None</code> evaluates to <code>True</code> if the player has entered a number.</li>
                        <li>This conditional statement is crucial as it determines whether the game should process and react to the player's input. Without a valid guess, there is no need for the game to evaluate or provide feedback.</li>
                        <li>It ensures that the game only responds when there is actual input to process, enhancing the game's efficiency and user experience.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Conditional Input Processing</strong>: This step shows the use of a conditional statement to check for player inputs, a fundamental concept in interactive game design for efficient and relevant response handling.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: In interactive games, checking for valid inputs before processing them is a best practice. It prevents unnecessary computations and ensures that the game reacts only to meaningful player actions, keeping the gameplay focused and engaging. 🎮💭
            </h3>
          </div>
  
          <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 25: Celebrating Success – Acknowledging the Correct Guess 🎉🏆
            </h2>
    
            <p>
                A thrilling moment in 'Number Guesser' is when a player guesses the number correctly. This step is about acknowledging and celebrating this success. It's akin to a burst of confetti when a contestant wins a prize, marking a moment of achievement and joy. 🎊🌟
            </p>

            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />

            <p>
              Let's delve into how this acknowledgment enhances the gameplay experience: 🏅✨
            </p>
        
            <ol>
                <li>
                    <strong>if guess == random_number:</strong> Checking for a correct guess.
                    <ul>
                        <li>This line checks if the player's guess matches the randomly generated number. It's a crucial moment in the game where the player’s input is compared against the game’s secret.</li>
                        <li>A correct guess is a key win condition of the game, signaling the player's success in unraveling the game's challenge.</li>
                    </ul>
                </li>
                <li>
                    <strong>display_text("Congratulations! You guessed the number!", BLUE, 10, 130):</strong> Displaying the success message.
                    <ul>
                        <li>When the guess is correct, this line displays a congratulatory message on the screen. The message is rendered in blue and positioned to be clearly visible.</li>
                        <li>This positive feedback is essential for player satisfaction. It acknowledges their achievement and provides a sense of closure and accomplishment.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Success Acknowledgment</strong>: This step illustrates the importance of providing positive feedback in response to player actions, a key element in creating an enjoyable and rewarding gaming experience.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: Celebrating player achievements, even in small games, significantly enhances player engagement and enjoyment. It creates memorable moments in the gameplay, encouraging players to continue engaging with the game. 🕹️🎯
            </h3>
          </div>
  
          <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 26: Guiding the Player – Advising to Increase the Guess 📈🔍
            </h2>
    
            <p>
                In 'Number Guesser', when the player's guess is lower than the target number, providing helpful feedback is key. This step is about encouraging the player to adjust their strategy by guessing a higher number. It's like gently steering someone in the right direction during a treasure hunt. 🔦🎯
            </p>

            <img src={twentySix} className='demo__image' alt='img_twentySix' />

            <p>
              Let's explore the importance of this directional hint in the gameplay: 🧭🌟
            </p>
        
            <ol>
                <li>
                    <strong>elif guess &lt; random_number:</strong> Checking if the guess is too low.
                    <ul>
                        <li>This line evaluates whether the player's guess is less than the randomly chosen number. It's a crucial part of the game's logic to guide the player towards the correct answer.</li>
                        <li>By determining that the guess is too low, the game can provide specific feedback, helping the player refine their approach.</li>
                    </ul>
                </li>
                <li>
                    <strong>display_text("Try a higher number!", BLUE, 10, 130):</strong> Providing a helpful hint.
                    <ul>
                        <li>When the guess is lower than the target, this line displays a prompt advising the player to try a higher number.</li>
                        <li>This guidance is displayed prominently on the screen, encouraging the player to adjust their guess upwards. It's a form of interactive feedback that adds to the puzzle-solving aspect of the game.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Interactive Guidance</strong>: This step demonstrates the use of conditional statements to provide specific feedback to players, guiding them towards the game's objective and enhancing the interactive experience.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: In puzzle and guessing games, providing hints or directional feedback can significantly enhance player engagement. It helps keep the challenge balanced and enjoyable, turning each guess into a learning opportunity and a step closer to success. 🕹️🧩
            </h3>
          </div>
  
          <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 27: Offering a Hint – Suggesting a Lower Guess 🔽🤔
            </h2>
    
            <p>
                In our 'Number Guesser' game, guiding players to the correct answer is part of the fun. When a player's guess is higher than the target number, it's helpful to nudge them in the right direction. This step is about suggesting a lower guess, akin to a game show host hinting that the answer is 'just a bit lower'. 📉🗣️
            </p>

            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />

            <p>
              Let's delve into how this hint aids in the gameplay: 🕵️‍♂️👇
            </p>
        
            <ol>
                <li>
                    <strong>else:</strong> Addressing a higher-than-needed guess.
                    <ul>
                        <li>This line is the concluding part of the conditional structure that began with checking if the guess is too low or exactly right. It covers the remaining scenario: when the guess is too high.</li>
                        <li>Using an <code>else</code> statement here ensures that all possible cases are covered, guiding the player regardless of their guess.</li>
                    </ul>
                </li>
                <li>
                    <strong>display_text("Try a lower number!", BLUE, 10, 130):</strong> Providing clear feedback.
                    <ul>
                        <li>This feedback tells the player that their guess is higher than the target number. Displayed on the screen, this hint encourages the player to adjust their guess downward.</li>
                        <li>Suggestions like this are essential for keeping the player engaged and oriented towards the game's objective. It helps maintain a balance between challenge and guidance, enhancing the overall puzzle-solving experience.</li>
                    </ul>
                </li>
            </ol>

            <h3>
              Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Strategic Gameplay Feedback</strong>: This step illustrates the importance of providing strategic hints based on player actions, a key element in interactive and engaging game design.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: In puzzle games, giving players hints on whether to go higher or lower adds an element of strategy and learning. It encourages players to think critically and make more informed guesses, turning the game into a fun and educational experience. 🕹️🧠
            </h3>
          </div>
  
          <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 28: Wrapping Up – Updating the Display and Exiting the Game 🏁💻
            </h2>
    
            <p>
                As we reach the conclusion of each loop in our 'Number Guesser' game, two critical actions are performed: refreshing the display and preparing to exit. This step is like the final bow at the end of a stage performance, ensuring everything concludes smoothly and appropriately. 🎭👋
            </p>

            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />

            <p>
              Let's examine the significance of these final lines in our game loop: 🔄🚪
            </p>
        
            <ol>
                <li>
                    <strong>pygame.display.flip():</strong> Updating the game display.
                    <ul>
                        <li>This line is responsible for updating the entire screen with everything that's been drawn during the current loop. It ensures that all the changes (like new text and hints) are visible to the player.</li>
                        <li>Think of it as turning the page in an animation flipbook – each flip reveals the next image, making the story come alive.</li>
                    </ul>
                </li>
                <li>
                    <strong>pygame.quit():</strong> Exiting the game cleanly.
                    <ul>
                        <li>This final line signals the end of the game. It deactivates the Pygame library and closes the game window, ensuring that the game exits without leaving any processes hanging.</li>
                        <li>Properly closing the game is crucial for resource management and user experience, akin to turning off the lights and closing the door when leaving a room.</li>
                    </ul>
                </li>
            </ol>
    
            <h3>
                Breaking Down the Syntax:
            </h3>
    
            <ul>
                <li>
                    <strong>Display Update and Game Termination</strong>: This step demonstrates the importance of refreshing the game's visual content and properly exiting the game, crucial aspects of game loop management and resource handling.
                </li>
            </ul>
    
            <h3>
                🎈 Did You Know?: The practice of regularly updating the game display and cleanly exiting the game is fundamental in game development. It ensures a smooth and consistent player experience and maintains system integrity upon game closure. 🕹️🔌
            </h3>
          </div>

          <div className='step__selector'>
            <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

            <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
          </div>
  
      </div>
  )
}

export default NumGuess