import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';
import "./attendance.css";

const Attendance = ({ badge, path, setPath, project, setProject, setAttendance, status, setStatus, attendanceName, attendanceMessage, setAttendanceMessage, submitAttendance }) => {
  return (
    <div className='attendance__container'>

        <div className='attendance__form'>

        <div className='attendance__icon__container'>
            <FaRegWindowClose style={{ cursor: "pointer" }} onClick={()=>setAttendance(false)} size={26} />
        </div>

        <h1>{attendanceName}</h1>

        <div className='options__container' >
            <p>
            Learning Path:
            </p>

            <select value={path} onChange={(e)=>setPath(e.target.value)} >
                <option value={"scratch"} >Scratch</option>
                <option value={"python"} >Python</option>
                <option value={"roblox"} >Roblox & Lua</option>
                <option value={"fullstack"} >Full Stack Development</option>
            </select>
        </div>

        <select value={project} onChange={(e)=>setProject(e.target.value)} className='project-selector'>
        {
            badge[path]?.map((course) => {
                return (
                <option value={course[0]} >{course[0]}</option>
                )
            })
        }
        </select>







        <div className='options__container' >
            <p>
                Was the project completed?
            </p>

            <select className="wasCompleted" value={status} onChange={(e)=>setStatus(e.target.value)} >
                <option value={""} ></option>
                <option value={true} >Yes</option>
                <option value={false} >No</option>
            </select>
        </div>



        <textarea className='textAreaNotes' placeholder='Write a message to the parents of your student!' value={attendanceMessage} onChange={(e)=>setAttendanceMessage(e.target.value)} ></textarea>

        <button className='submitNotes' onClick={()=>submitAttendance()} >Submit</button>

        </div>

    </div>
  )
}

export default Attendance