import React, {useState} from 'react';
import one from './robloxImages/SOCCER-SHOWDOWN/1.png';
import two from './robloxImages/SOCCER-SHOWDOWN/2.png';
import three from './robloxImages/SOCCER-SHOWDOWN/3.png';
import four from './robloxImages/SOCCER-SHOWDOWN/4.png';
import five from './robloxImages/SOCCER-SHOWDOWN/5.png';
import six from './robloxImages/SOCCER-SHOWDOWN/6.png';
import seven from './robloxImages/SOCCER-SHOWDOWN/7.png';
import eight from './robloxImages/SOCCER-SHOWDOWN/8.png';
import nine from './robloxImages/SOCCER-SHOWDOWN/9.png';
import ten from './robloxImages/SOCCER-SHOWDOWN/10.png';
import eleven from './robloxImages/SOCCER-SHOWDOWN/11.png';
import twelve from './robloxImages/SOCCER-SHOWDOWN/12.png';
import thirteen from './robloxImages/SOCCER-SHOWDOWN/13.png';
import fourteen from './robloxImages/SOCCER-SHOWDOWN/14.png';
import fifteen from './robloxImages/SOCCER-SHOWDOWN/15.png';
import sixteen from './robloxImages/SOCCER-SHOWDOWN/16.png';
import seventeen from './robloxImages/SOCCER-SHOWDOWN/17.png';
import eighteen from './robloxImages/SOCCER-SHOWDOWN/18.png';
import nineteen from './robloxImages/SOCCER-SHOWDOWN/19.png';
import twenty from './robloxImages/SOCCER-SHOWDOWN/20.png';
import twentyOne from './robloxImages/SOCCER-SHOWDOWN/21.png';
import twentyTwo from './robloxImages/SOCCER-SHOWDOWN/22.png';
import twentyThree from './robloxImages/SOCCER-SHOWDOWN/23.png';
import twentyFour from './robloxImages/SOCCER-SHOWDOWN/24.png';
import twentyFive from './robloxImages/SOCCER-SHOWDOWN/25.png';
import twentySix from './robloxImages/SOCCER-SHOWDOWN/26.png';
import twentySeven from './robloxImages/SOCCER-SHOWDOWN/27.png';
import twentyEight from './robloxImages/SOCCER-SHOWDOWN/28.png';
import twentyNine from './robloxImages/SOCCER-SHOWDOWN/29.png';
import thirty from './robloxImages/SOCCER-SHOWDOWN/30.png';
import thirtyOne from './robloxImages/SOCCER-SHOWDOWN/31.png';
import thirtyTwo from './robloxImages/SOCCER-SHOWDOWN/32.png';
import thirtyThree from './robloxImages/SOCCER-SHOWDOWN/33.png';
import thirtyFour from './robloxImages/SOCCER-SHOWDOWN/34.png';
import thirtyFive from './robloxImages/SOCCER-SHOWDOWN/35.png';
import thirtySix from './robloxImages/SOCCER-SHOWDOWN/36.png';
import thirtySeven from './robloxImages/SOCCER-SHOWDOWN/37.png';
import thirtyEight from './robloxImages/SOCCER-SHOWDOWN/38.png';
import thirtyNine from './robloxImages/SOCCER-SHOWDOWN/39.png';
import forty from './robloxImages/SOCCER-SHOWDOWN/40.png';
import fortyOne from './robloxImages/SOCCER-SHOWDOWN/41.png';
import fortyTwo from './robloxImages/SOCCER-SHOWDOWN/42.png';
import fortyThree from './robloxImages/SOCCER-SHOWDOWN/43.png';
import fortyFour from './robloxImages/SOCCER-SHOWDOWN/44.png';
import fortyFive from './robloxImages/SOCCER-SHOWDOWN/45.png';
import fortySix from './robloxImages/SOCCER-SHOWDOWN/46.png';
import fortySeven from './robloxImages/SOCCER-SHOWDOWN/47.png';
import fortyEight from './robloxImages/SOCCER-SHOWDOWN/48.png';
import fortyNine from './robloxImages/SOCCER-SHOWDOWN/49.png';
import fifty from './robloxImages/SOCCER-SHOWDOWN/50.png';
import fiftyOne from './robloxImages/SOCCER-SHOWDOWN/51.png';
import fiftyTwo from './robloxImages/SOCCER-SHOWDOWN/52.png';
import fiftyFour from './robloxImages/SOCCER-SHOWDOWN/54.png';
import fiftyFive from './robloxImages/SOCCER-SHOWDOWN/55.png';
import fiftySix from './robloxImages/SOCCER-SHOWDOWN/56.png';
import fiftySeven from './robloxImages/SOCCER-SHOWDOWN/57.png';
import fiftyEight from './robloxImages/SOCCER-SHOWDOWN/58.png';
import fiftyNine from './robloxImages/SOCCER-SHOWDOWN/59.png';
import sixty from './robloxImages/SOCCER-SHOWDOWN/60.png';
import sixtyOne from './robloxImages/SOCCER-SHOWDOWN/61.png';
import sixtyTwo from './robloxImages/SOCCER-SHOWDOWN/62.png';
import sixtyThree from './robloxImages/SOCCER-SHOWDOWN/63.png';
import sixtyFour from './robloxImages/SOCCER-SHOWDOWN/64.png';
import sixtyFive from './robloxImages/SOCCER-SHOWDOWN/65.png';
import sixtySix from './robloxImages/SOCCER-SHOWDOWN/66.png';
import sixtySeven from './robloxImages/SOCCER-SHOWDOWN/67.png';
import sixtyEight from './robloxImages/SOCCER-SHOWDOWN/68.png';
import sixtyNine from './robloxImages/SOCCER-SHOWDOWN/69.png';
import seventy from './robloxImages/SOCCER-SHOWDOWN/70.png';
import seventyOne from './robloxImages/SOCCER-SHOWDOWN/71.png';
import seventyTwo from './robloxImages/SOCCER-SHOWDOWN/72.png';
import seventyThree from './robloxImages/SOCCER-SHOWDOWN/73.png';
import seventyFour from './robloxImages/SOCCER-SHOWDOWN/74.png';
import seventyFive from './robloxImages/SOCCER-SHOWDOWN/75.png';
import seventySix from './robloxImages/SOCCER-SHOWDOWN/76.png';
import seventySeven from './robloxImages/SOCCER-SHOWDOWN/77.png';
import seventyEight from './robloxImages/SOCCER-SHOWDOWN/78.png';
import seventyNine from './robloxImages/SOCCER-SHOWDOWN/79.png';
import eighty from './robloxImages/SOCCER-SHOWDOWN/80.png';
import eightyOne from './robloxImages/SOCCER-SHOWDOWN/81.png';
import eightyTwo from './robloxImages/SOCCER-SHOWDOWN/82.png';
import eightyThree from './robloxImages/SOCCER-SHOWDOWN/83.png';
import eightyFour from './robloxImages/SOCCER-SHOWDOWN/84.png';
import eightyFive from './robloxImages/SOCCER-SHOWDOWN/85.png';
import eightySix from './robloxImages/SOCCER-SHOWDOWN/86.png';
import eightySeven from './robloxImages/SOCCER-SHOWDOWN/87.png';
import eightyEight from './robloxImages/SOCCER-SHOWDOWN/88.png';
import eightyNine from './robloxImages/SOCCER-SHOWDOWN/89.png';
import ninety from './robloxImages/SOCCER-SHOWDOWN/90.png';
import ninetyOne from './robloxImages/SOCCER-SHOWDOWN/91.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const Soccer = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 93;

  return (
    <div className='robloxGames__container'>
        <h1>
        ⚽ Soccer Showdown: Have a showdown in the soccer arena. ⚽
        </h1>

        <h3 className='intro'>
            As the sun☀️ sets and crowds fill the stadiums🏟️. Step out to the competitive world of Soccer⚽ Showdown, where bloxy players stand off face-to-face ready to show the soccer prowess⚔️
        </h3>

        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>







        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Choosing Your Template 🌟</h2>
            <ul>
                <li>
                Open Roblox Studio from your desktop or Start menu🖱️
                </li>
                <li>
                Sign in with your Roblox account or create a new one🔑
                </li>
                <li>
                Click "New" to create a new project and select "Classic Baseplate" as the template🗺️<br/><br/>(By doing this, you're starting a fresh new game project with a simple baseplate to build your obby on.🛠️)
                </li>
            </ul>

            <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Inserting a New Part 🛠️</h2>
            <ul>
                <li>
                Navigate to the "Home" tab at the top of the screen.
                </li>

                <li>
                Click on the "Part" button to insert a new part. Make sure you choose the ball shape as this part will serve as the soccer ball in the game.
                </li>
            </ul>

            <img src={two} className='demo__image' alt='img_two' />
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 3: Scaling Your Part 📏</h2>
            <ul>
                <li>
                Go to the "Home" tab, and this time, select the "Scale" tool. This will allow you to adjust the size of your part.
                </li>

                <li>
                    Rename the part to "Ball" and then set the color of the ball.
                </li>
            </ul>

            <img src={three} className='demo__image' alt='img_three' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: Adding the Script 📜</h2>
            <ul>
                <li>
                    Add the script to the "Ball" so that we can adjust the physics of the ball to behave as a soccer ball when kicked by players.
                </li>
            </ul>

            <img src={four} className='demo__image' alt='img_four' />
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Writing Your First Variable 📜</h2>
            <ul>
                <li>
                    Start scripting by typing local ball = script.Parent which establishes a reference to our part (the ball) within the script.
                </li>
            </ul>

            <img src={five} className='demo__image' alt='img_five' />
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Initiating the Soccer Ball's Script 🌟</h2>
            <ul>
                <li>
                With the script editor open, start defining a function named kickBall. This function will be responsible for what happens when our soccer ball is kicked in the game.
                </li>

                <li>
                In programming, functions are like recipes that perform specific tasks when called upon. Here, kickBall will eventually contain instructions for moving the ball when a player interacts with it.
                </li>

                <li>
                    Then create the function call at the bottom of the script which will activate the function when the ball is touched... or kicked.
                </li>
            </ul>

            <img src={six} className='demo__image' alt='img_six' />
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Identifying the Player 🕵️‍♂️</h2>
            <ul>
                <li>
                Inside the kickBall function, add a line to identify if the object that touched the ball is a player.
                </li>

                <li>
                We want the ball to react only when a player touches it, not when it comes into contact with any other object in the game.
                </li>

                <li>
                :FindFirstChild('Humanoid'): This method searches the parent object for a child named 'Humanoid', which is a special object in Roblox that signifies a player character.
                </li>
            </ul>

            <img src={seven} className='demo__image' alt='img_seven' />
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Making the Ball Reactive 🏃‍♂️⚽</h2>
            <ul>
                <li>
                Add a conditional statement to check if the human variable actually contains something. This is done using an if statement.
                </li>

                <li>
                An if statement allows the script to make decisions. Here, it decides whether to proceed based on whether a player (Humanoid) has touched the ball.
                </li>

                <li>
                if human then: This checks if the human variable is not nil (i.e., something was found by FindFirstChild).
                </li>
            </ul>

            <img src={eight} className='demo__image' alt='img_eight' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Calculating the Kick Direction ⚽🧭</h2>
            <ul>
                <li>
                Within the if human block of your kickBall function, calculate the direction in which the ball should be kicked. This is determined by subtracting the position of the object that hit the ball from the ball's position.
                </li>

                <li>
                We need to know which way to send the ball flying when it gets kicked. By finding the difference between the positions, we can understand the direction of the kick based on where the player touched the ball.
                </li>

                <li>
                ball.Position: This is a Vector3 value representing the ball's location in the game world.
                </li>

                <li>
                hit.Position: Also a Vector3, this represents the location of the player or object that touched the ball.
                </li>
            </ul>

            <img src={nine} className='demo__image' alt='img_nine' />
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Applying Force to the Ball 🚀</h2>
            <ul>
                <li>
                Now apply this direction to the ball as a velocity. Normalize the direction to get a unit vector (which indicates direction only, not magnitude) and then multiply it by a number to give it a certain speed.
                </li>

                <li>
                The Velocity property of the ball controls how fast and in what direction it moves. Setting this properly will make the ball move as if it were kicked.
                </li>
            </ul>

            <img src={ten} className='demo__image' alt='img_ten' />
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: Test the Ball 📝</h2>
            <ul>
                <li>
                    With the ball script complete, hit the "play" button to kick and test the ball.
                </li>

                <li>
                    When you are done testing it, stop the test and go back to design mode.
                </li>
            </ul>

            <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Grassy Fields 🌱</h2>
            <ul>
                <li>
                    We need to start building the soccer field that the players will have their soccer showdown on.
                </li>

                <li>
                    Add a new "Part" into the workspace and color it green.
                </li>

                <li>
                    Anchor the field so it does not move.
                </li>

                <li>
                    Conclude this step by renaming the part to "Field".
                </li>
            </ul>

            <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: Center Field 🎯</h2>
            <ul>
                <li>
                Add another "Part" into the workspace, this time to act as a center line in the field, dividing the field for both teams.
                </li>

                <li>
                    Span the white line across the field and rename it to "Line". Ensure that this part is anchored so that players do not move the center field.
                </li>
            </ul>

            <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: Adding Bleachers for the Audience 🏟️</h2>
            <ul>
                <li>
                In the Roblox Studio, search for 'bleachers' in the Toolbox to find seating for your audience. Drag and place these in your game world, positioning them to face the soccer field.
                </li>

                <li>
                Bleachers will provide a place for virtual spectators to sit, adding realism and atmosphere to your soccer stadium.
                </li>

                <li>
                Open the Toolbox from the Home or Model tab if it isn't already open.
                </li>

                <li>
                Type bleachers into the search bar and press Enter.
                </li>

                <li>
                Browse the options, select a model that suits your stadium's look, and click to add it to your game.
                </li>

                <li>
                Use the move and rotate tools to position the bleachers correctly.
                </li>
            </ul>

            <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15: Lighting Up the Field with Stadium Lights 💡</h2>
            <ul>
                <li>
                Now, let's illuminate our soccer field by adding stadium lights. Search for 'stadium light' in the Toolbox and place them strategically around the field.
                </li>

                <li>
                Proper lighting is essential for creating the right ambiance for a soccer game and ensures players and spectators can see clearly.
                </li>

                <li>
                Use the Toolbox search function again, this time looking for stadium lights.
                </li>

                <li>
                Ensure the lights are facing the field and are evenly spaced for consistent lighting.
                </li>
            </ul>

            <img src={fifteen} className='demo__image' alt='img_fifteen' />
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 16: Setting Up the Goals 🥅⚽</h2>
            <ul>
                <li>
                The most crucial parts of a soccer game are the goals. Search for 'soccer goal' in the Toolbox and add two goals to either end of your soccer field.
                </li>

                <li>
                Without goals, there's no way to score in soccer! Placing these gives players an objective and a way to win the game.
                </li>

                <li>
                In the Toolbox, type in soccer goal and hit Enter to search.
                </li>

                <li>
                Use the move and rotate tools to ensure they're facing the right way and are centered on the end lines of your soccer field.
                </li>
            </ul>

            <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 17: Establishing Borders 🧱</h2>
            <ul>
                <li>
                    For any sports game to be valid, there needs to be borders. Ie players can not just walk off of the field, let's start this process with the first border.
                </li>

                <li>
                    Add a "Part" into the workspace and put it on one of the four sides of the soccer field.
                </li>

                <li>
                    Use the "Scale" tool to make it very tall and span across the entire length of the field. 
                </li>

                <li>
                    Use the "anchor" tool to ensure that the wall is anchored.
                </li>
            </ul>

            <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 18: All Borders 🧱🧱🧱🧱</h2>
            <ul>
                <li>
                    Repeat the process for the previous step for all of the sides of the field.
                </li>
            </ul>

            <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 19: Invisible Borders 🪶</h2>
            <ul>
                <li>
                    Set the "Transparency" property for all of the border parts to "1", thus making our borders invisible.
                </li>
            </ul>

            <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 20: Adding the Sideline ⚽</h2>
            <ul>
                <li>
                    To add the sideline into the soccer arena, pick one of the sides and push the bleachers of the side back to make room for the sideline.
                </li>

                <li>
                    Then add a new "Part" into the empty area where the bleachers were. Scale this part to fill the sideline area.
                </li>

                <li>
                    Ensure that the "Part" is named to "sideline", you have a fun color picked, and that it is anchored.
                </li>
            </ul>

            <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 21: Bleacher Spawn ⭐</h2>
            <ul>
                <li>
                    When players join this game they will spawn in the stadium as audience, then after selecting a team to join they are teleported to the field.
                </li>

                <li>
                    Add two spawn points in the stadiums.
                </li>
            </ul>

            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 22: Blue & Yellow Selectors 🟦🟨</h2>
            <ul>
                <li>
                    We need "selector" blocks for players to choose the team that they want to join.
                </li>

                <li>
                    Add two new "Parts" to start this process. Color the first "blue" and the second "yellow", placing them on the sideline.
                </li>

                <li>
                    In the "Explorer" tab it is important that you follow the naming and organizational conventions as they will work hand-in-hand with the script.
                </li>

                <li>
                    Name the yellow part to "yellowSelector" and the blue part to "blueSelector". Put both of these parts into the a folder and name the folder "Selectors".
                </li>
            </ul>

            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 23: ServerScriptService Scripting 📝</h2>
            <ul>
                <li>
                    Locate the "ServerScriptService" within the "Explorer" tab and add a "Script".
                </li>
            </ul>

            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 24: Setup Game Script 🎮</h2>
            <ul>
                <li>
                    Rename the newly added "script" to "setupGameScript", as the purpose of the script will be to setup the teams, points, and leaderboard of the game.
                </li>
            </ul>

            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 25: Setting Up Teams in Your Game 🚹</h2>
            <ul>
                <li>
                Begin by accessing the "Teams" service within your game. This will allow you to create teams for players to join.
                </li>

                <li>
                Teams are essential for a soccer game as they distinguish players and enable scoring and competition.
                </li>

                <li>
                local Teams: This declares a local variable named Teams to store our service.
                </li>

                <li>
                game:GetService("Teams"): This function call retrieves the Teams service from the game, which manages the team properties and membership.
                </li>
            </ul>

            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 26: Accessing the Players 🧍‍♂️</h2>
            <ul>
                <li>
                Retrieve the "Players" service similar to how you retrieved the "Teams" service. This service will manage the players in your game.
                </li>

                <li>
                The Players service is crucial for keeping track of the people in your game, allowing you to assign them to teams and manage their interactions.
                </li>

                <li>
                local Players: A local variable to store the Players service.
                </li>

                <li>
                game:GetService("Players"): Retrieves the Players service which is responsible for managing player-specific properties and their presence in the game.
                </li>
            </ul>

            <img src={twentySix} className='demo__image' alt='img_twentySix' />
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 27: Creating a Team Instance 🏳️</h2>
            <ul>
                <li>
                Now that you have access to the Teams service, create a new team. This involves creating an instance of a "Team" and setting its properties.
                </li>

                <li>
                By creating a Team instance, you provide a group that players can join, which will be used to separate them into different sides in the soccer game.
                </li>

                <li>
                local BlueTeam: This creates a local variable to store the new team instance.
                </li>

                <li>
                Instance.new("Team"): This function creates a new instance of a Team object, which can then be customized and added to the Teams service.
                </li>
            </ul>

            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 28: Customizing Team Properties 🎨</h2>
            <ul>
                <li>
                After creating a team, set its properties like Name and TeamColor. These properties are crucial for identification and visual distinction between teams.
                </li>

                <li>
                The Name property allows you to reference the team in code and display it in the game's user interface. The TeamColor property visually distinguishes the teams within the game world.
                </li>

                <li>
                BlueTeam.Name: Sets the name of the team to "Blue".
                </li>

                <li>
                BrickColor.new("Bright blue"): BrickColor.new creates a new BrickColor object with the specified color, "Bright blue" in this case.
                </li>

                <li>
                BlueTeam.Parent: Assigns the newly created team to the Teams service by setting its parent.
                </li>
            </ul>

            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 29: Adding Multiple Teams 🏒🥍</h2>
            <ul>
                <li>
                Continue adding teams to your game by creating additional instances of the "Team" object and setting their properties.
                </li>

                <li>
                Multiple teams allow for more complex gameplay and the ability to host games with more than two sides.
                </li>
            </ul>

            <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 30: Creating a Neutral Team 🕊️</h2>
            <ul>
                <li>
                Introduce a neutral team, which can be used for players who are not participating or for other game mechanics.
                </li>

                <li>
                A neutral team is useful for organizing players who are in a lobby, spectating, or otherwise not active participants in the main gameplay.
                </li>

                <li>
                    This is the team that players will start in when they join the game, before choosing the color they want to join.
                </li>
            </ul>

            <img src={thirty} className='demo__image' alt='img_thirty' />
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 31: Handling New Players with a Function 🤖</h2>
            <ul>
                <li>
                Write a function called onPlayerAdded which will handle tasks to be executed when a new player joins the game.
                </li>

                <li>
                This function ensures that each player is properly set up when they enter the game, which includes creating their leaderstats or assigning them to a team.
                </li>

                <li>
                function onPlayerAdded(player): Declares a function that will be called with player as an argument whenever a new player joins.
                </li>

                <li>
                Players.PlayerAdded:Connect(onPlayerAdded): Connects the onPlayerAdded function to the PlayerAdded event, which fires whenever a new player enters the game.
                </li>
            </ul>

            <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 32: Creating Leaderstats for Players 📊</h2>
            <ul>
                <li>
                Inside the onPlayerAdded function, create a new Folder instance named 'leaderstats' and parent it to the player.
                </li>

                <li>
                leaderstats is a Roblox convention used to store player statistics, such as score or currency, that can be displayed on the in-game leaderboard.
                </li>

                <li>
                local leaderstats: Declares a local variable to hold the new Folder instance.
                </li>

                <li>
                Instance.new("Folder"): Creates a new Folder object.
                </li>

                <li>
                leaderstats.Name: Sets the name of the folder, which is important as Roblox looks for this specific name to create leaderboards.
                </li>

                <li>
                leaderstats.Parent: Sets the parent of the 'leaderstats' folder to the player's instance, adding it to the player's object hierarchy.
                </li>
            </ul>

            <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 33: Adding Team Value to Leaderstats 🧮</h2>
            <ul>
                <li>
                Still within the onPlayerAdded function, create a StringValue that will store the player's team name and parent it to the leaderstats folder.
                </li>

                <li>
                This allows the game to keep track of which team the player is on and can be used for various gameplay mechanics, such as scoring.
                </li>
            </ul>

            <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 34: Setting Up the Score Tracking 🏆</h2>
            <ul>
                <li>
                    Creating an "IntValue" will give us a data store to contain the player's score which is an integer or "int", which just means number.
                </li>

                <li>
                    Name this value to "Score" as it will display this name later.
                </li>

                <li>
                    Start the "Value" at 0 so players have to earn their points.
                </li>

                <li>
                    Parent the score to the leaderstats so that it appears on the leaderboard.
                </li>
            </ul>

            <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 35: Starting the Player Off ⬜</h2>
            <ul>
                <li>
                    To start the player off in our game, when they spawn, write the following line of code so that the player starts in the NeutralTeam.
                </li>
            </ul>

            <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 36: Coding the Blue Team Selector 🟦</h2>
            <ul>
                <li>
                    Remember the blue and yellow selectors that we created earlier? We need to code these so that when players touch them they are teleported to that team's side of the field and placed in that team.
                </li>

                <li>
                    Start this process by adding a "Script" into the "blueSelector".
                </li>
            </ul>

            <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 37: Organizing Your Script 🚀</h2>
            <ul>
                <li>
                Name this new Script blueSelectorScript. This script will be the brain behind our blue selector, managing its behavior during the game.
                </li>
            </ul>

            <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 38: Coding Team Assignments ✍️</h2>
            <ul>
                <li>
                With the blueSelectorScript open, begin your Lua coding adventure by creating a reference to the Teams service.
                </li>

                <li>
                The Teams service will help you manage the team settings and properties in your game.
                </li>
            </ul>

            <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 39: Adding Player Services 🧑‍💻</h2>
            <ul>
                <li>
                Continue your scripting journey by adding a new line of code under the Teams reference.
                </li>

                <li>
                This new line of code introduces the Players service to your script. It's crucial for tracking and managing all the players who will be jumping into your game.
                </li>
            </ul>

            <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 40: Connecting to the Blue Team 🔵</h2>
            <ul>
                <li>
                In your script, add a line that defines the BlueTeam by searching for a team named "Blue" within the Teams service.
                </li>

                <li>
                This step is crucial because it links our script to the actual team we’ve set up in the game. The WaitForChild method ensures that the script waits for the "Blue" team to be available before proceeding, preventing any errors that might occur if the script runs before the team is created.
                </li>
            </ul>

            <img src={forty} className='demo__image' alt='img_forty' />
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 41: Defining the Selector's Part 🔗</h2>
            <ul>
                <li>
                Next, we'll define a part variable to reference the physical part in the game world that this script is attached to.
                </li>

                <li>
                Here, script.Parent refers to the parent object of the script, which is the in-game object that the script is associated with. This is important because we want to make sure our script knows which object it's supposed to interact with, especially when there are multiple selectable objects.
                </li>
            </ul>

            <img src={fortyOne} className='demo__image' alt='img_fortyOne' />
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 42: Setting Up Teleportation 🌐</h2>
            <ul>
                <li>
                Now, let’s add a variable for teleporting players. We want players who select the blue team to be teleported to a specific location. Right now the "blueSpawn.CFrame" does not yet exist as be have not create the "blueSpawn" block, the block at which the players will teleport to. We will do that shortly.
                </li>

                <li>
                game.Workspace.blueSpawn.CFrame locates the blueSpawn object in the Workspace and grabs its CFrame, which is a complex data type that includes position and rotation. By setting teleportPosition to this CFrame, you’re specifying where in the game world players will be teleported to when they join the Blue Team.
                </li>
            </ul>

            <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 43: Triggering Actions with Touch 👾</h2>
            <ul>
                <li>
                Begin by defining a function that will be called whenever the part is touched in the game.
                </li>

                <li>
                This function, onPartTouched, is a placeholder right now. It will eventually contain the logic that decides what happens when a player’s character touches the part we have associated with this script.
                </li>

                <li>
                The hit parameter will represent the object that comes into contact with the part. This is often a player’s character, which we will use to identify the player and teleport them if necessary.
                </li>
            </ul>

            <img src={fortyThree} className='demo__image' alt='img_fortyThree' />
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 44: Identifying the Player 🕵️</h2>
            <ul>
                <li>
                Inside the onPartTouched function, add the following code to identify the player who touched the part.
                </li>

                <li>
                GetPlayerFromCharacter is a method provided by the Players service that returns the player object associated with a character. hit.Parent is used because when a character touches the part, hit is actually the humanoid object inside the character model, and the character model itself is the parent of the humanoid.
                </li>

                <li>
                This step is essential because we need to know which player touched the part to assign them to the correct team or perform any other action.
                </li>
            </ul>

            <img src={fortyFour} className='demo__image' alt='img_fortyFour' />
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 45: Checking Team Alignment Before Teleporting ❗</h2>
            <ul>
                <li>
                Add a conditional check inside the onPartTouched function to ensure that the player is not already on the Blue Team, so they are not added to it twice.
                </li>

                <li>
                The if statement checks two conditions: first, that the player variable actually references a player (it's not nil), and second, that the player's current team is not the Blue Team (player.Team ~= BlueTeam).
                </li>

                <li>
                This check prevents players who are already on the Blue Team from being teleported again, which could be disorienting or disruptive during gameplay.
                </li>
            </ul>

            <img src={fortyFive} className='demo__image' alt='img_fortyFive' />
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 46: Assigning the Player to the Blue Team 🎽</h2>
            <ul>
                <li>
                Within the onPartTouched function, after verifying the player is not already on the Blue Team, add the following line to assign them to it.
                </li>

                <li>
                The player.Team property is used to assign the player to a team. By setting this property to BlueTeam, you're officially placing the player on the blue team within the game.
                </li>

                <li>
                This line of code is crucial because it aligns the player with the blue team, which could affect their spawn location, team-based game mechanics, and team identification within the game.
                </li>
            </ul>

            <img src={fortySix} className='demo__image' alt='img_fortySix' />
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 47: Updating Leaderboard Stats 📊</h2>
            <ul>
                <li>
                Right after assigning the player to the Blue Team, ensure you update their team on the leaderboard (if one exists) by adding.
                </li>

                <li>
                This conditional statement checks if the leaderstats object exists for the player and if it contains a Team value. The leaderstats is a common way to store player stats like score, team, and other information.
                </li>

                <li>
                By setting player.leaderstats.Team.Value, you ensure that the leaderboard reflects the player's new team. This is important for keeping in-game scoreboards accurate and up-to-date with the player’s current team.
                </li>
            </ul>

            <img src={fortySeven} className='demo__image' alt='img_fortySeven' />
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 48: Identifying the Player's Positional Part 📍</h2>
            <ul>
                <li>
                To prepare for teleporting the player, first find the central part of the player's character that will be used for moving them in the game space.
                </li>

                <li>
                HumanoidRootPart is typically the main body part that represents the player's location in the game world. It's used as a reference point for any movement or teleportation.
                </li>

                <li>
                Storing the HumanoidRootPart in a variable is an important first step before manipulating the player's position. This action does not yet move the player; it simply locates the necessary part of the character for when we want to initiate teleportation.
                </li>
            </ul>

            <img src={fortyEight} className='demo__image' alt='img_fortyEight' />
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 49: Player Teleportation 🚀</h2>
            <ul>
                <li>
                    By creating the "if HumanoidRootPart" statement we are checking to ensure that we have located the focal point of the player by which we will teleport them.
                </li>

                <li>
                    The following line of code "HumanoidRootPart.CFrame = teleportPosition" will teleport the player to the blue teleport block that we will soon make.
                </li>
            </ul>

            <img src={fortyNine} className='demo__image' alt='img_fortyNine' />
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 50: Yellow Selector Script 🟨📝</h2>
            <ul>
                <li>
                    Just like we coded the blueSelector to put the player in the blue team, we need to do the same thing for the yellowSelector.
                </li>

                <li>
                    That being said, add a "Script" into the "yellowSelector".
                </li>
            </ul>

            <img src={fifty} className='demo__image' alt='img_fifty' />
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 51: Proper Naming 🏷️</h2>
            <ul>
                <li>
                    Name the newly added "Script" to "yellowSelectorScript" to reflect what this script is.
                </li>
            </ul>

            <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 52: Duplicate Code 🐑</h2>
            <ul>
                <li>
                    The code for the yellowSelector is almost exactly the same as the blueSelector.
                </li>

                <li>
                    Copy the code in the "blueSelectorScript" and paste it into the "yellowSelectorScript".
                </li>

                <li>
                    Now, all you have to do is change all instances of where the work "Blue" or "blue" is types and replace them with "Yellow" or "yellow", this will now make the player sorted and spawned into the yellow team instead of the blue team.
                </li>
            </ul>

            <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 53: Starting Yellow Spawn Point 🟨</h2>
            <ul>
                <li>
                Remember the blocks in the selector script that teleport the players to their side of the field? We need to make the block that is referenced in the selector script so that the players are teleported to their team. Let's start with the "yellowSpawn".
                </li>

                <li>
                    Add a new "Part" and put it on the side of the field where you want the yellow players to start.
                </li>

                <li>
                    Rename this "Part" to "yellowSpawn".
                </li>

                <li>
                    Make the block yellow and anchor it.
                </li>
            </ul>

        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 54: Finishing Yellow Spawn Point 🟨</h2>
            <ul>
                <li>
                    Finish making the yellow spawn point by making it invisible and turning off the CanCollide property.
                </li>
            </ul>

            <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 55: Starting Blue Spawn Point 🟦</h2>
            <ul>
                <li>
                    Add a new "Part" and put it on the side of the field where you want the blue players to start.
                </li>

                <li>
                    Rename this "Part" to "blueSpawn".
                </li>

                <li>
                    Make the block blue and anchor it.
                </li>
            </ul>

            <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 56: Finishing Blue Spawn Point 🟦</h2>
            <ul>
                <li>
                    Finish making the blue spawn point by making it invisible and turning off the CanCollide property.
                </li>
            </ul>

            <img src={fiftySix} className='demo__image' alt='img_fiftySix' />
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 57: Detecting goals Yellow Hitbox ⚽🟨</h2>
            <ul>
                <li>
                    We need to create "hitboxes" in the goals to detect when a player scores a goal.
                </li>

                <li>
                    Start this by adding a new "Part" and renaming this part to "HitboxYellow".
                </li>

                <li>
                    Customize the block as seen below (in the goal) set it to yellow, anchor it, and turn the transparency to "0.5".
                </li>
            </ul>

            <img src={fiftySeven} className='demo__image' alt='img_fiftySeven' />
        </div>

        <div style={currentStep == 58 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 58: Detecting goals Blue Hitbox ⚽🟦</h2>
            <ul>
                <li>
                    Do the same things for the other goal, this time blue.
                </li>

                <li>
                    Then take both the "HitboxYellow" and "HitboxBlue" parts in the Explorer tab and put them into a folder named "GoalDetect".
                </li>
            </ul>

            <img src={fiftyEight} className='demo__image' alt='img_fiftyEight' />
        </div>

        <div style={currentStep == 59 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 59: Respawn the Ball ⚽⭐</h2>
            <ul>
                <li>
                    We need to create a ball respawn to reset the ball at the center of the field when one of the players scores a goal.
                </li>

                <li>
                    Start by adding a new "Part" into the field and placing it in the air at the center of the field, this is the point where the ball will drop from.
                </li>

                <li>
                Correctly set the properties of this part by anchoring it, coloring it red, and renaming it to "ballRespawn".
                </li>
            </ul>

            <img src={fiftyNine} className='demo__image' alt='img_fiftyNine' />
        </div>

        <div style={currentStep == 60 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 60: Setting Up Your Scripting Environment 🎯</h2>
            <ul>
                <li>
                Add a script, the script will be a child of HitboxBlue and is highlighted to show it's selected and ready for coding. We are going to code the "HitboxBlue" for the blue team goal.
                </li>
            </ul>

            <img src={sixty} className='demo__image' alt='img_sixty' />
        </div>

        <div style={currentStep == 61 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 61: Accessing Game Services and Yellow Team ⚙️</h2>
            <ul>
                <li>
                Open the newly created Script under HitboxBlue.
                </li>

                <li>
                Start your script by declaring references to essential Roblox services.
                </li>

                <li>
                These lines of code are crucial for any gameplay that involves team dynamics or player interactions. Teams allows you to work with team-related properties and events, while Players will enable you to interact with the player objects in the game.
                </li>

                <li>
                WaitForChild is used to safely retrieve a child object with the name "Yellow" from the Teams service. This is typically used to ensure that the script does not proceed until the specified team is found, thus avoiding potential errors.
                </li>

                <li>
                By storing the YellowTeam in a variable, you can easily reference this particular team elsewhere in your script without repeatedly calling WaitForChild, making your code cleaner and more efficient.
                </li>
            </ul>

            <img src={sixtyOne} className='demo__image' alt='img_sixtyOne' />
        </div>

        <div style={currentStep == 62 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
             
            <h2>Step 62: Establishing Script Components 🛠️</h2>
            <ul>
                <li>
                Within the script editor for HitboxBlue, set up a reference to the part that the script is attached to by establishing a variable part.
                </li>

                <li>
                This line of code is fundamental to the script because script.Parent refers to the object within the game to which the script is attached—in this case, the HitboxBlue. By storing this object in the variable part, you have established the connection between the script and the hitbox that will detect when a goal is scored.
                </li>
            </ul>

            <img src={sixtyTwo} className='demo__image' alt='img_sixtyTwo' />
        </div>

        <div style={currentStep == 63 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 63: Accessing Shared Game Resources 💾 </h2>
            <ul>
                <li>
                Access the ReplicatedStorage service, which is a container for storing objects that need to be accessible on both the server and the client.
                </li>

                <li>
                This service is used to store and replicate objects like RemoteEvents or RemoteFunctions that can be used for communication between the server and the client.
                </li>
            </ul>

            <img src={sixtyThree} className='demo__image' alt='img_sixtyThree' />
        </div>

        <div style={currentStep == 64 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 64: Setting Up Event Handling 🏆</h2>
            <ul>
                <li>
                Prepare to handle a goal-scoring event by retrieving a reference to a RemoteEvent stored in ReplicatedStorage named WinEvent.
                </li>

                <li>
                WaitForChild("WinEvent") is a method that pauses the script until the child named "WinEvent" is found within ReplicatedStorage. This RemoteEvent will likely be used to signal when a goal has been scored and to execute code in response to that event, such as incrementing a team's score.
                </li>
            </ul>

            <img src={sixtyFour} className='demo__image' alt='img_sixtyFour' />
        </div>

        <div style={currentStep == 65 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 65: Preparing the Goal Detection Function 🕵️</h2>
            <ul>
                <li>
                In your script, set up a function to handle the event of an object touching the part.
                </li>

                <li>
                This function, onPartTouched, will be triggered whenever a collision occurs with the hitbox. The hit argument represents the object that made contact with the hitbox.
                </li>
            </ul>

            <img src={sixtyFive} className='demo__image' alt='img_sixtyFive' />
        </div>

        <div style={currentStep == 66 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 66: Identifying the Ball 🎈</h2>
            <ul>
                <li>
                Inside the onPartTouched function, start by checking if the object that touched the hitbox is the ball.
                </li>

                <li>
                The conditional statement checks if the Name property of the hit object is equal to "Ball". It's important to ensure that only the ball triggers a goal event and not any other object.
                </li>
            </ul>

            <img src={sixtySix} className='demo__image' alt='img_sixtySix' />
        </div>

        <div style={currentStep == 67 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 67: Iterating Over Players 🔁</h2>
            <ul>
                <li>
                Continue within the conditional block to add a loop that will iterate over all players currently in the game.
                </li>

                <li>
                The ipairs function is used to iterate over the sequence of players returned by Players:GetPlayers(). In this loop, you'll eventually include logic to determine which team scored and update the game's state accordingly.
                </li>
            </ul>

            <img src={sixtySeven} className='demo__image' alt='img_sixtySeven' />
        </div>

        <div style={currentStep == 68 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 68: Checking for Team Membership 🚀</h2>
            <ul>
                <li>
                In this step, you're going to make sure that only players on the Yellow Team can score a point.
                </li>

                <li>
                Inside the loop, add a condition to check the player's team with if player.Team == YellowTeam then.
                </li>
            </ul>

            <img src={sixtyEight} className='demo__image' alt='img_sixtyEight' />
        </div>

        <div style={currentStep == 69 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 69: Accessing Player Stats 🌟</h2>
            <ul>
                <li>
                Inside the if player.Team == YellowTeam then condition, retrieve the player's leaderstats by adding local leaderstats = player:WaitForChild("leaderstats").
                </li>

                <li>
                This line of code makes sure you safely access the leaderstats object before proceeding.
                </li>
            </ul>

            <img src={sixtyNine} className='demo__image' alt='img_sixtyNine' />
        </div>

        <div style={currentStep == 70 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 70: Finding the Score 🎉</h2>
            <ul>
                <li>
                After getting the leaderstats, find the player's score by adding local score = leaderstats:WaitForChild("Score").
                </li>

                <li>
                This ensures that the Score value is present before you try to change it.
                </li>
            </ul>

            <img src={seventy} className='demo__image' alt='img_seventy' />
        </div>

        <div style={currentStep == 71 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 71: Updating Scores and Declaring a Winner 🎯</h2>
            <ul>
                <li>
                Stay within the if player.Team == YellowTeam then block where you've just accessed the score.
                </li>

                <li>
                First, you'll check if the score object actually exists by using if score then.
                </li>

                <li>
                If it does, increment the score by 1 with score.Value = score.Value + 1.
                </li>

                <li>
                Next, reset the position of the ball by setting its CFrame to a predefined respawn position: hit.CFrame = game.Workspace.ballRespawn.CFrame.
                </li>

                <li>
                Now, add a condition to check if the score has reached 5. If so, declare the Yellow Team as the winner by printing a message and firing an event to all clients.
                </li>
            </ul>

            <img src={seventyOne} className='demo__image' alt='img_seventyOne' />
        </div>

        <div style={currentStep == 72 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 72: Coding Hitbox Yellow 🟨</h2>
            <ul>
                <li>
                    Now add a script into the "HitboxYellow" block, this script will contain the code that allows the blue team players to score points. Much like the previous script.
                </li>
            </ul>
            
            <img src={seventyTwo} className='demo__image' alt='img_seventyTwo' />
        </div>

        <div style={currentStep == 73 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 73: Changing the Code 📝</h2>
            <ul>
                <li>
                    Copy the code from the previous script you wrote and paste it into this one, although replace all instances of "Yellow" and "yellow" with "Blue" and "blue" so that the blue players can score points in this script.
                </li>
            </ul>

            <img src={seventyThree} className='demo__image' alt='img_seventyThree' />
        </div>

        <div style={currentStep == 74 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 74: Initialize ReplicatedStorage ⚡</h2>
            <ul>
                <li>
                    Locate the "ReplicatedStorage" in the "Explorer" tab and add a "RemoteEvent" into the "ReplicatedStorage".
                </li>

                <li>
                    This will allow the goal detector script to communicate with a future screen gui script, such that when a team wins (gets five goals) the winner is displayed via screen gui. All due to the RemoteEvent allowing the two scripts to communicate to each other.
                </li>
            </ul>

            <img src={seventyFour} className='demo__image' alt='img_seventyFour' />
        </div>

        <div style={currentStep == 75 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 75: Rename the RemoteEvent 📝</h2>
            <ul>
                <li>
                    Rename the "RemoteEvent" to "WinEvent" to better describe what it is for.
                </li>
            </ul>

            <img src={seventyFive} className='demo__image' alt='img_seventyFive' />
        </div>

        <div style={currentStep == 76 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 76: Starting the Message Board 🖥️</h2>
            <ul>
                <li>
                    We are going to make the message board to detect which team won the game. Add a "ScreenGui" into the "StarterGui".
                </li>
            </ul>

            <img src={seventySix} className='demo__image' alt='img_seventySix' />
        </div>

        <div style={currentStep == 77 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 77: Add the TextLabel 🏷️</h2>
            <ul>
                <li>
                    Add the "TextLabel" into the "ScreenGui" so that we can display messages.
                </li>
            </ul>

            <img src={seventySeven} className='demo__image' alt='img_seventySeven' />
        </div>

        <div style={currentStep == 78 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 78: Customize the TextLabel 🟩</h2>
            <ul>
                <li>
                    Customize the text label to a color that you want and remove the text that says "label".
                </li>
            </ul>

            <img src={seventyEight} className='demo__image' alt='img_seventyEight' />
        </div>

        <div style={currentStep == 79 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 79: Adding the LocalScript 📜🖊️</h2>
            <ul>
                <li>
                Add a LocalScript. LocalScripts run on a player's device, making them ideal for controlling UI elements and player-specific actions. 
                </li>
            </ul>

            <img src={seventyNine} className='demo__image' alt='img_seventyNine' />
        </div>

        <div style={currentStep == 80 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 80: Referencing the ScreenGui in the Script 📝🔍</h2>
            <ul>
                <li>
                Your first line of code creates a reference to the ScreenGui by using script.Parent.Parent. In Lua, script refers to the current script, and .Parent moves up one level in the hierarchy. So script.Parent.Parent refers to the ScreenGui object. 
                </li>
            </ul>

            <img src={eighty} className='demo__image' alt='img_eighty' />
        </div>

        <div style={currentStep == 81 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 81: Disabling the ScreenGui 🚫💻</h2>
            <ul>
                <li>
                screenGui.Enabled = false: This effectively makes the ScreenGui invisible and non-interactive when the game loads. 
                </li>
            </ul>

            <img src={eightyOne} className='demo__image' alt='img_eightyOne' />
        </div>

        <div style={currentStep == 82 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 82: Setting Up Replicated Storage & Win Event 📚💡</h2>
            <ul>
                <li>
                Declare ReplicatedStorage by using game:GetService("ReplicatedStorage"). This service is used to store objects that need to be accessed by both the server and the client. 
                </li>

                <li>
                Create a reference to an event named WinEvent within ReplicatedStorage by using ReplicatedStorage:WaitForChild("WinEvent"). 
                </li>

                <li>
                By using WaitForChild, you ensure that the script waits for the WinEvent to be replicated before proceeding, avoiding potential errors if it tries to access something that isn't there yet!
                </li>
            </ul>

            <img src={eightyTwo} className='demo__image' alt='img_eightyTwo' />
        </div>

        <div style={currentStep == 83 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 83: Capturing Teleport Positions 🚀📍</h2>
            <ul>
                <li>
                teleportPositionYellow and teleportPositionBlue. These will store the CFrame (position and orientation) for the spawn points of two teams.
                </li>

                <li>
                Access the spawn points by referencing game.Workspace.yellowSpawn.CFrame and game.Workspace.blueSpawn.CFrame. This is where players will be teleported to during the game. 
                </li>
            </ul>

            <img src={eightyThree} className='demo__image' alt='img_eightyThree' />
        </div>

        <div style={currentStep == 84 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 84: Creating the Win Event Function 🎯🔧</h2>
            <ul>
                <li>
                We're declaring a function named onTeamWin. This function is designed to be called when a team wins the game.
                </li>

                <li>
                Then, we're connecting this function to the teamWinEvent's OnClientEvent. This establishes a link between the event and our function, so when the event is fired, our function will execute with the provided message. The event is fired when a different script "fires" it when a team wins the game, we have already written that script in the goal hitboxes.
                </li>

                <li>
                The purpose of this setup is to ensure that when the WinEvent is fired, the onTeamWin function reacts accordingly. This function will be responsible for showing the win message to the player, but right now, it's like an empty envelope ready to be filled with the message.
                </li>
            </ul>

            <img src={eightyFour} className='demo__image' alt='img_eightyFour' />
        </div>

        <div style={currentStep == 85 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 85: Activating the GUI on Win 🌟👨‍💻</h2>
            <ul>
                <li>
                Now we're filling in the onTeamWin function with code that will execute when a team wins.
                </li>

                <li>
                The ScreenGui is set to be visible by setting its Enabled property to true. This will display the GUI when the function is triggered.
                </li>

                <li>
                The Text property of the script's parent, which is assumed to be a TextLabel within ScreenGui, is updated with the win message passed to the function.
                </li>

                <li>
                The rationale here is to provide visual feedback to players. This script will be listening for the win event, and once it happens, it lets players know the result immediately on their screen.
                </li>
            </ul>

            <img src={eightyFive} className='demo__image' alt='img_eightyFive' />
        </div>

        <div style={currentStep == 86 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 86: Delay and Hide the GUI 🕒</h2>
            <ul>
                <li>
                The wait(5) function pauses the script for 5 seconds. This gives players time to read the win message before the GUI disappears.
                </li>

                <li>
                After the wait, ScreenGui's Enabled property is set back to false, which hides the GUI from the player's view. Then the game will reset.
                </li>
            </ul>

            <img src={eightySix} className='demo__image' alt='img_eightySix' />
        </div>

        <div style={currentStep == 87 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 87: Accessing Game Services 🤹‍♂️🔁</h2>
            <ul>
                <li>
                Create local variables to reference the Players and Teams services within your game using game:GetService.
                </li>

                <li>
                local Players = game:GetService("Players") gets the service that manages all the player objects in your game.
                </li>

                <li>
                local Teams = game:GetService("Teams") gets the service that manages the team objects.
                </li>
            </ul>

            <img src={eightySeven} className='demo__image' alt='img_eightySeven' />
        </div>

        <div style={currentStep == 88 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 88: Iterating Over Players 🏃‍♂️🔄</h2>
            <ul>
                <li>
                The for loop uses pairs to iterate over all players returned by Players:GetPlayers().
                </li>

                <li>
                for _, player in pairs(Players:GetPlayers()) do starts a loop where player represents each player object in turn.
                </li>

                <li>
                Loops like this are common when you need to perform the same action for each player, such as updating scores, changing team assignments, or respawning.
                </li>
            </ul>

            <img src={eightyEight} className='demo__image' alt='img_eightyEight' />
        </div>

        <div style={currentStep == 89 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 89: Resetting Player Scores 🔄</h2>
            <ul>
                <li>
                Within the loop, you're checking if players have a leaderboard stat for score and resetting it to 0.
                </li>

                <li>
                player.leaderstats.Score.Value = 0 sets the Score value to 0, effectively resetting it.
                </li>
            </ul>

            <img src={eightyNine} className='demo__image' alt='img_eightyNine' />
        </div>

        <div style={currentStep == 90 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 90: Getting Player Characters and Root Parts 🔍🎭</h2>
            <ul>
                <li>
                Assign the Character of each player to a local variable named character. The Character is a model that represents the player's avatar in the game world.
                </li>

                <li>
                Next, you find the HumanoidRootPart of the character, which is the central part of a Roblox character used for movement and positioning.
                </li>

                <li>
                This step is crucial for later steps where you will need to change the player's position in the game, such as teleporting them to a specific location after a win or loss.
                </li>
            </ul>

            <img src={ninety} className='demo__image' alt='img_ninety' />
        </div>

        <div style={currentStep == 91 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 91: Teleporting Players Based on Team 👥</h2>
            <ul>
                <li>
                Now you're using the player's team information to teleport them to specific locations.
                </li>

                <li>
                if player.Team == Teams.Yellow then and elseif player.Team == Teams.Blue then are conditions that compare the player's team to the Yellow and Blue teams in the game.
                </li>

                <li>
                Depending on the team, the CFrame property of the HumanoidRootPart is set to the corresponding teleport position, effectively moving the player to that location.
                </li>

                <li>
                humanoidRootPart.CFrame = teleportPositionYellow moves the player to the yellow team's spawn point.
                </li>

                <li>
                humanoidRootPart.CFrame = teleportPositionBlue moves the player to the blue team's spawn point.
                </li>

                <li>
                Teleporting players in this manner is a common way to reset player positions at the end of a game round, ensuring they start from a designated point for the next round.
                </li>
            </ul>

            <img src={ninetyOne} className='demo__image' alt='img_ninetyOne' />
        </div>



        <div style={currentStep == 92 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Testing and Publishing Your Game🔬🌐
            </h2>
            <h2>
            Testing Your Game Locally🕹️
            </h2>
            <ul>
                <li>
                In Roblox Studio, click on the "Home" tab at the top
                </li>
                <li>
                Click the "Play" button to test your game locally
                </li>
                <li>
                You'll spawn on the starting platform and can play through your game, ensuring that each feature works as intended
                </li>
                <li>
                If you find any issues, pause the game by clicking the "Stop" button, then make the necessary adjustments in your game and test again.
                </li>
                <li>
                Testing your game locally helps identify any potential problems or bugs before sharing it with the public. This ensures a smooth gaming experience for everyone!👩‍💻🎮
                </li>
            </ul>
        </div>


        <div style={currentStep == 93 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Publishing Your Game to Roblox🚀
            </h2>
            <ul>
                <li>
                Once you're satisfied with your project, click on the "File" tab in the top left corner of Roblox Studio
                </li>
                <li>
                Select "Publish to Roblox" and choose "Create New Game" (or update an existing game if you've published it before)
                </li>
                <li>
                Fill in the required information, such as game name, description, and genre
                </li>
                <li>
                Set the game's thumbnail and icon to visually represent your game (optional)
                </li>
                <li>
                Click the "Publish" button to make your game live on the Roblox platform
                </li>
                <li>
                When you publish your game, you're sharing it with the entire Roblox community! This is your chance to showcase your creativity and see other players enjoy your adventure.🌟🌍
                </li>
            </ul>

            <h3>
            Developing a game on Roblox is no small feat, and it's a testament to your skills and passion for game development. Your commitment to learning, problem-solving, and bringing your ideas to life is truly inspiring. Building a game requires a combination of technical know-how, design expertise, and the ability to engage and entertain players, and you have clearly excelled in all these areas.<br/><br/>Not only have you demonstrated your talent in creating a game, but you have also showcased your ability to think critically, work through challenges, and persevere. These are invaluable skills that will serve you well not only in game development but also in various other areas of your life.<br/><br/>I encourage you to take pride in your achievement and to continue pursuing your passion for game development. Your dedication and talent are sure to lead to even greater successes in the future. Remember, the journey of learning and improvement is ongoing, and every project you undertake will help you grow and refine your skills.
            </h3>
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default Soccer