import React from 'react';
import './classCard.css';

const ClassCard = ({ day, changeDetails, changeGroupAttendance, changeAttendance }) => {
  return (
    <div className='class__card'>
        <div className='class__details'>
        <h2>{day?.teacherTime}</h2>
        <p>{day?.studentName}</p>
        </div>

        <div className='card__button__container' >

        {
            day?.groupSession != true &&
            <button onClick={()=>changeDetails(day)} className='details' >Details</button>
        }
        
        {
            day?.groupSession == true ?
            <button onClick={()=>changeGroupAttendance(day)} className='attendance' >Group Attendance</button>
            :
            <button onClick={()=>changeAttendance(day)} className='attendance' >Take Attendance</button>
        }
        
        <button className='meeting'><a target="_blank" href={`${day?.join_url}`} >Start Meeting</a></button>
        </div>
    </div>
  )
}

export default ClassCard