import React, {useState} from 'react';
import one from './fullstackImages/Cookie-Clicker-IMAGES/1.png';
import two from './fullstackImages/Cookie-Clicker-IMAGES/2.png';
import three from './fullstackImages/Cookie-Clicker-IMAGES/3.png';
import four from './fullstackImages/Cookie-Clicker-IMAGES/4.png';
import five from './fullstackImages/Cookie-Clicker-IMAGES/5.png';
import six from './fullstackImages/Cookie-Clicker-IMAGES/6.png';
import seven from './fullstackImages/Cookie-Clicker-IMAGES/7.png';
import eight from './fullstackImages/Cookie-Clicker-IMAGES/8.png';
import nine from './fullstackImages/Cookie-Clicker-IMAGES/9.png';
import ten from './fullstackImages/Cookie-Clicker-IMAGES/10.png';
import eleven from './fullstackImages/Cookie-Clicker-IMAGES/11.png';
import twelve from './fullstackImages/Cookie-Clicker-IMAGES/12.png';
import thirteen from './fullstackImages/Cookie-Clicker-IMAGES/13.png';
import fourteen from './fullstackImages/Cookie-Clicker-IMAGES/14.png';
import fifteen from './fullstackImages/Cookie-Clicker-IMAGES/15.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const CookieClicker = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 15;

  return (
    <div className='development__container'>
        <h1>
        🍪🍪 Crumbs of Glory: The Ultimate Cookie Clicker 🍪🍪
        </h1>

        <h3 className='intro'>
        Welcome, fellow cookie enthusiasts! 🍪 Ever dreamed of becoming the ultimate cookie tycoon without the sticky fingers? Well, today’s your lucky day! In this sweet tutorial, we’re going to bake up something special—a Cookie Clicker game in HTML that’s so addictive, you might need a virtual cookie jar to hide it from yourself! Get ready to click, crunch, and code your way to cookie domination. Let’s get those digital ovens preheated!️‍🔥        
        </h3>

        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>

          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>

        </div>


        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Setting Up Your Adventure Space! 📚</h2>

            <p>
            Hey there, young coders! Ready to embark on an epic cookie coding journey? Make sure to start this project off on the right foot by downloading the project template. After downloading the template you should have the following files inside of the project. 📚✨
            </p>
            <img src={one} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                Install the template for this project.
                </li>

                <li>
                Open the project in Visual Studio Code.
                </li>

                <li>
                Ensure that you have the following files: <strong>app.js</strong>, <strong>index.html</strong>, and <strong>style.css</strong>
                </li>

                <li>
                    Also check the make sure that the <strong>images</strong> folder is located within the project directory.
                </li>
            </ol>

            <p>
            You've just learned about directories (fancy name for folders) and files. Remember, keeping your code organized is key to being a great coding genius!
            </p>
        </div>


        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Examining Your Canvas 👀🖼️</h2>

            <p>
            Now that you have the necessary project files installed. Examine your HTML file. In this file you will see the HTML elements that we be the main focus of this project experience. The image of the cookie, the <strong>h2</strong> tag (displays cookie count), the cookie clicker button, and the reset button. The cookie clicker does not work yet, that is where we come in; it is time to write some JavaScript and bring this project to life!
            </p>
            <img src={two} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                Open the <strong>index.html</strong> file.
                </li>

                <li>
                Get familiar with the html tags in the file.
                </li>

                <li>
                <strong>(Optional): </strong>Replace the <strong>src</strong> attribute of the <strong>img</strong> tag with one of the other cookie images in the images folder! 
                </li>
            </ol>
        </div>


        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 3: Inject the JavaScript 🧪</h2>

            <p>
            Since you are a seasoned coder at this point in your journey, remember how we get our CSS to effect the HTML? We import it! This is already done, but it is time for you to learn how to import your JavaScript file so that it can effect the HTML just like the CSS does.
            </p>
            <img src={three} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                Find a line to write the import statement right above the closing <strong>&lt;/body&gt;</strong> tag.
                </li>

                <li>
                Write the following line of code to import the JavaScript file: <strong>&lt;script src="./app.js" &gt;&lt;/script&gt;</strong>
                </li>

                <li>
                The script tags indicate to the HTML file that we are importing scripts (JavaScript commands) and not another HTML file or stylesheet (CSS)
                </li>

                <li>
                The <strong>src</strong> attribute points to the file that we are importing <strong>app.js</strong> (our JavaScript file).
                </li>
            </ol>
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: Let the Countdown Begin ⏰😲</h2>

            <p>
            Now that our JavaScript file is imported into the HTMl file, any code that we write in <strong>app.js</strong> will automatically apply in the HTML file. In this step, we will begin the script by learning about variables and creating one to track how many clicks have been made to the button!
            </p>
            <img src={four} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                Open the <strong>app.js</strong> file.
                </li>

                <li>
                On line one write the following line of code: <strong>let count = 0;</strong>
                </li>

                <li>
                    This is a variable that we have created. A variable is a container of data.
                </li>

                <li>
                    When we make a variable we let the computer know that we are making a variable by declaring it. We have "declared" the variable with the word <strong>let</strong>. You can also declare a variable with the words <strong>const</strong> or <strong>var</strong>. Declaring a variable is like shouting at the computer: <em>HEY!! WE ARE MAKING A VARIABLE!</em>
                </li>

                <li>
                    The most important part of the variable is giving it a name. Just like how you have a name, the variable needs one too; this way we can reference it. So, when ever we write the word "count" in the script, the computer will know that we are talking about the variable.
                </li>

                <li>
                    After the name there is the <strong>=</strong> sign that assigns the name a value.
                </li>

                <li>
                    The value of the variable is what we put on the right side of the <strong>=</strong> sign. In this case, the starting value of <strong>count</strong> is <strong>0</strong>, indicating that the click count will start at zero.
                </li>

                <li>
                    The point of variables is that they track and contain data. When a user clicks the button, we will add <strong>1</strong> to the <strong>count</strong> variable, thus increasing the value to track user clicks.
                </li>
            </ol>
        </div>




        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Increase the Count ➕➕</h2>

            <p>
            At this point you might be thinking, <em>how do we increase the count variable?!</em> Well young apprentice, when we want to give the computer a set of instructions to follow like <strong>saying hello</strong>, or <strong>starting a timer</strong>, or in this case <strong>increasing a count</strong>; then we need to create a function. A function is container (like a variable), but the difference is that this container holds instructions. Examine the image below:
            </p>
            <img src={five} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                Underneath where you created the <strong>count</strong> variable. Write the function as you see in the image above.
                </li>

                <li>
                    When you are creating a function in JavaScript, you always start by writing the word <strong>function</strong> to declare it (let the computer know what you are trying to do)!
                </li>

                <li>
                    After you declare the function give it a name, the name typically reflects what the function will do. In this case, the function will increment the <strong>count</strong> variable, so I named it <strong>incrementCount</strong>.
                </li>

                <li>
                    Don't forget to add a set of parenthesis <strong>()</strong> after the name. These are required after giving the function a name according to JavaScript syntax.
                </li>

                <li>
                    After the parenthesis goes the curly brackets. These will contain the JavaScript code that actually increases the <strong>count</strong> variable.
                </li>

                <li>
                    In the next step we will begin the code that increases the count variable.
                </li>
            </ol>
        </div>





        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Fill the Function 🫙🫙</h2>

            <p>
            Any code that we write in the empty space between the curly brackets are the JavaScript commands that will run in sequential order. The first command is to increase the count variable.
            </p>

            <img src={six} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Write the following JavaScript code: <strong>count += 1;</strong>
                </li>

                <li>
                    This line of code takes the current value of the count variable and increments it by <strong>1</strong>.
                </li>

                <li>
                    The <strong>+=</strong> operator takes the value that is located on the left side of it and increases it by the value on the right side of it. Starting off, the value of count is <strong>0</strong>, so the first time that this function runs it will increase the value of <strong>count</strong> from <strong>0</strong> to <strong>1</strong>. But if the value of <strong>count</strong> is <strong>4</strong>, then this line of code would increase the value of <strong>count</strong> to <strong>5</strong>.
                </li>
            </ol>
        </div>



        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Count in Action 💥💥💥</h2>

            <p>
            Now that the <strong>count</strong> variable has been increased, we need to show this in the HTML document. If you look in the HTML document, notice there is an <strong>h2</strong> tag with the text <strong>"Cookie Count:"</strong> this tag also contains a <strong>span</strong> tag containing the number <strong>0</strong> we need to replace that number with the <strong>count</strong> variable. This <strong>span</strong> tag has an <strong>id</strong> of <strong>count</strong>. What you are going to do, is write some JavaScript that references the <strong>span</strong> tag with an <strong>id</strong> of <strong>count</strong> and set is equal to the <strong>count</strong> variable instead of the number <strong>0</strong>.
            </p>

            <img src={seven} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Write the following JavaScript code: <strong>document.getElementById('count').textContent</strong>
                </li>

                <li>
                    Let's break down what this means:
                    <ol>
                        <li>
                            <strong>document</strong> is a reference the html document.
                        </li>

                        <li>
                            <strong>.getElementById</strong> is a method that we use to located any html element based on a specified <strong>id</strong>. In this case the <strong>id</strong> is called <strong>count</strong> and is located the quotation marks.
                        </li>

                        <li>
                            <strong>.textContent</strong> is a reference to the text inside of the tag. Right now, if you examine the html document, the text inside of the <strong>span</strong> tags with the id of <span>count</span> is currently <span>0</span>. But we will set it equal to the count variable
                        </li>
                    </ol>
                </li>
            </ol>
        </div>




        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Dig a little deeper 🪨</h2>

            <p>
            If the last step was confusing, then let's dig a little deeper in with the image below. Notice the yellow arrow pointing to the line of code. In this line of code, there is a <strong>span</strong> tag, with a property called <strong>id</strong> that is equal to <strong>count</strong>. The last step is what references this tag.
            </p>

            <img src={eight} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Setting the Stage 🎭</h2>

            <p>
                Okay so now that you know what tag that <strong>document.getElementById('count').textContent</strong> is referencing. You need to actually set the value of what the <strong>count</strong> variable is equal to!
            </p>

            <img src={nine} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    At the end of <strong>document.getElementById('count').textContent</strong> add <strong>= count;</strong>
                </li>

                <li>
                    This will replace the current content of the <strong>span</strong> tag with the <strong>count</strong> variable, thus reflecting what the click count is at on the web page!
                </li>
            </ol>
        </div>


        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Give the Function A Call 📞🤳</h2>

            <p>
                The function is done! <em>Let us review:</em> We have a variable called <strong>count</strong>, this variable starts at zero. We have a function named <strong>incrementCount</strong>, this function will increase the value of the <strong>count</strong> variable and replace the content of the <strong>span</strong> with the value of the <strong>count</strong> variable. But now we need to tell the computer when the use the <strong>incrementCount</strong> function. On its own, the computer does not know when to use the function, but we do! We will use the function when the user clicks the <strong>"Click Me!"</strong> button.
            </p>

            <img src={ten} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Locate the <strong>Click Me!</strong> button inside of the HTML file.
                </li>

                <li>
                    Add an <strong>onclick</strong> attribute. This is an attribute that will run desired code when the user clicks the button.
                </li>

                <li>
                    The code that you want to run when the user clicks the button is the <strong>incrementCount</strong> function.
                </li>

                <li>
                    Follow the image guide above to complete this step.
                </li>
            </ol>
        </div>


        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: <em>REDO</em>, <em>REDO</em> ↩️↩️</h2>

            <p>
                Your count incremet process is done, but what happens when the user wants to reset the cookie counter? Well... we need a function for that too! But this is an easy one...
            </p>

            <img src={eleven} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Create a line of code underneath the <strong>incrementCount</strong> function. It is very important that you are coding under the last curly bracket of the <strong>incrementCount</strong> function, so that the computer knows you are creating a different function.
                </li>

                <li>
                    Start coding the new function by declaring it with the word <strong>function</strong> and give it the name <strong>resetCount</strong>, <em>OH,</em> and don't forget your <strong>()</strong> and <strong>curly brackets</strong>.
                </li>
            </ol>
        </div>


        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Make the Function Function ⚙️</h2>

            <p>
                To make this function work, all you are going to need to do is reset the count variable, changing it back to zero. Easy as 🥧 
            </p>

            <img src={twelve} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Write the following line of JavaScript code: <strong>count = 0</strong>
                </li>

                <li>
                    This will take the count variable wether it's currently 1 or 1,000 and set it back to 0.
                </li>
            </ol>
        </div>


        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: Finish the Function 🏁</h2>

            <p>
                Let's wrap this function up! Think really hard here... why is this function not done? We reset the count variable to zero, that should do the trick... but we need to replace the current text in the <strong>span</strong> tag with the updated count variable. Look at the image below.
            </p>

            <img src={thirteen} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Reference the <strong>span</strong> tag with the <strong>id</strong> of <strong>count</strong> and set it equal to the updated <strong>count</strong> variable.
                </li>

                <li>
                    Write the following line of JavaScript code: <strong>document.getElementById('count').textContent = count</strong>
                </li>
            </ol>
        </div>


        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: Reset the Cookies 🍪⏰</h2>

            <p>
                Do you remember that to use a function we have to tell the computer when and were to use it? Otherwise, the function just sits on the shelf and gets dusty. With this in 🧠, find the <strong>Reset Cookie</strong> button in your html file and tell the computer to use the function there (this is called "calling the function").
            </p>

            <img src={fourteen} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Go the the <strong>index.html</strong> file.
                </li>

                <li>
                    Locate the <strong>Reset Cookie</strong> button.
                </li>

                <li>
                    Add an <strong>onclick</strong> attribute to tell the computer what to do when a user clicks this button.
                </li>

                <li>
                    In the <strong>onclick</strong> attribute, go and run the <strong>resetCount</strong> function as seen in the image above.
                </li>
            </ol>
        </div>


        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15: Wrapping Up 🎁</h2>

            <p>
                Give yourself a pat on the back young coder! This project is done! Have fun clicking 🐁
            </p>

            <img src={fifteen} className='demo__image' alt='img_one' />
            
        </div>



        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default CookieClicker