import React, {useState} from 'react';
import one from './scratchImages/angrySharks/1.png';
import two from './scratchImages/angrySharks/2.png';
import three from './scratchImages/angrySharks/3.png';
import four from './scratchImages/angrySharks/4.png';
import five from './scratchImages/angrySharks/5.png';
import six from './scratchImages/angrySharks/6.png';
import seven from './scratchImages/angrySharks/7.png';
import eight from './scratchImages/angrySharks/8.png';
import nine from './scratchImages/angrySharks/9.png';
import ten from './scratchImages/angrySharks/10.png';
import eleven from './scratchImages/angrySharks/11.png';
import twelve from './scratchImages/angrySharks/12.png';
import thirteen from './scratchImages/angrySharks/13.png';
import fourteen from './scratchImages/angrySharks/14.png';
import fifteen from './scratchImages/angrySharks/15.png';
import sixteen from './scratchImages/angrySharks/16.png';
import seventeen from './scratchImages/angrySharks/17.png';
import eighteen from './scratchImages/angrySharks/18.png';
import nineteen from './scratchImages/angrySharks/19.png';
import twenty from './scratchImages/angrySharks/20.png';
import twentyOne from './scratchImages/angrySharks/21.png';
import twentyTwo from './scratchImages/angrySharks/22.png';
import twentyThree from './scratchImages/angrySharks/23.png';
import twentyFour from './scratchImages/angrySharks/24.png';
import twentyFive from './scratchImages/angrySharks/25.png';
import twentySix from './scratchImages/angrySharks/26.png';
import twentySeven from './scratchImages/angrySharks/27.png';
import twentyEight from './scratchImages/angrySharks/28.png';
import twentyNine from './scratchImages/angrySharks/29.png';
import thirty from './scratchImages/angrySharks/30.png';
import thirtyOne from './scratchImages/angrySharks/31.png';
import thirtyTwo from './scratchImages/angrySharks/32.png';
import thirtyThree from './scratchImages/angrySharks/33.png';
import thirtyFour from './scratchImages/angrySharks/34.png';
import thirtyFive from './scratchImages/angrySharks/35.png';
import thirtySix from './scratchImages/angrySharks/36.png';
import thirtySeven from './scratchImages/angrySharks/37.png';
import thirtyEight from './scratchImages/angrySharks/38.png';
import thirtyNine from './scratchImages/angrySharks/39.png';
import forty from './scratchImages/angrySharks/40.png';
import fortyOne from './scratchImages/angrySharks/41.png';
import fortyTwo from './scratchImages/angrySharks/42.png';
import fortyThree from './scratchImages/angrySharks/43.png';
import fortyFour from './scratchImages/angrySharks/44.png';
import fortyFive from './scratchImages/angrySharks/45.png';
import fortySix from './scratchImages/angrySharks/46.png';
import fortySeven from './scratchImages/angrySharks/47.png';
import fortyEight from './scratchImages/angrySharks/48.png';
import fortyNine from './scratchImages/angrySharks/49.png';
import fifty from './scratchImages/angrySharks/50.png';
import fiftyOne from './scratchImages/angrySharks/51.png';
import fiftyTwo from './scratchImages/angrySharks/52.png';
import fiftyThree from './scratchImages/angrySharks/53.png';
import fiftyFour from './scratchImages/angrySharks/54.png';
import fiftyFive from './scratchImages/angrySharks/55.png';
import fiftySix from './scratchImages/angrySharks/56.png';
import fiftySeven from './scratchImages/angrySharks/57.png';
import fiftyEight from './scratchImages/angrySharks/58.png';
import fiftyNine from './scratchImages/angrySharks/59.png';
import sixty from './scratchImages/angrySharks/60.png';
import sixtyOne from './scratchImages/angrySharks/61.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import "./tutorial.css";

const AngrySharks = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 61;

  return (
    <div className='scratchGames__container'>
        <h1>
        🦈💢 "Rage Reef: The Angry Sharks Uprising!" 💢🦈        
        </h1>

        <h3 className='intro'>
        Dive into the treacherous waters of "Rage Reef: The Angry Sharks Uprising!" 🦈💢 Beneath the tranquil waves lies a world where the ocean's apex predators are on the brink of rebellion. Fueled by the disturbances in their habitat, a group of formidable sharks have united, and their fury knows no bounds. 🌊🔥
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>





        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎉 Step 1 🎉</h2>
          <ul>
            <li>
              To start this project navigate to the Scratch website.
            </li>

            <li>
              Scratch: <a target="_blank" href="https://scratch.mit.edu/" >https://scratch.mit.edu/</a>
            </li>

            <li>
              Select the "create" tab at the top to open a new project.
            </li>
          </ul>

          <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌟 Step 2 🌟</h2>
          <ul>
            <li>
              As our gripping adventure begins we need to replace Scratch Cat with "Shark2". This shark knowns no bounds and has no limits. His mission will be to defend his side of the reef from the invasive crab army!
            </li>

            <li>
              Make sure to set the shark's size from 100 to 60.
            </li>
          </ul>

          <img src={two} className='demo__image' alt='img_two' />
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚀 Step 3 🚀</h2>
          <ul>
            <li>
            The objective of this shark and your mission will be to use "arrows" to aim and launch a shark attack on the crabs.
            </li>

            <li>
              To accomplish this daring feat, add an "arrow1" sprite into the game canvas!
            </li>
          </ul>

          <img src={three} className='demo__image' alt='img_three' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎈 Step 4 🎈</h2>
          <ul>
            <li>
              First, we need to code the "arrow". Ensure that the arrow is selected and add a "when flag clicked" block into the game canvas.
            </li>

            <li>
              This block will start the first code sequence of the "when flag clicked".
            </li>
          </ul>

          <img src={four} className='demo__image' alt='img_four' />
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🔥 Step 5 🔥</h2>
          <ul>
            <li>
              Since we are coding the "arrow", you may ask what this entails. The arrow will control the speed, velocity, and gravity of the game in order to calculate the launch of the shark.
            </li>

            <li>
              That being said, we need to create some variables for this process.
            </li>

            <li>
              Navigate to the "variables" section of the game and select the "make a variable" button.
            </li>
          </ul>

          <img src={five} className='demo__image' alt='img_five' />
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>💡 Step 6 💡</h2>
          <ul>
            <li>
              This variable should be named "Launch Speed" which will control the speed that the shark is launched into the air!
            </li>

            <li>
              Name the variable "Launch Speed" and select "OK" when you are ready.
            </li>
          </ul>

          <img src={six} className='demo__image' alt='img_six' />
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍀 Step 7 🍀</h2>
          <ul>
            <li>
              In the "variables" section find the block that says: "set Launch Speed to 10"
            </li>

            <li>
              We are using this block so that when we are about the launch the shark it will have a default speed of ten.
            </li>

            <li>
              Later in the project we will create a code sequence that allows the player in increase or decrease the "Launch Speed", this way the shark will be able to go faster or slower.
            </li>
          </ul>

          <img src={seven} className='demo__image' alt='img_seven' />
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌈 Step 8 🌈</h2>

          <ul>
            <li>
              Add the block "go to X: Y:"
            </li>

            <li>
              Do you remember what the "go to X: Y:" block does? This block will position that image in the location that we tell it in the game. In our case, we are wanting to place our arrow in the bottom left corner of the screen where we will be launching the sharks from.
            </li>

            <li>
              For the "X" value fill "-200" and for the "Y" value fill "-140". These are the coordinates for the bottom left position on the game screen.
            </li>
          </ul>

          <img src={eight} className='demo__image' alt='img_eight' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎁 Step 9 🎁</h2>
          <ul>
            <li>
              Let's test the blocks we have coded so far.
            </li>

            <li>
              Press the "flag" button to start the game, if your code matches the code below you should see your "Launch Speed" set to 10 and the arrow at the bottom left corner of the game. Onwards!
            </li>
          </ul>

          <img src={nine} className='demo__image' alt='img_nine' />
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎶 Step 10 🎶</h2>
          <ul>
            <li>
              In order to continue coding the "arrow" sprite add the following two blocks: "point in direction 45" and "go to front layer"
            </li>

            <li>
              These two blocks will; one, point the arrow in the starting position; and two, make sure the arrow is not hidden behind any other sprites.
            </li>
          </ul>

          <img src={ten} className='demo__image' alt='img_ten' />
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌺 Step 11 🌺</h2>
          <ul>
            <li>
            I want your to remember what this arrow is for. We are using it to control how the shark is launched. Shark attack!
            </li>

            <li>
              The next sequence of code for the "arrow" will allow the player to lower the "launch speed" of the shark when they use the "down arrow" on the keyboard.
            </li>

            <li>
              So, find the "when down arrow key pressed" block and drag it into the code canvas!
            </li>

            <li>
              Now any code that comes after this block will run if the player presses the "down arrow".
            </li>
          </ul>

          <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍄 Step 12 🍄</h2>
          <ul>
            <li>
              The first thing that we need to do after the player clicks the "down arrow" is to check if the "launch speed" variable is greater than 1. We are doing this so that the player does not lower the "launch speed" variable less than 0.
            </li>

            <li>
              Find the "if block" and check if the "launch speed" is greater than 1. If it is greater than one, the code inside of the "if block" will happen!
            </li>
          </ul>

          <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍪 Step 13 🍪</h2>
          <ul>
            <li>
              So far when the player clicks the "down arrow" on the key board, to lower the launch speed of the shark, the code checks if the launch speed is greater than 1. If it is greater than 1, then we will lower the launch speed.
            </li>

            <li>
              To lower the launch speed find the "change launch speed by" block and change it by "-0.1".
            </li>
          </ul>

          <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐾 Step 14 🐾</h2>
          <ul>
            <li>
              Now we have two sequences of code for the "arrow". But we need a third!
            </li>

            <li>
              This third sequence will control increasing the "launch speed" when a player clicks the "up arrow".
            </li>

            <li>
            Start this third sequence with a "when up arrow key pressed" block.
            </li>
          </ul>

          <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌊 Step 15 🌊</h2>
          <ul>
            <li>
              Now when the "up arrow" is clicked we need to make sure that the "launch speed" is less than 20 to increase it. In other words, we will not let the player's increase the launch speed more than 20. These sharks are not trying to go to the moon!
            </li>

            <li>
              Create an "if block" sequence that checks if the launch speed is less than 20. Use the image below as a guide to help you navigate the code.
            </li>
          </ul>

          <img src={fifteen} className='demo__image' alt='img_fifteen' />
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍉 Step 16 🍉</h2>
          <ul>
            <li>
              If the "launch speed" is in fact less than 20 and the player is clicking the "up arrow" to increase it; then we will increase the launch speed by "0.1"
            </li>

            <li>
              Find and add the "change launch speed by 0.1" block and fit it into the "if block" sequence.
            </li>

            <li>
            And there we have it! Three code sequences for the arrow.... but we are not done yet!
            </li>
          </ul>

          <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎭 Step 17 🎭</h2>
          <ul>
            <li>
              We need two more sequences of code for the arrow! There are very short, so we can get them both done in this step.
            </li>

            <li>
              Right now the up and down arrow will allow the player to increase or decrease the launch speed. But what if we want to rotate the arrow to aim as we launch the shark? That's what these next two sequences will allow. By clicking the left or right arrows, the player will be able to rotate the arrow to aim and shoot the shark into the air!
            </li>

            <li>
              Look at the two new sequences in the image below and add them into the game.
            </li>

            <li>
              The first: "when left arrow key pressed" and "turn left 3 degrees" will let the player rotate the arrow upwards.
            </li>

            <li>
              The second: "when right arrow key pressed" and "turn right 3 degrees" will let the player rotate the arrow downwards. 
            </li>
          </ul>

          <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎨 Step 18 🎨</h2>
          <ul>
            <li>
              The arrow now controls all aspects of launching the shark, but now we need to actually code the shark to respond to the launch settings of the arrow.
            </li>

            <li>
            Add a "when flag clicked" to start the first sequence of code for the shark.
            </li>
          </ul>

          <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚴 Step 19 🚴</h2>
          <ul>
            <li>
              When the shark is launched we do not want the shark to rotate (or spin) as it speeds through the air.
            </li>

            <li>
              To prevent that from happening, add a "set rotation style don't rotate" block. This block can be found in the "motion" section.
            </li>
          </ul>

          <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍦 Step 20 🍦</h2>
          <ul>
            <li>
              When the player starts the game, we want the shark to be positioned underneath the arrow. 
            </li>

            <li>
              To do this, add a "go to arrow1" block. So that the shark starts at the arrow and is launched from that position!
            </li>
          </ul>

          <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌍 Step 21 🌍</h2>
          <ul>
            <li>
              Navigate to the variables section and select the "make a variable" button as we venture into the next steps of this project!
            </li>
          </ul>

          <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🕺 Step 22 🕺</h2>
          <ul>
            <li>
              Name the new variable "Gravity" and select "OK" when ready. This variable will control the force applied downwards on the shark as the shark launches through the air.
            </li>
          </ul>

          <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎤 Step 23 🎤</h2>
          <ul>
            <li>
              Think about gravity in real life! Does it push things down or lift them up?...It pushes things down!
            </li>

            <li>
              This means that in our game we need to set our gravity variable to a negative number rather than a positive one, this negative number will push the shark down as it speeds through the air so that it does not infinitely fly up!
            </li>

            <li>
              Add a block that reads "set Gravity to -0.2"
            </li>
          </ul>

          <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎬 Step 24 🎬</h2>
          <ul>
            <li>
              We have coded all of the things that would allow us to launch a shark, but the player does not have a way to actually command the shark into the air (or ocean).
            </li>

            <li>
              Add a "when space key pressed" block into the code canvas, the "space key" will be the key that triggers code to start launching the shark.
            </li>
          </ul>

          <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎮 Step 25 🎮</h2>
          <ul>
            <li>
              Before we start the launch sequence (after pressing space) two things need to happen: The "bite" sound plays and the shark starts at the "arrow" before going airborne!
            </li>

            <li>
              Do these two actions by adding a "start sound bite" and a "go to arrow1" block.
            </li>
          </ul>

          <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>📚 Step 26 📚</h2>
          <ul>
            <li>
              As the shark swims through the air, there needs to be a speed at which the shark falls.
            </li>

            <li>
              Create a variable named "Fall Speed" in the variables section.
            </li>
          </ul>

          <img src={twentySix} className='demo__image' alt='img_twentySix' />
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚂 Step 27 🚂</h2>
          <ul>
            <li>
              Now take the "set fall speed to 0" block and add it to the sequence before we complete the code that starts the launch of the shark.
            </li>
          </ul>

          <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🏖 Step 28 🏖</h2>
          <ul>
            <li>
              Before we code the shark's launch we need to add an "octopus" sprite that will act as a blockade or defender of the crabs.
            </li>

            <li>
              Add the "octopus" and set the size "50".
            </li>
          </ul>

          <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎡 Step 29 🎡</h2>
          <ul>
            <li>
              Now that we added the octopus, we can navigate back to shark and continue the code sequence.
            </li>

            <li>
              Add a "repeat until" block.
            </li>

            <li>
              Within the "repeat until" block add an "or" operator containing: "touching mouse pointer" and "touching edge"
            </li>

            <li>
              Use the image below to guide you.
            </li>

            <li style={{ color: "red" }} >
              Change "touching mouse-pointer" to "touching octopus"
            </li>
          </ul>

          <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍕 Step 30 🍕</h2>
          <ul>
            <li>
            This "repeat until" block will repeat code until the shark is touching the edge of the game screen or it is touching an octopus.
            </li>

            <li>
              The new code below that is being "repeated" causes the shark to fly.
            </li>

            <li>
              Add the three new blocks that you see below.
            </li>

            <li>
              The first, moves the shark how ever many steps the launch speed is set to.
            </li>

            <li>
              The second, changes the Y movement of the shark by the fall speed.
            </li>

            <li>
              The third, changes the fall speed of the shark based on the gravity.
            </li>

            <li>
              With all of these blocks put together we get a complexly organized sequence of code to launch our shark based on real time physics and movement.
            </li>
          </ul>
          
          <img src={thirty} className='demo__image' alt='img_thirty' />
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌜 Step 31 🌜</h2>
          <ul>
            <li>
              At the bottom of this sequence add a "go to arrow1" block, now the shark will reset at the arrow when it is completely done launching.
            </li>
          </ul>

          <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>⛱ Step 32 ⛱</h2>
          <ul>
            <li>
              Our much awaited moment has arrived, let's add the crabs!
            </li>

            <li>
              Navigate to the "sprites" section and add a crab into the game.
            </li>
          </ul>

          <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍩 Step 33 🍩</h2>
          <ul>
            <li>
              Change the size of the crab from 100 to 80. These crabs are far to big!!!
            </li>
          </ul>

          <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐝 Step 34 🐝</h2>
          <ul>
            <li>
              Now when the game starts we want this crab to "hide" or go invisible. Now why would be want that?
            </li>

            <li>
              This crab is the original, we are going to create and spawn clones in the game that will be shown... but the original will remain hidden!
            </li>
          </ul>

          <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎢 Step 35 🎢</h2>
          <ul>
            <li>
              Create a variable named "NumCrabs" this will determine how many crabs to create in the game... these will be the clones that appear.
            </li>
          </ul>

          <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍿 Step 36 🍿</h2>
          <ul>
            <li>
              Add the block: "set NumCrabs to 3"
            </li>

            <li>
              This block will determine how many crab clones we create in the game!
            </li>
          </ul>

          <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>⭐ Step 37 ⭐</h2>
          <ul>
            <li>
              The image below is creating a brand new sequence to repeat cloning the original crab however many times that the "NumCrabs" variable is, in this case three.
            </li>

            <li>
              Create the sequence below to emulate the process described above.
            </li>
          </ul>

          <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎠 Step 38 🎠</h2>
          <ul>
            <li>
              Add the "when I start as a clone" block, this block will begin the code sequence to control the clone.
            </li>
          </ul>

          <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🦋 Step 39 🦋</h2>
          <ul>
            <li>
              When the clone starts, it needs to go to a random position on the screen.
            </li>

            <li>
              Make this happen with a "go to X: Y:" block that picks a random position with two different "random operators"
            </li>

            <li>
              The first random operator should range from 0 to 200 and the second operator should range from -140 to 140.
            </li>
          </ul>

          <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌲 Step 40 🌲</h2>
          <ul>
            <li>
            In addition to picking a random position that the crab clones start, they each need to be a random size.
            </li>

            <li>
            To accomplish this add a "set size to" block defined by a "pick random 50 to 100" block.
            </li>
          </ul>

          <img src={forty} className='demo__image' alt='img_forty' />
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌸 Step 41 🌸</h2>
          <ul>
            <li>
              Now let's also adjust the color of the crab, add a "set color effect to" block with a "pick random -10 to 20" inside of it.
            </li>
          </ul>

          <img src={fortyOne} className='demo__image' alt='img_fortyOne' />
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🥤 Step 42 🥤</h2>
          <ul>
            <li>
              Add the next two blocks: "show" and "wait until touching shark2".
            </li>

            <li>
              These two blocks will will ensure the crab clone appears on the screen and will not do anything until it is in the face of danger (the shark is touching it).
            </li>
          </ul>

          <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>⛵ Step 43 ⛵</h2>
          <ul>
            <li>
              Next add the "start sound pop" which will play when the crab is touching the shark based on the block above (wait until touching shark2).
            </li>
          </ul>

          <img src={fortyThree} className='demo__image' alt='img_fortyThree' />
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚁 Step 44 🚁</h2>
          <ul>
            <li>
              Now that the crab is touching the shark, it means that the player successfully launched a shark attack, we need to eliminate one of the crabs by adding the "change NumCrabs by -1" block. Thus, we are subtracting a crab from the crab count.
            </li>
          </ul>

          <img src={fortyFour} className='demo__image' alt='img_fortyFour' />
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌠 Step 45 🌠</h2>
          <ul>
            <li>
              What do we do when there are no crabs left? We do not keep subtracting the crabs, in fast we need to end the game.
            </li>

            <li>
              That being said add an "if block" checking if the number of crabs is 0 and if so, broadcasting "Game Over".
            </li>

            <li>
              Add an "if block" sequence and fill the details as seen below.
            </li>
          </ul>

          <img src={fortyFive} className='demo__image' alt='img_fortyFive' />
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          
          <h2>🍜 Step 46 🍜</h2>
          <ul>
            <li>
              At the very bottom of this sequence add a "delete this clone".
            </li>

            <li>
              We have subtracted from "NumCrabs" but now is the time to delete the clone that was hit (or attacked)!
            </li>
          </ul>

          <img src={fortySix} className='demo__image' alt='img_fortySix' />
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🏄 Step 47 🏄</h2>
          <ul>
            <li>
              Adjust the size of the shark from 100 to 50.
            </li>
          </ul>
          
          <img src={fortySeven} className='demo__image' alt='img_fortySeven' />
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍓 Step 48 🍓</h2>
          <ul>
            In the shark create the following block that you see in the image below: "point in direction" containing "direction of arrow1". Totaling to "point in direction direction of arrow1"
          </ul>

          <img src={fortyEight} className='demo__image' alt='img_fortyEight' />
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍋 Step 49 🍋</h2>
          <ul>
            <li>
              Insert this new block to be under the "set fall speed to 0" block.
            </li>
          </ul>

          <img src={fortyNine} className='demo__image' alt='img_fortyNine' />
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍓 Step 50 🍓</h2>
          <ul>
            <li>
              Now let's code the octopus, guardian of the crabs (he blocks the sharks).
            </li>

            <li>
              Click the octopus to code it and add the following two blocks: "when flag clicked" and "go to X: -12 Y: -52"
            </li>

            <li>
              This will correctly position the octopus in the right place at the start of the game.
            </li>
          </ul>

          <img src={fifty} className='demo__image' alt='img_fifty' />
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎒 Step 51 🎒</h2>
          <ul>
            <li>
              Select the "paint" tool as we will be creating a display for the player's final score.
            </li>
          </ul>

          <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚗 Step 52 🚗</h2>
          <ul>
            <li>
              Use the tools in the custom sprite section to create a board like the following.
            </li>

            <li>
              Notice the empty space in between the "you used" and "launches"; this is where the score variable will be displayed.
            </li>
          </ul>

          <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌵 Step 53 🌵</h2>
          <ul>
            <li>
              Navigate back to the code once you completed you design and rename the sprite to "Display".
            </li>
          </ul>

          <img src={fiftyThree} className='demo__image' alt='img_fiftyThree' />
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍔 Step 54 🍔</h2>
          <ul>
            <li>
              Create a "Launches" variable that will track how many launches it took the player to defeat the crabs.
            </li>

            <li>
              This will be displayed on the board that we just created!
            </li>
          </ul>

          <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍟 Step 55 🍟</h2>
          <ul>
            <li>
              Now to code the board add a "when space key pressed" block followed by a "change launches by 1" block.
            </li>

            <li>
              This will increase the "launches" variable each time the space bar is pressed (also causing the shark to be launched). The result: a counter for how many launches it took our player to win!
            </li>
          </ul>

          <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍉 Step 56 🍉</h2>
          <ul>
            <li>
              Now add a "when flag clicked" with a "hide" block so the message board is invisible when the game starts. But don't worry, we will make it appear at the end of the game!
            </li>
          </ul>

          <img src={fiftySix} className='demo__image' alt='img_fiftySix' />
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎀 Step 57 🎀</h2>
          <ul>
            <li>
              Add the following three blocks: "go to X: 0 Y: 0", "set Launches to 0", and "hide variable Launches" in order to configure the message board for proper display.
            </li>
          </ul>

          <img src={fiftySeven} className='demo__image' alt='img_fiftySeven' />
        </div>

        <div style={currentStep == 58 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍍 Step 58 🍍</h2>
          <ul>
            <li>
              Then add the following blocks to display the message board when the game is over.
            </li>

            <li>
              First: "when I receive Game Over"
            </li>

            <li>
              Second: "go to front layer"
            </li>
          </ul>

          <img src={fiftyEight} className='demo__image' alt='img_fiftyEight' />
        </div>

        <div style={currentStep == 59 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎁 Step 59 🎁</h2>
          <ul>
            <li>
              The next block "show variable launches" will display the variable in the blank space and the "stop all" will finally stop the game.
            </li>
          </ul>

          <img src={fiftyNine} className='demo__image' alt='img_fiftyNine' />
        </div>

        <div style={currentStep == 60 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍇 Step 60 🍇</h2>
          <ul>
            <li>
              Add the ocean background to spruce up our oceanic adventure!
            </li>
          </ul>

          <img src={sixty} className='demo__image' alt='img_sixty' />
        </div>

        <div style={currentStep == 61 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍨 Step 61 🍨</h2>
          <ul>
            <li>
              Congratulations my friend! Adventure awaits your lucky players.... make sure to name your game and press that "save now" button! Until next time...
            </li>
          </ul>

          <img src={sixtyOne} className='demo__image' alt='img_sixtyOne' />
        </div>



        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default AngrySharks