import React, {useState} from 'react';
import one from './scratchImages/dragonparkImages/1.png';
import two from './scratchImages/dragonparkImages/2.png';
import three from './scratchImages/dragonparkImages/3.png';
import four from './scratchImages/dragonparkImages/4.png';
import five from './scratchImages/dragonparkImages/5.png';
import six from './scratchImages/dragonparkImages/6.png';
import seven from './scratchImages/dragonparkImages/7.png';
import eight from './scratchImages/dragonparkImages/8.png';
import nine from './scratchImages/dragonparkImages/9.png';
import ten from './scratchImages/dragonparkImages/10.png';
import eleven from './scratchImages/dragonparkImages/11.png';
import twelve from './scratchImages/dragonparkImages/12.png';
import thirteen from './scratchImages/dragonparkImages/13.png';
import fourteen from './scratchImages/dragonparkImages/14.png';
import fifteen from './scratchImages/dragonparkImages/15.png';
import sixteen from './scratchImages/dragonparkImages/16.png';
import seventeen from './scratchImages/dragonparkImages/17.png';
import eighteen from './scratchImages/dragonparkImages/18.png';
import nineteen from './scratchImages/dragonparkImages/19.png';
import twenty from './scratchImages/dragonparkImages/20.png';
import twentyOne from './scratchImages/dragonparkImages/21.png';
import twentyTwo from './scratchImages/dragonparkImages/22.png';
import twentyThree from './scratchImages/dragonparkImages/23.png';
import twentyFour from './scratchImages/dragonparkImages/24.png';
import twentyFive from './scratchImages/dragonparkImages/25.png';
import twentySix from './scratchImages/dragonparkImages/26.png';
import twentySeven from './scratchImages/dragonparkImages/27.png';
import twentyEight from './scratchImages/dragonparkImages/28.png';
import twentyNine from './scratchImages/dragonparkImages/29.png';
import thirty from './scratchImages/dragonparkImages/30.png';
import thirtyOne from './scratchImages/dragonparkImages/31.png';
import thirtyTwo from './scratchImages/dragonparkImages/32.png';
import thirtyThree from './scratchImages/dragonparkImages/33.png';
import thirtyFour from './scratchImages/dragonparkImages/34.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import "./tutorial.css";

const DragonPark = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 33;

  return (
    <div className='scratchGames__container'>
        <h1>
        🐉✨ "DragonPark: The Enchanted Park Odyssey!" ✨🐉
        </h1>
        
        <h3 className='intro'>
        Welcome to "DragonPark: The Enchanted Park Odyssey!" 🐉✨ In this mesmerizing game, players are thrown into a realm where majestic dragons rule the skies, and enchanted parks are the heart of their kingdoms. Your quest? To design, cultivate, and manage the ultimate dragon sanctuary, ensuring each creature feels right at home 🌌
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>





        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎉 Step 1 🎉</h2>

          <ul>
            <li>
              To start this project navigate to the Scratch website.
            </li>

            <li>
              Scratch: <a target="_blank" href="https://scratch.mit.edu/" >https://scratch.mit.edu/</a>
            </li>

            <li>
              Select the "create" tab at the top to open a new project.
            </li>
          </ul>

          <img src={one} className='demo__image' alt='img_one' />
        </div>
        
        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌟 Step 2 🌟</h2>
          <ul>
            <li>
              There are a few very important actions required in this step.
            </li>

            <li>
              First, delete the cat.
            </li>

            <li>
              Second, add the dragon from the "new sprites" section you see below.
            </li>

            <li>
              Third, add a fun background! The one you see below is "nebula" because in the vast expansion of space.... a majestic dragon lurks!
            </li>
          </ul>

          <img src={two} className='demo__image' alt='img_two' />
        </div>
        
        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚀 Step 3 🚀</h2>
          <ul>
            <li>
              Let's get coding this dragon!
            </li>

            <li>
              Add a "when flag clicked" block to start the first code sequence.
            </li>

            <li>
              Add a "switch backdrop to nebula"... or fill in that blank with whatever background you decided to use. This ensures the right background always starts with the start of the game.
            </li>
          </ul>

          <img src={three} className='demo__image' alt='img_three' />
        </div>
        
        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎈 Step 4 🎈</h2>
          <ul>
            <li>
              There are two variables that we need to make.
            </li>

            <li>
              Go to the "variables" section and select the "make a variable" button.
            </li>
          </ul>

          <img src={four} className='demo__image' alt='img_four' />
        </div>
        
        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🔥 Step 5 🔥</h2>
          <ul>
            <li>
              Here create the first variable and label it as "Hunger Level". This will track the dragon's hunger when we feed it.
            </li>
          </ul>

          <img src={five} className='demo__image' alt='img_five' />
        </div>
        
        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>💡 Step 6 💡</h2>
          <ul>
            <li>
              Create the next variable, Thirst Level, to track how thirsty it is when we give it water.
            </li>

            <li>
              Remember: variables are containers of data that hold information.
            </li>
          </ul>

          <img src={six} className='demo__image' alt='img_six' />
        </div>
        
        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍀 Step 7 🍀</h2>
          <ul>
            <li>
              If you completed the last three steps correctly, you should see the variable names and numbers displayed on the game screen.
            </li>

            <li>
              Check for correctness, then add two new blocks. "set Hunger Level to 0" and "set Thirst Level to 0". These blocks will reset the levels every time a new game is started.
            </li>
          </ul>

          <img src={seven} className='demo__image' alt='img_seven' />
        </div>
        
        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌈 Step 8 🌈</h2>
          <ul>
            <li>
              This dragon is not just a regular dragon that roars and breaths fire... he can talk! And he even has a name!
            </li>

            <li>
              The block that we are using in this step will reflect those things.
            </li>

            <li>
              Add the block that you see below to the sequence.
            </li>

            <li>
              This block will make the dragon talk for "2 seconds" and displays the message "My name is Grumpy, the dragon".
            </li>
          </ul>

          <img src={eight} className='demo__image' alt='img_eight' />
        </div>
        
        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎁 Step 9 🎁</h2>
          <ul>
            <li>
              We need another sequence of code for our dragon friend Grumpy. This sequence will be activates when we click Grumpy, so add the "when this sprite clicked" block.
            </li>
          </ul>

          <img src={nine} className='demo__image' alt='img_nine' />
        </div>
        
        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎶 Step 10 🎶</h2>
          <ul>
            <li>
              Add the start sound block to play a sound when Grumpy is clicked.
            </li>
          </ul>

          <img src={ten} className='demo__image' alt='img_ten' />
        </div>
        
        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌺 Step 11 🌺</h2>
          <ul>
            <li>
              Our sequence is not done, while a sound may play... we also want to activate a dance or action.
            </li>

            <li>
              Add a "repeat" block with an amount of "4".
            </li>
          </ul>

          <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>
        
        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍄 Step 12 🍄</h2>
          <ul>
            <li>
              Inside of this repeat block there are 4 new blocks to create a dragon dance.
            </li>

            <li>
              The first, "switch costume to dragon-c"
            </li>

            <li>
              The second, "wait 0.2 seconds"
            </li>

            <li>
              The third, "switch costume to dragon-a"
            </li>

            <li>
              The fourth, "wait 0.2 seconds"
            </li>

            <li>
              By cycling this pattern, the dragon will switch between its costumes and create a dance like movement. In this case, breath fire into the air!
            </li>
          </ul>

          <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>
        
        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍪 Step 13 🍪</h2>
          <ul>
            <li>
              Look at what we have below, a tasty snack for grumpy!
            </li>

            <li>
              Add the "Cheese Puff" sprite from the sprite area and resize it to a size of "80", a fun sized snack for Grumpy!
            </li>
          </ul>

          <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>
        
        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐾 Step 14 🐾</h2>
          <ul>
            <li>
              Let's get coding this cheesy snack!
            </li>

            <li>
              Add a "when sprite clicked" block with a "go to front layer" block to ensure the snake displays when we click the snack.
            </li>
          </ul>

          <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>
        
        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌊 Step 15 🌊</h2>
          <ul>
            <li>
            When we click the snack, we want to broadcast a message to the other Sprites in order to create an action.
            </li>

            <li>
              Add a "broadcast" block and create a "new message".
            </li>
          </ul>

          <img src={fifteen} className='demo__image' alt='img_fifteen' />
        </div>
        
        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍉 Step 16 🍉</h2>
          <ul>
            <li>
              For the new message write the word "food".
            </li>
          </ul>

          <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>
        
        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎭 Step 17 🎭</h2>
          <ul>
            <li>
              The sequence below is what you should have thus far in the code for the cheese puffs.
            </li>
          </ul>

          <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>
        
        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎨 Step 18 🎨</h2>
          <ul>
            <li>
              Next, add a glass of water.
            </li>
          </ul>

          <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>
        
        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚴 Step 19 🚴</h2>
          <ul>
            <li>
              Add the next three blocks of code to the water sprite. It is very similar to the cheese puff code, this will make sure that when the water is clicked it was stay on the scree and broadcast a message.
            </li>

            <li>
              Remember "broadcasting" allows sprites to communicate what is happening to each other, it does not make a visible change on the screen.
            </li>
          </ul>

          <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>
        
        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍦 Step 20 🍦</h2>
          <ul>
            <li>
              The result of this code should cause the water to empty and fill back up to let the users know Grumpy is taking a drink. To do this we need to change the costumes of the water glass.
            </li>

            <li>
              Go ahead and add the two new blocks of code that you see below. "wait 1 second" and "switch costume to glass water-b".
            </li>
          </ul>

          <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>
        
        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌍 Step 21 🌍</h2>
          <ul>
            <li>
              Then add three more blocks to the sequence.
            </li>

            <li>
              Start sound "Water Drop" for a drinking noise.
            </li>

            <li>
              Wait 1 second to pause, and switch the costume back to "glass water-a"
            </li>

            <li>
              This whole sequence should end with the water looking like it is being emptied and filled back up with a sound effect added.
            </li>
          </ul>

          <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>
        
        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🕺 Step 22 🕺</h2>
          <ul>
            <li>
              Attach the code for the water together to create the following end result you see in the image.
            </li>
          </ul>

          <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>
        
        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          
          <h2>🎤 Step 23 🎤</h2>
          <ul>
            <li>
              Click the dragon to go back to it, as we will continue to code out majestic friend Grumpy the dragon!
            </li>
          </ul>

          <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>
        
        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎬 Step 24 🎬</h2>
          <ul>
            <li>
              In Grumpy's code we need to create a third sequence of code.
            </li>

            <li>
              Add the "when I receive food" block.
            </li>

            <li>
              This block is activated, when the "broadcast food" block starts.
            </li>
          </ul>

          <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
        </div>
        
        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎮 Step 25 🎮</h2>
          <ul>
            <li>
              After the "when I receive food" block activates, you need a "glide 1 seconds to cheese puffs" and "start sound magic spell".
            </li>

            <li>
              This gracefully lets Grumpy fly to his snack and enjoy his tasty treat!
            </li>
          </ul>

          <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
        </div>
        
        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>📚 Step 26 📚</h2>
          <ul>
            <li>
              After Grumpy eats, we need to change his hunger level.
            </li>

            <li>
              Do this by adding the "change hunger level by 1" block, this will increase the "hunger level" variable we created earlier.
            </li>
          </ul>

          <img src={twentySix} className='demo__image' alt='img_twentySix' />
        </div>
        
        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚂 Step 27 🚂</h2>
          <ul>
            <li>
              Add a "wait 0.5 block"
            </li>

            <li>
              Add a "glide 1 seconds to x: -83 y: 53"
            </li>

            <li>
              These new blocks will get Grumpy to move back to his original spot in the game after eating his snack.
            </li>
          </ul>

          <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
        </div>
        
        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🏖 Step 28 🏖</h2>
          <ul>
            <li>
              Then add a "when I receive drink" block to to create the sequence to get Grumpy to take a sip of water!
            </li>
          </ul>

          <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
        </div>
        
        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎡 Step 29 🎡</h2>
          <ul>
            <li>
              The next block will cause his to glide to the water, follow the image below and add it!
            </li>
          </ul>

          <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
        </div>
        
        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍕 Step 30 🍕</h2>
          <ul>
            <li>
              After he drinks his water, increase the thirst level with the "change thirst level by 1" block.
            </li>
          </ul>

          <img src={thirty} className='demo__image' alt='img_thirty' />
        </div>
        
        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌜 Step 31 🌜</h2>
          <ul>
            <li>
              Adding the "wait 0.5 seconds" block that you see below will get him to pause as he drinks his refreshing glass of water.
            </li>
          </ul>

          <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
        </div>
        
        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>⛱ Step 32 ⛱</h2>
          <ul>
            <li>
              Just like the sequence of code for the food, add the next block to cause his to glide back to his original spot: glide 1 seconds to X: -83 Y: 53
            </li>
          </ul>

          <img src={thirtyThree} className='demo__image' alt='img_thirtyTwo' />
        </div>
        
        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐝 Step 33 🐝</h2>
          <ul>
            <li>
              All of the work is done! It is time for the most important part naming and saving! Represent your project well with an awesome name and hit that save button!
            </li>
          </ul>
          <img src={thirtyFour} className='demo__image' alt='img_thirtyThree' />
        </div>
      


        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>

    </div>
  )
}

export default DragonPark