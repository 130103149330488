import React, {useState} from 'react';
import one from './robloxImages/CASTLE-CONUNDRUM/1.png';
import two from './robloxImages/CASTLE-CONUNDRUM/2.png';
import three from './robloxImages/CASTLE-CONUNDRUM/3.png';
import four from './robloxImages/CASTLE-CONUNDRUM/4.png';
import five from './robloxImages/CASTLE-CONUNDRUM/5.png';
import six from './robloxImages/CASTLE-CONUNDRUM/6.png';
import seven from './robloxImages/CASTLE-CONUNDRUM/7.png';
import eight from './robloxImages/CASTLE-CONUNDRUM/8.png';
import nine from './robloxImages/CASTLE-CONUNDRUM/9.png';
import ten from './robloxImages/CASTLE-CONUNDRUM/10.png';
import eleven from './robloxImages/CASTLE-CONUNDRUM/11.png';
import twelve from './robloxImages/CASTLE-CONUNDRUM/12.png';
import thirteen from './robloxImages/CASTLE-CONUNDRUM/13.png';
import fourteen from './robloxImages/CASTLE-CONUNDRUM/14.png';
import fifteen from './robloxImages/CASTLE-CONUNDRUM/15.png';
import sixteen from './robloxImages/CASTLE-CONUNDRUM/16.png';
import seventeen from './robloxImages/CASTLE-CONUNDRUM/17.png';
import eighteen from './robloxImages/CASTLE-CONUNDRUM/18.png';
import nineteen from './robloxImages/CASTLE-CONUNDRUM/19.png';
import twenty from './robloxImages/CASTLE-CONUNDRUM/20.png';
import twentyOne from './robloxImages/CASTLE-CONUNDRUM/21.png';
import twentyTwo from './robloxImages/CASTLE-CONUNDRUM/22.png';
import twentyThree from './robloxImages/CASTLE-CONUNDRUM/23.png';
import twentyFour from './robloxImages/CASTLE-CONUNDRUM/24.png';
import twentyFive from './robloxImages/CASTLE-CONUNDRUM/25.png';
import twentySix from './robloxImages/CASTLE-CONUNDRUM/26.png';
import twentySeven from './robloxImages/CASTLE-CONUNDRUM/27.png';
import twentyEight from './robloxImages/CASTLE-CONUNDRUM/28.png';
import twentyNine from './robloxImages/CASTLE-CONUNDRUM/29.png';
import thirty from './robloxImages/CASTLE-CONUNDRUM/30.png';
import thirtyOne from './robloxImages/CASTLE-CONUNDRUM/31.png';
import thirtyTwo from './robloxImages/CASTLE-CONUNDRUM/32.png';
import thirtyThree from './robloxImages/CASTLE-CONUNDRUM/33.png';
import thirtyFour from './robloxImages/CASTLE-CONUNDRUM/34.png';
import thirtyFive from './robloxImages/CASTLE-CONUNDRUM/35.png';
import thirtySix from './robloxImages/CASTLE-CONUNDRUM/36.png';
import thirtySeven from './robloxImages/CASTLE-CONUNDRUM/37.png';
import thirtyEight from './robloxImages/CASTLE-CONUNDRUM/38.png';
import thirtyNine from './robloxImages/CASTLE-CONUNDRUM/39.png';
import forty from './robloxImages/CASTLE-CONUNDRUM/40.png';
import fortyOne from './robloxImages/CASTLE-CONUNDRUM/41.png';
import fortyTwo from './robloxImages/CASTLE-CONUNDRUM/42.png';
import fortyThree from './robloxImages/CASTLE-CONUNDRUM/43.png';
import fortyFour from './robloxImages/CASTLE-CONUNDRUM/44.png';
import fortyFive from './robloxImages/CASTLE-CONUNDRUM/45.png';
import fortySix from './robloxImages/CASTLE-CONUNDRUM/46.png';
import fortySeven from './robloxImages/CASTLE-CONUNDRUM/47.png';
import fortyEight from './robloxImages/CASTLE-CONUNDRUM/48.png';
import fortyNine from './robloxImages/CASTLE-CONUNDRUM/49.png';
import fifty from './robloxImages/CASTLE-CONUNDRUM/50.png';
import fiftyOne from './robloxImages/CASTLE-CONUNDRUM/51.png';
import fiftyTwo from './robloxImages/CASTLE-CONUNDRUM/52.png';
import fiftyThree from './robloxImages/CASTLE-CONUNDRUM/53.png';
import fiftyFour from './robloxImages/CASTLE-CONUNDRUM/54.png';
import fiftyFive from './robloxImages/CASTLE-CONUNDRUM/55.png';
import fiftySix from './robloxImages/CASTLE-CONUNDRUM/56.png';
import fiftySeven from './robloxImages/CASTLE-CONUNDRUM/57.png';
import fiftyEight from './robloxImages/CASTLE-CONUNDRUM/58.png';
import fiftyNine from './robloxImages/CASTLE-CONUNDRUM/59.png';
import sixty from './robloxImages/CASTLE-CONUNDRUM/60.png';
import sixtyOne from './robloxImages/CASTLE-CONUNDRUM/61.png';
import sixtyTwo from './robloxImages/CASTLE-CONUNDRUM/62.png';
import sixtyThree from './robloxImages/CASTLE-CONUNDRUM/63.png';
import sixtyFour from './robloxImages/CASTLE-CONUNDRUM/64.png';
import sixtyFive from './robloxImages/CASTLE-CONUNDRUM/65.png';
import sixtySix from './robloxImages/CASTLE-CONUNDRUM/66.png';
import sixtySeven from './robloxImages/CASTLE-CONUNDRUM/67.png';
import sixtyEight from './robloxImages/CASTLE-CONUNDRUM/68.png';
import sixtyNine from './robloxImages/CASTLE-CONUNDRUM/69.png';
import seventy from './robloxImages/CASTLE-CONUNDRUM/70.png';
import seventyOne from './robloxImages/CASTLE-CONUNDRUM/71.png';
import seventyTwo from './robloxImages/CASTLE-CONUNDRUM/72.png';
import seventyThree from './robloxImages/CASTLE-CONUNDRUM/73.png';
import seventyFour from './robloxImages/CASTLE-CONUNDRUM/74.png';
import seventyFive from './robloxImages/CASTLE-CONUNDRUM/75.png';
import seventySix from './robloxImages/CASTLE-CONUNDRUM/76.png';
import seventySeven from './robloxImages/CASTLE-CONUNDRUM/77.png';
import seventyEight from './robloxImages/CASTLE-CONUNDRUM/78.png';
import seventyNine from './robloxImages/CASTLE-CONUNDRUM/79.png';
import eighty from './robloxImages/CASTLE-CONUNDRUM/80.png';
import eightyOne from './robloxImages/CASTLE-CONUNDRUM/81.png';
import eightyTwo from './robloxImages/CASTLE-CONUNDRUM/82.png';
import eightyThree from './robloxImages/CASTLE-CONUNDRUM/83.png';
import eightyFour from './robloxImages/CASTLE-CONUNDRUM/84.png';
import eightyFive from './robloxImages/CASTLE-CONUNDRUM/85.png';
import eightySix from './robloxImages/CASTLE-CONUNDRUM/86.png';
import eightySeven from './robloxImages/CASTLE-CONUNDRUM/87.png';
import eightyEight from './robloxImages/CASTLE-CONUNDRUM/88.png';
import eightyNine from './robloxImages/CASTLE-CONUNDRUM/89.png';
import ninety from './robloxImages/CASTLE-CONUNDRUM/90.png';
import ninetyOne from './robloxImages/CASTLE-CONUNDRUM/91.png';
import ninetyTwo from './robloxImages/CASTLE-CONUNDRUM/92.png';
import ninetyThree from './robloxImages/CASTLE-CONUNDRUM/93.png';
import ninetyFour from './robloxImages/CASTLE-CONUNDRUM/94.png';
import ninetyFive from './robloxImages/CASTLE-CONUNDRUM/95.png';
import ninetySix from './robloxImages/CASTLE-CONUNDRUM/96.png';
import ninetySeven from './robloxImages/CASTLE-CONUNDRUM/97.png';
import ninetyEight from './robloxImages/CASTLE-CONUNDRUM/98.png';
import ninetyNine from './robloxImages/CASTLE-CONUNDRUM/99.png';
import hundred from './robloxImages/CASTLE-CONUNDRUM/100.png';
import hundredOne from './robloxImages/CASTLE-CONUNDRUM/101.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const Escape = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 103;

  return (
    <div className='robloxGames__container'>
        <h1>
        🏰 Castle Conundrum: Special Escape Room 🚪
        </h1>

        <h3 className='intro'>
        From treacherous dungeons🏰 to fire breathing dragons🔥, this castle is no joke. Craft the castle walls to trap players as they make their way to the very end!🎮
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>



        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Choosing Your Template 🌟</h2>
            <ul>
                <li>
                Open Roblox Studio from your desktop or Start menu🖱️
                </li>
                <li>
                Sign in with your Roblox account or create a new one🔑
                </li>
                <li>
                Click "New" to create a new project and select "Castle" as the template🗺️
                </li>
            </ul>

            <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Find Your Castle Gate 🧐</h2>
            <ul>
                <li>
                Walk up to the castle gate.
                </li>

                <li>
                Notice the portcullis (gate) is currently down. This is where players will enter your castle, so it's essential to know how it looks and operates.
                </li>
            </ul>
            
            <img src={two} className='demo__image' alt='img_two' />
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 3: Access the Explorer Panel 🔍</h2>
            <ul>
                <li>
                Click on the "View" tab at the top of the screen.
                </li>

                <li>
                Ensure the "Explorer" window is open. If not, click on the "Explorer" option to open it. This is where you will see all the game objects.
                </li>
            </ul>

            <img src={three} className='demo__image' alt='img_three' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: Locate the Portcullis in the Explorer 📜</h2>
            <ul>
                <li>
                In the "Explorer" window, look for the "Walls" folder.
                </li>

                <li>
                Open the "Walls" folder.
                </li>
            </ul>

            <img src={four} className='demo__image' alt='img_four' />
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Select the Portcullis for Editing 🛠️</h2>
            <ul>
                <li>
                With the "Walls" folder expanded, click on "Portcullis" to select it.
                </li>

                <li>
                Notice that the portcullis is now highlighted in blue in the game editor view, indicating it is selected.
                </li>

                <li>
                With the portcullis selected, you're ready to delete it as we will be building a different entrance.
                </li>
            </ul>
            <img src={five} className='demo__image' alt='img_five' />
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Removing Obstacles 🚫🚪</h2>
            <ul>
                <li>
                Clear the path for your players.
                </li>

                <li>
                In the "Explorer" panel, locate and select the "Portcullis" within the "Walls" group, then press the 'Delete' key.
                </li>
            </ul>

            <img src={six} className='demo__image' alt='img_six' />
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Introducing a New Part 🧩🚪</h2>
            <ul>
                <li>
                Within Roblox Studio, use the "Part" tool to add a new game element that will function as a door. This object will later become a pivotal challenge in your game, regulating player movement through the castle.
                </li>

                <li>
                The door serves as a gameplay mechanic that controls player progression. By requiring players to complete certain challenges to pass through, you create a sense of achievement and maintain a narrative flow that keeps players engaged and motivated.
                </li>
            </ul>

            <img src={seven} className='demo__image' alt='img_seven' />
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Positioning Your Challenge 📐🎯</h2>
            <ul>
                <li>
                Select the newly created part labeled as "Part" in the "Explorer" window. Use the move tool to position it precisely at the entrance of the castle where the portcullis once was.
                </li>

                <li>
                The placement of this door is crucial as it will be the first significant barrier players encounter. Proper positioning ensures the door is seamlessly integrated into the environment and players can interact with it naturally as part of the game's challenges.
                </li>

                <li>
                    Renaming this part is key, rename the block from "part" to "BigDoor". Ensure that this BigDoor is covering the entrance as seen in the image below.
                </li>
            </ul>
            <img src={eight} className='demo__image' alt='img_eight' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Coloring Your Interactive Challenge 🔴🎨</h2>
            <ul>
                <li>
                With the "BigDoor" part selected in the Explorer window, access the "Color" tool in the toolbar. Choose a vibrant red to color the door. This makes it stand out distinctly against the castle's stonework.
                </li>
                <li>
                The use of color in game design is a powerful tool for guiding player behavior. By coloring the door red, you're signaling its importance and hinting that it's an interactive object. Red can often denote barriers or objectives in games, which will help players identify that this door is key to their progression.
                </li>
            </ul>
            <img src={nine} className='demo__image' alt='img_nine' />
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Accessing Object Properties for Detailed Customization 🛠️</h2>
            <ul>
                <li>
                Prepare for detailed customization of the new door.
                </li>

                <li>
                With the "BigDoor" still selected, open the "Properties" window by clicking on the "Properties" button in the toolbar if it's not already open. This window will display various attributes of the door part that you can modify.
                </li>

                <li>
                The Properties window is where you fine-tune the characteristics of game elements. It allows you to adjust specifics such as transparency, reflectance, or whether the object is anchored or not. This level of customization is essential for ensuring that the door behaves as intended within the game, such as being immovable until the player completes the necessary challenges to unlock it.
                </li>
            </ul>
            <img src={ten} className='demo__image' alt='img_ten' />
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: Adjusting Door Visibility 🚪↔️🔍</h2>
            <ul>
                <li>
                In the "Properties" window, find the "Transparency" field of the "BigDoor" object and set it to a value that makes the door semi-transparent, such as 0.5. This allows players to see that there is something beyond the door, sparking curiosity.
                </li>

                <li>
                The transparency effect serves as a visual clue for players that the door may be interacted with. It can indicate that the door is a barrier that might disappear or become accessible once certain conditions in the game are met, such as completing a puzzle or obtaining a key.
                </li>
            </ul>
            <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Establishing Game Boundaries 🗺️🚧</h2>
            <ul>
                <li>
                Define the playable area within the game.
                </li>

                <li>
                To make these boundaries line the castle border with parts and scale them up so players can not jump over them, and anchor then to ensure that they do not fall over. Use the image below as a reference.
                </li>

                <li>
                Setting boundaries is essential to keep players within the designed game area and to prevent them from wandering off into unfinished parts of the map or out of the game environment, ensuring a cohesive game experience.
                </li>
            </ul>
            <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: Fine-Tuning Boundary Properties 🎚️🏰</h2>
            <ul>
                <li>
                In the "Properties" window, adjust the "Transparency" setting of the boundary parts to make them invisible or give them a subtle visual cue like a faint color.
                </li>

                <li>
                While boundaries are necessary, they don't always have to be visible to the player. Making them transparent maintains immersion by not visually distracting the player, while still keeping them within the designated play area.
                </li>
            </ul>
            <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: Locating the Castle's Doorway 🚪🔍</h2>
            <ul>
                <li>
                Find the doorway within the castle's walls that leads into the inner chambers. This can be found on the side of the castle structure, indicated by a distinct archway.
                </li>
            </ul>
            <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15: Entering the Dining Area 🍖🏰</h2>
            <ul>
                <li>
                Navigate through the selected doorway into the castle to reveal the dining area. This is where players will engage with various elements of the game, so it's important to ensure the space is easily accessible and well-defined.
                </li>

                <li>
                    The dining area serves as a central hub within the castle. It's a space where players can gather, receive quests, or even enjoy a moment of respite from their adventures. By entering this area, you're setting the stage for future interactions and gameplay elements that will be developed in this space, such as puzzles, character interactions, or finding game-critical items.
                </li>
            </ul>
            <img src={fifteen} className='demo__image' alt='img_fifteen' />
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 16: Placing the Blockade 🚧🚪</h2>
            <ul>
                <li>
                In the Roblox Studio, use the "Part" tool to create a block and place it within one of the arches of the dining hall doorway. This part will act as a temporary barrier or a 'forcefield' that prevents players from passing.
                </li>
            </ul>
            <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 17: Making the Blockade Noticeable 🎨👀</h2>
            <ul>
                <li>
                With the blockade selected, choose a bright color like red using the "Color" tool, which will signify that this is an important and interactive object within the game.
                </li>
            </ul>
            <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 18: Setting Up the Forcefield Properties 🌐✨</h2>
            <ul>
                <li>
                Adjust the "Transparency" property of the blockade to make it partially see-through, reflecting its nature as a forcefield rather than a solid wall.
                </li>

                <li>
                Giving the blockade a semi-transparent appearance helps communicate to the player that it's a temporary barrier. It indicates that while they can't pass through just yet, there may be a way to deactivate or remove this forcefield by completing a challenge.
                </li>
            </ul>
            <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 19: Replicating the Forcefield Across All Doorways 🔁🚪</h2>
            <ul>
                <li>
                Uniformly block all doorways in the dining hall to create multiple challenges.
                </li>

                <li>
                Copy the forcefield part you created and place identical blocks in the arches of each of the four doorways present in the dining hall.
                </li>
            </ul>
            <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 20: Replicating the Forcefield Across All Doorways 🔄✨</h2>
            <ul>
                <li>
                Consistency in the properties of the forcefields is key to not confuse players. It reinforces the idea that the same type of challenge or mechanism will deactivate them. Consistent visual cues across all doorways help establish a clear objective for players to work towards in the game. Ensure that there is a force field in all four door ways.
                </li>
            </ul>
            <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 21: Organizing Your Castle's Doors 🛡️</h2>
            <ul>
                <li>
                In your Roblox game workspace, locate the "Explorer" panel on the right.
                </li>
                <li>
                    Add a folder into your Explorer and rename it to "Doors", then put all of the door parts into this folder.
                </li>
            </ul>
            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 22: Starting the Challenge Mechanism 🎮</h2>
            <ul>
                <li>
                Go to the "Home" tab at the top of your screen and look for the "Part" button.
                </li>

                <li>
                This part will serve as one of the "levers". Players are challenged to turn the levers correctly in order to unlock the force field doors we just created.
                </li>
            </ul>
            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 23: Setting Up the Lever 🛠️</h2>
            <ul>
                <li>
                Select the newly created part in the "Explorer" panel, which will act as the lever.
                </li>

                <li>
                Go to the "Properties" panel, where you can change this part's characteristics like size, form, and orientation to make it look like a lever.
                </li>

                <li>
                    This lever will turn from red to green when it is in the correct position, make sure your part is renamed to "lever".
                </li>
            </ul>
            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 24: Organizing the Lever 🛠️</h2>
            <ul>
                <li>
                To organize the lever, create a "Levers" folder that will contain the lever as seen in the image below..
                </li>
            </ul>
            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 25: Enabling Lever Interaction 🖱️</h2>
            <ul>
                <li>
                Now that the lever has been placed and customized, it’s time to make it interactive.
                </li>

                <li>
                In the "Explorer" panel, click on the lever part and add a "ClickDetector" from the insert object menu. This component will detect player clicks.
                </li>

                <li>
                The "ClickDetector" allows the lever to respond to player interactions. You can then script responses to these interactions, such as rotating the lever or triggering an in-game event.
                </li>
            </ul>
            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 26: Scripting the Lever Action 📜</h2>
            <ul>
                <li>
                First, make sure your lever part is selected in the "Explorer" panel.
                </li>

                <li>
                Then, with your lever selected, you'll need to add a script. 
                </li>

                <li>
                The purpose of the script is to define what happens when a player interacts with the lever. It will contain all the instructions needed to make the lever move.
                </li>

                <li>
                Once you have inserted the script, it will appear nested under the lever part in the "Explorer" panel. This indicates that the script is specifically tied to this lever.
                </li>
            </ul>
            <img src={twentySix} className='demo__image' alt='img_twentySix' />
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 27: Initializing the Script Variables 🏗️</h2>
            <ul>
                <li>
                Variables in programming are used to store information that can be referenced and manipulated by the script. They're fundamental in making your code cleaner and more efficient.
                </li>

                <li>
                Open the newly created script by double-clicking it. This will bring up the script editor.
                </li>

                <li>
                At the top of the script, you'll write the line local part = script.Parent. This line creates a variable named part that references the lever part to which the script is attached (script.Parent).
                </li>

                <li>
                script.Parent: This is how you get the parent object of the script, which is the lever part in this case.
                </li>

                <li>
                This initial variable is crucial because it allows you to refer to the lever part throughout the rest of the script without having to repeatedly write script.Parent.
                </li>
            </ul>
            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 28: Accessing the ClickDetector 🕹️</h2>
            <ul>
                <li>
                ClickDetectors are special objects that detect mouse clicks on a part. They're essential for creating interactive gameplay elements that players can click on.
                </li>

                <li>
                This line is searching within the lever part for a child object that is a ClickDetector.
                </li>

                <li>
                By setting this variable, your script can now use clickDetector to access the ClickDetector properties and events, which will be necessary for detecting when a player clicks the lever.
                </li>
            </ul>
            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 29: Storing the Lever's Default Orientation 🔄</h2>
            <ul>
                <li>
                Keeping track of the lever's original orientation is important for tracking what the orientation should be to change the color to green (indicating the lever was correctly turned by the player).
                </li>

                <li>
                Vector3.new(0, 0, 0): This creates a new Vector3 object, which in Roblox is used to represent 3D coordinates or rotations. Here, it's being initialized to 0 for all three axes (x, y, z), assuming the lever starts with no rotation.
                </li>
            </ul>

            <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 30: Setting Up the Click Function 🖱️</h2>
            <ul>
                <li>
                The function you define in this step will be what's called when the player clicks the lever. It's a critical part of making the lever do something in response to a player's action.
                </li>

                <li>
                For now, you're just setting up the framework of the function. Inside this function, you will later add code that defines the lever's behavior when clicked, such as changing its orientation.
                </li>
            </ul>

            <img src={thirty} className='demo__image' alt='img_thirty' />
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 31: Implementing Lever Rotation Logic 🔁</h2>
            <ul>
                <li>
                Add a line inside the onClick function that changes the lever's orientation upon a click: part.Orientation = part.Orientation + Vector3.new(0, 0, 15).
                </li>

                <li>
                part.Orientation refers to the current rotation of the lever in the game world.
                </li>

                <li>
                By adding Vector3.new(0, 0, 15) to the current orientation, you rotate the lever 15 degrees around the Z-axis every time it is clicked. This creates the effect of the lever being pulled.
                </li>

                <li>
                It's essential to use a Vector3 because Roblox uses this data structure to represent 3D rotations and positions. Each component (X, Y, Z) corresponds to an axis in 3D space.
                </li>
            </ul>
            <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 32: Establishing Visual Feedback 🚦</h2>
            <ul>
                <li>
                A new function named updateColor is created to provide visual feedback to the player: it will change the lever's color depending on its orientation.
                </li>

                <li>
                This feedback is crucial in games to give players instant recognition of their actions' effects, enhancing the game experience.
                </li>

                <li>
                Inside the updateColor function, you will write logic to check the lever's orientation and update its color accordingly. For now, the function is empty, but you'll add the conditional logic next.
                </li>
            </ul>
            <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 33: Conditional Color Change 🟥🟩</h2>
            <ul>
                <li>
                You've started to fill in the updateColor function with a conditional statement: if part.Orientation == defaultOrientation then.
                </li>

                <li>
                This if statement checks if the lever's current orientation matches the default orientation (which was previously set to Vector3.new(0, 0, 0)).
                </li>

                <li>
                If the condition is true, it means the lever is in its original position, and you set its color to green using part.BrickColor = BrickColor.new("Bright green").
                </li>

                <li>
                This gives players a visual cue that the lever is in the starting position. If the lever is not in the default orientation, the color change will be handled in the next step.
                </li>
            </ul>
            <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 34: Adding an Alternative Color State 🔴</h2>
            <ul>
                <li>
                Now you've expanded the updateColor function with an else clause: else part.BrickColor = BrickColor.new("Bright red").
                </li>

                <li>
                This part of the conditional statement acts as the alternative action if the if condition is not met (meaning the lever is not in its default orientation).
                </li>

                <li>
                Setting the brick color to red provides immediate feedback that the lever is in an active or changed state, which can signal to the player that something has been activated or needs to be reset.
                </li>
            </ul>

            <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 35: Event Connection and Function Execution 🔄</h2>
            <ul>
                <li>
                Finally, you've connected the onClick function to the click detector by using clickDetector.MouseClick:Connect(onClick). his line of code creates a direct link between clicking the lever and executing the onClick function.
                </li>

                <li>
                Additionally, you will need to ensure that the updateColor function is called within onClick to update the lever's color when it's clicked. This step is the culmination of your scripting efforts, bringing together interaction and visual feedback, which are core components of game mechanics.
                </li>
            </ul>
            <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 36: Writing the Update Loop 🔄🎭</h2>
            <ul>
                <li>
                In your script, establish a loop that will continually check and update the lever's color.
                </li>

                <li>
                Write while wait() do to start a loop that will run indefinitely with a small pause each iteration to avoid overloading the game.
                </li>

                <li>
                Inside the loop, call the updateColor() function with updateColor(). This will ensure that the lever's color is consistently updated based on its orientation.
                </li>

                <li>
                The wait() function pauses the loop, helping to control the frequency of updates. You can specify the number of seconds to wait as an argument if you want to adjust the update rate.
                </li>
            </ul>
            <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 37: Duplicating Levers for Multiple Doors 🚪🚪</h2>
            <ul>
                <li>
                In Roblox Studio, select the lever that you have already scripted.
                </li>

                <li>
                Use the duplicate function (Ctrl+D or right-click and select 'Duplicate') to create a copy of the lever.
                </li>

                <li>
                Position the duplicated levers next to other doors where you want similar functionality. This can be done by dragging them in the workspace or using the move tool for precise placement.
                </li>
            </ul>
            <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 38: Placing Levers in the Game Environment 🎮</h2>
            <ul>
                <li>
                After duplicating, you need to place each lever correctly next to the corresponding doors in your game. 4 Doors = 4 Levers.
                </li>

                <li>
                Use the move tool to position the levers exactly where you want players to interact with them.
                </li>
            </ul>

            <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 39: Adjusting Lever Orientation 🔄🔄</h2>
            <ul>
                <li>
                Select each lever individually and adjust its orientation to match the environment aesthetics and gameplay mechanics. By changing the orientation the users have to turn the levers to get it into the correct orientation.
                </li>

                <li>
                Click on the rotate tool and drag the handles that appear to rotate the lever around the desired axis.
                </li>
            </ul>

            <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 40: Adjusting Lever Orientation 🔄🔄</h2>
            <ul>
                <li>
                Repeat the task in the previous step for all of the levers.
                </li>
            </ul>

            <img src={forty} className='demo__image' alt='img_forty' />
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 41: Implementing a New Script 📝</h2>
            <ul>
                <li>
                Add a script directly into the "Levers" folder, this script will check the orientation of all levers and turn the doors green and open them when the player orients all of the levers correctly.
                </li>
            </ul>

            <img src={fortyOne} className='demo__image' alt='img_fortyOne' />
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 42: Script Renaming 🔄</h2>
            <ul>
                <li>
                The script previously known as "Script" is renamed to "checkLevers". This name change hints at the script's purpose, which is likely to involve checking the levers' states as part of the game's puzzle mechanics.
                </li>
            </ul>

            <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 43: Defining the Parent 🏠</h2>
            <ul>
                <li>
                The script is given a line of code that defines leversFolder as a variable pointing to the script's parent object. This suggests that the script will operate on or access the lever objects within this specific folder.
                </li>
            </ul>

            <img src={fortyThree} className='demo__image' alt='img_fortyThree' />
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 44: Referencing the Doors 🚪</h2>
            <ul>
                <li>
                A new line is introduced, establishing doorsFolder as a variable that holds a reference to the "Doors" folder in the game's workspace. This link is crucial for any code that aims to modify door objects in response to lever interactions (levers being turned correctly so doors being opened).
                </li>
            </ul>

            <img src={fortyFour} className='demo__image' alt='img_fortyFour' />
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 45: Function Creation 🌟</h2>
            <ul>
                <li>
                The beginnings of a function named areAllLeversGreen is written into the script. This function is intended to check whether all levers are in a "green" state, an indication that the levers are in the correct orientation for the game's puzzle challenge.
                </li>
            </ul>

            <img src={fortyFive} className='demo__image' alt='img_fortyFive' />
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 46: Iterating Over Levers 🔄</h2>
            <ul>
                <li>
                In this step, you're creating a loop to iterate through all the lever objects within the leversFolder. This is done using the for loop in combination with the pairs function, which is a standard way to iterate over a table in Lua.
                </li>

                <li>
                The pairs function returns both the key and value of the table, but since the key is not needed, it's conventionally discarded with an underscore _. The lever variable will hold each individual lever instance as the loop iterates.
                </li>

                <li>
                for _, lever in pairs(...) is the loop construct.
                </li>

                <li>
                leversFolder:GetChildren() calls a method to get all child objects of leversFolder.
                </li>

                <li>
                do ... end marks the start and end of the loop body.
                </li>
            </ul>

            <img src={fortySix} className='demo__image' alt='img_fortySix' />
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 47: Conditional Checking 🚦</h2>
            <ul>
                <li>
                Here, a conditional statement is added to the loop's body to check if each lever is a BasePart and if its BrickColor is not "Bright green". The IsA function is used to ensure that the object is of the correct type before attempting to access its BrickColor. This type-checking is crucial to avoid errors when dealing with different object types. The ~= operator is used to compare the BrickColor to the desired value, and if the condition is met, the function returns false, indicating not all levers are green.
                </li>

                <li>
                if ... then ... end is the conditional statement.
                </li>

                <li>
                lever:IsA("BasePart") checks if the lever object is a BasePart.
                </li>

                <li>
                lever.BrickColor ~= BrickColor.new("Bright green") checks if the lever's color is not bright green.
                </li>
            </ul>

            <img src={fortySeven} className='demo__image' alt='img_fortySeven' />
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 48: Completing the Function 🎯</h2>
            <ul>
                <li>
                After the loop, the function areAllLeversGreen concludes by returning true. This line is reached only if none of the levers failed the green check, meaning all levers are indeed green. The function overall is determining whether all the levers in the leversFolder are set to green, which could be part of a game mechanic where the player must position all levers correctly to proceed.
                </li>

                <li>
                return true is the statement used to return a value from the function.
                </li>
            </ul>

            <img src={fortyEight} className='demo__image' alt='img_fortyEight' />
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 49: New Function for Red Levers 🔴</h2>
            <ul>
                <li>
                Another function, isAnyLeverRed, is initiated. This function's name suggests it will serve a similar purpose as the previous function but for checking if any lever is red. This will be used to check for incorrect lever positions in the game.
                </li>
            </ul>

            <img src={fortyNine} className='demo__image' alt='img_fortyNine' />
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 50: Loop Structure for Red Levers 🔍</h2>
            <ul>
                <li>
                The function isAnyLeverRed is set up with a for loop structure identical to the one in areAllLeversGreen. It indicates that a similar iteration will take place, but the purpose here will be to check for red levers instead. The loop's body will include logic to detect if any lever's BrickColor is red.
                </li>
            </ul>

            <img src={fifty} className='demo__image' alt='img_fifty' />
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 51: Checking for Red Levers 🚨</h2>
            <ul>
                <li>
                This step involves finalizing the function isAnyLeverRed(), which is designed to check if any lever within the leversFolder has been set to red. The function iterates over each lever and uses an if statement to check the BrickColor property. If any lever is red, the function immediately returns true, indicating the presence of a red lever.
                </li>

                <li>
                if lever:IsA("BasePart") ensures that the object being checked is indeed a part that can have a BrickColor.
                </li>

                <li>
                lever.BrickColor == BrickColor.new("Bright red") checks if the lever's color is bright red.
                </li>

                <li>
                return true exits the function with a value of true, indicating a red lever is found.
                </li>
            </ul>

            <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 52: Return False if No Red Levers Found 🔴➡️🟢</h2>
            <ul>
                <li>
                Indicate that if no levers are red, the function confirms that all levers are not red by returning false.
                </li>

                <li>
                return false should be used here to indicate that no red levers were found after the loop completes.
                </li>
            </ul>

            <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 53: Defining the Function to Modify Doors 🚪🔧</h2>
            <ul>
                <li>
                In this step, we begin defining a new function, turnDoorsGreen(). This function's purpose will be to iterate over door objects and modify their properties, but at this point, we are only declaring the function without adding any logic inside it.
                </li>

                <li>
                This declaration sets up a named block of code that we can call later in our script when we want to change the doors' properties.
                </li>
            </ul>

            <img src={fiftyThree} className='demo__image' alt='img_fiftyThree' />
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 54: Looping Over Door Objects 🔄</h2>
            <ul>
                <li>
                Now we add a loop inside the turnDoorsGreen function, which will go through each object within the doorsFolder. At this stage, we're setting up the structure to iterate over the doors, but we're not yet specifying what to do with each door object. This loop prepares us to apply some operation to each door in the following steps.
                </li>

                <li>
                for _, door in pairs(doorsFolder:GetChildren()) do begins a loop that will visit each child object of doorsFolder.
                </li>
            </ul>

            <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 55: Implementing Logic Within the Loop 🚪🟢</h2>
            <ul>
                <li>
                Within the loop, we introduce an if statement that checks each door object to see if it's a BasePart, which is a common type for physical objects in game environments that can have properties like color and collision settings. The logic to change the door's color to green and to make it non-collidable (indicating an open or unlocked state) will be included here.
                </li>

                <li>
                    if door:IsA("BasePart") then checks whether the door object is a BasePart before trying to change its properties.
                </li>

                <li>
                door.BrickColor = BrickColor.new("Bright green") sets the color of the door to bright green
                </li>

                <li>
                door.CanCollide = false disables physical collisions for the door, which might be used to signal that the door can be passed through or is in an 'open' state.
                </li>
            </ul>

            <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 56: Creating the 'turnDoorsRed' Function 🚪</h2>
            <ul>
                <li>
                Start by defining a new function called turnDoorsRed. This function will be responsible for changing the color of the doors in your game to red and making them solid so players can't pass through them.
                </li>
            </ul>
        
            <img src={fiftySix} className='demo__image' alt='img_fiftySix' />
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 57: Adding Logic to the turnDoorsRed Function 🔴➡️🚪</h2>
            <ul>
                <li>
                Now, you're filling in the turnDoorsRed function with the code that does the changing door colors!
                </li>

                <li>
                Inside the function, start a loop with for _, door in pairs(doorsFolder:GetChildren()) do. This line loops through each door in the doorsFolder.
                </li>

                <li>
                Add an if statement: if door:IsA("BasePart") then to check if each item is indeed a door.
                </li>

                <li>
                Inside the if, set the door's color to red with door.BrickColor = BrickColor.new("Bright red").
                </li>

                <li>
                Change the door's collision property with door.CanCollide = true so players will bump into it.
                </li>
            </ul>
        
            <img src={fiftySeven} className='demo__image' alt='img_fiftySeven' />
        </div>

        <div style={currentStep == 58 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 58: Introducing a Delay with wait() ⏱️🔄</h2>
            <ul>
                <li>
                This step introduces a bit of timing to your game's logic, which can be used to create delays or timed events.
                </li>

                <li>
                Below your turnDoorsRed function, start a new loop with while wait(1) do. This tells Roblox to wait 1 second before looping again.
                </li>

                <li>
                Close the loop with end. For now, it doesn't do anything else, but later you can add code here that you want to repeat every second.
                </li>
            </ul>

            <img src={fiftyEight} className='demo__image' alt='img_fiftyEight' />
        </div>

        <div style={currentStep == 59 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 59: Adding a Conditional Check to the Loop 🔄✅</h2>
            <ul>
                <li>
                Now, you're enhancing the loop with a condition to check the state of the levers in your game.
                </li>

                <li>
                Inside the while wait(1) do loop, add an if statement: if areAllLeversGreen() then. This checks if a function areAllLeversGreen returns true, meaning all levers are green
                </li>

                <li>
                Why? You're setting conditions for game events. Here, you're saying "if all levers are green, then do something."
                </li>
            </ul>

            <img src={fiftyNine} className='demo__image' alt='img_fiftyNine' />
        </div>

        <div style={currentStep == 60 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 60: Implementing an elseif Condition 🔄🚦</h2>
            <ul>
                <li>
                After the if statement, add an elseif statement: elseif isAnyLeverRed() then. This checks another condition: if any lever in the game is red. You want to respond to different situations in your game. If any lever is red, you'll want to do something different than if they're all green.
                </li>

                <li>
                Inside the elseif, call turnDoorsRed(). This will trigger the turnDoorsRed function, to turn them back into red and blocking the player from passing.
                </li>
            </ul>

            <img src={sixty} className='demo__image' alt='img_sixty' />
        </div>

        <div style={currentStep == 61 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 61: Testing your Levers 🚦</h2>
            <ul>
                <li>
                We need to test the lever and door functionality!
                </li>

                <li>
                    Test the game, if you have followed the previous steps correctly then when you turn all of the levers into the correct orientation, then the levers and doors will turn green. Allowing players to unlock the next part of the castle.
                </li>
            </ul>

            <img src={sixtyOne} className='demo__image' alt='img_sixtyOne' />
        </div>

        <div style={currentStep == 62 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 62: Hide the Key 🔑</h2>
            <ul>
                <li>
                    After the players escape the dinning hall room. Their next objective is to find the key. Now what does that mean for us? We need to create and hide the key!
                </li>

                <li>
                    FInd the key that is in the image below in the "toolbox", make sure you locate the same one in the image below.
                </li>
            </ul>

            <img src={sixtyTwo} className='demo__image' alt='img_sixtyTwo' />
        </div>

        <div style={currentStep == 63 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 63: Making Coins 🪙</h2>
            <ul>
                <li>
                After the user finds the key, they will need to wonder the castle grounds in order to collect all of the coins.
                </li>

                <li>
                    Let's start by making the first coin with a "cylinder" part. Add this part to the workspace.
                </li>
            </ul>

            <img src={sixtyThree} className='demo__image' alt='img_sixtyThree' />
        </div>

        <div style={currentStep == 64 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 64: Coin Color 🟡</h2>
            <ul>
                <li>
                Scale and rotate the cylinder to create a coin as seen below.
                </li>

                <li>
                    Give the coin a fitting color like gold or silver.
                </li>

                <li>
                    In the explorer tab rename the "part" to "Coin".
                </li>
            </ul>

            <img src={sixtyFour} className='demo__image' alt='img_sixtyFour' />
        </div>

        <div style={currentStep == 65 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 65: Coin Material 🟡🪙</h2>
            <ul>
                <li>
                Open the "Material Manager" and give the coin a material of "Foil" to create the metal coin look.
                </li>
            </ul>

            <img src={sixtyFive} className='demo__image' alt='img_sixtyFive' />
        </div>

        <div style={currentStep == 66 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 66: Setting Up Your Coin Script 🪙💻</h2>
            <ul>
                <li>
                Add a script into the coin, because we will be making the coin collectable to give the player points.
                </li>
            </ul>

            <img src={sixtySix} className='demo__image' alt='img_sixtySix' />
        </div>

        <div style={currentStep == 67 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 67: Initiating the Script 🚀</h2>
            <ul>
                <li>
                In the script, type local coin = script.Parent to create a reference to the coin object. This line of code means our script knows which object it’s going to be working with.
                </li>

                <li>
                script.Parent is a way to say "Hey, the thing this script is attached to!" in the Roblox scripting world.
                </li>
            </ul>

            <img src={sixtySeven} className='demo__image' alt='img_sixtySeven' />
        </div>

        <div style={currentStep == 68 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 68: Crafting a Function Skeleton 🛠️📜</h2>
            <ul>
                <li>
                Write function onTouched() and end it with end. This is the skeleton of our function that will run whenever our coin is touched.
                </li>
            </ul>

            <img src={sixtyEight} className='demo__image' alt='img_sixtyEight' />
        </div>

        <div style={currentStep == 69 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 69: Connecting Function to Event 🔗🎈</h2>
            <ul>
                <li>
                Add coin.Touched:Connect(onTouched) below the function. This line tells Roblox to call the onTouched function whenever the coin is touched. It's like setting up a doorbell; when someone presses it (or in this case, touches the coin), something happens! Ding dong! 🛎️
                </li>
            </ul>

            <img src={sixtyNine} className='demo__image' alt='img_sixtyNine' />
        </div>

        <div style={currentStep == 70 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 70: Identifying the Player 👤🕹️</h2>
            <ul>
                <li>
                Inside the onTouched function, add local player = game.Players:GetPlayerFromCharacter(hit.Parent). This finds the player who touched the coin.
                </li>

                <li>
                hit is what we call the object that touched our coin, and hit.Parent is likely the player’s character. 
                </li>
            </ul>

            <img src={seventy} className='demo__image' alt='img_seventy' />
        </div>

        <div style={currentStep == 71 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 71: Accessing Player Stats 🌟</h2>
            <ul>
                <li>
                Write local leaderstats = player:FindFirstChild("leaderstats") inside the onTouched function.
                </li>

                <li>
                This code searches for a child of the player named "leaderstats". If it exists, we can use it to track the player's score or other stats. It's like opening a player's scorecard to note down their points.
                </li>

                <li>
                leaderstats: This is the name we're giving to the variable.
                </li>

                <li>
                player:FindFirstChild("leaderstats"): This attempts to find a child object of player named "leaderstats". The FindFirstChild method returns nil if it doesn't find anything, which is why we check for its existence later.
                </li>
            </ul>

            <img src={seventyOne} className='demo__image' alt='img_seventyOne' />
        </div>

        <div style={currentStep == 72 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 72: Ensuring Points are Tracked 🏆</h2>
            <ul>
                <li>
                This step aims to grab the specific stat for coins. We use FindFirstChild again to look within leaderstats for an object named "Coins", which will track the number of coins a player has collected.
                </li>

                <li>
                leaderstats:FindFirstChild("Coins"): This searches within leaderstats for a child named "Coins", which should be the stat object that holds the player's coin count.
                </li>
            </ul>

            <img src={seventyTwo} className='demo__image' alt='img_seventyTwo' />
        </div>

        <div style={currentStep == 73 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 73: Conditional Checks for Robustness ✅</h2>
            <ul>
                <li>
                Write if points and player then before the rest of your code inside the onTouched function.
                </li>

                <li>
                This line checks if points and player actually exist before proceeding. It prevents errors in case the coin is touched by something that isn't a player or if the player doesn't have a "Coins" object in their leaderstats.
                </li>

                <li>
                This condition checks that both points and player are not nil, indicating that the necessary objects are in place.
                </li>
            </ul>

            <img src={seventyThree} className='demo__image' alt='img_seventyThree' />
        </div>

        <div style={currentStep == 74 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 74: Updating Points and Cleaning Up 🔄🧹</h2>
            <ul>
                <li>
                Inside the if statement, update the points by writing points.Value = points.Value + 1 and then remove the coin with coin:Destroy().
                </li>

                <li>
                When a player touches the coin, we increase their coin count by 1, and then we make the coin disappear by destroying it. It's like adding a point to their scorecard and then taking the coin off the table.
                </li>
            </ul>
            
            <img src={seventyFour} className='demo__image' alt='img_seventyFour' />
        </div>

        <div style={currentStep == 75 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 75: Cloning Coins for More Fun! 🔄</h2>
            <ul>
                <li>
                Use Roblox Studio to duplicate your original coin to create a total of eight coins.
                </li>

                <li>
                By duplicating the coin, we're creating multiple opportunities for players to increase their score. This process involves selecting the original coin in the "Explorer" panel, using the copy and paste functionality, or simply pressing Ctrl+D to duplicate it. 
                </li>

                <li>
                Place these duplicates strategically within your game world so players can find and collect them. It’s like hiding Easter eggs around the yard for an egg hunt — each one is a delightful surprise!
                </li>
            </ul>

            <img src={seventyFive} className='demo__image' alt='img_seventyFive' />
        </div>

        <div style={currentStep == 76 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 76: Hiding the Coins 🏰</h2>
            <ul>
                <li>
                Now take all of the eight coin clones that you created and hide them throughout the castle for players to hunt down.
                </li>
            </ul>

            <img src={seventySix} className='demo__image' alt='img_seventySix' />
        </div>

        <div style={currentStep == 77 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 77: Creating a Leaderstats Script</h2>
            <ul>
                <li>
                Navigate to the ServerScriptService in the "Explorer" panel and insert a new script.
                </li>

                <li>
                ServerScriptService is a container for scripts that run on the server, affecting the game's backend logic. By adding a script here, we're setting up the logic for a leaderboard, which will track and display player statistics, like their score, throughout their session.
                </li>
            </ul>

            <img src={seventySeven} className='demo__image' alt='img_seventySeven' />
        </div>

        <div style={currentStep == 78 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 78: Rename the Script 📊👩‍💻</h2>
            <ul>
                <li>
                Rename the script to "leaderboardScript" to properly label the script.
                </li>
            </ul>

            <img src={seventyEight} className='demo__image' alt='img_seventyEight' />
        </div>

        <div style={currentStep == 79 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 79: Initializing the Leaderboard 📊👩‍💻</h2>
            <ul>
                <li>
                This function will run every time a new player joins the game. Its purpose is to create a new Folder inside the player object that will store the player's individual stats, like their score or coins collected.
                </li>

                <li>
                function onPlayerAdded(player): This is the declaration of a new function that takes one argument, player, which represents the player object.
                </li>

                <li>
                game.Players.PlayerAdded:Connect(onPlayerAdded): This line of code connects the onPlayerAdded function to the PlayerAdded event, which is triggered whenever a new player enters the game.
                </li>
            </ul>

            <img src={seventyNine} className='demo__image' alt='img_seventyNine' />
        </div>

        <div style={currentStep == 80 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 80: Crafting the Leaderstats Folder 🗂️</h2>
            <ul>
                <li>
                Here, we're creating a new Folder instance in the game. This Folder will be used to store individual statistics for each player, such as the number of coins they've collected. Think of it as a personal file for each player's achievements and progress.
                </li>

                <li>
                Instance.new("Folder"): This creates a new instance of a Folder. Instances are basic objects within Roblox, and a Folder is a simple container that can hold other objects.
                </li>
            </ul>

            <img src={eighty} className='demo__image' alt='img_eighty' />
        </div>

        <div style={currentStep == 81 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 81: Naming the Leaderstats Folder 🏷️</h2>
            <ul>
                <li>
                In your onPlayerAdded function, after creating the leaderstats folder, assign the name "leaderstats" to it.
                </li>

                <li>
                Giving the folder the name "leaderstats" is important because Roblox recognizes this specific name for tracking and displaying player statistics in-game, such as scores or currency. It's a convention in Roblox that ensures compatibility and functionality across the platform.
                </li>
                <li>
                leaderstats.Name = "leaderstats": This line assigns the name "leaderstats" to our newly created folder, allowing Roblox to identify it as the container for player stats.
                </li>
            </ul>

            <img src={eightyOne} className='demo__image' alt='img_eightyOne' />
        </div>

        <div style={currentStep == 82 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 82: Parenting Leaderstats to the Player 🧑‍💼➡️📂</h2>
            <ul>
                <li>
                Set the leaderstats folder as a child of the player object by setting its Parent property.
                </li>

                <li>
                Parenting the leaderstats folder to the player object integrates it into the player's hierarchy, ensuring that their statistics are stored and can be retrieved as they interact with the game.
                </li>

                <li>
                leaderstats.Parent = player: This sets the parent of the leaderstats object to the player, effectively linking the player's stats to their profile.
                </li>
            </ul>

            <img src={eightyTwo} className='demo__image' alt='img_eightyTwo' />
        </div>

        <div style={currentStep == 83 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 83: Creating a Points Statistic 🌟</h2>
            <ul>
                <li>
                Inside the onPlayerAdded function, after setting the parent of leaderstats, create an IntValue to keep track of the player's points. This IntValue will be used to store the player's score, such as the number of coins they have collected. It's a dedicated data type in Roblox for holding integer values.
                </li>

                <li>
                local points = Instance.new("IntValue"): Creates a new integer value object that will hold the points or coins collected by the player.
                </li>
            </ul>

            <img src={eightyThree} className='demo__image' alt='img_eightyThree' />
        </div>

        <div style={currentStep == 84 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 84: Setting Up the Points Statistic 📈</h2>
            <ul>
                <li>
                Name the IntValue as "Coins" and initialize it with a value of 0. Also, parent it to the leaderstats folder.
                </li>

                <li>
                Naming the IntValue "Coins" specifies what the statistic represents. Starting at 0 is important as it establishes the baseline for the players to start from when the game begins.
                </li>

                <li>
                points.Name = "Coins": This names the IntValue to "Coins", making it clear that this stat represents the coins collected.
                </li>

                <li>
                points.Value = 0: Sets the default number of coins to 0 at the start of the game.
                </li>

                <li>
                points.Parent = leaderstats: This embeds the points object within the leaderstats folder, making it a statistic that belongs to the player.
                </li>
            </ul>

            <img src={eightyFour} className='demo__image' alt='img_eightyFour' />
        </div>

        <div style={currentStep == 85 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 85: Visual Confirmation of Big Door 🕵️‍♂️</h2>
            <ul>
                <li>
                Remember the "BigDoor" that we made at the very start of this project? Go and find it for the next part of this tutorial.
                </li>

                <li>
                    We will be scripting this "BigDoor" such that it checks to see if the player has completed all of the challenges before allows them to leave the castle (the levers challenge, the key, and the coins).
                </li>
            </ul>

            <img src={eightyFive} className='demo__image' alt='img_eightyFive' />
        </div>

        <div style={currentStep == 86 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 86: Initialize a Script for the BigDoor 🚪💻</h2>
            <ul>
                <li>
                Start by locating the BigDoor object in the Explorer panel and insert a new Script into it.
                </li>

                <li>
                This script will control the door's behavior, such as opening when a player has completed the challenges. By placing the script directly inside the BigDoor object, you ensure that all the code will specifically control this door.
                </li>
            </ul>

            <img src={eightySix} className='demo__image' alt='img_eightySix' />
        </div>

        <div style={currentStep == 87 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 87: Define the Parent Object for the Script 📜👆</h2>
            <ul>
                <li>
                Inside the newly created Script, assign the door itself to a variable using local block = script.Parent.
                </li>

                <li>
                By setting the script's parent to a variable, you're creating a reference to the door object, allowing you to easily manipulate the door within the script.
                </li>
            </ul>

            <img src={eightySeven} className='demo__image' alt='img_eightySeven' />
        </div>

        <div style={currentStep == 88 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 88: Create a Function to Check Items ✅🔍</h2>
            <ul>
                <li>
                Write a function hasRequiredItems(player) that will check if the player has the necessary items to interact with the door. This function is crucial for game logic. It will determine if the player meets the conditions to open the door, such as having a specific number of coins.
                </li>
            </ul>

            <img src={eightyEight} className='demo__image' alt='img_eightyEight' />
        </div>

        <div style={currentStep == 89 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 89: Accessing Leaderstats Within the Function 📊🔓</h2>
            <ul>
                <li>
                Within the hasRequiredItems function, access the player's leaderstats by finding it as a child of the player object.
                </li>

                <li>
                leaderstats is where you're tracking player stats. Accessing it allows you to check the player's current stats, like the number of coins they have.
                </li>
            </ul>

            <img src={eightyNine} className='demo__image' alt='img_eightyNine' />
        </div>

        <div style={currentStep == 90 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 90: Accessing the Coins Variable 🪙</h2>
            <ul>
                <li>
                Inside the function, find the Coins IntValue within leaderstats to access the player's coin count.
                </li>

                <li>
                This is where you're checking the actual value of coins the player has collected. You will use this value to decide if the player can open the door or needs to collect more coins.
                </li>
            </ul>

            <img src={ninety} className='demo__image' alt='img_ninety' />
        </div>

        <div style={currentStep == 91 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 91: Initializing the Has Key Variable 🗝️</h2>
            <ul>
                <li>
                Type in local hasKey = false inside your function. This line of code creates a local variable named hasKey and sets it to false. This means that by default, players do not have the key. In the code we will later check if the player has the key and if they do switch this value to true.
                </li>
            </ul>

            <img src={ninetyOne} className='demo__image' alt='img_ninetyOne' />
        </div>

        <div style={currentStep == 92 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 92: Validating Coins 🪙</h2>
            <ul>
                <li>
                Now, let's make sure the player has enough coins to proceed. 
                </li>

                <li>
                This snippet checks if the coins variable exists and whether its value is less than 8. If either condition is true, the function returns false, meaning the player doesn't have the required items, as the coin value should be greater or equal to than 8 not less than.
                </li>
            </ul>
            
            <img src={ninetyTwo} className='demo__image' alt='img_ninetyTwo' />
        </div>

        <div style={currentStep == 93 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 93: Looping Through the Backpack 🎒</h2>
            <ul>
                <li>
                Next up, we're going to look through the player's backpack for certain items. Notice the loop introduced in the screenshot.
                </li>

                <li>
                With this loop, we go through each item in the player’s backpack. The underscore _ is a Lua convention for a variable that we don't plan to use.
                </li>
            </ul>

            <img src={ninetyThree} className='demo__image' alt='img_ninetyThree' />
        </div>

        <div style={currentStep == 94 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 94: Checking for the Key 🔑</h2>
            <ul>
                <li>
                In this step, we're adding logic to our loop to check each item in the player's backpack to see if it's the key we're looking for.
                </li>

                <li>
                Inside the loop we wrote in the previous steps, add an if statement to check if the item is a 'Tool' and if its name is 'Key':
                </li>

                <li>
                item:IsA("Tool") checks if the item is of the type 'Tool'. This is important because we want to ensure that we're checking actual items that the player can use, not other types of objects.
                </li>

                <li>
                item.Name == "Key" is checking if the name of the item is exactly "Key".
                </li>

                <li>
                hasKey = true sets our variable hasKey to true, indicating that the player does indeed have the key.
                </li>

                <li>
                break stops the loop immediately since we don't need to check further if we've found the key.
                </li>
            </ul>

            <img src={ninetyFour} className='demo__image' alt='img_ninetyFour' />
        </div>

        <div style={currentStep == 95 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 95: Returning the Result 🔄</h2>
            <ul>
                <li>
                After we've checked all items in the player’s backpack, we need to return the result of our hasKey variable.
                </li>

                <li>
                This line returns the value of hasKey from our function. If the player has the key, hasKey would be true, and false if not. This return statement is essential for the function to communicate with other parts of the script about the player's status regarding the key possession.
                </li>
            </ul>

            <img src={ninetyFive} className='demo__image' alt='img_ninetyFive' />
        </div>

        <div style={currentStep == 96 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 96: Starting an Event Handler 👐</h2>
            <ul>
                <li>
                Now, we're beginning a new function that will handle an event. Specifically, this function will run whenever a specific in-game object is touched.
                </li>

                <li>
                Create a new function onTouched with a parameter hit.
                </li>
            </ul>

            <img src={ninetySix} className='demo__image' alt='img_ninetySix' />
        </div>

        <div style={currentStep == 97 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 97: Identifying the Player 🕵️</h2>
            <ul>
                <li>
                Within the onTouched function, we aim to find out which player touched the object.
                </li>

                <li>
                game.Players:GetPlayerFromCharacter(hit.Parent) is a method provided by Roblox that retrieves the player object from the character that touched the object, which is typically the parent of the hit object.
                </li>
            </ul>

            <img src={ninetySeven} className='demo__image' alt='img_ninetySeven' />
        </div>

        <div style={currentStep == 98 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 98: Validating the Touch 🚫</h2>
            <ul>
                <li>
                We need to ensure that the hit event actually involves a player character, not just any object in the game.
                </li>

                <li>
                This line uses a common Lua pattern to exit the function early if player is nil (which in Lua means false or "no value").
                </li>

                <li>
                if not player then return end checks if player is not true (or in this context, checks if player doesn't exist), and if so, it returns immediately, stopping the execution of the function. This is used to prevent the rest of the code from running if the function was triggered by something other than a player character.
                </li>
            </ul>

            <img src={ninetyEight} className='demo__image' alt='img_ninetyEight' />
        </div>

        <div style={currentStep == 99 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 99: Adding Feedback with Block Color Change 🚦</h2>
            <ul>
                <li>
                When a player interacts with objects in a game, providing immediate and clear feedback is essential. It improves the game's usability and makes it more engaging. In this step, you will be modifying the color of a block to provide visual feedback to the player.
                </li>

                <li>
                Inside the onTouched function, use an if statement to call the hasRequiredItems function with the player object as its argument.
                </li>

                <li>
                if hasRequiredItems(player) then: This checks if the player has the required items by calling the hasRequiredItems function we created earlier.
                </li>

                <li>
                block.BrickColor = BrickColor.new("Bright green"): If the player has the required items, the block's color changes to bright green. BrickColor.new() is a constructor that creates a new BrickColor object based on the color name given as a string.
                </li>

                <li>
                block.CanCollide = false: This makes the block non-collidable, meaning the player can pass through it if they have the right items.
                </li>

                <li>
                The else clause is executed if the player doesn't have the required items. The block turns bright red, and block.CanCollide = true keeps the block solid so the player can't pass through.
                </li>

                <li>
                This use of color and collision properties provides intuitive feedback. A green block signifies success, while a red block indicates that something is missing or incorrect.
                </li>
            </ul>

            <img src={ninetyNine} className='demo__image' alt='img_ninetyNine' />
        </div>

        <div style={currentStep == 100 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 100: Connecting the Function to the Block 🖇️</h2>
            <ul>
                <li>
                Now that we have our onTouched function set up to give feedback to the player based on their items, we need to connect this function to the actual block in the game. This is where Roblox's event handling comes into play, allowing us to define behavior in response to game events, such as a player's touch.
                </li>

                <li>
                block.Touched:Connect(onTouched): This line creates a connection between the Touched event of the block object and the onTouched function. Whenever the block is touched by any part in the game, the onTouched function will be called with the touching part as the hit parameter.
                </li>

                <li>
                It's important to note that this line should be placed outside of the onTouched function, typically at the end of the script, to ensure that the function is connected after it has been fully defined.
                </li>
            </ul>

            <img src={hundred} className='demo__image' alt='img_oneHundred' />
        </div>

        <div style={currentStep == 101 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 101: Finishing Up 🏁</h2>
            <ul>
                <li>
                Now that we have built and scripted all of the challenges, items, and blockades. Have fun testing your new project and make sure that the players can "escape" the castle when they complete all of the challenges and collect all of the required relics!
                </li>

                <li>
                Keep in mind to save your work and test the game after each step to ensure that everything is working as expected. It’s always exciting to see your code come to life in the game! 🌟🎮
                </li>
            </ul>

            <img src={hundredOne} className='demo__image' alt='img_oneHundredOne' />
        </div>


        <div style={currentStep == 102 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 102: Testing and Publishing Your Game🔬🌐
            </h2>
            <h2>
            Testing Your Game Locally🕹️
            </h2>
            <ul>
                <li>
                In Roblox Studio, click on the "Home" tab at the top
                </li>
                <li>
                Click the "Play" button to test your game locally
                </li>
                <li>
                You'll spawn on the starting platform and can play through your game, ensuring that each feature works as intended
                </li>
                <li>
                If you find any issues, pause the game by clicking the "Stop" button, then make the necessary adjustments in your game and test again.
                </li>
                <li>
                Testing your game locally helps identify any potential problems or bugs before sharing it with the public. This ensures a smooth gaming experience for everyone!👩‍💻🎮
                </li>
            </ul>
        </div>

        <div style={currentStep == 103 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 103: Publishing Your Game to Roblox🚀
            </h2>
            <ul>
                <li>
                Once you're satisfied with your project, click on the "File" tab in the top left corner of Roblox Studio
                </li>
                <li>
                Select "Publish to Roblox" and choose "Create New Game" (or update an existing game if you've published it before)
                </li>
                <li>
                Fill in the required information, such as game name, description, and genre
                </li>
                <li>
                Set the game's thumbnail and icon to visually represent your game (optional)
                </li>
                <li>
                Click the "Publish" button to make your game live on the Roblox platform
                </li>
                <li>
                When you publish your game, you're sharing it with the entire Roblox community! This is your chance to showcase your creativity and see other players enjoy your adventure.🌟🌍
                </li>
            </ul>
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>


    </div>
  )
}

export default Escape