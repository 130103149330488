import React, {useState} from 'react';
import one from './fullstackImages/dartFrog/1.png';
import two from './fullstackImages/dartFrog/2.png';
import three from './fullstackImages/dartFrog/3.png';
import four from './fullstackImages/dartFrog/4.png';
import five from './fullstackImages/dartFrog/5.png';
import six from './fullstackImages/dartFrog/6.png';
import seven from './fullstackImages/dartFrog/7.png';
import eight from './fullstackImages/dartFrog/8.png';
import nine from './fullstackImages/dartFrog/9.png';
import ten from './fullstackImages/dartFrog/10.png';
import eleven from './fullstackImages/dartFrog/11.png';
import twelve from './fullstackImages/dartFrog/12.png';
import thirteen from './fullstackImages/dartFrog/13.png';
import fourteen from './fullstackImages/dartFrog/14.png';
import fifteen from './fullstackImages/dartFrog/15.png';
import sixteen from './fullstackImages/dartFrog/16.png';
import seventeen from './fullstackImages/dartFrog/17.png';
import eighteen from './fullstackImages/dartFrog/18.png';
import nineteen from './fullstackImages/dartFrog/19.png';
import twenty from './fullstackImages/dartFrog/20.png';
import twentyOne from './fullstackImages/dartFrog/21.png';
import twentyTwo from './fullstackImages/dartFrog/22.png';
import twentyThree from './fullstackImages/dartFrog/23.png';
import twentyFour from './fullstackImages/dartFrog/24.png';
import twentyFive from './fullstackImages/dartFrog/25.png';
import twentySix from './fullstackImages/dartFrog/26.png';
import twentySeven from './fullstackImages/dartFrog/27.png';
import twentyEight from './fullstackImages/dartFrog/28.png';
import twentyNine from './fullstackImages/dartFrog/28.png';
import thirty from './fullstackImages/dartFrog/30.png';
import thirtyOne from './fullstackImages/dartFrog/31.png';
import thirtyTwo from './fullstackImages/dartFrog/32.png';
import thirtyThree from './fullstackImages/dartFrog/33.png';
import thirtyFour from './fullstackImages/dartFrog/34.png';
import thirtyFive from './fullstackImages/dartFrog/35.png';
import thirtySix from './fullstackImages/dartFrog/36.png';
import thirtySeven from './fullstackImages/dartFrog/37.png';
import thirtyEight from './fullstackImages/dartFrog/38.png';
import thirtyNine from './fullstackImages/dartFrog/39.png';
import forty from './fullstackImages/dartFrog/40.png';
import fortyOne from './fullstackImages/dartFrog/41.png';
import fortyTwo from './fullstackImages/dartFrog/42.png';
import fortyThree from './fullstackImages/dartFrog/43.png';
import fortyFour from './fullstackImages/dartFrog/44.png';
import fortyFive from './fullstackImages/dartFrog/45.png';
import fortySix from './fullstackImages/dartFrog/46.png';
import fortySeven from './fullstackImages/dartFrog/47.png';
import fortyEight from './fullstackImages/dartFrog/48.png';
import fortyNine from './fullstackImages/dartFrog/49.png';
import fifty from './fullstackImages/dartFrog/50.png';
import fiftyOne from './fullstackImages/dartFrog/51.png';
import fiftyTwo from './fullstackImages/dartFrog/52.png';
import fiftyThree from './fullstackImages/dartFrog/53.png';
import fiftyFour from './fullstackImages/dartFrog/54.png';
import fiftyFive from './fullstackImages/dartFrog/55.png';
import CopyBox from './copyBox/copybox';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const Frog = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 54;

  return (
    <div className='development__container'>
        <h1>
        🐸 Introduction to CSS: The Poison Dart Frog 🐸
        </h1>

        <h3 className='intro'>
            Ever wondered how some websites look so pretty? Or how some have amazing colors? Well, all thanks to CSS we can style our html so that it has different shapes, colors, and sizes to create a beautiful website... much like a painting! In this tutorial we will be making a colorful and stunning website about the Poison Dart Frog!
        </h3>



        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>

        
        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Starting Your Coding Adventure! 🎮</h2>

            <p>
            Hey there, future coding stars! 🌟 Let's kick off our coding quest by creating a new world for our colorful friends, the Poison Dart Frogs! 🐸
            </p>
            
            <img src={one} className='demo__image' alt='img_one' />

            <h3>Step Content:</h3>

            <ol>
                <li>Open Visual Studio Code, your tool for weaving the web! ✨</li>

                <li>Create a new folder to contain your project, the one in the image above is named DART-FROG – this is going to be the cozy home for all your cool coding creations.</li>

                <li>
                Inside the DART-FROG folder, create a new file by clicking the new file icon or right-clicking and selecting 'New File'. Name this mystical scroll index.html. This powerful parchment is where we'll create our code to bring our Poison Dart Frog world to life!
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>Folders & Files: Just like in a treasure chest, folders help us organize our loot! Files are the precious gems we create and keep in these folders.</li>

                <li>HTML: This is the secret language of the web that helps us create amazing internet adventures.</li>
            </ul>
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Your Images! 🖼️</h2>
            <p>
            We need to gather our project ingredients, which are our pictures for the potion dart frogs!
            </p>
            <img src={two} className='demo__image' alt='img_two' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Right-click on the project folder and select 'New Folder'. Name this folder images – it's like a photo album for our froggy friends.
                </li>

                <li>
                Now, it's time to fill it up! 📸 We will download the images in the next step ahead!
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Organizing Resources: It’s important to keep our images in a special folder to stay neat and tidy, like a well-kept recipe book.
                </li>
            </ul>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 3: All Frogs on Deck! 🐸⚓</h2>

            <p>
            Ahoy, coding captains! It's time to ensure all our froggy crew members are on board! 🚢
            </p>

            <img src={three} className='demo__image' alt='img_three' />

            <h3>Step Content:</h3>

            <ol>
                <li>Use the buttons below to download all of the fun frog pictures and save them into the "images" folder.</li>

                <li>Make sure each of our Poison Dart Frogs is here: bluefrog.png, curiousfrog.png, frogmap.png, redfrog.png, and yellowfrog.png. Count them like stars in the sky! 🌌</li>
            
                <li>Great job! With all these pictures, our web page will be more colorful than a rainbow! 🌈</li>
            </ol>



            {/*<div className='download__buttons__container'>
                <Download src={blueFrog} filename="bluefrog.png" />

                <Download src={curiousFrog} filename="curiousfrog.png" />

                <Download src={frogMap} filename="frogmap.png" />

                <Download src={redFrog} filename="redfrog.png" />

                <Download src={yellowFrog} filename="yellowfrog.png" />
            </div>*/}

            <h3>Concepts:</h3>

            <ul>
                <li>
                Image Files: These files are like windows into another world, showing us all the beauty of our dart frogs.
                </li>

                <li>
                File Naming: Like naming a pet, giving files clear names helps us know which frog is which, without having to peek inside!
                </li>
            </ul>
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: Carving Out Our Jungle Home 🌳🌿</h2>

            <p>
            Get ready, explorers! It's time to carve out the foundation of our jungle where our Poison Dart Frogs will leap and play. 🐸 
            </p>
            
            <img src={four} className='demo__image' alt='img_four' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Start by declaring &lt;!DOCTYPE html&gt; at the top of your index.html file. This is like telling your computer, "Hey! We're about to speak in the language of the web jungle, called HTML!" 📣
                </li>

                <li>
                Next, we use the &lt;html lang="en"&gt; tag. This tag is like the roots of a tree, supporting everything else that grows. The lang="en" part is like telling all the animals in the jungle that we're speaking in English, so everyone understands. 🌐
                </li>

                <li>
                And don't forget the closing &lt;/html&gt; tag at the end! It's like saying, "Okay, the story of our jungle ends here." It wraps up all the content we'll put inside it, much like the canopy of the rainforest shelters all the creatures living below. 🌳
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Doctype Declaration: Just like every adventure has a beginning, every HTML document must start with &lt;!DOCTYPE html&gt; to let the browser know which version of HTML we're using.
                </li>

                <li>
                HTML Tags: These tags are like the gates to our jungle. The opening &lt;html&gt; tag is where the journey begins, and the closing &lt;/html&gt; tag is where our adventure is contained.
                </li>

                <li>
                Language Attribute: Using lang="en" is essential for our jungle tourists who speak different languages; it helps web browsers and assistive technologies provide the right experience.
                </li>
            </ul>
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Setting Up Camp 🏕️🛠️</h2>

            <p>
            Bravo, trailblazers! Now let's set up camp in our jungle by adding a place to hold important information about our web habitat.
            </p>

            <img src={five} className='demo__image' alt='img_five' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Just below the opening &lt;html&gt; tag, type in the &lt;head&gt; tag. This is like setting up the main tent at our campsite. It doesn't hold our frogs yet, but it stores important tools and information like maps and guides for our jungle explorers. 🏞️
                </li>

                <li>
                Remember to close the &lt;head&gt; section with a &lt;/head&gt; tag. This tells everyone, "Our campsite setup is complete, and it's time to explore the jungle!" It's like zipping up our tent to keep all the important stuff safe while we go on a day's adventure. 🎒
                </li>

                <li>
                Right now, our &lt;head&gt; is like an empty tent, but soon we'll fill it with fascinating things like the title of our website that make our site look good to the creatures of the web forest.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Head Element: The &lt;head&gt; element in an HTML document is like a camp's command center. It's where we put things like the title of the page, links to our styling (CSS) to dress up our content, and other metadata that doesn't appear directly in the jungle clearing but is super important for the explorers who come to visit.
                </li>

                <li>
                Closing Tags: Just like how every tool has its place in a backpack, every opening tag in HTML should have a closing tag to tell the browser where that section ends.
                </li>
            </ul>

            <p>
            Keep up the great work, jungle friends! With each step, our digital jungle comes more alive, ready to host the colorful world of our Poison Dart Frogs. Stay curious and keep exploring, and soon we'll have a web page as lively as a rainforest chorus at dusk! 🌄🎶
            </p>
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Marking the Territory with Metadata 🗺️📍</h2>

            <p>
            Young explorers of the web forest, gather 'round! It's time to mark our territory with some secret signs and signals that only the wise web browsers and animals of the digital forest understand. 🌐✨
            </p>
            
            <img src={six} className='demo__image' alt='img_six' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                First, we place a &lt;meta charset="UTF-8"&gt; tag in our document's head. This is like a translator that helps all the different animals and browsers speak the same language, ensuring our froggy facts show up correctly for everyone. 🤝
                </li>

                <li>
                Next up, let's put in a &lt;meta name="viewport" content="width=device-width, initial-scale=1.0"&gt;. This magical incantation makes sure our jungle view looks great on everything from a tiny frog-sized phone to a big desktop tree trunk. 📱💻
                </li>

                <li>
                Lastly, we add a &lt;title&gt; tag – this is like naming our territory. It's the name that will appear on the web browser tab, like a flag planted at the top of our web treehouse. Our title will be "The Poison Dart Frog," so every digital adventurer knows what fantastic creatures they're about to meet. 🏷️🐸
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>Metadata: These &lt;meta&gt; tags are like the invisible tracks and traces that guide web travelers through the digital jungle.</li>

                <li>Character Set: The charset UTF-8 is the most widely used encoding and includes almost all characters from all languages – a true Rosetta Stone of the web.</li>

                <li>Viewport: It adjusts the website's scale on different devices, like a pair of binoculars with zoom in and out to get the best view of the jungle's beauty.</li>
            </ul>
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Fashioning Our Jungle Look with CSS 🎨🌲</h2>

            <p>
            Alright, little chameleons, now it's time to fashion our jungle with some style – literally! We're going to create a style.css file which is like the palette of colors and textures we'll use to paint our jungle's look and feel. 🖌️🌈
            </p>
            
            <img src={seven} className='demo__image' alt='img_seven' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                In your project folder, create a new file and call it style.css. This is like your toolbox of colors, patterns, and materials to dress up our jungle web page.
                </li>

                <li>
                Right now, your style.css file is like a blank canvas, but don't worry – we're going to fill it with all sorts of stylish rules that will make our jungle pop with life! 🎨🍃
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                CSS File: This file is like the wardrobe of our web jungle, where we pick out outfits for our HTML elements to make them look snazzy. CSS is the web language all dedicated to styling HTML.
                </li>

                <li>
                Stylesheets: Think of it like a set of instructions that tell the browser how to dress up our content, from the color of the frog's skin to the size of the leaves on the trees.
                </li>
            </ul>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Linking Our CSS to the HTML 🌐🔗</h2>

            <p>
            It's time to link our jungle look (CSS) with our jungle map (HTML)! We are doing this so that the HTML file can access the CSS file that will color and style it.
            </p>
            
            <img src={eight} className='demo__image' alt='img_eight' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Inside the &lt;head&gt; of your index.html, add a line &lt;link href="./style.css" rel="stylesheet" /&gt;. This is the bridge that connects our HTML content with the CSS decorations. It's like telling your browser, "Hey, the instructions for how to make our website look amazing are right over here!"
                </li>

                <li>
                Once you've added this link, your browser will know to fetch your style.css file and use it to start painting the leaves, coloring the frogs, and setting up the scenery just the way we want. 🌴🎨
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Linking CSS: By linking our CSS file, we're providing a path for the browser to follow, so it knows how to display our web page's elements correctly.
                </li>

                <li>
                Stylesheet Relation: The rel="stylesheet" attribute is like a sign that says, "Follow this path to find the style guide for our jungle." It tells the browser that the linked file is a stylesheet that describes the page's presentation.
                </li>
            </ul>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Creating the Body of Our Jungle 🌴🌺</h2>

            <p>
            Adventurers, now we dive into the heart of the jungle – the &lt;body&gt; of our HTML document! This is where all the action happens: the trees, the rivers, and of course, our stunning Poison Dart Frogs. In other words... this is where the content that appears on the website will be created! 🐸🌳
            </p>

            <img src={nine} className='demo__image' alt='img_nine' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Just after our head section (which was like our jungle's control center), we open up the &lt;body&gt;. This tag is like stepping into the lush greenery of our forest. It will contain all the content that our visitors will see and interact with.
                </li>

                <li>
                Right now, it's like an open clearing in the forest, ready to be filled with life and color!
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Body Tag: The &lt;body&gt; tag is where all our visible content goes – think of it as the stage where our jungle play is performed.
                </li>

                <li>
                Content Area: Everything you place between the opening &lt;body&gt; and closing &lt;/body&gt; tags will be what's displayed in the browser window, like the flora and fauna that make up our jungle's ecosystem.
                </li>
            </ul>
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Building the Main Content Area 🛠️🌿</h2>

            <p>
            Time to build our jungle's main gathering space – the &lt;div&gt; with a class of "main-content". This is like the central part of our jungle where all the exciting things will happen. 🎉🍃
            </p>
            <img src={ten} className='demo__image' alt='img_ten' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                We use a &lt;div&gt; tag, which stands for 'division', to create a section or a container. Our "main-content" div is like the main clearing in the jungle where all our visitors will gather.
                </li>

                <li>
                By giving it a class of "main-content", we can later style this area to stand out. Styling this &lt;div&gt; will come later, in the CSS file.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Divisions (div): These are like invisible fences that help us organize different parts of our jungle.
                </li>

                <li>
                Classes: Classes are like labels that we put on different sections so we can identify them later. If we want to change how a section looks, we just look for its label! 
                </li>
            </ul>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: Introducing Our Jungle's Headline 📰🌺</h2>

            <p>
            Gather 'round, fellow jungle journalists, as we craft the headline of our jungle story! The &lt;h1&gt; tag we're adding is the big, bold title at the top of our page. 🗞️🔖
            </p>
            <img src={eleven} className='demo__image' alt='img_eleven' />

            <h3>Step Content:</h3>
            <ol>
                <li>
                The &lt;h1&gt; stands for 'Heading 1', which is the most important heading in our jungle newspaper. It's the first thing visitors will see, like a towering tree that stands above the rest.
                </li>

                <li>
                Inside our &lt;h1&gt; tags, we write "All About Poison Dart Frogs", inviting everyone to learn about these fascinating creatures. It's like the sign that hangs above our jungle's information center.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Headings: Headings are like signs in our jungle; they tell visitors what each area is about. &lt;h1&gt; is the most significant heading – it's the king of the jungle in our web safari.
                </li>

                <li>
                Hierarchy: Just like in the animal kingdom, there's a hierarchy in our headings too. &lt;h1&gt; is the top of the food chain, and it should be used for the main title of our page.
                </li>
            </ul>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Crafting the Subheadings of Our Jungle 🌿📝</h2>

            <p>
            It's time to map out the trails in our jungle – these are the subheadings that guide our curious explorers to more discoveries. 🗺️🌴
            </p>
            
            <img src={twelve} className='demo__image' alt='img_twelve' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Beneath our big, bold &lt;h1&gt; title, we add an &lt;h2&gt; tag for our first trail marker, titled "Introduction". It's like a smaller signpost pointing to a fascinating area of our jungle where explorers can learn the basics about our amazing Poison Dart Frogs.
                </li>

                <li>
                This &lt;h2&gt; tag is like the smaller sibling of &lt;h1&gt;. It's important but knows to let the main title stand out. We'll use more &lt;h2&gt; tags later to introduce new areas like "Habitat", "Diet", and "Fun Facts". 🌟
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Subheadings (h2): These are the navigational cues in our content, breaking up the page into sections that are easy to explore.
                </li>

                <li>
                Content Structure: Just like a well-laid out trail, a well-structured page helps visitors find their way around and enjoy their journey.
                </li>
            </ul>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: Adding a Glimpse of Our Colorful Creatures 🐸🖼️</h2>

            <p>
            What's a jungle without a glimpse of its star inhabitants? Let's add a splash of color with an image of one of our most vibrant residents! 🎨🐸
            </p>
            
            <img src={thirteen} className='demo__image' alt='img_thirteen' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Right below our "Introduction" signpost (&lt;h2&gt;), we place an &lt;img/&gt; tag. This isn't just any image; it's a window into the world of our blue poison dart frog, the azure jewel of our jungle.
                </li>

                <li>
                The src attribute is the path to our image, much like the coordinates to a hidden treasure. In our case, it's "./images/bluefrog.png", leading directly to the photo we stored in our jungle's image folder.
                </li>

                <li>
                The alt attribute is a description of the image, which is not only helpful to explorers who might not be able to see the image but also to the creatures of the web who crawl our site (like search engines) to understand what's displayed. It reads, "Picture of a Poison Dart Frog", giving everyone a hint of the beauty it represents.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Image Tag (img): This is the snapshot of our jungle, showcasing its inhabitants and beauties. It brings our content to life.
                </li>

                <li>
                Source (src) Attribute: This tells the browser where to find the image we're displaying.
                </li>
            </ul>

            <p>
            By including both the sights (with our images) and the signposts (with our headings), we're making our digital jungle a welcoming and easy-to-navigate place for all who wish to venture into its depths. Keep trekking through the coding underbrush, and you'll soon have a website as lively and lush as the rainforest itself! 🌱🔍
            </p>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: Narrating the Jungle Story 📖🍃</h2>

            <p>
            Now let’s weave the story of our jungle by adding some fascinating frog facts! 📖🐸
            </p>
            <img src={fourteen} className='demo__image' alt='img_fourteen' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Inside our "main-content" jungle clearing, we'll lay down a cozy layer of information with a paragraph tag &lt;p&gt;. This is like a storybook opening right in the heart of our forest, inviting everyone to learn about the intriguing world of Poison Dart Frogs.
                </li>

                <li>
                This &lt;p&gt; tag holds our narrative, giving a snapshot of these vibrant creatures – their vivid colors, their home across Central and South America, and their mysterious toxic secretions. It's the whispering leaves telling tales of the jungle. 🌿
                </li>

                <li>
                    After creating the &lt;p&gt; tag you can either write your own passage or copy and paste the text in the box below into the &lt;p&gt; tag.
                </li>
            </ol>

            <CopyBox 
                text={"Poison dart frogs are a group of brightly colored frogs that are native to Central and South America. They are known for their toxic secretions and the use of their poison for hunting by indigenous tribes."}
            />

            <h3>Concepts:</h3>

            <ul>
                <li>
                Paragraphs (p): These are the building blocks of our jungle story, each one a stepping stone deeper into the habitat of our frogs.
                </li>

                <li>
                Narrative Content: It provides context and information, making our jungle not just a place to visit but a story to be told and discovered.
                </li>
            </ul>
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15: Organizing the Jungle Information 🌳🔍</h2>

            <p>
            Time to section off parts of our jungle for different displays of information! 📚🐾
            </p>
            <img src={fifteen} className='demo__image' alt='img_fifteen' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Under our introduction, we'll use another &lt;div&gt; tag, this time with a class of "info-section". Think of it like creating separate exhibits in our jungle museum – each one dedicated to showcasing different aspects of our dart frogs' lives.
                </li>

                <li>
                For now, this section is like an empty enclosure, but soon we'll fill it with interactive displays.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Divisions (div): They're used to organize our content into logical sections, like different habitats within our vast jungle.
                </li>

                <li>
                Classes for Styling: The "info-section" class will help us style these sections differently if we want to, just like how different parts of the jungle have their own unique feel.
                </li>
            </ul>
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 16: Mapping the Journey 🗺️</h2>

            <p>
            Explorers, ready your compasses! We're about to chart the territories where our elusive Poison Dart Frogs roam. 🧭🐸
            </p>
            <img src={sixteen} className='demo__image' alt='img_sixteen' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Inside our "info-section" clearing, we place an &lt;h2&gt; tag marked "Distribution". 
                </li>

                <li>
                This subheading will introduce a new area of exploration, where we will later unveil a map that traces the frogs' homes across the lush landscapes of Central and South America. It’s like preparing the ground for a grand map of our amphibian friends' homeland.
                </li>
            </ol>
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 17: Unveiling the Frog Territories 🌳📍</h2>
            
            <p>
            Now we unveil the grand map of our Poison Dart Frog territories! 🗺️🐸
            </p>
            <img src={seventeen} className='demo__image' alt='img_seventeen' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Directly below our "Distribution" heading, we insert an &lt;img/&gt; tag with the source pointing to our "frogmap.png". This map is a treasure, showing the wide range of our froggy friends across continents.
                </li>

                <li>
                The class="distribution-map" is like a flag that marks this image as important - our map will get special styling to stand out when we style it in the CSS file as the centerpiece of this section.
                </li>

                <li>
                With the alt="Distribution map of poison dart frogs", even explorers who can't see the image will know what treasure it holds – a key to understanding the frogs' far-reaching homes.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Image with a Class: Adding a class to our image allows us to style it specifically, making sure it's a focal point just like the most impressive attractions in our jungle.
                </li>

                <li>
                Alt Text: Provides a description for screen readers and search engines, ensuring that all explorers, regardless of how they journey through our digital jungle, can understand the rich diversity of the frog habitats.
                </li>
            </ul>
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 18: Describing the Habitat 🌏📖</h2>

            <p>
            Let's elaborate on the widespread habitat of our poison dart frogs with a descriptive paragraph to accompany the map.
            </p>
            
            <img src={eighteen} className='demo__image' alt='img_eighteen' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                After placing the map image in our "info-section," we insert a paragraph to give context to the visuals. This text explains the geographical spread of poison dart frogs, reinforcing the visual data presented in the map.
                </li>

                <li>
                We’re effectively combining visual learning with text-based information, catering to different learning preferences and providing a more comprehensive understanding.
                </li>

                <li>
                    You can use the text in the box below to fill the &lt;p&gt; tag.
                </li>
            </ol>

            <CopyBox
            text={"Poison dart frogs are found in Central and South America, from Nicaragua and Costa Rica in the north to Brazil, Bolivia, and Peru in the south."}
            />

            <h3>Concepts:</h3>

            <ul>
                <li>Paragraphs (&lt;p&gt;): Serve as blocks of text that provide details and explanations, essential for in-depth understanding of content.</li>

                <li>Complementary Text: When used alongside images, text can explain and give context to visuals, making the information more accessible and memorable.</li>
            </ul>
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 19: Beginning CSS Styling 🎨💻</h2>

            <p>
            Now we start shaping the visual aspects of our website by adding styles in the CSS file.
            </p>
            
            <img src={nineteen} className='demo__image' alt='img_nineteen' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                In style.css, we begin with the body selector. This will apply styles to the entire body of the webpage, setting a default look and feel.
                </li>

                <li>
                The rules we place here are like the foundational colors of a painting, the background canvas upon which all other styles will be applied. Right now the curly brackets are empty but when we write our CSS rules they will go in the brackets of the html that we are targeting and be applied as such.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                CSS Selectors: Used to target HTML elements and apply specific styles to them. The body selector targets the &lt;body&gt; tag which is containing the entire website. So any styles we later write within the brackets will be applied to everything on the website since the &lt;body&gt; tag contains everything.
                </li>

                <li>
                CSS Declarations: Inside the curly braces following the selector, we will define various properties and values to control how the targeted element should look (e.g., font-family, color, margin).
                </li>

                <li>
                Cascading Style Sheets (CSS): This is the language used for describing the presentation of a document written in HTML. CSS describes how elements should be styled on screen, on paper, in speech, or on other media.
                </li>
            </ul>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 20: Styling the Font 🖋️💅</h2>
            
            <p>
            We're dressing up our text to make it as easy on the eyes as the smooth leaves of the rainforest! 🌿✨
            </p>
            <img src={twenty} className='demo__image' alt='img_twenty' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Within our style.css, we've added a font-family property to the body selector. This means every word on our webpage will wear the font style of Arial, with a fallback to the default sans-serif font in case the visitor of our website can't use Arial due to an older device or browser.
                </li>

                <li>
                Arial is known for its clean and easy-to-read letters, making our jungle tales as clear as a calm river.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Font-Family: This CSS property specifies the typeface that should be used for the text.
                </li>

                <li>
                Sans-Serif: Refers to fonts without decorative strokes at the ends of letters, which are generally considered modern and streamlined.
                </li>
            </ul>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 21: Ensuring Consistency in Headings 🌐📏</h2>

            <p>
            Consistency is key in the jungle, and the same goes for our headings! 🗝️📚
            </p>
            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                We've grouped h1, h2, and h3 together in our style.css so that we can write CSS rules for all h1, h2, and h3 tags at once (they will share the same styles).
                </li>

                <li>
                With this CSS rule, we're making sure that all our headings have the same style, which will give our site a cohesive look, much like a harmonious ecosystem.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Grouped Selectors: By grouping selectors, we can apply the same styles to multiple elements, reducing repetition in our code.
                </li>

                <li>
                Hierarchy of Headings: Although they share the same font, the h1 is typically the largest and most important, with h2 and h3 as subheadings in descending order of prominence.
                </li>
            </ul>

            <p>
            By shaping our site with these steps, we create a visually pleasing and easy-to-navigate space, not unlike our well-kept and vibrant rainforest! 🌳🔍 Let's keep the momentum going as we bring the full picture of our digital jungle to life! 🖥️
            </p>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 22: Coloring the Headings 🖌️🎨</h2>
            
            <p>
            Our headings are about to get a splash of the deep blue sea! 🌊
            </p>
            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
            
            <h3>Step Content:</h3>

            <ol>
                <li>
                We've set the color property to navy for our h1, h2, and h3 elements within the CSS. This will change the text color of all our headings to navy blue, bringing a tone of authority and elegance to the titles, like the depth of the ocean.
                </li>

                <li>
                Navy blue is a color that's easy to read and brings a nice contrast against a light background, making sure our users can navigate the content with ease.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>Color Property: Determines the text color of an element.</li>

                <li>CSS Properties: They are the specific aspects of styling that we want to apply, such as color, size, margin, padding, etc.</li>
            </ul>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 23: Styling the Main Content 📐✒️</h2>
            
            <p>
            Let’s frame our main content to make it stand out! 🖼️✨
            </p>
            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                A new rule is introduced for the .main-content class in the CSS, which will directly affect the div tagged with this class in the HTML. We're getting ready to give it some specific styles.
                </li>

                <li>
                Remember when we created a &lt;div&gt; and gave it a class of "main-content"? This area will hold most of our site’s information, so we’ll want to ensure it’s styled in a way that's visually appealing and draws the visitor's attention.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>Class Selector (.main-content): Targets any element with the class "main-content" and applies the declared styles.</li>

                <li>
                    The Dot ("."): When styling a class you always add a "." in front of the name of the class within the CSS file. But when it comes to styling a tag (without a class) then you do not need the dot. Think back to when we styled the h1, h2, and h3 tags... no dot was used.
                </li>

                <li>
                Styling Containers: By styling the .main-content container, we can control the look of a large portion of our site all at once, since the main-content div contains most of the website, ensuring consistent and effective design.
                </li>
            </ul>
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 24: Adjusting the Main Content Width 🌐📐</h2>
            
            <p>
            Our content's stage is getting its dimensions set, ready for the performance! 📐🎭
            </p>
            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                The width property has been set to 80%, which means the .main-content will now take up 80% of the width of its parent element. This gives it ample space for our text and images while leaving some breathing room on the sides for a polished look.
                </li>

                <li>
                This percentage-based width allows for a responsive design, as the content will scale in size depending on the screen size or the window in which the website is viewed.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Width: Controls the width of the element's content area. When set as a percentage, it's relative to the parent element's width.
                </li>

                <li>
                Responsive Design: A strategy that makes web pages render well on a variety of devices and window or screen sizes, ensuring usability and satisfaction.
                </li>
            </ul>
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 25: Centering the Main Content 🎯📏</h2>
            
            <p>
            Let's center our stage where the main content will shine! 🌟
            </p>
            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                We've added margin: 0 auto; to the .main-content class in our CSS. This is the code that centers our content block horizontally on the page, providing a balanced, professional look.
                </li>

                <li>
                By setting the top and bottom margins to 0 and the left and right to auto, the browser calculates equal margins on either side, thus centering the content within the parent container.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Margin: The margin property in CSS controls the space around elements. It can be set for all four sides at once or each side individually.
                </li>

                <li>
                Auto Value: When set for left and right margins, it automatically adjusts the margins to be equal, centering the element within its container.
                </li>
            </ul>
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 26: Targeting Nested Elements 🎯🌲</h2>
            
            <p>
            We're diving deeper into the style jungle to target our nested elements! 🍃🔍
            </p>
            <img src={twentySix} className='demo__image' alt='img_twentySix' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                In our style.css, we've got a new rule for .main-content .info-section. This specific selector means that we're focusing on any element with the info-section class that is inside an element with the main-content class. It's like following a path in the forest that leads to a hidden glade.
                </li>

                <li>
                    By selecting .main-content .info-section, we can properly apply styles to the content in the .info-section class that is in the .main-content class.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Descendant Selector: The space between .main-content and .info-section in our CSS selector means we're selecting .info-section elements that are inside .main-content. It's a way to style elements specifically when they are inside a particular parent.
                </li>

                <li>
                Nested Elements: Elements that are inside other elements. In our HTML structure, the div with the info-section class is nested within the div with the main-content class.
                </li>
            </ul>

            <p>
            Let's leap forward and keep styling our digital ecosystem with precision and care! 🌐🐾
            </p>
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 27: Spacing Between Info Sections 📐🌿</h2>
            
            <p>
            Now, let's ensure each of our info sections doesn’t stick to each other like wet leaves after a rainstorm! 💧🍂
            </p>
            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                We've specified margin-bottom: 20px; for the .main-content .info-section. This style adds 20 pixels of space below each of our info sections. It separates them cleanly.
                </li>

                <li>
                This margin will help our visitors differentiate between the different sections of our webpage, much like clearings that break up the expanse of the forest.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Margin-Bottom: This property adds space below an element, which can help to visually separate it from elements that follow.
                </li>

                <li>
                Pixels (px): A unit of measurement in digital screens; by using pixels, we ensure a consistent space is applied regardless of the screen size or resolution.
                </li>
            </ul>
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 28: Targeting the Distribution Map 🗺️🐸</h2>
            
            <p>
            Alright, junior web explorers, it’s time to put our CSS safari hats on! We’re about to track down and highlight our .distribution-map within its natural habitat. 🕵️‍♂️🌿            </p>
            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                In our style.css, we’ve begun a new declaration block: .main-content .info-section .distribution-map. Think of this like using a magnifying glass to focus on one particular frog out of the whole rainforest! 🐸🔍                
                </li>

                <li>
                But wait! Before we start giving it some fancy looks, let’s make sure we’ve got the right spot. Check that you've written the class correctly in your stylesheet. It’s all about attention to detail in the coding wilderness!
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                CSS Selectors: By using CSS selectors like .main-content .info-section .distribution-map, we're creating a breadcrumb trail right to the element we want to style.
                </li>

                <li>
                Cascading Style Sheets (CSS): Remember, CSS is all about adding style to our HTML elements. By targeting classes, we can apply specific styles to specific elements without affecting others.                </li>
            </ul>

            <p>
            With our target locked in, we’re ready to jazz up that map and make it a standout feature on our webpage, just like the dazzling colors of a poison dart frog! 🎨🐸
            </p>
        </div>

        {/*<div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 29: Styling the Distribution Map 🗺️🐸</h2>
            
            <p>
            Now that we've got our selector ready, it's time to add some style rules to make our distribution map look just right. It's showtime for our distribution map! Let's make sure it's dressed to impress all who wander into our froggy domain. 🎩✨
            </p>
            <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
            
            <h3>Step Content:</h3>

            <ol>
                <li>
                Apply width: 500px; and height: 400px; within the .main-content .info-section .distribution-map block. This is like setting the stage for our map to shine, giving it the perfect amount of room to display its information. 📏✨
                </li>

                <li>
                    We are also going to target the 
                </li>
            </ol>
        </div>*/}

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 29: Styling the Distribution Map 🗺️🐸</h2>
            
            <p>
            Now that we've got our selector ready, it's time to add some style rules to make our distribution map look just right. It's showtime for our distribution map! Let's make sure it's dressed to impress all who wander into our froggy domain. 🎩✨
            </p>
            <img src={thirty} className='demo__image' alt='img_thirty' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Apply width: 500px; and height: 400px; within the .main-content .info-section .distribution-map block. This is like setting the stage for our map to shine, giving it the perfect amount of room to display its information. 📏✨
                </li>

                <li>
                    We are also going to target the &lt;img&gt; tag, which will contain the styles for all images in the website.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>Width and Height: These CSS properties are like the dimensions of a leaf. They determine how much space our map will take up on the forest floor of our webpage.</li>

                <li>
                Starting a New CSS Rule: By adding a new CSS rule for img elements we are saying to create styles for all of the &lt;img&gt; tags.
                </li>
            </ul>
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 30: Ensuring Our Images Stay Within Bounds 🖼️🔍</h2>
            
            <p>
            We’re zeroing in on our images, ensuring they’re as adaptable as a tree frog jumping from branch to branch. The goal is to make sure they scale beautifully no matter the size of the screen.
            </p>
            <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />

            <h3>Step Content:</h3> 

            <ol>
                <li>
                Explorers, it's time to set some boundaries in the jungle of our webpage. Just like the banks of a river guide the flow of water, our max-width property will ensure that our images never overflow their container. 🌊➡️🏞️
                </li>

                <li>
                In your style.css, under the img selector, let's add max-width: 100%;. This CSS snippet is the vine that keeps our images swinging perfectly within the screen, giving them the freedom to resize gracefully and maintain their aspect ratio without spilling over.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Responsive Design: This is like the stretchy skin of a frog – it allows the content to fit across different environments, from the vast desktop monitors to the compact screens of mobile devices.
                </li>

                <li>
                Max-width: By setting max-width: 100%;, we are telling our images to be fluid, taking up no more than 100% of their parent element's width. It's a crucial aspect of responsive web design, ensuring compatibility with various screen sizes.
                </li>
            </ul>
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 31: Ensuring Responsiveness of the Distribution Map 📍🗺️</h2>
            
            <p>
            Web cadets, let's ensure our map is as responsive as a dart frog leaping to a new leaf! 🐸
            </p>
            <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                In the heart of our digital rainforest, our map must be fluid and adaptable, just like the ever-changing environment of the Amazon! To do this, add max-width: 100%; and height: auto; to the .main-content .info-section .distribution-map rule.
                </li>

                <li>
                The max-width: 100%; rule is like telling our map, "You can grow as large as the container holding you, but no larger!" It's a way to prevent our map from overflowing the screen.
                </li>

                <li>
                Meanwhile, height: auto; ensures the map's height will adjust based on its width, maintaining the correct aspect ratio, just as a frog's call adjusts to the acoustics of its surroundings. 🎶
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Responsive Design: This approach ensures that our web content looks good on all devices, just like how a frog’s coloration helps it thrive in various environments.
                </li>

                <li>
                max-width: This property limits the width of an element to a maximum value, which is key to responsive scaling.
                </li>

                <li>
                height: auto: This value keeps the original aspect ratio of an image after resizing, much like a frog's ability to maintain its proportions whether it's on land or water.
                </li>
            </ul>
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 32: Constructing the Informational Eco-Dome 🌐🛠️</h2>
            
            <p>
            Our HTML journey now introduces a fresh div with the class info-section. This element is the soil from which our informational content will grow, a structured space dedicated to enlightening our visitors with knowledge about poison dart frogs.
            </p>
            <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                We need to write down the foundations of a new habitat: a div with a class set to info-section. Think of it as staking out a new plot in our garden. Here, we’ll cultivate engaging content that will flourish into a full-blown display of fascinating facts. 📚
                </li>

                <li>
                For now, our div stands empty.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Class Attributes: By assigning the class info-section, we're tagging this div as a distinct area. Classes act as identifiers for styling, so that when we target it from the CSS it had said styles applied.
                </li>
            </ul>
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 33: Varieties of Poison Dart Frogs 🌟🐸</h2>
            
            <p>
            Code-wranglers, it's time to give our 'Varieties of Poison Dart Frogs' the prominence it deserves in the wild web jungle! 🌴🖥️
            </p>
            <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                With our &lt;h2&gt; tag snugly nestled within the .info-section class, we're declaring a bold topic for web travelers to notice. This isn’t just any title; it's the colorful canopy under which the diverse world of our amphibian friends will be displayed.
                </li>

                <li>
                Type out &lt;h2&gt;Varities of Poison Dart Frogs&lt;/h2&gt; in your index.html. It's like planting a flag on a newly discovered land. Our heading will act as a beacon, attracting visitors' eyes and piquing their curiosity to learn more about these mesmerizing creatures. 🚩🔍
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Semantic Markup: This ensures our content is accessible and organized, providing both users and search engines with clear indicators of content structure.                
                </li>
            </ul>
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 34: Constructing the Table Structure 🛠️📊</h2>
            
            <p>
            We're about to construct the skeletal framework for our data display. 👩‍💼
            </p>
            <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />

            <h3>Step Content:</h3>
            <ol>
                <li>
                In the .info-section, right below our h2 header, we add the &lt;table&gt; element. This is where we'll grow a structured grid of information, much like a botanist arranges specimens for study and admiration.
                </li>

                <li>
                Go ahead and type &lt;table&gt;&lt;/table&gt; in your index.html, right below the h2. Right now, it’s an empty vessel, but soon we will populate it with rows and cells, each brimming with vibrant details about our dart frogs. 
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                HTML Tables: The cornerstone of data organization on the web, tables allow us to present information in an ordered and systematic way.
                </li>
            </ul>

            <p>
            With the framework of our table laid out, we're all set to branch out into the details, filling it with the rich data that represents the diversity of our amphibian subjects. Let the data expedition commence!
            </p>
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 35: Setting the Stage with Table Headers 🎭🐸</h2>
            
            <p>
            It's time to construct the 'skeleton' of our frog fact compendium! 🛠️
            </p>
            <img src={thirtySix} className='demo__image' alt='img_thirtySix' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Dive into your HTML file and within the .info-section, where your &lt;table&gt; element is. 
                </li>

                <li>
                But what’s a table without headers? Let's give our table rows (&lt;tr&gt;) some table headers (&lt;th&gt;) labeled Species, Description, and Image.
                </li>

                <li>
                    What this does will create the first row in the table (side-to-side) and the table head &lt;th&gt; are titles for the items that will be under them when we create more rows.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                HTML Tables: Tables in HTML are a way to present information in rows and columns. They give structure, much like the branches of a tree provide a framework for leaves and fruit.
                </li>

                <li>
                Table Headers: The &lt;th&gt; elements act as the signposts of our table, indicating what each column represents. They're essential for clarity, much like clear signage on a nature trail. With our table headers in place, we’ve laid the groundwork for detailed insights into our poison dart frog family. 
                </li>
            </ul>
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 36: Introducing Data with Table Cells 🌿🔬</h2>
            
            <p>
            The evolution of our table continues! This time, we're filling in the body of our table with rows of cells. These are the containers for the specific details and images of our frogs, similar to niches within a habitat where each creature plays its part. 
            </p>
            <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Within your &lt;table&gt;, add a new row with &lt;tr&gt;.
                </li>

                <li>
                Within this row, we're going to insert our table data cells &lt;td&gt;.
                </li>

                <li>
                    Each &lt;td&gt; is the items in the second row in correspondence to the table heads (&lt;th&gt;) that are the titles.
                </li>

                <li>
                    In this new second table row add the content in the image above. "Blue Poinson Dart Frog" for the species, the passage for it, and the image.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Table Rows and Data Cells: Just like an intricate network of roots, the &lt;tr&gt; and &lt;td&gt; elements provide the support system for the content of our table, holding each fact and image in place.
                </li>

                <li>
                Semantic HTML: By using proper table elements, we ensure that our information is accessible and understandable, not only for humans but also for web crawlers and screen readers that might be trekking through our code.
                </li>
            </ul>

            <p>
            Our table is almost ready to showcase the dazzling array of poison dart frogs. As we add content to these cells, we’ll see our digital ecosystem come to life with vibrant details. Stay tuned for the next steps, where we’ll adorn our table with colorful content! 🎨🐸
            </p>
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 37: Adding Layers of Data 🐠📊</h2>
            
            <p>
            We're about to delve deeper into the understory of our table with a new row that's just brimming with information.
            </p>
            <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                In your &lt;table&gt;, nest another &lt;tr&gt; to create a new row for our next species. 
                </li>

                <li>
                Inside this row, &lt;td&gt; tags are used like little nests for each piece of data: one for the species name, another for its description, and a third for its image.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Table Rows and Data: Each &lt;tr&gt; is like a new layer in the forest floor, with &lt;td&gt; elements providing the space for different types of content.
                </li>
            </ul>

            <p>
            We’ve just enriched our digital ecosystem with more diverse species, enhancing the visitor's journey through our webpage. Let's keep building this biodiversity as we move on to our next species in the following step. Stay curious! 🌟🔍🐸
            </p>
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 38: Introducing a New Frog Species 🐸➕</h2>

            <p>
            We're expanding our virtual vivarium with a new row in the table of our info-section. This is like discovering a new species in the wild!
            </p>
            <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                In the HTML code, we've added a new tr element with its own td compartments. Each td serves as a little pod for different pieces of information about our newest star, the Golden Poison Dart Frog.
                </li>

                <li>
                Ensure you've added the new tr exactly after the last one in your HTML document. It should have its own td elements for the species name, description, and an image.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>HTML Tables: Think of tables like the branches of a tree, each tr is a new limb that holds different leaves (td elements), where each leaf contains unique data.</li>
            </ul>
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 39: Styling Our Table Elements 🎨</h2>
            
            <p>
                Let's get back to CSS!
            </p>
            <img src={forty} className='demo__image' alt='img_forty' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                In our style.css, we've targeted the table, th, and td elements with a single rule so that we can style them all at once.
                </li>

                <li>
                Type in table, th, td followed by your curly braces. Inside, we're going to add styles that bring uniformity and readability to our data. Think of it as the interior design for our virtual museum of dart frogs.
                </li>

                <li>
                For now, we've set the stage with our CSS selector. Imagine the perfect lighting and display cases being installed. We're just moments away from making it all look impeccable!
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                CSS Targeting: With table, th, td, we're targeting multiple elements at once, ensuring a consistent design across our table.
                </li>

                <li>
                Design Unity: Just like a well-planned exhibit, our table's design will guide visitors' eyes smoothly from one fascinating fact to the next.
                </li>
            </ul>
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 40: Framing Our Froggy Table 🐸🖼️</h2>
            
            <p>
            At this stage, our digital rainforest is blooming with facts, but our table looks a bit like an undisturbed pond, serene but plain. Let’s frame it to draw the observer's eye!
            </p>
            <img src={fortyOne} className='demo__image' alt='img_fortyOne' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                In your style.css, let's add some definition to our table by giving it a border.
                </li>

                <li>
                    Type the following CSS rule: border: 1px solid black;
                </li>

                <li>
                It's like creating a picture frame for our table of froggy facts, each cell outlined to pop against the background.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Borders in CSS: Borders provide visual boundaries around elements, much like the borders of a habitat or the vibrant outline of some frog species.
                </li>

                <li>
                Grouping Selectors: By listing table, th, td together, we apply the same style to all these elements, ensuring consistency in our table's look, akin to the uniformity of a frog's spots.
                </li>
            </ul>
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 41: Unifying Our Table Borders 🔗</h2>
            
            <p>
            It's essential to ensure that the borders within our table don't appear uneven.
            </p>
            <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                In your style.css, add the following rule inside your table selector: border-collapse: collapse;
                </li>

                <li>
                This property merges adjacent cell borders into a single, solid line, just like vines intertwining to form a single, stronger lifeline.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Border-Collapse in CSS: This property is used to collapse the borders between table cells into one single border. It tidies up the table, providing a unified and clear structure.
                </li>

                <li>
                Styling Tables: Styling tables is about ensuring data isn't just presented but showcased in an organized and visually pleasing manner.
                </li>
            </ul>
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 42: Tailoring Table Cells 🎨🔲</h2>
            
            <p>
            Time to put on our design caps, friends! 🎓💻 Our mission now is to style the individual cells within our frog fact-file. Each header and cell is a little window into the world of these amazing amphibians, so let's make sure they're perfectly framed.
            </p>
            <img src={fortyThree} className='demo__image' alt='img_fortyThree' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                In our style.css, you'll need to write a line to target the th and td tags.
                </li>

                <li>
                    This CSS target will contain the rules to style the "th" and "td" elements.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>CSS Element Selectors: Using th, td is like selecting all the leaves on a branch. They let us apply styles to all table headers and cells, bringing uniformity to our informational display.</li>
            
                <li>
                Styling Strategy: We're ensuring we know our subjects—headers and cells—inside and out before dressing them up.
                </li>
            </ul>
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 43: Cushioning Our Content 🐸💺</h2>
            
            <p>
            Time to fluff up the lily pads! Just like our dart frog friends enjoy a soft landing, our table cells also deserve a cozy cushioning. 🌟🛏️ Let's jump into our style.css and add a squishy layer of padding to our th and td elements.
            </p>
            <img src={fortyFour} className='demo__image' alt='img_fortyFour' />

            <h3></h3>

            <ol>
                <li>
                Underneath the existing th, td block, add a new rule: th, td padding: 15px;
                </li>

                <li>
                This defines how much empty space each cell will have around its content, creating a visually pleasing and readable table.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Padding: In the wild web-woods, padding is the empty space that keeps our content from feeling too close to the edge. It's a cushion that adds space inside each cell, separating text from the boundaries. 
                </li>

                <li>
                Readability: Just as a natural habitat needs to be hospitable for wildlife, a table must be comfortable for the reader's eyes. Padding achieves just that by giving words room to stand out.
                </li>
            </ul>
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 44: Aligning Our Amphibians 🐸📐</h2>
            
            <p>
            Diving deeper into the aesthetics of our table, we're now focusing on alignment. The addition of text-align: left; to the th and td elements is about to organize our text to hug the left side.
            </p>
            <img src={fortyFive} className='demo__image' alt='img_fortyFive' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Ready to get our data neatly lined up? Let's guide our content to the left side, just like how frogs would align on a branch to catch some sun. 🌞
                </li>

                <li>
                You need to add text-align: left; to the th, td block, ensuring that all text in our table headers and data cells stick to the left, making it easier and more natural to read.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Text Alignment: It's a fundamental tool in the web stylist's kit, allowing us to place text exactly where it'll be most effective.
                </li>
            </ul>

            <p>
            Now that our text is smartly aligned, our table not only looks professional but also invites readers into the world of poison dart frogs without the visual clutter. Let's leap forward to the next styling step! 🚀📖
            </p>
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 45: Setting the Stage for Conservation Discussion 🌱📢</h2>

            <p>
            Fellow nature enthusiasts, it's time to prepare a special corner of our webpage - the conservation status of our colorful friends, the poison dart frogs. 🌳
            </p>
            
            <img src={fortySix} className='demo__image' alt='img_fortySix' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Go ahead and insert a new div element within the body of our index.html. Give this div a class of "info-section". 
                </li>

                <li>
                This will act as a container for our upcoming content on conservation.
                </li>
            </ol>

        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 46: Headlining Conservation 🌍</h2>
            
            <p>
            This header will be a beacon, drawing attention to the fragility and importance of frog conservation efforts.
            </p>
            <img src={fortySeven} className='demo__image' alt='img_fortySeven' />

            <h3>Step Content:</h3>

            <ol>
                <li>Now, let's anchor our new info-section with a bold statement.</li>

                <li>
                Type in an &lt;h2&gt; element and fill it with the title "Conservation Status". 
                </li>

                <li>
                This heading will serve as a rallying cry, beckoning all who visit our webpage to take heed of the conservation messages we'll soon unfold. 📣
                </li>
            </ol>
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 47: Crafting the Conservation Message 🌍🐸</h2>
            
            <p>
            This paragraph is set to become the home of an important conservation message about our subject - the vibrant poison dart frogs. 
            </p>
            <img src={fortyEight} className='demo__image' alt='img_fortyEight' />

            <h3>Step Content:</h3>

            <ol>
                <li>We’ve nestled a cozy &lt;p&gt; tag right under the Conservation Status heading within our .info-section div. </li>

                <li>
                Make sure to encapsulate your message within the &lt;p&gt; tag to ensure it's well-presented and digestible for our eco-conscious readers.
                </li>

                <li>
                    If you do not know what to write use the copy paste box down below.
                </li>
            </ol>

            <CopyBox 
            text={"Due to habitat loss, several species of poison dart frogs are considered threatened. Three species are listed as critically endangered by the IUCN, four species are listed as endangered, and thirteen species are listed as vulnerable."}
            />

            <h3>Concepts:</h3>

            <ul>
                <li>
                Paragraphs in HTML: The &lt;p&gt; tag defines a block of text, known as a paragraph. It's a fundamental element used to structure textual content on web pages.
                </li>
            </ul>
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 48: Introducing a New Info-Section 🌱📃</h2>
            
            <p>
            Our web page’s biodiversity is expanding! We're adding another .info-section to introduce a new topic or category of information. Just like in a nature reserve, each section serves a specific purpose in educating visitors.
            </p>
            <img src={fortyNine} className='demo__image' alt='img_fortyNine' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Right below the closing tag of our previous .info-section, let's construct a new division. This new .info-section will be ready to host another important aspect of our ecological narrative.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Divisions in HTML: The &lt;div&gt; tag is an all-purpose container in HTML, used to group together and section off content for styling and organization.
                </li>

                <li>
                Class Attribute: By assigning the class .info-section to our &lt;div&gt;, we can target this section with specific styles in our CSS, keeping our styling consistent across similar sections.
                </li>
            </ul>
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 49: Unveiling Fascinating Tidbits 🐸</h2>
            
            <p>
            We’re adding yet another layer of intrigue to our webpage with an “Interesting Facts” section. 
            </p>
            <img src={fifty} className='demo__image' alt='img_fifty' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Inside the .info-section we just created, insert an h2 tag with the content “Interesting Facts.” 
                </li>

                <li>
                Remember, the h2 tag is a semantic HTML element that represents a level two heading, giving importance to the text it encloses.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Semantic HTML: By using semantic tags like h2, we help define the structure of our webpage more clearly, not just for users, but for search engines as well, which can improve our site’s SEO.
                </li>

                <li>
                Headings: Headings create a hierarchy that structures our content, making it easier for users to navigate and understand the information presented.
                </li>
            </ul>
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 50: Listing the Lore 📝</h2>
            
            <p>
            Grab your explorer's notebook; it’s list-making time! Let’s jot down some fun facts about our subject in a way that’s easy to scan and digest.
            </p>
            <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Underneath the “Interesting Facts” heading, we’ll craft an unordered list by adding a ul element. 
                </li>

                <li>
                Then, for each interesting fact, we’ll create a new li or list item. 
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Unordered Lists: A ul is a way to group items that can be in any order – like a collection of rare gems that are equally precious, regardless of where they lie.  
                </li>

                <li>
                List Items: Each li represents a single item in a list, and when viewed together, they form a cohesive collection of points.
                </li>
            </ul>
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 51: Expanding the Sanctuary – A New Info Section 🌱</h2>

            <p>
            This division will be the container for a new block of content, enriching our page with more structured information.
            </p>
            <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />

            <h3>Step Content:</h3>
            <ol>
                <li>
                Right below our previous sections, add another div element with a class attribute set to info-section. 
                </li>
            </ol>

            <h3>Concepts:</h3>
            <ul>
                <li>
                Content Structure: Dividing content into sections using div elements helps maintain a clean and organized structure in our HTML, much like the clearings in a forest that separate different ecosystems.
                </li>
            </ul>
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 52: A New Topic Emerges – Adding an h2 Heading 📝</h2>
            
            <p>
            Here we see the addition of an h2 element within our new info section. This heading will announce the topic of the content that will follow.
            </p>
            <img src={fiftyThree} className='demo__image' alt='img_fiftyThree' />

            <h3>Step Content:</h3>

            <ol>
                <li>Inside the fresh info-section you've just planted, sow an h2 element and give it the title of "References" that reflects the upcoming content.</li>
            </ol>
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 53: Citing the Sources of Wisdom – Adding References 📚</h2>

            <p>
            Knowledge is like the canopy of the rainforest: vast and interconnected. It’s time to acknowledge the roots of our wisdom by adding a section for references.
            </p>
            <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Add an ordered list (ol) and add list items (li) with hyperlinks (a tags) pointing to the original sources of your content.
                </li>

                <li>
                    The &lt;a&gt; tag is known as an "anchor" tag and is used to create a hyper link that will direct the user to a different website of your choosing.
                </li>

                <li>
                    The website that the anchor tag takes the user to when they click is up to you, but you need an "href" within the anchor tag (just like the image above has) with a value of the link of the website users should be directed to when they click.
                </li>

                <li>
                    Use the copy box below to get the link of the referenced website.
                </li>
            </ol>

            <CopyBox
            text={"https://en.wikipedia.org/wiki/Poison_dart_frog"}
            />

            <h3>Concepts:</h3>

            <ul>
                <li>
                Hyperlinks: Hyperlinks are the pathways that connect one piece of digital information to another, allowing the seamless flow of discovery, much like the interconnectedness of biodiversity in an ecosystem.
                </li>

                <li>
                By offering references, we not only build credibility but also provide a launching point for further exploration. Let’s foster the spirit of learning and discovery! 🌟
                </li>
            </ul>
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 54: More References 🌐</h2>
            
            <p>
            As we continue to build our informational habitat, let’s weave in another thread to the web of knowledge. It’s time to add a National Geographic reference to our growing list, ensuring our readers have access to a diverse ecosystem of facts.
            </p>
            <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />

            <h3>Step Content:</h3>

            <ol>
                <li>
                Underneath your previous list item in the ol, nest another li element.
                </li>

                <li>
                Within it, place an a tag to create a hyperlink.
                </li>

                <li>
                Set the href attribute to point towards the National Geographic’s page on poison dart frogs, making sure the visible text reads “National Geographic: Poison Dart Frogs.” This acts as a window to a world of comprehensive insights into our amphibious subjects.
                </li>

                <li>
                    The link is in the box below.
                </li>
            </ol>

            <CopyBox
            text={"https://www.nationalgeographic.com/animals/amphibians/group/poison-dart-frogs/"}
            />

            <h3>Concepts:</h3>

            <ul>
                <li>
                Expanding Resources: Like the spreading branches of a kapok tree, adding more references provides broader support and reach for our knowledge base.
                </li>

                <li>
                Linking Authority: National Geographic is a giant in wildlife education, akin to a towering tree in the rainforest canopy. By linking to their resources, we lend authoritative weight to our content.
                </li>
            </ul>
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
        
    </div>
  )
}

export default Frog