import React from 'react';
import { useLocation } from 'react-router-dom';
import './pythonTutorial.css';
import Pong from '../tutorials/python/pong';
import Snake from '../tutorials/python/snake';
import Navbar from '../components/navbar/navbar';
import Breakout from '../tutorials/python/breakout';
import SpaceInvaders from '../tutorials/python/spaceInvaders';
import TicTacToe from '../tutorials/python/tictactoe';
import Maze from '../tutorials/python/maze';
import Hangman from '../tutorials/python/hangman';
import Catch from '../tutorials/python/catch';
import Enemy from '../tutorials/python/enemy';
import NumGuess from '../tutorials/python/numGuess';

const PythonTutorial = () => {
  const location = useLocation();


  return (
    <div>
        <Navbar />
        {
            location.pathname === "/python-guide/pong" &&
            <Pong />
        }

        {
            location.pathname === "/python-guide/snake" &&
            <Snake />
        }

        {
            location.pathname === "/python-guide/breakout" &&
            <Breakout />
        }

        {
            location.pathname === "/python-guide/spaceinvaders" &&
            <SpaceInvaders />
        }

        {
            location.pathname === "/python-guide/tictactoe" &&
            <TicTacToe />
        }

        {
            location.pathname === "/python-guide/maze" &&
            <Maze />
        }

        {
            location.pathname === "/python-guide/hangman" &&
            <Hangman />
        }

        {
            location.pathname === "/python-guide/chickencatch" &&
            <Catch />
        }

        {
            location.pathname === "/python-guide/enemyescape" &&
            <Enemy />
        }

        {
            location.pathname === "/python-guide/numpy" &&
            <NumGuess />
        }
    </div>
  )
}

export default PythonTutorial