import React, {useState} from 'react';
import one from './robloxImages/PAINTBALL/1.png';
import two from './robloxImages/PAINTBALL/2.png';
import three from './robloxImages/PAINTBALL/3.png';
import four from './robloxImages/PAINTBALL/4.png';
import five from './robloxImages/PAINTBALL/5.png';
import six from './robloxImages/PAINTBALL/6.png';
import seven from './robloxImages/PAINTBALL/7.png';
import eight from './robloxImages/PAINTBALL/8.png';
import nine from './robloxImages/PAINTBALL/9.png';
import ten from './robloxImages/PAINTBALL/10.png';
import eleven from './robloxImages/PAINTBALL/11.png';
import twelve from './robloxImages/PAINTBALL/12.png';
import thirteen from './robloxImages/PAINTBALL/13.png';
import fourteen from './robloxImages/PAINTBALL/14.png';
import fifteen from './robloxImages/PAINTBALL/15.png';
import sixteen from './robloxImages/PAINTBALL/16.png';
import seventeen from './robloxImages/PAINTBALL/17.png';
import eighteen from './robloxImages/PAINTBALL/18.png';
import nineteen from './robloxImages/PAINTBALL/19.png';
import twenty from './robloxImages/PAINTBALL/20.png';
import twentyOne from './robloxImages/PAINTBALL/21.png';
import twentyTwo from './robloxImages/PAINTBALL/22.png';
import twentyThree from './robloxImages/PAINTBALL/23.png';
import twentyFour from './robloxImages/PAINTBALL/24.png';
import twentyFive from './robloxImages/PAINTBALL/25.png';
import twentySix from './robloxImages/PAINTBALL/26.png';
import twentySeven from './robloxImages/PAINTBALL/27.png';
import twentyEight from './robloxImages/PAINTBALL/28.png';
import twentyNine from './robloxImages/PAINTBALL/29.png';
import thirty from './robloxImages/PAINTBALL/30.png';
import thirtyOne from './robloxImages/PAINTBALL/31.png';
import thirtyTwo from './robloxImages/PAINTBALL/32.png';
import thirtyThree from './robloxImages/PAINTBALL/33.png';
import thirtyFour from './robloxImages/PAINTBALL/34.png';
import thirtyFive from './robloxImages/PAINTBALL/35.png';
import thirtySix from './robloxImages/PAINTBALL/36.png';
import thirtySeven from './robloxImages/PAINTBALL/37.png';
import thirtyEight from './robloxImages/PAINTBALL/38.png';
import thirtyNine from './robloxImages/PAINTBALL/39.png';
import forty from './robloxImages/PAINTBALL/40.png';
import fortyOne from './robloxImages/PAINTBALL/41.png';
import fortyTwo from './robloxImages/PAINTBALL/42.png';
import fortyThree from './robloxImages/PAINTBALL/43.png';
import fortyFour from './robloxImages/PAINTBALL/44.png';
import fortyFive from './robloxImages/PAINTBALL/45.png';
import fortySix from './robloxImages/PAINTBALL/46.png';
import fortySeven from './robloxImages/PAINTBALL/47.png';
import fortyEight from './robloxImages/PAINTBALL/48.png';
import fortyNine from './robloxImages/PAINTBALL/49.png';
import fifty from './robloxImages/PAINTBALL/50.png';
import fiftyOne from './robloxImages/PAINTBALL/51.png';
import fiftyTwo from './robloxImages/PAINTBALL/52.png';
import fiftyThree from './robloxImages/PAINTBALL/53.png';
import fiftyFour from './robloxImages/PAINTBALL/54.png';
import fiftyFive from './robloxImages/PAINTBALL/55.png';
import fiftySix from './robloxImages/PAINTBALL/56.png';
import fiftySeven from './robloxImages/PAINTBALL/57.png';
import fiftyEight from './robloxImages/PAINTBALL/58.png';
import fiftyNine from './robloxImages/PAINTBALL/59.png';
import sixty from './robloxImages/PAINTBALL/60.png';
import sixtyOne from './robloxImages/PAINTBALL/61.png';
import sixtyTwo from './robloxImages/PAINTBALL/62.png';
import sixtyThree from './robloxImages/PAINTBALL/63.png';
import sixtyFour from './robloxImages/PAINTBALL/64.png';
import sixtyFive from './robloxImages/PAINTBALL/65.png';
import sixtySix from './robloxImages/PAINTBALL/66.png';
import sixtySeven from './robloxImages/PAINTBALL/67.png';
import sixtyEight from './robloxImages/PAINTBALL/68.png';
import sixtyNine from './robloxImages/PAINTBALL/69.png';
import seventy from './robloxImages/PAINTBALL/70.png';
import seventyOne from './robloxImages/PAINTBALL/71.png';
import seventyTwo from './robloxImages/PAINTBALL/72.png';
import seventyThree from './robloxImages/PAINTBALL/73.png';
import seventyFour from './robloxImages/PAINTBALL/74.png';
import seventySix from './robloxImages/PAINTBALL/76.png';
import seventySeven from './robloxImages/PAINTBALL/77.png';
import seventyEight from './robloxImages/PAINTBALL/78.png';
import seventyNine from './robloxImages/PAINTBALL/79.png';
import eighty from './robloxImages/PAINTBALL/80.png';
import eightyOne from './robloxImages/PAINTBALL/81.png';
import eightyTwo from './robloxImages/PAINTBALL/82.png';
import eightyThree from './robloxImages/PAINTBALL/83.png';
import eightyFour from './robloxImages/PAINTBALL/84.png';
import eightyFive from './robloxImages/PAINTBALL/85.png';
import eightySix from './robloxImages/PAINTBALL/86.png';
import eightySeven from './robloxImages/PAINTBALL/87.png';
import eightyEight from './robloxImages/PAINTBALL/88.png';
import eightyNine from './robloxImages/PAINTBALL/89.png';
import ninety from './robloxImages/PAINTBALL/90.png';
import ninetyOne from './robloxImages/PAINTBALL/91.png';
import ninetyTwo from './robloxImages/PAINTBALL/92.png';
import ninetyThree from './robloxImages/PAINTBALL/93.png';
import ninetyFour from './robloxImages/PAINTBALL/94.png';
import ninetyFive from './robloxImages/PAINTBALL/95.png';
import ninetySix from './robloxImages/PAINTBALL/96.png';
import ninetySeven from './robloxImages/PAINTBALL/97.png';
import ninetyEight from './robloxImages/PAINTBALL/98.png';
import ninetyNine from './robloxImages/PAINTBALL/99.png';
import hundred from './robloxImages/PAINTBALL/100.png';
import hundredOne from './robloxImages/PAINTBALL/101.png';
import hundredTwo from './robloxImages/PAINTBALL/102.png';
import hundredThree from './robloxImages/PAINTBALL/103.png';
import hundredFour from './robloxImages/PAINTBALL/104.png';
import hundredFive from './robloxImages/PAINTBALL/105.png';
import hundredSix from './robloxImages/PAINTBALL/106.png';
import hundredSeven from './robloxImages/PAINTBALL/107.png';
import hundredEight from './robloxImages/PAINTBALL/108.png';
import hundredNine from './robloxImages/PAINTBALL/109.png';
import hundredTen from './robloxImages/PAINTBALL/110.png';
import hundredEleven from './robloxImages/PAINTBALL/111.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const Paintball = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 113;

  return (
    <div className='robloxGames__container'>
        <h1>
        🖌️ Paintball Blitz: Making a paintball game with different tools 🎨
        </h1>

        <h3 className='intro'>
        All aboard the coding train🚆, we are on our way to the next exciting adventure. Join me as we craft colorful paintball blasters🔫, belligerent paintball cannons🖌️, and all sorts of colorful fun🎨
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>


        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Choosing Your Template 🌟</h2>
            <ul>
                <li>
                Open Roblox Studio from your desktop or Start menu🖱️
                </li>
                <li>
                Sign in with your Roblox account or create a new one🔑
                </li>
                <li>
                Click "New" to create a new project and select "Classic Baseplate" as the template🗺️<br/><br/>(By doing this, you're starting a fresh new game project with a simple baseplate to build your obby on.🛠️)
                </li>
            </ul>
            <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Accessing the Toolbox 🧰🔨</h2>
            <ul>
                <li>
                With your baseplate open, locate the "Toolbox" section, by clicking the "Toolbox" button. Click on the "Toolbox" icon to open it. This is where you'll find models and other assets to add to your game.
                </li>
            </ul>

            <img src={two} className='demo__image' alt='img_two' />
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 3: Adding Obstacles and Boundaries 🚧🛡️</h2>
            <ul>
                <li>
                In the Toolbox, use the search bar to look for "fence" models.
                </li>

                <li>
                Browse through the options and select a fence that fits the theme of your paintball arena.
                </li>

                <li>
                Click to place the fence on your baseplate, providing boundaries for players during the game.
                </li>
            </ul>

            <img src={three} className='demo__image' alt='img_three' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: Enclosing the Arena 🏗️🚪</h2>
            <ul>
                <li>
                After placing your first fence, continue to add fences around the perimeter of your baseplate.
                </li>

                <li>
                Adjust the size and orientation of the fences as needed to enclose the area completely. This creates a boundary for your paintball game.
                </li>
            </ul>

            <img src={four} className='demo__image' alt='img_four' />
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Constructing the Central Border 🏗️📏</h2>
            <ul>
                <li>
                With your arena's boundaries set, it's time to structure the central play area. Locate the "Part" tool in the toolbar at the top of the screen.
                </li>

                <li>
                Click on "Part" to add a new building block to your baseplate. This part will act as a central border or obstacle for players.
                </li>

                <li>
                Position the part in the center of your baseplate, resizing it as necessary to create an effective barrier or divider for the gameplay area.
                </li>

                <li>
                To ensure the part remains fixed during the game, use the "Anchor" tool from the toolbar to anchor your new border in place.
                </li>

                <li>
                In the "Explorer" panel on the right, you'll see the new part listed under "Workspace". You can rename this part to "Central Border" for clarity.
                </li>
            </ul>

            <img src={five} className='demo__image' alt='img_five' />
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Texturing the Terrain 🎨🏞️</h2>
            <ul>
                <li>
                Select the "Material Manager" tool from the top toolbar to open the materials menu.
                </li>

                <li>
                Browse through the available materials and choose one that fits the theme of your paintball game, like 'Limestone' for a rugged look.
                </li>

                <li>
                Apply your chosen material to the baseplate by clicking on it. This will set the atmosphere for your arena. Make sure to also choose the color that you want.
                </li>
            </ul>

            <img src={six} className='demo__image' alt='img_six' />
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Creating Spawn Points 📍</h2>
            <ul>
                <li>
                Use the "Part" tool to create a spawn point. You can color it red to signify it's for the red team.
                </li>

                <li>
                Once the part is placed, go to the "Properties" window and ensure "CanCollide" is unchecked. This prevents players from colliding with the spawn point.
                </li>

                <li>
                Rename the part to "redSpawner" in the "Explorer" panel to keep your workspace organized.
                </li>

                <li>
                Anchor the part to keep players from moving it.
                </li>
            </ul>

            <img src={seven} className='demo__image' alt='img_seven' />
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Organizing Spawn Points 🗂️📍</h2>
            <ul>
                <li>
                After creating multiple red spawn points, it's time to keep your workspace neat.
                </li>

                <li>
                In the "Explorer" panel, create a new folder. Rename this folder to "redSpawners".
                </li>

                <li>
                Drag all your selected red spawn points into the "redSpawners" folder. This will make your workspace more organized and managing your game elements easier.
                </li>
            </ul>

            <img src={eight} className='demo__image' alt='img_eight' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Setting Up Opponent Spawn Points 🚩🔵</h2>
            <ul>
                <li>
                Now it's time to create spawn points for the opposing team.
                </li>

                <li>
                Duplicate the red spawn points and move them to the opposite side of the baseplate for the blue team.
                </li>

                <li>
                Change the color of these new spawn points to blue to distinguish between the two teams.
                </li>

                <li>
                    Name the folder that they are contained in to "blueSpawners" and rename the parts to "blueSpawn".
                </li>
            </ul>

            <img src={nine} className='demo__image' alt='img_nine' />
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Adding Gameplay Scripts 📜💻</h2>
            <ul>
                <li>
                Go to the "ServerScriptService" in the "Explorer" panel.
                </li>

                <li>
                Click and insert a new script. This will be used to write game logic to setup the teams.
                </li>
            </ul>

            <img src={ten} className='demo__image' alt='img_ten' />
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: Rename the Script 📜📜</h2>
            <ul>
                <li>
                    Rename the newly added script to "setupTeams" as this script will be used to set up the teams.
                </li>
            </ul>

            <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Initializing Teams in the Game 🚀</h2>
            <ul>
                <li>
                We start by creating references to the Teams and Players services within the game. These services are essential for managing the teams and the players in our game.
                </li>

                <li>
                Teams and Players: These are variables that will store the reference to the respective services in the game.
                </li>

                <li>
                game:GetService("ServiceName"): This function call is used to get a service that is built into Roblox, where "ServiceName" is the name of the service you want to access.
                </li>

                <li>
                Setting up these variables at the start of your script is like laying down the plumbing for a building. Without these, we can't control the flow of the game, such as assigning players to teams or tracking their scores.
                </li>
            </ul>

            <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: Creating a Team Object for Blue Team 👤</h2>
            <ul>
                <li>
                Now, we create a new team object. This will be used to define one of the competing teams in our game - the Blue Team.
                </li>

                <li>
                Instance.new("ClassName"): This function creates a new instance of a Roblox class. In this case, "Team" is the class we're creating an instance of.
                </li>

                <li>
                Teams in Roblox are like containers that hold players. By creating a team, we're preparing a 'bucket' where players will be grouped to facilitate team-based gameplay, like ensuring that players on the same team can't shoot each other.
                </li>
            </ul>

            <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: Configuring the Blue Team Properties 🔵✍️</h2>
            <ul>
                <li>
                After creating the Blue Team object, we need to configure its properties such as name, team color, and making it a child of the Teams service.
                </li>

                <li>
                ObjectName.Property = Value: This pattern is used to set the properties of an object. For example, BlueTeam.Name sets the name of the team to "Blue".
                </li>

                <li>
                BrickColor.new("ColorName"): This function creates a new BrickColor object, which defines the color of bricks (and in this case, the color representing the team).
                </li>

                <li>
                .Parent: This property is used to set the parent of an object in the hierarchy. Setting the parent of BlueTeam to Teams adds it to the game's Teams service.
                </li>

                <li>
                By setting these properties, we're giving identity to the Blue Team, making it recognizable in the game's user interface and to the scripts that will govern the team's behavior.
                </li>
            </ul>

            <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15: Creating a Team Object for Red Team 🔴👤</h2>
            <ul>
                <li>
                Similarly to the Blue Team, we also need a Red Team. This step is about creating another team object for the competitors.
                </li>

                <li>
                As before, Instance.new("ClassName") is used to create a new team instance, this time for the Red Team.
                </li>

                <li>
                The creation of the Red Team allows for a balanced competition and enables the game to place players into two distinct groups, fostering a competitive environment.
                </li>
            </ul>

            <img src={fifteen} className='demo__image' alt='img_fifteen' />
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 16: Configuring the Red Team Properties 🔴✍️</h2>
            <ul>
                <li>
                Next, give your Red Team a name with RedTeam.Name = "Red".
                </li>

                <li>
                Choose a color for your team with RedTeam.TeamColor = BrickColor.new("Bright red").
                </li>

                <li>
                Finally, set the parent of the Red Team to the Teams service with RedTeam.Parent = Teams.
                </li>
            </ul>

            <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 17: Setting Up Spawn Locations 📍</h2>
            <ul>
                <li>
                Find the spawn locations for the red team using local redSpawners = game.Workspace:FindFirstChild("redSpawners").
                </li>

                <li>
                This line of code searches for all objects named "redSpawners" in the Workspace which will be where the Red Team players will respawn.
                </li>
            </ul>

            <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 18: Blue Team's Spawn Point 🌐</h2>
            <ul>
                <li>
                Similar to the red team, we locate the spawn locations for the blue team with local blueSpawners = game.Workspace:FindFirstChild("blueSpawners").
                </li>

                <li>
                This makes sure the Blue Team has a place to spawn from within the Workspace.
                </li>
            </ul>

            <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 19: Ready to Teleport! 🚀</h2>
            <ul>
                <li>
                Start by creating a function with function teleportPlayer(player, team).
                </li>

                <li>
                You'll fill this function later to teleport players to their respective team's spawn location.
                </li>
            </ul>

            <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 20: Determining Spawn Locations Based on Team 🎯</h2>
            <ul>
                <li>
                Now that we have our teams and spawn locations set up, it's time to write a function that will teleport players to their team's spawn point.
                </li>

                <li>
                local spawners creates a local variable within the function to hold the spawn locations.
                </li>

                <li>
                team == BlueTeam checks if the team passed to the function is the Blue Team.
                </li>

                <li>
                blueSpawners:GetChildren() gets all the spawn locations for the Blue Team.
                </li>

                <li>
                or redSpawners:GetChildren() is used if the team is not the Blue Team, then it gets the Red Team's spawn locations.
                </li>

                <li>
                By assigning the correct spawn locations to the spawners variable, we ensure that players are teleported to the right place in the game based on their team!
                </li>
            </ul>

            <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 21: Ensuring There Are Spawn Points Available 🔄</h2>
            <ul>
                <li>
                Before we teleport players, we need to make sure that there are available spawn points.
                </li>

                <li>
                #spawners gets the count of spawn points in the spawners table.
                </li>

                <li>
                &gt; 0 confirms there's at least one spawn point available.
                </li>

                <li>
                This step is crucial because attempting to teleport a player to a non-existent location would cause errors in your game.
                </li>
            </ul>

            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 22: Selecting a Random Spawn Point 🎲</h2>
            <ul>
                <li>
                Once we confirm that spawn points are available, the next step is to select one at random for the player.
                </li>

                <li>
                Use Lua's math.random() function to pick a random spawn point.
                </li>

                <li>
                local randomSpawner creates a new local variable to store the chosen spawn point.
                </li>

                <li>
                math.random(#spawners) generates a random number between 1 and the total number of spawners.
                </li>

                <li>
                Randomizing the spawn point is a great way to keep the game fair and unpredictable for players!
                </li>
            </ul>

            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 23: Waiting for the Player's Character to Load 🔄</h2>
            <ul>
                <li>
                In Roblox, players have a character model that represents them in the game world. Before we can teleport the player, we need to ensure their character has loaded.
                </li>

                <li>
                repeat starts a loop that will run until the condition is met.
                </li>

                <li>
                wait() pauses the loop to prevent it from running continuously and potentially crashing the game.
                </li>

                <li>
                until player.Character checks if the player's character has loaded in the game.
                </li>

                <li>
                This loop is essential because it ensures that the teleportation only occurs once we have a valid character to move.
                </li>
            </ul>

            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 24: Finding the Humanoid Root Part 🚶</h2>
            <ul>
                <li>
                After ensuring a player's character has loaded, the next critical step is to locate the HumanoidRootPart. This part is the primary positioning and movement controller for the character.
                </li>

                <li>
                Locate the HumanoidRootPart by using the :FindFirstChild method on the player’s character.
                </li>

                <li>
                player.Character references the player's character model in the game.
                </li>

                <li>
                :FindFirstChild("HumanoidRootPart") is a method that searches the player’s character for a child named "HumanoidRootPart".
                </li>

                <li>
                This step is essential because the HumanoidRootPart is used as the reference point for moving or teleporting the character within the game world.
                </li>
            </ul>

            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 25: Teleporting the Player 🌌</h2>
            <ul>
                <li>
                With the HumanoidRootPart found, we can now teleport the player to the chosen spawn point.
                </li>

                <li>
                Teleport the player by setting the HumanoidRootPart's CFrame to that of the random spawner.
                </li>

                <li>
                if humanoidRootPart then checks if the HumanoidRootPart was successfully found.
                </li>

                <li>
                humanoidRootPart.CFrame sets the position and orientation of the HumanoidRootPart.
                </li>

                <li>
                randomSpawner.CFrame is the position and orientation of the chosen spawn point.
                </li>

                <li>
                Setting the CFrame of the HumanoidRootPart effectively moves the player's character to the location of the spawn point, making it appear as if they have been teleported.
                </li>
            </ul>

            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 26: Preparing for Character Addition 🎭</h2>
            <ul>
                <li>
                function onCharacterAdded(character) declares a new function named onCharacterAdded with a parameter named character.
                </li>

                <li>
                This function will be used to bind to an event that Roblox fires every time a player's character is added to the game. The parameter character will be a reference to the new character model, allowing us to perform actions like teleportation immediately after the character appears in the game.
                </li>
            </ul>

            <img src={twentySix} className='demo__image' alt='img_twentySix' />
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 27: Identifying the Humanoid Component 🕵️‍♂️</h2>
            <ul>
                <li>
                When a character is added to the game, we need to locate its humanoid component. This component is crucial because it controls the health, animations, and other aspects of the character model.
                </li>

                <li>
                Use FindFirstChildWhichIsA to find the humanoid component.
                </li>

                <li>
                :FindFirstChildWhichIsA("Humanoid") is a method that finds the first child of the character that is a humanoid.
                </li>

                <li>
                This step is essential because without identifying the humanoid component, we can't respond to events like the character's death.
                </li>
            </ul>

            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 28: Preparing to Handle Humanoid Death Event 💔</h2>
            <ul>
                <li>
                Once we have a reference to the humanoid component of the character, we can prepare to respond to significant events.
                </li>
                
                <li>
                if humanoid then checks if the humanoid variable actually holds a reference to a humanoid object.
                </li>

                <li>
                This preparation step is critical as it ensures that the next block of code we write, which will handle the humanoid's death, only runs if the humanoid component exists. Without this safety check, we might attempt to access properties or connect to events on a non-existent object, which would result in errors.
                </li>
            </ul>

            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 29: Setting Up the Death Event Handler 🛠️</h2>
            <ul>
                <li>
                After confirming the humanoid exists, we proceed to set up a connection to the Died event, which will trigger when the humanoid dies.
                </li>

                <li>
                humanoid.Died:Connect(function() end) connects an anonymous function to the Died event, which is triggered when the humanoid's health reaches zero.
                </li>

                <li>
                This connection is crucial for game mechanics that rely on character death, such as respawn systems, score updates, or triggering in-game effects. By using an anonymous function, we prepare a placeholder where we can later define the actions that should occur upon death.
                </li>
            </ul>

            <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 30: Implementing the Respawn Mechanism ⏳</h2>
            <ul>
                <li>
                Inside the death event handler, we implement the logic that should execute when the humanoid dies. This typically involves a delay before respawning the character to allow for any death animations or effects to play out.
                </li>

                <li>
                Inside the anonymous function connected to the Died event, add a wait statement and the call to the teleportPlayer function.
                </li>

                <li>
                The wait here serves to provide a brief period before the character respawns, giving a more natural feel to the game's flow and allowing time for any related game mechanics to process the death.
                </li>

                <li>
                Through these steps, we have effectively linked the respawn and teleportation logic to the event of a character's death, enabling us to create a more immersive and responsive gameplay experience.
                </li>
            </ul>

            <img src={thirty} className='demo__image' alt='img_thirty' />
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 31: Setting Up Player Leaderstats Function 🌟</h2>
            <ul>
                <li>
                With each new player joining the game, we need a system to track their individual stats, such as score or team. This is where the onPlayerAdded function comes into play.
                </li>

                <li>
                This function will be crucial as it will be called whenever a new player enters the game, providing a hook for us to add leaderstats or other necessary initialization for the player.
                </li>
            </ul>

            <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 32: Creating the Leaderstats Folder 📁</h2>
            <ul>
                <li>
                To hold the player's stats like score, we create a new folder within each player object called leaderstats. This folder will be displayed in the in-game leaderboard.
                </li>

                <li>
                Instance.new("Folder") constructs a new folder object.
                </li>

                <li>
                leaderstats.Name = "leaderstats" sets the name of the folder, which is important because Roblox uses this name to recognize it as the container for leaderboard stats.
                </li>

                <li>
                leaderstats.Parent = player attaches the folder to the player object, ensuring that it's tied to the correct player.
                </li>

                <li>
                This step is critical for score tracking and other statistics, as Roblox’s leaderboard system automatically picks up any leaderstats folder inside a player object to display.
                </li>
            </ul>

            <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 33: Adding Stats to Leaderstats 📊</h2>
            <ul>
                <li>
                Now that we have a leaderstats folder, we need to populate it with actual stats. These can be numbers or strings, representing different quantifiable data points like score or team.
                </li>

                <li>
                This StringValue will allow us to display the player’s team on the leaderboard, making it easy to see which team each player belongs to during the game.
                </li>
            </ul>

            <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 34: Creating the Score Statistic 📈</h2>
            <ul>
                <li>
                After setting up the player's leaderboard folder and team value, it's time to create a stat to track the player's score.
                </li>

                <li>
                Add a IntValue to the leaderstats to represent the player's score.
                </li>

                <li>
                Instance.new("IntValue") creates a new integer value object to hold numeric stats.
                </li>

                <li>
                ScoreValue.Name = "Score" names the stat, which is how it will be identified on the leaderboard.
                </li>

                <li>
                ScoreValue.Value = 0 initializes the score to zero.
                </li>

                <li>
                ScoreValue.Parent = leaderstats attaches the score stat to the leaderstats folder.
                </li>

                <li>
                The score is a dynamic stat that will be updated throughout the game, reflecting the player's performance.
                </li>
            </ul>

            <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 35: Initializing Team Count Variables 🏁</h2>
            <ul>
                <li>
                To balance teams or track team sizes, we'll need to initialize counters for each team.
                </li>

                <li>
                Declare two variables to keep track of the number of players on each team.
                </li>

                <li>
                local blueTeamCount and local redTeamCount are variables that will store the count of players on the Blue and Red teams, respectively.
                </li>

                <li>
                Initializing them to zero sets the starting point for the count.
                </li>

                <li>
                These variables will be used later to tally the number of players on each team, which could be used for gameplay mechanics or team-balancing features.
                </li>
            </ul>

            <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 36: Starting a Loop to Count Team Members 🔄</h2>
            <ul>
                <li>
                We're setting up a loop to go through all players in the game to count how many are on each team.
                </li>

                <li>
                for _, p in pairs(game.Players:GetPlayers()) do starts a loop where p will represent each player in the game.
                </li>

                <li>
                pairs(game.Players:GetPlayers()) is a Lua standard function for iterating over tables; in this case, it's used to iterate over the list of players.
                </li>
            </ul>

            <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 37: Counting Players on Each Team Inside the Loop 🔢</h2>
            <ul>
                <li>
                Now we'll add the logic inside the loop to increment our team counters based on each player's team.
                </li>

                <li>
                if p.Team == BlueTeam then checks if a player is part of the Blue Team.
                </li>

                <li>
                blueTeamCount = blueTeamCount + 1 increments the blue team counter if the player is on the Blue Team.
                </li>

                <li>
                elseif p.Team == RedTeam then checks if a player is part of the Red Team.
                </li>

                <li>
                redTeamCount = redTeamCount + 1 increments the red team counter if the player is on the Red Team.
                </li>

                <li>
                This conditional logic within the loop is responsible for tallying up the number of players on each team, allowing the game to make decisions based on team sizes, like balancing teams or updating a scoreboard.
                </li>
            </ul>

            <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 38: Team Assignment Logic 🎽🤹</h2>
            <ul>
                <li>
                In this step, we're going to write the code that decides which team a player should be assigned to, aiming for a balanced game.
                </li>

                <li>
                Start by writing an if statement to compare the blueTeamCount and redTeamCount: if blueTeamCount &lt; redTeamCount then.
                </li>

                <li>
                If the BlueTeam has fewer players, assign the new player to the BlueTeam with player.Team = BlueTeam.
                </li>

                <li>
                Then, set a TeamValue.Value to the BlueTeam.Name, so we know which team the player is on.
                </li>

                <li>
                If the RedTeam has fewer or the same number of players, the else block is executed, assigning the player to the RedTeam with player.Team = RedTeam.
                </li>

                <li>
                Similarly, set the TeamValue.Value to the RedTeam.Name.
                </li>

                <li>
                Close off the if statement with end to ensure the code block is properly terminated.
                </li>
            </ul>

            <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 39: Character Addition Event Hook 🎣👾</h2>
            <ul>
                <li>
                This step involves setting up a function that will be called whenever a new character is added to the game for a player.
                </li>

                <li>
                Just under your team assignment logic, start listening for a character being added using player.CharacterAdded:Connect(function(character).
                </li>

                <li>
                This line sets up an event listener that calls a function whenever the player's character is added to the game.
                </li>

                <li>
                Notice that the actual content of the function is not completed here; we will fill this in the next step.
                </li>
            </ul>

            <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 40: Defining the Character Addition Event 📝🚀</h2>
            <ul>
                <li>
                Inside the player.CharacterAdded event, call the teleportPlayer function to move the player to the correct location based on their team. You pass player and player.Team as arguments to the function.
                </li>

                <li>
                Also, call the onCharacterAdded function and pass character as an argument. This function will handle additional tasks whenever a character is added.
                </li>

                <li>
                Close your character addition function with end to wrap up the logic for this event.
                </li>
            </ul>

            <img src={forty} className='demo__image' alt='img_forty' />
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 41: Connecting Player Addition Event 🔄🆕</h2>
            <ul>
                <li>
                Finally, you'll ensure that your game listens for any new players joining.
                </li>

                <li>
                Write Players.PlayerAdded:Connect(onPlayerAdded) outside and below the CharacterAdded event code.
                </li>

                <li>
                This line hooks up the onPlayerAdded function to the PlayerAdded event so that whenever a new player joins the game, the onPlayerAdded function is called.
                </li>
            </ul>

            <img src={fortyOne} className='demo__image' alt='img_fortyOne' />
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 42: Inserting the Paintball Gun Body 🏗️</h2>
            <ul>
                <li>
                In this step, we're starting to create the body of our paintball gun.
                </li>

                <li>
                Click on the "Part" button as indicated by the green box to insert a new part into your game. This will be the main body of your paintball gun.
                </li>
            </ul>

            <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 43: Assembling the Gun's Main Parts 🛠️</h2>
            <ul>
                <li>
                This step focuses on adding and adjusting parts to form the main structure of our paintball gun.
                </li>

                <li>
                In Roblox Studio, continue working on your paintball gun by adding more parts. Use the "Part" button in the "Model" tab, as indicated by the green circle, to insert additional parts.
                </li>

                <li>
                After inserting a new part, select it and use the "Scale" tool (highlighted in the green box) to change its dimensions. Make each part resemble a piece of the paintball gun, like the barrel or handle.
                </li>

                <li>
                As you add and scale these parts, they'll appear in the "Explorer" window (where the green arrow points). Each part will be listed under "Workspace".
                </li>
            </ul>

            <img src={fortyThree} className='demo__image' alt='img_fortyThree' />
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 44: Coloring the Gun Parts 🎨</h2>
            <ul>
                <li>
                In this step, we're adding some color to the parts of the gun to make it visually appealing.
                </li>

                <li>
                With the parts you've created for your paintball gun in place, it's time to give them some life with color.
                </li>

                <li>
                Select the part you want to color in the workspace or in the "Explorer" window on the right.
                </li>

                <li>
                Click on the "Color" option in the "Home" tab, which is circled in green in the image.
                </li>

                <li>
                Apply the color to each part of the gun, like the barrel, body, and handle, to make your paintball gun stand out in the game.
                </li>
            </ul>

            <img src={fortyFour} className='demo__image' alt='img_fortyFour' />
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 45: Naming the Gun Handle 🏷️</h2>
            <ul>
                <li>
                Naming the parts correctly is essential for future scripting and game design clarity.
                </li>

                <li>
                In the "Explorer" window, find the new part you've added that will serve as the handle for the gun.
                </li>

                <li>
                The part might be named "Part" by default. Right-click on it to rename it.
                </li>

                <li>
                Type "Handle" as the new name for the part, as indicated by the green box in the image. This helps you and others working on the game to identify the part's purpose easily.
                </li>

                <li>
                Ensure the "Handle" is correctly positioned in relation to the other parts of the gun, such as being underneath the body of the gun for the player to "hold".
                </li>
            </ul>

            <img src={fortyFive} className='demo__image' alt='img_fortyFive' />
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 46: Adding the "Tool" 🛠️</h2>
            <ul>
                <li>
                Once the tool is created, it will appear in the "Explorer" window.
                </li>

                <li>
                Now you have the tool that you'll later add the paintball gun parts to.
                </li>
            </ul>

            <img src={fortySix} className='demo__image' alt='img_fortySix' />
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 47: Assembling the Paintball Gun into the Tool 🖇️</h2>
            <ul>
                <li>
                This step involves putting all the paintball gun parts into the tool you've created.
                </li>

                <li>
                In the "Explorer" window, drag each part of the paintball gun that you've created and drop them into the tool object you just made.
                </li>

                <li>
                Ensure all parts (like the gun body, barrel, and handle) are children of the tool object.
                </li>

                <li>
                This will group all the gun parts under the tool, making it easier to manage the gun as a single entity in the game.
                </li>
            </ul>

            <img src={fortySeven} className='demo__image' alt='img_fortySeven' />
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 48: Renaming the Tool to "Paintball Blaster" ✏️</h2>
            <ul>
                <li>
                Now we will rename the tool to something more specific to its functionality.
                </li>

                <li>
                Click on the tool object in the "Explorer" window to select it. Once selected, rename the tool to "Paintball Blaster" for clarity and thematic relevance.
                </li>

                <li>
                This name change helps players understand what the tool is when they see it in the game.
                </li>
            </ul>

            <img src={fortyEight} className='demo__image' alt='img_fortyEight' />
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 49: Weld the Part to the Handle ⚒️🔗</h2>
            <ul>
                <li>
                Select the part that you want to connect to the handle, for example, the gun body.
                </li>

                <li>
                    Add a "WeldConstraint" into this selected part.
                </li>

                <li>
                    Find the properties of this newly added "WeldConstraint" set "Part0" to the part the "WeldConstraint" is inside of and "Part1" to what we want to attach it to, in our case the "Handle".
                </li>
            </ul>

            <img src={fortyNine} className='demo__image' alt='img_fortyNine' />
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 50: Welding All of the Parts to the Handle 🛠️🔨</h2>
            <ul>
                <li>
                Repeat the welding process for each part of the paintball gun, making sure each part is welded to the handle.
                </li>

                <li>
                Verify that all parts are correctly welded together by testing the game and seeing if the paintball gun parts are stuck together or fall apart.
                </li>

                <li>
                Once all parts are welded, your "Paintball Blaster" is now ready to be scripted and used in the game.
                </li>
            </ul>

            <img src={fifty} className='demo__image' alt='img_fifty' />
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 51: Adding a Script to the Paintball Gun 📜💻</h2>
            <ul>
                <li>
                This step involves adding a script to your paintball gun, which is essential for defining the gun's behavior in the game.
                </li>

                <li>
                In the Roblox Studio, locate your "Paintball Blaster" in the "Explorer" window.
                </li>

                <li>
                    Add a script into the "Paintball Blaster".
                </li>
            </ul>

            <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 52: Setting Up the Script Basics 📘👩‍💻</h2>
            <ul>
                <li>
                In this step, you're creating a reference to the paintball gun within the script so that the script can control the gun's actions.
                </li>

                <li>
                Open the script within your "Paintball Blaster" tool by double-clicking on it in the "Explorer" window.
                </li>

                <li>
                Start by writing the code local Tool = script.Parent at the top of the script. 
                </li>

                <li>
                Tool is the name of the variable that you are declaring. It's a reference to the paintball gun that the script will control.
                </li>

                <li>
                .Parent is a property that gets the parent object of the script, which in this case is the "Paintball Blaster" tool.
                </li>

                <li>
                This variable is necessary because it establishes a connection between the script and the gun. It allows the script to access and modify the properties of the gun, such as firing paintballs or handling when a player picks up the gun.
                </li>
            </ul>

            <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 53: Defining the firePaintball Function 🖊️🚀</h2>
            <ul>
                <li>
                Now that we have a reference to the tool, let's define a function that will eventually hold the logic for firing a paintball.
                </li>

                <li>
                Beneath the Tool variable, define a new function with function firePaintball(). 
                </li>

                <li>
                firePaintball is the name of the function. It's a descriptive name indicating that this function will handle the action of firing a paintball.
                </li>

                <li>
                The empty function body between function firePaintball() and end is where the code for creating and firing the paintball will go.
                </li>

                <li>
                Adding this function is an initial step in scripting the paintball gun's behavior, and it's a container that will hold more complex logic as you build out the functionality of the gun.
                </li>
            </ul>

            <img src={fiftyThree} className='demo__image' alt='img_fiftyThree' />
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 54: Establishing the Character Reference 🧍‍♂️🔗</h2>
            <ul>
                <li>
                This step is about identifying which character is holding the paintball gun.
                </li>

                <li>
                In the script, add the line local character = Tool.Parent within the firePaintball() function.
                </li>

                <li>
                local character is creating a local variable named character which will be used to store a reference to the character that is holding the paintball gun.
                </li>

                <li>
                Tool.Parent gets the parent object of the Tool, which should be the character model if the tool is being held.
                </li>
            </ul>

            <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 55: Getting the Humanoid Component 🧬⚙️</h2>
            <ul>
                <li>
                Add the line local humanoid = character:FindFirstChildWhichIsA("Humanoid") below the character variable definition. 
                </li>

                <li>
                character:FindFirstChildWhichIsA("Humanoid") is a method call that searches the children of the character object for the first object that is a Humanoid, which is a special object in Roblox that controls health, animations, and more.
                </li>

                <li>
                Understanding the presence of a humanoid component is essential because it will allow us to check if the character is alive and able to perform actions like firing a paintball.
                </li>
            </ul>

            <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 56: Determining the Player 🕹️🧑‍💼</h2>
            <ul>
                <li>
                Next, we need to establish which player is controlling the character.
                </li>

                <li>
                game.Players:GetPlayerFromCharacter(character) is a function call that returns the player object associated with the given character.
                </li>

                <li>
                    The difference between the character and the player is that the character is the physical body of the player that you see on the screen and "player" are the invisible attributes that can control the "character".
                </li>
            </ul>

            <img src={fiftySix} className='demo__image' alt='img_fiftySix' />
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 57: Validating the Player and Humanoid 🛂✅</h2>
            <ul>
                <li>
                We need to ensure that the script only runs when there's a valid player and humanoid.
                </li>

                <li>
                player and humanoid checks that both variables are not nil (i.e., they exist and are valid).
                </li>

                <li>
                This conditional is crucial because we should only attempt to fire a paintball if we have a valid player controlling a live character.
                </li>
            </ul>

            <img src={fiftySeven} className='demo__image' alt='img_fiftySeven' />
        </div>

        <div style={currentStep == 58 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 58: Creating the Paintball Instance 💣🆕</h2>
            <ul>
                <li>
                Use local paintball = Instance.new("Part") to create a new part in the game, which will be our paintball.
                </li>

                <li>
                Set properties like Shape and Material to give the paintball a round appearance and a material that stands out, such as neon.
                </li>

                <li>
                This part of the code is crucial because it defines the actual object that will be shot out of the paintball gun when the player fires it.
                </li>
            </ul>

            <img src={fiftyEight} className='demo__image' alt='img_fiftyEight' />
        </div>

        <div style={currentStep == 59 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 59: Setting the Paintball Color Based on Player's Team 🎨</h2>
            <ul>
                <li>
                This step involves giving the paintball a color that corresponds to the player's team, enhancing the gameplay experience by visually distinguishing between teams.
                </li>

                <li>
                Inside the firePaintball function, after creating the paintball, add an if statement to check the player's team. Use if player.Team then to determine if the player is assigned to a team. 
                </li>

                <li>
                If the player is on a team, the paintball's color is set to match that team's color: paintball.BrickColor = player.Team.TeamColor.
                </li>

                <li>
                This conditional statement is important because it only attempts to set the paintball's color if the player is on a team, avoiding errors if player.Team is nil.
                </li>
            </ul>

            <img src={fiftyNine} className='demo__image' alt='img_fiftyNine' />
        </div>

        <div style={currentStep == 60 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 60: Configuring the Paintball Properties for Gameplay 🛠️</h2>
            <ul>
                <li>
                Here, you'll define the physical properties of the paintball to ensure it behaves correctly when fired.
                </li>

                <li>
                After setting the team color, configure the size of the paintball: paintball.Size = Vector3.new(1, 1, 1), which gives it a uniform size in all dimensions.
                </li>

                <li>
                Make sure the paintball does not collide with other objects, as it should only detect hits but not be obstructed by them: paintball.CanCollide = false.
                </li>

                <li>
                The paintball should not be affected by gravity when fired, so it flies in a straight trajectory: paintball.Anchored = false.
                </li>

                <li>
                These properties are crucial for the paintball's interaction in the game world, allowing it to move freely and only respond when it hits a target.
                </li>
            </ul>

            <img src={sixty} className='demo__image' alt='img_sixty' />
        </div>

        <div style={currentStep == 61 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 61: Setting the Paintball from the Gun's Position 🚀</h2>
            <ul>
                <li>
                Finally, position the paintball at the gun's firing point and ensure it's a part of the game's environment to interact with other elements.
                </li>

                <li>
                Set the initial position of the paintball to the gun's handle to make it appear as though it's being fired from the gun: paintball.Position = Tool.Handle.Position.
                </li>

                <li>
                Parent the paintball to the workspace so it becomes active in the game world: paintball.Parent = workspace.
                </li>

                <li>
                By setting the position to the handle's position, you ensure that the paintball appears at the correct starting point. Parenting it to the workspace is necessary for the paintball to be seen and to interact with other objects in the game.
                </li>

                <li>
                This step is essential for making the action of firing the paintball visible and functional within the game, providing immediate feedback to the player that their gun has fired a shot.
                </li>
            </ul>

            <img src={sixtyOne} className='demo__image' alt='img_sixtyOne' />
        </div>

        <div style={currentStep == 62 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 62: Adding Movement to the Paintball 🌟💨</h2>
            <ul>
                <li>
                This step is about adding a BodyVelocity object to the paintball, which controls its speed and direction once fired.
                </li>

                <li>
                Create a new BodyVelocity object: local bodyVelocity = Instance.new("BodyVelocity"). This Roblox object is used to apply a continuous force to a part, moving it in a specific direction at a set speed.
                </li>
            </ul>

            <img src={sixtyTwo} className='demo__image' alt='img_sixtyTwo' />
        </div>

        <div style={currentStep == 63 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 63: Setting the Direction and Speed 🏎️</h2>
            <ul>
                <li>
                Now, let's point our paintball in the right direction. Set the Velocity property of bodyVelocity to the LookVector of the tool's handle, multiplied by 100 to give it the speed it needs. This makes sure the paintball shoots straight ahead from the player's tool. Add the line bodyVelocity.Velocity = Tool.Handle.CFrame.LookVector * 100.
                </li>
            </ul>

            <img src={sixtyThree} className='demo__image' alt='img_sixtyThree' />
        </div>

        <div style={currentStep == 64 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 64: Applying Max Force 🏋️‍♂️</h2>
            <ul>
                <li>
                To ensure our paintball has unlimited force and doesn't slow down mid-air, we'll use math.huge. This effectively means "as much force as you can give!" Set the MaxForce property to a new Vector3 with math.huge for all its components. Write bodyVelocity.MaxForce = Vector3.new(math.huge, math.huge, math.huge).
                </li>
            </ul>

            <img src={sixtyFour} className='demo__image' alt='img_sixtyFour' />
        </div>

        <div style={currentStep == 65 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 65: Attaching Velocity to Paintball 🧲</h2>
            <ul>
                <li>
                The final step to get our paintball moving is to parent the bodyVelocity to the paintball itself. This connects the force we've set up to the actual paintball object, making it move when it's created. Just below your previous code, add bodyVelocity.Parent = paintball.
                </li>
            </ul>

            <img src={sixtyFive} className='demo__image' alt='img_sixtyFive' />
        </div>

        <div style={currentStep == 66 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 66: Cleaning Up After the Shot 🗑️</h2>
            <ul>
                <li>
                We need to make sure our paintball doesn't stick around forever after it's been shot. Use game:GetService("Debris"):AddItem(paintball, 5) to automatically remove the paintball from the game after 5 seconds, preventing any unnecessary clutter in our game world.
                </li>
            </ul>

            <img src={sixtySix} className='demo__image' alt='img_sixtySix' />
        </div>

        <div style={currentStep == 67 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 67: Detecting a Hit 🕵️‍♂️</h2>
            <ul>
                <li>
                To determine if our paintball has hit something, we'll use paintball.Touched:Connect(function(hit). This line sets up an event connection that calls a function whenever the paintball touches another object in the game.
                </li>
            </ul>

            <img src={sixtySeven} className='demo__image' alt='img_sixtySeven' />
        </div>

        <div style={currentStep == 68 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 68: Finding Who Got Hit 🔍</h2>
            <ul>
                <li>
                Inside our touched event, let’s figure out who's been hit by the paintball. By storing hit.Parent in local hitCharacter, we're getting the object that the paintball collided with, which is usually the character model of a player in Roblox.
                </li>
            </ul>

            <img src={sixtyEight} className='demo__image' alt='img_sixtyEight' />
        </div>

        <div style={currentStep == 69 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 69: Identifying the Player and Shooter 🎮</h2>
            <ul>
                <li>
                Now we dig deeper to find out exactly which player's character has been hit. First, use hitCharacter:FindFirstChildWhichIsA("Humanoid") to ensure we're interacting with a player character.
                </li>

                <li>
                Then, retrieve the player object from the character with local hitPlayer = game.Players:GetPlayerFromCharacter(hitCharacter).
                </li>

                <li>
                Finally, identify the shooter (the player who fired the paintball) with local shooterPlayer = game.Players:GetPlayerFromCharacter(character).
                </li>
            </ul>

            <img src={sixtyNine} className='demo__image' alt='img_sixtyNine' />
        </div>

        <div style={currentStep == 70 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 70: Differentiating Teams 🤝</h2>
            <ul>
                <li>
                It's important that we only register a hit if the shooter and the hit player are on different teams. Add an if-statement to check this.
                </li>

                <li>
                This line checks that the humanoid is valid, the hit character isn't the shooter themselves, and that the teams are different before proceeding with any hit logic.
                </li>
            </ul>

            <img src={seventy} className='demo__image' alt='img_seventy' />
        </div>

        <div style={currentStep == 71 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 71: Applying Damage to the Hit Player 🎮💥</h2>
            <ul>
                <li>
                When a paintball hits a player on a different team, it should inflict damage. Insert hitHumanoid:TakeDamage(10) within the if-statement to take away 10 health points from the hit player's humanoid.
                </li>

                <li>
                After applying damage, make sure to clean up by removing the paintball from the game using paintball:Destroy().
                </li>
            </ul>

            <img src={seventyOne} className='demo__image' alt='img_seventyOne' />
        </div>

        <div style={currentStep == 72 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 72: Checking for the Shooter Player 🎮</h2>
            <ul>
                <li>
                If the paintball hit is valid, the shooter should earn points. Start by checking if shooterPlayer is a valid object with an if-statement. As in the player who fired the paintball gun is still active/alive in the game.
                </li>
            </ul>

            <img src={seventyTwo} className='demo__image' alt='img_seventyTwo' />
        </div>

        <div style={currentStep == 73 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 73: Awarding Points for a Successful Hit 🏆✨</h2>
            <ul>
                <li>
                Inside the if-statement, add a nested check to see if shooterPlayer has a leaderstats object and, within that, a Score object.
                </li>

                <li>
                If Score exists, increment the shooter's score by 1 to reward them for the hit. Use score.Value = score.Value + 1.
                </li>
            </ul>

            <img src={seventyThree} className='demo__image' alt='img_seventyThree' />
        </div>

        <div style={currentStep == 74 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 74: Setting Up the Paintball Tool Activation 🧰</h2>
            <ul>
                <li>
                To let players shoot paintballs, connect the tool activation to a function. Use Tool.Activated:Connect(firePaintball). This ensures that when the tool is activated (typically by a player clicking), it calls the firePaintball function.
                </li>
            </ul>

            <img src={seventyFour} className='demo__image' alt='img_seventyFour' />
        </div>

        <div style={currentStep == 75 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 75: Testing the Paintball Blaster 📝</h2>
            <ul>
                <li>
                    Now that everything in the script is done, it is time to test out your new creation.
                </li>

                <li>
                    Test your paintball gun for anything you might want to change. Maybe you need to rotate the handle? Change the direction of the tip where the paintball spawn from? Or simply make it smaller!
                </li>
            </ul>

        </div>

        <div style={currentStep == 76 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 76: Player Start Pack 🎒</h2>
            <ul>
                <li>
                    In the "Explorer" tab find where the blaster tool is located, then drag and drop it into the folder labeled as "StarterPack".
                </li>

                <li>
                    StarterPack is a folder that contains items you would want players to spawn in the game with, in our case the blaster.
                </li>

                <li>
                    When you do this you may see the blaster disappear from the screen, if that is the case, you can always move the blaster back to the workspace area of the "Explorer" tab when you want to edit it.
                </li>
            </ul>

            <img src={seventySix} className='demo__image' alt='img_seventySix' />
        </div>

        <div style={currentStep == 77 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 77: New Local Script 🗺️</h2>
            <ul>
                <li>
                    In the "Explorer" tab locate the "StarterPlayer" folder, then within that find the "StarterPlayerScripts" folder, and add a "LocalScript" into it.
                </li>

                <li>
                    We will later code this local script to put the players into first person mode while playing the paintball game.
                </li>
            </ul>

            <img src={seventySeven} className='demo__image' alt='img_seventySeven' />
        </div>

        <div style={currentStep == 78 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 78: Rename the Script 🏷️</h2>
            <ul>
                <li>
                    Rename the "LocalScript" to "FirstPersonScript" to appropriately reflect what this script is going to do.
                </li>
            </ul>

            <img src={seventyEight} className='demo__image' alt='img_seventyEight' />
        </div>

        <div style={currentStep == 79 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 79: Initializing the Player Service 🌟</h2>
            <ul>
                <li>
                Open your script in Roblox Studio and get ready to type some code!
                </li>

                <li>
                Start by creating a variable to hold the Player service. Type local Players = game:GetService("Players") just like in the screenshot.
                </li>
                
                <li>
                This line tells the game, "Hey, I'm going to need to keep track of all our players!"
                </li>
            </ul>

            <img src={seventyNine} className='demo__image' alt='img_seventyNine' />
        </div>

        <div style={currentStep == 80 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 80: Building the First Person Lock Function 🛠️</h2>
            <ul>
                <li>
                Right below the Players variable, let's declare a new function. Type local function lockFirstPerson(player).
                </li>

                <li>
                This function will be our secret tool to lock players into first-person view. 
                </li>

                <li>
                Keep it neat and tidy with proper spacing, so everything is clear and easy to read.
                </li>
            </ul>

            <img src={eighty} className='demo__image' alt='img_eighty' />
        </div>

        <div style={currentStep == 81 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 81: Setting the Camera Mode 📸</h2>
            <ul>
                <li>
                Now, we're going to tell the game exactly how we want to lock the camera.
                </li>

                <li>
                Inside our lockFirstPerson function, add the line player.CameraMode = Enum.CameraMode.LockFirstPerson.
                </li>

                <li>
                This line is the command that does the heavy lifting. It's like telling the camera, "You stay right here and follow our player's every move!"
                </li>

                <li>
                The Enum.CameraMode.LockFirstPerson is the special code that locks the camera. Memorize it, cherish it, it's gold! 
                </li>
            </ul>

            <img src={eightyOne} className='demo__image' alt='img_eightyOne' />
        </div>

        <div style={currentStep == 82 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 82: Reacting to Character Spawns 🤖</h2>
            <ul>
                <li>
                We want to ensure the camera locks in first-person every time the player spawns. Let's set that up!
                </li>
            </ul>

            <img src={eightyTwo} className='demo__image' alt='img_eightyTwo' />
        </div>

        <div style={currentStep == 83 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 83: Looping Through All Players 🔄🎮</h2>
            <ul>
                <li>
                Alright, team! Let's make sure everyone gets locked into the first-person perspective from the get-go!
                </li>

                <li>
                Beneath your lockFirstPerson function, we're going to create a loop. Type for _, player in pairs(Players:GetPlayers()) do.
                </li>

                <li>
                What's this loop doing? It's like a roll call, checking in with every player in the game! 
                </li>

                <li>
                Inside this loop, call our lockFirstPerson function with the player as an argument: lockFirstPerson(player).
                </li>
            </ul>

            <img src={eightyThree} className='demo__image' alt='img_eightyThree' />
        </div>

        <div style={currentStep == 84 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 84: Connecting New Players to the Function 🎣</h2>
            <ul>
                <li>
                This line is like setting a trap; when a new player steps in, BAM! They're in first-person mode. 🌟
                </li>

                <li>
                The PlayerAdded event fires whenever a new player joins, and Connect hooks them up to our lockFirstPerson function immediately. 
                </li>
            </ul>

            <img src={eightyFour} className='demo__image' alt='img_eightyFour' />
        </div>

        <div style={currentStep == 85 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 85: Adding the Screen GUI 🧩🖼️</h2>
            <ul>
                <li>
                    To begin making a countdown timer for players to see how much time is left in the round we need to begin by navigating to the 'StarterGui' section in your Explorer panel.
                </li>

                <li>
                Click on the '+' sign next to 'StarterGui' to add a new ScreenGui element.
                </li>
            </ul>

            <img src={eightyFive} className='demo__image' alt='img_eightyFive' />
        </div>

        <div style={currentStep == 86 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 86: Adding the Text Label 🏷️</h2>
            <ul>
                <li>
                With 'ScreenGui' selected, hit the '+' button again to bring up the elements.
                </li>

                <li>
                Select 'TextLabel' to add a new label to our 'ScreenGui'. This will be where our timer's digits live. 
                </li>

                <li>
                You'll see your brand new 'TextLabel' nested neatly under 'ScreenGui'. It's like putting a name tag on our timer!
                </li>
            </ul>

            <img src={eightySix} className='demo__image' alt='img_eightySix' />
        </div>

        <div style={currentStep == 87 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 87: Customizing the Text Label 🎨</h2>
            <ul>
                <li>
                With your 'TextLabel' selected, check out the Properties panel.
                </li>

                <li>
                Find 'BackgroundColor3' in the Appearance section.
                </li>

                <li>
                Change the 'BackgroundColor3' property to a color of your choice. This example uses a nice blue: RGB [85, 85, 255]. Just like choosing a team color!
                </li>

                <li>
                Now our timer's not just functional; it's also stylish!
                </li>
            </ul>

            <img src={eightySeven} className='demo__image' alt='img_eightySeven' />
        </div>

        <div style={currentStep == 88 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 88: Adding the Local Script 🧠</h2>
            <ul>
                <li>
                We need some brains behind this operation—enter the Local Script!
                </li>

                <li>
                Select 'LocalScript' to add a script that will control our timer's behavior.
                </li>

                <li>
                A new 'LocalScript' will appear as a child of 'TextLabel'. It's the puppet master that will make our timer tick! 
                </li>

                <li>
                Take note, this script is not the timer itself. This script will take the countdown logic from a different script and render this onto the screen gui.
                </li>
            </ul>

            <img src={eightyEight} className='demo__image' alt='img_eightyEight' />
        </div>

        <div style={currentStep == 89 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 89: Accessing ReplicatedStorage 🔄</h2>
            <ul>
                <li>
                Start by declaring a variable to access the ReplicatedStorage service. Type local ReplicatedStorage = game:GetService("ReplicatedStorage").
                </li>

                <li>
                Why ReplicatedStorage? It's a special container that holds data, assets, or objects you want to use across the server and all clients. Think of it as the game's shared backpack!
                </li>
            </ul>

            <img src={eightyNine} className='demo__image' alt='img_eightyNine' />
        </div>

        <div style={currentStep == 90 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 90: Awaiting the Timer Event 🔎</h2>
            <ul>
                <li>
                Here's what's happening: We're creating a local variable timerEvent that pauses the script until the TimerEvent object appears in ReplicatedStorage.
                </li>

                <li>
                WaitForChild("TimerEvent") is a method that suspends the script's execution until the child named "TimerEvent" is found within ReplicatedStorage. It ensures our script only runs when the timer mechanism is in place.
                </li>

                <li>
                "TimerEvent" has not yet been created but we will soon make it for the first time.
                </li>
            </ul>

            <img src={ninety} className='demo__image' alt='img_ninety' />
        </div>

        <div style={currentStep == 91 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 91: Identifying the Timer Label 🏷️</h2>
            <ul>
                <li>
                Write local timerLabel = script.Parent to declare a variable for the text label.
                </li>

                <li>
                This command is crucial because it links our script to the GUI component that displays the time. script.Parent refers to the object that the script is attached to, which, in our GUI setup, should be the TextLabel.
                </li>
            </ul>

            <img src={ninetyOne} className='demo__image' alt='img_ninetyOne' />
        </div>

        <div style={currentStep == 92 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 92: Crafting the Update Function ⚙️</h2>
            <ul>
                <li>
                As we delve into the depths of our timer logic, we're crafting a function that will keep our timer's display updated in real-time. 
                </li>

                <li>
                This function will be triggered every time our TimerEvent sends out new data—like every tick of the clock.
                </li>

                <li>
                    In other words there will be another script working hand-in-hand with this one. The other script will actually be counting down in a timer like format. The script we are currently making is taking the process and displaying it to the GUI.
                </li>
            </ul>

            <img src={ninetyTwo} className='demo__image' alt='img_ninetyTwo' />
        </div>

        <div style={currentStep == 93 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 93: Connecting the Event to the Function 🔄</h2>
            <ul>
                <li>
                Within our updateTime function, we've introduced a new conditional statement: if type(data) == "number" then.
                </li>

                <li>
                This line is the gatekeeper. It checks the type of data we receive. If data is a number, we know it's time-related and should be converted to a string for display.
                </li>

                <li>
                The code timerLabel.Text = tostring(data) is the translator, turning the number into text that our TextLabel can show to the player.
                </li>

                <li>
                But what if data isn't a number? We've got that covered too! The line elseif type(data) == "string" then is our plan B.
                </li>

                <li>
                If data is a string, this means it's not a countdown but a message for the player, such as "Game Over!" or "Match Starts Soon!".
                </li>

                <li>
                In that case, timerLabel.Text = data sets the TextLabel to show the message directly without any conversion.
                </li>

                <li>
                We close off our conditional with an end, putting a lid on our logic box. It ensures our function knows where the decision-making ends.
                </li>
            </ul>

            <img src={ninetyThree} className='demo__image' alt='img_ninetyThree' />
        </div>

        <div style={currentStep == 94 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 94: Setting Up the Server-Side Script 🌐</h2>
            <ul>
                <li>
                For our game's timer to work flawlessly, it's essential to have a script on the server that tracks and updates the time. This is where the ServerScriptService comes into play.
                </li>

                <li>
                In the Roblox Studio Explorer, find the ServerScriptService. This is the secure place where server-only scripts reside, ensuring that they run on the server and not on the client's machine.
                </li>

                <li>
                Click the "+" on "ServerScriptService" to add a new "Script".
                </li>

                <li>
                The purpose of this script is to handle the game logic that clients shouldn't see or manipulate. It'll be responsible for starting the timer, keeping it updated, and broadcasting the timer's state to all players.
                </li>
            </ul>

            <img src={ninetyFour} className='demo__image' alt='img_ninetyFour' />
        </div>

        <div style={currentStep == 95 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 95: Accessing Game Services 📡</h2>
            <ul>
                <li>
                Just like in the LocalScript, we need to access certain services within our server script to control the game's environment.
                </li>

                <li>
                local Teams = game:GetService("Teams"): This accesses the Teams service, which manages the different teams within your game.
                </li>

                <li>
                local Players = game:GetService("Players"): This gets the Players service, letting you interact with the list of players in the game.
                </li>

                <li>
                local ReplicatedStorage = game:GetService("ReplicatedStorage"): This line is crucial as it allows the script to use the ReplicatedStorage service to share data between the server and clients.
                </li>

                <li>
                Each of these services plays a vital role in managing the game. Teams for assigning players to different teams, Players for tracking player information, and ReplicatedStorage for sharing data like the timer across the server-client divide.
                </li>
            </ul>

            <img src={ninetyFive} className='demo__image' alt='img_ninetyFive' />
        </div>

        <div style={currentStep == 96 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 96: Creating the Timer Event 🔧</h2>
            <ul>
                <li>
                To synchronize the timer across all clients, we need a RemoteEvent that can send messages from the server to the clients.
                </li>

                <li>
                Instance.new("RemoteEvent") creates a new RemoteEvent object. RemoteEvents are used for secure client-server communication.
                </li>

                <li>
                The timerEvent will be used to send the timer updates to all clients. When the server changes the timer, it will fire this event, and all clients listening to this event will update their timers accordingly, since we have created the mechanism for this in the previous local script.
                </li>
            </ul>

            <img src={ninetySix} className='demo__image' alt='img_ninetySix' />
        </div>

        <div style={currentStep == 97 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 97: Naming and Storing the Timer Event 🏷️🗂️</h2>
            <ul>
                <li>
                With the RemoteEvent created, it's crucial to properly name and store it for access across the game.
                </li>

                <li>
                Start by giving your RemoteEvent a recognizable name with timerEvent.Name = "TimerEvent". This ensures that when you or other scripts look for this event, it can be easily found by its name.
                </li>

                <li>
                Then, set the event's parent to ReplicatedStorage using timerEvent.Parent = ReplicatedStorage. This effectively stores the event in a common area accessible by both the server and all clients, making it possible for the server to communicate with the clients.
                </li>
            </ul>

            <img src={ninetySeven} className='demo__image' alt='img_ninetySeven' />
        </div>

        <div style={currentStep == 98 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 98: Constructing the Timer Start Function ⏱️🛠️</h2>
            <ul>
                <li>
                Now, let's define the function that will initiate the countdown.
                </li>

                <li>
                Type function startTimer(player) to declare the function. This function will be responsible for starting the timer for each player.
                </li>

                <li>
                Inside this function, you will later add the logic for setting the initial timer value and starting the countdown.
                </li>
            </ul>

            <img src={ninetyEight} className='demo__image' alt='img_ninetyEight' />
        </div>

        <div style={currentStep == 99 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 99: Initializing the Timer Variables ⏲️📊</h2>
            <ul>
                <li>
                It's essential to initialize the variables that will hold the timer's value and its running state.
                </li>

                <li>
                Inside the startTimer function, declare local timerValue = 3 * 60. This sets the timer's starting value, which in this case is 180 seconds, or 3 minutes.
                </li>

                <li>
                Next, set local timerRunning = true to indicate that the timer should be active. This variable will be used to control the countdown loop, allowing it to be stopped when needed.
                </li>
            </ul>

            <img src={ninetyNine} className='demo__image' alt='img_ninetyNine' />
        </div>

        <div style={currentStep == 100 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 100: Reset Timer Function 🔄</h2>
            <ul>
                <li>
                To handle game restarts or new rounds, we need a way to reset the timer.
                </li>

                <li>
                Within the startTimer function, create a nested function named resetTimer with local function resetTimer().
                </li>

                <li>
                Inside resetTimer, reassign timerValue to 3 * 60 to reset it back to 3 minutes.
                </li>
            </ul>

            <img src={hundred} className='demo__image' alt='img_oneHundred' />
        </div>

        <div style={currentStep == 101 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 101: Implementing the stopTimer Function ⏹️</h2>
            <ul>
                <li>
                In this phase, we are adding the ability to stop our game's timer. 
                </li>

                <li>
                Inside the startTimer function, below the resetTimer definition, declare a local function named stopTimer.
                </li>

                <li>
                Within the stopTimer function, set the variable timerRunning to false. This will signal our timer to cease ticking down.
                </li>

                <li>
                Notice that the stopTimer function does not change the timerValue. It only stops the countdown.
                </li>
            </ul>

            <img src={hundredOne} className='demo__image' alt='img_oneHundredOne' />
        </div>

        <div style={currentStep == 102 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 102: Connecting Character Events to the Timer 🎮➡️</h2>
            <ul>
                <li>
                At the end of the startTimer function, but still within it, you'll see a connection being made to the resetTimer function using player.CharacterAdded:Connect(resetTimer)
                </li>
            </ul>

            <img src={hundredTwo} className='demo__image' alt='img_oneHundredTwo' />
        </div>

        <div style={currentStep == 103 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 103: Preparing for a Loop with Infinite Possibilities 🔁</h2>
            <ul>
                <li>
                After the end of the startTimer function, start a while true do loop.
                </li>

                <li>
                This infinite loop will run forever, or until the game stops it.
                </li>
            </ul>

            <img src={hundredThree} className='demo__image' alt='img_oneHundredThree' />
        </div>

        <div style={currentStep == 104 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 104: Adding a Delay and Condition to the Loop ⏲️🔄</h2>
            <ul>
                <li>
                Inside the infinite while loop, add a wait(1) function call. This will pause the loop for 1 second on each iteration, preventing it from overloading the game.
                </li>

                <li>
                Insert an if statement to check if timerRunning is true.
                </li>
            </ul>

            <img src={hundredFour} className='demo__image' alt='img_oneHundredFour' />
        </div>

        <div style={currentStep == 105 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 105: Countdown Logic Implementation ⌛</h2>
            <ul>
                <li>
                Now, we're adding the countdown functionality within our infinite loop. This is where the timer actually ticks down.
                </li>

                <li>
                Inside the if timerRunning then block, decrement timerValue by 1. This will decrease our timer's value every second because our loop waits for 1 second in each iteration due to wait(1).
                </li>

                <li>
                Immediately after reducing the timerValue, we call timerEvent:FireClient(player, timerValue). This line sends the updated timer value to the player's client, thus communicating to the local script to update the screen gui. It's crucial for keeping the player informed about the remaining time.
                </li>

                <li>
                The FireClient function is a RemoteEvent that communicates from the server (where this script runs) to the client (the player's game/local script made earlier). It's how we send data in real-time to the players.
                </li>
            </ul>

            <img src={hundredFive} className='demo__image' alt='img_oneHundredFive' />
        </div>

        <div style={currentStep == 106 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 106: Handling Timer Expiration ⏲️🔚</h2>
            <ul>
                <li>
                Here we're adding a condition to handle what happens when the timer runs out.
                </li>

                <li>
                After decrementing the timer value, add an if statement to check if timerValue is less than or equal to 0.
                </li>

                <li>
                This check is vital because it determines if the timer has reached zero and the game should perform end-of-timer actions, like deciding the game's winner or resetting for another round.
                </li>
            </ul>

            <img src={hundredSix} className='demo__image' alt='img_oneHundredSix' />
        </div>

        <div style={currentStep == 107 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 107: Calculating Team Scores 🧮🏆</h2>
            <ul>
                <li>
                Inside the if timerValue &lt;= 0 then block, declare two local variables, blueScore and redScore, and initialize them to 0.
                </li>

                <li>
                Use a for loop to iterate through all the players using Players:GetPlayers().
                </li>

                <li>
                Within the loop, check each player's team and increment the corresponding team's score by the player's individual score, which is retrieved from player.leaderstats.Score.Value.
                </li>

                <li>
                This step is where the game logic determines the collective score of each team by summing up individual scores. This is a common pattern in team-based games where individual performance contributes to the team's total score.
                </li>
            </ul>

            <img src={hundredSeven} className='demo__image' alt='img_oneHundredSeven' />
        </div>

        <div style={currentStep == 108 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 108: Determining the Winner 🏁👑</h2>
            <ul>
                <li>
                Still inside the if timerValue &lt;= 0 then block and after calculating the scores, determine the winner by comparing blueScore and redScore.
                </li>

                <li>
                Assign a local variable winner with a string that announces which team won, or if it's a tie.
                </li>

                <li>
                This decision is critical because it concludes the game round by evaluating which team scored higher. It's a simple yet effective way to determine the outcome of a competitive match.
                </li>
            </ul>

            <img src={hundredEight} className='demo__image' alt='img_oneHundredEight' />
        </div>

        <div style={currentStep == 109 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 109: Wrapping Up the Round 🎉</h2>
            <ul>
                <li>
                After determining the winner and notifying the player with timerEvent:FireClient(player, winner), give the players a moment to see the result by adding a wait(10) line. This 10-second pause lets players process who won before the game moves on.
                </li>

                <li>
                After the pause, use player:LoadCharacter() to respawn the player. This is essential because it signifies the end of a round and a fresh start. Players expect a reset after a game concludes, and this is how you provide it.
                </li>

                <li>
                Call resetTimer() to set the timerValue back to 180 seconds and timerRunning to true, prepping the timer for the next round.
                </li>
            </ul>

            <img src={hundredNine} className='demo__image' alt='img_oneHundredNine' />
        </div>

        <div style={currentStep == 110 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 110: Setting Up the Player Timer 🕗</h2>
            <ul>
                <li>
                Inside this new function, start an infinite loop with while true do. This loop will keep running for each player as long as they are in the game.
                </li>

                <li>
                Call startTimer(player) to begin the timer for the newly added player.
                </li>

                <li>
                Add a wait(60) to ensure that there is a 1-minute delay before the startTimer function is called again for the same player.
                </li>
            </ul>

            <img src={hundredTen} className='demo__image' alt='img_oneHundredTen' />
        </div>

        <div style={currentStep == 111 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 111: Spruce up the Map 🌱🗺️</h2>
            <ul>
                <li>
                    Now that your blaster, team organization, and timer is all built out, finish your project by customizing your map.
                </li>

                <li>
                    Open the "Toolbox" and add obstacles, bases, and more for a fun map where players can battle in Paintball Blitz.
                </li>
            </ul>
            
            <img src={hundredEleven} className='demo__image' alt='img_oneHundredEleven' />
        </div>



        <div style={currentStep == 112 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Testing and Publishing Your Game🔬🌐
            </h2>
            <h2>
            Testing Your Game Locally🕹️
            </h2>
            <ul>
                <li>
                In Roblox Studio, click on the "Home" tab at the top
                </li>
                <li>
                Click the "Play" button to test your game locally
                </li>
                <li>
                You'll spawn on the starting platform and can play through your game, ensuring that each feature works as intended
                </li>
                <li>
                If you find any issues, pause the game by clicking the "Stop" button, then make the necessary adjustments in your game and test again.
                </li>
                <li>
                Testing your game locally helps identify any potential problems or bugs before sharing it with the public. This ensures a smooth gaming experience for everyone!👩‍💻🎮
                </li>
            </ul>
        </div>


        <div style={currentStep == 113 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Publishing Your Game to Roblox🚀
            </h2>
            <ul>
                <li>
                Once you're satisfied with your project, click on the "File" tab in the top left corner of Roblox Studio
                </li>
                <li>
                Select "Publish to Roblox" and choose "Create New Game" (or update an existing game if you've published it before)
                </li>
                <li>
                Fill in the required information, such as game name, description, and genre
                </li>
                <li>
                Set the game's thumbnail and icon to visually represent your game (optional)
                </li>
                <li>
                Click the "Publish" button to make your game live on the Roblox platform
                </li>
                <li>
                When you publish your game, you're sharing it with the entire Roblox community! This is your chance to showcase your creativity and see other players enjoy your adventure.🌟🌍
                </li>
            </ul>

            <h3>
            Developing a game on Roblox is no small feat, and it's a testament to your skills and passion for game development. Your commitment to learning, problem-solving, and bringing your ideas to life is truly inspiring. Building a game requires a combination of technical know-how, design expertise, and the ability to engage and entertain players, and you have clearly excelled in all these areas.<br/><br/>Not only have you demonstrated your talent in creating a game, but you have also showcased your ability to think critically, work through challenges, and persevere. These are invaluable skills that will serve you well not only in game development but also in various other areas of your life.<br/><br/>I encourage you to take pride in your achievement and to continue pursuing your passion for game development. Your dedication and talent are sure to lead to even greater successes in the future. Remember, the journey of learning and improvement is ongoing, and every project you undertake will help you grow and refine your skills.
            </h3>
        </div>
 
        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>


    </div>
  )
}

export default Paintball