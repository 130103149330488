import React, {useState} from 'react';
import one from './pythonImages/breakoutImages/1.png';
import two from './pythonImages/breakoutImages/2.png';
import three from './pythonImages/breakoutImages/3.png';
import four from './pythonImages/breakoutImages/4.png';
import five from './pythonImages/breakoutImages/5.png';
import six from './pythonImages/breakoutImages/6.png';
import seven from './pythonImages/breakoutImages/7.png';
import eight from './pythonImages/breakoutImages/8.png';
import nine from './pythonImages/breakoutImages/9.png';
import ten from './pythonImages/breakoutImages/10.png';
import eleven from './pythonImages/breakoutImages/11.png';
import twelve from './pythonImages/breakoutImages/12.png';
import thirteen from './pythonImages/breakoutImages/13.png';
import fourteen from './pythonImages/breakoutImages/14.png';
import fifteen from './pythonImages/breakoutImages/15.png';
import sixteen from './pythonImages/breakoutImages/16.png';
import seventeen from './pythonImages/breakoutImages/17.png';
import eighteen from './pythonImages/breakoutImages/18.png';
import nineteen from './pythonImages/breakoutImages/19.png';
import twenty from './pythonImages/breakoutImages/20.png';
import twentyOne from './pythonImages/breakoutImages/21.png';
import twentyTwo from './pythonImages/breakoutImages/22.png';
import twentyThree from './pythonImages/breakoutImages/23.png';
import twentyFour from './pythonImages/breakoutImages/24.png';
import twentyFive from './pythonImages/breakoutImages/25.png';
import twentySix from './pythonImages/breakoutImages/26.png';
import twentySeven from './pythonImages/breakoutImages/27.png';
import twentyEight from './pythonImages/breakoutImages/28.png';
import twentyNine from './pythonImages/breakoutImages/29.png';
import thirty from './pythonImages/breakoutImages/30.png';
import thirtyOne from './pythonImages/breakoutImages/31.png';
import thirtyTwo from './pythonImages/breakoutImages/32.png';
import thirtyThree from './pythonImages/breakoutImages/33.png';
import thirtyFour from './pythonImages/breakoutImages/34.png';
import thirtyFive from './pythonImages/breakoutImages/35.png';
import thirtySix from './pythonImages/breakoutImages/36.png';
import thirtySeven from './pythonImages/breakoutImages/37.png';
import thirtyEight from './pythonImages/breakoutImages/38.png';
import thirtyNine from './pythonImages/breakoutImages/39.png';
import forty from './pythonImages/breakoutImages/40.png';
import fortyOne from './pythonImages/breakoutImages/41.png';
import fortyTwo from './pythonImages/breakoutImages/42.png';
import fortyThree from './pythonImages/breakoutImages/43.png';
import fortyFour from './pythonImages/breakoutImages/44.png';
import fortyFive from './pythonImages/breakoutImages/45.png';
import fortySix from './pythonImages/breakoutImages/46.png';
import fortySeven from './pythonImages/breakoutImages/47.png';
import fortyEight from './pythonImages/breakoutImages/48.png';
import fortyNine from './pythonImages/breakoutImages/49.png';
import fifty from './pythonImages/breakoutImages/50.png';
import fiftyOne from './pythonImages/breakoutImages/51.png';
import fiftyTwo from './pythonImages/breakoutImages/52.png';
import fiftyThree from './pythonImages/breakoutImages/53.png';
import fiftyFour from './pythonImages/breakoutImages/54.png';
import fiftyFive from './pythonImages/breakoutImages/55.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import './tutorial.css';

const Breakout = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 48;

  return (
    <div className='pythonGames__container'>
        <h1>
        🧱The Fun World of Breakout🧱
        </h1>

        <h3 className='intro'>
        🚀 Welcome to the exciting journey of creating the classic Breakout game using Python and Pygame! We're going to break down the process into fun, bite-sized steps. 🎉 So, let's start coding! 🎮
        </h3>

        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>


        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 1: Importing Essential Modules
            </h2>

            <img src={one} className='demo__image' alt='img_one' />

            <p>
            What it does:
            </p>

            <ul>
                <li>
                Before we set out to create the game, we need some tools in our toolkit. These lines are like unpacking our toolbox.
                </li>

                <li>
                import pygame: Pygame is a library (a collection of modules) that allows us to create games and multimedia applications. By importing it, we can access all its functionalities.
                </li>

                <li>
                import sys: This module provides access to some variables used or maintained by the interpreter and to functions that interact strongly with the interpreter.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                pygame: To make our game visually appealing and interactive. It provides us with functions to draw shapes, capture mouse events, play sounds, and more.
                </li>

                <li>
                sys: We'll need this to close our game properly.
                </li>
            </ul>

            <p>
            Syntax Breakdown:
            </p>

            <ul>
                <li>
                import: A keyword in Python used to bring in modules or libraries into the current project.
                </li>

                <li>
                pygame & sys: The names of the modules we are importing.
                </li>
            </ul>

            <h3>
            Concept Corner: Think of a module as a gadget in a spy's toolkit. Just as a spy uses different gadgets for different missions, in programming, we use various modules for different tasks. By saying import, we're telling Python, "Hey, I'm going to need this tool from my toolkit, so have it ready!" Remember, the journey of a thousand lines of code begins with a single line. Celebrate these first steps, and as you continue, you'll find yourself building a full-fledged game that looks and feels like it's straight out of the future! 🚀
            </h3>
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 2: Crafting the Foundation with Functions
            </h2>

            <img src={three} className='demo__image' alt='img_two' />

            <p>
            What it does: 
            </p>

            <ul>
                <li>
                🔨 This line begins the definition of a function called new_level.
                </li>

                <li>
                🚀 A function is like a mini-program within our main program. It's a sequence of statements that perform a specific task, packaged as a unit.
                </li>

                <li>
                🔧 By calling this function and providing it with the number of rows and columns, we can generate a new level of bricks for our futuristic game
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                🌌 In the vast universe of coding, it's essential to keep our code organized. By using a function, we can avoid repeating ourselves. Instead of writing out the process of creating a new level every time we need one, we just call this function.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                def: This is a keyword in Python that tells the computer we are about to define a function.
                </li>

                <li>
                new_level: This is the name of our function. Naming is essential - it should be descriptive enough to tell us what the function does.
                </li>

                <li>
                (brick_rows, brick_columns): These are called parameters. They are values we can give to the function to tell it how many rows and columns of bricks we want in our new level.
                </li>
            </ul>

            <h3>
            Concept Corner 🌠: Imagine our function as a future-tech vending machine. Instead of snacks, this machine dispenses levels of our game. We tell the machine (via the parameters) how many rows and columns we want, and voila! It gives us a brand new level.<br/><br/>💡 Tip: Always document your functions. If another coder or even future-you looks at this function, they should immediately understand its purpose.<br/><br/>That wraps up the foundation of our game! As we proceed, we'll delve deeper into the universe of code, crafting each part of our game with precision. Remember, every line of code is a stepping stone to our final game! 🚀🎮🛸
            </h3>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 3: Designing The Bricks of the Future
            </h2>

            <img src={four} className='demo__image' alt='img_three' />

            <p>
            What it does:
            </p>

            <p>
            🧱 These lines set the basic dimensions for the bricks in our game.
            </p>

            <ul>
                <li>
                brick_width: This sets how wide each brick will be. It's set to 70 pixels.
                </li>

                <li>
                brick_height: This sets the height of each brick. Here, each brick will be 30 pixels tall.
                </li>

                <li>
                brick_margin: This is the space between each brick, ensuring they don't touch each other. It's set to 5 pixels.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <p>
            🌆 Imagine a city skyline of the future. Buildings aren't right up against each other; there's space in between! Similarly, in our game, bricks represent challenges. By setting their width, height, and the margin between them, we're ensuring that our game looks organized and aesthetically pleasing.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                brick_width, brick_height, brick_margin: These are variable names. Variables in coding are like containers. They store values that we can use and change as needed.
                </li>

                <li>
                =: This is the assignment operator. It's used to give a value to our variable. It's like telling the computer, "Hey, whenever I mention brick_width, I'm referring to the value 70."
                </li>
            </ul>

            <h3>
            Concept Corner 🛰️: Think of these brick settings as architectural plans for building structures in a future city. When constructing a skyscraper (or in our case, a brick), you'd need to know its dimensions. That's precisely what we're doing here – setting up the blueprints for our game's challenges.<br/><br/>Maybe in the year 2300, bricks are more elongated, or perhaps they're spaced out even more. The future is yours to design! 🎨🌌🎮
            </h3>
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 4: Constructing the Future, One Brick at a Time
            </h2>

            <p>
            Now, we're about to use some advanced future-tech machinery to lay out our bricks. Let's dive in!
            </p>

            <img src={nine} className='demo__image' alt='img_four' />

            <p>
            What it does:
            </p>

            <p>
            🏗️ This line constructs our bricks and places them in a neat grid layout.
            </p>

            <ul>
                <li>
                We're creating a list (or a collection) of bricks where each brick is a rectangle defined by the pygame.Rect function.
                </li>

                <li>
                The formula inside helps to position each brick based on its number in the row and column.
                </li>

                <li>
                The use of for row in range(brick_rows) and for column in range(brick_columns) ensures we cover the entire grid.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <p>
            🌌 In the vast expanse of our game's universe, bricks are the primary challenges our player faces. We need to set them up in an organized manner to create levels of increasing complexity and ensure the game remains visually appealing.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                bricks: This is our variable that will store the list of all bricks we've created.
                </li>

                <li>
                []: Square brackets indicate a list in Python. Think of it as a container holding multiple items. 
                </li>

                <li>
                pygame.Rect: A function provided by Pygame that creates a rectangular shape.
                </li>

                <li>
                column * (brick_width + brick_margin) + brick_margin: This formula calculates the x-position (horizontal placement) of each brick based on its column number.
                </li>

                <li>
                row * (brick_height + brick_margin) + brick_margin: Similarly, this formula calculates the y-position (vertical placement) of each brick based on its row number.
                </li>

                <li>
                for row in range(brick_rows) for column in range(brick_columns): This is a nested list comprehension. It's a concise way to create our brick grid by iterating through each row and column.
                </li>
            </ul>

            <h3>
            Concept Corner 🌠: Imagine you have a drone that's placing blocks in a field. This drone needs exact coordinates for each block. Our code is giving those precise instructions, ensuring every block is placed perfectly without overlapping.<br/><br/>🚀 Fun Fact: In coding, it's often said "Don't repeat yourself." This principle is why we use tools like list comprehensions. Instead of manually specifying where each brick goes, we give a formula and let Python do the heavy lifting, placing each brick for us!<br/><br/>Ready to lay more bricks? The journey to our futuristic Breakout game is getting more exciting with every step! 🎮🌌🏗️
            </h3>
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 5: The Return of the Bricks
            </h2>

            <p>
            As we journey deeper into our code, we've reached a pivotal moment where our function prepares to send its carefully constructed bricks back to the main game. Let's break it down!
            </p>

            <img src={ten} className='demo__image' alt='img_five' />

            <p>
            What it does:
            </p>
            <p>
            📤 This line sends the list of bricks we've just constructed back to whichever part of our game calls (or uses) the new_level function.
            </p>

            <p>
            Why it's used: 
            </p>

            <p>
            🔄 In the cycle of functions, after performing its intended tasks, a function often needs to provide a result back to the main program or the calling function. In our space-age game, after creating our bricks, we need to make them available to the main gameplay loop, so our players have some bricks to target!
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                return: This is a Python keyword signaling that what follows should be sent back to the calling function or main program.
                </li>

                <li>
                bricks: This is our previously constructed list of bricks. By placing it after return, we're ensuring that this list is what gets sent back.
                </li>
            </ul>

            <h3>
            Concept Corner 🌠: Think of the return keyword as a teleportation device. Once our function has done its job (in this case, creating the bricks), return teleports our result (the bricks) back to the main game, ready for action!<br/><br/>🛸 Pro Tip: Not all functions need to return something. Some functions might just perform an action without needing to send anything back. But in cases where we've computed or created something valuable like our bricks, it's crucial to use return to get that value back to the main game.<br/><br/>Mission update: We've successfully prepared our bricks for gameplay! With each step, our game becomes more dynamic and engaging. Gear up for more space-age coding adventures ahead! 🎮🌌🛰️
            </h3>
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 6: Initiating the Ball-Reset Protocol
            </h2>

            <p>
            Every cosmic game of Breakout requires the ability to reset the position and movement of our celestial ball. In this step, we're setting up a protocol to do just that.
            </p>

            <img src={eleven} className='demo__image' alt='img_six' />

            <p>
            What it does:
            </p>

            <p>
            🚀 This line initiates the definition of a new function named reset_ball. This function will contain all the instructions needed to reset our game ball to its default state.
            </p>

            <p>
            Why it's used:
            </p>

            <p>
            🔄 Games are dynamic, and players will frequently encounter scenarios where the ball needs to be reset (like missing a hit or moving to a new level). Instead of writing out the reset code every time we need it, we use a function. Whenever the ball needs to be reset, we can simply 'call' or 'invoke' this function.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                def: This is a Python keyword that starts the definition of a new function.
                </li>

                <li>
                reset_ball: This is the name we're giving to our function. Using descriptive names helps us (and any other future coders) quickly understand the purpose of the function.
                </li>

                <li>
                (): These parentheses will contain any input parameters if the function needs them. In this case, our reset_ball function doesn't need any extra information to do its job, so they're empty.
                </li>
            </ul>

            <h3>
            Concept Corner 🌠: Think of functions like specialized robots in a futuristic factory. Each robot (function) has a specific task. Whenever we need that task done, we simply activate (or call) that robot. Our reset_ball function is a robot specifically designed to position our game ball just right, readying it for another round of cosmic Breakout!<br/><br/>👾 Pro Tip: Functions are a coder's best friend. They promote code reusability and make our programs organized and efficient. Imagine if we had to manually reset the ball every time without a function - our code would become lengthy and harder to manage!<br/><br/>Brace yourself, space cadet! With our ball-reset protocol in place, we're gearing up for more interstellar gaming action. Onward to the next line! 🎮🌌
            </h3>
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 7: Resetting the Cosmic Ball
            </h2>

            <p>
            Time to introduce a new game element: the ball. Our Breakout wouldn't be complete without it. It's the core tool our player uses to break those bricks! This step dives into a function to reset the ball's position.
            </p>

            <img src={twelve} className='demo__image' alt='img_seven' />

            <p>
            What it does:
            </p>

            <p>
            🌍 This line lets the function know that we are referring to the ball, ball_dx, and ball_dy variables that exist outside the function, at a more 'universal' or 'global' level in our program.
            </p>

            <p>
            Why it's used:
            </p>

            <p>
            🔗 Sometimes, we need a function to interact with variables that were defined outside of it. Without the global keyword, if we tried to assign a new value to ball, ball_dx, or ball_dy inside the function, Python would think we're creating new local variables inside the function. The global keyword ensures we're working with the original variables, keeping everything interconnected in our game universe.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                global: This is a Python keyword that allows a function to use and modify variables that are defined outside of it.
                </li>

                <li>
                ball: This will be our main game ball's position and size, stored as a rectangular shape.
                </li>

                <li>
                ball_dx, ball_dy: These variables represent the ball's movement direction and speed in the horizontal (dx) and vertical (dy) axes.
                </li>
            </ul>

            <h3>
            Concept Corner 🚀: Imagine our game's universe. Different parts of the code are like planets or galaxies. The global keyword is like a wormhole, connecting different parts of the universe and ensuring that when we talk about the ball in our function, we're talking about the same cosmic ball that exists in the broader universe of our game.<br/><br/>🌟 Coding Insight: While the global keyword is handy, overusing it can make larger programs hard to manage. Think of it as a powerful tool in our futuristic coding arsenal: potent when used wisely, but things can get tangled if used recklessly. As we code further into the future, we'll aim for balance and organization in our game's universe. 🌌🎮🛸
            </h3>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 8: Initializing the Cosmic Sphere
            </h2>

            <p>
            In this step, we'll harness the power of Pygame's Rect (rectangle) to represent our game's ball. Though it's a rectangle in structure, it'll represent a spherical ball on our game screen. Let's set the stage for this sphere's journey!
            </p>
            
            <img src={fourteen} className='demo__image' alt='img_eight' />

            <p>
            What it does:
            </p>

            <p>
            🌌 This line creates a rectangle (that represents our ball) and places it right at the center of our game screen. This rectangle (or our ball) will have dimensions based on the variable ball_size, making it a square shape.
            </p>

            <p>
            Why it's used:
            </p>

            <p>
            🎮 Our Breakout game requires a ball to collide with the bricks and paddle. To achieve that, we use Pygame's built-in Rect functionality. This line ensures that every time we initiate or reset the ball, it always starts from the center of our screen, ready for action.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                ball: This is the variable we're using to store our rectangle representing the game ball.
                </li>

                <li>
                pygame.Rect(): This is a Pygame function to create a new rectangle. The rectangle's primary function here is to give our ball shape, position, and dimensions.
                </li>

                <li>
                screen_width // 2 and screen_height // 2: These expressions determine the initial x (horizontal) and y (vertical) positions of our ball, ensuring it spawns at the center of our screen.
                </li>

                <li>
                ball_size: This variable defines both the width and height of our rectangle, ensuring our "ball" is square.
                </li>
            </ul>

            <h3>
            Concept Corner 🌠: Using rectangles to represent spheres might seem a bit spacey! But in the realm of 2D game development, rectangles are a universal tool. They simplify things like movement and collision detection. So, while it may be a rectangle in code, it's a soaring sphere in the game!<br/><br/>Hold tight, space coder! With our cosmic sphere set and ready, our Breakout universe is coming alive. Onward to the next line for more stellar adventures! 🚀🌍🕹️
            </h3>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 9: Setting the Stellar Pathway 🌠
            </h2>

            <p>
            Our cosmic sphere is ready to soar, but in which direction will it go? The cosmos is vast, after all. In this step, we'll chart out its initial course through our star-studded Breakout universe.
            </p>

            <img src={fifteen} className='demo__image' alt='img_nine' />

            <p>
            What it does:
            </p>
            <p>
            🌌 This line assigns movement speeds to our celestial sphere, both horizontally (ball_dx) and vertically (ball_dy). The ball will move at the rate of ball_speed horizontally to the right and will ascend at the same rate in the vertical direction due to the negative value.
            </p>

            <p>
            Why it's used:  
            </p>

            <p>
            🛸 Every space journey needs a trajectory. The ball_dx and ball_dy variables determine how quickly and in which direction our sphere travels across the game screen. Without this, our ball would remain stationary, missing out on the galactic fun!
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                ball_dx, ball_dy: These variables represent the horizontal and vertical velocities of our ball. The dx stands for the change in x-direction (horizontal), and the dy stands for the change in y-direction (vertical).
                </li>

                <li>
                =: This is the assignment operator in Python. It takes the value on the right and assigns it to the variable on the left.
                </li>

                <li>
                ball_speed: This variable defines the base speed at which our ball will travel.
                </li>

                <li>
                -ball_speed: The negative sign here means our ball will move upwards. In the world of game programming, a positive y-value typically moves objects downward, while a negative y-value sends them upward.
                </li>
            </ul>

            <h3>
            Concept Corner 🌠: Movement in games is all about understanding coordinates and directions. Just as we have North, South, East, and West on Earth, in our gaming universe, we have up, down, left, and right. The values of dx and dy are like the cosmic compass for our sphere's journey!<br/><br/>👾 Space Trivia: Ever wondered why in many games, positive y-values make objects move downwards? It's a convention that dates back to early computer graphics where the origin (0, 0) was at the top-left corner of the screen!<br/><br/>Chart your course and brace for adventure! With our sphere's trajectory set, we're prepared for a thrilling journey through bricks and challenges. Let's propel to the next line and see what the cosmos holds! 🚀🌌🎮
            </h3>
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 10: Powering Up the Display Console 🖥️🌌
            </h2>

            <p>
            As we construct our cosmic game universe, it's essential to have a display console to view our intergalactic exploits. This next line lays the groundwork for our stellar visual experience.
            </p>

            <img src={sixteen} className='demo__image' alt='img_ten' />

            <p>
            What it does:
            </p>

            <p>
            🌌 This line initializes (or starts up) all the internal modules that Pygame needs to function properly. Think of it as powering up the spaceship's main console before taking off into the cosmos.
            </p>

            <p>
            Why it's used:
            </p>

            <p>
            🚀 Before diving deep into game creation and launching our sphere into action, we need to ensure that the Pygame engine is fully operational. This line guarantees all modules, tools, and functionalities are online and ready to support our galactic adventure.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                pygame: This refers to the Pygame library, our toolkit for crafting this mesmerizing space-themed Breakout game.
                </li>

                <li>
                .init(): This method (or function) from the Pygame library is the "start button" for our game engine. It's essential to call this before using any other Pygame functions.
                </li>
            </ul>

            <h3>
            Concept Corner 🌠: If you've ever played with toy rockets, you know there's a sequence to follow: setting it up, pressing the ignition button, and watching it soar. In the realm of game development, pygame.init() is our ignition button, preparing all systems for liftoff!<br/><br/>👾 Stellar Tip: Initializing your tools and resources is a common practice in many coding endeavors, not just game development. It's like ensuring the stage is set, the lights are on, and the actors are ready before the curtain rises on a play.<br/><br/>Get ready to beam up more game features! With our display console online and Pygame's power at our fingertips, the universe is our playground. Let's rocket forth and decode more stellar lines! 🚀🖥️👩‍🚀
            </h3>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 11: Shaping the Universe's Canvas 🌠🎨
            </h2>

            <p>
            Now that we have powered up our Pygame engine, it's time to craft the vast expanse of space where our cosmic game will unfold. These next lines will set the dimensions of our universe's canvas.
            </p>

            <img src={seventeen} className='demo__image' alt='img_eleven' />

            <p>
            What it does:
            </p>

            <p>
            🌌 These lines define the dimensions of our game screen or window. screen_width is set to 800 pixels wide, and screen_height is set to 600 pixels tall. Together, they sketch the boundaries of our interstellar playground.
            </p>

            <p>
            Why it's used:
            </p>

            <p>
            🌍 Just as planets have sizes and boundaries, our game requires a defined space to operate within. By setting the width and height, we ensure that everything in our game, from the bouncing ball to the brick barriers, fits within this celestial theater.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                screen_width and screen_height: These are variables. They store and label specific pieces of information – in this case, the dimensions of our game screen in pixels.
                </li>

                <li>
                =: The assignment operator. It takes the value on its right and assigns it to the variable on its left.
                </li>

                <li>
                800 and 600: These numeric values represent the width and height of our game screen in pixels, respectively.
                </li>
            </ul>

            <h3>
            Concept Corner 🌠: When creating a visual experience, dimensions matter. A game's screen size can influence everything from player experience to the game's difficulty. Think of screen dimensions like choosing the size of a canvas before painting a masterpiece!<br/><br/>👾 Galactic Factoid: The choice of 800x600 pixels is a nod to earlier computer monitor resolutions. It's a balance of enough space for gameplay without overwhelming newer space cadets. But as you journey further into game development, feel free to experiment with larger cosmic canvases!<br/><br/>With the boundaries of our universe set, it's time to populate it with stars, planets, and thrilling gameplay elements. Grab your astronaut helmet, and let's spacewalk to the next code line! 🚀🎨🎮
            </h3>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 12: Crafting the Cosmic Window 🌌🖥️
            </h2>

            <img src={eighteen} className='demo__image' alt='img_twelve' />

            <p>
            What it does:
            </p>

            <p>
            🌌 This line creates our game window, or as we like to think of it, our "Cosmic Window," with the dimensions we set previously (screen_width and screen_height). The screen variable now holds this window, which we'll use to showcase all the visual elements of our game.
            </p>

            <p>
            Why it's used:
            </p>

            <p>
            🚀 Every space captain needs a cockpit window to navigate the stars. Similarly, players need a visual arena to play our game. The set_mode() function helps us define this arena, ensuring that our spaceship, stars, and all game elements have a place to shine.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                screen: This variable will store our main game window. Think of it as our viewport into the universe we're creating.
                </li>

                <li>
                pygame.display: This is Pygame's module dedicated to display-related functionalities.
                </li>

                <li>
                .set_mode(): A function from the pygame.display module. It helps establish the size of the game window based on the dimensions provided within the parentheses.
                </li>

                <li>
                (screen_width, screen_height): These are the dimensions we established earlier. By placing them in a tuple (a type of data collection in Python), we feed the set_mode() function the width and height for our game screen.
                </li>
            </ul>

            <h3>
            Concept Corner 🌠: Just like when we open a new app or program on a computer, a window appears. In game development, this "window" is the primary stage where all visuals are displayed. Everything we see and interact with in the game happens here.<br/><br/>👾 Galactic Note: Choosing the right window size can influence the game's feel and playability. It can determine how much a player sees, how fast objects move, and how the game feels overall. Like an astronaut picking the right spaceship, game developers often tweak window sizes to get the perfect experience.<br/><br/>Our Cosmic Window is now open, ready to project the wonders of our interstellar Breakout game. With each line, our universe is taking shape. Onwards, space explorer! The next frontier awaits! 🚀🖥️🌠
            </h3>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 13: Naming the Cosmic Adventure 🌌🚀✨
            </h2>

            <p>
            Every great space mission has a name that resonates through history—Apollo, Voyager, Cassini. Our game is no exception. With this next line, we christen our cosmic journey, setting the stage for an interstellar saga.
            </p>

            <img src={nineteen} className='demo__image' alt='img_thirteen' />

            <p>
            What it does:
            </p>

            <p>
            🌌 This line assigns the title "Breakout" to our game window. When players launch our game, they will see "Breakout" at the top of the window, reminding them of the legendary space adventure they're about to embark upon.
            </p>

            <p>
            Why it's used: 
            </p>

            <p>
            🚀 Naming is powerful. By giving our game a name, we provide it with identity, character, and a sense of purpose. Names can evoke emotions, set expectations, and entice players into diving deeper into the universe we've created.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                pygame.display: As before, this is Pygame's dedicated module for handling everything display-related.
                </li>

                <li>
                .set_caption(): A function from the pygame.display module, it's used to set the title (or caption) of the game window.
                </li>

                <li>
                Breakout: The name of our game, enclosed in single quotes. This indicates it's a string (a type of data in Python that represents text).
                </li>
            </ul>

            <h3>
            Concept Corner 🌠: In the vast world of game development, having a recognizable name helps your game stand out in the cosmos of countless stars (games). It's a beacon, a signal to players about the type of experience they can expect.<br/><br/>👾 Space Trivia: The name "Breakout" pays homage to a classic arcade game from the 1970s, where players break walls of bricks using a ball. Our cosmic version elevates this experience to a galactic level!<br/><br/>With our space mission now christened, players know what to expect: a blend of nostalgic gameplay with a cosmic twist. Prepare for liftoff, space adventurer! More stellar code awaits. 🌌🚀🎮
            </h3>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 14: Painting with Cosmic Colors 🌌🎨🌠
            </h2>

            <p>
            In the universe of gaming, colors breathe life into our digital realms. They evoke emotions, guide players, and create depth. In this step, we're diving into our game's color palette, setting the stage for a visually striking cosmic adventure.
            </p>

            <img src={twenty} className='demo__image' alt='img_fourteen' />

            <p>
            What it does:
            </p>

            <p>
            🌌 These lines define five colors that we'll be using in our game. Each color is represented as an RGB tuple, specifying the Red, Green, and Blue values, respectively. These RGB values range from 0 to 255 and combine in various intensities to produce different colors. 
            </p>

            <p>
            Why it's used:
            </p>

            <p>
            🎨 Colors play a pivotal role in game design. They can:
            </p>

            <ul>
                <li>
                Guide Players: Players instinctively respond to colors, which can act as visual cues, guiding them through the game.
                </li>

                <li>
                Enhance Aesthetics: A well-chosen color scheme can make a game more visually appealing, enhancing the player's overall experience.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                WHITE, BLUE, RED, GREEN, BACKGROUND_COLOR: These are variable names. They act as labels, allowing us to refer to specific colors throughout our game without needing to remember or rewrite the RGB values each time.
                </li>

                <li>
                =: The assignment operator, assigning the color tuple values on the right to the variables on the left.
                </li>

                <li>
                (255, 255, 255), etc.: These are tuples, each containing three integers. The integers represent the RGB values for the respective color. For instance, (255, 0, 0) creates a bright red, while (30, 30, 30) is a dark shade, almost like the void of space, perfect for a background.
                </li>
            </ul>


            <h3>
            Concept Corner 🌠: RGB stands for "Red, Green, Blue." By varying the intensity of these three primary colors, we can create a vast spectrum of colors. For instance, full intensity of red and no green or blue gives us pure red (255, 0, 0), while combining full intensity of all three gives white (255, 255, 255).<br/><br/>👾 Galactic Design Tip: While setting colors, it's beneficial to use named variables. Not only does it make our code more readable, but it also allows for easier adjustments later. If we decide halfway through our journey that we want a different shade of space blue, we only have to change it in one spot!<br/><br/>With our cosmic color palette set, our game is ready to shimmer with interstellar hues. Ready your brushes, space artist! Our universe beckons with infinite potential. 🌌🎨🚀
            </h3>
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 15: Designing Our Spacecraft & Paddle 🚀🌌🎮
            </h2>

            <p>
            Just as astronauts need their spaceships to explore the cosmos, players need tools to navigate our game's universe. In Breakout, our primary tool is a paddle—a spacecraft of sorts—that keeps the ball in play and smashes bricks. In this step, we lay the blueprint for this pivotal game element.
            </p>

            <img src={twentyOne} className='demo__image' alt='img_fifteen' />

            <p>
            What it does:
            </p>

            <p>
            🌌 These lines determine the size of our paddle—the spacecraft that players control. Specifically:
            </p>

            <ul>
                <li>
                paddle_width sets the paddle's width to 100 pixels.
                </li>

                <li>
                paddle_height sets its height to 20 pixels.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>
            <p>
            🚀 The paddle is a cornerstone of our game. It's what players will use to:
            </p>

            <ul>
                <li>
                Defend: Prevent the ball from disappearing into the abyss (the bottom of the screen).
                </li>

                <li>
                Attack: Direct the ball towards bricks, breaking them to score points. The size of the paddle is crucial. Too big, and the game becomes too easy. Too small, and it's overly challenging. Finding the right balance ensures a game that's both fun and challenging.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                paddle_width & paddle_height: These are variable names. By naming our values, we make our code more readable and can easily adjust the paddle's size if needed.
                </li>

                <li>
                =: This is Python's assignment operator. It assigns the values on its right to the variables on its left.
                </li>

                <li>
                100 & 20: These are the actual values (in pixels) assigned to the paddle's width and height.
                </li>
            </ul>

            <h3>
            Concept Corner 🌠: Pixels are the tiny squares that make up digital images on our screens. In game development, we often define sizes and positions in terms of pixels.<br/><br/>👾 Galactic Game Tip: Game mechanics, like the paddle's size, can be tweaked to adjust difficulty levels. By altering the paddle's dimensions, we can influence the game's challenge and pacing. Always playtest to find that perfect balance!<br/><br/>With our paddle's blueprint in hand, our game's physics takes shape. But this is just the blueprint—soon, we'll bring this cosmic paddle to life, ready for players to take the helm and embark on their space odyssey! 🌌🚀🎮
            </h3>
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 16: Spaceship Size and Stellar Speed 🚀🌠
            </h2>

            <p>
            In our astral adventure, the spaceship (paddle) must be the right size to navigate through space debris (bricks), and our space orb (ball) must possess an ideal speed to keep our astronauts on their toes. It's time to dial in these settings!
            </p>

            <img src={twentyTwo} className='demo__image' alt='img_sixteen' />

            <p>
            What it does:
            </p>

            <p>
            🌌 These lines determine crucial elements for our game's dynamics:
            </p>

            <ul>
                <li>
                paddle_speed sets our spaceship's thrusters, allowing it to zip across the screen at 8 pixels per game loop iteration.
                </li>

                <li>
                ball_size sizes up our astral ball, ensuring it has a diameter of 20 pixels.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <p>
            🚀 Accuracy and Responsiveness! The dimensions and speeds matter for gameplay experience:  
            </p>


            <ul>
                <li>
                Paddle Speed: Dictates how swiftly players can respond to the ball's movements. Too slow and the game feels sluggish; too fast and it becomes chaotic.
                </li>

                <li>
                Ball Size: Influences visibility and playability. A tiny ball might be too hard to see, while a giant one lacks challenge.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                paddle_speed & ball_size: Variable names representing our values, making our code more readable and giving us easy access to these settings.
                </li>

                <li>
                =: The assignment operator in Python, which connects the value on the right to the variable on the left.
                </li>

                <li>
                8 & 20: Numeric values dictating the speed of the paddle and the diameter of the ball in pixels.
                </li>
            </ul>

            <h3>
            Concept Corner 🌠: Game mechanics, like speed and object size, greatly affect player experience. Finding a harmonious balance ensures the game remains fun, challenging, and engaging.<br/><br/>👾 Galactic Game Tip: Playtesting is paramount! Once you've set values like speed and size, gather some starry-eyed testers. Their feedback will help fine-tune the cosmic ballet of paddle, ball, and bricks.<br/><br/>With our spaceship's thrusters calibrated and our space orb precisely sized, our astral arena is one step closer to hosting thrilling cosmic clashes. Prepare for a game experience that's light-years beyond ordinary! 🚀🌌🎮
            </h3>
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 17: Orbital Velocity and Starry Score Start 🌌⭐
            </h2>

            <p>
            As we continue our space odyssey, we must consider the speed of our celestial orb (the ball) as it zips through the galaxy, and also set a starting point for our cosmic adventurers' score as they shatter interstellar bricks.
            </p>

            <img src={twentyThree} className='demo__image' alt='img_seventeen' />

            <p>
            What it does:
            </p>

            <p>
            🌌 These lines adjust fundamental dynamics of our game:
            </p>

            <ul>
                <li>
                ball_speed gives our space orb its initial momentum, setting its movement speed to 5 pixels per game loop iteration.
                </li>

                <li>
                score kickstarts our player's stellar journey at 0 points. This will rise as they successfully navigate challenges and smash bricks.
                </li>
            </ul>

            <p>
            Why it's used: 
            </p>

            <ul>
                <li>
                Ball Speed: This is central to our game's challenge. A slower orb offers an easygoing play, while a faster one raises the stakes. A speed of 5 provides a balanced start, keeping players engaged without overwhelming them.
                </li>

                <li>
                Score: Beginning at zero emphasizes the player's progress and accomplishments. Every brick smashed brings them closer to galactic greatness.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                ball_speed & score: Variable names that make our intentions clear, offering straightforward access to these crucial game settings.
                </li>

                <li>
                =: The trusty Python assignment operator, bestowing the value on its right to the variable on its left.
                </li>

                <li>
                5 & 0: Numeric values dictating the space orb's velocity and the initial scorecard.
                </li>
            </ul>

            <h3>
            Concept Corner 🌠: Setting a game's pace and progression is both art and science. Speed settings, like our ball's velocity, directly impact a player's experience, while scoring systems provide the drive to push further and reach higher.<br/><br/>👾 Galactic Game Tip: Dynamic gameplay can be achieved by changing the ball's speed as players progress. Consider boosting its velocity after smashing certain bricks or when reaching new levels for added challenge!<br/><br/>With our celestial ball's velocity set and our scoring system initialized, the stage is set for players to embark on their space-age saga. Watch as they chase after dazzling scores and navigate the galaxy's challenges. Adventure awaits in this cosmic breakout game! 🚀🌌🎮
            </h3>
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 18: Galactic Progression - Setting Our Course 🚀🌠
            </h2>

            <p>
            Journeying through the infinite expanse of space requires not just speed and skill but a clear direction! As our game advances, our cosmic players must know where they stand and the challenges that lie ahead. Let's chart our journey with levels!
            </p>

            <img src={twentyFour} className='demo__image' alt='img_eighteen' />

            <p>
            What it does:
            </p>

            <p>
            🌌 These lines structure the progression of our starlit sojourn:
            </p>

            <ul>
                <li>
                level signifies the starting phase of our adventure. All brave spacefarers commence their journey at level 1.
                </li>

                <li>
                max_levels lays down the ultimate challenge, marking the total number of cosmic stages a player can tackle, set at 3.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                Current Level (level): An indicator of the player's current progress. As they navigate through challenges and smash more bricks, they ascend through the levels.
                </li>

                <li>
                Maximum Levels (max_levels): Establishes the final frontier of our breakout universe. It sets a clear goalpost, urging players to strive and conquer all 3 cosmic stages.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                level & max_levels: Descriptive variable names, encapsulating our game progression settings.
                </li>

                <li>
                =: Python's assignment operator, linking the values (on the right) to their respective variables.
                </li>

                <li>
                1 & 3: Numeric values that indicate the starting point and the final stage of our galactic adventure.
                </li>
            </ul>

            <h3>
            Concept Corner 🌌: Game progression provides a roadmap, letting players know where they are and the pinnacle they can reach. Such structure enhances engagement, as each level ups the stakes and delivers fresh challenges.<br/><br/>👾 Galactic Game Tip: To make your cosmic quest even more enthralling, consider adding unique challenges, visuals, or mechanics to each level. This variability ensures that every stage offers a new and exciting experience!<br/><br/>Having established our journey's starting point and its apex, players now have a clear trajectory for their space escapade. As they navigate the cosmos, smashing bricks and avoiding obstacles, they'll always be aware of their current standing and the heights they can reach. Gear up for a multi-leveled, space-age breakout experience! 🚀🌌🎮
            </h3>
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 19: Crafting the Cosmic Paddle 🌌🚀
            </h2>

            <p>
            In the vastness of space, our players need a trusty vessel to navigate through and counter the fast-moving space orbs. Enter the cosmic paddle, the player's primary means of interaction in our breakout universe!
            </p>

            <img src={twentySix} className='demo__image' alt='img_nineteen' />

            <p>
            What it does:
            </p>

            <p>
            🌌 This line constructs our galactic paddle into existence:
            </p>

            <ul>
                <li>
                We utilize the pygame.Rect function to create a rectangular shape that represents our paddle.
                </li>

                <li>
                The paddle's position is set such that it:
                <ul>
                    <li>
                    Horizontally centers at the bottom of our game screen (screen_width - paddle_width) // 2).
                    </li>

                    <li>
                    Has a slight offset from the screen's base (screen_height - paddle_height - 10).
                    </li>
                </ul>
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                The paddle acts as the player's main tool to influence the game. They use it to strike the space orb, guiding its trajectory to smash celestial bricks and navigate challenges.
                </li>

                <li>
                Its strategic placement ensures that players have a fair starting position, ready to engage with the game right away.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                paddle: A clear variable name, serving as our cosmic paddle's reference throughout the game.
                </li>

                <li>
                pygame.Rect: A built-in Pygame function that crafts a rectangular shape. Essential for many in-game objects, including our paddle.
                </li>

                <li>
                The four parameters inside pygame.Rect dictate:
                <ul>
                    <li>
                    X Position: ((screen_width - paddle_width) // 2). This centers the paddle on the screen. 
                    </li>

                    <li>
                    Y Position: screen_height - paddle_height - 10. This places the paddle towards the screen's bottom with a slight margin.
                    </li>

                    <li>
                    Width: paddle_width. Our previously defined width for the paddle.
                    </li>

                    <li>
                    Height: paddle_height. The predetermined height of our paddle.
                    </li>
                </ul>
                </li>
            </ul>

            <h3>
            Concept Corner 🌌: In games, user input is paramount. The paddle serves as the primary medium for player interaction. Its positioning, dimensions, and response to user commands influence the gameplay experience.<br/><br/>👾 Galactic Game Tip: Enhance your game's appeal by tweaking the paddle's dynamics. Consider power-ups that change its size, special effects when it hits the ball, or even a trail that mimics a comet's tail!<br/><br/>With our cosmic paddle forged and set in place, players now possess the means to engage with the space orb, directing it towards the gleaming star bricks. The stage is set for an enthralling space odyssey full of challenges, strategy, and excitement. Prepare for a stellar paddle-play experience! 🌌🚀🎮
            </h3>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 20: The Starlit Sphere - Initiating the Cosmic Ball 🌌🌕
            </h2>

            <p>
            In the celestial dance of our Breakout galaxy, one luminescent orb holds a unique sway. The ball, our gleaming sphere of action, zips through space, colliding with stellar obstacles and charting the course of our cosmic saga!
            </p>

            <img src={twentySeven} className='demo__image' alt='img_twenty' />

            <p>
            What it does:
            </p>

            <p>
            🌌 With this line, we add the radiant cosmic ball into our game realm:
            </p>

            <ul>
                <li>
                We invoke the reset_ball() function, which, as we defined earlier, initializes (or resets) the ball's position and motion direction.
                </li>

                <li>
                By design, the ball starts at the screen's center and moves upwards.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                The ball is pivotal to the Breakout gameplay. Its trajectories, speeds, and collisions determine scores, progression, and outcomes.
                </li>

                <li>
                We need to ensure the ball starts in a predictable manner, providing players with a fair shot right from the get-go. 
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                reset_ball(): A function call. By adding (), we're telling Python to execute the instructions we outlined in the reset_ball function.
                </li>

                <li>
                This sets the ball's position (centered on the screen) and motion direction (upwards).
                </li>
            </ul>

            <h3>
            Concept Corner 🌌: Game elements should be consistent and predictable to provide players with a sense of control and fairness. The reset_ball function offers a standardized way to initiate or reset the ball, ensuring gameplay consistency.<br/><br/>With our cosmic ball now whizzing through the Breakout galaxy, players are in for a thrilling interstellar experience. Each bounce, each collision, each trajectory change crafts a unique tale of strategy, reflexes, and anticipation. Ready, set, let the starlit dance begin! 🌌🌕🎮
            </h3>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 21: Constructing the Constellation - Designing the Star Bricks 🌌✨
            </h2>


            <p>
            Among the cosmic entities of our game, the shimmering star bricks form mesmerizing constellations, challenging players with their intricate arrangements and promising rewards for those who dare to navigate through them!
            </p>

            <img src={twentyEight} className='demo__image' alt='img_twentyOne' />

            <p>
            What it does:
            </p>
            <p>
            🌌 This line weaves the pattern of bricks for our players to engage with:
            </p>

            <ul>
                <li>
                The new_level() function is called with two parameters, 5 and 10, specifying that our constellation will be shaped with 5 rows and 10 columns of bricks.
                </li>

                <li>
                The outcome of this function, an array of these brick objects, is stored in the variable bricks.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                Star bricks represent the primary objectives in our game. Players aim to strategically hit them with the cosmic ball to gain points and progress to subsequent levels.
                </li>

                <li>
                This initial configuration sets the stage for the player, introducing them to the basic gameplay layout.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                bricks: A variable that will hold our array (or list) of star brick objects.
                </li>

                <li>
                new_level(5, 10): A function call to new_level, which is designed to create a specific layout of bricks.
                <ul>
                    <li>
                    5: Specifies the number of rows of bricks.
                    </li>

                    <li>
                    10: Dictates the number of columns.
                    </li>
                </ul>
                </li>
            </ul>

            <h3>
            Concept Corner 🌌: Levels or stages are an age-old concept in gaming. They introduce increasing complexity or variety as players progress, ensuring a dynamic, engaging, and continually challenging gameplay experience.<br/><br/>With our constellation of star bricks now twinkling in our Breakout galaxy, players have clear objectives to aim for. The alignment of these bricks, their colors, and any special properties they might have can turn the gameplay from a simple ball-paddle-brick interaction into an epic cosmic adventure full of surprises, challenges, and rewards! Onwards to the starry challenges! 🌌✨🎮
            </h3>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 22: Galactic Typography - Deciphering the Starry Script 🌌🖋
            </h2>

            <p>
            In our vast cosmos, stories of galaxies far and wide are often written in the stars. As space travelers, we too need a script, a galactic typeface that conveys our achievements and chronicles our adventures in the game. Enter the font of our cosmic tales!
            </p>

            <img src={twentyNine} className='demo__image' alt='img_twentyTwo' />

            <p>
            What it does:
            </p>

            <p>
            🌌 This line chooses a font and style for our game's text:
            </p>

            <ul>
                <li>
                The pygame.font.Font function is used to create a font object. This object will allow us to render text in a specific style and size.
                </li>

                <li>
                pygame.font.get_default_font() fetches the default font style Pygame offers.
                </li>

                <li>
                24 specifies the size of the font. Our cosmic script will be written with a font size of 24 pixels.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                Games often display information like scores, levels, or messages. Having a consistent, readable font ensures players can easily understand and engage with this information.
                </li>

                <li>
                A font that fits the game's theme enhances the overall aesthetics and immersive experience.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                font: A variable assigned to hold our chosen font object.
                </li>

                <li>
                pygame.font.Font(): A Pygame function to create a new font object. It usually takes two parameters:
                <ul>
                    <li>
                    The first parameter is the font style. Here, we use pygame.font.get_default_font() to opt for Pygame's default choice.
                    </li>

                    <li>
                    The second parameter is the size of the font. We've chosen 24, indicating our font will be 24 pixels in height.
                    </li>
                </ul>
                </li>
            </ul>

            <h3>
            Concept Corner 🌌: Typography in gaming is more than just words on a screen. The right choice of font and style can set the game's mood, reinforce its theme, and enhance player immersion.<br/><br/>With the font set, our cosmic chronicles now have a script, a medium to convey scores, levels, messages, and tales of galactic achievements. Ready to pen down your space odyssey? The cosmos awaits your story! 🌌🖋🎮
            </h3>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 23: Infinity Loop - The Everlasting Cosmic Cycle 🌌🔄
            </h2>

            <p>
            The universe is a mysterious expanse where stars are born, shine, and eventually fade, only to be reborn again in a never-ending cosmic cycle. As space explorers, we too embrace a cycle, the game loop, which continually checks, updates, and redraws our gaming universe!
            </p>

            <img src={thirty} className='demo__image' alt='img_twentyThree' />

            <p>
            What it does:
            </p>

            <p>
            🌌 This line initiates an infinite loop, signifying the start of our game's core cycle:
            </p>

            <ul>
                <li>
                The term while begins a loop in Python. Everything indented under this line will repeatedly execute as long as the condition after while holds true.
                </li>

                <li>
                True is a boolean value that is, well, always true! This means everything under this line will keep executing endlessly until an external action (like quitting the game) or an internal command (like a break statement) stops it.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                Games operate on a continuous cycle where they check for player inputs, update game states, detect collisions, and redraw the screen – all in rapid succession to create the illusion of real-time interaction.
                </li>

                <li>
                This main game loop ensures that our Breakout game is constantly active, responsive, and updating.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                while: This keyword introduces a loop in Python. It will repeatedly execute its block of code as long as its condition is met.
                </li>

                <li>
                True: A boolean value representing truth. When used in a while loop, it creates an infinite loop.
                </li>
            </ul>

            <h3>
            Concept Corner 🌌: The main game loop is the heart of most video games. It's what gives games their dynamic nature, responding instantly to player actions, updating visuals, and keeping the game world alive.<br/><br/>With the initiation of the infinite loop, our Breakout cosmos is set in motion, continually evolving, shining, and challenging space travelers. But remember, every cycle has its events, challenges, and stories. Are you ready to experience the galactic events within this cycle? Dive into the loop and let's explore further! 🌌🔄🎮
            </h3>
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 24: Celestial Events - Witnessing Cosmic Phenomena 🌠🔭
            </h2>

            <p>
            Every cosmic cycle in our gaming universe is filled with countless celestial events - from the birth of stars to the intricate dance of galaxies. Similarly, in our game, events like player inputs or system messages occur continuously, and our code must be ever-vigilant to respond to these.
            </p>

            <img src={thirtyOne} className='demo__image' alt='img_twentyFour' />

            <p>
            What it does:
            </p>

            <p>
            🌠 This line starts a loop that will iterate through and process every event that occurs in our game during a specific cycle of our main game loop:
            </p>

            
            <ul>
                <li>
                pygame.event.get() captures a list of all the events that happened since the last cycle of the game loop. This includes keyboard presses, mouse movements, system signals, and more.
                </li>

                <li>
                The for loop then iterates over each of these events, allowing us to handle or respond to them appropriately.
                </li>
            </ul>


            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                A responsive game needs to constantly listen for and react to player inputs (like moving the paddle) and system events (like closing the game window).
                </li>

                <li>
                This event loop ensures our Breakout game is always ready to engage with the player's actions or system's messages.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                for: A keyword that introduces a loop in Python. This loop will iterate over each item in a sequence (in this case, each event).
                </li>

                <li>
                event: A temporary variable that holds the current event being examined in the loop.
                </li>

                <li>
                in: A keyword used in the for loop to indicate the sequence over which the loop will iterate.
                </li>

                <li>
                pygame.event.get(): A Pygame function that fetches a list of all recent events
                </li>
            </ul>

            <h3>
            Concept Corner 🌠: Events in game development are similar to sensory stimuli in humans. Just as our brain continually processes sensory data (sight, sound, touch), a game processes events to determine what's happening and how to respond.<br/><br/>With this event-checking mechanism in place, our cosmic Breakout realm is now attuned to the intricacies of the galaxy – always ready to dance with the stars, respond to the player's wishes, and evolve with the unfolding cosmic tale. Onwards, to the next celestial event! 🌠🔭🎮
            </h3>
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 25: The Galactic Exit - Safeguarding the Escape Pod 🚀🚪✨
            </h2>

            <p>
            In our galactic journey, there are moments when we must exit the mysterious realms and find solace in the familiar. Just as an astronaut has an emergency exit in their spaceship, players need a way to gracefully exit our cosmic game.
            </p>

            <img src={thirtyTwo} className='demo__image' alt='img_twentyFive' />

            <p>
            What it does:
            </p>

            <p>
            🚀 These lines provide the exit mechanism for our game:
            </p>

            <ol>
                <li>
                if event.type == pygame.QUIT: checks if the current event in our event loop (from the previous step) is a "QUIT" event. This event occurs when a player tries to close the game window (e.g., by clicking the close button).
                </li>

                <li>
                pygame.quit() ensures all Pygame modules are cleanly deactivated. This is a graceful shutdown of the game engine, ensuring no errors or hiccups as the game concludes.
                </li>

                <li>
                sys.exit() exits Python, making sure our program fully terminates without lingering processes.
                </li>
            </ol>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                Players need a smooth and error-free method to exit the game.
                </li>

                <li>
                By ensuring we respond to the QUIT event and shut down the game properly, we prevent potential issues or crashes and guarantee a seamless gaming experience.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if: A keyword that begins a conditional statement, checking if a certain condition is true.
                </li>

                <li>
                event.type: Refers to the type of the current event being checked in our event loop.
                </li>

                <li>
                pygame.QUIT: A constant in Pygame representing the event when a player wants to exit the game.
                </li>

                <li>
                pygame.quit(): A Pygame function that deactivates all Pygame modules, ensuring a clean game exit.
                </li>

                <li>
                sys.exit(): A function from the sys module (remember we imported it at the start) that exits Python.
                </li>
            </ul>

            <h3>
            Concept Corner 🚀: Just as a spacecraft's integrity is paramount for astronauts, the stability and responsiveness of a game are vital for players. A game that handles exits smoothly respects the player's intentions and offers a polished experience.<br/><br/>With the galactic exit in place, explorers can journey through the Breakout universe knowing they have a safe passage back home when they desire. As custodians of this cosmic realm, it's our duty to ensure every aspect of the journey, even the exit, is stellar. Let's continue our voyage and unveil more mysteries of our game universe! 🚀🚪✨🎮
            </h3>
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 26: Paddle Propulsion - Engage Hyperdrive! 🌌🛸💫
            </h2>

            <p>
            In our astral arena, our trusty paddle is not just a piece of wood—it's our spaceship, our last line of defense against cascading cosmic orbs. For our ship to dodge or intercept these orbs, we need to ensure it's equipped with the most responsive propulsion system in the galaxy!
            </p>

            <img src={thirtyThree} className='demo__image' alt='img_twentySix' />

            <p>
            What it does:
            </p>

            <p>
            🛸 This line checks which keys on the keyboard are currently being pressed:
            </p>

            <ul>
                <li>
                pygame.key.get_pressed() returns a list that represents the state of each key on the keyboard. If a key is pressed, its corresponding position in the list will be True, otherwise, it'll be False.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                In our cosmic game of Breakout, players need to move the paddle (our spaceship) left or right to deflect the ball. This movement is controlled using the keyboard's left and right arrow keys.
                </li>

                <li>
                By capturing the state of all keys on the keyboard, we can effortlessly detect if the player is pressing the arrow keys, thus enabling the paddle's movement.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                keys: A variable where we store the list representing the state of all keyboard keys.
                </li>

                <li>
                pygame.key: This refers to the Pygame module responsible for handling keyboard input.
                </li>

                <li>
                get_pressed(): A method within the pygame.key module that fetches the state of every key on the keyboard.
                </li>
            </ul>

            <h3>
            Concept Corner 🛸: Imagine being in a spaceship racing through nebulas and dodging asteroids. The responsiveness and accuracy of the control panel (in our case, the keyboard) can be the difference between a successful voyage and a cosmic collision!<br/><br/>By syncing our game with the player's commands, we're ensuring that our Breakout universe responds to every pulse, every heartbeat of the player. As we delve deeper, let's equip ourselves with more knowledge and tools to sculpt this universe further! 🌌🛸💫🎮
            </h3>
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 27: The Space Racer - Navigating the Nebula Corridors 🚀⬅️➡️
            </h2>

            <p>
            In the vastness of space, dodging incoming threats is a skill every astronaut must master. And in our Breakout universe, that means moving our spaceship (the paddle) swiftly across the screen to deflect incoming orbs. The speed and precision with which our players maneuver this craft depend entirely on the cosmic pathways we design for them!
            </p>

            <img src={thirtyFour} className='demo__image' alt='img_twentySeven' />

            <p>
            What it does:
            </p>

            <p>
            🚀 These lines control the movement of the paddle (our spaceship) to the left:
            </p>

            <ol>
                <li>
                if keys[pygame.K_LEFT]: Checks if the left arrow key (pygame.K_LEFT) is currently pressed.
                </li>

                <li>
                and paddle.left &gt; 0: Ensures that the left edge of the paddle hasn't reached the left edge of the screen. If the paddle's left edge is already at the screen's leftmost point (0 pixels), we don't want it to move further left.
                </li>

                <li>
                paddle.left -= paddle_speed: Moves the paddle to the left by reducing its left edge's position by paddle_speed pixels.
                </li>
            </ol>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                It's imperative in our cosmic Breakout adventure that players can move the paddle swiftly to counter the ball's unpredictable movements.
                </li>

                <li>
                These lines allow players to move the paddle to the left, ensuring they have control and flexibility in defending against the ball's assault.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                keys[pygame.K_LEFT]: This checks the state of the left arrow key within the keys list we captured in the previous step.
                </li>

                <li>
                paddle.left: Represents the x-coordinate of the paddle's left edge.
                </li>

                <li>
                &gt; 0: A condition that checks if a number is greater than zero.
                </li>

                <li>
                -=: A shorthand operator that decreases the value of a variable. Here, it reduces the paddle.left value by paddle_speed, moving the paddle left.
                </li>
            </ul>

            <h3>
            Concept Corner 🚀: Just as navigational prowess is crucial for astronauts in a treacherous asteroid belt, the ability to move the paddle swiftly and precisely is key for players in our Breakout galaxy. Their ability to respond to threats (like the ball hitting the base) is amplified by the seamless controls we provide.<br/><br/>👾 Galactic Game Tip: Always ensure players feel in control. Their connection with the game is mediated through these controls. When they press a button, and the game responds instantly and accurately, it builds trust and immersion.<br/><br/>As our space racers navigate the nebula corridors, it's our duty as galactic game developers to ensure their journey is smooth, intuitive, and responsive. With these lines in place, our players have one more tool in their arsenal to conquer the Breakout cosmos. Onward to more cosmic coding! 🚀⬅️➡️🎮
            </h3>
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 28: Cosmic Boosters Engaged! - Dodging Star Clusters to the Right 🚀⭐
            </h2>

            <p>
            In our celestial odyssey, the cosmos is filled with unpredictable twists and turns. As our spaceship (the paddle) zips across the galactic screen, it's vital we offer players the agility to dart in any direction. Here, we'll enable them to steer the paddle towards the starry vastness of the right!
            </p>

            <img src={thirtyFive} className='demo__image' alt='img_twentyEight' />

            <p>
            What it does:
            </p>

            <p>
            🌌 These lines control the rightward movement of our spaceship:
            </p>

            <ol>
                <li>
                if keys[pygame.K_RIGHT]: Detects if the right arrow key (pygame.K_RIGHT) is actively pressed.
                </li>

                <li>
                and paddle.right &lt; screen_width: This confirms that the right edge of the paddle hasn't touched the right boundary of the screen. If the paddle's right edge is already at the screen's furthest right point (screen_width pixels), it should remain stationary.
                </li>

                <li>
                paddle.left += paddle_speed: This propels the paddle to the right, increasing its left edge's position by paddle_speed pixels.
                </li>
            </ol>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                The vastness of space is filled with unknown challenges. Our players need the dexterity to move the paddle to the right and intercept the unpredictable ball trajectories.
                </li>

                <li>
                This code offers them the necessary control to adjust and respond, ensuring a dynamic and immersive gaming experience.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                keys[pygame.K_RIGHT]: This inspects the status of the right arrow key within the keys array, which we examined earlier.
                </li>

                <li>
                paddle.right: Denotes the x-coordinate of the paddle's right edge.
                </li>

                <li>
                &lt; screen_width: A condition that checks if a number is less than the screen's width (screen_width).
                </li>

                <li>
                +=: An abbreviation that increases the value of a variable. Here, it augments the paddle.left value by paddle_speed, making the paddle zoom to the right.
                </li>
            </ul>

            <h3>
            Cosmic Knowledge Cluster 🌌: Just as interstellar pilots need impeccable control when dodging asteroid belts or approaching starbases, our Breakout spacefarers should feel in total command. The gameplay's responsiveness (like moving the paddle instantly when a key is pressed) fosters a deeper player-game bond.<br/><br/>🛸 Space Sage Advice: In the gaming universe, responsiveness is paramount. Players should feel that their inputs are acknowledged immediately. This enhances gameplay immersion and ensures players feel connected to the virtual cosmos they're navigating.<br/><br/>With these lines incorporated, our players can now evade obstacles and navigate challenges both to the left and right with equal aplomb. The cosmos is vast, and every direction holds its unique adventures. Gear up for the next cosmic coding leap! 🚀⭐🎮
            </h3>
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 29: Intergalactic Ball Dynamics - The Dance of Stars 🌠💫
            </h2>

            <p>
            In this cosmic code-a-thon, our bouncing ball represents a wandering star, shimmering and dancing through space. Every time our spaceship (the paddle) makes contact or the star encounters celestial boundaries, its trajectory alters. Let's dive deep into the heart of the cosmos and understand these astral maneuvers.
            </p>

            <img src={thirtySix} className='demo__image' alt='img_twentyNine' />

            <p>
            🌠 Star's Motion:
            </p>

            <ol>
                <li>
                ball.left += ball_dx: Shifts our glowing star (the ball) horizontally. The movement's direction and magnitude are dictated by ball_dx (positive value moves right, negative value ventures left).
                </li>

                <li>
                ball.top += ball_dy: Navigates our luminescent celestial body vertically. Here, the course and size of the move are steered by ball_dy (positive value descends downwards, and a negative value ascends upwards).
                </li>
            </ol>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                Space isn't static! Stars, planets, and galaxies are constantly in motion, and our game reflects this celestial dynamism.
                </li>

                <li>
                By updating the ball's position, we emulate the unpredictability and thrill of the universe, where every second counts, and every move can lead to uncharted territories or unseen challenges.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                ball.left and ball.top: These are the x (horizontal) and y (vertical) coordinates of our star (the ball), respectively.
                </li>

                <li>
                +=: This nifty operator increases the existing value by a specified amount. So, ball.left gets updated by the value of ball_dx, and ball.top is altered by ball_dy.
                </li>

                <li>
                ball_dx and ball_dy: These variables capture the ball's horizontal and vertical velocities. Their values will decide the direction and speed of our wandering star.
                </li>
            </ul>

            <h3>
            Stellar Knowledge Sphere 🌠: The universe's charm lies in its dynamism. Stars are born, they evolve, and eventually, they perish. In our cosmic game, the ball's motion signifies this unending cycle of celestial events, reminding players of the vastness and wonder of space.<br/><br/>🔭 Cosmic Explorer Tip: Space is all about movement. Whether it's a planet revolving around its star, a comet dashing through the solar system, or galaxies colliding in a grand cosmic dance, motion defines the universe. As you code, remember, you're not just moving pixels; you're emulating the essence of the cosmos!<br/><br/>With these lines set in the code constellation, our game now thrives with the energy and unpredictability of a bustling galaxy. Next, we'll delve deeper into the universe, exploring more coding challenges and celestial phenomena. Buckle up, space coder! 🌌🎮💫
            </h3>
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 30: Cosmic Boundaries - When Stars Collide 🌌🌟
            </h2>

            <p>
            Imagine a shooting star zipping through the vast expanse of space, reflecting off celestial bodies, leaving a trail of stardust. In our cosmic game, we don't have shooting stars, but we have our ball that bounces around, and just like in space, it needs to respect boundaries!
            </p>

            <img src={thirtySeven} className='demo__image' alt='img_thirty' />

            <p>
            What it does:
            </p>

            <ul>
                <li>
                These lines check if our star (the ball) has ventured beyond the left or right boundaries of the screen.
                </li>

                <li>
                If the star hits the left or right edge, its horizontal direction is reversed, simulating a bounce!
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                Just like a spaceship bouncing off an asteroid field's force field, the ball should not simply disappear or continue moving out of the screen when it hits the edge.
                </li>

                <li>
                This mechanism ensures the ball remains within the gameplay area, adding an element of challenge and excitement.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                if ball.left &lt; 0: Checks if the leftmost edge of the ball has gone past the left edge of the screen (which has a coordinate of 0).
                </li>

                <li>
                or ball.right &gt; screen_width: Checks if the rightmost edge of the ball has gone past the right boundary of our game screen (represented by screen_width).
                </li>

                <li>
                ball_dx = -ball_dx: If either of the above conditions is true, this line reverses the horizontal direction of the ball. If ball_dx was positive (moving right), it will become negative (moving left), and vice versa.
                </li>
            </ol>

            <h3>
            Cosmic Knowledge Capsule 🌌: Just like planets have orbits they must not deviate from, our game's ball has its boundaries. This confinement adds a touch of realism and makes our space voyage more intriguing and challenging.<br/><br/>🚀 Galactic Game Builder Tip: Games often play with boundaries, both visible and invisible. It's these boundaries that frame the gameplay, setting rules, and expectations. As our cosmic ball bounces within its boundaries, it offers players a sense of structure amidst the vastness of space.<br/><br/>So, intrepid coder, you've ensured that even in the vast expanse of the virtual universe, there are rules to follow. With every line, our cosmic adventure becomes more thrilling. Ready to dive deeper into the stars? 🌠🕹️🚀
            </h3>
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 31: Celestial Ceiling - The Boundary Above 🌌⛔
            </h2>

            <p>
            As we traverse the cosmos, there are boundaries everywhere. Just as stars have their orbits and galaxies their confines, our game's ball must respect the ceiling of its universe.
            </p>

            <img src={thirtyEight} className='demo__image' alt='img_thirtyOne' />

            <p>
            What it does:
            </p>

            <ul>
                <li>
                These lines of code keep an eye on our starry ball, checking to see if it attempts to break through the upper boundary of our cosmic arena.
                </li>

                <li>
                If our stellar sphere hits the top of the screen, its vertical trajectory is inverted, causing it to ricochet back into the game.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                To ensure our ball remains in the gameplay area, we have to prevent it from leaving through the top. It's like an invisible force field protecting our cosmic space.
                </li>

                <li>
                The bounce-off provides an additional challenge for players, ensuring they remain alert and in constant movement.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                if ball.top &lt; 0: Evaluates if the topmost edge of the ball has ventured past the upper boundary of the screen, which is represented by the coordinate 0.
                </li>

                <li>
                ball_dy = -ball_dy: If the aforementioned condition is true, this piece of magic reverses the vertical movement of the ball. If ball_dy was positive (going downwards), it'll turn negative (going upwards), and vice versa.
                </li>
            </ol>
            <h3>
            Celestial Factoids 🌌: Space may seem infinite, but even in its vastness, there are cosmic boundaries. These boundaries in our game mirror the rules that celestial objects must adhere to in the actual universe.<br/><br/>🚀 Galactic Game Builder Tip: The unseen boundaries of our game are like the black holes of space - invisible yet influential. They provide structure, challenge, and a framework that guides the player's journey. It's these little details that transform a game into a thrilling cosmic adventure.<br/><br/>Future Astronaut, by keeping our ball within the confines of the screen, you're replicating the gravitational pull that governs our cosmos. What cosmic rules will you set next? 🌠🎮🌌
            </h3>
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 32: Paddle's Cosmic Dance - The Meeting Point 🪐💃🌌
            </h2>

            <p>
            In the vast expanse of space, encounters between celestial bodies are momentous. This next step explores the interaction between our ball, the energetic star, and our paddle, the stabilizing force in our galactic game.
            </p>

            <img src={thirtyNine} className='demo__image' alt='img_thirtyTwo' />
            <p>
            What it does:
            </p>

            <ul>
                <li>
                These lines keep track of the interaction between the ball (our miniature star) and the paddle (the player's shield in this cosmic game).
                </li>

                <li>
                If the ball collides with the paddle, its trajectory changes, and it bounces off in the opposite vertical direction.
                </li>
            </ul>

            
            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                Interaction is essential in any game. It's the moments when the ball and paddle meet that offer challenge, excitement, and that sense of accomplishment.
                </li>

                <li>
                Without this rebound mechanic, our ball would simply pass through the paddle, which would not only reduce the challenge of the game but also break its rules and realism.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>
            <ol>
                <li>
                ball.colliderect(paddle): This function checks for a collision between two rectangular objects in Pygame. Here, it determines if our ball has made contact with the paddle.
                </li>

                <li>
                ball_dy = -ball_dy: If the aforementioned collision is detected, this expression reverses the vertical trajectory of the ball, causing a bounce effect.
                </li>
            </ol>

            <h3>
            Interstellar Insights 🌌: The dynamic between celestial bodies is one of attraction and repulsion. Much like our ball and paddle, stars, planets, and other celestial bodies are in a constant dance of gravitational pulls and pushes.<br/><br/>Navigator of the Nebulas, each time our ball meets the paddle, you're witnessing a miniature cosmic ballet, where strategy, reflexes, and laws of motion come to play. How will you direct this dance? 🌠🕹️🪐
            </h3>
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 33: The Galactic Walls 🌌🚧
            </h2>

            <p>
            As we navigate the cosmos, boundaries guide our journey. Sometimes they're the magnetic fields of planets, other times they're the heat of a star. In our game, the walls play this crucial role. Let's examine the left and right boundaries of our galactic arena!
            </p>

            <img src={forty} className='demo__image' alt='img_thirtyThree' />

            <p>
            What it does:
            </p>

            <ul>
                <li>
                This line checks if the ball (our interstellar explorer) has reached the left or right edges of our cosmic screen.
                </li>

                <li>
                If it touches either side, the ball will reverse its horizontal trajectory, mimicking a bounce off a wall.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                Just as space missions need to be aware of and navigate around cosmic bodies and boundaries, our game ball needs borders to provide challenge and maintain its path within the gameplay area.
                </li>

                <li>
                This code ensures that the ball doesn't venture too far out into the cosmic void (outside our screen), keeping the gameplay focused and contained.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                ball.left &lt; 0: Checks if the left side of the ball (the westward edge of our star) has gone past the left boundary of the screen.
                </li>

                <li>
                ball.right &gt; screen_width: Verifies if the right side of the ball (the eastward horizon) has exceeded the right boundary of our gaming galaxy.
                </li>

                <li>
                ball_dx = -ball_dx: If either of the above conditions is met, the ball's horizontal speed and direction are inverted, causing it to "bounce" off the screen edge.
                </li>
            </ol>

            <h3>
            Interstellar Insights 🌌: Space may seem boundless, but even in its vast expanse, there are limits and boundaries. Nebulas, black holes, and magnetic fields dictate the paths of celestial bodies. Similarly, our game has its boundaries, keeping the gameplay engaging and within reach.<br/><br/>🚀 Galactic Game Builder Tip: Boundaries in games, much like in the universe, offer direction, challenge, and sometimes protection. As you create games, think of how you can use boundaries in both conventional and creative ways to guide and challenge your players. Safe travels, Cosmic Coder! 🌠🎮🪐
            </h3>
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 34: Intergalactic Collisions 🌌💥
            </h2>

            <p>
            When heavenly bodies collide in the cosmos, it's not just a celestial event—it creates ripples in the very fabric of spacetime. In our game, when the ball collides with a brick, it's a similar moment of impact.        
            </p>

            <img src={fortyOne} className='demo__image' alt='img_thirtyFour' />

            <p>
            What it does:
            </p>

            <ul>
                <li>
                This line of code checks if our cosmic ball has made contact with any of the space bricks in our gameplay area.
                </li>

                <li>
                colliderect() is a method that checks for a collision between two rectangles. Here, it's used to see if the bounding rectangle of the ball intersects with that of a brick.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                The core challenge of our space-themed Breakout game revolves around breaking bricks using the ball. Every time the ball comes into contact with a brick, a collision is detected, which further guides the game mechanics (e.g., bouncing the ball, removing the brick, updating the score).            </li>

                <li>
                This line ensures that these crucial game events are detected and processed to give our players a thrilling gaming experience.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                ball.colliderect(brick): The method colliderect() belongs to Pygame's Rect class and is used to determine if two rectangles overlap. It returns True if there's an overlap (i.e., a collision) and False otherwise. Here, it's checking the collision between our ball and a given brick.
                </li>
            </ol>

            <h3>
            Galactic Gyan 🌌: In the vast expanse of the universe, collisions between celestial bodies can lead to spectacular events, like the formation of new stars or black holes. Similarly, in our game, each collision between the ball and a brick not only progresses the game but also provides players with a sense of achievement and thrill.<br/><br/>🚀 Astral Artisan Tip: Collision detection is a fundamental concept in game development. Whether it's asteroids colliding in space or characters battling on a faraway planet, understanding how to detect and handle these interactions is vital to creating immersive gameplay. Aim for the stars, but remember, it's the journey and the challenges along the way that make the adventure truly memorable! 🌠🎮🌟
            </h3>
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 35: Celestial Consequences 🌌💫
            </h2>

            <p>
            Space is vast and unpredictable! When stars collide, they can create powerful supernovas. In our game, when the cosmic ball crashes into a space brick, a series of chain reactions ensue. Let's dive into what happens post-collision!
            </p>

            <img src={fortyTwo} className='demo__image' alt='img_thirtyFive' />

            <p>
            What it does:
            </p>

            <ul>
                <li>
                bricks.remove(brick): This line of code removes the brick that was hit by the ball from the list of bricks. This gives the illusion that the ball has 'destroyed' the brick upon collision, allowing the player to progress in the game.
                </li>

                <li>
                ball_dy = -ball_dy: This modifies the direction of the ball's vertical movement. If the ball was moving upward, it'll now move downward and vice versa. It's the game's way of making the ball bounce away from the brick after a collision.
                </li>

                <li>
                score += 10: Each collision with a brick rewards the player with points. This line increases the player's score by 10 points for each brick hit.
                </li>

                <li>
                break: This line exits the loop that's checking for collisions with bricks. Once a collision is found and processed, there's no need to continue checking the remaining bricks during this loop cycle.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                It's essential to give players a visual reward and sense of progress. By removing the brick, players can see the impact of their actions, motivating them to continue playing.
                </li>

                <li>
                A real-life ball bounces away when it hits an obstacle. Similarly, in our game, to mimic the physics of an actual ball and to ensure gameplay continuity, the ball's direction is reversed upon hitting a brick.
                </li>

                <li>
                A scoring system is integral to most games as it provides a measure of success and a challenge for players. Earning points for hitting bricks makes the game competitive and adds an extra layer of fun.
                </li>

                <li>
                By using the break statement, we optimize our game's performance. Instead of continuing to check for further collisions in this frame, we save computational resources by exiting the loop as soon as a collision is detected.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                bricks.remove(brick): The remove() method is used to delete a specified item from a list.
                </li>

                <li>
                ball_dy = -ball_dy: This line inversely modifies the value of ball_dy. If ball_dy was 5, it becomes -5, and if it was -5, it becomes 5.
                </li>

                <li>
                score += 10: The += operator increases the value of the variable on its left by the value on its right.
                </li>

                <li>
                break: This keyword is used to exit a loop prematurely.
                </li>
            </ol>

            <h3>
            Galactic Gyan 🌌: Actions have consequences, both in the cosmos and in coding. As in the universe, where a star's explosion can birth new celestial bodies or cause a black hole, in our game, the ball's collision with a brick triggers multiple outcomes, creating a rich and interactive game environment.<br/><br/>🚀 Astral Artisan Tip: Feedback loops are essential in game design. Whether it's the removal of a brick, the bounce of a ball, or the increase in score, these responses to player actions make the game more immersive and engaging. Always remember, it's the little details that transform a simple game into a cosmic adventure! 🌠🕹️🌌
            </h3>
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 36: Starship Status - Levels and Landing! 🚀🪐
            </h2>

            <p>
            Space, infinite and expansive, is full of mysteries waiting to be unraveled. Just as our astronaut advances through galaxies, it's crucial for our game to keep track of the player's progress. Let's inspect this intriguing interstellar inquiry!
            </p>

            <img src={fortyThree} className='demo__image' alt='img_thirtySix' />

            <p>
            What it does:
            </p>


            <ol>
                <li>
                This line checks two conditions simultaneously:
                <ul>
                    <li>
                    Whether there are no bricks left in the bricks list. This is done by checking if the length of the list bricks is 0.
                    </li>

                    <li>
                    Whether the current game level (level) is less than the maximum levels allowed in the game (max_levels).
                    </li>
                </ul>
                </li>
            </ol>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                As our astronaut clears challenges, they advance to new frontiers, symbolizing their growth and mastery. In our game, the player progresses to the next level when they clear all bricks. However, they should only advance if they haven't already reached the maximum level. This line ensures that the player's progress is correctly managed.
                </li>

                <li>
                By limiting the number of levels with max_levels, we ensure the game has an endpoint. This provides a measurable challenge for players to aspire towards, rather than an endless journey through space.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                len(bricks): This fetches the number of elements (in our case, bricks) within the bricks list.
                </li>

                <li>
                == 0: Checks if the number of bricks left is zero.
                </li>

                <li>
                and: A logical operator in Python used to combine conditional statements. Both conditions on either side of the and must be True for the entire statement to be True.
                </li>

                <li>
                level &lt; max_levels: This checks if the current game level is less than the maximum levels set for the game.
                </li>
            </ul>

            <h3>
            🌌 Galactic Glimpse: Like any cosmic explorer, milestones motivate movement. By providing achievable targets in the form of levels, we set our astronaut on a path of discovery and challenge, ensuring their journey through the galaxies is both thrilling and rewarding. Onward to the next cosmic challenge! 🌠🚀🌌
            </h3>
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 37: Galactic Growth! 🌌🚀🌠
            </h2>

            <p>
            As our valiant voyager ventures further into the vast void of space, challenges escalate, making the cosmic conquest even more compelling. The universe offers more intricate asteroid fields and faster-moving celestial bodies. Ready for a stelliferous surge in space?
            </p>

            <img src={fortyFour} className='demo__image' alt='img_thirtySeven' />

            <p>
            What it does:
            </p>

            <ul>
                <li>
                level += 1: This elevates our cosmic explorer to the next level. It's equivalent to saying "add 1 to the current level value."
                </li>

                <li>
                bricks = new_level(5 + level, 10): Each time the player progresses to a new level, a fresh pattern of space bricks is prepared for them. This function (new_level) crafts a new array of bricks. The number of rows of bricks is influenced by the current level, ensuring escalating complexity.
                </li>

                <li>
                reset_ball(): After leveling up, the ball's position and velocity are reset to their initial state. This ensures our cosmic ball is ready for the new challenges awaiting it.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ol>
                <li>
                Rewarding progress is vital. This line provides a sense of achievement and motivates players to continue their quest.
                </li>

                <li>
                Variety is the spice of space life! By dynamically adjusting the number of brick rows based on the player's current level, the game remains fresh, unpredictable, and progressively challenging.
                </li>

                <li>
                It's essential to reposition our cosmic ball to a neutral state before diving into a new level. This ensures fairness and equips players to face the new array of bricks from a familiar starting position.
                </li>
            </ol>
            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                +=: This is a shorthand operator in Python. Instead of writing level = level + 1, we use += to achieve the same effect.
                </li>

                <li>
                new_level(...): This function, defined earlier, takes in the number of rows and columns to create a fresh formation of bricks. The number of rows (5 + level) increases with each level, making it progressively challenging.
                </li>

                <li>
                reset_ball(): This function repositions the ball to the center of the screen and gives it a default velocity, preparing it for the upcoming challenges.
                </li>
            </ul>

            <h3>
            🌌 Galactic Glimpse: Like an astronaut ascending through unknown territories, the player's journey should be sprinkled with challenges, surprises, and rewards. These lines ensure that with each victory, the horizon expands, offering a tantalizing taste of the next tantalizing trial in the tapestry of the cosmos. 🪐🛸🌌
            </h3>
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 38: Space Catastrophe! 🌌🚀🌋
            </h2>

            <p>
            The universe is vast, filled with wonders and hazards. Sometimes, our cosmic ball may plummet into the unknown abyss of space, beyond the boundaries of our screen. But what happens when such a calamity befalls?
            </p>

            <img src={fortyFive} className='demo__image' alt='img_thirtyEight' />

            <p>
            What it does:
            </p>

            <ul>
                <li>
                🌋 Galactic Abyss Check: This line of code checks whether the top edge of our cosmic ball has descended past the bottom boundary of our screen (or game window).
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                🌌 Cosmic Consequences: In the world of gaming, actions have consequences. If our player doesn't intercept the cosmic ball with the paddle, the ball plunges into the space abyss. By adding this line, we're setting up a mechanism to detect this unfortunate event. It's essential to ensure that the game provides feedback and reacts to such occurrences, enhancing player engagement and offering challenges.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>
            

            <ul>
                <li>
                if ...:: The foundation of decision-making in Python! It evaluates the condition provided, and if that condition is True, it will proceed with the subsequent code block.
                </li>

                <li>
                ball.top: This accesses the top position of our ball object. It provides the y-coordinate of the ball's top edge.
                </li>

                <li>
                &gt; screen_height: This checks if the aforementioned y-coordinate exceeds the total height of our game window (space universe!). If it does, our ball has indeed crossed the boundary into the unknown abyss.
                </li>
            </ul>
            
            <h3>
            🌌 Galactic Glimpse: While our space explorers navigate the vast cosmos, mishaps are bound to occur. This line of code prepares our game to react, empathize, and challenge the player when the cosmic ball drifts into the celestial void. Remember, every great space tale has its moments of suspense and thrill! 🌠🛸🌋
            </h3>
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 39: The Void Consumes All! 🌌🚀💥
            </h2>

            <p>
            Oh no! The cosmic ball has ventured too far, plummeting into the depths of the infinite space abyss. Just like stars facing their final moments, our game must come to a temporary end. But fear not, because endings also mean new beginnings!
            </p>

            <img src={fortySix} className='demo__image' alt='img_thirtyNine' />

            <p>
            What it does:
            </p>

            <p>
            🚀 Farewell, Universe: These two lines instruct the game (our universe) to gracefully shut down and exit when the ball has fallen beyond the screen.
            </p>

            <p>
            Why it's used:
            </p>

            <p>
            🌌 The Cosmic Cycle: Games, like cosmic entities, have life cycles. To maintain immersion, games should respond to player actions. When the cosmic ball drops into the void, it signals a game over state, providing a definitive ending to the player's current session.
            </p>


            <h3>
            Syntax Breakdown:
            </h3>
            
            <ol>
                <li>
                pygame.quit()
                <ul>
                    <li>
                    Duty: This command tells Pygame to uninitialize all its modules. It's a cleanup step to ensure all resources Pygame used get freed up.
                    </li>

                    <li>
                    Impact: Though the game window will close, the Python program might still run in the background if we don't complement this with another exit command.
                    </li>
                </ul>
                </li>

                <li>
                sys.exit()
                <ul>
                    <li>
                    Journey's End: It signals to the system that our Python script wants to exit.
                    </li>

                    <li>
                    Significance: This is especially important if we're running our game in an environment that needs to know when our script finishes, like an integrated development environment (IDE). By invoking this, we ensure our game completely stops running.
                    </li>
                </ul>
                </li>
            </ol>

            <h3>
            🌌 Galactic Glimpse: Space exploration teaches us that even in failure, there's beauty and knowledge. These lines offer a chance for players to reflect on their journey, learn from their errors, and return stronger, ready to face the challenges of the cosmos once again! 💪🚀🌠
            </h3>
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 40: The Cleansing Nebula 🌌🌀✨
            </h2>

            <p>
            After every cosmic storm or celestial event, space undergoes a rejuvenation phase where cosmic dust and remnants are cleared away. Our game screen needs a similar refreshing phase, where we cleanse the remnants of the past frame to make way for a new one.
            </p>

            <img src={fortySeven} className='demo__image' alt='img_forty' />

            <p>
            What it does:
            </p>

            <ul>
                <li>
                Space Wipe: This command fills the entire game screen with a single color, effectively "resetting" or "clearing" everything that was previously drawn on it.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>


            <ul>
                <li>
                🌌 Stellar Refresh: As the game progresses, objects like the paddle, ball, and bricks move around. To animate these movements, we need to redraw the objects in their new positions in every frame. But before we can do that, we need to clear the old frame, and that's what this line achieves.
                </li>

                <li>
                ✨ The Constant Cosmos: It ensures that the game screen is consistently updated, preventing any ghostly remnants or images of the previous frame from appearing.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                screen.fill(...)
                <ul>
                    <li>
                    Nebular Function: fill is a method (a function attached to an object) provided by Pygame for surfaces. Our game screen is a type of surface.
                    </li>

                    <li>
                    Cosmic Color: BACKGROUND_COLOR is a tuple we defined earlier that contains RGB values (Red, Green, Blue). This tuple determines the color with which the screen is filled. In our game, it represents a deep-space hue to keep in line with our galactic theme.
                    </li>
                </ul>
                </li>
            </ol>

            <h3>
            🌌 Galactic Glimpse: Space, with its vast emptiness, serves as a canvas for the marvels of the universe, from shooting stars to swirling galaxies. Similarly, our game screen, once cleared, becomes a canvas for the dynamic, ever-changing gameplay! 🎮🌠✨
            </h3>
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 41: The Grid Galaxy 🌌🔲🌟
            </h2>

            <p>
            In the vast expanse of space, navigation aids such as star maps and grid systems are essential for interstellar travelers. Our game borrows a similar concept to offer a grid-like backdrop, helping players understand movement within this celestial arcade.
            </p>

            <img src={fortyEight} className='demo__image' alt='img_fortyOne' />

            <p>
            What it does:
            </p>

            <ul>
                <li>
                🔲 Stellar Grid Creation: These two lines of code work together to draw vertical lines across the width of our game screen, creating a grid-like appearance.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                🌌 Navigational Nebula: The grid acts as a subtle background, providing visual structure without distracting from the primary gameplay. It's like the twinkling stars that guide space travelers without overshadowing the mesmerizing beauty of celestial bodies.
                </li>

                <li>
                🌟 Cosmic Consistency: Providing consistent spatial references can enhance the player's perception of movement and depth in the game, much like how astronauts use stars to gauge distances in space.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                for x in range(0, screen_width, 40):
                <ul>
                    <li>
                    Galactic Loop: This for loop iterates over a sequence of numbers representing pixel positions along the width of the screen.
                    </li>

                    <li>
                    Star Steps: Using range with three arguments, the loop starts at pixel 0, ends at screen_width, and increments by 40 pixels each time. This spacing gives our grid a consistent pattern.
                    </li>
                </ul>
                </li>

                <li>
                pygame.draw.line(...)
                <ul>
                    <li>
                    Nebular Needle: This function draws a straight line on a given surface. In our case, it's drawing vertical lines on our game screen.
                    </li>

                    <li>
                    Cosmic Color: (50, 50, 50) is an RGB tuple representing a dark shade, subtly different from our background, perfect for our grid lines.
                    </li>

                    <li>
                    Stellar Start and End: (x, 0) and (x, screen_height) specify the starting and ending coordinates of each line. This ensures each line starts from the very top (0) and goes down to the very bottom (screen_height) of our screen, at different horizontal positions (x).
                    </li>
                </ul>
                </li>
            </ol>

            <h3>
            🌌 Galactic Glimpse: The universe is a vast space, and having reference points is essential. Think of this grid as our game's very own constellation, guiding players through their breakout journey! 🚀🌠🔲
            </h3>
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 42: The Horizontal Horizon 🌠📏🌌
            </h2>

            <p>
            After delving deep into the vertical expanse of our game universe, it's time to traverse the horizontal dimensions. Let's spread a horizon of lines, reminiscent of stardust trails, throughout our galactic game screen.
            </p>

            <img src={fortyNine} className='demo__image' alt='img_fortyTwo' />

            <p>
            What it does:
            </p>

            <ul>
                <li>
                📏 Celestial Latitude Lines: These lines of code conjure horizontal lines across the screen's height, crafting an intersecting matrix when combined with our previous vertical lines.
                </li>
            </ul>

            <ul>
                <li>
                🌌 Galactic Grid Expansion: Our grid grows richer in detail and structure, offering a more immersive environment for players.
                </li>

                <li>
                🌠 Nebular Navigation: Horizontal lines accentuate the vertical, producing a harmonious equilibrium that facilitates orientation and movement perception.
                </li>

                <li>
                🌠 Starlit Symmetry: Aesthetically, it mirrors our vertical grid, adding a touch of symmetry to our gameplay space.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                for y in range(0, screen_height, 40):
                <ul>
                    <li>
                    Interstellar Iteration: The loop journeys through pixel positions along the screen's height.
                    </li>

                    <li>
                    Astral Advancement: Beginning at pixel 0, moving towards screen_height, progressing by 40 pixels, it paves a pathway of equidistant horizontal lines.
                    </li>
                </ul>
                </li>

                <li>
                pygame.draw.line(...)
                <ul>
                    <li>
                    Horizon Highlighter: Entrusted with the task of crafting straight lines on the game canvas, it now illustrates horizontal wonders.
                    </li>

                    <li>
                    Stellar Shade: (50, 50, 50) remains our chosen RGB hue, maintaining visual consistency with the vertical lines.
                    </li>

                    <li>
                    Galactic Genesis and Termination: (0, y) to (screen_width, y) outlines each line's inception and conclusion. Originating from the screen's left edge and concluding at its right, they're drawn at diverse vertical altitudes, represented by y.
                    </li>
                </ul>
                </li>
            </ol>

            <h3>
            🌠 Cosmic Consideration: Much like in the grand cosmos where galaxies span across both vertical and horizontal planes, our game too enjoys a diverse array of orientations, each adding to its celestial charm. Onward to the next horizon! 🌌🚀📏
            </h3>
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 43: Paddle's Galactic Glow ✨🚀🛸
            </h2>
            
            <p>
            The paddle is not just a tool; it's our spaceship. It must stand out, bold against the backdrop of the universe, to ensure it serves as a guiding light for players.
            </p>

            <img src={fifty} className='demo__image' alt='img_fortyThree' />

            <p>
            What it does:
            </p>

            <ul>
                <li>
                🚀 Rectangular Resplendence: The first line adds a rectangular form to our paddle, radiating a deep blue glow that harmonizes with our cosmic theme.
                </li>

                <li>
                🛸 Elliptical Elegance: The subsequent line overlays an elliptical aura to the paddle, offering a softened, rounded appearance, reminiscent of celestial bodies.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>


            <ul>
                <li>
                ✨ Visual Vibrance: By incorporating both a rectangle and an ellipse with identical dimensions, our paddle shines with a unique dual-layered design.
                </li>

                <li>
                🛸 Distinctive Drive: This combined visual serves a dual purpose: it ensures the paddle is easily distinguishable amidst the myriad of screen elements and adds an element of style to our spaceship.
                </li>

                <li>
                🌌 Cosmic Cohesion: The chosen color (a shade of blue) resonates with the tranquil hues of the universe, ensuring our paddle doesn't feel out of place in its cosmic journey.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                pygame.draw.rect(screen, (0, 128, 255), paddle)
                <ul>
                    <li>
                    Galactic Geometry: Entrusted with drawing a rectangle, this function maps out our paddle's core form.
                    </li>

                    <li>
                    Starry Shade: (0, 128, 255) is the chosen RGB color - a serene shade of blue that mirrors the vastness of the space.
                    </li>

                    <li>
                    Paddle Parameters: The term paddle here represents a Pygame rectangle object, dictating the size and position of our drawn rectangle.
                    </li>
                </ul>
                </li>

                <li>
                pygame.draw.ellipse(screen, (0, 128, 255), paddle)
                <ul>
                    <li>
                    Celestial Curve: This function, with its elliptical magic, smoothens our paddle's sharp edges, bestowing it with an astral aura.
                    </li>

                    <li>
                    Matching Mystique: It shares the same color and screen coordinates as the rectangle, leading to an aligned, overlapping design.
                    </li>
                </ul>
                </li>
            </ol>

            <h3>
            🌌 Starry Thought: Every spaceship deserves its moment in the spotlight. In the vast cosmos of our game, the paddle now boasts an aura that's hard to miss. Let's steer it with pride! 🚀✨🛸
            </h3>
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 44: Drawing the Bricks 🧱🌌
            </h2>

            <p>
            Let's journey deeper into the fabric of our cosmic arena! As stars guide our paths in the vastness of space, our game has bricks to channel players' skills and determination. Now, we'll focus on bringing these bricks into the universe of our game.
            </p>

            <img src={fiftyOne} className='demo__image' alt='img_fortyFour' />

            <p>
            What it does:
            </p>

            <ul>
                <li>
                🌌 Starry Structures: Using a loop, we iterate through each brick stored in our bricks list.
                </li>

                <li>
                🧱 Celestial Building Blocks: Within this loop, we command our game engine to draw each brick on our game screen as a blue rectangle.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                ✨ Astral Challenge: Just as space explorers navigate through meteoroids and celestial objects, players have to maneuver through these bricks. The bricks serve as the primary challenge; players must strategically bounce the ball to break them.
                </li>

                <li>
                🔵 Visual Delight: The shade of blue chosen provides a calming, space-like feel and offers contrast against the deep background of our game, ensuring players can easily locate and aim for them.
                </li>
            </ul>

            <h3>
            Syntax breakdown:
            </h3>

            <ol>
                <li>
                for brick in bricks:: A loop that goes through each brick in our bricks list.
                </li>

                <li>
                pygame.draw.rect(screen, BLUE, brick): The Pygame function used to draw rectangles.

                <ul>
                    <li>
                    screen: Specifies where we want to draw the rectangle (on our main game screen).
                    </li>

                    <li>
                    BLUE: The color of the rectangle (we defined this earlier as a tuple representing blue).
                    </li>

                    <li>
                    brick: The rectangle object to be drawn (which represents a brick in our game).
                    </li>
                </ul>
                </li>
            </ol>

            <h3>
            🌌 With these lines of code, our cosmic bricks are now displayed, waiting for the astral ball to touch and illuminate them. Onwards to more galactic gameplay! 🚀🌟
            </h3>
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 45: Stellar Score Display 🌌🔢
            </h2>

            <p>
            In the cosmic game of breakout, players are driven by the thrill of the game and the motivation to top their own best scores. It's time to illuminate their achievements on the screen.
            </p>
            
            <img src={fiftyTwo} className='demo__image' alt='img_fortyFive' />

            <p>
            What it does:
            </p>

            <ul>
                <li>
                The line score_text = ... is an artistic alchemy that converts our player's score into a format ready for display.
                </li>

                <li>
                We use the font object (a typography style that we defined earlier) and its render method to craft a displayable representation of the player's current score.
                </li>

                <li>
                The f-string f"Score: &#10100;score&#10101;" is our cosmic spell that embeds the current value of the score variable right into the string. For instance, if a player's score is 42, the galaxy's magical number, the text will read Score: 42.
                </li>

                <li>
                The True argument is a touch of elegance; it enables antialiasing, making our stardust-text smooth and aesthetically pleasing.
                </li>

                <li>
                WHITE determines the color of our text, as luminous as a star in the deep cosmic void.
                </li>

                <li>
                The universe is our canvas, and the screen.blit(...) line is our paintbrush. We're placing our score onto the screen at the coordinates (10, 10).
                </li>

                <li>
                The top-left corner of the game window is our origin point (0,0). Thus, the score will appear 10 pixels from the left and 10 pixels from the top, floating like a constellation in the night sky.
                </li>
            </ul>

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                Keeping Players Engaged 🎮: Displaying the score is a feedback mechanism that rewards the player for their achievements in real-time. This real-time feedback reinforces positive actions and keeps players engaged.
                </li>

                <li>
                Cosmic Visibility 🌠: In a game, any ongoing information, like the score, must remain visible and clear to the player. It gives the player a sense of achievement and a target to beat.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                font.render(): This method is used to create a surface with the specified text rendered on it. It takes in three main arguments.
                </li>

                <li>
                screen.blit(): This method is used to draw one image onto another. It's an integral part of Pygame that allows us to project visuals onto our game screen. Here, we are drawing the score_text onto our main screen.
                </li>
            </ul>

            <h3>
            With the score now shining brightly, the player is ever aware of their cosmic achievements and is ready to soar to even greater heights! 🚀🌠
            </h3>
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 46: Lighting Up The Nebula Levels 🌌🌠
            </h2>

            <img src={fiftyThree} className='demo__image' alt='img_fortySix' />

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                Crafting the Starry Message 🌌✍️:
                <ul>
                    <li>
                    font.render(): This function transmutes the raw string data into a graphical text that can be showcased on our screen.
                    </li>

                    <li>
                    f"Level: &#10100;level&#10101;": A formatted string that includes the word "Level" followed by the actual level number. This keeps the player informed of their progress.
                    </li>

                    <li>
                    True: This flag turns on anti-aliasing for the text, making it smoother and more visually pleasing.
                    </li>

                    <li>
                    WHITE: An RGB color constant, ensuring the level text is bright and easily visible against the game's backdrop.
                    </li>
                </ul>
                </li>

                <li>
                Positioning in the Game Universe 🌌📍:
                <ul>
                    <li>
                    screen.blit(): This method allows us to draw the level_text onto our main game screen.
                    </li>

                    <li>
                    level_text: This is the text we rendered in the previous line, now ready to shine!
                    </li>

                    <li>
                    (10, 40): Coordinates that dictate where on the screen the level text will appear. Placing it slightly down from the top-left corner ensures it's both visible and unobtrusive.
                    </li>
                </ul>
                </li>
            </ol>

            <h3>
            Now, as players navigate the celestial challenges, they'll always have a bright reminder of how far they've journeyed and the levels they've mastered! 🌟🎮🌠
            </h3>
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 47: Flipping the Display Universe 🌌🔄
            </h2>

            <img src={fiftyFour} className='demo__image' alt='img_fortySeven' />

            <p>
            Why it's used:
            </p>

            <ul>
                <li>
                Celestial Refresh 🌠🔄: Just as stars in our universe constantly shimmer and renew their glow, our game's display needs periodic refreshing to reflect changes, such as moving the paddle, bouncing the ball, or breaking bricks.
                </li>

                <li>
                Real-time Feedback 🕹️✨: To ensure that the player sees the immediate result of their actions, like the updated score or a vanquished brick, we need to update the display continually. This function is that bridge between the player's actions and the visual feedback on the screen.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                pygame.display.flip(): This Pygame function updates the entire screen. Anytime we draw something new on the screen or wish to show a change, it will remain hidden in the backstage of our gaming universe until we call this function. With flip(), we bring the changes from the shadows into the light, showcasing them prominently in the player's view.
                </li>
            </ul>

            <h3>
            With this magical line, the actions and events in our gaming cosmos are continually reflected, ensuring the player stays engrossed in the stellar dance of ball, bricks, and paddle! 🌌🎮🌟
            </h3>
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 48: The Cosmic Pause Button 🌌⏳
            </h2>

            <img src={fiftyFive} className='demo__image' alt='img_fortyEight' />
            <p>
                Why it's used:
            </p>

            <ul>
                <li>
                Temporal Control 🌠⏳: The universe has its own rhythm, and so does our game. While celestial bodies move at their own pace, we often need to control the speed of our game elements to ensure a smooth and enjoyable experience for the player.
                </li>

                <li>
                Preventing Lightspeed Movement 🚀🚫: Without this delay, our game loop (the very heartbeat of our game's universe) would run at warp speed! It might cause the ball to move too quickly for our mere mortal reflexes, making the game near impossible to play.
                </li>
            </ul>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                pygame.time.delay(milliseconds): In our cosmic arcade, this function tells the game to pause for a certain number of milliseconds. It's like telling the stars to hold their position briefly before continuing their eternal dance.
                </li>

                <li>
                Given we're using 20 as our argument.
                </li>

                <li>
                We're asking the game to take a brief 20-millisecond pause each time it completes a cycle through the game loop. This ensures the game doesn't progress too quickly, making certain that every frame, action, and reaction is savored and experienced in full by the player. It's our way of making the celestial dance accessible and enjoyable for everyone! 🌌🕺💃🎮
                </li>
            </ul>
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default Breakout