import React, { useState, useEffect } from 'react';
import Sidebar from '../components/sidebar/sidebar';
import { useLocation } from 'react-router-dom';
import './home.css';
import Navbar from '../components/navbar/navbar';
import ScratchProjectBoard from '../components/project-boards/scratchProjectBoard';
import PythonProjectBoard from '../components/project-boards/pythonProjectBoard';
import RobloxProjectBoard from '../components/project-boards/robloxProjectBoard';
import FullstackProjectBoard from '../components/project-boards/fullstackProjectBoard';
import axios from 'axios';
import api from '../api/api';

const Home = () => {
  const location = useLocation();


  const fetchUser = async (id) => {
    await api.post(`${process.env.REACT_APP_API_URL}/single-user`, {
      id
    }).then((res) => {
      if (res.data.approved == false) {
        window.location.href = `https://kidslearncoding.org/login`
      } else {
        localStorage.setItem("user", JSON.stringify(res.data));
        window.dispatchEvent(new CustomEvent("roleChanged"));
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const validateToken = async (token) => {
    await api.post(`${process.env.REACT_APP_API_URL}/val-token`, {
      token
    }).then((res) => {
      fetchUser(res.data.user._id)
    }).catch((err) => {
      localStorage.removeItem('authToken');
      checkCookie()
    })
  }


  const checkCookie = async () => {
    // JavaScript that runs on my.kidslearncoding.org landing page
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');


    if (token) {
        localStorage.setItem('authToken', token);
        // Remove the token from the URL for security
        window.history.replaceState({}, document.title, "/");
        validateToken(token)
    } else {
        // If there's no token in the URL, you might want to log the token from Local Storage (if any)
        const storedToken = localStorage.getItem('authToken');
        if (storedToken) {
            validateToken(storedToken)
        } else {
            window.location.href = `https://kidslearncoding.org/login`;
        }
    }
  }

  useEffect(() => {
      checkCookie()
  }, []);




  return (
    <div className='home'>
        <Sidebar />

        <div className='page-right'>
          <Navbar />

          {
            (location?.pathname === "/scratch" || location?.pathname === "/") && (
              <ScratchProjectBoard />
            )
          }

          {
            location?.pathname === "/python" && (
              <PythonProjectBoard />
            )
          }

          {
            location?.pathname === "/lua" && (
              <RobloxProjectBoard />
            )
          }
          
          {
            location?.pathname === "/fullstack" && (
              <FullstackProjectBoard />
            )
          }

          {["/scratch", "/python", "/lua", "/fullstack"].includes(location.pathname) === false && (
            <div className='projects-container'>
              <h2>Error 404: Not Found!</h2>
            </div>
          )}

        </div>
    </div>
  )
}

export default Home