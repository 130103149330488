import React, {useState} from 'react'
import one from './pythonImages/chickenimages/1.png';
import two from './pythonImages/chickenimages/2.png';
import three from './pythonImages/chickenimages/3.png';
import four from './pythonImages/chickenimages/4.png';
import five from './pythonImages/chickenimages/5.png';
import six from './pythonImages/chickenimages/6.png';
import seven from './pythonImages/chickenimages/7.png';
import eight from './pythonImages/chickenimages/8.png';
import nine from './pythonImages/chickenimages/9.png';
import ten from './pythonImages/chickenimages/10.png';
import eleven from './pythonImages/chickenimages/11.png';
import twelve from './pythonImages/chickenimages/12.png';
import thirteen from './pythonImages/chickenimages/13.png';
import fourteen from './pythonImages/chickenimages/14.png';
import fifteen from './pythonImages/chickenimages/15.png';
import sixteen from './pythonImages/chickenimages/16.png';
import seventeen from './pythonImages/chickenimages/17.png';
import eighteen from './pythonImages/chickenimages/18.png';
import nineteen from './pythonImages/chickenimages/19.png';
import twenty from './pythonImages/chickenimages/20.png';
import twentyOne from './pythonImages/chickenimages/21.png';
import twentyTwo from './pythonImages/chickenimages/22.png';
import twentyThree from './pythonImages/chickenimages/23.png';
import twentyFour from './pythonImages/chickenimages/24.png';
import twentyFive from './pythonImages/chickenimages/25.png';
import twentySix from './pythonImages/chickenimages/26.png';
import twentySeven from './pythonImages/chickenimages/27.png';
import twentyEight from './pythonImages/chickenimages/28.png';
import twentyNine from './pythonImages/chickenimages/29.png';
import thirty from './pythonImages/chickenimages/30.png';
import thirtyOne from './pythonImages/chickenimages/31.png';
import thirtyTwo from './pythonImages/chickenimages/32.png';
import thirtyThree from './pythonImages/chickenimages/33.png';
import thirtyFour from './pythonImages/chickenimages/34.png';
import thirtyFive from './pythonImages/chickenimages/35.png';
import thirtySix from './pythonImages/chickenimages/36.png';
import thirtySeven from './pythonImages/chickenimages/37.png';
import thirtyEight from './pythonImages/chickenimages/38.png';
import thirtyNine from './pythonImages/chickenimages/39.png';
import forty from './pythonImages/chickenimages/40.png';
import fortyOne from './pythonImages/chickenimages/41.png';
import fortyTwo from './pythonImages/chickenimages/42.png';
import fortyThree from './pythonImages/chickenimages/43.png';
import fortyFour from './pythonImages/chickenimages/44.png';
import fortyFive from './pythonImages/chickenimages/45.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import './tutorial.css';

const Catch = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 45;

  return (
    <div className='pythonGames__container'>
        <h1>
        🐔 Let's catch some chickens! 🐔
        </h1>

        <h3 className='intro'>
        In this fun tutorial, we're going to learn how to create a "Chicken Catch" game using Python and the Pygame library! Are you ready to start this amazing journey? Let's go! 🎉
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>






        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 1: Readying the Environment🎨🖼️
            </h2>

            <p>
                Before we can start coding this Chicken Catch Game, we need to setup proper file structure within Visual Studio Code and download some necessary assets!
            </p>

            <img src={one} className='demo__image' alt='img_one' />

            <ol>
                <li>Create a "Chicken-Catch" folder to contain your project.</li>

                <li>Open this folder with Visual Studio Code.</li>

                <li>Add a file and name it "catch.py". "catch" being the file name and ".py" letting Visual Studio Code that this is a Python file.</li>

                <li>Add a folder to contain the images that we will download a program for this project.</li>

                <li>Download the images by clicking the button below and storing them in your "images" folder.</li>
            </ol>

            {/*<div className="downloadImageButtonContainer">
                <Download
                    src={bucket}
                    filename="bucket.png"
                />

                <Download
                    src={chicken}
                    filename="chicken.png"
                />

                <Download
                    src={sky}
                    filename="sky.png"
                />
            </div>*/}
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 2: Setting the Foundation 🛠️🎮
            </h2>

            <p>
                Welcome to the exciting journey of creating our Chicken Catch game using Python and Pygame! In this first step, we're laying the groundwork by importing some essential modules. Let's gear up for some fun and coding! 🚀🐔
            </p>

            <img src={two} className='demo__image' alt='img_two' />

            <p>
            What are these lines doing? 🤔🧩
            </p>

            <ol>
                <li>
                    <strong>import pygame</strong>: Pygame is the star of our game development. This library is packed with functionalities to create graphics, sounds, and game dynamics. It's our toolkit for bringing the Chicken Catch game to life! 🎨🎼
                </li>
                <li>
                    <strong>import sys</strong>: The 'sys' module in Python gives us access to some system-specific functions and variables. For our game, it's particularly useful for managing the game window and processing events. 💻🔧
                </li>
                <li>
                    <strong>import random</strong>: Randomness adds spice to games! This module will help us in generating random events in Chicken Catch, making it unpredictable and more enjoyable. 🎲🐣
                </li>
                <li>
                    <strong>from pygame.locals import *</strong>: By importing specific constants and functions from Pygame, this line equips us with additional tools for managing game events and controls more effectively. It's like adding extra flavors to our gaming recipe! 📚🔑
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>import</strong>: In Python, 'import' is used to bring in modules (collections of functions, methods, and types) into our script. It's like inviting friends over to help with a fun project!
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Pygame is widely used in the world of educational game development. Its simplicity and power make it a great tool for beginners to dive into the world of programming and game creation! 🌍🕹️
            </h3>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>   
            <h2>
                Step 3: Kickstarting the Pygame Engine 🚀🖥️
            </h2>

            <p>
                With our essential tools imported, let's get the Pygame engine running. This next line is small yet mighty, crucial for launching our gaming adventure. 🌟🎲
            </p>

            <img src={three} className='demo__image' alt='img_three' />

            <p>
            What does this line do? 🤔🔍
            </p>

            <ol>
                <li>
                    <strong>pygame.init()</strong>: This is like turning the key in the ignition of a car. The <code>pygame.init()</code> function initializes all the Pygame modules we'll be using. It's setting up the environment so we can dive into creating graphics, handling sounds, and much more. Without this, our Pygame car wouldn't start! 🚗💨
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Initializing Pygame</strong>: The <code>init()</code> function in Pygame prepares the library to be used in our program. It initializes various background aspects needed for game development.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Initializing Pygame is a standard first step in almost every Pygame script. It ensures that your game can access all the functionalities of the library effectively, setting the stage for a smooth gaming experience. 🎭🎮
            </h3>
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 4: Crafting the Game Window 🖼️📏
            </h2>

            <p>
                Now that our Pygame engine is humming, let's shape the space where our Chicken Catch adventure will unfold. In this step, we're setting up the game window – our digital canvas. It's like building the stage for our play! 🎭🏗️
            </p>

            <img src={four} className='demo__image' alt='img_four' />

            <p>
            Let's delve deep into these lines: 🧐🔬
            </p>

            <ol>
                <li>
                    <strong>WIDTH, HEIGHT = 800, 600</strong>: Here, we're defining the dimensions of our game window. Think of WIDTH and HEIGHT like the length and breadth of a painter's canvas. We're setting our digital canvas to 800 pixels in width and 600 pixels in height, creating a spacious area for our gameplay and graphics. 📐🌌
                </li>
                <li>
                    <strong>win = pygame.display.set_mode((WIDTH, HEIGHT))</strong>: This line is where the magic happens! We're calling the <code>set_mode</code> function from Pygame's display module, passing in our WIDTH and HEIGHT as a tuple. This function brings our game window to life with the specified dimensions. The variable 'win' now represents our game window. Every time we want to change something on the screen, we'll interact with 'win'. It's like the director of our digital theater! 🎬🪟
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Variable Assignment</strong>: We use the equals sign (<code>=</code>) to assign values to variables in Python. Here, WIDTH and HEIGHT are variables that store the dimensions of our game window, which we then use to create the window.
                </li>
                <li>
                    <strong>Creating the Display Window</strong>: The <code>set_mode</code> function is a crucial aspect of Pygame. It initializes the game window or screen with the given size.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The dimensions of the game window play a critical role in game design. They affect how players interact with the game and perceive its content. Choosing the right size is like setting the perfect stage for a play; it can make all the difference in the audience's experience! 🎭👀
            </h3>
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 5: Naming Our Game Window – A Title for Our Adventure 🌟🏷️
            </h2>

            <p>
                A great game deserves a great title! Just like a book or a movie, our game needs a name that captures the essence of the adventure. In this step, we'll give our game window a title, making it easily recognizable and inviting. 📖🔖
            </p>

            <img src={five} className='demo__image' alt='img_five' />

            <p>
            Let's explore the significance of this line: 🕵️‍♂️📚
            </p>
            <ol>
                <li>
                    <strong>pygame.display.set_caption("Catch the Falling Objects")</strong>: This line of code is all about branding and identity.
                    <ul>
                        <li><strong>pygame.display.set_caption(…)</strong>: We're using a Pygame function that sets the title of our game window. The title appears in the window's title bar, which is typically at the top of the window.</li>
                        <li><strong>"Catch the Falling Objects"</strong>: This is our chosen title. It's concise, descriptive, and catchy. It gives players a hint about the game's objective – catching objects that fall from the top of the screen. It's like the headline of a newspaper article, drawing readers into the story. 📰👀</li>
                    </ul>
                    By giving our game window a title, we're not just adding a functional element; we're also adding a piece of our game's personality and theme. It's the first thing players will notice, setting the tone for their experience. 🎭✨
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>
            <ul>
                <li>
                    <strong>Window Caption</strong>: The window caption or title is an integral part of the game window. It helps in identifying the game and can be used to convey the game's theme or essence.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The title of a game can be a powerful tool in capturing the player's interest and setting expectations. It's often the first piece of the game's narrative that a player encounters, making it a key element of game storytelling and marketing! 🎮📖
            </h3>
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 6: Synchronizing the Game's Heartbeat – The Clock 🕒💓
            </h2>

            <p>
                Smooth gameplay requires a steady rhythm. In this step, we're introducing a clock to our game. It's like setting a metronome for a musician, ensuring everything moves in harmony. 🎶⏲️
            </p>

            <img src={six} className='demo__image' alt='img_six' />

            <p>
            Let's dissect this line and its role in our game: 🧠🔍
            </p>

            <ol>
                <li>
                    <strong>clock = pygame.time.Clock()</strong>: This line is crucial for managing the timing and speed of our game.
                    <ul>
                        <li><strong>pygame.time.Clock()</strong>: Here, we're creating a Clock object from Pygame's time module. This object will help us control how fast our game updates – essentially, it lets us set the frame rate. The frame rate is the number of times the game updates per second. A higher frame rate means smoother motion, but it also requires more processing power.</li>
                        <li><strong>clock</strong>: We store the Clock object in a variable named 'clock'. It's like having a stopwatch in our game, which we can start, stop, or check at any point. This gives us precise control over the game's pacing, making sure it's neither too fast for the player to enjoy nor too slow to be engaging.</li>
                    </ul>
                    By employing a clock, we're taking a significant step in professionalizing our game's mechanics. It helps in delivering a consistent, enjoyable experience, no matter how fast or slow the player's computer is. ⏱️🎮
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Creating a Clock Object</strong>: The Clock object in Pygame is a powerful tool for controlling the timing of game events. It's essential for games where timing and frame rate play a key role in the gameplay.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The concept of frame rate is not just important in games but also in movies and animations. It's all about creating the illusion of motion. In games, a stable frame rate can significantly improve the player's experience, making the game feel smoother and more responsive. 🎥🌟
            </h3>
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 7: Setting the Pace – Frames Per Second (FPS) 🎞️🕒
            </h2>

            <p>
                The rhythm of a game is key to its feel and playability. In this step, we're going to set this rhythm by defining the game's Frames Per Second (FPS). It's like choosing the tempo for a song that sets its mood and style. 🎵🧭
            </p>

            <img src={seven} className='demo__image' alt='img_seven' />

            <p>
            Let's understand the impact of this line: 🤔💡
            </p>

            <ol>
                <li>
                    <strong>FPS = 60</strong>: This simple line plays a pivotal role in our game's performance.
                    <ul>
                        <li><strong>FPS</strong>: FPS stands for Frames Per Second. It's a measure of how many times the display is updated every second. In gaming, a higher FPS results in smoother motion and transitions. By setting FPS to 60, we're aiming for a smooth and fluid experience, which is standard in many games.</li>
                        <li><strong>60</strong>: The number 60 is a common choice for FPS in games. It strikes a balance between providing a smooth visual experience and being achievable on most hardware. It ensures that our game runs smoothly on a wide range of systems, without requiring high-end hardware.</li>
                    </ul>
                    Choosing the right FPS is like setting the right speed for a movie projector. Too fast, and the viewer can't grasp the details; too slow, and the motion becomes choppy. At 60 FPS, we aim to give players a seamless and engaging experience. 🌠🕹️
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Defining FPS</strong>: In Python, setting a variable like FPS is straightforward. This value is then used throughout the game to regulate how often we update the game's state and redraw the screen.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: While 60 FPS is standard for many games, some modern games and displays support higher rates like 120 FPS or even 240 FPS, providing even smoother visuals. However, the human eye's ability to perceive these differences varies from person to person. 🚀👀
            </h3>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 8: Setting the Stage with Colors – White and Black 🎨🖌️
            </h2>

            <p>
                Colors play a crucial role in setting the mood and visual appeal of a game. In this step, we're defining two fundamental colors that will be used in our Chicken Catch game. Let's add some color to our digital canvas! 🌈👩‍🎨
            </p>

            <img src={eight} className='demo__image' alt='img_eight' />

            <p>
            Delving into the meaning of these color definitions: 🎨🔍
            </p>

            <ol>
                <li>
                    <strong>WHITE = (255, 255, 255)</strong> and <strong>BLACK = (0, 0, 0)</strong>: These lines set up two essential colors using RGB (Red, Green, Blue) values.
                    <ul>
                        <li><strong>WHITE</strong>: This color is defined by setting all RGB values to their maximum (255). In RGB, combining all colors at full intensity gives us white. It's like blending all the lights of a stage to create a bright, clear spotlight. 🌟🔦</li>
                        <li><strong>BLACK</strong>: Here, we're doing the opposite. Setting all RGB values to zero results in black, the absence of light. Black can be used for backgrounds, text, or other elements where we want contrast or focus. It's like the quiet of a theater before the show starts. 🌌🎭</li>
                    </ul>
                    By defining these colors, we're preparing our basic palette. In game design, colors are not just decorative; they communicate mood, draw attention, and contribute to the game's usability and aesthetics. 🖼️🌈
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>RGB Color Values</strong>: The RGB color model is a primary color model used in digital graphics. Colors are created by combining red, green, and blue light in varying intensities.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The choice of background and text colors can greatly affect the player's comfort and the game's readability. High contrast, like white text on a black background, is often used for clarity, especially in fast-paced games! 📖🚀
            </h3>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 9: Loading and Scaling Images – Bringing Graphics to Life 📸🌍
            </h2>

            <p>
                A game's charm often lies in its visual elements. In this step, we start working on a function to load images and adjust their size to fit our game's design. It's like choosing the right decorations for a party! 🖼️🎉
            </p>

            <img src={nine} className='demo__image' alt='img_nine' />

            <p>
            Let's dive into what each part of this function does: 🧐🔬
            </p>

            <ol>
                <li>
                    <strong>def load_scaled_image(path, width, height):</strong> This line begins the definition of a new function named <code>load_scaled_image</code>. Functions in Python are like recipes; they're sets of instructions that perform a specific task. This particular function will have three parameters: <code>path</code> (the file path to the image we want to load), <code>width</code>, and <code>height</code> (the desired dimensions for the image). It's our custom tool for handling game graphics, ensuring they fit perfectly in our game world. 🌐🛠️
                </li>
                <li>
                    <strong>img = pygame.image.load(path)</strong>: Inside our function, this line is the first step in our image handling process. It uses Pygame's image module to load an image from the specified path. The image file located at the path is loaded into the variable <code>img</code>. This is like picking a specific photo from an album to display in our digital gallery. 🖼️🗂️
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Function Definition</strong>: The <code>def</code> keyword in Python starts the definition of a function. Functions can take parameters, which are inputs used by the function to perform its task.
                </li>
                <li>
                    <strong>Loading Images</strong>: Pygame provides a straightforward way to load images using the <code>pygame.image.load</code> function. This is crucial for adding visual elements to games.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Managing graphics efficiently is key in game development. Functions like <code>load_scaled_image</code> help optimize game performance by controlling image sizes, which can be particularly important for games with numerous graphical elements. 🎮💡
            </h3>
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 10: Perfecting Image Sizes – Scaling for Impact 🖼️↔️🔍
            </h2>

            <p>
                After loading our images, the next crucial step is to ensure they fit perfectly in our game's world. In this step, we focus on scaling the images to the right size. It's like tailoring a costume to fit an actor perfectly! ✂️👗
            </p>

            <img src={ten} className='demo__image' alt='img_ten' />

            <p>
            Let's explore these lines in detail: 🧵🔬
            </p>

            <ol>
                <li>
                    <strong>scaled_img = pygame.transform.scale(img, (width, height))</strong>: This line adjusts the size of our image.
                    <ul>
                        <li><strong>pygame.transform.scale(…)</strong>: We're using a Pygame function that scales an image to a new size. This function takes the original image (<code>img</code>) and a tuple containing the new dimensions (<code>width</code>, <code>height</code>).</li>
                        <li><strong>scaled_img</strong>: The result of the scaling process is stored in a new variable, <code>scaled_img</code>. This variable will now hold the image adjusted to our desired size. It's like resizing a picture to fit perfectly into a frame. 🖼️📏</li>
                    </ul>
                    Scaling images is essential in game design to ensure that they look good and fit well within different elements of the game, like the background, characters, or UI components. 🎨🕹️
                </li>
                <li>
                    <strong>return scaled_img</strong>: Finally, our function returns the scaled image. The <code>return</code> statement is used to send the scaled image back to wherever the function was called from. This makes the scaled image available for use in our game. It's like delivering a finished art piece to be displayed in a gallery! 📦🖼️
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Image Scaling</strong>: Scaling an image is about changing its size. The <code>pygame.transform.scale</code> function is used for this purpose in Pygame, allowing us to fit images into the desired space within the game.
                </li>
                <li>
                    <strong>Returning a Value</strong>: In Python, <code>return</code> is used in a function to send back a result to the caller. This is how functions provide processed data or objects back for further use.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: In digital graphics, scaling is a common technique used not just in games but also in various applications like photo editing and graphic design. It allows creators to use the same image in different contexts and sizes, offering versatility and consistency. 🌐👩‍🎨
            </h3>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 11: Setting the Scale Factor – Adjusting Image Proportions 📏🌌
            </h2>

            <p>
                In game development, managing the size of graphics is key to a cohesive and engaging visual experience. In this step, we introduce a scale factor to control the size of our images relative to the game window. It's like choosing the right magnification for a telescope to get the best view of the stars! 🔭✨
            </p>

            <img src={eleven} className='demo__image' alt='img_eleven' />

            <p>
            Let's delve into the significance of this scale factor: 🔍📐
            </p>
            <ol>
                <li>
                    <strong>scale_factor = 6</strong>: This line sets the scale factor for our game's images.
                    <ul>
                        <li>The <strong>scale_factor</strong> is a numerical value that we will use to adjust the size of our images. By setting it to 6, we are planning to reduce the size of our images relative to their original dimensions. This factor plays a crucial role in how we apply our <code>load_scaled_image</code> function.</li>
                        <li>Using a scale factor is a common practice in game design to ensure that images fit well within the game environment, especially when dealing with multiple screen sizes or resolutions. It allows us to maintain proportionality and consistency in our visual elements.</li>
                    </ul>
                    The choice of scale factor is a blend of art and science – it affects not only the aesthetics of the game but also its functionality and playability. It's like finding the perfect balance in a recipe to make it just right! 🎨⚖️
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Variable Assignment</strong>: In Python, assigning a value to a variable is straightforward. Here, we create a variable named <code>scale_factor</code> and assign it the value 6, which we'll use in scaling operations.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The concept of scaling is integral in graphic design and game development. It ensures that visual elements not only look good but also function well across different devices and platforms. It's a critical aspect of creating a versatile and adaptable visual experience! 📲🌍
            </h3>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 12: Introducing the Basket – Loading and Scaling a Key Image 🧺🖼️
            </h2>

            <p>
                In our Chicken Catch game, the basket plays a pivotal role. It's the player's tool to catch the falling objects. In this step, we're going to load and scale the basket image, preparing it for action. It's like crafting the perfect net for a butterfly hunt! 🦋🥅
            </p>

            <img src={twelve} className='demo__image' alt='img_twelve' />

            <p>
            Let's break down this line and its contribution to our game: 🧐🔧
            </p>

            <ol>
                <li>
                    <strong>basket_img = load_scaled_image("images/bucket.png", 100, 100)</strong>: This line is about bringing our basket image into the game and adjusting its size to fit our design needs.
                    <ul>
                        <li><strong>load_scaled_image(…)</strong>: We're calling the function we defined earlier. This function will load an image from the specified path – in this case, "images/bucket.png", which is the image of our basket.</li>
                        <li><strong>100, 100</strong>: These are the dimensions to which we want to scale our basket image. By setting both width and height to 100 pixels, we're ensuring that our basket has the right size to be noticeable and functional without overwhelming the game's visual space.</li>
                        <li><strong>basket_img</strong>: The scaled image is stored in a variable called <code>basket_img</code>. This variable will be used whenever we need to display or interact with the basket in our game. It's like having a ready-to-use prop in our game's toolkit. 🛠️🧺</li>
                    </ul>
                    Properly sizing and loading essential game elements like the basket is crucial. It's not just about aesthetics; it's about making the game playable and enjoyable. The basket's size relative to the falling objects and the game window will significantly impact the game's challenge and fun. 🎮🎯
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Loading and Scaling an Image</strong>: This step combines two critical actions – loading an image file and scaling it to the desired size, using the custom function we created for efficient and flexible graphics handling.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: In game design, the visual representation of interactive elements like the basket is key to user experience. It helps players quickly understand their role and how to interact with the game, enhancing engagement and intuitiveness. 🎨👀
            </h3>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 13: Adding the Falling Objects – The Chicken Image 🐔🖼️
            </h2>

            <p>
                A game of catching isn't complete without objects to catch! In this step, we're focusing on the star of our Chicken Catch game: the chickens. Let's load and scale the chicken image, preparing these feathered friends for their on-screen debut. 🌟🐥
            </p>

            <img src={thirteen} className='demo__image' alt='img_thirteen' />

            <p>
            Let's examine the role of this line in our game: 🕵️‍♂️🐔
            </p>

            <ol>
                <li>
                    <strong>object_img = load_scaled_image("images/chicken.png", 90, 90)</strong>: This line is crucial for introducing the main object of our game - the chicken.
                    <ul>
                        <li><strong>load_scaled_image("images/chicken.png", 90, 90)</strong>: Here, we're calling our function again, this time to load the chicken image. The function will find the image at "images/chicken.png" and scale it to a width and height of 90 pixels each. This size is chosen to make the chickens noticeable yet manageable as falling objects in the game.</li>
                        <li><strong>object_img</strong>: After scaling, the image is assigned to the variable <code>object_img</code>. This variable will represent our chickens in the game, used whenever we need to display or interact with these falling objects. It's like preparing the main characters for their stage performance. 🎭🐤</li>
                    </ul>
                    The appearance and size of the falling objects are key to the game's challenge and aesthetics. Getting them right enhances the visual appeal and the overall gameplay experience, making it fun and engaging for players. 🎮👌
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Loading and Scaling an Image</strong>: Similar to how we handled the basket image, this step focuses on preparing another vital graphical element of the game. Using the same function for different images shows the flexibility and reusability of our code.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The choice of objects in a catch game not only defines its theme but also its playability. Different shapes, sizes, and colors can add varying levels of difficulty and visual interest, making each game unique and exciting. 🎲🎨
            </h3>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 14: Painting the Backdrop – The Background Image 🌌🖌️
            </h2>

            <p>
                Every great stage needs a captivating backdrop. For our Chicken Catch game, this backdrop is the sky. In this step, we're going to load the background image that sets the scene for our gameplay. It's like creating the perfect setting for our story! 🏞️🎨
            </p>
            
            <img src={fourteen} className='demo__image' alt='img_fourteen' />

            <p>
            Let's explore the significance of this line: 🕵️🌤️
            </p>

            <ol>
                <li>
                    <strong>background_img = pygame.image.load("images/sky.png")</strong>: This line is all about creating the visual foundation of our game.
                    <ul>
                        <li><strong>pygame.image.load("images/sky.png")</strong>: We're utilizing Pygame's image loading capability to bring in our background image. The image located at "images/sky.png" is loaded into our game. This image, depicting the sky, will serve as the backdrop against which all the action takes place. It's like setting the stage for a play, providing context and atmosphere to the scene.</li>
                        <li><strong>background_img</strong>: The loaded image is stored in a variable called <code>background_img</code>. This variable will be used throughout the game to refer to and draw the background. It's like having a painted canvas ready to add more elements to it.</li>
                    </ul>
                    The background image is a vital aspect of game aesthetics. It helps create an immersive environment, drawing players into the game world. A well-chosen background can significantly enhance the visual appeal and set the tone for the gameplay. 🎭🌄
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>
            <ul>
                <li>
                    <strong>Loading an Image</strong>: This step demonstrates the straightforward process of loading an image in Pygame. The <code>pygame.image.load</code> function is essential for adding graphical elements to the game.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Backgrounds in games are not just static images; they contribute to the game's narrative, mood, and even gameplay. They can be as important as the characters and objects, providing depth and context to the game's world. 🖼️🌍
            </h3>
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 15: Setting Up Score and Game Duration – The Essence of Challenge 🕒🏆
            </h2>

            <p>
                What's a game without a goal and a timeframe? In this step, we establish two critical aspects of our Chicken Catch game: the scoring system and the game duration. It's like setting the rules for a sports match! ⚽⏱️
            </p>

            <img src={fifteen} className='demo__image' alt='img_fifteen' />

            <p>
            Let's delve into these foundational elements: 🧠⚖️
            </p>
            <ol>
                <li>
                    <strong>score = 0</strong>: This line sets the initial score for the player.
                    <ul>
                        <li>The <strong>score</strong> variable will keep track of the player's points earned during the game. Starting at zero, it's like a blank slate where the player's achievements will be recorded.</li>
                        <li>Scoring systems are integral to most games. They provide motivation, measure progress, and add a competitive edge, encouraging players to improve and reach higher scores.</li>
                    </ul>
                    The score is a simple yet powerful tool in game design. It quantifies success and skill, offering players a tangible goal to strive for. 🎯📈
                </li>
                <li>
                    <strong>game_duration = 60000</strong>: This line defines how long the game will last, in milliseconds.
                    <ul>
                        <li><strong>game_duration</strong>: Setting it to 60000 means our game will run for 60,000 milliseconds, or 60 seconds (1 minute). It's the time window the player has to catch as many objects as possible.</li>
                        <li>Time limits in games create urgency and excitement. They challenge players to achieve as much as they can in a set period, making the gameplay more dynamic and engaging.</li>
                    </ul>
                    By establishing a game duration, we're not just creating a sense of urgency; we're also ensuring that each game session is a compact and intense experience. ⌛🚀
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Variable Initialization</strong>: Initializing variables like <code>score</code> and <code>game_duration</code> is a fundamental step in setting up the game's mechanics and structure.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Balancing the scoring system and game duration is crucial in game design. It ensures that the game is neither too easy nor impossibly hard, providing a satisfying challenge to a wide range of players. 🎮🔧
            </h3>
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 16: Implementing a Timer Display – Counting Down the Seconds 🕰️⏳
            </h2>

            <p>
                Time is of the essence in our Chicken Catch game. In this step, we're adding a timer display to keep the player informed about the time remaining. It's like having a stopwatch in a race, showing how much time is left to achieve the goal. ⏱️🏁
            </p>

            <img src={sixteen} className='demo__image' alt='img_sixteen' />
            <p>
            Let's examine the functionality of this timer: 🧭👀
            </p>


            <ol>
                <li>
                    <strong>def draw_timer(time_remaining):</strong> This line defines a new function called <code>draw_timer</code>.
                    <ul>
                        <li>The function <code>draw_timer</code> is designed to display the time left in the game. It takes one parameter, <code>time_remaining</code>, which represents the remaining time in milliseconds.</li>
                        <li>Functions like this are vital in games that are time-sensitive. They provide players with real-time feedback, helping them strategize and make decisions quickly.</li>
                    </ul>
                </li>
                <li>
                    <strong>draw_text(f"Time: &#10100;time_remaining // 1000&#10101;s", WIDTH - 150, 10)</strong>: Inside the function, we're calling another function, <code>draw_text</code>, to display the timer on the screen.
                    <ul>
                        <li><strong>f"Time: &#10100;time_remaining // 1000&#10101;s"</strong>: This is a formatted string. It calculates the remaining time in seconds (by dividing milliseconds by 1000) and creates a string to display, like "Time: 30s".</li>
                        <li><strong>WIDTH - 150, 10</strong>: These values set the position of the timer text on the screen. Placing it near the top right corner makes it easily visible without obstructing the gameplay.</li>
                    </ul>
                    Displaying the timer is not just a functional element; it adds to the excitement and urgency of the game, enhancing the player's engagement and experience. ⏲️🎮
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Function Definition</strong>: Defining a function for specific tasks like drawing the timer is a common practice in programming. It keeps our code organized and reusable.
                </li>
                <li>
                    <strong>Formatted Strings</strong>: The use of formatted strings (f-strings) in Python makes it easy to create dynamic text based on variable values, ideal for updating game information like timers.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: A visible timer in games can significantly impact player behavior, often leading to more strategic plays and heightened emotions as the clock ticks down. It's a simple element that adds depth to the gaming experience. 🧠💡
            </h3>
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 17: Crafting the Text Display Function – Communicating with Style 📝🎨
            </h2>

            <p>
                Effective communication is key in games. In this step, we'll create a function to display text on the screen, a versatile tool for showing scores, timers, and messages. It's like setting up a billboard that conveys important information to the players! 🌟📌
            </p>

            <img src={seventeen} className='demo__image' alt='img_seventeen' />

            <p>
            Let's dive into the mechanics of this text rendering function: 🔍✒️
            </p>

            <ol>
                <li>
                    <strong>def draw_text(text, x, y, size=40, color=BLACK):</strong> This line starts the definition of a new function, <code>draw_text</code>.
                    <ul>
                        <li>The function is designed to draw text on the game window. It takes several parameters: <code>text</code> (the string to display), <code>x</code> and <code>y</code> (coordinates for text placement), and optional parameters <code>size</code> (font size) and <code>color</code> (text color, defaulting to black).</li>
                        <li>This flexibility allows us to use the function for various purposes throughout the game, like showing scores, countdowns, or end-game messages.</li>
                    </ul>
                </li>
                <li>
                    <strong>font = pygame.font.Font(None, size)</strong>: We create a font object.
                    <ul>
                        <li>Pygame's <code>font</code> module is used to create a font object. <code>None</code> means we're using the default system font, and <code>size</code> sets the font size.</li>
                        <li>This font object will determine how our text looks. The right font size and style can significantly impact readability and the game's aesthetic appeal.</li>
                    </ul>
                </li>
                <li>
                    <strong>text_surface = font.render(text, True, color)</strong>: Render the text.
                    <ul>
                        <li>The <code>render</code> method of the font object creates an image of the text. <code>True</code> enables anti-aliasing, making the text smoother, and <code>color</code> sets the text color.</li>
                        <li>This step converts our text into a graphic that can be displayed on the screen. It's like turning words into a visual element of the game.</li>
                    </ul>
                </li>
                <li>
                    <strong>win.blit(text_surface, (x, y))</strong>: Display the text on the game window.
                    <ul>
                        <li>The <code>blit</code> method is used to draw the text image (<code>text_surface</code>) onto the game window (<code>win</code>) at the specified coordinates (<code>x</code>, <code>y</code>).</li>
                        <li>This final step makes the text visible to the players, effectively communicating important information or feedback during the game.</li>
                    </ul>
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Function Definition</strong>: Creating a function for text rendering is a common practice in game programming. It centralizes the text drawing process, making the code more organized and reusable.
                </li>
                <li>
                    <strong>Rendering Text</strong>: In Pygame, rendering text involves creating a surface with the text rendered on it, which can then be drawn (blitted) onto the screen.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The way information is presented in games, through text or visuals, can greatly affect the player's experience. Clear, well-placed text can make a game more intuitive and enjoyable, enhancing the overall user experience. 🖋️👁️
            </h3>
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 18: Creating the Basket Class – Building a Key Game Component 🧺👨‍💻
            </h2>

            <p>
                In object-oriented programming, classes are like blueprints for creating objects. For our Chicken Catch game, we need a basket to catch the falling objects. In this step, we'll create a class to represent the basket, defining its properties and behaviors. It's like designing a character for a play! 🎭📐
            </p>

            <img src={eighteen} className='demo__image' alt='img_eighteen' />

            <p>
            Let's delve into each part of this class and its purpose: 🧩🔍
            </p>


            <ol>
                <li>
                    <strong>class Basket:</strong> This line declares a new class named 'Basket'.
                    <ul>
                        <li>In Python, a class is a way to bundle data and functionality together. Creating a 'Basket' class allows us to encapsulate all the properties and behaviors of the basket in our game.</li>
                        <li>This approach makes our code more organized, modular, and easier to manage. It's like having a specific toolbox for everything related to the basket.</li>
                    </ul>
                </li>
                <li>
                    <strong>def __init__(self, x, y, width, height, img):</strong> This is the constructor for the Basket class.
                    <ul>
                        <li>The <code>__init__</code> method is called automatically when a new instance of the class is created. It sets up the initial state of the basket object.</li>
                        <li>This method takes several parameters: the position (<code>x</code>, <code>y</code>), size (<code>width</code>, <code>height</code>), and image (<code>img</code>) of the basket.</li>
                    </ul>
                </li>
                <li>
                    <strong>self.x = x</strong>, <strong>self.y = y</strong>, <strong>self.width = width</strong>, <strong>self.height = height</strong>, <strong>self.img = img</strong>: These lines set the object's properties.
                    <ul>
                        <li>Each line assigns the values passed to the constructor to the basket object's properties. <code>self</code> refers to the instance of the object itself.</li>
                        <li>These properties (<code>x</code>, <code>y</code>, <code>width</code>, <code>height</code>, <code>img</code>) define where the basket will be positioned on the screen, how large it will be, and how it will look.</li>
                    </ul>
                    The Basket class is a fundamental component of our game. It's where we define what a basket is and how it behaves. This encapsulation is a cornerstone of object-oriented programming, bringing clarity and flexibility to our game's code. 📦🛠️
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Class Definition</strong>: A class in Python is defined using the <code>class</code> keyword, followed by the class name and a colon. It serves as a template for creating objects.
                </li>
                <li>
                    <strong>Constructor Method</strong>: The <code>__init__</code> method is a special method in Python classes. It's used for initializing the object's attributes and is often referred to as the constructor.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Using classes and objects in game development allows for the creation of complex behaviors and interactions. It's a way to model real-world concepts within the game, making them more intuitive and lifelike. 🌍🎲
            </h3>
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 19: Bringing the Basket to the Screen – The Draw Method 🧺🎨
            </h2>

            <p>
                Having defined our Basket class, it's time to make the basket visible in our game. This step focuses on adding a method to the Basket class that draws the basket on the game window. It's like giving our basket the spotlight on the stage! 🖼️🔦
            </p>

            <img src={nineteen} className='demo__image' alt='img_nineteen' />
            <p>
            Let's understand the functionality of this method: 🎭👁️
            </p>

            <ol>
                <li>
                    <strong>def draw(self, win):</strong> This line defines the 'draw' method within the Basket class.
                    <ul>
                        <li>The <code>draw</code> method is a custom function that we're adding to our Basket class. Its purpose is to display the basket image on the game window.</li>
                        <li>This method takes two arguments: <code>self</code> (which refers to the instance of the Basket object) and <code>win</code> (the game window where we want to draw the basket).</li>
                    </ul>
                </li>
                <li>
                    <strong>win.blit(self.img, (self.x, self.y))</strong>: The line that actually draws the basket.
                    <ul>
                        <li>The <code>blit</code> function is used to draw one surface onto another. In this case, we're drawing the basket's image (<code>self.img</code>) onto the game window (<code>win</code>).</li>
                        <li>The coordinates (<code>self.x</code>, <code>self.y</code>) determine where on the window the basket will be drawn. This placement is crucial for gameplay, as it affects how players interact with falling objects.</li>
                    </ul>
                    By implementing the draw method, we're not only making the basket visible but also defining its role in the game. It's a key step in bringing our game's main interactive element to life! 🎬🧺
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Method Definition</strong>: Methods in a Python class are functions that are associated with the class. The 'draw' method is specific to the Basket class and is essential for its functionality in the game.
                </li>
                <li>
                    <strong>Drawing Graphics</strong>: The <code>blit</code> function is a fundamental part of drawing graphics in Pygame. It's used for rendering images or 'surfaces' onto the game window.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The ability to draw elements dynamically on the screen is one of the cornerstones of game development. It allows for the creation of interactive and visually rich environments, essential for an immersive gaming experience. 🌐🕹️
            </h3>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 20: Instantiating the Basket – Bringing Our Class to Life 🧺🚀
            </h2>

            <p>
                After creating the Basket class blueprint, it's time to bring this vital game element into action. In this step, we'll create an actual instance of the Basket, placing it in the game world. It's like casting a character in a play and giving them their spot on stage! 🌟🎭
            </p>

            <img src={twenty} className='demo__image' alt='img_twenty' />

            <p>
            Let's break down this line and its role in our game: 🧐🔧
            </p>

            <ol>
                <li>
                    <strong>basket = Basket(WIDTH // 2 - 50, HEIGHT - 100, 100, 100, basket_img)</strong>: This line creates a new Basket object.
                    <ul>
                        <li>We're calling the <code>Basket</code> class constructor to create a new instance of the Basket. This instance is what the player will control to catch the falling objects in the game.</li>
                        <li>The parameters define the basket's position and size. <code>WIDTH // 2 - 50</code> and <code>HEIGHT - 100</code> set the basket's initial position, centering it at the bottom of the screen. The values <code>100, 100</code> are the width and height, and <code>basket_img</code> is the image we loaded and scaled earlier.</li>
                        <li>By assigning this instance to the variable <code>basket</code>, we now have a specific basket object that we can refer to and manipulate throughout the game. It's like having our main actor ready to perform.</li>
                    </ul>
                    Creating an instance of the Basket is a crucial step in game setup. It transforms our class blueprint into a tangible element within the game's world, ready to interact with the player and other objects. 🛠️🧺
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>
            <ul>
                <li>
                    <strong>Instantiating a Class</strong>: In object-oriented programming, creating an instance of a class is known as instantiation. It turns the class blueprint into an actual object we can use in the game.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The process of instantiation in programming is like casting in a play. Just as actors bring characters to life, instances bring classes to life, each with its own properties and behaviors that contribute to the story. 🎭🌐
            </h3>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 21: Randomizing Object Positions – Spawning the Chickens 🎲🐔
            </h2>

            <p>
                In Chicken Catch, the challenge lies in catching objects that fall from random positions. This step introduces randomness to the game, enhancing its unpredictability and excitement. Let's determine where our chickens start their descent! 🌌🐥
            </p>

            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />

            <p>
            Let's unpack the purpose and impact of this line: 🤔🔢
            </p>

            <ol>
                <li>
                    <strong>object_x = random.randint(0, WIDTH - 50)</strong>: This line randomly determines the horizontal starting position of the falling objects.
                    <ul>
                        <li><strong>random.randint(0, WIDTH - 50)</strong>: The <code>random.randint</code> function generates a random integer. Here, it's used to pick a number between 0 and <code>WIDTH - 50</code> (the width of the game window minus the width of the object). This ensures that the object spawns within the bounds of the screen.</li>
                        <li>By assigning this value to <code>object_x</code>, we set the x-coordinate (horizontal position) for where the chicken will appear at the top of the screen. It's like choosing a random spot in the sky for a bird to start flying from.</li>
                    </ul>
                    Randomizing the starting position of objects is a simple yet effective way to add variety and challenge to the game. Each catch becomes a unique experience, keeping the player engaged and on their toes. 🎮🎭
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Generating Random Numbers</strong>: The <code>random.randint</code> function is a widely used method in Python to generate random integers, perfect for introducing unpredictability into games.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Randomness is a key element in many games, contributing to their replayability. It ensures that no two game sessions are exactly alike, offering new challenges and experiences each time. 🎰🔄
            </h3>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 22: Setting the Fall – Object Y-Coordinate and Speed 🍂⚡
            </h2>

            <p>
                For our falling objects to feel dynamic and challenging, we need to define not just where they start, but also how they move. In this step, we'll set the initial vertical position and descent speed of our chickens. It's like setting the rules for a skydiving adventure! 🪂💨
            </p>

            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />

            <p>
            Let's dive into what each of these settings contributes to the game: 🧐🌪️
            </p>

            <ol>
                <li>
                    <strong>object_y = -50</strong>: This line sets the initial vertical position of the falling objects.
                    <ul>
                        <li>The value <code>-50</code> places the object just above the top of the game window. This means that when the game starts, the objects will appear to be falling into the screen from above.</li>
                        <li>Starting the objects off-screen and having them fall into view enhances the element of surprise and anticipation for the player. It's like the thrilling moment before a performer jumps onto the stage.</li>
                    </ul>
                </li>
                <li>
                    <strong>object_speed = 5</strong>: This line defines the speed at which the objects will fall.
                    <ul>
                        <li>Setting the speed to <code>5</code> means that the objects will move down 5 pixels every frame. This speed is fast enough to challenge the player, but not so fast that it becomes impossible to catch the objects.</li>
                        <li>The speed of falling objects is crucial in determining the difficulty of the game. It needs to be balanced to provide a fair but challenging experience for the player.</li>
                    </ul>
                    The combination of starting position and speed dictates the flow of the game. It determines how quickly the player needs to react and adds a sense of urgency to the gameplay. ⏳🐥
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Initial Position and Speed</strong>: Setting the y-coordinate and speed of the objects are fundamental aspects of game mechanics. They define how the objects behave within the game world.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The dynamics of object movement in a game, like the speed and direction, can greatly influence a player's experience. It challenges their reflexes and decision-making skills, making the game more engaging and fun. 🎲🚀
            </h3>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 23: Designing the Main Game Window – Setting the Scene 🖼️🌄
            </h2>

            <p>
                The game window is the canvas where our Chicken Catch adventure comes to life. In this step, we're creating a function to draw the main elements of our game scene. It's like arranging the set for a theater play, where every piece contributes to the story. 🎭🌳
            </p>

            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />

            <p>
            Let's delve into the details of this function: 🎨👀
            </p>

            <ol>
                <li>
                    <strong>def draw_window(time_remaining):</strong> This line starts the definition of a new function, <code>draw_window</code>.
                    <ul>
                        <li>This function is responsible for drawing the main elements of our game on the window in each frame. It takes one parameter, <code>time_remaining</code>, which will be used to display the countdown timer.</li>
                        <li>Organizing our drawing code into a function like this helps keep our main game loop clean and makes it easier to manage the game's visuals.</li>
                    </ul>
                </li>
                <li>
                    <strong>win.blit(background_img, (0, 0))</strong>: Drawing the background.
                    <ul>
                        <li>The <code>blit</code> method is used to draw one surface onto another. Here, we're drawing the background image (<code>background_img</code>) onto the game window (<code>win</code>).</li>
                        <li>The coordinates <code>(0, 0)</code> ensure that the background image covers the entire game window, starting from the top-left corner. This sets the backdrop for all other game elements.</li>
                    </ul>
                    Setting up the background is a vital step in game design. It creates the environment in which the game takes place, influencing the player's immersion and overall experience. 🌅🎮
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Function Definition</strong>: In Python, functions are defined using the <code>def</code> keyword. They encapsulate a set of instructions that perform a specific task.
                </li>
                <li>
                    <strong>Drawing with blit</strong>: The <code>blit</code> function is a fundamental aspect of rendering graphics in Pygame. It's used for placing images onto the game window.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The background of a game does more than just provide a visual setting; it can also influence the mood, provide context for the game's story, and even affect gameplay mechanics. It's a multifaceted tool in the game developer's toolkit. 🖌️🎲
            </h3>
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 24: Displaying the Basket and Falling Objects – Action on Screen 🧺🐔
            </h2>

            <p>
                With our game's backdrop set, it's time to add the key interactive elements: the basket and the falling objects. This step is all about bringing these vital components into the game window, making the gameplay come alive. 🚀🎮
            </p>

            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />

            <p>
            Let's dissect how these elements contribute to the game's dynamics: 🧩🔍
            </p>

            <ol>
                <li>
                    <strong>basket.draw(win)</strong>: This line is responsible for drawing the basket on the game window.
                    <ul>
                        <li>We're calling the <code>draw</code> method of our <code>basket</code> object. This method was defined in the Basket class to handle displaying the basket image at its current position.</li>
                        <li>The <code>win</code> argument specifies that we want to draw the basket on the main game window. It's crucial for the player to see and move the basket, as it's the primary means of interacting with the falling objects.</li>
                    </ul>
                </li>
                <li>
                    <strong>win.blit(object_img, (object_x, object_y))</strong>: This line draws the falling object (the chicken) on the screen.
                    <ul>
                        <li>Using <code>blit</code>, we place the object's image (<code>object_img</code>) at the coordinates defined by <code>object_x</code> and <code>object_y</code>. These values change over time, making the object appear to fall.</li>
                        <li>The falling objects are central to the game's challenge. Their movement and positioning dictate the player's actions and strategies.</li>
                    </ul>
                    Displaying the basket and the falling objects is where the game's core interaction takes place. It's the moment where strategy, timing, and skill come into play, providing the thrill and challenge of the game. 🎲✨
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Object Rendering</strong>: In Pygame, rendering objects like the basket and falling chickens involves positioning their images on the game window using the <code>blit</code> method.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The way objects are displayed and animated in a game greatly influences its playability and appeal. Dynamic and responsive visuals keep the game engaging, encouraging players to react and interact. 🎨🕹️
            </h3>
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 25: Updating Game Info and Refreshing the Screen – Feedback and Continuity 🖥️🔄
            </h2>

            <p>
                In any game, providing real-time feedback to the player is crucial. This step focuses on updating the game's display with score and time information, and refreshing the screen to reflect these updates. It's like continuously updating a scoreboard during a sports game! 🏀⏱️
            </p>

            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />

            <p>
            Let's explore each of these updates and their significance: 📊🔍
            </p>

            <ol>
                <li>
                    <strong>draw_text(f"Score: &#10100;score&#10101;", 10, 10)</strong>: Displaying the player's current score.
                    <ul>
                        <li>This line uses the <code>draw_text</code> function we created to display the current score on the screen. The formatted string <code>f"Score: &#10100;score&#10101;"</code> dynamically shows the player's score, updating as the game progresses.</li>
                        <li>Placing this text at coordinates <code>(10, 10)</code> ensures it's visible without obstructing the gameplay. It's a way to keep players informed about their performance in real-time.</li>
                    </ul>
                </li>
                <li>
                    <strong>draw_timer(time_remaining)</strong>: Updating the time left in the game.
                    <ul>
                        <li>This line calls the <code>draw_timer</code> function, which displays the remaining game time. It helps players gauge how much time they have left to increase their score.</li>
                        <li>Timely updates on the remaining duration add to the game's tension and excitement, encouraging players to act swiftly and strategically.</li>
                    </ul>
                </li>
                <li>
                    <strong>pygame.display.update()</strong>: Refreshing the game window.
                    <ul>
                        <li>The <code>pygame.display.update()</code> function updates the entire screen. All the changes we've made (drawing the basket, objects, score, and timer) are now rendered visible to the player.</li>
                        <li>This function is essential for creating smooth animations and ensuring that all visual updates appear in real-time. It's like the final cut of a movie, where all the separate scenes come together to form a cohesive story.</li>
                    </ul>
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Real-time Feedback</strong>: Displaying the score and timer provides players with immediate feedback on their performance and progress, crucial elements in game design.
                </li>
                <li>
                    <strong>Screen Refresh</strong>: The <code>pygame.display.update()</code> function is a critical part of the game loop, ensuring that the visual elements are updated correctly on the screen.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The concept of real-time feedback in games goes beyond scores and timers. It encompasses all aspects of the game that respond to the player's actions, contributing to a dynamic and engaging experience. This interactivity is what makes video games so captivating and immersive! 🕹️💥
            </h3>
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 26: Controlling the Basket – Handling Movement 🧺🏃‍♂️
            </h2>

            <p>
                A crucial aspect of gameplay in Chicken Catch is moving the basket to catch falling objects. In this step, we start building the functionality to control the basket's movement. It's like giving the player the steering wheel in a driving game! 🚗🎮
            </p>

            <img src={twentySix} className='demo__image' alt='img_twentySix' />

            <p>
            Let's delve into the mechanics of basket movement: 🕹️🔧
            </p>

            <ol>
                <li>
                    <strong>def handle_basket_movement(keys_pressed, basket):</strong> This line defines a new function for handling the basket's movement.
                    <ul>
                        <li>The function <code>handle_basket_movement</code> is designed to respond to player input and move the basket accordingly. It takes two parameters: <code>keys_pressed</code> (a collection of key states indicating which keys are being pressed) and <code>basket</code> (the Basket object we want to move).</li>
                        <li>By creating a dedicated function for movement, we encapsulate this logic, making our main game loop cleaner and more manageable. It's like having a specific choreographer for a dance sequence in a performance.</li>
                    </ul>
                </li>
                <li>
                    <strong>move_speed = 5</strong>: Setting the movement speed of the basket.
                    <ul>
                        <li>This line defines the speed at which the basket will move. The value <code>5</code> means the basket will move by 5 pixels each time an input is detected. This speed should be fast enough for the player to react to falling objects, but not so fast that it becomes uncontrollable.</li>
                        <li>Choosing the right movement speed is crucial for balanced gameplay. It affects how easily players can catch the objects, thus influencing the overall difficulty of the game.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Function Definition</strong>: In Python, functions are defined using the <code>def</code> keyword. This function is specifically crafted to handle the dynamics of the basket's movement.
                </li>
                <li>
                    <strong>Setting Movement Speed</strong>: The speed of movement is a key factor in gameplay mechanics. It needs to be well-balanced to ensure the game is challenging yet playable.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The responsiveness of controls in a game greatly affects the player's experience. Smooth and intuitive controls make the game more enjoyable and engaging, allowing players to immerse themselves fully in the game world. 🕹️💡
            </h3>
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 27: Implementing Basket Movement – Responding to Player Input 🕹️⬅️➡️
            </h2>

            <p>
                Making the basket responsive to player commands is essential for an interactive Chicken Catch game. This step focuses on how the basket moves in response to key presses, giving players control over their game character. It's like guiding a puppet on strings! 🎮🧵
            </p>

            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />

            <p>
            Let's explore how these lines enable basket movement: 🧠🏀
            </p>

            <ol>
                <li>
                    <strong>if keys_pressed[K_LEFT] and basket.x &gt; 0:</strong> This line checks for left movement.
                    <ul>
                        <li><strong>keys_pressed[K_LEFT]</strong>: This condition checks if the left arrow key is pressed.</li>
                        <li><strong>basket.x &gt; 0</strong>: This ensures that the basket doesn't move off the screen to the left. It's a boundary condition to keep the basket within the visible game area.</li>
                        <li>When both conditions are true, the basket's x-coordinate is decreased by <code>move_speed</code>, moving the basket left.</li>
                    </ul>
                </li>
                <li>
                    <strong>if keys_pressed[K_RIGHT] and basket.x &lt; WIDTH - basket.width:</strong> This line checks for right movement.
                    <ul>
                        <li><strong>keys_pressed[K_RIGHT]</strong>: Here, we check if the right arrow key is pressed.</li>
                        <li><strong>basket.x &lt; WIDTH - basket.width</strong>: This condition ensures that the basket doesn't move off the screen to the right.</li>
                        <li>When this condition is met, <code>basket.x</code> is increased by <code>move_speed</code>, moving the basket right.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Conditional Movement</strong>: The movement of the basket is controlled by checking key presses and the basket's current position. It prevents the basket from moving beyond the game window's limits.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Responsive and intuitive controls are crucial for a satisfying gaming experience. They make the game feel more interactive and engaging, as players feel directly connected to the actions happening on screen. 🎮🎲
            </h3>
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 28: Initializing the Game Timer – Starting the Countdown ⏱️🚦
            </h2>

            <p>
                Timing is a crucial element in many games, including Chicken Catch. In this step, we set up our game timer to track how long the player has been playing. It's like starting a stopwatch at the beginning of a race! ⌛🏁
            </p>

            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />

            <p>
            Let's explore the significance of this line: 🕰️🔍
            </p>

            <ol>
                <li>
                    <strong>start_ticks = pygame.time.get_ticks()</strong>: This line marks the beginning of the game's time measurement.
                    <ul>
                        <li>The <code>pygame.time.get_ticks()</code> function returns the number of milliseconds since the Pygame library was initialized. It acts as a clock, keeping track of the passage of time.</li>
                        <li>By storing this value in <code>start_ticks</code>, we set a reference point for our game timer. This allows us to calculate the elapsed time by comparing the current time against this starting point.</li>
                        <li>Tracking time is essential for gameplay elements that depend on duration, such as limiting the game session or triggering events after specific intervals.</li>
                    </ul>
                    The timer's start is a key moment in our game. It initiates the countdown and sets the pace for the player's experience, adding a layer of urgency and challenge to the gameplay. 🏃‍♂️⏳
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Timing with Pygame</strong>: Pygame provides convenient functions like <code>get_ticks</code> for timing, crucial for implementing mechanics like countdowns and time-based challenges in games.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The use of timers in games dates back to arcade classics, where they were used to create pressure and urgency, encouraging quicker play and adding to the excitement. This concept continues to be a popular tool in game design. ⏰🎮
            </h3>
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 29: The Heart of the Game – The Main Loop 🔄💻
            </h2>

            <p>
                At the core of every game is its main loop, where all the action happens. This step is where we set up the continuous cycle that keeps our Chicken Catch game running. It's like the beating heart of our digital creation! 💗🎮
            </p>

            <img src={twentyNine} className='demo__image' alt='img_twentyNine' />

            <p>
            Let's dive into the mechanics of the main loop: 🕹️⚙️
            </p>

            <ol>
                <li>
                    <strong>while True:</strong> This line starts an infinite loop, the heart of our game's continuous running process.
                    <ul>
                        <li>An infinite loop like <code>while True</code> is used in game development to repeatedly execute game logic, such as handling input, updating game state, and rendering graphics. It continues until an explicit break condition or game exit.</li>
                        <li>This loop is where all the magic happens – from responding to player actions to updating the game screen. It keeps the game alive and responsive.</li>
                    </ul>
                </li>
                <li>
                    <strong>clock.tick(FPS)</strong>: Regulating the game's frame rate.
                    <ul>
                        <li>The <code>clock.tick(FPS)</code> function is called to control how quickly the loop runs. <code>FPS</code> (Frames Per Second) determines how many times the loop runs per second.</li>
                        <li>By setting this, we ensure that our game runs at a consistent speed, providing a smooth experience. It prevents the game from running too fast on powerful hardware or too slow on less capable machines.</li>
                    </ul>
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Infinite Loop</strong>: The use of <code>while True</code> for the game loop is a standard practice in game programming. It provides a continuous cycle for game operations.
                </li>
                <li>
                    <strong>Frame Rate Control</strong>: Controlling the frame rate is essential in game design. It balances the game's performance and responsiveness across different hardware.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The concept of a game loop is as old as video games themselves. It's a fundamental part of game architecture, enabling real-time interaction and dynamic game worlds. This continuous cycle is what makes interactive gaming possible. 🕹️🌐
            </h3>
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 30: Tracking Elapsed Time – The Game Clock Ticks On ⏲️📈
            </h2>

            <p>
                Keeping track of time is crucial in our Chicken Catch game, especially for creating a sense of urgency and progress. In this step, we calculate the elapsed time since the start of the game. It's like monitoring the timer in a sports event! ⏱️🏃‍♂️
            </p>

            <img src={thirty} className='demo__image' alt='img_thirty' />

            <p>
            Let's examine the importance of this time calculation: 🕒🔍
            </p>

            <ol>
                <li>
                    <strong>elapsed_time = pygame.time.get_ticks() - start_ticks</strong>: This line calculates the time that has passed since the game started.
                    <ul>
                        <li><strong>pygame.time.get_ticks()</strong>: This function returns the number of milliseconds since Pygame was initialized. At this point in the game loop, it gives us the current time.</li>
                        <li><strong>start_ticks</strong>: Remember, this is the time recorded when the game began (from Step 27).</li>
                        <li>By subtracting <code>start_ticks</code> from the current time, we get <code>elapsed_time</code> – the total time passed in milliseconds since the game started. This allows us to keep track of how long the game has been running and manage game events based on time.</li>
                    </ul>
                    Measuring elapsed time is key for time-based game mechanics, such as limiting game duration, triggering events, or increasing difficulty over time. It adds a dynamic aspect to the gameplay, keeping players engaged and challenged. ⌛🚀
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Calculating Elapsed Time</strong>: This is a common practice in games and other real-time applications. It helps in creating time-dependent functionality and making the game experience more dynamic.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The concept of time in games goes beyond just a countdown or timer. It can be used to create pacing, influence player behavior, and even shape the narrative of the game. Time management is a crucial skill in both playing and designing games. 🕰️🎲
            </h3>
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 31: Calculating Remaining Time – The Countdown Continues ⏳👀
            </h2>

            <p>
                As our Chicken Catch game progresses, keeping track of how much time is left becomes increasingly important. This step involves calculating the remaining game time, creating a sense of urgency for the player. It's like watching the final minutes tick down in a timed exam! 📝⌛
            </p>

            <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />

            <p>
                Let's explore the role of this calculation in the game: 🧮🕰️
            </p>

            <ol>
                <li>
                    <strong>time_remaining = game_duration - elapsed_time</strong>: This line calculates how much time is left before the game ends.
                    <ul>
                        <li><strong>game_duration</strong>: Recall that this variable, set earlier, represents the total duration of the game in milliseconds.</li>
                        <li><strong>elapsed_time</strong>: This is the time that has already passed since the start of the game.</li>
                        <li>By subtracting <code>elapsed_time</code> from <code>game_duration</code>, we get <code>time_remaining</code>. This tells us how many milliseconds are left until the game reaches its conclusion.</li>
                    </ul>
                    This remaining time calculation is crucial for gameplay dynamics. It informs the player about the urgency of their actions and can be used to trigger time-based events or alter the game's difficulty as the end approaches. 🚨🏁
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Time Management</strong>: In game design, managing the time element effectively can greatly influence the player's experience and the game's challenge level.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Time management in games serves not just as a challenge but also as a motivator. It encourages players to improve their skills, strategize better, and try to beat their or others' high scores, adding to the game's replay value. ⏱️🎮
            </h3>
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 32: Ending the Game – Time's Up! ⏰🛑
            </h2>

            <p>
                Every game needs a finish line, and in Chicken Catch, it's defined by time. This step handles the end of the game when the timer runs out. It's like the final buzzer in a basketball game signaling the end of play! 🏀⌚
            </p>

            <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />

            <p>
            Let's delve into how this concludes our game: 🏁🔚
            </p>

            <ol>
                <li>
                    <strong>if time_remaining &lt;= 0:</strong> This line checks if the game time has run out.
                    <ul>
                        <li>This conditional statement evaluates whether the remaining time is less than or equal to zero. It's a crucial check to determine if the allotted game duration has been reached.</li>
                        <li>Once the time is up, this condition becomes true, indicating that the game should come to an end. It's a way of signaling that the player's time to catch chickens is over.</li>
                    </ul>
                </li>
                <li>
                    <strong>break</strong>: Exiting the main game loop.
                    <ul>
                        <li>The <code>break</code> statement is used to exit the while loop. When the game time is up, this statement is executed, effectively ending the main game loop and, consequently, the game session.</li>
                        <li>Exiting the loop transitions the game to its next phase, which could be displaying the final score, showing a game over screen, or returning to a main menu.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Conditional Logic</strong>: Using an <code>if</code> statement to check game conditions like time or score is a fundamental technique in game programming.
                </li>
                <li>
                    <strong>Exiting a Loop</strong>: The <code>break</code> statement provides a way to exit loops when certain conditions are met, crucial for controlling game flow.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The end of a game is as important as its beginning and middle. How a game concludes can significantly impact the player's overall experience and their impression of the game. It's a critical moment that encapsulates the entire gameplay journey. 🎮🏆
            </h3>
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 33: Handling Game Exit – Graceful Closure 🚪🔚
            </h2>

            <p>
                An essential aspect of game design is managing how players can exit the game. In this step, we're setting up event handling to ensure that the game closes smoothly when the player decides to quit. It's like having a polite and efficient way to say goodbye at the end of a party! 👋💻
            </p>

            <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />

            <p>
            Let's unpack how these lines ensure a smooth exit: 🛑👨‍💻
            </p>

            <ol>
                <li>
                    <strong>for event in pygame.event.get():</strong> This line starts a loop to check for Pygame events.
                    <ul>
                        <li>Pygame uses an event system to handle user interactions, such as keyboard presses, mouse movements, or window actions. The <code>pygame.event.get()</code> function fetches all the events that have occurred since the last time it was called.</li>
                        <li>Iterating through these events allows us to respond to specific actions taken by the player.</li>
                    </ul>
                </li>
                <li>
                    <strong>if event.type == QUIT:</strong> Checking for the quit event.
                    <ul>
                        <li>This condition checks if the current event is a QUIT event, which occurs when the player closes the game window (such as clicking the 'x' button).</li>
                        <li>It's a crucial check to ensure the game knows when the player wants to stop playing.</li>
                    </ul>
                </li>
                <li>
                    <strong>pygame.quit()</strong> and <strong>sys.exit()</strong>: Exiting the game.
                    <ul>
                        <li><code>pygame.quit()</code> is called to cleanly deactivate the Pygame library. This is an important step in closing the game properly, releasing any resources Pygame is using.</li>
                        <li><code>sys.exit()</code> is then used to exit the program completely, ensuring the game closes without leaving any processes running in the background.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Event Handling</strong>: Responding to events like QUIT is a fundamental aspect of game programming, allowing for interactive and responsive game experiences.
                </li>
                <li>
                    <strong>Clean Exit</strong>: Properly exiting a game is crucial for resource management and user experience. It ensures that the game ends without issues or lingering processes.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Proper exit handling in games reflects good software design practices. It shows attention to detail and care for the player's experience, even when they decide to leave the game. It's a mark of well-designed software! 💡👍
            </h3>
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 34: Capturing Player Input – Key Press Detection ⌨️🔍
            </h2>

            <p>
                In interactive games like Chicken Catch, responding to player input is fundamental. This step focuses on detecting key presses, which allows us to control the basket's movement based on the player's commands. It's like tuning into a conversation to hear what's being said! 🎧🗣️
            </p>

            <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />

            <p>
                Let's explore the function and importance of this line: 🕹️💡
            </p>

            <ol>
                <li>
                    <strong>keys_pressed = pygame.key.get_pressed()</strong>: This line captures the current state of the keyboard.
                    <ul>
                        <li>The function <code>pygame.key.get_pressed()</code> returns a snapshot of all the keys on the keyboard and their current state (pressed or not pressed).</li>
                        <li>By storing this information in the variable <code>keys_pressed</code>, we have a way to check, at any point in our game loop, which keys are being pressed down. This enables real-time response to player input.</li>
                        <li>Key press detection is essential for controlling game elements, such as moving the basket left or right, based on player actions.</li>
                    </ul>
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Keyboard Input Handling</strong>: Detecting key presses in Pygame is a straightforward process, but it's vital for creating interactive and responsive game experiences.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The ability to respond to keyboard inputs is what makes games interactive and engaging. It closes the gap between the player and the game world, allowing for immersive and dynamic gameplay. 🕹️🌐
            </h3>
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 35: Activating Basket Control – Bringing Movement to Life 🧺💨
            </h2>

            <p>
                With player inputs captured, the next crucial step is to translate these inputs into actual movement in the game. In this step, we'll use the function we created to control the basket's movement based on the player's key presses. It's like choreographing a dance to the rhythm of the music! 🎵🕺
            </p>

            <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />

            <p>
            Let's examine the impact of this function call: 🕹️🔧
            </p>

            <ol>
                <li>
                    <strong>handle_basket_movement(keys_pressed, basket)</strong>: This line calls the function responsible for moving the basket.
                    <ul>
                        <li>We invoke the <code>handle_basket_movement</code> function, which we defined earlier, to manage the basket's position based on the current key presses.</li>
                        <li>The function takes two arguments: <code>keys_pressed</code> (which contains the state of all keyboard keys) and <code>basket</code> (our Basket object).</li>
                        <li>This line ensures that the basket responds to the player's left and right key presses, allowing them to maneuver the basket across the screen in an attempt to catch the falling objects.</li>
                    </ul>
                    By integrating this function into our game loop, we're adding the essential interactivity that makes the game engaging. It's the link between the player's intentions and the game's response. 🎮🧠
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Movement Function Call</strong>: Calling a function like <code>handle_basket_movement</code> allows for the encapsulation of movement logic, keeping the main game loop clean and the code more manageable.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The responsiveness of a game to player inputs is a critical aspect of game design. It not only affects gameplay mechanics but also significantly influences the player's sense of control and immersion in the game world. Responsive controls are key to a satisfying gaming experience. 🕹️⚙️
            </h3>
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 36: Advancing the Falling Objects – Downward Movement 🍁⬇️
            </h2>

            <p>
                A key aspect of our Chicken Catch game is the continuous descent of falling objects. In this step, we update the vertical position of the object to simulate its fall. It's like watching leaves gently drop from a tree in autumn! 🌳🍂
            </p>

            <img src={thirtySix} className='demo__image' alt='img_thirtySix' />

            <p>
            Let's delve into the significance of this line: 🚀🔻
            </p>

            <ol>
                <li>
                    <strong>object_y += object_speed</strong>: This line is responsible for moving the falling object downwards.
                    <ul>
                        <li>The <code>object_y</code> variable represents the vertical position of the falling object on the screen. By incrementing it by <code>object_speed</code>, we make the object move down each frame.</li>
                        <li>This continuous downward movement is what creates the challenge in the game. The player must maneuver the basket to catch these objects as they fall.</li>
                        <li>The speed at which the objects fall (<code>object_speed</code>) has been previously set, determining how fast the game's challenge progresses. Adjusting this speed can vary the difficulty level of the game.</li>
                    </ul>
                    The downward movement of objects is a simple yet effective mechanic that adds dynamism to the game. It's a fundamental part of what makes Chicken Catch engaging and fun. 🐔💨
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Updating Position</strong>: The line <code>object_y += object_speed</code> is a common way to update an object's position over time in game development, creating a sense of motion.
                </li>
            </ul>

            <h3>
            🎈 Did You Know?: The principle of moving objects in games is based on the idea of animation frames. By changing an object's position slightly with each frame, we create the illusion of smooth movement, a concept central to animation and game graphics. 🎞️🎮
            </h3>
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 37: Resetting and Scoring – Falling Object Dynamics 🔄🎯
            </h2>

            <p>
                In games, managing what happens when an object reaches a certain point or condition is crucial. This step deals with resetting the falling object when it moves past the bottom of the screen and adjusting the score accordingly. It's like managing the consequences in a game of action and reaction! 🌌⬇️
            </p>

            <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />

            <p>
            Let's dissect these lines and their impact on the game: 🎲🔬
            </p>

            <ol>
                <li>
                    <strong>if object_y &gt; HEIGHT:</strong> Checking if the object has passed the bottom of the screen.
                    <ul>
                        <li>This condition checks whether the falling object has moved beyond the bottom of the game window (represented by <code>HEIGHT</code>).</li>
                        <li>If true, it implies that the object was not caught by the basket, and the game needs to respond to this event.</li>
                    </ul>
                </li>
                <li>
                    <strong>object_x = random.randint(0, WIDTH - 50)</strong>: Randomly resetting the object's horizontal position.
                    <ul>
                        <li>When the object moves past the bottom, it needs to reappear from the top. This line chooses a new random horizontal starting position for the object to begin its descent again.</li>
                        <li>The use of <code>random.randint</code> ensures that the game remains unpredictable and challenging, as the objects don't always fall from the same position.</li>
                    </ul>
                </li>
                <li>
                    <strong>object_y = -50</strong>: Resetting the object's vertical position.
                    <ul>
                        <li>Setting <code>object_y</code> to <code>-50</code> places the object just above the top of the screen, making it ready to fall down again.</li>
                        <li>This reset simulates a continuous stream of objects falling, keeping the game's pace and challenge constant.</li>
                    </ul>
                </li>
                <li>
                    <strong>score -= 2</strong>: Adjusting the score for a missed catch.
                    <ul>
                        <li>Missing an object results in a penalty, decreasing the player's score by 2 points. This adds an extra layer of challenge, motivating players to catch as many objects as possible.</li>
                        <li>The score change serves as immediate feedback to the player, indicating the success or failure of their actions.</li>
                    </ul>
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Conditional Logic and Scoring</strong>: This step demonstrates the use of conditional statements to change the game state and score based on the player's actions and the game's rules.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The concept of scoring and penalties in games goes back to the earliest arcade games. It creates a sense of accomplishment and challenge, driving players to improve their skills and strategies. It's a timeless element of game design that adds depth and engagement. 🕹️🏆
            </h3>
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 38: Detecting Catches – Collision Logic 🧺🎯
            </h2>

            <p>
                In Chicken Catch, the core gameplay involves catching falling objects with the basket. This step focuses on detecting when an object has been successfully caught. It's like setting the rules for scoring a goal in a soccer match! ⚽🥅
            </p>

            <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />

            <p>
            Let's break down this collision detection logic: 🕹️🔍
            </p>

            <ol>
                <li>
                    <strong>if (basket.x &lt; object_x &lt; basket.x + basket.width or basket.x &lt; object_x + 50 &lt; basket.x + basket.width):</strong> This line checks if the falling object has landed within the bounds of the basket.
                    <ul>
                        <li>The condition consists of two parts, connected by an 'or' operator. Each part checks whether a portion of the falling object (its starting point or its ending point after adding its width) is within the horizontal range of the basket.</li>
                        <li><strong>basket.x &lt; object_x &lt; basket.x + basket.width</strong>: This checks if the left side of the object falls within the basket.</li>
                        <li><strong>basket.x &lt; object_x + 50 &lt; basket.x + basket.width</strong>: This checks if the right side of the object (object_x + 50) falls within the basket.</li>
                        <li>If either of these conditions is true, it means the object has intersected with the basket, indicating a successful catch.</li>
                    </ul>
                    Collision detection is a fundamental aspect of many games, determining interactions between objects. In Chicken Catch, this logic is key for calculating scores and advancing the gameplay. 🚀🕹️
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Collision Detection</strong>: The use of coordinate-based conditions to determine if two objects in a game (like the basket and a falling object) have intersected or 'collided'.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: Collision detection is not just about physical collisions; it's a way to make virtual objects interact in a meaningful way. It's used in various genres of games to create challenges, puzzles, and interactions, adding depth to gameplay. 🎮💥
            </h3>
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 39: Enhancing Collision Detection – Vertical Alignment Check 🧺🔽
            </h2>

            <p>
                Continuing from our previous step on collision detection, we now focus on ensuring the falling objects align vertically with the basket for a successful catch. This step completes the logic to accurately detect when an object has been caught. It's like fine-tuning the sensors in a game to ensure accurate scoring! 🕹️🎯
            </p>

            <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />

            <p>
            Let's dissect this vertical alignment check: 🔍🆙
            </p>

            <ol>
                <li>
                    <strong>and (basket.y &lt; object_y + 50 &lt; basket.y + basket.height or basket.y &lt; object_y &lt; basket.y + basket.height):</strong> This code completes the collision detection by adding vertical conditions.
                    <ul>
                        <li>Similar to the horizontal check, this condition involves two parts to verify if the falling object is within the vertical range of the basket.</li>
                        <li><strong>basket.y &lt; object_y + 50 &lt; basket.y + basket.height</strong>: Checks if the bottom part of the object is within the vertical boundaries of the basket.</li>
                        <li><strong>basket.y &lt; object_y &lt; basket.y + basket.height</strong>: Checks if the top part of the object is within the vertical boundaries of the basket.</li>
                        <li>Only when both horizontal and vertical conditions are met (as combined by the 'and' operator) is the object considered to have been successfully caught by the basket.</li>
                    </ul>
                    This comprehensive collision detection ensures that catches are registered accurately, based on the precise positioning of the object relative to the basket. It's a crucial part of the game mechanics, adding realism and fairness to the gameplay. 🚀🧺
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Advanced Collision Detection</strong>: This step shows how to use coordinate comparisons to determine if two objects not only overlap horizontally but also align vertically, ensuring a more precise and realistic collision detection.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: In video games, collision detection is not just a technical feature; it's a way to enhance the player's immersion and interaction with the game world. Accurate collision detection makes games feel more responsive and engaging, a key aspect of game design. 🌐🎲
            </h3>
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 40: Scoring a Catch – Updating Game State 🎉📈
            </h2>

            <p>
                Successfully catching objects is at the heart of the Chicken Catch game. This step focuses on what happens when a catch is made: resetting the object's position for a new fall and updating the score. It's like awarding points in a sport for a well-executed play! 🏀🏆
            </p>

            <img src={forty} className='demo__image' alt='img_forty' />

            <p>
            Let's examine the impact of these actions on the game: 🕹️📊
            </p>

            <ol>
                <li>
                    <strong>object_x = random.randint(0, WIDTH - 50)</strong>: Randomly resetting the object's horizontal position.
                    <ul>
                        <li>Once an object is caught, it needs to be reset to keep the game going. This line selects a new random horizontal starting position for the object, ensuring the game remains unpredictable and challenging.</li>
                    </ul>
                </li>
                <li>
                    <strong>object_y = -50</strong>: Resetting the object's vertical position.
                    <ul>
                        <li>Setting the y-coordinate to <code>-50</code> places the object just above the top of the screen, ready for its next descent.</li>
                    </ul>
                </li>
                <li>
                    <strong>score += 1</strong>: Increasing the player's score.
                    <ul>
                        <li>For each successful catch, the player's score is increased by 1 point. This serves as a reward for the player's skill and effort, motivating them to keep playing and improving their performance.</li>
                        <li>The score is a crucial feedback mechanism in games, providing players with a sense of achievement and progress.</li>
                    </ul>
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Resetting and Scoring</strong>: This combination of resetting the object and updating the score is a common pattern in games where players are rewarded for completing specific actions or challenges.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The feedback loop of action, result, and reward is a fundamental principle of game design. It engages players by providing a sense of accomplishment and progress, which are key drivers of player enjoyment and retention. 🕹️🎖️
            </h3>
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 41: Refreshing the Game Scene – Updating the Display 🖥️🌟
            </h2>

            <p>
                A key aspect of creating a dynamic game experience is regularly updating the game display. This step involves calling the function we created to draw the game's elements, reflecting the ongoing changes in the game state. It's like continually repainting a canvas to capture a scene's evolving nature! 🎨🔄
            </p>

            <img src={fortyOne} className='demo__image' alt='img_fortyOne' />

            <p>
            Let's delve into the role of this function call: 🖌️🕹️
            </p>

            <ol>
                <li>
                    <strong>draw_window(time_remaining)</strong>: Calling the function to update the game screen.
                    <ul>
                        <li>This line invokes the <code>draw_window</code> function, which is responsible for drawing all the visible elements in the game, like the background, basket, falling objects, score, and timer.</li>
                        <li>The <code>time_remaining</code> argument is passed to the function to update the timer display on the screen.</li>
                        <li>Regularly updating the game window is crucial to show the player the immediate consequences of their actions, like catching or missing objects, and the changing game timer.</li>
                    </ul>
                    This constant refreshing of the game scene is what makes digital games engaging. It provides a real-time interactive experience, keeping players immersed and involved in the gameplay. 🚀📺
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Updating the Display</strong>: Regularly calling a function like <code>draw_window</code> is essential in game loops to ensure that the visuals accurately reflect the current state of the game.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The concept of redrawing the game screen at regular intervals is what allows video games to simulate motion and change. This technique is rooted in the principles of animation and is a cornerstone of interactive media. 🎬🎮
            </h3>
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 42: Clearing the Canvas – Preparing for New Drawings 🎨🌟
            </h2>

            <p>
                In any dynamic game, it's essential to start each frame with a clean slate. This step involves clearing the game window before redrawing updated game elements. Think of it as erasing a whiteboard before drawing new content. 🖌️📋
            </p>

            <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />

            <p>
            Let's understand the purpose of this action: 🧽🖼️
            </p>

            <ol>
                <li>
                    <strong>win.fill(WHITE)</strong>: This line clears the entire game window with a white color.
                    <ul>
                        <li>The <code>fill</code> method is used on the game window object <code>win</code> to fill it with a single color. Here, <code>WHITE</code> is the color used, which we defined earlier in the game.</li>
                        <li>By filling the screen with a solid color, we effectively remove all previous drawings. This is necessary to avoid visual artifacts and to update the screen with the latest game state in the next drawing phase.</li>
                        <li>This process is similar to flipping to a new page in an animation sketchbook, where each new page starts blank, allowing for the creation of a new image.</li>
                    </ul>
                    Clearing the screen at the beginning of each frame is a standard practice in game development. It ensures that each frame is drawn afresh, maintaining the visual integrity and coherence of the game's graphics. 🚀📺
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Screen Clearing</strong>: The <code>fill</code> method is a simple yet effective way to clear the screen in preparation for redrawing updated game elements.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: This method of clearing and redrawing the screen is rooted in the concept of double buffering, a technique used in computer graphics to minimize flickering and provide smoother visual updates. It's an essential technique in modern game development. 🖥️👾
            </h3>
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 43: Signaling the End – Displaying the Game Over Message 🏁📢
            </h2>

            <p>
                In any game, communicating the end of a session is crucial for player understanding. This step involves displaying a 'Game Over' message, signifying the conclusion of the game. It's like the final curtain call in a theater performance, signaling the end of the show. 🎭🔚
            </p>

            <img src={fortyThree} className='demo__image' alt='img_fortyThree' />

            <p>
            Let's explore the impact of this line on the game experience: 💬👀
            </p>

            <ol>
                <li>
                    <strong>draw_text("Game Over", WIDTH // 2 - 100, HEIGHT // 2 - 100, size=60)</strong>: Displaying the end-of-game message.
                    <ul>
                        <li>This line uses our <code>draw_text</code> function to place a 'Game Over' message on the screen. This text informs players that the game session has concluded.</li>
                        <li>The coordinates <code>(WIDTH // 2 - 100, HEIGHT // 2 - 100)</code> are calculated to position the message roughly in the center of the screen, ensuring it is easily noticeable by the player.</li>
                        <li>The size parameter is set to <code>60</code>, making the text larger and more prominent, befitting the importance of this message.</li>
                    </ul>
                    Displaying a 'Game Over' message is a traditional element in video games, providing clear feedback to the player about the completion of the game. It's a moment of closure, allowing players to reflect on their performance and encouraging them to play again. 🎲🏆
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>End-Game Feedback</strong>: The use of text messages like 'Game Over' is a common way to communicate important game states to the player, marking transitions such as the end of a game session.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The 'Game Over' screen has a long history in gaming, often serving as a prompt for players to try again and beat their previous scores. It's a simple yet effective tool for engagement and replayability in games. 🕹️🔄
            </h3>
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 44: Displaying the Final Score – Acknowledging Player Achievement 🎖️📊
            </h2>

            <p>
                In gaming, acknowledging the player's efforts with a final score is crucial for a satisfying conclusion. This step involves displaying the final score at the end of the Chicken Catch game, providing players with a quantifiable measure of their performance. It's like showing the scorecard at the end of a sports match. 🏆📝
            </p>

            <img src={fortyFour} className='demo__image' alt='img_fortyFour' />

            <p>
            Let's delve into the significance of this score display: 🕹️🔢
            </p>

            <ol>
                <li>
                    <strong>draw_text(f"Final Score: &#10100;score&#10101;", WIDTH // 2 - 100, HEIGHT // 2, size=40)</strong>: This line is responsible for showing the player's final score.
                    <ul>
                        <li>Using the <code>draw_text</code> function, we display the score the player achieved by the end of the game. The text includes the player's score, dynamically inserted into the string with &#10100;score&#10101;.</li>
                        <li>The coordinates <code>(WIDTH // 2 - 100, HEIGHT // 2)</code> place the score message centrally on the screen, just below the 'Game Over' message, making it easily visible.</li>
                        <li>The font size is set to <code>40</code>, large enough to be easily readable, while differentiating it from the more prominent 'Game Over' message.</li>
                    </ul>
                    Displaying the final score provides players with a sense of closure and achievement. It's a feedback mechanism that lets them know how well they performed and serves as an incentive to improve in future games. 🌟🕹️
                </li>
            </ol>

            <h3>
                Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Score Display</strong>: Presenting the final score at the end of a game is a common practice, providing players with a tangible outcome of their efforts and potentially motivating them to play again.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: The final score in games often serves as a benchmark for players to measure their progress and improvement over time. It's a classic element in games that spurs competition and self-improvement. 📈🏅
            </h3>
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 45: Concluding the Game – Finalizing the Session 🏁👋
            </h2>

            <p>
                The end of a game session is as important as its beginning. This final step ensures a proper conclusion to the Chicken Catch game by updating the display with the final messages and then gracefully exiting. It's like the final bow at the end of a performance, followed by closing the curtains. 🎭🚪
            </p>

            <img src={fortyFive} className='demo__image' alt='img_fortyFive' />

            <p>
            Let's walk through each of these concluding actions: 📺⏳
            </p>

            <ol>
                <li>
                    <strong>pygame.display.update()</strong>: Final screen update.
                    <ul>
                        <li>This line updates the entire screen to display the 'Game Over' message and the final score. It ensures that these last crucial elements are rendered for the player to see.</li>
                    </ul>
                </li>
                <li>
                    <strong>pygame.time.delay(5000)</strong>: Pausing before exit.
                    <ul>
                        <li>This function pauses the game for 5000 milliseconds (5 seconds), allowing the player time to view the final score and process the end of the game.</li>
                        <li>A delay before closing the game window offers a moment of reflection and closure for the player.</li>
                    </ul>
                </li>
                <li>
                    <strong>pygame.quit()</strong>: Shutting down Pygame.
                    <ul>
                        <li>This command deactivates all Pygame modules, tidying up any resources that Pygame was using. It's an important step to ensure that the game shuts down cleanly.</li>
                    </ul>
                </li>
                <li>
                    <strong>sys.exit()</strong>: Exiting the program.
                    <ul>
                        <li>This line exits Python entirely, ensuring that the game application closes properly without leaving any running processes.</li>
                    </ul>
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>Graceful Exit</strong>: These final steps in the game loop handle the transition out of the game smoothly and effectively, providing a good user experience.
                </li>
            </ul>

            <h3>
                🎈 Did You Know?: A smooth and thoughtful conclusion to a game respects the player's experience and time spent. It's a sign of well-designed software, emphasizing the importance of not just how games play, but also how they end. 🌟👍
            </h3>
        </div>



        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default Catch