import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import PythonTutorial from './pages/pythonTutorial';
import ScratchTutorial from './pages/scratchTutorial';
import RobloxTutorial from './pages/robloxTutorial';
import FullstackTutorial from './pages/fullstackTutorial';
import JoinMeeting from './pages/joinMeeting';
import ClassesOnDeck from './pages/classesondeck';
import TeacherResources from './pages/teacher-resources/teacherResources';
import MyProgress from './pages/my-progress/myProgress';
import Availability from './pages/availability/availability';

function App() {
  return (
    <Router>
      <Routes>
        
        <Route exact path="/*" element={<Home />} />
        
        <Route exact path="/python-guide/*" element={<PythonTutorial />} />

        <Route exact path="/scratch-guide/*" element={<ScratchTutorial />} />

        <Route exact path="/roblox-guide/*" element={<RobloxTutorial />} />

        <Route exact path="/fullstack-guide/*" element={<FullstackTutorial />} />

        <Route exact path="/join-meeting" element={<JoinMeeting />} />

        <Route exact path="/upcoming-classes" element={<ClassesOnDeck />} />

        <Route exact path="/teacher-resources" element={<TeacherResources />} />

        <Route exact path="/my-progress" element={<MyProgress />} />

        <Route exact path="/availability" element={<Availability />} />

      </Routes>
    </Router>
  );
}

export default App;
