import React from 'react';
import { useNavigate } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
  const navigate = useNavigate();
  const role = JSON.parse(localStorage.getItem("user"))?.role

  return (
    <div className='navbar'>
        <div className='navbar-left'>
            <p onClick={()=>navigate("/scratch")} >Home</p>
        </div>


        {
          role === "student" &&
          <div className='navbar-right'>
            <p onClick={()=>navigate("/join-meeting")} >Join Class</p>

            <p onClick={()=>navigate("/my-progress")} >My Progress</p>

            {/*<p>Account</p>*/}
          </div>
        }

        {
          role === "teacher" &&
          <div className='navbar-right'>
            <p onClick={()=>navigate("/upcoming-classes")}>Classes On Deck</p>

            <p onClick={()=>navigate("/teacher-resources")}>Teacher Resources</p>

            <p onClick={()=>navigate("/availability")}>Your Availability</p>
          </div>
        }
    </div>
  )
}

export default Navbar