import React, {useState} from 'react';
import './copybox.css';

const CopyBox = ({ text }) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopySuccess('Copied!');
        setTimeout(() => setCopySuccess(''), 2000); // Reset message after 2 seconds
      },
      (err) => {
        setCopySuccess('Failed to copy!');
        console.error('Error copying text: ', err);
      }
    );
  };


  return (
    <div className="copy-container">
      <textarea className="copy-text" value={text} readOnly />
      <button className="copy-button" onClick={copyToClipboard}>
        Copy Text
      </button>
      {copySuccess && <div className="copy-success">{copySuccess}</div>}
    </div>
  )
}

export default CopyBox