import React, {useState} from 'react';
import one from './robloxImages/GEMHUNTER/1.png';
import two from './robloxImages/GEMHUNTER/2.png';
import three from './robloxImages/GEMHUNTER/3.png';
import four from './robloxImages/GEMHUNTER/4.png';
import five from './robloxImages/GEMHUNTER/5.png';
import six from './robloxImages/GEMHUNTER/6.png';
import seven from './robloxImages/GEMHUNTER/7.png';
import eight from './robloxImages/GEMHUNTER/8.png';
import nine from './robloxImages/GEMHUNTER/9.png';
import ten from './robloxImages/GEMHUNTER/10.png';
import eleven from './robloxImages/GEMHUNTER/11.png';
import twelve from './robloxImages/GEMHUNTER/12.png';
import thirteen from './robloxImages/GEMHUNTER/13.png';
import fourteen from './robloxImages/GEMHUNTER/14.png';
import fifteen from './robloxImages/GEMHUNTER/15.png';
import sixteen from './robloxImages/GEMHUNTER/16.png';
import seventeen from './robloxImages/GEMHUNTER/17.png';
import eighteen from './robloxImages/GEMHUNTER/18.png';
import nineteen from './robloxImages/GEMHUNTER/19.png';
import twenty from './robloxImages/GEMHUNTER/20.png';
import twentyOne from './robloxImages/GEMHUNTER/21.png';
import twentyTwo from './robloxImages/GEMHUNTER/22.png';
import twentyThree from './robloxImages/GEMHUNTER/23.png';
import twentyFour from './robloxImages/GEMHUNTER/24.png';
import twentyFive from './robloxImages/GEMHUNTER/25.png';
import twentySix from './robloxImages/GEMHUNTER/26.png';
import twentySeven from './robloxImages/GEMHUNTER/27.png';
import twentyEight from './robloxImages/GEMHUNTER/28.png';
import twentyNine from './robloxImages/GEMHUNTER/29.png';
import thirty from './robloxImages/GEMHUNTER/30.png';
import thirtyOne from './robloxImages/GEMHUNTER/31.png';
import thirtyTwo from './robloxImages/GEMHUNTER/32.png';
import thirtyThree from './robloxImages/GEMHUNTER/33.png';
import thirtyFour from './robloxImages/GEMHUNTER/34.png';
import thirtyFive from './robloxImages/GEMHUNTER/35.png';
import thirtySix from './robloxImages/GEMHUNTER/36.png';
import thirtySeven from './robloxImages/GEMHUNTER/37.png';
import thirtyEight from './robloxImages/GEMHUNTER/38.png';
import thirtyNine from './robloxImages/GEMHUNTER/39.png';
import forty from './robloxImages/GEMHUNTER/40.png';
import fortyOne from './robloxImages/GEMHUNTER/41.png';
import fortyTwo from './robloxImages/GEMHUNTER/42.png';
import fortyThree from './robloxImages/GEMHUNTER/43.png';
import fortyFour from './robloxImages/GEMHUNTER/44.png';
import fortyFive from './robloxImages/GEMHUNTER/45.png';
import fortySix from './robloxImages/GEMHUNTER/46.png';
import fortySeven from './robloxImages/GEMHUNTER/47.png';
import fortyEight from './robloxImages/GEMHUNTER/48.png';
import fortyNine from './robloxImages/GEMHUNTER/49.png';
import fifty from './robloxImages/GEMHUNTER/50.png';
import fiftyOne from './robloxImages/GEMHUNTER/51.png';
import fiftyTwo from './robloxImages/GEMHUNTER/52.png';
import fiftyThree from './robloxImages/GEMHUNTER/53.png';
import fiftyFour from './robloxImages/GEMHUNTER/54.png';
import fiftyFive from './robloxImages/GEMHUNTER/55.png';
import fiftySix from './robloxImages/GEMHUNTER/56.png';
import fiftySeven from './robloxImages/GEMHUNTER/57.png';
import fiftyEight from './robloxImages/GEMHUNTER/58.png';
import fiftyNine from './robloxImages/GEMHUNTER/59.png';
import sixty from './robloxImages/GEMHUNTER/60.png';
import sixtyOne from './robloxImages/GEMHUNTER/61.png';
import sixtyTwo from './robloxImages/GEMHUNTER/62.png';
import sixtyThree from './robloxImages/GEMHUNTER/63.png';
import sixtyFour from './robloxImages/GEMHUNTER/64.png';
import sixtyFive from './robloxImages/GEMHUNTER/65.png';
import sixtySix from './robloxImages/GEMHUNTER/66.png';
import sixtySeven from './robloxImages/GEMHUNTER/67.png';
import sixtyEight from './robloxImages/GEMHUNTER/68.png';
import sixtyNine from './robloxImages/GEMHUNTER/69.png';
import seventy from './robloxImages/GEMHUNTER/70.png';
import seventyOne from './robloxImages/GEMHUNTER/71.png';
import seventyTwo from './robloxImages/GEMHUNTER/72.png';
import seventyThree from './robloxImages/GEMHUNTER/73.png';
import seventyFour from './robloxImages/GEMHUNTER/74.png';
import seventyFive from './robloxImages/GEMHUNTER/75.png';
import seventySix from './robloxImages/GEMHUNTER/76.png';
import seventySeven from './robloxImages/GEMHUNTER/77.png';
import seventyEight from './robloxImages/GEMHUNTER/78.png';
import seventyNine from './robloxImages/GEMHUNTER/79.png';
import eighty from './robloxImages/GEMHUNTER/80.png';
import eightyOne from './robloxImages/GEMHUNTER/81.png';
import eightyTwo from './robloxImages/GEMHUNTER/82.png';
import eightyThree from './robloxImages/GEMHUNTER/83.png';
import eightyFour from './robloxImages/GEMHUNTER/84.png';
import eightyFive from './robloxImages/GEMHUNTER/85.png';
import eightySix from './robloxImages/GEMHUNTER/86.png';
import eightySeven from './robloxImages/GEMHUNTER/87.png';
import eightyEight from './robloxImages/GEMHUNTER/88.png';
import eightyNine from './robloxImages/GEMHUNTER/89.png';
import ninety from './robloxImages/GEMHUNTER/90.png';
import NineOne from './robloxImages/GEMHUNTER/91.png';
import NineTwo from './robloxImages/GEMHUNTER/92.png';
import NineThree from './robloxImages/GEMHUNTER/93.png';
import NineFour from './robloxImages/GEMHUNTER/94.png';
import NineFive from './robloxImages/GEMHUNTER/95.png';
import NineSix from './robloxImages/GEMHUNTER/96.png';
import NineSeven from './robloxImages/GEMHUNTER/97.png';
import NineEight from './robloxImages/GEMHUNTER/98.png';
import NineNine from './robloxImages/GEMHUNTER/99.png';
import oneHundred from './robloxImages/GEMHUNTER/100.png';
import OneZeroOne from './robloxImages/GEMHUNTER/101.png';
import OneZeroTwo from './robloxImages/GEMHUNTER/102.png';
import OneZeroThree from './robloxImages/GEMHUNTER/103.png';
import OneZeroFour from './robloxImages/GEMHUNTER/104.png';
import OneZeroFive from './robloxImages/GEMHUNTER/105.png';
import OneZeroSix from './robloxImages/GEMHUNTER/106.png';
import OneZeroSeven from './robloxImages/GEMHUNTER/107.png';
import OneZeroEight from './robloxImages/GEMHUNTER/108.png';
import OneZeroNine from './robloxImages/GEMHUNTER/109.png';
import OneOneZero from './robloxImages/GEMHUNTER/110.png';
import OneOneOne from './robloxImages/GEMHUNTER/111.png';
import OneOneTwo from './robloxImages/GEMHUNTER/112.png';
import OneOneThree from './robloxImages/GEMHUNTER/113.png';
import OneOneFour from './robloxImages/GEMHUNTER/114.png';
import OneOneFive from './robloxImages/GEMHUNTER/115.png';
import OneOneSix from './robloxImages/GEMHUNTER/116.png';
import OneOneSeven from './robloxImages/GEMHUNTER/117.png';
import OneOneEight from './robloxImages/GEMHUNTER/118.png';
import OneOneNine from './robloxImages/GEMHUNTER/119.png';
import OneTwoZero from './robloxImages/GEMHUNTER/120.png';
import OneTwoOne from './robloxImages/GEMHUNTER/121.png';
import OneTwoTwo from './robloxImages/GEMHUNTER/122.png';
import OneTwoThree from './robloxImages/GEMHUNTER/123.png';
import OneTwoFour from './robloxImages/GEMHUNTER/124.png';
import OneTwoFive from './robloxImages/GEMHUNTER/125.png';
import OneTwoSix from './robloxImages/GEMHUNTER/126.png';
import OneTwoSeven from './robloxImages/GEMHUNTER/127.png';
import OneTwoEight from './robloxImages/GEMHUNTER/128.png';
import OneTwoNine from './robloxImages/GEMHUNTER/129.png';
import OneThreeZero from './robloxImages/GEMHUNTER/130.png';
import OneThreeOne from './robloxImages/GEMHUNTER/131.png';
import OneThreeTwo from './robloxImages/GEMHUNTER/132.png';
import OneThreeThree from './robloxImages/GEMHUNTER/133.png';
import OneThreeFour from './robloxImages/GEMHUNTER/134.png';
import OneThreeFive from './robloxImages/GEMHUNTER/135.png';
import OneThreeSix from './robloxImages/GEMHUNTER/136.png';
import OneThreeSeven from './robloxImages/GEMHUNTER/137.png';
import OneThreeEight from './robloxImages/GEMHUNTER/138.png';
import OneThreeNine from './robloxImages/GEMHUNTER/139.png';
import OneFourZero from './robloxImages/GEMHUNTER/140.png';
import OneFourOne from './robloxImages/GEMHUNTER/141.png';
import OneFourTwo from './robloxImages/GEMHUNTER/142.png';
import OneFourThree from './robloxImages/GEMHUNTER/143.png';
import OneFourFour from './robloxImages/GEMHUNTER/144.png';
import OneFourFive from './robloxImages/GEMHUNTER/145.png';
import OneFourSix from './robloxImages/GEMHUNTER/146.png';
import OneFourSeven from './robloxImages/GEMHUNTER/147.png';
import OneFourEight from './robloxImages/GEMHUNTER/148.png';
import OneFourNine from './robloxImages/GEMHUNTER/149.png';
import OneFiveZero from './robloxImages/GEMHUNTER/150.png';
import OneFiveOne from './robloxImages/GEMHUNTER/151.png';
import OneFiveTwo from './robloxImages/GEMHUNTER/152.png';
import OneFiveThree from './robloxImages/GEMHUNTER/153.png';
import OneFiveFour from './robloxImages/GEMHUNTER/154.png';
import OneFiveFive from './robloxImages/GEMHUNTER/155.png';
import OneFiveSix from './robloxImages/GEMHUNTER/156.png';
import OneFiveSeven from './robloxImages/GEMHUNTER/157.png';
import OneFiveEight from './robloxImages/GEMHUNTER/158.png';
import OneFiveNine from './robloxImages/GEMHUNTER/159.png';
import OneSixZero from './robloxImages/GEMHUNTER/160.png';
import OneSixOne from './robloxImages/GEMHUNTER/161.png';
import OneSixTwo from './robloxImages/GEMHUNTER/162.png';
import OneSixThree from './robloxImages/GEMHUNTER/163.png';
import OneSixFour from './robloxImages/GEMHUNTER/164.png';
import OneSixFive from './robloxImages/GEMHUNTER/165.png';
import OneSixSix from './robloxImages/GEMHUNTER/166.png';
import OneSixSeven from './robloxImages/GEMHUNTER/167.png';
import OneSixEight from './robloxImages/GEMHUNTER/168.png';
import OneSixNine from './robloxImages/GEMHUNTER/169.png';
import OneSevenZero from './robloxImages/GEMHUNTER/170.png';
import OneSevenOne from './robloxImages/GEMHUNTER/171.png';
import OneSevenTwo from './robloxImages/GEMHUNTER/172.png';
import OneSevenThree from './robloxImages/GEMHUNTER/173.png';
import OneSevenFour from './robloxImages/GEMHUNTER/174.png';
import OneSevenFive from './robloxImages/GEMHUNTER/175.png';
import OneSevenSix from './robloxImages/GEMHUNTER/176.png';
import OneSevenSeven from './robloxImages/GEMHUNTER/177.png';
import OneSevenEight from './robloxImages/GEMHUNTER/178.png';
import OneSevenNine from './robloxImages/GEMHUNTER/179.png';
import OneEightZero from './robloxImages/GEMHUNTER/180.png';
import OneEightOne from './robloxImages/GEMHUNTER/181.png';
import OneEightTwo from './robloxImages/GEMHUNTER/182.png';
import OneEightThree from './robloxImages/GEMHUNTER/183.png';
import OneEightFour from './robloxImages/GEMHUNTER/184.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const Gem = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 186;

  return (
    <div className='robloxGames__container'>
        <h1>
        💎 Gem Hunter: Find All of the Gems and Defeat the Challenges 💎
        </h1>

        <h3 className='intro'>
        In this flamboyant🦩 project ready up to find treasures of the deep, craft high tech relics and break new barriers🧱 as you take your Lua coding skills🖥️ to the next level ⬆️
        </h3>

        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>

        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 1: Choosing Your Template 🌟</h2>
          <ul>
              <li>
              Open Roblox Studio from your desktop or Start menu🖱️
              </li>
              <li>
              Sign in with your Roblox account or create a new one🔑
              </li>
              <li>
              Click "New" to create a new project and select "Classic Baseplate" as the template🗺️<br/><br/>(By doing this, you're starting a fresh new game project with a simple baseplate to build your obby on.🛠️)
              </li>
          </ul>

          <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 2: Initialize Your Workspace 🎨</h2>

          <ul>
            <li>
            After selecting the "Classic Baseplate", you'll see your workspace with a large, flat grey area in the center—this is your baseplate!
            </li>

            <li>
            Familiarize yourself with the toolbar at the top. Tools like "Select", "Move", and "Scale" will be essential as you build.
            </li>

            <li>
            Take a moment to explore the interface. Notice the "Explorer" and "Properties" tabs on the side—they will be key for customizing your game.
            </li>
          </ul>

          <img src={two} className='demo__image' alt='img_two' />
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 3: Terrain Editing Tools 🌄</h2>

          <ul>
            <li>
            To start shaping your world, you need to access the "Terrain Editor". Look for the mountain-like icon in the "view" tab, it is your gateway to creating landscapes.
            </li>

            <li>
            Click on the "Terrain Editor". A new window will pop up with options like "Create", "Edit", "Generate", and "Import".
            </li>

            <li>
            Before you dive into editing, notice the "Clear" button. It allows you to reset your terrain, but be careful not to erase your hard work by accident!
            </li>
          </ul>

          <img src={three} className='demo__image' alt='img_three' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 4: Generating Terrain 🌳</h2>

          <ul>
            <li>
            In the "Terrain Editor", click on the "Generate" tab to start creating natural landscapes.
            </li>

            <li>
            You'll see a preview of your baseplate in the workspace area with various axis indicators (red for X, green for Y, blue for Z).
            </li>

            <li>
            Use the axis indicators to understand how your terrain will be positioned in 3D space. This helps you visualize the world you're about to create!
            </li>
          </ul>

          <img src={four} className='demo__image' alt='img_four' />
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 5: Customizing Terrain Biomes 🌲❄️🏜️</h2>

          <ul>
            <li>
            Under the "Generate" tab, you'll find a section called "Material Settings".
            </li>

            <li>
            Here you can select different biomes such as "Water", "Plains", "Mountains", and more to add variety to your terrain.
            </li>

            <li>
            Check the boxes next to the biomes you want to include. This will give your game unique areas that players can explore and enjoy!
            </li>
          </ul>

          <img src={five} className='demo__image' alt='img_five' />
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 6: Generating Your Terrain 🗻🌲</h2>

          <ul>
            <li>
            After selecting your desired biomes, it's time to bring them to life! Click the big "Generate" button. This is where the magic happens—you're creating the physical space where players will explore and interact.
            </li>

            <li>
            By selecting various biomes, you ensure your game has diversity, which is essential for an engaging player experience. Different terrain types can inspire different gameplay elements, like obstacle courses in canyons or treasure hunts in arctic regions.
            </li>
          </ul>

          <img src={six} className='demo__image' alt='img_six' />
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 7: Observing Your Creation 🌍</h2>

          <ul>
            <li>
            Behold the landscape you've generated! You'll see mountains, lakes, and plains—each biome you've selected now has a place in your game.
            </li>

            <li>
            This step is crucial because it's your first look at the world you're building. It's a chance to think about gameplay—where will players start? What challenges will they face? Use this step to envision the paths players might take.
            </li>
          </ul>

          <img src={seven} className='demo__image' alt='img_seven' />
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 8: Testing the Environment 👀</h2>

          <ul>
            <li>
            Click the "Play" button to jump into your game as a player. This is an essential step for any game developer. You need to experience your game from a player's perspective to ensure everything feels right.
            </li>

            <li>
            As you walk or fly around, ask yourself: Is the terrain navigable? Are there any unintentional obstacles or impossible areas? This playtest can reveal aspects of your design that need tweaking.
            </li>

            <li>
            Don't forget to exit play mode when you're done testing. Use the "Stop" button to return to editing mode and make necessary adjustments.
            </li>
          </ul>

          <img src={eight} className='demo__image' alt='img_eight' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 9: Polishing Your Game 🛠️</h2>

          <ul>
            <li>
            After testing, you might find that some areas aren't quite perfect. That's totally normal! Now, you'll use the "Stop" button to exit the play mode and return to the editor.
            </li>

            <li>
            Use this opportunity to reflect on the player experience. Could the mountains be more challenging? Does the plains area need more features? This is your chance to refine the game and add details that make your world unique.
            </li>
          </ul>

          <img src={nine} className='demo__image' alt='img_nine' />
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 10: Custom Terrain Modifications ✏️</h2>

          <ul>
            <li>
            Sometimes, automated generation isn't enough. You need a personal touch. That's where the "Edit" tab in the Terrain Editor comes in. Click on it to access tools like "Draw", "Grow", and "Erode".
            </li>

            <li>
            Use the "Draw" tool to manually sculpt terrain features. This could be anything from a small path winding through the hills to a vast plateau for an epic castle. This level of customization is what can set your game apart.
            </li>
          </ul>

          <img src={ten} className='demo__image' alt='img_ten' />
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 11: Customizing Terrain Material 🖌️</h2>

          <ul>
            <li>
            To give your spawn area that we are about to build a distinct look, head over to the "Material Settings" in the Terrain Editor. Here you can choose the surface appearance of your base.
            </li>

            <li>
            By selecting a material, like the cobblestone highlighted in the screenshot, you're not just changing the color, but also the texture. This adds a layer of realism and depth to your game world.
            </li>

            <li>
            Applying material is not just for aesthetics; it can also inform players of different zones within your game, such as a safe zone with a smooth, paved look versus a rugged, challenging terrain outside the base area.
            </li>
          </ul>

          <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 12: Creating the Spawn Base Floor 🏗️</h2>

          <ul>
            <li>
            After setting the terrain material, it's time to build structures. Use the Terrain Editor to switch between different tools like "Select", "Transform", and "Paint" to modify your terrain into the desired shape for your base.
            </li>

            <li>
            Building the foundation is critical as it establishes the layout and boundaries of your spawn area. Players will recognize this as the starting point for their in-game journey.
            </li>
          </ul>

          <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 13: Flattening Terrain for Construction ⚒️</h2>

          <ul>
            <li>
            Now, use the "Flatten" tool to create an even surface on which players will spawn. A flat area is essential for placing game objects like spawn points, decorations, or interactive elements consistently.
            </li>

            <li>
            The flatten tool ensures that any structure you build on top will be stable and won't have issues like floating or clipping through the terrain, which could disrupt the gameplay experience.
            </li>

            <li>
            Adjust the brush settings like size and strength to control the extent of the flattening. Careful control here means a more polished final product, providing a seamless start for players entering the game.
            </li>
          </ul>

          <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 14: Adding Structural Elements 🏛️</h2>

          <ul>
            <li>
            It's time to start adding structures to your base area. Select the "Part" tool to create pillars that will support additional elements like roofs or platforms.
            </li>

            <li>
            These pillars aren't just structural elements; they also contribute to the aesthetic and thematic design of your base, giving players visual cues about the game's setting.
            </li>

            <li>
            Place each pillar carefully, using the grid and alignment tools to ensure symmetry and consistency. This attention to detail will make your base area look professional and well-designed.
            </li>
          </ul>

          <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 15: Building Upon the Foundation 🛠️</h2>

          <ul>
            <li>
            Use the tools available in Roblox Studio to duplicate elements, ensuring uniformity and saving time. This can also help in creating patterns or designs that resonate with your game's theme.
            </li>
          </ul>

          <img src={fifteen} className='demo__image' alt='img_fifteen' />
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 16: Constructing the Roof 🏠</h2>

          <ul>
            <li>
            The roof is a crucial element, as it frames the spawn area and provides a sense of enclosure and safety for players. Use the "Part" tool to create the roof structure, ensuring it aligns properly with the top of the pillars. This will require the "wedge" part.
            </li>

            <li>
            While placing the roof parts, use the "Color" tool to choose a color that stands out or matches your base's aesthetic theme. The color of the roof can also be used to signal important areas within the game.
            </li>

            <li>
            Once the parts are in place, use the "Anchor" tool to lock them in position. This ensures that the roof segments remain stationary and don’t fall or move during gameplay, maintaining the integrity of your base structure.
            </li>
          </ul>

          <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 17: Defining the Spawn Points 📍</h2>

          <ul>
            <li>
            Now that your base has taken shape, it's time to designate where players will enter the game. Use the "Spawn" tool to place spawn points on the base floor.
            </li>

            <li>
            Spawn points are more than just starting locations; they can dictate the flow of the game and player traffic. Position them strategically to guide players towards objectives or areas of interest.
            </li>
          </ul>

          <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 18: Lighting Up the Area 🔥</h2>

          <ul>
            <li>
            Adequate lighting is essential, especially if your game includes day-night cycles or darker areas. Use the "Toolbox" to search for light elements like torches that fit your game's theme.
            </li>

            <li>
            Place these light sources around the base to provide visibility and ambience. Lighting can also highlight important features or pathways for players to follow.
            </li>

            <li>
            Experiment with different light colors and intensities to create the desired mood. Remember that lighting can significantly affect the player's experience, so it should complement the overall design of your base.
            </li>
          </ul>

          <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 19: Crafting the Green Gem 💎</h2>

          <ul>
            <li>
            Begin crafting the gem, which is a focal point of your game's hunt. Start with a simple block using the "Part" tool. This will be the base shape of your gem.
            </li>

            <li>
              When you are designing the gems in our game it is important to match the same color scheme in this tutorial.
            </li>
          </ul>

          <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 20: Detailing the Gem 🔷</h2>

          <ul>
            <li>
            With the basic shape in place, it's time to give the gem some character. Use the "Material Manager" to apply a material that will make the gem stand out, like the glowing "Neon" material.
            </li>

            <li>
            The material you choose should not only be visually appealing but also convey the gem's importance. A glowing material can suggest value and desirability, enticing players to hunt for it.
            </li>
          </ul>

          <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 21: Opening the Explorer Panel 🛠️</h2>

          <ul>
            <li>
            Find the Explorer button on the upper left side of the screen (it looks like a hierarchy panel). Click on it to open the Explorer panel.
            </li>

            <li>
            In the Explorer panel, you will see a list of objects in your game. This is where all the parts and components of your game are listed.
            </li>
          </ul>

          <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 22: Adding a Tool to Your Workspace 👷</h2>

          <ul>
            <li>
            With your Explorer panel open, locate the Workspace section.
            </li>
            <li>
            Right-click on the Workspace and hover over Insert Object to bring up a list of items you can add.
            </li>
            <li>
            Select Tool from the list to add it to your game's workspace.
            </li>
            <li>
            You will see a new Tool object appear under the Workspace in the Explorer panel.
            </li>
          </ul>

          <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 23: Locating and Selecting an Existing Part 🔍</h2>

          <ul>
            <li>
            In the Explorer, look through the list of items until you find the green gem, which is the Part you want to work with.
            </li>
            <li>
            Click on the Part in the Explorer to select it. It should also become highlighted in the workspace.
            </li>
          </ul>

          <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 24: Adding the Part to the Tool 👷</h2>

          <ul>
            <li>
            With the green Part selected in the Explorer, drag it into the Tool object in the Explorer list to make it a child of the Tool. This means the Part will now function as part of the Tool.
            </li>
            <li>
            In the workspace, you should see the green Part now attached to the Tool, indicating that they're linked.
            </li>
          </ul>

          <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 25: Renaming the Part to 'Handle' 🛠️</h2>

          <ul>
            <li>
            In the Explorer, under the Tool object, you will see the green Part you just added.
            </li>
            <li>
            Right-click on the green Part and select Rename from the context menu.
            </li>
            <li>
            Rename the part to "Handle" so that players can interact with it as the handle of the tool in the game.
            </li>
            <li>
            Confirm that the Part's name has changed to "Handle" in the Explorer.
            </li>
          </ul>

          <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 26: Renaming the Tool for Clarity 📝</h2>

          <ul>
            <li>
            After adding the "Handle" to the "Tool" object, it's important to rename the "Tool" to something more descriptive. This helps you and other developers quickly understand what the tool is when looking at the Explorer panel.
            </li>

            <li>
            In the Explorer panel, right-click on the "Tool" object and select Rename. Give it a name that reflects its purpose in the game, like "Green Gem".
            </li>
          </ul>

          <img src={twentySix} className='demo__image' alt='img_twentySix' />
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 27: Testing the Gem in the Game Environment 🧪</h2>

          <ul>
            <li>
            With the tool now properly named, you'll want to test how it looks and behaves in the game. Testing is a critical part of game development, ensuring that each element works as intended.
            </li>

            <li>
            Click the Play button in the top menu to enter the game environment as a player.
            </li>
          </ul>

          <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 28: Preparing to Build a Gem Dispenser 🚧</h2>

          <ul>
            <li>
            With the tool tested, the next step is to create a new part that will serve as the beginning of a gem dispenser. This dispenser will be an in-game mechanism for players to acquire the gem you're creating.
            </li>

            <li>
            Click on the Part button in the toolbar to add a new part to the workspace. This part will act as the base or platform from which the gems will be dispensed.
            </li>
          </ul>

          <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 29: Configuring the New Part's Size 🔧</h2>

          <ul>
            <li>
            After inserting a new part into your game for the gem dispenser, it's important to adjust its properties to fit its purpose. In the Properties panel, you can change attributes such as size, position, and more.
            </li>
            <li>
            Specifically, you should adjust the Size property of the part to ensure it can adequately contain the gems and dispense them to players. The size shown in the properties panel - typically values like 10, 1, 10 - will define the length, height, and width of the dispenser base.
            </li>
          </ul>

          <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 30: Finalizing the Part with Color and Anchoring 🎨</h2>

          <ul>
            <li>
            Once you've configured the properties of your new part, giving it the right look and functionality is next. Using the Color tool in the toolbar, choose a color that will make this part of the dispenser stand out or blend with the game environment as desired.
            </li>

            <li>
            After coloring, ensure the part is anchored by selecting it and clicking the Anchor tool. This will prevent the part from being affected by the physics engine, which is crucial for static structures like a dispenser that shouldn't move or fall over.
            </li>
          </ul>
          <img src={thirty} className='demo__image' alt='img_thirty' />
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 31: Placing the Hitbox Part 🔨</h2>

          <ul>
            <li>
            To create interactive components like a gem dispenser, you'll need a part that can detect when a player is nearby to trigger the dispensing action. This is commonly known as a "hitbox".
            </li>

            <li>
            Insert a new Part into your workspace, which will act as this hitbox. You can do this by clicking the Part button in the toolbar.
            </li>

            <li>
            Place this hitbox part just above the base part where the gems will be dispensed. The precise placement ensures that when a player approaches, the hitbox will correctly register their presence and can be scripted to release a gem. Later, the hitbox will be invisible.
            </li>
          </ul>
          <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 32: Adjusting the Hitbox Name 🛠️</h2>

          <ul>
            <li>
              Now rename this block to "hitbox".
            </li>
          </ul>
          <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 33: Configuring the Hitbox Part Properties 🎚️</h2>

          <ul>
            <li>
            Select the hitbox part and open the Properties panel. Here you will find the CanCollide property, which should be unchecked for this particular part.
            </li>

            <li>
            Unchecking CanCollide means that while the hitbox can trigger events when a player enters its space, it won't physically block the player's movement. This is important for a smooth player experience, as you don't want invisible barriers hindering movement.
            </li>

            <li>
            Setting up your hitbox with the correct properties ensures that it functions as an invisible trigger rather than a physical obstacle.
            </li>
          </ul>
          <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 34: Finalizing the Hitbox Size and Position 📏</h2>

          <ul>
            <li>
            Double-check the size and position of your hitbox part to ensure that it aligns with the base part and covers the appropriate area for interaction. When a player enters this area this how we will detect when to give them a gem.
            </li>
            <li>
            You may need to use the Move and Scale tools again to make fine adjustments. Remember that the hitbox's position and size will directly impact when and how players can interact with the dispenser.
            </li>
            <li>
            A well-placed hitbox will seamlessly integrate with the dispenser's functionality, making the experience intuitive for the player.
            </li>
          </ul>
          <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 35: Setting the Hitbox Transparency 🌐</h2>

          <ul>
            <li>
            For the hitbox part to be invisible during gameplay, which is standard for interactive triggers, you'll need to set its Transparency to 1 in the Properties panel.
            </li>
            <li>
            A transparency of 1 means the part is fully invisible in the game view. This is essential for maintaining immersion, as you don't want players to see the mechanics behind the interaction.
            </li>
            <li>
            By making the hitbox transparent, you've created an invisible interactive element that can trigger game mechanics like dispensing a gem without affecting the visual aesthetics of your game environment.
            </li>
          </ul>
          <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 36: Creating a Weld Constraint for the Dispenser 🔗</h2>

          <ul>
            <li>
            In game development, it's often necessary to ensure that certain parts move together as if they were a single unit. This is where constraints come into play. In Roblox Studio, a WeldConstraint physically joins two parts together.
            </li>
            <li>
            To add a WeldConstraint, select the hitbox or base part, hover the part you want to add it to and select the "+" button. Then, search for and select the "WeldConstraint".
            </li>
            <li>
            Why is this important? If your dispenser has moving parts or you decide to relocate it, the hitbox will maintain its relative position to the base, ensuring consistent player interaction.
            </li>
          </ul>
          <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 37: Configuring the Weld Constraint ⚙️</h2>

          <ul>
            <li>
            After placing the WeldConstraint, it's critical to configure it correctly. In the Properties panel, set the Part0 and Part1 properties to the two parts you want to weld together, which will typically be the hitbox and the base.
            </li>
            <li>
            This configuration step is crucial because it defines which parts the constraint affects. Without properly setting these, the parts will not stay together, breaking the intended functionality of the dispenser.
            </li>
          </ul>
          <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 38: Adding a Model to the Workspace 🏗️</h2>

          <ul>
            <li>
            In Roblox Studio, a Model is a container that groups parts and other objects together. This is useful for organizing parts that form a complex object, like our gem dispenser.
            </li>
            <li>
              To create a Model, hover the "workspace" and click the "+" to select and add the Model.
            </li>
            <li>
            Grouping related parts into a Model simplifies the workspace and makes it easier to duplicate, move, and manipulate complex objects as a single entity. It's a foundational practice for maintaining order and clarity as your game grows more complex.
            </li>
          </ul>
          <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 39: Renaming the Model for Easy Identification ✏️</h2>

          <ul>
            <li>
            After creating the Model, it's important to rename it to something descriptive. This helps you quickly identify and locate it within the Explorer.
            </li>

            <li>
            To rename the Model, find it in the Explorer, right-click, and select Rename. Enter a name that clearly indicates the Model's purpose in the game, such as "GemDispenser1", as there will be more.
            </li>
          </ul>
          <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 40: Organizing the Model’s Components 📂</h2>

          <ul>
            <li>
            Now that you have a named Model, it’s time to ensure all its components are correctly organized within it. This includes any parts, scripts, or constraints that belong to the gem dispenser mechanism.
            </li>
            <li>
            Drag the "Hitbox" and "base" into the model. Make sure to add a "Folder".
            </li>
          </ul>

          <img src={forty} className='demo__image' alt='img_forty' />
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 41: Organizing Your Assets 🗃️</h2>

          <ul>
            <li>
            To keep everything in order, click on the Green Gem in the Explorer window, and drag it into the Folder. This will make our scripting and object management much more manageable later on!
            </li>
          </ul>
          <img src={fortyOne} className='demo__image' alt='img_fortyOne' />
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 42: Refining the Structure 🔧</h2>

          <ul>
            <li>
            Notice the subtle change here? We've renamed the Handle within the Green Gem folder to part.
            </li>
            <li>
              The reason for this is simple, we do not want players to be able to pick up and grab the gem... well not the original at least. You see when a player touches the Hitbox we are going to give them a clone of the gem that will have a "Handle" thus allowing them to grab clones but keep the original on the player for ALL of the players to view and interact with. We will begin this script shortly!
            </li>
          </ul>
          <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 43: Adding Functionality with Scripts 📜✨</h2>

          <ul>
            <li>
            We've added a Script to GemDispenser1. This script is where we'll write code that will define the actions of our dispenser. 
            </li>
          </ul>
          <img src={fortyThree} className='demo__image' alt='img_fortyThree' />
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 44: Creating the grabGem Function 🖊️🔍</h2>

          <ul>
            <li>
            Open the Script within GemDispenser1.
            </li>

            <li>
            function is a keyword that starts the declaration of a new function.
            </li>

            <li>
            grabGem is the name of our function. You can name your function anything you like.
            </li>

            <li>
            After defining the function, set it to listen for a 'Touched' event by adding this line below your function: script.Parent.Hitbox.Touched:Connect(grabGem)
            </li>

            <li>
            script.Parent refers to the parent object of the script, which should be GemDispenser1.
            </li>

            <li>
            .Hitbox specifies the child part of the parent object we're interested in, the Hitbox in this case.
            </li>

            <li>
            Touched is an event that fires when the Hitbox is physically interacted with in the game.
            </li>

            <li>
            :Connect(grabGem) tells the script to call the grabGem function when the Touched event occurs.
            </li>
          </ul>
          <img src={fortyFour} className='demo__image' alt='img_fortyFour' />
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 45: Adding a Parameter to the grabGem Function 📝✅</h2>

          <ul>
            <li>
            Modify the grabGem function to take a parameter. This will allow the function to know which specific part triggered the event.
            </li>

            <li>
            part inside () is a parameter that the function will use. When the Touched event happens, it sends the part that was touched as an argument to this parameter.
            </li>
          </ul>
          <img src={fortyFive} className='demo__image' alt='img_fortyFive' />
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 46: Identifying the Player's Character 👤</h2>

          <ul>
            <li>
            Add a line of code to define character as the parent of the part that triggered the event.
            </li>

            <li>
            character is a variable that will reference the player's character model in the game.
            </li>
          </ul>
          <img src={fortySix} className='demo__image' alt='img_fortySix' />
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 47: Associating the Character with the Player 🎮➡️👥</h2>

          <ul>
            <li>
            Introduce a line to link the character with the actual player object.
            </li>
            <li>
            game.Players is a service that holds all the player objects.
            </li>
            <li>
            GetPlayerFromCharacter is a method that returns the player object that a character belongs to.
            </li>
          </ul>
          <img src={fortySeven} className='demo__image' alt='img_fortySeven' />
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 48: Accessing the Gem (Stone) 💎</h2>

          <ul>
            <li>
            Retrieve the stone (gem) that will be interacted with.
            </li>
            <li>
            script.Parent.Folder accesses the Folder we've set up in previous steps which contains the gem.
            </li>
            <li>
            GetChildren() is a method that returns a table of all the children of an object, in this case, all objects in the folder.
            </li>
            <li>
            [1] indicates we want the first object in this list, which is the gem we want to interact with.
            </li>
          </ul>
          <img src={fortyEight} className='demo__image' alt='img_fortyEight' />
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 49: Checking for Player and Gem ✔️</h2>

          <ul>
            <li>
            Add a conditional to check if a player is present and if the stone is not already in their backpack.
            </li>
          </ul>

          <img src={fortyNine} className='demo__image' alt='img_fortyNine' />
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 50: Ensuring the Gem is Available 🚫🎒</h2>

          <ul>
            <li>
            Finalize the condition by adding the actual function content to give the gem to the player if it's not already in the backpack. The content of what happens inside the if statement will be covered in the next steps.
            </li>

            <li>
            Add a conditional to check if the stone is not already in their backpack.
            </li>
          </ul>
          <img src={fifty} className='demo__image' alt='img_fifty' />
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 51: Verifying Player and Non-possession of Gem 🚫👤💎</h2>

          <ul>
            <li>
            Expand the conditional check within the grabGem function to include a check for the gem's presence in the player's character model. This is crucial because we want to ensure that the gem can only be grabbed if it isn't already attached to the player in any way, either in the backpack or in the character's hand.
            </li>

            <li>
            and is a logical operator that ensures all conditions must be true for the code block to execute.
            </li>

            <li>
            not is a logical negation operator that inverts the truthiness of the following expression.
            </li>

            <li>
            FindFirstChild is a function that searches for a child with the specified name. If it does not exist, it returns nil, which is treated as false in a conditional statement.
            </li>
          </ul>
          <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 52: Cloning the Gem for Collection 🐑</h2>

          <ul>
            <li>
            Inside the if block, use the Clone method to create a copy of the stone object. This is a necessary step because we don't want to move the original stone from the scene; instead, we want to give the player a copy of it.
            </li>

            <li>
            local clone creates a new local variable named clone which will store the copy of the stone.
            </li>

            <li>
            stone:Clone() calls the Clone method on the stone object, which creates and returns a duplicate of the stone.
            </li>
          </ul>
          <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 53: Renaming the Cloned Gem ✏️💎</h2>

          <ul>
            <li>
            To avoid any potential conflicts or confusion in the game, rename the part of the cloned gem to a standard name, such as "Handle". This is common practice in Roblox scripting to ensure that cloned items have a consistent identifier.
            </li>

            <li>
            clone.part.Name accesses the Name property of the part object inside the clone. In Roblox, Handle is often used as the name for the main part of a tool or item.
            </li>

            <li>
              This line of code make's the clone inventoriable, as opposed to the original gem on the dispensers which players are unable to hold.
            </li>
          </ul>
          <img src={fiftyThree} className='demo__image' alt='img_fiftyThree' />
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 54: Making the Gem Grabbable 🛠️💎</h2>

          <ul>
            <li>
            Set the Anchored property of the cloned gem's handle to false. This makes the gem capable of being picked up and moved around, as opposed to being fixed in place in the game world.
            </li>

            <li>
            clone.Handle.Anchored targets the Anchored property of the Handle part of the clone.
            </li>

            <li>
            false sets the property to false, allowing the object to be affected by physics and player interactions.
            </li>
          </ul>
          <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 55: Assigning the Gem to the Player 🎒➡️💎</h2>

          <ul>
            <li>
            Finally, set the Parent property of the cloned gem to the player's Backpack. This effectively gives the gem to the player, adding it to their inventory.
            </li>

            <li>
            clone.Parent targets the Parent property of the clone, which determines the object's location in the game's hierarchy.
            </li>

            <li>
            player.Backpack is the player's backpack object, where tools and items are stored for the player to use.
            </li>
          </ul>

          <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 56: Testing the Gem Dispenser 🧪💎</h2>

          <ul>
            <li>
            After setting up your gem dispenser in Roblox Studio, it's time to conduct a test. Press 'Play' to enter the game environment and interact with the dispenser to ensure the gem can be collected as expected.
            </li>

            <li>
            This step is critical to verify that the scripting and game mechanics are functioning correctly. It allows you to identify any bugs or issues that need to be resolved before duplicating the dispenser setup for multiple gems.
            </li>
          </ul>
          <img src={fiftySix} className='demo__image' alt='img_fiftySix' />
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 57: Duplicating Gem Dispensers 🎨🔄</h2>

          <ul>
            <li>
            With a single gem dispenser working correctly, you'll want to provide more gems for players to collect. In Roblox Studio, duplicate your original gem dispenser four times to create a total of five dispensers.
            </li>

            <li>
            This is done by copying and pasting the original dispenser or using the duplicate tool. Ensure each dispenser is placed correctly in your game world, ready for further customization.
            </li>
          </ul>

          <img src={fiftySeven} className='demo__image' alt='img_fiftySeven' />
        </div>

        <div style={currentStep == 58 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 58: Customizing the Second Gem Dispenser to Red 🖌️🔴</h2>

          <ul>
            <li>
            Select the second gem dispenser in the Explorer panel of Roblox Studio.
            </li>

            <li>
            In the Properties panel, locate the Color property and change it to a vibrant red. This color change visually differentiates this dispenser from others, indicating a different type of gem.
            </li>

            <li>
            Rename the gem dispenser to Red Gem in the Explorer to reflect the color change. This renaming is not just for aesthetics; it also helps in identifying the gem during gameplay and scripting.
            </li>
          </ul>
          <img src={fiftyEight} className='demo__image' alt='img_fiftyEight' />
        </div>

        <div style={currentStep == 59 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 59: Changing the Third Gem Dispenser to Yellow 🖌️🟡</h2>

          <ul>
            <li>
            Click on the third gem dispenser in the Explorer panel.
            </li>

            <li>
            Adjust the Color property to a bright yellow using the Properties panel. Yellow can be a signal for caution or value in-game, and it's important that it stands out against the environment.
            </li>

            <li>
            Update the name of the gem dispenser to Yellow Gem to maintain consistency and ease of access during game development.
            </li>
          </ul>
          <img src={fiftyNine} className='demo__image' alt='img_fiftyNine' />
        </div>

        <div style={currentStep == 60 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 60: Coloring the Fourth Gem Dispenser Blue 🖌️🔵</h2>

          <ul>
            <li>
            Select the fourth gem dispenser from the Explorer panel.
            </li>

            <li>
            Use the Properties panel to set the Color property to blue. The color blue might represent rarity or a special attribute within the game's context.
            </li>

            <li>
            Rename the dispenser to Blue Gem in the Explorer. Accurate naming assists you later when you need to reference these objects in your scripts or for game logic.
            </li>
          </ul>
          <img src={sixty} className='demo__image' alt='img_sixty' />
        </div>

        <div style={currentStep == 61 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 61: Assigning Pink to the Fifth Gem Dispenser 🖌️💖</h2>

          <ul>
            <li>
            Navigate to the fifth gem dispenser in the Explorer panel.
            </li>
            <li>
            Change its Color property to pink through the Properties panel. Pink could be used to attract a particular player demographic or as part of a theme.
            </li>
            <li>
            In the Explorer, rename this gem dispenser to Pink Gem. Consistent naming helps keep your game organized, especially when you start to have a large number of objects.
            </li>
          </ul>
          <img src={sixtyOne} className='demo__image' alt='img_sixtyOne' />
        </div>

        <div style={currentStep == 62 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 62: Hiding the Red Gem Dispenser 🔴🕵️‍♂️</h2>

          <ul>
            <li>
            Find a secluded spot in your Roblox game environment that can serve as a hidden location. This should be a place where players can be challenged to find the gem.
            </li>

            <li>
            Move your red gem dispenser to this hidden location, ensuring it's not immediately visible to players who may be passing by.
            </li>

            <li>
            The idea is to encourage exploration within the game. By hiding gem dispensers, you can create a more engaging and rewarding experience as players discover them.
            </li>
          </ul>
          <img src={sixtyTwo} className='demo__image' alt='img_sixtyTwo' />
        </div>

        <div style={currentStep == 63 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 63: Placing the Forcefield Part 🛡️📍</h2>

          <ul>
            <li>
            In the Roblox Studio, select the 'Part' tool to create a new part in your workspace. This will act as the physical structure for your forcefield.
            </li>

            <li>
            Use the move tool to position the part where you want the forcefield to be located, typically at a strategic point that controls player progression.
            </li>

            <li>
            The forcefield part will function as a barrier that can only be passed if the player has met certain conditions, adding a layer of challenge and interaction to the game.
            </li>
          </ul>
          <img src={sixtyThree} className='demo__image' alt='img_sixtyThree' />
        </div>

        <div style={currentStep == 64 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 64: Configuring the Forcefield 🔧</h2>

          <ul>
            <li>
            Once the part is placed, adjust its size and orientation so that it effectively blocks the intended path.
            </li>

            <li>
            Go to the properties of the part and set the 'Anchored' property to true, ensuring that the forcefield stays in place.
            </li>
          </ul>
          <img src={sixtyFour} className='demo__image' alt='img_sixtyFour' />
        </div>

        <div style={currentStep == 65 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 65: Setting Up the Forcefield's Visual Properties 🎨🔲</h2>

          <ul>
            <li>
            In the properties window, locate the 'BrickColor' or 'Color' property to change the forcefield's color. Choose a color that signifies its purpose in the game—for example, a bright color like lime green could signify an active barrier.
            </li>

            <li>
            Adjust the 'Transparency' property to give it a semi-transparent look. A value around 0.5 to 0.7 usually works well to indicate that the forcefield is not a solid barrier but has some form of permeability.
            </li>

            <li>
            Finally, make sure to rename the part to something descriptive, such as "greenForceField," which can help you easily identify and reference it within your scripts.
            </li>
          </ul>
          <img src={sixtyFive} className='demo__image' alt='img_sixtyFive' />
        </div>

        <div style={currentStep == 66 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 66: Identifying the Script's Parent Object 📌</h2>

          <ul>
            <li>
            In the Explorer window, locate the greenForceField object.
            </li>

            <li>
            Notice that greenForceField is highlighted, indicating it is the object we will be working with.
            </li>
            <li>
            Make sure it's selected before moving on to adding a script to it.
            </li>
          </ul>
          <img src={sixtySix} className='demo__image' alt='img_sixtySix' />
        </div>

        <div style={currentStep == 67 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 67: Initiating a Script 📝</h2>

          <ul>
            <li>
            Double-click the Script to open it in the scripting area.
            </li>

            <li>
            Begin your script by creating a variable to reference the parent object of the script. Type local block = script.Parent on the first line.
            </li>

            <li>
            This line sets a local variable block as a reference to the script's parent, which is greenForceField.
            </li>
          </ul>
          <img src={sixtySeven} className='demo__image' alt='img_sixtySeven' />
        </div>

        <div style={currentStep == 68 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 68: Defining a Function Placeholder 🛠️</h2>

          <ul>
            <li>
            Below the line where you defined block, type function onTouched().
            </li>

            <li>
            Press Enter and type end to close the function.
            </li>

            <li>
            This creates a function named onTouched but does not yet define any actions within it.
            </li>
          </ul>
          <img src={sixtyEight} className='demo__image' alt='img_sixtyEight' />
        </div>

        <div style={currentStep == 69 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 69: Connecting the Function to an Event 🔗</h2>

          <ul>
            <li>
            To make our function do something when an event occurs, we connect it to the Touched event of the block.
            </li>

            <li>
            Write block.Touched:Connect(onTouched) below the end of your function.
            </li>

            <li>
            This line tells Roblox to call the onTouched function whenever the greenForceField block is touched.
            </li>
          </ul>
          <img src={sixtyNine} className='demo__image' alt='img_sixtyNine' />
        </div>

        <div style={currentStep == 70 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 70: Passing Parameters to the Function 📊</h2>

          <ul>
            <li>
            We'll want to know more about the touch event, like what touched the block.
            </li>

            <li>
            Modify the first line of the onTouched function to function onTouched(hit).
            </li>

            <li>
            Now, the function can receive information about the touch event in the parameter hit.
            </li>
          </ul>
          <img src={seventy} className='demo__image' alt='img_seventy' />
        </div>

        <div style={currentStep == 71 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 71: Identifying the Player Interacting with the Object 🧑‍💻</h2>

          <ul>
            <li>
            When an object is touched, we want to know which player touched it. To do this, we retrieve the player object associated with the touching character.
            </li>

            <li>
            Insert local player = game.Players:GetPlayerFromCharacter(hit.Parent) inside the onTouched function.
            </li>

            <li>
              The hit parameter represents the object that initiated the touch event, typically a part of a player's character model. The Parent property is used to access the character model itself.
            </li>

            <li>
            GetPlayerFromCharacter is a method of the Players service that takes a character model and returns the corresponding player object if one exists.
            </li>

            <li>
            This is crucial for distinguishing between touches from players and other objects in the game world.
            </li>
          </ul>

          <img src={seventyOne} className='demo__image' alt='img_seventyOne' />
        </div>

        <div style={currentStep == 72 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 72: Checking for Player Existence Before Continuing 🤔</h2>

          <ul>
            <li>
            We need to ensure that the touch event was triggered by a player and not something else.
            </li>

            <li>
            Add an if statement with the condition if player then to check if the player variable is not nil.
            </li>

            <li>
            This conditional statement is used to perform actions only if a player has touched the object, preventing the script from attempting to perform actions on non-player touches, such as when an object in the game world touches the force field.
            </li>

            <li>
            If player is nil, the script will skip the code inside the if block, avoiding any errors or unintended behavior.
            </li>
          </ul>
          <img src={seventyTwo} className='demo__image' alt='img_seventyTwo' />
        </div>

        <div style={currentStep == 73 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 73: Searching for Specific Items in the Player's Inventory 🔍</h2>

          <ul>
            <li>
            Once we've confirmed a player is interacting with the object, we might want to check for specific items in their inventory.
            </li>

            <li>
            Write the line local tool = player.Backpack:FindFirstChild("Green Gem") within the if block.
            </li>

            <li>
            This line searches the player's backpack for an item named "Green Gem". The Backpack is a container that holds a player's tools and items.
            </li>

            <li>
            FindFirstChild is a function that searches through all children of an instance (in this case, the Backpack) for a child with the given name. If it finds one, it returns that child; if not, it returns nil.
            </li>

            <li>
            This step is essential for game mechanics that require a player to have specific items to interact with objects or areas in the game world.
            </li>

            <li>
            FindFirstChild("Green Gem"): Searches for a child named "Green Gem" in the Backpack.
            </li>
          </ul>
          <img src={seventyThree} className='demo__image' alt='img_seventyThree' />
        </div>

        <div style={currentStep == 74 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 74: Adding More Conditions to the Interaction 🔄</h2>

          <ul>
            <li>
            Now we will ensure that the action only happens if the player has the "Green Gem".
            </li>

            <li>
            Inside the if player then block, after defining the tool variable, add another conditional: if tool then. This checks if the tool variable is not nil, meaning the "Green Gem" was indeed found in the player's Backpack.
            </li>

            <li>
            Just like before, end this new if block with end. This nested if statement is where you'll put the code to be executed when the player with the "Green Gem" touches the greenForceField.
            </li>
          </ul>
          <img src={seventyFour} className='demo__image' alt='img_seventyFour' />
        </div>

        <div style={currentStep == 75 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 75: Modifying Object Properties Based on Conditions 🔄➡️🚫</h2>

          <ul>
            <li>
            If the player has the "Green Gem", we want the greenForceField to become non-collidable, allowing the player to pass through.
            </li>

            <li>
            Within the if tool then block, write the line block.CanCollide = false.
            </li>

            <li>
            block.CanCollide is a property that determines whether other objects can collide with this one.
            </li>

            <li>
            Setting it to false means that objects, including players, will pass through the greenForceField without colliding with it.
            </li>

            <li>
            This action is significant because it alters the environment based on the player's inventory, adding an interactive element to your game.
            </li>
          </ul>

          <img src={seventyFive} className='demo__image' alt='img_seventyFive' />
        </div>

        <div style={currentStep == 76 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 76: When the player does not have the Gem 🚫</h2>

          <ul>
            <li>
              So what is the player does not have the tool/gem in their inventory? We need to have an "else" statement for when "if tool" equates to false. If the player does not have the tool add an "else" statement to keep the collisions on, thus blocking the player from passing through the force field.
            </li>
          </ul>
          <img src={seventySix} className='demo__image' alt='img_seventySix' />
        </div>

        <div style={currentStep == 77 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 77: Yellow Gem Dispenser 🟡</h2>

          <ul>
            <li>
              Next, exit the script that we just created and find the "yellow" gem dispenser we will be focusing on next.
            </li>

            <li>
              Move this dispenser to the area where you want players to find it.
            </li>
          </ul>
          <img src={seventySeven} className='demo__image' alt='img_seventySeven' />
        </div>

        <div style={currentStep == 78 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 78: Duplicate Green Forcefield ➡️</h2>

          <ul>
            <li>
              In order to create a "red forcefield" to check if the player has the "red" gem in order to get the "yellow" gem, then just duplicate the greenForceField and move it to the area of the yellow gem dispenser.
            </li>
          </ul>
          <img src={seventyEight} className='demo__image' alt='img_seventyEight' />
        </div>

        <div style={currentStep == 79 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 79: Making the Red Forcefield 🔴</h2>

          <ul>
            <li>
              To make the "redForceField" just change the color of the "greenForceField" from green to red and make sure to rename the duplicate to "redForceField".
            </li>
          </ul>
          <img src={seventyNine} className='demo__image' alt='img_seventyNine' />
        </div>

        <div style={currentStep == 80 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 80: Adjust Forcefield Script 📝</h2>

          <ul>
            <li>
              While the script of this duplicate force field will remain relatively the same, we need to make a small adjustment.
            </li>

            <li>
              Find the "tool" variable and adjust the value to reflect the gem that the user should have in order to pass through the "redForceField", in this case we are now searching for the user having the "Red Gem" as opposed to the "Green Gem".
            </li>
          </ul>
          <img src={eighty} className='demo__image' alt='img_eighty' />
        </div>

        <div style={currentStep == 81 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 81: More Duplicates 🐑</h2>

          <ul>
            <li>
              Since this yellow gem dispenser is not in a hole, we need a forcefield on each side.
            </li>

            <li>
              Duplicate your "redForceField", so that there is one to block off the dispenser on every side.
            </li>
          </ul>
          <img src={eightyOne} className='demo__image' alt='img_eightyOne' />
        </div>

        <div style={currentStep == 82 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 82: Hide the Blue Dispenser 💧</h2>

          <ul>
            <li>
              Now find a good spot for your "blueGemDispenser" somewhere preferable near the water to match the blue color scheme.
            </li>
          </ul>
          <img src={eightyTwo} className='demo__image' alt='img_eightyTwo' />
        </div>

        <div style={currentStep == 83 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 83: Duplicate Forcefield</h2>

          <ul>
            <li>
              Now duplicate the previously made forcefield and drag it near the blue gem dispenser.
            </li>
          </ul>
          <img src={eightyThree} className='demo__image' alt='img_eightyThree' />
        </div>

        <div style={currentStep == 84 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 84: Forcefield Adjustments</h2>

          <ul>
            <li>
              Change the color of the forcefield to match the color of the gem the user should have in order to advance past this current forcefield, in our case yellow.
            </li>

            <li>
              Rename your new forcefield.
            </li>

            <li>
              Do not forget to adjust the "tool" variable in the script to match the gem that the user should have for this forcefield.
            </li>
          </ul>
          <img src={eightyFour} className='demo__image' alt='img_eightyFour' />
        </div>

        <div style={currentStep == 85 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 85: More Yellow Forcefields</h2>

          <ul>
            <li>
              Add a Yellow Forcefield for each side of the exposed gem dispenser.
            </li>
          </ul>
          <img src={eightyFive} className='demo__image' alt='img_eightyFive' />
        </div>

        <div style={currentStep == 86 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 85: Relocate the Pink Forcefield 💓</h2>

          <ul>
            <li>
              Find the pink forcefield and relocate it to the desired area you want players to search and find it.
            </li>
          </ul>
          <img src={eightySix} className='demo__image' alt='img_eightySix' />
        </div>

        <div style={currentStep == 87 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 87: Pink Dispenser and Blue Forcefield</h2>

          <ul>
            <li>
              Follow the same pattern we have been following thus far and duplicate the forcefield previously only now changing the color of it to match the color of the gem that the user should have in their inventory.
            </li>

            <li>
              Remember to rename this forcefield.
            </li>
          </ul>
          <img src={eightySeven} className='demo__image' alt='img_eightySeven' />
        </div>

        <div style={currentStep == 88 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 88: Re-scripting the Blue Forcefield 🔵</h2>

          <ul>
            <li>
              This is the part where we change the "tool" variable to match the gem that the user should have in order to pass through this forcefield.
            </li>
          </ul>
          <img src={eightyEight} className='demo__image' alt='img_eightyEight' />
        </div>

        <div style={currentStep == 89 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 89: More Blues 🔵🔵🔵</h2>

          <ul>
            <li>
              Add a blue forcefield to each open side around the pink gem dispenser.
            </li>
          </ul>
          <img src={eightyNine} className='demo__image' alt='img_eightyNine' />
        </div>

        <div style={currentStep == 90 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 90: Adding a Script 📜</h2>

          <ul>
            <li>
            Navigate to the "ServerScriptService" in the Explorer window.
            </li>

            <li>
            Right-click on "ServerScriptService" and select "Insert Object".
            </li>

            <li>
            Choose "Script" from the menu. This will add a new script to your game, which is essential for coding game behaviors.
            </li>
          </ul>
          <img src={ninety} className='demo__image' alt='img_Nine' />
        </div>

        <div style={currentStep == 91 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 91: Naming Your Script ✍️</h2>

          <ul>
            <li>
            Click on the new script you've added. It will be named "Script" by default.
            </li>

            <li>
            Rename the script to "timerScript" to give it a specific purpose. This can be done by right-clicking the script and selecting "Rename", or by clicking on the script name and typing the new name.
            </li>
          </ul>
          <img src={NineOne} className='demo__image' alt='img_NineOne' />
        </div>

        <div style={currentStep == 92 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 92: Initialize Player Service Connection 🌐</h2>

          <ul>
            <li>
            Inside the "timerScript", start by declaring a variable to hold the Players service.
            </li>

            <li>
            Type the code: local Players = game:GetService("Players").
            </li>

            <li>
            his line is critical because it allows your script to interact with the game's player information. The local keyword makes Players a variable that's only accessible within this script. game:GetService("Players") is a method call that fetches the Players service, which manages all the player instances in the game. By storing it in a variable, you can now easily access player data, like their names or attributes, within your script.
            </li>
          </ul>

          <img src={NineTwo} className='demo__image' alt='img_NineTwo' />
        </div>

        <div style={currentStep == 93 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 93: Accessing the ReplicatedStorage for Shared Data 🔄</h2>

          <ul>
            <li>
            In a new line below the Players service, declare another variable for the ReplicatedStorage.
            </li>

            <li>
            ReplicatedStorage is a service used to store and replicate data across both the server and all clients. This means that any object placed inside this service will be available to every player's game. It's essential for sharing data, such as assets or modules, that needs to be consistent for all players.
            </li>
          </ul>
          <img src={NineThree} className='demo__image' alt='img_NineThree' />
        </div>

        <div style={currentStep == 94 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 94: Creating a Remote Event for Server-Client Communication 📡</h2>

          <ul>
            <li>
            Proceed to the next line to create a new Remote Event object.
            </li>

            <li>
            Enter local timerEvent = Instance.new("RemoteEvent").
            </li>

            <li>
            A Remote Event is an object used for creating a communication link between the server and clients. This line creates a new Remote Event which we can use to send messages or data back and forth. For instance, if you have a timer on the server that clients need to see, the Remote Event can broadcast the timer's value to all connected clients. Clients, referring to players in the game (or their computer screens).
            </li>

            <li>
            Instance.new: A constructor function that creates a new instance of a given class.
            </li>

            <li>
            ("RemoteEvent"): The class name of the object being created.
            </li>
          </ul>
          <img src={NineFour} className='demo__image' alt='img_NineFour' />
        </div>

        <div style={currentStep == 95 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 95: Naming the Remote Event for Reference 🔖</h2>

          <ul>
            <li>
            Add a line to set the name of your newly created Remote Event.
            </li>

            <li>
            After creating the Remote Event, it's good practice to assign it a unique name. This name is used to identify the Remote Event within scripts and the game's hierarchy. Naming it "TimerEvent" indicates its purpose and helps keep your code organized, making it clear that this Remote Event will be related to timing functions in the game.
            </li>
          </ul>
          <img src={NineFive} className='demo__image' alt='img_NineFive' />
        </div>

        <div style={currentStep == 96 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 96: Set Remote Event Parent 📡</h2>

          <ul>
            <li>
            After creating the Remote Event, you need to set its parent to ReplicatedStorage. This makes the Remote Event accessible to both the server and all clients.
            </li>

            <li>
            Add the line: timerEvent.Parent = ReplicatedStorage.
            </li>

            <li>
            By setting the Parent property of the timerEvent to ReplicatedStorage, you're ensuring that the event is replicated across the server and clients, allowing for server-to-client and client-to-server communication.
            </li>

            <li>
              ReplicatedStorage is the service that holds objects shared across the server and clients.
            </li>
          </ul>
          <img src={NineSix} className='demo__image' alt='img_NineSix' />
        </div>

        <div style={currentStep == 97 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 97: Define Required Tools 🛠️🔍</h2>

          <ul>
            <li>
            To manage game items, you'll declare a list (table) of required tools. These are the tools (gems) we will make sure that the user has before the timer gets to 0.
            </li>

            <li>
            This empty table requiredTools will be used to store the names of tools that players must collect. Tables in Lua are used as arrays or dictionaries to hold collections of data.
            </li>
          </ul>
          <img src={NineSeven} className='demo__image' alt='img_NineSeven' />
        </div>

        <div style={currentStep == 98 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 98: Populate Required Tools List 📋✨</h2>

          <ul>
            <li>
            Now, you'll populate the requiredTools table with strings representing the names of the tools.
            </li>

            <li>
            Insert the names of the tools into the table like so: local requiredTools = &#10100;"Green Gem", "Red Gem", "Blue Gem", "Yellow Gem", "Pink Gem"&#10101;.
            </li>

            <li>
            By listing the names of the tools, you're creating a reference for the script to check whether players have collected all necessary items. Each string is the name of a tool and is enclosed in quotes, separated by commas.
            </li>
          </ul>
          <img src={NineEight} className='demo__image' alt='img_NineEight' />
        </div>

        <div style={currentStep == 99 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          
          <h2>Step 99: Create a Function to Check Tools 🔄🔎</h2>

          <ul>
            <li>
            Now you will write a function to check if a player has all required tools.
            </li>

            <li>
            Declare the function with function hasAllRequiredTools(player).
            </li>

            <li>
            This function will take a player object as an argument and will be used to determine if the player has collected all tools listed in requiredTools. The player parameter will refer to a player instance in the game.
            </li>
          </ul>
          <img src={NineNine} className='demo__image' alt='img_NineNine' />
        </div>

        <div style={currentStep == 100 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 100: Access Player's Backpack 🎒🔍</h2>

          <ul>
            <li>
            Inside the hasAllRequiredTools function, you'll add a line to get the player's Backpack, which holds their items.
            </li>

            <li>
            This line searches for the first child of the player's instance named "Backpack", which is where the player's tools are stored. The FindFirstChild method is a safe way to search for objects in a player's hierarchy without causing an error if it doesn't exist.
            </li>
          </ul>

          <img src={oneHundred} className='demo__image' alt='img_One' />
        </div>

        <div style={currentStep == 101 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 101: Check for Backpack's Existence 🎒✅</h2>

          <ul>
            <li>
            Within the hasAllRequiredTools function, after finding the Backpack, we check if it exists.
            </li>

            <li>
            This line is a conditional check to see if the backpack variable is not nil (which means the Backpack was found). If the Backpack doesn't exist, the function returns false, implying the player does not have the required tools since they don't have a Backpack.
            </li>

            <li>
            return false: Exits the function and returns false.
            </li>
          </ul>
          <img src={OneZeroOne} className='demo__image' alt='img_OneOne' />
        </div>

        <div style={currentStep == 102 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 102: Iterate Over Required Tools 🔁🛠️</h2>

          <ul>
            <li>
            Next, we iterate over each tool in the requiredTools table.
            </li>

            <li>
            Write: for _, toolName in ipairs(requiredTools) do.
            </li>

            <li>
            This loop will go through each tool name stored in requiredTools. The ipairs function iterates over the array part of the table in order. The underscore (_) is a Lua convention for a variable that is not used (in this case, the index).
            </li>

            <li>
              We are looping through each item to later check if each on is in the player's backpack.
            </li>
          </ul>
          <img src={OneZeroTwo} className='demo__image' alt='img_OneTwo' />
        </div>

        <div style={currentStep == 103 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 103: Check Each Tool in Backpack 🧐🎒</h2>

          <ul>
            <li>
            Inside the loop, we'll check if each required tool is in the player's Backpack.
            </li>

            <li>
            For each toolName in the requiredTools, we use FindFirstChild on the backpack to check if the tool is present. If FindFirstChild returns nil (meaning the tool is not found), we return false, indicating not all required tools are present.
            </li>

            <li>
            backpack:FindFirstChild(toolName): Searches the Backpack for a child with the name toolName.
            </li>
          </ul>
          <img src={OneZeroThree} className='demo__image' alt='img_OneThree' />
        </div>

        <div style={currentStep == 104 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 104: All Tools Verified, Return True ✅</h2>

          <ul>
            <li>
            If the loop completes without returning false, it means all tools are present.
            </li>

            <li>
            After the loop, write: return true.
            </li>

            <li>
            Reaching this line means the player's Backpack contained all the tools listed in requiredTools, so the function returns true to indicate the player has all required tools.
            </li>
          </ul>
          <img src={OneZeroFour} className='demo__image' alt='img_OneFour' />
        </div>

        <div style={currentStep == 105 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 105: Create Start Timer Function ⏲️🚀</h2>

          <ul>
            <li>
            Now, we'll define a new function that will handle the timer for the game.
            </li>

            <li>
            This function startTimer will likely be used to start a countdown or track time for a player in the game. The player parameter indicates that the timer will be specific to each player depending on when they started playing the game.
            </li>
          </ul>
          <img src={OneZeroFive} className='demo__image' alt='img_OneFive' />
        </div>

        <div style={currentStep == 106 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 106: Initialize the Timer Value ⏲️🎛️</h2>

          <ul>
            <li>
            In the startTimer function, we declare a variable to store the initial timer value.
            </li>

            <li>
            his line sets the timer for 10 minutes (since 10 * 60 seconds equals 10 minutes). The variable timerValue holds the number of seconds for which the timer will run, providing a countdown for players to complete an objective or challenge within the game.
            </li>
          </ul>
          <img src={OneZeroSix} className='demo__image' alt='img_OneSix' />
        </div>

        <div style={currentStep == 107 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 107: Indicate Timer Status 🚦</h2>

          <ul>
            <li>
            To manage the state of the timer, declare a boolean variable.
            </li>

            <li>
            This boolean variable timerRunning is used to track whether the timer is active. Setting it to true means the timer starts running as soon as the startTimer function is called. This is crucial for conditional checks within the game loop to determine when to update the timer and when to execute time-based events.
            </li>
          </ul>
          <img src={OneZeroSeven} className='demo__image' alt='img_OneSeven' />
        </div>

        <div style={currentStep == 108 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 108: Create a Reset Timer Function 🔁🔄</h2>

          <ul>
              <li>
              Inside the startTimer function, define a nested function to reset the timer.
              </li>

              <li>
              The resetTimer function is defined to reset the timerValue back to its initial state and ensure timerRunning is set to true. This can be used when the game needs to restart the timer, such as when the player retries a challenge or a new game round begins.
              </li>

              <li>
              local function resetTimer(): Starts the definition of a local function within startTimer
              </li>

              <li>
              timerValue = 10 * 60: Resets the timer to 10 minutes.
              </li>
              <li>
              timerRunning = true: Restarts the timer.
              </li>
          </ul>
          <img src={OneZeroEight} className='demo__image' alt='img_OneEight' />
        </div>

        <div style={currentStep == 109 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 109: Define a Function to Stop the Timer ⏹️🚫</h2>

          <ul>
            <li>
            Add another local function within startTimer to stop the timer.
            </li>

            <li>
            The stopTimer function sets timerRunning to false, effectively stopping the timer. This can be called when the player completes the objective before the time expires, or the game round ends. It's a critical part of managing game flow and ensuring that actions are not taken after the timer has ended.
            </li>
          </ul>
          <img src={OneZeroNine} className='demo__image' alt='img_OneNine' />
        </div>

        <div style={currentStep == 110 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 110: Automatically Reset Timer on Character Spawn 🔄👤</h2>

          <ul>
            <li>
            Finally, connect the resetTimer function to the player's CharacterAdded event.
            </li>

            <li>
            This line of code ensures that every time a player's character spawns (or respawns) in the game, the resetTimer function is called automatically. The CharacterAdded event fires when a new character for the player is added to the game, which can occur at the start of the game or upon respawn after a character's demise.
            </li>
          </ul>
          
          <img src={OneOneZero} className='demo__image' alt='img_OneTen' />
        </div>

        <div style={currentStep == 111 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 111: Implement the Timer Loop ⏲️🔄</h2>

          <ul>
            <li>
            Inside the startTimer function, you'll write a while true do loop.
            </li>

            <li>
            This loop will run indefinitely, creating a basic game loop for the timer.
            </li>

            <li>
            The while true do loop is a common construct used in Lua (and many programming languages) to create a loop that will run until it's explicitly stopped or the condition within it causes a break. In this case, it will allow us to repeatedly check the timer's status and count down.
            </li>
          </ul>
          <img src={OneOneOne} className='demo__image' alt='img_OneEleven' />
        </div>

        <div style={currentStep == 112 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 112: Introduce a Wait and Check Timer Status ⏳✅</h2>

          <ul>
            <li>
            Inside the loop, insert a wait(1) call to pause the loop for one second.
            </li>

            <li>
            Then, add an if timerRunning then condition to check if the timer is active.
            </li>

            <li>
            The wait(1) function is used to pause the loop's execution for one second, which is essential to create a countdown effect. The if statement checks the timerRunning variable; if it's true, the subsequent code (which we assume will decrement the timer) will execute.
            </li>

            <li>
            wait(1): Pauses the loop for 1 second before the next iteration.
            </li>

            <li>
            if timerRunning then: Checks if the timerRunning variable is true.
            </li>
          </ul>
          <img src={OneOneTwo} className='demo__image' alt='img_OneTwelve' />
        </div>

        <div style={currentStep == 113 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 113: Decrement the Timer 🔽</h2>

          <ul>
            <li>
            Still within the if statement, decrease the timerValue by 1.
            </li>

            <li>
            This line is where the countdown happens. Each time the loop runs, and if the timer is running, it will subtract one from timerValue, effectively counting down each second. This is the central mechanism of the timer functionality.
            </li>

            <li>
            timerValue = timerValue - 1: Subtracts 1 from timerValue, decreasing the timer.
            </li>
          </ul>
          <img src={OneOneThree} className='demo__image' alt='img_OneThirteen' />
        </div>

        <div style={currentStep == 114 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 114: Update the Client with the Timer Value 📤</h2>

          <ul>
            <li>
            Within the same if condition, call timerEvent:FireClient(player, timerValue).
            </li>

            <li>
            The FireClient function sends an update to the specific player's client (computer screen) with the current timerValue. This keeps the player informed about the timer, which could be used to update a UI element on their screen, for instance.
            </li>
          </ul>
          <img src={OneOneFour} className='demo__image' alt='img_OneFourteen' />
        </div>

        <div style={currentStep == 115 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 115: Check for Timer Completion 🏁</h2>

          <ul>
            <li>
            Add a new if statement: if timerValue &lt;= 0 then.
            </li>

            <li>
            This conditional check is used to determine if the countdown has reached zero. If timerValue is less than or equal to zero, it implies that the timer has run out, and any code within this if block would typically handle the end of the timer, such as calling the stopTimer function or triggering an event in the game.
            </li>
          </ul>
          <img src={OneOneFive} className='demo__image' alt='img_OneFifteen' />
        </div>

        <div style={currentStep == 116 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 116: Adding Game Logic for Tools 🛠️</h2>

          <ul>
            <li>
            In this step, we're diving into the script to make the game respond to whether or not a player has all the required tools. The code is checking if the timer has run out and then whether the player has all the necessary items. If not, the game stops the timer, respawns the player, and resets the timer. If the player has all items, they receive a special message!
            </li>
          </ul>
          <img src={OneOneSix} className='demo__image' alt='img_OneSixteen' />
        </div>

        <div style={currentStep == 117 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 117: Preparing to Handle New Players 🎮</h2>

          <ul>
            <li>
            Now, we're setting up to handle new players joining the game. We're about to write a function that will be triggered whenever a new player is added to the game to ensure their timer properly starts.
            </li>
          </ul>
          <img src={OneOneSeven} className='demo__image' alt='img_OneSeventeen' />
        </div>

        <div style={currentStep == 118 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 118: Defining the Player-Added Function 🤖</h2>

          <ul>
            <li>
            Here, we're continuing from the previous step, defining the actual function that will handle new players. The function will be connected to the PlayerAdded event.
            </li>
          </ul>
          <img src={OneOneEight} className='demo__image' alt='img_OneEighteen' />
        </div>

        <div style={currentStep == 119 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 119: Setting Up a Repeating Timer for Each Player ⏲️</h2>

          <ul>
            <li>
            Within the function we defined in Step 118, we're adding a loop that will start a timer for each player repeatedly, every 60 seconds.
            </li>
          </ul>
          <img src={OneOneNine} className='demo__image' alt='img_OneNineteen' />
        </div>

        <div style={currentStep == 120 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 120: Creating the Game Interface 🖥️</h2>

          <ul>
            <li>
            Moving on to the Roblox Studio interface, we are going to add a ScreenGui into the StarterGui, this ScreenGui will later contain the graphic design of the timer that the players will see.
            </li>
          </ul>
          <img src={OneTwoZero} className='demo__image' alt='img_OneTwenty' />
        </div>

        <div style={currentStep == 121 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 121: Adding a Text Label to the Interface 🏷️</h2>

          <ul>
              <li>
              Finally, we're adding a TextLabel to our GUI. This label will be used to display text on the screen, in this case the timer count down previously scripted.
              </li>
          </ul>
          <img src={OneTwoOne} className='demo__image' alt='img_OneTwentyOne' />
        </div>

        <div style={currentStep == 122 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 122: Customizing the Text Label 🎨</h2>

          <ul>
            <li>
            In this step, we're focused on customizing the appearance of the TextLabel within the ScreenGui. This is an essential part of user interface design, making sure that information like scores, messages, or instructions are clear and fit well with the overall design of the game.
            </li>

            <li>
            The red circles indicate where we've made changes to the label's size. The AbsoluteSize property has been set to 358x98 pixels. This size determines how much space the label will occupy on the screen, and it's important to choose a size that makes the label readable without obstructing gameplay.
            </li>
          </ul>
          <img src={OneTwoTwo} className='demo__image' alt='img_OneTwentyTwo' />
        </div>

        <div style={currentStep == 123 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 123: Adjusting Label Color 🌈</h2>

          <ul>
            <li>
            Now, you're enhancing the visual appeal of your TextLabel by adjusting the BackgroundColor3 property, which changes the background color of the label. The color is set using RGB (Red, Green, Blue) values, and here it appears you've chosen a vibrant green (RGB: 57, 255, 90). This is a great choice if you want the label to stand out against the background.
            </li>
          </ul>
          <img src={OneTwoThree} className='demo__image' alt='img_OneTwentyThree' />
        </div>

        <div style={currentStep == 124 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 124: Setting Label Transparency 🔳</h2>

          <ul>
            <li>
            By adjusting the BackgroundTransparency property, you're controlling how opaque or transparent the TextLabel's background is. A value of 0 means the background is fully opaque, while 1 would make it fully transparent. Here, a value of 0.4 has been chosen, offering a balance that ensures the text stands out while still allowing the game's background to be visible.
            </li>

            <li>
            This is particularly important for user experience; you want to ensure that players can quickly read the label without it distracting from the game itself.
            </li>
          </ul>
          <img src={OneTwoFour} className='demo__image' alt='img_OneTwentyFour' />
        </div>

        <div style={currentStep == 125 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 125: Implementing a Local Script 📝</h2>

          <ul>
            <li>
            You're about to add functionality to your TextLabel by implementing a LocalScript. This is a type of script in Roblox that runs on the player's computer, allowing you to create individualized client-side experiences, such as updating GUI elements in response to player actions or game events.
            </li>
          </ul>
          <img src={OneTwoFive} className='demo__image' alt='img_OneTwentyFive' />
        </div>

        <div style={currentStep == 126 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 126: Accessing Replicated Storage 🗂️</h2>

          <ul>
            <li>
            In this step, you're beginning to write a LocalScript that will interact with various parts of the game. The first line of code is accessing a service within Roblox known as ReplicatedStorage, which is a container for storing objects that should be replicated across the server and all clients (players).
            </li>
          </ul>
          <img src={OneTwoSix} className='demo__image' alt='img_OneTwentySix' />
        </div>

        <div style={currentStep == 127 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 127: Waiting for a Specific Child 🕓</h2>

          <ul>
            <li>
            Next, you're writing a line that waits for a specific object named "TimerEvent" to exist in ReplicatedStorage before the script continues. This is likely an object that will be used to synchronize a timer across clients.
            </li>

            <li>
            ReplicatedStorage:WaitForChild("TimerEvent"): This function call will pause the script until the TimerEvent object is found within ReplicatedStorage.
            </li>
          </ul>
          <img src={OneTwoSeven} className='demo__image' alt='img_OneTwentySeven' />
        </div>

        <div style={currentStep == 128 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 128: Defining the Timer Label 🏷️</h2>

          <ul>
            <li>
            Now, you're setting up a reference to the GUI element that will display the timer to the player.
            </li>
          </ul>
          <img src={OneTwoEight} className='demo__image' alt='img_OneTwentyEight' />
        </div>

        <div style={currentStep == 129 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 129: Creating an Update Function 🔄</h2>

          <ul>
            <li>
            Here, you start defining a function named updateTime, which will be responsible for updating the timer display.
            </li>

            <li>
            function updateTime(data): Declares a function called updateTime which takes one parameter named data.
            </li>

            <li>
              This function will be used to change the timer displayed on the player's screen based on the data it receives, which will likely be the remaining time.
            </li>
          </ul>
          <img src={OneTwoNine} className='demo__image' alt='img_OneTwentyNine' />
        </div>

        <div style={currentStep == 130 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 130: Checking Data Type ✅</h2>

          <ul>
            <li>
            In this step, you're adding a type check inside the updateTime function to ensure that the data being processed is a number, as you would expect time values to be numerical.
            </li>
          </ul>
          <img src={OneThreeOne} className='demo__image' alt='img_OneThirty' />
        </div>

        <div style={currentStep == 131 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 131: Updating the Timer Function 🔄</h2>

          <ul>
            <li>
            You're enhancing the updateTime function to update the text of the timer label. This function will be called whenever the timer event is fired, and the text on the GUI will reflect the current game time.
            </li>

            <li>
            timerLabel.Text = tostring(data): If it is a number, this line converts the number to a string and sets it as the text of the timer label.
            </li>

            <li>
            Why do this? It's because GUI elements display text, not raw numbers, so we need to convert any numerical data to a string format before displaying it on the screen.
            </li>
          </ul>
          <img src={OneThreeOne} className='demo__image' alt='img_OneThirtyOne' />
        </div>

        <div style={currentStep == 132 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 132: Handling Different Data Types 🛠️</h2>

          <ul>
            <li>
            Here, you're extending the updateTime function to handle cases where the data might not be a number. This could be useful for displaying messages like "Ready?" or "Go!".
            </li>

            <li>
            elseif type(data) == "string" then: This checks if the data is a string.
            </li>

            <li>
            timerLabel.Text = data: If it is a string, it directly sets the text of the timer label to that string.
            </li>

            <li>
              Why add this? It provides flexibility in your game's timer system, allowing for textual messages to be displayed as well as numerical countdowns.
            </li>
          </ul>
          <img src={OneThreeTwo} className='demo__image' alt='img_OneThirtyTwo' />
        </div>

        <div style={currentStep == 133 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 133: Connecting the Update Function to the Event 📡</h2>

          <ul>
              <li>
              In this step, you're connecting the updateTime function to the timerEvent so that it will be called whenever the OnClientEvent is fired from the server.
              </li>

              <li>
              timerEvent.OnClientEvent:Connect(updateTime): This creates a connection between the client event (which the server can trigger) and the updateTime function.
              </li>

              <li>
              Why is this necessary? By connecting the function to the event, you ensure that the timer label is updated in real-time based on the game's state, providing a real-time timer.
              </li>
          </ul>
          <img src={OneThreeThree} className='demo__image' alt='img_OneThirtyThree' />
        </div>

        <div style={currentStep == 134 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 134: Accessing the Toolbox 🧰</h2>

          <ul>
            <li>
            This step is more about using the Roblox Studio's interface rather than scripting. You're accessing the Toolbox, which is a panel in Roblox Studio that allows you to add pre-built models, decals, audio, and more to your game.
            </li>

            <li>
            Why use the Toolbox? It saves time by allowing you to use assets created by others, which can be especially helpful if you're not a modeler or if you need a placeholder while developing your game.
            </li>
          </ul>
          <img src={OneThreeFour} className='demo__image' alt='img_OneThirtyFour' />
        </div>

        <div style={currentStep == 135 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 135: Placing a Model from the Toolbox 🏛️</h2>

          <ul>
            <li>
            In this step, you're selecting and placing a model from the Toolbox into your game. The image shows the selection of a temple model, which you can drag and drop into your game environment.
            </li>

            <li>
            You would search for a specific type of model, in this case, a "temple," then browse through the results.
            </li>
          </ul>
          <img src={OneThreeFive} className='demo__image' alt='img_OneThirtyFive' />
        </div>

        <div style={currentStep == 136 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 136: Adding a Game Element</h2>

          <ul>
            <li>
            We need to create a blue forcefield-like object in the game environment, which is to be placed within the entrance of the temple structure.
            </li>

            <li>
              This block will turn into a blockade to only allow players who have all of the gems to enter the temple.
            </li>
          </ul>
          <img src={OneThreeSix} className='demo__image' alt='img_OneThirtySix' />
        </div>

        <div style={currentStep == 137 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 137: Adjusting Object Properties</h2>

          <ul>
            <li>
            Here, you're using the scaling tool from the editor's toolbar to adjust the size of the selected object. This tool helps you to resize game elements non-uniformly across different axes.
            </li>

            <li>
            Use the scale tool to adjust the object's dimensions to fit the intended doorway space.
            </li>

            <li>
              Ensure that the part is anchored.
            </li>
          </ul>
          <img src={OneThreeSeven} className='demo__image' alt='img_OneThirtySeven' />
        </div>

        <div style={currentStep == 138 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 138: Navigating the Explorer Panel and Renaming</h2>

          <ul>
            <li>
            Use the Explorer to find and select game elements, renaming the part to "border".
            </li>
          </ul>
          <img src={OneThreeEight} className='demo__image' alt='img_OneThirtyEight' />
        </div>

        <div style={currentStep == 139 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 139: Adding Scripts</h2>

          <ul>
            <li>
              Add a script into the block.
            </li>
            <li>
            Double-click the script attached to the game element in the Explorer to open it for editing.
            </li>
          </ul>
          <img src={OneThreeNine} className='demo__image' alt='img_OneThirtyNine' />
        </div>

        <div style={currentStep == 140 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 140: Writing the border Script</h2>

          <ul>
            <li>
            Begin scripting by establishing a reference to the game object (the part) the script is attached to.
            </li>

            <li>
            It's essential to reference the object in the script to modify its properties, such as visibility, color, or to add interactive functionalities.
            </li>
          </ul>
          <img src={OneFourZero} className='demo__image' alt='img_OneForty' />
        </div>

        <div style={currentStep == 141 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 141: Initialize Required Gems 🔨</h2>

          <ul>
            <li>
              Begin by declaring a table to store the names of the gems required for the game. Each gem is a string within the table.
            </li>

            <li>
            These are the gems that the player must have in order to walk through the border.
            </li>
          </ul>
          <img src={OneFourOne} className='demo__image' alt='img_OneFortyOne' />
        </div>

        <div style={currentStep == 142 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 142: Create a Function to Check Gems 🔍</h2>

          <ul>
            <li>
            Add a function definition below your table of gems. Type function hasAllRequiredStones(player).
            </li>

            <li>
            This function will be used to check if a player has collected all required gems.
            </li>

            <li>
            The cursor is placed right after the function declaration, ready for you to write the internal logic.
            </li>
          </ul>
          <img src={OneFourTwo} className='demo__image' alt='img_OneFortyTwo' />
        </div>

        <div style={currentStep == 143 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 143: Search for the Backpack 🎒</h2>

          <ul>
            <li>
            Inside the function, start by finding the player's backpack. Write the line local backpack = player:FindFirstChild("Backpack").
            </li>

            <li>
            This will search for an object named "Backpack" within the player.
            </li>
          </ul>
          <img src={OneFourThree} className='demo__image' alt='img_OneFortyThree' />
        </div>

        <div style={currentStep == 144 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 144: Add a Safety Check ✅</h2>

          <ul>
            <li>
            Insert a safety check by typing: if not backpack then return false end.
            </li>

            <li>
            This line checks if the backpack exists. If not, it returns false.
            </li>
          </ul>
          <img src={OneFourFour} className='demo__image' alt='img_OneFortyFour' />
        </div>

        <div style={currentStep == 145 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 145: Loop Through Required Gems 🔄</h2>

          <ul>
            <li>
            This loop will iterate over each gem in your requiredStones table.
            </li>
          </ul>
          <img src={OneFourFive} className='demo__image' alt='img_OneFortyFive' />
        </div>

        <div style={currentStep == 146 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 146: Check for Each Required Gem 🔎</h2>

          <ul>
            <li>
            Inside the for loop you created earlier, add an if statement: if not backpack:FindFirstChild(stone) then.
            </li>

            <li>
            This statement checks whether each required stone is present in the player's backpack. The function FindFirstChild looks for an object with the name of the stone within the backpack.
            </li>

            <li>
            If a required stone is not found (if not), then return false is executed. This means the function will end and indicate that the player does not have all the required gems.
            </li>
          </ul>
          <img src={OneFourSix} className='demo__image' alt='img_OneFortySix' />
        </div>

        <div style={currentStep == 147 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 147: Confirm All Gems Collected ✅</h2>

          <ul>
            <li>
            After the for loop, add a single line: return true.
            </li>

            <li>
            This line is executed if the loop completes without returning false, which would only happen if all required gems are present in the backpack.
            </li>

            <li>
            Returning true confirms that the player has all the required gems.
            </li>
          </ul>
          <img src={OneFourSeven} className='demo__image' alt='img_OneFortySeven' />
        </div>

        <div style={currentStep == 148 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 148: Detect Player Interaction 👆</h2>

          <ul>
            <li>
            Below your existing code, define a new function with function onPartTouched(other).
            </li>

            <li>
            This function will be triggered when a part (typically an in-game object) is touched by something else, which is usually a player's character.
            </li>

            <li>
            The other parameter will reference the object that touched the part.
            </li>
          </ul>
          <img src={OneFourEight} className='demo__image' alt='img_OneFortyEight' />
        </div>

        <div style={currentStep == 149 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 149: Identify the Player 👤</h2>

          <ul>
            <li>
            Inside the onPartTouched function, assign the player to a local variable with local player = game.Players:GetPlayerFromCharacter(other.Parent).
            </li>

            <li>
            This line of code is used to retrieve the player object from the character that touched the part. The GetPlayerFromCharacter function is part of the Players service, which is a feature in Roblox that allows you to access player-related information.
            </li>

            <li>
            other.Parent is the character model since in Roblox, the parts (like arms, legs, head) are children of the character model in the hierarchy.
            </li>
          </ul>

          <img src={OneFourNine} className='demo__image' alt='img_OneFortyNine' />
        </div>

        <div style={currentStep == 150 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 150: Ensure a Player is Detected 🔒</h2>

          <ul>
            <li>
            Continue within the onPartTouched function by adding an if condition: if player then.
            </li>

            <li>
            This condition checks if the player variable actually contains a player object, which would mean that a player has touched the part.
            </li>

            <li>
            The body of this if statement (to be added in subsequent steps) will contain the code that should execute when a player touches the part.
            </li>
          </ul>
          <img src={OneFiveZero} className='demo__image' alt='img_OneFifty' />
        </div>

        <div style={currentStep == 151 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 151: Implement Gem Check to Allow Passage 🚪</h2>

          <ul>
            <li>
            Inside the if player block, use the function hasAllRequiredStones(player) to check if the player has collected all the required gems.
            </li>

            <li>
            If the player has all the gems (then), set part.CanCollide = false. This makes the part non-collidable, allowing the player to pass through it.
            </li>

            <li>
            Use wait(1) to create a delay for 1 second. This delay gives the player time to pass through the part.
            </li>

            <li>
            After the wait, set part.CanCollide = true to make the part solid again, preventing further passage. This creates an interaction where only players with all gems can pass through a barrier at a given time.
            </li>
          </ul>
          <img src={OneFiveOne} className='demo__image' alt='img_OneFiftyOne' />
        </div>

        <div style={currentStep == 152 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 152: Connect Function to Part Event 🔗</h2>

          <ul>
            <li>
            After defining the onPartTouched function, connect it to the Touched event of the part using part.Touched:Connect(onPartTouched).
            </li>

            <li>
            This line makes it so that when the part is touched, the onPartTouched function is called, running its code.
            </li>

          </ul>
          <img src={OneFiveTwo} className='demo__image' alt='img_OneFiftyTwo' />
        </div>

        <div style={currentStep == 153 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 153: Adjust Object Transparency</h2>

          <ul>
            <li>
            In the Roblox Studio interface, select the border part.
            </li>

            <li>
            In the Properties window, find the Transparency field. Setting this value adjusts how see-through the part is.
            </li>

            <li>
            A Transparency value of 0.7 means the part is fairly transparent, allowing players to see that there is something behind or inside it, which can be important for giving visual clues in-game.
            </li>
          </ul>
          <img src={OneFiveThree} className='demo__image' alt='img_OneFiftyThree' />
        </div>

        <div style={currentStep == 154 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 154: Access the Toolbox 👀</h2>

          <ul>
            <li>
            In the Roblox Studio toolbar, ensure that the Toolbox is open.
            </li>
          </ul>
          <img src={OneFiveFour} className='demo__image' alt='img_OneFiftyFour' />
        </div>

        <div style={currentStep == 155 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 155: Add Springvia Toolbox</h2>

          <ul>
            <li>
            Open the Toolbox panel, which is Roblox Studio's asset library, by clicking on its icon or pressing Ctrl + T.
            </li>

            <li>
              Find the spring seen in the image below and add it.
            </li>
          </ul>
          <img src={OneFiveFive} className='demo__image' alt='img_OneFiftyFive' />
        </div>

        <div style={currentStep == 156 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 156: Assembling the Spring Tool 🛠️</h2>

          <ul>
            <li>
              To make this spring inventoriable it needs to be inside of a tool.
            </li>

            <li>
              That being said, add a "tool" into the workspace.
            </li>
          </ul>
          <img src={OneFiveSix} className='demo__image' alt='img_OneFiftySix' />
        </div>

        <div style={currentStep == 157 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 157: Assembling the Spring Tool Continued 🛠️</h2>

          <ul>
            <li>
              To pick this spring up it will need a handle. Add a new "part" into the workspace that we will later make into the spring handle.
            </li>
          </ul>
          <img src={OneFiveSeven} className='demo__image' alt='img_OneFiftySeven' />
        </div>

        <div style={currentStep == 158 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 158: Positioning the Spring Tool 🛠️</h2>

          <ul>
            <li>
              Take the part that you just added it and scale/move it such that it is centered inside of the spring. Use the image below to guide you.
            </li>
          </ul>
          <img src={OneFiveEight} className='demo__image' alt='img_OneFiftyEight' />
        </div>

        <div style={currentStep == 159 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 159: Make the Handle 🛠️</h2>

          <ul>
            <li>
              Now that the part is correctly positioned in the location that players will grab the spring, rename the part to "Handle" so that players can actually hold it.
            </li>
          </ul>
          <img src={OneFiveNine} className='demo__image' alt='img_OneFiftyNine' />
        </div>

        <div style={currentStep == 160 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 160: Organize the Tool 🛠️</h2>

          <ul>
            <li>
              To finish assembling the tool, add the "Handle" and the "Spring" into the tool via the explorer tab.
            </li>
          </ul>
          <img src={OneSixZero} className='demo__image' alt='img_OneSixty' />
        </div>

        <div style={currentStep == 161 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 161: Name the Tool 🛠️</h2>

          <ul>
            <li>
              Rename this tool to "Spring" such that it appears as so in the player's inventory.
            </li>
          </ul>
          <img src={OneSixOne} className='demo__image' alt='img_OneSixtyOne' />
        </div>

        <div style={currentStep == 162 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 162: Name the Spring 🛠️</h2>

          <ul>
            <li>
              Now that everything is configured how we want it, we need to "weld" the Spring part to the Handle part so that when the Handle is grabbed by the player they can pick up the spring by the welded handle.
            </li>

            <li>
              Start this process by adding a "WeldConstraint" into the "Spring".
            </li>
          </ul>
          <img src={OneSixTwo} className='demo__image' alt='img_OneSixtyTwo' />
        </div>

        <div style={currentStep == 163 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 163: Finish our Weld 🛠️</h2>

          <ul>
            <li>
              To complete the WeldConstraint, ensure that you can see its properties via the property tab.
            </li>

            <li>
              Set Part0 of the WeldConstraint to "Spring". What is being welded.
            </li>

            <li>
              Set Part1 of the WeldConstraint to "Handle". What we are welding the spring to.
            </li>
          </ul>
          <img src={OneSixThree} className='demo__image' alt='img_OneSixtyThree' />
        </div>

        <div style={currentStep == 164 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 164: Scripting the Spring 📝</h2>

          <ul>
            <li>
              Remember the whole point of the spring? To give the player a "super jump" power when they hold it. This needs to be scripted. Go Ahead and add the script we will code into the "Spring" tool.
            </li>
          </ul>
          <img src={OneSixFour} className='demo__image' alt='img_OneSixtyFour' />
        </div>

        <div style={currentStep == 165 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 165: Declaring the Spring Object 🌟</h2>

          <ul>
            <li>
            Start by declaring a local variable named spring.
            </li>

            <li>
            Assign spring the value of script.Parent. This will reference the parent object of the script in the hierarchy.
            </li>
          </ul>
          <img src={OneSixFive} className='demo__image' alt='img_OneSixtyFive' />
        </div>

        <div style={currentStep == 166 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 166: Initializing the Player Variable 🎮</h2>

          <ul>
            <li>
            Next, declare a local variable named player.
            </li>

            <li>
            Initially, set player to nil to indicate that it has no value yet. As we do not know who the player is until they pick up the spring.
            </li>
          </ul>
          <img src={OneSixSix} className='demo__image' alt='img_OneSixtySix' />
        </div>

        <div style={currentStep == 167 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 167: Creating the Equipped Function 💡</h2>

          <ul>
            <li>
            Create a function named equippedSpring that takes a parameter named part. For now, leave the function body empty.
            </li>

            <li>
            Below the function, connect the equippedSpring function to the Equipped event of spring using spring.Equipped:Connect(equippedSpring).
            </li>
          </ul>
          <img src={OneSixSeven} className='demo__image' alt='img_OneSixtySeven' />
        </div>

        <div style={currentStep == 168 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 168: Assigning the Player 🏃</h2>

          <ul>
            <li>
            Inside the equippedSpring function, assign spring.Parent to the player variable.
            </li>

            <li>
            This assigns the parent of spring (which should be the player character) to the player variable when the spring is equipped.
            </li>

            <li>
            Your function should now have a line inside it: player = spring.Parent.
            </li>
          </ul>
          <img src={OneSixEight} className='demo__image' alt='img_OneSixtyEight' />
        </div>

        <div style={currentStep == 169 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 169: Boosting Jump Power 🚀</h2>

          <ul>
            <li>
            Still inside the equippedSpring function, after setting the player variable, add a new line to set the JumpPower property of the player's Humanoid to 200.
            </li>

            <li>
            This line increases the player's jump power when the spring is equipped.
            </li>
          </ul>
          <img src={OneSixNine} className='demo__image' alt='img_OneSixtyNine' />
        </div>

        <div style={currentStep == 170 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 170: Creating the Unequipped Function 🔌</h2>

          <ul>
            <li>
            Now, create a new function named unequippedSpring. Leave the body of this function empty for the moment.
            </li>

            <li>
            Below this new function, connect the unequippedSpring function to the Unequipped event of spring using spring.Unequipped:Connect(unequippedSpring).
            </li>

            <li>
            This setup allows you to define what happens when the spring is unequipped.
            </li>
          </ul>
          <img src={OneSevenOne} className='demo__image' alt='img_OneSeventy' />
        </div>

        <div style={currentStep == 171 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 171: Resetting Jump Power on Unequipped 🔄</h2>

          <ul>
            <li>
            In the unequippedSpring function, set the player.Humanoid.JumpPower to 50. This line of code is crucial because when the spring is unequipped, you want the player's jump power to return to its normal state.
            </li>

            <li>
            By adding this code, you ensure that the player's abilities are appropriately adjusted when they stop using the spring item.
            </li>
          </ul>
          <img src={OneSevenOne} className='demo__image' alt='img_OneSeventyOne' />
        </div>

        <div style={currentStep == 172 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 172: Adjusting the Spring's Transparency 🏞️</h2>

          <ul>
            <li>
            Check that the Handle of the Spring object has its Transparency property set to 1. This makes the handle invisible in the game, which can be used to create the effect of the spring being a power-up or to keep the focus on the spring's visual effect rather than the handle itself.
            </li>
          </ul>
          <img src={OneSevenTwo} className='demo__image' alt='img_OneSeventyTwo' />
        </div>

        <div style={currentStep == 173 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 173:  Scaling the Spring 📏</h2>

          <ul>
            <li>
            With the Spring object still selected, choose the Scale tool in the toolbar. Drag the handles that appear around the spring to resize it. This will change the spring's size in the game, allowing you to make it more or less prominent based on the level design and how much you want it to stand out.
            </li>
          </ul>
          <img src={OneSevenThree} className='demo__image' alt='img_OneSeventyThree' />
        </div>

        <div style={currentStep == 174 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 174: Adjusting the Spring Position 🛠️</h2>

          <ul>
            <li>
            Use the Move tool from the toolbar to adjust the position of the spring in the game world. This step is about placing the spring in a strategic location where players can find and use it. In our case, put it into the temple behind the forcefield.
            </li>
          </ul>
          <img src={OneSevenFour} className='demo__image' alt='img_OneSeventyFour' />
        </div>

        <div style={currentStep == 175 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 175: Customizing the Game Terrain 🖌️</h2>

          <ul>
            <li>
            Go to the Terrain Editor in Roblox Studio.
            </li>

            <li>
            Select the Edit tab to gain access to tools that can modify the terrain.
            </li>

            <li>
            For instance, you might want to create a hill or a pit near where the spring is placed to encourage players to use the spring to navigate these terrain features.
            </li>
          </ul>
          <img src={OneSevenFive} className='demo__image' alt='img_OneSeventyFive' />
        </div>

        <div style={currentStep == 176 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 176: Creating Terrain Features with Draw Tool 🏔️</h2>

          <ul>
            <li>
            Access the 'Terrain Editor' in Roblox Studio and navigate to the 'Edit' section.
            </li>

            <li>
            Choose the 'Draw' brush mode. Use this brush mode to create a tower/mountain like structure that players will need the super jump to get to the top of. This is where we will put the "Speed Staff" for the players to discover.
            </li>
          </ul>
          <img src={OneSevenSix} className='demo__image' alt='img_OneSeventySix' />
        </div>

        <div style={currentStep == 177 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 177: Placing Custom Parts in the Environment 🚧</h2>

          <ul>
            <li>
            In the 'Model' tab, click on 'Part' to create a new part. This action adds a basic block to your game which can be shaped and used for creating a platform.
            </li>

            <li>
            Remember to anchor your parts by clicking the 'Anchor' button. Anchoring ensures that your parts don't fall out of place or get moved around during gameplay, maintaining the integrity of your level design.
            </li>

            <li>
              This is the platform we will put the "Speed Staff" on.
            </li>
          </ul>
          <img src={OneSevenSeven} className='demo__image' alt='img_OneSeventySeven' />
        </div>

        <div style={currentStep == 178 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 178: Constructing the Speed Staff.🔩</h2>

          <ul>
            <li>
            Look at the structure that resembles a "Speed Staff" in your Roblox game. This object is made up of several parts that need to be firmly attached to one another to act as a single item in the game.
            </li>

            <li>
            Notice how each part of the staff is connected with WeldConstraint components. A WeldConstraint ensures that the parts it connects act as one solid object, rather than behaving as separate entities. This is crucial for maintaining the staff's integrity as players interact with it within the game.
            </li>

            <li>
            By using WeldConstraints, you're ensuring that no matter how the staff is moved or where it's placed, it will always maintain its structural design, giving the players a consistent experience.
            </li>
          </ul>
          <img src={OneSevenEight} className='demo__image' alt='img_OneSeventyEight' />
        </div>

        <div style={currentStep == 179 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 179: Beginning the Script for the Speed Staff 📝</h2>

          <ul>
            <li>
            Add the script associated with the "Speed Staff". This script is where you'll define the functionality of the staff.
            </li>

            <li>
            Start your script with the line: local staff = script.Parent. This line establishes a reference to the 'Speed Staff' object by accessing the parent of the script, which should be the staff itself. It's the first step in scripting the staff's interactions and behaviors in the game.
            </li>

            <li>
            This initial line of code is essential because it connects the script's logic to the staff object. Without it, your script wouldn't know which object in the game it's supposed to affect.
            </li>
          </ul>
          <img src={OneSevenNine} className='demo__image' alt='img_OneSeventyNine' />
        </div>

        <div style={currentStep == 180 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 180: Setting Up the Player Variable for the Speed Staff 🧑‍💻</h2>

          <ul>
            <li>
            Directly below the first line in your script, add local player = nil. This line creates a variable called 'player' but doesn't assign it any value yet (nil).
            </li>

            <li>
            The 'player' variable is prepared to later hold a reference to the game's player who will interact with the 'Speed Staff'. This is a common practice in scripting where you set up variables early on that will be used in subsequent parts of the script.
            </li>

            <li>
            The reason for initializing 'player' to nil is that you're not ready to assign it until the staff is actually interacted with by a player in the game. At that point, the script will define who the player is based on who is interacting with the staff.
            </li>
          </ul>
          <img src={OneEightZero} className='demo__image' alt='img_OneEighty' />
        </div>

        <div style={currentStep == 181 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 181: Creating the Equipped Function for the Speed Staff ⚡️</h2>

          <ul>
            <li>
            The purpose of the equippedStaff function is to define what happens when a player picks up or equips the staff in the game. For a "Speed Staff", you might want to increase the player's walking speed, giving them the ability to move faster for a certain period or while the staff is equipped.
            </li>
          </ul>
          <img src={OneEightOne} className='demo__image' alt='img_OneEightyOne' />
        </div>

        <div style={currentStep == 182 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 182: Increasing Player's Speed on Equipping the Staff 🏃‍♂️</h2>

          <ul>
            <li>
            Here, you're assigning the parent object of the staff to the player variable, which typically is the character model in the game. Then, you're setting the WalkSpeed property on the player's humanoid to 50, which is a significant increase from the default walk speed, thereby simulating the speed enhancement provided by the Speed Staff.
            </li>
          </ul>
          <img src={OneEightTwo} className='demo__image' alt='img_OneEightyTwo' />
        </div>

        <div style={currentStep == 183 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 183: Defining the Unequipped Function for the Speed Staff 🌪️</h2>

          <ul>
            <li>
            The function unequippedStaff() line starts the definition of what will happen when the staff is unequipped.
            </li>

            <li>
            This function is crucial because any changes made to the player's properties when the staff is equipped should be reversible to avoid permanently altering the player's gameplay.
            </li>
          </ul>
          <img src={OneEightThree} className='demo__image' alt='img_OneEightyThree' />
        </div>

        <div style={currentStep == 184 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 184: Resetting Player's Speed on Unequipping the Staff 🔄</h2>

          <ul>
            <li>
            This line resets the player's WalkSpeed to 16, which is the default walk speed in Roblox. This is an important step for game balance, ensuring that the effects of the Speed Staff are temporary and controlled.
            </li>
          </ul>
          <img src={OneEightFour} className='demo__image' alt='img_OneEightyFour' />
        </div>



        <div style={currentStep == 185 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 185: Testing and Publishing Your Game🔬🌐</h2>

          <h2>
          Testing Your Game Locally🕹️
          </h2>
          <ul>
              <li>
              In Roblox Studio, click on the "Home" tab at the top
              </li>
              <li>
              Click the "Play" button to test your game locally
              </li>
              <li>
              You'll spawn on the starting platform and can play through your game, ensuring that each feature works as intended
              </li>
              <li>
              If you find any issues, pause the game by clicking the "Stop" button, then make the necessary adjustments in your game and test again.
              </li>
              <li>
              Testing your game locally helps identify any potential problems or bugs before sharing it with the public. This ensures a smooth gaming experience for everyone!👩‍💻🎮
              </li>
          </ul>
        </div>



        <div style={currentStep == 186 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 186: Publishing Your Game to Roblox🚀</h2>
          <ul>
              <li>
              Once you're satisfied with your project, click on the "File" tab in the top left corner of Roblox Studio
              </li>
              <li>
              Select "Publish to Roblox" and choose "Create New Game" (or update an existing game if you've published it before)
              </li>
              <li>
              Fill in the required information, such as game name, description, and genre
              </li>
              <li>
              Set the game's thumbnail and icon to visually represent your game (optional)
              </li>
              <li>
              Click the "Publish" button to make your game live on the Roblox platform
              </li>
              <li>
              When you publish your game, you're sharing it with the entire Roblox community! This is your chance to showcase your creativity and see other players enjoy your adventure.🌟🌍
              </li>
          </ul>

          <h3>
          Developing a game on Roblox is no small feat, and it's a testament to your skills and passion for game development. Your commitment to learning, problem-solving, and bringing your ideas to life is truly inspiring. Building a game requires a combination of technical know-how, design expertise, and the ability to engage and entertain players, and you have clearly excelled in all these areas.<br/><br/>Not only have you demonstrated your talent in creating a game, but you have also showcased your ability to think critically, work through challenges, and persevere. These are invaluable skills that will serve you well not only in game development but also in various other areas of your life.<br/><br/>I encourage you to take pride in your achievement and to continue pursuing your passion for game development. Your dedication and talent are sure to lead to even greater successes in the future. Remember, the journey of learning and improvement is ongoing, and every project you undertake will help you grow and refine your skills.
          </h3>
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>

    </div>
  )
}

export default Gem