import React, {useState} from 'react';
import one from './robloxImages/MAZE-RUNNER/1.png';
import two from './robloxImages/MAZE-RUNNER/2.png';
import three from './robloxImages/MAZE-RUNNER/3.png';
import four from './robloxImages/MAZE-RUNNER/4.png';
import five from './robloxImages/MAZE-RUNNER/5.png';
import six from './robloxImages/MAZE-RUNNER/6.png';
import seven from './robloxImages/MAZE-RUNNER/7.png';
import eight from './robloxImages/MAZE-RUNNER/8.png';
import nine from './robloxImages/MAZE-RUNNER/9.png';
import ten from './robloxImages/MAZE-RUNNER/10.png';
import eleven from './robloxImages/MAZE-RUNNER/11.png';
import twelve from './robloxImages/MAZE-RUNNER/12.png';
import thirteen from './robloxImages/MAZE-RUNNER/13.png';
import fourteen from './robloxImages/MAZE-RUNNER/14.png';

import sixteen from './robloxImages/MAZE-RUNNER/16.png';
import seventeen from './robloxImages/MAZE-RUNNER/17.png';
import eighteen from './robloxImages/MAZE-RUNNER/18.png';
import nineteen from './robloxImages/MAZE-RUNNER/19.png';
import twenty from './robloxImages/MAZE-RUNNER/20.png';
import twentyOne from './robloxImages/MAZE-RUNNER/21.png';
import twentyTwo from './robloxImages/MAZE-RUNNER/22.png';
import twentyThree from './robloxImages/MAZE-RUNNER/23.png';
import twentyFour from './robloxImages/MAZE-RUNNER/24.png';
import twentyFive from './robloxImages/MAZE-RUNNER/25.png';
import twentySix from './robloxImages/MAZE-RUNNER/26.png';
import twentySeven from './robloxImages/MAZE-RUNNER/27.png';
import twentyEight from './robloxImages/MAZE-RUNNER/28.png';
import twentyNine from './robloxImages/MAZE-RUNNER/29.png';
import thirty from './robloxImages/MAZE-RUNNER/30.png';
import thirtyOne from './robloxImages/MAZE-RUNNER/31.png';
import thirtyTwo from './robloxImages/MAZE-RUNNER/32.png';
import thirtyThree from './robloxImages/MAZE-RUNNER/33.png';
import thirtyFour from './robloxImages/MAZE-RUNNER/34.png';
import thirtyFive from './robloxImages/MAZE-RUNNER/35.png';
import thirtySix from './robloxImages/MAZE-RUNNER/36.png';
import thirtySeven from './robloxImages/MAZE-RUNNER/37.png';
import thirtyEight from './robloxImages/MAZE-RUNNER/38.png';
import thirtyNine from './robloxImages/MAZE-RUNNER/39.png';
import forty from './robloxImages/MAZE-RUNNER/40.png';
import fortyOne from './robloxImages/MAZE-RUNNER/41.png';
import fortyTwo from './robloxImages/MAZE-RUNNER/42.png';
import fortyThree from './robloxImages/MAZE-RUNNER/43.png';
import fortyFour from './robloxImages/MAZE-RUNNER/44.png';
import fortyFive from './robloxImages/MAZE-RUNNER/45.png';
import fortySix from './robloxImages/MAZE-RUNNER/46.png';
import fortySeven from './robloxImages/MAZE-RUNNER/47.png';
import fortyEight from './robloxImages/MAZE-RUNNER/48.png';
import fortyNine from './robloxImages/MAZE-RUNNER/49.png';
import fifty from './robloxImages/MAZE-RUNNER/50.png';
import fiftyOne from './robloxImages/MAZE-RUNNER/51.png';
import fiftyTwo from './robloxImages/MAZE-RUNNER/52.png';
import fiftyThree from './robloxImages/MAZE-RUNNER/53.png';
import fiftyFour from './robloxImages/MAZE-RUNNER/54.png';
import fiftyFive from './robloxImages/MAZE-RUNNER/55.png';
import fiftySix from './robloxImages/MAZE-RUNNER/56.png';
import fiftySeven from './robloxImages/MAZE-RUNNER/57.png';
import fiftyEight from './robloxImages/MAZE-RUNNER/58.png';
import fiftyNine from './robloxImages/MAZE-RUNNER/59.png';
import sixty from './robloxImages/MAZE-RUNNER/60.png';
import sixtyOne from './robloxImages/MAZE-RUNNER/61.png';
import sixtyTwo from './robloxImages/MAZE-RUNNER/62.png';
import sixtyThree from './robloxImages/MAZE-RUNNER/63.png';
import sixtyFour from './robloxImages/MAZE-RUNNER/64.png';
import sixtyFive from './robloxImages/MAZE-RUNNER/65.png';
import sixtySix from './robloxImages/MAZE-RUNNER/66.png';
import sixtySeven from './robloxImages/MAZE-RUNNER/67.png';
import sixtyEight from './robloxImages/MAZE-RUNNER/68.png';
import sixtyNine from './robloxImages/MAZE-RUNNER/69.png';
import seventy from './robloxImages/MAZE-RUNNER/70.png';
import seventyOne from './robloxImages/MAZE-RUNNER/71.png';
import seventyTwo from './robloxImages/MAZE-RUNNER/72.png';
import seventyThree from './robloxImages/MAZE-RUNNER/73.png';
import seventyFour from './robloxImages/MAZE-RUNNER/74.png';
import seventyFive from './robloxImages/MAZE-RUNNER/75.png';
import seventySix from './robloxImages/MAZE-RUNNER/76.png';
import seventySeven from './robloxImages/MAZE-RUNNER/77.png';
import seventyEight from './robloxImages/MAZE-RUNNER/78.png';
import seventyNine from './robloxImages/MAZE-RUNNER/79.png';
import eighty from './robloxImages/MAZE-RUNNER/80.png';
import eightyOne from './robloxImages/MAZE-RUNNER/81.png';
import eightyTwo from './robloxImages/MAZE-RUNNER/82.png';
import eightyThree from './robloxImages/MAZE-RUNNER/83.png';
import eightyFour from './robloxImages/MAZE-RUNNER/84.png';
import eightyFive from './robloxImages/MAZE-RUNNER/85.png';
import eightySix from './robloxImages/MAZE-RUNNER/86.png';
import eightySeven from './robloxImages/MAZE-RUNNER/87.png';
import eightyEight from './robloxImages/MAZE-RUNNER/88.png';
import eightyNine from './robloxImages/MAZE-RUNNER/89.png';
import ninety from './robloxImages/MAZE-RUNNER/90.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const Maze = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 92;

  return (
    <div className='robloxGames__container'>
        <h1>
        🍯 Maze Runner: Flee the Bees and Take their Honey 🐝
        </h1>

        <h3 className='intro'>
            Run fast and fly🪰 high, it is time to gear our players up with bee blasters🔫 and honey🍯 cannons to fight their way through the swarm of angry bees that live to protect their hive 🏠
        </h3>

        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>

        



        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Choosing Your Template 🌟</h2>
            <ul>
                <li>
                Open Roblox Studio from your desktop or Start menu🖱️
                </li>
                <li>
                Sign in with your Roblox account or create a new one🔑
                </li>
                <li>
                Click "New" to create a new project and select "Classic Baseplate" as the template🗺️<br/><br/>(By doing this, you're starting a fresh new game project with a simple baseplate to build your obby on.🛠️)
                </li>
            </ul>

            <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Insert Your First Game Object ➕</h2>
            <ul>
                <li>
                With the Classic Baseplate open, click on the "Part" button in the "Home" tab.
                </li>

                <li>
                A small grey block, called a "Part", will appear on your baseplate. This is the basic building block of your Roblox game.
                </li>

                <li>
                In the "Explorer" window, you'll see that "Part" has been added to your workspace, confirming the action you just took.
                </li>
            </ul>

            <img src={two} className='demo__image' alt='img_two' />
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 3: Transform Your Object 🔄📏</h2>
            <ul>
                <li>
                    Add more parts and use the select, move, scale, and rotate tools to create a "blaster" shaped object as seen below.
                </li>

                <li>
                    This is the "Bee Blaster" and will be used to fight the angry bees in the maze.
                </li>
            </ul>

            <img src={three} className='demo__image' alt='img_three' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: Customize Your Object 🎨🖌️</h2>
            <ul>
                <li>
                Go wild with your imagination and change the Part to any color you like.
                </li>

                <li>
                Observe how the Part's appearance updates immediately, adding a splash of personality to your game!
                </li>
            </ul>

            <img src={four} className='demo__image' alt='img_four' />
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Add a Tool to Your Game 🛠️</h2>
            <ul>
                <li>
                Notice the new addition in the "Explorer" window, labeled as "Tool" (highlighted in blue).
                </li>

                <li>
                This indicates that a tool has been added to your game. Tools are items that players can use; they could be anything from a sword to a building block.
                </li>
            </ul>

            <img src={five} className='demo__image' alt='img_five' />
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Organizing Your Game Elements 🗂️✨</h2>
            <ul>
                <li>
                In the "Explorer" window, you'll notice the "Bee Blaster" tool is now listed. This is the tool you just added. Rename the tool to "Bee Blaster".
                </li>

                <li>
                    Then, add all of the parts of the "Bee Blaster" into the tool.
                </li>
            </ul>

            <img src={six} className='demo__image' alt='img_six' />
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Mastering the Hierarchy 📊🔗</h2>
            <ul>
                <li>
                Looking at the "Explorer", notice the "Handle" that's now under "Bee Blaster".
                </li>

                <li>
                The "Handle" is a part of the "Bee Blaster" tool, it was originally named part (you need to rename "part" to "Handle").
                </li>
            </ul>

            <img src={seven} className='demo__image' alt='img_seven' />
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Refining Your Tools 🖌️</h2>
            <ul>
                <li>
                The "Tip" of the "Bee Blaster" is now visible on the workspace (circled in red), and in the "Explorer", it's listed as new part under the "Bee Blaster".
                </li>

                <li>
                    Make sure to name the part accordingly. 
                </li>
            </ul>

            <img src={eight} className='demo__image' alt='img_eight' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Assembling Parts Together 🧩🔧</h2>
            <ul>
                <li>
                In this step, as highlighted in the "Explorer" window, we see the addition of a "WeldConstraint" within the "Bee Blaster" tool. We are adding it to the first block named "part", not "Handle", as we will be welding all of the parts to the "Handle".
                </li>

                <li>
                A "WeldConstraint" is a crucial component that fuses parts together, ensuring that they move as a single unit. This is especially important for tools, as it prevents them from falling apart when being used in the game.
                </li>

                <li>
                The reason for welding parts is to maintain the tool's integrity during gameplay, allowing for a seamless experience for the player. The tool acts as one item rather than a collection of loose parts, making it more robust and reliable during interactions.
                </li>
            </ul>

            <img src={nine} className='demo__image' alt='img_nine' />
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Configuring Your Constraint Properties ⚙️📐</h2>
            <ul>
                <li>
                With the "WeldConstraint" in place, it's time to ensure that it's configured correctly. In the "Properties" window, we can see the two parts linked by the constraint, "Part0" and "Part1".
                </li>

                <li>
                    Right now in the properties of this "WeldConstraint", there is a Part0 and Part1. Both of which should have an empty box, indicating that the parts are not yet welded.
                </li>
            </ul>

            <img src={ten} className='demo__image' alt='img_ten' />
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: Finalizing the first Weld Between Parts 🚀🔗</h2>
            <ul>
                <li>
                    Set Part0 to the "Part" that it is in.
                </li>

                <li>
                    Set Part1 to the "Handle" we are welding the part to.
                </li>
            </ul>

            <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Adding a New WeldConstraint 🧲🔧</h2>
            <ul>
                <li>
                A new "WeldConstraint" has been added to the "Tip" of the "Bee Blaster," as seen in the "Explorer" window.
                </li>

                <li>
                Adding this constraint is about planning for the complex interactions your tool will have within the game. It's about considering how each part will function and ensuring that it does so in a way that is mechanically sound and maintains game performance. 
                </li>
            </ul>

            <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: Completing the WeldConstraint on the Tip 🛠️🎯</h2>
            <ul>
                <li>
                In the "Properties" window for the newly added "WeldConstraint" to the "Tip," you will assign the specific parts to be welded, similar to the previous step with Part0 and Part1.
                </li>

                <li>
                Completing this weld means you're one step closer to having a fully functional and reliable tool in your game. It's not just about making sure the parts stick together; it's about crafting an item that will behave predictably and effectively in the hands of the player, which is vital for gameplay balance and enjoyment. 
                </li>

                <li>
                Set Part0 to the "Tip" part and Part1 to the "Handle" part.
                </li>
            </ul>

            <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: Weld Every Part ➡️</h2>
            <ul>
                <li>
                    Add a "WeldConstraint" to every single part (except for the Handle) and weld each part to the Handle.
                </li>
            </ul>

            <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15: Test the Bee Blaster 🐝</h2>
            <ul>
                <li>
                    Test out your Bee Blaster to make sure it is the right size, orientation, and position. If it is not built how you like then go back to design mode and edit it.
                </li>
            </ul>

        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 16: Beginning Scripting for the Bee Blaster 📜✍️</h2>
            <ul>
                <li>
                As highlighted in the "Explorer" window, a script has been added as a child of the "Bee Blaster" tool. 
                </li>
            </ul>
            <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 17: Initializing the Tool Script 📜🛠️</h2>
            <ul>
                <li>
                In this step, we are beginning the scripting process for the "Bee Blaster" tool. The first line of code is crucial as it establishes a variable named Tool that refers to the script's parent object in the hierarchy, which in this case, is the "Bee Blaster" tool itself.
                </li>

                <li>
                The line local Tool = script.Parent is the standard way to get a reference to the tool object that the script is attached to. "Local" is used to declare a variable that is only accessible within the script, which is a good practice to prevent naming conflicts with other scripts.
                </li>
            </ul>

            <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 18: Defining the Fire Function 🎯🔥</h2>
            <ul>
                <li>
                In this step of our script, we're creating the core functionality for the "Bee Blaster" tool. We're writing a function named fireBeeBlaster that, when called, will perform the action of firing the blaster.
                </li>

                <li>
                Begin by defining the function with function fireBeeBlaster(). This line sets up a new function in Lua, which is a block of code designed to perform a specific task—in this case, to fire the blaster.
                </li>

                <li>
                Writing functions is a foundational concept in programming, as it allows you to execute the same piece of code from multiple places without rewriting it each time.
                </li>

                <li>
                Functions also help in organizing your code into manageable sections. By encapsulating the firing logic inside a function, you make your code cleaner, more readable, and easier to debug.
                </li>
            </ul>

            <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 19: Identifying the Character 🕵️‍♂️👤</h2>
            <ul>
                <li>
                After defining the function, we need to determine which character is using the tool. We do this by creating a local variable character that references the parent of the tool, which should be the character model.
                </li>

                <li>
                The line local character = Tool.Parent assigns the character model that is holding the tool to the variable character. In Roblox, the character model is the parent of the tool when the tool is equipped.
                </li>

                <li>
                This is important because actions performed by the tool, such as firing, often depend on the character's properties, like its position and orientation.
                </li>
            </ul>

            <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 20: Accessing the Humanoid Component 🚶‍♂️🔍</h2>
            <ul>
                <li>
                To make the tool's actions even more sophisticated, we can reference the character's Humanoid object. The Humanoid is a special object in Roblox that contains properties related to characters, such as health, animations, and states like jumping or running.
                </li>

                <li>
                local humanoid = character:FindFirstChildWhichIsA("Humanoid") uses a Roblox function to find the first child of the character model that is a Humanoid object and assigns it to the variable humanoid.
                </li>

                <li>
                The use of FindFirstChildWhichIsA is a safe way to search through the character's children because it returns nil if no Humanoid is found, preventing errors that would occur if you attempted to access a property or method on a non-existent object.
                </li>
            </ul>

            <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 21: Setting Up the Fire Function 🚀</h2>
            <ul>
                <li>
                In this step, we're starting to define a function to fire our Bee Blaster! Pay attention to the red arrow pointing to the line of code. It’s telling us we're using the GetPlayerFromCharacter method to grab the player object from the character.
                </li>

                <li>
                Make sure you've got this line in your function to ensure your Bee Blaster knows who is firing it!
                </li>
            </ul>

            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 22: Checking for Player and Humanoid 🕵️‍♂️</h2>
            <ul>
                <li>
                Here we're adding a safety check! The code makes sure that both player and humanoid are valid before proceeding.
                </li>

                <li>
                Always good to double-check, so we don't end up with any errors!
                </li>
            </ul>

            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 23: Creating the Honey Part 🍯</h2>
            <ul>
                <li>
                Ooh, now we're getting to the sweet stuff! The red arrow shows we're creating a new part in the game which will represent our honey.
                </li>

                <li>
                This is where the magic starts, as we're creating something out of nothing!
                </li>
            </ul>

            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 24: Shaping the Honey 🐝</h2>
            <ul>
                <li>
                    Now, let's give our honey some shape! The red arrow is pointing to where we define the shape of our honey part. We're making it a ball - perfect for blasting!
                </li>
            </ul>

            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 25: Customizing the Honey Blast 💥</h2>
            <ul>
                <li>
                This step is all about customization! Look at all the properties we're setting for our honey part: name, material, color, and size. These lines of code make our honey blast look cool and feel unique.
                </li>

                <li>
                With this, our honey blast is not just effective; it's also eye-catching! 🌟
                </li>
            </ul>
            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 26: Honey Properties 🚫🍯</h2>
            <ul>
                <li>
                honey.CanCollide = false ensures that the honey part won't trigger any collision response with other objects.
                </li>

                <li>
                honey.Anchored = false allows the honey part to move freely in the world under the influence of physics, rather than being fixed in place.
                </li>

                <li>
                honey.Position = Tool.Tip.Position sets the starting position of the honey blast to be the same as the position of the tool's tip.
                </li>

                <li>
                honey.Parent = workspace makes the honey part a child of the workspace, which is necessary for it to appear in the game.
                </li>
            </ul>

            <img src={twentySix} className='demo__image' alt='img_twentySix' />
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 27: Creating the BodyVelocity Object 🌬️</h2>
            <ul>
                <li>
                In this step, we're creating an object that will be responsible for moving our honey blast through the air.
                </li>

                <li>
                BodyVelocity is a physics object that allows us to apply a consistent force to a part in a specific direction.
                </li>

                <li>
                local bodyVelocity declares a new local variable named bodyVelocity. This means the variable is only accessible within the block of code where it's defined.
                </li>

                <li>
                Instance.new("BodyVelocity") is a function call that creates a new instance of a BodyVelocity object. Think of it like grabbing a new tool from the toolbox that can make objects move.
                </li>

                <li>
                By creating a BodyVelocity object and storing it in a variable, we're preparing to give our honey blast the ability to fly through the game world. It's like attaching an engine to a car but not turning it on just yet.
                </li>
            </ul>
        
            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 28: Assigning Velocity to the Honey Blast 🚀</h2>
            <ul>
                <li>
                Now that we have our BodyVelocity object, it's time to give our honey blast a direction and speed.
                </li>

                <li>
                This line of code will propel our honey forward in the direction the tool is facing.
                </li>

                <li>
                bodyVelocity.Velocity refers to the Velocity property of our BodyVelocity object. This property determines the direction and speed of the honey's movement.
                </li>

                <li>
                Tool.Tip.CFrame.LookVector * 100 calculates the direction. Tool.Tip.CFrame.LookVector gives a unit vector (a vector with length 1) pointing in the direction the tool's tip is facing. Multiplying this by 100 sets the speed, making our honey blast off like a rocket!
                </li>

                <li>
                By setting the Velocity property, we are telling the honey blast to move in a certain direction at a specified speed as soon as it's created. Imagine the honey blast as a ball being thrown — this code determines the direction and how hard it's thrown.
                </li>
            </ul>

            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 29: Attaching the BodyVelocity 🛠️</h2>
            <ul>
                <li>
                Now we need to make sure the BodyVelocity is applied to the honey blast.
                </li>

                <li>
                The Parent property sets which object the BodyVelocity will affect. In this case, it's our honey blast.
                </li>

                <li>
                bodyVelocity.Parent is like telling the BodyVelocity, "You are going to work on this specific part (the honey)."
                </li>

                <li>
                "= honey" assigns the honey blast as the parent. This links our BodyVelocity to the honey, ensuring the honey will move according to the Velocity we set.
                </li>

                <li>
                This line is crucial because it effectively turns on the "engine" we attached to the honey blast. Without this, the BodyVelocity would be like an engine sitting in a garage, not doing much.
                </li>
            </ul>

            <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 30: Auto-Cleanup with Debris Service 🧹</h2>
            <ul>
                <li>
                After the honey blast moves, we don’t want it to fly through the air forever. We’ll use the Debris service to automatically clean it up after a short time.
                </li>

                <li>
                This ensures that our game environment remains clean and performant by removing the honey blast after it's no longer needed.
                </li>

                <li>
                game:GetService("Debris") grabs the Debris service, which is responsible for cleaning up objects after a certain time.
                </li>

                <li>
                :AddItem(honey, 5) schedules the removal of the honey blast. The first argument is the object to clean up, and the second is how long to wait before cleanup (in 5 seconds).
                </li>

                <li>
                It’s like setting a timer for the honey blast. Once it hits 5 seconds after being created, it will disappear, thanks to the Debris service. This is similar to setting a piece of paper on fire — it burns brightly for a moment (does its job) and then turns to ash (gets cleaned up).
                </li>
            </ul>

            <img src={thirty} className='demo__image' alt='img_thirty' />
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 31: Detecting Honey Blast Hit 🎯</h2>
            <ul>
                <li>
                When our honey blast "touches" something in the game, we want to know about it. For this, we use the .Touched event and connect it to a function.
                </li>

                <li>
                The .Touched event triggers when the part it’s connected to, in this case, the honey, comes into contact with another part in the game world.
                </li>

                <li>
                By connecting a function to this event, we’re setting up a response for when the honey blast hits something. This could be a player, a wall, or any other part.
                </li>
            </ul>

            <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 32: Identifying the Hit Object 🕵️</h2>
            <ul>
                <li>
                After detecting a hit, we want to find out what was hit. Specifically, we're interested in whether the hit object is a character's part.
                </li>

                <li>
                In Roblox, parts that belong to a character are parented under a model representing that character. So, by checking the Parent of the hit part, we can determine if it's part of a character.
                </li>

                <li>
                This line of code stores the parent object of whatever our honey blast hit. If the blast hits a player's arm, hit.Parent would refer to the player's character model.
                </li>
            </ul>

            <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 33: Checking for a Humanoid 🧬</h2>
            <ul>
                <li>
                We need to check if the hit object is indeed a character, which is typically indicated by the presence of a Humanoid object.
                </li>

                <li>
                The Humanoid object is a special type of object in Roblox that indicates the model is a character (player or NPC). By finding this, we confirm that the honey blast has hit a character.
                </li>

                <li>
                We're looking inside the hitCharacter for a child object that is a Humanoid. If FindFirstChildWhichIsA finds a Humanoid, hitHumanoid will not be nil; it will be the Humanoid object.
                </li>
            </ul>

            <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 34: Confirming the Hit and Applying Damage 💥</h2>
            <ul>
                <li>
                Before applying damage, we must ensure that the character hit is not the one who fired the honey blast, to avoid self-inflicted damage.
                </li>

                <li>
                We perform this check to make sure players can't hurt themselves with their own honey blast. The ~= character part ensures the character hit is different from the character stored in the character variable.
                </li>

                <li>
                The script checks two conditions: if hitHumanoid is not nil (meaning the hit object is a character) and if the character hit is not the one who fired the blast. Only if both are true will the script proceed.
                </li>
            </ul>

            <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 35: Applying Damage and Destroying the Blast 🧨</h2>
            <ul>
                <li>
                If the hit object is a character other than the shooter, we apply damage to the humanoid and then destroy the honey blast object to make it disappear.
                </li>

                <li>
                hitHumanoid:TakeDamage(10) applies a specified amount of damage to the Humanoid object. In this case, we're inflicting 10 damage points.
                </li>

                <li>
                honey:Destroy() removes the honey blast from the game world, ensuring that it doesn't persist indefinitely after making an impact.
                </li>

                <li>
                This ensures that when the honey blast hits a target, it has a tangible effect by reducing the target's health. After doing its job, the honey blast is cleaned up to maintain game performance and realism.
                </li>
            </ul>

            <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 36: Firing the fireBeeBlaster Function 💥🧨</h2>
            <ul>
                <li>
                    Write the following line of code to call the fireBeeBlaster function when the "tool" is activated. In other words, when the player clicks the screen to fire the blaster while they are holding it this line of code will detect that and call the function.
                </li>
            </ul>

            <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 37: Bee Body Creation 🐝</h2>
            <ul>
                <li>
                    We are going to delve into designing the angry bee(s) that will attack the player who is trying to navigate through the maze to take all the honey.
                </li>

                <li>
                    To make the bee, start with the "body". Insert a part, make it yellow, and rename the part to "body".
                </li>
            </ul>

            <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 38: Bee Creation 🐝🐝</h2>
            <ul>
                <li>
                    Design the bee as you see in the image below. Use different shaped parts to make the body, head, stripes, eyes, and ears. Don't forget that you can use the select, scale, move, and rotate tools to aid you in this process.
                </li>
            </ul>

            <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 39: Making the Model 📦</h2>
            <ul>
                <li>
                    Add a "model" into the workspace, this will later contain all of the parts of the bee.
                </li>
            </ul>

            <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 40: Tidying up the Bee 🎁</h2>
            <ul>
                <li>
                    After you have added your "Model" into the workspace, rename is to "bee". As this will contain all of the parts of the bee.
                </li>

                <li>
                    Then drag and drop all of the parts of the bee into the model that is named "bee". Use the image below as a reference if you have any confusion on this step.
                </li>
            </ul>

            <img src={forty} className='demo__image' alt='img_forty' />
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 41: Adding the Hitbox 📦</h2>
            <ul>
                <li>
                    What is a hitbox you ask? A hitbox is a defined area (with a part) that surrounds an object.
                </li>

                <li>
                    We are adding a hitbox to the bee so that when the bee rams into a player (stings them) the hitbox will detect this and execute a "sting" script.
                </li>

                <li>
                    To add a hitbox, add a part to the workspace, surround the bee with it, and rename the part to "Hitbox".
                </li>
            </ul>

            <img src={fortyOne} className='demo__image' alt='img_fortyOne' />
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 42: Invisible Hitbox 🫥</h2>
            <ul>
                <li>
                    Move the "Hitbox" into the "bee" model to make it part of the bee.
                </li>

                <li>
                    Then find the properties of the "Hitbox" and set the "Transparency" property to "1" in order to make the "Hitbox" invisible.
                </li>
            </ul>

            <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 43: Begin Welding 🔗</h2>
            <ul>
                <li>
                    Right now if we play this game, all of the parts of the bee would fall onto the ground.... giving us a pile of messy black and yellow parts.
                </li>

                <li>
                    To prevent this we need to weld all of the parts to the "body", the focal point of the bee.
                </li>

                <li>
                    Start this process by adding a "WeldConstraint" into the "Hitbox".
                </li>

                <li>
                    Set the "Part0" property of the "WeldConstraint" to "Hitbox" and "Part1" to "body".
                </li>

                <li>
                    "Hitbox" is what we are welding and "Body" is what we are welding it to!
                </li>
            </ul>

            <img src={fortyThree} className='demo__image' alt='img_fortyThree' />
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 44: Continue Welding 🔗🔗</h2>
            <ul>
                <li>
                    Now just as we welded the "Hitbox" to the "body" weld the "Part" to the "body".
                </li>

                <li>
                    Add a "WeldConstraint" to "Part" and set "Part0" to "Part" and "Part1" to "body".
                </li>
            </ul>

            <img src={fortyFour} className='demo__image' alt='img_fortyFour' />
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 45: Finish Welding 🔗🔗🔗</h2>
            <ul>
                <li>
                    Finish welding the bee by adding a "WeldConstraint" to all of the parts in the model and welding all of them to the "body". This implies adding a "WeldConstraint" to everything EXCEPT the "body".
                </li>
            </ul>

            <img src={fortyFive} className='demo__image' alt='img_fortyFive' />
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 46: Initializing the Bee Script 🐝📜</h2>
            <ul>
                <li>
                We are adding a script to one of the bee model's parts, specifically the "body" part. This script will govern the bee's behavior.
                </li>
            </ul>

            <img src={fortySix} className='demo__image' alt='img_fortySix' />
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 47: Referencing the Body Part 📜</h2>
            <ul>
                <li>
                The script will use its parent (the "body" part of the bee model) as a reference point for actions or properties that need to be applied to the bee.
                </li>

                <li>
                script.Parent references the parent object of the script, which is the "body" part of the bee model in the game. By defining the part variable, we set a reference to the body of the bee. This is like saying, "This script is specifically for controlling this part of the bee."
                </li>
            </ul>

            <img src={fortySeven} className='demo__image' alt='img_fortySeven' />
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 48: Setting Up Variables for Movement 🏃‍♂️💨</h2>
            <ul>
                <li>
                To make the bee move, we need to establish some variables that will be used to define its movement, such as speed.
                </li>

                <li>
                local target will later hold a reference to where the bee is supposed to move toward.
                </li>

                <li>
                local target creates a placeholder variable for the target location.
                </li>

                <li>
                local speed = 50 initializes a variable named speed with a value of 50, setting the bee's movement speed.
                </li>
            </ul>

            <img src={fortyEight} className='demo__image' alt='img_fortyEight' />
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 49: Finding the BodyVelocity Component 🔄</h2>
            <ul>
                <li>
                We need to find or create a BodyVelocity object for the bee's body part to control its movement.
                </li>

                <li>
                BodyVelocity is used to apply continuous force to the part, making it move. By retrieving it, we can manipulate the bee's movement.
                </li>

                <li>
                local bodyVelocity is a new variable that will store the BodyVelocity object.
                </li>

                <li>
                part:FindFirstChild("BodyVelocity") searches the part for a child named "BodyVelocity". If it exists, it will be stored in the bodyVelocity variable.
                </li>
            </ul>

            <img src={fortyNine} className='demo__image' alt='img_fortyNine' />
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 50: Ensuring BodyVelocity Exists 🔍</h2>
            <ul>
                <li>
                If the BodyVelocity object doesn't exist on the bee's body, we must create it to allow for controlled movement.
                </li>

                <li>
                This ensures the bee has the necessary component to move in a specified direction at a set speed.
                </li>

                <li>
                if not bodyVelocity then checks if bodyVelocity is nil (does not exist).
                </li>

                <li>
                Instance.new("BodyVelocity", part) creates a new BodyVelocity object and parents it to the part.
                </li>

                <li>
                If the bee's body part doesn't have a BodyVelocity object, we create one so the bee can fly. It’s like adding an engine to a car that didn’t have one.
                </li>
            </ul>

            <img src={fifty} className='demo__image' alt='img_fifty' />
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 51: Configuring the BodyGyro for Movement 🌐</h2>
            <ul>
                <li>
                In this step, the script is initializing a variable named bodyGyro by attempting to find a child named "BodyGyro" within the part that the script is attached to. The FindFirstChild method is used for this purpose. This method will search through all the children of the specified object (in this case, part) and return the first child that has the name provided in the argument. If no child is found with that name, nil is returned.            
                </li>

                <li>
                This step is important because you are setting up a reference to an existing BodyGyro if it's already a child of the part. A BodyGyro is a constraint that aligns a part with a specified orientation, which can be used to stabilize the part or orient it in a particular direction.
                </li>
            </ul>

            <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 52: Checking for BodyGyro 🌪️</h2>
            <ul>
                <li>
                The script checks if the bodyGyro variable is nil. If it is, this means that there was no "BodyGyro" object found as a child of part. Therefore, a new BodyGyro instance is created and parented to the part.
                </li>

                <li>
                Creating a BodyGyro if one does not exist is crucial for the functionality of the script. It ensures that the part will have the required BodyGyro to function as intended in the game.
                </li>
            </ul>

            <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 53: Configure Body Gyro 📝</h2>
            <ul>
                <li>
                Once the script ensures that a BodyGyro exists, it sets its properties. The P (power), D (damping), and maxTorque properties are set to specific values. The P property determines the strength of the BodyGyro, the D property affects how quickly it stops moving, and maxTorque determines the maximum amount of torque that can be applied to keep the part aligned with its goal orientation.
                </li>

                <li>
                These settings are essential to control how the BodyGyro behaves. For example, a higher P value will make the BodyGyro more forceful in aligning the part, while a higher D value will make it come to rest more quickly. Setting maxTorque to zero effectively turns off the BodyGyro, meaning it will not apply any force to the part.
                </li>
            </ul>

            <img src={fiftyThree} className='demo__image' alt='img_fiftyThree' />
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 54: Beginning the Update Function 🚀</h2>
            <ul>
                <li>
                Start by creating a new function called update. This will be the function that, when called, will update the bee's flight in your game.
                </li>

                <li>
                To define a function in Lua, you'll write function update() - this tells the game that you're starting a new block of code that can be executed whenever you call update()
                </li>
            </ul>

            <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 55: Implementing a Loop in the Update Function 🔁</h2>
            <ul>
                <li>
                Inside the update function, introduce a while loop that will run continuously. This is done by writing while wait() do.
                </li>

                <li>
                The wait() function is a pause that lets other processes run before continuing the loop. This prevents your game from freezing!
                </li>
            </ul>

            <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 56: Adding Conditionals to the Loop ➡️</h2>
            <ul>
                <li>
                Now, let's add some logic to our loop. We'll use an if statement to check if a certain condition is true.
                </li>

                <li>
                Write if target then, which means "if there is a target, then do something." Don't forget to write an else block as well - this will specify what to do if there is no target. The target, in this case is the player that the bee is locked on to.
                </li>

                <li>
                End the if block with end to close the conditional statement properly.
                </li>
            </ul>

            <img src={fiftySix} className='demo__image' alt='img_fiftySix' />
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 57: Setting Velocities Based on Target Presence ✨</h2>
            <ul>
                <li>
                Within the if target condition, start to define what should happen when a target exists.
                </li>

                <li>
                Find the target's "Head" part with local head = target.Parent:FindFirstChild("Head").
                </li>

                <li>
                If the head exists, calculate the direction and set the velocity towards the target.
                </li>

                <li>
                In the else part, set the bodyVelocity's velocity to a neutral Vector3.new(0, 0, 0), indicating no movement when there's no target.
                </li>
            </ul>

            <img src={fiftySeven} className='demo__image' alt='img_fiftySeven' />
        </div>

        <div style={currentStep == 58 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 58: Connecting the Update Function to Player Addition 🌐</h2>
            <ul>
                <li>
                After closing the update function, connect it to an event that triggers when a new player is added to the game.
                </li>

                <li>
                Inside this function, you will eventually call update() to run your update logic for each player added.
                </li>
            </ul>

            <img src={fiftyEight} className='demo__image' alt='img_fiftyEight' />
        </div>

        <div style={currentStep == 59 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 59: Connecting Character Events to Update Logic 🧠🔗</h2>
            <ul>
                <li>
                In this step, you're going to create a connection between the spawning of a player's character and the update logic you've written. This is important because your game needs to know when to start acting on a new character that enters the game world.
                </li>

                <li>
                Inside the PlayerAdded event connection, you're now setting up a new event connection for when the character of the player is added to the game. By connecting to the CharacterAdded event, you're ensuring that any necessary setup or initialization for the character can occur as soon as they join the game.
                </li>

                <li>
                The code snippet player.CharacterAdded:Connect(function(character)) is a listener that waits for the character to be added. When the character is finally added, the code within the listener will execute.
                </li>

                <li>
                CharacterAdded: This is an event that triggers when the player's character spawns.
                </li>

                <li>
                Essentially, you're setting up the groundwork to dynamically assign targets as new characters come into play, which will be essential for the update logic to function correctly. This is a common pattern in game development, where you have to react to game events (like spawning or despawning) to manage game state and behaviors.
                </li>
            </ul>
            <img src={fiftyNine} className='demo__image' alt='img_fiftyNine' />
        </div>

        <div style={currentStep == 60 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 60: Setting the Target and Initiating the Update Function 🎮⚙️</h2>
            <ul>
                <li>
                local head: This declares a local variable within the scope of the function. It means that head will only be accessible within this block of code.
                </li>

                <li>
                Next, you assign this head object to the target variable with target = head. By doing this, you're telling your script, "This is the object I want you to focus on." The target variable is used in your update function to apply the bee flying at the player (target).
                </li>

                <li>
                By writing update() at the end of the CharacterAdded connection, you're ensuring that as soon as the character's head is set as the target, your game begins to apply whatever logic you've written in update to that character.
                </li>
            </ul>

            <img src={sixty} className='demo__image' alt='img_sixty' />
        </div>

        <div style={currentStep == 61 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 61: Making the Bee Massless 🪶</h2>
            <ul>
                <li>
                    Select all of the parts in the "bee" model and make them "Massless" so the weight of the bee does not impair the flight script that we just coded.
                </li>
            </ul>

            <img src={sixtyOne} className='demo__image' alt='img_sixtyOne' />
        </div>

        <div style={currentStep == 62 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 62: Scale the Bee ⚖️</h2>
            <ul>
                <li>
                    Select the bee model and the scale tool to scale the bee down the size that you want it in the game.
                </li>
            </ul>

            <img src={sixtyTwo} className='demo__image' alt='img_sixtyTwo' />
        </div>

        <div style={currentStep == 63 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 63: Setting Up the Hitbox Script 📦</h2>
            <ul>
                <li>
                Look in the Explorer window on the right side of your Roblox Studio.
                </li>

                <li>
                Find and select the object labeled Hitbox under the bee object.
                </li>

                <li>
                Open the script.
                </li>
            </ul>
            
            <img src={sixtyThree} className='demo__image' alt='img_sixtyThree' />
        </div>

        <div style={currentStep == 64 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 64: Starting the script</h2>
            <ul>
                <li>
                Inside the script, type local hitbox = script.Parent
                </li>

                <li>
                This line creates a local variable named hitbox and assigns it the script's parent object, which is the 'Hitbox' we've selected earlier.
                </li>

                <li>
                This is an important step because it sets up a reference to the hitbox we want to interact with in our game.
                </li>
            </ul>
            <img src={sixtyFour} className='demo__image' alt='img_sixtyFour' />
        </div>

        <div style={currentStep == 65 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 65: Establishing the Bee Reference 🐝</h2>
            <ul>
                <li>
                Under the line where we defined the hitbox, type local bee = hitbox.Parent.
                </li>

                <li>
                This creates a new variable named bee that references the parent of the hitbox, which is the 'bee' object in the hierarchy.
                </li>

                <li>
                This step is crucial as it links our script directly to the bee model, allowing us to code behaviors and interactions for it.
                </li>
            </ul>
            <img src={sixtyFive} className='demo__image' alt='img_sixtyFive' />
        </div>

        <div style={currentStep == 66 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 66: Adding Sting Damage 💢</h2>
            <ul>
                <li>
                Add a new line in the script: local stingDamage = 5.
                </li>

                <li>
                This line of code introduces a new variable called stingDamage and sets its value to 5.
                </li>

                <li>
                The stingDamage variable will be used later to determine how much damage the bee's sting will do in the game.
                </li>
            </ul>

            <img src={sixtySix} className='demo__image' alt='img_sixtySix' />
        </div>

        <div style={currentStep == 67 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 67: Implementing Cooldown Timer ⏲️</h2>
            <ul>
                <li>
                We can't have our bee stinging all willy-nilly; let's add a cooldown period.
                </li>

                <li>
                Directly below the stingDamage line, add local cooldown = 2.
                </li>

                <li>
                This line sets up a cooldown variable with a value of 2, which will be used to manage the time between stings.
                </li>

                <li>
                Implementing a cooldown helps balance the gameplay and prevents the bee from being too overpowered.
                </li>
            </ul>

            <img src={sixtySeven} className='demo__image' alt='img_sixtySeven' />
        </div>

        <div style={currentStep == 68 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 68: Tracking the Last Sting Time 🕒</h2>
            <ul>
                <li>
                After setting up the basics, it's important to track when our bee last stung something. This is where the lastStingTime variable comes into play.
                </li>

                <li>
                Add the following line to your script: local lastStingTime = {}.
                </li>

                <li>
                This line creates a table called lastStingTime. In Lua, a table is a versatile data structure that can be used as an array, a dictionary, or a set. Here, we'll be using it to keep track of the last time our bee stung for each player.
                </li>

                <li>
                Using a table allows us to store multiple values associated with player identifiers, ensuring that each player has their own individual cooldown timer.
                </li>
            </ul>
            <img src={sixtyEight} className='demo__image' alt='img_sixtyEight' />
        </div>

        <div style={currentStep == 69 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 69: Setting Up the onHit Function 🎯</h2>
            <ul>
                <li>
                To make our game interactive, we need to define what happens when our hitbox collides with something.
                </li>

                <li>
                function onHit(hit) declares a new function called onHit that takes one argument, hit, which represents the object that the hitbox has touched. end is used to close the function block in Lua.
                </li>

                <li>
                This onHit function will later contain the logic for what happens when the bee's hitbox touches another object, such as applying damage to a player.
                </li>

                <li>
                Below the function, write hitbox.Touched:Connect(onHit). This line is crucial because it connects the onHit function to the Touched event of the hitbox. Whenever the hitbox is touched, the onHit function will be called with information about what was touched.
                </li>
            </ul>

            <img src={sixtyNine} className='demo__image' alt='img_sixtyNine' />
        </div>

        <div style={currentStep == 70 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 70: Identifying the Player 🔍</h2>
            <ul>
                <li>
                Now we're getting to the heart of the interaction: identifying which player has been stung.
                </li>

                <li>
                Inside the onHit function, add the line local player = game.Players:GetPlayerFromCharacter(hit.Parent).
                </li>

                <li>
                local player declares a local variable within the function scope to hold the reference to the player.
                </li>

                <li>
                game.Players is a service in Roblox that provides access to the players in the game.
                </li>

                <li>
                GetPlayerFromCharacter is a method that takes a character instance and returns the player object associated with it. Since the hit parameter represents the part that was touched, hit.Parent is typically the character model in Roblox games.
                </li>

                <li>
                By identifying the player, we can apply specific effects or damage to them, which is critical for the gameplay. This line is what bridges our hitbox's interaction with the actual game world, allowing us to affect players when they interact with the bee (they are going to get stung).
                </li>
            </ul>

            <img src={seventy} className='demo__image' alt='img_seventy' />
        </div>

        <div style={currentStep == 71 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 71: Ensuring Only Players are Affected 🚫</h2>
            <ul>
                <li>
                We want to make sure that our bee's sting only affects players and not other objects in the game.
                </li>

                <li>
                Inside the onHit function, after identifying the player, add a safeguard: if not player then return end.
                </li>

                <li>
                This line of code uses a conditional if statement to check if the player variable does not have a valid player object.
                </li>

                <li>
                If there's no player, return end immediately exits the function without executing any more code, ensuring that the subsequent logic only affects player characters.
                </li>
            </ul>

            <img src={seventyOne} className='demo__image' alt='img_seventyOne' />
        </div>

        <div style={currentStep == 72 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 72: Obtaining the Current Time ⌛</h2>
            <ul>
                <li>
                To implement a cooldown system, we need to know the current time when a hit occurs.
                </li>

                <li>
                Add the line local currentTime = tick().
                </li>

                <li>
                local currentTime creates a local variable to store the time at the moment of the hit.
                </li>

                <li>
                tick() is a Lua function that returns the current time in seconds since an arbitrary point (often the time at which the game started). It's used here to timestamp the hit event.
                </li>
            </ul>

            <img src={seventyTwo} className='demo__image' alt='img_seventyTwo' />
        </div>

        <div style={currentStep == 73 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 73: Checking the Cooldown 🔄</h2>
            <ul>
                <li>
                Next, we need to check if enough time has passed since the last time the bee stung the player.
                </li>

                <li>
                This if statement checks two things: 1. Whether there is a recorded last sting time for this player. 2. If the current time minus the last sting time is less than the cooldown period.
                </li>

                <li>
                If both conditions are met, the function returns early, meaning the sting is still on cooldown and the player will not be stung again just yet.
                </li>
            </ul>

            <img src={seventyThree} className='demo__image' alt='img_seventyThree' />
        </div>

        <div style={currentStep == 74 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 74: Finding the Humanoid 🔍</h2>
            <ul>
                <li>
                In Roblox, the Humanoid object is what controls the health, animation, and other properties of a character model.
                </li>

                <li>
                To apply damage to the player, first find their Humanoid by adding local humanoid = hit.Parent:FindFirstChild("Humanoid").
                </li>

                <li>
                hit.Parent is the character model that the hitbox collided with, and FindFirstChild("Humanoid") is a method that looks for a child named "Humanoid" in that model.
                </li>

                <li>
                Storing the result in the local variable humanoid allows us to manipulate the player's character (like applying damage) if it exists.
                </li>
            </ul>

            <img src={seventyFour} className='demo__image' alt='img_seventyFour' />
        </div>

        <div style={currentStep == 75 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 75: Applying Damage to the Humanoid 🏹</h2>
            <ul>
                <li>
                After confirming the target is a player's character and not on cooldown, we apply damage.
                </li>

                <li>
                if humanoid then: This checks if the humanoid variable successfully found a Humanoid object in the character model.
                </li>

                <li>
                humanoid:TakeDamage(stingDamage): This is a method call on the Humanoid object. TakeDamage is a function that applies damage to the humanoid. The amount of damage applied is the value stored in the stingDamage variable, which we previously set to 5.
                </li>

                <li>
                    By applying damage, we create consequences for the player being hit by the bee, adding challenge and engagement to the game.
                </li>
            </ul>

            <img src={seventyFive} className='demo__image' alt='img_seventyFive' />
        </div>

        <div style={currentStep == 76 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 76: Recording the Sting Time 🕒</h2>
            <ul>
                <li>
                After a successful sting, we need to log the time to enforce the cooldown.
                </li>

                <li>
                lastStingTime[player] accesses the lastStingTime table using the player object as a key.
                </li>

                <li>
                currentTime is the timestamp we captured earlier using the tick() function.
                </li>

                <li>
                This line sets the current time as the last sting time for the specific player, which we will check against in future hits to determine if the cooldown period has passed.
                </li>

                <li>
                This step is crucial for the cooldown mechanic to work properly. It ensures that once the player is stung, they won't be stung again until the cooldown has expired.
                </li>
            </ul>

            <img src={seventySix} className='demo__image' alt='img_seventySix' />
        </div>

        <div style={currentStep == 77 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 77: Retrieving the Blast Effect from the Blaster 🔫</h2>
            <ul>
                <li>
                In our game, players will have blasters that can fire at the bee. Let's ensure we can retrieve the blast effect when a player successfully hits the bee.
                </li>

                <li>
                local blast creates a new variable within the function scope to hold the reference to the blast effect.
                </li>

                <li>
                workspace.Blast is supposed to find an object named "Blast" in the workspace, which represents the visual effect of the blaster's shot.
                </li>
            </ul>

            <img src={seventySeven} className='demo__image' alt='img_seventySeven' />
        </div>

        <div style={currentStep == 78 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 78: Handling the Bee's Response to the Blaster Hit 🐝💔</h2>
            <ul>
                <li>
                After the bee is hit by a blast from the player's blaster, we want to simulate the bee's reaction to this event, which is the bee getting destroyed.
                </li>

                <li>
                if blast then: This line checks if there is a blast variable available, which should have been set if a blast has hit the bee.
                </li>

                <li>
                The for loop iterates over all the parts of the bee using bee:GetChildren(), which returns all child objects of the bee. The underscore (_) is used as a variable name to indicate that the loop variable is not used.
                </li>

                <li>
                part:Destroy(): Each part of the bee is destroyed. This can simulate the bee being disintegrated by the blast.
                </li>

                <li>
                The intention here is to show an immediate effect when the bee is hit by a blast. Destroying the bee parts provides a visual cue that the bee has been hit and serves as feedback to the player that their shot was successful.
                </li>
            </ul>

            <img src={seventyEight} className='demo__image' alt='img_seventyEight' />
        </div>

        <div style={currentStep == 79 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 79: Adding the Local Script 📜</h2>
            <ul>
                <li>
                In Roblox, LocalScripts are used for scripts that run on a player's device, thus only applying code to a certain player.
                </li>

                <li>
                In the Roblox Studio, navigate to the StarterPlayer section of the Explorer panel.
                </li>

                <li>
                Click on StarterPlayerScripts and choose to insert a LocalScript. This script will run locally for each player, allowing you to handle client-side features like camera control, user interface, and input.
                </li>

                <li>
                    We are making this script to put the players in "first person" mode when they join the game.
                </li>
            </ul>

            <img src={seventyNine} className='demo__image' alt='img_seventyNine' />
        </div>

        <div style={currentStep == 80 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 80: Accessing the Players Service 🕹️</h2>
            <ul>
                <li>
                Open the newly added LocalScript.
                </li>

                <li>
                Now that we have our LocalScript, we'll start by getting a reference to the Players service. This service allows us to interact with the players in the game, such as getting their data or responding to their actions.
                </li>

                <li>
                game:GetService("Players") is a method call that returns a reference to the Players service, which manages all player-related information in the game.
                </li>
            </ul>

            <img src={eighty} className='demo__image' alt='img_eighty' />
        </div>

        <div style={currentStep == 81 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            
            <h2>Step 81: Defining the Lock Function 🛑</h2>
            <ul>
                <li>
                Now that we have our LocalScript ready, let's define a function that will be responsible for locking the player's camera to the first-person perspective.
                </li>

                <li>
                Write a local function named lockFirstPerson that takes player as a parameter.
                </li>
            </ul>

            <img src={eightyOne} className='demo__image' alt='img_eightyOne' />
        </div>

        <div style={currentStep == 82 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 82: Setting Camera Mode to First Person 📷</h2>
            <ul>
                <li>
                Within this function, you'll set the camera mode for the player.
                </li>

                <li>
                This line sets the camera mode for the player to LockFirstPerson which means the player's view is locked to first-person mode, and they cannot zoom out to third-person.
                </li>
            </ul>

            <img src={eightyTwo} className='demo__image' alt='img_eightyTwo' />
        </div>

        <div style={currentStep == 83 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 83: Handling New Characters 🎭</h2>
            <ul>
                <li>
                Players might respawn or their characters might reload. We need to make sure the camera is set to first-person every time the character is added to the game.
                </li>

                <li>
                Still within the lockFirstPerson function, connect a new event handler to the CharacterAdded event of the player object. This event is fired every time the player's character is added to the game (like when respawning). The anonymous function within Connect then sets the camera mode to first-person again.
                </li>
            </ul>

            <img src={eightyThree} className='demo__image' alt='img_eightyThree' />
        </div>

        <div style={currentStep == 84 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 84: Applying the Camera Lock 🔒</h2>
            <ul>
                <li>
                This step ensures that each player's camera is locked to the first-person perspective as soon as they join the game.
                </li>

                <li>
                Loop through all the current players using a for loop and apply the lockFirstPerson function to each one. This is important because the game may have players already in the session when the script starts running.
                </li>

                <li>
                The pairs function is used to iterate over the players in the game. The underscore _ is a Lua convention for a variable that you don't plan to use (in this case, the index of the current iteration).
                </li>

                <li>
                lockFirstPerson(player) calls the function you defined earlier, setting the camera mode for each player to first-person.
                </li>
            </ul>

            <img src={eightyFour} className='demo__image' alt='img_eightyFour' />
        </div>

        <div style={currentStep == 85 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 85: Listening for New Players 🎧</h2>
            <ul>
                <li>
                Now you'll want to ensure that any new player who joins the game also has their camera locked to first-person.
                </li>

                <li>
                Connect the PlayerAdded event of the Players service to the lockFirstPerson function.
                </li>

                <li>
                This line sets up an event listener, which calls lockFirstPerson every time a new player joins, ensuring their camera mode is set correctly.
                </li>

                <li>
                By setting up these steps, your game will now consistently enforce a first-person perspective for all players, both current and new, providing a consistent experience and possibly reducing advantages that might come from third-person view exploits.
                </li>
            </ul>

            <img src={eightyFive} className='demo__image' alt='img_eightyFive' />
        </div>

        <div style={currentStep == 86 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 86: Moving the Bee Blaster ➡️</h2>
            <ul>
                <li>
                    Take the Bee Blaster that is currently in your workspace and drag and drop it into the "StarterPack" which is a folder located near the bottom of the Explorer tab. This folder holds items that players will start with when they join the game, in our case the "Bee Blaster".
                </li>
            </ul>

            <img src={eightySix} className='demo__image' alt='img_eightySix' />
        </div>

        <div style={currentStep == 87 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 87: Grassy Fields 🟩</h2>
            <ul>
                <li>
                    Now zoom out to get a bird's eye view of your base plate. Then select the "Baseplate" part in the Explorer tab and set its color to "green". For this game, we will need a grassy field.
                </li>
            </ul>

            <img src={eightySeven} className='demo__image' alt='img_eightySeven' />
        </div>

        <div style={currentStep == 88 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 88: Adding Grass 🌱</h2>
            <ul>
                <li>
                    Open the "Material Manager", located in the "Home" tab and find and select one of the grass materials for our already green baseplate.
                </li>
            </ul>

            <img src={eightyEight} className='demo__image' alt='img_eightyEight' />
        </div>

        <div style={currentStep == 89 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 89: Adding the Maze 🏃</h2>
            <ul>
                <li>
                    This game is called Maze Runner, let's add the maze!
                </li>

                <li>
                    In this step open the "toolbox" and find the right maze to your liking; think about the players when you are choosing the maze that you want to use. Will they be able to run through it? Fight the bees? Get the treasure?
                </li>
            </ul>

            <img src={eightyNine} className='demo__image' alt='img_eightyNine' />
        </div>

        <div style={currentStep == 90 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 90: Final Touches 🏁</h2>
            <ul>
                <li>
                    From a bird's eye view, you can see a final product in the image below. You should have something similar.
                </li>

                <li>
                    Using the toolbox, you could have chosen a maze model or built your own.
                </li>

                <li>
                    Don't forget to stash away some treasure at the center or end of your maze!
                </li>
            </ul>

            <img src={ninety} className='demo__image' alt='img_ninety' />
        </div>



        <div style={currentStep == 91 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Testing and Publishing Your Game🔬🌐
            </h2>
            <h2>
            Testing Your Game Locally🕹️
            </h2>
            <ul>
                <li>
                In Roblox Studio, click on the "Home" tab at the top
                </li>
                <li>
                Click the "Play" button to test your game locally
                </li>
                <li>
                You'll spawn on the starting platform and can play through your game, ensuring that each feature works as intended
                </li>
                <li>
                If you find any issues, pause the game by clicking the "Stop" button, then make the necessary adjustments in your game and test again.
                </li>
                <li>
                Testing your game locally helps identify any potential problems or bugs before sharing it with the public. This ensures a smooth gaming experience for everyone!👩‍💻🎮
                </li>
            </ul>
        </div>

        <div style={currentStep == 92 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Publishing Your Game to Roblox🚀
            </h2>
            <ul>
                <li>
                Once you're satisfied with your project, click on the "File" tab in the top left corner of Roblox Studio
                </li>
                <li>
                Select "Publish to Roblox" and choose "Create New Game" (or update an existing game if you've published it before)
                </li>
                <li>
                Fill in the required information, such as game name, description, and genre
                </li>
                <li>
                Set the game's thumbnail and icon to visually represent your game (optional)
                </li>
                <li>
                Click the "Publish" button to make your game live on the Roblox platform
                </li>
                <li>
                When you publish your game, you're sharing it with the entire Roblox community! This is your chance to showcase your creativity and see other players enjoy your adventure.🌟🌍
                </li>
            </ul>

            <h3>
            Developing a game on Roblox is no small feat, and it's a testament to your skills and passion for game development. Your commitment to learning, problem-solving, and bringing your ideas to life is truly inspiring. Building a game requires a combination of technical know-how, design expertise, and the ability to engage and entertain players, and you have clearly excelled in all these areas.<br/><br/>Not only have you demonstrated your talent in creating a game, but you have also showcased your ability to think critically, work through challenges, and persevere. These are invaluable skills that will serve you well not only in game development but also in various other areas of your life.<br/><br/>I encourage you to take pride in your achievement and to continue pursuing your passion for game development. Your dedication and talent are sure to lead to even greater successes in the future. Remember, the journey of learning and improvement is ongoing, and every project you undertake will help you grow and refine your skills.
            </h3>
        </div>


        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
        
    </div>
  )
}

export default Maze