import React, {useState} from 'react';
import one from './scratchImages/appleImages/1.png';
import two from './scratchImages/appleImages/2.png';
import three from './scratchImages/appleImages/3.png';
import four from './scratchImages/appleImages/4.png';
import five from './scratchImages/appleImages/5.png';
import six from './scratchImages/appleImages/6.png';
import seven from './scratchImages/appleImages/7.png';
import eight from './scratchImages/appleImages/8.png';
import nine from './scratchImages/appleImages/9.png';
import ten from './scratchImages/appleImages/10.png';
import eleven from './scratchImages/appleImages/11.png';
import twelve from './scratchImages/appleImages/12.png';
import thirteen from './scratchImages/appleImages/13.png';
import fourteen from './scratchImages/appleImages/14.png';
import fifteen from './scratchImages/appleImages/15.png';
import sixteen from './scratchImages/appleImages/16.png';
import seventeen from './scratchImages/appleImages/17.png';
import eighteen from './scratchImages/appleImages/18.png';
import nineteen from './scratchImages/appleImages/19.png';
import twenty from './scratchImages/appleImages/20.png';
import twentyOne from './scratchImages/appleImages/21.png';
import twentyTwo from './scratchImages/appleImages/22.png';
import twentyThree from './scratchImages/appleImages/23.png';
import twentyFour from './scratchImages/appleImages/24.png';
import twentyFive from './scratchImages/appleImages/25.png';
import twentySix from './scratchImages/appleImages/26.png';
import twentySeven from './scratchImages/appleImages/27.png';
import twentyEight from './scratchImages/appleImages/28.png';
import twentyNine from './scratchImages/appleImages/29.png';
import thirty from './scratchImages/appleImages/30.png';
import thirtyOne from './scratchImages/appleImages/31.png';
import thirtyTwo from './scratchImages/appleImages/32.png';
import thirtyThree from './scratchImages/appleImages/33.png';
import thirtyFour from './scratchImages/appleImages/34.png';
import thirtyFive from './scratchImages/appleImages/35.png';
import thirtySix from './scratchImages/appleImages/36.png';
import thirtySeven from './scratchImages/appleImages/37.png';
import thirtyEight from './scratchImages/appleImages/38.png';
import thirtyNine from './scratchImages/appleImages/39.png';
import forty from './scratchImages/appleImages/40.png';
import fortyOne from './scratchImages/appleImages/41.png';
import fortyTwo from './scratchImages/appleImages/42.png';
import fortyThree from './scratchImages/appleImages/43.png';
import fortyFour from './scratchImages/appleImages/44.png';
import fortyFive from './scratchImages/appleImages/45.png';
import fortySix from './scratchImages/appleImages/46.png';
import fortySeven from './scratchImages/appleImages/47.png';
import fortyEight from './scratchImages/appleImages/48.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import "./tutorial.css";

const AppleCatch = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 48;

  return (
    <div className='scratchGames__container'>
        <h1>
        🍎✨ "Apple Avalanche: A Scratch-tastic Catching Quest!" ✨🍎
        </h1>
        <h3 className='intro'>
        Dive into the delightful world of "Apple Avalanche: A Scratch-tastic Catching Quest!" 🍎✨ In this vibrant and interactive game, players must swiftly maneuver to catch falling apples, all while navigating challenges and surprises. Crafted with love and precision through Scratch, this game not only offers endless entertainment but also showcases the incredible power of coding and creativity. 🎮💡
        </h3>

        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>











        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎉 Step 1 🎉</h2>

          <ul>
            <li>
              To start this project navigate to the Scratch website.
            </li>

            <li>
              Scratch: <a target="_blank" href="https://scratch.mit.edu/" >https://scratch.mit.edu/</a>
            </li>

            <li>
              Select the "create" tab at the top to open a new project.
            </li>
          </ul>
          
          <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          
          <h2>🌟 Step 2 🌟</h2>

          <ul>
            <li>
              We do not need our Scratch cat friend for this "Apple Catch" game.... I mean, cats do not belong in apple catch.
            </li>

            <li>
              Delete Mr. (or Mrs.) Cat from the game screen.
            </li>

            <li>
              Select the "new sprite" button at the bottom right corner and select the "bowl".
            </li>
          </ul>
          
          <img src={two} className='demo__image' alt='img_two' />
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚀 Step 3 🚀</h2>

          <ul>
            <li>
              Let's get coding!
            </li>

            <li>
              Below are two different blocks that we need to start with.
            </li>

            <li>
              The first "when flag clicked" let's us start the code when the user clicks the "flag" to start the game.
            </li>

            <li>
              The next block: "go to X: Y:" makes our bowl start at a certain X/Y coordinate on the screen. In our case, we want the X value to say "0" and the "Y" coordinate to say "-148" this will position the bowl at the bottom center of the screen when the game starts!
            </li>
          </ul>
          
          <img src={three} className='demo__image' alt='img_three' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎈 Step 4 🎈</h2>

          <ul>
            <li>
              Next, add a "forever" block.
            </li>

            <li>
              The "forever" block looks like a hook or a "C" so that we can put other blocks inside of it.
            </li>

            <li>
              This block makes any code inside of it repeat forever and ever. We are going to use this so that the movement of the bowl repeats forever.... in other words we will forever move left when the user clicks the left key and right when the user clicks the right key. This will be in our next steps.
            </li>
          </ul>
          
          <img src={four} className='demo__image' alt='img_four' />
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🔥 Step 5 🔥</h2>

          <ul>
            <li>
              Now is a good time to test the blocks we have so far.
            </li>

            <li>
              Press the "flag" button to run the code. When you press it, your bowl should go to the bottom center of the screen if it is coded correctly.
            </li>
          </ul>
          
          
          <img src={five} className='demo__image' alt='img_five' />
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>💡 Step 6 💡</h2>

          <ul>
            <li>
              We are now going to start the code that makes it so the bowl moves to the right when the user clicks the right arrow.
            </li>

            <li>
              We need to use an "if block" for this step. The "if block" works like this: it says a statement such as "if it is raining" then does a result such as "get an umbrella". This "if block" has the requirement at the top and the result in the "curve" part. So when the statement is met we do a certain things. In our case the statement is "when the user clicks the right key" and the result is "move the bowl to the right".
            </li>

            <li>
              Start by find the "if block" and dragging it out.
            </li>

            <li>
              Then find the "key pressed" block (the blue one below) and put it into the "if block".
            </li>
          </ul>
          
          <img src={six} className='demo__image' alt='img_six' />
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍀 Step 7 🍀</h2>

          <ul>
            <li>
              Let's get moving.... now that we have the requirement "if the user pressed the right key" we need to move it to the right.
            </li>

            <li>
              We can do this with the "change X by 10" block. Which will increase the X value of the block by 10, thus moving it to the right.
            </li>
          </ul>
          
          <img src={seven} className='demo__image' alt='img_seven' />
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌈 Step 8 🌈</h2>

          <ul>
            <li>
              We currently have the steps to move the bowl to the right, but now we need to do the same for the left.
            </li>

            <li>
              Find the "if block" and complete the statement with a "key left arrow" pressed block.
            </li>
          </ul>
          
          <img src={eight} className='demo__image' alt='img_eight' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎁 Step 9 🎁</h2>

          <ul>
            <li>
              Now get the "change X by" block and place it into the "if block". We are wanting to change X by negative 10. Any negative number on the X axis will move to the item to the left.
            </li>
          </ul>
          
          <img src={nine} className='demo__image' alt='img_nine' />
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎶 Step 10 🎶</h2>

          <ul>
            <li>
              Now that we have two separate protocol (block of code) for moving to the right and moving to the left. We need to place these two sequences into the "forever" block, so now we will "forever" be able to move to the left and right when the user clicks the arrows!
            </li>
          </ul>
          
          <img src={ten} className='demo__image' alt='img_ten' />
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌺 Step 11 🌺</h2>

          <ul>
            <li>
              The bowl is done and now we need apples!
            </li>

            <li>
              Go to the "new sprite" section and add an "apple" into the workspace.
            </li>

            <li>
              Find the "size" property of the apple and change it from 100 to 70 to shrink, you know... so that they are catching size. Refer to the image below if needed. We will begin coding it shortly!
            </li>
          </ul>
          
          <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍄 Step 12 🍄</h2>

          <ul>
            <li>
              The code that you see in the image below are the first few blocks needed for the apple.
            </li>

            <li>
              "when flag clicked" to start the code and the "forever" block to contain the next blocks!
            </li>
          </ul>
          
          <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍪 Step 13 🍪</h2>

          <ul>
            <li>
              Before we add code into the "forever" block we need to create an "if statement sequence".... outlining what will happen when the apple touches the bowl or is "caught"
            </li>

            <li>
              Start this sequence with an "if block".
            </li>

            <li>
              Add a statement into the "if block" to check if it is touching the bowl.
            </li>
          </ul>
          <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐾 Step 14 🐾</h2>

          <ul>
            <li>
              Keeping track of the score is no easy task, and that is exactly what we need to do when the apple touches the bowl. Increase the score!
            </li>

            <li>
              To increase the score we need a "variable".
            </li>

            <li>
              A variable is a piece of data that contains information. In our case, the variable will contain the "score".
            </li>

            <li>
              To create a variable "navigate" to the left side of the screen and select the orange "variables" section.
            </li>

            <li>
              In this section click the "make a variable" button.
            </li>
          </ul>
          
          <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
         <h2>🌊 Step 15 🌊</h2>

          <ul>
            <li>
              After selecting "make a variable", give it a name of "score" and press "OK".
            </li>
          </ul>
          
          <img src={fifteen} className='demo__image' alt='img_fifteen' />
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍉 Step 16 🍉</h2>

          <ul>
            <li>
              Now that we have created the score variable, naturally it will start at zero... but remember if the apple touches the bowl, we want to increase it.
            </li>

            <li>
              Find the "change my variable by 1" block and put it into the "if block". 
            </li>

            <li>
            Change the dropdown of "my variable" to "score".
            </li>
          </ul>
          
          <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎭 Step 17 🎭</h2>

          <ul>
            <li>
              This "if block" will contain all of the things that should happen when the apple touches the bowl. Including but now limited to changing the score.
            </li>

            <li>
              We also want to play a sound when the apple touches the bowl... this will make our game more fun and interactive.
            </li>

            <li>
              Find the "play sound until done block" in the "sounds section" and select the "chomp" sounds; like we are eating an apple!
            </li>
          </ul>
          
          <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎨 Step 18 🎨</h2>

          <ul>
            <li>
              The next two blocks will work together to make the apple, after being touched, reappear at the top of the screen to be caught again.
            </li>

            <li>
              The first one you see below will pick a random position on the screen to send the apple and the second new block will send it to the top of the screen add the "go to random position" block and the "set y to 180"
            </li>
          </ul>
          
          <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚴 Step 19 🚴</h2>

          <ul>
            <li>
              Our process for when the apple touches the bowl is complete. When the apple touches the bowl; we will add a point, play a "chomp" sound, and put it back at the top to fall again.
            </li>

            <li>
              Now place this protocol in the "forever" block so that this repeats forever in the game!
            </li>
          </ul>
          
          <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍦 Step 20 🍦</h2>

          <ul>
            <li>
              We need to create another set of code for the apple.
            </li>

            <li>
              Imagine a very lucky person gets to play our game.... but someone else just played it and got a score of 50. How would we let the new player start with a fresh score?
            </li>

            <li>
              Well, when the new user clicks the "flag" to start the game (run the code) we should reset the score to zero!
            </li>

            <li>
              Add a "when flag clicked" block and a "set score to zero" block right underneath it. Now when the user clicks the flag the score will reset to zero.
            </li>
          </ul>
          
          <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌍 Step 21 🌍</h2>

          <ul>
            <li>
              Now we just need two new blocks on this new set to set the apple at the top of the screen when the user clicks the flag.
            </li>

            <li>
              Add the two new blocks that you see below "go to random position" and "set Y to 180".
            </li>
          </ul>
          
          <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🕺 Step 22 🕺</h2>

          <ul>
            <li>
              Thus far we have two sets of blocks, (the first) to do something when the apple touches the bowl, (the second) to start the game.... position the apple and reset the score.
            </li>

            <li>
              But we need a third, the apple needs to be told to start falling from the sky!
            </li>

            <li>
              To start this "third set" add the "when flag clicked" with a "forever". The forever will contain code to move the apple down forever so that it looks like it is falling! 
            </li>
          </ul>
          
          <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎤 Step 23 🎤</h2>

          <ul>
            <li>
              This next block "change Y by -8" makes the apple move down at a rate of 8. The "negative" makes it go down, rather than positive which would be up.
            </li>
          </ul>
          
          <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎬 Step 24 🎬</h2>

          <ul>
            <li>
              What happens if that apple reaches the bottom and does not get caught... we still need to respawn it at the top!
            </li>

            <li>
              We are going to use an "if block" to check if this happens.
            </li>

            <li>
              Add the "if block".
            </li>

            <li>
              Then find the green "less than" equation. We are going to use this to check if the "Y" position of the apple is less than "-170". If the "Y" position of the apple is less than "-170" this means it is at the bottom of the screen and we need to send it back to the top!
            </li>
          </ul>
          
          <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎮 Step 25 🎮</h2>

          <ul>
            <li>
              We have checked if the apple has hit the bottom now, send it back to the top with the two new blocks you see below.
            </li>
          </ul>
          
          <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>📚 Step 26 📚</h2>

          <ul>
            <li>
              The protocol for checking if the apple is at the bottom and resetting it is complete, now put that inside of the forever block of the third set of code to make this repeat throughout the game.
            </li>
          </ul>
          
          <img src={twentySix} className='demo__image' alt='img_twentySix' />
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚂 Step 27 🚂</h2>

          <ul>
            <li>
              Click the "flag" and test the game, if you have followed along correctly; you should be able to catch the falling apples!
            </li>
          </ul>
          
          <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🏖 Step 28 🏖</h2>

          <ul>
            <li>
              The next steps are going to be super fun, because we are going to use the apple we just created to make a super rare and challenging "Golden Apple".
            </li>

            <li>
              To start his process, look at the "apple" icon at the bottom of the screen.
            </li>

            <li>
              Right click this icon and select the "duplicate" option.
            </li>

            <li>
              We are going to duplicate the original apple so that we do not have to recreate all of the code, since the red apple and golden apple's code are very similar.
            </li>
          </ul>
          
          <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎡 Step 29 🎡</h2>

          <ul>
            <li>
              After duplicating the apple your new apple should be named "Apple2".
            </li>

            <li>
              Make sure you have "Apple2" selected, because we are going to start transforming it into the "Golden Apple".
            </li>
          </ul>
          
          <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍕 Step 30 🍕</h2>

          <ul>
            <li>
              Look for the name label that says "Apple2" and click it, re type it to say "Golden Apple".
            </li>
          </ul>
          
          <img src={thirty} className='demo__image' alt='img_thirty' />
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          
          <h2>🌜 Step 31 🌜</h2>

          <ul>
            <li>
              After re naming it, we need to start working on the re design.
            </li>

            <li>
              Look at the top left corner of the screen. You should see three tabs: code, costumes, and sound.
            </li>

            <li>
              Click on the "costumes" option. This is where we will begin the "Golden Apple" design process!
            </li>
          </ul>
          
          <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>⛱ Step 32 ⛱</h2>

          <ul>
            <li>
              Below I have circled some of the tools that I used to edit the apple to make it gold.
            </li>

            <li>
              The second tool outlined in red will let you select the center of the apple (colored part).
            </li>

            <li>
              The first tool highlighted will let you re color the apple.
            </li>

            <li>
              The arrow that you see is just pointing at what the apple should look like when you are some; best of luck!
            </li>
          </ul>
          
          <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍩 Step 33 🍩</h2>

          <ul>
            <li>
              Let's get back to the code. When you are done editing look at the top left and select the "code" tab to super blast back into the code canvas.
            </li>

            <li>
              The red highlights that you see on the canvas show the new changes.
            </li>

            <li>
              Instead of changing the score by "1", like the red apple. We are changing it by "5".
            </li>

            <li>
              The fall rate is also increased to "-12" to make it fall fast.... the harder the challenge the better the reward!
            </li>
          </ul>
          
          
          <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          
          <h2>🐝 Step 34 🐝</h2>

          <ul>
            <li>
              Our apple changes are all done!
            </li>

            <li>
              Let's add an epic background to play our game.
            </li>

            <li>
              The bottom right section of the screen has an "add background" button for you to choose an environment for the players.
            </li>
          </ul>
          
          <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎢 Step 35 🎢</h2>

          <ul>
            <li>
              <li>
                From all of the awesome options that you can play your game, choose the one that you think looks the coolest!
              </li>
            </li>
          </ul>
          
          <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍿 Step 36 🍿</h2>
          <ul>
            <li>
              After clicking the one that you want, you should be zipped back to the code where you will see the new changes!
            </li>
          </ul>
          
          <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>⭐ Step 37 ⭐</h2>

          <ul>
            <li>
              The next adventure that we will embark on together is creating a "VICTORY" message for our victorious players.
            </li>

            <li>
              We will need to create this, it does not exist in the "sprite section".
            </li>

            <li>
              Look at what I selected in the image below. 
            </li>

            <li>
              Hover the "new sprite" button (cat button) and then click the "paintbrush icon" to build your own sprite.
            </li>
          </ul>
          
          <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎠 Step 38 🎠</h2>

          <ul>
            <li>
              In the new screen that you see click the "text" button to add the text.
            </li>

            <li>
              A box will appear where you can actually type in your own custom victory message!
            </li>
          </ul>
          
          <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🦋 Step 39 🦋</h2>

          <ul>
            <li>
              Go ahead and customize your victory message.
            </li>

            <li>
              Select a color that suites your theme.
            </li>

            <li>
              Then choose the right font.
            </li>

            <li>
              You can also make the text bigger!
            </li>
          </ul>
          
          <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌲 Step 40 🌲</h2>

          <ul>
            <li>
              There is now code that we must add so that this message only appears when the time is right!
            </li>

            <li>
              Add a "when flag clicked" block to start the code when the user starts playing.
            </li>

            <li>
              Then add a "go to front layer" block. This block makes sure the message is not covered by apples. It can be found in the "looks" category!
            </li>
          </ul>          
          
          <img src={forty} className='demo__image' alt='img_forty' />
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌸 Step 41 🌸</h2>

          <ul>
            <li>
              Two new blocks are needed. 
            </li>

            <li>
              A "forever" block. And a "if block" inside of the "forever".
            </li>
          </ul>
          
          <img src={fortyOne} className='demo__image' alt='img_fortyOne' />
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🥤 Step 42 🥤</h2>

          <ul>
            <li>
              This "if block" is looking pretty empty, we need to get to work on it!
            </li>

            <li>
              Find the "or block" this is the next block we need.
            </li>
          </ul>
          
          <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>⛵ Step 43 ⛵</h2>

          <ul>
            <li>
              Add two more green blocks, one greater than block, and one equal to block.
            </li>

            <li>
              The first will check if "score" is greater than 50.
            </li>

            <li>
              The second will beck if "score" is equal to 50.
            </li>

            <li>
              Later we will check for these conditions and end the game with a victory if they are met!
            </li>
          </ul>
          
          <img src={fortyThree} className='demo__image' alt='img_fortyThree' />
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚁 Step 44 🚁</h2>

          <ul>
            <li>
              Place the "greater than" green set on the left side of the "or" and the "equal to" on the right side of the "or". 
            </li>

            <li>
              Then place the "or" into the "if block".
            </li>
            
            <li>
              Now the "if block" reads: if the score is greater than or equal to 50...... if this condition is met; the user has caught 50 apples and won the game!
            </li>
          </ul>
          
          <img src={fortyFour} className='demo__image' alt='img_fortyFour' />
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌠 Step 45 🌠</h2>

          <ul>
            <li>
              This is where we use the "show" block to display the victory message and the "stop all" block to end the game.
            </li>

            <li>
              Add these next two blocks.
            </li>
          </ul>
          
          <img src={fortyFive} className='demo__image' alt='img_fortyFive' />
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍜 Step 46 🍜</h2>

          <ul>
            <li>
              With the "victory sprite" still selected, look at the bottom section for the "eye cancel" block and click it. This will make the victory message invisible when the user starts the game.
            </li>
          </ul>
          
          <img src={fortySix} className='demo__image' alt='img_fortySix' />
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🏄 Step 47 🏄</h2>

          <ul>
            <li>
              Go ahead and press that flag to play and test your new epic apple catch adventure!
            </li>
          </ul>
          
          <img src={fortySeven} className='demo__image' alt='img_fortySeven' />
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍓 Step 48 🍓</h2>
          <ul>
            <li>
              Don't forget to name and save your game.
            </li>

            <li>
              Type and good name into the box at the top of the screen and slam that "save now" button.... we do not want to loose that precious progress!
            </li>
          </ul>
          
          <img src={fortyEight} className='demo__image' alt='img_fortyEight' />
        </div>


        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default AppleCatch