import React, { useState } from 'react'
import studio from './fullstackImages/start/studio.png';
import welcome from './fullstackImages/start/welcome.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const Start = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 2;

  return (
    <div className='development__container'>
        <h1>
        🖥️ Installing Visual Studio Code 🖥️
        </h1>

        <h3 className='intro'>
        Ready to write, debug, and conquer the digital domain with Visual Studio Code? 🚀🎨 Every line you craft can turn into a powerful application, a mesmerizing website, or even a game-changing algorithm! 🌐🔍🎩<br/><br/>Remember: The world's most epic software began with someone just like you — a blank file and a spark of inspiration. Now, it’s your turn to light up the tech universe with your brilliance! 🌌💡🛸
        </h3>








        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>




        <div style={ currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 1: Installing Visual Studio Code
            </h2>

            <ul>
                <li>
                    This tutorial is very simple, just click the following link to install VSC: <a target="_blank" href="https://code.visualstudio.com/download" >https://code.visualstudio.com/download</a>
                </li>

                <li>
                    When you navigate to the installation page, notice there are three different option to install. Select the option that you computer has. Is it a Windows? Is it a Linuix? Is it a Mac?
                </li>
            </ul>
            <img src={studio} className='demo__image' alt="img_one" />
        </div>

        <div style={ currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 2: Opening Visual Studio Code
            </h2>

            <ul>
                <li>
                    If you have properly installed Visual Studio Code, you should be able to open it.
                </li>

                <li>
                    Click on the icon for the installed Visual Studio Code package.
                </li>

                <li>
                    When the application starts and opens, you should see the screen of VSC that is similair to the image below.
                </li>
            </ul>
            <img src={welcome} className='demo__image' alt="img_one" />

            <h3>
            So, grab your keyboard, power up VS Code, and let's set the coding world on fire! 🔥💻🌟
            </h3>
        </div>

    </div>
  )
}

export default Start