import React from 'react';
import { useLocation } from 'react-router-dom';
import './robloxTutorial.css';
import Navbar from '../components/navbar/navbar';
import Start from '../tutorials/roblox/start';
import Obby from '../tutorials/roblox/obby';
import Gem from '../tutorials/roblox/gem';
import Escape from '../tutorials/roblox/escape';
import Maze from '../tutorials/roblox/maze';
import Paintball from '../tutorials/roblox/paintball';
import Cheese from '../tutorials/roblox/cheese';
import Soccer from '../tutorials/roblox/soccer';
import Heros from '../tutorials/roblox/heros';
import './robloxTutorial.css';

const RobloxTutorial = () => {
  const location = useLocation();

  return (
    <div>
        <Navbar />

        {
            location.pathname === "/roblox-guide/start" &&
            <Start />
        }

        {
            location.pathname === "/roblox-guide/obby" &&
            <Obby />
        }

        {
            location.pathname === "/roblox-guide/gem-hunter" &&
            <Gem />
        }

        {
            location.pathname === "/roblox-guide/castle-conundrum" &&
            <Escape />
        }

        {
            location.pathname === "/roblox-guide/maze-runner" &&
            <Maze />
        }

        {
            location.pathname === "/roblox-guide/paintball" &&
            <Paintball />
        }

        {
            location.pathname === "/roblox-guide/cheese-chase" &&
            <Cheese />
        }

        {
            location.pathname === "/roblox-guide/soccer" &&
            <Soccer />
        }

        {
            location.pathname === "/roblox-guide/save-the-city" &&
            <Heros />
        }
    </div>
  )
}

export default RobloxTutorial