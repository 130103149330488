import React, {useState} from 'react';
import one from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/1.png';
import two from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/2.png';
import three from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/3.png';
import four from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/4.png';
import five from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/5.png';
import six from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/6.png';
import seven from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/7.png';
import eight from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/8.png';
import nine from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/9.png';
import ten from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/10.png';
import eleven from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/11.png';
import twelve from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/12.png';
import thirteen from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/13.png';
import fourteen from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/14.png';
import fifteen from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/15.png';
import sixteen from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/16.png';
import seventeen from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/17.png';
import eighteen from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/18.png';
import nineteen from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/19.png';
import twenty from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/20.png';
import twentyOne from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/21.png';
import twentyTwo from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/22.png';
import twentyThree from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/23.png';
import twentyFour from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/24.png';
import twentyFive from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/25.png';
import twentySix from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/26.png';
import twentySeven from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/27.png';
import twentyEight from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/28.png';
import twentyNine from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/29.png';
import thirty from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/30.png';
import thirtyOne from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/31.png';
import thirtyTwo from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/32.png';
import thirtyThree from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/33.png';
import thirtyFour from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/34.png';
import thirtyFive from './fullstackImages/Fancy-Rock-Paper-Scissors-IMAGES/35.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const RockPaperScissors = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 35;

  return (
    <div className='development__container'>

        <h1>
        ✊✋✌️ Rock Paper Scissors Showdown 🕹️💥🧠
        </h1>

        <h3 className='intro'>
        Get ready to throw down in the ultimate digital duel! ✊✋✌️ In this tutorial, we're going to build a classic Rock Paper Scissors game using JavaScript, and trust me, it's going to be a blast! So, roll up your sleeves, flex those coding muscles, and let's dive into the fun world of Rock Paper Scissors—where strategy meets simplicity! 🎮🔥
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>

          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />

          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>

        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Prepare the Battle Arena ⚔️🤺</h2>

            <p>
                In your quest for glory you need to make sure that your space is neat and tidy to ensure a flawless victory. So download the the project template and make sure you have all the assets as seen in the image below!
            </p>
            <img src={one} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Download the project template and open it up in Video Studio Code.
                </li>

                <li>
                    You should have the following assets in the project: <strong>index.html</strong>, <strong>style.css</strong>, <strong>app.js</strong>, and the <strong>images</strong> folder.
                </li>
            </ol>
        </div>


        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Adding the Centerpiece 🏆</h2>

            <p>
                One of the most important parts of the <strong>index.html</strong> (if not the most important), is importing the JavaScript file. In this step, we will be importing the <strong>app.js</strong> file.
            </p>
            <img src={two} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Find a line above the closing <strong>&lt;/body&gt;</strong> tag in the <strong>index.html</strong> file and add the following code to import the <strong>app.js</strong> JavaScript file: <strong>&lt;script src="app.js"&gt;&lt;/script&gt;</strong>
                </li>
            </ol>
        </div>


        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 3: Play time! 🎮🛝</h2>

            <p>
                To play this game🎮 we are going to create a function that controls the flow of the script🌊. The function we are making in this script will setup our project for success🏆
            </p>
            <img src={three} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Navigate to the <strong>app.js</strong> file.
                </li>

                <li>
                    On the first line of the <strong>app.js</strong> file declare a new function by writting: <strong>function</strong>
                </li>

                <li>
                    Assign this function a name of <strong>playGame</strong> as this name reflects what the function will do, play the game.
                </li>

                <li>
                    When the player opens the website they will pick either: <strong>rock</strong>, <strong>paper</strong>, or <strong>scissors</strong>. This choice will be given to the function from the parameter <strong>userChoice</strong>. Create the <strong>userChoice</strong> parameter and put it into the parenthesis in the <strong>playGame</strong> function.
                </li>

                <li>
                    Don't forget to include the <strong>curly brackets</strong> that will contain the code that the function will execute.
                </li>
            </ol>
        </div>




        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: Reviewing Parameters 🔄🔄</h2>

            <p>
                Remember🧠 parameters? We looked at them in the last project... <em>LET'S HAVE A QUICK REVIEW!!</em> Parameters are defined when we call the function📞 If you examine the image below, notice how there are three buttons for the three different choices <strong>(rock, paper, scissors)</strong>; each <strong>onclick</strong> in the buttons calls the same function, the <strong>playGame</strong> function. But look very close and notice that in the parenthesis of each function call we write something different. What we write in the parenthesis of the function call is the value of <strong>userChoice</strong> when the <strong>playGame</strong> function executes👀
            </p>
            <img src={four} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Open the <strong>index.html</strong> file.
                </li>

                <li>
                    Create an <strong>onclick</strong> attribute in each choice button.
                </li>

                <li>
                    For each <strong>onclick</strong> method call the <strong>playGame</strong> function.
                </li>

                <li>
                    Define the <strong>userChoice</strong> parameter in the parenthesis of the <strong>playGame</strong> function call.
                </li>
            </ol>
        </div>



        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Reviewing Parameters 🔄🔄</h2>

            <p>
                In the <strong>index.html</strong> file there is a <strong>span</strong> tag that has an <strong>id</strong> of <strong>user-choice</strong>. This <strong>span</strong> targets🎯 where we want to display the text of what option the player choose. To do this, in the JavaScript, we will write a line of code that targets this <strong>span</strong> tag with an <strong>id</strong> of <strong>user-choice</strong> and set the text value to the <strong>userChoice</strong> parameter.
            </p>
            <img src={five} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    In the <strong>playGame</strong> function write the following line of code: <strong>document.getElementById('user-choice').textContent = userChoice</strong>
                    <ul>
                        <li>
                            The first part of this line aims at the <strong>span</strong> tag that has an <strong>id</strong> of <strong>user-choice</strong>. 
                        </li>

                        <li>
                            The second part of this line sets the <strong>textContent</strong> equal to the <strong>userChoice</strong> parameter.
                        </li>
                    </ul>
                </li>
            </ol>
        </div>



        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Go To the Span Tag 🟢🏷️</h2>

            <p>
                Let's examine what we just did, we just wrote the following line of code: <strong><strong>document.getElementById('user-choice').textContent = userChoice</strong></strong> and if you investigate🔍 the <strong>index.html</strong> file you will find the following line of HTML: <strong>&lt;h2&gt;Your Choice: &lt;span id="user-choice"&gt;&lt;/span&gt;&lt;/h2&gt;</strong>. That line of HTML is what is targeted from the JavaScript in order to set the text of the <strong>span</strong> tag equal to the <strong>userChoice</strong>. Great work so far, let us move onward👉
            </p>
            <img src={six} className='demo__image' alt='img_one' />
        </div>



        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Generate the Computer's Choice 💻🎲</h2>

            <p>
                So now that we have displayed the user's option out of <strong>rock</strong>, <strong>paper</strong>, or <strong>scissors</strong> we need to get the option that the computer chooses! The computer will be the player's oppenent🤺 and to make it fair we will later use a random algorithm to generate the computer's choice🎲
            </p>
            <img src={seven} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                To get the computer's choice we are going to make a variable for it and set the variable's value equal to calling📞 a function that will generate the computer's choice.
                </li>

                <li>
                Start by initializing the variable with <strong>const computerChoice</strong>
                </li>

                <li>
                    Then call the function that will generate the computer's choice by writting the functions name: <strong>generateComputerChoice()</strong>
                </li>

                <li>
                    It is very important to note📝 that we have not created this function yet. In the next step, we will create this function in the next step.
                </li>
            </ol>
        </div>




        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Cook Up The Function 🥘</h2>

            <p>
                Since we called the function that does not even exist yet we need to make it. Just like when you order food at a restaurant, you need to make it! So now, let's cook up this function. <em>YUM!!!</em>🍳
            </p>
            <img src={eight} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Exit the <strong>playGame</strong> function by finding a line to type on that is after the closing curly bracket of the <strong>playGame</strong> function.
                </li>

                <li>
                    Declare the function and give it the name <strong>generateComputerChoice</strong>.
                </li>

                <li>
                    Look at the image above and ensure that the function you are making is spelled the same way as when you called the function in the last step.
                </li>
            </ol>
        </div>


        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Give the Computer Some Choices 💭</h2>

            <p>
                If this function is going to generate the choice for the computer, we need to give the computer those choices. In this step, we will dive🥽 into arrays and learn how we can give the computer its choices🕹️
            </p>
            <img src={nine} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    The choices that the computer will get are the same as the player: <strong>rock</strong>, <strong>paper</strong>, or <strong>scissors</strong>.
                </li>

                <li>
                    We need to contain these values in an array. Remember an array is just a set of square brackets containing multiple values.
                </li>

                <li>
                    Declare the array with <strong>const</strong>
                </li>

                <li>
                    Give the array a name of <strong>choices</strong>
                </li>

                <li>
                    Set the value of the array to the following: <strong>['rock', 'paper', 'scissors']</strong>
                </li>
            </ol>
        </div>



        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Roll the Dice 🎲💨</h2>

            <p>
                To get the computer's random choice we are going to use the <strong>Math.random</strong> JavaScript method that will get a random number between 1 and 3 and that number will correspond to the choices in the array. But remember JavaScript arrays start counting at zero so <strong>rock</strong> is <strong>0</strong>, <strong>paper</strong> is <strong>1</strong>, and <strong>scissors</strong> is <strong>2</strong>.
            </p>
            <img src={ten} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Create the variable that will be equal to the random number by declaring it with <strong>const</strong> and naming it <strong>randomNumber</strong>.
                </li>

                <li>
                    Set this new <strong>randomNumber</strong> variable equal to the following code that will genereate the random number between 0 and 2: <strong>Math.floor(Math.random() * 3)</strong>
                </li>
            </ol>
        </div>



        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: Match the Number 🃏</h2>

            <p>
                Getting the random number was easy because we let <strong>Math.random</strong> do all of the heavy lifting🏋🏼 In this step📶, we will focus on getting one of the options: <strong>rock</strong>, <strong>paper</strong>, and <strong>scissors</strong> based on the corresponding number; this is called <em>INDEXING!!</em>
            </p>
            <img src={eleven} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Start by creating the variable <strong>choosenItem</strong> and we will make it equal to the choice that corresponds to the <strong>randomNumber</strong> variable.
                </li>

                <li>
                    Set the value of the <strong>choosenItem</strong> variable equal to the following code: <strong>choices[randomNumber]</strong>
                    <ul>
                        <li>
                            This will set <strong>choosenItem</strong> equal to the value in the array that corresponds with the randomly choosen number.
                        </li>
                    </ul>
                </li>
            </ol>
        </div>


        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Return the Choice 💳</h2>

            <p>
                Now that we have the <strong>choosenItem</strong> variable we know what choice the computer has made! How exciting🤩 but now it is time... <em>TO RETURN IT</em>. Remember when we called this function with the following line of code: <strong>const computerChoice = generateComputerChoice()</strong>. When we write <strong>return choosenItem</strong> we are setting the value of the <strong>computerChoice</strong> variable equal to <strong>choosenItem</strong>. Also keep in mind when we return something in a function <em>returning</em> is always done at the end of the function.
            </p>
            <img src={twelve} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    At the bottom of the <strong>generateComputerChoice</strong> function write the following code: <strong>return choosenItem</strong>
                </li>
            </ol>
        </div>


        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: Reassesment 🧪</h2>

            <p>
                Take a look at the <strong>playGame</strong> function🎮. The last line of code we wrote in there is <strong>const computerChoice = generateComputerChoice()</strong>, the <strong>computerChoice</strong> variable calls the <strong>generateComputerChoice</strong> function, but the value of the variable is what the <strong>generateComputerChoice</strong> function <strong>returns</strong>.
            </p>
            <img src={thirteen} className='demo__image' alt='img_one' />
            
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: Computer's Announcement 🔊</h2>

            <p>
                The <strong>computerChoice</strong> variable finally has a value😅. Let's announce to everyone what the computer randomly choose🎲. To accomplish this we will target a <strong>span</strong> tag that has an <strong>id</strong> of <strong>computer-choice</strong> in the HTML file.
            </p>
            <img src={fourteen} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Write the following line of code to single out the HTML element in the HTML file that we will use to display the computer's choice: <strong>document.getElementById('computer-choice').textContent</strong>
                </li>

                <li>
                    Set that HTML element equal to the computer's choice with: <strong>= computerChoice</strong> this will display the computer's choice onto the screen!
                </li>
            </ol>
        </div>

        
        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15: Where does the computer display this? 🫤</h2>

            <p>
                We need to review how the <strong>document.getElementById('computer-choice').textContent = computerChoice</strong> works. In the HTML you have the <strong>h2</strong> tag that is highlighted in the image below. In the <strong>h2</strong> tag there is a <strong>span</strong> tag. The <strong>span</strong> tag has an <strong>id</strong> of <strong>computer-choice</strong>. The JavaScript <strong>document.getElementById('computer-choice').textContent = computerChoice</strong> is targeting this HTML and setting it equal to the value of the <strong>computerChoice</strong> variable.
            </p>
            <img src={fifteen} className='demo__image' alt='img_one' />
        </div>


        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 16: Paint a Picture 🖼️</h2>

            <p>
                The function will now display the text for the user's choice and the computer's choice. <em>HOORAYYY</em>🥳. But don't you want it to be more interactive? Add some fun visuals? In this step we will start the process for displaying the corresponding images for the choices made; <strong>rock</strong>, <strong>paper</strong>, or <strong>scissors</strong>.
            </p>
            <img src={sixteen} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                After setting the computer choice text in the <strong>playGame</strong> function call📞 a function named <strong>updateImages</strong>.
                </li>
                
                <li>
                    Write the following line of code: <strong>updateImages(userChoice, computerChoice)</strong>
                </li>

                <li>
                    This will run the <strong>updateImages</strong> function and give it two <strong>parameters</strong> (userChoice and computerChoice)
                </li>

                <li>
                    We will create this function in the next step.
                </li>
            </ol>
        </div>



        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 17: Update Images 🚡</h2>

            <p>
                To actually update the images we need to create the function that we just called📞. After all we can't call a function if it doesn't even exist!
            </p>
            <img src={seventeen} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Declare the function by writting <strong>function</strong>
                </li>

                <li>
                    Give the function a name of <strong>updateImages</strong>, as you just called it with that name!
                </li>

                <li>
                    Add the <strong>userChoice</strong> and <strong>computerChoice</strong> parameters to the function, since these variables were given to the function back when we called📞 it!
                </li>
            </ol>
        </div>



        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 18: Find the Image 🔍</h2>

            <p>
                To set the image to the image of the user's chocie we need to create a variable that is equal to the location of the selected image file.
            </p>
            <img src={eighteen} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Create a variable named <strong>userImagePath</strong>
                </li>

                <li>
                    Set the variable equal to a set of back ticks: <strong>``</strong>
                </li>

                <li>
                    We always use back ticks when we are about to combine a string value with a variable (these are different from the quotation marks), they should be the key next to the number 1 key on your keyboard!
                </li>

                <li>
                    Inside of the back ticks write the following code: <strong>./images/$&#123;userChoice&#125;.png</strong>
                    <ul>
                        <li>
                            This is a file path, it points to the image file that you want.
                        </li>

                        <li>
                            <strong>./images</strong> points to the images folder and <strong>$&#123;userChoice&#125;.png</strong> is the image file based on the value of the <strong>userChoice</strong> value.
                        </li>
                    </ul>
                </li>
            </ol>
        </div>


        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 19: Find the Computer Image 🔍🖥️</h2>

            <p>
                This step works just like the previous step, in that we will be finding the image to display for the computer's choice based on the <strong>conputerChoice</strong> variable and the file path to the image.
            </p>
            <img src={nineteen} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Create the <strong>computerImagePath</strong> variable.
                </li>

                <li>
                    Set the value of it to the following: <strong>./images/$&#123;computerChoice&#125;.png</strong>
                </li>
            </ol>
        </div>



        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 20: Frame the Painting 🖼️</h2>

            <p>
                Just how we can set the text of an HTML element of the JavaScript we can also set the <strong>src</strong> of an image tag from the JavaScript. The <strong>src</strong> of an image is the "source" and refers to the filepath of the image that we want to display🖼️
            </p>
            <img src={twenty} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    The <strong>img</strong> tag in the HTML file that we want to display the image in is the player's image and has a class of <strong>player-image</strong>
                </li>

                <li>
                    Target this image tag with the following line of code: <strong>document.querySelector('.player-image').src</strong>
                </li>

                <li>
                    And set it to the user's image by making it equal to the <strong>userImagePath</strong> variable: <strong>= userImagePath</strong>
                </li>
            </ol>
        </div>



        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 21: Admiring your Work 🤩</h2>

            <p>
                Lets take a look👀 at the <strong>img</strong> tag that we just set.
            </p>
            <img src={twentyOne} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    In the <strong>index.html</strong> file we have the following <strong>img</strong> tag: <strong>&lt;img class="player-image" src="./images/nothing.png" /&gt;</strong>
                </li>

                <li>
                    In the JavaScript we target this <strong>img</strong> tag by the class of <strong>player-image</strong>.
                </li>

                <li>
                    Look at the <strong>src</strong> in the image above. By default it is set to <strong>nothing.png</strong> but when we wrote the JavaScript in the last step it switches from <strong>nothing.png</strong> to the image of the user's choice.
                </li>
            </ol>
        </div>




        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 22: The Computer Needs An Image Too 💻📸</h2>

            <p>
                Just like we display the player's choosen image, we need to display the computer's image too. This will create a fun 1 v. 1 battle where both images are face-to-face ready to engage in battle⚔️
            </p>
            <img src={twentyTwo} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    The <strong>img</strong> tag in the HTML file has a class of <strong>computer-image</strong>, with that in mind, write the following JavaScript to target this tag: <strong>document.querySelector('.computer-image').src</strong>
                </li>

                <li>
                    And then set the <strong>src</strong> of the image to the <strong>computerImagePath</strong> variable that is equal to the image file that we want to display.
                </li>
            </ol>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 23: Looking at the Computer 💻</h2>

            <p>
                If you want to take a quick review, then examine the image below🔍. This image tag has the class of <strong>computer-image</strong> which is how we target the tag from the JavaScript. Also note📝 that the default image is the <strong>nothing.png</strong> image... <em>that is until we change it!</em>
            </p>
            <img src={twentyThree} className='demo__image' alt='img_one' />
        </div>


        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 24: Get the Winner 🏆</h2>

            <p>
                Every battle has a winner... <em>unless it's a tie</em>🪢 We need to announce the winner and to do this there are many steps we will take📶. The first step is to call the function that will get the result. This function will take in the two choices and figure out which one wins. Let's start by calling📞 the function.
            </p>
            <img src={twentyFour} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                Create a variable named <strong>result</strong> that will be equal to the player either winning or losing.
                </li>

                <li>
                    Set the variable equal to calling the <strong>getResult</strong> function. We will make this function in the next step.
                </li>

                <li>
                    By now you should have written the following line of code: <strong>const result = getResult(userChoice, computerChoice)</strong>
                </li>

                <li>
                    Writting the <strong>userChoice</strong> variable and the <strong>computerChoice</strong> variable in the parenthesis of the function call is providing the function the variables to take them and analyze the winner🏆
                </li>
            </ol>
        </div>


        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 25: Analyze the Result 📊</h2>

            <p>
                Make the <strong>getResult</strong> function in this step. This function is pretty cool😎 because it is going to tell us if the computer won or the player won.
            </p>
            <img src={twentyFive} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Declare the function with the word <strong>function</strong>
                </li>

                <li>
                    Name the function <strong>getResult</strong>
                </li>

                <li>
                    Pass in the two parameters both <strong>userChoice</strong> and <strong>computerChoice</strong>.
                </li>

                <li>
                    Remember to include the parenthesis and curly brackets.
                </li>
            </ol>
        </div>


        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 26: Is it a draw? 🖊️</h2>

            <p>
                Before we try to figure out who won the game of <em>ROCK, PAPER, SCISSORS!!!</em> Let's see if it was a tie. Remember🧠 when we want to check if something is true or false we use an <strong>if statement</strong>.
            </p>
            <img src={twentySix} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Create the <strong>if statement</strong> and in the parenthesis of the <strong>if statement</strong>, write the equation we are evaluating to either <strong>true</strong> or <strong>false</strong>. 
                </li>

                <li>
                    The equation that will go in the parenthesis is <strong>userChoice === computerChoice</strong> and if this is <strong>true</strong> that means that it is a tie and we will run the following code: <strong>return "It's a draw!"</strong>
                </li>
            </ol>
        </div>


        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 27: Everything Else 🌌</h2>

            <p>
                If it is not a tie then we need to figure out who won🏆. To do this, let's add an else statement that will contain the code that runs if it is not a tie.
            </p>
            <img src={twentySeven} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    An <strong>else</strong> statement runs code if the previous <strong>if statement</strong> evalutates to false. Think like this: <em>the if statement was false so we are going to run the code in the else statement instead...</em> It is kind of like a last resort.
                </li>
                
                <li>
                    Write the following code: <strong>else</strong> followed by a set of curly brakets
                </li>

                <li>
                    Inside of the curly brackets we will write the code that determines who won, since we now know that it is not a tie.
                </li>
            </ol>
        </div>


        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 28: You Rock! 🪨</h2>

            <p>
                The first option that the user could have picked is rock. In this step, we will check if the user picked rock and if they picked rock we will then pair it up against what the computer picked to analyze the outcome if the user's choice was rock🪨
            </p>
            <img src={twentyEight} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Inside of the <strong>else</strong> statement, write an <strong>if statement</strong> that checks if the <strong>userChoice</strong> variable is rock.
                </li>

                <li>
                    Start by typing <strong>if</strong> followed by a set of parenthesis.
                </li>

                <li>
                    Inside of the parenthesis type the following equation that will be evaluated to either true or false: <strong>userChoice === 'rock'</strong>
                </li>

                <li>
                    Don't forget the curly brackets at the end of the <strong>if statement</strong> that will run the code if the user's choice was in fact rock.
                </li>
            </ol>
        </div>

        
        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 29: Analyze the Rock 📈🎸</h2>

            <p>
                So now what? The user choose rock, we need to figure out what the computer choose and go from there! Inside of the <strong>if statement</strong> that checks if the user's choice was rock, you are going to check if the computer's choice was scissors✂️ and if the computer's choice was scissors then that means the player wins but if it is not scissors that means the computer's choice was paper and the player looses. <em>OH NO! THE TRAGEDY!!</em>
            </p>
            <img src={twentyNine} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                In the <strong>if statement</strong> that checks if the player's choice was rock, create a new <strong>if statement</strong> that checks if the computer's choice was scissors.
                </li>

                <li>
                    If the computer's choice is scissors then the player wins because they choose rock but if the computer's choice was not scissors there is only one other option, and that is paper. But notice how there is nothing chacking if the computer's choice is paper; that is because we use an <strong>else</strong> statement as it is the only other option. Follow the code in the image above to complete this <strong>conditional chain</strong>.
                </li>
            </ol>
        </div>


        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 30: User Choosing Paper 📜</h2>

            <p>
                The previous <strong>conditional chain</strong> starts by checking if the player picked rock🪨 and then analyzes it to the computer. At the end of the <strong>if statement</strong> checking the user's choice for rock, add an <strong>else if</strong> statement to check if the user choose paper. Which implies that we will need to check the user's choice of paper📰 agianst what the computer choose.
            </p>
            <img src={thirty} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Add an <strong>else if</strong> statement at the end of the previous <strong>if statement</strong> as seen in the image above (follow the yellow highlight to see the start and end points).
                </li>

                <li>
                    In the parenthesis of the <strong>else if</strong> statement check if the user choose paper.
                </li>

                <li>
                    In the curly brackets of the <strong>else if</strong> statement check if the computer choose rock and evalute the result.
                </li>
            </ol>
        </div>


        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 31: User Choosing Scissors ✂️</h2>

            <p>
                If the player is getting a bit agressive with their attacks⚔️ and chooses scissors✂️ then we need to analyze the result just like we did for the other two options!
            </p>
            <img src={thirtyOne} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                Take a gander at the image above and try to follow the flow🌊 of the conditionals. The first <strong>if statement</strong> checks if the user choose rock (then analyzes the result), then the <strong>else if statement</strong> checks if the user choose paper (then analyzes the result), and next we will add the next <strong>else if statement</strong> to see if the user choose scissors and analyze the result from there.
                </li>

                <li>
                    Create the <strong>else if statement</strong> that checks if the user choose scissors with the following equation: <strong>userChoice === 'scissors'</strong>
                </li>

                <li>
                    Within the new <strong>else if statement</strong> analyze the result of the computer's choice.
                </li>
            </ol>
        </div>


        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 32: Bird's Eye View 🦅👁️</h2>

            <p>
                Zoom out and scan the whole function👀 The function shoudld match the image down below, take a deep breath and compare your work to the picture to prevent any bugs in your code🪲
            </p>
            <img src={thirtyTwo} className='demo__image' alt='img_one' />
        </div>


        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 33: Back to the Beginning 🏫</h2>

            <p>
                Okay this step is called <em>"back to the beginning"</em>, but don't fret☹️, because we aren't starting over. Just go back to your <strong>playGame</strong> function and take a look at the following line of code: <strong>const result = getResult(userChoice, computerChoice)</strong>. While the <strong>result</strong> variable is equal to calling📞 the <strong>getResult</strong> function, what it is really equal to is what the <strong>getResult</strong> function returns (either <strong>you win</strong> or <strong>you loose</strong>). <em>OKAY LET'S KEEP CODING!!</em>
            </p>
            <img src={thirtyThree} className='demo__image' alt='img_one' />
        </div>


        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 34: Display the Result ☀️</h2>

            <p>
                We have the result of the <strong>rock, paper, scissors!!!</strong> Stored in the <strong>result</strong> variable. It is finally time to display📱 the value of the <strong>result</strong> variable to the HTML file and put it up on the screen for all to see🖼️
            </p>
            <img src={thirtyFour} className='demo__image' alt='img_one' />

            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Start by targeting the HTML tag that we want to put the text into.
                </li>

                <li>
                    This HTML tag has a <strong>id</strong> of <strong>result</strong>, so write the following line of code to target it: <strong>document.getElementById('result').textContent</strong>
                </li>

                <li>
                    And then, set the value of the text equal to the <strong>result</strong> variable.
                </li>
            </ol>
        </div>


        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 35: Look At the Result 🎂</h2>

            <p>
                Want to look👀 at the HTML tag that we just targeted?🎯 Navigate into the <strong>index.html</strong> file and find the <strong>h2</strong> tag containing the <strong>span</strong> tag with a class of <strong>result</strong>. This is where we just displayed the text from the JavaScript in the previous step🔙.<br/><br/>Congradulations🏅 on making it this far, you are becoming quite the prodigy🏆
            </p>
            <img src={thirtyFive} className='demo__image' alt='img_one' />
        </div>



        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default RockPaperScissors