import React, {useState} from 'react';
import one from './pythonImages/pongImages/1.png';
import two from './pythonImages/pongImages/2.png';
import three from './pythonImages/pongImages/3.png';
import four from './pythonImages/pongImages/4.png';
import five from './pythonImages/pongImages/5.png';
import six from './pythonImages/pongImages/6.png';
import seven from './pythonImages/pongImages/7.png';
import eight from './pythonImages/pongImages/8.png';
import nine from './pythonImages/pongImages/9.png';
import ten from './pythonImages/pongImages/10.png';
import eleven from './pythonImages/pongImages/11.png';
import twelve from './pythonImages/pongImages/12.png';
import thirteen from './pythonImages/pongImages/13.png';
import fourteen from './pythonImages/pongImages/14.png';
import fifteen from './pythonImages/pongImages/15.png';
import sixteen from './pythonImages/pongImages/16.png';
import seventeen from './pythonImages/pongImages/17.png';
import eighteen from './pythonImages/pongImages/18.png';
import nineteen from './pythonImages/pongImages/19.png';
import twenty from './pythonImages/pongImages/20.png';
import twentyOne from './pythonImages/pongImages/21.png';
import twentyTwo from './pythonImages/pongImages/22.png';
import twentyThree from './pythonImages/pongImages/23.png';
import twentyFour from './pythonImages/pongImages/24.png';
import twentyFive from './pythonImages/pongImages/25.png';
import twentySix from './pythonImages/pongImages/26.png';
import twentySeven from './pythonImages/pongImages/27.png';
import twentyEight from './pythonImages/pongImages/28.png';
import twentyNine from './pythonImages/pongImages/29.png';
import thirty from './pythonImages/pongImages/30.png';
import thirtyOne from './pythonImages/pongImages/31.png';
import thirtyTwo from './pythonImages/pongImages/32.png';
import thirtyThree from './pythonImages/pongImages/33.png';
import thirtyFour from './pythonImages/pongImages/34.png';
import thirtyFive from './pythonImages/pongImages/35.png';
import thirtySix from './pythonImages/pongImages/36.png';
import thirtySeven from './pythonImages/pongImages/37.png';
import thirtyEight from './pythonImages/pongImages/38.png';
import thirtyNine from './pythonImages/pongImages/39.png';
import forty from './pythonImages/pongImages/40.png';
import fortyOne from './pythonImages/pongImages/41.png';
import fortyTwo from './pythonImages/pongImages/42.png';
import fortyThree from './pythonImages/pongImages/43.png';
import fortyFour from './pythonImages/pongImages/44.png';
import fortyFive from './pythonImages/pongImages/45.png';
import fortySix from './pythonImages/pongImages/46.png';
import fortySeven from './pythonImages/pongImages/47.png';
import fortyEight from './pythonImages/pongImages/48.png';
import fortyNine from './pythonImages/pongImages/49.png';
import fifty from './pythonImages/pongImages/50.png';
import fiftyOne from './pythonImages/pongImages/51.png';
import fiftyTwo from './pythonImages/pongImages/52.png';
import fiftyThree from './pythonImages/pongImages/53.png';
import fiftyFour from './pythonImages/pongImages/54.png';
import fiftyFive from './pythonImages/pongImages/55.png';
import fiftySix from './pythonImages/pongImages/56.png';
import fiftySeven from './pythonImages/pongImages/57.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import './tutorial.css';

const Pong = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 53;


  return (
    <div className='pythonGames__container'>
        <h1>
        🏓Let's Create a Pong Game with Python and Pygame!🏓
        </h1>

        <h3 className='intro'>
        Welcome to this exciting tutorial on creating your very own Pong game! We'll be using Python and the Pygame library to bring our game to life. Follow along, and you'll have your own Pong game in no time! 🎮✨
        </h3>



        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>



        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Laying down the foundation 🧱</h2>

            <p>
                Every project needs a place to live! In step 1, we need to create and open a folder to house our project; then, create a file named "pong" and give it an extensions of ".py".<br/><br/>".py" let's our computer know that the code we will be writing in this file is Python code!
            </p>
            <img src={one} className='demo__image' alt='img_one' />
        </div>
        
        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 2: Gearing Up for a Western Showdown! 🤠🐎
            </h2>
            
            <p>
            Explanation: Howdy, partner! Before we can have ourselves a good ol' Western standoff in the world of Pong, we need to be equipped with the right tools. Think of these lines as heading over to the general store to pick up some essentials.
            </p>
            <img src={two} className='demo__image' alt='img_two' />

            <ul>
                <li>
                import pygame: This is like picking up our trusty lasso. 🌵 pygame is the tool we're going to use to create and run our game. It gives us all the features we need, from drawing our paddles and ball to handling the fast-paced action.
                </li>

                <li>
                import sys: Now, every cowboy or cowgirl needs a good pair of boots, right? 🥾 The sys module, in this case, is like those boots. It's not flashy like our lasso (or pygame), but it's essential. We'll use it to make a smooth exit from our game when the time's right.
                </li>
            </ul>

            <h3>
            Topic: The Essentials of the Wild West Game Development:
            </h3>

            <ul>
                <li>
                Prepare Before the Duel: In the Wild West, before any duel or big event, there was always some preparation involved. Similarly, in the world of coding and game development, before we dive into the action of creating the game, we need to ensure we have all the necessary tools and modules. It's all about setting the foundation right.
                </li>

                <li>
                Choosing the Right Tools: Choosing the right libraries and modules can drastically affect our game's performance and possibilities. With pygame and sys, we have made some top-notch choices!
                </li>

                <li>
                Onwards to Adventure: Now that we're equipped, it's time to dive deeper into the wild and exciting world of Pong, Wild West style! Grab your hat, and let's mosey on to the next steps.
                </li>
            </ul>

            <h3>
            Strap on your boots and get ready for a wild ride! Let's venture further into the coding desert and bring our game to life. 🌵🌅🤖🎮
            </h3>
        </div>
        
        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 3: Setting Up the Pong Courthouse! 🎲
            </h2>

            <p>
            Explanation: Well, after a long day of ropin' and ridin', folks in the Wild West liked to relax and have some fun. And what better place to do that than the local county courthouse? In our coding adventure, reset_game() is our courthouse. It's where all the fun components of our Pong game get set up and reset when we want a fresh start. Whenever we call on reset_game(), it's like walking into that courthouse, with all of our trusty tools, files, and officials ready to go.<br/><br/>Imagine it: The swinging doors open wide, and there's a brand new Pong table right in the center. Players on either side are raring to go, and the ball is placed right in the middle. The game is set, scores are reset, and the crowd is hushed in anticipation.
            </p>
            <img src={three} className='demo__image' alt='img_three' />

            <h3>
            Topic: The Magic of Functions in the Wild West:
            </h3>

            <ul>
                <li>
                What's a Function?: In the world of coding, functions is like our trusty courthouse or hubs of activity. They're areas in our code where we can group specific tasks and then call upon them whenever we need. This way, we don't have to keep rewriting the same lines of code over and over.
                </li>

                <li>
                Why the Name 'reset_game'?: Just like every courthouse had its unique name (like 'Clack Country Court' or 'US Courthouse'), our function needs a name to identify it. We've named it reset_game because its primary purpose is to reset and set up all the game components whenever we need to start fresh.
                </li>

                <li>
                When Do We Visit the Courthouse?: Just like folks visit the courthouse when it is time to start a new hearing, we'll call our reset_game() function when we want to start a new round of Pong or reset the game to its initial state.
                </li>
            </ul>

            <h3>
            Now that our Pong Function is setup, let's step inside and see how we can set up the perfect game environment for our players! 🎱🤠🎮
            </h3>
        </div>
        
        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 4: Gathering the Pong Jury! 🐎🤠
            </h2>

            <p>
            Explanation: Alrighty, partner! Just like every jury has its key members, our Pong game has its central characters. And before we dive into any action inside our reset_game function, we gotta make sure we know who's part of the jury and who ain't. By declaring them with the global keyword, we're telling our code: "Hey, these variables we're talking about? They ain't just local cowpokes hanging in the courthouse. They're big shots known all over the code town!"
            </p>
            <img src={five} className='demo__image' alt='img_four' />

            <h3>
            So, we've got:
            </h3>

            <ul>
                <li>
                ball: Our trusty steed, racing back and forth across the screen.
                </li>

                <li>
                ball_speed_x and ball_speed_y: The speed at which our steed gallops in the x (horizontal) and y (vertical) directions.
                </li>

                <li>
                paddle_a and paddle_b: Our two Pong gun-slingers, ready for a duel on each side of the screen.
                </li>

                <li>
                score_a and score_b: The scorekeepers, keeping track of how many rounds each gunslinger has won.
                </li>
            </ul>

            <h3>
            Topic: The Tale of Global vs. Local in the Wild West:
            </h3>

            <ul>
                <li>
                What's a Global Variable?: Just like a renowned cowboy known across multiple towns, a global variable is known and can be accessed anywhere in our entire code.
                </li>

                <li>
                And a Local Variable?: It's like a local townsfolk - known and recognized in just one specific part of the code, say, inside our courthouse, but not in any other courthouse (function).
                </li>

                <li>
                Why Use 'global'?: Sometimes, inside our function, we want to refer to those big-shot cowboys known all over. To ensure we're talking about the renowned cowboy and not mistaking them for a local with the same name, we use the keyword global.
                </li>
            </ul>

            <h3>
            With our Pong jury rounded up, it's time to set the stage for some action-packed duels! 🎯🔫🐎🕹️
            </h3>
        </div>
        
        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 5: Designing Paddle A's Outfit 🤠👢
            </h2>

            <p>
            Explanation: Woah, Nelly! It's time to get one of our main gunslingers, Paddle A, dressed and ready for the showdown! Every cowboy needs the right outfit to make an impression, and Paddle A is no exception.<br/><br/>This line is like fitting our cowboy with a hat, boots, and a trusty belt. We're using pygame.Rect to craft the outfit, which in the world of Pong, is a rectangle representing our paddle. The things in the parentheses (parameters) given to pygame.Rect define how our paddle will look and where it'll stand:
            </p>
            <img src={seven} className='demo__image' alt='img_five' />

            <ol>
                <li>
                Position from the left (10): We're making sure Paddle A stands 10 steps from the left end of the screen, ready to duel.
                </li>

                <li>
                Position from the top (screen_height // 2 - paddle_height // 2): We want Paddle A to strut into the middle of the screen. To get that, we take the entire screen's height, divide it by two to find the center, then adjust it a smidge by half the paddle's height. This ensures our cowboy stands tall and central.
                </li>

                <li>
                Width and Height (paddle_width, paddle_height): Just how wide and tall Paddle A's outfit is gonna be. This determines how our cowboy looks to the world!
                </li>
            </ol>

            <h3>
            Topic: Crafting the Perfect Cowboy Outfit:
            </h3>

            <ul>
                <li>
                What's pygame.Rect?: Think of it as the tailor shop in our wild west town. It crafts shapes, especially rectangles, which we can use to represent objects in our game, like our paddles or the ball.
                </li>

                <li>
                Positioning and Dimensions: In the world of game design, where and how an object appears is crucial. By setting the x and y positions (from the left and top, respectively) and defining the width and height, we're giving our game objects a visual identity.
                </li>
            </ul>

            <h3>
            With Paddle A suited up, we're one step closer to our epic Pong showdown! 🌵🎮🤠
            </h3>
        </div>
        
        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 6: Gearing Up Paddle B 🤠🐴
            </h2>

            <p>
            Explanation: Hold onto your hats, partners! If Paddle A is the gunslinger, then Paddle B is the brave sheriff in town. Every sheriff needs the right gear to maintain law and order, and Paddle B is no different.<br/><br/>This line is like choosing the right hat, shiny star badge, and those iconic cowboy boots for our sheriff. We're using pygame.Rect again, but this time for Paddle B's gear.
            </p>
            <img src={nine} className='demo__image' alt='img_six' />

            <h3>
            Here's what we're telling the tailor (or, in our case, pygame.Rect):
            </h3>

            <ol>
                <li>
                Position from the left (screen_width - 10 - paddle_width): The sheriff stands confidently 10 steps from the right end of the town, ensuring law and order. We subtract 10 and the paddle's width from the screen's total width to make sure our sheriff is right where we want 'em.
                </li>

                <li>
                Position from the top (screen_height // 2 - paddle_height // 2): Just like our gunslinger, the sheriff stands tall and proud in the center of the town. This positioning ensures that Paddle B is in the middle and ready for action.
                </li>

                <li>
                Width and Height (paddle_width, paddle_height): This is the size of our sheriff's gear, making sure it fits just right.
                </li>
            </ol>

            <h3>
            Topic: The Duel Between Two Paddles:
            </h3>

            <ul>
                <li>
                Why two paddles?: In the Wild West of Pong, our two paddles represent two players battling it out in a duel of reflexes. One paddle is controlled by one player, and the other paddle by another player (or the computer in some versions).
                </li>

                <li>
                Positioning Matters: The difference in positioning between Paddle A and Paddle B is essential. They stand on opposite sides, representing two sides of the gameplay.
                </li>
            </ul>

            <h3>
            Alrighty! With both our gunslinger and sheriff dressed to the nines, this town is all set for a legendary face-off! 🌵🎮🔫🤠
            </h3>
        </div>
        
        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 7: The Golden Ball 🌟
            </h2>

            <p>
            Explanation: Now that we have our gunslinger and sheriff ready to go, it's time to introduce the most crucial part of their duel - the golden ball! In the game of Pong, the ball, zipping from one end to the other, waiting for either the gunslinger or the sheriff to strike it.
            </p>
            <img src={eleven} className='demo__image' alt='img_seven' />

            <h3>
            This line of code draws our shiny golden ball using pygame.Rect:
            </h3>

            <ol>
                <li>
                Position from the left (screen_width // 2 - ball_size // 2): We're placing our ball right in the heart of the town, dead center. This positioning ensures that the ball starts in the middle of our game screen.
                </li>

                <li>
                Position from the top (screen_height // 2 - ball_size // 2): Again, we're keeping it center stage. The ball is positioned right between where our gunslinger and sheriff stand.
                </li>

                <li>
                Width and Height (ball_size, ball_size): The size of our ball, shining brightly and just waiting to be hit!
                </li>
            </ol>

            <h3>
            Topic: The Importance of the Ball in Pong:
            </h3>

            <ul>
                <li>
                Why the Ball is Central: The ball is the centerpiece of Pong. Without it, our two paddles would just stand there with nothing to do. The ball keeps the action moving, bringing excitement and unpredictability to the game.
                </li>

                <li>
                Starting in the Middle: By starting the ball in the center, we give neither the gunslinger nor the sheriff an unfair advantage. It's the Wild West's way of saying, "May the best paddle win!"
                </li>
            </ul>

            <h3>
            So, partners, we've got our duelists, and we've got our ball. It's nearly high noon, and the stage is set for a showdown! 🌵🌞🤠
            </h3>
        </div>
        
        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 8: Ball on the Move! 🌪
            </h2>

            <p>
            Explanation: Every player knows that a ball doesn't just hang mid-air, waiting! It's got to move, zip, and zoom! Our ball in Pong is no different. To make our ball dart across the screen, we need to give it some speed, both horizontally (from left to right) and vertically (from top to bottom).
            </p>
            <img src={twelve} className='demo__image' alt='img_eight' />

            <h3>
            This line of code is handing our ball its speed:
            </h3>

            <ol>
                <li>
                Horizontal Speed (ball_speed_x = 1): This determines how fast the ball will travel left or right. We're setting it to a pace of "1", meaning every time our game updates, the ball moves a little bit in the X (horizontal) direction.
                </li>

                <li>
                Vertical Speed (ball_speed_y = 1): This sets the pace for how quickly the ball goes up or down. With a value of "1", it'll make sure our ball moves steadily in the Y (vertical) direction.
                </li>
            </ol>

            <h3>
            Topic: The Dynamics of Movement in Games:
            </h3>

            <ul>
                <li>
                Why Speed Matters: In any game, movement is what brings the action to life. In Pong, the ball's speed is essential because it adds excitement, requires quick reactions, and keeps players on their toes.
                </li>

                <li>
                X vs. Y Movement: Think of the screen as a big old map of the Wild West. The X direction is like traveling from the courthouse to the jailhouse (left to right), while the Y direction is like moving from the mountains to the desert (top to bottom).
                </li>
            </ul>

            <h3>
            Alright, cowboy! With our ball now having the speed to skedaddle, our duel in the desert is about to get a whole lot more thrilling! 🔥🤠
            </h3>
        </div>
        
        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 9: Scoreboard Setup! 🤠📜
            </h2>

            <p>
            Explanation: Y'all ever heard of a duel without keepin' track of who's outshooting who? Neither have I! Just as every cowboy and cowgirl keeps tally of their achievements, our Pong game needs a way to track which player's shooting straighter and scoring more points.
            </p>
            <img src={thirteen} className='demo__image' alt='img_nine' />

            <h3>
            This line right here sets up our scoring system:
            </h3>

            <ol>
                <li>
                Score for Player A (score_a = 0): Every time Player A lands the ball past Player B, they'll earn themselves a point. But before the game starts, Player A's score is set to zero - a clean slate for the showdown!
                </li>

                <li>
                Score for Player B (score_b = 0): Same goes for Player B. They start with no points, but every successful shot past Player A will earn them a shiny point to their name.
                </li>
            </ol>

            <h3>
            Topic: The Importance of Scoring in Games:
            </h3>

            <ul>
                <li>
                Fair and Square: Scoring ensures that games are fair and players get recognized for their skills and efforts. Without it, how would we ever know who's the top gunslinger in town?
                </li>

                <li>
                Friendly Rivalry: Whether it's a Pong game or a cowboy duel, a bit of friendly competition never hurt nobody! Scoring pushes players to improve, strategize, and have a ton of fun.
                </li>
            </ul>

            <h3>
            So, grab your lasso and get ready! With our scoreboard set up, it's time to see who's the quickest draw in the Wild West of Pong! 🌵👢🏆🤠
            </h3>
        </div>
        
        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 10: Howdy, Pygame! 🤠🐎
            </h2>

            <p>
            Explanation: Before any cowboy gets to ridin' or ropin', they gotta saddle up their horse first. Similarly, before we start our Pong duel in the digital desert, we gotta prepare or "initialize" the Pygame engine, which is what makes our game gallop!<br/><br/>When we call pygame.init(), we're tellin' Pygame, "Hey partner, get everything set up and ready for action!"
            </p>
            <img src={fourteen} className='demo__image' alt='img_ten' />

            <ol>
                <li>
                Saddlin' up: Just as a cowboy checks the saddle, reins, and stirrups, pygame.init() ensures that all the necessary game systems, like graphics and sound, are set up and ready to roll.
                </li>

                <li>
                Start of a Journey: This is akin to the start of a cowboy's adventure across the prairie. With Pygame initialized, our game can now display graphics, play sounds, and handle user input.
                </li>
            </ol>

            <h3>
            Topic: Initialization in Programming:
            </h3>

            <ul>
                <li>
                Begin at the Beginning: Initialization is like the first step in many journeys. It sets the stage, ensuring everything's in its right place before the real action begins.
                </li>

                <li>
                No Surprises: By initializing things in order, we ensure there aren't any unexpected hitches or issues later down the trail.
                </li>

                <li>
                Every Tool has its Start: Whether it's Pygame, another library, or even a cowboy's lasso, most tools require some form of setup or initialization to be used properly.
                </li>
            </ul>

            <h3>
            So, with Pygame all set up and rarin' to go, let's continue our journey through the digital Wild West and build ourselves a Pong game! 🐍🌵🎮🤠
            </h3>
        </div>
        
        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 11: Panning Out Our Playground 🌵🏞️
            </h2>

            <p>
            Explanation: Think of the old Wild West towns with their main streets stretchin' out between rows of houses and general stores. Before building the town, you gotta decide how long and wide that main street's gonna be, right?
            </p>

            <img src={fifteen} className='demo__image' alt='img_eleven' />

            <h3>
            These two lines are settin' the size of our Pong "town" - the playground where all the Pong action will happen!
            </h3>

            <ul>
                <li>
                screen_width = 1200: This is like deciding how long the main street is from the sheriff's office all the way to the courthouse.
                </li>

                <li>
                screen_height = 800: This determines how wide that street is from the northernmost shop to the southernmost hitching post.
                </li>
            </ul>

            <p>
            With these measurements in hand, we'll know just how big to make our Pong arena so that our paddles and ball have enough space to duke it out!
            </p>

            <h3>
            Topic: Screen Dimensions in Games:
            </h3>

            <ul>
                <li>
                Tailored Fit: In game design, we decide on screen dimensions based on what kind of game we're making and what experience we want our players to have. A fast-paced action game might need a wide arena, while a puzzle game might have a smaller, more intimate space.
                </li>

                <li>
                The Bigger Picture: When you see a game on your screen, whether it's on a computer, a phone, or a console, the game's dimensions have been carefully chosen to suit that platform and the game's style.
                </li>

                <li>
                Changing Perspectives: Some games even let players choose or change screen resolutions to best fit their device and preferences.
                </li>
            </ul>

            <h3>
            With our Pong town's main street set, it's time to start building the structures (like the paddles and ball) where all the action's gonna take place! 🌅🕹️🤠🌵
            </h3>
        </div>
        
        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 12: Building the Courthouse's Facade 🚪
            </h2>

            <p>
            Explanation: Every Wild West town's got its main attraction, right? A courthouse where them outlaws get braught to justice. Now that we've laid out our main street, it's time to construct that grand entrance to our county courthouse!<br/><br/>This line is like hammering together the big wooden doors and setting up the glass windows. It tells our game where to display all the action by creating the screen (or window) where our Pong game will play out.
            </p>
            <img src={sixteen} className='demo__image' alt='img_twelve' />

            <p>
            pygame.display.set_mode((screen_width, screen_height)): This here's our way of saying, "Hey, build me a game screen that's as long and as wide as our main street!" We're taking those dimensions we set earlier (screen_width and screen_height) and using them to construct our game's viewing area.
            </p>

            <p>
            So, next time you mosey on into a building in the Wild West, just remember: Behind those swinging doors, there might just be a game of Pong waitin' for ya!
            </p>

            <h3>
            Topic: Game Windows in Design:
            </h3>

            <ul>
                <li>
                Windows to Worlds: When making a game, the window you see on your screen is like a portal to another world. This window can be sized and shaped in all sorts of ways to best fit the game's theme and mechanics.
                </li>

                <li>
                Resolution Revelations: Ever heard of game resolution settings? They let players adjust the size and clarity of the game window to best fit their device.
                </li>

                <li>
                Full-Screen Fantasy: Some games offer a full-screen mode, immersing players completely by filling up the entire display with the game world.
                </li>
            </ul>

            <h3>
            With the courthouse doors now swingin' open, we're all set to welcome folks into our Pong showdown! 🎵🎮🤠🌵
            </h3>
        </div>
        
        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 13: The Calling Card 🎨🔖
            </h2>

            <p>
            Explanation: Just like any establishment in the Wild West, our Pong Courthouse needs a sign out front. Something that tells every cowboy, cowgirl, and wanderer exactly what this place is all about!<br/><br/>This line is like painting a big ol' sign that says, "Pong Courthouse" and hanging it right above our entrance. It lets everyone know that this ain't just any old tavern; it's the place to be if you're lookin' for a thrilling game of Pong! 
            </p>
            <img src={seventeen} className='demo__image' alt='img_thirteen' />

            <h3>
            Topic: The Importance of Titles:
            </h3>

            <ul>
                <li>
                A Game's Identity: The title of a game is its first impression. It can intrigue, inspire, or even intimidate a potential player.
                </li>

                <li>
                Branding and Beyond: Outside of the gaming world, the name of a business or product can be crucial to its success. Branding, which includes names, logos, and slogans, plays a huge role in marketing and public perception.
                </li>
            </ul>

            <h3>
            With our sign hanging proudly, our Pong Courthouse is now officially open for business. Everyone in town will be talking about it! 🤠🌟🎮🎵🐎
            </h3>
        </div>
        
        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 14: Crafting the Paddles and Ball Dimensions 📏🤠
            </h2>

            <p>
            Out here in the Wild West, folks have all sorts of competitions: horse racing, sharpshooting, and even... paddle duels? That's right! Our version of the old Pong game is like a quick-draw contest between two paddles and a fast-moving ball.
            </p>
            <img src={eighteen} className='demo__image' alt='img_fourteen' />

            <h3>
            To set the stage, we need to know the size of our weapons: the paddles and the ball.
            </h3>

            <ol>
                <li>
                paddle_width, paddle_height = 10, 100: This line is like telling our carpenter, "Hey, partner, make me two paddles. Each one should be 10 units wide and 100 units tall." That's the size we reckon will make for the most exciting duels!
                </li>

                <li>
                ball_size = 25: And here, we're deciding on the size of our ball. At 25 units, it's big enough to see but small enough to fly across our courthouse at high speed.
                </li>
            </ol>

            <h3>
            Topic: Size in Pong:
            </h3>

            <ul>
                <li>
                Balance and Fairness: Both players need to have equal chances. If one paddle was much larger than the other, it wouldn't be a fair game. So, our paddles are the same size.
                </li>

                <li>
                Challenge: The size of the ball can determine how challenging the game is. A bigger ball might be easier to hit, while a smaller one could zip past you in the blink of an eye!
                </li>

                <li>
                Visibility: It's crucial to ensure that players can easily see and track the ball's movement. Too small, and it becomes a ghost; too big, and it loses its excitement.
                </li>
            </ul>

            <h3>
            So, with our paddles and ball crafted to perfection, we're ready for the most thrilling Pong duels the West has ever seen! 🌵🌄🏓🎉
            </h3>
        </div>
        
        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 15: Setting the Game's Speed 🐎⚡
            </h2>

            <p>
            Explanation: Imagine you're about to jump on your trusty steed and chase down a runaway stagecoach. The faster your horse, the better your chances. Just like that, in the world of Pong, our paddles need speed to chase down that flying ball! Here, we're deciding just how fast our paddles will move across the screen.<br/><br/>By setting paddle_speed = 2, we're saying our paddles gallop across the screen at a speed of 2 units every time they move. It's the perfect balance of quick reactions and strategic moves!
            </p>
            <img src={nineteen} className='demo__image' alt='img_fifteen' />

            <h3>
            Topic: Speed in Pong:<br/>Speed is vital in Pong. The faster the paddles, the quicker players need to react. But there's a catch – if they're too fast, the game might become too hard and less fun. But if they're too slow, it might be too easy and boring. So, setting the right speed is crucial for an exciting and balanced game!
            </h3>

            <h3>
            Syntax:
            </h3>

            <ul>
                <li>
                variable_name = value: This is the basic format to assign a value to a variable. 
                </li>

                <li>
                paddle_speed is the name of our variable, and 2 is the value we're assigning to it. Every time we refer to paddle_speed in our code, Python will know we're talking about the number 2.
                </li>
            </ul>

            <h3>
            Now, with our paddle speed set, players are ready to gallop after the ball with style and speed! 🤠🐎💨🏓
            </h3>
        </div>
        
        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 16: Setting the Victory Condition 🏆🌵
            </h2>

            <p>
            Explanation: In the wild, wild west, every gunslinger knows they need a target, a goal to shoot for! In our game, players need a target too – a score to reach to claim victory! By setting winning_score = 10, we're declaring that the first player to score 10 points will be the winner of the Pong duel!<br/><br/>But wait! Before the duel starts, both players stand still, waiting for the signal to draw. That's what game_active = False is about. It tells our game: "Hold your horses! 🐴 Don't start just yet!" Only when the players are ready will the game commence.
            </p>
            <img src={twenty} className='demo__image' alt='img_sixteen' />

            <h3>
            Topic: Game States:
            </h3>

            <p>
            Most video games have various states or phases, like 'start', 'playing', and 'game over'. These states determine what's currently happening in the game and what the player can or cannot do. In our Pong game, we have a simple state to check: Is the game active and playing? Or is it waiting for something (like the player to press a key) to start?
            </p>

            <h3>
            Syntax:
            </h3>

            <ol>
                <li>
                variable_name = value: As before, we're assigning values to variables. Here, winning_score is our target score and game_active is our game state variable. 
                </li>

                <li>
                False: This is a boolean value. Booleans can only be True or False and are super handy for "yes or no" situations in coding, like checking if our game is active or not.
                </li>
            </ol>

            <h3>
            Alright, partner! With our victory conditions and game state set, players know what they're aiming for and when to draw their paddles! 🤠🏆
            </h3>
        </div>
        
        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 17: Creating a Wanted Poster (But for Text!) 📜🌵
            </h2>

            <p>
            Explanation: You ever see them wanted posters hangin around town? They've got big, bold letters shouting "WANTED!" to grab folks' attention. In our game, we need something to display our scores with style. This line is like setting up our own "Wanted Poster Printer", but instead of criminals, we're displaying scores and game instructions.
            </p>
            <img src={twentyOne} className='demo__image' alt='img_seventeen' />

            <h3>
           Topic: Text Rendering in Games:<br/>Games need a way to display text to the player, whether it's for scores, instructions, or dramatic game storylines. To do this, we use something called a "font". A font determines how text will look when it's displayed. The size, style, and other attributes of text can be defined using a font. In this step, we're selecting the default font that comes with pygame and setting it to a size of 36 pixels. 
            </h3>

            <h3>
                Syntax:
            </h3>

            <ol>
                <li>
                    pygame.font.Font(): This is a method from the pygame library to create a new font object.
                </li>

                <li>
                    pygame.font.get_default_font(): Another method from pygame which gets the name of the default font.
                </li>

                <li>
                    , 36: This sets the size of our font. Bigger numbers mean bigger text!
                </li>
            </ol>

            <h3>
                Now that we've got our Wanted Poster Printer set up, we're ready to display any messages or scores we need in big, bold Wild West style! 🤠📜
            </h3>
        </div>
        
        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 18: Round Up the Game Elements! 🤠🐴
            </h2>

            <p>
            Explanation: Just like how a cowboy rounds up cattle before the start of a journey, we're calling upon all our game elements to get them in their starting positions. This ensures that everything's in its rightful place before the game's high-noon showdown begins!
            </p>
            <img src={twentyTwo} className='demo__image' alt='img_eighteen' />

            <h3>
            Topic: Function Calls in Python:<br/>A function is a block of organized, reusable code that performs a specific task. When we want that task to be performed, we "call" the function by its name. In this case, we've already defined the reset_game() function earlier to set up our game elements. By writing reset_game(), we're calling upon that function, and all the code inside it will be executed.
            </h3>

            <h3>
            Syntax:
            </h3>

            <ol>
                <li>
                reset_game: This is the name of the function we've previously defined.
                </li>

                <li>
                (): These parentheses signify we're calling (or invoking) the function, letting Python know we want to run all the actions inside it.
                </li>
            </ol>

            <h3>
            So, cowboy, thanks to our trusty reset_game() call, our paddles, ball, and scores are all set and ready for the duel! 🌵🐍🎮
            </h3>
        </div>
        
        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 19: Into the Wild West Desert 🏜️
            </h2>

            <p>
            Explanation: Imagine you're riding through the vast deserts of the Wild West. You keep going and going without an end in sight – just like our game loop here! This line of code means we're starting an endless loop, which will keep our game running until something inside the loop tells it to stop.
            </p>
            <img src={twentyThree} className='demo__image' alt='img_nineteen' />

            <h3>
            Topic: Loops in Python:<br/>A loop is a sequence of instructions that is continually repeated until a certain condition is reached. The while loop runs as long as the condition provided is true. In this case, our condition is simply the value True, which means our loop will run endlessly.
            </h3>

            <h3>
            Syntax:
            </h3>

            <ol>
                <li>
                while: This is a keyword that signifies the start of a while loop.
                </li>

                <li>
                True: This is a Boolean value (either True or False). In our case, we're using True to create an infinite loop.
                </li>

                <li>
                :: This colon indicates the start of the loop's body. Every indented line after this will be part of the loop and will keep running in sequence until we exit the loop.
                </li>
            </ol>

            <h3>
            Keep your hat on tight, partner, 'cause we're in for a non-stop ride! 🤠🐎🌅
            </h3>
        </div>
        
        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 20: Keep an Eye Out for Events! 🌵👀
            </h2>

            <p>
            Explanation: Imagine you're a cowboy on the lookout for any signs of activity in the desert - maybe a rattlesnake slithering by or a tumbleweed rolling across the landscape. In our game, we have to keep an eye out for any events, like key presses or mouse clicks, that might happen. This line of code is like our lookout point where we scan for any such events!
            </p>
            <img src={twentyFour} className='demo__image' alt='img_twenty' />

            <h3>
            Topic: Event Handling in Pygame:<br/>In games and many applications, we often need to react to various events - such as when a player pushes a button or moves their mouse. pygame.event.get() fetches all the events that have occurred since we last checked. We then loop through each of these events using a for loop to handle them appropriately.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ol>
                <li>
                for: This is a keyword that signifies the start of a for loop.
                </li>

                <li>
                event: A temporary variable that will represent each individual event as we loop through them.
                </li>

                <li>
                in: Another keyword used in a for loop, it shows that we're examining each item in a list (or similar structure).
                </li>

                <li>
                pygame.event.get(): A Pygame function that fetches a list of all the recent events.
                </li>
            </ol>

            <h3>
            Remember to stay alert, cowboy! Just as we need to be aware of every rustle in the desert, our game needs to catch and react to every event! 🤠🔍🐍
            </h3>
        </div>
        
        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 21: Responding to a Quit Signal 🌵🚫🎮
            </h2>

            <p>
            Explanation: Y'all know those old courthouse doors that swing open and shut? Well, imagine if a cowboy wanted to leave the courthouse, and as they swing the door open, a bell rings to alert everyone. In our game, when a player wants to stop playing (by closing the window, for instance), we need to respond quickly and properly. These lines of code are our game's way of hearing that bell ring and then safely shutting everything down!
            </p>
            <img src={twentyFive} className='demo__image' alt='img_twentyOne' />

            <h3>
            Topic: Exiting the Game:<br/>Exiting a game may seem simple, but doing so gracefully (without causing errors or leaving things running in the background) requires careful handling. In Pygame, the pygame.QUIT event is triggered when the player tries to close the game window. When this happens, we need to perform two tasks: we tell Pygame to shut down with pygame.quit(), and then we completely exit out of the Python script with sys.exit().
            </h3>

            <h3>
                Syntax:
            </h3>

            <ol>
                <li>
                if: This keyword starts a conditional statement, which will execute the following code block only if a specific condition is true.
                </li>

                <li>
                event.type: This checks the type of the current event we're examining in our loop.
                </li>

                <li>
                ==: This is a comparison operator. It checks if the value on the left is equal to the value on the right.
                </li>

                <li>
                pygame.QUIT: This is a constant in Pygame representing the event when a user tries to close the game window.
                </li>

                <li>
                pygame.quit(): A function to close the Pygame window and clean up resources.  
                </li>

                <li>
                sys.exit(): This function exits the Python script entirely. To use it, we need the sys module, which we imported earlier.
                </li>
            </ol>

            <h3>
            Always remember, partner: it's not just about entering the Wild West, but knowing when and how to leave! 🌵🚪🔔
            </h3>
        </div>
        
        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 22: Detecting a Key Press 🌵🎹
            </h2>

            <p>
            Explanation: Imagine a cowboy playing a honky-tonk piano. Every time they press a key, it makes a sound. Similarly, when our player presses a key on their keyboard, our game needs to "hear" that sound and react accordingly. This line of code is our game's way of listening for when a key on the keyboard is pressed down.
            </p>
            <img src={twentySix} className='demo__image' alt='img_twentyTwo' />

            <h3>
            Topic: Keyboard Input:<br/>In video games, player actions are usually tied to keyboard or controller inputs. Pygame has a set of events dedicated to handling these inputs. One of these events is pygame.KEYDOWN, which is triggered when any key on the keyboard is pressed. By capturing this event, we can start implementing interactions, such as moving a character or pausing the game.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ol>
                <li>
                if: This is a conditional statement. The code inside its block will only execute if the condition is true.
                </li>

                <li>
                event.type: This checks the type of the current event we're looping through.
                </li>

                <li>
                ==: A comparison operator that checks if the value on the left is equal to the value on the right.
                </li>

                <li>
                pygame.KEYDOWN: A constant in Pygame representing the event that occurs when a key on the keyboard is pressed.
                </li>
            </ol>

            <h3>
            So, cowboy, think of all the potential actions our game can take! 🌵🎹🎶
            </h3>
        </div>
        
        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 23: The Spacebar Showdown 🌵🌌
            </h2>

            <p>
            Explanation: Just like in those old Western showdowns where two cowboys face each other, waiting for the right moment to draw their guns, our game waits for the player to press the spacebar to start or restart the game. The spacebar acts as our "draw" command!
            </p>
            <img src={twentySeven} className='demo__image' alt='img_twentyThree' />

            <h3>
            Topic: Specific Key Input:<br/>While the previous step told our game to listen for any key press, this step narrows it down to specifically listening for the spacebar. In many games, the spacebar is often used for primary actions like jumping, shooting, or in our case, starting the game.
            </h3>

            <h3>
            Syntax:
            </h3>

            <ol>
                <li>
                if: Another conditional statement. The code inside its block will only execute if the condition is true.
                </li>

                <li>
                event.key: This captures which specific key has been pressed during a pygame.KEYDOWN event.
                </li>

                <li>
                ==: A comparison operator that checks if the value on the left is equal to the value on the right.
                </li>

                <li>
                pygame.K_SPACE: A constant in Pygame representing the spacebar key.
                </li>
            </ol>

            <h3>
            So partner, the next time you hit that spacebar, remember, it's like calling out "Draw!" in a Wild West showdown! 🤠🌌
            </h3>
        </div>
        
        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 24: Ready, Set, Duel! 🌵
            </h2>

            <p>
            Explanation: In the Wild West, a cowboy wouldn't draw his gun unless he's ready for a duel. Similarly, we want to start our Pong duel only if it isn't already in progress. This line checks if our game isn't active and if so, gets things rolling!
            </p>
            <img src={twentyEight} className='demo__image' alt='img_twentyFour' />

            <h3>
            Topic: Game States:<br/>Most video games have various states: maybe a start menu, the main gameplay, and perhaps a game over screen. For our Pong game, we're primarily concerned with two states: when the game is ongoing (or active) and when it's waiting to start. By checking if game_active is False (or "not active"), we can decide when to kick off a new round of Pong.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ol>
                <li>
                if: A conditional statement that checks the following condition.
                </li>

                <li>
                not: This is a logical operator that inverts the value of the condition that follows it. If the condition is True, not will make it False and vice versa.
                </li>

                <li>
                game_active: This is a variable that represents whether the game is currently in progress or not. If game_active is True, the game is on; if it's False, the game is paused or hasn't started.
                </li>
            </ol>

            <h3>
            So, cowboy, when the game ain't active and you press that spacebar, get ready for the duel to start! 🌵🤠
            </h3>
        </div>
        
        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 25: The Duel's Dawn! 🌄🤠
            </h2>

            <p>
            Explanation: The sun rises over the horizon as the townsfolk gather in anticipation. By setting game_active to True, we signal the crack of dawn, announcing that the Pong duel is officially starting. And just to make sure everything's in place for this epic showdown, we call the reset_game() function, laying out the paddles and ball for our brave players. 
            </p>
            <img src={twentyNine} className='demo__image' alt='img_twentyFive' />

            <h3>
            Topic: Variables and Function Calls:<br/>We're using a variable named game_active to keep track of whether our Pong game is ongoing. By setting it to True, we're telling our game code, "Hey! It's game time!". Following that, we're calling the reset_game() function. This ensures that even if the game was played before, everything is set back to its starting position for a new round.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ol>
                <li>
                game_active = True: This line is setting the value of the variable game_active to True.
                </li>

                <li>
                reset_game(): This is a function call. By appending () to the name of a function (in this case, reset_game), we're telling Python to execute or "call" that function.
                </li>
            </ol>

            <h3>
            Now, gather 'round everyone, for the duel is about to begin! 🌞🎮🏜️
            </h3>
        </div>
        
        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 26: Gathering Storm Clouds ☁️🤠
            </h2>
                
            <p>
            Explanation: Picture this: There's tension in the air. You can almost feel the storm clouds gathering, hinting at the fierce duel that's about to begin. The if game_active: checks if our duel (or Pong game) is actively ongoing. If it is, then the following indented lines of code will run, bringing all the action and drama of the game to the forefront.  
            </p>
            <img src={thirty} className='demo__image' alt='img_twentySix' />

            <h3>
            Topic: Conditional Statements:<br/>In programming, sometimes we only want certain lines of code to run when specific conditions are met. The if statement helps us do that. Here, we're saying, "Only proceed with the next actions if the game is active."
            </h3>

            <h3>
                Syntax:
            </h3>

            <ol>
                <li>
                if game_active:: This is a basic if statement. It checks if the condition within the parentheses (in this case, game_active) is True. If it is, the indented block of code that follows will be executed.
                </li>
            </ol>

            <h3>
            Prepare your reflexes and strategy, 'cause if the duel is on, every move counts! 🐎🕹️🏜️
            </h3>
        </div>
        
        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 27: Checking for Keys! 🗝️🤠
            </h2>

            <p>
            Explanation: Imagine a rusty old chest in the corner of the courthouse, rumored to have treasures inside. But to open it, you'd need the right keys. In our game, the "keys" represent the buttons pressed on the keyboard. This line of code helps us check which "keys" are currently being pressed so we can respond with the appropriate action, like moving a paddle or launching the ball.
            </p>
            <img src={thirtyOne} className='demo__image' alt='img_twentySeven' />

            <h3>
            Topic: Keyboard Inputs:<br/>Games often rely on user input to guide their actions, like moving a character or making decisions. Here, we're trying to figure out which keyboard keys are pressed so that we can act accordingly in the game.  
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                pygame.key.get_pressed(): This is a function from the pygame library that returns a list of boolean values representing the state of each key on the keyboard. If a key is pressed, its corresponding value will be True, otherwise, it will be False.
                </li>
            </ul>

            <h3>
            Keep those fingers nimble and ready, partner! Your next move could make all the difference in this duel! 🎹🕹️🌵
            </h3>
        </div>
        
        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 28: Paddle A's Grand Movement! 🏇🆙
            </h2>
            
            <p>
            Explanation: Alrighty cowboy, ever seen a horse move only when it's safe to do so? Imagine Paddle A is like a wild stallion trying to gallop upwards, but only if there's still space in front of it. The "W" key is like a whistle calling the stallion. If you blow that whistle (press the "W" key) and there's room to move (the paddle isn't already at the top), our stallion (Paddle A) will gallop upwards!
            </p>
            <img src={thirtyTwo} className='demo__image' alt='img_twentyEight' />

            <h3>
            Topic: Conditional Movement:<br/>In many games, objects (like paddles) are moved based on player input, but also within certain boundaries. Here, we want Paddle A to move upwards when the "W" key is pressed, but we also want to make sure it doesn't move beyond the screen's edge.
            </h3>

            <ul>
                <li>
                if keys[pygame.K_w]: This checks if the "W" key is currently being pressed.
                </li>

                <li>
                and paddle_a.top &gt; 0: This ensures that the top edge of Paddle A is still within the screen. The .top attribute gives us the y-coordinate of the top of the rectangle.
                </li>

                <li>
                paddle_a.y -= paddle_speed: If both conditions are met, this line moves Paddle A upwards by decreasing its y-coordinate by the paddle's speed. 
                </li>
            </ul>

            <h3>
            Keep those reins tight, partner! Guiding that paddle just right might win you the duel! 🎮🌄🐎
            </h3>
        </div>
        
        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 29: Paddle A's Daring Descent! 🏇🆓
            </h2>

            <p>
            Explanation: Well, howdy partner! Just as Paddle A has the spirit to move upwards like a gust of wind, it's got the heart to move downwards when it feels the call of the wild! The "S" key is like a nudge to our wild steed. When you give that nudge (press the "S" key) and there's open range below (meaning the paddle isn't already at the bottom), our fearless steed (Paddle A) will dash downwards!
            </p>
            <img src={thirtyThree} className='demo__image' alt='img_twentyNine' />

            <h3>
            Topic: Conditional Movement:<br/>Games often have objects that move according to player input, but within certain constraints. Here, Paddle A is set to move downwards when the "S" key is pressed, but only if it won't leave the boundaries of the screen.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                if keys[pygame.K_s]: This checks if the "S" key is currently being pressed.
                </li>

                <li>
                and paddle_a.bottom &lt; screen_height: This ensures Paddle A's descent won't make it leave our screen. The .bottom attribute provides the y-coordinate of the rectangle's bottom edge.
                </li>

                <li>
                paddle_a.y += paddle_speed: If the above conditions are met, this line moves Paddle A downwards by increasing its y-coordinate according to the paddle's speed.
                </li>
            </ul>

            <h3>
            Hang on to your cowboy hat! Steering your paddle down the screen might just help you dodge that incoming ball! 🎮🌵🤠
            </h3>
        </div>
        
        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 30: Paddle B's Great Ascent! 🌵🐎🆙
            </h2>

            <p>
            Explanation: Yeehaw! Just as our trusty steed Paddle A can gallop up and down the range, we've got another challenger in town - Paddle B! This wild mustang listens to the call of the "UP" arrow key. When it feels that arrow pointing skywards, and there's still some land above it to cover, it gallops upwards like there's gold in them hills!
            </p>
            <img src={thirtyFour} className='demo__image' alt='img_thirty' />

            <h3>
            Topic: Conditional Movement for Paddle B:<br/>Just like Paddle A, Paddle B can also move according to the player's wishes. It's got its own keys to heed and its own trails to blaze, but it too respects the limits of the land (or screen in this case).
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                if keys[pygame.K_UP]: This tests if the "UP" arrow key is currently being pressed.
                </li>

                <li>
                and paddle_b.top &gt; 0: This ensures Paddle B's ascent won't send it soaring out of our view. The .top attribute gives the y-coordinate of the rectangle's top edge.
                </li>

                <li>
                paddle_b.y -= paddle_speed: If both conditions above are true, Paddle B will ascend by decreasing its y-coordinate by the paddle's speed.
                </li>
            </ul>

            <h3>
            So, saddle up, partner! With both paddles under your control, you're the sheriff of this pong frontier! 🤠🎮🏞️
            </h3>
        </div>
        
        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 31: Paddle B's Daring Descent! 🌵🐎🔽
            </h2>

            <p>
            Explanation: Hold on to your hat, partner! Just as Paddle B can scale the heights, it's also fearless enough to descend into the wild canyons of our game screen. When it hears the "DOWN" arrow being summoned, and there's still unexplored territory below, it dashes downwards, ready for any duel that comes its way!
            </p>
            <img src={thirtyFive} className='demo__image' alt='img_thirtyOne' />

            <h3>
            Topic: Conditional Movement for Paddle B (Continuation): The wild west is vast, and Paddle B wants to see it all. Now, with the "DOWN" arrow as its guide, Paddle B can traverse the game screen from top to bottom, ensuring that it's always in the right position for any standoff with the bouncing ball.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                if keys[pygame.K_DOWN]: This checks if the "DOWN" arrow key is currently pressed.
                </li>

                <li>
                and paddle_b.bottom &lt; screen_height: This ensures that as Paddle B gallops downwards, it won't end up under the screen. The .bottom attribute gives the y-coordinate of the rectangle's bottom edge.
                </li>

                <li>
                paddle_b.y += paddle_speed: If both conditions are met, Paddle B will descend by increasing its y-coordinate by the paddle's speed.
                </li>
            </ul>

            <h3>
            Now, with both ascent and descent in its arsenal, Paddle B is a force to be reckoned with on this pong prairie! 🤠🎮🌄
            </h3>
        </div>
        
        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 32: The Ball's Wild Ride! 🌵🌪🏐
            </h2>

            <p>
            Explanation: Yeehaw! Our trusty ball is just like a tumbleweed, rolling freely across the plains. As time ticks by, the ball takes on its journey, moving left or right, and embracing the freedom of the wild west!
            </p>
            <img src={thirtySix} className='demo__image' alt='img_thirtyTwo' />

            <h3>
            Topic: Updating Ball's Horizontal Movement:<br/>Imagine the ball as a roaming cowboy on a horse, wandering the vast landscape. With every beat of time, the cowboy (ball) shifts its position, guided by the speed and direction of its horse (ball_speed_x).
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                ball.x: This is the horizontal position (x-coordinate) of our ball.
                </li>

                <li>
                ball_speed_x: Think of this as how fast the horse is galloping. A positive value means the horse is heading to the right (towards the setting sun!), while a negative value means it's heading left (towards the morning sun!).
                </li>

                <li>
                ball.x += ball_speed_x: With this, we update the ball's current position based on the speed and direction.
                </li>
            </ul>

            <h3>
            Let's see where the winds (and our code) take our ball next in the wild land of pong! 🤠🌄🏐🌪
            </h3>
        </div>
        
        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 33: The Ball's Vertical Adventure! 🌵🌄🏐
            </h2>

            <p>
            Explanation: Hold onto your hats, partners! Just as our ball roams left and right like a free spirit, it also ventures up and down the vast canyons and mesas of the wild west. Let it soar like an eagle or dive like a falcon, feeling every rise and fall of the landscape!
            </p>
            <img src={thirtySeven} className='demo__image' alt='img_thirtyThree' />

            <h3>
            Topic: Updating Ball's Vertical Movement:<br/>Picture the ball as a bird soaring above the wild west. Sometimes, it takes to the sky, while at other times, it swoops down to get a closer look at the ground. The vertical movement of our ball captures this essence, allowing it to move upwards or downwards based on its speed and direction.
            </h3>

            <h3>
            Syntax:
            </h3>

            <ul>
                <li>
                ball.y: This represents the vertical position (y-coordinate) of our ball, similar to the altitude at which our bird is flying.
                </li>

                <li>
                ball_speed_y: This is the bird's vertical speed. A positive value sends our bird flying upwards towards the clouds, while a negative value makes it descend to skim the ground.
                </li>

                <li>
                ball.y += ball_speed_y: By adding the vertical speed to the ball's current position, we dictate whether our bird ascends or descends.
                </li>
            </ul>

            <h3>
            Buckle up, and let's see where our adventurous ball is headed next in this wild, wild game! 🤠🏞🦅🏐
            </h3>
        </div>
        
        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 34: When Balls Collide with Paddles! 🌵🤠🏐
            </h2>

            <p>
            Explanation: Imagine a cowboy trying to lasso a runaway calf in the middle of a bustling rodeo. It takes skill and timing! Similarly, our ball tries to dart past the paddles, but if it gets "lassoed" or caught by either paddle, something interesting is bound to happen!
            </p>
            <img src={thirtyEight} className='demo__image' alt='img_thirtyFour' />

            <h3>
            Topic: Detecting Ball and Paddle Collision:<br/>In our game, just as cowboys and outlaws have standoffs, our ball has its own face-offs with the paddles. We need to detect when the ball and a paddle meet (or collide). This line of code is the town's sheriff, ensuring that if such an encounter happens, appropriate action is taken.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                ball.colliderect(): This function checks if our speedy ball collides or intersects with another object (like our paddles). It's like checking if a cowboy's lasso has caught that runaway calf.
                </li>

                <li>
                paddle_a and paddle_b: These are our two paddles trying to block the ball.
                </li>

                <li>
                or: This logical operator is like our lookout – it watches for a collision with either of the two paddles.
                </li>
            </ul>

            <h3>
            Get ready for a wild showdown! If the ball gets caught by a paddle, things are about to take a swift turn! 🌵🤠🏐🤺
            </h3>
        </div>
        
        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 35: Ball Takes a U-turn! 🌵🤠🔄
            </h2>

            <p>
            Explanation: Y'know how cowboys have that swift move where they suddenly change direction, especially during a chase? Well, our ball does something similar! When it collides with a paddle, it swiftly turns around, bouncing back in the opposite direction.
            </p>
            <img src={thirtyNine} className='demo__image' alt='img_thirtyFive' />

            <h3>
            Topic: Changing Ball's Direction:<br/>When our ball has its little showdown with a paddle and they meet (collide), we don't want the ball to just stop or continue in the same direction. No sir! We want it to ricochet and go the opposite way. This line ensures that the ball does a quick U-turn when it hits a paddle.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                ball_speed_x: This variable controls the speed and direction of our ball on the horizontal axis (left and right). Think of it like the galloping speed of a horse.
                </li>

                <li>
                -ball_speed_x: By taking the negative of its current value, we're telling our ball (or our galloping horse) to reverse its direction and run the other way!
                </li>
            </ul>

            <h3>
            Hold on to your hats! With each hit, the chase becomes more thrilling as the ball changes direction and keeps the game lively! 🌵🤠🔄🏐
            </h3>
        </div>
        
        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 36: The Bouncing Ball Act! 🌵🤠🎉
            </h2>

            <p>
            Explanation: Imagine your ball is like a lively jackrabbit hoppin' around the desert. If it hops too high, it might hit the sky (the top of our screen). On the other hand, if it digs too low, it'll hit the ground (the bottom of our screen). In both cases, we need to make sure it bounces back into play!
            </p>
            <img src={forty} className='demo__image' alt='img_thirtySix' />

            <h3>
            Topic: Ball Vertical Boundary Check:<br/>This line checks if the ball has hit the top or bottom of our screen. If it has, we'll soon tell it to bounce back in the opposite direction.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                ball.top: Gets the topmost y-coordinate of our ball.
                </li>

                <li>
                &lt;= 0: Checks if the ball's top has moved above the top edge of the screen.
                </li>

                <li>
                or: This is a logical operator. It means only one of the conditions on its left or right needs to be true for the whole statement to be true.
                </li>

                <li>
                ball.bottom: Gets the bottommost y-coordinate of our ball.
                </li>

                <li>
                &gt;= screen_height: Checks if the ball's bottom has moved below the bottom edge of our screen.
                </li>
            </ul>

            <h3>
            Keep those boots on tight, and those eyes peeled, 'cause the ball's about to make its move! 🤠🌵🏐
            </h3>
        </div>
        
        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 37: Reversin' the Ball's Bounce on the Desert Floor and Sky 🤠🌵☁️
            </h2>

            <p>
            Explanation: Imagine the ball as a tumbleweed rollin' through the wild west. When it hits a cliff (top) or gets caught by a sneaky rattlesnake (bottom), it doesn't just stop – it tumbles back in the opposite direction. That's the desert's way of sayin', "Nope, not today!"
            </p>
            <img src={fortyOne} className='demo__image' alt='img_thirtySeven' />

            <h3>
            Topic: Ball Vertical Speed Inversion: This line changes the vertical direction of our ball, makin' it bounce back if it touches the top or bottom of our game screen.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                ball_speed_y: This variable represents the vertical speed and direction of our ball.
                </li>

                <li>
                = -ball_speed_y: This part makes the ball's vertical speed go in the opposite direction by changing its sign. If it was goin' up, now it's headed down, and vice versa.
                </li>
            </ul>

            <h3>
            Let's keep on rollin' through this digital desert and see what's next! 🤠🌵🌀
            </h3>
        </div>
        
        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 38: Scoring a Point for Player B when the Ball Goes Past Player A 🤠🌵🏆
            </h2>

            <p>
            Explanation: If the ball goes past Player A's paddle and hits the left edge of the screen, that's a point for Player B! 
            </p>
            <img src={fortyTwo} className='demo__image' alt='img_thirtyEight' />

            <h3>
            Topic: Ball Out of Bounds Check on the Left:<br/>This code checks if the ball has moved beyond the left boundary of our game screen, signifyin' that Player B has scored a point.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                if: This is a conditional statement in Python, used to check if a certain condition is true before executing some code.
                </li>

                <li>
                ball: This is the ball object, represented by a rectangle in our game. The Rect class in Pygame has various attributes like top, bottom, left, right which give the coordinates of the respective edges of the rectangle.
                </li>

                <li>
                .left: This attribute provides the x-coordinate of the leftmost edge of the ball object. When working with screen coordinates in Pygame, the top-left corner is (0,0), and as you move rightward or downward, the numbers increase. So, a ball's .left attribute moving below 0 means it's out of the screen on the left side.
                </li>

                <li>
                &lt; 0: This checks if the leftmost part of the ball has moved beyond the left boundary of our screen (having a position less than 0 on the x-axis).
                </li>
            </ul>

            <h3>
            Now, let's saddle up and see what happens when Player B scores! 🤠🏜🏆
            </h3>
        </div>
        
        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 39: Updating Player B's Score 🤠🌵🔢
            </h2>

            <p>
            Explanation: Player B has just won the round of this fierce duel, so it's time to add a point to his score tally. Much like a cowboy notchin' another victory on his belt, we're adding one to Player B's score.
            </p>
            <img src={fortyThree} className='demo__image' alt='img_thirtyNine' />

            <h3>
            Topic: Incrementing Player B's Score:<br/>Here, we're rewarding Player B with a point since the ball has gone past Player A's paddle and hit the left side of the screen.
            </h3>

            <ul>
                <li>
                score_b: This is a variable representing Player B's score in our game.
                </li>

                <li>
                += 1: This is an increment operation in Python. When this operation is applied to a variable, it adds the specified value (in this case, 1) to the variable's current value. So, score_b += 1 is essentially equivalent to score_b = score_b + 1.
                </li>
            </ul>

            <h3>
            This line makes sure that every time Player B scores by getting the ball past Player A's paddle, his score increases by 1 point. Get ready to continue the duel and see who wins the golden badge of the Wild West Pong showdown! 🤠🌟
            </h3>
        </div>
        
        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 40: Checking If Player B Wins the Duel 🤠🌵🏆
            </h2>

            <p>
            Explanation: Hold onto your horses, partner! Player B might've just won the whole darn shootout. If Player B's score matches the score needed to win the game, we'll be ending this round and prepping for another face-off.
            </p>
            <img src={fortyFour} className='demo__image' alt='img_forty' />

            <h3>
            Topic: Determining the End of a Round:<br/>In this section, we're checking if Player B has reached the set winning score. If so, the current round comes to a close.
            </h3>

            <ul>
                <li>
                if score_b == winning_score:: This initiates a conditional check.
                </li>

                <li>
                score_b: This is the variable that holds Player B's current score.
                </li>

                <li>
                winning_score: This is the pre-determined score that a player must reach to win the round.
                </li>

                <li>
                ==: In Python, this is the equality operator. It checks if the values on both sides are equal.
                </li>

                <li>
                game_active = False: Inside the condition, we're setting the game_active variable to False.
                </li>

                <li>
                game_active: This variable determines if the game is currently active or paused. When set to False, it indicates that the round is over and the game is paused.
                </li>
            </ul>

            <h3>
            So if Player B racks up enough points to match the winning_score, we'll declare him the winner of this round of the Wild West Pong Duel and halt the game for a moment. Ready your reflexes, partner, for the next showdown may be just around the bend! 🤠🏜️🌟
            </h3>
        </div>
        
        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 41: Resetting Ball Position for Player B's Point 🤠🌵🏐
            </h2>

            <p>
            Explanation: Well, looks like Player B scored, but the duel ain't over just yet! Instead of ending the game, we're just gonna reset the ball to the center of the battlefield so the face-off can continue.
            </p>
            <img src={fortyFive} className='demo__image' alt='img_fortyOne' />

            <h3>
            Topic: Ball Position Reset after Player B Scores:<br/>If Player B scores but hasn't reached the winning score, we place the ball back in the middle of the screen for a fresh start.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                else:: This introduces an alternative code block that gets executed when the preceding if condition (in this case, score_b == winning_score) is not met.
                </li>

                <li>
                ball.x = screen_width // 2 - ball_size // 2: The expression as a whole positions the center of the ball at the center of the screen horizontally.
                </li>

                <li>
                ball.x: Refers to the horizontal position (X-coordinate) of the ball.
                </li>

                <li>
                screen_width // 2: This calculates half of the screen's width, effectively finding the center of the screen in the horizontal direction.
                </li>

                <li>
                ball_size // 2: Half of the ball's width.
                </li>

                <li>
                ball.y = screen_height // 2 - ball_size // 2: The expression positions the center of the ball at the center of the screen vertically.
                </li>

                <li>
                ball.y: Refers to the vertical position (Y-coordinate) of the ball.
                </li>

                <li>
                screen_height // 2: Calculates half of the screen's height to find the vertical center.
                </li>
            </ul>

            <h3>
            So, partner, even if Player B managed to shoot one past Player A, if it ain't the winning shot, the game continues! Reload and get ready, for the Wild West Pong Duel is still on! 🤠🔥🎯
            </h3>
        </div>
        
        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 42: Invert Ball Horizontal Direction 🤠🌵🏐
            </h2>
            
            <p>
            Explanation: Alright, partner, after Player B scores, the ball ain't just gonna stay put. It's like a ricocheting ball; we're sendin' it flyin' back the other way!
            </p>
            <img src={fortySix} className='demo__image' alt='img_fortyTwo' />

            <h3>
            Topic: Changing Ball Direction after Player B Scores:<br/>If Player B scores, the ball's horizontal direction is reversed. This means if it was moving rightwards, it's now gonna head leftwards, and vice versa.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                ball_speed_x = -ball_speed_x:
                </li>

                <li>
                ball_speed_x: Refers to the current horizontal speed of the ball. It could be positive (moving right) or negative (moving left).
                </li>

                <li>
                -ball_speed_x: By multiplying the current speed with -1, we invert its direction. So if it was 1 (rightwards), it becomes -1 (leftwards) and vice versa.
                </li>
            </ul>

            <h3>
            Gear up, cowboy! With the ball's direction reversed, the showdown continues. Player A better be ready to defend, for the ball's comin' right back at 'em! 🤠🔥🏐
            </h3>
        </div>
        
        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 43: Detecting Ball Out of Bounds on Player B's Side 🤠🌵
            </h2>

            <p>
            Explanation: If that pesky pong ball goes beyond the right boundary, it means Player A done scored a point against Player B! When that happens, we gotta add a point to Player A's score.
            </p>
            <img src={fortySeven} className='demo__image' alt='img_fortyThree' />

            <h3>
            Syntax:
            </h3>

            <ul>
                <li>
                    if ball.right &gt; screen_width:
                </li>

                <li>
                    if: This word starts off a condition. If what follows is true, then we'll do whatever's indented below it.
                </li>

                <li>
                    ball.right: This here refers to the rightmost edge of our pong ball.
                </li>

                <li>
                &gt; screen_width: If the rightmost part of the ball goes beyond the screen's width, then it's out of bounds on Player B's side.
                </li>

                <li>
                score_a += 1:
                </li>

                <li>
                score_a: Player A's current score.
                </li>

                <li>
                += 1: A quick way of saying, "add one to the score". It's like Player A shooting a can off a fence and marking it as a point.
                </li>
            </ul>

            <h3>
            So, partner, every time Player A makes that ball slip past Player B, there's a reason for celebration. But remember, the game ain't over till the final whistle! 🤠🎉🏆
            </h3>
        </div>
        
        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 44: Checking Player A's Winning Condition and Ball Reset 🤠🌵
            </h2>

            <p>
            Explanation: Hang onto your hat, partner! Now, in the wild, wild world of Pong, when Player A's score equals that all-important winning score, we've got ourselves a champion in town. Just like a gunslinger who's hit all the targets, Player A's proven their mettle. And when that happens, the game comes to a temporary halt, waiting for the next showdown.
            </p>
            <img src={fortyEight} className='demo__image' alt='img_fortyFour' />

            <h3>
            Syntax:
            </h3>

            <ul>
                <li>
                if score_a == winning_score:
                </li>

                <li>
                if: This keyword initiates a conditional check. If the condition that follows is met, we'll venture into the indented code below.
                </li>

                <li>
                score_a: Represents Player A's current score in our dueling match.
                </li>

                <li>
                ==: This is an equality check, partner. We're seeing if two values are the same.
                </li>

                <li>
                winning_score: This is the set score that determines when a player has won.
                </li>

                <li>
                game_active = False:
                </li>

                <li>
                game_active: This is a flag that tells us if our game of Pong is currently ongoing.
                </li>

                <li>
                = False: Setting it to False means our game takes a little break, likely to let folks in the town celebrate the winner and prep for the next round.
                </li>
            </ul>

            <h3>
            With these lines in place, Player A's triumphs don't go unnoticed. But remember, every duel can have a new outcome. On to the next showdown! 🤠🏆🎉
            </h3>
        </div>
        
        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 45: Resetting Ball Position after Player A Scores 🤠🌵
            </h2>
            
            <p>
            Explanation: Well howdy! Looks like our cowboy game of Pong needs a fresh start after Player A scores, but doesn't quite secure the win. Ie the player scores so the ball spawns back in the center. "Try and score again, partner!"
            </p>
            <img src={fortyNine} className='demo__image' alt='img_fortyFive' />

            <h3>
            Topic: Reload and Reshoot:<br/>In this unpredictable Pong duel, when Player A manages to get one past Player B but hasn't won the whole showdown yet, we reset our ball to the center, ready to be fired again.
            </h3>

            <h3>
            Syntax:
            </h3>

            <ul>
                <li>
                    else: This keyword starts a block of code that executes if the condition in the previous if wasn't met.
                </li>

                <li>
                ball.x = screen_width // 2 - ball_size // 2
                </li>

                <li>
                ball.x: Determines the horizontal position of our ball.
                </li>

                <li>
                screen_width // 2: This gives us half of the screen's width, effectively the center.
                </li>

                <li>
                - ball_size // 2: Subtracting half the ball's size ensures the ball's center aligns with the screen's center.
                </li>

                <li>
                ball.y = screen_height // 2 - ball_size // 2
                </li>

                <li>
                ball.y: Sets the vertical position of the ball.
                </li>

                <li>
                screen_height // 2: Finds the vertical center of our gaming screen.
                </li>

                <li>
                - ball_size // 2: Makes sure the ball sits perfectly in the vertical middle.
                </li>

                <li>
                ball_speed_x = -ball_speed_x:
                </li>

                <li>
                ball_speed_x: This variable dictates the ball's horizontal speed and direction.
                </li>

                <li>
                = -ball_speed_x: Flips the direction, so if the ball was moving right, now it'll hustle to the left.
                </li>
            </ul>
            
            <h3>
            Time to see if Player A can keep the momentum going or if Player B's defense is about to rise to the occasion. Either way, our Pong courthouse's filled with anticipation! 🤠🎯🔙
            </h3>
        </div>
        
        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 46: Clearing the Screen for a New Draw 🤠🌵
            </h2>

            <p>
            Explanation: Before we get to paint a new scene in our Pong game, we've got to clear out the old. This here line is like sweepin' the dust off the floor, makin' it pristine for a new round of action. We're clearin' our screen canvas by fillin' it with the color black, getting ready to draw our new game state.
            </p>
            <img src={fifty} className='demo__image' alt='img_fortySix' />
            <h3>
            Topic: The Clean Slate:<br/>Each frame of our game, we clear the entire screen to make sure old drawings don't overlap with the new. This is essential for smooth gameplay and accurate representation of game elements.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                screen: This is our main display surface in pygame where all the drawing happens.
                </li>

                <li>
                .fill(...): This method fills the entire surface with a single color.
                </li>

                <li>
                (0, 0, 0): This is a tuple representing the RGB (Red, Green, Blue) values of the color. Here, all values are set to 0, which means the color is black.
                </li>
            </ul>

            <h3>
            So, saddle up! We're prepping the screen for another exciting draw of the game elements. 🎨🤠🖼️
            </h3>
        </div>
        
        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 47: Drawin' the Pong Paddles and Ball 🤠🏓
            </h2>

            <p>
            Explanation: Yeehaw! 🤠 Now that we've cleared out the courthouse, it's time to lay down the card table, chairs, and clean the desks – or in our Pong Courthouse, the paddles and the ball. These here lines of code be the brushstrokes painting the paddles and ball onto our cleared canvas.
            </p>
            <img src={fiftyOne} className='demo__image' alt='img_fortySeven' />

            <h3>
            Topic: The Duel of the Draw:<br/>Drawing is a crucial part of any game loop. For our game to show any changes in the state (like a ball movin' or paddles slidin'), we've got to draw those elements in their updated positions each frame.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                pygame.draw.rect(screen, color, rectangle_object)
                </li>

                <li>
                screen: The surface we're drawin' on, our main display.
                </li>

                <li>
                color: The RGB tuple to color the rectangle. Here, (255, 255, 255) is the RGB representation for white.
                </li>

                <li>
                rectangle_object: This is the rectangle (like our paddles) we're aiming to draw.
                </li>

                <li>
                pygame.draw.ellipse(screen, color, rectangle_object)
                </li>

                <li>
                Pretty similar to our rect function above, but instead of a straight rectangle, this function draws an ellipse that fits within the boundaries of the given rectangle. Since our ball is square-shaped, this ellipse will appear as a circle.
                </li>
            </ul>

            <h3>
            So partner, we've got our playing field set with the paddles and the ball. Ready for the duel to continue? 🏓🤠🌵
            </h3>
        </div>
        
        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 48: Tallying Up the Scores 🤠🔢
            </h2>

            <p>
            Explanation: Time to count the gold nuggets! 🤠🥇 Each time one of the cowpokes (players) wins a round, they get a score. This line is like the town's ledger, keepin' a record of each player's score and displaying it up on the tavern board for all to see.
            </p>
            <img src={fiftyTwo} className='demo__image' alt='img_fortyEight' />

            <h3>
            Topic: The Importance of Scorekeeping:<br/>In the vast expanse of the Wild West, a cowboy's reputation was everything. In our Pong game, the score is a way to keep track of which player is closer to becoming the ultimate Pong outlaw. By displaying it prominently, we keep the stakes high and the tension thicker than a bull's hide.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                font.render(text, antialias, color)
                </li>

                <li>
                text: The string to be displayed. Here we're usin' an f-string to display the scores of the two players with a dash in between.
                </li>

                <li>
                antialias: This be a boolean. When set to True, it makes the text look smoother by blending the pixel colors on the text's edges.
                </li>

                <li>
                color: The RGB tuple to color the text. (255, 255, 255) be the RGB for white.
                </li>
            </ul>

            <h3>
            With the scores now displayed, everyone in the tavern knows just who's the sharpest in this Pong duel! 🤠🌵
            </h3>
        </div>
        
        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'> 
            <h2>
                Step 49: Displaying The Scores 🤠📜
            </h2>

            <p>
            Explanation: Now that we've chalked down the scores on our ledger, it's time to display them up high on the courthouse board for all the town folk to see. We're placin' it smack dab in the middle of the top, so no one can miss it, not even Blind Bill! 🤠
            </p>
            <img src={fiftyThree} className='demo__image' alt='img_fortyNine' />

            <h3>
            Syntax:
            </h3>

            <ul>
                <li>
                screen.blit(source, dest)
                </li>

                <li>
                source: The image or surface to be displayed on the screen. Here we're using the score_text we rendered earlier.
                </li>

                <li>
                dest: A tuple representing the x and y coordinates where the source will be placed. The formula used here makes sure the score is centered horizontally, with a slight offset from the top edge.
                </li>

                <li>
                screen_width // 2: This gets us the middle of the screen width.
                </li>

                <li>
                score_text.get_width() // 2: This gets half the width of our score text, helping us ensure it's centered.
                </li>
            </ul>

            <h3>
            Now, as the sun sets on the Wild West horizon and the golden score numbers shine brightly, every cowboy and cowgirl in the tavern can easily see who's closer to bein' the Pong outlaw champion. 🌅🤠🌵🔢
            </h3>
        </div>
        
        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 50: Passing on the Legend 🤠🔥
            </h2>

            <p>
            Explanation: Imagine, a cowboy wanders into the town, sees folks engaged in a heated game of Pong, but isn't quite sure how to join the duel. He needs to know the legend of how to start his own challenge. This line checks if our Pong game is paused or hasn't begun yet. If so, we'll be displayin' the legendary instructions on how to start the duel!
            </p>
            <img src={fiftyFour} className='demo__image' alt='img_fifty' />

            <h3>
            Topic: The Importance of Invitations:<br/>A game of Pong without a way to start would be like a courthouse without a door - so we make sure to invite newcomers to join the fun, be part of the legend.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                if not game_active:
                </li>

                <li>
                Here, the if keyword introduces a condition.
                </li>

                <li>
                The not operator is used to reverse the logical state of its operand.
                </li>

                <li>
                game_active is our flag variable that tracks whether the game is currently active or not. If the game isn't active (game_active is False), then the condition becomes True, and the code inside this block will execute, showing the legendary instructions.
                </li>
            </ul>

            <h3>
            So, cowboy, if you're new in town and want to challenge the reigning champ, just wait for the game to pause, and the legend will guide you on how to start your own epic Pong duel! 🐴🔥
            </h3>
        </div>
        
        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 51: Sharin' the Pong Legend 🤠🌵
            </h2>

            <p>
            Explanation: The instructions that will guide a budding Pong duelist to start their own challenge.  
            </p>
            <img src={fiftyFive} className='demo__image' alt='img_fiftyOne' />

            <h3>
            Topic: Passing Down the Knowledge:<br/>In the Wild West, legends weren't just shared through tales by the campfire, but through signs, scrolls, and sometimes, even through a song by a wandering bard. Our Pong game is no different. We're craftin' a sign for all to see, so that anyone, knows how to step up and start a game.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                instruction_text = : Here, we're defining a variable named instruction_text. This variable will hold our rendered text - essentially the crafted sign or scroll that'll be shown on screen. 
                </li>

                <li>
                font.render(...) : This method is how we "craft" or render our text. We use the font object we created earlier to handle this.
                </li>

                <li>
                Press SPACE to start": This is the string of text we're looking to display.
                </li>

                <li>
                True: This is an anti-aliasing flag. It smoothens the font on the screen. In our courthouse analogy, think of it as fine printin' for our legendary scroll.
                </li>

                <li>
                (255, 255, 255): This is the color of the text, in RGB format. It translates to white. A standout color, so it's clear and bold for anyone to see
                </li>
            </ul>

            <h3>
            The next time a cowboy ambles in, uncertain of how to join the game, they'll have clear instructions waitin' for 'em. The legend lives on! 🚀
            </h3>
        </div>
        
        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 52: Displaying the Legend 🤠🌵
            </h2>

            <p>
            Explanation: The tale's been written and the sign's been crafted. But what good is a scroll if it's hidden away in a dusty drawer? This line's all about nailing that scroll right in the center of the courthouse wall for all to see. We are taking that score that we created in the previous step and making it appear on the screen.
            </p>
            <img src={fiftySix} className='demo__image' alt='img_fiftyTwo' />

            <h3>
            Syntax:
            </h3>

            <ul>
                <li>
                The blit method is used to draw or place one surface (like our instruction text) onto another (our game screen). Think of it like nailing a poster onto a wooden wall.
                </li>

                <li>
                instruction_text: This is the surface (our previously crafted scroll) that we want to display on our game screen.
                </li>

                <li>
                (screen_width // 2 - instruction_text.get_width() // 2, screen_height // 2 - instruction_text.get_height() // 2) : This is the tuple (pair of values) specifying the x (horizontal) and y (vertical) coordinates where we want to position the top-left corner of our scroll.
                </li>

                <li>
                screen_width // 2: This gets the center of our screen in the horizontal direction.
                </li>

                <li>
                instruction_text.get_width() // 2: This gets half the width of our instruction text.
                </li>

                <li>
                By subtracting the two, we make sure the text is centered horizontally.
                </li>

                <li>
                Similarly, using screen_height and instruction_text.get_height(), we make sure the text is centered vertically.
                </li>
            </ul>

            <h3>
            So, next time a cowboy moseys into the Pong courthouse with a puzzled look on their face, they'll know just what to do, thanks to our prominently placed scroll of wisdom. Onward, partner! 🐎🌟📜
            </h3>
        </div>
        


        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 53: Showtime for the Pong Duel 🤠🌌
            </h2>

            <p>
            Explanation: Imagine the curtains on the main stage of our courthouse, hiding all the preparations, the setting, the actors, and their props. When everything's set just right, those curtains are drawn back, revealing the entire scene to the eager audience. This line of code does precisely that - it reveals the newly painted frame to our players, bringing our Pong duel to life for another round of intense action.
            </p>

            <img src={fiftySeven} className='demo__image' alt="img_fiftySeven" />

            <h3>
            Topic: The Grand Reveal:<br/>The whole town's been buzzing, awaiting the grand performance. With this command, we pull back the curtains and the game's fresh state is revealed, ensuring every move, every score, and every piece of guidance is visible for the duelists.
            </h3>

            <h3>
                Syntax:
            </h3>

            <ul>
                <li>
                pygame.display.flip(): This function updates the entire display window with everything that's been drawn since the last update.
                </li>

                <li>
                In the realm of Pygame, there are often two screens at play: one you're drawing onto behind the scenes (often called the backbuffer), and the one that's currently being shown to the players (frontbuffer). The flip() function swaps these screens, bringing all your recent drawings to the forefront for the players to see.
                </li>

                <li>
                It's like the final act, revealing the results of all the previous actions and preparations.
                </li>
            </ul>

            <h3>
            Now, every stroke of the brush, every tweak, and every update made to our gaming canvas is shown in its full glory, ensuring that our Pong duel remains the talk of the Wild West! 🌌🎭🎨🔦
            </h3>
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>

    </div>
  )
}

export default Pong