import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

const GroupAttendance = ({ setGroupAttendance, attendanceName, attendanceMessage, setAttendanceMessage, submitGroupAttendance }) => {
  return (
    <div className='group-attendance__container'>
        <div className='group-attendance__form'>

        <div className='group-attendance__icon__container'>
            <FaRegWindowClose style={{ cursor: "pointer" }} onClick={()=>setGroupAttendance(false)} size={26} />
        </div>

        <h1 className='groupName'>{attendanceName}</h1>



        <textarea className='groupAttendance__textarea' placeholder='Write a message to the parents of your student!' value={attendanceMessage} onChange={(e)=>setAttendanceMessage(e.target.value)} ></textarea>

        <button className='groupAttendance__button' onClick={()=>submitGroupAttendance()} >Submit</button>

        </div>
    </div>
  )
}

export default GroupAttendance