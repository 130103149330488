import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import "./details.css";

const Details = ({ scratch, python, roblox, fullstack, details, setDetails, detailsName, detailsPath, setDetailsPath, studentCertificates, setSelectedCert, awardCertificate }) => {
  return (
    <div className='overlay'>
        <div className='details-container'>

            <div className='details-form'>

            <div className='details-icon-container'>
                <FaRegWindowClose style={{ cursor: "pointer" }} onClick={()=>setDetails(false)} size={26} />
            </div>

            <h1>{detailsName}'s Progress</h1>


            <div className='label-container'>
                <h3 onClick={()=>setDetailsPath("scratch")} >Scratch</h3>

                <h3 onClick={()=>setDetailsPath("python")} >Python</h3>

                <h3 onClick={()=>setDetailsPath("roblox")} >Roblox</h3>

                <h3 onClick={()=>setDetailsPath("fullstack")} >Fullstack</h3>

                <h3 onClick={()=>setDetailsPath("certificates")} >Certificates</h3>
            </div>

            <div className='project-tag-container'>
                {
                    detailsPath =="scratch" &&
                    scratch?.map((project) => {
                        return (
                            <div className={project[1] == true ? 'project-tag-true' : 'project-tag-false'}>
                                <h3>{project[0]}</h3>
                            </div>
                        )
                    })
                }

                {
                    detailsPath =="python" &&
                    python?.map((project) => {
                        return (
                            <div className={project[1] == true ? 'project-tag-true' : 'project-tag-false'}>
                                <h3>{project[0]}</h3>
                            </div>
                        )
                    })
                }
                
                {
                    detailsPath =="roblox" &&
                    roblox?.map((project) => {
                        return (
                            <div className={project[1] == true ? 'project-tag-true' : 'project-tag-false'}>
                                <h3>{project[0]}</h3>
                            </div>
                        )
                    })
                }
                
                {
                    detailsPath =="fullstack" &&
                    fullstack?.map((project) => {
                        return (
                            <div className={project[1] == true ? 'project-tag-true' : 'project-tag-false'}>
                                <h3>{project[0]}</h3>
                            </div>
                        )
                    })
                }
            </div>

            {
                detailsPath === "certificates" &&
                <div className='certificates-container'>

                    {
                        studentCertificates?.length === 0 ?
                        <div className='empty__projects'>
                            <p>
                                The student has not been awarded any certificates.
                            </p>
                        </div>
                        :
                        <div className='certificates'>
                        {
                            studentCertificates?.map((cert)=> {
                            return (
                                <div className='certificate'>
                                <p>{cert}</p> <AiOutlineCheckCircle className='check' size={20} style={{ color: "white", marginLeft: "8px" }} />
                                </div>
                            )
                            })
                        }
                        </div>
                    }

                    <div className='award-container'>
                        <p>Choose Certificate to Award Student With!</p>
                        
                        <select onChange={(e)=>setSelectedCert(e.target.value)} className='certSelector'>
                            <option></option>
                            <option value={"Fundamentals of Scratch Programming"} >Fundamentals of Scratch Programming</option>
                            <option value={"Fundamentals of Lua Programming"} >Fundamentals of Lua Programming</option>
                            <option value={"Python Programming Certificate"} >Python Programming Course</option>
                            <option value={"Fundamentals of HTML & CSS"} >Fundamentals of HTML & CSS</option>
                            <option value={"Fundamentals of JavaScript"} >Fundamentals of JavaScript</option>
                            <option value={"Fundamentals of ReactJs"} >Fundamentals of ReactJs</option>
                            <option value={"Fundamentals of Node & ExpressJs"} >Fundamentals of Node & ExpressJs</option>
                        </select>
                        
                        <button className='certButton' onClick={()=>awardCertificate()}>
                            Award Certificate
                        </button>
                    </div>
                </div>
            }

            </div>

        </div>
    </div>
  )
}

export default Details