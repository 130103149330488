import React, {useState} from 'react';
import one from './scratchImages/dinojumpImages/1.png';
import two from './scratchImages/dinojumpImages/2.png';
import three from './scratchImages/dinojumpImages/3.png';
import four from './scratchImages/dinojumpImages/4.png';
import five from './scratchImages/dinojumpImages/5.png';
import six from './scratchImages/dinojumpImages/6.png';
import seven from './scratchImages/dinojumpImages/7.png';
import eight from './scratchImages/dinojumpImages/8.png';
import nine from './scratchImages/dinojumpImages/9.png';
import ten from './scratchImages/dinojumpImages/10.png';
import eleven from './scratchImages/dinojumpImages/11.png';
import twelve from './scratchImages/dinojumpImages/12.png';
import thirteen from './scratchImages/dinojumpImages/13.png';
import fourteen from './scratchImages/dinojumpImages/14.png';
import fifteen from './scratchImages/dinojumpImages/15.png';
import sixteen from './scratchImages/dinojumpImages/16.png';
import seventeen from './scratchImages/dinojumpImages/17.png';
import eighteen from './scratchImages/dinojumpImages/18.png';
import nineteen from './scratchImages/dinojumpImages/19.png';
import twenty from './scratchImages/dinojumpImages/20.png';
import twentyOne from './scratchImages/dinojumpImages/21.png';
import twentyTwo from './scratchImages/dinojumpImages/22.png';
import twentyThree from './scratchImages/dinojumpImages/23.png';
import twentyFour from './scratchImages/dinojumpImages/24.png';
import twentyFive from './scratchImages/dinojumpImages/25.png';
import twentySix from './scratchImages/dinojumpImages/26.png';
import twentySeven from './scratchImages/dinojumpImages/27.png';
import twentyEight from './scratchImages/dinojumpImages/28.png';
import twentyNine from './scratchImages/dinojumpImages/29.png';
import thirty from './scratchImages/dinojumpImages/30.png';
import thirtyOne from './scratchImages/dinojumpImages/31.png';
import thirtyTwo from './scratchImages/dinojumpImages/32.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import "./tutorial.css";

const DinoJump = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 32;

  return (
    <div className='scratchGames__container'>
        <h1>
        🦖💨 "Dino Jump: The Jurassic Jump Journey!" 🌋🌿
        </h1>
        <h3 className='intro'>
        Step into the prehistoric world with "Dino Jump" 🦖💨 As a nimble dino, your mission is to leap over hurdles, dodge dangers, and collect ancient treasures while aiming for the highest score! 🌋🦕🏆        
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>



        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎉 Step 1 🎉</h2>

          <ul>
            <li>
              To start this project navigate to the Scratch website.
            </li>

            <li>
              Scratch: <a target="_blank" href="https://scratch.mit.edu/" >https://scratch.mit.edu/</a>
            </li>

            <li>
              Select the "create" tab at the top to open a new project.
            </li>
          </ul>
          
          <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌟 Step 2 🌟</h2>

          <ul>
            <li>
              What's the name of this game? DINO JUMP! Not, cat jump.... get rid of the cat and add the super speedy dino from the "sprite box".
            </li>

            <li>
              The size of this dino should be 70 change it to match the size of 70 rather than the default of 100.
            </li>
          </ul>
          
          <img src={two} className='demo__image' alt='img_two' />
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚀 Step 3 🚀</h2>
          <ul>
            <li>
              For this project you need the exact same background that I have, so that your dino is coded correctly and can run on the road!
            </li>

            <li>
              In the background selection search for "night city" and use the one with the road!
            </li>
          </ul>
          
          <img src={three} className='demo__image' alt='img_three' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎈 Step 4 🎈</h2>
          <ul>
            <li>
              With the right background selected, you should have the same thing that you see in the image below.
            </li>
          </ul>

          <img src={four} className='demo__image' alt='img_four' />
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🔥 Step 5 🔥</h2>
          <ul>
            <li>
            "Mr. Rex" (t-rex) will be leaping over lady bugs, since the lady bugs and t-rex's have had a sacred treaty to not hurt each other. Mr. Rex is very careful not to step on them.
            </li>

            <li>
              Find the "ladybug2" in the sprites and add it to the project!
            </li>
          </ul>
          
          <img src={five} className='demo__image' alt='img_five' />
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>💡 Step 6 💡</h2>
          <ul>
            <li>
              Change the size of "ladybug2" from 100 to 70, this is the proper hopping distance for Mr. Rex.
            </li>
          </ul>
          
          <img src={six} className='demo__image' alt='img_six' />
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍀 Step 7 🍀</h2>
          <ul>
            <li>
              With the ladybug selected, let us begin coding it.
            </li>

            <li>
              Add the two new blocks of code that you see in the image below into the code for the ladybug. 
            </li>
          </ul>

          <img src={seven} className='demo__image' alt='img_seven' />
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌈 Step 8 🌈</h2>

          <ul>
            <li>
              Add a "go to X: Y:" block with the X having a value of "190" and Y having a value of "-100". This ensures that the ladybug starts in the right position (the bottom right corner).
            </li>
          </ul>

          <img src={eight} className='demo__image' alt='img_eight' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎁 Step 9 🎁</h2>
          <ul>
            <li>
              What we need now is, for the ladybug to run at Mr. Rex.... hoping he leaps over her.
            </li>

            <li>
              Look at the block below, it says: "glide 2 seconds to X: -240 Y: -100"
            </li>

            <li>
              This makes the ladybug glide from the starting point to the point that Mr. Rex will be at.
            </li>
          </ul>
          
          <img src={nine} className='demo__image' alt='img_nine' />
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          
          <h2>🎶 Step 10 🎶</h2>
          <ul>
            <li>
              If you test the game now the ladybug will start the bottom right corner and glide over to the left side for two seconds before respawning on the right side. This repeat of the code is all thanks to the forever block!
            </li>
          </ul>

          <img src={ten} className='demo__image' alt='img_ten' />
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌺 Step 11 🌺</h2>
          <ul>
            <li>
              Select the dino sprite and let us begin coding him!
            </li>

            <li>
              Add a "when space pressed block". This block will activate code underneath it when the user clicks the "space bar" on the keyboard. This should then code the dino to leap upwards.
            </li>
          </ul>
          
          <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍄 Step 12 🍄</h2>
          <ul>
            <li>
              Add a "start sound pop" block to add a jumping sound effect, then add a "repeat 10" block, we will use this in the next steps.
            </li>
            <li>
              The "repeat 10" block is very similar to the "forever block", but instead of repeating "forever" it only repeats the code it contains 10 times.
            </li>
          </ul>

          <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍪 Step 13 🍪</h2>
          <ul>
            <li>
              Add a "change y by" block with a value of "15". This is in the "repeat 10" block so that the Y value of the dino is increase by 15 a total of 10 times, thus propelling him up into the air.
            </li>
          </ul>
          
          <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐾 Step 14 🐾</h2>
          <ul>
            <li>
              Create two more blocks, another "repeat 10" and another "change Y by", but the value should be "-15" to bring the dino back down from the sky.
            </li>
          </ul>

          <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌊 Step 15 🌊</h2>
          <ul>
            <li>
              Each successful jump should result in a point for the player.
            </li>

            <li>
              Select a "make a variable" button in the "variables" section.
            </li>
          </ul>

          <img src={fifteen} className='demo__image' alt='img_fifteen' />
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍉 Step 16 🍉</h2>
          <ul>
            <li>
              Name the variable "score".
            </li>

            <li>
              Press the "OK" button when you are ready to generate this variable!
            </li>
          </ul>

          <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎭 Step 17 🎭</h2>
          <ul>
            <li>
              Now at the bottom of your set of code for the dino, connect the "change score by 1" block to increase the player's score after the dino successfully lands behind the ladybug.
            </li>
          </ul>

          <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎨 Step 18 🎨</h2>
          <ul>
            <li>
              We need yet another set of of code for Mr. Rex... his job is not done.
            </li>

            <li>
              Create a "when flag clicked" block to start this second code sequence!
            </li>
          </ul>
          
          <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚴 Step 19 🚴</h2>
          <ul>
            <li>
              The "go to X: Y:" block seen below, will place Mr. Rex at the bottom left corner of the game. This is where he will leap over the ladybugs.
            </li>

            <li>
              The X value should be "-186" and the Y should be "-48" to ensure he is in the right spot.
            </li>
          </ul>

          <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍦 Step 20 🍦</h2>
          <ul>
            <li>
              Next, add a "set score to zero" so that the score resets to zero at the start of every game and no score overlaps into the next game... that would not be very fair!
            </li>
          </ul>
          
          <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌍 Step 21 🌍</h2>
          <ul>
            <li>
              Add a "forever" block. This "forever" block will forever loop through the code sequence that checks if the dino is touching the ladybug. If such a collision were to occur; the game would be over and our ladybug friend would be squished.... oh nooo!
            </li>
          </ul>
          
          <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          
          <h2>🕺 Step 22 🕺</h2>
          <ul>
            <li>
              Create the "if block" to check if the dino is touching the ladybug.
            </li>

            <li>
              Follow the example below to create "if touching Ladybug2 then".
            </li>

            <li>
              In the next step we will define what should happen if our new condition is met.
            </li>
          </ul>

          <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎤 Step 23 🎤</h2>
          <ul>
            <li>
              The next block is a "broadcast" block, in this case we are broadcasting the message: "Game Over"
            </li>

            <li>
              This block communicates a message all of the other sprites and blocks in the project. It also works in conjunction with a "when received block". The "when received block" waits for the "Game Over" message to be broadcasting and will react a certain way.
            </li>

            <li>
              Create the "broadcast Game Over" block that you see below.
            </li>
          </ul>
          
          <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎬 Step 24 🎬</h2>

          <ul>
            <li>
              Now add a "stop all" block, this will stop the game.... since Mr. Rex here stepped on a ladybug and it is now game over for him!
            </li>
          </ul>
          
          <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎮 Step 25 🎮</h2>

          <ul>
            <li>
              Our "if block" set is done and we need to put it into the "forever" loop, so now we can forever loop through the "if block"... checking if Mr. Rex stepped on a ladybug and ending the game if so!
            </li>
          </ul>
          
          <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>📚 Step 26 📚</h2>

          <ul>
            <li>
              The next step in our epic adventure (project), is creating the "Game Over" message for when Mr. Rex squishes a ladybug.
            </li>

            <li>
              To create this message we need to create a new sprite.
            </li>

            <li>
              Navigate to the bottom right corner of the screen and hover the "new sprite" button, from there click the "paintbrush" icon to create a new custom sprite.
            </li>
          </ul>

          <img src={twentySix} className='demo__image' alt='img_twentySix' />
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚂 Step 27 🚂</h2>

          <ul>
            <li>
              Create a Sprite similar to the one you see below.
            </li>

            <li>
              Add "new text" and customize the message to your liking!
            </li>
          </ul>

          <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🏖 Step 28 🏖</h2>

          <ul>
            <li>
              After completing the design of your new sprite, navigate back to the workspace and rename the sprite to "GameOver" as seen in the image below.
            </li>
          </ul>

          <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎡 Step 29 🎡</h2>

          <ul>
            <li>
              This sprite should have code that makes it only appear when the player takes a loss, not always on the screen.
            </li>

            <li>
              Start with a "when flag clicked" block followed by a "hide" block. So, when the game starts (flag is clicked) the "game over" message will be hidden. The next steps will outline when to show it!
            </li>
          </ul>

          <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍕 Step 30 🍕</h2>

          <ul>
            <li>
              Remember when we broadcasted the "Game Over" message, using a "broadcast" block?
            </li>

            <li>
              The next block is a "when I receive Game Over", this one waits for the "broadcast" block to start and when it does it "receives" the message and starts its own sequence of code.
            </li>

            <li>
              After the "when I receive Game Over", connect a "show" block.
            </li>

            <li>
              When the "Game Over" messsage is received the "game over image" with show.
            </li>
          </ul>

          <img src={thirty} className='demo__image' alt='img_thirty' />
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌜 Step 31 🌜</h2>

          <ul>
            <li>
              Test your game out!
            </li>

            <li>
              If you have followed all of the steps carefully, your project should be complete and you can play your new dino jump odyssey!
            </li>
          </ul>
          
          <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>⛱ Step 32 ⛱</h2>

          <ul>
            <li>
              Don't forget to save!
            </li>

            <li>
              Give your project a name and hit that save button!
            </li>
          </ul>

          <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
        </div>



        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default DinoJump