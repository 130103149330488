import React, {useState} from 'react';
import one from './scratchImages/underwaterImages/1.png';
import two from './scratchImages/underwaterImages/2.png';
import three from './scratchImages/underwaterImages/3.png';
import four from './scratchImages/underwaterImages/4.png';
import five from './scratchImages/underwaterImages/5.png';
import six from './scratchImages/underwaterImages/6.png';
import seven from './scratchImages/underwaterImages/7.png';
import eight from './scratchImages/underwaterImages/8.png';
import nine from './scratchImages/underwaterImages/9.png';
import ten from './scratchImages/underwaterImages/10.png';
import eleven from './scratchImages/underwaterImages/11.png';
import twelve from './scratchImages/underwaterImages/12.png';
import thirteen from './scratchImages/underwaterImages/13.png';
import fourteen from './scratchImages/underwaterImages/14.png';
import fifteen from './scratchImages/underwaterImages/15.png';
import sixteen from './scratchImages/underwaterImages/16.png';
import seventeen from './scratchImages/underwaterImages/17.png';
import eighteen from './scratchImages/underwaterImages/18.png';
import nineteen from './scratchImages/underwaterImages/19.png';
import twenty from './scratchImages/underwaterImages/20.png';
import twentyOne from './scratchImages/underwaterImages/21.png';
import twentyTwo from './scratchImages/underwaterImages/22.png';
import twentyThree from './scratchImages/underwaterImages/23.png';
import twentyFour from './scratchImages/underwaterImages/24.png';
import twentyFive from './scratchImages/underwaterImages/25.png';
import twentySix from './scratchImages/underwaterImages/26.png';
import twentySeven from './scratchImages/underwaterImages/27.png';
import twentyEight from './scratchImages/underwaterImages/28.png';
import twentyNine from './scratchImages/underwaterImages/29.png';
import thirty from './scratchImages/underwaterImages/30.png';
import thirtyOne from './scratchImages/underwaterImages/31.png';
import thirtyTwo from './scratchImages/underwaterImages/32.png';
import thirtyThree from './scratchImages/underwaterImages/33.png';
import thirtyFour from './scratchImages/underwaterImages/34.png';
import thirtyFive from './scratchImages/underwaterImages/35.png';
import thirtySix from './scratchImages/underwaterImages/36.png';
import thirtySeven from './scratchImages/underwaterImages/37.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import "./tutorial.css";

const UnderwaterAdventures = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 37;

  return (
    <div className='scratchGames__container'>
        <h1>
        🌊🐠 "Underwater Adventures: Mysteries of the Deep!" 🐟🌌
        </h1>

        <h3 className='intro'>
        Plunge into the enigmatic depths with "Underwater Adventures: Mysteries of the Deep!" 🌊🐠 This underwater exploration game takes players on a captivating journey through the world's most secretive and awe-inspiring underwater realms. From vibrant coral reefs teeming with life to ancient sunken cities awaiting discovery, every dive reveals a new layer of marine adventures. 🏺🔍
        </h3>



        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>



        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎉 Step 1 🎉</h2>

          <ul>
            <li>
              To start this project navigate to the Scratch website.
            </li>

            <li>
              Scratch: <a target="_blank" href="https://scratch.mit.edu/" >https://scratch.mit.edu/</a>
            </li>

            <li>
              Select the "create" tab at the top to open a new project.
            </li>
          </ul>
          
          <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌟 Step 2 🌟</h2>
          <ul>
            <li>
            Our explorer friend down in the image below is the main character of this abysmal adventure!
            </li>

            <li>
              Cats do not belong under the ocean, so replace the Scratch cat with a scuba diver!
            </li>
          </ul>

          <img src={two} className='demo__image' alt='img_two' />
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚀 Step 3 🚀</h2>
          <ul>
            <li>
            Every code sequence needs a block to get started, our "when flag clicked" block will start the sequence of code when the player starts the game. Add the "when flag clicked" block.
            </li>
          </ul>

          <img src={three} className='demo__image' alt='img_three' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎈 Step 4 🎈</h2>
          <ul>
            <li>
              Before we can introduce the main plot of our game, we need to add a variable to keep track of the score.
            </li>

            <li>
              Locate the variables section and click the "make a variable" button. Name your variable "score", this will be the data to contain how many points that the player has!
            </li>
          </ul>

          <img src={four} className='demo__image' alt='img_four' />
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🔥 Step 5 🔥</h2>
          <ul>
            <li>
              Connect the "set score to 0" block under the "flag clicked" block.
            </li>

            <li>
              Now every time that a player starts the game, the score will reset to zero.
            </li>
          </ul>

          <img src={five} className='demo__image' alt='img_five' />
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>💡 Step 6 💡</h2>
          <ul>
            <li>
              The block that you see below "reset timer" is new... you and I have never ventured with this one before. But the time to use it is here! 
            </li>

            <li>
              Add a "reset timer" block under the "score" block.
            </li>

            <li>
              This block, as you may have guessed will reset the game timer so that we can track the player's time in the game.
            </li>

            <li>
            But you may be thinking..... hold on what? Why are be tracking the time? This adventure will have the scuba diver finding fish for points and avoiding sharks. When a player touches a shark they will loose points, but when they find fish they will get points. If the player gets 20 points they will win, but if they get -20 points they will loose. The timer will let us see how long it took for them to win or loose.
            </li>
          </ul>

          <img src={six} className='demo__image' alt='img_six' />
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍀 Step 7 🍀</h2>

          <ul>
            <li>
              Most games need a "forever" block to let us repeat a sequence. In our case, we will always need the scuba diver to follow the player's mouse.
            </li>

            <li>
              Add a "forever" block to repeat a sequence and a "go to mouse-pointer" block so that the scuba diver will always follow the player's mouse.
            </li>
          </ul>

          <img src={seven} className='demo__image' alt='img_seven' />
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌈 Step 8 🌈</h2>
          <ul>
            <li>
              We will also need to "forever" checking if the player has a winning score.
            </li>

            <li>
              Add a "if statement" block to create a conditional.
            </li>

            <li>
              Then, check if the "score" variable is greater than (more than) 20 points!
            </li>
          </ul>

          <img src={eight} className='demo__image' alt='img_eight' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎁 Step 9 🎁</h2>
          <ul>
            <li>
              What happens when the player wins?
            </li>

            <li>
              A victory message should be displayed and a "stop all" block should be used to stop the game.
            </li>

            <li>
            Add the two new blocks that you see in the image below.
            </li>
          </ul>

          <img src={nine} className='demo__image' alt='img_nine' />
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎶 Step 10 🎶</h2>

          <ul>
            <li>
              Onward young sea explorer!
            </li>

            <li>
              Move the "if block" that contains the winning condition into the "forever" block to check if we achieve the winning score!
            </li>

            <li>
              Then, create the "lose statement" look at the image below and replicate the "if statement" that checks if the "score" is less than -20, thus causing the player to loose.
            </li>
          </ul>

          <img src={ten} className='demo__image' alt='img_ten' />
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌺 Step 11 🌺</h2>
          <ul>
            <li>
              When the "if block" for the "lose" statement is complete, add it into the "forever" loop.
            </li>

            <li>
              A quick breakdown: When the start the game the score is reset to zero for the new play and so is the timer, we then forever loop through following the player's mouse to make the diver move and forever check if the player has won or was defeated by the sharks.... which we will soon add.
            </li>
          </ul>

          <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍄 Step 12 🍄</h2>
          <ul>
            <li>
              We need a variable to display the time kept by the timer.
            </li>

            <li>
              "make a variable" and name it "Time"!
            </li>
          </ul>

          <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍪 Step 13 🍪</h2>
          <ul>
            <li>
              Name your variable "Time" as seen in the image below.
            </li>
          </ul>

          <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐾 Step 14 🐾</h2>
          <ul>
            <li>
              Look at the block below and add it.
            </li>

            <li>
              This block "set time to timer" takes the timer from the game and displays it on the screen with the variable by making the variable equal to the timer.
            </li>
          </ul>

          <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌊 Step 15 🌊</h2>
          <ul>
            <li>
              Friend or foe? This next step we are introducing our fishy friend who will give us points as we find him in the sea.
            </li>

            <li>
              Go to the "add sprite" section and add the fish that you see in the image below so that we can continue coding!
            </li>

            <li>
              Set the size to 40!
            </li>
          </ul>

          <img src={fifteen} className='demo__image' alt='img_fifteen' />
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍉 Step 16 🍉</h2>
          <ul>
            <li>
              Let's start coding the fish, we need this fish to constantly be swimming. In order to keep it in perpetual motion, let's add a "when flag clicked" block to start the game and a "forever" block to loop through the code that will cause it to swim.
            </li>
          </ul>

          <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎭 Step 17 🎭</h2>
          <ul>
            <li>
              In order to create the swimming movement of the fish, we need it to face in the direction that it swims. The direction that is swims will be random.
            </li>

            <li>
              Add a "point in direction" block to get it to move.
            </li>

            <li>
              Then add the "pick random" operator block to pick a random direction to swim between "-180" and "180"
            </li>
          </ul>

          <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎨 Step 18 🎨</h2>
          <ul>
            <li>
              The last block only shifts the direction that the fish is facing, but the next sequence should force it to swim.
            </li>

            <li>
              To create this sequence add a "repeat 25" block. Inside of the repeat we need a "move 10 steps" block to move it and a "if on edge, bounce" block.
            </li>

            <li>
              Now the fish will swim around and bounce off the edges of the screen!
            </li>
          </ul>

          <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚴 Step 19 🚴</h2>
          <ul>
            <li>
              Take the "repeat" sequence that we created in the last step and add it into the "forever" block.
            </li>

            <li>
              The next block that we are adding will cause the the fish to create "clones" of itself. The result will be many of this same fish all swimming in different directions. The ocean is full of fish, not just one!
            </li>

            <li>
              Add the "create clone of myself" block under the swimming sequence to create multiple of this fish.
            </li>
          </ul>

          <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍦 Step 20 🍦</h2>
          <ul>
            <li>
              But what happens when the clones of this swimming fish are created? Don't they also need to swim? And explore? And live?
            </li>

            <li>
              That is certainly the case! So, we need to code the clones!!!
            </li>

            <li>
              Add the block that says "when I start as a clone"; in order to code the clones.
            </li>

            <li>
              The next block, "set ghost effect to 50" will make the clones slightly more transparent form the original so that the player can tell the difference.
            </li>
          </ul>

          <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌍 Step 21 🌍</h2>
          <ul>
            <li>
              Add the "repeat 300" block and the "point towards diver" block to cause this clone to swim like the original; thus, pointing and swimming at the diver for the diver to catch!
            </li>
          </ul>

          <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🕺 Step 22 🕺</h2>
          <ul>
            <li>
              While the clones now point at the diver, they need to swim at the diver. Add the "move 1 steps" block. The original swims faster while the clones swim at a rate of "1" making them slower than the original.
            </li>
          </ul>

          <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎤 Step 23 🎤</h2>
          <ul>
            <li>
              But what happens when the fish touches the diver? That is the next sequence we shall assemble.
            </li>

            <li>
              Start this next sequence with an "if block", that checks if the fish is "touching the diver".
            </li>

            <li>
              If the fish ends up touching the diver we give the player a point for catching the fish. Add the "change score by 1" block.
            </li>
          </ul>
          
          <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎬 Step 24 🎬</h2>
          <ul>
            <li>
              There are two more blocks that we should add if the player touches the fish. First, make the "bubbles" sound play and "delete this clone" to despawn the fish that is caught.
            </li>

            <li>
              Add the two new blocks that you see in the image below.
            </li>
          </ul>
          
          <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎮 Step 25 🎮</h2>
          <ul>
            <li>
              Move the sequence (if block) that we just created into the "repeat" block to constantly check if the fish gets caught.
            </li>

            <li>
              Now, under the "repeat" sequence add a "delete this clone" block.
            </li>
          </ul>

          <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>📚 Step 26 📚</h2>
          <ul>
            <li>
              An enemy enters the adventure! Oh no.... a shark. That's what we need next in this oceanic adventure. Add the "shark2" sprite and set the size to "40".
            </li>
          </ul>

          <img src={twentySix} className='demo__image' alt='img_twentySix' />
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚂 Step 27 🚂</h2>
          <ul>
            <li>
              Let's start coding the shark, although similar to the fish. The shark will take points from the player.
            </li>

            <li>
              Add a "when flag clicked" block and a "forever" block to start the sequence.
            </li>
          </ul>

          <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🏖 Step 28 🏖</h2>
          <ul>
            <li>
              The shark needs to point in the random direction that it is swimming just like the fish. Add the "point towards" block with the "random" operator to point in a random direction between "-180" and "180".
            </li>
          </ul>
          
          <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎡 Step 29 🎡</h2>
          <ul>
            <li>
              The new code that you see below is the same as the fish. Add a "repeat 25" sequence thus causing the shark to swim and bounce off of the edges of the game.
            </li>
          </ul>

          <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍕 Step 30 🍕</h2>
          <ul>
            <li>
              Add the "create clone of myself" block so that our shark has duplicates all swimming at the player in an angry frenzy to take the player's points.
            </li>
          </ul>

          <img src={thirty} className='demo__image' alt='img_thirty' />
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌜 Step 31 🌜</h2>
          <ul>
            <li>
              Let's get coding the clones, add a "when I start as a clone" and the "ghost effect" to start the clone's code and change the appearance to be different from the original.
            </li>
          </ul>

          <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>⛱ Step 32 ⛱</h2>
          <ul>
            <li>
              The next sequence that is in the image below, will "repeat 300" to cause the clone to swim with a "point towards diver" block and a "move 1 steps" block. Both pointing the clone in the right direction and propelling it forward!
            </li>
          </ul>

          <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍩 Step 33 🍩</h2>
          <ul>
            <li>
              Add an "if touching diver" block to check if any of the shark clones are touching the diver. We will define what happens in the next statement.
            </li>
          </ul>

          <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐝 Step 34 🐝</h2>
          <ul>
            <li>
              If the shark clone touches the diver three things should happen: the player looses 3 points, the sound is played, and the clone is deleted.
            </li>

            <li>
              Add the three new blocks into the "if block" to cause the result of the "if statement" to commence.
            </li>
          </ul>

          <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎢 Step 35 🎢</h2>
          <ul>
            <li>
              Now add a "delete this clone" block to the very bottom of the statement if the shark never catches up the player.
            </li>
          </ul>

          <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍿 Step 36 🍿</h2>
          <ul>
            <li>
              Great work so far! But what kind of ocean adventure would this be without the "underwater" background in the Scratch backgrounds category. Add that "underwater" background to spruce up the experience for the players.
            </li>
          </ul>

          <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>⭐ Step 37 ⭐</h2>
          <ul>
            <li>
              Great work! You are all done.
            </li>

            <li>
              Make sure to give your project a fun name and save it, so that you can play it later!
            </li>
          </ul>

          <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default UnderwaterAdventures