import React, {useState} from 'react';
import one from './robloxImages/SAVE-THE-CITY/1.png';
import two from './robloxImages/SAVE-THE-CITY/2.png';
import three from './robloxImages/SAVE-THE-CITY/3.png';
import four from './robloxImages/SAVE-THE-CITY/4.png';
import five from './robloxImages/SAVE-THE-CITY/5.png';
import six from './robloxImages/SAVE-THE-CITY/6.png';
import seven from './robloxImages/SAVE-THE-CITY/7.png';
import eight from './robloxImages/SAVE-THE-CITY/8.png';
import nine from './robloxImages/SAVE-THE-CITY/9.png';
import ten from './robloxImages/SAVE-THE-CITY/10.png';
import eleven from './robloxImages/SAVE-THE-CITY/11.png';
import twelve from './robloxImages/SAVE-THE-CITY/12.png';
import thirteen from './robloxImages/SAVE-THE-CITY/13.png';
import fourteen from './robloxImages/SAVE-THE-CITY/14.png';
import fifteen from './robloxImages/SAVE-THE-CITY/15.png';
import sixteen from './robloxImages/SAVE-THE-CITY/16.png';
import seventeen from './robloxImages/SAVE-THE-CITY/17.png';
import eighteen from './robloxImages/SAVE-THE-CITY/18.png';
import nineteen from './robloxImages/SAVE-THE-CITY/19.png';
import twenty from './robloxImages/SAVE-THE-CITY/20.png';
import twentyOne from './robloxImages/SAVE-THE-CITY/21.png';
import twentyTwo from './robloxImages/SAVE-THE-CITY/22.png';
import twentyThree from './robloxImages/SAVE-THE-CITY/23.png';
import twentyFour from './robloxImages/SAVE-THE-CITY/24.png';
import twentyFive from './robloxImages/SAVE-THE-CITY/25.png';
import twentySix from './robloxImages/SAVE-THE-CITY/26.png';
import twentySeven from './robloxImages/SAVE-THE-CITY/27.png';
import twentyEight from './robloxImages/SAVE-THE-CITY/28.png';
import twentyNine from './robloxImages/SAVE-THE-CITY/29.png';
import thirty from './robloxImages/SAVE-THE-CITY/30.png';
import thirtyOne from './robloxImages/SAVE-THE-CITY/31.png';
import thirtyTwo from './robloxImages/SAVE-THE-CITY/32.png';
import thirtyThree from './robloxImages/SAVE-THE-CITY/33.png';
import thirtyFour from './robloxImages/SAVE-THE-CITY/34.png';
import thirtyFive from './robloxImages/SAVE-THE-CITY/35.png';
import thirtySix from './robloxImages/SAVE-THE-CITY/36.png';
import thirtySeven from './robloxImages/SAVE-THE-CITY/37.png';
import thirtyEight from './robloxImages/SAVE-THE-CITY/38.png';
import thirtyNine from './robloxImages/SAVE-THE-CITY/39.png';
import forty from './robloxImages/SAVE-THE-CITY/40.png';
import fortyOne from './robloxImages/SAVE-THE-CITY/41.png';
import fortyTwo from './robloxImages/SAVE-THE-CITY/42.png';
import fortyThree from './robloxImages/SAVE-THE-CITY/43.png';
import fortyFour from './robloxImages/SAVE-THE-CITY/44.png';
import fortyFive from './robloxImages/SAVE-THE-CITY/45.png';
import fortySix from './robloxImages/SAVE-THE-CITY/46.png';
import fortySeven from './robloxImages/SAVE-THE-CITY/47.png';
import fortyEight from './robloxImages/SAVE-THE-CITY/48.png';
import fortyNine from './robloxImages/SAVE-THE-CITY/49.png';
import fifty from './robloxImages/SAVE-THE-CITY/50.png';
import fiftyOne from './robloxImages/SAVE-THE-CITY/51.png';
import fiftyTwo from './robloxImages/SAVE-THE-CITY/52.png';
import fiftyThree from './robloxImages/SAVE-THE-CITY/53.png';
import fiftyFour from './robloxImages/SAVE-THE-CITY/54.png';
import fiftyFive from './robloxImages/SAVE-THE-CITY/55.png';
import fiftySix from './robloxImages/SAVE-THE-CITY/56.png';
import fiftySeven from './robloxImages/SAVE-THE-CITY/57.png';
import fiftyEight from './robloxImages/SAVE-THE-CITY/58.png';
import fiftyNine from './robloxImages/SAVE-THE-CITY/59.png';
import sixty from './robloxImages/SAVE-THE-CITY/60.png';
import sixtyOne from './robloxImages/SAVE-THE-CITY/61.png';
import sixtyTwo from './robloxImages/SAVE-THE-CITY/62.png';
import sixtyThree from './robloxImages/SAVE-THE-CITY/63.png';
import sixtyFour from './robloxImages/SAVE-THE-CITY/64.png';
import sixtyFive from './robloxImages/SAVE-THE-CITY/65.png';
import sixtySix from './robloxImages/SAVE-THE-CITY/66.png';
import sixtySeven from './robloxImages/SAVE-THE-CITY/67.png';
import sixtyEight from './robloxImages/SAVE-THE-CITY/68.png';
import sixtyNine from './robloxImages/SAVE-THE-CITY/69.png';
import seventy from './robloxImages/SAVE-THE-CITY/70.png';
import seventyOne from './robloxImages/SAVE-THE-CITY/71.png';
import seventyTwo from './robloxImages/SAVE-THE-CITY/72.png';
import seventyThree from './robloxImages/SAVE-THE-CITY/73.png';
import seventyFour from './robloxImages/SAVE-THE-CITY/74.png';
import seventyFive from './robloxImages/SAVE-THE-CITY/75.png';
import seventySix from './robloxImages/SAVE-THE-CITY/76.png';
import seventySeven from './robloxImages/SAVE-THE-CITY/77.png';
import seventyEight from './robloxImages/SAVE-THE-CITY/78.png';
import seventyNine from './robloxImages/SAVE-THE-CITY/79.png';
import eighty from './robloxImages/SAVE-THE-CITY/80.png';
import eightyOne from './robloxImages/SAVE-THE-CITY/81.png';
import eightyTwo from './robloxImages/SAVE-THE-CITY/82.png';
import eightyThree from './robloxImages/SAVE-THE-CITY/83.png';
import eightyFour from './robloxImages/SAVE-THE-CITY/84.png';
import eightyFive from './robloxImages/SAVE-THE-CITY/85.png';
import eightySix from './robloxImages/SAVE-THE-CITY/86.png';
import eightySeven from './robloxImages/SAVE-THE-CITY/87.png';
import eightyEight from './robloxImages/SAVE-THE-CITY/88.png';
import eightyNine from './robloxImages/SAVE-THE-CITY/89.png';
import ninety from './robloxImages/SAVE-THE-CITY/90.png';
import ninetyOne from './robloxImages/SAVE-THE-CITY/91.png';
import ninetyTwo from './robloxImages/SAVE-THE-CITY/92.png';
import ninetyThree from './robloxImages/SAVE-THE-CITY/93.png';
import ninetyFour from './robloxImages/SAVE-THE-CITY/94.png';
import ninetyFive from './robloxImages/SAVE-THE-CITY/95.png';
import ninetySix from './robloxImages/SAVE-THE-CITY/96.png';
import ninetySeven from './robloxImages/SAVE-THE-CITY/97.png';
import ninetyEight from './robloxImages/SAVE-THE-CITY/98.png';
import ninetyNine from './robloxImages/SAVE-THE-CITY/99.png';
import hundred from './robloxImages/SAVE-THE-CITY/100.png';
import hundredOne from './robloxImages/SAVE-THE-CITY/101.png';
import hundredTwo from './robloxImages/SAVE-THE-CITY/102.png';
import hundredThree from './robloxImages/SAVE-THE-CITY/103.png';
import hundredFour from './robloxImages/SAVE-THE-CITY/104.png';
import hundredFive from './robloxImages/SAVE-THE-CITY/105.png';
import hundredSix from './robloxImages/SAVE-THE-CITY/106.png';
import hundredSeven from './robloxImages/SAVE-THE-CITY/107.png';
import hundredEight from './robloxImages/SAVE-THE-CITY/108.png';
import hundredNine from './robloxImages/SAVE-THE-CITY/109.png';
import hundredTen from './robloxImages/SAVE-THE-CITY/110.png';
import hundredEleven from './robloxImages/SAVE-THE-CITY/111.png';
import hundredTwelve from './robloxImages/SAVE-THE-CITY/112.png';
import hundredThirteen from './robloxImages/SAVE-THE-CITY/113.png';
import hundredFourteen from './robloxImages/SAVE-THE-CITY/114.png';
import hundredFifteen from './robloxImages/SAVE-THE-CITY/115.png';
import hundredSixteen from './robloxImages/SAVE-THE-CITY/116.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const Heros = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 118;

  return (
    <div className='robloxGames__container'>
        <h1>🏙️ Save the City: Keeping the Streets of Bloxville Safe 🏙️</h1>

        <h3 className='intro'>
        🏙️ Save the City from the Zombies as you blast your way through them with different super powers: laser eyes, teleport, super speed, and galactic jump.... or gear up with legendary weapons and go toe-to-toe with the villains that roam the roads. 💥
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>




        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Choosing Your Template 🌟</h2>
            <ul>
                <li>
                Open Roblox Studio from your desktop or Start menu🖱️
                </li>
                <li>
                Sign in with your Roblox account or create a new one🔑
                </li>
                <li>
                Click "New" to create a new project and select "Classic Baseplate" as the template🗺️<br/><br/>(By doing this, you're starting a fresh new game project with a simple baseplate to build your obby on.🛠️)
                </li>
            </ul>

            <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Accessing Your Tools 🔨</h2>
            <ul>
                <li>
                With your baseplate loaded, it's time to add a fun town for players to explore! Check out the upper menu and click on the "Toolbox" icon.
                </li>

                <li>
                This is where you'll find all sorts of items, models, and scripts to populate your game. Think of it as your treasure chest full of creative goodies!
                </li>
            </ul>

            <img src={two} className='demo__image' alt='img_two' />
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 3: Adding a Premade Model 🏠</h2>
            <ul>
                <li>
                Time to bring your world to life! In the "Toolbox" search bar, type in "dead town" or any other scenery you wish to add.
                </li>

                <li>
                Browse through the search results and select a model that catches your eye. Let's say you choose the "Dead Town" model.
                </li>

                <li>
                Click on the model to place it into your baseplate. Wow, your game is already taking shape!
                </li>
            </ul>

            <img src={three} className='demo__image' alt='img_three' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: Bringing Your Game to Life with Scripts 🌟</h2>
            <ul>
                <li>
                Look to the "Explorer" panel on your screen; this is where you organize and manage all game elements.
                </li>

                <li>
                Find "ServerScriptService" in the list. This special service will host the scripts that run on the server side, affecting all players in the game.
                </li>

                <li>
                    Add a new Script into "ServerScriptService".
                </li>
            </ul>

            <img src={four} className='demo__image' alt='img_four' />
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Naming Your Script for Super Powers 💥</h2>
            <ul>
                <li>
                Now that you've added a script, let's give it an appropriate name. Single-click on the new "Script" that appeared in "ServerScriptService".
                </li>

                <li>
                Right-click and choose "Rename" to edit the script's name.
                </li>

                <li>
                Type in "powerScript" as the new name and press Enter. This name suggests that the script will grant players superpowers—pretty exciting, right?
                </li>

                <li>
                With "powerScript" in place, you're all set to program some awesome abilities that players can use to battle enemies in your game!
                </li>
            </ul>

            <img src={five} className='demo__image' alt='img_five' />
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Initializing the Powers Framework 🌐</h2>
            <ul>
                <li>
                In your script, begin by creating an empty table named powers. In programming, a table is a versatile data structure that you can use to store a collection of items. In Lua, tables are fundamental, as they can hold multiple data types, including numbers, strings, and functions.
                </li>

                <li>
                By writing local powers = &#10100;&#10101;, you declare a table that will contain all the superpowers available in your game. The local keyword means that this table will only be accessible in the scope of this script, which is a good practice to prevent conflicts with other scripts.
                </li>
            </ul>

            <img src={six} className='demo__image' alt='img_six' />
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Defining Superpower Functions 📝</h2>
            <ul>
                <li>
                Now that you have your powers table, you'll populate it with functions. Each function represents a different superpower. Functions are blocks of code that perform a specific task when called.
                </li>

                <li>
                Inside the powers table, you define several functions such as superSpeed, superJump, flying, laserEyes, and teleportation. Each function is defined as function(player) indicating that they will take a player object as an argument. This parameter will be used to apply the superpower effects to the player who receives them.
                </li>

                <li>
                Currently, these functions are placeholders, meaning they don't have any code inside them yet (end right after the declaration). This step is like writing down the titles of the chapters of a book you're about to write; you know what each chapter will be about, but you haven't filled in the content yet.
                </li>
            </ul>

            <img src={seven} className='demo__image' alt='img_seven' />
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Starting the Super Speed Power 🏃‍♂️</h2>
            <ul>
                <li>
                You're now going to start filling in the functionality for the superSpeed superpower. You'll write the actual code that will be executed when you want to give a player this superpower.
                </li>

                <li>
                Inside the superSpeed function, you access the player's Humanoid object, which controls many aspects of the player's character, including movement speed. You then set the WalkSpeed property to 250, a significant increase from the default value, giving the player the experience of moving at superhuman speeds.
                </li>

                <li>
                This step is where the superpower comes to life in the game. By changing the WalkSpeed, you directly affect how the player interacts with the game world, making them faster and adding excitement to the gameplay.
                </li>

                <li>
                WalkSpeed: A property of Humanoid that dictates how fast the character moves.
                </li>
            </ul>

            <img src={eight} className='demo__image' alt='img_eight' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Enhancing Jumping Capabilities 🏃‍♂️</h2>
            <ul>
                <li>
                In the superJump function, we will boost the player's ability to jump higher. For this, you will adjust the JumpPower property of the player's Humanoid.
                </li>
                
                <li>
                Setting player.Character.Humanoid.JumpPower = 200 modifies the jump height to a new level, allowing the player to leap over obstacles with ease. This creates a more dynamic gameplay experience by giving players the ability to explore your game world from different heights and perspectives.
                </li>

                <li>
                This enhancement to the jumping ability is an example of modifying a player's physical capabilities in the game, which can lead to exciting new challenges and ways to navigate the game environment.
                </li>

                <li>
                player.Character.Humanoid.JumpPower = 200: This line of code is setting the JumpPower of the Humanoid to 200, which increases the height that the player can jump.
                </li>
            </ul>

            <img src={nine} className='demo__image' alt='img_nine' />
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Activating Flight Mode 🦅</h2>
            <ul>
                <li>
                Moving on to the flying function, we will grant players the ability to fly. This is achieved by changing the state of the player's Humanoid to the Flying state.
                </li>

                <li>
                    This line of code merely opens up the ability to fly, but is not what will cause the player to fly. Think of this as the player putting on their flight gear.
                </li>
            </ul>

            <img src={ten} className='demo__image' alt='img_ten' />
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: Setting Up Flight Activation 🚀</h2>
            <ul>
                <li>
                In our flying function, we're not just changing the humanoid state; we're also preparing a mechanism that will allow us to activate flying through a local script. Local scripts run on a player's computer, allowing for client-specific actions like controlling flight.
                </li>

                <li>
                To set this up, you create a BoolValue object. This object serves as a flag that can be toggled on or off. By naming it "Fly" and setting its value to true, you're indicating that this player has the ability to fly, but this is not what makes them fly. In the local script we will create later on, it will check if the player contains this BoolValue and if they do it will activate a function in the local script that will make the player fly.
                </li>

                <li>
                You then parent this BoolValue to the player by setting boolValue.Parent = player. This means that the BoolValue becomes part of the player's object model and can be checked by other scripts.
                </li>

                <li>
                Later on, a local script will look for this BoolValue in the player's model. If it finds "Fly" set to true, the script will know that it should allow the player to fly. This check is crucial because it ensures that the player can only fly when the game has granted them the power to do so.
                </li>

                <li>
                local boolValue = Instance.new("BoolValue"): This line creates a new BoolValue object in memory.
                </li>

                <li>
                boolValue.Name = "Fly": Assigns the name "Fly" to the BoolValue, making it identifiable.
                </li>

                <li>
                boolValue.Value = true: Sets the BoolValue to true, effectively acting as an "on" switch for the ability to fly.
                </li>

                <li>
                boolValue.Parent = player: Attaches the BoolValue to the player object, ensuring that the script responsible for enabling flight can access this value and determine whether to allow the player to fly.
                </li>
            </ul>

            <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Enabling Laser Vision 🔥</h2>
            <ul>
                <li>
                Similar to the flying ability, we're adding functionality for 'Laser Eyes'. This superpower will presumably allow the player to emit a laser beam from their eyes within the game.
                </li>

                <li>
                Within the laserEyes function, you establish a new BoolValue just like for the flying function. This BoolValue named "Lasers" is set to true and parented to the player, signaling that the player has gained the laser eyes ability.
                </li>

                <li>
                This BoolValue acts as a flag for a local script to detect whether the player should be able to use laser vision. When the local script detects that "Lasers" is true, it will execute the code to enable laser vision for the player. We will create said local script shortly.
                </li>

                <li>
                By structuring your superpowers in this way, you're creating a scalable system where new abilities can be added and managed easily. Each superpower has a corresponding BoolValue that local scripts can reference, ensuring that powers can be turned on and off independently from the local scripts.
                </li>

                <li>
                local boolValue = Instance.new("BoolValue"): Creates a new BoolValue object.
                </li>

                <li>
                boolValue.Name = "Lasers": Sets the name of the BoolValue to "Lasers".
                </li>

                <li>
                boolValue.Value = true: Activates the BoolValue by setting it to true.
                </li>

                <li>
                boolValue.Parent = player: Attaches the BoolValue to the player, making it accessible for local scripts.
                </li>
            </ul>

            <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: Implementing Teleportation ⚡</h2>
            <ul>
                <li>
                The teleportation function is being prepared to give players the power to teleport. This powerful ability can allow players to move instantly between two points within the game.
                </li>

                <li>
                Again, a BoolValue named "Teleport" is created, set to true, and then parented to the player. This indicates that the teleportation ability has been granted to the player.
                </li>

                <li>
                As with the previous powers, this BoolValue will be used by a local script to check if the player can teleport. If the "Teleport" value is true, the local script will allow the player to teleport, potentially with a cool visual effect or animation.
                </li>

                <li>
                Creating a separate BoolValue for each power maintains a clean and manageable codebase, where each ability's activation is controlled independently.
                </li>

                <li>
                local boolValue = Instance.new("BoolValue"): Instantiates a new BoolValue.
                </li>

                <li>
                boolValue.Name = "Teleport": Names the BoolValue "Teleport" for identification.
                </li>

                <li>
                boolValue.Value = true: Sets the boolean to true, indicating the power is available.
                </li>

                <li>
                boolValue.Parent = player: Sets the player as the parent of the BoolValue to track which powers the player has.
                </li>
            </ul>

            <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: Listing Power Keys 🗝️</h2>
            <ul>
                <li>
                First, we create a local table powerKeys. This table is intended to hold the keys (or names) of each power, which corresponds to the functions we've defined in the powers table.
                </li>

                <li>
                local powerKeys = {}: Initializes an empty table to store the power keys.
                </li>
            </ul>

            <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15: For Loop 🔄</h2>
            <ul>
                <li>
                We use a for loop with pairs(powers) to iterate over the powers table. The pairs function is a Lua standard for iterating over tables. It allows us to go through each key-value pair in the powers table.
                </li>

                <li>
                Inside the loop, we use table.insert(powerKeys, key) to add each key to the powerKeys table. This table will be used to randomly select a power to give to the player, ensuring that we can access all available powers without hard-coding their names elsewhere.
                </li>

                <li>
                for key, _ in pairs(powers) do: for starts the loop, key holds the current key from the powers table, _ is a placeholder for the value which we're not using here, and pairs(powers) is the iterator function over the powers table.
                </li>

                <li>
                table.insert(powerKeys, key): Inserts the current key into the powerKeys table.
                </li>
            </ul>

            <img src={fifteen} className='demo__image' alt='img_fifteen' />
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 16: Removing Existing Powers 🚫</h2>
            <ul>
                <li>
                We define a function removeExistingPowers(player) to ensure that a player can only have one superpower at a time. This function will be called before a new power is assigned to remove any previously assigned powers.
                </li>

                <li>
                This function will go through the powerKeys table and check each power. If a player has a BoolValue corresponding to a power (indicating they currently possess that power), it will be removed, thus deactivating the power.
                </li>

                <li>
                The purpose of this function is to maintain game balance and prevent a single player from accumulating multiple powers, which could disrupt gameplay.
                </li>

                <li>
                function removeExistingPowers(player) end: Declares a new function called removeExistingPowers that will take a player object as an argument.
                </li>
            </ul>

            <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 17: Identifying Active Power BoolValues 🕵️‍♂️</h2>
            <ul>
                <li>
                Inside the removeExistingPowers function, you start by creating a list of power names with local powerNames = &#10100;"fly", "lasers", "teleport"&#10101;. This array holds the names of the BoolValue objects that represent each power.            </li>

                <li>
                Notably, JumpPower and WalkSpeed are not included in this list. This is because they are properties of the Humanoid, not separate BoolValue objects. We can directly modify these properties without needing to track them as individual powers.
                </li>

                <li>
                By listing only the powers that are represented by BoolValue objects, you are setting up a targeted approach for the removal process. This ensures that when you're ready to remove a power, you're specifically looking for those that have a BoolValue indicator in the player's object model.
                </li>

                <li>
                local powerNames = &#10100;"fly", "lasers", "teleport"&#10101; Initializes a local table with strings corresponding to the names of the BoolValue objects associated with each power.
                </li>
            </ul>

            <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 18: Preparing to Remove Powers 🔄</h2>
            <ul>
                <li>
                Now that you have identified which BoolValue objects to look for, the next step is to prepare to loop through each power name. This is where you'll check if the player has any of these BoolValue objects.
                </li>

                <li>
                The loop setup uses for _, powerName in ipairs(powerNames) do, which will iterate over the powerNames array. The underscore _ is used as a placeholder for the index number, which isn't needed here—only the power names are important.
                </li>

                <li>
                This loop is crucial as it establishes the mechanism to cycle through each power and check for its presence on the player. If found, the next steps would involve removing them, which would be detailed in the following step.
                </li>
            </ul>

            <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 19: Looping for Power Removal 🗑️</h2>
            <ul>
                <li>
                Within the loop, you would use the FindFirstChild method to see if the player has the BoolValue for each power. The method player:FindFirstChild(powerName) attempts to find a child of player with the given name.
                </li>

                <li>
                If FindFirstChild returns a BoolValue, it means the player currently has this power activated. You can then deactivate the power by either setting the BoolValue.Value to false or removing it entirely from the player.
                </li>

                <li>
                local existingPower = player:FindFirstChild(powerName): Searches for a BoolValue by the name of the power.
                </li>
            </ul>

            <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 20: Removing Existing Powers 🗑️</h2>
            <ul>
                <li>
                The removeExistingPowers function's role is to clear any active powers from the player. In the loop, we check if the player has a BoolValue corresponding to a power by using player:FindFirstChild(powerName).
                </li>

                <li>
                if existingPower then existingPower:Destroy() end: This condition checks if existingPower is not nil (i.e., the power exists on the player). If it does, the Destroy method is called to remove it.
                </li>
            </ul>

            <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 21: Connecting Player Addition Event 🔄</h2>
            <ul>
                <li>
                The script connects to the PlayerAdded event of the Players service using game.Players.PlayerAdded:Connect(function(player)). This ensures that a given set of actions will be performed every time a new player joins the game.
                </li>

                <li>
                game.Players.PlayerAdded:Connect(function(player)) end: This line sets up a new event listener that will run the provided function whenever a new player is added to the game.
                </li>
            </ul>
            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 22: Connecting Character Addition Event 🔄</h2>
            <ul>
                <li>
                A nested connection is made to the CharacterAdded event within the PlayerAdded event listener. This is done with player.CharacterAdded:Connect(function(character)).
                </li>

                <li>
                This setup allows the script to perform actions specifically when a player's character is created or respawns in the game. It's a common practice to set up character-specific powers or settings within this event.
                </li>

                <li>
                player.CharacterAdded:Connect(function(character)) end: Adds an event listener to the player's CharacterAdded event, which triggers when the player's character spawns.
                </li>
            </ul>

            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 23: Handling Character Respawn 🛑</h2>
            <ul>
                <li>
                After a player's character has been added to the game, you need to handle what happens when the character dies. The character.Humanoid.Died:Connect(function()) is a listener that triggers a series of actions when the character's humanoid dies.            
                </li>

                <li>
                Inside the connected function, you first reset the humanoid's WalkSpeed and JumpPower to their default values: character.Humanoid.WalkSpeed = 16 and character.Humanoid.JumpPower = 50. This is important because when a character respawns, they should start with standard abilities, ensuring consistent gameplay.
                </li>

                <li>
                The removeExistingPowers(player) function is then called to ensure that any previously assigned powers are removed from the player upon death. This clears the state, preventing the carryover of powers after respawn, which could lead to unexpected game behavior or unfair advantages.
                </li>

                <li>
                character.Humanoid.Died:Connect(function()): This sets up an event listener for the humanoid's death event.
                </li>

                <li>
                character.Humanoid.WalkSpeed = 16: Resets the WalkSpeed property to the Roblox default value.
                </li>

                <li>
                character.Humanoid.JumpPower = 50: Resets the JumpPower property to the Roblox default value.
                </li>

                <li>
                removeExistingPowers(player): Calls a function to remove any active power indicators from the player.
                </li>

                <li>
                By ensuring that all special abilities are reset upon character death, the game maintains balance and fairness. Each respawn is a fresh start, and players will need to acquire powers again to enhance their character's abilities.
                </li>
            </ul>

            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 24: Random Power Assignment 🎲</h2>
            <ul>
                <li>
                After a player's character dies and respawns, we want to spice things up by giving them a random power!
                </li>

                <li>
                Create a variable named index which gets a random number between 1 and the length of your powerKeys table. This will select a random index from the table.
                </li>

                <li>
                With this index, get the corresponding power key from powerKeys and store it in a new variable called powerKey.
                </li>

                <li>
                Use the powerKey to get the specific power function from the powers table and store it in powerFunc.
                </li>
            </ul>

            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 25: Patience is a Virtue - Waiting for Respawn 🔄</h2>
            <ul>
                <li>
                To ensure that the powers are only assigned after the character has respawned, we need to add a waiting mechanism.
                </li>

                <li>
                Insert a repeat...until loop right after the variables you just created.
                </li>

                <li>
                This loop will make the script wait until the player's Character is not nil and the Humanoid object is found within the character. This means the player has respawned.
                </li>
            </ul>

            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 26: Activate the Power! 💥</h2>
            <ul>
                <li>
                Call the removeExistingPowers function to clear any previous powers the player had. This keeps things clean and ensures no power stacking.
                </li>

                <li>
                Then, call the powerFunc with player as the argument to give the player their new random power.
                </li>

                <li>
                After your repeat...until loop, place the following code to remove existing powers and apply the new one.
                </li>
            </ul>

            <img src={twentySix} className='demo__image' alt='img_twentySix' />
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 27: Beginning the LocalScript 📝</h2>
            <ul>
                <li>
                    Now that we completed the powerScript we need to write the corresponding LocalScripts for each power that requires one; flying, laser eyes, and teleportation to name a few. These three powers in the powerScript give the player a "BoolValue" indicating that they should have said power. Now we need to create the LocalScript for each of those three powers that checks: does this player have the boolean for flying, lasers, or teleportation? If so, allow them to use that power with the LocalScript.
                </li>

                <li>
                    This LocalScript will be fore the flying power.
                </li>

                <li>
                    Locate the "StarterPlayerScripts" folder and add a "LocalScript".
                </li>
            </ul>

            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 28: Name It 🐦</h2>
            <ul>
                <li>
                    Rename the newly added "LocalScript" to "flyScript" to reflect which power this "LocalScript" is for.
                </li>
            </ul>

            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 29: Activating User Input Service 🎮</h2>
            <ul>
                <li>
                In this step, we are setting up the foundation to allow players to interact with our game. We're enabling the User Input Service, which is crucial for detecting player inputs like keyboard strokes or mouse clicks.
                </li>

                <li>
                This line creates a local variable called UserInputService. The game:GetService("UserInputService") part tells Roblox to fetch the service that handles user inputs, such as keyboard and mouse actions.
                </li>

                <li>
                Make sure you spell "UserInputService" correctly, as Lua is case-sensitive, and errors in service names can cause your script to break!
                </li>
            </ul>

            <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 30: The Players Service 🧍‍♂️</h2>
            <ul>
                <li>
                Now we're going to grab another essential service called Players. This service lets us interact with the people in our game.
                </li>

                <li>
                You now have a local variable called Players that holds the Players service, enabling you to access player-related properties and events.
                </li>

                <li>
                You now have a local variable called Players that holds the Players service, enabling you to access player-related properties and events.
                </li>
            </ul>

            <img src={thirty} className='demo__image' alt='img_thirty' />
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 31: Setting Up Flight Variables ✈️</h2>
            <ul>
                <li>
                We're about to give our players the super cool ability to fly! To do this, we need to define some basic variables.
                </li>

                <li>
                The flySpeed variable determines how fast the player will fly, and flying is a boolean that we'll use to check if the player is currently flying or not.
                </li>

                <li>
                Set flySpeed to a comfortable number 50 is a good start. If it's too slow or too fast, you can come back and change it later. flying should start as false because the player won't be flying until they use the controls to fly.
                </li>
            </ul>

            <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 32: Identifying the Player Character 🚶‍♂️🔍</h2>
            <ul>
                <li>
                Now, we are going to ensure that our script recognizes the player's character in the game. This is essential for later applying the flying effect to the correct avatar.
                </li>

                <li>
                Here, local player will store the player's character, and player.CharacterAdded:Wait() will pause the script until the player's character has been added to the game. This is important for synchronization purposes!
                </li>
            </ul>

            <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>   
            <h2>Step 33: Preparing for Takeoff 🛫</h2>
            <ul>
                <li>
                It's time to create a function that will allow our players to take flight!
                </li>

                <li>
                This empty startFly function will later contain the code that lets players fly.
                </li>
            </ul>

            <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 34: Activating Flight Mode 🚀</h2>
            <ul>
                <li>
                This line sets the flying variable to true, which we'll use as a signal to trigger the flying behavior in our game. When flying is true, our script will know that the player should be... well, flying!
                </li>
            </ul>

            <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 35: Attaching to the Humanoid 🤖</h2>
            <ul>
                <li>
                In this step, we’re going to attach a script to the player’s humanoid object. The humanoid is a special object that controls the characters in Roblox, including their health, animations, and whether they're standing on a platform.
                </li>

                <li>
                local humanoid is a new variable that stores the humanoid object from the player's character.
                </li>

                <li>
                player.Character:FindFirstChildOfClass("Humanoid") searches the player's character for the first object that is a "Humanoid" class.
                </li>

                <li>
                humanoid.PlatformStand = true prevents the character from falling over while flying by setting the PlatformStand property to true, which makes the humanoid stand on an invisible platform.
                </li>
            </ul>

            <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 36: Enabling Body Velocity 🌬️</h2>
            <ul>
                <li>
                Now, we'll add a BodyVelocity object to the player’s character. This object will allow us to control the velocity at which the player moves while flying, essentially controlling the flight speed and direction.
                </li>

                <li>
                Instance.new("BodyVelocity", player.Character.PrimaryPart) creates a new BodyVelocity object and parents it to the primary part (usually the torso) of the player's character.
                </li>

                <li>
                bodyVelocity.Velocity = Vector3.new(0, 0, 0) sets the initial velocity to 0 on all axes, meaning the character won't move until we use one of the buttons to fly in a certain direction.
                </li>

                <li>
                bodyVelocity.MaxForce = Vector3.new(math.huge, math.huge, math.huge) ensures that the force that can be applied to move the player is virtually unlimited in all directions.
                </li>
            </ul>

            <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 37: Preparing for Player Input 🎛️</h2>
            <ul>
                <li>
                We're preparing to handle player input to control the flight. We do this by setting up an input handler function that will listen for any keypresses or mouse clicks from the player.
                </li>

                <li>
                local function onInput(input, isProcessed) defines a local function named onInput. It will be used to handle all incoming player inputs.
                </li>

                <li>
                input is a parameter that will contain information about the key or button the player pressed.
                </li>

                <li>
                isProcessed is a boolean parameter that indicates whether the input has already been processed by another script, which is useful to prevent double-handling of the same input.
                </li>
            </ul>

            <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 38: Processing Player Input for Direction 🧭</h2>
            <ul>
                <li>
                In this step, we begin to process the player's keyboard input to determine the direction of flight. This is crucial for a responsive and intuitive flying experience.
                </li>

                <li>
                Inside your onInput function, start by checking if the input hasn't been processed with.
                </li>

                <li>
                The Vector3.new() creates a new vector with x, y, and z components, representing three-dimensional space in Roblox. Here, it's initialized to zero by default.
                </li>
            </ul>

            <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 39: Adding Movement Logic 🕹️</h2>
            <ul>
                <li>
                Now we will add the actual movement logic based on the player’s input. This involves checking which key is pressed and modifying the direction variable accordingly.
                </li>

                <li>
                if input.KeyCode == Enum.KeyCode.D checks if the 'D' key is pressed.
                </li>

                <li>
                direction = direction + Vector3.new(0, 0, -2) adjusts the direction vector. The Vector3.new(0, 0, -2) specifies that pressing 'D' should move the character to the right (negative on the Z-axis in Roblox’s coordinate system).
                </li>
            </ul>

            <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 40: Expanding Directional Controls 🎮⬆️⬇️⬅️➡️</h2>
            <ul>
                <li>
                To create a full range of movement, we need to account for all the directional keys: W, A, S, D, Space (for upward movement), and LeftShift (for downward movement).
                </li>

                <li>
                Continue adding more elseif conditions for each key within the onInput function to make sure that players can fly in all directions.
                </li>

                <li>
                Each elseif block adds to the direction vector based on the key pressed.
                </li>

                <li>
                By setting up these conditions, we allow the player to move in any direction by pressing the corresponding keys. The values you add to the direction vector determine the speed and direction of movement. The direction vector will later be applied to the BodyVelocity to move the character in the game world.
                </li>
            </ul>
            <img src={forty} className='demo__image' alt='img_forty' />
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 41: Applying Velocity to Flight 🛸</h2>
            <ul>
                <li>
                After setting up the direction based on the player's input, it's time to apply this direction as a velocity to the player's character to actually move them through the game world.
                </li>

                <li>
                bodyVelocity.Velocity sets the velocity of the BodyVelocity object we attached to the player's character.
                </li>

                <li>
                direction * flySpeed multiplies the direction vector by the flying speed, scaling the movement speed accordingly. The direction vector determines the direction the player will move based on the input, while flySpeed is a scalar value that controls how fast the player moves.
                </li>
            </ul>

            <img src={fortyOne} className='demo__image' alt='img_fortyOne' />
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 42: Connecting the Input Event 🎮🔗</h2>
            <ul>
                <li>
                To make the script respond to player inputs, we must connect our input handling function to the UserInputService. This is what listens for any keypresses or mouse clicks.
                </li>

                <li>
                UserInputService.InputBegan is an event that fires whenever a user begins an input action, like pressing a key.
                </li>

                <li>
                :Connect(onInput) connects our onInput function to the InputBegan event, so that onInput is called whenever the player presses a key.
                </li>
            </ul>

            <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 43: Detecting When Input Ends 🎮✋</h2>
            <ul>
                <li>
                It's not enough to know when a player starts pressing a key; we also need to know when they stop. This allows us to halt movement when the key is released.
                </li>

                <li>
                UserInputService.InputEnded is an event that fires when the player stops pressing a key.
                </li>

                <li>
                :Connect(function(input) end) connects an anonymous function to the InputEnded event. Inside this function, we will later add code to handle the stopping of input, which will affect the player's movement.
                </li>

                <li>
                By handling both the start and the end of inputs, we create a smooth and responsive control system for the player's flight.
                </li>
            </ul>

            <img src={fortyThree} className='demo__image' alt='img_fortyThree' />
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 44: Halting Movement on Key Release 🛑✋</h2>
            <ul>
                <li>
                When the player releases a movement key, we need to stop the character's movement. To do this, we will reset the BodyVelocity to zero when none of the movement keys are being pressed.
                </li>

                <li>
                In the InputEnded event connection, inside the anonymous function, add a conditional statement.
                </li>

                <li>
                We check if any of the keys related to movement (W, A, S, D, Space, LeftShift) have been released.
                </li>

                <li>
                If true, we set the bodyVelocity.Velocity to a zero vector Vector3.new(0, 0, 0), which stops the character from moving.
                </li>
            </ul>

            <img src={fortyFour} className='demo__image' alt='img_fortyFour' />
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 45: Defining the stopFly Function 🚦🛑</h2>
            <ul>
                <li>
                To handle stopping the flight, we will define a stopFly function that resets the flying state and cleans up any objects or settings related to flying.
                </li>

                <li>
                Below your InputEnded connection, define this function.
                </li>
            </ul>

            <img src={fortyFive} className='demo__image' alt='img_fortyFive' />
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 46: Executing the Landing Sequence 🛬</h2>
            <ul>
                <li>
                Within the stopFly function, we will perform the necessary actions to stop the player from flying and ensure they can walk normally again.
                </li>

                <li>
                flying = false changes the state to indicate the player is no longer flying.
                </li>

                <li>
                local humanoid = player.Character:FindFirstChildOfClass("Humanoid") retrieves the humanoid object from the player's character again, as we will change its properties.
                </li>

                <li>
                humanoid.PlatformStand = false resets the humanoid's PlatformStand property to false, allowing the character to walk and be affected by physics again.
                </li>

                <li>
                player.Character.PrimaryPart.BodyVelocity:Destroy() removes the BodyVelocity object from the player's character, halting any residual movement from the flight.
                </li>

                <li>
                By defining stopFly, we create a method to safely transition the player from flying back to walking, ensuring a smooth gameplay experience. This function will be called when the flight should be terminated, such as when the player lands or a certain condition to disable flight is met.
                </li>
            </ul>

            <img src={fortySix} className='demo__image' alt='img_fortySix' />
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 47: Toggling Flight with a Key 🎮🔑</h2>
            <ul>
                <li>
                To allow the player to start or stop flying by pressing a specific key, we introduce a toggleFly function that switches between the startFly and stopFly states.
                </li>

                <li>
                This function checks the current state of flying. If flying is true, it calls stopFly() to land the player; if flying is false, it calls startFly() to take off.
                </li>
            </ul>

            <img src={fortySeven} className='demo__image' alt='img_fortySeven' />
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 48: Linking the Toggle to User Input 🎮⚙️</h2>
            <ul>
                <li>
                In this step, we establish the connection between the user pressing a key and the action of toggling the flight mode on or off. This is done by listening to the input event and triggering the toggleFly function when the correct key is pressed.
                </li>

                <li>
                This code snippet is creating an anonymous function that will be called every time a user begins an input action, such as pressing a key. The input argument contains the details of that input event, and isProcessed indicates whether the input has already been acted upon by a different part of the program.
                </li>
            </ul>

            <img src={fortyEight} className='demo__image' alt='img_fortyEight' />
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 49: Checking for the Fly Toggle Key 🛫🔍</h2>
            <ul>
                <li>
                Now we need to decide what happens inside that anonymous function when the InputBegan event occurs. Specifically, we want to toggle flying when the 'F' key is pressed.
                </li>

                <li>
                if not isProcessed checks if the input event has not been handled by another part of the program. If it's true, it means the input is "fresh" and we can safely act on it.
                </li>

                <li>
                input.KeyCode == Enum.KeyCode.F checks if the input received is specifically the 'F' key being pressed.
                </li>

                <li>
                toggleFly() calls the function we defined earlier, which will switch the player's flying state based on whether they are currently flying or not.
                </li>

                <li>
                The combination of these lines of code means that when the player presses the 'F' key, and no other script has already handled this keypress, we will toggle the player's ability to fly. This makes 'F' the "flight toggle key" in our game, providing an intuitive and easy way for players to switch between walking and flying modes.
                </li>
            </ul>

            <img src={fortyNine} className='demo__image' alt='img_fortyNine' />
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 50: Debriefing 📁</h2>
            <ul>
                <li>
                    Let's review what we have accomplished thus far. So far, the "flying" function in the "powerScript" give the player a BoolValue called "fly" and the "LocalScript" we just wrote checks to see if the player has this "BoolValue" and if so allows them to fly. In the image below there is a check mark next to the boxed in "fly" function because we are done with this and its corresponding "LocalScript".
                </li>

                <li>
                    The next function "laserEyes" also gives the player a "BoolValue" (this one named "lasers") and needs a corresponding "LocalScript" to check if the user has the "lasers" BoolValue to activate a lasers LocalScript giving them the actual laser eyes.
                </li>
            </ul>

            <img src={fifty} className='demo__image' alt='img_fifty' />
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 51: Another LocalScript 📝</h2>
            <ul>
                <li>
                    Locate the "StarterPlayerScripts" folder so that we can start the laser eyes local script. Add a "LocalScript" into this folder, next to the "flyScript".
                </li>
            </ul>

            <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 52: laserScript 💥</h2>
            <ul>
                <li>
                    Rename the newly added script to "laserScript" to properly reflect what this script is for.
                </li>
            </ul>

            <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 53: Initialize User Input Service 🌟</h2>
            <ul>
                <li>
                In this step, we're setting up the groundwork to handle player inputs. Make sure you've opened your Roblox Studio and you're in the script where you want to detect user input.
                </li>

                <li>
                Type in local UserInputService = game:GetService("UserInputService") into your script. This line of code creates a local variable called UserInputService which will store the User Input Service from the game. It allows us to detect and manage user inputs like keyboard presses or mouse clicks!
                </li>
            </ul>

            <img src={fiftyThree} className='demo__image' alt='img_fiftyThree' />
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 54: Access the Players Service 🚀</h2>
            <ul>
                <li>
                Now that we can handle inputs, let's make sure we can interact with players in the game.
                </li>

                <li>
                Below your previous code, add local Players = game:GetService("Players"). This new line is creating another local variable named Players, which grabs the Players service. This service is essential for getting information about the players in your game, like their characters, their names, and more! 
                </li>
            </ul>

            <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 55: Get the Local Player and Mouse 🎮</h2>
            <ul>
                <li>
                Next up, we're diving into player-specific details.
                </li>

                <li>
                These lines are quite crucial. The first one gets the LocalPlayer, which is the player who is running the script on their computer. The second line then gets the mouse of that player. You'll use these to track the player's mouse movements and clicks in your game for when the player is trying to shoot lasers!
                </li>
            </ul>

            <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 56: Crafting the Laser Shooting Function 🔥</h2>
            <ul>
                <li>
                Time to make things exciting! We're going to create a function that allows our player to shoot lasers!
                </li>

                <li>
                Write a function named shootLaser by typing function shootLaser() and end it with end. This will be our container for the laser shooting code.
                </li>

                <li>
                Inside the function, connect this function to the mouse's Button1Down event by adding mouse.Button1Down:Connect(shootLaser) below the end. This means whenever the left mouse button is clicked, our shootLaser function will be called. 
                </li>
            </ul>

            <img src={fiftySix} className='demo__image' alt='img_fiftySix' />
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 57: Getting the Player's Character 🤖</h2>
            <ul>
                <li>
                Inside the shootLaser function, add local character = player.Character. This line fetches the player's character, which is their avatar in the game. We'll need this to know where to shoot the laser from! 
                </li>
            </ul>

            <img src={fiftySeven} className='demo__image' alt='img_fiftySeven' />
        </div>

        <div style={currentStep == 58 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 58: Safety Check Before Shooting 🛡️</h2>
            <ul>
                <li>
                Still inside the shootLaser function, add a safety check: if character and player.lasers.Value then. This line checks two things: If the player has a character (they're in the game) and if they have lasers enabled (a custom property we assume is set up in the player). Only if both are true, we'll let the shooting happen. This prevents errors and ensures everything is good to go! 
                </li>
            </ul>

            <img src={fiftyEight} className='demo__image' alt='img_fiftyEight' />
        </div>

        <div style={currentStep == 59 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 59: Locating the Player's Humanoid and Head 📍</h2>
            <ul>
                <li>
                Inside the shootLaser function, after checking if the character and lasers exist, define two local variables: local humanoid = character:FindFirstChild("Humanoid") and local head = character:FindFirstChild("Head"). These lines make sure we can access the player's humanoid and head, which are essential for positioning the laser correctly. 
                </li>
            </ul>

            <img src={fiftyNine} className='demo__image' alt='img_fiftyNine' />
        </div>

        <div style={currentStep == 60 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 60: Double-Check for Humanoid and Head 🛑</h2>
            <ul>
                <li>
                Safety first! Let's make sure we have all the necessary parts before firing.
                </li>

                <li>
                Add another conditional check within the first if statement: if humanoid and head then. This ensures that both the humanoid and head objects are successfully found in the character before proceeding. Only if this is true will we continue to the next steps. This helps avoid any errors during the laser creation process. 
                </li>
            </ul>

            <img src={sixty} className='demo__image' alt='img_sixty' />
        </div>

        <div style={currentStep == 61 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 61: Creating the Laser ✨</h2>
            <ul>
                <li>
                Now the fun part – it's time to create the laser!
                </li>

                <li>
                Inside the nested if statement, where we've confirmed the existence of the humanoid and head, add a line to create the laser part: local laser = Instance.new("Part", workspace). This line of code conjures a new Part (which will be our laser) and places it in the workspace, which is the main environment where our game objects reside. It's almost time to bring the light show to life!
                </li>
            </ul>

            <img src={sixtyOne} className='demo__image' alt='img_sixtyOne' />
        </div>

        <div style={currentStep == 62 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 62: Styling the Laser 🎨</h2>
            <ul>
                <li>
                Now that we have our laser, let's make it look cool and behave properly!
                </li>

                <li>
                BrickColor to "Bright red" for that classic laser look. 
                </li>

                <li>
                Material to Neon to give it a glow.
                </li>

                <li>
                Anchored to true so it stays in place once shot. 
                </li>

                <li>
                CanCollide to false so it won’t bump into other objects.
                </li>

                <li>
                Size to a thin and long shape, resembling a laser beam.
                </li>

                <li>
                CFrame to position the laser starting from the player's head and pointing to where the mouse cursor is. 
                </li>
            </ul>

            <img src={sixtyTwo} className='demo__image' alt='img_sixtyTwo' />
        </div>

        <div style={currentStep == 63 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 63: Cleaning Up 🧹</h2>
            <ul>
                <li>
                After setting the properties, add a line to clean up the laser after a short time: game:GetService("Debris"):AddItem(laser, 0.2). This will remove the laser from the game after 0.2 seconds to avoid clutter. 
                </li>
            </ul>

            <img src={sixtyThree} className='demo__image' alt='img_sixtyThree' />
        </div>

        <div style={currentStep == 64 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 64: Laser Collision Handling 🎛️</h2>
            <ul>
                <li>
                Below the Debris line, create a local function named onLaserTouch that takes otherPart as a parameter: local function onLaserTouch(otherPart). This function will handle what happens when our laser touches something. 
                </li>

                <li>
                Connect the onLaserTouch function to the laser's Touched event: laser.Touched:Connect(onLaserTouch). Now, whenever our laser makes contact with another object in the game, onLaserTouch will be called
                </li>
            </ul>

            <img src={sixtyFour} className='demo__image' alt='img_sixtyFour' />
        </div>

        <div style={currentStep == 65 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 65: Identifying the Target 🎯</h2>
            <ul>
                <li>
                When our laser hits something, we need to figure out what it hit. Let's set that up!
                </li>

                <li>
                Inside the onLaserTouch function, start by finding out what character the laser has hit with local otherCharacter = otherPart.Parent.
                </li>

                <li>
                Next, grab the humanoid component of that character: local otherHumanoid = otherCharacter:FindFirstChild("Humanoid")
                </li>

                <li>
                Then, identify the player from the character: local otherPlayer = Players:GetPlayerFromCharacter(otherCharacter).
                </li>
            </ul>

            <img src={sixtyFive} className='demo__image' alt='img_sixtyFive' />
        </div>

        <div style={currentStep == 66 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 66: Applying Damage ⚔️</h2>
            <ul>
                <li>
                Now it's time to make the laser do something — like dealing damage to the target!
                </li>

                <li>
                Inside the onLaserTouch function, add a conditional to check if the humanoid and player are valid and not the player who shot the laser: if otherHumanoid and otherPlayer ~= player then.
                </li>

                <li>
                If true, apply damage to the humanoid: otherHumanoid:TakeDamage(10).
                </li>

                <li>
                This way, your laser will harm other players if they get hit, adding some exciting gameplay to your Roblox game!
                </li>
            </ul>

            <img src={sixtySix} className='demo__image' alt='img_sixtySix' />
        </div>

        <div style={currentStep == 67 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 67: The Next LocalScript 📝</h2>
            <ul>
                <li>
                    Navigate to "ServerScriptService" and add another "LocalScript" to start coding out the teleportation power.
                </li>
            </ul>

            <img src={sixtySeven} className='demo__image' alt='img_sixtySeven' />
        </div>

        <div style={currentStep == 68 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 68: Renaming 🏷️</h2>
            <ul>
                <li>
                    Rename the newly added "LocalScript" to "teleportScript" in order to properly reflect its purpose.
                </li>
            </ul>

            <img src={sixtyEight} className='demo__image' alt='img_sixtyEight' />
        </div>

        <div style={currentStep == 69 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 69: Initialize Replicated Storage 🌐</h2>
            <ul>
                <li>
                In this step, we're setting the stage for shared game data. Begin by opening your script and typing the following line of code.
                </li>

                <li>
                This line creates a local variable named ReplicatedStorage which accesses the Roblox service ReplicatedStorage. This service is where you can store objects that should be shared between the server and all clients.
                </li>
            </ul>

            <img src={sixtyNine} className='demo__image' alt='img_sixtyNine' />
        </div>

        <div style={currentStep == 70 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 70: Accessing the Players Service 🎮</h2>
            <ul>
                <li>
                This command hooks up your script to the Players service, which is crucial for managing the players in your game. This service will help us keep track of everyone who is playing your game. 
                </li>
            </ul>

            <img src={seventy} className='demo__image' alt='img_seventy' />
        </div>

        <div style={currentStep == 71 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 71: Grabbing All Players 🎮</h2>
            <ul>
                <li>
                With the Players service available, we can now get a list of all the players.
                </li>

                <li>
                This new line will create a local variable allPlayers that holds a list of all the players currently in the game.
                </li>
            </ul>

            <img src={seventyOne} className='demo__image' alt='img_seventyOne' />
        </div>

        <div style={currentStep == 72 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 72: Integrating User Input Service 🎮⌨️</h2>
            <ul>
                <li>
                This line creates a local variable called UserInputService. This service is crucial for detecting and responding to user inputs, such as keyboard strokes or mouse clicks. By storing it in a variable, we gain the ability to listen for player actions and make our game interactive. The marker shows you where to add this new line in your script.
                </li>

                <li>
                game:GetService("UserInputService") is a method that retrieves the specified service from the Roblox game engine.
                </li>
            </ul>

            <img src={seventyTwo} className='demo__image' alt='img_seventyTwo' />
        </div>

        <div style={currentStep == 73 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 73: Creating a Teleport Event ✨</h2>
            <ul>
                <li>
                We're creating a teleportEvent variable and setting it to an object we expect to find in ReplicatedStorage. The WaitForChild method is used when you're trying to access an object that may not be immediately available because it's being loaded or created. This is especially important for networked events, which might be replicated across the server and clients at different times. 
                </li>

                <li>
                local teleportEvent is the variable that will reference our teleport event.
                </li>

                <li>
                ReplicatedStorage:WaitForChild("TeleportEvent") tells the script to pause and wait until an object named TeleportEvent is found within ReplicatedStorage.
                </li>
            </ul>

            <img src={seventyThree} className='demo__image' alt='img_seventyThree' />
        </div>

        <div style={currentStep == 74 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 74: Setting Up a Function for Key Presses 🌟🖱️</h2>
            <ul>
                <li>
                In this step, we're going to create a function that will later be used to handle key presses, but we're not going to add any specific functionality inside it yet. The function will be named onSpacePress, and we will also make sure it's connected to the InputBegan event, which fires whenever a user starts a new input, like pressing a key.
                </li>
            </ul>

            <img src={seventyFour} className='demo__image' alt='img_seventyFour' />
        </div>

        <div style={currentStep == 75 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 75: Identifying the Spacebar Press 🌌🔑</h2>
            <ul>
                <li>
                In this step, we are creating a conditional statement within our onSpacePress function. The purpose of this if statement is to check whether the player has pressed the spacebar. We do this by comparing input.KeyCode to Enum.KeyCode.Space, which is the enumeration for the spacebar key in Roblox.
                </li>

                <li>
                Enum.KeyCode.Space is the enum value for the spacebar.
                </li>
            </ul>

            <img src={seventyFive} className='demo__image' alt='img_seventyFive' />
        </div>

        <div style={currentStep == 76 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>  
            <h2>Step 76: Iterating Over All Players 🔄</h2>
            <ul>
                <li>
                Here, we're using a for loop to iterate over all players currently in the game. The ipairs function is a Lua iterator that works with arrays or lists; allPlayers is expected to be a list of player objects. The loop will execute the code inside for each player individually.
                </li>

                <li>
                This prepares us to perform actions on each player, which will be specified in subsequent steps.
                </li>
            </ul>

            <img src={seventySix} className='demo__image' alt='img_seventySix' />
        </div>

        <div style={currentStep == 77 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 77: Checking Player's Teleport Status 🔄✅</h2>
            <ul>
                <li>
                In this step, we're adding another if statement inside our loop over all players. This conditional checks a teleport property of each player, which is assumed to be a boolean value. If it is true, it indicates that this player is ready or needs to be teleported.
                </li>

                <li>
                if player.teleport.Value == true then checks if the teleport property of the player object is true.
                </li>
            </ul>

            <img src={seventySeven} className='demo__image' alt='img_seventySeven' />
        </div>

        <div style={currentStep == 78 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 78: Acquiring the Mouse Object 🖱️✨</h2>
            <ul>
                <li>
                Within the function we've established to respond to spacebar presses, we're now creating a reference to the mouse object for the local player.
                </li>

                <li>
                local mouse = Players.LocalPlayer:GetMouse() is a method call that returns the mouse object for the local player, which can then be used to get information such as the current position of the cursor.
                </li>

                <li>
                This step is crucial for obtaining the necessary information from the player's input device, which will be used in subsequent steps to teleport the player. Ie where they point there mouse in the area they want to teleport to.
                </li>
            </ul>

            <img src={seventyEight} className='demo__image' alt='img_seventyEight' />
        </div>

        <div style={currentStep == 79 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 79: Initiating the Teleport Event 🌐</h2>
            <ul>
                <li>
                Now we're adding the logic to fire the teleport event with the position of the mouse's target.
                </li>

                <li>
                if mouse.Target then checks to see if the mouse is currently hovering over a target in the game, which would typically be a part or object in the Roblox world.
                </li>

                <li>
                local position = mouse.Target.Position stores the 3D position of the target the mouse is hovering over.
                </li>

                <li>
                teleportEvent:FireServer(position) uses a RemoteEvent (assumed to be named teleportEvent and previously created in ReplicatedStorage) to send a message to the server with the position to teleport the player to. The script that receives this message will relocate the player from point A to point B, this is the next thing we will do.
                </li>
            </ul>

            <img src={seventyNine} className='demo__image' alt='img_seventyNine' />
        </div>

        <div style={currentStep == 80 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 80: Moving the Player when they Teleport 📝</h2>
            <ul>
                <li>
                    Add a new "Script" into "ServerScriptService" to receive the event fired in the previous script, when the event is received in this script, this is where we will write the code that moves the player from point A to point B.
                </li>
            </ul>

            <img src={eighty} className='demo__image' alt='img_eighty' />
        </div>

        <div style={currentStep == 81 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 81: Rename the Script 🏷️</h2>
            <ul>
                <li>
                    Rename this script to "teleportationActivate" to reflect what this script is for.
                </li>
            </ul>

            <img src={eightyOne} className='demo__image' alt='img_eightyOne' />
        </div>

        <div style={currentStep == 82 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 82: Creating a RemoteEvent for Teleportation 🔧</h2>
            <ul>
                <li>
                In this step, we are setting up a crucial component for our game: a RemoteEvent for teleportation. The Instance.new("RemoteEvent") creates a new RemoteEvent object. This RemoteEvent will be used to communicate between the client and the server, allowing us to trigger teleportation actions from the client side that the server will respond to.
                </li>

                <li>
                local teleportEvent declares a new local variable to store our RemoteEvent.
                </li>

                <li>
                Instance.new("RemoteEvent") creates a new instance of a RemoteEvent.
                </li>
            </ul>

            <img src={eightyTwo} className='demo__image' alt='img_eightyTwo' />
        </div>

        <div style={currentStep == 83 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 83: Naming and Parenting the RemoteEvent 🎮</h2>
            <ul>
                <li>
                Here, we're giving our new RemoteEvent a name and setting its parent to ReplicatedStorage, which is a service in Roblox that holds objects shared across the server and all clients.
                </li>

                <li>
                teleportEvent.Name = "TeleportEvent" assigns the name "TeleportEvent" to our RemoteEvent so it can be easily identified and accessed by scripts.
                </li>

                <li>
                teleportEvent.Parent = ReplicatedStorage sets the parent of the teleportEvent to ReplicatedStorage, ensuring it is replicated across the server and all clients.
                </li>
            </ul>

            <img src={eightyThree} className='demo__image' alt='img_eightyThree' />
        </div>

        <div style={currentStep == 84 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 84: Creating the Teleportation Function and Setting the Connection 🛠️🔗</h2>
            <ul>
                <li>
                In this step, we've written a function named teleportPlayer. This function will handle the teleportation logic, which we will define in the next step. The function expects two parameters: player, which represents the player who needs to be teleported, and position, which will be the destination coordinates.
                </li>

                <li>
                teleportEvent.OnServerEvent:Connect(teleportPlayer) creates a link between the server event and our function, so when the event is triggered, teleportPlayer is called with the appropriate arguments.
                </li>
            </ul>

            <img src={eightyFour} className='demo__image' alt='img_eightyFour' />
        </div>

        <div style={currentStep == 85 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 85: Implementing the Teleportation Logic 🔄</h2>
            <ul>
                <li>
                Now we add the core logic inside the teleportPlayer function. We use a conditional statement to check that the player's character has both a "Humanoid" and a "HumanoidRootPart" before attempting to change the position. If these components exist, we set the CFrame of the HumanoidRootPart to the new position, effectively teleporting the player.
                </li>

                <li>
                if player.Character:FindFirstChild("Humanoid") and player.Character:FindFirstChild("HumanoidRootPart") then ensures that the necessary character components exist.
                </li>

                <li>
                player.Character.HumanoidRootPart.CFrame = CFrame.new(position) teleports the player by setting the position of the HumanoidRootPart to the coordinates specified by position.
                </li>

                <li>
                With this conditional check, we make sure that our teleportation only occurs if the player's character is fully loaded and has the necessary parts to avoid any potential errors.
                </li>
            </ul>

            <img src={eightyFive} className='demo__image' alt='img_eightyFive' />
        </div>

        <div style={currentStep == 86 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 86: Opening the Toolbox 🛠️</h2>
            <ul>
                <li>
                    You see in order to add Zombies into our map we need to find the Roblox approved zombie NPC's in the "Toolbox".
                </li>

                <li>
                    Go ahead and start this process by opening the "Toolbox".
                </li>
            </ul>

            <img src={eightySix} className='demo__image' alt='img_eightySix' />
        </div>

        <div style={currentStep == 87 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 87: Locate the Drooling Zombie 🧟</h2>
            <ul>
                <li>
                    This step is very important that you add the same zombie model in the screen below.
                </li>

                <li>
                    Within the "Toolbox" search bar, enter "zombie" and select the one labeled as "Drooling Zombie".
                </li>

                <li>
                    After adding it to your workspace double check the image below to ensure that it is the same one.
                </li>
            </ul>

            <img src={eightySeven} className='demo__image' alt='img_eightySeven' />
        </div>

        <div style={currentStep == 88 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 88: Let's Stay Organized 📂</h2>
            <ul>
                <li>
                    Add a new "Folder" into your workspace.
                </li>
            </ul>

            <img src={eightyEight} className='demo__image' alt='img_eightyEight' />
        </div>

        <div style={currentStep == 89 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 89: Folder Adjustments 📁</h2>
            <ul>
                <li>
                    Rename the "Folder" that you just added to "Zombie".
                </li>

                <li>
                    Then, drag and drop the "Drooling Zombie" model into this renamed folder.
                </li>
            </ul>

            <img src={eightyNine} className='demo__image' alt='img_eightyNine' />
        </div>

        <div style={currentStep == 90 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 90: Building Zombie Spawners ⭐</h2>
            <ul>
                <li>
                    Now instead of duplicating the original zombie dozens of times, we are going to build zombie spawners so that they regularly spawn through out the game; giving players constant enemies to battle.
                </li>

                <li>
                    Start this process by adding a "Part" into the workspace that will serve as a spawner.
                </li>
            </ul>

            <img src={ninety} className='demo__image' alt='img_ninety' />
        </div>

        <div style={currentStep == 91 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 91: Spawner Properties 🎨</h2>
            <ul>
                <li>
                    Let's configure the properties of this part to coincide with a zombie spawner.
                </li>

                <li>
                    Rename the "Part" to "zombieSpawner".
                </li>

                <li>
                    Anchor the spawner.
                </li>

                <li>
                    Set the "Transparency" to "1".
                </li>
            </ul>

            <img src={ninetyOne} className='demo__image' alt='img_ninetyOne' />
        </div>

        <div style={currentStep == 92 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 92: Spawner Collisions 💥</h2>
            <ul>
                <li>
                    Turn off the CanCollide property so that when zombie spawn from the spawner they do not get stuck inside of the block.
                </li>
            </ul>

            <img src={ninetyTwo} className='demo__image' alt='img_ninetyTwo' />
        </div>

        <div style={currentStep == 93 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 93: Another Folder 📁</h2>
            <ul>
                <li>
                    Add another folder into the workspace, this folder will later contain the spawners that we spread throughout the map, as there will later be multiple of them.
                </li>
            </ul>

            <img src={ninetyThree} className='demo__image' alt='img_ninetyThree' />
        </div>

        <div style={currentStep == 94 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 94: Spawner Properties 📁</h2>
            <ul>
                <li>
                    Rename this folder to "spawners" and place the first "zombieSpawner" inside of it.
                </li>
            </ul>

            <img src={ninetyFour} className='demo__image' alt='img_ninetyFour' />
        </div>

        <div style={currentStep == 95 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 95: Zombie Spawner Script 📜</h2>
            <ul>
                <li>
                    The spawners won't spawn zombies themselves! We must code them, and in order to do that, add a script into the spawner block.
                </li>
            </ul>

            <img src={ninetyFive} className='demo__image' alt='img_ninetyFive' />
        </div>

        <div style={currentStep == 96 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 96: Starting Your Spawn Zombie Function 🧟‍♂️✨</h2>
            <ul>
                <li>
                Great job on getting this far! Now, we're going to start with a new function to spawn zombies in our game. 
                </li>

                <li>
                Type in the following to create a new function: function spawnZombie().
                </li>

                <li>
                You've just set up a container for our zombie-spawning code. It's like telling the game, "Hey, I'm about to give you instructions on how to bring zombies to life!"
                </li>
            </ul>

            <img src={ninetySix} className='demo__image' alt='img_ninetySix' />
        </div>

        <div style={currentStep == 97 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 97: Finding Your Zombie Model 🕵️‍♂️</h2>
            <ul>
                <li>
                Now, let's locate our zombie model in the game's workspace.
                </li>

                <li>
                Inside the spawnZombie function, write local zombie = game.Workspace.Zombie:GetChildren()[1].
                </li>

                <li>
                This line of code means, "Look in the game's workspace, find the folder named 'Zombie', and get its first child."
                </li>
            </ul>

            <img src={ninetySeven} className='demo__image' alt='img_ninetySeven' />
        </div>

        <div style={currentStep == 98 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 98: Cloning Your Zombie 🧬</h2>
            <ul>
                <li>
                This new line is the command that duplicates our zombie, so we have one to work with without changing the original. It's like photocopying your favorite comic book so you can keep the original safe! 
                </li>
            </ul>

            <img src={ninetyEight} className='demo__image' alt='img_ninetyEight' />
        </div>

        <div style={currentStep == 99 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 99: Placing the Zombie Clone in the World 🌍</h2>
            <ul>
                <li>
                With our zombie clone ready, let's put it into the game world.
                </li>

                <li>
                This tells the game, "Take our zombie clone and put it in the "Clones" folder inside the workspace."
                </li>

                <li>
                    In the next step, we will add the "Clones" folder.
                </li>
            </ul>

            <img src={ninetyNine} className='demo__image' alt='img_ninetyNine' />
        </div>

        <div style={currentStep == 100 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 100: Clones Folder 📁</h2>
            <ul>
                <li>
                    Look the side at the "Explorer" tab and add a new "Folder".
                </li>

                <li>
                    Rename this folder to "Clones" as this folder will contain the zombies that are cloned in the spawner script.
                </li>
            </ul>

            <img src={hundred} className='demo__image' alt='img_oneHundred' />
        </div>

        <div style={currentStep == 101 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 101: Positioning Your Zombie Clone 🧟📍</h2>
            <ul>
                <li>
                To set the position, add zombieClone.Torso.CFrame = game.Workspace.zombieSpawner.CFrame
                </li>

                <li>
                This line sets the position of the zombie's torso to match that of the 'zombieSpawner' object in the workspace.
                </li>

                <li>
                It's like teleporting your zombie to the right spot on the map where it's supposed to appear. Be precise! Zombies can't just spawn in walls or trees, right?
                </li>
            </ul>

            <img src={hundredOne} className='demo__image' alt='img_oneHundredOne' />
        </div>

        <div style={currentStep == 102 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 102: Creating an Endless Zombie Horde 🌊</h2>
            <ul>
                <li>
                Below your function, type while true do.
                </li>
                
                <li>
                This is like flipping the switch on the zombie machine to 'on' and never turning it off. Zombies will keep coming and coming! 
                </li>
            </ul>

            <img src={hundredTwo} className='demo__image' alt='img_oneHundredTwo' />
        </div>

        <div style={currentStep == 103 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 103: Adding Randomness to Zombie Spawning 🎲</h2>
            <ul>
                <li>
                Inside the loop, declare a wait time with local waitTime = math.random(1, 4).
                </li>

                <li>
                Then, make the script wait for that random time using wait(waitTime).
                </li>

                <li>
                After the wait, call the function with spawnZombie().
                </li>

                <li>
                The whole loop now reads: "Wait a random time, then spawn a zombie, and repeat forever."
                </li>
            </ul>

            <img src={hundredThree} className='demo__image' alt='img_oneHundredThree' />
        </div>

        <div style={currentStep == 104 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 104: More Zombie Spawners 🐑🧟</h2>
            <ul>
                <li>
                    Navigate back to the workspace and duplicate the zombieSpawner to create more, make sure all of the duplicates and the original are located in the "Folder" seen in the image below. You must add this folder to the explorer tab.
                </li>
            </ul>

            <img src={hundredFour} className='demo__image' alt='img_oneHundredFour' />
        </div>

        <div style={currentStep == 105 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 105: Spawners Folder 📁</h2>
            <ul>
                <li>
                    The "Folder" added in the previous step needs a name, rename it to "Spawners".
                </li>
            </ul>

            <img src={hundredFive} className='demo__image' alt='img_oneHundredFive' />
        </div>

        <div style={currentStep == 106 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 106: ServerScriptService Script 📜</h2>
            <ul>
                <li>
                    Locate the "ServerScriptService" in the "Explorer" tab and add a "Script".
                </li>
            </ul>

            <img src={hundredSix} className='demo__image' alt='img_oneHundredSix' />
        </div>

        <div style={currentStep == 107 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 107: Leaderboard Script 📜🏷️</h2>
            <ul>
                <li>
                    Rename the newly added script to "leaderboardScript" to properly reflect that this script will setup the leaderboard whenever a player joins the game, thus allowing them to track how many zombie kills they have as they player and display who has the most.
                </li>
            </ul>

            <img src={hundredSeven} className='demo__image' alt='img_oneHundredSeven' />
        </div>

        <div style={currentStep == 108 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 108: Initialize Player Service 🌟</h2>
            <ul>
                <li>
                In this step, we're setting up our game to recognize and manage players. To do this, we first create a variable named Players that will store the player service. This service is essential for tracking players in our game.
                </li>

                <li>
                Type local Players = game:GetService("Players") into your script. This line of code is making a call to the game to get the Players service and storing it in a variable named Players.
                </li>

                <li>
                Great! Now our game knows that we’ll be working with players! 
                </li>
            </ul>

            <img src={hundredEight} className='demo__image' alt='img_oneHundredEight' />
        </div>

        <div style={currentStep == 109 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 109: Set Up Player Added Event 🎣</h2>
            <ul>
                <li>
                Next, we'll create a function to handle new players when they join the game. This is like setting a welcome mat for new arrivals!
                </li>

                <li>
                Write the function definition function onPlayerAdded(player). This creates a new function named onPlayerAdded which will be triggered whenever a player joins.
                </li>

                <li>
                Connect this function to the PlayerAdded event of our Players service by typing Players.PlayerAdded:Connect(onPlayerAdded).
                </li>
            </ul>

            <img src={hundredNine} className='demo__image' alt='img_oneHundredNine' />
        </div>

        <div style={currentStep == 110 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 110: Find Player Leaderstats 🔍</h2>
            <ul>
                <li>
                Now let's add some functionality inside our onPlayerAdded function. We're going to look for a 'leaderstats' object inside the player's data.
                </li>

                <li>
                Inside the onPlayerAdded function, type local leaderstats = player:FindFirstChild("leaderstats"). This attempts to find an object named 'leaderstats' within the player's items.
                </li>

                <li>
                The local keyword is used to make leaderstats a variable that only exists within the onPlayerAdded function.
                </li>

                <li>
                This step is crucial for tracking each player's score, currency, or other statistics. If 'leaderstats' exists, we can then add values to it, like points or levels!
                </li>
            </ul>

            <img src={hundredTen} className='demo__image' alt='img_oneHundredTen' />
        </div>

        <div style={currentStep == 111 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 111: Create Leaderstats Folder 🛠️</h2>
            <ul>
                <li>
                First, check if the 'leaderstats' folder already exists for a player by using if not leaderstats then.
                </li>

                <li>
                If it doesn't exist, create a new folder by typing leaderstats = Instance.new("Folder").
                </li>

                <li>
                Set the name of the folder to 'leaderstats' with leaderstats.Name = "leaderstats".
                </li>

                <li>
                Make sure the folder is a child of the player by setting leaderstats.Parent = player.
                </li>

                <li>
                Now, whenever a player joins, they'll have their very own 'leaderstats' folder. It's like giving them their personal scoreboard!
                </li>
            </ul>

            <img src={hundredEleven} className='demo__image' alt='img_oneHundredEleven' />
        </div>

        <div style={currentStep == 112 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 112: Setting Up Points 🔢</h2>
            <ul>
                <li>
                Next, we want to add a way to keep score! We're going to look for a 'Points' value in 'leaderstats'.
                </li>

                <li>
                Search for a 'Points' value by writing local points = leaderstats:FindFirstChild("Points").
                </li>

                <li>
                This step prepares us to give players points as they play, which is super exciting!
                </li>
            </ul>

            <img src={hundredTwelve} className='demo__image' alt='img_oneHundredTwelve' />
        </div>

        <div style={currentStep == 113 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 113: Initialize Points 🌟</h2>
            <ul>
                <li>
                If a player doesn't have a 'Points' value yet, we're going to create one for them.
                </li>

                <li>
                Check if 'Points' exists with if not points then.
                </li>

                <li>
                Create a new 'IntValue' for points using points = Instance.new("IntValue").
                </li>

                <li>
                Name this value 'Points' with points.Name = "Points".
                </li>

                <li>
                Set the initial value to 0 because everyone starts from scratch with points.Value = 0.
                </li>

                <li>
                Lastly, set 'points' to be a child of 'leaderstats' with points.Parent = leaderstats.
                </li>

                <li>
                And just like that, every player now starts with 0 points, but they won’t stay there for long! Let’s get ready to rack up those points! 
                </li>
            </ul>

            <img src={hundredThirteen} className='demo__image' alt='img_oneHundredThirteen' />
        </div>

        <div style={currentStep == 114 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 114: Zombie Blaster 🔫</h2>
            <ul>
                <li>
                    Next players who do not have a lethal power (teleport, jump, and speed) need a blaster to fight the zombie with.
                </li>

                <li>
                    To do this we need a very specific blaster from the "Toolbox".
                </li>

                <li>
                    Open the "Toolbox".
                </li>

                <li>
                    In the search bar, enter "MP5k". It is very important that you add the same blaster as seen in the image below as we will be adjust existing code in it.
                </li>
            </ul>

            <img src={hundredFourteen} className='demo__image' alt='img_oneHundredFourteen' />
        </div>

        <div style={currentStep == 115 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 115: Adjusting Existing Code 🔧</h2>
            <ul>
                <li>
                    Look in the "Explorer" tab and locate your newly added "MP5K". Click the arrow to open it. 
                </li>

                <li>
                    Locate the script in the "MP5K" named "Server", as seen in the image below.
                </li>

                <li>
                    Once you open this script find the highlighted function below; named "DamageAndTagHumanoid", this is the function we will be adjusting.
                </li>
            </ul>

            <img src={hundredFifteen} className='demo__image' alt='img_oneHundredFifteen' />
        </div>

        <div style={currentStep == 116 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>    
            <h2>Step 116: Code to Blast Zombies 💥</h2>
            <ul>
                <li>
                    The script we are editing already has the ability to blast and damage zombies, what we are adding is the code that gives the player points when they so this.
                </li>

                <li>
                    Add the following line of code to get point when you hit a zombie: player.leaderstats.Points.Value = player.leaderstats.Points.Value + 1
                </li>

                <li>
                    With this final line of code players can now get powers, fight zombies, and compete for points!
                </li>
            </ul>

            <img src={hundredSixteen} className='demo__image' alt='img_oneHundredSixteen' />
        </div>

        <div style={currentStep == 117 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>    
            <h2>
            Step 117: Testing and Publishing Your Game🔬🌐
            </h2>
            <h2>
            Testing Your Game Locally🕹️
            </h2>
            <ul>
                <li>
                In Roblox Studio, click on the "Home" tab at the top
                </li>
                <li>
                Click the "Play" button to test your game locally
                </li>
                <li>
                You'll spawn on the starting platform and can play through your game, ensuring that each feature works as intended
                </li>
                <li>
                If you find any issues, pause the game by clicking the "Stop" button, then make the necessary adjustments in your game and test again.
                </li>
                <li>
                Testing your game locally helps identify any potential problems or bugs before sharing it with the public. This ensures a smooth gaming experience for everyone!👩‍💻🎮
                </li>
            </ul>
        </div>


        <div style={currentStep == 118 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>    
            <h2>
            Step 118: Publishing Your Game to Roblox🚀
            </h2>
            <ul>
                <li>
                Once you're satisfied with your project, click on the "File" tab in the top left corner of Roblox Studio
                </li>
                <li>
                Select "Publish to Roblox" and choose "Create New Game" (or update an existing game if you've published it before)
                </li>
                <li>
                Fill in the required information, such as game name, description, and genre
                </li>
                <li>
                Set the game's thumbnail and icon to visually represent your game (optional)
                </li>
                <li>
                Click the "Publish" button to make your game live on the Roblox platform
                </li>
                <li>
                When you publish your game, you're sharing it with the entire Roblox community! This is your chance to showcase your creativity and see other players enjoy your adventure.🌟🌍
                </li>
            </ul>
            <h3>
            Developing a game on Roblox is no small feat, and it's a testament to your skills and passion for game development. Your commitment to learning, problem-solving, and bringing your ideas to life is truly inspiring. Building a game requires a combination of technical know-how, design expertise, and the ability to engage and entertain players, and you have clearly excelled in all these areas.<br/><br/>Not only have you demonstrated your talent in creating a game, but you have also showcased your ability to think critically, work through challenges, and persevere. These are invaluable skills that will serve you well not only in game development but also in various other areas of your life.<br/><br/>I encourage you to take pride in your achievement and to continue pursuing your passion for game development. Your dedication and talent are sure to lead to even greater successes in the future. Remember, the journey of learning and improvement is ongoing, and every project you undertake will help you grow and refine your skills.
            </h3>
        </div>


        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>


    </div>
  )
}

export default Heros