import React, { useState } from 'react';
import one from './fullstackImages/travelAgent/1.png';
import two from './fullstackImages/travelAgent/2.png';
import three from './fullstackImages/travelAgent/3.png';
import four from './fullstackImages/travelAgent/4.png';
import five from './fullstackImages/travelAgent/5.png';
import six from './fullstackImages/travelAgent/6.png';
import seven from './fullstackImages/travelAgent/7.png';
import eight from './fullstackImages/travelAgent/8.png';
import nine from './fullstackImages/travelAgent/9.png';
import ten from './fullstackImages/travelAgent/10.png';
import eleven from './fullstackImages/travelAgent/11.png';
import twelve from './fullstackImages/travelAgent/12.png';
import thirteen from './fullstackImages/travelAgent/13.png';
import fourteen from './fullstackImages/travelAgent/14.png';
import fifteen from './fullstackImages/travelAgent/15.png';
import sixteen from './fullstackImages/travelAgent/16.png';
import seventeen from './fullstackImages/travelAgent/17.png';
import eighteen from './fullstackImages/travelAgent/18.png';
import nineteen from './fullstackImages/travelAgent/19.png';
import twenty from './fullstackImages/travelAgent/20.png';
import twentyOne from './fullstackImages/travelAgent/21.png';
import twentyTwo from './fullstackImages/travelAgent/22.png';
import twentyThree from './fullstackImages/travelAgent/23.png';
import twentyFour from './fullstackImages/travelAgent/24.png';
import twentyFive from './fullstackImages/travelAgent/25.png';
import twentySix from './fullstackImages/travelAgent/26.png';
import twentySeven from './fullstackImages/travelAgent/27.png';
import twentyEight from './fullstackImages/travelAgent/28.png';
import twentyNine from './fullstackImages/travelAgent/28.png';
import thirty from './fullstackImages/travelAgent/30.png';
import thirtyOne from './fullstackImages/travelAgent/31.png';
import thirtyTwo from './fullstackImages/travelAgent/32.png';
import thirtyThree from './fullstackImages/travelAgent/33.png';
import CopyBox from './copyBox/copybox';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";


const PepesTravel = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 32;

  return (
    <div className='development__container'>
        <h1>
        ✈️ Introducing Divs: Pepe the Frog's Travel Blog ✈️
        </h1>

        <h3 className='intro'>
        Welcome young explorer! You have journeyed far and wide to get here... and we are happy you that you have arrived. Pepe the Frog is a seasoned adventurer much like yourself and he needs your help. You see, Pepe does not know how to code and you do! Through this tutorial you will put your skills to the test to create an amazing travel blog for our green friend!
        </h3>




        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>



        <div style={ currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 1: Create Your Very Own Web Page! 🌐✨
          </h2>

          <p>
          Welcome to the start of your coding journey, young explorer! Look at that clean, empty space—it's your digital canvas, and it's called index.html. This is the heart of your website, the main page that everyone will see first!
          </p>

          <img src={one} className='demo__image' alt='img_one' />

          <h3>🛠 How to Complete This Step:</h3>

          <p>
          To begin, make sure your code editor is open (that's the big window where you'll write your code). Then, create a file named index.html. It's like the title page of a book, telling the internet, "Here starts my story!"
          </p>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>index.html is the default page that web browsers look for when they visit a website. It's your homepage!</li>

            <li>Every web page starts with a blank file like this. It's waiting for you to write HTML code that will tell the browser what to display.</li>
          </ul>
        </div>

        <div style={ currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 2: Create a Home for Your Pictures! 📁🖼️
          </h2>

          <p>
          Great job starting your web page! Now, let's create a special place to keep your pictures. This folder is named images, and it's where you'll store all the cool photos for your website. It's like a photo album for your digital memories!
          </p>

          <img src={two} className='demo__image' alt='img_two' />

          <h3>🛠 How to Complete This Step:</h3>

          <p>
          Right-click on your project's name—over there on the side, where it says TRAVEL-AGENT—and choose to add a new folder. Name this new folder images. Now it's ready to hold all the pictures you want to share with the world!
          </p>

          <h3>
          🌟 Concepts to Learn:
          </h3>

          <ul>
            <li>Organizing your files is super important! By putting pictures in an images folder, you're keeping your project neat and tidy.</li>

            <li>Later, when you want to show a picture on your web page, you'll know exactly where to find it—in the images folder!</li>
          </ul>
        </div>

        <div style={ currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 3: Gather Your Travel Photos! 🏞️🏝️🍕
          </h2>

          <p>
          Whoa! Look at those cool pictures you need! You need to download island.png, meep.png, and pizzeria.png, all ready to make your web page super interesting. Each one of these is like a window to a fun place you can tell your friends about.
          </p>
          
          <img src={three} className='demo__image' alt='img_three' />

          <h3>🛠 How to Complete This Step:</h3>

          <p>
            Click the buttons down below to download the images and then save them to your newly added "images" folder.
          </p>

          {/*<div className='download__buttons__container'>
            <Download src={island} filename="island.png" />

            <Download src={meep} filename="meep.png" />
            
            <Download src={pizzeria} filename="pizzeria.png" />
          </div>*/}

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>The images should be in a format that web pages can use, like .png or .jpg. These formats are like the languages that web browsers understand for pictures.</li>

            <li>Now that your pictures are in the images folder, you'll be able to add them to your web page so everyone can see them.</li>
          </ul>

          <p>
            Ready for the next step? Let's keep this adventure going!
          </p>
        </div>

        <div style={ currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 4: Setting Up Your Page Language 🌍✍️
          </h2>

          <p>
          Now we're going to tell the internet what language we're using for our web page. First, we declare that we're using HTML with &lt;!DOCTYPE html&gt;. This is important because it's like the secret handshake that introduces our page to the web browser, making sure it understands we're using the latest HTML5 code.
          </p>

          <img src={four} className='demo__image' alt='img_four' />

          <h3>🛠 How to Complete This Step:</h3>

          <p>
          At the very top of your index.html file, type in &lt;!DOCTYPE html&gt;. Right below it, let's add &lt;html lang="en"&gt;. Don't forget to close the html tag at the end with &lt;/html&gt;.
          </p>

          <h3>
          🌟 Concepts to Learn:
          </h3>

          <ul>
            <li>
            &lt;!DOCTYPE html&gt; is a declaration that tells the web browser which version of HTML the page is written in, which is HTML5 in our case.
            </li>

            <li>
            The html element is the root of the HTML document. The lang="en" attribute within it specifies that we are writing in English. This helps search engines and browsers provide language-specific assistance, like correct spell checking and translation features.
            </li>
          </ul>

        </div>

        <div style={ currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 5: Initializing the Head Section 🧠✨
          </h2>

          <p>
          The head of an HTML document is like the control center for your webpage. It doesn't display content directly to viewers, but it holds crucial information about your site. This step is about laying the groundwork for that control center by introducing the &lt;head&gt; element.
          </p>

          <img src={five} className='demo__image' alt='img_five' />

          <h3>
          🛠 How to Complete This Step:
          </h3>

          <p>
          Insert the &lt;head&gt; opening tag after the opening &lt;html&gt; tag. Ensure you leave space for additional elements that we'll be adding inside the head section. After you've done this, add the closing &lt;/head&gt; tag. Remember, everything that provides information about your webpage, like stylesheets, scripts, and meta information, will go inside these tags.
          </p>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
            The &lt;head&gt; element contains meta-information about the document, which can be used by browsers to ensure proper display, by search engines for SEO, and for other purposes.
            </li>

            <li>
            No content inside the &lt;head&gt; will be directly visible on the web page itself. Instead, it affects the behind-the-scenes aspects of your web page, like its behavior and appearance in search results and browser tabs.
            </li>
          </ul>
        </div>

        <div style={ currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 6: Making Your Page Friendly for Everyone! 🌟👀</h2>

          <p>
          Have you ever played a video game where the characters speak a language you don't understand? Well, websites can speak many languages too! To make sure your website 'speaks' in a way that all computers can understand, we use something called 'character encoding'. Think of it like choosing the alphabet for your webpage that includes all the letters and emojis you can think of! 🌍🔤          
          </p>

          <img src={six} className='demo__image' alt='img_six' />

          <h3>
          🛠 How to Complete This Step:
          </h3>

          <ol>
            <li>
            Look at the top part of your webpage, which we call the 'head', and type this crazy line: &lt;meta charset="UTF-8"&gt;. It's like setting the universal translator for your website, so no matter where someone is or what computer they use, the letters and symbols on your page show up right. 🖥️✨
            </li>

            <li>
            Next, let's give your webpage a name that appears on the tab at the top of the browser. This is like the title of your favorite book. It helps people know what your website is about before they even open it! Between the &lt;head&gt; tags, after the charset line, add &lt;title&gt;Pepe's Travel Blog&lt;/title&gt;. Use your imagination to come up with the most exciting title! 🎨📚
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>UTF-8' in the meta tag is a secret code that includes all sorts of characters from languages around the world, even the cool symbols like 😀 and ❤️!</li>

            <li>The &lt;title&gt; tag is super important because it's like the name of your website that shows up in the browser's tab, sort of like the title on the cover of a book. It's one of the first things someone sees when they find your webpage, so make it catchy and fun!</li>
          </ul>

          <p>
          By finishing this step, you've just told the computer how to show your words and pictures correctly, and you've given your page a cool title that everyone can see. You're doing great—your webpage is starting to get really exciting! 🚀🌈
          </p>
        </div>

        <div style={ currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 7: Preparing the Stage with the Body Element 🎭📝</h2>

          <p>
          It's showtime! The &lt;body&gt; of your HTML is like the stage for a play. This is where all the parts of your website that people can see and interact with will go, like pictures, stories, buttons, and games!
          </p>

          <img src={seven} className='demo__image' alt='img_seven' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>Right after the closing &lt;/head&gt; tag, type in &lt;body&gt;.</li>

            <li>Leave some space like an empty stage—this is where you will add all the cool stuff later.</li>

            <li>Close the body section with &lt;/body&gt;.</li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
            The &lt;body&gt; tag marks the beginning of everything that will be visible on the webpage, such as text, images, and links.
            </li>

            <li>
            Think of the &lt;body&gt; as your playground: it’s the space where you can put all the content that people visiting your website will see and enjoy.
            </li>
          </ul>

          <h3>💼 Use Cases:</h3>

          <ul>
            <li>
            Want to write a welcome message? It goes inside the &lt;body&gt;.
            </li>

            <li>
            How about showing a photo? Yep, that goes in the &lt;body&gt; too!
            </li>

            <li>
            Even when you want to make things move or play sounds, all those scripts will affect what’s inside the &lt;body&gt;.
            </li>
          </ul>
        </div>

        <div style={ currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 8: Dividing the Stage into Sections with Div Tags 📦🌟</h2>

          <p>
          Now, let's divide the stage into sections. We use &lt;div&gt; tags for that—it's like drawing invisible lines on your stage to know where each actor stands or where the scenery goes.
          </p>
          
          <img src={eight} className='demo__image' alt='img_eight' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Inside your &lt;body&gt; tag, create a space for your first section by typing &lt;div&gt;.
            </li>

            <li>
            Imagine what you want in this part of your page. It’s like planning where to put a treasure chest or where a dragon might sleep!
            </li>

            <li>
            Close this section with &lt;/div&gt;.
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
            A &lt;div&gt; is a container that helps organize content on the webpage. You can style these sections later with colors, borders, or even tell them where to sit on the page with CSS (that's the costume and makeup for your website!).
            </li>

            <li>
            Divs are super useful for grouping elements together. If you want several things to stick together like a team, you put them in a &lt;div&gt;.
            </li>
          </ul>

          <h3>💼 Use Cases:</h3>

          <ul>
            <li>
            Creating a navigation bar at the top of your page? Use a &lt;div&gt; to hold all the links.
            </li>

            <li>
            Want a section for user comments or a gallery of images? Each of these can be wrapped in their own &lt;div&gt;.
            </li>
          </ul>

          <p>
          By completing these steps, you're setting up a strong, organized structure for your website's content. It's like drawing a map before starting a treasure hunt; it'll guide you where to go next as you fill your page with exciting elements! 🗺️✏️
          </p>
        </div>

        <div style={ currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 9: Adding a Title to Your Page 🌟📃</h2>

          <p>
          The headline or title is like the name of a storybook—it's usually the first thing people see. In web language, we use something called an &lt;h1&gt; tag for our main headline. It's like writing the title on the cover of your storybook in big, bold letters to catch everyone's attention!
          </p>
          
          <img src={nine} className='demo__image' alt='img_nine' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Inside the &lt;div&gt; tag, which is like a box on your webpage, write &lt;h1&gt;Pepe the Frog's Travel Blog&lt;/h1&gt;.
            </li>

            <li>
            This &lt;h1&gt; is like shouting out the title of your page. It’s the biggest and most important headline you can use!
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
            &lt;h1&gt; stands for 'Heading 1' and is the most significant heading tag in HTML. It's used to represent the main heading of a page, similar to the main title of a book or a chapter.
            </li>

            <li>
            Headings are used by search engines to understand the structure and content of your webpage. So using &lt;h1&gt; for your main title helps people find your webpage when they search for something cool to read!
            </li>
          </ul>

          <h3>💼 Use Cases:</h3>

          <ul>
            <li>The main title of your blog or a new section on your page.</li>

            <li>The headline for an article or an important topic on your website.</li>
          </ul>
        </div>

        <div style={ currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 10: Creating Space for More Content 🛠️💡</h2>

          <p>
          Sometimes on your web page, you'll want to make a bit of space between your title and whatever comes next. In HTML, we don't press the 'Enter' key to do this; instead, we use something called a 'break'. The &lt;br/&gt; tag is like saying, "take a breath here" before moving on to the next part of your story, adding empty space into the website.
          </p>

          <img src={ten} className='demo__image' alt='img_ten' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>Right after your big title inside the &lt;h1&gt; tag, type in &lt;br/&gt; to add a little space.</li>

            <li>If you want even more space, like taking a bigger breath, add another &lt;br/&gt; right after the first one!</li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
            &lt;br/&gt; stands for 'line break' and is a self-closing tag, which means it doesn't need an ending tag.
            </li>

            <li>
            It's used to create a single line break, which is like moving down one line on the page—helpful for when you need to separate lines of text or content.
            </li>
          </ul>

          <h3>💼 Use Cases:</h3>

          <ul>
            <li>Separating lines in a poem or an address.</li>

            <li>Adding space between a title and the first paragraph, or between paragraphs themselves.</li>
          </ul>

          <p>
          By completing these steps, you've given your webpage a bold title and created just the right amount of space before the next exciting part of your travel blog begins. Think of it as setting the scene before the curtain rises on a grand play! 🎭👏
          </p>

        </div>

        <div style={ currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 11: Organizing Your Content with Divisions 📦🎨</h2>

          <p>
          Let's organize your webpage by creating different sections using &lt;div&gt; tags. Each &lt;div&gt; is like a container or a box where we can put different parts of our webpage, like titles, paragraphs, or pictures. It's how we keep things neat and tidy!
          </p>
          
          <img src={eleven} className='demo__image' alt='img_eleven' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Underneath the breaks (&lt;br/&gt; tags), start a new &lt;div&gt;. This will be like drawing an invisible box on your page.
            </li>

            <li>
            We don’t need to put anything inside this box right now, so just close it with &lt;/div&gt;.
            </li>

            <li>
            Remember, we can add as many boxes as we need later, each one for different bits of our webpage!
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
              &lt;div&gt; tags are super handy for grouping related content together, which can help us when we want to style or position things later with CSS.
            </li>

            <li>
            Think of &lt;div&gt; as the building blocks of your webpage. They can stack up like Lego pieces to form the structure of your site.
            </li>
          </ul>
        </div>

        <div style={ currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 12: Highlighting Your Sections with Headings and Emphasis 🖍️✒️</h2>

          <p>
          Now, we're going to make parts of your page stand out! We use &lt;h2&gt; for smaller headings that are still pretty important. And when we want to make a word really pop, we wrap it in &lt;em&gt; tags, which is like adding a highlighter or italics in a book.
          </p>

          <img src={twelve} className='demo__image' alt='img_twelve' />

          <h3>
          🛠 How to Complete This Step:
          </h3>

          <ol>
            <li>
            Inside your new &lt;div&gt;, add an &lt;h2&gt; tag, like this: &lt;h2&gt;1. Meep City&lt;/h2&gt;.
            </li>

            <li>
            To emphasize "Meep City," put &lt;em&gt; around it: &lt;em&gt;Meep City&lt;/em&gt;.
            </li>

            <li>
            Your final tag will look like this: &lt;h2&gt;1. &lt;em&gt;Meep City&lt;/em&gt;&lt;/h2&gt;.
            </li>
          </ol>

          <h3>
          🌟 Concepts to Learn:
          </h3>

          <ul>
            <li>
              &lt;h2&gt; is used for subheadings. They are not as big as &lt;h1&gt; but still important to describe the section that follows.
            </li>

            <li>
              &lt;em&gt; stands for 'emphasis' and it tells the browser (and your readers) that this text is important, often shown in italics.
            </li>
          </ul>

          <h3>
          💼 Use Cases:
          </h3>

          <ul>
            <li>
            Subheadings for different sections of your page, like "Fun Facts" or "Games."
            </li>

            <li>
            Emphasizing important words or phrases that you want to stand out.
            </li>
          </ul>

          <p>
          By completing these steps, you've just added a splash of style to your webpage's title and created a new section that will catch your readers' eyes. You're turning your webpage into a beautifully organized digital scrapbook! 🌈📖
          </p>
        </div>

        <div style={ currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 13: Bringing Pictures into Your Story 🖼️✨</h2>
          
          <p>
          A picture is worth a thousand words, right? Let's add some colorful images to your travel blog. With an &lt;img/&gt; tag, you can show your readers what Meep City looks like. Imagine you're opening a little window on your webpage for everyone to peek through!
          </p>

          <img src={thirteen} className='demo__image' alt='img_thirteen' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Inside your &lt;div&gt;, just below the &lt;h2&gt; tag, type in &lt;img/&gt;.
            </li>

            <li>
            Add src="./images/meep.png" to tell the browser where your picture is. This is like giving the browser a map to find your picture.
            </li>

            <li>
            Set width="300" to make sure your picture fits nicely on the page, not too big and not too small!
            </li>

            <li>
            The alt attribute is really important too! Write alt="Picture of Meep City" so even if the picture doesn't show up, everyone will know what's supposed to be there.
            </li>
          </ol>

          <h3>
          🌟 Concepts to Learn:
          </h3>

          <ul>
            <li>
            &lt;img/&gt; is a self-closing tag that lets you insert images into your webpage.
            </li>

            <li>
            The src (source) attribute is where you put the path to your image file, like a signpost pointing to where your image lives.
            </li>

            <li>
            The width attribute controls how wide the picture appears on the webpage.
            </li>

            <li>
            The alt (alternative text) attribute describes the image. It helps people who can’t see the image understand what it shows and improves accessibility.
            </li>
          </ul>

          <h3>
          💼 Use Cases:
          </h3>

          <ul>
            <li>
            Show off where you've traveled in your blog.            
            </li>

            <li>
            Add pictures to explain a story or to make a tutorial more fun and engaging.
            </li>
          </ul>

        </div>

        <div style={ currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 14: Describing Your Adventures 🌍📖</h2>

          <p>
          Next up, let's describe the exciting places in your blog. For this, we use smaller headings like &lt;h3&gt; to introduce new sections, like "About the Place." And to make it even more eye-catching, we'll underline it by wrapping it in &lt;u&gt; tags.
          </p>

          <img src={fourteen} className='demo__image' alt='img_fourteen' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Below your picture, create a new headline with &lt;h3&gt;.
            </li>

            <li>
            Type in About the Place as the text.
            </li>

            <li>
            Wrap About the Place in &lt;u&gt; tags to underline it, like this: &lt;u&gt;About the Place&lt;/u&gt;.
            </li>

            <li>
            Close your headline with &lt;/h3&gt;.
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
              &lt;h3&gt; is used for subheadings that are less prominent than &lt;h1&gt; and &lt;h2&gt;, but still important.
            </li>

            <li>
            The &lt;u&gt; tag is used for underlining text, which can help emphasize certain sections or titles.
            </li>
          </ul>

          <h3>💼 Use Cases:</h3>

          <ul>
            <li>Titles for different sections of content, like a menu in a restaurant or chapters in a book.</li>

            <li>Making key phrases stand out to readers, like the title of a new section or a word that's really important.</li>
          </ul>

          <p>
          By completing these steps, you’re turning your blog into a visual adventure. You’ve added a snapshot of Meep City and started a section where you can share all the fun details. Keep going, and let’s make your travel blog the most exciting one out there! 🚀📚
          </p>
        </div>

        <div style={ currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 15: Telling a Story with Paragraphs 📖🌟
          </h2>

          <p>
          Just like in a storybook, we can fill our webpage with tales of our adventures. In HTML, the &lt;p&gt; tag is like a paragraph in a book; it holds all the words where we tell our story. Let's add a paragraph to tell everyone about Meep City!
          </p>

          <img src={fifteen} className='demo__image' alt='img_fifteen' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Right after the &lt;h3&gt; tag, begin a new line and write &lt;p&gt; to start a paragraph.
            </li>

            <li>
            Now, type a whimsical description of Meep City. Let your imagination run wild as you describe the place where the streets are paved with giggles!
            </li>

            <li>
            After you've finished your story, close the paragraph with &lt;/p&gt;. This is like putting a full stop at the end of a wonderful tale.
            </li>

            <li>
              If you do not know what to write, you can copy and paste the text in the box below
            </li>
          </ol>

          <CopyBox 
            text={"In the whimsical world of online realms, there exists a place so bursting with joy and color it makes rainbows look dull in comparison: Meep City. Here, the streets are paved with giggles, and the rivers flow with lemonade, as meeps—fluffy, orb-shaped creatures with a penchant for hats—wobble about, hosting tea parties for dinosaurs and pirate ships alike. It's a land where the impossible not only happens but is heartily encouraged, turning every day into an adventure in silliness."}
          />

          <h2>🌟 Concepts to Learn:</h2>

          <ul>
            <li>
            &lt;p&gt; tags are used to create paragraphs on your webpage. They help to break up your text into easy-to-read sections.
            </li>

            <li>
            Think of paragraphs as containers for your thoughts, stories, or explanations—they help to keep your writing clear and organized.
            </li>
          </ul>

        </div>

        <div style={ currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 16: Highlighting Activities with Underlined Headings ⭐️🏃</h2>

          <p>
          What can you do in Meep City? Let's make a special headline to talk about the fun activities there! We'll use an &lt;h3&gt; tag again because it's still an important headline, but not as big as our main title. And we'll underline it with &lt;u&gt; to show it's a new exciting part.
          </p>

          <img src={sixteen} className='demo__image' alt='img_sixteen' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Below your paragraph about Meep City, create a space for your headline by typing &lt;h3&gt;.
            </li>

            <li>
            Type Things to Do because we're going to list some awesome activities next.
            </li>

            <li>
            Underline it by adding &lt;u&gt; tags around Things to Do, like this: &lt;u&gt;Things to Do&lt;/u&gt;.
            </li>

            <li>
            Finish off by closing the &lt;h3&gt; tag.
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>


          <ul>
            <li>
            Using &lt;h3&gt; helps to organize your webpage by breaking it into sections with headings and subheadings.
            </li>

            <li>
            The &lt;u&gt; tag isn't just for underlining—it also indicates that something is important or should be paid attention to.
            </li>
          </ul>

          <p>
          By adding this underlined heading, you're setting the stage for more details to come, inviting your readers to explore what's fun and interesting about Meep City. It's like the chapter title in a book that says, "This next part is going to be super exciting!" 🎉📚
          </p>

        </div>

        <div style={ currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 17: Listing the Fun Things to Do 📋✅</h2>

          <p>
          Let's tell everyone about all the cool activities they can do in Meep City! In HTML, we can make a list of items using &lt;ul&gt; for an 'unordered list', which is like a bulleted list in a word document, and &lt;li&gt; for 'list item', which is each bullet point.
          </p>
          
          <img src={seventeen} className='demo__image' alt='img_seventeen' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Start by writing &lt;ul&gt; underneath the "Things to Do" heading to begin our list.
            </li>

            <li>
            Then, for every fun activity, we'll make a new bullet point. Write &lt;li&gt; followed by the activity, like "Adopt and Customize a Meep".
            </li>

            <li>
            After the description, close it with &lt;/li&gt;.
            </li>

            <li>
            Repeat for each activity you want to list and when you're done, close the list with &lt;/ul&gt;.
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
              &lt;ul&gt; is used to start a bulleted list, where the order of items isn't important.
            </li>

            <li>
              &lt;li&gt; is used for each item in the list, and they automatically get a bullet point in front of them.
            </li>
          </ul>

          <h3>💼 Use Cases:</h3>

          <ul>
            <li>Listing things like ingredients in a recipe or steps in a how-to guide.</li>

            <li>Bullet points in a presentation slide or key points in a summary.</li>
          </ul>
        </div>

        <div style={ currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 18: Spacing Out Your Content 🚀🛰️</h2>

          <p>
          Webpages need breathing room too! Just like in a storybook, you wouldn't want all the words squished together. The &lt;br/&gt; tag is our way of adding a little space between sections or after a list. It's like a pause between thoughts.
          </p>

          <img src={eighteen} className='demo__image' alt='img_eighteen' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            After your list (&lt;/ul&gt;), if you want to create a gap before starting something new, add a &lt;br/&gt; tag.
            </li>

            <li>
              Pay close attention to the image above and where the &lt;br/&gt; tags are located, not only are they under the &lt;ul&gt;, they are also under the &lt;div&gt; that is containing the section for Meep City.
            </li>

            <li>
              These break tags will separate the div for Meep City and the div for the next location on the travel blog.
            </li>

            <li>
            If you want even more space, like starting a new chapter in a book, add another &lt;br/&gt; tag right after the first one!
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
              &lt;br/&gt; creates a single line break, similar to hitting 'Enter' on your keyboard in a text document.
            </li>
          </ul>

          <h3>💼 Use Cases:</h3>

          <ul>
            <li>
            Separating paragraphs in a blog post or an essay.
            </li>

            <li>
            Making space after a list before starting a new section or adding an image.
            </li>
          </ul>

        </div>

        <div style={ currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 19: Setting Up Another Section 🏗️📝</h2>

          <p>
          Our travel blog is growing, and it's time to add another section! This will be for another exciting place to explore. We're going to use &lt;div&gt; tags again to create a separate space on our webpage for this new adventure.
          </p>

          <img src={nineteen} className='demo__image' alt='img_nineteen' />

          <h3>
          🛠 How to Complete This Step:
          </h3>

          <ol>
            <li>
            After the space we made with &lt;br/&gt; tags, start a new section by adding a &lt;div&gt; tag.
            </li>

            <li>
            This is your new container for another location in your travel blog. Think of it as creating a new chapter in your adventure book.
            </li>

            <li>
            Close this section with a &lt;/div&gt; tag. Right now, it's an empty box, but soon we'll fill it up with more stories and pictures.
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
              &lt;div&gt; tags are the go-to when we want to organize our webpage into sections.
            </li>

            <li>
            They are like invisible boxes that help us separate different parts of our content, making our site tidy and well-structured.
            </li>
          </ul>
        </div>

        <div style={ currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 20: Introducing Yoshi's Island 🏝️</h2>

          <p>
          Now let's headline our new section. This time it's all about Yoshi's Island, a place of wonders and excitement! We'll use an &lt;h2&gt; tag because it's a main heading for this new section, and we'll make it stand out by adding emphasis with the &lt;em&gt; tag.
          </p>

          <img src={twenty} className='demo__image' alt='img_twenty' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Inside the new &lt;div&gt; you just created, let's introduce Yoshi's Island with an &lt;h2&gt; tag.
            </li>

            <li>
            Add 2. Yoshi's Island to tell readers this is the second exciting place you're telling them about.
            </li>

            <li>
            Emphasize the name of the island by wrapping Yoshi's Island with &lt;em&gt; tags, making it &lt;em&gt;Yoshi's Island&lt;/em&gt;.
            </li>

            <li>
            Close the heading with &lt;/h2&gt;.
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
            &lt;h2&gt; tags are used for main headings within a section, and they help to keep your webpage organized.
            </li>

            <li>
            The &lt;em&gt; tag emphasizes text and usually displays it in italics, which is a way to say, "Hey, look here! This is important!"
            </li>
          </ul>

          <h3>💼 Use Cases:</h3>

          <ul>
            <li>
            Titling a new blog post or article.
            </li>

            <li>
            Highlighting the name of a place or key feature in a guide.
            </li>
          </ul>
        </div>

        <div style={ currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 21: Adding an Image of Yoshi's Island 🌴📸</h2>

          <p>
          Yoshi's Island is known for its colorful landscapes and fun adventures! Let's give our readers a glimpse of this place by adding a picture. Remember, images add life to your words and let your readers’ imaginations soar.
          </p>

          <img src={twentyOne} className='demo__image' alt='img_twentyOne' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Right after the heading for Yoshi's Island, let's insert an image. Type &lt;img/&gt;.
            </li>

            <li>
            Use src="./images/island.png" to point to the image file, showing where the image of Yoshi's Island is kept.
            </li>

            <li>
            Set width="300" so the image fits nicely on our page and aligns with our layout.
            </li>

            <li>
            Add alt="Picture of Yoshi's Island" so everyone knows what the image represents, even if it doesn't load.
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
            The &lt;img /&gt; tag is used to embed an image in an HTML page.
            </li>

            <li>
            The src attribute specifies the path to the image.
            </li>

            <li>
            The width attribute specifies the width of the image in pixels.
            </li>

            <li>
            The alt attribute provides alternative information for an image if a user cannot view it.
            </li>
          </ul>
        </div>

        <div style={ currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 22: Describing Yoshi's Island 📝
          </h2>

          <p>
          Let's detail the wonders of Yoshi's Island with a descriptive subheading. Just like before, we'll create a new segment within this section to talk about what makes Yoshi's Island unique.
          </p>

          <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Beneath your image of Yoshi's Island, add a new line and type &lt;h3&gt; for a subheading.
            </li>

            <li>
            Inside this tag, write About the Place to introduce your description of Yoshi's Island.
            </li>

            <li>
            To emphasize this subheading and make it stand out, wrap About the Place with &lt;u&gt; tags, resulting in &lt;u&gt;About the Place&lt;/u&gt;.
            </li>

            <li>
            Don’t forget to close your &lt;h3&gt; tag.
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>The &lt;h3&gt; tag is typically used for subheadings.</li>

            <li>The &lt;u&gt; tag is used to underline text, adding emphasis to the subheading and indicating a new subsection.</li>
          </ul>

          <p>
          By adding an image and a descriptive title, you've set the stage for sharing all the details of Yoshi's Island. The image entices readers, and the subheading promises more information about this vibrant location. Let's keep building the blog with more descriptions and adventures! 🖋️🌟
          </p>
        </div>

        <div style={ currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 23: Adding Descriptive Text for Yoshi's Island 🏝️🌟</h2>

          <p>
          After setting the scene with a wonderful image, let’s add some fun facts about Yoshi's Island to captivate our explorers. Descriptive text helps our readers imagine they are bouncing around with the dinos!
          </p>

          <img src={twentyThree} className='demo__image' alt='img_twentyThree' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Below the About the Place subtitle, press Enter to start a new line for our paragraph, using the &lt;p&gt; tag.
            </li>

            <li>
            In the paragraph, let's describe Yoshi's Island. You could write something like "It's a place where the ground is bouncy and you can find fruity adventures around every corner!"
            </li>

            <li>If you do not know what to write, then copy and paste the text in the box below into your &lt;p&gt; tag.</li>
          </ol>

          <CopyBox 
          text={"A place where the ground is as bouncy as a trampoline and the trees bear fruit that comes in every flavor of the rainbow, including socks and sunshine. Here, baby dinosaurs of every hue frolic under candy-floss clouds, embarking on adventures that involve tossing eggs like confetti at bewildered enemies. It's a land where the sun always smiles, the waterfalls sing, and the only rule is to have as much fun as possible before naptime."}
          />

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
            The &lt;p&gt; tag defines a paragraph in HTML, which is a block of text.
            </li>

            <li>
            Good descriptive text in paragraphs can transport readers to the place we're talking about.
            </li>
          </ul>
        </div>

        <div style={ currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 24: Listing Fun Activities at Yoshi's Island 🎉🎈</h2>

          <p>
          What’s a travel blog without recommendations on what to do? Let’s guide our little adventurers through the best activities on Yoshi's Island with a fun to-do list!
          </p>

          <img src={twentyFour} className='demo__image' alt='img_twentyFour' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Start a new line below our descriptive paragraph and type in &lt;h3&gt; to introduce our 'Things to Do' section.
            </li>

            <li>
            Make this title pop with &lt;u&gt;Things to Do&lt;/u&gt; inside the &lt;h3&gt; tags, so it stands out as an important part of our blog.
            </li>

            <li>
            Remember to close the heading with &lt;/h3&gt; to wrap it up.
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
            The &lt;h3&gt; tag is great for secondary headings, which we're using to introduce new sections.
            </li>

            <li>
            Underlining with the &lt;u&gt; tag draws attention to this interactive part of our blog, where we suggest activities.
            </li>
          </ul>

          <p>
          Now, let's keep the momentum going and fill in those fun activities for our young readers to dream about! 🏖️
          </p>
        </div>

        <div style={ currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 25: Listing More Exciting Activities on Yoshi's Island 🌴🍉</h2>

          <p>
          Continuing with our spirited guide for our adventurers, we're adding more activities to do on Yoshi's Island. These activities promise fun-filled experiences and are sure to get those hearts racing with excitement!
          </p>

          <img src={twentyFive} className='demo__image' alt='img_twentyFive' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Begin with an opening unordered list tag &lt;ul&gt; to start our bullet list of activities.
            </li>

            <li>
            Add list items &lt;li&gt; for each exciting activity. Think about fun adventures, like "Egg-Throwing Adventures", where you can pretend to be part of Yoshi's egg-tossing escapades.
            </li>

            <li>
            Close off the list with the closing &lt;/ul&gt; tag, signifying the end of our bulleted list.
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
            The &lt;ul&gt; tag is used to create a list that is unordered, which means that the list items have bullet points by default.
            </li>

            <li>
            &lt;li&gt; tags are used inside an unordered or ordered list to define each item on the list.
            </li>
          </ul>
        </div>

        <div style={ currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 26: Creating Space After Yoshi's Island 🎈</h2>

          <p>
          After listing out all those thrilling activities, we're going to create a bit of space on our page. This visual break helps separate our list from the next exciting section we have planned for our junior explorers.
          </p>

          <img src={twentySix} className='demo__image' alt='img_twentySix' />

          <h3>
          🛠 How to Complete This Step:
          </h3>

          <ol>
            <li>
            After the closing &lt;/div&gt; tag that contains the content for Yoshi's Island, insert two line breaks using the &lt;br/&gt; tag. This adds some vertical space on the page.
            </li>

            <li>
            These line breaks serve as a pause, giving readers a moment before they move on to the next portion of our adventure tale.
            </li>
          </ol>
        </div>

        <div style={ currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 27: Creating a Space for Papa's Pizzeria 🎉</h2>

          <p>
          Now we are going to make a special space on our page for Papa's Pizzeria. This is like drawing an imaginary box where we'll later add all the fun details about the pizzeria.
          </p>

          <img src={twentySeven} className='demo__image' alt='img_twentySeven' />

          <h3>
          🛠 How to Complete This Step:
          </h3>

          <ol>
            <li>
            Below the previous section, make sure you’ve pressed Enter twice. We like to have a little space before starting something new—it's like taking a deep breath before jumping into the pool!
            </li>

            <li>
            Now, let’s type &lt;div&gt; to start our imaginary box. This is like saying, "Hey, everything about Papa's Pizzeria will go inside here!"
            </li>

            <li>
            Press Enter to move to the next line, and type &lt;/div&gt; to close our box. This is like saying, "Okay, that’s all for the pizzeria section!"
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
            The &lt;div&gt; tag is used to divide our page into sections or "boxes," which can help organize our content.
            </li>

            <li>
            By separating sections with &lt;div&gt;, we make our website easy to navigate, like having different rooms in a house for different activities.
            </li>
          </ul>
        </div>

        <div style={ currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 28: Giving Papa's Pizzeria a Big, Cheesy Title 🧀</h2>

          <p>
          Every great place needs a big sign to tell you you've arrived! Let's give Papa's Pizzeria a title that stands out so everyone knows about this yummy spot.
          </p>

          <img src={twentyEight} className='demo__image' alt='img_twentyEight' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Inside the &lt;div&gt; we just made, press Enter to start a new line for our title.
            </li>

            <li>
            Type &lt;h2&gt; to begin our heading, which is like making a sign for the pizzeria.
            </li>

            <li>
            Now add the pizzeria's name in style by typing &lt;em&gt;Papa's Pizzeria&lt;/em&gt;. This is like painting the sign in fancy letters to catch everyone's eye.
            </li>

            <li>
            Don't forget to type &lt;/h2&gt; after the name to finish our heading. This tells the webpage, "The sign ends here!"
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
            An &lt;h2&gt; tag is perfect for main headings, like the name of a restaurant in our guide.
            </li>

            <li>
            Using &lt;em&gt; inside the heading makes the text italic, which can make certain words feel more special or important.
            </li>
          </ul>
        </div>


        <div style={ currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 29: Introducing Papa's Pizzeria with Image and Subtitle 🌟</h2>

          <p>
          It's time to give our readers a glimpse of the cozy Papa's Pizzeria. We’ll start by placing a tempting image and creating a subtitle that invites curiosity.
          </p>

          <img src={thirty} className='demo__image' alt='img_thirty' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Below the &lt;h2&gt; tag for Papa's Pizzeria, insert an &lt;img/&gt; tag to add a picture. Set the src attribute to the image file path, add a width of "300" for size consistency, and don't forget the alt text for accessibility.
            </li>

            <li>
            Start a new line after the image and add an &lt;h3&gt; tag for the 'About the Place' subtitle. Emphasize this text with the &lt;u&gt; tag to make it stand out.
            </li>
          </ol>

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
            The &lt;img/&gt; tag is self-closing and is used to embed images in an HTML page.
            </li>

            <li>
            The &lt;h3&gt; tag is used for lower-level headings and subtitles, organizing content into sections.
            </li>

            <li>
            The &lt;u&gt; tag is used to underline text, drawing attention to headings or keywords.
            </li>
          </ul>
        </div>

        <div style={ currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 30: Describing the Ambiance of Papa's Pizzeria 📝✨</h2>

          <p>
          With the stage set for Papa's Pizzeria, let’s add a description that transports readers right into the heart of this delightful eatery.
          </p>

          <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Directly under the 'About the Place' subtitle, initiate a paragraph with the &lt;p&gt; tag to add descriptive text about Papa's Pizzeria.
            </li>

            <li>
            Craft a sentence or two that paints a picture of the ambiance and experience of visiting the pizzeria. For example, "Step into a world where each pizza is a slice of heaven, and the aromas tell tales of traditional Italian cooking."
            </li>

            <li>
            Close the paragraph with the &lt;/p&gt; tag to complete this section of content.
            </li>

            <li>
              If you so not know what to write you can copy and paste the text in the box below into your &lt;p&gt; tag.
            </li>
          </ol>

          <CopyBox 
            text={"The only place in the world where the pizzas are so custom-made, they can include toppings from yesterday's dreams and tomorrow's wishes. Here, the dough is tossed higher than a circus acrobat, catching flavors from the clouds themselves, while a symphony of cheese and sauce dances to the rhythm of the oven's hum. It's a culinary wonderland where every slice tells a story, and the pepperoni smiles back at you, promising adventures in every bite."}
          />

          <h3>🌟 Concepts to Learn:</h3>

          <ul>
            <li>
            The &lt;p&gt; tag denotes a paragraph and is used to display text in a block format.
            </li>

            <li>
            Crafting engaging descriptions can help create a vivid image in the reader's mind, enhancing the storytelling aspect of the blog.
            </li>
          </ul>
        </div>

        <div style={ currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 31: Highlighting Papa's Pizzeria’s Special Features ✨</h2>

          <p>
          Give the visitors of our blog a taste of what makes Papa's Pizzeria special. We’ll add an attention-grabbing subtitle that leads into some savory descriptions!
          </p>

          <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Right after the image of the pizzeria, add an &lt;h3&gt; tag to begin our subtitle.
            </li>

            <li>
            To emphasize the subtitle, enclose "Things to Do" in &lt;u&gt; tags within the &lt;h3&gt; tags. This styling cues readers that they're about to learn something exciting!
            </li>

            <li>
            Close the subtitle with the corresponding &lt;/h3&gt; tag to complete this formatting. Remember, HTML tags must be opened and closed correctly to work!
            </li>
          </ol>

        </div>

        <div style={ currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 32: Listing Engaging Activities at Papa's Pizzeria 🎨</h2>

          <p>
          We’ve set the scene with our description, now it's time to draw the readers in with hands-on activities. A list of things to do engages the imagination and invites future visits!
          </p>
          
          <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />

          <h3>🛠 How to Complete This Step:</h3>

          <ol>
            <li>
            Below the subtitle "Things to Do," which is wrapped in &lt;h3&gt; and &lt;u&gt; tags for emphasis, begin your bulleted list with the &lt;ul&gt; tag to introduce an unordered list.
            </li>

            <li>
            or each activity, start a new line with a &lt;li&gt; tag, add a descriptive title of the activity like "Craft Custom Pizzas," and then close it with &lt;/li&gt;.
            </li>

            <li>
            Repeat this for each activity you want to include, such as "Manage Orders" or "Experiment with Recipes."
            </li>

            <li>
            After your last list item, close your unordered list with the &lt;/ul&gt; tag.
            </li>

            <li>
            Each list item should be an action that entices the reader to engage more deeply with the content and dream about participating in the adventure!
            </li>
          </ol> 

          <p>
          Now our young chefs can imagine all the fun they can have crafting pizzas and becoming part of the pizzeria's bustling environment! 🧑‍🍳✨
          </p>
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default PepesTravel