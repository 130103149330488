import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FaRegWindowClose } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import Navbar from '../components/navbar/navbar';
import axios from 'axios'
import './classesondeck.css';
import ClassCard from '../components/teacher-comps/class-card/classCard';
import Attendance from '../components/teacher-comps/attendance/attendance';
import GroupAttendance from '../components/teacher-comps/group-attendance/groupAttendance';
import Details from '../components/teacher-comps/details/details';
import api from '../api/api';
import Loader from '../components/loader/loader';

const ClassesOnDeck = () => {
  const _id = JSON.parse(localStorage.getItem("user"))?._id
  const navigate = useNavigate()

  var [meetingLink, setMeetingLink] = useState("");
  var [modal, setModal] = useState(false);

  var [loading, setLoading] = useState(false);
  var [path, setPath] = useState("scratch");
  var [project, setProject] = useState("Apple Catch");
  var [status, setStatus] = useState(false);
  var [tempID, setTempID] = useState("");


  var [details, setDetails] = useState(false);
  var [detailsName, setDetailsName] = useState("");
  var [detailsPath, setDetailsPath] = useState("scratch")

  var [scratch, setScratch] = useState([]);
  var [python, setPython] = useState([]);
  var [roblox, setRoblox] = useState([]);
  var [fullstack, setFullstack] = useState([]);

  var [dayDetails, setDayDetails] = useState();
  var [selectedCert, setSelectedCert] = useState();
  var [studentID, setStudentID] = useState("");
  var [studentCertificates, setStudentCertificates] = useState();
  var [badge, setBadge] = useState(null);

  var [attendance, setAttendance] = useState(false);
  var [groupAttendance, setGroupAttendance] = useState(false);
  var [groupEmails, setGroupEmails] = useState();
  var [groupId, setGroupId] = useState();
  var [attendanceName, setAttendanceName] = useState("");
  var [attendanceMessage, setAttendanceMessage] = useState("");
  var [attendanceEmail, setAttendanceEmail] = useState("");
  var [attendancePhoneNumber, setAttendancePhoneNumber] = useState("");
  var [IDofStudent, setIDofStudent] = useState("");
  var [reschedules, setReschedules] = useState([]);
  var [classID, setClassID] = useState();


  var [monday, setMonday] = useState([]);
  var [tuesday, setTuesday] = useState([]);
  var [wednesday, setWednesday] = useState([]);
  var [thursday, setThursday] = useState([]);
  var [friday, setFriday] = useState([]);
  var [saturday, setSaturday] = useState([]);


  const awardCertificate = async () => {
    console.log(dayDetails)
    console.log(selectedCert)

    await api.post(`${process.env.REACT_APP_API_URL}/award-certificate`, {
      studentID: dayDetails?.studentID, certificateName: selectedCert
    }).then((res) => {
      console.log(res)
      toast.success("Success!")
    }).catch((err) => {
      console.log(err)
      if (err.response.status == 404) {
        toast.error("Already Awarded!")
      } else {
        toast.error("Error!")
      }
      
    })

  }






  const getTeacherClasses = async () => {
    try {
      const res = await api.post(`${process.env.REACT_APP_API_URL}/classes-of-teacher`, { _id });
      console.log(res)

      
      function organizeByDayOfWeek(arr) {
        console.log(arr[0].teacherDOW)
        // Initialize variables for each day of the week
        let monday = [];
        let tuesday = [];
        let wednesday = [];
        let thursday = [];
        let friday = [];
        let saturday = [];
        let sunday = [];
      
        // Loop through each object in the array
        arr.forEach((obj) => {
          console.log(obj)
          // Check the "teacherDOW" property and push the object to the corresponding array
          switch (obj.teacherDOW.toLowerCase()) {
            case 'monday':
              monday.push(obj);
              break;
            case 'tuesday':
              tuesday.push(obj);
              break;
            case 'wednesday':
              wednesday.push(obj);
              break;
            case 'thursday':
              thursday.push(obj);
              break;
            case 'friday':
              friday.push(obj);
              break;
            case 'saturday':
              saturday.push(obj);
              break;
            default:
              console.warn(`Unknown day: ${obj.teacherDOW}`);
          }
        });
      
        // Return an object containing the arrays for each day of the week
        var week = {
          monday: monday, 
          tuesday: tuesday, 
          wednesday: wednesday, 
          thursday: thursday, 
          friday: friday, 
          saturday: saturday
        }
        return week;
      }

      
      const organizedData = organizeByDayOfWeek(res.data);
      

      function sortSchedulesByTime(schedule) {
        // Helper function to parse the time and return a Date object for sorting
        function parseTime(timeString) {
          const [time, modifier] = timeString.split(/(am|pm)/);
          let [hours, minutes] = time.split(":").map(Number);
          if (modifier === "pm" && hours !== 12) hours += 12;
          if (modifier === "am" && hours === 12) hours = 0;
          return new Date(0, 0, 0, hours, minutes);
        }
      
        // Helper function to format time as "5 PM" or "8 AM"
        function formatTime(timeString) {
          const [time, modifier] = timeString.split(/(am|pm)/);
          let [hours] = time.split(":").map(Number);
          return `${hours} ${modifier.toUpperCase()}`;
        }
      
        // Go through each day and sort based on the start time of "teacherTime"
        Object.keys(schedule).forEach(day => {
          schedule[day].sort((a, b) => {
            const startA = parseTime(a.teacherTime.split("-")[0]);
            const startB = parseTime(b.teacherTime.split("-")[0]);
            return startA - startB;
          });
      
          // Reformat each "teacherTime" to display only the starting hour with AM/PM
          schedule[day] = schedule[day].map(entry => ({
            ...entry,
            teacherTime: formatTime(entry.teacherTime.split("-")[0])
          }));
        });
      
        return schedule;
      }
      
      
      const sortedSchedule = sortSchedulesByTime(organizedData);
      
      setMonday(sortedSchedule.monday)
      setTuesday(sortedSchedule.tuesday)
      setWednesday(sortedSchedule.wednesday)
      setThursday(sortedSchedule.thursday)
      setFriday(sortedSchedule.friday)
      setSaturday(sortedSchedule.saturday)

  
      setLoading(true);
    } catch (err) {
      console.log(err);
    }
  };




  



  useEffect(() => {
    getTeacherClasses()
  }, [])





  const getIsDisabled = () => {
    const clickedTime = localStorage.getItem('buttonClickedTime');
    if (!clickedTime) return false;

    const now = new Date().getTime();
    const timeDifference = (now - clickedTime) / 1000; // in seconds

    return timeDifference < 1800; // 30 minutes = 1800 seconds
  };


  const [isDisabled, setIsDisabled] = useState(getIsDisabled());


  useEffect(() => {
    if (!isDisabled) return;

    // Calculate the time remaining until 30 minutes are up
    const clickedTime = Number(localStorage.getItem('buttonClickedTime'));
    const now = new Date().getTime();
    const timeDifference = now - clickedTime;
    const timeLeft = 1800000 - timeDifference; // 30 minutes = 1800000 milliseconds

    // Set a timeout for the remaining time
    const timeoutId = setTimeout(() => {
        setIsDisabled(false);
        localStorage.removeItem('buttonClickedTime');
    }, timeLeft);

    return () => clearTimeout(timeoutId); // Clear timeout on component unmount
  }, [isDisabled]);



  const changeGroupAttendance = async (dayInfo) => {
    window.scrollTo(0,0);

    setGroupAttendance(true)
    setAttendanceName(dayInfo?.studentName)
    setTempID(dayInfo?.studentID)
    

    await api.post(`${process.env.REACT_APP_API_URL}/fetch-single-group-from-name`, {
      _name: dayInfo?.studentName
    }).then((res) => {
      setGroupEmails(res.data.emails)
      setGroupId(res.data.students)
    }).catch((err) => {
      console.log(err)
    })
  }



  const changeAttendance = async (dayInfo) => {
    window.scrollTo(0,0);

    console.log(dayInfo)

    
    setAttendanceName(dayInfo?.studentName)
    setTempID(dayInfo?.studentID)
    setClassID(dayInfo?._id)

    await api.post(`${process.env.REACT_APP_API_URL}/get-badge`, {
      studentID: dayInfo?.studentID
    }).then((res) => {
      setBadge(res.data[0])
    })

    await api.post(`${process.env.REACT_APP_API_URL}/single-user`, {
      id: dayInfo?.studentID
    }).then((res) => {
      setAttendanceEmail(res.data.email)
      setAttendancePhoneNumber(res.data.phoneNumber)
      setIDofStudent(res.data._id)
    }).catch((err) => {
      console.log(err)
    })

    setAttendance(true)
  }


  const changeDetails = async (day) => {
    window.scrollTo(0,0);

    setDetails(true)
    setDayDetails(day)
    setDetailsName(day?.studentName)
    setStudentID(day?.studentID)

    await api.post(`${process.env.REACT_APP_API_URL}/get-student-award-models`, {
      studentID: day?.studentID
    }).then((res)=> {
      console.log(res)

      var certNames = []
      for (let i=0; i < res.data.length; i++) {
        certNames.push(res.data[i]?.name)
      }
      setStudentCertificates(certNames)
      console.log(studentCertificates)
    })

    await api.post(`${process.env.REACT_APP_API_URL}/get-badge`, {
      studentID: day?.studentID
    }).then((res) => {

      console.log(res.data[0])

      setScratch(res.data[0].scratch)
      setPython(res.data[0].python)
      setRoblox(res.data[0].roblox)
      setFullstack(res.data[0].fullstack)

    }).catch((err) => {
      console.log(err)
    })

  }




  const submitAttendance = async () => {
    try {
      const sendNotesResponse = await api.post(`${process.env.REACT_APP_API_URL}/send-private-lesson-notes`, {
        email: attendanceEmail,
        message: attendanceMessage,
        link: IDofStudent,
        teacherID: _id,
        classID: classID
      });

      //studentID, projectType, projectName
      const updateBadgeResponse = await api.post(`${process.env.REACT_APP_API_URL}/update-badge-status`, {
        studentID: tempID, 
        projectType: path, 
        projectName: project, 
        status: status
      });

      const teacherStatus = await api.post(`${process.env.REACT_APP_API_URL}/add-to-status`, {
        teacherID: _id
      });

      // Perform actions after all requests succeed
      toast.success("Notes sent!");
      setAttendanceMessage("");
      setPath("");
      setStatus(false);
      setAttendance(false);
    } catch (err) {
      // Handle errors
      console.log(err);
      toast.error("Error, contact admin!")
    }
  };


  


  const submitGroupAttendance = async () => {
    await api.post(`${process.env.REACT_APP_API_URL}/group-notes`, {
      groupEmails, groupId, message: attendanceMessage
    }).then((res) => {
      toast.success(res.data.message)
      setAttendanceMessage("")
      setAttendance(false)
      setGroupAttendance(false)
    }).catch((err) => {
      console.log(err)
    })
  }

  function formatDate(dateString) {
    // Split the input date string to avoid any time zone issues
    const [year, month, day] = dateString.split('-');

    // Format the date as MM/DD
    return `${month}/${day}`;
  }


  return (
    <div>
      <ToastContainer />
      <Navbar />


      {
        details &&
        <Details detailsName={detailsName} detailsPath={detailsPath} setDetailsPath={setDetailsPath} studentCertificates={studentCertificates} setSelectedCert={setSelectedCert} awardCertificate={awardCertificate} details={details} setDetails={setDetails} scratch={scratch} python={python} roblox={roblox} fullstack={fullstack} />
      }


      {
        attendance &&
        <Attendance 
          badge={badge}
          path={path} 
          setPath={setPath} 
          project={project} 
          setProject={setProject} 
          setAttendance={setAttendance} 
          status={status} 
          setStatus={setStatus} 
          attendanceName={attendanceName} 
          attendanceMessage={attendanceMessage} 
          setAttendanceMessage={setAttendanceMessage} 
          submitAttendance={submitAttendance} 
        />
      }

      {
        groupAttendance &&
        <GroupAttendance setGroupAttendance={setGroupAttendance} attendanceName={attendanceName} attendanceMessage={attendanceMessage} setAttendanceMessage={setAttendanceMessage} submitGroupAttendance={submitGroupAttendance} />
      }

      {
        loading === false ?
        <div className='schedule__loader'>
          <Loader />
        </div>
        :
        <div className='schedule__container'>


          {
          reschedules?.length > 0 &&
          <div className='reschedule__display__container'>
              <h2>Upcoming Rescheduled Classes:</h2>
              <div className='rescheduled__cards'>
                {
                  reschedules?.map((data) => {
                    return (
                    <div className='rescheduled__card'>
                        <h3 className='rescheduleDate'>Reschedule Date: {formatDate(data?.rescheduleDay)}</h3>
                        <h3>Time: {data?.rescheduleTeacherTime}</h3>
                        <h3 className='canceledDate'>Canceled Date: {formatDate(data?.canceledDate)}</h3>
                        <h2>{data?.classData?.studentName}</h2>
                        <button className='startClassButton'><a target="_blank" href={`${data?.zoomLink}`} >Start Class</a></button>
                    </div>
                    )
                  })
                }
              </div>
          </div>
          }

          <div className='classes__container'>

            <div className='day__container'>
              <h1>
                Monday:
              </h1>

              <div className='class__card__container'>
                {
                  monday.length > 0 ?
                  monday?.map((day) => {
                    return (
                      <ClassCard day={day} changeDetails={changeDetails} changeGroupAttendance={changeGroupAttendance} changeAttendance={changeAttendance} />
                    )
                  })
                  :
                  <div className='no-classes-container'>
                  <h2>No Classes</h2>
                  </div>
                }
              </div>

            </div>



            <div className='day__container'>
              <h1>
                Tuesday:
              </h1>

              <div className='class__card__container'>
                {
                  tuesday.length > 0 ?
                  tuesday?.map((day) => {
                    return (
                      <ClassCard day={day} changeDetails={changeDetails} changeGroupAttendance={changeGroupAttendance} changeAttendance={changeAttendance} />
                    )
                  })
                  :
                  <div className='no-classes-container'>
                  <h2>No Classes</h2>
                  </div>
                }
              </div>

            </div>


            <div className='day__container'>
              <h1>
                Wednesday:
              </h1>


              <div className='class__card__container'>
                {
                  wednesday.length > 0 ?
                  wednesday?.map((day) => {
                    return (
                      <ClassCard day={day} changeDetails={changeDetails} changeGroupAttendance={changeGroupAttendance} changeAttendance={changeAttendance} />
                    )
                  })
                  :
                  <div className='no-classes-container'>
                  <h2>No Classes</h2>
                  </div>
                }
              </div>

            </div>


            <div className='day__container'>
              <h1>
                Thursday:
              </h1>

              <div className='class__card__container'>
                {
                  thursday.length > 0 ?
                  thursday?.map((day) => {
                    return (
                      <ClassCard day={day} changeDetails={changeDetails} changeGroupAttendance={changeGroupAttendance} changeAttendance={changeAttendance} />
                    )
                  })
                  :
                  <div className='no-classes-container'>
                  <h2>No Classes</h2>
                  </div>
                }
              </div>

            </div>


            <div className='day__container'>
              <h1>
                Friday:
              </h1>

              <div className='class__card__container'>
                {
                  friday.length > 0 ?
                  friday?.map((day) => {
                    return (
                      <ClassCard day={day} changeDetails={changeDetails} changeGroupAttendance={changeGroupAttendance} changeAttendance={changeAttendance} />
                    )
                  })
                  :
                  <div className='no-classes-container'>
                  <h2>No Classes</h2>
                  </div>
                }
              </div>

            </div>


            <div className='day__container'>
              <h1>
                Saturday:
              </h1>

              <div className='class__card__container'>
                {
                  saturday.length > 0 ?
                  saturday?.map((day) => {
                    return (
                      <ClassCard day={day} changeDetails={changeDetails} changeGroupAttendance={changeGroupAttendance} changeAttendance={changeAttendance} />
                    )
                  })
                  :
                  <div className='no-classes-container'>
                  <h2>No Classes</h2>
                  </div>
                }
              </div>

            </div>



              
          </div>

        </div>
      }

    </div>
  )
}

export default ClassesOnDeck