import React, {useState} from 'react';
import one from './fullstackImages/squidInkStudios/1.png';
import two from './fullstackImages/squidInkStudios/2.png';
import three from './fullstackImages/squidInkStudios/3.png';
import four from './fullstackImages/squidInkStudios/4.png';
import five from './fullstackImages/squidInkStudios/5.png';
import six from './fullstackImages/squidInkStudios/6.png';
import seven from './fullstackImages/squidInkStudios/7.png';
import eight from './fullstackImages/squidInkStudios/8.png';
import nine from './fullstackImages/squidInkStudios/9.png';
import ten from './fullstackImages/squidInkStudios/10.png';
import eleven from './fullstackImages/squidInkStudios/11.png';
import twelve from './fullstackImages/squidInkStudios/12.png';
import thirteen from './fullstackImages/squidInkStudios/13.png';
import fourteen from './fullstackImages/squidInkStudios/14.png';
import fifteen from './fullstackImages/squidInkStudios/15.png';
import sixteen from './fullstackImages/squidInkStudios/16.png';
import seventeen from './fullstackImages/squidInkStudios/17.png';
import eighteen from './fullstackImages/squidInkStudios/18.png';
import nineteen from './fullstackImages/squidInkStudios/19.png';
import twenty from './fullstackImages/squidInkStudios/20.png';
import twentyOne from './fullstackImages/squidInkStudios/21.png';
import twentyTwo from './fullstackImages/squidInkStudios/22.png';
import twentyThree from './fullstackImages/squidInkStudios/23.png';
import twentyFour from './fullstackImages/squidInkStudios/24.png';
import twentyFive from './fullstackImages/squidInkStudios/25.png';
import twentySix from './fullstackImages/squidInkStudios/26.png';
import twentySeven from './fullstackImages/squidInkStudios/27.png';
import twentyEight from './fullstackImages/squidInkStudios/28.png';
import twentyNine from './fullstackImages/squidInkStudios/28.png';
import thirty from './fullstackImages/squidInkStudios/30.png';
import thirtyOne from './fullstackImages/squidInkStudios/31.png';
import thirtyTwo from './fullstackImages/squidInkStudios/32.png';
import thirtyThree from './fullstackImages/squidInkStudios/33.png';
import thirtyFour from './fullstackImages/squidInkStudios/34.png';
import thirtyFive from './fullstackImages/squidInkStudios/35.png';
import thirtySix from './fullstackImages/squidInkStudios/36.png';
import thirtySeven from './fullstackImages/squidInkStudios/37.png';
import thirtyEight from './fullstackImages/squidInkStudios/38.png';
import thirtyNine from './fullstackImages/squidInkStudios/39.png';
import forty from './fullstackImages/squidInkStudios/40.png';
import fortyOne from './fullstackImages/squidInkStudios/41.png';
import fortyTwo from './fullstackImages/squidInkStudios/42.png';
import fortyThree from './fullstackImages/squidInkStudios/43.png';
import fortyFour from './fullstackImages/squidInkStudios/44.png';
import fortyFive from './fullstackImages/squidInkStudios/45.png';
import fortySix from './fullstackImages/squidInkStudios/46.png';
import fortySeven from './fullstackImages/squidInkStudios/47.png';
import fortyEight from './fullstackImages/squidInkStudios/48.png';
import fortyNine from './fullstackImages/squidInkStudios/49.png';
import fifty from './fullstackImages/squidInkStudios/50.png';
import fiftyOne from './fullstackImages/squidInkStudios/51.png';
import fiftyTwo from './fullstackImages/squidInkStudios/52.png';
import fiftyThree from './fullstackImages/squidInkStudios/53.png';
import fiftyFour from './fullstackImages/squidInkStudios/54.png';
import fiftyFive from './fullstackImages/squidInkStudios/55.png';
import fiftySix from './fullstackImages/squidInkStudios/56.png';
import fiftySeven from './fullstackImages/squidInkStudios/57.png';
import CopyBox from './copyBox/copybox';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const SquidInk = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 57;

  return (
    <div className='development__container'>
        <h1>
        🦑 CSS Continued: SquidInk Studios 🦑
        </h1>

        <h3 className='intro'>
            If squids are known for one thing... its their ink. In this CSS project you are going to use your tasteful CSS skills to help our underwater friends setup a store front on the web to sell their art supplies to the rest of the creatures under the ocean 🖌️🌴
        </h3>



        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>



        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Open the Door to Coding! 🚪💻</h2>

            <p>
            Hey there, young coder! Are you ready to embark on a digital art adventure with SquidInk Studios? Grab your gear, 'cause here's your very first step:
            </p>
            
            <img src={one} className='demo__image' alt='img_one' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Create index.html in your coding environment.
                </li>

                <li>
                This is where we will create our webpage!
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                    "index" is the name of the file.
                </li>

                <li>
                    ".html" let's the computer know that the code in this file is HTML.
                </li>
            </ul>
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Creating a Home for Your Images 🏠</h2>

            <p>
            Artists need their palette organized, and so do coders! You're about to create a special place where all your beautiful images will live.
            </p>
            <img src={two} className='demo__image' alt='img_two' />

            <h3>Do This:</h3>

            <ol>
                <li>
                In your file explorer, make a new folder and name it images. This will be the treasure chest for your visual assets.
                </li>

                <li>
                Pat yourself on the back — you've just added a layer of organization to your project!
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Folders: Like rooms in a house, folders help keep your files tidy and in the right place.
                </li>

                <li>
                Project Structure: Setting up a good foundation now will make it easier to find and use your files later.
                </li>
            </ul>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 3: Populating Your Image Gallery 🖌️
            </h2>
            
            <p>
            Every gallery needs artwork, and your website gallery is no different! Let's fill it up with images that will bring SquidInk Studios to life.
            </p>
            <img src={three} className='demo__image' alt='img_three' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Gather all your image files — download them by clicking the buttons below.
                </li>

                <li>
                Place each image file into the images folder you just created. Name them properly so you can easily identify them later. You'll have brush.png, canvas.png, map.png, paint.png, and SquidInkCover.png.
                </li>
            </ol>


            {/*<div className='download__buttons__container'>
                <Download src={brush} filename="brush.png" />

                <Download src={canvas} filename="canvas.png" />

                <Download src={map} filename="map.png" />

                <Download src={paint} filename="paint.png" />

                <Download src={SquidInkCover} filename="SquidInkCover.png" />
            </div>*/}

            <h3>Concepts:</h3>

            <ul>
                <li>
                File Naming: Like labeling your colors, naming files clearly helps you quickly grab what you need.
                </li>

                <li>
                Using Assets: You're learning how to integrate different elements, like images, into your project.
                </li>
            </ul>

        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: Laying the HTML Foundation 🏗️</h2>
            
            <p>
            Fantastic work so far! Now, let’s build the core structure of our webpage. This step is all about putting up the essential HTML tags that every webpage needs.
            </p>
            <img src={four} className='demo__image' alt='img_four' />

            <h3>Do This:</h3>

            <ol>
                <li>
                After creating &lt;!DOCTYPE html&gt; declaration, type in the opening &lt;html&gt; tag with the language attribute set to English like this: &lt;html lang="en"&gt;.
                </li>

                <li>
                Leave some space - we'll fill this in soon with the head and body of our HTML document.
                </li>

                <li>
                Close off your HTML structure with the ending &lt;/html&gt; tag.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                DOCTYPE Declaration: Tells the browser that we're using HTML5, the latest standard.
                </li>

                <li>
                Language Attribute: Helps search engines and browsers know what language the website is in.
                </li>

                <li>
                Opening and Closing Tags: Remember that most HTML elements need an opening and a closing tag – it's like saying "hello" and "goodbye"!
                </li>
            </ul>
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Introducing the Head Element 🧠📝</h2>
            
            <p>
            Now it's time to give your webpage a mind of its own with the &lt;head&gt; element. This is where a lot of the behind-the-scenes action happens.
            </p>
            <img src={five} className='demo__image' alt='img_five' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Inside the opening and closing &lt;html&gt; tags, add the opening &lt;head&gt; tag.
                </li>

                <li>
                For now, we'll leave it like an artist's palette – ready and waiting for colors. So, just type in the closing &lt;/head&gt; tag right after.
                </li>

                <li>
                Don’t add anything inside the head yet; we'll get there in the next steps!
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Head Element: This is where you will later add elements like the title of your page, links to stylesheets, and other metadata.
                </li>

                <li>
                Clean Coding: We're practicing clean coding by setting up our elements before filling them in, keeping everything organized.
                </li>
            </ul>

            <p>
            You're doing splendidly! A great coder is an organized coder! 🎉📊
            </p>
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Powering Up the Head Tags 🎩💡</h2>

            <p>
            Super job, young coder! It's time to power up the &lt;head&gt; section of your HTML document. This part is invisible to visitors, but absolutely crucial for the web browsers.
            </p>
            <img src={six} className='demo__image' alt='img_six' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Inside the &lt;head&gt; tag, add a &lt;meta charset="UTF-8"&gt; tag. This helps ensure that all the characters on your page show up correctly, no matter what language they're in.
                </li>

                <li>
                Next, add a &lt;meta name="viewport" content="width=device-width, initial-scale=1.0"&gt;. This makes sure your site looks good on all devices, from tiny phones to big desktops!
                </li>

                <li>
                Cap it off with a &lt;title&gt; tag. Inside it, write SquidInk Studios to name your web masterpiece.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Character Set: The UTF-8 character set includes pretty much every character from every human language. Pretty cool, huh?
                </li>

                <li>
                Viewport: It’s like adjusting the lens on a camera to make sure your site is just as beautiful on all screens.
                </li>

                <li>
                Title: It’s not just a label; it's how your site will be known across the vast lands of the internet!
                </li>
            </ul>
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Dressing Up with CSS 🎨</h2>
            
            <p>
            Let's get fancy! Stylesheets are like the clothes of your webpage; they make everything look snazzy. It’s time to create one!
            </p>
            <img src={seven} className='demo__image' alt='img_seven' />

            <h3>Do This:</h3>

            <ol>
                <li>
                In your project folder, create a new file and name it style.css. This is where all your styling will happen.
                </li>

                <li>
                For now, it's a blank slate. But soon, you'll fill it with colors, fonts, and layouts to bring SquidInk Studios to life!
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                CSS Files: These are separate files where you write styles for your HTML elements. Keeping them separate keeps your project tidy.
                </li>

                <li>
                Linking CSS: You’ll learn to link this CSS file to your HTML later so that the styles know where to go and jazz up the right page.
                </li>
            </ul>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Linking Up Your Styles 🖇️</h2>
            
            <p>
            Now, let’s connect your HTML page to its stylish outfit—a CSS stylesheet that will dress up your content.
            </p>
            <img src={eight} className='demo__image' alt='img_eight' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Within the &lt;head&gt; of your HTML document, just below the title, add a link to your stylesheet with &lt;link href="./style.css" rel="stylesheet" /&gt;.
                </li>

                <li>
                This self-closing tag is your golden ticket to a beautiful website, telling the browser where to find the CSS styles that will make your page look amazing.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Link Element: This tag links your HTML file to your CSS file, much like connecting your phone to Wi-Fi to get those sweet, sweet internet vibes.
                </li>

                <li>
                Attributes: href tells the browser where to find the CSS file, and rel describes the relationship between the HTML and linked document.
                </li>
            </ul>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Crafting the Body of Your Page 🛠️</h2>
            
            <p>
            With your head complete, it's time to sketch out the body! This is where all the visual parts of your webpage will live.
            </p>
            <img src={nine} className='demo__image' alt='img_nine' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Right after the closing &lt;/head&gt; tag, add the opening &lt;body&gt; tag. This is where you'll later add all your content like text, images, and maybe even a squid or two!
                </li>

                <li>
                Leave the body empty for now. We'll fill it in soon with lots of colorful content.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Body Element: Think of this like the stage of a theater where all the action happens.
                </li>

                <li>
                Structuring HTML: Good structure is key to a well-functioning webpage. Just like building a house, you need a solid foundation before you add the furniture.
                </li>
            </ul>

            <p>
            You’re doing an awesome job, coder! Your page is coming together like a masterpiece in a gallery. Keep it up! 🌟🛠️
            </p>
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Building the Main Content 🏗️</h2>
            
            <p>
            It’s time to create the core section of your page, the main content area. This is where all your main goodies will go.
            </p>
            <img src={ten} className='demo__image' alt='img_ten' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Inside the &lt;body&gt; tag, add a &lt;div&gt; with a class named main-content. This div will act like a container for all the main parts of your webpage.
                </li>

                <li>
                Remember to close your div with &lt;/div&gt;. It’s like closing the lid on a treasure chest full of amazing content!
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Div Elements: The &lt;div&gt; tag is used to group block-elements to format them with CSS. Think of it as a container.
                </li>

                <li>
                Class Attribute: Using the class attribute, you can apply the same styling to all elements with the same class name. Think of class as a label for the div or anything we apply a class to.
                </li>
            </ul>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: Welcoming Visitors with a Headline 🎉👋</h2>
            
            <p>
            First impressions matter! Let’s greet your visitors with a big, friendly welcome message.
            </p>
            <img src={eleven} className='demo__image' alt='img_eleven' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Inside your main-content div, add an &lt;h1&gt; tag, the HTML tag used for the main heading.
                </li>

                <li>
                Type in your welcome message: “Welcome to SquidInk Studios!” between the opening and closing &lt;h1&gt; tags.
                </li>

                <li>
                You’ve just set up a warm welcome for everyone who visits your site.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Heading Tags: Headings are used to define HTML headings and are important for the structure and SEO of your webpages.
                </li>

                <li>
                Content Structure: By using headings correctly, you're creating a content hierarchy that helps visitors understand the flow of your website.
                </li>
            </ul>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Introducing the About Section 🤓</h2>
            
            <p>
            Every great website has a story to tell. Let’s introduce yours with an "About Us" section!
            </p>
            <img src={twelve} className='demo__image' alt='img_twelve' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Below the welcoming &lt;h1&gt; in your main-content div, add an &lt;h2&gt; tag for a secondary heading.
                </li>

                <li>
                Inside the &lt;h2&gt; tags, type “About Us” to create a clear, standout section where you'll later add details about SquidInk Studios.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Headings: They help structure your content and make it easy to read. Different levels (h1, h2, h3, etc.) denote the hierarchy of importance.
                </li>

                <li>
                Sections: Clear sections help organize your webpage, making it user-friendly.
                </li>
            </ul>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: Setting Up the Cover Image 🖼️</h2>
            
            <p>
            A picture is worth a thousand words, so let's set the stage for your studio’s snapshot!
            </p>
            <img src={thirteen} className='demo__image' alt='img_thirteen' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Under the “About Us” heading, create a new div with the class coverImage-container. This will be the container for your cover image.
                </li>

                <li>
                Keep the div empty for now—we’ll be filling it with a beautiful image soon.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Image Containers: Divs can act as containers for images, allowing you to style and position your pictures neatly on the page.
                </li>

                <li>
                Classes for Styling: The class attribute will link this div to CSS styles that will define how your cover image looks and fits within the page layout.
                </li>
            </ul>

            <p>
            You're assembling the pieces perfectly, just like a puzzle. Great job! The body of your webpage is taking shape beautifully. 🌟🛠️
            </p>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: Adding the Cover Image 🌆</h2>
            
            <p>
            A visual treat awaits! Let’s add an eye-catching cover image to your "About Us" section that tells a story all by itself.
            </p>
            <img src={fourteen} className='demo__image' alt='img_fourteen' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Inside the coverImage-container div, use the &lt;img/&gt; tag to add your cover image.
                </li>

                <li>
                Set the src attribute to the path of your image file, which is ./images/SquidInkCover.png.
                </li>

                <li>
                Don’t forget the alt attribute, like alt="Store picture", which describes the image for users who might not be able to see it.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Image Tags: The &lt;img/&gt; tag is self-closing and is used to embed an image in an HTML page.
                </li>

                <li>
                Source Attribute: src stands for "source" and it points to the image's location in your project.
                </li>
            </ul>
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15: Starting the CSS Styling 🎨</h2>
            
            <p>
            Now, let's give your webpage some style! We'll begin by setting the stage for the overall look of the page with some basic CSS.
            </p>
            <img src={fifteen} className='demo__image' alt='img_fifteen' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Open your style.css file.
                </li>

                <li>
                Start by typing body (with the curly brackets) —ninside these braces is where you will add style rules for the body element.
                </li>

                <li>
                For now, leave the curly braces empty. We’ll fill them with properties that will dictate how your page looks in the browser.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                CSS Selectors: body is a selector that allows you to apply styles to the entire body of your webpage by targeting the body tag that contains everything.
                </li>

                <li>
                CSS Rules: Inside the curly braces, you’ll later add properties like background-color, font-family, and color to style the selected element.
                </li>
            </ul>
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 16: Choosing a Font Family 🖋️</h2>
            
            <p>
            Just like picking out an outfit, choosing a font for your website sets the ambiance for your visitors.
            </p>
            <img src={sixteen} className='demo__image' alt='img_sixteen' />

            <h3>Do This:</h3>

            <ol>
                <li>
                In your style.css file, target the body selector to apply styles to the whole page.
                </li>

                <li>
                Add the font-family property and set it to Arial, sans-serif. This tells the browser to use Arial, and if it's not available, any available sans-serif font.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Font Family: This CSS property specifies the typeface for the text on your webpage.
                </li>

                <li>
                Fallback Fonts: Specifying a second, generic family (like sans-serif) is a backup in case the first choice does not work.
                </li>
            </ul>
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 17: Setting the Background Color 🎨</h2>

            <p>
            The background color is like the canvas for your webpage's art. Let's pick a color that complements your content.
            </p>
            <img src={seventeen} className='demo__image' alt='img_seventeen' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Still working within the body selector in your CSS file, add the background-color property.
                </li>

                <li>
                Choose a color—like #F5F5F5, a light grey—which will be gentle on the eyes and give a professional look to your site.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Background Color: This property sets the color behind your webpage's content, helping to create contrast and readability.
                </li>

                <li>
                Hex Codes: Colors in CSS can be specified with hexadecimal codes, a six-digit combination of numbers and letters that define a specific shade.
                </li>
            </ul>
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 18: Resetting Margins 📐</h2>

            <p>
            Before we start adding specific styles, it's important to reset the default margins of the webpage to ensure consistency across different browsers.
            </p>
            <img src={eighteen} className='demo__image' alt='img_eighteen' />

            <h3>Do This:</h3>

            <ol>
                <li>
                In your CSS file, add margin: 0; within the body selector’s curly braces.
                </li>

                <li>
                This line of code will remove any default margins around the edges of the page, so your design doesn’t have unexpected spacing. The margins that are being removed is basically just excess space on the borders/edges of the website.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Margin Property: Controls the space outside of an element’s border, in this case the body's.
                </li>
            </ul>
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 19: Removing Default Padding 🚫</h2>
            
            <p>
            Just as we reset the margins, we also want to make sure there's no unexpected padding inside elements that could throw off our design.
            </p>
            <img src={nineteen} className='demo__image' alt='img_nineteen' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Stay in the body selector and add padding: 0; to remove any default padding.
                </li>

                <li>
                Now, the content of your body element will touch the edges of the browser window, giving you full control to start adding your own spacing as needed.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Padding Property: Controls the space inside of an element, between the content and the border.
                </li>
            </ul>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 20: Text Color for Readability 🌟📖</h2>
            
            <p>
            Choosing the right text color is crucial for making your content pop while ensuring it’s comfortable to read.
            </p>
            <img src={twenty} className='demo__image' alt='img_twenty' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Within your CSS file, you’ve already set the basic style for the body. Let’s give your text a color with the color property.
                </li>

                <li>
                Add color: #333; to your body style rules. The #333 is a dark grey that provides high contrast against the light background, making your text legible.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Text Color: This property sets the color of the text inside an element, contributing to the visual hierarchy and readability.
                </li>

                <li>
                Contrast: High contrast between text and background colors is key for accessibility and ease of reading.
                </li>
            </ul>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 21: Styling the Main Heading 🎨👑</h2>
            
            <p>
            Headings are the crowns of your content. A distinct style for your main heading will grab attention and set the tone.
            </p>
            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Now, let’s style the main heading, &lt;h1&gt;. In your style.css, create a new style block for h1.
                </li>

                <li>
                Inside the curly braces, set the color property for h1 to a color that stands out, like #8B0000, a deep red.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Heading Style: Applying a distinct style to h1 elements ensures they stand out from other text, drawing immediate attention.
                </li>

                <li>
                CSS Selectors: By using h1 as a selector, you're specifically targeting all &lt;h1&gt; elements in the HTML, allowing for consistent styling.
                </li>
            </ul>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 22: Harmonizing Headings 🎵</h2>
            
            <p>
            Consistency in design leads to harmony on your page. Just like a well-composed song, every element on your webpage should resonate with the others. Let’s ensure our headings not only stand out but also align with the overall design.
            </p>
            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Continue in your style.css file, where you have styled your h1 element. Now, let’s give your h2 elements the same color treatment to maintain visual consistency for all headings.
                </li>

                <li>
                Below the h1 block, add an h2 block and set the color property to the same deep red color #8B0000.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                CSS Classes: While classes (.) target specific elements, element selectors like h1, h2 target every matching element on the page.
                </li>

                <li>
                Color Scheme: A consistent color scheme for similar elements helps create a cohesive visual experience.
                </li>
            </ul>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 23: Styling the Main Content 🎨📦</h2>
            
            <p>
            The main content of your webpage is where users will spend most of their time. It's not just about the text; it’s about creating an inviting space for your visitors. Let’s style the main content area to make it welcoming.
            </p>
            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Create a new CSS block targeting the .main-content class. This class was added to your main content div in your HTML file.
                </li>

                <li>
                For now, let’s leave this block empty. We will soon add properties like width, margin, and padding to define how this area should look and feel on the page.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>Content Area: This is the heart of your webpage where the main information is presented. It should be easy to read and attractive.</li>

                <li>Styling Classes: Adding styles to classes allows you to apply the same styles to all elements with that class, ensuring uniformity and reducing repetition in your code.</li>
            </ul>
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 24: Setting the Width for Main Content 📏</h2>
            
            <p>
            The width of your main content is crucial for readability. Too wide, and it's hard for eyes to track from line to line; too narrow, and the text looks cramped. Setting a width with a percentage gives you a fluid layout that adapts to different screen sizes, maintaining user-friendliness.
            </p>
            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Inside the .main-content block of your CSS file, you need to specify a width of 80%. This means the main content will take up 80% of the width of its containing element, often the body of the page.
                </li>

                <li>
                This width provides a comfortable reading area on both large and small screens while allowing some breathing room on the sides.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Fluid Layouts: Using percentages for width allows elements to adjust to the viewport, creating responsive designs without the use of media queries.
                </li>

                <li>
                Readability: Ensuring the main content area isn't too wide supports optimal line length for readability.
                </li>
            </ul>
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 25: Centring the Main Content 🔍</h2>
            
            <p>
            With the width set, let's ensure that the main content is nicely centered. Centring content can help draw the user's eye and create a visually appealing layout that looks balanced and intentional.
            </p>
            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Still within the .main-content selector, add margin: 20px auto;. The 20px gives a bit of space above and below the main content, while auto on the sides works with the width to center the block within its parent element.
                </li>

                <li>
                The automatic margins on the sides are calculated evenly by the browser, centering your content area.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Margin Shorthand: When using the margin property, you can specify all four margins within one shorthand property. The values go clockwise from the top (top, right, bottom, left).
                </li>

                <li>
                Automatic Margins: Auto margins are a powerful aspect of CSS layout and are particularly useful for horizontal centring.
                </li>
            </ul>
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 26: Creating Comfortable Spacing 🌟</h2>
            
            <p>
            Websites, like living rooms, need comfortable spacing. Padding inside your main content area gives text room to breathe, making it easier on the viewer's eyes. It’s about balancing content density with open space to achieve an aesthetically pleasing design.
            </p>
            <img src={twentySix} className='demo__image' alt='img_twentySix' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Within your .main-content style block, you've already defined the width and centered the content. Now, add padding: 20px; to provide a uniform cushion of space inside the container, separating the text from the edges.
                </li>

                <li>
                This padding ensures that the text isn't flush against the container's edges, which can feel cramped and is harder to read.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Padding: This CSS property defines space directly inside the boundaries of an element. Adding padding can increase the readability of content.
                </li>

                <li>
                Whitespace: Adequate whitespace around content can make web pages appear less cluttered and more user-friendly.
                </li>
            </ul>
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 27: Distinguishing the Main Content Area 🎭</h2>
            
            <p>
            Your main content area is the star of the show. It should stand out as the primary focus for readers. A different background color from the rest of the page can achieve this, drawing the eye to where the important content lives.
            </p>
            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Continue to style the .main-content block by setting a background-color. Adding background-color: #FFF; creates a clean, white backdrop for your text, making it pop against the softer grey of the body’s background.
                </li>

                <li>
                The contrast between the main content area and the rest of the page not only enhances focus but also gives your website a modern and professional look.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Background Color: It can significantly affect the readability and mood of the website. The right choice of background color for content areas can highlight the main sections and improve user experience.
                </li>

                <li>
                Design Contrast: Playing with contrast is a fundamental design principle that can guide users’ attention to or away from certain elements on a webpage.
                </li>
            </ul>
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 28: Rounding the Corners 🖌️🔲</h2>
            
            <p>
            Sharp corners can give a harsh and boxy feel to your design. Rounding the corners of your main content area can make your site feel friendlier and more approachable.
            </p>
            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Add border-radius: 8px; to your .main-content CSS block. This rounds the corners of your main content div, providing a smoother visual transition between the content and the background.
                </li>

                <li>
                The 8px radius is subtle yet sufficient to take the edge off, literally, without making the content area look too rounded. The higher the value the more round it will be.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Border Radius: This property is used to create rounded corners on elements. It’s part of the CSS3 specification and can enhance the aesthetics of your site.
                </li>

                <li>
                Visual Softening: Rounded corners can make interfaces seem more friendly and 'soft', often used in modern web and app design.
                </li>
            </ul>
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 29: Code Review Time! 🕵️‍♂️</h2>

            <p>
            Reviewing code is a crucial part of learning to program. It reinforces concepts and ensures that everything is in place. Let's go through the CSS for the .main-content class.
            </p>
            <img src={twentyNine} className='demo__image' alt='img_twentyNine' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Locate the .main-content class.
                </li>

                <li>
                Check that width is set to 80%. This sets the width of the main content area to 80% of its parent element, centering it on the page.
                </li>

                <li>
                Confirm margin is 20px auto. This gives a 20px margin on the top and bottom, and auto on the left and right, which centers the block horizontally.
                </li>

                <li>
                Verify padding is 20px, which provides a 20px space inside the border of the .main-content elements around the content.
                </li>

                <li>
                Ensure the background-color is #FFF, giving the content area a white background.
                </li>

                <li>
                The border-radius should be 8px, rounding the corners of the content box for a softer look.
                </li>

            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Width & Margin: These properties are used to control element sizing and positioning.
                </li>

                <li>
                Padding: Adds space inside an element, improving content readability.
                </li>

                <li>
                Background: Defines the element's background aesthetics.
                </li>

                <li>
                Border-Radius: Used for rounding the corners of an element's outer border edge.
                </li>
            </ul>

            <p>
            By reviewing your code, you’re ensuring that your styling is consistent and effective, setting a solid foundation for a well-structured website. Remember, good code review practices are just as important as writing code! 🎯✨
            </p>
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 30: Adding Depth with Shadow 🎭⚫</h2>

            <p>
            Flat designs are clean, but a little depth can make elements pop off the page. A subtle shadow can give the illusion of elevation, making your main content stand out.
            </p>
            <img src={thirty} className='demo__image' alt='img_thirty' />

            <h3>Do This:</h3>

            <ol>
                <li>
                To the same .main-content block, append box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);. This applies a shadow that seems to lift the content off the background.
                </li>

                <li>
                The values define the shadow's x-offset, y-offset, blur radius, and color, with the alpha channel (0.15) providing transparency for a gentle, soft shadow.
                </li>

                <li>
                    When you are ready create the next CSS target ".main-content .coverImage-container".
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Box Shadow: This property adds a shadow to an element, creating a sense of depth. You can control the horizontal and vertical offsets, the blur radius, the spread radius, and the color.
                </li>

                <li>
                Depth and Dimension: Subtle uses of shadows can add depth to a flat design, helping to differentiate between elements and increasing the overall aesthetic appeal.
                </li>
            </ul>
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 31: Flexing Your Containers 🏋️‍♂️</h2>

            <p>
            Flexbox is a powerful layout tool that makes it a breeze to design complex layouts. It allows you to align and distribute space among items in a container, even when their size is unknown or dynamic.
            </p>
            <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Within the .main-content .coverImage-container CSS block, set display: flex;. This tells the browser that you want to use the Flexbox model for all the child elements inside .coverImage-container.
                </li>

                <li>
                    This display: flex allows us to write "flex properties", which are properties that enable us to control the spacing, alignment, and orientation of what it is we are styling; in this case the "coverImage-container" and the image that will be inside of it.
                </li>

                <li>
                This single line of code empowers you to control the alignment, direction, order, and size of elements within this container using additional Flexbox properties that you may add later.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Flexbox: A layout model that simplifies the creation of flexible and responsive layouts.
                </li>

                <li>
                Flex Container: The element with display: flex; becomes a flex container, and its children become flex items, thus allowing us to apply flex properties to control the alignment, direction, order, and size.
                </li>
            </ul>
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 32: Understanding Flex Direction 🧭</h2>

            <p>
            Have you ever played with a compass? It tells you which direction you're heading, right? Well, in the world of web design, we have something similar called flex-direction that tells our website elements which way to go! Let's set the direction for our .coverImage-container to make our content look neat and tidy.
            </p>
            <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Find your .main-content .coverImage-container class in your CSS file. This is where we'll be writing our code.
                </li>

                <li>
                Add flex-direction: column;. This tells all the content inside to line up vertically! 
                </li>

                <li>
                Save your file and refresh your browser to see the content within "coverImage-container" be oriented vertically!
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                display: flex; turns on flexbox, which is a tool that helps us arrange elements in a row or a column.
                </li>

                <li>
                flex-direction: column; makes sure that all the items inside your container stack on top of each other from top to bottom. If you want them to line up side by side, you'd use flex-direction: row; instead.
                </li>
            </ul>
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 33: Aligning Items in Flexbox 🎯</h2>

            <p>
            Alright, squad! Now that our elements know where to stand, let's make sure they're centered on the stage. With align-items, we can tell them to stand right in the middle, so everyone in the audience gets a great view!
            </p>
            <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Stay focused on the .main-content .coverImage-container class. It's showtime! 🎭
                </li>

                <li>
                We already have display: flex; and flex-direction: column; from our previous adventure. Now let's add another line: align-items: center;
                </li>

                <li>
                This bit of code is like a director telling actors to stay in the center of the stage.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                align-items: center; is part of the Flexbox toolkit. It centers your items along the cross axis. Since we're using flex-direction: column;, the cross axis is horizontal, so this centers our items left-to-right.
                </li>

                <li>
                Remember, align-items works perpendicular to the direction set by flex-direction. So if you were using flex-direction: row;, align-items would center things top-to-bottom instead.
                </li>
            </ul>
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 34: Centering with Justify Content 🚀</h2>

            <p>
            Get ready to align content vertically within a container using the power of Flexbox's justify-content property.
            </p>
            <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Add the property justify-content: flex-start;. This aligns the flex items to the start of the div..
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Flexbox: A modern layout model giving you an efficient way to lay out, align, and distribute space among items in a container.
                </li>

                <li>
                justify-content: This CSS property aligns the flex items on the main axis (horizontal if your flex-direction is row, vertical if column).
                </li>

                <li>
                flex-start: Aligns items to the start of the flex container. For a column, this means the top of the container.
                </li>
            </ul>
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 35: Expanding to Full Width 🎨</h2>

            <p>
            Learn to expand your container to the full available width inside its parent element.
            </p>
            <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
            
            <h3>Do This:</h3>

            <ol>
                <li>
                Continue in the .main-content .coverImage-container rule.
                </li>

                <li>
                Add width: 100%; to make the container take up all available horizontal space within its parent.
                </li>

                <li>
                Don't forget to save and observe the result in the browser.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Width: The width property specifies the width of the element's content area.
                </li>

                <li>
                100%: By setting the width to 100%, it will consume all available space along the main axis of its parent, respecting padding and borders.
                </li>
            </ul>

            <p>
            With these steps, your content container is both vertically aligned to the start and stretched to the full width of its parent. This sets a strong foundation for more complex layouts!
            </p>
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 36: Styling Images with CSS</h2>

            <p>
            We're going to ensure that any img element within our HTML scales nicely to the width of its containing element without exceeding it.
            </p>
            <img src={thirtySix} className='demo__image' alt='img_thirtySix' />

            <h3>Do This:</h3>

            <ol>
                <li>
                max-width: 100%;: This property sets the maximum width of the image to 100% of its containing element. So if the image is naturally larger than the container, it will scale down to fit within it. This is very useful for responsive design, as the image will shrink on smaller screens, like those of mobile devices, ensuring it doesn't overflow the container.
                </li>

                <li>
                height: auto;: When we set the height to auto, it maintains the aspect ratio of the image. The browser will automatically adjust the height relative to the width, preserving the original proportions of the image. This means no matter how wide the image becomes (up to its natural size), it will always look right and not stretched or squished.
                </li>
            </ol>

        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 37: Adding a Paragraph in HTML</h2>

            <p>
            The p tag defines a block of text known as a paragraph. Here's the HTML snippet:
            </p>
            <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Opening &lt;p&gt;: This indicates the start of the paragraph element.
                </li>

                <li>
                Content: The text within the opening &lt;p&gt; and closing &lt;/p&gt; tags is the content of the paragraph. This content will be displayed on the webpage as a block of text, and by default, it is styled with some space above and below it, separating it from other elements like headings or other paragraphs. This space is known as margin.
                </li>

                <li>
                    After creating the &lt;p&gt; tag you can copy and paste the text in the box below between the two.
                </li>
            </ol>

            <CopyBox
            text={"SquidInk Studios is a family-owned art store located in the heart of Manhattan. We offer a wide range of high-quality art supplies for both professional artists and hobbyists."}
            />

            <p>
            When this paragraph is added to your HTML file and viewed in a web browser, the text will be displayed according to the default styles of the browser. You can add CSS to style it, such as setting the font size, line height, or color to match your design needs.
            </p>
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 38: Constructing the Information Section Container 🏗️</h2>

            <p>
            Before you can add content, you need to create a space where that content will live. This is where the concept of a container comes in. In web design, a container is an element that holds other elements together and can be styled collectively. In this step, we will create a container that will eventually house the information about the products.
            </p>
            <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Directly beneath the paragraph (&lt;p&gt;) tag that describes SquidInk Studios, create a new div element.                
                </li>

                <li>
                Assign a class to this div by setting the class attribute to "info-section". Your line of HTML should look like this: &lt;div class="info-section"&gt;&lt;/div&gt;.
                </li>

                <li>
                This div will act as a container for future content related to your products or services.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Containers in HTML: Think of containers as boxes within your webpage where you can place content. They are essential for organizing your site's layout.
                </li>

                <li>
                Information Architecture: This is about organizing the content on your website so that it's understandable and navigable. This includes using the right HTML elements and structuring your CSS appropriately.
                </li>
            </ul>
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 39: Introducing the Section Header 📝</h2>

            <p>
            Headers are like the titles on a page of a book; they tell you what you can expect to read in the following section. The h2 element is used for the major headers on a webpage, second in hierarchy to h1, which is usually reserved for page titles or main content headings.
            </p>
            <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Inside the info-section div you just created, insert an &lt;h2&gt; tag.
                </li>

                <li>
                Between the opening &lt;h2&gt; and closing &lt;/h2&gt;, type in the title for your section. For example, you can write “Our Products”.
                </li>

                <li>
                With that, you have effectively placed a header that introduces the content that will follow.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                h2 Element: The h2 element represents a level 2 header in your HTML document. It's used when you want to introduce a new section or topic within your content.
                </li>

                <li>
                Content Structure: Using headers properly structures your content, making it more digestible and easier to understand. It breaks down the information into manageable pieces.
                </li>
            </ul>
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 40: Listing Your Products with Unordered Lists 📝</h2>

            <p>
            An unordered list in HTML provides a way to organize items visually on a page. It's not just about the list items themselves; it's about presenting them in a way that's easy for your users to understand and navigate. In this step, you will learn how to use the &lt;ul&gt; and &lt;li&gt; elements to create a list of products for SquidInk Studios.
            </p>
            <img src={forty} className='demo__image' alt='img_forty' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Inside the info-section div, right after the h2 tag, start an unordered list with the &lt;ul&gt; tag.
                </li>

                <li>
                Add three list items with the &lt;li&gt; tag. Each list item will represent a different product category such as 'Paints', 'Brushes', and 'Canvases'.
                </li>

                <li>
                For now, keep the list items empty. You will populate them with content in the next step.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Unordered Lists (&lt;ul&gt;): This HTML element represents a list of items, where the order of the items is not important. It's great for grouping similar items together.
                </li>

                <li>
                List Items (&lt;li&gt;): Each item in your list is wrapped in an &lt;li&gt; tag, signifying it as a single entry within the list.
                </li>

                <li>
                Content Organization: Organizing content into lists helps users process information more efficiently. It breaks down data into digestible chunks, improving readability.
                </li>
            </ul>
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 41: Adding Images to List Items 🌄</h2>

            <p>
            Images play a crucial role in web design by providing a visual complement to your text content. They can be especially powerful in product listings, allowing users to quickly identify items of interest. In this step, we'll enhance the product list by adding images to each list item.
            </p>
            <img src={fortyOne} className='demo__image' alt='img_fortyOne' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Within each &lt;li&gt; element, insert an &lt;img/&gt; tag.
                </li>

                <li>
                Set the src attribute to point to the image file that corresponds to the product. For example, the first list item might have an image of paint, so set src="./images/paint.png".
                </li>

                <li>
                Use the alt attribute to provide an alternative text description of the image. This improves accessibility and helps with SEO if the image cannot be displayed.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Images in HTML (&lt;img/&gt;): The &lt;img/&gt; element represents an image in the document. It's self-closing, meaning it doesn't need a closing tag.
                </li>

                <li>
                Source Attribute (src): This attribute is required in an &lt;img/&gt; tag and specifies the path to the image you want to display.
                </li>

                <li>
                Alternative Text (alt): The alternative text is used to describe the image if it cannot be displayed and is also read by screen readers for users with visual impairments.
                </li>
            </ul>
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 42: Describing Products with Text and Emphasis 🖋️</h2>

            <p>
            After visually presenting your products with images, the next step is to describe them with text. This is crucial as it gives your visitors an understanding of what you offer and why it might be right for them. Strong descriptions can influence and enhance the user experience on your site.
            </p>
            <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Under each product image within the &lt;li&gt; elements, add a paragraph (&lt;p&gt;) tag to hold the descriptive text about the product.
                </li>

                <li>
                Start your description with a bold tag &lt;strong&gt; to highlight the product name. This makes the name stand out and immediately catch the viewer's eye.
                </li>

                <li>
                After the product name, write a clear and concise description of the product. For instance, for the 'Paints', describe the types you offer and perhaps mention the quality or uses.
                </li>

                <li>
                Repeat this process for each list item, ensuring each product has a unique description that explains its features or benefits.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Paragraphs in HTML (&lt;p&gt;): The paragraph element is used to define blocks of text. It's a block-level element that automatically starts on a new line.
                </li>

                <li>
                Text Emphasis (&lt;strong&gt;): Using the &lt;strong&gt; tag semantically indicates that the text is of strong importance, which is typically displayed as bold by web browsers.
                </li>

                <li>
                Product Descriptions: Good product descriptions provide the essential information needed to make a purchasing decision. They should be informative, succinct, and highlight the key selling points of the product.
                </li>
            </ul>
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 43: Styling the Information Section with CSS 🎨</h2>

            <p>
            Now that you have structured the content with HTML, it's time to make it visually appealing with CSS. Styling is not just about making things look pretty—it's about creating a user-friendly interface that guides visitors through your site with ease. The .info-section class styling you will apply helps to define the visual layout of the product information area on the SquidInk Studios website.
            </p>
            <img src={fortyThree} className='demo__image' alt='img_fortyThree' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Create the .info-section class selector.
                </li>

                <li>
                Apply a margin property of 20px 0. This means the top and bottom margins are set to 20px, and the left and right margins are set to 0. This centers the content within the section and gives it vertical breathing room.
                </li>

                <li>
                By styling the .info-section, you are enhancing the visual spacing, which helps to separate the content from other elements on the page, making it more digestible.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Class Selectors: In CSS, the class selector is used to select elements with a specific class attribute. It’s denoted by a period (.) followed by the class name.
                </li>

                <li>
                Margin Property: The margin property in CSS is used to create space around elements, outside of any defined borders. With four values, it works clockwise from the top (top, right, bottom, left).
                </li>
            </ul>
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 44: Refining the List Style 📐</h2>

            <p>
            Customizing the style of your lists is essential to maintain the visual harmony of your webpage. Default browser styles often include bullet points and indents, which may not fit well with your design. By removing these and resetting the padding, you create a clean slate to apply your own styles and ensure that your design is consistent.
            </p>
            <img src={fortyFour} className='demo__image' alt='img_fortyFour' />

            <h3>Do This:</h3>
            
            <ol>
                <li>
                Find the ul element in your CSS file. This style will apply to all unordered lists on your webpage, so ensure you target the ones you intend to style.
                </li>

                <li>
                Set the list-style property to none. This removes the default bullet points.
                </li>

                <li>
                Set the padding to 0 to remove the default padding and ensure the list aligns nicely with the other content.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                List Style: The list-style CSS property is used to define the appearance of the list item markers (such as bullets or numbers).
                </li>

                <li>
                Padding: Padding creates space within an element, between the element's content and its border. Setting it to 0 removes any inherent space that might affect your layout.
                </li>

                <li>
                CSS Reset: The practice of resetting certain CSS properties to ensure consistency across different browsers is known as a CSS reset.
                </li>
            </ul>
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 45: Aligning List Items with Flexbox</h2>

            <p>
            Flexbox is a powerful tool for aligning items horizontally and vertically within their container, and it's perfect for aligning elements like images and text within a list item. Using Flexbox, you can easily center content, distribute space, and create a responsive design that adjusts to different screen sizes.
            </p>
            <img src={fortyFive} className='demo__image' alt='img_fortyFive' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Now target the li elements within the ul in your CSS file to apply Flexbox styling.
                </li>

                <li>
                Set the display property to flex. This will initiate Flexbox for the list items.
                </li>

                <li>
                Use align-items: center; to vertically center the items within the list. This ensures that the text and images are aligned in the middle.
                </li>

                <li>
                Apply a margin to the list items with margin: 20px 0; to give them vertical space between each other.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Flexbox: The Flexible Box Layout Module makes it easier to design flexible responsive layout structures without using float or positioning.
                </li>

                <li>
                align-items: This Flexbox property aligns items vertically within the container, and center is one of its values.
                </li>

                <li>
                Vertical Centering: Aligning items in the center vertically is a common design pattern that helps maintain a balanced and accessible interface.
                </li>
            </ul>
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 46: Targeting Images within List Items for Styling 🎯</h2>

            <p>
            To make our images integrate seamlessly with the text in our product listings, we need to specifically target them with CSS. This selective targeting allows us to apply styles directly to the images inside list items, ensuring that any changes won't unintentionally affect images elsewhere on the page.
            </p>
            <img src={fortySix} className='demo__image' alt='img_fortySix' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Directly beneath the ul li block, create a new CSS rule that targets images within list items. This is done by writing ul li img.
                </li>

                <li>
                For now, simply open and close your CSS rule with curly braces. You’re creating a placeholder for the specific styles you’ll add next.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                CSS Selectors: The ul li img selector is a descendant selector in CSS that targets img elements specifically inside li elements, which are inside ul elements.
                </li>

                <li>
                Targeted Styling: By targeting elements specifically, we can apply styles that won't affect other similar elements elsewhere on the site, providing us with precise control over our page layout.
                </li>
            </ul>
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 47: Creating Visual Separation for List Images 🔲➖</h2>

            <p>
            Now that we have targeted our images, we'll create visual separation by adding a margin. Margins are the breath of fresh air for web elements—they prevent our images from clinging too tightly to the text, allowing each element its own space to shine.
            </p>
            <img src={fortySeven} className='demo__image' alt='img_fortySeven' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Return to the ul li img selector you just created in your CSS file.
                </li>

                <li>
                Within the curly braces, add a declaration for the margin-right property. This will add space to the right of the image.
                </li>

                <li>
                Set the value of margin-right to 20px. This is typically enough space to clearly separate the image from the text but feel free to adjust if your design calls for a different spacing.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Margins: Margins are used in CSS to create extra space around elements. Unlike padding, margins affect the space outside of an element.
                </li>

                <li>
                margin-right: This property specifically applies space on the right side of an element, which is perfect for our horizontal list layout.
                </li>
            </ul>
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 48: Setting a Fixed Size for Images 🔲📏</h2>

            <p>
            Consistency in image size not only adds to the aesthetic appeal of a website but also provides a more uniform and professional look to your product listings. Let's standardize the size of the images in the list items.
            </p>
            <img src={fortyEight} className='demo__image' alt='img_fortyEight' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Add a width property and set its value to 150px. This will ensure that all images within the list items have the same width.
                </li>

                <li>
                Add a height property, also setting it to 150px, to maintain a square aspect ratio for all images.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Fixed Dimensions: Setting both the width and height ensures that all images will be exactly the same size, regardless of their original dimensions.
                </li>

                <li>
                Square Aspect Ratio: Using equal width and height dimensions creates square images, which are often easier to arrange in a layout and provide a sense of order. 
                </li>
            </ul>
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 49: Ensuring Images Fit Well in Their Frame 🖼️✂️</h2>

            <p>
            Now that our images have a consistent size, we want to make sure they look good too! No stretched or squished photos—each image should cover its designated area without losing its aspect ratio. This is where the object-fit property comes into play.
            </p>
            <img src={fortyNine} className='demo__image' alt='img_fortyNine' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Continue within the same ul li img CSS rule where you've set the size of the images.
                </li>

                <li>
                Add the object-fit property with a value of cover. This makes the image cover the area of the frame (the width and height you’ve set), cropping it if necessary, without distorting the image.
                </li>

                <li>
                After saving, refresh your webpage. Your images will now fill their square frames nicely, providing a gallery-like uniformity to the product list.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                object-fit Property: This CSS property determines how the content of a replaced element, such as an img, should be resized to fit its container.                
                </li>
            </ul>
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 50: Softening Image Edges with Border Radius 🌟🖼️</h2>

            <p>
            Sharp corners on images can make a design feel rigid. By applying a border-radius, we can soften the edges, giving the images a more polished, friendly look. This small touch can significantly affect the feel of the site, making it more welcoming.
            </p>
            <img src={fifty} className='demo__image' alt='img_fifty' />

            <h3>Do This:</h3>

            <ol>
                <li>
                In your CSS file, locate the ul li img selector where you've been styling your images.
                </li>

                <li>
                Add the border-radius property and set it to 8px. This will round the corners of your images, providing a subtle but visually appealing effect.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                border-radius: This CSS property is used to round the corners of an element's outer border edge.
                </li>

                <li>
                Aesthetic Details: Small changes like border-radius can have a big impact on the overall aesthetics and user experience of a website.
                </li>
            </ul>
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 51: Preparing for Additional Content Sections 📃</h2>

            <p>
            Great web design often involves repeating patterns. With one product section complete, you might want to add more. Setting up another content section allows for future expansion, like adding details about services, customer testimonials, or more product categories.
            </p>
            <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Return to your HTML document and add a new info-section div.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Content Expansion: Creating space for future content ensures that your website can grow and evolve with your business needs.
                </li>
            </ul>
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 52: Introducing a New Section Header 📍</h2>

            <p>
            Dividing your content into sections with clear headings not only helps visitors navigate your page but also improves the overall structure and accessibility of your website. Let's add a header for a new section where you can provide important information like your location.
            </p>
            <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />

            <h3>Do This:</h3>

            <ol>
                <li>
                In your HTML file, find the new info-section div you created earlier.
                </li>

                <li>
                Within this div, add an h2 element for your section header. For this example, let's title it "Location" to inform visitors where they can find SquidInk Studios.
                </li>

                <li>
                Save your HTML file. When you refresh your page, you'll see the new "Location" header, marking the start of a new content section.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Headings: Headings (h1, h2, h3, etc.) are used to structure content and give it hierarchical importance on the page.
                </li>

                <li>
                Section Header: The h2 tag is used for secondary headers, which denote the beginning of a new section within the page.
                </li>
            </ul>
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 53: Adding Descriptive Text to Your New Section 📝</h2>

            <p>
            A header attracts attention, but it's the descriptive text that delivers the information. Adding a paragraph under your section header provides visitors with valuable details in a clear and concise format.
            </p>
            <img src={fiftyThree} className='demo__image' alt='img_fiftyThree' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Under the "Location" header in the same info-section div, add a paragraph (p) element.
                </li>

                <p>
                Inside the paragraph, write a description that provides the necessary location details of SquidInk Studios.
                </p>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Content Descriptions: Clear and detailed descriptions within your content are essential for informing and engaging with your audience.
                </li>

                <li>
                HTML Paragraphs: The p tag defines a block of text, which is separated from other blocks by blank lines and can be styled independently.
                </li>
            </ul>
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 54: Adding a Map Image to Your Location Section 🗺️</h2>

            <p>
            A picture is worth a thousand words, and when it comes to directions, a map is invaluable. Including a map image in your location section can visually guide your customers to your storefront, making it a practical addition to your contact information.
            </p>
            <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Inside the info-section div dedicated to your location, right after the paragraph with your address, insert an img tag.
                </li>

                <li>
                Set the src attribute to the relative path where your map image is stored, like ./images/map.png.
                </li>

                <li>
                Use the alt attribute to describe the image, which is essential for accessibility and SEO. Here you can simply put "Map".
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Images in HTML: The img tag is used to embed an image in an HTML page. The src attribute specifies the path to the image, and the alt attribute provides alternative information if the image cannot be displayed.
                </li>
            </ul>
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 55: Preparing for Additional Sections 🛠️📄</h2>

            <p>
            As your website grows, so does the need for more content sections. You may want to add details about your services, testimonials, or more product categories. Preparing the layout for additional content keeps your website scalable and manageable.
            </p>
            <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />

            <h3>Do This:</h3>

            <ol>
                <li>
                After closing the current info-section div, create another div with the same class for more content.
                </li>

                <li>
                Leave this new div empty for now. This sets the stage for additional information you may add later.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Scalability: By adding multiple info-section divs, you're planning for the future scalability of your website.
                </li>
            </ul>
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 56: Creating the Contact Section Header ☎️</h2>

            <p>
            The 'Contact Us' section is a vital part of any website, acting as a bridge between you and your visitors. A clear and visible header for this section is crucial for guiding users who wish to reach out.
            </p>
            <img src={fiftySix} className='demo__image' alt='img_fiftyFive' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Within the new info-section div you’ve prepared, create an h2 header with the text "Contact Us".
                </li>

                <li>
                Ensure the header is correctly nested inside the div to maintain a proper HTML structure.
                </li>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                Headers: In HTML, headers (&lt;h1&gt; to &lt;h6&gt;) denote the start of a new section and give visitors a hint of what the section is about.
                </li>
            </ul>
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 57: Adding Contact Information 📧📍</h2>

            <p>
            After signaling to your visitors where they can find contact information with a header, the next step is to actually provide that information. Email and phone numbers are direct lines of communication for inquiries, support, or feedback.
            </p>
            <img src={fiftySeven} className='demo__image' alt='img_fiftyFive' />

            <h3>Do This:</h3>

            <ol>
                <li>
                Directly below the "Contact Us" header within the info-section div, add a paragraph (&lt;p&gt;) for the email address.
                </li>

                <li>
                Add another paragraph for the phone number.
                </li>

                <p>
                Make sure each piece of information is fake, never give away your real contact information online. Provide a placeholder value to simulate what the email address and phone number would be for this fictitious store.
                </p>
            </ol>

            <h3>Concepts:</h3>

            <ul>
                <li>
                HTML Paragraphs: Separate paragraphs for different types of contact information help to keep the information organized and accessible.
                </li>
            </ul>
        </div>


        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>


    </div>
  )
}

export default SquidInk