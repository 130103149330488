import React, {useState} from 'react'
import one from './scratchImages/pongImages/1.png';
import two from './scratchImages/pongImages/2.png';
import three from './scratchImages/pongImages/3.png';
import four from './scratchImages/pongImages/4.png';
import five from './scratchImages/pongImages/5.png';
import six from './scratchImages/pongImages/6.png';
import seven from './scratchImages/pongImages/7.png';
import eight from './scratchImages/pongImages/8.png';
import nine from './scratchImages/pongImages/9.png';
import ten from './scratchImages/pongImages/10.png';
import eleven from './scratchImages/pongImages/11.png';
import twelve from './scratchImages/pongImages/12.png';
import thirteen from './scratchImages/pongImages/13.png';
import fourteen from './scratchImages/pongImages/14.png';
import fifteen from './scratchImages/pongImages/15.png';
import sixteen from './scratchImages/pongImages/16.png';
import seventeen from './scratchImages/pongImages/17.png';
import eighteen from './scratchImages/pongImages/18.png';
import nineteen from './scratchImages/pongImages/19.png';
import twenty from './scratchImages/pongImages/20.png';
import twentyOne from './scratchImages/pongImages/21.png';
import twentyTwo from './scratchImages/pongImages/22.png';
import twentyThree from './scratchImages/pongImages/23.png';
import twentyFour from './scratchImages/pongImages/24.png';
import twentyFive from './scratchImages/pongImages/25.png';
import twentySix from './scratchImages/pongImages/26.png';
import twentySeven from './scratchImages/pongImages/27.png';
import twentyEight from './scratchImages/pongImages/28.png';
import twentyNine from './scratchImages/pongImages/29.png';
import thirty from './scratchImages/pongImages/30.png';
import thirtyOne from './scratchImages/pongImages/31.png';
import thirtyTwo from './scratchImages/pongImages/32.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import "./tutorial.css";

const Pong = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 32;
  
  return (
    <div className='scratchGames__container' >
        <h1>
        🏓💡 "Paddle Pursuit: The Next-Gen Pong Challenge!" 💡🏓        
        </h1>

        <h3 className='intro'>
        Step back into the golden era of gaming with a modern twist in "Paddle Pursuit: The Next-Gen Pong Challenge!" 🏓💡 This isn't just the classic Pong you remember; it's an electrifying reimagining of the legendary game that started it all! Are you ready to reignite the rivalry and prove your Pong prowess? 🏓🔥🎮
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>




        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎉 Step 1 🎉</h2>
          <ul>
            <li>
              To start this project navigate to the Scratch website.
            </li>

            <li>
              Scratch: <a target="_blank" href="https://scratch.mit.edu/" >https://scratch.mit.edu/</a>
            </li>

            <li>
              Select the "create" tab at the top to open a new project.
            </li>
          </ul>

          <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌟 Step 2 🌟</h2>
          <ul>
            <li>
              In the game "pong" there is a paddle bouncing a ball. When the ball hits the paddle the player gets a point, but when they miss the ball... it is game over!
            </li>

            <li>
              Delete the cat and find the "Paddle" in the sprites section. This Sprite looks like a green line.
            </li>
          </ul>

          <img src={two} className='demo__image' alt='img_two' />
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚀 Step 3 🚀</h2>
          <ul>
            <li>
              The final product for the code of the paddle will be the paddle following where the user points their mouse on the screen. This way the user can try to catch the ball by moving the mouse!
            </li>

            <li>
              To start the project begin with a "when flag clicked" followed by a "forever" block, remember what ever code that we create in the "forever" block will be repeated.
            </li>

            <li>
              In the next step we will make sure that the paddle follows the player's mouse "forever".
            </li>
          </ul>

          <img src={three} className='demo__image' alt='img_three' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎈 Step 4 🎈</h2>
          <ul>
            <li>
              To set the position of the paddle based on the player's mouse, we will change the "X" axis of the paddle. The "X" axis of the paddle controls the movement of left to right.
            </li>

            <li>
              Add the "set x to 10" block.
            </li>
          </ul>

          <img src={four} className='demo__image' alt='img_four' />
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🔥 Step 5 🔥</h2>
          <ul>
            <li>
              But now, instead of setting the movement of the paddle's "X" axis to "10", we should set it to "mouse X" which is the "X" position of the mouse.
            </li>

            <li>
              This will result in the "X" position of the paddle to match the "X" position of the mouse.
            </li>

            <li>
              When the player moves their mouse left the paddle will move left, and when the player moves their mouse to the right the paddle will move to the right.
            </li>
          </ul>

          <img src={five} className='demo__image' alt='img_five' />
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>💡 Step 6 💡</h2>
          <ul>
            <li>
              Right now the paddle is in the center of the screen, to give the player a fair opportunity to catch the ball, we need the paddle to be at the bottom of the screen.
            </li>

            <li>
              Click and drag the paddle to be at the bottom of the screen as seen in the image below.
            </li>
          </ul>

          <img src={six} className='demo__image' alt='img_six' />
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍀 Step 7 🍀</h2>
          <ul>
            <li>
              It is time to introduce the ball into our game, open the "sprites" category and add the "ball". We will begin adding its code in the next step.
            </li>
          </ul>

          <img src={seven} className='demo__image' alt='img_seven' />
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌈 Step 8 🌈</h2>
          <ul>
            <li>
              As per most elements in our game, we need to start the code with a "when flag clicked".
            </li>

            <li>
              The first block of code after the "when flag clicked" should be a "turn right 15 degrees" so that we can start the ball in the correct position.
            </li>
          </ul>

          <img src={eight} className='demo__image' alt='img_eight' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎁 Step 9 🎁</h2>
          <ul>
            <li>
              Then add a "forever" block to keep the ball in perpetual motion.
            </li>
          </ul>

          <img src={nine} className='demo__image' alt='img_nine' />
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎶 Step 10 🎶</h2>
          <ul>
            <li>
            With in the "forever" block add two new pieces: "move 15 steps" and "if on edge, bounce"
            </li>

            <li>
              These two new blocks are in the "forever" so the ball will constantly be moving "15 steps" and bouncing if it hits the edge of the screen.
            </li>
          </ul>

          <img src={ten} className='demo__image' alt='img_ten' />
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌺 Step 11 🌺</h2>
          <ul>
            <li>
              The ball will need a second sequence of code to track the score and respond to the user catching it with the paddle.
            </li>

            <li>
              Start this second sequence of code with a "when flag clicked".
            </li>
          </ul>

          <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍄 Step 12 🍄</h2>
          <ul>
            <li>
              In order to track the player's score we need to create a variable for it.
            </li>

            <li>
            Navigate to the "variables" section and press the "make a variable" button.
            </li>
          </ul>

          <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍪 Step 13 🍪</h2>
          <ul>
            <li>
              Name the variable "score", remember this is just the name... the value will be the actual number of the score.
            </li>

            <li>
              Press "OK" after naming the variable "score".
            </li>
          </ul>
        
          <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐾 Step 14 🐾</h2>
          <ul>
            <li>
              The first block after the "when flag clicked" will set the score to 0. Now the score will reset to 0 at the start of every game.
            </li>

            <li>
              Add a "set Score to 0" block. Use the image below as needed.
            </li>
          </ul>

          <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌊 Step 15 🌊</h2>
          <ul>
            <li>
              Add a "forever" block to contain the up coming code.
            </li>
          </ul>

          <img src={fifteen} className='demo__image' alt='img_fifteen' />
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍉 Step 16 🍉</h2>
          <ul>
            <li>
              Next, we need to check if this ball that we are currently coding is touching the "paddle" to do this we need an "if then block". Find and add the "if then block".
            </li>

            <li>
              In the "if then block" create a condition of "touching paddle", the blue block that you see in the image below.
            </li>

            <li>
              The result of this should be an "if statement" checking for the condition "if touching paddle then". We will fill out what happens if the it is touching the paddle in the next step!
            </li>
          </ul>

          <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎭 Step 17 🎭</h2>
          <ul>
            <li>
              If the ball touches the paddle, then we need to increase the score and bounce the ball off of the paddle.
            </li>

            <li>
              Increase the score by adding the block: "change score by 1"
            </li>

            <li>
              To start the "bounce" movement add a "turn right 15 degrees" block.
            </li>
          </ul>

          <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎨 Step 18 🎨</h2>
          <ul>
            <li>
              Currently we have "turn right 15 degrees" which will always bounce the ball in the same direction. We need to pick a random direction.
            </li>

            <li>
              Find the "pick random" operator and replace the "15" with it.
            </li>

            <li>
              The "pick random" operator should pick a random number between "170" and "190".
            </li>
          </ul>

          <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚴 Step 19 🚴</h2>
          <ul>
            <li>
              Now that we have completed the block that picks the direction the ball bounces off of paddle, we need to move it in that direction with "move 15 steps".
            </li>

            <li>
            Add a move 15 steps to move the ball forward and a "wait 0.5 seconds".
            </li>
          </ul>

          <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍦 Step 20 🍦</h2>
          <ul>
            <li>
              The image that you see below is the final result of the second code sequence for the ball.
            </li>
          </ul>

          <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌍 Step 21 🌍</h2>
          <ul>
            <li>
              The next section of this project is the create an "out of bounds" section, where if the ball goes there then the game is over!
            </li>

            <li>
              Add a new sprite and search for the "line" sprite and add it.
            </li>
          </ul>
        
          <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🕺 Step 22 🕺</h2>
          <ul>
            <li>
              After you add the line size and move it to the very bottom edge of the game, this will be the "out of bounds" line.
            </li>

            <li>
              Use the image below to guide you sizing and shaping the line.
            </li>
          </ul>

          <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎤 Step 23 🎤</h2>
          <ul>
            <li>
              Let's get coding this "out of bounds" line.
            </li>

            <li>
              Add a "when flag clicked" to start the code when the game starts and a "go to X: 0 Y: -180" to ensure that the line starts at the bottom of the screen.
            </li>

            <li>
              Remember the "go to X: Y:" block moves the sprite to the coordinates that we give it. In our case the "X" needs to be "0" and the "Y" needs to be "-180".
            </li>
          </ul>

          <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎬 Step 24 🎬</h2>
          <ul>
            <li>
              Add a "forever" block, we will use this "forever" block to forever be checking if the ball is touching the line.
            </li>
          </ul>

          <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎮 Step 25 🎮</h2>
          <ul>
            <li>
              Code the statement to check if the line is touching the ball.
            </li>

            <li>
              Use a "if block" with a condition of "touching ball" and a result of "stop all".
            </li>

            <li>
            Assemble these pieces to create the sequence that you see below.
            </li>
          </ul>

          <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>📚 Step 26 📚</h2>
          <ul>
            <li>
              Drag and drop the "if block" that you created in the last step into the "forever" block so that the code is always checking if the ball is touching the line.
            </li>
          </ul>

          <img src={twentySix} className='demo__image' alt='img_twentySix' />
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚂 Step 27 🚂</h2>
          <ul>
            <li>
              Now what happens when the ball touches the line? The game is over and the player looses! But what if the player wins? We need a victory sprite!
            </li>

            <li>
              Select the "paintbrush" to create a custom sprite that will become out victory message.
            </li>
          </ul>

          <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🏖 Step 28 🏖</h2>
          <ul>
            <li>
            Use the "add text" button and write a "You Win" message, customize the text and go back to the code of it when you are done.
            </li>
          </ul>

          <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎡 Step 29 🎡</h2>
          <ul>
            <li>
              To code this victory message add the two following blocks: "when flag clicked" and "hide".
            </li>

            <li>
              When the game starts (when flag clicked) the "hide" block causes the message to become invisible; we will make it visible when someone wins the game.
            </li>
          </ul>

          <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍕 Step 30 🍕</h2>
          <ul>
            <li>
            Find the block "wait until" and replace the time (default number) with an "equals operator" checking if the "score" variable is equal to "5".
            </li>

            <li>
              The result of the block should be similar to the image below: "wait until score equals 5".
            </li>
          </ul>

          <img src={thirty} className='demo__image' alt='img_thirty' />
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌜 Step 31 🌜</h2>
          <ul>
            <li>
              When the score is equal to 5, add the "show" block to make the message visible and the "stop all" to stop the game from playing; because, the game is over!
            </li>
          </ul>

          <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>⛱ Step 32 ⛱</h2>
          <ul>
            <li>
              And the final step is to give our "Pong" game a name and save it for other Scratchers to enjoy. Congratulations on completing this amazing project!
            </li>
          </ul>

          <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default Pong