import React, { useState, useEffect } from 'react';
import './myProgress.css';
import { ToastContainer } from 'react-toastify';
import Navbar from '../../components/navbar/navbar';
import Sidebar from '../../components/sidebar/sidebar';
import Loader from '../../components/loader/loader';
import { SiScratch } from "react-icons/si";
import { AiOutlinePython } from "react-icons/ai";
import { SiLua } from "react-icons/si";
import { MdOutlineEngineering } from "react-icons/md";
import axios from 'axios';
import ProgressCard from '../../components/progress-card/progressCard';
import MetalBar from '../../components/metal-bar/metalBar';
import api from '../../api/api';

const MyProgress = () => {
  const _id = JSON.parse(localStorage.getItem("user"))?._id
  var [scratch, setScratch] = useState([]);
  var [python, setPython] = useState([]);
  var [roblox, setRoblox] = useState([]);
  var [fullstack, setFullstack] = useState([]);
  var [subject, setSubject] = useState("scratch");
  var [percentComplete, setPercentComplete] = useState("0%");
  var [loading, setLoading] = useState(false);





  const fetchUser = async (id) => {
    await api.post(`${process.env.REACT_APP_API_URL}/single-user`, {
      id
    }).then((res) => {
      if (res.data.approved == false) {
        window.location.href = `https://kidslearncoding.org/login`
      } else {
        localStorage.setItem("user", JSON.stringify(res.data));
        window.dispatchEvent(new CustomEvent("roleChanged"));
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const validateToken = async (token) => {
    await api.post(`${process.env.REACT_APP_API_URL}/val-token`, {
      token
    }).then((res) => {
      fetchUser(res.data.user._id)
    }).catch((err) => {
      localStorage.removeItem('authToken');
      checkCookie()
    })
  }



  const checkCookie = async () => {
    // JavaScript that runs on my.kidslearncoding.org landing page
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');


    if (token) {
        localStorage.setItem('authToken', token);
        // Remove the token from the URL for security
        window.history.replaceState({}, document.title, "/");
        validateToken(token)
    } else {
        // If there's no token in the URL, you might want to log the token from Local Storage (if any)
        const storedToken = localStorage.getItem('authToken');
        if (storedToken) {
            validateToken(storedToken)
        } else {
            window.location.href = `https://kidslearncoding.org/login`;
        }
    }
  }

  useEffect(() => {
      checkCookie()
  }, []);



  const getAwards = async () => {
    await api.post(`${process.env.REACT_APP_API_URL}/get-student-award-models`, {
        studentID: _id
    }).then((res) => {
        console.log(res.data)
    }).catch((err) => {
        console.log(err)
    })
  }



  const getStudentProjects = async () => {
    setLoading(true)
    await api.post(`${process.env.REACT_APP_API_URL}/get-badge`, {
        studentID: _id
    }).then((res) => {
        console.log(res)
        setScratch(res.data[0].scratch)
        setPython(res.data[0].python)
        setRoblox(res.data[0].roblox)
        setFullstack(res.data[0].fullstack)

        var projectCount = 0;
        var projectTrue = 0;
        var projectFalse = 0;
        for (let i = 0; i < res.data[0].scratch.length; i++) {
            projectCount += 1

            if (res.data[0].scratch[i][1] == true) {
                projectTrue += 1;
            } else if (res.data[0].scratch[i][1] == false) {
                projectFalse += 1;
            }
        }

        var percent = Math.round((projectTrue / projectCount) * 100);

        setPercentComplete(percent)

        setLoading(false)
    }).catch((err) => {
        console.log(err)
        setLoading(false)
    })
  }


  useEffect(()=> {
    var choosenDataset;

    if (subject == 'scratch') {
        choosenDataset = scratch;
    }

    if (subject == 'python') {
        choosenDataset = python;
    }

    if (subject == 'roblox') {
        choosenDataset = roblox;
    }

    if (subject == 'fullstack') {
        choosenDataset = fullstack;
    }

    var projectCount = 0;
    var projectTrue = 0;
    var projectFalse = 0;
    for (let i = 0; i < choosenDataset.length; i++) {
        projectCount += 1

        if (choosenDataset[i][1] == true) {
            projectTrue += 1;
        } else if (choosenDataset[i][1] == false) {
            projectFalse += 1;
        }
    }

    var percent = Math.round((projectTrue / projectCount) * 100);

    setPercentComplete(percent)
  }, [subject])


  useEffect(()=> {
    getStudentProjects()
  }, [])

  return (
    <div className='my-progress-page'>
        <ToastContainer />
        <Sidebar />

        <div className='page-right'>
            <Navbar />

            {
                loading === true ?
                <div className="myprogress__loading">
                    <Loader />
                </div>
                :
                <div className='my-progress-container'>

                    <div className='progress-container-left'>
                        <div className='progressbar-container'>
                            <ProgressCard subject={subject} percentComplete={percentComplete} />
                        </div>

                        <div className='projects'>
                            {
                                subject == "scratch" &&
                                scratch?.map((item) => {
                                    return (
                                    <MetalBar projectName={item[0]} projectStatus={item[1]} />
                                    )
                                })
                            }

                            {
                                subject == "python" &&
                                python?.map((item) => {
                                    return (
                                    <MetalBar projectName={item[0]} projectStatus={item[1]} />
                                    )
                                })
                            }

                            {
                                subject == "roblox" &&
                                roblox?.map((item) => {
                                    return (
                                    <MetalBar projectName={item[0]} projectStatus={item[1]} />
                                    )
                                })
                            }

                            {
                                subject == "fullstack" &&
                                fullstack?.map((item) => {
                                    return (
                                    <MetalBar projectName={item[0]} projectStatus={item[1]} />
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='progress-container-right'>
                        <div onClick={()=>setSubject("scratch")} className='path'>
                            <SiScratch size={58} className='path-icon' /> 

                            <div class="glitch-wrapper">
                                <div class="glitch" data-glitch="scratch">scratch</div>
                            </div>
                        </div>

                        <div onClick={()=>setSubject("python")} className='path'>
                            <AiOutlinePython size={58} className='path-icon' /> 
                            
                            <div class="glitch-wrapper">
                                <div class="glitch" data-glitch="python">python</div>
                            </div>
                        </div>

                        <div onClick={()=>setSubject("roblox")} className='path'>
                            <SiLua size={58} className='path-icon' />
                            <div class="glitch-wrapper">
                                <div class="glitch" data-glitch="roblox">roblox</div>
                            </div>
                        </div>
                        
                        <div onClick={()=>setSubject("fullstack")} className='path'>
                            <MdOutlineEngineering size={58} className='path-icon' /> 
                            <div class="glitch-wrapper">
                                <div class="glitch" data-glitch="fullstack">fullstack</div>
                            </div>
                        </div>
                    </div>

                </div>
            }
        </div>
    </div>
  )
}

export default MyProgress