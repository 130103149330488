import React, {useState} from 'react';
import one from './scratchImages/foodfightImages/1.png';
import two from './scratchImages/foodfightImages/2.png';
import three from './scratchImages/foodfightImages/3.png';
import four from './scratchImages/foodfightImages/4.png';
import five from './scratchImages/foodfightImages/5.png';
import six from './scratchImages/foodfightImages/6.png';
import seven from './scratchImages/foodfightImages/7.png';
import eight from './scratchImages/foodfightImages/8.png';
import nine from './scratchImages/foodfightImages/9.png';
import ten from './scratchImages/foodfightImages/10.png';
import eleven from './scratchImages/foodfightImages/11.png';
import twelve from './scratchImages/foodfightImages/12.png';
import thirteen from './scratchImages/foodfightImages/13.png';
import fourteen from './scratchImages/foodfightImages/14.png';
import fifteen from './scratchImages/foodfightImages/15.png';
import sixteen from './scratchImages/foodfightImages/16.png';
import seventeen from './scratchImages/foodfightImages/17.png';
import eighteen from './scratchImages/foodfightImages/18.png';
import nineteen from './scratchImages/foodfightImages/19.png';
import twenty from './scratchImages/foodfightImages/20.png';
import twentyOne from './scratchImages/foodfightImages/21.png';
import twentyTwo from './scratchImages/foodfightImages/22.png';
import twentyThree from './scratchImages/foodfightImages/23.png';
import twentyFour from './scratchImages/foodfightImages/24.png';
import twentyFive from './scratchImages/foodfightImages/25.png';
import twentySix from './scratchImages/foodfightImages/26.png';
import twentySeven from './scratchImages/foodfightImages/27.png';
import twentyEight from './scratchImages/foodfightImages/28.png';
import twentyNine from './scratchImages/foodfightImages/29.png';
import thirty from './scratchImages/foodfightImages/30.png';
import thirtyOne from './scratchImages/foodfightImages/31.png';
import thirtyTwo from './scratchImages/foodfightImages/32.png';
import thirtyThree from './scratchImages/foodfightImages/33.png';
import thirtyFour from './scratchImages/foodfightImages/34.png';
import thirtyFive from './scratchImages/foodfightImages/35.png';
import thirtySix from './scratchImages/foodfightImages/36.png';
import thirtySeven from './scratchImages/foodfightImages/37.png';
import thirtyEight from './scratchImages/foodfightImages/38.png';
import thirtyNine from './scratchImages/foodfightImages/39.png';
import forty from './scratchImages/foodfightImages/40.png';
import fortyOne from './scratchImages/foodfightImages/41.png';
import fortyTwo from './scratchImages/foodfightImages/42.png';
import fortyThree from './scratchImages/foodfightImages/43.png';
import fortyFour from './scratchImages/foodfightImages/44.png';
import fortyFive from './scratchImages/foodfightImages/45.png';
import fortySix from './scratchImages/foodfightImages/46.png';
import fortySeven from './scratchImages/foodfightImages/47.png';
import fortyEight from './scratchImages/foodfightImages/48.png';
import fortyNine from './scratchImages/foodfightImages/49.png';
import fifty from './scratchImages/foodfightImages/50.png';
import fiftyOne from './scratchImages/foodfightImages/51.png';
import fiftyTwo from './scratchImages/foodfightImages/52.png';
import fiftyThree from './scratchImages/foodfightImages/53.png';
import fiftyFour from './scratchImages/foodfightImages/54.png';
import fiftyFive from './scratchImages/foodfightImages/55.png';
import fiftySix from './scratchImages/foodfightImages/56.png';
import fiftySeven from './scratchImages/foodfightImages/57.png';
import fiftyEight from './scratchImages/foodfightImages/58.png';
import fiftyNine from './scratchImages/foodfightImages/59.png';
import sixty from './scratchImages/foodfightImages/60.png';
import sixtyOne from './scratchImages/foodfightImages/61.png';
import sixtyTwo from './scratchImages/foodfightImages/62.png';
import sixtyThree from './scratchImages/foodfightImages/63.png';
import sixtyFour from './scratchImages/foodfightImages/64.png';
import sixtyFive from './scratchImages/foodfightImages/65.png';
import sixtySix from './scratchImages/foodfightImages/66.png';
import sixtySeven from './scratchImages/foodfightImages/67.png';
import sixtyEight from './scratchImages/foodfightImages/68.png';
import sixtyNine from './scratchImages/foodfightImages/69.png';
import seventy from './scratchImages/foodfightImages/70.png';
import seventyOne from './scratchImages/foodfightImages/71.png';
import seventyTwo from './scratchImages/foodfightImages/72.png';
import seventyThree from './scratchImages/foodfightImages/73.png';
import seventyFour from './scratchImages/foodfightImages/74.png';
import seventyFive from './scratchImages/foodfightImages/75.png';
import seventySix from './scratchImages/foodfightImages/76.png';
import seventySeven from './scratchImages/foodfightImages/77.png';
import seventyEight from './scratchImages/foodfightImages/78.png';
import seventyNine from './scratchImages/foodfightImages/79.png';
import eighty from './scratchImages/foodfightImages/80.png';
import eightyOne from './scratchImages/foodfightImages/81.png';
import eightyTwo from './scratchImages/foodfightImages/82.png';
import eightyThree from './scratchImages/foodfightImages/83.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import "./tutorial.css";

const FoodFight = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 83;

  return (
    <div className='scratchGames__container'>
        <h1>
        🍔🥧 "Feast Frenzy: The Ultimate Food Fight Showdown!" 🍕🍩        
        </h1>
        <h3 className='intro'>
        Dive into the delicious chaos of "Feast Frenzy: The Ultimate Food Fight Showdown!" 🍔🥧 Where meals turn into melee and culinary skills meet combat thrills! Wielding food-themed weapons ranging from pizza-slice shurikens to apple grenades. 🍕💥        
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>



        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎉 Step 1 🎉</h2>
          <ul>
            <li>
              To start this project navigate to the Scratch website.
            </li>

            <li>
              Scratch: <a target="_blank" href="https://scratch.mit.edu/" >https://scratch.mit.edu/</a>
            </li>

            <li>
              Select the "create" tab at the top to open a new project.
            </li>
          </ul>

          <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌟 Step 2 🌟</h2>
          <ul>
            <li>
            Welcome to this food fight experience, usually we delete our Scratch cat friend... but in this project, he is the main character. Ready to fight with food! Just go ahead and add an "orange" into the game.
            </li>
          </ul>

          <img src={two} className='demo__image' alt='img_two' />
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚀 Step 3 🚀</h2>
          <ul>
            <li>
              Get coding this cat! As the main character in our game's story line; the cat will move through the game as the player shoots healthy foods at the evil junk food.
            </li>

            <li>
              To get the Cat to constantly be moving. Start the game with a "when flag clicked" block followed by a "forever" block. In the forever block add a "move 1 steps" to move the cat "forever".
            </li>
          </ul>

          <img src={three} className='demo__image' alt='img_three' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎈 Step 4 🎈</h2>
          <ul>
            <li>
              The game needs a speed at which we fight the off the junk food with our healthy array of fruit-tastic artillery.
            </li>

            <li>
              Click the "make a variable" button to create a variable for the game's speed.
            </li>
          </ul>

          <img src={four} className='demo__image' alt='img_four' />
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🔥 Step 5 🔥</h2>
          <ul>
            <li>
              Name this new variable "Game Speed" a simple and straightforward name.
            </li>
          </ul>

          <img src={five} className='demo__image' alt='img_five' />
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>💡 Step 6 💡</h2>
          <ul>
            <li>
              Next make a variable named "score" to keep track of the player's score.
            </li>
          </ul>

          <img src={six} className='demo__image' alt='img_six' />
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍀 Step 7 🍀</h2>
          <ul>
            <li>
              Make a variable called "Lives" to keep track of how many lives that the player has.
            </li>

            <li>
              Now you should have a total of three variables: Game Speed, Score, and Lives.
            </li>
          </ul>

          <img src={seven} className='demo__image' alt='img_seven' />
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌈 Step 8 🌈</h2>
          <ul>
            <li>
              Get a new "when flag clicked" block to start a new code sequence for the cat that will do a different thing other other move the cat, like our original sequence.
            </li>

            <li>
              Follow the "flag clicked" block with a "set Game Speed to 1" block this will start the "Game Speed" at the lowest speed, as the game progresses the speed of the game will increase (more junk food enemies will spawn fast).
            </li>
          </ul>

          <img src={eight} className='demo__image' alt='img_eight' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎁 Step 9 🎁</h2>
          <ul>
            <li>
              We need to finish setting up the settings and variables of this game.
            </li>

            <li>
              Set the score to 2 when the player starts and the lives to 3. Look at the image below as a reference.
            </li>
          </ul>

          <img src={nine} className='demo__image' alt='img_nine' />
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎶 Step 10 🎶</h2>
          <ul>
            <li>
              This next block, the purple, one sets the proper size for the cat when we start the game.
            </li>

            <li>
              Add the "set size to 70%" block so that the Cat's size is 70% of the original size when the game starts.
            </li>
          </ul>

          <img src={ten} className='demo__image' alt='img_ten' />
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌺 Step 11 🌺</h2>
          <ul>
            <li>
              The next block will position the Cat at the center of the game screen? How does it do that you ask? Well..... we use the "go to X: 0 Y: 0" block to set the cat's position to 0,0 which is the very center of the screen.
            </li>
          </ul>

          <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍄 Step 12 🍄</h2>
          <ul>
            <li>
              Continue to position the cat with a "point in direction 90" block.
            </li>
          </ul>

          <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍪 Step 13 🍪</h2>
          <ul>
            <li>
              This next bloc, "show", will just ensure that the cat is not invisible when the game starts. Although this should not happen, it is good to put safe guards in place to guarantee proper game play.
            </li>
          </ul>

          <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐾 Step 14 🐾</h2>
          <ul>
            <li>
              Add a "forever" block. This block will contain the code to check if the player is clicking the arrows in order to move the cat.
            </li>
          </ul>

          <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌊 Step 15 🌊</h2>
          <ul>
            <li>
              If you look at the image below, there is an entire sequence that has been added.
            </li>

            <li>
              This "if block" results in checking if the player is pressing the left arrow to move, and if so turns the cat in the left direction.
            </li>

            <li>
              Create the sequence that you see below in your own game.
            </li>
          </ul>

          <img src={fifteen} className='demo__image' alt='img_fifteen' />
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍉 Step 16 🍉</h2>
          <ul>
            <li>
              Put the sequence that you created in the last step into the "forever" block and create a parallel sequence checking for the "right arrow".
            </li>
          </ul>

          <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎭 Step 17 🎭</h2>
          <ul>
            <li>
              With both movement "if blocks" complete, ensure they are both correctly positioned into the "forever" block as seen in the image below.
            </li>
          </ul>

          <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎨 Step 18 🎨</h2>
          <ul>
            <li>
              The block that you see in the image below will gradually increase the game's speed as the player's score increases.
            </li>

            <li>
              Get the "set game speed to" block and set it with the equation that is assembled in the green operator below.
            </li>
          </ul>

          <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚴 Step 19 🚴</h2>
          <ul>
            <li>
              We will now create the third sequence of code for the cat, this one allows the player's to shoot the oranges.
            </li>

            <li>
              Start the sequence with a "when flag clicked" block followed by a "forever" block.
            </li>
          </ul>

          <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍦 Step 20 🍦</h2>
          <ul>
            <li>
              Create an "if statement" block to check if the player is pressing the "space bar" and if so create a clone of the orange. The clone of the orange is the one we will shoot.
            </li>

            <li>
              Start this with an "if block" containing a "key space pressed" block, that when pressed creates a clone of the orange using a "create clone of orange" block.
            </li>
          </ul>

          <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌍 Step 21 🌍</h2>
          <ul>
            <li>
              The next block will prevent the player from pressing the space bar too much so that they can only shoot one at a time.
            </li>

            <li>
              To do this grab a "wait until" block, within the wait until block add a "not key space pressed".
            </li>

            <li>
              This makes it so that the previous space bar press is complete so the player can not just hold the space bar down to rapid fire the oranges.
            </li>
          </ul>

          <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🕺 Step 22 🕺</h2>
          <ul>
            <li>
              Take the if block statement and put it into the "forever" block, thus forever checking if the player is pressing the space bar and reacting if so.
            </li>
          </ul>

          <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎤 Step 23 🎤</h2>
          <ul>
            <li>
              When the player clicks the "space bar" a clone of the orange is created.... but we never code the part where the orange flys through the air at the junk food. Let's do that now!
            </li>

            <li>
              Select the orange to code it and start this code sequence with a "when I start as a clone" block.
            </li>
          </ul>

          <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎬 Step 24 🎬</h2>
          <ul>
            <li>
              When the orange clone is created it should start at the Cat and fly at the junk food. To do this add a "go to cat" block so that the orange starts at the cat before flying at the junk food.
            </li>
          </ul>
          
          <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎮 Step 25 🎮</h2>
          <ul>
            <li>
              Add the next block, "point in direction" - "direction of cat" to properly position the orange.
            </li>
          </ul>
          
          <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>📚 Step 26 📚</h2>
          <ul>
            <li>
              "move 20 steps" will cause the orange to fly through the air and "show" will ensure that the orange is visible, add the two new blocks that you see in the image below.
            </li>
          </ul>

          <img src={twentySix} className='demo__image' alt='img_twentySix' />
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚂 Step 27 🚂</h2>
          <ul>
            <li>
              The next block(s) will repeat the orange flying through the air until it hits the edges of the game screen.
            </li>

            <li>
              To accomplish this, add a "repeat until" block and for the condition add "touching edge". The code that we will repeat until we touch the edge is "move 10 steps".
            </li>
          </ul>

          <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🏖 Step 28 🏖</h2>
          <ul>
            <li>
              Delete the clone with the new block if the orange hits the edge.
            </li>

            <li>
              Add the "delete this clone" block.
            </li>
          </ul>
          
          <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎡 Step 29 🎡</h2>
          <ul>
            <li>
              Make the original orange invisible by changing the visibility setting at the bottom, only the clones should be visible when they are shot, rather than the original orange on the screen.
            </li>
          </ul>

          <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍕 Step 30 🍕</h2>
          <ul>
            <li>
              Our foe has arrived, the giant donut of doom!!!
            </li>

            <li>
              Add the donut into the game, this will be the enemy that we will fight off with oranges.
            </li>
          </ul>
          
          <img src={thirty} className='demo__image' alt='img_thirty' />
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌜 Step 31 🌜</h2>
          <ul>
            <li>
              The default size of the donut is far to big, make it smaller by changing the size to "40".
            </li>
          </ul>

          <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>⛱ Step 32 ⛱</h2>
          <ul>
            <li>
              To code the donut, add a "when flag clicked" block for the start and set the rotational movement to "left-right". Then add a hide block so that only the clones that are later created are displayed. Follow the example below.
            </li>
          </ul>

          <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍩 Step 33 🍩</h2>
          <ul>
            <li>
              To start the process of spawning a donut enemy we need a "forever" block to loop through this protocol.
            </li>

            <li>
              Within the forever loop add a "wait pick random 2 to 4 seconds" block. This way the code will wait 2-4 seconds before moving to the next block (which will clone the donut).
            </li>
          </ul>

          <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐝 Step 34 🐝</h2>
          <ul>
            <li>
              Now go ahead and clone the donut with a "create clone of myself" block.
            </li>
          </ul>

          <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎢 Step 35 🎢</h2>
          <ul>
            <li>
              What happens when the clone is created? We are about to create that. To start the clone's code add a "when I start as a clone" block.
            </li>
          </ul>

          <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍿 Step 36 🍿</h2>
          <ul>
            <li>
              The cloned donut needs to travel to the center of the game screen. 
            </li>

            <li>
              Start this process with a "go to X: 0 Y: 0" block.
            </li>
          </ul>

          <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>⭐ Step 37 ⭐</h2>
          <ul>
            <li>
              The direction that the donut should point as it moves should be random. To make this random movement, add a "point in direction" block with a "pick random -180 to 180" operator.
            </li>
          </ul>

          <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎠 Step 38 🎠</h2>
          <ul>
            <li>
              Now to get the donut to travel forward, add a "move 300 steps" block.
            </li>

            <li>
              The donut clone should be visible with a "show" block.
            </li>

            <li>
              It should also point towards the cat with a "point towards cat" block.
            </li>
          </ul>

          <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🦋 Step 39 🦋</h2>
          <ul>
            <li>
              We should have the donut clone repeat the movement of moving until it either hits the cat or is shot down with an orange. Start this process by adding the "repeat until touching cat" block.
            </li>
          </ul>

          <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌲 Step 40 🌲</h2>
          <ul>
            <li>
              The "move game speed block" that you see down below will cause the donut to move at the speed of the game, the longer that the game is played the faster it gets!
            </li>
          </ul>

          <img src={forty} className='demo__image' alt='img_forty' />
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌸 Step 41 🌸</h2>
          <ul>
            <li>
              Well what happens when that flying orange of fury smashes that donut to bits?
            </li>

            <li>
              The donut should get blasted out of this realm.
            </li>

            <li>
              To start this process we first need to check if the donut is touching the orange, do this with a "if touching orange" block.
            </li>

            <li>
              The "if block" should contain three blocks that; one, play the "chomp" sound, increase the score by 1, and then delete the donut.
            </li>

            <li>
              Use the image below as an example of the "if statement" block that you need to create.
            </li>
          </ul>

          <img src={fortyOne} className='demo__image' alt='img_fortyOne' />
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🥤 Step 42 🥤</h2>
          <ul>
            <li>
              Take the "if statement" sequence that you created in the last step and put it in the "repeat block".
            </li>
          </ul>
          
          <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>⛵ Step 43 ⛵</h2>
          <ul>
            <li>
              Now if the donut flys through the air without ever getting hit by and orange and it touches the cat, we will add the next two blocks "broad cast lose a life" and "delete this clone" in order that the player loses a life for getting hit and the donut disappears.
            </li>
          </ul>

          <img src={fortyThree} className='demo__image' alt='img_fortyThree' />
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚁 Step 44 🚁</h2>
          <ul>
            <li>
              Whenever the cat (player) loses the life, the donut needs to get deleted in order to reset the game for the player's next life.
            </li>

            <li>
              Add a "when I receive lose a life" block to the donut followed by a "delete this clone" block.
            </li>
          </ul>

          <img src={fortyFour} className='demo__image' alt='img_fortyFour' />
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌠 Step 45 🌠</h2>
          <ul>
            <li>
              We need to determine what happens to the cat when it loses a life, navigate back to the code of the cat by clicking it.
            </li>

            <li>
              Add a "when I receive lose a life" block to the cat.
            </li>
          </ul>

          <img src={fortyFive} className='demo__image' alt='img_fortyFive' />
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍜 Step 46 🍜</h2>
          <ul>
            <li>
              When the cat does lose a life three things need to happen; first, the hide block to reset the cat's appearance. Second, the "meow" sound for a fun lose effect. Third, the "change lives by 1" block for the player to lose a life.
            </li>

            <li>
              Use the image below to guide you in creating the next sequence.
            </li>
          </ul>
          
          <img src={fortySix} className='demo__image' alt='img_fortySix' />
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🏄 Step 47 🏄</h2>
          <ul>
            <li>
              We now need a process to control the flow of the game depending on how many lives the player has.
            </li>

            <li>
              Look at the block below, this flow will have an outcome for if the player has more than zero lives but also a section for what to do if the lives are less than zero.
            </li>

            <li>
              Start by adding the "if .... else" block and check if the lives are greater than zero.
            </li>
          </ul>

          <img src={fortySeven} className='demo__image' alt='img_fortySeven' />
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍓 Step 48 🍓</h2>
          <ul>
            <li>
              If the lives are greater than zero we will wait two seconds before we show the cat, now the cat will respawn after loosing a life.
            </li>
          </ul>

          <img src={fortyEight} className='demo__image' alt='img_fortyEight' />
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍋 Step 49 🍋</h2>
          <ul>
            <li>
              The section labeled as "else" controls the process of what to do in the even the player's lives are equal to or less than zero.
            </li>

            <li>
              In this section add the following blocks in the image to broadcast a game over and stop the game from continuing. 
            </li>
          </ul>

          <img src={fortyNine} className='demo__image' alt='img_fortyNine' />
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎎 Step 50 🎎</h2>
          <ul>
            <li>
              Look back at the "if section" and add a "go to X: 0 Y: 0" block so that the cat is reset at the center of the game's screen when he resets.
            </li>
          </ul>

          <img src={fifty} className='demo__image' alt='img_fifty' />
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎒 Step 51 🎒</h2>
          <ul>
            <li>
              The next section of the game will use strawberries to create special animations.
            </li>

            <li>
              Add the "strawberry" into the game and set the size to "40".
            </li>
          </ul>

          <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🚗 Step 52 🚗</h2>
          <ul>
            <li>
              This sequence will detail a "strawberry explosion" when the player looses a life, making the cat explode with strawberries.
            </li>

            <li>
              To start this add a "when I receive lose a life" block followed by "go to cat" and "show".
            </li>
          </ul>

          <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🌵 Step 53 🌵</h2>
          <ul>
            <li>
              This explosion of strawberries will repeat until it hits the edges of the screen then it will stop. Look at the code sequence below and follow along. This part might be confusing now, but later down the road the pieces will come together nicely!
            </li>
          </ul>

          <img src={fiftyThree} className='demo__image' alt='img_fiftyThree' />
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍔 Step 54 🍔</h2>
          <ul>
            <li>
              The new sequence below creates the explosion animation of the strawberries but hides it until the cat looses a life.
            </li>

            <li>
              Look at the image below and follow the sequence to create a strawberry explosion. 
            </li>
          </ul>

          <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍟 Step 55 🍟</h2>
          <ul>
            <li>
              While the donut is a formidable foe, the cake is an even stronger enemy.
            </li>

            <li>
              Add a "cake" into the game and set the size to "50", this will be the next enemy that we will fight off.
            </li>
          </ul>

          <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍉 Step 56 🍉</h2>
          <ul>
            <li>
              Add the new code to start the movement sequence of the cake. Start with a "when flag clicked block" followed by setting the rotation style and "hide" to hide it until it is cloned.
            </li>
          </ul>

          <img src={fiftySix} className='demo__image' alt='img_fiftySix' />
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎀 Step 57 🎀</h2>
          <ul>
            <li>
              Add a forever block in order that we can constantly spawn cake clones into the game.
            </li>
          </ul>
          
          <img src={fiftySeven} className='demo__image' alt='img_fiftySeven' />
        </div>

        <div style={currentStep == 58 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍍 Step 58 🍍</h2>
          <ul>
            <li>
              To spawn the cake clones into the game we need to wait every 5-10 seconds before spawning then with a "wait" block containing a "pick random 5 to 10".
            </li>

            <li>
              After the random wait interval, create the clone.
            </li>
          </ul>

          <img src={fiftyEight} className='demo__image' alt='img_fiftyEight' />
        </div>

        <div style={currentStep == 59 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🎁 Step 59 🎁</h2>
          <ul>
            <li>
              We must now outline what needs to happen when the clone is created. Add the "when I start as a clone" block. 
            </li>

            <li>
              Connect a "go to X: 0 Y: 0" block so that the cake will move towards the center of the screen where the player is.
            </li>

          </ul>

          <img src={fiftyNine} className='demo__image' alt='img_fiftyNine' />
        </div>

        <div style={currentStep == 60 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍇 Step 60 🍇</h2>
          <ul>
            <li>
              We must also define what direction that the cake is pointing as it moves, this will be a random value.
            </li>

            <li>
              Add the "point in direction" block with a random value between "-180" and "180" to accomplish this.
            </li>
          </ul>

          <img src={sixty} className='demo__image' alt='img_sixty' />
        </div>

        <div style={currentStep == 61 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍨 Step 61 🍨</h2>
          <ul>
            <li>
              Let's get this cake moving towards the cat, add the next three block to move at the cat while displaying the cat and pointing it at the cat.
            </li>
          </ul>

          <img src={sixtyOne} className='demo__image' alt='img_sixtyOne' />
        </div>

        <div style={currentStep == 62 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍑 Step 62 🍑</h2>
          <ul>
            <li>
              We must add a "repeat block" until the cake touches the cat to keep the cake moving.
            </li>

            <li>
              Add the "repeat until" block with a "touching cat" condition in the empty space of the repeat block.
            </li>
          </ul>

          <img src={sixtyTwo} className='demo__image' alt='img_sixtyTwo' />
        </div>

        <div style={currentStep == 63 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🍰 Step 63 🍰</h2>
          <ul>
            <li>
              To move the cake at the cat, we need to move it at the current rate of the game using the "game speed" variable.
            </li>

            <li>
              Add the "move steps" block. The amount of steps should be the "game speed" multiplied by 2.
            </li>
          </ul>

          <img src={sixtyThree} className='demo__image' alt='img_sixtyThree' />
        </div>

        <div style={currentStep == 64 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐼 Step 64 🐼</h2>
          <ul>
            <li>
              As the cake moves through the air we need to check if it is crushed by the deadly orange.
            </li>

            <li>
              Add an "if block" checking if the cake is touching the orange.
            </li>
          </ul>

          <img src={sixtyFour} className='demo__image' alt='img_sixtyFour' />
        </div>

        <div style={currentStep == 65 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐨 Step 65 🐨</h2>
          <ul>
            <li>
              When the cake collides with the orange, we need to destroy the cake as it has been over powered.
            </li>

            <li>
              Start by playing the birthday sound, increasing the player's score, and deleting the clone (the clone of the cake).
            </li>
          </ul>

          <img src={sixtyFive} className='demo__image' alt='img_sixtyFive' />
        </div>

        <div style={currentStep == 66 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐻 Step 66 🐻</h2>
          <ul>
            <li>
              But what happens if the cake is never defeated with oranges? What if it smashes into the player? 
            </li>

            <li>
              If this happens, then we will broadcast losing a life to the player and delete the cake with the two following blocks: "broadcast lose a life" and "delete this clone".
            </li>
          </ul>

          <img src={sixtySix} className='demo__image' alt='img_sixtySix' />
        </div>

        <div style={currentStep == 67 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐦 Step 67 🐦</h2>
          <ul>
            <li>
              Let's delete the cake when the life is lost.
            </li>

            <li>
              Add a "when I receive lose a life" to the cake's code followed by a "delete this clone" block.
            </li>
          </ul>

          <img src={sixtySeven} className='demo__image' alt='img_sixtySeven' />
        </div>

        <div style={currentStep == 68 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐥 Step 68 🐥</h2>
          <ul>
            <li>
              What do they say about apples? An apple a day keeps the junk food away? Something like that....
            </li>

            <li>
              Either way, we are going to add apples into this game. The apples will give the player a life when they eat it. Making them healthier, just like real life!
            </li>

            <li>
            Add a "apple" sprite into the game and set the size to "50".
            </li>
          </ul>

          <img src={sixtyEight} className='demo__image' alt='img_sixtyEight' />
        </div>

        <div style={currentStep == 69 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐤 Step 69 🐤</h2>
          <ul>
            <li>
            The first sequence of code for the apple will create the clones to fly at the player every 20 to 35 seconds (making the apples a rare occurrence).
            </li>

            <li>
              Follow the sequence below for the apple. Pay attention to what is happening: we set the rotation style and forever create a clone of the apple every 20 to 35 seconds!
            </li>
          </ul>

          <img src={sixtyNine} className='demo__image' alt='img_sixtyNine' />
        </div>

        <div style={currentStep == 70 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐣 Step 70 🐣</h2>
          <ul>
            <li>
              But now.... we must say what happens when we are able to create the clone of the apple. Start with a "when I start as a clone" and a "go to X: 0 Y: 0" to set it at the center of the screen.
            </li>
          </ul>

          <img src={seventy} className='demo__image' alt='img_seventy' />
        </div>

        <div style={currentStep == 71 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🐢 Step 71 🐢</h2>
          <ul>
            <li>
              The next block picks the random direction that the apple will face as it flys at the player for them to catch and eat up!
            </li>

            <li>
              Accomplish the pointing feature with a "point in direction" block followed by a "pick random" to randomize the direction of the apple.
            </li>
          </ul>

          <img src={seventyOne} className='demo__image' alt='img_seventyOne' />
        </div>

        <div style={currentStep == 72 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🦄 Step 72 🦄</h2>
          <ul>
            <li>
              To get the apple moving add a "move 300 steps" block followed by a "show" to ensure that it is visible.
            </li>
          </ul>

          <img src={seventyTwo} className='demo__image' alt='img_seventyTwo' />
        </div>

        <div style={currentStep == 73 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🦚 Step 73 🦚</h2>
          <ul>
            <li>
              Under the "show" block connect a "point towards cat" block to point the apple at the cat as it moves.
            </li>
          </ul>

          <img src={seventyThree} className='demo__image' alt='img_seventyThree' />
        </div>

        <div style={currentStep == 74 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🦜 Step 74 🦜</h2>
          <ul>
            <li>
              We now need to repeat this movement of the apple until it either touches the cat or hits the wall. Add a "repeat until touching cat" block in order that we can continue the flight path of the apple.
            </li>
          </ul>

          <img src={seventyFour} className='demo__image' alt='img_seventyFour' />
        </div>

        <div style={currentStep == 75 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🦢 Step 75 🦢</h2>
          <ul>
            <li>
              We will continue the moving the apple based on the current speed of the game. To do this, add a "move steps" blocked filled with the "Game Speed" variable. As the game speed increases so will the speed of the apple's movement.
            </li>
          </ul>

          <img src={seventyFive} className='demo__image' alt='img_seventyFive' />
        </div>

        <div style={currentStep == 76 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🦉 Step 76 🦉</h2>
          <ul>
            <li>
              And now we will check if the apple is touching the orange with an "if block", fill the if block with the code below to play and audio as the apple is eaten and delete it from the game screen.
            </li>
          </ul>

          <img src={seventySix} className='demo__image' alt='img_seventySix' />
        </div>

        <div style={currentStep == 77 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🦎 Step 77 🦎</h2>
          <ul>
            <li>
              Next we create the code to increase the player's life if they are able to catch the apple and delete it when it is caught.
            </li>

            <li>
              Follow the example below to create this effect.
            </li>
          </ul>

          <img src={seventySeven} className='demo__image' alt='img_seventySeven' />
        </div>

        <div style={currentStep == 78 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🦔 Step 78 🦔</h2>
          <ul>
            <li>
            If the cat looses a life as the apple is on the screen, then we will need to delete the apple as the game's screen resets.
            </li>

            <li>
              That being said, add the following two blocks to the apple: "when I receive lose a life" and "delete this clone".
            </li>
          </ul>

          <img src={seventyEight} className='demo__image' alt='img_seventyEight' />
        </div>

        <div style={currentStep == 79 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🦕 Step 79 🦕</h2>
          <ul>
            <li>
              We need to create a "game over" message for when the player runs out of their three lives.
            </li>

            <li>
              Create your own custom sprite and generate a message board such as the one in the image below.
            </li>

            <li>
              When you are done designing your message board, go back to the code as we will resume there.
            </li>
          </ul>

          <img src={seventyNine} className='demo__image' alt='img_seventyNine' />
        </div>

        <div style={currentStep == 80 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🦖 Step 80 🦖</h2>
          <ul>
            <li>
              When the game starts we need to hide this message board, as it should only be shown when the game is actually over. So, add a "when flag clicked" followed by a "hide" block to make it invisible. Then add a "go to X: 0 Y: 0" block in order that it may be positioned at the center of the screen.
            </li>
          </ul>

          <img src={eighty} className='demo__image' alt='img_eighty' />
        </div>

        <div style={currentStep == 81 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🦗 Step 81 🦗</h2>
          <ul>
            <li>
              The next sequence of code will display this message board when the game is over.
            </li>

            <li>
              Examine the code below and start with a "when I receive GameOver" block and the corresponding blocks to display the message.
            </li>
          </ul>

          <img src={eightyOne} className='demo__image' alt='img_eightyOne' />
        </div>

        <div style={currentStep == 82 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🦘 Step 82 🦘</h2>
          <ul>
            <li>
              When fun is a game without a game background?
            </li>

            <li>
              Choose the most thrilling background for this food fight frenzy, what would be the most fun for a food fight?
            </li>
          </ul>

          <img src={eightyTwo} className='demo__image' alt='img_eightyTwo' />
        </div>

        <div style={currentStep == 83 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>🦙 Step 83 🦙</h2>
          <ul>
            <li>
              Last but definitely not least never forget to name this game and save it so that you do not loose your precious progress and other gamers can embark on your experience!
            </li>
          </ul>
          <img src={eightyThree} className='demo__image' alt='img_eightyThree' />
        </div>


        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default FoodFight