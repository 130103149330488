import React, {useState} from 'react';
import one from './fullstackImages/sunnySideForecast/1.png';
import two from './fullstackImages/sunnySideForecast/2.png';
import three from './fullstackImages/sunnySideForecast/3.png';
import four from './fullstackImages/sunnySideForecast/4.png';
import five from './fullstackImages/sunnySideForecast/5.png';
import six from './fullstackImages/sunnySideForecast/6.png';
import seven from './fullstackImages/sunnySideForecast/7.png';
import eight from './fullstackImages/sunnySideForecast/8.png';
import nine from './fullstackImages/sunnySideForecast/9.png';
import ten from './fullstackImages/sunnySideForecast/10.png';
import eleven from './fullstackImages/sunnySideForecast/11.png';
import twelve from './fullstackImages/sunnySideForecast/12.png';
import thirteen from './fullstackImages/sunnySideForecast/13.png';
import fourteen from './fullstackImages/sunnySideForecast/14.png';
import fifteen from './fullstackImages/sunnySideForecast/15.png';
import sixteen from './fullstackImages/sunnySideForecast/16.png';
import seventeen from './fullstackImages/sunnySideForecast/17.png';
import eighteen from './fullstackImages/sunnySideForecast/18.png';
import nineteen from './fullstackImages/sunnySideForecast/19.png';
import twenty from './fullstackImages/sunnySideForecast/20.png';
import twentyOne from './fullstackImages/sunnySideForecast/21.png';
import twentyTwo from './fullstackImages/sunnySideForecast/22.png';
import twentyThree from './fullstackImages/sunnySideForecast/23.png';
import twentyFour from './fullstackImages/sunnySideForecast/24.png';
import twentyFive from './fullstackImages/sunnySideForecast/25.png';
import twentySix from './fullstackImages/sunnySideForecast/26.png';
import twentySeven from './fullstackImages/sunnySideForecast/27.png';
import twentyEight from './fullstackImages/sunnySideForecast/28.png';
import twentyNine from './fullstackImages/sunnySideForecast/28.png';
import thirty from './fullstackImages/sunnySideForecast/30.png';
import thirtyOne from './fullstackImages/sunnySideForecast/31.png';
import thirtyTwo from './fullstackImages/sunnySideForecast/32.png';
import thirtyThree from './fullstackImages/sunnySideForecast/33.png';
import thirtyFour from './fullstackImages/sunnySideForecast/34.png';
import thirtyFive from './fullstackImages/sunnySideForecast/35.png';
import thirtySix from './fullstackImages/sunnySideForecast/36.png';
import thirtySeven from './fullstackImages/sunnySideForecast/37.png';
import thirtyEight from './fullstackImages/sunnySideForecast/38.png';
import thirtyNine from './fullstackImages/sunnySideForecast/39.png';
import forty from './fullstackImages/sunnySideForecast/40.png';
import fortyOne from './fullstackImages/sunnySideForecast/41.png';
import fortyTwo from './fullstackImages/sunnySideForecast/42.png';
import fortyThree from './fullstackImages/sunnySideForecast/43.png';
import fortyFour from './fullstackImages/sunnySideForecast/44.png';
import fortyFive from './fullstackImages/sunnySideForecast/45.png';
import fortySix from './fullstackImages/sunnySideForecast/46.png';
import fortySeven from './fullstackImages/sunnySideForecast/47.png';
import fortyEight from './fullstackImages/sunnySideForecast/48.png';
import fortyNine from './fullstackImages/sunnySideForecast/49.png';
import fifty from './fullstackImages/sunnySideForecast/50.png';
import fiftyOne from './fullstackImages/sunnySideForecast/51.png';
import fiftyTwo from './fullstackImages/sunnySideForecast/52.png';
import fiftyThree from './fullstackImages/sunnySideForecast/53.png';
import fiftyFour from './fullstackImages/sunnySideForecast/54.png';
import fiftyFive from './fullstackImages/sunnySideForecast/55.png';
import fiftySix from './fullstackImages/sunnySideForecast/56.png';
import fiftySeven from './fullstackImages/sunnySideForecast/57.png';
import fiftyEight from './fullstackImages/sunnySideForecast/58.png';
import fiftyNine from './fullstackImages/sunnySideForecast/59.png';
import sixty from './fullstackImages/sunnySideForecast/60.png';
import sixtyOne from './fullstackImages/sunnySideForecast/61.png';
import sixtyTwo from './fullstackImages/sunnySideForecast/62.png';
import sixtyThree from './fullstackImages/sunnySideForecast/63.png';
import sixtyFour from './fullstackImages/sunnySideForecast/64.png';
import sixtyFive from './fullstackImages/sunnySideForecast/65.png';
import sixtySix from './fullstackImages/sunnySideForecast/66.png';
import CopyBox from './copyBox/copybox';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const SunnySideForecast = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 66;

  return (
    <div className='development__container'>

        <h1>
        ⚡ Welcome to the "Sunny Side Forecast" Web Development Adventure! ⚡
        </h1>

        <h3 className='intro'>
        🌤️ Step out into the light of web creation with us as we embark on a delightful journey to build the "Sunny Side Forecast" website—a hub for all things weather. Whether you're a budding web developer or a seasoned pro looking to add another project to your portfolio, this experience is designed to enrich your skills and brighten your understanding of web development.        
        </h3>



        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>


        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 1: Setting Up Your Weather Station 🌤️</h2>

          <p>
          Ready to build your own weather station website? Let’s get started! 🚀 In this first step, you see an empty index.html file, which is like the foundation of our house. It's where we'll build everything up!
          </p>
          <img src={one} className='demo__image' alt='img_one' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Open up your code editor, create a new file, and save it as index.html in a project folder you can name SUNNY-SIDE-FORECAST.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Project Organization: Understand how to organize your files neatly. Think of it like keeping your toys in the right boxes!
            </li>
          </ul>
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 2: Adding a Room to Your House 🏠</h2>

          <p>
          We're going to create a special space for our weather images. Just like how pictures make a room look awesome, images make a website look cool!
          </p>
          <img src={two} className='demo__image' alt='img_two' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Inside your SUNNY-SIDE-FORECAST project folder, make another folder and name it images.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Folders and Files: Just like how your room has different drawers for different things, websites use folders to keep files organized.
            </li>
          </ul>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 3: Gathering Your Weather Tools! 🛠️🌤️</h2>

          <p>
          Now, let's gather some cool weather tools - our images!
          </p>
          <img src={three} className='demo__image' alt='img_three' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Save these images as search.png and weather.png. Then, place them gently in the images folder of your SUNNY-SIDE-FORECAST project. This way, they’re ready to be part of your cool weather forecasting site.
            </li>

            <li>
              Use the buttons below to download them.
            </li>
          </ol>

          {/*<div className='download__buttons__container'>
            <Download src={search} filename="search.png" />

            <Download src={weather} filename="weather.png" />
          </div>*/}

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Downloading and Saving Images: Discover how to get images from the internet and keep them in your project, just like collecting gems for a treasure chest.
            </li>
          </ul>
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 4: Laying the Groundwork for Your Digital World 🌐</h2>

          <p>
          Fantastic work, explorers of the digital universe! 🌌 You've found your space, now let's start building it up. In this step, you'll set the stage for all the amazing things you'll add to your website.
          </p>
          <img src={four} className='demo__image' alt='img_four' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Type in the basic HTML skeleton. Remember to start with &lt;!DOCTYPE html&gt; at the top. This is like saying "Hello!" in computer language, telling the browser that you're speaking in HTML.
            </li>

            <li>
            Next, add your &lt;html lang="en"&gt; tag. This tells the browser, "I'm going to speak in English." Just like saying "Hola!" if you're about to speak Spanish!
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            DOCTYPE: Learn why we need to declare the type of document we're creating. It's like choosing the right type of paper for your drawing!
            </li>

            <li>
            Language Attribute: Discover how setting a language helps your website know how to talk and behave. Imagine if your video game knew you were playing in English and gave you secret tips!
            </li>
          </ul>
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 5: Brain and Heart of Your Website! 🧠❤️</h2>

          <p>
          You're doing such an amazing job! Now, let's dive into the brain and heart of your website – the &lt;head&gt;!
          </p>
          <img src={five} className='demo__image' alt='img_five' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Look inside your index.html. Create that new tag &lt;head&gt;. That's where your website starts to think. It's like putting on your thinking cap! 🎩
            </li>

            <li>
            Inside the &lt;head&gt;, you can later add a title, link to styles, and other smart stuff. But for now, let's keep it empty, like a clean chalkboard waiting for brilliant ideas.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            The &lt;head&gt; Tag: This is where all the behind-the-scenes work happens. It's like the control room for a spaceship, but for your website!
            </li>

            <li>
            Planning Ahead: Just like how you plan your outfit for the next day, we're planning where all the cool stuff will go on our website.
            </li>
          </ul>
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 6: Your Website's Smart Specs! 👓</h2>

          <p>
          You've come to a critical point: giving your website its own identity and making it smart about different screens!
          </p>
          <img src={six} className='demo__image' alt='img_six' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Crack open that index.html once again, we're heading back into the control room—the &lt;head&gt; section!
            </li>

            <li>
            Add a &lt;meta charset="UTF-8"&gt; tag. This is like teaching your website the alphabet, ensuring it understands all the different characters you'll use.
            </li>

            <li>
            Next, pop in a &lt;meta name="viewport" content="width=device-width, initial-scale=1.0"&gt; tag. This clever line helps your site look fabulous on any device, from a giant desktop to a tiny phone!
            </li>

            <li>
            Lastly, give your site a name by adding a &lt;title&gt;SunnySideForecast&lt;/title&gt; tag. 
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Character Set: Understand why websites need to know about UTF-8, the standard language for almost all characters and emojis! 🤓
            </li>

            <li>
            Title Tag: Gives your site a name that shows up in the browser tab, like a label on your lunchbox.
            </li>
          </ul>
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 7: Tailoring Your Site's Look with CSS 🎨</h2>

          <p>
          Artists and fashion designers of the coding world, it's time to get stylish! We'll create a wardrobe for your website—its very own CSS file!
          </p>
          <img src={seven} className='demo__image' alt='img_seven' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            In your project folder, create a new file and call it style.css. 
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            CSS Files: CSS files are the fashion designers for your website, deciding everything from font sizes to colors.
            </li>
          </ul>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 8: Connecting Style to Substance 🔗</h2>

          <p>
          Here we go, creative coders! It's time to connect your stylish style.css file to your HTML, so your website can start to show off its fashion sense!
          </p>
          <img src={eight} className='demo__image' alt='img_eight' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Head back to the control room of your HTML—yep, the &lt;head&gt; section!
            </li>

            <li>
            Type &lt;link href="./style.css" rel="stylesheet" /&gt;. This is like giving your website a map to find its closet, so it knows what styles to put on!
            </li>

            <li>
            Save your index.html file, and give yourself a high-five—you're making great progress!
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Link Tag: This tag creates a link between your HTML file and your CSS file. It's like telling your phone where to find the music when you want to play a song.
            </li>

            <li>
            Attributes: href stands for "hypertext reference" and points to the location of the CSS file. The rel attribute stands for "relationship" and tells the browser that the linked file is a stylesheet.
            </li>

            <li>
            File Paths: The ./ before style.css means "start looking in the current directory." It's like saying "start from here" when you're giving directions.
            </li>
          </ul>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 9: Ready, Set, Build! 🏗️</h2>

          <p>
          All set for construction! With your blueprint in place, it's time to build the body of your website. This is where all your content will live.
          </p>
          <img src={nine} className='demo__image' alt='img_nine' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Take a leap into the &lt;body&gt; of your HTML. This is your canvas, where you'll paint with content like text, images, and buttons.
            </li>

            <li>
            For now, let's keep it empty. Think of it as preparing your art supplies before you start creating your masterpiece.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Body Tag: The &lt;body&gt; tag defines the document's body, which contains all the contents of an HTML document, like text, images, links, tables, lists, and more. This is where all the visible parts of your HTML document are placed.
            </li>

            <li>
            Content Structure: Just as a house needs a solid foundation before you add the furniture, your &lt;body&gt; needs to be structured properly before you start filling it with all your awesome content.
            </li>
          </ul>
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 10: Building the Framework of Your Page 🏗️📐</h2>

          <p>
          Alright, young architects, it's time to build the frame for our weather forecast display!
          </p>
          <img src={ten} className='demo__image' alt='img_ten' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Within the &lt;body&gt; of your HTML document, we're going to construct a big box where all our weather forecasting will happen. Type in &lt;div class="page__container"&gt;&lt;/div&gt;.
            </li>

            <li>
            Think of this div as a big piece of land where you will build everything else. The class name is like giving your land a street address, so later on, you can target it in the CSS file to apply styles!
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Div Elements: The &lt;div&gt; element is like a LEGO brick in the web world. It's a general-purpose container for other elements, allowing you to group things together.
            </li>

            <li>
            Classes: A class in HTML is an attribute you use to identify multiple elements that share the same style or behavior.
            </li>

            <li>
            Container Concept: By creating a div with a class of page__container, you're establishing a designated area in your HTML that you can style collectively through CSS. It's similar to defining a specific area in a garden where you'll plant only flowers of a certain color.
            </li>
          </ul>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 11: Dividing Your Space 🌟🏡</h2>

          <p>
          Now, let's split our land into two areas: one for general weather information and another for detailed forecasts.
          </p>
          <img src={eleven} className='demo__image' alt='img_eleven' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Inside your page__container div, create two new divs: one with class="left__container" and another with class="right__container".
            </li>

            <li>
            These two div elements will help us organize our content. The left one will have things like the weather icon and temperature, while the right one will hold more detailed information like wind speed and humidity.
            </li>

            <li>
            Remember, we're not putting any content inside just yet, we're just setting up the rooms in our digital house!
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Layout with Divs: Using div elements helps us create a layout on our web page. It's like deciding where the rooms in a house will go before you start decorating them.
            </li>

            <li>
            Multiple Classes: You can have many different classes in your HTML document, and each one can be styled uniquely in your CSS. This is similar to having different sections in a school, like the library and the cafeteria, each with its own look and purpose.
            </li>
          </ul>

          <p>
          You're on your way to becoming a master builder of the web. Keep stacking those digital bricks! 🧱🎉
          </p>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 12: Creating the Search Bar 🕵️🔍</h2>

          <p>
          Let's craft a special tool for our visitors to search for their local weather!
          </p>
          <img src={twelve} className='demo__image' alt='img_twelve' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Within the left__container of your HTML, it's time to add a feature. Start by creating a new div with class="searchbar".
            </li>

            <li>
            Inside this div, we'll need some text and an image to make it clear it's a search area. So, type in a &lt;p&gt; tag with the words "Search Location" — this is like a signpost telling people what to do.
            </li>

            <li>
            Now, let's add the search icon. Use the &lt;img/&gt; tag and set its src to "./images/search.png". Make sure to include an alt attribute with the description "search", so even if the image doesn't load, people will know what's supposed to be there.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            HTML &lt;div&gt; Element: div stands for division and it's used to group block-level content in HTML. It’s incredibly versatile and mostly used with CSS to style sections of your webpage.
            </li>

            <li>
            Classes in HTML: When you give an element a class, like searchbar, you're creating a hook that you can use in your CSS to apply specific styles just to elements with that class.
            </li>

            <li>
            Image Tag and Source Attribute: The &lt;img/&gt; tag is used to embed an image in an HTML page. The src attribute specifies the path to the image you want to display, and the alt attribute provides alternative information for an image if a user for some reason cannot view it (due to slow connection, an error in the src attribute, or if the user uses a screen reader).
            </li>
          </ul>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 13: Adding the Weather Image 🖼️🌦️</h2>

          <p>
          Let’s add a splash of visual flair with a weather icon.
          </p>
          <img src={thirteen} className='demo__image' alt='img_thirteen' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Right below the search bar div inside the left__container, it’s time to create a home for our weather icon. Type &lt;div class="img__container"&gt;.
            </li>

            <li>
            Inside this new div, use the &lt;img/&gt; tag to insert your weather icon with src="./images/weather.png" and don’t forget to describe the image in the alt attribute with "weather image". This will be the picture that changes with the weather, so make it a good one!
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Image Container: Just as a picture frame holds a photo, the div with the class img__container will hold your weather image. It gives us more control over the image’s positioning and styling on the page.
            </li>

            <li>
            Source Attribute (src): This attribute of the &lt;img/&gt; tag is where you specify the URL (path) of the image you want to display. In this case, it’s telling the browser to find an image named weather.png inside the images folder at the current directory.
            </li>

            <li>
            Alternate Text (alt): The alt attribute provides alternative information for the image if for some reason it cannot be displayed. 
            </li>
          </ul>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 14: Displaying the Temperature 🌡️📈</h2>

          <p>
          Let’s make sure everyone can see how hot or cold it is!
          </p>
          <img src={fourteen} className='demo__image' alt='img_fourteen' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Time to show the temperature! Inside the left__container, right under the img__container, create a new div with the class information.
            </li>

            <li>
            Within this information div, add an &lt;h1&gt; element for the main temperature reading. Type in 31 followed by a &lt;span&gt; to encase the degrees symbol and the C for Celsius. It should look like this: &lt;h1&gt;31&lt;span&gt;°C&lt;/span&gt;&lt;/h1&gt;. This lets us style the number and the units separately if we want to, like making the degree symbol a little smaller to stand out less.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Semantic HTML: The &lt;h1&gt; element is used for the main heading of a section, making it clear that it’s important — in this case, it’s the temperature, which is usually the first thing people want to know.
            </li>

            <li>
            Span for Inline Elements: The &lt;span&gt; tag is used for grouping and applying styles to elements. Think of it as singling out specific code for specific styling.
            </li>
          </ul>
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 15: Adding a Weather Condition Label 🌥️</h2>

          <p>
          Let’s describe the weather with a little more detail next to our temperature!
          </p>
          <img src={fifteen} className='demo__image' alt='img_fifteen' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Right after your h1 tag with the temperature, let’s add a heading for the current weather condition. Use &lt;h2&gt;Mostly Cloudy&lt;/h2&gt; right underneath the temperature.
            </li>

            <li>
            This h2 heading will serve as a subheading, providing more information about the weather in a way that's still visually important but doesn't compete with the temperature reading for attention.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Headings Structure: Headings provide structure to your content, with &lt;h1&gt; being the most important, and &lt;h2&gt;-&lt;h6&gt; as subheadings. They create a hierarchy, just like titles and subtitles in a book, making it easier to understand how the information is organized.
            </li>

            <li>
            Heading Styles: While h1 might be big and bold, h2 is usually a little smaller but still stands out. It’s a good practice to use headings in order to provide a clear structure to your webpage.
            </li>
          </ul>
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 16: Adding Date and Time Details ⏰📅</h2>

          <p>
          Time to add when this weather forecast is predicting! Let’s get the details down.
          </p>
          <img src={sixteen} className='demo__image' alt='img_sixteen' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Just below where you described the weather, let’s provide our forecast’s "when". Start with a line for visual separation; type &lt;div class="line"&gt;&lt;/div&gt;.
            </li>

            <li>
            Now, let’s add two paragraphs: one with &lt;p class="date"&gt;21-July-2023&lt;/p&gt; for the date, and another with &lt;p class="time"&gt;Friday, 1:30PM&lt;/p&gt; for the time. These will tell our users the specific date and time of the weather information being displayed.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Visual Separation in Design: The div with a class of line can be styled to create a horizontal line, which is commonly used in web design to separate different sections of content visually. It's like using a divider in a binder.
            </li>

            <li>
            Class Attribute for Styling Paragraphs: By using class="date" and class="time", you assign a unique class to each paragraph. This means you can style them differently in your CSS. For example, you might want the date to be bold and the time to be italic.
            </li>
          </ul>

          <p>
          Fantastic work! You've just added another layer of useful information to your weather station. Each step you take adds more depth and detail to your website, making it more informative and user-friendly. Keep going!
          </p>
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 17: Identifying the Location 📍</h2>

          <p>
          We can't forget where our weather forecast is for! Let's pin down our location.
          </p>
          <img src={seventeen} className='demo__image' alt='img_seventeen' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Beneath all the other information in the left__container, it's time to place a landmark. Type &lt;h1 class="location"&gt;San Antonio, Texas&lt;/h1&gt; to mark the spot.
            </li>

            <li>
              You can replace "San Antonio, Texas" with any location that you see fit.
            </li>

            <li>
            This h1 tag will prominently display the name of the location for your weather forecast. Think of it as the sign that stands tall on your weather station.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Hierarchy of Headings: In HTML, you can have more than one h1 tag, especially if you're building a large page with separate sections. In this case, we're using a second h1 because the location is a major piece of information, just like the temperature.
            </li>

            <li>
            Class for Custom Styling: The class location will allow you to target this h1 tag in your CSS to apply specific styles that may differ from other h1 elements. For example, you might want this to have a different color or font size to distinguish it as a location marker.
            </li>
          </ul>
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 18: Choosing the Right Font 🖋️</h2>

          <p>
          Before we make our webpage look pretty, we need the perfect font!
          </p>
          <img src={eighteen} className='demo__image' alt='img_eighteen' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            In your style.css file, start by importing the font we’ll use. 
            </li>

            <li>
            This line of code imports a variety of weights and styles of the Roboto font from Google Fonts, giving you a versatile selection to design with.
            </li>

            <li>
              You need to copy the line below and paste it into the top of your CSS file to import this font.
            </li>
          </ol>

          <CopyBox
          text={"@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');"}
          />

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Font Importing: The @import rule in CSS allows you to import styles from other style sheets. In this case, we’re importing a font from Google Fonts, which is an easy way to add quality typography to your webpage without any cost.
            </li>

            <li>
            URL Function: The url function is used to include a file that's located on the web. When you set the href attribute to a URL, it fetches the specified resource, much like adding a hyperlink in your document.
            </li>

            <li>
            Font Families and Variants: We specify family=Roboto:ital,wght@... to load the Roboto font with its various weights and styles (italic and different boldness levels). Having a variety of font weights and styles allows you to emphasize different parts of your content.
            </li>
          </ul>
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 19: Resetting Default Styles 🔄</h2>

          <p>
          Now, let's ensure we have a blank canvas before we start adding our own styles.
          </p>
          <img src={nineteen} className='demo__image' alt='img_nineteen' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            After importing the font, it’s time to reset the default styles that browsers apply. Type * margin: 0; padding: 0; box-sizing: border-box;
            </li>

            <li>
            This CSS rule is a universal selector (*), affecting all elements on the page. 
            </li>

            <li>
            Setting the margin and padding to 0 removes any default spacing that the browser might apply, and box-sizing: border-box; makes element sizing more manageable by including padding and border in the element's total width and height.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            CSS Universal Selector (*): The universal selector is like a net that catches every element on the page. It applies the styles to every single thing, so we use it here to set a consistent starting point.
            </li>

            <li>
            Margin and Padding: These properties control the space around elements. Margin is the space outside an element's border, while padding is the space between an element's content and its border. Resetting these helps avoid unexpected spacing from the browser’s default styles.
            </li>

            <li>
            Box-Sizing: The box-sizing property controls how the total width and height of an element is calculated. By default, it's content-box, which means width and height only apply to the content inside an element, not its padding or border. Changing it to border-box makes designing your layout more intuitive because the padding and border are included in the width/height calculations.
            </li>
          </ul>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 20: Setting the Font for Your Website 📝✨</h2>

          <p>
          Let’s make sure every part of your website texts looks clean and professional with our chosen font.
          </p>
          <img src={twenty} className='demo__image' alt='img_twenty' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Within your universal selector in style.css, which currently affects every element on the page, add font-family: 'Roboto', sans-serif;.
            </li>

            <li>
            This line ensures that unless specified otherwise, all text in your webpage will use the Roboto font or fall back to the default sans-serif font if Roboto isn't available.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            CSS Font-Family: The font-family property in CSS specifies the font for an element. The fonts are listed in order of preference, so if the first isn’t available, it goes to the next one, and so on. Roboto is our primary choice, but if it can't be loaded for any reason, the browser will use a generic sans-serif font as a backup.
            </li>

            <li>
            Sans-serif Fonts: These fonts do not have decorative lines at the end of the strokes, which makes them appear cleaner and more modern. They are often used for digital screens due to their readability.
            </li>
          </ul>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 21: Organizing Your Content with Flexbox 🧩🎛️</h2>

          <p>
          Now let’s make sure everything on your page is neatly laid out using the powers of Flexbox.
          </p>
          <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Add styling for the .page__container by writing .page__container display: flex; flex-direction: row; in your style.css.
            </li>

            <li>
            This will set your page container to a flex container, causing its children (the left and right containers) to be laid out as flexible boxes in a row.
            </li>

            <li>
              Since page container contains the "left__container" and the "right__container", this rule will set the two divs side by side.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Flexbox: Flexbox is a CSS layout mode that makes it easy to design a flexible responsive layout structure without using float or positioning. display: flex; initiates Flexbox on the container.
            </li>

            <li>
            Flex Direction: By default, flex-direction is set to row, which means that the child elements will sit next to each other in a row, from left to right. You can also set this to column to stack them vertically from top to bottom.
            </li>
          </ul>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 22: Setting Your Page to Full View 🖥️📏</h2>

          <p>
          We want our weather station to fill the screen beautifully, from top to bottom, left to right!
          </p>
          <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            In your .page__container class within style.css, add width: 100%; and height: 100vh;. This tells the container to be as wide as the browser window and as tall as 100% of the viewport height.
            </li>

            <li>
            100% width ensures the container stretches across the full width of the screen, while 100vh (viewport height) makes sure it covers the entire height of the viewport.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Viewport Width (vw) and Height (vh): These units are based on the size of the browser window. 100vw would be 100% of the viewport's width, while 100vh is 100% of the viewport's height. They are responsive units, meaning they will adjust with the size of the window.
            </li>
          </ul>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 23: Styling the Left Container 🎨👈</h2>

          <p>
          Let’s get the left part of our page ready for all the cool weather details we'll display!
          </p>
          <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Inside your style.css, target the .left__container within .page__container by adding .page__container .left__container.
            </li>

            <li>
            For now, let's keep the curly braces empty. We'll fill them with styling rules that will make this part of your page look awesome in the next steps!
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            CSS Class Selector: The .left__container class selector allows you to apply styles to any element with this class within the .page__container. This is particularly useful when you have more than one element with the same class but want to style them differently depending on where they are located.
            </li>
          </ul>
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 24: Laying Out the Left Container 🔲👈</h2>

          <p>
          Let’s lay out the left section of our page neatly and in order.
          </p>
          <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            In your style.css, add styling for the .left__container. Write display: flex; flex-direction: column;
            </li>

            <li>
            This CSS rule will apply Flexbox to the left container and stack its child elements vertically, making sure everything within is neatly organized from top to bottom.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Flexbox Display: Setting display: flex; establishes a flex context for all its direct children, which allows you to easily align and space the child elements.
            </li>

            <li>
            Column Direction: flex-direction: column; arranges the child elements in a vertical direction. It's like stacking blocks on top of each other.
            </li>
          </ul>
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 25: Centering Content in the Left Container 🎯👈</h2>

          <p>
          We want our content to look centered and balanced within the left container.
          </p>
          <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Continue adding to your .left__container styles. Now add align-items: center; right after flex-direction: column;.
            </li>

            <li>
            This line of CSS code will center the child elements of .left__container horizontally, ensuring that items like the search bar, weather image, and information are aligned in the middle of the container.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Align Items: In a Flexbox layout, align-items: center; is used to align items along the cross axis (which is horizontal since our flex-direction is column). It’s a powerful tool to vertically center content in a flexible and responsive way.
            </li>

            <li>
            Responsive Alignment: This property ensures that no matter the screen size or the amount of content, your items will stay centered within their container. This adds to the overall aesthetic of your design and helps maintain consistency across different devices.
            </li>
          </ul>
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 26: Defining the Width and Height 📏📐</h2>

          <p>
          Now, let's give some dimensions to the left container to ensure it takes up just the right amount of space.
          </p>
          <img src={twentySix} className='demo__image' alt='img_twentySix' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Add to your .left__container rules in the style.css file: set width: 30%; and height: 100%;.
            </li>

            <li>
            The width: 30%; rule will make the left container take up 30% of the width of its parent container, the .page__container. height: 100%; ensures it stretches all the way down the height of the page.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Percentage Width: When you use percentages for width, you’re making the element's width relative to the width of its parent element. This is responsive because as the browser window size changes, so does the width of the .left__container.
            </li>

            <li>
            Height: Setting height to 100% makes sure that the .left__container will be as tall as its parent element, filling the vertical space completely.
            </li>
          </ul>
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 27: Adding Background Color and Padding 🎨🛋️</h2>

          <p>
          Let’s add some style and spacing to the left container to make it pop and give its contents some breathing room.
          </p>
          <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Continue styling .left__container. Add background-color: #fff; to give it a white background. Then add padding: 24px; to create some space inside the container around its content.
            </li>

            <li>
            background-color: #fff; paints the background of the left container white, making it stand out against other elements. The padding adds 24 pixels of space inside the edges of the container, so its contents don't touch the sides.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Background Color: In CSS, background-color is used to define the color behind an element's content and padding. The value #fff is the hexadecimal code for white.
            </li>

            <li>
            Padding: Padding is the space between the content of the element and its border. It’s like the cushion on a chair—it makes the content inside the container not too cramped.
            </li>
          </ul>
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 28: Starting the Searchbar Styling 🔍✨</h2>

          <p>
          Time to style the search bar so it can be the star of the show in the left container!
          </p>
          <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Within your style.css, target the search bar by writing .page__container .left__container .searchbar. This CSS rule targets the search bar within the left container of your page.
            </li>

            <li>
            Keep the curly braces empty for now, as this is where we'll later add some specific styles to make the search bar look great.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            CSS Selectors: By combining selectors like .page__container .left__container .searchbar, we're being very specific about what we want to style. This way, we can apply unique styles to elements within different containers.
            </li>
          </ul>
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 29: Laying Out the Searchbar Internals 🔍📐</h2>

          <p>
          Let’s make sure everything in the search bar is aligned just right.
          </p>
          <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Add the following properties to the .searchbar: display: flex; and flex-direction: row;. This turns the search bar into a flex container and arranges its contents in a row.
            </li>

            <li>
            By doing this, any elements inside the search bar will be placed next to each other horizontally.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Flexbox in Action: Setting display: flex; on the search bar lets us utilize the powers of Flexbox, making it easy to space and align the items inside it.
            </li>

            <li>
            Flex Direction: The flex-direction: row; property means that the children of the search bar (like the input field and search button) will be laid out in a horizontal line from left to right.
            </li>
          </ul>
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 30: Perfecting the Searchbar Layout 🔎🎨</h2>

          <p>
          Let's ensure that everything inside our search bar is not only in line but also spaced out nicely!
          </p>
          <img src={thirty} className='demo__image' alt='img_thirty' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            In your style.css, within the .page__container .left__container .searchbar rule, add two more properties: align-items: center; and justify-content: space-between;.
            </li>

            <li>
            align-items: center; vertically centers the content inside the search bar, ensuring that the text and icon are aligned in the middle.
            </li>

            <li>
            justify-content: space-between; spaces out the children elements (like the text and the search icon) evenly inside the search bar, with the first item at the start and the last item at the end.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Align Items: This property aligns flex items along the cross axis (up and down if flex-direction is row), ensuring they're centered in the container if there's extra space.
            </li>

            <li>
            Justify Content: This property defines the alignment along the main axis (left to right if flex-direction is row). space-between puts equal space between items, pushing the first and last items to the edges.
            </li>
          </ul>
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 31: Setting the Searchbar Size 📐🔍</h2>

          <p>
          Let's define the size of the search bar to ensure it has the perfect fit within our left container.
          </p>
          <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            In the style.css, under the .page__container .left__container .searchbar CSS rule, you’re going to specify the width and height for the search bar. Add width: 100%; to make the search bar stretch across the entire width of the left container. Then add height: 60px; to give it a fixed height.
            </li>

            <li>
            The width: 100%; ensures the search bar extends the full width of its container, making it responsive and flexible. The height: 60px; provides a tall enough space for users to easily click or tap into the search field.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Width and Height in CSS: These properties are used to set the width and height of an element. When you set the width to 100%, the element will occupy all the available horizontal space within its parent container. A fixed height in pixels provides a consistent size regardless of the screen or container size.
            </li>

            <li>
            Responsive Design: By setting the width using a percentage, your search bar will adapt to different screen sizes, making it responsive. The fixed height ensures that the search bar remains usable and visually consistent across devices.
            </li>
          </ul>
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 32: Styling the Searchbar 🔍✨</h2>

          <p>
          Now that we've set the size of the search bar, it's time to make it pop with some styling!
          </p>
          <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Go back to the .page__container .left__container .searchbar CSS rule in your style.css file.
            </li>

            <li>
            Time to add some padding: set padding-left: 12px; and padding-right: 12px; to give some space inside the search bar on both sides.
            </li>

            <li>
            For a smoother look, add border-radius: 12px; to round the corners of the search bar.
            </li>

            <li>
            Use margin-bottom: 18px; to push anything below the search bar down a bit, creating a nice separation.
            </li>

            <li>
            Lastly, give it a fresh color with background-color: #d8d8ff;, a light purple that's easy on the eyes and adds a pop of color.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Padding: Padding adds space inside an element, between the content and the border. It's like giving your content a little cushion so it doesn't touch the sides.
            </li>

            <li>
            Border Radius: This property rounds the corners of an element's outer border edge. You can make it as round as a circle or just slightly curved.
            </li>

            <li>
            Margin: Margin is the space outside the border of an element. It pushes other elements away, acting like a bubble of personal space for the element.
            </li>

            <li>
            Background Color: This property sets the background color of an element. Colors can be defined by name, HEX, RGB, RGBA, etc.
            </li>
          </ul>

          <p>
          With these styles, your search bar is not only functional but also has a delightful appearance that invites interaction. It's looking fabulous! 🌟👌
          </p>
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 33: Adjusting the Search Icon 🕵️‍♂️</h2>

          <p>
          Get ready to zoom in on the search icon! You’ll want to make sure the magnifying glass is just the right size—not too big, not too small, but juuuust right! Here’s how:
          </p>
          <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Find the spot where you want to add your new style rule. We’re aiming at the image inside your .searchbar which lives inside the .left__container. The CSS selector will look like this: .page__container .left__container .searchbar img.
            </li>

            <li>
            Set the height to a neat 40px to keep the icon from getting oversized.
            </li>

            <li>
            Add object-fit: contain; to make sure the image keeps its original, lovely shape as it fits into its new home.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Height: This CSS property specifies the height of the element's content area. It's like picking the right-sized clothes for your icon to ensure it looks its best.
            </li>

            <li>
            Object-fit: This CSS gem tells the browser how an &lt;img/&gt; should be resized to fit its container. Contain scales the image to maintain its aspect ratio while fitting within the element's content box.
            </li>
          </ul>
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 34: Crafting the Perfect Frame for Our Image 🖼️✂️</h2>

          <p>
          With our image container, we're framing our weather snapshot like a masterpiece in an art gallery. Let's give it the dimensions it deserves!
          </p>
          <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Find the .page__container .left__container .img__container selector. This is our canvas for the image container.
            </li>

            <li>
            Set width: 100%; to let it breathe across the full width of its parent container. No squishing our artwork!
            </li>

            <li>
            Assign height: 220px; to give it enough space to display the weather image without cramping.
            </li>

            <li>
            Add margin-bottom: 18px; to push away any elements below, ensuring our image stands out.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Width and Height: These properties define the width and height of an element, ensuring our content has the room it needs.
            </li>

            <li>
            Margin: Think of margin as the gallery wall space around our image container; it ensures no other elements crowd our visual display.
            </li>
          </ul>
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 35: Bringing Images to Life 🖼️✨</h2>

          <p>
          Now, let’s ensure our weather images look crisp and clear, no matter their size!
          </p>
          <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Inside your style.css, find the .page__container .left__container .img_container img selector.
            </li>

            <li>
            Set width: 100%; to make sure the image takes the full width of its container.
            </li>

            <li>
            Apply height: 100%; to make the image fully occupy the height of its container.
            </li>

            <li>
            Use object-fit: contain; to ensure that your image maintains its aspect ratio while fitting into the container.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Width and Height: These properties are used to set the width and height of an element. By setting them to 100%, the element will occupy all the available space in its parent container.
            </li>

            <li>
            Object-fit: This CSS property sets how the content of a replaced element, like an img, should be resized to fit its container. The contain value scales the content to maintain its aspect ratio while fitting within the element's content box.
            </li>
          </ul>
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 36: Structuring the Information Section 📄🎨</h2>

          <p>
          Time to structure the information section for a neat presentation of weather details.
          </p>
          <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Go to the .page__container .left__container .information selector in your style.css file.
            </li>

            <li>
            Set display: flex; to initiate a flex container for better alignment of child elements.
            </li>

            <li>
            Choose flex-direction: column; to stack child elements vertically.
            </li>

            <li>
            Center everything with align-items: center; so that the contents align perfectly in the middle.
            </li>

            <li>
            Finally, ensure the container takes the full width by setting width: 100%;
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Flexbox: A flexible box layout model that makes it easier to design flexible and responsive layout structures without floats or positioning.
            </li>

            <li>
            Flex Direction: This property defines the direction of the flex items within the container when the layout is a single line.
            </li>

            <li>
            Align-items: This aligns flex items of the current flex line the same way as justify-content but in the perpendicular direction.
            </li>
          </ul>
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 37: Enlarge and Emphasize the Temperature Display 🔎</h2>

          <p>
          With the structure of our weather display in place, let's turn up the heat on our temperature reading to make it the focal point of our left container!
          </p>
          <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Target the .page__container .left__container .information h1 selector in your style.css.
            </li>

            <li>
            Bump up the font-size to 64px to make the temperature sizeable and readable from a distance.
            </li>

            <li>
            Set the font-weight to 400 for a weight that’s bold enough to stand out but not too heavy. This balances legibility with impact.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Font-size: This property specifies the size of the text. Using pixels gives us precise control over text size.
            </li>

            <li>
            Font-weight: This controls how thick or thin characters in text should be displayed. Lower values are thinner; higher values are thicker.
            </li>
          </ul>
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 38: Subtly Style the Temperature Unit 🌡️</h2>

          <p>
          The degree symbol and unit are important but shouldn't overshadow the temperature value. Let's style them to be informative but unobtrusive.
          </p>
          <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Create the .page__container .left__container .information h1 span selector in your style.css.
            </li>

            <li>
            Set the font-size to 24px, a size that supports the temperature reading without competing with it.
            </li>

            <li>
            Lighten the font-weight to 300, which is lighter than the temperature value, ensuring it doesn't dominate the visual hierarchy.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Font-size & Font-weight: Adjusting these properties for nested elements (like span inside h1) allows for nuanced typographic hierarchy, guiding the viewer's attention with size and weight variations.
            </li>
          </ul>
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 39: Accentuating Headlines with Weight and Style</h2>

          <p>
          In web design, the visual hierarchy is key. It guides the user's eye to the most important information first. Now, let's focus on making your headlines stand out and be the focal point!
          </p>
          <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Navigate to your style.css file and create the .page__container .left__container .information h2 CSS rule.
            </li>

            <li>
            We'll be setting the font-weight property to a lighter 300. This property controls how thick or thin characters in text should be displayed and a lighter weight creates a nice contrast with other elements on the page.
            </li>

            <li>
            No need to adjust the font-size here, as the default size you've set earlier will do the job.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Font Weight: In typography, weight refers to the thickness of the character outlines relative to their height. A lower font-weight makes text appear lighter, which can be used for subtler emphasis compared to bolder weights.
            </li>
          </ul>
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 40: Creating a Visual Break with Lines</h2>

          <p>
          Lines are more than just dividers; they're also a design element that can add structure and rhythm to your page. Let's add a line to visually separate content and make it easier for users to follow.
          </p>
          <img src={forty} className='demo__image' alt='img_forty' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            In your style.css file, find or create the .page__container .left__container .information .line rule.
            </li>

            <li>
            Set the width to 100% to ensure that the line stretches across the full width of the container it's in.
            </li>

            <li>
            Use border-top to create the line. Set it to 1px solid black to have a clean, visible line without making it too prominent.
            </li>

            <li>
            Spacing is important for aesthetic balance. Add margin-top and margin-bottom of 12px to create equal space above and below the line.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Width: This defines the width of an element, and setting it to 100% ensures it will fill the width of its parent container.
            </li>

            <li>
            Border-top: This property specifically sets the top border of an element, allowing you to create lines and underlines.
            </li>

            <li>
            Margin: Top and bottom margins create space above and below an element, providing room to breathe and improving readability.
            </li>
          </ul>
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 41: Fine tuning Your Date Display 📅</h2>

          <p>
          It's all about the details when it comes to design. You've positioned the date, but it needs a bit of breathing room. This step focuses on adjusting the spacing to ensure the date isn't crammed against other elements.
          </p>
          <img src={fortyOne} className='demo__image' alt='img_fortyOne' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            In your CSS file, locate .page__container .left__container .information .date.
            </li>

            <li>
            Set margin-bottom to 8px. This property adds a small gap below the date, preventing it from touching other elements, like a comfortable cushion ensuring elements aren't stacked on top of each other.
            </li>

            <li>
            By tweaking this margin, you're ensuring that the date has its own distinct space, which is vital for a clean layout.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Margin Bottom: This property specifies the space below an element, part of the box model which is a fundamental concept in web design for managing spacing and layout.
            </li>
          </ul>
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 42: Emphasizing the Location 📍</h2>

          <p>
          Your users need to know where the weather forecast applies. That's why the location should stand out, but not overwhelm.
          </p>
          <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Find .page__container .left__container .location in the style sheet.
            </li>

            <li>
            Apply a margin-top of 18px. This pushes the location down slightly from any elements above it, giving it a prominent, isolated position which helps in drawing attention.
            </li>

            <li>
            Adjust the font-weight to 300. A lighter font weight here contrasts nicely with bolder elements, making for an elegant and readable header. It's like the difference between a regular pen and a bold marker – both have their place, and using them thoughtfully can make your content clearer and more appealing.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Margin Top: It's like margin-bottom but works the opposite way. It creates space above an element, giving it room to breathe at the top.
            </li>

            <li>
            Font-Weight: This property controls how thick or thin characters in text should be displayed. Think of it as the 'thickness' of the text. In design, weight is used to attract attention and create a visual hierarchy.
            </li>
          </ul>
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 43: Initiating the Header Section 🛠️👷</h2>

          <p>
          Let's roll up our sleeves and start constructing the area that visitors will first lay eyes on – the header section of the right container. This will be our canvas for adding titles, navigation, or any introductory content.          
          </p>

          <img src={fortyThree} className='demo__image' alt='img_fortyThree' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Navigate to your right__container div in the HTML file.
            </li>

            <li>
            Inside this right__container, introduce a new div and bestow it with the class name header. This label will be crucial when we style it later in CSS, so it's like assigning a role in a play to our star actor – the header.
            </li>

            <li>
            Imagine this div as the crown of your right container.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Division Elements (Divs) 📦: In the world of web design, div tags are the building blocks. They're like the containers you use to organize your desk; each one holds different items but together, they create a tidy workspace.
            </li>

            <li>
            Class Attribute 🏷️: When you assign a class to different tags, you're telling your CSS, "Hey, these guys are together." It's a way to apply the same styles to multiple items without repeating yourself.
            </li>
          </ul>
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 44: Crafting the Header 🛠️🔝</h2>

          <p>
          Time to create a welcoming header for your page that tells users what information they'll find in each section!
          </p>
          <img src={fortyFour} className='demo__image' alt='img_fortyFour' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Inside this header div, insert two &lt;h1&gt; elements.
            </li>

            <li>
            In the first &lt;h1&gt;, type "Today" to represent the current weather conditions.
            </li>

            <li>
            In the second &lt;h1&gt;, type "Tomorrow" to represent the forecast for the next day.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Headings: Headings (&lt;h1&gt; to &lt;h6&gt;) are used to denote different levels of content importance, with &lt;h1&gt; being the highest level.
            </li>
          </ul>

        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 45: Introducing the Card Container 🃏🗃️</h2>

          <p>
          Now let's add a place where detailed weather cards will be displayed for the current and next day!
          </p>
          <img src={fortyFive} className='demo__image' alt='img_fortyFive' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Just below your header &lt;div&gt;, create another &lt;div&gt; element.
            </li>

            <li>
            Assign this new div a class of "card_container". This is where you'll later add individual weather cards.
            </li>

            <li>
            Close off the div.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Layout Preparation: Preemptively structuring your HTML with future content in mind is a best practice for web development. It allows for easier maintenance and updates.
            </li>
          </ul>
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 46: Whipping Up the Wind Card 🌬️🃏</h2>

          <p>
          Let’s put together the first weather detail card! This one will show the wind speed, a cool feature for anyone planning to fly a kite or just curious about the blustery conditions.
          </p>
          <img src={fortySix} className='demo__image' alt='img_fortySix' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Add a new &lt;div&gt; element: Inside your card_container &lt;div&gt;, create a new div with the class "card".
            </li>

            <li>
            Insert Content: Within this card div, you're going to add two things: a &lt;p&gt; tag with the text "Wind", and an &lt;h2&gt; tag with "6 km/h". This tells your users that the wind speed is 6 kilometers per hour.
            </li>

            <li>
            Close the Card: Don't forget to close your card div after the content.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Units of Measurement: Including units like "km/h" is essential for clarity. Users won't have to guess what the number refers to.
            </li>
          </ul>
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 47: Crafting a Full Forecast Deck 🌤️🎴</h2>

          <p>
          It's time to expand your horizon and build a complete deck of weather cards. Each card will show different weather details, giving users a comprehensive view of the day's forecast.
          </p>
          <img src={fortySeven} className='demo__image' alt='img_fortySeven' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Duplicate the Card: Go to your card_container &lt;div&gt; that you created in step 46. Inside it, you'll replicate the &lt;div&gt; with the class "card" a total of nine times to create a deck of weather cards.
            </li>

            <li>
            Customize Each Card: Within each "card" &lt;div&gt;, you'll insert unique weather details. This could include temperature, humidity, precipitation, and more. For example, one card could say "Humidity: 78%" and another could say "Sunset: 8:30 PM".
            </li>

            <li>
            Ensure Variety: Make sure each card contains different information so that, together, they paint a full picture of the weather conditions.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Repetition in HTML: By copying and pasting the card structure, you are reusing HTML elements. It’s a common practice to maintain consistency in design and function.
            </li>

            <li>
            Unique Content: Although the cards share the same structure, the content should be diverse to provide a broad range of information.
            </li>

            <li>
            Organized Information: The "card" format helps keep information tidy and digestible, allowing for quick scanning by users.
            </li>
          </ul>
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 48: Acknowledging the Source 🌐📡</h2>

          <p>
          Every wise weather report comes with a trusty source! It's time to give credit where credit is due by adding a note about the data source.
          </p>
          <img src={fortyEight} className='demo__image' alt='img_fortyEight' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Add a Data Source Note: Beneath the card_container &lt;div&gt; in your HTML file, you're going to add a paragraph (&lt;p&gt;) element that informs users about the source of your weather data.
            </li>

            <li>
            Set the Class: Assign this paragraph element a class of "data" so you can style it later on.
            </li>

            <li>
            Insert the Text: Inside the paragraph tags, type the following text: "All data provided by your local weather station." This gives proper acknowledgment to the source of your information and adds a layer of credibility to your weather forecast.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Source Attribution: It's important to credit the source of the information you present. It's a nod to the original creators and a legal requirement under certain conditions.
            </li>

            <li>
            Class Attribute: Using the class attribute allows you to apply specific styles to elements in your CSS. It's a way to identify elements that belong to a certain group or should have a certain style.
            </li>
          </ul>
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 49: Structuring the Right Container 🌟</h2>

          <p>
          Let's get the right container in shape for all the weather details it's going to hold!
          </p>
          <img src={fortyNine} className='demo__image' alt='img_fortyNine' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Open your style.css file and create the .page_container .right_container CSS rule we're about to energize!
            </li>
          </ol>

        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 50: Cementing Flexbox Powers 🧱✨</h2>

          <p>
          Now it's time to solidify the layout of our right container, which is the cozy home for our weather forecast cards!
          </p>
          <img src={fifty} className='demo__image' alt='img_fifty' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Make sure you're in your style.css file and focus on the .page__container .right__container rule.
            </li>

            <li>
            Apply display: flex; to initiate Flexbox, which will give us a superpower to arrange our child elements beautifully.
            </li>

            <li>
            Set the flex-direction to column to stack our weather cards on top of each other from top to bottom.
            </li>

            <li>
            Use align-items: flex-start; to align our cards to the start of the container, keeping everything tight and to the left.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Flexbox: It's a layout module that gives us a way to lay out, align and distribute space among items in a container, even when their size is unknown.
            </li>

            <li>
            flex-direction: By setting this to column, we're making sure that the flex items align vertically.
            </li>

            <li>
            align-items: With flex-start, all our items will align to the top of the container, creating a uniform look.
            </li>
          </ul>
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 51: Adjusting the Right Container's Proportions 📏✨</h2>

          <p>
          Now we're going to refine our right container's dimensions to ensure it takes up just the right amount of space and accommodates all the neat weather cards we're going to display.
          </p>
          <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Set the width property to 70%. This will allocate 70% of the page's width to our right container, giving it a substantial but not overpowering presence.
            </li>

            <li>
            Define the height as 110%. Why more than 100%, you ask? This ensures that our container can stretch a bit beyond the viewport if needed, giving room for all our content without squishing.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Width and Height: These fundamental properties control the size of boxes in CSS. While width controls how wide an element is, height controls how tall it is.
            </li>
          </ul>
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 52: Cozying Up the Right Container 🛋️</h2>

          <p>
          Let's enhance the comfort and appeal of our right container with some padding and a touch of color! This will create a visually pleasing space for our weather details and add an elegant touch to the overall design.
          </p>
          <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Time to give some breathing room with padding. Set padding to 32px. This provides a nice, even space inside the container, so the content doesn't hug the edges too closely.
            </li>

            <li>
            Now for a splash of color! Apply a background-color of #d1d1d1, which is a light gray that offers a subtle, modern look while keeping things clean and uncluttered.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Padding: Padding creates space within an element, offering a cushion between the content and the border. It's essential for a polished look and readability.
            </li>

            <li>
            Background-color: This property is a decorator's dream, allowing you to fill the background of an element with your color of choice. It can influence the mood and style of your design significantly.
            </li>
          </ul>
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 53: Aligning the Header Elements 📐✨</h2>

          <p>
          Let's get those header elements in a row—literally! We'll be using the power of Flexbox to line up the "Today" and "Tomorrow" headers side by side for a neat and organized look.
          </p>
          <img src={fiftyThree} className='demo__image' alt='img_fiftyThree' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Grab your coding gear and create the .page__container .right__container .header selector in your style.css file.
            </li>

            <li>
            Set the display property to flex which is like telling your elements to get cozy and line up for a group photo.
            </li>

            <li>
            Specify the flex-direction as row to ensure that "Today" and "Tomorrow" sit next to each other like best buddies on a park bench.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Display: Flex: This is a declaration of teamwork, allowing child elements (called flex items) to work together in a flexible layout.
            </li>

            <li>
            Flex-direction: This property defines the direction flex items are placed in the flex container. row means horizontal, from left to right.
            </li>
          </ul>
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 54: Perfect Alignment in the Header 🌟📏</h2>

          <p>
          Time to fine-tune the alignment of our header to make it not only organized but also polished to perfection. We're going to make sure "Today" and "Tomorrow" are not only in line but also spaced just right and vertically centered.
          </p>
          <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Jump back into the .page__container .right__container .header section of your style.css.
            </li>

            <li>
            Add align-items: center; to vertically center our elements in the header, ensuring that the text is aligned perfectly in the middle of the container.
            </li>

            <li>
            Use justify-content: space-between; to place an equal amount of space between "Today" and "Tomorrow", giving them the room they deserve.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Align-items: This property aligns items vertically and accepts several values; center is like a middle ground, ensuring everything is evenly aligned along the cross axis.
            </li>

            <li>
            Justify-content: This deals with the distribution of space between items along the main axis; space-between will place equal spacing between elements.
            </li>
          </ul>
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 55: Defining the Header's Proportions 📐✨</h2>

          <p>
          Now let's bring some definition to the header. By setting the width and margin, we ensure that our header is not only visually appealing but also structured with intent
          </p>
          <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Within the .page__container .right__container .header block of your style.css, it's time to confine the header to a cozy width. Set width: 35%; to give it a snug fit that’s neither too wide nor too cramped.
            </li>

            <li>
            To prevent the header from sticking to the content below, sprinkle in a little breathing room with margin-bottom: 24px;. This space acts as a visual cue that separates the header from the rest of the content, enhancing readability.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Width: This property specifies the width of the content area or box. Using a percentage bases the width on the width of the element's containing block, allowing for flexible design.
            </li>

            <li>
            Margin-Bottom: This property creates extra space below an element. It's like the cushion at the end of a paragraph that signals a pause before moving on to the next section.
            </li>
          </ul>
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 56: Emboldening the Header Title 🎨✍️</h2>

          <p>
          To make a statement, let's embolden our header title.
          </p>
          <img src={fiftySix} className='demo__image' alt='img_fiftySix' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Dive into your style.css and create the .page__container .right__container .header h1 selector.
            </li>

            <li>
            Assign font-weight: 400; to the selector. This gives the text a bold, yet not overpowering presence, striking the perfect balance between prominence and elegance.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Font Weight: This property sets how thick or thin characters in text should be displayed. While 400 is typically the default or 'normal' weight, increasing the number thickens the text, making it bolder.
            </li>
          </ul>
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 57: Laying Out the Forecast Cards 🌤️➡️</h2>

          <p>
          Now, let’s arrange our weather cards in a neat row. 
          </p>
          <img src={fiftySeven} className='demo__image' alt='img_fiftySeven' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Find your way to the .page__container .right__container .card__container selector in your style.css.
            </li>

            <li>
            Set the display property to flex. Flexbox is a one-dimensional layout method for laying out items in rows or columns.
            </li>

            <li>
            Then, assign flex-direction: row; to line up all the child elements (our cards) in a row from left to right.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Flexbox: A powerful layout tool that allows responsive elements within a container to be automatically arranged depending upon screen size.
            </li>

            <li>
            Flex Direction: This property defines in which direction the container wants to stack the flex items.
            </li>
          </ul>
        </div>

        <div style={currentStep == 58 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 58: Flexibly Wrapping the Forecast Cards 🎁🔄</h2>

          <p>
          Let’s make our card layout more responsive! With a twist of CSS, we can ensure the weather cards neatly wrap into the next line on smaller screens, keeping them in order and easy to read.
          </p>
          <img src={fiftyEight} className='demo__image' alt='img_fiftyEight' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            You've already told the cards to sit in a row, but now add flex-wrap: wrap; to let them flow into a new line as needed.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Flex Wrap: This property allows items in a flex container to be wrapped onto multiple lines, from top to bottom when they exceed the width of the screen.
            </li>
          </ul>
        </div>

        <div style={currentStep == 59 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 59: Harmonizing the Card Layout 🃏🎨</h2>

          <p>
          Now, let's ensure our weather cards are not just wrapping but also spaced out evenly to create a harmonious visual experience.
          </p>
          <img src={fiftyNine} className='demo__image' alt='img_fiftyNine' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            After making sure the cards wrap, add justify-content: space-between;. This will space out the cards evenly across the container.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Justify Content: This CSS property aligns the flex items inside their container according to the given value. When set to space-between, it distributes the items evenly, with the first item at the start and the last item at the end of the container.
            </li>
          </ul>
        </div>

        <div style={currentStep == 60 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 60: Maximize the Card Display Area 🎴📏</h2>

          <p>
          Let's make sure our card container takes full advantage of the space available to display weather cards neatly across the entire width of the right container.
          </p>
          <img src={sixty} className='demo__image' alt='img_sixty' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            You're going to ensure that this container spans the full width of its parent by setting width: 100%;. Remember its parent is the "right__container".
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>Width: This fundamental CSS property specifies the width of the element's content area. By setting it to 100%, you're telling the browser to make the element's content area as wide as its container's content area, without overflow.</li>
          </ul>
        </div>

        <div style={currentStep == 61 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 61: Sizing Up the Cards ♦️📐</h2>

          <p>
          To create an appealing layout for our weather dashboard, we're going to give each weather card its own distinct space. This step focuses on defining the size of each card.
          </p>
          <img src={sixtyOne} className='demo__image' alt='img_sixtyOne' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Create the .page__container .right__container .card__container .card rule.
            </li>

            <li>
            Set the width of each card to 30%. This width is relative to their container, allowing three cards to fit in a row comfortably.
            </li>

            <li>
            Define the height of the cards as 190px to ensure that they are all uniformly sized and can comfortably house the content.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Percentage Width: It helps in creating a responsive design, as the cards will adjust their width based on the percentage of the parent container's width.
            </li>
          </ul>
        </div>

        <div style={currentStep == 62 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 62: More Touches to the Cards 🖌️🎨</h2>

          <p>
          Now that our cards have a defined size, let's give them some style! A few adjustments will ensure they look great and stand out.
          </p>
          <img src={sixtyTwo} className='demo__image' alt='img_sixtyTwo' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Find your .page__container .right__container .card__container .card selector in your style.css.
            </li>

            <li>
            To prevent the cards from sticking to each other, set margin-bottom to 24px. This adds a little space below each card.
            </li>

            <li>
            For that sleek, modern look, apply border-radius of 12px to each card, which will round the corners nicely.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Margin: Margin controls the space outside of an element's border. Unlike padding, it affects the space between elements, not the space between an element's content and its border.
            </li>

            <li>
            Border-radius: This property is used to create rounded corners on elements. The higher the value, the more circular the corner will be. It's a great way to soften the look of your elements.
            </li>
          </ul>

          <p>
          With these styles, the cards will not only contain the weather information neatly but also present it in a visually appealing way, thanks to the added whitespace and rounded corners. It's all about the details! 🌟
          </p>
        </div>

        <div style={currentStep == 63 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 63: Cozy Colors and Comfortable Padding 🎨🛋️</h2>

          <p>
          Let's enhance the visual comfort of our cards with some color and padding. 
          </p>
          <img src={sixtyThree} className='demo__image' alt='img_sixtyThree' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Set the background-color to #748CF1, a soothing shade of blue that'll give your cards a calm and professional appearance.
            </li>

            <li>
            Add padding of 24px to create some breathing room inside each card, making the content within more legible and neatly organized.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Background-color: This property sets the color behind the content of your box. A well-chosen color can make a big difference in design, bringing attention to elements or making them more readable.
            </li>
          </ul>
        </div>

        <div style={currentStep == 64 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 64: Perfecting the Typography 🖋️</h2>

          <p>
          Next up, we'll tweak the typography inside our cards for that perfect readability. A well-set type not only conveys information but also enhances the user experience.
          </p>
          <img src={sixtyFour} className='demo__image' alt='img_sixtyFour' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            In your style.css, write the .page__container .right__container .card__container .card p rule, where p stands for paragraph – the text element we're styling.
            </li>

            <li>
            Set the font-size to 28px to make the text large enough to read from a distance, which can be especially handy if your screen is part of a dashboard.
            </li>

            <li>
            Change the color to #fff (white) for a high contrast against our card's background color. This ensures maximum legibility and a crisp, modern look.
            </li>
          </ol>
        </div>

        <div style={currentStep == 65 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 65: Heading Styles That Headline 🎨</h2>

          <p>
          Let's spruce up the headings within our cards to make them stand out as the key pieces of information they are.
          </p>
          <img src={sixtyFive} className='demo__image' alt='img_sixtyFive' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Create the .page__container .right__container .card__container .card h2 selector in your CSS. Here, h2 represents the headings in our cards.
            </li>

            <li>
            Crank up the font-size to 38px to give those headings a bold presence that captures attention.
            </li>

            <li>
            For consistency and visual impact, let's keep the color set to #fff (white), the same as our paragraph text. This uniform color choice ties together the text elements within each card.
            </li>

            <li>
            To give our headings a little room to breathe, set margin-top to 16px. This space separates the heading from elements above it, preventing a cluttered look.
            </li>
          </ol>

          <p>
          With the headings now larger and in charge, they act as clear signposts for the information within each card, guiding the user's eye and making the experience intuitive and user-friendly. Like a headline in a newspaper, they tell you what's important at a glance! 📰✨
          </p>
        </div>

        <div style={currentStep == 66 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 66: Fine-tune the Data Font Weight 🖋️</h2>

          <p>
          We want to ensure that the data text strikes the perfect balance between being bold enough to be easily readable, without overshadowing the more important elements like the headings.
          </p>
          <img src={sixtySix} className='demo__image' alt='img_sixtySix' />
          
          <h3>What to Do:</h3>

          <ol>
            <li>
            Create the .page__container .right__container .data CSS rule. This is where we'll adjust the styling for all elements with the class data, which we've used for the textual data points in our layout.
            </li>

            <li>
            Set font-weight to 500. This medium weight makes the text stand out enough for legibility, but it's not so bold that it competes with our headings.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Font-weight: This property sets the thickness of the text. Weights range from 100 (thin) to 900 (ultra-bold). A weight of 500 is often considered medium or semi-bold.
            </li>
          </ul>

          <p>
          Now our data text has just the right emphasis: noticeable but not overbearing, like the bassline of a song—felt in the background, supporting the melody without taking over. 🎶👌
          </p>
        </div>




        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>

        
    </div>
  )
}

export default SunnySideForecast