import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../interface-images/klc-logo.png';
import { SiScratch } from "react-icons/si";
import { FaPython } from "react-icons/fa";
import { SiLua } from "react-icons/si";
import { TbBrandJavascript } from "react-icons/tb";
import { FaChevronLeft } from 'react-icons/fa';
import { FaArrowLeft } from "react-icons/fa";
import { FaSignOutAlt } from 'react-icons/fa';
import './sidebar.css';

const Sidebar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const checkCookie = async () => {
    const storedToken = localStorage.getItem('authToken');
    if (!storedToken) {
      window.location.href = `https://kidslearncoding.org/login`;
    }
  }

  const logout = async () => {
    localStorage.removeItem('authToken');
    checkCookie()
  }

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <button className={`toggle-btn ${!isOpen ? 'rotate' : ''}`} onClick={toggleSidebar}>
        <FaArrowLeft className="arrow-icon" />
      </button>

      <div className='logo-container'>
        <img src={logo} alt="Logo" />
      </div>

      <div className={`header-container ${isOpen ? 'show' : 'hide'}`}>
        <h2>Languages:</h2>
      </div>

      <div onClick={() => navigate("/scratch")} className={`course-option ${isOpen ? '' : 'centered'}`}>
        <SiScratch className='icon' />
        {isOpen && <p>Scratch</p>}
      </div>

      <div onClick={() => navigate("/python")} className={`course-option ${isOpen ? '' : 'centered'}`}>
        <FaPython className='icon' />
        {isOpen && <p>Python</p>}
      </div>

      <div onClick={() => navigate("/lua")} className={`course-option ${isOpen ? '' : 'centered'}`}>
        <SiLua className='icon' />
        {isOpen && <p>Lua</p>}
      </div>

      <div onClick={() => navigate("/fullstack")} className={`course-option ${isOpen ? '' : 'centered'}`}>
        <TbBrandJavascript className='icon' />
        {isOpen && <p>Fullstack</p>}
      </div>

      
      {/* Logout button aligned at the bottom */}
      <div onClick={() => logout()} className={`logout-option ${isOpen ? '' : 'centered'}`}>
        <FaSignOutAlt className='icon' />
        {isOpen && <p>Logout</p>}
      </div>
    </div>
  );
};

export default Sidebar;
