import React, {useState} from 'react'
import one from './pythonImages/mazeimages/1.png';
import two from './pythonImages/mazeimages/2.png';
import three from './pythonImages/mazeimages/3.png';
import four from './pythonImages/mazeimages/4.png';
import five from './pythonImages/mazeimages/5.png';
import six from './pythonImages/mazeimages/6.png';
import seven from './pythonImages/mazeimages/7.png';
import eight from './pythonImages/mazeimages/8.png';
import nine from './pythonImages/mazeimages/9.png';
import ten from './pythonImages/mazeimages/10.png';
import eleven from './pythonImages/mazeimages/11.png';
import twelve from './pythonImages/mazeimages/12.png';
import thirteen from './pythonImages/mazeimages/13.png';
import fourteen from './pythonImages/mazeimages/14.png';
import fifteen from './pythonImages/mazeimages/15.png';
import sixteen from './pythonImages/mazeimages/16.png';
import seventeen from './pythonImages/mazeimages/17.png';
import eighteen from './pythonImages/mazeimages/18.png';
import nineteen from './pythonImages/mazeimages/19.png';
import twenty from './pythonImages/mazeimages/20.png';
import twentyOne from './pythonImages/mazeimages/21.png';
import twentyTwo from './pythonImages/mazeimages/22.png';
import twentyThree from './pythonImages/mazeimages/23.png';
import twentyFour from './pythonImages/mazeimages/24.png';
import twentyFive from './pythonImages/mazeimages/25.png';
import twentySix from './pythonImages/mazeimages/26.png';
import twentySeven from './pythonImages/mazeimages/27.png';
import twentyEight from './pythonImages/mazeimages/28.png';
import twentyNine from './pythonImages/mazeimages/29.png';
import thirty from './pythonImages/mazeimages/30.png';
import thirtyOne from './pythonImages/mazeimages/31.png';
import thirtyTwo from './pythonImages/mazeimages/32.png';
import thirtyThree from './pythonImages/mazeimages/33.png';
import thirtyFour from './pythonImages/mazeimages/34.png';
import thirtyFive from './pythonImages/mazeimages/35.png';
import thirtySix from './pythonImages/mazeimages/36.png';
import thirtySeven from './pythonImages/mazeimages/37.png';
import thirtyEight from './pythonImages/mazeimages/38.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import './tutorial.css';

const Maze = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 38;

  return (
    <div className='pythonGames__container'>
        <h1>
        🌟Let's explore a Digital Maze🌟
        </h1>
        <h3 className='intro'>
        🚀 Welcome to an exciting tutorial on how to create a simple maze game using Python and Pygame! 🎮 We'll guide you step by step, so let's get started! 🌟
        </h3>

        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>



        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
                Step 1: Readying the Environment🎨🖼️
            </h2>

            <p>
                Before we can start coding this Maze Game Python project, we need to setup proper file structure within Visual Studio Code and download some necessary assets!
            </p>

            <img src={one} className='demo__image' alt='img_one' />

            <ol>
                <li>Create a "Maze Runner" folder to contain your project.</li>

                <li>Open this folder with Visual Studio Code.</li>

                <li>Add a file and name it "maze.py". "maze" being the file name and ".py" letting Visual Studio Code that this is a Python file.</li>

                <li>Add a folder to contain the images that we will download a program for this project.</li>

                <li>Download the images by clicking the button below and storing them in your "images" folder.</li>
            </ol>

            {/*<div className="downloadImageButtonContainer">
                <Download
                    src={background}
                    filename="background.png"
                />

                <Download
                    src={end}
                    filename="end.png"
                />

                <Download
                    src={player}
                    filename="player.png"
                />
            </div>*/}
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 2: Setting the Stage 🎭🖥️
            </h2>

            <p>
            Welcome to the first step of creating our amazing Maze Game using Python and Pygame! Here, we're going to start by laying the groundwork for our coding adventure. 🚀👩‍💻
            </p>

            <img src={two} className='demo__image' alt='img_two' />

            <p>
            What does this code do? 🌟
            </p>

            <ol>
                <li>
                    <strong>import sys</strong>: This line brings in the 'sys' module, which provides access to some variables used or maintained by the Python interpreter and functions that interact strongly with the interpreter. It's like giving our game the ability to talk to Python itself! 🐍🗣️
                </li>

                <li>
                    <strong>import pygame</strong>: Here we're importing Pygame, a set of Python modules designed for writing video games. Think of it as our magic toolbox 🧰✨ for making the game interactive and fun.
                </li>

                <li>
                    <strong>import time</strong>: By importing the 'time' module, we're equipping our game with the ability to track time. This is super handy for adding delays, timers, or just syncing things up. ⏱️🕒
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>import</strong>: The import statement in Python is used to bring in modules (a file containing Python definitions and statements) into the script. It's like saying, "Hey, I want to use some cool stuff someone else has already written!"
                </li>
            </ul>

            <h3>
            🎈 Did You Know?: The 'sys' module is often used in scripts that need to interact with the Python interpreter more directly. It's a bit like having backstage access to a Python show! 🎭🐍
            </h3>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 3: Igniting the Pygame Engine 🚀🎮
            </h2>

            <p>
            Now that we've got our essential tools, it's time to fire up the Pygame engine and get it ready for action! Let's dive into the next line of our Maze Game. 🧩👩‍💻
            </p>

            <img src={three} className='demo__image' alt='img_three' />

            <p>
            What does this line do? 🌟
            </p>

            <ol>
                <li>
                    <strong>pygame.init()</strong>: This is like turning the key in an engine. It initializes all the modules required for Pygame. Imagine it as waking up all the game-making magic that Pygame has to offer! 🧙‍♂️✨
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>pygame.init()</strong>: The init() function is a way to kickstart all the Pygame's internal workings. It's necessary to call this before doing anything else in Pygame. Just like how you need to start a car before you can drive it!
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Pygame is built on top of SDL (Simple DirectMedia Layer), which is a library that handles graphics, audio, and other multimedia components. By initializing Pygame, you're also setting up all these elements in the background! 🖥️🎵
            </h3>
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 4: Dimensioning Our Game World 🌍📏
            </h2>

            <p>
            Every adventure needs a space to unfold, and our Maze Game is no different! In this step, we'll define the dimensions of our game world. 🖼️👾
            </p>

            <img src={four} className='demo__image' alt='img_four' />

            <p>
            What does this line do? 🌟
            </p>

            <ol>
                <li>
                    <strong>width, height = 640, 480</strong>: Here, we're setting the stage – literally! We're defining the width and height of our game window in pixels. Think of it as choosing the size of a canvas for our digital masterpiece. 640 pixels wide and 480 pixels tall is a classic, balanced size that works well on most screens. 🖥️🎨
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
                <li>
                    <strong>width, height = 640, 480</strong>: This is a neat Python trick for assigning values to multiple variables in one line. 'width' is set to 640, and 'height' to 480. These measurements will dictate how much space our game will take up on the screen.
                </li>
            </ul>

            <h3>
            🎈 Did You Know?: The resolution of 640x480 pixels is often referred to as "VGA" resolution, a throwback to the early days of computer graphics. It's a nod to gaming history while being practical for modern development! 💻🕹️
            </h3>
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 5: Bringing the Game Window to Life 🚀🖥️
            </h2>

            <p>
            After setting the dimensions of our maze world, it's time to create the window where all the action will happen. Let's bring our game stage to life! 💡🎭
            </p>

            <img src={five} className='demo__image' alt='img_five' />

            <p>
            What do these lines do? 🌟
            </p>


            <ol>
            <li>
                <strong>screen = pygame.display.set_mode((width, height))</strong>: This line is where the magic happens! We're telling Pygame to create a new window (or screen) with the dimensions we defined earlier. This is like opening a portal to our game universe – a window into the world of our maze. 🪟✨
            </li>

            <li>
                <strong>pygame.display.set_caption('Maze Game')</strong>: Now that we have our game window, let's give it a name! This line sets the title of the window to 'Maze Game'. It's like putting a nameplate on our digital creation. 🏷️🎮
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>pygame.display.set_mode((width, height))</strong>: This function from Pygame creates the actual game window. The parameters `(width, height)` define the size of this window, as we determined in the previous step.
            </li>

            <li>
                <strong>pygame.display.set_caption('Maze Game')</strong>: This line of code is used to set the title of the window. Whatever string we pass in the parentheses – in this case, 'Maze Game' – appears in the title bar of the window.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: The window caption isn't just for show – it can be useful for players to identify the game when they have multiple windows open. It's a small detail that adds to the professionalism and polish of your game! 🖼️👌
            </h3>
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 6: Crafting Our Hero – The Player Character 🦸‍♂️🎨
            </h2>

            <p>
            Every game needs a protagonist, and in our maze, it's no different! In this step, we'll create our player character and get them ready for the adventure ahead. 🌟👾
            </p>

            <img src={six} className='demo__image' alt='img_six' />

            <p>
            What do these lines do? 🌟
            </p>

            <ol>
            <li>
                <strong>player_img = pygame.image.load('images/player.png')</strong>: First things first, we need a visual for our player. This line loads an image file named 'player.png' from the 'images' directory. It's like choosing the perfect outfit for our character – this is the visual representation of the player in our game world! 🎽🖼️
            </li>

            <li>
                <strong>player_img = pygame.transform.scale(player_img, (20, 20))</strong>: Now, we have our player's image, but it might not be the right size. This line adjusts the size of the image to a 20x20 pixel square. It's crucial to scale our character appropriately to fit into the maze environment and ensure that it interacts correctly with the game world. Think of it as tailoring the character's outfit to fit just right. 📏✂️
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>pygame.image.load('images/player.png')</strong>: This function from the Pygame library is used to load an image from a file. Here, 'images/player.png' is the path to our image file. It's like telling Pygame, "Hey, here's the picture I want to use for my player!"
            </li>

            <li>
                <strong>pygame.transform.scale(player_img, (20, 20))</strong>: After loading the image, we use this function to resize it. The first parameter is the image we want to scale, and the second parameter is a tuple representing the new size – in this case, 20 pixels by 20 pixels. It's a bit like resizing a photo to fit perfectly in a frame.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: The art and visuals of a character can greatly influence how players perceive and interact with the game. It's not just about looking good; it's about creating an avatar that players can connect with and enjoy controlling! 🎮🖌️
            </h3>
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 7: Setting the Goal – The End Image 🏁🎨
            </h2>

            <p>
            Every maze needs an end goal, and in this step, we'll create a visual representation for the finish line of our game. Let's design the target our player aims to reach! 🌟🏆
            </p>

            <img src={seven} className='demo__image' alt='img_seven' />

            <p>
            What do these lines do? 🌟
            </p>


            <ol>
            <li>
                <strong>end_img = pygame.image.load('images/end.png')</strong>: First, we need an image to represent the end point or goal of our maze. This line loads an image named 'end.png' from the 'images' directory. It's like selecting a trophy or a flag that marks the finish line in our game world. 🏁🖼️
            </li>

            <li>
                <strong>end_img = pygame.transform.scale(end_img, (20, 20))</strong>: Similar to our player character, the end image might not be the perfect size right off the bat. This line adjusts the size of the 'end' image to a 20x20 pixel square, ensuring it fits nicely in our maze layout. It's about giving the goal the right prominence in our game – visible but not overpowering. 📐🔍
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>pygame.image.load('images/end.png')</strong>: This function is used to load an image file into our game. Here, 'images/end.png' is the file path to the image we want to use as the end point in our maze.
            </li>

            <li>
                <strong>pygame.transform.scale(end_img, (20, 20))</strong>: This function is used for resizing the loaded image. The first parameter specifies the image to scale, and the second parameter – (20, 20) – sets the new dimensions of the image. It's like fitting the final piece of a puzzle in just the right place.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: The end point in a game is not just a target; it's a visual cue that guides players and gives them a sense of purpose. Designing it thoughtfully can enhance the overall game experience! 🎯👀
            </h3>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 8: Setting the Scene – The Background Image 🌄🎭
            </h2>

            <p>
            Now that we have our player and the end goal, it's time to paint our maze world! In this step, we're going to set up the background image that forms the backdrop of our game. 🎨🖼️
            </p>

            <img src={eight} className='demo__image' alt='img_eight' />

            <p>
            What does this line do? 🌟
            </p>


            <ol>
            <li>
                <strong>background_image = pygame.image.load('images/background.png')</strong>: This line is like choosing the perfect scenery for our maze adventure. We're loading an image named 'background.png' from the 'images' directory to serve as the background of our game. It sets the tone and atmosphere, providing a visual context for our maze. Imagine it as laying down the canvas before starting to paint. 🎨🏞️
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>pygame.image.load('images/background.png')</strong>: This function call tells Pygame to load an image file. Here, 'images/background.png' is the path to the image file that we want to use as the background in our game. It's the first step in visual storytelling within our maze.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: The background of a game is not just a static image; it sets the mood and immerses players in the game's world. A well-chosen background can make a simple maze feel like a grand adventure! 🌌🧭
            </h3>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 9: The Art of Colors – Defining Our Palette 🎨🌈
            </h2>

            <p>
            A game isn't just shapes and figures; it's a canvas where colors bring everything to life! In this step, we'll define the color palette for our maze game. 🖌️🌟
            </p>

            <img src={nine} className='demo__image' alt='img_nine' />

            <p>
            What do these lines do? 🌟
            </p>


            <ol>
                <li>
                    <strong>WHITE = (255, 255, 255)</strong>: Here, we're creating a color called WHITE. In the world of digital colors, (255, 255, 255) represents the color white. The three numbers are RGB (Red, Green, Blue) values, each ranging from 0 to 255. A combination of maximum red, green, and blue gives us pure white – like a blank canvas waiting for our creativity! 🖼️
                </li>

                <li>
                    <strong>BLACK = (0, 0, 0)</strong>: Similarly, BLACK is defined with RGB values (0, 0, 0). No light in all three channels means complete darkness, hence the black color. It's like the deep, mysterious void in our game, adding depth and contrast. 🌌🖤
                </li>

                <li>
                    <strong>GOLD = (212, 175, 55)</strong>: GOLD is a custom color we've created using a specific mix of RGB values. This rich, golden hue can add a sense of treasure and triumph to our game, enhancing the visual appeal and thematic storytelling. 💰
                </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>Color Variables</strong>: In Python, colors in Pygame are typically defined using RGB tuples. Each tuple contains three integers, each ranging from 0 (no intensity) to 255 (full intensity), representing the intensity of red, green, and blue channels, respectively.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: The use of colors in games is not just for aesthetics; it can be used to convey mood, guide players, and even tell a story. Choosing the right colors can significantly impact the player's experience and enjoyment! 🎭🎨
            </h3>

        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 10: Constructing the Maze – Laying Out the Path 🧱🗺️
            </h2>

            <p>
            Now comes one of the most exciting parts – creating the actual maze for our game! In this step, we'll lay out the paths and walls of our maze, designing the labyrinth our player will navigate. 🌟🏰
            </p>

            <img src={ten} className='demo__image' alt='img_ten' />

            <p className='pyExample'>
            maze_data = [<br/>
            &nbsp;&nbsp;"XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",<br/>
            &nbsp;&nbsp;"XP              X             EX",<br/>
            &nbsp;&nbsp;"X XXXXXXXXXXXX X XXXXXXXXXXX XXX",<br/>
            &nbsp;&nbsp;"X X           X X              X",<br/>
            &nbsp;&nbsp;"X X XXXXXXXXX X XXX XXXXXXXXXXXX",<br/>
            &nbsp;&nbsp;"X X X         X X              X",<br/>
            &nbsp;&nbsp;"X X X XXXXXXXXX XXXXXXXXXXXX XXX",<br/>
            &nbsp;&nbsp;"X X X                          X",<br/>
            &nbsp;&nbsp;"X XXXXXXXXXXXX X XXXXXXXXXXX XXX",<br/>
            &nbsp;&nbsp;"X              X     X         X",<br/>
            &nbsp;&nbsp;"XXXXXXXXXXXXXXXXXXX XXXXXXXX XXX",<br/>
            &nbsp;&nbsp;"X                              X",<br/>
            &nbsp;&nbsp;"X XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",<br/>
            &nbsp;&nbsp;"X                              X",<br/>
            &nbsp;&nbsp;"X XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",<br/>
            &nbsp;&nbsp;"X                              X",<br/>
            &nbsp;&nbsp;"XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",<br/>
            &nbsp;&nbsp;"X                              X",<br/>
            &nbsp;&nbsp;"X                              X",<br/>
            &nbsp;&nbsp;"XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"<br/>
            &nbsp;&nbsp;"X                              X",<br/>
            &nbsp;&nbsp;"X                              X",<br/>
            &nbsp;&nbsp;"XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"<br/>
            &nbsp;&nbsp;"X                              X",<br/>
            &nbsp;&nbsp;"X                              X",<br/>
            &nbsp;&nbsp;"XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"<br/>
            ]
            </p>

            <p>
            What does this code do? 🌟
            </p>

            <ol>
            <li>
                <strong>maze_data = [...]</strong>: Here, we're defining a variable called 'maze_data' which is an array of strings. Each string represents a row in our maze, with each character in the string representing a different cell in that row. It's like drawing a map for our adventure, where every 'X' marks a wall and spaces represent open paths. 🗺️🖋️
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>maze_data</strong>: This is an array (a list in Python terms) where each element is a string. The strings are made up of 'X', 'P', 'E', and space characters. 'X' denotes a wall, 'P' marks the player's starting position, 'E' signifies the end point, and spaces indicate open paths where the player can move.
            </li>

            <li>
                <strong>String Representation of the Maze</strong>: Each string is a horizontal slice of our maze. When stacked together, they form the complete maze layout. It's a simple yet powerful way to visually design our game's main challenge.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Using strings to represent game maps is a classic technique in game development. It's a straightforward way to visualize and design game levels, especially in tile-based games like our maze! 🎮🏗️
            </h3>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 11: Defining the Player's Territory – The Player Rectangle 🚶‍♂️🔲
            </h2>

            <p>
            After designing our maze and character, it's time to give our player a physical presence in the game world. In this step, we'll define the player's space or 'territory' within the maze. 🌟🌍
            </p>

            <img src={eleven} className='demo__image' alt='img_eleven' />

            <p>
            What does this line do? 🌟
            </p>


            <ol>
            <li>
                <strong>player_rect = player_img.get_rect()</strong>: This line is crucial for how our player interacts with the maze. 'get_rect()' is a Pygame function that gets a rectangle object, which represents the area of the screen occupied by the player image. Think of it as drawing an invisible box around our player character, defining where they can move and interact in the maze. It's like marking out a personal bubble in the digital world! 📦👤
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>player_img.get_rect()</strong>: 'get_rect()' is a method in Pygame used on surfaces (in this case, our player image). It returns a rectangle object that has the same size as the surface. This rectangle can be used for positioning, detecting collisions, and other crucial game mechanics.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: In many games, characters and objects are often represented by rectangles for collision detection and physics simulations. It's a simple yet effective way to manage interactions in a game world! 🌐🤖
            </h3>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 12: Preparing for the Journey – Initializing the Player's Start Position 🚦📍
            </h2>

            <p>
            Before our player embarks on their maze adventure, we need to set the starting point. In this step, we'll initialize the player's starting position. 🌟👣
            </p>

            <img src={twelve} className='demo__image' alt='img_twelve' />

            <p>
            What does this line do? 🌟
            </p>


            <ol>
            <li>
                <strong>player_start = None</strong>: This line sets up a placeholder for where our player will begin their journey in the maze. By assigning 'None' to 'player_start', we're saying, "We don’t know where the player will start yet, but we’re reserving a spot for that information." It's like marking an 'X' on a treasure map, but not having drawn the map yet. We're preparing to define this crucial piece of information as we build our maze. 🗺️✖️
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>player_start = None</strong>: In Python, 'None' is a special value that represents 'nothing' or 'no value'. By setting 'player_start' to 'None', we're essentially creating a variable that will later hold the coordinates of the player's starting position in the maze.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Initializing variables with 'None' is a common practice in programming. It helps in managing the state of variables and prevents errors that could occur from using uninitialized variables. It’s like saying, “This will be important later, but for now, it’s a placeholder.” 🕒📌
            </h3>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 13: Racing Against Time – Setting the Timer ⏳⏰
            </h2>

            <p>
            What's a maze adventure without a sense of urgency? In this step, we add an exciting twist: a time limit! Let's set up a timer to challenge our players to beat the maze before time runs out. 🚀🕒
            </p>

            <img src={thirteen} className='demo__image' alt='img_thirteen' />

            <p>
            What do these lines do? 🌟
            </p>

            <ol>
            <li>
                <strong>start_time = pygame.time.get_ticks()</strong>: This line marks the beginning of our countdown. 'pygame.time.get_ticks()' returns the number of milliseconds since Pygame was initialized. It's like hitting the start button on a stopwatch as soon as our game begins. This will be our reference point to track how much time has elapsed in the game. ⏱️🚦
            </li>

            <li>
                <strong>time_limit = 30000</strong>: Here, we're setting the time limit for our maze challenge. '30000' represents 30,000 milliseconds, which equals 30 seconds. It's the total time our players have to navigate through the maze and reach the end. It adds a thrilling rush to the game, as players race against the clock! ⌛🏃‍♂️
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>pygame.time.get_ticks()</strong>: This function is a part of Pygame's time module. It provides the current time in milliseconds, which is useful for measuring time intervals, like our game's time limit.
            </li>

            <li>
                <strong>time_limit = 30000</strong>: This is a simple assignment of a numerical value to the variable 'time_limit'. The number 30000 here is the total duration in milliseconds for which the game will run before the time is up.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Adding a time limit to games can create a sense of urgency and excitement. It challenges players to think and act quickly, making the gameplay more dynamic and thrilling! ⚡🎲
            </h3>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 14: Preparing the Foundation – Initializing the Maze Array 🏗️🌐
            </h2>

            <p>
            As we get closer to bringing our maze to life, it's essential to have a structure in place to hold its layout. In this step, we'll initialize the foundation of our maze. 🛠️🧩
            </p>

            <img src={fourteen} className='demo__image' alt='img_fourteen' />

            <p>
            What does this line do? 🌟
            </p>

            <ol>
            <li>
                <strong>maze = []</strong>: This simple yet powerful line sets up an empty list (or array in other programming languages) named 'maze'. Think of it as building the shelves before stocking the books. Here, our 'shelves' will eventually hold the details of our maze's walls and paths. It’s the first step in turning our 'maze_data' into an interactive part of the game world. 📚🔍
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>maze = []</strong>: In Python, '[]' denotes an empty list. This is where we'll store the elements that make up our maze, such as walls and open spaces. It's a versatile and essential part of creating dynamic, interactive environments in games.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Lists are one of the fundamental data structures in Python. They are incredibly versatile and can be used to store a wide range of data types. In game development, they're often used to manage game elements, levels, and even animations! 📋✨
            </h3>
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 15: Building the Maze – Starting the Construction Loop 🔁🏰
            </h2>

            <p>
            It's time to turn our maze blueprint into a reality! In this step, we start the process of building the maze by iterating over our maze data. Let's break ground on this exciting phase! 🚧🗺️
            </p>

            <img src={fifteen} className='demo__image' alt='img_fifteen' />

            <p>
            What do these lines do? 🌟
            </p>

            <ol>
            <li>
                <strong>for i, row in enumerate(maze_data):</strong>: This line begins a loop that goes through each row in our 'maze_data'. 'enumerate(maze_data)' is a handy function that gives us two things for each loop: the index (i) and the content (row). The index 'i' is like a row number in our maze, and 'row' is the string that represents the walls and paths of that row. It's like walking through each row of our maze blueprint and noting its design. 🚶‍♂️📝
            </li>

            <li>
                <strong>maze_row = []</strong>: Inside the loop, we initialize an empty list called 'maze_row'. For each row of our maze, we'll use 'maze_row' to store individual parts of that row, like the walls and open spaces. Think of 'maze_row' as a container where we collect the elements of each row before placing them into the larger 'maze'. 📦🧱
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>for i, row in enumerate(maze_data):</strong>: 'for' starts a loop in Python, and 'enumerate()' is a function that adds a counter to an iterable. In our case, it helps us loop through each row in 'maze_data' with both its index (i) and its value (row).
            </li>

            <li>
                <strong>maze_row = []</strong>: This creates a new, empty list in each iteration of the loop. It's a fresh start for each row in our maze, allowing us to construct each row individually.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Loops and arrays are fundamental concepts in programming. They allow developers to efficiently handle repetitive tasks and organize data. In game development, they're crucial for creating levels, characters, and interactive elements! 🔄🎮
            </h3>
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 16: Laying Out Each Row – The Inner Maze Loop 🔍🧩
            </h2>

            <p>
            After setting up the structure for each row of our maze, it's time to delve into the details of every cell in those rows. Here, we'll identify the player's starting point in the maze. Let's get into the nitty-gritty of our maze layout! 🔎🖼️
            </p>
            <img src={sixteen} className='demo__image' alt='img_sixteen' />

            <p>
            What do these lines do? 🌟
            </p>


            <ol>
            <li>
                <strong>for j, cell in enumerate(row):</strong>: This nested loop dives into each row of our maze. For every row, it examines each cell (character) in that row. 'enumerate(row)' here gives us both the column number (j) and the content of the cell (cell). It's like examining every square inch of our maze blueprint, understanding what each symbol represents. 🔍🗺️
            </li>

            <li>
                <strong>if cell == 'P':</strong>: In this conditional statement, we're looking for the cell that contains 'P', which stands for 'Player'. This is where our player will start their journey in the maze.
            </li>

            <li>
                <strong>player_start = (j * 20, i * 20)</strong>: Once we find the 'P', we set 'player_start' to the coordinates of that cell. The coordinates are calculated by multiplying the column number (j) and the row number (i) by 20. This translates our simple text representation into actual coordinates on the screen. It's like marking the exact spot on our map where our adventure begins! 🌟📍
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>for j, cell in enumerate(row):</strong>: This line iterates through each character in a row. 'j' is the index (or position) of each character in the row, and 'cell' is the character itself.
            </li>

            <li>
                <strong>if cell == 'P':</strong>: This is a conditional statement that checks if the current cell is the player's starting point ('P').
            </li>

            <li>
                <strong>player_start = (j * 20, i * 20)</strong>: This assigns the (x, y) coordinates for the player's start position based on the position of 'P' in the maze data. The multiplication by 20 scales these positions to match the size of the cells in our game display.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Conditional statements and loops are powerful tools in programming. They enable developers to process and respond to data efficiently, making them indispensable in game development and many other applications! 🛠️💻
            </h3>
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 17: Constructing Walls – Defining Obstacles in the Maze 🧱🚧
            </h2>

            <p>
            A maze isn't complete without walls to challenge our players! In this step, we'll define the walls within our maze, setting up the obstacles that make navigating the maze an intriguing challenge. 🕵️‍♂️🔐
            </p>
            <img src={seventeen} className='demo__image' alt='img_seventeen' />
            <p>
            What do these lines do? 🌟
            </p>

            <ol>
            <li>
                <strong>if cell == 'X':</strong>: This line checks each cell in our maze to see if it contains an 'X'. In our maze design, an 'X' represents a wall. It's like looking at the blueprint and identifying where the solid barriers are located. 🧐🗺️
            </li>

            <li>
                <strong>wall = pygame.Rect(j * 20, i * 20, 20, 20)</strong>: When we find an 'X', this line creates a wall at that position. 'pygame.Rect()' creates a rectangle, which in this case represents a wall. The parameters (j * 20, i * 20, 20, 20) set the position and size of the rectangle. The position is determined by multiplying the row and column indices (i and j) by 20, and the size is set to be a 20x20 pixel square. It's like placing a block in our digital maze, creating the paths our player will navigate. 📐🧱
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>if cell == 'X':</strong>: This is a conditional statement that checks for the presence of a wall ('X') in the current cell.
            </li>

            <li>
                <strong>pygame.Rect(j * 20, i * 20, 20, 20)</strong>: This function call creates a Pygame rectangle object. The first two parameters are the x and y coordinates of the rectangle's top-left corner, and the last two parameters are its width and height. In our game, this rectangle is used to represent a wall in the maze.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: In many video games, walls and obstacles are not just physical barriers; they also add to the strategy and design of the game, creating a more engaging and challenging experience for the player! 🎮🤔
            </h3>
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 18: Assembling the Maze – Adding Walls to the Structure 🏗️🖼️
            </h2>

            <p>
            After creating the walls, it's time to place them into our maze structure. In this step, we'll integrate the walls into our maze layout, making our digital labyrinth come to life. 🧱🎮
            </p>

            <img src={eighteen} className='demo__image' alt='img_eighteen' />

            <p>
            What do these lines do? 🌟
            </p>


            <ol>
            <li>
                <strong>maze_row.append(wall)</strong>: This line adds the wall we've just created to 'maze_row'. Remember, 'maze_row' is a list that represents one row of our maze. By using 'append()', we are placing the wall into its correct position in that row. It's like adding a brick to a wall in our maze, one at a time, until the row is complete. 🧱🛠️
            </li>

            <li>
                <strong>maze.append(maze_row)</strong>: Once we have finished building a row with all its walls and paths, this line adds the completed 'maze_row' to the 'maze'. The 'maze' is a list of these rows, and as we 'append' each 'maze_row', our maze grows vertically, row by row. Think of it as stacking layers on a cake, where each layer is intricately designed to be part of a delicious whole. 🍰🏛️
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>maze_row.append(wall)</strong>: 'append()' is a method in Python that adds an item to the end of a list. Here, it's adding the 'wall' object to the current 'maze_row'.
            </li>

            <li>
                <strong>maze.append(maze_row)</strong>: Similar to the previous line, this uses 'append()' to add the entire 'maze_row' to the 'maze'. After this line, the 'maze_row' becomes part of the complete maze structure.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Building a game environment piece by piece is similar to puzzle-solving. It requires careful planning and execution, and when done correctly, it results in an engaging and immersive game world for players to explore! 🧩🌍
            </h3>
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 19: Positioning the Player – Setting the Starting Point 🌟🚶‍♂️
            </h2>

            <p>
            With our maze constructed and the player's start location determined, it's time to place our player character in the maze. In this step, we'll set the starting position of our player in the game world. Let's get our hero ready for the adventure! 🏁👤
            </p>

            <img src={nineteen} className='demo__image' alt='img_nineteen' />

            <p>
            What does this line do? 🌟
            </p>


            <ol>
            <li>
                <strong>player_rect.topleft = player_start</strong>: This line is where we position our player character at the starting point of the maze. 'player_rect' is a rectangle that represents our player in the game. By setting its 'topleft' attribute to 'player_start', we align the top-left corner of the player's rectangle with the coordinates of the starting position. It's like placing a game piece on the start square of a board game. 🎲📍
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>player_rect.topleft</strong>: In Pygame, a rectangle object (or 'Rect') has several attributes that define its position and size. 'topleft' is an attribute that specifies the coordinates of the rectangle's top-left corner.
            </li>

            <li>
                <strong>player_start</strong>: This is a tuple containing the x and y coordinates for the player's starting position in the maze, determined earlier in our code.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Positioning elements in a game is crucial for gameplay mechanics, such as collision detection, movement, and interaction with the game world. Getting this right enhances the player's experience and immersion in the game! 🕹️🌏
            </h3>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 20: Keeping Track of Time – Creating a Game Clock ⏲️⏳
            </h2>

            <p>
            In the world of gaming, timing is everything! In this step, we'll introduce a game clock to manage the flow of time within our maze. Let's set up the heartbeat of our game! ⏰❤️
            </p>

            <img src={twenty} className='demo__image' alt='img_twenty' />

            <p>
            What does this line do? 🌟
            </p>

            <ol>
            <li>
                <strong>clock = pygame.time.Clock()</strong>: This line creates a Clock object from Pygame's time module. The 'Clock' object is crucial for controlling the game's frame rate and managing how fast the game runs. It's like having a metronome that keeps the rhythm of our game, ensuring everything moves smoothly and consistently. 🎵🕒
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>pygame.time.Clock()</strong>: This function call creates a new Clock object. In Pygame, this object is used to track and control the time between each frame in the game, allowing us to update the game's state at regular intervals.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Controlling the frame rate is essential in game development. It affects not only the game's playability but also how the player perceives motion and action within the game world. A stable frame rate keeps the game experience smooth and enjoyable! 🖼️🚀
            </h3>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 21: The Game Loop – Powering the Gameplay 🔄🎮
            </h2>

            <p>
            The heart of any game is its game loop, where all the magic happens. In this step, we'll create the central loop that drives our maze game. Let's bring our game to life! 💫🕹️
            </p>

            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />

            <p>
            What do these lines do? 🌟
            </p>


            <ol>
            <li>
                <strong>running = True</strong>: This line sets up a boolean variable named 'running' and assigns it the value 'True'. It's like flipping the 'on' switch for our game. This variable will be used to control the game loop, determining when the game should continue running and when it should end. 💡🔛
            </li>

            <li>
                <strong>while running:</strong>: Here begins the game loop with a 'while' statement. As long as 'running' is 'True', the loop will keep executing. Inside this loop, all the game's updates, events, and rendering happen. It's the continuous cycle that keeps the game active, reacting to player input, updating game state, and redrawing the screen frame by frame. Think of it as the heartbeat of our game, keeping the action going. ❤️🔄
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>running = True</strong>: This is a simple assignment of a boolean value ('True') to the variable 'running'. Boolean values are either 'True' or 'False' and are used to control the flow of programs in many programming languages.
            </li>

            <li>
                <strong>while running:</strong>: This is a 'while' loop, one of the fundamental control structures in Python. It repeatedly executes a block of code as long as its condition ('running' is 'True' in this case) remains true.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: The game loop is a fundamental concept in game development. It's what allows games to be interactive and responsive. Everything from updating game mechanics to rendering graphics happens within this loop! 🌍💻
            </h3>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 22: Setting the Scene – Displaying the Background 🖼️🌄
            </h2>

            <p>
            A great game needs a great setting! In this step, we'll start drawing our game scene by placing the background image on the screen. It's time to paint our digital canvas! 🎨🖥️
            </p>

            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />

            <p>
            What does this line do? 🌟
            </p>

            <ol>
            <li>
                <strong>screen.blit(background_image, (0, 0))</strong>: This line is crucial for visual presentation in our game. 'blit' is a method in Pygame used to draw one image onto another. Here, we're drawing the 'background_image' onto the 'screen'. The coordinates (0, 0) specify the top-left corner of the screen, meaning the background image will cover the entire game window. It's like laying the foundation of a painting, setting the stage for all the action to unfold. 🖌️🏞️
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>screen.blit(...)</strong>: In Pygame, 'blit' is used for drawing images or surfaces onto the screen. The first argument is the image to be drawn, and the second argument is the position where it should be drawn on the screen.
            </li>

            <li>
                <strong>(0, 0)</strong>: These are the x and y coordinates on the screen where the top-left corner of the background image will be placed. Starting at (0, 0) ensures the image covers the entire screen.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: The background sets the mood and tone of a game. It's not just a visual element; it can contribute to the storytelling, provide clues, and enhance the overall aesthetic appeal of the game! 🎭🌌
            </h3>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 23: Responding to Events – Handling User Input and Exiting the Game 🎮🚪
            </h2>

            <p>
            Games are interactive by nature, and in this step, we start responding to player actions! We'll handle user input and the condition to exit the game. Let's make our maze responsive to its players! 🕹️👤
            </p>

            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
            <p>
            What do these lines do? 🌟
            </p>

            <ol>
            <li>
                <strong>for event in pygame.event.get():</strong>: This line starts a loop that checks for all events that have occurred. In Pygame, an 'event' is any action or input from the user, like pressing a key or clicking the mouse. The 'pygame.event.get()' function gets all the events from the event queue. It's like having a receptionist who informs us about every interaction at the entrance of our maze. 🛎️👥
            </li>

            <li>
                <strong>if event.type == pygame.QUIT:</strong>: Inside the loop, we check if any of the events are of the type 'QUIT'. The 'QUIT' event occurs when the player tries to close the game window. This line is our way of asking, "Is someone trying to exit the game?" 🚪❓
            </li>

            <li>
                <strong>running = False</strong>: If the event is a 'QUIT' event, we set 'running' to 'False'. This effectively stops the game loop, leading to the closure of the game. It's like turning off the lights and closing the door when the last guest leaves our maze party. 💡🔚
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>for event in pygame.event.get():</strong>: This line iterates through a list of all the events that have happened. Each 'event' is an object that contains information about the interaction.
            </li>

            <li>
                <strong>if event.type == pygame.QUIT:</strong>: This conditional checks whether the event type is 'QUIT' (like closing the game window).
            </li>

            <li>
                <strong>running = False</strong>: This changes the value of the 'running' variable to 'False', which will break the game loop and end the game.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Handling events properly is crucial in game design. It ensures that the game reacts appropriately to player actions and provides a seamless and enjoyable gaming experience! 🕹️🌐
            </h3>
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 24: Preparing for Movement – Capturing Key Presses and Setting Directions 🎮👣
            </h2>

            <p>
            A maze game isn't just about the walls and paths; it's also about movement and exploration. In this step, we'll set up the groundwork for moving our player through the maze. Let's get ready to navigate! 🚶‍♂️🗺️
            </p>

            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />

            <p>
            What do these lines do? 🌟
            </p>

            <ol>
            <li>
                <strong>keys = pygame.key.get_pressed()</strong>: This line captures the current state of all keyboard keys. 'pygame.key.get_pressed()' returns a list where each element corresponds to a key on the keyboard, and its value indicates whether the key is currently being pressed down. It's like having a sensor on each key to detect if the player is giving a command. 🕹️🔑
            </li>

            <li>
                <strong>dx, dy = 0, 0</strong>: Here, we initialize two variables 'dx' and 'dy' (short for delta x and delta y) to zero. These will be used to represent the change in the player's position along the x-axis (horizontal) and y-axis (vertical), respectively. Initially, we set them to zero because the player is not moving yet. It's like having our player standing still, ready to start walking. 🚦👟
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>pygame.key.get_pressed()</strong>: This function checks the state of all keyboard keys at the moment it is called and returns a list of Boolean values representing their states (pressed or not pressed).
            </li>

            <li>
                <strong>dx, dy = 0, 0</strong>: This is a way of assigning initial values to 'dx' and 'dy'. In Python, you can assign values to multiple variables in a single line like this. The initial value of 0 indicates no movement.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Detecting and responding to player inputs is what makes games interactive. The way a game processes and reacts to these inputs can significantly affect the gameplay experience and player satisfaction! 🎲👾
            </h3>
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 25: Bringing Motion to Life – Handling Player Movement 🏃‍♂️💨
            </h2>

            <p>
            Now that we're ready to move, it's time to turn key presses into actual movement within our maze. In this step, we'll make our player navigate through the maze based on player input. Let's get moving! 🎮👟
            </p>

            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
            
            <p>
            What do these lines do? 🌟
            </p>

            <ol>
            <li>
                <strong>if keys[pygame.K_LEFT]:</strong>: This line checks if the left arrow key is pressed. If it is, we set 'dx' to '-5'. This means we'll move the player 5 pixels to the left on the screen. It's like stepping to the left in our digital world. 👈🚶‍♂️
            </li>

            <li>
                <strong>if keys[pygame.K_RIGHT]:</strong>: Similarly, this checks for the right arrow key. Pressing the right key sets 'dx' to '5', moving the player 5 pixels to the right. Each key press is a small step in our maze journey. 👉🚶‍♂️
            </li>

            <li>
                <strong>if keys[pygame.K_UP]:</strong>: This line responds to the up arrow key. When pressed, 'dy' is set to '-5', which moves the player up by 5 pixels. It's like walking upwards on our game map. 🔼🚶‍♂️
            </li>

            <li>
                <strong>if keys[pygame.K_DOWN]:</strong>: Lastly, pressing the down arrow key sets 'dy' to '5', moving the player down by 5 pixels. We're creating a simple yet effective way for players to navigate the maze. 🔽🚶‍♂️
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>if keys[pygame.K_LEFT], keys[pygame.K_RIGHT], keys[pygame.K_UP], keys[pygame.K_DOWN]:</strong>: These lines check the state of specific keys (left, right, up, down) in the list returned by 'pygame.key.get_pressed()'. If a key is pressed, the corresponding direction variable ('dx' for left/right, 'dy' for up/down) is updated.
            </li>

            <li>
                <strong>dx = -5, dx = 5, dy = -5, dy = 5:</strong>: These assignments change the player's movement direction and speed. The values determine how many pixels the player moves each frame, with negative values for left/up movement and positive values for right/down movement.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: The way a game handles movement can greatly affect its feel and playability. Smooth and responsive controls are key to an enjoyable gaming experience! 🕹️👌
            </h3>
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 26: Making the Move – Applying Movement to the Player 🕺💨
            </h2>

            <p>
            After setting the direction for our player's movement, it's time to put those plans into action. In this step, we'll actually move our player within the maze. Let's bring our character to life! 🚀👾
            </p>

            <img src={twentySix} className='demo__image' alt='img_twentySix' />

            <p>
            What does this line do? 🌟
            </p>

            <ol>
            <li>
                <strong>player_rect.move_ip(dx, dy)</strong>: This line is where the player's rectangle, 'player_rect', is moved on the screen. The 'move_ip()' method stands for "move in place", and it shifts the rectangle's position. The parameters 'dx' and 'dy' represent the distance to move in the x (horizontal) and y (vertical) directions, respectively. This method updates the player's position based on the current movement directions we set in the previous step. It's like taking a step in a chosen direction in our digital maze world. 👣🧭
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>player_rect.move_ip(dx, dy)</strong>: 'move_ip()' is a method provided by Pygame's Rect object. It alters the position of the Rect by adding 'dx' to its x-coordinate and 'dy' to its y-coordinate, effectively moving the Rect (and thus the player's image) on the screen.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Movement in games is not just about changing positions; it's about creating a sense of action and interaction within the game world. The way characters move can greatly impact the gaming experience! 🌍🏃‍♂️
            </h3>
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 27: Navigating the Challenges – Implementing Collision Detection 🚧🎮
            </h2>

            <p>
            A maze game isn't just about moving freely; it's also about overcoming obstacles! In this step, we'll implement collision detection to ensure our player doesn't walk through the walls of the maze. Let's make our maze a real challenge! 🧱🕵️‍♂️
            </p>

            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />

            <p>
            What do these lines do? 🌟
            </p>

            <ol>
            <li>
                <strong>for row in maze:</strong>: This line starts a loop through each row in the 'maze'. We're checking every part of our maze to see if the player encounters any walls.
            </li>

            <li>
                <strong>for wall in row:</strong>: Within each row, we further loop through each 'wall' element. It's a thorough check to see if our player has bumped into any wall in the maze.
            </li>

            <li>
                <strong>if player_rect.colliderect(wall):</strong>: Here, we use 'colliderect()', a Pygame method, to check if the player's rectangle ('player_rect') intersects with any wall rectangle. This is how we detect a collision – it's like our player running into a wall in the maze.
            </li>

            <li>
                <strong>player_rect.move_ip(-dx, -dy)</strong>: If a collision is detected, we move the player back to their previous position by reversing the movement. The minus signs in front of 'dx' and 'dy' undo the last movement step, preventing the player from moving through the wall. It's our way of saying, "Oops, you can't go through here!" 🚷↩️
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>for row in maze:</strong>: This loops through each row in the 'maze' array.
            </li>

            <li>
                <strong>for wall in row:</strong>: This nested loop iterates through each 'wall' element in the current row.
            </li>

            <li>
                <strong>if player_rect.colliderect(wall):</strong>: This checks for a collision between the player's rectangle and a wall rectangle.
            </li>

            <li>
                <strong>player_rect.move_ip(-dx, -dy)</strong>: This line moves the player's rectangle back to its previous position, effectively handling the collision response.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Collision detection is a key element in many games. It adds realism and challenge, forcing players to navigate obstacles and interact with the game world more strategically! 💡🎲
            </h3>
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 28: Visualizing the Obstacles – Drawing the Maze Walls 🖌️🧱
            </h2>

            <p>
            After ensuring our player can't walk through walls, it's time to make these walls visible! In this step, we'll draw the walls of the maze on the screen, bringing the challenge to visual life. 🎨👀
            </p>

            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />

            <p>
            What do these lines do? 🌟
            </p>

            <ol>
            <li>
                <strong>for row in maze:</strong>: This line starts a loop through each row of our 'maze'. We're going through the maze layout to find where the walls are.
            </li>

            <li>
                <strong>for wall in row:</strong>: Within each row, we further iterate through each 'wall' element. This is part of our detailed check through the maze's structure.
            </li>

            <li>
                <strong>pygame.draw.rect(screen, BLACK, wall)</strong>: Here, we use the Pygame function 'draw.rect' to visually represent each wall on the screen. The first argument 'screen' is where we want to draw (our game window), 'BLACK' is the color of the walls, and 'wall' is the rectangle object representing each wall's position and size. It's like painting the barriers of our digital labyrinth, making them visible and tangible for the player. 🖼️🚧
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>pygame.draw.rect(...)</strong>: This function draws a rectangle on a surface. In our case, it draws each wall on the game window ('screen'). The parameters include the surface to draw on, the color of the rectangle, and the rectangle object itself.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Visual feedback is crucial in games. It helps players understand the game environment and their interactions with it. Good visual design can significantly enhance the gaming experience! 🌈💻
            </h3>
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 29: Showcasing the Hero – Displaying the Player on the Screen 🦸‍♂️🌟
            </h2>

            <p>
            With our maze and its walls rendered, it's time to put our player in the spotlight! In this step, we'll display the player's character on the screen, making it a visible and active part of the game. Let's bring our hero to the foreground! 🎮🔦
            </p>

            <img src={twentyNine} className='demo__image' alt='img_twentyNine' />

            <p>
            What does this line do? 🌟
            </p>


            <ol>
            <li>
                <strong>screen.blit(player_img, player_rect)</strong>: This line is crucial for making our player character visible in the maze. The 'blit' method is used again here, but this time to draw the 'player_img' (the image of our player) onto the 'screen' at the position specified by 'player_rect'. The 'player_rect' object not only defines the player's current position but also its size and shape. It's like placing our game's protagonist on the stage, ready for action! 🎭👤
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>screen.blit(player_img, player_rect)</strong>: The 'blit' function takes two arguments – the image to be drawn ('player_img') and the position where it should be drawn ('player_rect'). This combination of image and rectangle ensures that our player appears exactly where they should be in the maze.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: The player character is often the central element of a game, serving as the player's avatar in the game world. How it is presented and controlled can greatly affect the player's connection to the game and overall experience! 🕹️❤️
            </h3>
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 30: Defining the Goal – Setting Up the End Point 🏁🌟
            </h2>

            <p>
            Every maze needs an end goal, and our game is no exception. In this step, we define the end point of our maze, the ultimate destination our player aims to reach. Let's mark the finish line! 🎯🚪
            </p>

            <img src={thirty} className='demo__image' alt='img_thirty' />

            <p>
            What does this line do? 🌟
            </p>

            <ol>
            <li>
                <strong>end_cell = pygame.Rect(maze_data[1].index('E') * 20, 20, 20, 20)</strong>: This line creates a rectangle representing the end point of our maze. We locate the 'E' character in our 'maze_data', which marks the end cell. The 'index' method finds the position of 'E' in the second row of our maze data (hence 'maze_data[1]'). We then multiply this position by 20 to get the x-coordinate of our end cell in pixels. The y-coordinate is set to 20 pixels (as it's in the second row), and the size of the cell is 20x20 pixels. It's like drawing the treasure chest on our treasure map – the ultimate prize in our maze adventure. 🏆🗺️
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>pygame.Rect(...)</strong>: This function in Pygame is used to create a rectangle, which is a fundamental shape used for graphics and collision detection. It takes parameters for the x and y coordinates, as well as the width and height of the rectangle.
            </li>

            <li>
                <strong>maze_data[1].index('E') * 20, 20, 20, 20</strong>: This calculation determines the position and size of the end cell. The x-coordinate is calculated by finding the column of 'E' and scaling it, and the y-coordinate is set based on the row. The width and height are set to 20 pixels each, making it a perfect square.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: In game design, the end goal is not just a target; it's a motivator that guides the player's journey. It adds purpose and direction to the gameplay, making the game more engaging and rewarding! 🌈🎯
            </h3>
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 31: Framing the Objective – Creating the End Point Rectangle 🏆🔲
            </h2>

            <p>
            After marking the location for our end point, it's time to give it a visual form. In this step, we'll create a rectangle for our end point image, preparing it to be displayed in the maze. Let's make our goal not just a concept, but a visible target! 🖼️🎯
            </p>

            <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />

            <p>
            What does this line do? 🌟
            </p>

            <ol>
            <li>
                <strong>end_rect = end_img.get_rect()</strong>: This line creates a rectangle object for our end point image, 'end_img'. Just like we did for the player, we use 'get_rect()' to get a rectangle that matches the size of our end point image. This rectangle, 'end_rect', will be used to position and display the end image on the screen. It's like framing our 'finish line' sign, making it ready to be placed in our digital world. 🖼️📍
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>end_img.get_rect()</strong>: This method creates a Rect object that matches the size and shape of 'end_img', the image for our end point. The Rect object is a versatile tool in Pygame used for positioning, rendering images, and collision detection.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: In games, the visual representation of goals and objectives is key to providing players with clear targets and motivations. A well-defined goal can greatly enhance the gaming experience and player engagement! 🌟🎲
            </h3>
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 32: Showcasing the Destination – Displaying the End Point 🏁🖼️
            </h2>

            <p>
            With the end point of our maze visually prepared, it's time to place it in the game world. In this step, we'll display the end point image on the screen, making it a visible and reachable goal for our player. Let's illuminate the finish line! 🌟🏆
            </p>

            <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />

            <p>
            What does this line do? 🌟
            </p>

            <ol>
            <li>
                <strong>screen.blit(end_img, end_cell)</strong>: This line draws the end point image, 'end_img', onto the game screen. We use the 'blit' method again, just like we did with the player image. The 'end_cell' rectangle determines where on the screen the image is placed. This step visually marks the finish line of our maze, creating a clear target for our players to reach. It's like hanging a flag at the end of a race track, signifying where the journey is meant to conclude. 🏁🎨
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>screen.blit(end_img, end_cell)</strong>: The 'blit' function is used to draw the 'end_img' on the 'screen' at the position specified by the 'end_cell' rectangle. This aligns the end point image with the location we defined for the maze's finish line.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Visual cues like the end point in games not only guide players but also provide a sense of progress and achievement. Seeing the goal can be a strong motivator and adds to the overall enjoyment of the game! 🚀🎯
            </h3>
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 33: Celebrating Success – Winning the Game 🏆🎉
            </h2>

            <p>
            What's a maze game without the thrill of victory? In this step, we'll define what happens when our player reaches the end point, completing the maze. Let's create a moment of triumph! 🥇🚀
            </p>

            <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />

            <p>
            What do these lines do? 🌟
            </p>

            <ol>
            <li>
                <strong>if player_rect.colliderect(end_cell):</strong>: This line checks if the player's rectangle ('player_rect') intersects with the rectangle for the end cell ('end_cell'). In simple terms, it's checking if the player has reached the end point of the maze. It's the moment where we check if our player has successfully navigated to the goal. 🏁👤
            </li>

            <li>
                <strong>print("You won!")</strong>: If the player reaches the end point, we print a message to the console celebrating their victory. This is a simple way to acknowledge the player's achievement. It's like throwing confetti and cheering for the player's success. 🎊📢
            </li>

            <li>
                <strong>running = False</strong>: Finally, we set 'running' to 'False', which ends the game loop. This stops the game, signifying that the maze has been completed. It's like crossing the finish line in a race and stopping the clock. 🛑⏱️
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>if player_rect.colliderect(end_cell):</strong>: This line uses the 'colliderect' method to check for a collision (or overlap) between the player's rectangle and the end cell's rectangle.
            </li>

            <li>
                <strong>print("You won!")</strong>: This command prints the message "You won!" to the console.
            </li>

            <li>
                <strong>running = False</strong>: Setting 'running' to 'False' breaks the game loop, effectively ending the game.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Providing immediate feedback on success is important in games. It rewards players for their efforts and achievements, encouraging them to play more and improve their skills! 🌟🕹️
            </h3>
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 34: Racing Against Time – Managing the Game Timer ⏳⌛
            </h2>

            <p>
            In a game where timing is key, keeping track of how long the player has been navigating the maze adds excitement and urgency. In this step, we'll manage the game timer, updating how much time the player has left to reach the end. Let's keep an eye on the clock! ⏰👀
            </p>

            <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />

            <p>
            What do these lines do? 🌟
            </p>

            <ol>
            <li>
                <strong>current_time = pygame.time.get_ticks()</strong>: This line fetches the current time in milliseconds since the start of the game. It's like checking a stopwatch to see how much time has passed. 🕒
            </li>

            <li>
                <strong>time_elapsed = current_time - start_time</strong>: Here, we calculate the time elapsed by subtracting the start time from the current time. This tells us how long the player has been in the maze. It's a way of measuring the duration of the player's journey. ⌚🏃‍♂️
            </li>

            <li>
                <strong>time_remaining = time_limit - time_elapsed</strong>: Finally, we determine how much time is left by subtracting the elapsed time from the total time limit. This gives the player an idea of the urgency – how much time they have left to complete the maze. It's like the countdown timer in a race, adding tension and excitement to the game! 🏁⏱️
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>pygame.time.get_ticks()</strong>: This function returns the number of milliseconds since Pygame was initialized, effectively acting as a game clock.
            </li>

            <li>
                <strong>time_elapsed = current_time - start_time</strong>: This calculation measures how much time has passed since the start of the game.
            </li>

            <li>
                <strong>time_remaining = time_limit - time_elapsed</strong>: This calculates how much time is left before the time limit is reached.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Time management in games can greatly influence the gameplay dynamics. A ticking clock can add pressure, challenge, and even a sense of accomplishment as players beat the clock! ⏳🎮
            </h3>
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 35: The Final Countdown – Handling the Time-Up Condition ⌛🚫
            </h2>

            <p>
            Just as reaching the end point means victory, running out of time represents a challenge unmet. In this step, we'll handle what happens when the player doesn't reach the end before time runs out. Let's add a crucial twist to our game! ⏰😟
            </p>

            <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />

            <p>
            What do these lines do? 🌟
            </p>

            <ol>
            <li>
                <strong>if time_remaining &lt;= 0:</strong>: This line checks if the remaining time has reached zero or gone below it. It's like the final buzzer in a timed sports match, signaling that time has run out. 🚨⏲️
            </li>

            <li>
                <strong>print("Time's up! You lost.")</strong>: If time is up, we print a message to the console to inform the player that they have lost the game due to running out of time. It's a direct way of communicating the result to the player. 💬😢
            </li>

            <li>
                <strong>running = False</strong>: Finally, by setting 'running' to 'False', we end the game loop. This stops the game, signifying that the challenge has ended without the player reaching the goal. It's the closing of the curtain on this attempt. 🛑🎭
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>if time_remaining &lt;= 0:</strong>: This conditional statement checks if the 'time_remaining' has reached or fallen below zero.
            </li>

            <li>
                <strong>print("Time's up! You lost.")</strong>: This prints a message to the console, providing feedback to the player about the game outcome.
            </li>

            <li>
                <strong>running = False</strong>: Changing the value of 'running' to 'False' stops the game loop and ends the game.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Implementing time constraints in games can create a sense of urgency and excitement. It encourages players to strategize and make quick decisions, enhancing the overall gameplay experience! ⏳🎲
            </h3>
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 36: Keeping an Eye on the Clock – Displaying Remaining Time ⏲️👀
            </h2>

            <p>
            As our players navigate the maze, it's important to keep them informed about how much time they have left. In this step, we'll display the remaining time on the screen, adding to the game's urgency and excitement. Let's keep the players updated! ⌛📺
            </p>

            <img src={thirtySix} className='demo__image' alt='img_thirtySix' />

            <p>
            What do these lines do? 🌟
            </p>

            <ol>
            <li>
                <strong>else:</strong>: This part of the code executes if the 'time_remaining' is still above zero, meaning the game is still on.
            </li>

            <li>
                <strong>time_text = f'Time Remaining: ...'</strong>: We create a string that shows the remaining time in seconds. The time is formatted nicely to show seconds and tenths of a second, making it easy and intuitive for players to read.
            </li>

            <li>
                <strong>font = pygame.font.Font(None, 30)</strong>: Here, we create a Pygame font object to define how our text will look. 'None' uses the default font, and '30' sets the font size.
            </li>

            <li>
                <strong>timer_surface = font.render(time_text, True, (255, 0, 0))</strong>: This line creates an image (surface) of our text. The text is rendered in red color (255, 0, 0) for high visibility.
            </li>

            <li>
                <strong>screen.blit(timer_surface, (10, 10))</strong>: Finally, we draw this text image on the screen at coordinates (10, 10). This places the timer near the top-left corner of the screen, where it's easily visible to the player.
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>f'Time Remaining: ...'</strong>: This is a formatted string (f-string) in Python, allowing us to incorporate variables directly within the string.
            </li>

            <li>
                <strong>pygame.font.Font(...)</strong>: This function in Pygame is used to create a font object for rendering text.
            </li>

            <li>
                <strong>font.render(...)</strong>: This method creates an image of the text string in the specified font and color.
            </li>

            <li>
                <strong>screen.blit(...)</strong>: This function is used to draw the text image on the game screen.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Displaying a timer or countdown in games can significantly enhance the sense of urgency and excitement. It keeps players aware and strategically engaged as they try to beat the clock! ⏳🎮
            </h3>
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 37: Refresh and Pace – Updating the Display and Regulating Frame Rate 🔄⏱️
            </h2>

            <p>
            A smooth gaming experience depends on regularly updating the game's visuals and maintaining a consistent frame rate. In this step, we'll refresh the game screen and introduce a slight delay to regulate how quickly the game updates. Let's fine-tune our game's rhythm! 🖥️🎶
            </p>

            <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />

            <p>
            What do these lines do? 🌟
            </p>

            <ol>
            <li>
                <strong>pygame.display.flip()</strong>: This line is crucial for making all our drawing visible on the screen. 'pygame.display.flip()' updates the entire surface of the display with everything we've drawn since the last flip. Without this, our updates (like player movement, timer, etc.) wouldn't be visible. It's like turning the page in an animated flipbook, revealing the next image. 📖🎞️
            </li>

            <li>
                <strong>pygame.time.delay(33)</strong>: After updating the display, we use 'pygame.time.delay(33)' to pause the game loop for 33 milliseconds. This delay helps in regulating the frame rate of the game, aiming for a smooth and consistent experience. At roughly 30 frames per second, it strikes a balance between being responsive and not overloading slower systems. It's the metronome setting the tempo for our game's action. 🎵⏳
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>pygame.display.flip()</strong>: This command updates the display to reflect any changes made to the screen's surface.
            </li>

            <li>
                <strong>pygame.time.delay(33)</strong>: This introduces a short pause in the game loop, effectively limiting the speed at which the game updates.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Maintaining a steady frame rate is key in game design. It ensures the game runs smoothly across different devices, providing a consistent experience for all players. 🌍💻
            </h3>
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 38: The Final Act – Exiting the Game Gracefully 🚪👋
            </h2>

            <p>
            Every game needs a proper way to conclude, and in this step, we'll ensure our maze game ends smoothly and cleanly. Let's wrap up our game with the care it deserves! 🌟🛑
            </p>

            <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />

            <p>
            What do these lines do? 🌟
            </p>

            <ol>
            <li>
                <strong>pygame.quit()</strong>: This line is crucial for ending the game properly. 'pygame.quit()' deactivates the Pygame library, closing any game windows and releasing resources. It's like saying goodbye and turning off the lights after a great party. It ensures that we leave everything tidy and clean, with no lingering processes or resources being used unnecessarily. 🧹💡
            </li>

            <li>
                <strong>sys.exit()</strong>: After shutting down Pygame, 'sys.exit()' is used to exit the program entirely. It's a standard way to stop the Python script. This ensures that the game ends without any errors or issues, providing a smooth exit back to the operating system or calling environment. It's the final curtain call of our game's performance. 🚪👏
            </li>
            </ol>

            <h3>
            Breaking Down the Syntax:
            </h3>

            <ul>
            <li>
                <strong>pygame.quit()</strong>: This function is called to cleanly deactivate all Pygame modules.
            </li>

            <li>
                <strong>sys.exit()</strong>: This function, part of Python's standard 'sys' module, is used to exit the program.
            </li>
            </ul>

            <h3>
            🎈 Did You Know?: Properly closing a game or application is an important aspect of software development. It prevents memory leaks and other issues, ensuring that the software remains efficient and reliable. 🌐🔒
            </h3>
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default Maze