import React, {useState} from 'react';
import one from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/1.png';
import two from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/2.png';
import three from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/3.png';
import four from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/4.png';
import five from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/5.png';
import six from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/6.png';
import seven from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/7.png';
import eight from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/8.png';
import nine from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/9.png';
import ten from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/10.png';
import eleven from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/11.png';
import twelve from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/12.png';
import thirteen from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/13.png';
import fourteen from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/14.png';
import fifteen from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/15.png';
import sixteen from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/16.png';
import seventeen from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/17.png';
import eighteen from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/18.png';
import nineteen from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/19.png';
import twenty from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/20.png';
import twentyOne from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/21.png';
import twentyTwo from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/22.png';
import twentyThree from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/23.png';
import twentyFour from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/24.png';
import twentyFive from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/25.png';
import twentySix from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/26.png';
import twentySeven from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/27.png';
import twentyEight from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/28.png';
import twentyNine from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/29.png';
import thirty from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/30.png';
import thirtyOne from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/31.png';
import thirtyTwo from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/32.png';
import thirtyThree from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/33.png';
import thirtyFour from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/34.png';
import thirtyFive from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/35.png';
import thirtySix from './fullstackImages/Disco-Dance-Color-Changer-IMAGES/36.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const ColorChanger = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 36;

  return (
    <div className='development__container'>

        <h1>
        🪩🕺💃 Disco Dance: Color Changer 🔴🟢🔵
        </h1>

        <h3 className='intro'>
        💃 Ready to boogie down with your browser? 🎉 Welcome to the grooviest tutorial on the web! Today, we’re going to create a 'Disco Dance Color Changer' that’s sure to light up your screen 🌈 and get your code dancing to the rhythm 🎶. So grab your virtual dancing shoes, and let’s get this disco party started! 🪩        
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>

          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>

        </div>





        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Setting Up The Dance Floor! 🕺🕺</h2>

            <p>
            Ever wondered how some coders are so good at what they do? It's because they practice their dance moves all day long! (And by dance moves we mean coding)... Let's get groovy and install the <strong>Disco Dance Color Changer</strong> project template and start dancing!
            </p>
            <img src={one} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                Install the <strong>DISCO-DANCE-COLOR-CHANGER-TEMPLATE</strong> and open it up in <em>Visual Studio Code.</em>
                </li>

                <li>
                Make sure that is was properly installed by examining the project directory and finding the following three files: <strong>app.js</strong>, <strong>index.html</strong>, and <strong>style.css</strong>.
                </li>
            </ol>
        </div>


        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Scope Out Your Moves 👀💃</h2>

            <p>
                Every good dancer knows how important it is to get familair with the stage before they get groovy. Examine you HTML file and pay close attention to the buttons in the <strong>button-container</strong> div, because these are the buttons that we will create JavaScript functions for to bust out the moves.
            </p>
            <img src={two} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                Open the <strong>index.html</strong> file and look for the buttons.
                </li>

                <li>
                    Think about what kind of JavaScript functions we will make for these buttons.
                </li>
            </ol>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 3: Hang the Disco Ball 🪩</h2>

            <p>
                Just like we need to hang the disco ball above our dance floor, we need to import our <strong>app.js</strong> (JavaScript file) in the HTML file to make sure that all of the colorful JavaScript code that we write takes effect in the HTML file!
            </p>
            <img src={three} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                At the bottom <strong>index.html</strong> file, right above the closing <strong>&lt;/body&gt;</strong> tag, write the following line of code to import the JavaScript file: <strong>&lt;script src="app.js"&gt;&lt;/script&gt;</strong>
                </li>
            </ol>
        </div>


        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: The First Function 🥇💛</h2>

            <p>
                The very first function that we are going make will be for the yellow button! The yellow button <em>(as you may have guessed)</em>, will turn the screen yellow. Make sure that you are in the JavaScript file for this step.
            </p>
            <img src={four} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Open the <strong>app.js</strong> file.
                </li>

                <li>
                    On line one, let the computer know that we are making a function by declaring it with the word <strong>function</strong>
                </li>

                <li>
                    Give the function the name <strong>turnYellow</strong>
                </li>

                <li>
                    Don't forget to add the parenthesis and curly brackets to contain the instructions for the function.
                </li>
            </ol>
        </div>


        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Yellow Days 🌻⭐</h2>

            <p>
                The color yellow is typically associated with happiness and sunshine. When I see the color yellow, I think of my pet duck Mr. MoMo. If you are ready to make the screen yellow, then look at the image below and try to understand what is happening here.
            </p>
            <img src={five} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Go into the function, this will only require one line of code to make the screen yellow.
                </li>

                <li>
                    Write the following line of JavaScript code: <strong>document.body.style.backgroundColor = "yellow"</strong>
                </li>

                <li>
                    Let's break it down🕺
                    <ul>
                        <li>
                            <strong>document</strong> refers to the HTML file.
                        </li>
                        
                        <li>
                            <strong>body</strong> is the <strong>body</strong> tag in the html file (it contains the whole website).
                        </li>

                        <li>
                            <strong>style</strong> indicates that we are about to change a css property from our JavaScript code.
                        </li>

                        <li>
                            <strong>backgroundColor</strong> is the specific CSS property that we will be changing.
                        </li>

                        <li>
                            <strong>= "yellow"</strong> changes the backgroundColor of the website to yellow.
                        </li>
                    </ul>
                </li>
            </ol>
        </div>


        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Go Yellow 🟨</h2>

            <p>
                The function that we just created is only one line of code long, but alas; we must use the function! Remember using a function is known as "calling the function", like <em>RING, RING YOU THERE JAVASCRIPT???</em>
            </p>
            <img src={six} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Naivgate back to the <strong>index.html</strong> file.
                </li>

                <li>
                    Locate the <strong>Yellow</strong> button. This button has a <strong>class</strong> attribute of <strong>color-yellow</strong>.
                </li>

                <li>
                    Add an <strong>onclick</strong> attribute to the button.
                </li>

                <li>
                    When the user clicks the button call the <strong>turnYellow</strong> function.
                </li>
            </ol>
        </div>



        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Go Green 💚</h2>

            <p>
                From what I remember🧠, yellow is <em>NOT</em> the only color in a disco dance party. We need more colors... next up... is green. Let's turn back to our JavaScript file and follow the same pattern we did for yellow, but this time, it's green!
            </p>
            <img src={seven} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Naivgate back to the <strong>app.js</strong> file.
                </li>

                <li>
                    Go under the <strong>turnYellow</strong> function and begin coding the next function.
                </li>

                <li>
                    Remember to create a function, you must first "declare" it by writing the word <strong>function</strong>.
                </li>

                <li>
                    After you declare this function give it the name <strong>turnGreen</strong>, followed by its own parenthesis and curly brackets.
                </li>
            </ol>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Green Functions Rock 🪨🍏</h2>

            <p>
                Changing the background color of an HTML file from a JavaScript file is the same every single time. Use the same structure of code that you did in the <strong>turnYellow</strong> function, but set the color value to <strong>"green"</strong> instead.
            </p>
            <img src={eight} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Go into the <strong>turnGreen</strong> function, between the curly brackets and write the following line of JavaScript: <strong>document.body.style.backgroundColor = "green"</strong>
                </li>
            </ol>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Calling on Green 📞🥬</h2>

            <p>
                Every function needs to be "called" when we want to use it, even the green ones! Go back to the HTML file and locate the green button and call the <strong>turnGreen</strong> function when someone clicks the button.
            </p>
            <img src={nine} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Navigate to the <strong>index.html</strong> file.
                </li>

                <li>
                    Use an <strong>onclick</strong> command to run the <strong>turnGreen</strong> function.
                </li>
            </ol>
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Blue Skies 🟦🏙️</h2>

            <p>
            Have you ever looked up in admiration at how blue the sky is? Well it was made that way! And we are going to make our website that way in this step. Get ready lift off, because there are clear skies ahead🚀
            </p>
            <img src={ten} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Under the <strong>turnGreen</strong> function, create the <strong>turnBlue</strong> function with the same protocol as our other color functions.
                </li>

                <li>
                    Declare the function with the word <strong>function</strong> to tell the computer that we are making a function.
                </li>

                <li>
                    Assign the name <strong>turnBlue</strong> to the function, <em>like we are naming our new puppy</em>🐶
                </li>

                <li>
                    And never forget to add the parenthesis and curly brackets for proper syntax.
                </li>

                <li>
                    Inside of the function write the following line of JavaScript code: <strong>document.body.style.backgroundColor = "Blue"</strong>
                    <ul>
                        <li>The first part indicating what it is we are changing the color of.</li>

                        <li>The second part indicating what color we are changing it to.</li>
                    </ul>
                </li>
            </ol>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: Turn the Lights On 💡</h2>

            <p>
                When the user clicks the <strong>blue</strong> button we want the screen to turn blue, just how the sun rises every morning and paints the horizon blue! We are going to "call" the function like how we always do📞
            </p>
            <img src={eleven} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Navigate to the <strong>index.html</strong> file.
                </li>

                <li>
                    Find the <strong>blue</strong> button that is a <strong>div</strong> with a <strong>class</strong> of <strong>color-blue</strong>.
                </li>

                <li>
                    Give it an <strong>onclick</strong> attribute where we will call the <strong>turnBlue</strong> function when a user clicks the button.
                </li>
            </ol>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Purple Grapes 🍇🍇</h2>

            <p>
                If you like grapes🍇, then you will fall in love with this step❤️. Because we are coloring the screen purple with the <em>PURPLE BUTTON!</em> And if you don't like grapes then we can theme this with purple flowers instead 🪻
            </p>
            <img src={twelve} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Navigate to the <strong>app.js</strong> file.
                </li>

                <li>
                    Create the <strong>turnPurple</strong> function under the <strong>turnBlue</strong> function that we did in the last step.
                </li>

                <li>
                    After you set up the function, then write the following line of code in the function to turn the screen purple: <strong>document.body.style.backgroundColor = "purple"</strong>
                </li>
            </ol>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: Purple Punch 🍇👊💥</h2>

            <p>
                When the user sees the purple button they are going to smash that button👊 <em>BECAUSE WHO DOESN'T LIKE PURPLE?!</em> and after they click the button the computer will do some super thinking and give your function a call☎️
            </p>
            <img src={thirteen} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Journey into the <strong>index.html</strong> file.
                </li>

                <li>
                    Seek and destroy the purple button... but don't destroy it, just seek it.
                </li>

                <li>
                    Once you find the purple button create the <strong>onclick</strong> attribute and call the <strong>turnPurple</strong> function.
                </li>
            </ol>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: Anger Management Classes🟥🐂</h2>

            <p>
                Everyone knows that when a bull sees the color red it gets angry... really angry... (don't ask me how I know). For this step we will be turning the screen red and hope that none of our users on the website are wild bulls. Let's get coding:
            </p>
            <img src={fourteen} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Go back into the <strong>app.js</strong> file.
                </li>

                <li>
                    Declare the function with the word <strong>function</strong>.
                </li>

                <li>
                    Give the function the name <strong>turnRed</strong>.
                </li>

                <li>
                    Finish setting up the function with the parenthesis and curly brackets. 
                </li>

                <li>
                    Turn the screen red with the following line of JavaScript: <strong>document.body.style.backgroundColor = "red"</strong>.
                </li>
            </ol>
        </div>




        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15: Red Light 🚦🟥</h2>

            <p>
                Turn the screen red like a traffic light, by finding the red button and creating the function call within the onclick attribute.
            </p>
            <img src={fifteen} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Go back into the <strong>index.html</strong> file.
                </li>

                <li>
                    Locate the red button.
                </li>

                <li>
                    Add an <strong>onclick</strong> attribute.
                </li>

                <li>
                    Call the <strong>turnRed</strong> function.
                </li>
            </ol>
        </div>


        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 16: Go Gold Or Go Home 🏆🏡</h2>

            <p>
                Have you ever won first place at something? Did they give you a gold medal? In this step we are going to give our users a gold screen <strong>(they will feel like a winner)</strong>. 
            </p>
            <img src={sixteen} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Look back into the <strong>app.js</strong> file.
                </li>

                <li>
                    Create the <strong>turnGold</strong> function:
                    <ul>
                        <li>
                            Declare the function with the word <strong>function</strong>.
                        </li>

                        <li>
                            Name the function <strong>turnGold</strong>.
                        </li>

                        <li>
                            Include proper syntax of parenthesis and curly brackets.
                        </li>
                    </ul>
                </li>

                <li>
                    Inside of the function write the following line of JavaScript to turn the screen gold: <strong>document.body.style.backgroundColor = "gold"</strong>
                </li>
            </ol>
        </div>


        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 17: Time to Shine 👑</h2>

            <p>
                Remember all that talk about <em>going gold</em> in that last step?... <em>IT'S TIME FOR THAT.</em> Go back to the HTMl file and make it happen!
            </p>
            <img src={seventeen} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Go back into the <strong>index.html</strong> file.
                </li>

                <li>
                    Locate the gold button.
                </li>

                <li>
                    Add an <strong>onclick</strong> attribute.
                </li>

                <li>
                    Call the <strong>turnGold</strong> function.
                </li>
            </ol>
        </div>


        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 18: Lights Out ⬛</h2>

            <p>
                When the disco dance draws down and the lights go out, everything turns black. It is time to simmer down the dance party and shut the lights off when the dance floor is empty. Let's get started with the <strong>turnBlack</strong> function.
            </p>
            <img src={eighteen} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Navigate to the <strong>app.js</strong> file.
                </li>

                <li>
                    Underneath your last function start coding the next one, <strong>turnBlack</strong>.
                </li>

                <li>
                    Declare the function to let the computer know that we are creating a function. Declare the function by writting the word <strong>function</strong>.
                </li>

                <li>
                    Name the function <strong>turnBlack</strong> to let everyone no that this is no ordinary color function, <em>it's lights our for the night!</em>
                </li>

                <li>
                    Add the parenthesis and curly brackets to contain our JavaScript.
                </li>

                <li>
                    Write the following JavaScript to aim at the body of the website and turn it to the color black: <strong>document.body.style.backgroundColor = "black"</strong>
                </li>
            </ol>
        </div>


        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 19: The Last Color Button 🖱️</h2>

            <p>
                Ready to wrap up this part of the project? Finish strong by connecting the <strong>turnBlack</strong> function to its button that the user is to click when it's <em>lights out!</em>
            </p>
            <img src={nineteen} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Go back into the <strong>index.html</strong> file.
                </li>

                <li>
                    Locate the black button.
                </li>

                <li>
                    Add an <strong>onclick</strong> attribute.
                </li>

                <li>
                    Call the <strong>turnBlack</strong> function.
                </li>
            </ol>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 20: Rewind the Dance Floor 🔙💃</h2>

            <p>
                After all of these crazy color buttons, what happens if a user wants to reset their screen. Say they turn the screen to the color red and it makes them very angry... what then? Well, we need a JavaScript function to reset the color of the screen back to its default.
            </p>
            <img src={twenty} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Let's go into the <strong>app.js</strong> file.
                </li>

                <li>
                    At the bottom of the script decalre a new function and name it <strong>resetColor</strong>.
                </li>
            </ol>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 21: What's the default color? 🤍</h2>

            <p>
                If you recall, when you started this project and opened up the HTML file in your browser... what was the default color? <em>It was white!</em> With that in mind, we will turn the screen back to white when a user smashes that reset color button!            
            </p>
            <img src={twentyOne} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Inside of your <strong>resetColor</strong> function write the following line of JavaScript code: <strong>document.body.style.backgroundColor = "white"</strong>
                </li>

                <li>
                    And this will turn the body of the website back to white when this function runs, but we are not done yet because we need to call this function when a user click the button!
                </li>
            </ol>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 22: Locate the Reset Button 🎯</h2>

            <p>
                The reset button is in a different div than all of the color buttons we were previously working with. It should be located in a div with a class of <strong>button-container</strong> find the reset button and use the image below to assist you if necessary.
            </p>
            <img src={twentyTwo} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Navigate to the <strong>index.html</strong> file.
                </li>

                <li>
                    Find the <strong>div</strong> with a class of <strong>button-container</strong>.
                </li>

                <li>
                    In this container, location the <strong>reset button</strong>
                </li>

                <li>
                    Inside of the <strong>reset button</strong> add an <strong>onclick</strong> attribute.
                </li>

                <li>
                    When a user clicks the <strong>reset button</strong> call the <strong>resetColor</strong> function from the <strong>onclick</strong> attribute.
                </li>
            </ol>
        </div>



        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 23: Random Colors 🟢🔵🔴🟢🔵</h2>

            <p>
                If you were looking closely, right beside the <strong>reset button</strong> that you worked on in the last step there is a <strong>random color button</strong> that needs it's function. In this step we will be making the <strong>randomColor</strong> function.
            </p>
            <img src={twentyThree} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Back in the <strong>app.js</strong> file, find some space to code.
                </li>

                <li>
                    Start by declaring the <strong>function</strong>.
                </li>

                <li>
                    Give the function the name <strong>randomColor</strong>.
                </li>

                <li>
                    Finish up this step with the parenthesis and curly brackets for the function.
                </li>
            </ol>
        </div>


        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 24: Color Array 📋</h2>

            <p>
                This function is going to pick a random color and turn the screen to that color. This is a long thought out process that will require some new concepts. In this function you will learn about arrays, math.random, and indexing. But for this specific step we will talk about arrays.
            </p>
            <img src={twentyFour} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    What is an array?
                    <ul>
                        <li>
                            An array is just like a variable.
                        </li>

                        <li>
                            Look at the image above, there is an array named <strong>colors</strong>
                        </li>

                        <li>
                            We need to declare and name the array, in this case it is named <strong>colors</strong>
                        </li>

                        <li>
                            But <em>unlike</em> a variable, an array contains multiple values.
                        </li>

                        <li>
                            In this case, the array contains five different colors. But arrays can contain as many values as you want.
                        </li>

                        <li>
                            Arrays will always be made with a pair of square brackets <strong>[]</strong> to contain the list of values.
                        </li>
                    </ul>
                </li>

                <li>
                    Make the <strong>colors</strong> array in your script with the five following colors: red, blue, green, yellow, purple.
                </li>
            </ol>
        </div>


        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 25: Measure Up 📏👆</h2>

            <p>
                Did you know that you can get the lengh of an array in JavaScript? JavaScript is super cool like that😎 But you may be thinking why we would do that when we can just look at the array and see that there are five colors.... Well if the length of the array ever changes the function needs to keep track of that or if there are so many colors that we don't have time to count them then it would help then too. In this step we will create a variable to find out how many colors there are before we pick a random one!
            </p>
            <img src={twentyFive} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Create a variable named <strong>amountOfColors</strong>. It will be equal to how many colors are in the array.
                </li>

                <li>
                    To get the amount of items in an array all you have to do is type in the name of that array, in this case <strong>colors</strong>, and then write <strong>.length</strong> right after it.
                </li>

                <li>
                    We will end up with the following line of code: <strong>const amountOfColors = colors.length</strong>
                </li>

                <li>
                    Right now the <strong>amountOfColors</strong> variable is equal to <strong>5</strong>, the length of the <strong>colors</strong> array.
                </li>
            </ol>
        </div>


        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 26: Getting the Random number 🎲</h2>

            <p>
                This step is where things start to get a little crazy🫨 Getting a random number is no small feat, but there is no mountain that you can't climb⛰️ In this step you will learn how to get a random number!
            </p>
            <img src={twentySix} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    We are getting a random number in this step because the number that we get corresponds to the color in the array. If the random number is 3 then the selected random color would be the thrid one (green).
                </li>

                <li>
                    Start by creating the <strong>randomNumber</strong> variable to store the value.
                </li>

                <li>
                    That value will be the following line of code: <strong>Math.Floor(Math.random() * amountOfColors)</strong>, let's break this down.
                    <ul>
                        <li>
                        <strong>Math.random()</strong> gives us a random decimal between 0 and 1. For example, let's say it gives us 0.25.
                        </li>

                        <li>
                        We multiply 0.25 by <strong>amountOfColors</strong>. If <strong>amountOfColors</strong> is 5, then 0.25 * 5 = 1.25.
                        </li>

                        <li>
                        <strong>Math.floor()</strong> takes 1.25 and rounds it down to the nearest whole number. So, 1.25 becomes 1.
                        </li>

                        <li>
                        The final result is 1, which corresponds to the second color in the list (since arrays start at index 0).
                        </li>
                        
                    </ul>
                </li>
            </ol>
        </div>



        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 27: Indexing 📏</h2>

            <p>
                Do you know what indexing is? Indexing is the process of taking an array and calling out a specific value. We always index with a set of square brackets <strong>[]</strong>. <em>Example:</em> the array is <strong>colors</strong> and we want the thrid color (green). So what we would do is write <strong>colors[2]</strong> this would point to green because we start counting at zero in an array. If you look at the array in the image down below see that red is 0, blue is 1, green is 2, yellow is 3, and purple is 4.
            </p>
            <img src={twentySeven} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    To get the randomly choosen color we are going to gets the colors that corresponds to the random number that we got from the last step.
                </li>

                <li>
                    Start by creating the <strong>randomColor</strong> variable.
                </li>

                <li>
                    Give it a value of <strong>colors[randomNumber]</strong> this will get the index of the random number. <strong>For example:</strong> the randomNumber is 3 then the <strong>randomColor</strong> will be yellow.
                </li>
            </ol>
        </div>


        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 28: Reflect the Color 🪞🪩</h2>

            <p>
                In our last step we picked a random color from the colors array by getting a randomNumber and indexing the array. In this step we will take that choosen color and set is to the HTML, thus updating the screen color!
            </p>
            <img src={twentyEight} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Underneath where we choose our random color, write the following line of code: <strong>document.body.style.backgroundColor = randomColor</strong>
                </li>

                <li>
                    This will update the background color of the site with the <strong>randomColor</strong> variable.
                </li>
            </ol>
        </div>


        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 29: Activate Randomness 💥🎲</h2>

            <p>
                Now that our <strong>randomColor</strong> function is complete... everything is going according to our master plan. But we must coninue coding... Let's continue by locating the <strong>Random Background Color</strong> button and connecting it with the function.
            </p>
            <img src={twentyNine} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                Open the <strong>index.html</strong> file.
                </li>

                <li>
                    Find the <strong>button-container</strong> div containing the main buttons.
                </li>

                <li>
                    In this div, locate the <strong>Random Background Color</strong> button.
                </li>

                <li>
                    Add an <strong>onclick</strong> attribute with the <strong>randomColor</strong> function so that when a user clicks this button the <strong>randomColor</strong> function executes and changes their screen to a random color.... <em>DISCO TIME!!</em>🪩
                </li>
            </ol>
        </div>


        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 30: Disco Frenzy 💥🪩</h2>

            <p>
                What's a dance party without a dance floor? In this step we are going to start making a disco function that rapidly changes the color of the users screen to create a "dance floor" like effect.
            </p>
            <img src={thirty} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                Navigate to the <strong>app.js</strong> file to complete this step.
                </li>

                <li>
                    Create a variable as follows: <strong>let discoInterval</strong>
                </li>

                <li>
                    Notice that this variable does not have a value, for now we are leaving it as <strong>undefined</strong> which means it is not equal to anything yet. We are doing this because we are going to assign the value later.
                </li>

                <li>
                    Also notice that this variable was declared with <strong>let</strong> instead of <strong>const</strong>. Both work to declare a variable but the difference is that when you declare a variable with <strong>const</strong> you can not change the value of the variable later on, but when you declare a variable with <strong>let</strong> you can in fact change the variable later.
                </li>

                <li>
                    Declare a function named <strong>startDisco</strong> and be sure to include proper syntax.
                </li>
            </ol>
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 31: Dance Floor Colors 🌻</h2>

            <p>
                In this step we are going to make the variables that will control the spectrum of colors that are flashed on the screen. The two are seen below.
            </p>
            <img src={thirtyOne} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    In the <strong>startDisco</strong> function create the following variable: <strong>let red = 0;</strong>
                </li>

                <li>
                    In the <strong>startDisco</strong> function create the following variable: <strong>let blue = 255;</strong>
                </li>
            </ol>
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 32: Creating the Interval ⏲️</h2>

            <p>
                The following code we are about to write is a sequence that scales the spectrum of red-blue up and down and applies this sequence into the html file, thus making the flashing disco sequence... <em>time to get groovy!🕺</em>
            </p>
            <img src={thirtyTwo} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Follow along with the code in the image above.
                </li>

                <li>
                <strong>setInterval</strong> is a built-in JavaScript function that repeatedly calls a function or executes a code snippet with a fixed time delay between each call.
                </li>

                <li>
                <strong>discoInterval</strong> is a variable that stores the reference to the interval. This will be useful if you want to stop the interval later using <strong>clearInterval(discoInterval)</strong>.
                </li>

                <li>
                RGB (Red, Green, Blue) is a color model used in web design, where colors are defined by specifying the intensity of these three colors. Each component can have a value between 0 and 255.
                </li>
            </ol>
        </div>


        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 33: Time to Start 🎠</h2>

            <p>
                Now that you finished up the <strong>startDisco</strong> function, let's put it into action with an <strong>onclick</strong> attribute in its button to start the color sequence!
            </p>
            <img src={thirtyThree} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Navigate to the <strong>index.html</strong> file.
                </li>

                <li>
                    Find the <strong>Disco Dance</strong> button.
                </li>

                <li>
                    Add an <strong>onclick</strong> attribute into the button.
                </li>

                <li>
                    Add the <strong>startDisco</strong> function call into the <strong>onclick</strong> attribute.
                </li>
            </ol>
        </div>


        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 34: Time to Stop 🛑</h2>

            <p>
                Let's stop this color sequence with a short but sweet🍬 <strong>stopDisco</strong> function, the counterpart to the <strong>startDisco</strong> function.
            </p>
            <img src={thirtyFour} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                    Go to the <strong>app.js</strong> file.
                </li>

                <li>
                    Declare a function with the <strong>function</strong> keyword and name it <strong>stopDisco</strong>.
                </li>

                <li>
                    Write the following line of code in the function: <strong>clearInterval(discoInterval)</strong>
                    <ul>
                        <li>This will stop the color flashing.</li>
                    </ul>
                </li>

                <li>
                    Write the following line of code in the function: <strong>resetColor()</strong>
                    <ul>
                        <li>This will reset the color back to white after the flashing stops.</li>
                    </ul>
                </li>
            </ol>
        </div>




        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 35: Hit the Brakes 🚗💥</h2>

            <p>
                Time to hit the brakes💥, stop the disco in a flash by finding the <strong>Stop Disco</strong> button and connecting it to its corresponding function!
            </p>
            <img src={thirtyFive} className='demo__image' alt='img_one' />
            
            <h3>Here’s what you need to do:</h3>

            <ol>
                <li>
                Navigate back into the <strong>index.html</strong> file.
                </li>

                <li>
                    Locate the <strong>Stop Disco</strong> button.
                </li>

                <li>
                    Add the following line of code into the button: <strong>onclick="stopDisco()"</strong>
                </li>
            </ol>
        </div>


        
        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 36: You Won the Dance Contest 🕺🏅</h2>

            <p>
                Congradulations🏅 You <em>DANCED AND DANCED AND DANCED</em> your way to success! Share your project with a friend or keep coding this project and see how much more you can <em>DANCE.</em> This project is all done and should look something like the picture down below📸
            </p>
            <img src={thirtySix} className='demo__image' alt='img_one' />
            
        </div>




        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default ColorChanger