import React, {useState} from 'react';
import one from './robloxImages/CHEESE-CHASE/1.png';
import two from './robloxImages/CHEESE-CHASE/2.png';
import three from './robloxImages/CHEESE-CHASE/3.png';
import four from './robloxImages/CHEESE-CHASE/4.png';
import five from './robloxImages/CHEESE-CHASE/5.png';
import six from './robloxImages/CHEESE-CHASE/6.png';
import seven from './robloxImages/CHEESE-CHASE/7.png';
import eight from './robloxImages/CHEESE-CHASE/8.png';
import nine from './robloxImages/CHEESE-CHASE/9.png';
import ten from './robloxImages/CHEESE-CHASE/10.png';
import eleven from './robloxImages/CHEESE-CHASE/11.png';
import twelve from './robloxImages/CHEESE-CHASE/12.png';
import thirteen from './robloxImages/CHEESE-CHASE/13.png';
import fourteen from './robloxImages/CHEESE-CHASE/14.png';
import fifteen from './robloxImages/CHEESE-CHASE/15.png';
import sixteen from './robloxImages/CHEESE-CHASE/16.png';
import seventeen from './robloxImages/CHEESE-CHASE/17.png';
import eighteen from './robloxImages/CHEESE-CHASE/18.png';
import nineteen from './robloxImages/CHEESE-CHASE/19.png';
import twenty from './robloxImages/CHEESE-CHASE/20.png';
import twentyOne from './robloxImages/CHEESE-CHASE/21.png';
import twentyTwo from './robloxImages/CHEESE-CHASE/22.png';
import twentyThree from './robloxImages/CHEESE-CHASE/23.png';
import twentyFour from './robloxImages/CHEESE-CHASE/24.png';
import twentyFive from './robloxImages/CHEESE-CHASE/25.png';
import twentySix from './robloxImages/CHEESE-CHASE/26.png';
import twentySeven from './robloxImages/CHEESE-CHASE/27.png';
import twentyEight from './robloxImages/CHEESE-CHASE/28.png';
import twentyNine from './robloxImages/CHEESE-CHASE/29.png';
import thirty from './robloxImages/CHEESE-CHASE/30.png';
import thirtyOne from './robloxImages/CHEESE-CHASE/31.png';
import thirtyTwo from './robloxImages/CHEESE-CHASE/32.png';
import thirtyThree from './robloxImages/CHEESE-CHASE/33.png';
import thirtyFour from './robloxImages/CHEESE-CHASE/34.png';
import thirtyFive from './robloxImages/CHEESE-CHASE/35.png';
import thirtySix from './robloxImages/CHEESE-CHASE/36.png';
import thirtySeven from './robloxImages/CHEESE-CHASE/37.png';
import thirtyEight from './robloxImages/CHEESE-CHASE/38.png';
import thirtyNine from './robloxImages/CHEESE-CHASE/39.png';
import forty from './robloxImages/CHEESE-CHASE/40.png';
import fortyOne from './robloxImages/CHEESE-CHASE/41.png';
import fortyTwo from './robloxImages/CHEESE-CHASE/42.png';
import fortyThree from './robloxImages/CHEESE-CHASE/43.png';
import fortyFour from './robloxImages/CHEESE-CHASE/44.png';
import fortyFive from './robloxImages/CHEESE-CHASE/45.png';
import fortySix from './robloxImages/CHEESE-CHASE/46.png';
import fortySeven from './robloxImages/CHEESE-CHASE/47.png';
import fortyEight from './robloxImages/CHEESE-CHASE/48.png';
import fortyNine from './robloxImages/CHEESE-CHASE/49.png';
import fifty from './robloxImages/CHEESE-CHASE/50.png';
import fiftyOne from './robloxImages/CHEESE-CHASE/51.png';
import fiftyTwo from './robloxImages/CHEESE-CHASE/52.png';
import fiftyThree from './robloxImages/CHEESE-CHASE/53.png';
import fiftyFour from './robloxImages/CHEESE-CHASE/54.png';
import fiftyFive from './robloxImages/CHEESE-CHASE/55.png';
import fiftySix from './robloxImages/CHEESE-CHASE/56.png';
import fiftySeven from './robloxImages/CHEESE-CHASE/57.png';
import fiftyEight from './robloxImages/CHEESE-CHASE/58.png';
import fiftyNine from './robloxImages/CHEESE-CHASE/59.png';
import sixty from './robloxImages/CHEESE-CHASE/60.png';
import sixtyOne from './robloxImages/CHEESE-CHASE/61.png';
import sixtyTwo from './robloxImages/CHEESE-CHASE/62.png';
import sixtyThree from './robloxImages/CHEESE-CHASE/63.png';
import sixtyFour from './robloxImages/CHEESE-CHASE/64.png';
import sixtyFive from './robloxImages/CHEESE-CHASE/65.png';
import sixtySix from './robloxImages/CHEESE-CHASE/66.png';
import sixtySeven from './robloxImages/CHEESE-CHASE/67.png';
import sixtyEight from './robloxImages/CHEESE-CHASE/68.png';
import sixtyNine from './robloxImages/CHEESE-CHASE/69.png';
import seventy from './robloxImages/CHEESE-CHASE/70.png';
import seventyOne from './robloxImages/CHEESE-CHASE/71.png';
import seventyTwo from './robloxImages/CHEESE-CHASE/72.png';
import seventyThree from './robloxImages/CHEESE-CHASE/73.png';
import seventyFour from './robloxImages/CHEESE-CHASE/74.png';
import seventyFive from './robloxImages/CHEESE-CHASE/75.png';
import seventySix from './robloxImages/CHEESE-CHASE/76.png';
import seventySeven from './robloxImages/CHEESE-CHASE/77.png';
import seventyEight from './robloxImages/CHEESE-CHASE/78.png';
import seventyNine from './robloxImages/CHEESE-CHASE/79.png';
import eighty from './robloxImages/CHEESE-CHASE/80.png';
import eightyOne from './robloxImages/CHEESE-CHASE/81.png';
import eightyTwo from './robloxImages/CHEESE-CHASE/82.png';
import eightyThree from './robloxImages/CHEESE-CHASE/83.png';
import eightyFour from './robloxImages/CHEESE-CHASE/84.png';
import eightyFive from './robloxImages/CHEESE-CHASE/85.png';
import eightySix from './robloxImages/CHEESE-CHASE/86.png';
import eightySeven from './robloxImages/CHEESE-CHASE/87.png';
import eightyEight from './robloxImages/CHEESE-CHASE/88.png';
import eightyNine from './robloxImages/CHEESE-CHASE/89.png';
import ninety from './robloxImages/CHEESE-CHASE/90.png';
import ninetyOne from './robloxImages/CHEESE-CHASE/91.png';
import ninetyTwo from './robloxImages/CHEESE-CHASE/92.png';
import ninetyThree from './robloxImages/CHEESE-CHASE/93.png';
import ninetyFour from './robloxImages/CHEESE-CHASE/94.png';
import ninetyFive from './robloxImages/CHEESE-CHASE/95.png';
import ninetySix from './robloxImages/CHEESE-CHASE/96.png';
import ninetySeven from './robloxImages/CHEESE-CHASE/97.png';
import ninetyEight from './robloxImages/CHEESE-CHASE/98.png';
import ninetyNine from './robloxImages/CHEESE-CHASE/99.png';
import hundred from './robloxImages/CHEESE-CHASE/100.png';
import hundredOne from './robloxImages/CHEESE-CHASE/101.png';
import hundredTwo from './robloxImages/CHEESE-CHASE/102.png';
import hundredThree from './robloxImages/CHEESE-CHASE/103.png';
import hundredFour from './robloxImages/CHEESE-CHASE/104.png';
import hundredFive from './robloxImages/CHEESE-CHASE/105.png';
import hundredSix from './robloxImages/CHEESE-CHASE/106.png';
import hundredSeven from './robloxImages/CHEESE-CHASE/107.png';
import hundredEight from './robloxImages/CHEESE-CHASE/108.png';
import hundredNine from './robloxImages/CHEESE-CHASE/109.png';
import hundredTen from './robloxImages/CHEESE-CHASE/110.png';
import hundredEleven from './robloxImages/CHEESE-CHASE/111.png';
import hundredTwelve from './robloxImages/CHEESE-CHASE/112.png';
import hundredThirteen from './robloxImages/CHEESE-CHASE/113.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";


const Cheese = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 115;

  return (
    <div className='robloxGames__container'>
        <h1>
        🌟 Cheese Chase: Factory Adventure; have fun playing as a mouse as you navigate the factory to collect as much cheese as possible and evade the angry gaurd cat! 🌟
        </h1>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>














        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Choosing Your Template 🌟</h2>
            <ul>
                <li>
                Open Roblox Studio from your desktop or Start menu🖱️
                </li>
                <li>
                Sign in with your Roblox account or create a new one🔑
                </li>
                <li>
                Click "New" to create a new project and select "Classic Baseplate" as the template🗺️<br/><br/>(By doing this, you're starting a fresh new game project with a simple baseplate to build your obby on.🛠️)
                </li>
            </ul>

            <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Add Your First Object 📦</h2>
            <ul>
                <li>
                In your workspace, you'll notice a vast, empty baseplate. It's time to add something to it!
                </li>

                <li>
                Click on the "Part" button in the Home tab toolbar. This will drop a new block, known as a "part," onto your baseplate.
                </li>

                <li>
                You'll see the new part appear in the Explorer panel under Workspace, labeled as "Part".
                </li>
            </ul>

            <img src={two} className='demo__image' alt='img_two' />
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 3: Scale Your Object 📏</h2>
            <ul>
                <li>
                With the new part selected, switch to the "Scale" tool in the toolbar to change the size of your part.
                </li>

                <li>
                Drag the handles that appear on the part to resize it to your desired dimensions.
                </li>

                <li>
                Once you've resized it, the part will be named "Floor" in the Explorer panel, indicating that this part could serve as the ground for your game.
                </li>
            </ul>

            <img src={three} className='demo__image' alt='img_three' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 4: Create the CheeseSpawner 🧀🛠️</h2>
            <ul>
                <li>
                With your "Floor" part in place, it's time to create a special part that will function as your "CheeseSpawner".
                </li>

                <li>
                Click on the "Part" button again to add a new block into your world.
                </li>

                <li>
                Select this new part and change its name to "CheeseSpawner" in the Explorer panel to help identify it as the source of your cheese blocks that players will try to collect.
                </li>

                <li>
                Make sure to color it red and anchor it.
                </li>
            </ul>

            <img src={four} className='demo__image' alt='img_four' />
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 5: Prepare the CheeseSpawner for Action 🏗️🧀</h2>
            <ul>
                <li>
                In the Properties panel, under the "Collision" section, make sure the "CanCollide" option is unchecked. This is important as it allows the spawned cheese blocks to drop through the spawner without getting stuck.
                </li>
            </ul>

            <img src={five} className='demo__image' alt='img_five' />
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 6: Customize the CheeseSpawner's Material 🌟</h2>
            <ul>
                <li>
                Now that we have our "CheeseSpawner" part created, let's make it stand out.
                </li>

                <li>
                Select the "CheeseSpawner" and open the Material Manager from the toolbar.
                </li>

                <li>
                Choose a material that resonates with the theme of your game. For example, selecting "Force Field" gives it a high-tech or futuristic feel, which might suggest that this spawner uses advanced technology to create cheese. This visual cue helps players understand the nature of the spawner without any explanation needed.
                </li>
            </ul>

            <img src={six} className='demo__image' alt='img_six' />
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 7: Add a Script to the CheeseSpawner 📜🔧</h2>
            <ul>
                <li>
                To bring the "CheeseSpawner" to life, it's essential to add a script. This script will control how and when the cheese blocks are spawned.
                </li>

                <li>
                Click the "+" on CheeseSpawner to add the script.
                </li>
            </ul>

            <img src={seven} className='demo__image' alt='img_seven' />
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 8: Start Scripting the CheeseSpawner 🧀🖥️</h2>
            <ul>
                <li>
                With the script added, let's start coding. Open the script by double-clicking on it in the Explorer panel.
                </li>

                <li>
                .Parent is a property of the script object that refers to its parent object in the hierarchy, which in this case, is our "CheeseSpawner" part.
                </li>

                <li>
                This line of code is crucial because it allows us to reference the "CheeseSpawner" within the script easily, which we will need for spawning the cheese blocks.
                </li>
            </ul>

            <img src={eight} className='demo__image' alt='img_eight' />
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 9: Set the Spawn Interval 🕒</h2>
            <ul>
                <li>
                After setting up your script with the parentBlock reference, it's time to define how often the cheese will spawn.
                </li>

                <li>
                Add local spawnInterval = 1 to your script. This line sets a variable spawnInterval with a value of 1, which will be used as the time interval (in seconds) between each cheese block spawn.
                </li>
            </ul>

            <img src={nine} className='demo__image' alt='img_nine' />
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 10: Establish the Spawning Loop ♾️</h2>
            <ul>
                <li>
                The heart of your spawner is a loop that continuously runs.
                </li>

                <li>
                Write while true do to start an infinite loop. The while true construct is a common way to create a loop that will run indefinitely.
                </li>

                <li>
                This loop will keep running the code inside it, which will be responsible for creating the cheese blocks.
                </li>
            </ul>

            <img src={ten} className='demo__image' alt='img_ten' />
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 11: Add a Wait and Create a Block 🧀</h2>
            <ul>
                <li>
                Inside the loop, the first thing to do is to make the script wait for the specified spawnInterval using wait(spawnInterval). This pauses the loop, effectively creating a delay between each iteration, hence controlling how often the cheese blocks are spawned.
                </li>

                <li>
                Then, create a new cheese block with local cheeseBlock = Instance.new("Part"). This line creates a new Part instance, which will be our cheese block.
                </li>
            </ul>

            <img src={eleven} className='demo__image' alt='img_eleven' />
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 12: Configure the Cheese Block Properties 🧀🔧</h2>
            <ul>
                <li>
                Once the cheese block is created, it’s time to give it characteristics.
                </li>

                <li>
                Set the name of the block to "CheeseBlock" with cheeseBlock.Name = "CheeseBlock". This helps identify the spawned blocks during the game and in the script.
                </li>

                <li>
                Adjust the size to be appropriate for a block of cheese using cheeseBlock.Size = Vector3.new(2, 0.5, 2). The Vector3.new function creates a three-dimensional vector, which is used here to set the dimensions of the block (width, height, depth).
                </li>

                <li>
                Give it a cheese-like color with cheeseBlock.BrickColor = BrickColor.new("New Yeller"). The BrickColor.new function allows us to assign a color to the block by name, making it look like cheese.
                </li>

                <li>
                Set the position of the cheese block to be where the "CheeseSpawner" is located with cheeseBlock.Position = parentBlock.Position. This ensures that the cheese blocks will appear at the spawner's position in the game world.
                </li>
            </ul>

            <img src={twelve} className='demo__image' alt='img_twelve' />
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 13: Ensure Cheese Blocks Fall 🧀➡️📉</h2>
            <ul>
                <li>
                To ensure that the cheese blocks fall after being spawned, set their Anchored property to false. Anchored parts do not move, while unanchored parts are subject to physics.
                </li>

                <li>
                Then, you must assign the cheese block to the game's workspace for it to appear in the game world by setting cheeseBlock.Parent to parentBlock.Parent. This line places the cheese block in the same environment as the spawner, making it visible and interactive in the game.
                </li>
            </ul>

            <img src={thirteen} className='demo__image' alt='img_thirteen' />
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 14: Detect Player Interaction with Cheese 🎮🧀</h2>
            <ul>
                <li>
                Next, we'll add functionality to detect when a player touches the cheese block. This involves creating an event handler function, onTouched, that will execute code when the cheese block is touched.
                </li>

                <li>
                The line cheeseBlock.Touched:Connect(onTouched) creates a connection between the Touched event of cheeseBlock and the onTouched function, meaning onTouched will be called every time the cheese block is touched, so the player can collect the cheese.
                </li>
            </ul>

            <img src={fourteen} className='demo__image' alt='img_fourteen' />
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 15: Define Player Interaction Behavior 🤝🧀</h2>
            <ul>
                <li>
                Inside the onTouched function, we start with an if statement to check if the touching object is a player. We do this by checking if otherPart.Parent:FindFirstChildOfClass("Humanoid") is not nil, which indicates the presence of a Humanoid object typically found in player characters.
                </li>

                <li>
                When the if statement confirms a player has touched the cheese block, we can then program the desired interaction, such as increasing the player's score.
                </li>
            </ul>

            <img src={fifteen} className='demo__image' alt='img_fifteen' />
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 16: Identify the Player 🧑‍🚀</h2>
            <ul>
                <li>
                When a player’s character (which is composed of various parts including the humanoid) touches the cheese block, we first need to confirm which player it is.
                </li>

                <li>
                We use game.Players:GetPlayerFromCharacter(otherPart.Parent) to retrieve the player object. This is important because it allows us to interact with the player, such as changing their score or inventory.
                </li>
            </ul>

            <img src={sixteen} className='demo__image' alt='img_sixteen' />
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 17: Validate the Player Object 🕵️‍♂️</h2>
            <ul>
                <li>
                Once we have a potential player object, we need to check if the object actually is a player. This is a crucial check because many different things could touch the cheese block, not just players.
                </li>

                <li>
                The if player then check ensures that the subsequent code only runs if a valid player object was retrieved.
                </li>
            </ul>

            <img src={seventeen} className='demo__image' alt='img_seventeen' />
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 18: Update the Player's Score 📈</h2>
            <ul>
                <li>
                Assuming we have confirmed that a player has touched the cheese block, we now want to reward the player by increasing their score.
                </li>

                <li>
                First, we check if the player has a leaderboard setup with if player.leaderstats and player.leaderstats.Points then. This prevents errors if the player does not have a leaderboard entry for points. Which they in fact currently do not have, later in this tutorial we will make a script to setup the player's leaderstats and points.
                </li>

                <li>
                Then, we increase the player’s score by 1 with player.leaderstats.Points.Value = player.leaderstats.Points.Value + 1. This accesses the Points value in the player’s leaderboard and increments it.
                </li>
            </ul>

            <img src={eighteen} className='demo__image' alt='img_eighteen' />
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 19: Clean Up After Interaction 🧽</h2>
            <ul>
                <li>
                After the player has interacted with the cheese block (in this case, collecting it), we want to remove the cheese block from the game to prevent further interaction and to keep the game environment clean.
                </li>

                <li>
                We use cheeseBlock:Destroy() to remove the cheese block from the game completely.
                </li>
            </ul>

            <img src={nineteen} className='demo__image' alt='img_nineteen' />
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 20: Test the Cheese Spawner 🧀📝</h2>
            <ul>
                <li>
                    Now exit the script and go to the workspace, hit the "play" button to test out your cheese spawner.
                </li>

                <li>
                    When you test the cheese spawner, what the spawner create the cheese, but you will not yet be able to collect it until we script out the ability for players have be on the leaderboard and have points. As the current script (in the cheese block) only allows for collecting the cheese if the player has a "points" value.
                </li>
            </ul>
        
            <img src={twenty} className='demo__image' alt='img_twenty' />
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 21: Constructing the Landing Square 🛠️🟦</h2>
            <ul>
                <li>
                To begin, you need to create a landing platform for the cheese blocks. In Roblox Studio, click the "Part" button to insert a new part into your game world. This will serve as the "Square" where the cheese will land.
                </li>

                <li>
                Use the Scale tool to adjust the size of the Square. Set the size in the properties panel to something suitable for catching the falling cheese, like 8, 1, 8, giving it a broad and flat surface.
                </li>

                <li>
                Make sure the Square is positioned directly under the CheeseSpawner to catch the cheese blocks accurately and "anchored" so that it does not move.
                </li>
            </ul>

            <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 22: Adding the Script to the Square 📝</h2>
            <ul>
                <li>
                Add a script to control the square's interaction with the cheese blocks and throw the cheese block in one of the four directions of the square's sides.
                </li>
            </ul>

            <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 23: Starting the Script with Basic References 📄🔗</h2>
            <ul>
                <li>
                Open the script by double-clicking on it in the Explorer panel.
                </li>

                <li>
                Start with a reference to the square platform by assigning the script's parent to a variable. This is typically done with the line local platform = script.Parent, which allows the rest of the script to interact with the square part in the game world.
                </li>
            </ul>

            <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 24: Preparing for Random Direction 🎲🧭</h2>
            <ul>
                <li>
                Declare a variable at the beginning of the script to create a new instance of the Random class. This is done with local random = Random.new(), which will be used later in the script to generate random numbers for determining the direction of force to apply to the cheese blocks.
                </li>
            </ul>

            <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 25: Scripting the Touch Event Handler Function 🤖✋</h2>
            <ul>
                <li>
                Define a function that will handle the Touched event for the square. This function, which could be named pushBlock, will be triggered every time a cheese block lands on the square.
                </li>

                <li>
                Inside this function, you'll later add the logic to determine if the touching object is a cheese block and then apply a random force to it. For now, the function may just be set up as a local function with a parameter, ready to be filled with the interaction logic.
                </li>
            </ul>

            <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 26: Setting Up Your Function 🤖</h2>
            <ul>
                <li>
                We start by checking if the object that triggered the function is indeed a "Part" type and is named "Square" with the line if block:IsA("Part") and block.Name ~= "Square" then.
                </li>

                <li>
                Notice the ~=, which means "not equal" in Lua, the programming language used in Roblox. This condition is checking both the type and the name of the object.
                </li>

                <li>
                We haven't added any action inside the if statement yet; that's what we'll do in the next steps!
                </li>
            </ul>

            <img src={twentySix} className='demo__image' alt='img_twentySix' />
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 27: Defining Movement Directions 📐</h2>
            <ul>
                <li>
                We've created a table named directions with four directions: Right, Left, Forward, and Backward. These are the directions that we will send the cheese block when it hits the square.
                </li>

                <li>
                Each direction is represented by a Vector3.new(x, y, z), where x, y, and z are coordinates in 3D space. For example, Vector3.new(1, 0, 0) means moving right along the x-axis.
                </li>
            </ul>

            <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 28: Adding Randomness to the Push 🎲</h2>
            <ul>
                <li>
                We create a local variable pushDirection inside our if statement.
                </li>

                <li>
                This variable uses our random object and the NextInteger method to pick a random direction from the directions table.
                </li>

                <li>
                random:NextInteger(1, #directions) chooses a random number between 1 and the length of the directions table, which is 4 in this case.
                </li>

                <li>
                The # symbol is used to get the length of a table in Lua.
                </li>
            </ul>

            <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 29: Establishing Push Strength 🏋️‍♂️</h2>
            <ul>
                <li>
                In this step, we'll define how strong the push will be when our cheese block gets moved.
                </li>

                <li>
                We're adding a new line of code to set the push strength: local pushStrength = 50. This sets the strength to a constant value of 50.
                </li>

                <li>
                This variable will be used to determine how much force to apply to our block to push it in a random direction.
                </li>
            </ul>

            <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 30: Creating a BodyVelocity Object 🌪</h2>
            <ul>
                <li>
                We add a line to create a BodyVelocity object: local bodyVelocity = Instance.new("BodyVelocity").
                </li>

                <li>
                Instance.new is a function in Roblox that creates a new instance of a given class. Here, it's creating a BodyVelocity, which is used to give a constant velocity to a part, our block in this case.
                </li>
            </ul>

            <img src={thirty} className='demo__image' alt='img_thirty' />
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 31: Setting the Velocity 🚀</h2>
            <ul>
                <li>
                We assign our bodyVelocity's velocity property with our random direction multiplied by the push strength: bodyVelocity.Velocity = pushDirection * pushStrength.
                </li>

                <li>
                This line calculates the velocity by taking the direction vector from our directions list and multiplying each component (x, y, z) by the push strength.
                </li>
            </ul>

            <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 32: Applying Maximum Force 🔥</h2>
            <ul>
                <li>
                To ensure our block moves as intended, we must set a maximum force that can be applied.
                </li>

                <li>
                We add a line to set the MaxForce property of bodyVelocity: bodyVelocity.MaxForce = Vector3.new(5000, 5000, 5000).
                </li>

                <li>
                The MaxForce property determines the maximum amount of force that can be used to achieve the desired velocity. Setting it to a high value like Vector3.new(5000, 5000, 5000) ensures that the BodyVelocity can actually apply enough force to move the block.
                </li>
            </ul>

            <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 33: Attaching Velocity to the Block 🧲</h2>
            <ul>
                <li>
                In this step, we're attaching the BodyVelocity object to the block to make it move.
                </li>

                <li>
                The line bodyVelocity.Parent = block sets the parent of the BodyVelocity object to the block. This is crucial because it effectively attaches the BodyVelocity to the block, so when the block moves, it will use this BodyVelocity.
                </li>
            </ul>

            <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 34: Cleaning Up with Debris Service 🧹</h2>
            <ul>
                <li>
                Now, we'll ensure our game remains efficient by cleaning up objects that are no longer needed.
                </li>

                <li>
                The code game:GetService("Debris"):AddItem(bodyVelocity, 0.5) uses the Debris service to automatically remove the BodyVelocity object after 0.5 seconds.
                </li>

                <li>
                This is an important step for optimizing your game. It prevents the game from getting cluttered with objects that are no longer in use, which can slow down the game.
                </li>
            </ul>

            <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 35: Test the Square 🟩</h2>
            <ul>
                <li>
                    Test out your square block by simulating what will happen in the game. Place the cheese spawner over the square and when it generates cheese blocks they should land on the square and be thrown in a random direction.
                </li>
            </ul>

            <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 36: Design the Generator 🪑</h2>
            <ul>
                <li>
                    The structure you see below is to make our spawner/square into a fun "cheese generator" design.
                </li>

                <li>
                    Add new "Parts" into the workspace and use the select, move, scale, and rotate tools to emulate a similar design that you see below.
                </li>
            </ul>

            <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 37: The Conveyor Belt 🏭</h2>
            <ul>
                <li>
                    We need to create the conveyor belts that the cheese will travel down when it gets thrown off of the square.
                </li>

                <li>
                    Start this process by adding a "Part" into the workspace.
                </li>

                <li>
                    Position the newly added part at the side of the square, make sure to anchor it such that it stays in place.
                </li>

                <li>
                    When you are ready give it a fun color and rename it to "conveyorBelt".
                </li>
            </ul>

            <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 38: Moving Down the Line ➡️</h2>
            <ul>
                <li>
                    To allow the cheese blocks to move down the line, we must adjust the "AssemblyLinearVelocity" property. This property starts at a default of "0,0,0". 
                </li>

                <li>
                    "AssemblyLinearVelocity" controls the force that is applied in a certain direction to objects that touch the block, thus moving them.
                </li>

                <li>
                    Here we need to change the X value to "15". Look at the image of the "AssemblyLinearVelocity" property below as you set yours.
                </li>
            </ul>

            <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 39: Creating a Laser Part 🔴</h2>
            <ul>
                <li>
                First, we need to insert a new part into the workspace. This can be done by selecting the "Part" button in the toolbar.
                </li>

                <li>
                After inserting the part, you should see it appear in the "Explorer" window under Workspace. You can rename this part to "laser" to make it clear what this part will represent. Customize the looks to make it red and have the neon material.
                </li>
            </ul>

            <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 40: Assigning a Script to the Laser 🔍</h2>
            <ul>
                <li>
                With the laser part selected in the Explorer, click to add a new script. This script will control the behavior of the laser within the game.
                </li>

                <li>
                You can see the new script has been added under the "laser" part, indicating that the script is now a child of the laser part and will specifically control it.
                </li>
            </ul>

            <img src={forty} className='demo__image' alt='img_forty' />
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 41: Setting the Laser Variable ⌨️</h2>
            <ul>
                <li>
                Inside the script, the first line of code is written: local laser = script.Parent. This line of code is establishing a variable named laser which refers to the part that the script is attached to (in this case, our laser part).
                </li>

                <li>
                By setting this variable, we're creating a reference that we can use throughout the script to modify or check the properties of the laser part.
                </li>
            </ul>

            <img src={fortyOne} className='demo__image' alt='img_fortyOne' />
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 42: Writing the Deadly Block Function 🚨</h2>
            <ul>
                <li>
                This step involves creating a function that will make the laser "deadly" when a player's part touches it.
                </li>

                <li>
                You've started defining a function called deadlyBlock that takes part as a parameter.
                </li>

                <li>
                This function will contain the logic that determines what happens when something touches the laser.
                </li>
            </ul>

            <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 43: Detecting if a Humanoid is Touched ☠️</h2>
            <ul>
                <li>
                Within the deadlyBlock function, you've added a line to find a Humanoid object: local humanoid = part.Parent:FindFirstChild("Humanoid").
                </li>

                <li>
                This line looks for a Humanoid object within the parent of the touched part. In Roblox, the Humanoid object is typically part of a character model that represents a player or NPC (non-player character).
                </li>
            </ul>

            <img src={fortyThree} className='demo__image' alt='img_fortyThree' />
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 44: Dealing Damage to the Humanoid 🔥</h2>
            <ul>
                <li>
                You've included an if statement to check if a Humanoid was indeed found.
                </li>

                <li>
                If humanoid is not nil (meaning something was found), the script sets the Humanoid's Health to 0, effectively "killing" the character: humanoid.Health = 0.
                </li>
            </ul>

            <img src={fortyFour} className='demo__image' alt='img_fortyFour' />
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 45: Laser Duplicate 🟥</h2>
            <ul>
                <li>
                    The script for the deadly laser is done, leave the script and go to the workspace.
                </li>

                <li>
                    Duplicate the laser that you created and move it to the other side of the conveyor belt, as seen in the image below so that there are now two of them.
                </li>
            </ul>

            <img src={fortyFive} className='demo__image' alt='img_fortyFive' />
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 46: More Clones 🐑</h2>
            <ul>
                <li>
                    Take the conveyor belt/laser combination and duplicate it so that there are four of these designs, one for each side of the square that will deposit a cheese block to each side.
                </li>
            </ul>

            <img src={fortySix} className='demo__image' alt='img_fortySix' />
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 47: Let's Organize 📁</h2>
            <ul>
                <li>
                Take all of the parts that you created for this factory line design and add them into a "model".
                </li>
            </ul>

            <img src={fortySeven} className='demo__image' alt='img_fortySeven' />
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 48: Proper Naming 🏷️</h2>
            <ul>
                <li>
                    Rename the model to "FactoryLine" to maximize the readability of your explorer tab.
                </li>
            </ul>

            <img src={fortyEight} className='demo__image' alt='img_fortyEight' />
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 49: ServerScriptService Scripting ☁️📝</h2>
            <ul>
                <li>
                    Locate the "ServerScriptService" in the "Explorer" tab and add a "script" into it. This script will contain the code that takes the player when they join the game and transforms them into a rat!
                </li>
            </ul>

            <img src={fortyNine} className='demo__image' alt='img_fortyNine' />
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 50: Player to Rat 🧑➡️🐀</h2>
            <ul>
                <li>
                    Rename the newly added script to "playerToRat" to reflect what this script will do.
                </li>
            </ul>

            <img src={fifty} className='demo__image' alt='img_fifty' />
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 51: Choose the Rat Model 🐀</h2>
            <ul>
                <li>
                    Open the "toolbox" and in the search type in "rat".
                </li>

                <li>
                    Select the rat that you see in the image below so that the code we write will effect the model the same way it works in the tutorial. The rat may be listed as "Realistic Rat" in the toolbox search results.
                </li>
            </ul>

            <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 52: Starting the Transformation Function ➡️🐀</h2>
            <ul>
                <li>
                The function transformCharacterToBlock is created and takes two parameters: character, which is the player's character, and ratMeshTemplate, which is the template for the rat transformation.
                </li>

                <li>
                The function body is currently empty, but we will fill it in the next steps.
                </li>
            </ul>

            <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 53: Looping Through Character Parts 🔁</h2>
            <ul>
                <li>
                A for loop is initiated with ipairs(character:GetChildren()), which will iterate over all the children of the character, which are typically parts like the head, torso, arms, etc.
                </li>

                <li>
                Inside the loop, we will be checking and acting on each part, but the specific actions are not yet defined.
                </li>
            </ul>

            <img src={fiftyThree} className='demo__image' alt='img_fiftyThree' />
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 54: Destroying Unnecessary Parts 💥</h2>
            <ul>
                <li>
                Within the loop, an if statement checks if the current child is a BasePart, Humanoid, or HumanoidRootPart using the :IsA() method.
                </li>

                <li>
                If the child is one of these types, it is destroyed with child:Destroy(), removing it from the character.
                </li>
            </ul>

            <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 55: Creating the Rat Block 🐀</h2>
            <ul>
                <li>
                A new Part is created with Instance.new("Part") and stored in the variable block.
                </li>

                <li>
                This new part will serve as the basis for the rat character, but we still need to configure it with the rat mesh and necessary properties to complete the transformation, the rat mesh will sit on the block which will be invisible.
                </li>
            </ul>

            <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 56: Naming and Resizing the Block for Transformation 📏</h2>
            <ul>
                <li>
                In this step, we're continuing with the transformation function. We're setting up the new block that will act as the basis for the transformed character.
                </li>

                <li>
                We start by giving the block a name HumanoidRootPart with the line block.Name = "HumanoidRootPart". This is important because Roblox uses the part named HumanoidRootPart as a reference point for the character's position and movement in the world.
                </li>

                <li>
                Next, we set the size of the block with block.Size = Vector3.new(2, 0.1, 4). 
                </li>

                <li>
                Vector3.new creates a new vector3 object with x, y, and z components. 2 is the width of the block on the x-axis. 0.1 is the height of the block on the y-axis, making it very flat. 4 is the depth of the block on the z-axis.
                </li>

                <li>
                This specific size is chosen likely to match the size of the rat mesh that will be applied later, ensuring that the character's physical representation in the world matches the visual one.
                </li>
            </ul>

            <img src={fiftySix} className='demo__image' alt='img_fiftySix' />
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 57: Setting Block Properties for Interaction 🚫🚶‍♂️</h2>
            <ul>
                <li>
                We make the block non-anchored with block.Anchored = false, meaning it will be affected by physics such as gravity and collisions instead of staying fixed in space.
                </li>

                <li>
                block.CanCollide = true ensures that the block will collide with other objects. This is essential for the character to stand on surfaces and not fall through the world.
                </li>

                <li>
                We then set the block's parent to the character with block.Parent = character. This attaches the block to the character, making it part of the character's model in the game.
                </li>
            </ul>

            <img src={fiftySeven} className='demo__image' alt='img_fiftySeven' />
        </div>

        <div style={currentStep == 58 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 58: Cloning and Parenting the Rat Mesh 🐀🔄</h2>
            <ul>
                <li>
                Finally, we add the visual representation of the rat to the character.
                </li>

                <li>
                We clone the rat mesh template with local ratMesh = ratMeshTemplate:Clone(). Cloning is used in scripting to create an exact copy of an object, including all its properties and values, without affecting the original.
                </li>

                <li>
                ratMesh.Parent = character sets the parent of the cloned rat mesh to the character. Parenting in Roblox is the act of defining hierarchies, where one object becomes the "child" of another. In this case, the rat mesh is made a child of the character so that it moves and behaves as a part of the character model.
                </li>

                <li>
                This transformation is essential for the gameplay experience we're crafting. By changing the player's avatar into a rat, we're altering how they interact with the game world and other players, which can be used to fit the theme of the game or a specific gameplay mechanic we want to introduce.
                </li>
            </ul>

            <img src={fiftyEight} className='demo__image' alt='img_fiftyEight' />
        </div>

        <div style={currentStep == 59 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 59: Attaching the Mesh to the Block 🛠️</h2>
            <ul>
                <li>
                To position our mesh correctly, we've updated the ratMesh.CFrame to align with the block's center.
                </li>
            </ul>

            <img src={fiftyNine} className='demo__image' alt='img_fiftyNine' />
        </div>

        <div style={currentStep == 60 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 60: Securing the Mesh with a Weld Constraint 🔗</h2>
            <ul>
                <li>
                To keep our mesh firmly attached to the block, we introduce a WeldConstraint. This is like a digital glue in Roblox.
                </li>

                <li>
                We create the weld by calling Instance.new("WeldConstraint") and store it in a local variable named weld.
                </li>

                <li>
                Then we define weld.Part0 = block and weld.Part1 = ratMesh to specify what parts the weld is connecting.
                </li>

                <li>
                Finally, we set weld.Parent = ratMesh to put our weld into action. The green lines show the new code you need to add!
                </li>
            </ul>

            <img src={sixty} className='demo__image' alt='img_sixty' />
        </div>

        <div style={currentStep == 61 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 61: Adding a Humanoid Component to the Character 🤖</h2>
            <ul>
                <li>
                After ensuring our mesh is welded to the block, we need to give our character life by adding a Humanoid component.
                </li>

                <li>
                We create a new Humanoid by calling Instance.new("Humanoid") and make it a child of the character with humanoid.Parent = character.
                </li>

                <li>
                We then set humanoid.HealthDisplayDistance = 0 to hide the health bar since we might not need it.
                </li>

                <li>
                Lastly, we use humanoid.NameOcclusion = Enum.NameOcclusion.NoOcclusion to ensure the name is always visible, regardless of what's in front of it.
                </li>
            </ul>

            <img src={sixtyOne} className='demo__image' alt='img_sixtyOne' />
        </div>

        <div style={currentStep == 62 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 62: Referencing the Mesh Template 📝</h2>
            <ul>
                <li>
                In the line of code highlighted by the green arrow, we are creating a local variable named ratMeshTemplate and assigning it to a specific object in the game's workspace with local ratMeshTemplate = game.Workspace.meshblock.
                </li>

                <li>
                This line is crucial because it tells our script where to find the mesh we want to clone for each player. The game.Workspace is a special container for all the physical parts of the game world, and meshblock is the name of the mesh we're using as our template.
                </li>

                <li>
                game.Workspace.meshblock: This is the path to our mesh template. game is the root node of all Roblox games, Workspace is where game objects are stored, and meshblock is the name of our template object.
                </li>
            </ul>

            <img src={sixtyTwo} className='demo__image' alt='img_sixtyTwo' />
        </div>

        <div style={currentStep == 63 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 63: Listening for New Players 🎧</h2>
            <ul>
                <li>
                With our mesh template set, it's time to handle new players joining the game. To do this, we use an event listener that waits for the PlayerAdded event to occur.
                </li>

                <li>
                The line game.Players.PlayerAdded:Connect(function(player) uses the Connect method to set up a listener on the PlayerAdded event. When a player joins, this event fires, and our anonymous function (the one that takes player as an argument) gets called.
                </li>

                <li>
                The player parameter represents the player who just joined. This allows us to interact with the new player's properties and methods.
                </li>
            </ul>

            <img src={sixtyThree} className='demo__image' alt='img_sixtyThree' />
        </div>

        <div style={currentStep == 64 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 64: Monitoring Player Characters 👀</h2>
            <ul>
                <li>
                After we detect a new player, we want to do something when their character spawns in the game world. This is done by nesting another event listener inside our PlayerAdded listener.
                </li>

                <li>
                The line player.CharacterAdded:Connect(function(character) sets up a listener for the CharacterAdded event on the player object. This event fires when the player's character is created in the game, allowing us to modify the character right as it spawns.
                </li>

                <li>
                player.CharacterAdded: An event that fires when the player's character is loaded into the game.
                </li>
            </ul>

            <img src={sixtyFour} className='demo__image' alt='img_sixtyFour' />
        </div>

        <div style={currentStep == 65 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 65: Ensuring the Humanoid is Ready 🧍‍♂️</h2>
            <ul>
                <li>
                To modify a player's character, we need to make sure their Humanoid object is fully loaded. This is crucial because the Humanoid object controls the health, animations, and other aspects of a character in Roblox.
                </li>

                <li>
                The line character:WaitForChild('Humanoid') uses the WaitForChild method. This pauses the script until the Humanoid child of the character is found, ensuring that we don't try to modify a character before it's completely loaded into the game.
                </li>
            </ul>

            <img src={sixtyFive} className='demo__image' alt='img_sixtyFive' />
        </div>

        <div style={currentStep == 66 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 66: Transforming the Character 🔄</h2>
            <ul>
                <li>
                Once we're certain the Humanoid object is available, we can proceed to transform the character. This is where the actual game mechanics come into play, allowing us to modify the character's appearance.
                </li>

                <li>
                The function transformCharacterToBlock(character, ratMeshTemplate) is called to carry out this transformation where the player is turned into a block and the rat mesh is welded to this block giving the illusion of the player playing as the rat. 
                </li>
            </ul>

            <img src={sixtySix} className='demo__image' alt='img_sixtySix' />
        </div>

        <div style={currentStep == 67 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 67: Handling Character Death 👓</h2>
            <ul>
                <li>
                In many games, it's important to respond to a character's death to reset gameplay elements, update scores, or manage respawns.
                </li>

                <li>
                When the Humanoid's health reaches zero, this event triggers, and any code inside the connected function will execute.
                </li>

                <li>
                Inside the connected function, we have a wait(5) call, which pauses the execution for 5 seconds. This could be used to give players a moment before any respawn or reset actions are taken.
                </li>

                <li>
                Finally, transformCharacterToBlock(player.Character, ratMeshTemplate) is called again, which suggests that upon death, the character will undergo the same transformation process as when they first spawned. 
                </li>
            </ul>

            <img src={sixtySeven} className='demo__image' alt='img_sixtySeven' />
        </div>

        <div style={currentStep == 68 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 68: Factory Floor Spawners ⭐</h2>
            <ul>
                <li>
                    Our current setup consists of the "floor" block which will serve as the "factory floor", then there is a "FactoryLine" (cheese spawner), and now we need spawners for the players. Add as many or as little spawners as you see fit by clicking the "Spawn" button to generate them; then, use the "move" tool to place them around the factory floor.
                </li>
            </ul>

            <img src={sixtyEight} className='demo__image' alt='img_sixtyEight' />
        </div>

        <div style={currentStep == 69 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 69: Test your Designs 📝</h2>
            <ul>
                <li>
                    Click the "Play" button to test your design, are the cheese blocks shooting out? Can you navigate as the rat correctly?
                </li>

                <li>
                    Please note that you will not be able to "collect" the cheese yet as we have not added "points" value for the cheese to go to.
                </li>

                <li>
                    Exit the test when you are ready to continue designing.
                </li>
            </ul>

            <img src={sixtyNine} className='demo__image' alt='img_sixtyNine' />
        </div>

        <div style={currentStep == 70 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 70: The Angry Cat 🐈</h2>
            <ul>
                <li>
                Time to setup the arch enemy of the factory rats. The angry cat, who guards the factory day and night.  
                </li>

                <li>
                    Open the "Toolbox" and in the searchbar type "cat". Then, find and select the same cat you see in the image below.
                </li>
            </ul>

            <img src={seventy} className='demo__image' alt='img_seventy' />
        </div>

        <div style={currentStep == 71 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 71: Starting the Cat Platform ⬛</h2>
            <ul>
                <li>
                    The block you see below will act as a platform for the car, in other words, we are going to weld te cat to this block then code the block to locate and move the cat towards who ever is it targeting to attack.
                </li>

                <li>
                    Start by adding a "Part" into the workspace as seen below
                </li>
            </ul>

            <img src={seventyOne} className='demo__image' alt='img_seventyOne' />
        </div>

        <div style={currentStep == 72 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 72: Start Organizing 📁</h2>
            <ul>
                <li>
                    We need to start organizing our items to create a proper design.
                </li>

                <li>
                    Start by adding a "Model" to the workspace.
                </li>

                <li>
                    Then place the cat on the block and flatten the block, use the image below to guide you through this.
                </li>
            </ul>

            <img src={seventyTwo} className='demo__image' alt='img_seventyTwo' />
        </div>

        <div style={currentStep == 73 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 73: Organization Continued 📂</h2>
            <ul>
                <li>
                    Finish organizing the cat design by place the "Cat" mesh and the "Part" that it is standing on inside of the "Model".
                </li>
            </ul>

            <img src={seventyThree} className='demo__image' alt='img_seventyThree' />
        </div>

        <div style={currentStep == 74 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 74: Begin Welding 🔗</h2>
            <ul>
                <li>
                    Add a "WeldConstraint" into the "Cat" mesh. This will allow us to "glue" the "Cat" mesh to the block, so that the block can move the "Cat" towards the player.
                </li>
            </ul>

            <img src={seventyFour} className='demo__image' alt='img_seventyFour' />
        </div>

        <div style={currentStep == 75 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 75: Finish Welding 🔗🔗</h2>
            <ul>
                <li>
                    Find the "Properties" of the "WeldConstraint".
                </li>

                <li>
                    Set "Part0" to "Cat" (what we are welding) and "Part1" to "Part" (what we are welding it to).
                </li>
            </ul>

            <img src={seventyFive} className='demo__image' alt='img_seventyFive' />
        </div>

        <div style={currentStep == 76 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 76: Adding a Script to Your Block 🐱💻</h2>
            <ul>
                <li>
                Open your Roblox game's workspace and navigate to the Explorer panel on the right-hand side.
                </li>

                <li>
                Choose "Script" from the list to add a new script to the block. This script is going to be the brains of the operation, making our cat block come to life and seek out players!
                </li>
            </ul>

            <img src={seventySix} className='demo__image' alt='img_seventySix' />
        </div>

        <div style={currentStep == 77 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 77: Initializing the Block Variable 📦🔗</h2>
            <ul>
                <li>
                    With the script added, it's time to start coding. Double-click the script that's now a child of the block in the Explorer to open the script editor.
                </li>

                <li>
                    Type local block = script.Parent on the first line of the script.
                </li>

                <li>
                    This line of code sets up a local variable named block which refers to the script's parent object - our cat block. Think of it as naming the block so we can easily talk to it in code.
                </li>
            </ul>

            <img src={seventySeven} className='demo__image' alt='img_seventySeven' />
        </div>

        <div style={currentStep == 78 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 78: Accessing the Players 👾🌐</h2>
            <ul>
                <li>
                    On the next line, type local players = game:GetService("Players").
                </li>

                <li>
                This line connects us to all the players in the game. The players variable is like a list of everyone who is in our game world, and now we can interact with them through our script.
                </li>
            </ul>

            <img src={seventyEight} className='demo__image' alt='img_seventyEight' />
        </div>

        <div style={currentStep == 79 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 79: Setting the Speed for the Chase 🐾⚡</h2>
            <ul>
                <li>
                In the script editor, after defining block and players, add a new line of code: local speed = 50.
                </li>

                <li>
                This line sets up a variable named speed that will determine how fast our cat block will move. The number 50 is the value of speed in studs per second.
                </li>

                <li>
                The reason we're setting this speed is to control how quickly the cat block will react and move towards players. Think of it as setting the pace for our cat's pounce!
                </li>
            </ul>

            <img src={seventyNine} className='demo__image' alt='img_seventyNine' />
        </div>

        <div style={currentStep == 80 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 80: Attaching BodyPosition to the Block 📍</h2>
            <ul>
                <li>
                This code creates a new BodyPosition object, which is a type of constraint that can move a part to a specific position in the world.
                </li>

                <li>
                We add the BodyPosition to our block because it's how we'll tell our cat where to go. It's like giving our block a GPS that will guide it to the players!
                </li>
            </ul>

            <img src={eighty} className='demo__image' alt='img_eighty' />
        </div>

        <div style={currentStep == 81 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 81: Unleashing Maximum Force 💪🌟</h2>
            <ul>
                <li>
                MaxForce is a property of BodyPosition that determines the maximum amount of force that can be applied to move the block.
                </li>

                <li>
                Vector3.new: Creates a new Vector3 value, which is essentially a 3D vector used in Roblox to represent forces in three dimensions (x, y, z).
                </li>

                <li>
                math.huge: A constant in Lua representing an infinitely large number, ensuring that our block has enough force to move to any position.
                </li>

                <li>
                This line makes sure that our block has unlimited strength to move in any direction. The Vector3 represents the force in the X, Y, and Z dimensions, and math.huge is a way of saying "as much force as needed."
                </li>
            </ul>

            <img src={eightyOne} className='demo__image' alt='img_eightyOne' />
        </div>

        <div style={currentStep == 82 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 82: Tweaking BodyPosition Properties 🐾🎛️</h2>
            <ul>
                <li>
                Now that you have a BodyPosition object, let's define how responsive it should be.
                </li>

                <li>
                Write bodyPosition.P = 10000 to set the position's power, which affects how quickly it can move the block to the target position.
                </li>

                <li>
                A high P value means the block will more aggressively correct its position to reach the target. This is essential for making the cat's movements sharp and precise, ensuring it can chase down players effectively.
                </li>

                <li>
                bodyPosition.P refers to the 'P' property, which stands for 'proportional' in the PID controller of the BodyPosition. It's the part that determines how strongly the block will try to reach its target position.
                </li>

                <li>
                10000 is a relatively high value, indicating a strong force that will be applied to correct the block's position.
                </li>
            </ul>

            <img src={eightyTwo} className='demo__image' alt='img_eightyTwo' />
        </div>

        <div style={currentStep == 83 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 83: Adding a BodyGyro for Orientation Control 🧭🔧</h2>
            <ul>
                <li>
                Your block can move, but it also needs to orient itself correctly. For that, you'll add a BodyGyro.
                </li>

                <li>
                The BodyGyro will keep the block upright and facing the right direction during movement, which is crucial for a visually coherent attack. Without it, the block might spin or flip in unnatural ways.
                </li>

                <li>
                Instance.new("BodyGyro", block) creates a new BodyGyro object, which will give our block the ability to maintain orientation.
                </li>

                <li>
                block is the parent object; it's where the BodyGyro will be applied.
                </li>
            </ul>

            <img src={eightyThree} className='demo__image' alt='img_eightyThree' />
        </div>

        <div style={currentStep == 84 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 84: Setting BodyGyro Strength and Responsiveness 💪🔄</h2>
            <ul>
                <li>
                After adding the BodyGyro, you need to define how strong and responsive it should be.
                </li>

                <li>
                Add bodyGyro.MaxTorque = Vector3.new(math.huge, math.huge, math.huge) to ensure your block can resist any twisting forces and stay oriented.
                </li>

                <li>
                Then, set bodyGyro.P = 10000, just like you did for BodyPosition, to determine how quickly it corrects its orientation.
                </li>

                <li>
                bodyGyro.MaxTorque sets the maximum rotational force the BodyGyro can apply. By using Vector3.new(math.huge, math.huge, math.huge), you're allowing unlimited force to maintain orientation.
                </li>

                <li>
                bodyGyro.P sets the power for orientation correction. A value of 10000 is strong and ensures quick and precise alignment.
                </li>
            </ul>

            <img src={eightyFour} className='demo__image' alt='img_eightyFour' />
        </div>

        <div style={currentStep == 85 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 85: Crafting the Player-Seeking Function 🧍🔍</h2>
            <ul>
                <li>
                Begin defining a function named getNearestPlayer by typing function getNearestPlayer() which sets the stage for the code that will find the closest player to the cat block.
                </li>

                <li>
                The getNearestPlayer function's purpose is to scan all the players in the game and find the one nearest to the cat block. This is essential for the block's targeting mechanism.
                </li>
            </ul>

            <img src={eightyFive} className='demo__image' alt='img_eightyFive' />
        </div>

        <div style={currentStep == 86 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 86: Initializing Tracking Variables 📍</h2>
            <ul>
                <li>
                Inside the getNearestPlayer function, declare local nearestPlayer = nil. This variable will hold the player object that is the closest to the block once identified.
                </li>

                <li>
                Next, set local shortestDistance = math.huge. This initializes the shortest distance as an extremely large number, acting as a starting point for comparison which we will match against the distance of each player, calculating which player is the shortest distance to the cat block.
                </li>
            </ul>

            <img src={eightySix} className='demo__image' alt='img_eightySix' />
        </div>

        <div style={currentStep == 87 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 87: Creating a Loop to Check Each Player 🏃‍♂️🔁</h2>
            <ul>
                <li>
                The loop uses the pairs() function to iterate over the list of players, which players:GetPlayers() retrieves.
                </li>

                <li>
                The underscore _ is used because you don't need the index variable provided by pairs(). You only care about the player objects.
                </li>
            </ul>

            <img src={eightySeven} className='demo__image' alt='img_eightySeven' />
        </div>

        <div style={currentStep == 88 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 88: Completing the Player-Seeking Function 🎮</h2>
            <ul>
                <li>
                The function iterates over all players, checking if the HumanoidRootPart exists, then calculates the distance to each player. If a player is closer than the previously recorded nearest player, the function updates nearestPlayer and shortestDistance.
                </li>
                
                <li>
                Calculate the distance between the player's HumanoidRootPart position and the block's position using (player.Character.HumanoidRootPart.Position - block.Position).magnitude. This calculates the 3D distance (magnitude of the vector difference) between the two points in space.
                </li>

                <li>
                If this distance is less than the shortestDistance, update shortestDistance and nearestPlayer with the current player.
                </li>

                <li>
                This function's purpose is to identify the closest target for the cat block to "attack". By determining the nearest player, the game can direct the block's movement towards that player, adding interactivity and challenge to the game.
                </li>
            </ul>

            <img src={eightyEight} className='demo__image' alt='img_eightyEight' />
        </div>

        <div style={currentStep == 89 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 89: Return nearestPlayer 🧍</h2>
            <ul>
                <li>
                    "return nearestPlayer" takes the player who's distance is closest to the cat (as calculated in the for loop) and gives us this player.
                </li>
            </ul>

            <img src={eightyNine} className='demo__image' alt='img_eightyNine' />
        </div>

        <div style={currentStep == 90 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 90: Implementing the onTouch Function 🐾🤖</h2>
            <ul>
                <li>
                Start a new function with function onTouch(other). This function is meant to be triggered whenever another object in the game touches the block (when the cat block touches the player).
                </li>
                
                <li>
                The other parameter represents the object that comes into contact with the block. This could be anything in the game world, but you'll later specify what actions to take if other is a player.
                </li>

                <li>
                The onTouch function will be essential for triggering interactions between the cat block and players or other objects. For example, when a player touches the block, you might want the block to "attack" or follow the player.
                </li>
            </ul>

            <img src={ninety} className='demo__image' alt='img_ninety' />
        </div>

        <div style={currentStep == 91 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 91: Programming the onTouch Event Handler 👋</h2>
            <ul>
                <li>
                Within the onTouched function, add a conditional statement to check if the touching object (other) is a player. You do this by checking if other.Parent has a Humanoid object, which is a characteristic of player characters in Roblox games.
                </li>

                <li>
                If the condition is true, you call other.Parent:FindFirstChildOfClass("Humanoid"):TakeDamage(math.huge). This line of code inflicts an immense amount of damage to the humanoid, effectively "killing" the player character in-game instantly.
                </li>

                <li>
                The onTouched function reacts when the block is touched. The use of FindFirstChildOfClass("Humanoid") ensures that the script only responds to player characters, as other game objects don't have a Humanoid class.
                </li>

                <li>
                TakeDamage(math.huge) applies a large enough damage value to surpass any reasonable amount of health a player may have, ensuring the block's touch is lethal. It’s like setting a variable to an extremely high number to ensure it exceeds any expected maximum.
                </li>
            </ul>

            <img src={ninetyOne} className='demo__image' alt='img_ninetyOne' />
        </div>

        <div style={currentStep == 92 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 92: Connecting the onTouch Function 🧲👾</h2>
            <ul>
                <li>
                Outside the function, you establish a connection between the block and the touch event by using block.Touched:Connect(onTouched). This means that the onTouched function will be called every time the block is touched by any object in the game.
                </li>

                <li>
                The Touched event is an event that Roblox fires when an object comes into contact with another object. Using Connect(onTouched) ensures that your custom function is triggered, allowing for specific responses to in-game interactions.
                </li>

                <li>
                This sets up interactive gameplay, where objects can cause effects—like the block attacking players—making the game world feel alive and responsive.
                </li>
            </ul>

            <img src={ninetyTwo} className='demo__image' alt='img_ninetyTwo' />
        </div>

        <div style={currentStep == 93 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 93: Creating an Endless Loop ♾️🔄</h2>
            <ul>
                <li>
                Finally, you start an endless loop with while true do. This loop will run indefinitely, constantly checking or performing actions as long as the game is running.
                </li>

                <li>
                Inside this loop, you will later place code that needs to be executed continuously, such as checking for the nearest player and moving the block towards them.
                </li>

                <li>
                The while true do loop is commonly used in Roblox scripts to create a repeating action or check, as there isn't an inherent game tick function like in some other game engines.
                </li>
            </ul>

            <img src={ninetyThree} className='demo__image' alt='img_ninetyThree' />
        </div>

        <div style={currentStep == 94 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 94: Implementing Target Acquisition in the Loop 🎯</h2>
            <ul>
                <li>
                In the infinite loop, you first use wait(0.1) to pause the loop for a short period, which is necessary to prevent the script from running too many times per second and potentially overloading the game.
                </li>

                <li>
                You then declare a variable local nearestPlayer = getNearestPlayer() to store the reference to the nearest player by calling the function you defined earlier. This function scans all players and returns the one closest to the block.
                </li>

                <li>
                This loop continuously checks for the nearest player, which is critical for the cat block to react and move towards targets in real-time.
                </li>
            </ul>

            <img src={ninetyFour} className='demo__image' alt='img_ninetyFour' />
        </div>

        <div style={currentStep == 95 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 95: Directing the Block Towards the Nearest Player 📍</h2>
            <ul>
                <li>
                Inside the loop, after acquiring the nearest player, check if the player is valid and has a HumanoidRootPart. This is to ensure that the block only targets actual player characters and not other objects.
                </li>

                <li>
                If a valid player is found, you retrieve their position with nearestPlayer.Character.HumanoidRootPart.Position and store it in local targetPosition.
                </li>

                <li>
                Then, update the BodyPosition and BodyGyro of the block to move and face towards the target position. This is done by setting bodyPosition.Position to the target's position and updating bodyGyro.CFrame with CFrame.lookAt, which orients the block to face the target.
                </li>

                <li>
                The script makes the block actively pursue the nearest player, creating a dynamic challenge as the block doesn't just passively wait to be touched.
                </li>

                <li>
                By updating BodyPosition and BodyGyro, you're programming the block to move and orient itself in a realistic manner, adding depth to the game's interaction mechanics.
                </li>

                <li>
                These steps finalize the behavior of the cat block, making it a moving entity that actively seeks out and faces the nearest player, enhancing the gameplay experience with an interactive opponent.
                </li>
            </ul>

            <img src={ninetyFive} className='demo__image' alt='img_ninetyFive' />
        </div>

        <div style={currentStep == 96 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 96: Invisible Cat Block ⬜</h2>
            <ul>
                <li>
                    Make the block that the cat is standing on invisible, so that it looks like it is the cat that is moving and not the block.
                </li>

                <li>
                    Open the "Properties" tab of the block and set the "Transparency" to "1".
                </li>
            </ul>

            <img src={ninetySix} className='demo__image' alt='img_ninetySix' />
        </div>

        <div style={currentStep == 97 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 97: New Script in ServerScriptService 📝</h2>
            <ul>
                <li>
                    Navigate down to "ServerScriptService" and add a new script.
                </li>
            </ul>

            <img src={ninetySeven} className='demo__image' alt='img_ninetySeven' />
        </div>

        <div style={currentStep == 98 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 98: Script Purpose 🎬</h2>
            <ul>
                <li>
                    The purpose of this script is to create a leaderboard displaying how much cheese each player has collected, thus allowing them to now pick up the cheese blocks.
                </li>

                <li>
                    Reflect the above statement by renaming the "Script" to "createLeaderboard". 
                </li>
            </ul>

            <img src={ninetyEight} className='demo__image' alt='img_ninetyEight' />
        </div>

        <div style={currentStep == 99 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 99: Initializing the Leaderboard Function 🚀</h2>
            <ul>
                <li>
                Start by defining a new function called createLeaderboard. This function will set the stage for our the leaderboard.
                </li>

                <li>
                Type function createLeaderboard(player) to declare the function.
                </li>

                <li>
                What we've done here is tell Roblox, "Hey, we're going to need a leaderboard, so let's make room for it when each player jumps into the game!"
                </li>
            </ul>

            <img src={ninetyNine} className='demo__image' alt='img_ninetyNine' />
        </div>

        <div style={currentStep == 100 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 100: Creating the Leaderboard Folder 📁</h2>
            <ul>
                <li>
                Inside the createLeaderboard function, let's get down to business by adding a new line of code.
                </li>

                <li>
                Type local leaderstats = Instance.new("Folder") to create a new Folder in the game that will hold our leaderboard stats.
                </li>

                <li>
                Give this folder a name by typing leaderstats.Name = "leaderstats".
                </li>

                <li>
                Attach this folder to each player by setting leaderstats.Parent = player.
                </li>
            </ul>

            <img src={hundred} className='demo__image' alt='img_oneHundred' />
        </div>

        <div style={currentStep == 101 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 101: Adding Points to the Leaderboard 🎉</h2>
            <ul>
                <li>
                Add local points = Instance.new("IntValue") to create a new integer value, which is perfect for holding score points.
                </li>

                <li>
                Give these points a label with points.Name = "Points".
                </li>

                <li>
                Set the initial score to zero by typing points.Value = 0 because everyone starts from scratch!
                </li>

                <li>
                Make sure these points are tucked into the leaderstats folder by setting points.Parent = leaderstats.
                </li>
            </ul>

            <img src={hundredOne} className='demo__image' alt='img_oneHundredOne' />
        </div>

        <div style={currentStep == 102 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 102: Setting Up the StarterGui for UI Design 🛠️</h2>
            <ul>
                <li>
                Get ready to dive into creating the "Cat Attack" message for your game when the user is attacked!
                </li>

                <li>
                Find the 'StarterGui' in the 'Explorer'. This is your canvas for creating the visual elements that players will interact with, add a "ScreenGui" inside of it..
                </li>
            </ul>

            <img src={hundredTwo} className='demo__image' alt='img_oneHundredTwo' />
        </div>

        <div style={currentStep == 103 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 103: Adding a Text Label to Your GUI 📝</h2>
            <ul>
                <li>
                The green arrow shows us that a 'TextLabel' has been added to 'ScreenGui'. A 'TextLabel' is a basic UI element used to display text on the screen.
                </li>

                <li>
                In the properties of the 'TextLabel', you can customize things like font, size, color, and what the text actually says.
                </li>
            </ul>

            <img src={hundredThree} className='demo__image' alt='img_oneHundredThree' />
        </div>

        <div style={currentStep == 104 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 104: Customizing Your Text Label ✨</h2>
            <ul>
                <li>
                With your 'TextLabel' selected, look at the properties window.
                </li>

                <li>
                You can see the green brackets highlighting where we can change the text to say anything we want, like "Cat Attack!!!"
                </li>

                <li>
                Adjust the 'TextColor3' property to change the color of the text. In this case, it's a bright red, sure to catch the player's eye!
                </li>

                <li>
                You can also play with other settings like 'TextSize' to make it bigger or smaller, and 'Font' to change the style.
                </li>

                <li>
                Customizing the 'TextLabel' is like dressing up your words for a fancy event—they've got to look their best to make an impact! 
                </li>
            </ul>

            <img src={hundredFour} className='demo__image' alt='img_oneHundredFour' />
        </div>

        <div style={currentStep == 105 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 105: Enabling and Disabling GUI Elements 🔲</h2>
            <ul>
                <li>
                Back in the 'Explorer', make sure you've got your 'ScreenGui' selected.
                </li>

                <li>
                Look at the properties of 'ScreenGui'. The green arrow points to the 'Enabled' checkbox.
                </li>

                <li>
                By toggling this checkbox, you can turn the entire 'ScreenGui' on or off. When it's unchecked, none of the UI elements within it will be visible to players.
                </li>

                <li>
                We want to turn it off and only turn it on when they players can caught by the cat.
                </li>
            </ul>

            <img src={hundredFive} className='demo__image' alt='img_oneHundredFive' />
        </div>

        <div style={currentStep == 106 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 106: Adding Scripts to StarterPlayerScripts 📚</h2>
            <ul>
                <li>
                Right under 'StarterPlayer', you'll see 'StarterPlayerScripts'.
                </li>

                <li>
                Inside this, a 'LocalScript' has been added. Local scripts are used to run code on the player's computer, which is perfect for controlling UI elements and client-side events, such as displaying the "Cat Attack" message on only one players screen when they get attacked rather than everyone in the server.
                </li>
            </ul>

            <img src={hundredSix} className='demo__image' alt='img_oneHundredSix' />
        </div>

        <div style={currentStep == 107 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 107: Identifying the Player in the Script 🧑‍💻</h2>
            <ul>
                <li>
                Start by creating a reference to the player using local player = game.Players.LocalPlayer. This line is crucial because it tells the script which player we’re dealing with—specifically, the one who is running the script on their machine.
                </li>

                <li>
                By using LocalPlayer, you ensure that the script only affects the player’s own experience and not everyone else's in the game.
                </li>
            </ul>

            <img src={hundredSeven} className='demo__image' alt='img_oneHundredSeven' />
        </div>

        <div style={currentStep == 108 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 108: Handling Player Defeat 🛑</h2>
            <ul>
                <li>
                Below the line where you defined local player, define a new function with function whenDefeated.
                </li>

                <li>
                Below the line where you defined local player, define a new function with function whenDefeated(character).
                </li>

                <li>
                    This function will display the "Cat Attack" message when the player is defeated.
                </li>
            </ul>

            <img src={hundredEight} className='demo__image' alt='img_oneHundredEight' />
        </div>

        <div style={currentStep == 109 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 109: Waiting for the Humanoid Object 🕒</h2>
            <ul>
                <li>
                Inside the whenDefeated function, add local humanoid = character:WaitForChild("Humanoid"). This line makes sure your script waits until the Humanoid object is available in the character before doing anything else.
                </li>

                <li>
                The Humanoid object controls the health, animations, and other properties of a player’s character, making it essential for tracking the character’s state.
                </li>

                <li>
                The WaitForChild function is important because it prevents the script from continuing until the "Humanoid" object is found within the player's character. Since many scripts depend on the Humanoid for information about the character's state (like whether they're alive or defeated), using WaitForChild ensures that your code only runs when it has all the necessary information, avoiding errors and unintended behavior.
                </li>
            </ul>

            <img src={hundredNine} className='demo__image' alt='img_oneHundredNine' />
        </div>

        <div style={currentStep == 110 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 110: Responding to Player's Character Death 🔄</h2>
            <ul>
                <li>
                Inside the whenDefeated function, after defining the humanoid, set up an event handler.
                </li>

                <li>
                Within this anonymous function, grab the ScreenGui from the player with local screenGui = player:WaitForChild("PlayerGui"):WaitForChild("ScreenGui").
                </li>

                <li>
                Enable it by setting screenGui.Enabled = true, wait for 5 seconds with wait(5), and then disable it again with screenGui.Enabled = false.
                </li>

                <li>
                The Died event on the Humanoid object is crucial for triggering actions when the player's character is defeated. By connecting to this event, we can create an immersive response, such as temporarily displaying a defeat screen. 
                </li>
            </ul>

            <img src={hundredTen} className='demo__image' alt='img_oneHundredTen' />
        </div>

        <div style={currentStep == 111 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 111: Putting up Walls 🧱</h2>
            <ul>
                <li>
                    Add four walls around the "Factory Floor" in an effort to build out the rest of the building.
                </li>
            </ul>

            <img src={hundredEleven} className='demo__image' alt='img_oneHundredEleven' />
        </div>

        <div style={currentStep == 112 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 112: Placing the Ceiling 🏠</h2>
            <ul>
                <li>
                    Place a ceiling on the walls, so that the players are in a closed factory environment.
                </li>
            </ul>

            <img src={hundredTwelve} className='demo__image' alt='img_oneHundredTwelve' />
        </div>

        <div style={currentStep == 113 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 113: Interior Designer 🎉</h2>
            <ul>
                <li>
                    Add fun items from the toolbox to enhance your factory experience. Maybe you want to go for bright and fun? Or dark and ambient? Or more industrial? Now is the time to make this factory yours.
                </li>
            </ul>

            <img src={hundredThirteen} className='demo__image' alt='img_oneHundredThirteen' />
        </div>

        <div style={currentStep == 114 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 114: Testing and Publishing Your Game🔬🌐
            </h2>
            <h2>
            Testing Your Game Locally🕹️
            </h2>
            <ul>
                <li>
                In Roblox Studio, click on the "Home" tab at the top
                </li>
                <li>
                Click the "Play" button to test your game locally
                </li>
                <li>
                You'll spawn on the starting platform and can play through your game, ensuring that each feature works as intended
                </li>
                <li>
                If you find any issues, pause the game by clicking the "Stop" button, then make the necessary adjustments in your game and test again.
                </li>
                <li>
                Testing your game locally helps identify any potential problems or bugs before sharing it with the public. This ensures a smooth gaming experience for everyone!👩‍💻🎮
                </li>
            </ul>
        </div>

        <div style={currentStep == 115 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 115: Publishing Your Game to Roblox🚀
            </h2>
            <ul>
                <li>
                Once you're satisfied with your project, click on the "File" tab in the top left corner of Roblox Studio
                </li>
                <li>
                Select "Publish to Roblox" and choose "Create New Game" (or update an existing game if you've published it before)
                </li>
                <li>
                Fill in the required information, such as game name, description, and genre
                </li>
                <li>
                Set the game's thumbnail and icon to visually represent your game (optional)
                </li>
                <li>
                Click the "Publish" button to make your game live on the Roblox platform
                </li>
                <li>
                When you publish your game, you're sharing it with the entire Roblox community! This is your chance to showcase your creativity and see other players enjoy your adventure.🌟🌍
                </li>
            </ul>

            <h3>
            Developing a game on Roblox is no small feat, and it's a testament to your skills and passion for game development. Your commitment to learning, problem-solving, and bringing your ideas to life is truly inspiring. Building a game requires a combination of technical know-how, design expertise, and the ability to engage and entertain players, and you have clearly excelled in all these areas.<br/><br/>Not only have you demonstrated your talent in creating a game, but you have also showcased your ability to think critically, work through challenges, and persevere. These are invaluable skills that will serve you well not only in game development but also in various other areas of your life.<br/><br/>I encourage you to take pride in your achievement and to continue pursuing your passion for game development. Your dedication and talent are sure to lead to even greater successes in the future. Remember, the journey of learning and improvement is ongoing, and every project you undertake will help you grow and refine your skills.
            </h3>
        </div>

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
        


        
    </div>
  )
}

export default Cheese