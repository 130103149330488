import React from 'react';
import { useLocation } from 'react-router-dom';
import './scratchTutorial.css';
import Navbar from '../components/navbar/navbar';
import AppleCatch from '../tutorials/scratch/appleCatch';
import BugHunter from '../tutorials/scratch/bugHunter';
import DinoJump from '../tutorials/scratch/dinoJump';
import DragonPark from '../tutorials/scratch/dragonPark';
import Pong from '../tutorials/scratch/pong';
import UnderwaterAdventures from '../tutorials/scratch/underwaterAdventures';
import AngrySharks from '../tutorials/scratch/angrySharks';
import FoodFight from '../tutorials/scratch/foodFight';

const ScratchTutorial = () => {
  const location = useLocation();
  
  return (
    <div>
        <Navbar />

        {
            location.pathname === "/scratch-guide/apple-catch" &&
            <AppleCatch />
        }

        {
            location.pathname === "/scratch-guide/bug-hunter" &&
            <BugHunter />
        }

        {
            location.pathname === "/scratch-guide/dino-jump" &&
            <DinoJump />
        }

        {
            location.pathname === "/scratch-guide/dragon-park" &&
            <DragonPark />
        }

        {
            location.pathname === "/scratch-guide/pong" &&
            <Pong />
        }

        {
            location.pathname === "/scratch-guide/underwater-adventures" &&
            <UnderwaterAdventures />
        }

        {
            location.pathname === "/scratch-guide/angry-sharks" &&
            <AngrySharks />
        }

        {
            location.pathname === "/scratch-guide/food-fight" &&
            <FoodFight />
        }
    </div>
  )
}

export default ScratchTutorial