import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import one from './robloxImages/start/one.jpg';
import two from './robloxImages/start/two.png';
import three from './robloxImages/start/three.png';
import four from './robloxImages/start/four.png';
import signup from './robloxImages/start/signup.png';
import studioInstall from './robloxImages/start/studioInstall.png';

const Start = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 6;

  return (
    <div className='robloxGames__container'>
        <h1>
        🎉Getting started with our Itegrated Development Environment, Roblox Studio🎮
        </h1>

        <p className='desc'>
        Ready to craft, design, and bring to life your very own Roblox adventures? 🏰🛸 With Roblox Studio, you're the director, designer, and hero of your tales! 🎬🎩🎉Fun Fact: The most mind-blowing games on Roblox started with someone just like you - a big idea and the excitement to create! Today, you’re stepping onto a stage of infinite wonders and creations. 🌌🚀
        </p>

        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>

        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 1: Get Ready! 🖥️</h2>
            <ul>
                <li>
                <strong>Find a Computer:</strong> Grab a computer where you can install software. Roblox Studio isn’t available for tablets or phones.🖱️
                </li>
                <li>
                <strong>Internet Connection:</strong> Make sure your computer is connected to the internet. You’ll need it to download Roblox Studio.
                </li>
            </ul>


            <img src={one} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>Step 2: Create a Roblox Account 📝</h2>

            <ul>
                <li>
                Only complete this step if you do not have a Roblox account, as you will need one to access Roblox Studio❗❗
                </li>

                <li>
                <strong>Go to Roblox Website:</strong> Open your web browser and go to <a href="http://www.roblox.com" target="_blank">www.roblox.com</a>                
                </li>
                <li>
                <strong>Sign Up:</strong> Click on the "Sign Up" button.
                </li>

                <li>
                Fill Out the Form:
                <ul>
                    <li>
                    <strong>Username:</strong> Pick a cool username.
                    </li>

                    <li>
                    <strong>Password:</strong> Choose a strong password and remember it.
                    </li>
                </ul>
                </li>

                <li>
                <strong>Sign Up:</strong> Click the "Sign Up" button to create your account. 🎉
                </li>
            </ul>

            <img src={signup} className='demo__image' alt='img_one' />
        </div>


        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 3: Download Roblox Studio 📥
            </h2>

            <ul>
                <li>
                <strong>Visit the Download Page:</strong> Click on this link following link to go directly to the download page for Roblox Studio and install it: <a href="https://roblox.com/create" target="_blank">Download Roblox Studio</a> 🌐
                </li>

                <li>
                <strong>Download Roblox Studio:</strong> You’ll see a button that says "Download Studio" or "Start Creating". Click it, and the download will start automatically.
                </li>
            </ul>

            <img src={studioInstall} className='demo__image' alt='img_one' />
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 4: Install Roblox Studio 🛠️
            </h2>

            <ul>
                <li>
                <strong>Open the Downloaded File:</strong> After the download finishes, find the Roblox Studio installer in your "Downloads" folder.                
                </li>

                <li>
                <strong>Run the Installer:</strong> Double-click the installer file to open it. 🖱️💾
                </li>

                <li>
                <strong>Follow the Installation Guide:</strong> Just click “Next” and “Install” on the installation until the setup completes.
                </li>
            </ul>

            <img src={two} className='demo__image' alt='img_one' />
        </div>



        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 5: Open Roblox Studio 🚪
            </h2>

            <ul>
                <li>
                <strong>Locate Roblox Studio:</strong> Look for "Roblox Studio" on your desktop or in your start menu.
                </li>

                <li>
                <strong>Launch the Program:</strong> Double-click the Roblox Studio icon to open it.
                </li>
            </ul>

            <img src={three} className='demo__image' alt='img_one' />
        </div>


        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            Step 6: Start Creating! 🏗️
            </h2>

            <ul>
                <li>
                <strong>Log Into Your Account:</strong> If prompted, log in with your Roblox account.
                </li>

                <li>
                <strong>Unleash Your Creativity:</strong> Use the tools provided to build and design your dream game. Drag and drop items, and adjust them to fit your vision! 🏰🎢🚀
                </li>
            </ul>

            <img src={four} className='demo__image' alt='img_one' />
        </div>



        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default Start