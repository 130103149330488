import React, {useState} from 'react';
import one from './fullstackImages/comfyCouchCo/1.png';
import two from './fullstackImages/comfyCouchCo/2.png';
import three from './fullstackImages/comfyCouchCo/3.png';
import four from './fullstackImages/comfyCouchCo/4.png';
import five from './fullstackImages/comfyCouchCo/5.png';
import six from './fullstackImages/comfyCouchCo/6.png';
import seven from './fullstackImages/comfyCouchCo/7.png';
import eight from './fullstackImages/comfyCouchCo/8.png';
import nine from './fullstackImages/comfyCouchCo/9.png';
import ten from './fullstackImages/comfyCouchCo/10.png';
import eleven from './fullstackImages/comfyCouchCo/11.png';
import twelve from './fullstackImages/comfyCouchCo/12.png';
import thirteen from './fullstackImages/comfyCouchCo/13.png';
import fourteen from './fullstackImages/comfyCouchCo/14.png';
import fifteen from './fullstackImages/comfyCouchCo/15.png';
import sixteen from './fullstackImages/comfyCouchCo/16.png';
import seventeen from './fullstackImages/comfyCouchCo/17.png';
import eighteen from './fullstackImages/comfyCouchCo/18.png';
import nineteen from './fullstackImages/comfyCouchCo/19.png';
import twenty from './fullstackImages/comfyCouchCo/20.png';
import twentyOne from './fullstackImages/comfyCouchCo/21.png';
import twentyTwo from './fullstackImages/comfyCouchCo/22.png';
import twentyThree from './fullstackImages/comfyCouchCo/23.png';
import twentyFour from './fullstackImages/comfyCouchCo/24.png';
import twentyFive from './fullstackImages/comfyCouchCo/25.png';
import twentySix from './fullstackImages/comfyCouchCo/26.png';
import twentySeven from './fullstackImages/comfyCouchCo/27.png';
import twentyEight from './fullstackImages/comfyCouchCo/28.png';
import twentyNine from './fullstackImages/comfyCouchCo/29.png';
import thirty from './fullstackImages/comfyCouchCo/30.png';
import thirtyOne from './fullstackImages/comfyCouchCo/31.png';
import thirtyTwo from './fullstackImages/comfyCouchCo/32.png';
import thirtyThree from './fullstackImages/comfyCouchCo/33.png';
import thirtyFour from './fullstackImages/comfyCouchCo/34.png';
import thirtyFive from './fullstackImages/comfyCouchCo/35.png';
import thirtySix from './fullstackImages/comfyCouchCo/36.png';
import thirtySeven from './fullstackImages/comfyCouchCo/37.png';
import thirtyEight from './fullstackImages/comfyCouchCo/38.png';
import thirtyNine from './fullstackImages/comfyCouchCo/39.png';
import forty from './fullstackImages/comfyCouchCo/40.png';
import fortyOne from './fullstackImages/comfyCouchCo/41.png';
import fortyTwo from './fullstackImages/comfyCouchCo/42.png';
import fortyThree from './fullstackImages/comfyCouchCo/43.png';
import fortyFour from './fullstackImages/comfyCouchCo/44.png';
import fortyFive from './fullstackImages/comfyCouchCo/45.png';
import fortySix from './fullstackImages/comfyCouchCo/46.png';
import fortySeven from './fullstackImages/comfyCouchCo/47.png';
import fortyEight from './fullstackImages/comfyCouchCo/48.png';
import fortyNine from './fullstackImages/comfyCouchCo/49.png';
import fifty from './fullstackImages/comfyCouchCo/50.png';
import fiftyOne from './fullstackImages/comfyCouchCo/51.png';
import fiftyTwo from './fullstackImages/comfyCouchCo/52.png';
import fiftyThree from './fullstackImages/comfyCouchCo/53.png';
import fiftyFour from './fullstackImages/comfyCouchCo/54.png';
import fiftyFive from './fullstackImages/comfyCouchCo/55.png';
import fiftySix from './fullstackImages/comfyCouchCo/56.png';
import fiftySeven from './fullstackImages/comfyCouchCo/57.png';
import fiftyEight from './fullstackImages/comfyCouchCo/58.png';
import fiftyNine from './fullstackImages/comfyCouchCo/59.png';
import sixty from './fullstackImages/comfyCouchCo/60.png';
import sixtyOne from './fullstackImages/comfyCouchCo/61.png';
import sixtyTwo from './fullstackImages/comfyCouchCo/62.png';
import sixtyThree from './fullstackImages/comfyCouchCo/63.png';
import sixtyFour from './fullstackImages/comfyCouchCo/64.png';
import sixtyFive from './fullstackImages/comfyCouchCo/65.png';
import sixtySix from './fullstackImages/comfyCouchCo/66.png';
import sixtySeven from './fullstackImages/comfyCouchCo/67.png';
import sixtyEight from './fullstackImages/comfyCouchCo/68.png';
import sixtyNine from './fullstackImages/comfyCouchCo/69.png';
import seventy from './fullstackImages/comfyCouchCo/70.png';
import seventyOne from './fullstackImages/comfyCouchCo/71.png';
import seventyTwo from './fullstackImages/comfyCouchCo/72.png';
import seventyThree from './fullstackImages/comfyCouchCo/73.png';
import seventyFour from './fullstackImages/comfyCouchCo/74.png';
import seventyFive from './fullstackImages/comfyCouchCo/75.png';
import seventySix from './fullstackImages/comfyCouchCo/76.png';
import seventySeven from './fullstackImages/comfyCouchCo/77.png';
import seventyEight from './fullstackImages/comfyCouchCo/78.png';
import seventyNine from './fullstackImages/comfyCouchCo/79.png';
import eighty from './fullstackImages/comfyCouchCo/80.png';
import eightyOne from './fullstackImages/comfyCouchCo/81.png';
import eightyTwo from './fullstackImages/comfyCouchCo/82.png';
import eightyThree from './fullstackImages/comfyCouchCo/83.png';
import eightyFour from './fullstackImages/comfyCouchCo/84.png';
import eightyFive from './fullstackImages/comfyCouchCo/85.png';
import eightySix from './fullstackImages/comfyCouchCo/86.png';
import eightySeven from './fullstackImages/comfyCouchCo/87.png';
import eightyEight from './fullstackImages/comfyCouchCo/88.png';
import eightyNine from './fullstackImages/comfyCouchCo/89.png';
import ninety from './fullstackImages/comfyCouchCo/90.png';
import ninetyOne from './fullstackImages/comfyCouchCo/91.png';
import ninetyTwo from './fullstackImages/comfyCouchCo/92.png';
import ninetyThree from './fullstackImages/comfyCouchCo/93.png';
import ninetyFour from './fullstackImages/comfyCouchCo/94.png';
import ninetyFive from './fullstackImages/comfyCouchCo/95.png';
import ninetySix from './fullstackImages/comfyCouchCo/96.png';
import ninetySeven from './fullstackImages/comfyCouchCo/97.png';
import ninetyEight from './fullstackImages/comfyCouchCo/98.png';
import ninetyNine from './fullstackImages/comfyCouchCo/99.png';
import oneHundred from './fullstackImages/comfyCouchCo/100.png';
import oneHundredOne from './fullstackImages/comfyCouchCo/101.png';
import oneHundredTwo from './fullstackImages/comfyCouchCo/102.png';
import oneHundredThree from './fullstackImages/comfyCouchCo/103.png';
import oneHundredFour from './fullstackImages/comfyCouchCo/104.png';
import oneHundredFive from './fullstackImages/comfyCouchCo/105.png';
import oneHundredSix from './fullstackImages/comfyCouchCo/106.png';
import oneHundredSeven from './fullstackImages/comfyCouchCo/107.png';
import oneHundredEight from './fullstackImages/comfyCouchCo/108.png';
import oneHundredNine from './fullstackImages/comfyCouchCo/109.png';
import oneHundredTen from './fullstackImages/comfyCouchCo/110.png';
import oneHundredEleven from './fullstackImages/comfyCouchCo/111.png';
import oneHundredTwelve from './fullstackImages/comfyCouchCo/112.png';
import oneHundredThirteen from './fullstackImages/comfyCouchCo/113.png';
import oneHundredFourteen from './fullstackImages/comfyCouchCo/114.png';
import oneHundredFifteen from './fullstackImages/comfyCouchCo/115.png';
import oneHundredSixteen from './fullstackImages/comfyCouchCo/116.png';
import oneHundredSeventeen from './fullstackImages/comfyCouchCo/117.png';
import oneHundredEighteen from './fullstackImages/comfyCouchCo/118.png';
import oneHundredNineteen from './fullstackImages/comfyCouchCo/119.png';
import oneHundredTwenty from './fullstackImages/comfyCouchCo/120.png';
import oneHundredTwentyOne from './fullstackImages/comfyCouchCo/121.png';
import oneHundredTwentyTwo from './fullstackImages/comfyCouchCo/122.png';
import oneHundredTwentyThree from './fullstackImages/comfyCouchCo/123.png';
import oneHundredTwentyFour from './fullstackImages/comfyCouchCo/124.png';
import oneHundredTwentyFive from './fullstackImages/comfyCouchCo/125.png';
import oneHundredTwentySix from './fullstackImages/comfyCouchCo/126.png';
import oneHundredTwentySeven from './fullstackImages/comfyCouchCo/127.png';
import oneHundredTwentyEight from './fullstackImages/comfyCouchCo/128.png';
import CopyBox from './copyBox/copybox';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";

const ComfyCouchCo = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 128;

  return (
    <div className='development__container'>

        <h1>
        🛋️ Welcome to the "ComfyCouchCo" Online Project! 🛋️
        </h1>

        <h3 className='intro'>
        Settle in and get comfortable as we embark on a cozy journey to build and launch the online home for "ComfyCouchCo"—a place where comfort is just a click away. Whether you’re a seasoned developer or just starting out with your first line of HTML, this project is the perfect spot to relax and learn.        
        </h3>


        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>

          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>

        </div>


        


        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 1: Starting Your Web Adventure 🚀</h2>

          <p>
          Welcome, young coders! Are you ready to build your very own webpage? Let's start our coding journey by creating a new file. 📄
          </p>
          <img src={one} className='demo__image' alt='img_one' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Click on the File menu at the top left, then select New File. Type index.html to name your file and press Enter. This will be the canvas for your web masterpiece! 🎨
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            HTML File: This is the backbone of your website, where all the content and structure live.
            </li>

            <li>
            File Naming: We use .html at the end to tell the computer, "Hey, this is a webpage!" ✨
            </li>
          </ul>
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 2: Organizing Your Space 🏗️</h2>

          <p>
          Great job creating your index.html! Now let's keep our virtual room tidy by making a special place for our pictures, called a folder.
          </p>
          <img src={two} className='demo__image' alt='img_two' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In the coding world, we like to organize our files in folders. We're creating a folder named images to store all our lovely pictures. 🖼️
            </li>

            <li>
            Right-click on the name of your project folder (it might say something like COMFY-COUCH-CO). Choose New Folder from the menu and type images as the name. This is where all our picture files will live happily. 🏠
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Folders/Directories: These are like drawers in a dresser, keeping your files sorted.
            </li>

            <li>
            File Organization: We're being super neat, which makes finding things later a snap!
            </li>
          </ul>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 3: Filling Your Album 📸</h2>

          <p>
          You're doing fantastically! Now it's time to add some sparkle to our project with images. Every picture tells a story!
          </p>
          <img src={three} className='demo__image' alt='img_three' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            We've got a bunch of images, like chair.png and logo.png, waiting to jazz up our website. They're like the stickers for our digital diary! 🌟
            </li>

            <li>
              Download your image files into the images folder we just made. Each image will be a star on our web stage! 🌈
            </li>
          </ol>

          {/*<div className='download__buttons__container'>
            <Download src={backdrop} filename="backdrop.png" />

            <Download src={bricks} filename="bricks.png" />

            <Download src={cactus} filename="cactus.png" />

            <Download src={chair} filename="chair.png" />

            <Download src={features1} filename="features1.png" />

            <Download src={features2} filename="features2.png" />

            <Download src={features3} filename="features3.png" />

            <Download src={features4} filename="features4.png" />

            <Download src={features5} filename="features5.png" />

            <Download src={logo} filename="logo.png" />

            <Download src={product1} filename="product1.jpg" />

            <Download src={product2} filename="product2.jpg" />

            <Download src={product3} filename="product3.jpg" />

            <Download src={product4} filename="product4.jpg" />

            <Download src={profile1} filename="profile1.png" />

            <Download src={profile2} filename="profile2.png" />

            <Download src={profile3} filename="profile3.png" />

            <Download src={sofa} filename="sofa.png" />

            <Download src={table} filename="table.png" />

            
          </div>*/}


          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Image Formats: .png and .jpg are common image types that work great on the web.
            </li>
          </ul>
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 4: Crafting Your Style 🎩✨</h2>

          <p>
          You've built the structure, now let's dress it up! It's time to make our website snazzy with some styling fun. Ready to wave your style wand? 🪄
          </p>
          <img src={four} className='demo__image' alt='img_four' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            We are adding a new file named style.css. This is where we will write all the CSS rules to make our website look cool and stylish. 🌈
            </li>

            <li>
            In your project folder, right-click and select New File. Type in style.css and press Enter. Imagine this file as your coloring book, where you'll add colors and patterns to your site. 🎨
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            CSS (Cascading Style Sheets): This is where we set the look of our webpage, like picking out costumes for a show. 🎉
            </li>

            <li>
            .css Extension: Just like .html tells the computer it's a webpage, .css tells it you’re about to get stylish!
            </li>
          </ul>
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 5: Laying the Foundation Bricks 🏗️🧱</h2>

          <p>
          Now, let’s start building with some HTML!
          </p>
          <img src={five} className='demo__image' alt='img_five' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Click on index.html to open your webpage file. 
            </li>

            <li>
            &lt;!DOCTYPE html&gt; This is like saying, “Hey browser, I'm going to speak in HTML!”
            </li>

            <li>
            &lt;html lang="en"&gt; Here we start the HTML document and tell the browser we're using English.
            </li>

            <li>
            Don’t forget your closing &lt;/html&gt; tag at the end. 
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            DOCTYPE Declaration: This line is the official starter flag for HTML5, the latest HTML version.
            </li>

            <li>
            Opening and Closing Tags: They wrap around our content like a sandwich. Make sure every &lt;tag&gt; has a &lt;/tag&gt; buddy. 🥪
            </li>
          </ul>

          <p>
          Great job, young coders! Keep up the energy as you transform your code into a magnificent digital castle! 🏰✨
          </p>
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 6: The Website Secrets 📜</h2>

          <p>
          This is where we add some code to help our webpage work on all devices.
          </p>
          <img src={six} className='demo__image' alt='img_six' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Let's add these lines in your index.html file inside the &lt;head&gt;
            </li>

            <li>
            &lt;meta charset="UTF-8"&gt; This ensures our webpage speaks in the universal language of characters, so no funny squiggles instead of letters! 📝
            </li>

            <li>
            &lt;meta name="viewport" content="width=device-width, initial-scale=1.0"&gt; This helps our page look good on all devices.
            </li>

            <li>
            &lt;title&gt;Comfy Couch Co&lt;/title&gt; Write the title of our adventure here; this is what shows up on the browser tab.
            </li>

            <li>
            &lt;link href="./style.css" rel="stylesheet" /&gt; This line links our HTML page to the CSS stylesheet. 
            </li>

            <li>
              Add the &lt;body&gt; tags so that we can later add our visible content of the website.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Meta Tags: These are like the rules for browsers, telling them how to behave.
            </li>

            <li>
            Title Tag: Every book has a title, and this is where we give our webpage its name.
            </li>
          </ul>
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 7: Crafting the Navigation Bar 🧭</h2>

          <p>
          The navigation bar is like the signpost of your website—it guides visitors to different parts of your site. It's crucial for good user experience!
          </p>
          <img src={seven} className='demo__image' alt='img_seven' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            We are creating a div element with the class navbar-container. div elements are like invisible boxes that help us organize content on our web page. By giving it a class name, we can apply specific styles to this div and anything inside it.
            </li>

            <li>
            In the &lt;body&gt; of your index.html, create a new div by typing &lt;div class="navbar-container"&gt;&lt;/div&gt;. This div will be the container for your navigation bar, and later, we will add items to it like links to other pages or sections of your website.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            &lt;div&gt; Elements: They are used to group other elements and help with layout and styling.
            </li>

            <li>
            Classes: These are identifiers that you can use to target elements with CSS and apply styles.
            </li>

            <li>
            Structure and Semantics: Even though div elements don’t carry any meaning themselves, using them with proper class names helps keep your HTML organized and makes it easier to read and maintain.
            </li>
          </ul>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 8: Segmenting the Navigation Bar 🛠️</h2>

          <p>
          To keep things organized and provide a better user experience, we often divide the navigation bar into sections, like the left and right areas.
          </p>
          <img src={eight} className='demo__image' alt='img_eight' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Within our navbar-container, we are adding two more div elements, each with its own class: navbar-left and navbar-right. This allows us to align parts of our navigation to the left or right, which is a common design pattern.
            </li>

            <li>
            Inside the navbar-container div, create two new div elements. The first with class="navbar-left" and the second with class="navbar-right". These will hold different navigation items. For instance, the left side could have links to your product pages, while the right side might contain a login link or a shopping cart icon.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Segmentation in Layout: Dividing parts of your webpage into segments can help with the design and layout. It allows you to style and position them as groups.
            </li>

            <li>
            Responsive Design: By using navbar-left and navbar-right, you can later use CSS to adapt the navigation bar to different screen sizes, ensuring it looks good on both desktops and mobile devices.
            </li>
          </ul>

          <p>
          With these steps, you're setting the foundation for a user-friendly navigation system on your website. Remember, the key to a good website is not just how it looks, but also how easy it is to navigate and find information.
          </p>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 9: Inserting the Logo in the Navigation Bar 🧭</h2>

          <p>
          A logo serves as the identity of your website. It's usually the first thing visitors notice, so it’s positioned prominently in the navigation bar.
          </p>
          <img src={nine} className='demo__image' alt='img_nine' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            This img tag is nested inside the div with the class navbar-left, indicating its position on the left side of the navigation bar.
            </li>

            <li>
            Within the navbar-left div, add an img tag.
            </li>

            <li>
            Set the src attribute to the path of your logo file, which should be saved inside the images folder. 
            </li>

            <li>
            The alt attribute is used to describe the image for users who may not be able to see it, like those using screen readers or if the image fails to load.
            </li>
          </ol>


          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            &lt;img/&gt; Tag: This self-closing tag is used to embed images into a web page.
            </li>

            <li>
            src Attribute: It specifies the path to the image you want to display.
            </li>

            <li>
            alt Attribute: Provides alternative information for an image if a user for some reason cannot view it (because of slow connection, an error in the src attribute, or if the user uses a screen reader).
            </li>
          </ul>
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 10: Adding Navigation Links 🔗</h2>

          <p>
          Navigation links are like doorways to different rooms in your website. They are essential for helping visitors find their way around.
          </p>
          <img src={ten} className='demo__image' alt='img_ten' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            After the img tag within the navbar-left div, add several p tags, each containing the name of a section or page of your website, like "All Products," "Sofas," "Chairs," and "Tables."
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            &lt;p&gt; Tag: The paragraph tag is used to define paragraphs of text.
            </li>
          </ul>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 11: Completing the Navigation Bar 🧭</h2>

          <p>
          Just as we added content to the left side of the navigation bar, we're now populating the right side. We've created another div with the class navbar-right and included more text elements within p tags. These items typically include links to user accounts, information about the company, and a shopping cart or total cost for online stores.          
          </p>
          <img src={eleven} className='demo__image' alt='img_eleven' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Inside the navbar-right div, continue to use p tags to add text elements such as "About Us," "My Account," and "$0.00," which might represent a shopping cart total or could be a placeholder for user-specific information.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Balanced Navigation Bar: A well-designed navigation bar usually has a visual balance, often achieved by having elements on both the left and right sides.
            </li>

            <li>
            Placeholder Content: The text "$0.00" can serve as a placeholder for dynamic content that will change when the site is fully functional, such as showing a user's cart total.
            </li>
          </ul>

          <p>
          By adding these elements to your navigation bar, you're setting up a framework that will make it easy for visitors to find the information they need and navigate your site effectively.
          </p>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 12: Importing a Font 🎨</h2>

          <p>
          In this step, we're enhancing the visual appeal of our website by importing a custom font using CSS.
          </p>
          <img src={twelve} className='demo__image' alt='img_twelve' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            The @import rule in CSS is used to import a font from an external source, in this case, Google Fonts. This font will be used across the entire website for a consistent look and feel.
            </li>

            <li>
            Open your style.css file and write the @import rule at the top of your file. This should be the first line, as it needs to load before any other styles are applied. The URL provided in the rule points to the font family "Roboto," which is a versatile, modern sans-serif font.
            </li>

            <li>
              You can copy and paste it from the box below.
            </li>
          </ol>

          <CopyBox
          text={"@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');"}
          />

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Font Family: This is a set of one or more fonts each with a different weight (thickness), style (italic/bold), and size.
            </li>

            <li>
            Google Fonts: A library of free licensed font families that you can use on your website.
            </li>
          </ul>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 13: Setting Global Styles 🌐</h2>

          <p>
          Now, let's establish some global styles for our website, which will apply to all elements unless otherwise specified.
          </p>
          <img src={thirteen} className='demo__image' alt='img_thirteen' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Right below the @import rule in your style.css file, create a new style block using the universal selector. Set margin and padding to 0, and box-sizing to border-box. This helps create a consistent starting point for styling your elements.
            </li>

            <li>
            You're using the universal selector (*) in CSS, which targets all elements on the webpage. Here, we're setting the default margin and padding to 0 to remove any browser default spacing, and setting the box-sizing to border-box, which changes how the width and height of elements are calculated.
            </li>

            <li>
              Set the font to the one we have imported.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Universal Selector (*): Targets all HTML elements on the page.
            </li>

            <li>
            Margin and Padding: The space outside and inside an element, respectively.
            </li>

            <li>
            Box-sizing: Determines how the dimensions of elements are calculated. border-box includes padding and border in the element's total width and height.
            </li>

            <li>
            Font Family: Setting a global font family ensures all your text uses the "Roboto" font unless it's overridden by more specific styles.
            </li>
          </ul>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 14: Setting Body Padding 📐</h2>

          <p>
          Padding is used to create space around an element's content, inside of any defined borders. We will apply padding to the &lt;body&gt; to ensure that the content has a consistent space around it, which enhances readability and aesthetics.
          </p>
          <img src={fourteen} className='demo__image' alt='img_fourteen' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Within your style.css file, target the body element and set the padding-left and padding-right properties to 68px. This will add a 68-pixel padding on the left and right sides.
            </li>

            <li>
            The body selector targets the main content area of your HTML document. By adding padding-left and padding-right, we are creating horizontal space on both sides of the body's content, preventing it from stretching all the way to the edges of the browser window.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Padding: Controls the space between the content of the element and its border.
            </li>

            <li>
            Readability: Adequate padding can make content more legible and visually appealing.
            </li>
          </ul>
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 15: Styling the Navbar Container with Flexbox 🧩</h2>

          <p>
          With the .navbar-container class, we're introducing Flexbox to create a flexible and responsive navigation bar. The display: flex; property activates Flexbox, and flex-direction: row; defines the main axis as horizontal, which is standard for navigation bars.
          </p>
          <img src={fifteen} className='demo__image' alt='img_fifteen' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Target the .navbar-container in your CSS and apply display: flex; to establish a Flexbox layout. Then, specify flex-direction: row; to align the child elements (the logo and navigation links) horizontally.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Flex Container: The element on which display: flex; is applied. It becomes a Flex container.
            </li>

            <li>
            Flex Direction: This defines the direction of the main axis and thus the direction in which the child elements are laid out.
            </li>
          </ul>
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 16: Aligning Navbar Items</h2>

          <p>
          The align-items and justify-content properties in a Flexbox layout provide control over the alignment and distribution of items within the container.
          </p>
          <img src={sixteen} className='demo__image' alt='img_sixteen' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            By setting align-items: center, we ensure that the items in the .navbar-container are centered vertically within the container. The justify-content: space-between property spaces out the items evenly across the horizontal axis, giving space between the groups of navigation items.
            </li>

            <li>
            Add these two properties to your .navbar-container rule in the style.css file to align the navigation items properly within the navbar.
            </li>
          </ol>

        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 17: Defining Navbar Dimensions</h2>

          <p>
          Setting explicit dimensions for elements can help to create a predictable and consistent layout for your web page.
          </p>
          <img src={seventeen} className='demo__image' alt='img_seventeen' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Define the width, height, and margin-top properties for the .navbar-container to set its size and spacing on the page.
            </li>

            <li>
              The width: 100% ensures the navbar spans the entire width of the viewport, height: 78px sets a fixed height for the navbar, and margin-top: 28px adds some space above the navbar, separating it from any content that may be above it.
            </li>
          </ol>

        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 18: Lining Up the Navbar-Left Items 🚏</h2>

          <p>
          We’re going to line up the items on the left side of our navigation bar using the Flexbox model.
          </p>
          <img src={eighteen} className='demo__image' alt='img_eighteen' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In your style.css, create a new style rule for .navbar-container .navbar-left. Apply Flexbox by using display: flex; and ensure the content flows horizontally with flex-direction: row;.
            </li>

            <li>
            The CSS rule .navbar-container .navbar-left targets the child element with the class navbar-left within the parent .navbar-container.
            </li>

            <li>
            By setting display: flex and flex-direction: row, all direct child elements of .navbar-left will be in a row, horizontally aligned.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Row Direction: This sets a horizontal flow for all the direct children of .navbar-left, which can be individual navigation links or other elements.
            </li>
          </ul>

          <p>
          This step ensures that the items on the left side of your navigation bar are aligned neatly, contributing to the clean and professional look of your website's header.
          </p>
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 19: Aligning and Spacing the Left Navigation 📏</h2>

          <p>
          For a more refined control over the navigation bar's appearance, we apply additional styling to the .navbar-left class to control how items are spaced and aligned.
          </p>
          <img src={nineteen} className='demo__image' alt='img_nineteen' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Amend the .navbar-container .navbar-left rule in your style.css file by adding justify-content: space-between; and width: 45%;. These properties work with Flexbox to space out the child elements and define the width of the left section of the navbar.
            </li>

            <li>
            We're adding more properties to .navbar-container .navbar-left to perfect the spacing. The justify-content: space-between property will space the child elements evenly, and the width: 45%; property ensures that .navbar-left only takes up 45% of the .navbar-container width, providing a responsive layout.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            justify-content: This Flexbox property aligns children within a container according to the specified parameter, which in this case, ensures that there is equal space between each child element.
            </li>

            <li>
            Width Percentage: Using a percentage for the width allows for a fluid and responsive design, adapting to the screen size.
            </li>
          </ul>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 20: Interactive Cursor for Navigation Items 🖱️</h2>

          <p>
          Interactive elements often change the cursor to indicate they can be clicked, improving user experience by providing visual feedback.
          </p>
          <img src={twenty} className='demo__image' alt='img_twenty' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Inside your style.css, target the p elements within .navbar-left by writing a new rule for .navbar-container .navbar-left p. Set the cursor property to pointer to change the cursor type on hover.
            </li>

            <li>
            The cursor: pointer; style changes the cursor to a pointing hand when a user hovers over the paragraph elements within .navbar-left, indicating that they are interactive.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Cursor Property: This defines the type of cursor to be displayed when pointing at an element.
            </li>

            <li>
            Hover Feedback: Changing the cursor type on hover provides a clear indication that an element is clickable.
            </li>

            <li>
            Nested Selectors: .navbar-container .navbar-left p is an example of a nested selector, where you specify a style for elements that are inside a specific parent element.
            </li>
          </ul>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 21: Styling the Logo Image 🎨</h2>

          <p>
          Properly styling the logo is key to ensuring it stands out as a focal point of your navigation bar.
          </p>
          <img src={twentyOne} className='demo__image' alt='img_twentyOne' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Write a CSS rule targeting the logo image within the left side of the navbar. Specify its width, object fit, and cursor style to ensure it displays nicely and invites interaction.
            </li>

            <li>
            The .navbar-container .navbar-left img selector targets the img element inside the .navbar-left class. 
            </li>

            <li>
            The width: 40%; property sets the width of the logo relative to its container, object-fit: contain; ensures that the logo scales correctly to fit this width without distortion, and cursor: pointer; indicates that the logo is clickable, typically leading back to the home page.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Width in Percentages: Using percentages for width allows the image to resize responsively with the viewport or container size.
            </li>

            <li>
            Object Fit: This CSS property is used to specify how an img or video should be resized to fit its container. contain means the image will scale to maintain its aspect ratio while fitting within the element's content box.
            </li>

            <li>
            Clickable Images: Setting cursor: pointer; changes the cursor to the hand icon on hover, making it clear that the logo is a clickable link.
            </li>
          </ul>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 22: Configuring the Right-Side Navbar 🛠️</h2>

          <p>
          Similar to the left side, we need to arrange the items on the right side of the navigation bar. We’ll use Flexbox again for a consistent and responsive layout.
          </p>
          <img src={twentyTwo} className='demo__image' alt='img_twentyTwo' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In your style.css file, target the .navbar-right class nested inside .navbar-container. 
            </li>

            <li>
            Set it to display as a Flexbox and define the properties to align the items. Additionally, set the width to control how much space this section of the navbar occupies.
            </li>

            <li>
            The .navbar-container .navbar-right CSS rule targets the div with the class navbar-right. Applying Flexbox properties to this class allows us to align the items horizontally and distribute them evenly with space between.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Flexbox Alignment: Using align-items: center; vertically centers the items in the navbar, while justify-content: space-between; places an equal amount of space between each item.
            </li>

            <li>
            Width Control: Assigning a width of 25% ensures that this part of the navbar does not stretch too far across the container, which can help maintain the visual balance between the left and right sections.
            </li>
          </ul>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 23: Making Right Navbar Items Clickable 🖱️</h2>

          <p>
          Ensuring that navigation elements respond to user interaction is an important part of web design. It enhances the user experience by signaling that elements are interactive.
          </p>
          <img src={twentyThree} className='demo__image' alt='img_twentyThree' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In your style.css, add a rule for paragraph tags within the .navbar-right class. Set the cursor to indicate a pointer on hover to imply that the text is meant to be clicked.
            </li>

            <li>
            The .navbar-container .navbar-right p CSS rule targets paragraph elements inside the navbar-right division of the navigation bar. 
            </li>

            <li>
            The cursor: pointer; style is applied to change the cursor to a hand icon when hovering over these elements, which suggests to users that the text is clickable.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Cursor Property: This defines the type of mouse cursor displayed when hovering over an element. A pointer cursor typically indicates a link or an actionable item.
            </li>

            <li>
            User Feedback: Visual feedback such as changing the cursor on hover helps indicate interactivity, making the interface more intuitive.
            </li>
          </ul>
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 24: Introducing the Hero Banner 🌟</h2>

          <p>
          A hero banner is a large, prominent section typically placed at the top of a webpage, designed to grab a user's attention with important content or calls to action.
          </p>
          <img src={twentyFour} className='demo__image' alt='img_twentyFour' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Right below the closing tag of your .navbar-container div, insert a new div and assign it the class hero-banner.
            </li>

            <li>
            This section will later be styled with background images, text, and other elements to highlight key content.
            </li>

            <li>
            We're adding a div element with the class hero-banner. This div will be styled to create a visually striking area that usually contains the main headline and a call-to-action (CTA) button or image.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Hero Banner: This is a design term for a large header area that is the first visual a visitor encounters on the site.
            </li>

            <li>
            Class Naming: By giving the div a class name of hero-banner, we can specifically target this section with CSS for styling.
            </li>
          </ul>
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 25: Populating the Hero Banner with Content 🖋️</h2>

          <p>
          The hero banner is the showcase of your site. Adding a compelling headline and a call-to-action (CTA) button can guide users towards important actions you want them to take.
          </p>
          <img src={twentyFive} className='demo__image' alt='img_twentyFive' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Within your hero-banner div, insert an &lt;h1&gt; tag for your main headline. Use the &lt;br/&gt; tag to break the line where you want to create a separation. Then, add a &lt;button&gt; with text that encourages action, like "Explore Store."
            </li>

            <li>
            In the hero-banner div, an &lt;h1&gt; tag is used to create a large, eye-catching headline. The line breaks &lt;br/&gt; ensure that the text is split over multiple lines for emphasis. A &lt;button&gt; tag is used to add a CTA, which is a key part of the hero section, prompting the user to take an action, like exploring the store.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
              &lt;h1&gt; Tag: This is the highest level heading tag and is typically used for main titles or important content on a page.
            </li>

            <li>
            Line Breaks (&lt;br/&gt;): These tags insert a single line break, useful for formatting titles or poetry.
            </li>

            <li>
            CTA Button: A button in a web page that prompts the user to take an action. It should be prominent and clearly labeled.
            </li>
          </ul>
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 26: Structuring the Hero Banner with Flexbox 🏗️</h2>

          <p>
          In this step, we will structure the hero banner using Flexbox, a powerful layout tool in CSS that makes it easier to design flexible responsive layout structures.
          </p>
          <img src={twentySix} className='demo__image' alt='img_twentySix' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Open your style.css file and add the .hero-banner class.
            </li>

            <li>
            Add display: flex; to make .hero-banner a flex container.
            </li>

            <li>
            Then add flex-direction: column; to stack the child elements vertically.
            </li>

            <li>
            Use align-items: flex-start; to align these items to the start of the cross axis (to the left).
            </li>

            <li>
            Finally, add justify-content: flex-start; to align the child elements to the start of the main axis (at the top).
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Flexbox Container: By declaring display: flex;, you're specifying that .hero-banner should behave as a Flexbox container.
            </li>

            <li>
            Column Direction: The flex-direction: column; property places the children in a top-down flow, which is standard for stacking items like headings and buttons.
            </li>

            <li>
            Alignment: align-items and justify-content are used to align the flex items horizontally and vertically within the container. Using flex-start aligns the items at the beginning of their respective axis.
            </li>
          </ul>
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 27: Defining the Hero Banner Size 📏</h2>

          <p>
          Now that you've set up the structure for your hero banner, it's time to define its size to ensure it captures the attention it deserves.
          </p>
          <img src={twentySeven} className='demo__image' alt='img_twentySeven' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In your style.css file, locate the .hero-banner class.
            </li>

            <li>
            Add width: 100%; to ensure the banner spans the entire width of the screen.
            </li>

            <li>
            Specify height: 520px; to give the banner a fixed height.
            </li>

            <li>
            By specifying width and height properties for the .hero-banner, you're setting the full dimensions of this section. A width of 100% makes the hero banner stretch across the full width of the viewport, while a height of 520px provides a tall, impactful space for your content and images.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Fixed Height: The height is set to a fixed pixel value, which will remain constant regardless of the screen size. This can be adjusted later with media queries for responsiveness.            
            </li>
          </ul>
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 28: Spacing and Padding the Hero Banner 🌌</h2>

          <p>
          Great design often comes down to spacing. Now, we'll focus on giving content inside the hero banner room to breathe with margins and padding.
          </p>
          <img src={twentyEight} className='demo__image' alt='img_twentyEight' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Add margin-top: 38px; to create space above the hero banner.
            </li>

            <li>
            Add margin-bottom: 38px; to create space below the hero banner.
            </li>

            <li>
            Set padding: 92px; to define the space inside the banner, surrounding the content.
            </li>

            <li>
            In the .hero-banner class, margin-top and margin-bottom properties are used to create vertical space between the hero banner and other elements on the page. The padding property is applied to create space within the hero banner, around the content.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Margin: This property controls the space outside an element's border. By setting margin-top and margin-bottom, you're ensuring the hero banner doesn't touch other elements directly, which could make the page feel cramped.
            </li>

            <li>
            Padding: This property controls the space inside an element's border. Adequate padding makes content easier to read and visually appealing.
            </li>

            <li>
            Vertical Space: The vertical margins help to visually separate the hero banner from the content above and below, making it stand out.
            </li>
          </ul>
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 29: Adding a Background to the Hero Banner 🖼️</h2>

          <p>
          A background image can make your hero banner pop and immediately draw the viewer's attention. Let's add one to your hero banner.
          </p>
          <img src={twentyNine} className='demo__image' alt='img_twentyNine' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Stay within the .hero-banner rule in your style.css.
            </li>

            <li>
            Type background-image: url('./images/chair.png'); to set the background image.
            </li>

            <li>
            Add background-position: center; to align the background image in the center of the banner.
            </li>

            <li>
            Include background-repeat: no-repeat; to prevent the image from repeating.
            </li>

            <li>
            Finally, insert background-size: cover; so the image covers the entire banner area evenly.
            </li>

            <li>
            You're adding CSS properties to the .hero-banner class to include a background image. The background-image property sets the image you want to display. background-position: center; ensures the image is centered in the container, background-repeat: no-repeat; stops the image from tiling, and background-size: cover; ensures the image covers the entire area of the container without stretching.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Background Image: The background image is a visual element that can communicate the theme of your website or highlight a product or service.
            </li>

            <li>
            Centering a Background: Centering is often used for background images to ensure the most important part of the image is visible.
            </li>

            <li>
            Covering Background: When background-size is set to cover, it will make sure the background image covers all available space, even if it has to be cut off a bit on the edges.
            </li>
          </ul>
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 30: Styling the Hero Banner Headline 🌈</h2>

          <p>
          The headline in your hero banner is what communicates the core message to your audience. It needs to be visually prominent and well-styled to capture attention.
          </p>
          <img src={thirty} className='demo__image' alt='img_thirty' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In your CSS file, target the &lt;h1&gt; element inside the .hero-banner class.
            </li>

            <li>
            Set the font size to 58px with the line font-size: 58px;.
            </li>

            <li>
            Define the color of the headline by adding color: #607D8B;.
            </li>

            <li>
            We are styling the &lt;h1&gt; element within the .hero-banner class. The font-size property is being set to 58px to make the headline large and legible, and the color property is set to #607D8B to give the text a distinct color that should stand out against the banner's background.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Font Size: This property controls how big or small text appears. For headlines, larger font sizes are often used to draw attention.
            </li>

            <li>
            Color Property: This defines the text color. Hexadecimal values, like #607D8B, allow for precise color specification.
            </li>

            <li>
            CSS Selectivity: Targeting .hero-banner h1 means you're styling only the &lt;h1&gt; elements that reside within elements with the class .hero-banner, not affecting other &lt;h1&gt; elements elsewhere.
            </li>
          </ul>

          <p>
          By completing this step, the headline of your hero banner will be both visible and stylistically harmonious with the rest of your site's design.
          </p>
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
        <h2>Step 31: Styling the Call-to-Action Button in the Hero Banner 🎨</h2>

          <p>
          To draw attention to the main call-to-action (CTA) button, we'll add distinct styling within our hero banner. This makes the button stand out and invites interaction from the user.
          </p>
          <img src={thirtyOne} className='demo__image' alt='img_thirtyOne' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Inside your style.css file, target the .hero-banner button rule. We will be defining the visual aspects of the button to ensure it captures user attention.
            </li>

            <li>
            Add a border of 2 pixels, solid, with the color #607D8B to give the button a defined edge. This color choice should complement the overall design palette of the hero banner.
            </li>

            <li>
            Set the background-color property to transparent to maintain the button's seamless integration with the hero banner's background, providing a modern and clean appearance.
            </li>
            <li>
            Finally, set the color of the button's text to #607D8B. This consistency in color between the border and the text maintains a cohesive design and enhances readability.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            border: The border property can add a visible edge to elements, which can be used to highlight interactive components like buttons.
            </li>

            <li>
            background-color: This property sets the background of an element, which can be made transparent to allow underlying design elements to show through.
            </li>

            <li>
            color: This property changes the color of the text inside an element, which is vital for readability and visual harmony within a design.
            </li>
          </ul>
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 32: Turning Up the Style on Our Hero Button ✨</h2>

          <p>
          We've got a button in our hero banner that's begging for a style upgrade! We're going to give it a text makeover so it can speak to our visitors with confidence.
          </p>
          <img src={thirtyTwo} className='demo__image' alt='img_thirtyTwo' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            First things first, let's give our button some boldness. Find the .hero-banner button in your CSS file and crank up the font-weight to 500. It's like hitting the gym but for your text—getting those bold gains!
            </li>

            <li>
            Now, we want everyone to read our button's message loud and clear, so pump up the font-size to 18px. Bigger font means it's easier on the eyes, like a signpost on the digital highway!
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            font-weight is all about the thickness of your text. It can be light and airy or thick and assertive. By setting it to 500, we're choosing a nice middle ground.
            </li>

            <li>
            font-size controls how big your text appears. With 18px, it's just the right size to be noticed without shouting at your visitors.
            </li>
          </ul>
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 33: Perfecting the Hero Button's Comfort Zone 🛋️📏</h2>

          <p>
          Let's make sure our hero button isn't just stylish but also has its personal space! We're talking width, height, and even a little breathing room on top so it can sit back and relax.
          </p>
          <img src={thirtyThree} className='demo__image' alt='img_thirtyThree' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            First, define the button's own space by setting the width to 182px. That's like giving it its own little room in the vast internet space.
            </li>

            <li>
            Next up, the height! Set it to a cozy 42px to ensure it's neither too slim nor too chunky—just perfect for clicking.
            </li>

            <li>
            And for the cherry on top, let's not forget a comfortable margin-top of 28px. This gives our button a little lift, like a comfy cushion, so it's not squished at the top.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            width is like the button's personal bubble. It determines how much room it takes from side to side.
            </li>

            <li>
            height is the button's stature. Just right, and it'll stand proud without towering over the content.
            </li>

            <li>
            margin-top is like the first-class legroom for elements. It prevents our button from feeling cramped and gives it the VIP treatment it deserves.
            </li>
          </ul>
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 34: Making Our Hero Button Interactive 🕹️✨</h2>

          <p>
          Now it's time to add a secret superpower to our button—interactivity! When our users hover over it, we want them to feel like they're about to start a grand adventure. So let's add some cool effects that say, "Click me, and let's go!"
          </p>
          <img src={thirtyFour} className='demo__image' alt='img_thirtyFour' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Find that heroic button in your CSS file, and let's make it respond to our user's actions by adding cursor: pointer;. This is like rolling out the red carpet, inviting users to step right up and click!
            </li>

            <li>
            Next, sprinkle in some animation magic with transition: 0.2s all ease-in;. This smoothens the button's transformations, giving it a graceful dance move when changes happen. It's like our button is saying, "I can moonwalk!" 🌜
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            cursor: pointer; tells the browser to display a pointer cursor, signaling an interactive element you can click on. It's like a digital "Welcome" sign!
            </li>

            <li>
            transition is all about smooth moves. It's the difference between a robot dance and a ballet spin, making style changes flow seamlessly.
            </li>
          </ul>
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 35: The Hover Highlight 🎥🌟</h2>

          <p>
          Our hero button is almost ready for its spotlight. Now, we want to roll out the special effects when someone hovers over the button. Let's make it pop with color and style!
          </p>
          <img src={thirtyFive} className='demo__image' alt='img_thirtyFive' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In your CSS, it's time to add a hover effect with .hero-banner button:hover. This special rule means "when hovered over, do something cool"!
            </li>

            <li>
            Set the border and background-color to #607D8B, which will fill in the button with a beautiful color, like dipping it into a paint bucket.
            </li>

            <li>
            Now change the text color to #ffffff (that's white). It'll shine bright like a diamond in the sky, making the text super readable and snazzy.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            The :hover pseudo-class is a command for your CSS, activating special styles when a user points their mouse at the element.
            </li>

            <li>
            Changing background-color and border on hover gives immediate visual feedback, which is like a secret handshake saying, "Yes, you're interacting with me!"
            </li>

            <li>
            Switching the text color on hover is a classic move for button distinction, like changing costumes on stage for the next scene.
            </li>
          </ul>
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 36: Crafting the Foundation of Our Section 🏗️</h2>

          <p>
          Time to build the base of our new section, like laying down the first brick of a Lego masterpiece. This is where we'll start shaping the future home of some awesome content!
          </p>
          <img src={thirtySix} className='demo__image' alt='img_thirtySix' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Dive into the HTML and find and create the new &lt;div&gt; with the class of section-one. This is the container where all our cool stuff will live. Think of it as the plot of land for your dream house!
            </li>

            <li>
            For now, it's just an empty lot, but not for long. Imagine the possibilities and get ready to fill it with creativity and code!
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            A &lt;div&gt; is like a cardboard box—it can hold anything. We use classes to label our boxes, so we don't mix up holiday decorations with summer clothes.
            </li>

            <li>
            class="section-one" is like a nametag for our &lt;div&gt;. It helps us find which box we need when we're rummaging through the attic of our code.
            </li>
          </ul>
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 37: Dividing Our Section into Columns 📊🔀</h2>

          <p>
          Why have one space when you can have two? It's time to split our section into a left and right side, like peanut butter and jelly. Each side will have its own flavor!
          </p>
          <img src={thirtySeven} className='demo__image' alt='img_thirtySeven' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Inside our section-one container, create two new &lt;div&gt;s: one with the class section-one-left and the other with section-one-right. This is like drawing a line in the sandbox to make sure our sandcastles have their own neighborhoods.
            </li>

            <li>
            Now we've got a sweet setup for a two div layout.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            By giving each &lt;div&gt; a different class, we're assigning them roles. It's like telling one actor to be Romeo and the other Juliet in our play—each has its unique part in the story.
            </li>
          </ul>
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 38: Filling Up the Right Space with Goodies 🍬👉</h2>

          <p>
          It's showtime in the section-one-right! Let's fill up this empty space with something that'll catch eyes and hearts. We're talking big titles, enticing offers, and buttons that beg to be clicked!
          </p>
          <img src={thirtyEight} className='demo__image' alt='img_thirtyEight' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Inside the right-hand side div, we're gonna drop a shiny &lt;h2&gt; tag. Your neon sign that says "Chair Collection!"
            </li>

            <li>
            Next up, a &lt;p&gt; tag with a sweet deal that no one can resist—how does "Launch Offer 15% Off!" sound?
            </li>

            <li>
            And the grand finale, a &lt;button&gt; that's like a treasure chest in a video game, it just needs to be opened. Label it "View Collection" because who wouldn't want to do that?
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            &lt;h2&gt; is your headline, the one that should scream "look at me" but with style and poise.
            </li>

            <li>
            Buttons in HTML are like doors to new worlds. Label them right, and people will want to step through.
            </li>
          </ul>
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 39: Making the Section Flex Its Muscles 💪</h2>

          <p>
          Get ready to see your content flex! We're going to give section-one the power to put its left and right sides side by side, like superheroes in a team-up pose.
          </p>
          <img src={thirtyNine} className='demo__image' alt='img_thirtyNine' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Zoom into your CSS file and target the .section-one class. We're about to turn it into a flexible container that can do some serious layout gymnastics.
            </li>

            <li>
            Apply display: flex; to it, and set its flex-direction: row;. Just like that, you've got a row of content that adjusts like a harmonica—neat and responsive.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            display: flex; is like a yoga instructor for your content, helping everything bend and stretch to fit perfectly on the screen.
            </li>

            <li>
            flex-direction: row; tells your items to line up side by side, like ducks in a row or pencils in a box.
            </li>
          </ul>
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 40: Aligning Our Section 🌟📏</h2>

          <p>
          Our section-one is looking good, but let's align everything just right to make it look great. With a touch of CSS, we can ensure everything in our section is perfectly placed, giving it that professional touch.
          </p>
          <img src={forty} className='demo__image' alt='img_forty' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Jump back into your CSS file, and let's get to work on .section-one. We've already got it flexing; now it's time to align!
            </li>

            <li>
            Add align-items: center; to vertically center everything in the section, making sure our content is as balanced as a seesaw.
            </li>

            <li>
            Throw in justify-content: space-between; to give our items some breathing room. This spreads out the children elements like cards on a table, giving everything its own spotlight.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            align-items: center; is like the middle of a teeter-totter; it keeps everything centered, no matter how much content you pile on.
            </li>

            <li>
            justify-content: space-between; ensures there's equal space between items, kind of like social distancing for your content. Everyone gets their own space to shine!
            </li>
          </ul>
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 41: Defining the Section's Size and Space 📐🛋️</h2>

          <p>
          We've got alignment down; now let's define the space our section will take up and the space it will leave for others. It's like deciding how big your sandcastle will be and how much beach you'll leave for other beachgoers.
          </p>
          <img src={fortyOne} className='demo__image' alt='img_fortyOne' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In the same breath as the last step, we're not done with .section-one yet. Let's set some boundaries with width: 100%;. This tells our section to stretch its arms wide open across the screen.
            </li>

            <li>
            Next up, give it a height with height: 380px;—tall enough to be noticed.
            </li>

            <li>
            Finally, let's not forget a polite margin-bottom: 52px;. Like saying "excuse me" when you walk past someone, this gives the content below it some personal space.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            width: 100%; means take all the space you can get horizontally. Like lying down across a sofa, your section-one is lounging from left to right.
            </li>

            <li>
            Setting a height is like choosing the right pair of pants, it's got to fit just right—not too short, not too long.
            </li>

            <li>
            margin-bottom adds a cushion of space below our element, preventing it from being too clingy with the content below. It's the comfort zone of web design.
            </li>
          </ul>
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>
          Step 42: Dressing Up the Left Panel with a Background 🎨
          </h2>

          <p>
          We're about to take the left side of our section from plain to fabulous by dressing it up with an image that will make visitors want to sit down and stay awhile. Imagine turning a bare wall into a feature piece with just the right painting.
          </p>
          <img src={fortyTwo} className='demo__image' alt='img_fortyTwo' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Dive into the deep end of your CSS file and swim over to the .section-one .section-one-left class. This is your canvas, waiting for some color!
            </li>

            <li>
            Use background-image: url('./images/sofa.png'); to add that image like you're hanging a picture on the wall. Just like that, our empty div is getting a major decor upgrade with a comfy sofa image.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            background-image lets you place images behind your content. Think of it as wallpapering your website's room. By using url, you're telling the browser where to find this awesome new wallpaper.
            </li>

            <li>
            The file path ./images/sofa.png is like directions to a hidden treasure. The dot (.) means "start where you are," and /images/sofa.png is the path to follow to find the gold—our sofa image in our project folder.
            </li>
          </ul>
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 43: Perfecting the Background Positioning 🖼️📍</h2>

          <p>
          A nice picture is good, but it's all about how you hang it. We're going to make sure our background image is centered, not repeating, and covering the whole area beautifully—no awkward cropping or tiling!
          </p>
          <img src={fortyThree} className='demo__image' alt='img_fortyThree' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Back in the .section-one .section-one-left class, after the background image, it's time to position it just right with background-position: center;. This is like making sure the picture hangs perfectly at eye level.
            </li>

            <li>
            Add background-repeat: no-repeat; to prevent the image from re rendering multiple times (sometimes with background-image if the image is smaller than the div it will generate multiple of the images to completely cover the div). We want one sofa, not a sofa showroom!
            </li>

            <li>
            Finally, use background-size: cover; to make sure our image stretches to fit the space, like a well-tailored suit. No pinched corners or stretched-out patterns, just smooth, seamless coverage.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            background-position: center; centers your image in the element, so it's not awkwardly sticking to one side. It's all about that aesthetic balance.
            </li>

            <li>
            background-repeat: no-repeat; is a one-time deal. It tells the browser to display the image once instead of tiling it like a bathroom floor.
            </li>

            <li>
            Finally, use background-size: cover; to make sure our image stretches to fit the space, like a well-tailored suit. No pinched corners or stretched-out patterns, just smooth, seamless coverage.
            </li>
          </ul>
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 44: Setting the Stage with Dimensions 📐✂️</h2>

          <p>
          Like a director choosing the perfect set for a scene, we're going to define exactly where our sofa image sits and how much space it takes up. We want our visitors to feel like they've walked into a room that's just the right size.
          </p>
          <img src={fortyFour} className='demo__image' alt='img_fortyFour' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Back to the style department of .section-one .section-one-left in your CSS script! You've got the background looking sharp; now let's size it up.
            </li>

            <li>
            Set width: 35%;. Why? Because we want our image to be like a statement piece of furniture—not taking over the space, but just enough to catch the eye. After all, we want to leave room on the right side for the section-one-right div.
            </li>

            <li>
            Apply height: 100%; to stretch the background image all the way from the floor to the ceiling of its container. 
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            width: 35%; gives us a proportional measurement relative to the parent container. Think of it as dividing your room into parts—this section takes up a little over a third, leaving room for more décor or content.
            </li>

            <li>
            height: 100%; means the content's height will match the height of its parent element. It's like deciding the height of a painting so that it fills the wall exactly. The parent element in this case, is the section-one div.
            </li>
          </ul>
        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 45: Structuring Content Vertically 🗃️↕️</h2>

          <p>
          Now, let’s take the right panel in section-one and stack its content like a neat pile of books. We’re going for a clean, organized look that’s as easy to follow as your favorite novel.
          </p>
          <img src={fortyFive} className='demo__image' alt='img_fortyFive' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Target .section-one .section-one-right in your stylesheet—it’s time to turn that side of the section into a column of content!
            </li>

            <li>
            Set the stage with display: flex; which is like choosing the layout of furniture in a room. Flex makes it ready to adapt and move as needed.
            </li>

            <li>
            Then, by declaring flex-direction: column;, you tell the browser to arrange the content top to bottom, like a tower of information.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            display: flex; It switches on flexbox, a powerful layout mode that’s all about building a flexible and responsive design.
            </li>

            <li>
            flex-direction: column; means you’re arranging your content vertically. Everything inside will stack up from top to bottom, rather than side by side.
            </li>
          </ul>
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 46: Aligning Content Inside the Flex Container 🎯</h2>

          <p>
          Let’s align everything inside the right panel of section-one to start from the same spot, giving it a clean and tidy edge. It’s like lining up your ducks in a row—but for web content.
          </p>
          <img src={fortySix} className='demo__image' alt='img_fortySix' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            With .section-one .section-one-right still in the spotlight, it’s time to fine-tune the alignment.
            </li>

            <li>
            Add align-items: flex-start; to line up your content at the start of the flex container, creating a uniform look.
            </li>

            <li>
            Pair it with justify-content: flex-start; to start the content from the top, avoiding any gaps or awkward space at the beginning.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            align-items: flex-start; pulls all your flex items to the start of the cross axis. In a column, this means the left side for most languages.
            </li>

            <li>
            justify-content: flex-start; stacks your items at the start of the main axis, so in our column, it’s the top. There’s no unnecessary space above your first item—it starts right at the top.
            </li>
          </ul>
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 47: Cushioning Content with Padding 🛋️🖼️</h2>

          <p>
          Just like adding cushions to a bench, we’re going to pad the right section of our section-one.
          </p>
          <img src={fortySeven} className='demo__image' alt='img_fortySeven' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Within the .section-one .section-one-right rule in your CSS, let's give your content some breathing room.
            </li>

            <li>
            Apply padding: 92px; to create a generous space inside the right section. This is like framing a painting, giving it the space to stand out and be admired on its own.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            padding is the space inside the border of an element. It's like the area inside a picture frame that separates the picture from the frame itself. With 92 pixels of padding, you're ensuring that the content doesn’t feel cramped or pushed against the edges.
            </li>

            <li>
            Padding can be set on all four sides of an element, or individually as padding-top, padding-right, padding-bottom, and padding-left. In this case, we're setting it uniformly on all sides for a balanced look.
            </li>
          </ul>
        </div>

        <div style={currentStep == 48 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 48: Setting the Scene with a Background Image 🎬🧱</h2>

          <p>
          Now let's add some texture to the right panel. A background image can set the tone.
          </p>
          <img src={fortyEight} className='demo__image' alt='img_fortyEight' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            You're going back to .section-one .section-one-right—this time to add some flair with a background image.
            </li>

            <li>
            Enter background-image: url('./images/bricks.png'); to lay down a brick pattern backdrop. This adds character and depth, like a feature wall in a trendy cafe.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            background-image adds a graphical element to the background of an element. Using a URL, you tell the browser where to find the image you want to display.
            </li>
          </ul>
        </div>

        <div style={currentStep == 49 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 49: Mastering Background Customization 🖌️🏙</h2>

          <p>
          With our scene already set, it's time to ensure our background behaves just right. No awkward repeats, and it should cover the entire area, creating an immersive and professional look.
          </p>
          <img src={fortyNine} className='demo__image' alt='img_fortyNine' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Stay in .section-one .section-one-right within your CSS. You've got the background image, but let's make it perfect.
            </li>

            <li>
            Ensure that the image doesn’t tile itself by adding background-repeat: no-repeat;. It's like telling a story once, well enough that it doesn’t need repeating.
            </li>

            <li>
            Make the image fill the entire space neatly with background-size: cover;. It’s like sizing a poster so it fits the frame exactly, without any edges peeping out or unsightly gaps.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            background-size: cover; ensures that the image covers the available area, stretching or shrinking as needed, but always filling the space edge to edge.
            </li>
          </ul>
        </div>

        <div style={currentStep == 50 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 50: Defining the Area 📏📐</h2>

          <p>
          Let's now define the exact size of our section-one-right to ensure it occupies the intended space within our layout, providing a harmonious balance to our section.
          </p>
          <img src={fifty} className='demo__image' alt='img_fifty' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            You're going to refine the .section-one .section-one-right rule by specifying its size.
            </li>

            <li>
            Set width: 60%; to claim the right amount of horizontal space. Like choosing a plot for a garden, we decide how much room our content needs to bloom.
            </li>

            <li>
            Match that with height: 100%; to take up the full vertical space. It's like measuring curtains to fall just right from top to bottom.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            width: 60%; allocates a precise portion of the parent's width to the element, giving it room to stand out without overwhelming the space.
            </li>

            <li>
            height: 100%; extends the element's height to match its container, ensuring a full and balanced vertical presence.
            </li>
          </ul>
        </div>

        <div style={currentStep == 51 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 51: Tailoring Text Style for Impact 🎨✍️</h2>

          <p>
          Now, we'll focus on making the headlines in our section-one-right pop. We'll tailor the style of our &lt;h2&gt; elements to make sure they make the right statement.
          </p>
          <img src={fiftyOne} className='demo__image' alt='img_fiftyOne' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Target the .section-one .section-one-right h2 selector in your CSS file. This is where we accessorize our content.
            </li>

            <li>
            Set color: #607D8B; to give your headlines a shade that speaks of calm and credibility. It's the color of a dusky sky just before nightfall, both soothing and deep.
            </li>

            <li>
            Apply font-size: 44px; to ensure the headlines are large enough to be seen from across the room but not so large that they shout at your visitors.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            color in CSS defines the text color of an element. By choosing #607D8B, we give the text a hue that matches the overall theme of the section, creating a cohesive visual experience.
            </li>

            <li>
            font-size determines how big or small the text appears on the screen. With 44px, we're going for a size that is both noticeable and readable, ensuring that the headlines are an anchor point for the rest of the content.
            </li>
          </ul>
        </div>

        <div style={currentStep == 52 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 52: Crafting the Button 🖱️👚</h2>

          <p>
          Buttons are the gateway to user interaction, and in step 52, we’re tailoring the button in section-one-right to be as inviting as a cozy cafe on a busy street.
          </p>
          <img src={fiftyTwo} className='demo__image' alt='img_fiftyTwo' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Target .section-one .section-one-right button in your CSS file. This button is our hand-shake or first impression to users.
            </li>

            <li>
            Add border: 2px solid #607D8B; to give it a strong outline, like framing a masterpiece. The color #607D8B brings a serene yet noticeable edge to it.
            </li>

            <li>
            Set the background-color: #607D8B; to fill the button with a solid, trustworthy hue that looks clickable.
            </li>

            <li>
            Use color: #ffffff; to make the text on the button pop, ensuring clarity and visibility.
            </li>

            <li>
            Style it with font-weight: 500; for the perfect balance of emphasis and readability.
            </li>

            <li>
            Ensure the text is comfortably legible by setting font-size: 18px;—not too small, not too large, just right.
            </li>

            <li>
            Define its space by assigning width: 182px; and height: 42px; so it's perfectly sized for a satisfying click.
            </li>

            <li>
            Give it a margin-top: 28px; to float it just right within the content, like hanging a sign at the ideal height.
            </li>

            <li>
            Set cursor: pointer; to transform the cursor into a clicking hand, inviting interaction.
            </li>

            <li>
            Finally, sprinkle it with transition: 0.2s all ease-in; for that smooth, professional interaction when the button's state changes.
            </li>
          </ol>

          <p>
          With step 52, your button is not just a button; it's a beacon, guiding users to their next action with style and grace. 🚥✨
          </p>
        </div>

        <div style={currentStep == 53 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 53: Styling the Hover State of the Button 🖱️</h2>

          <p>
          The hover state is where we bring a touch of interactivity to our button. It's like the button is saying, "Go ahead, click me!"
          </p>
          <img src={fiftyThree} className='demo__image' alt='img_fiftyThree' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Now target the .section-one .section-one-right button:hover in your CSS. This is when a user points their mouse over the button.
            </li>

            <li>
            Set border: 2px solid #607D8B; to maintain a defined edge that stays constant, ensuring our button is always getting all the attention.
            </li>

            <li>
            Change the background-color to transparent; to create a cool see-through effect when hovered over, like a window that reveals the world behind it when you get closer.
            </li>

            <li>
            Adjust the text color to #607D8B; to keep the text clearly visible against the transparent background, ensuring our message remains front and center.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            The :hover pseudo-class targets an element when the mouse hovers over it. It's like setting up a spotlight that turns on when an actor walks on stage.
            </li>

            <li>
            border stays the same as it was before hover, providing visual consistency.
            </li>

            <li>
            background-color: transparent; adds an element of interaction, making the button feel dynamic and responsive.
            </li>
          </ul>
        </div>

        <div style={currentStep == 54 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 54: Laying the Groundwork for Another Section 🏗️</h2>

          <p>
          As we move forward, it’s time to lay down another section, preparing to fill it with more content, style, and substance.
          </p>
          <img src={fiftyFour} className='demo__image' alt='img_fiftyFour' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Go to the HTML file and start writing the new &lt;div&gt; you're going to create, which will be called .section-two. It's a new container waiting to be filled with possibilities.
            </li>

            <li>
            Right now, it's just an empty space, a canvas ready for your creative ideas. Think about what you'll put here. More text? Images? A gallery?
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            A &lt;div&gt; is like a blank page in a book, an empty room in a house. It’s a generic block-level element used to group other elements to format them with CSS.
            </li>

            <li>
            The class="section-two" is an identifier that you'll use to target this &lt;div&gt; with CSS. It's like putting a label on a storage box so you know exactly what's inside when you need it.
            </li>
          </ul>
        </div>

        <div style={currentStep == 55 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 55: Structuring the Second Section 🛠️📐</h2>

          <p>
          Let's start building out section-two. 
          </p>
          <img src={fiftyFive} className='demo__image' alt='img_fiftyFive' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In your HTML file, you've got a new &lt;div&gt; tagged as .section-two, which is like opening a new chapter in your website's story.
            </li>

            <li>
            Within this div, create two more: .section-two-left and .section-two-right. These will be the two halves of your new section, like the pages of an open book ready to be filled.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            A &lt;div&gt; acts as a container for other elements and is used to structure your HTML document.
            </li>

            <li>
            Using class="section-two-left" and class="section-two-right" helps you style these sections independently in CSS. It's like assigning roles in a play; each actor (or div) knows where to stand on the stage.
            </li>
          </ul>
        </div>

        <div style={currentStep == 56 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 56: Populating the Left Panel with Content 📝🌟</h2>

          <p>
          Now, we'll populate section-two-left with compelling content, inviting users to learn more.
          </p>
          <img src={fiftySix} className='demo__image' alt='img_fiftySix' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Within the section-two-left div, add a headline (&lt;h2&gt;), a paragraph (&lt;p&gt;), and a button (&lt;button&gt;). These elements will work together to grab attention and encourage interaction.
            </li>

            <li>
            The &lt;h2&gt; will be your showstopper headline - "Table Collection!" - announcing the new content with flair.
            </li>

            <li>
            The &lt;p&gt; will hold an enticing offer, "Launch Offer 15% Off!", creating an incentive to engage further.
            </li>

            <li>
            The &lt;button&gt; labeled "View Collection" acts as a call to action, beckoning users to explore what you have showcased.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            &lt;h2&gt; tags are used for main headlines, making them larger and more noticeable than other text.
            </li>

            <li>
            &lt;p&gt; tags are for paragraphs, where you can elaborate on details or offers.
            </li>

            <li>
            &lt;button&gt; elements are for actions you want users to take, such as viewing more details about a product or offer.
            </li>
          </ul>
        </div>

        <div style={currentStep == 57 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 57: Introducing Flex Layout to Section Two 🌟</h2>

          <p>
          It's time to shape the layout of our new section-two, ensuring it’s as sleek and flexible as a gymnast. Flexbox is the tool of choice here, providing the agility we need for a responsive design.
          </p>
          <img src={fiftySeven} className='demo__image' alt='img_fiftySeven' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Head over to your CSS file and locate the .section-two class. This will be the Flexbox container for the two divs we've just created.
            </li>

            <li>
            Add display: flex; to initiate the Flexbox model, which is like choosing an open-concept floor plan for a room, offering plenty of layout possibilities.
            </li>

            <li>
            Set flex-direction: row; to align .section-two-left and .section-two-right side by side, like books on a shelf.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            display: flex; switches on the Flexbox layout mode for the container, making its children flexible items.
            </li>

            <li>
            flex-direction: row; lines up the children (.section-two-left and .section-two-right) horizontally.
            </li>
          </ul>
        </div>

        <div style={currentStep == 58 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 58: Perfecting the Alignment and Spacing 📏🛋️</h2>

          <p>
          Now let’s fine-tune how items inside section-two sit in relation to each other. It's like arranging furniture in a room for the perfect balance of function and form.
          </p>
          <img src={fiftyEight} className='demo__image' alt='img_fiftyEight' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Stay within .section-two in your CSS.
            </li>

            <li>
            Apply align-items: center; to vertically align the children in the middle of the container, ensuring they look good at any screen size.
            </li>

            <li>
            Set justify-content: space-between; to distribute the children evenly across the horizontal axis, with space between them, providing a clean and organized appearance.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            align-items: center; ensures that items are centered within the container along the cross-axis, preventing them from sticking to the top or bottom.
            </li>

            <li>
            justify-content: space-between; places equal space between the items, but not between the items and the container edges, giving a balanced distribution of space.
            </li>
          </ul>
        </div>

        <div style={currentStep == 59 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 59: Defining the Layout of Section Two 📐🌟</h2>

          <p>
          We’re going to give section-two a defined size and clear boundaries, creating a harmonious balance within our layout.
          </p>
          <img src={fiftyNine} className='demo__image' alt='img_fiftyNine' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Open your CSS file and find .section-two. This is our second major section, so let's give it the dimensions it deserves.
            </li>

            <li>
            Assign width: 100%; to allow the section to span the full width of the screen, giving it a grand presence.
            </li>

            <li>
            Set height: 380px; to give it a tall and prominent profile, ensuring all content within has ample space to breathe.
            </li>

            <li>
            Finish off with a margin-bottom: 52px; to push it away from whatever comes next, like the respectful distance between two pieces of art.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            width: 100%; ensures the section stretches across the full width of its container, creating a block that’s as wide as the screen or the parent element it’s inside.
            </li>

            <li>
            height: 380px; establishes a set height for the section, making sure it's tall enough to encapsulate its content comfortably.
            </li>

            <li>
            margin-bottom provides space below the section, preventing it from colliding with other elements and aiding in the creation of a well-spaced page layout.
            </li>
          </ul>
        </div>

        <div style={currentStep == 60 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 60: Setting Up a Column Structure in Section Two-Left 🏛️📚</h2>

          <p>
          Now let's turn our attention to the left side of section-two, where we want to structure our content vertically.
          </p>
          <img src={sixty} className='demo__image' alt='img_sixty' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Within .section-two .section-two-left, apply display: flex; to initiate a Flexbox layout, which will allow for a fluid and adaptable structure.
            </li>

            <li>
            Set flex-direction: column; to stack its children (the headline, paragraph, and button) vertically, one on top of the other.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            display: flex; establishes a flexible box layout, which is perfect for designing a responsive interface.
            </li>

            <li>
            flex-direction: column; makes the flex items stack in a vertical direction, as opposed to a row, which would align them horizontally.
            </li>
          </ul>
        </div>

        <div style={currentStep == 61 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 61: Enhancing Content Alignment in Section Two-Left 📐🖌️</h2>

          <p>
          We want the content in section-two-left to start in the top-left corner, giving it a clean and organized start. This step will ensure our content doesn't just float around but sits precisely where we want it to.
          </p>
          <img src={sixtyOne} className='demo__image' alt='img_sixtyOne' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In the CSS file, locate .section-two .section-two-left. It's time to align the content inside this container.
            </li>

            <li>
            Set align-items: flex-start; to align the content to the start of the cross axis, which is the left side for most writing modes.
            </li>

            <li>
            Add justify-content: flex-start; to align the content to the start of the main axis, ensuring it starts right from the top without any unnecessary space.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            align-items: flex-start; aligns items to the start of the cross axis.
            </li>

            <li>
            justify-content: flex-start; aligns items to the start of the main axis.
            </li>

            <li>
            padding: 92px; provides space within the container, around the content, for a breathable layout.
            </li>
          </ul>
        </div>

        <div style={currentStep == 62 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 62: Applying a Background to Section Two-Left 🌆🎨</h2>

          <p>
          Adding a background image can instantly uplift the look and feel of a section. In section-two-left, we'll apply an image that complements the content and enhances the visual appeal.
          </p>
          <img src={sixtyTwo} className='demo__image' alt='img_sixtyTwo' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Stay with .section-two .section-two-left in the CSS.
            </li>

            <li>
            Insert background-image: url('./images/bricks.png'); to add a stylish background, setting the tone and texture behind your content.
            </li>

            <li>
            Use background-position: center; to ensure the image is centered within the container, perfect for aesthetics.
            </li>

            <li>
            Apply background-repeat: no-repeat; so the image does not tile and repeat.
            </li>

            <li>
            Finish with background-size: cover; to ensure the background image covers the entire element, providing a seamless visual experience.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            background-image introduces a graphical element behind the content.
            </li>

            <li>
            background-position dictates where the image sits within the element.
            </li>

            <li>
            background-repeat controls whether an image should be repeated (tiled) or not.
            </li>

            <li>
            background-size: cover; ensures the image fills the entire element, adjusting its size as needed to cover the area without stretching the image out of proportion.
            </li>
          </ul>
        </div>

        <div style={currentStep == 63 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 63: Fine-Tuning the Left Column of Section Two 🎨📐</h2>

          <p>
          Just like a tailor adjusts a suit to fit perfectly, we're going to adjust the left column of section-two to ensure it presents our content precisely how we want.
          </p>
          <img src={sixtyThree} className='demo__image' alt='img_sixtyThree' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In the CSS file, target .section-two .section-two-left. This is where we shape the space that will frame our content.
            </li>

            <li>
            We've already given it a flex layout, and now we'll add width: 60%; to specify how much of the container's width it should occupy, creating a balanced layout.
            </li>

            <li>
            Assign height: 100%; to ensure our column stretches the full height of its parent container, making it look well-anchored and proportioned.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Setting the width as a percentage ensures that the container will be responsive and adapt to different screen sizes, maintaining the design's integrity.
            </li>

            <li>
            height: 100%; allows the content to fill the container fully from top to bottom, providing a visually pleasing experience by using all the available vertical space.
            </li>
          </ul>
        </div>

        <div style={currentStep == 64 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 64: Adding a Background Image to Section Two-Right 🏞️✨</h2>

          <p>
          Background images can tell a story all on their own. In section-two-right, we'll place an image that complements and contrasts with the left side, giving a visual cue that there's more to see.
          </p>
          <img src={sixtyFour} className='demo__image' alt='img_sixtyFour' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Target .section-two .section-two-right in your CSS file.
            </li>

            <li>
            Insert background-image: url('./images/table.png'); to set the stage with a visual element that ties into the content of the section, perhaps showcasing the product or theme discussed.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            The background-image property adds visual interest and depth, creating a more immersive experience for the user.
            </li>

            <li>
            Using url('./images/table.png') points the CSS to the exact location of the image, allowing it to be retrieved and displayed as the background.
            </li>
          </ul>
        </div>

        <div style={currentStep == 65 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 65: Optimizing Background Presentation 🌄🖌️</h2>

          <p>
          Now we'll enhance the background of section-two-right to make sure it's displayed just right, ensuring our backdrop looks as good on a screen as it would on a gallery wall.
          </p>
          <img src={sixtyFive} className='demo__image' alt='img_sixtyFive' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In the CSS file, find .section-two .section-two-right.
            </li>

            <li>
            Set the background-image: url('./images/table.png'); to bring your chosen image into the backdrop.
            </li>

            <li>
            Use background-position: center; to ensure the image sits perfectly at the heart of the section, neither veering too high nor too low.
            </li>

            <li>
            Apply background-repeat: no-repeat; to prevent the image from repeating itself, because one exquisite image is worth a thousand tiles.
            </li>

            <li>
            Ensure the image covers the available area neatly with background-size: cover;, for a fit that's as snug and seamless as a well-laid tablecloth.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            background-position adjusts where the image sits within its container, centering it for maximum impact.
            </li>

            <li>
            background-repeat avoids the repetitive pattern effect, keeping the background sleek and clean.
            </li>

            <li>
            background-size: cover; ensures that the background image is scaled to maintain its aspect ratio while filling the container's entire area.
            </li>
          </ul>
        </div>

        <div style={currentStep == 66 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 66: Defining the Proportions of Section Two-Right 📏✨</h2>

          <p>
          The right side of section-two needs to be as visually compelling as the left, with dimensions that balance the overall layout and give prominence to the background image.
          </p>
          <img src={sixtySix} className='demo__image' alt='img_sixtySix' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Continue with .section-two .section-two-right in your CSS.
            </li>

            <li>
            Specify width: 35%; to control how much of the section's width the right column should take up.
            </li>

            <li>
            Assign height: 100%; to have the right column stretch from top to bottom.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Setting the width as a percentage allows for a responsive design, which adjusts to different screen sizes while keeping a relative proportion.
            </li>
          </ul>
        </div>

        <div style={currentStep == 67 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 67: Stylizing Headlines in Section Two-Left 🎨🖋</h2>

          <p>
          Headlines grab attention and set the tone for the content to follow. We’ll style the headline in section-two-left to stand out and speak to the viewer.
          </p>
          <img src={sixtySeven} className='demo__image' alt='img_sixtySeven' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In the CSS file, target .section-two .section-two-left h2, which is the selector for headlines within the left side of the second section.
            </li>

            <li>
            Set color: #607D8B; to apply a color that’s both professional and pleasing to the eye, like the hue of twilight sky.
            </li>

            <li>
            Define font-size: 44px; to ensure the headline is prominent on the page, capturing attention with its size.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            The color property changes the text color, making it an integral part of the visual design and hierarchy.
            </li>

            <li>
            font-size affects how large the text appears on the screen, which in turn impacts readability and visual impact.
            </li>
          </ul>
        </div>

        <div style={currentStep == 68 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 68: Fine-tuning Paragraph Styling in Section Two-Left 📝✨</h2>

          <p>
          After the headline, the paragraph text carries the main message. We’ll ensure it’s as readable as it is informative.
          </p>
          <img src={sixtyEight} className='demo__image' alt='img_sixtyEight' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Target the .section-two .section-two-left p realm of your CSS to find the p selector.
            </li>

            <li>
            Apply color: #607D8B; to maintain visual consistency with the headline and the overall color scheme of the section.
            </li>

            <li>
            Adjust the font-size: 22px; to ensure that the paragraph text is comfortable to read without overpowering the headline.
            </li>

            <li>
            Set margin-top: 22px; and margin-bottom: 22px; to give the text breathing room from other elements above and below, ensuring it stands out.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            The font-size for paragraphs should be large enough for easy reading but balanced so as not to compete with headlines.
            </li>

            <li>
            margin-top and margin-bottom create space around the paragraph, helping to define the rhythm and flow of the content on the page.
            </li>
          </ul>
        </div>

        <div style={currentStep == 69 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 69: Button Styling for Action and Appeal 🖲️✨</h2>

          <p>
          A button is a call to action, and its design should be inviting to clicks. We'll make sure the button in section-two-left stands out as an enticing clickable element.
          </p>
          <img src={sixtyNine} className='demo__image' alt='img_sixtyNine' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In your CSS, pinpoint .section-two .section-two-left button. This selector targets the button within the left section.
            </li>

            <li>
            Apply border: 2px solid #607D8B; to give it a solid, distinguishable outline.
            </li>

            <li>
            Set background-color: #607D8B; for a button that stands out with a color that commands attention.
            </li>

            <li>
            The text should be clearly visible, so color: #ffffff; will make sure the button's label is legible.
            </li>

            <li>
            A font-weight of 500 strikes the balance between bold and normal, making the text weighty enough to read.
            </li>

            <li>
            With font-size: 18px;, the text size will be neither too big nor too small.
            </li>

            <li>
            Specify width: 182px; and height: 42px; to give the button a standard and comfortable size for users to click.
            </li>

            <li>
            Set a margin-top: 28px; to place it just right in the layout.
            </li>

            <li>
            cursor: pointer; is a must for a button, signalling an interactive element.
            </li>

            <li>
            Lastly, transition: 0.2s all ease-in; will smooth out the visual transition when the button state changes.
            </li>
          </ol>
        </div>

        <div style={currentStep == 70 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 70: Interactive Button Hover Effect 🖲️</h2>

          <p>
          Interactivity is key in web design, and hover effects can provide visual feedback that a button is clickable.
          </p>
          <img src={seventy} className='demo__image' alt='img_seventy' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Continue with the button styling in .section-two .section-two-left button:hover.
            </li>

            <li>
            Enhance the button's interactivity with a hover effect by setting the border color to remain the same.
            </li>

            <li>
            Change the background-color to transparent when hovered over to create an inviting see-through effect.
            </li>

            <li>
            Adjust the text color to #607D8B; to maintain readability and design consistency.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            The :hover pseudo-class is used to provide visual feedback when the cursor hovers over an element, increasing user engagement.
            </li>

            <li>
            The border remains the same on hover to maintain a consistent and strong outline.
            </li>

            <li>
            Changing the background-color on hover can add depth to the interaction, inviting users to click.
            </li>

            <li>
            The color property here ensures the text remains visible against the new transparent background.
            </li>
          </ul>
        </div>

        <div style={currentStep == 71 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 71: Starting the Features Section 🌟🛠️</h2>

          <p>
          This step is about laying the foundation for the 'features-section'.
          </p>
          <img src={seventyOne} className='demo__image' alt='img_seventyOne' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Within your HTML file, create the &lt;div&gt; with the class features-section. This is the container that will hold all the special feature cards.
            </li>

            <li>
            For now, this div is empty, awaiting the feature content that will highlight the standout aspects of what you're offering.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            The class="features-section" is like a dedicated gallery space within your website where you can exhibit the features that make your offering unique.
            </li>
          </ul>
        </div>

        <div style={currentStep == 72 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 72: Constructing a Feature Card 🖼️📌</h2>

          <p>
          Feature cards are bite-sized content blocks that draw attention to key aspects of your offering. Let's build the first one.
          </p>
          <img src={seventyTwo} className='demo__image' alt='img_seventyTwo' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Inside the features-section div, add a new div with the class feature-card.
            </li>

            <li>
            Use an &lt;img/&gt; tag with src="./images/features1.png" to visually represent the feature. The alt attribute should describe the image; in this case, "feature".
            </li>

            <li>
            Include a paragraph &lt;p&gt; with text "10 Years Experience" to succinctly inform the user about the depth of experience behind the product or service.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            class="feature-card" is like a frame for your feature, making it a distinct block of content within the features gallery.
            </li>

            <li>
            The &lt;img/&gt; element is used to embed an image representing the feature, making it immediately visible and recognizable.
            </li>

            <li>
            The &lt;p&gt; tag holds a short yet impactful message that communicates the value of the feature to the audience.
            </li>
          </ul>
        </div>

        <div style={currentStep == 73 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 73: Expanding the Features Gallery 🌐🃏</h2>

          <p>
          Having established the layout for our features, it's time to replicate the structure, creating a gallery of feature cards that showcase the different benefits of our site.
          </p>
          <img src={seventyThree} className='demo__image' alt='img_seventyThree' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            You've already got one feature card done. Great job! Now, replicate that structure for the remaining features.
            </li>

            <li>
            Add three more &lt;div&gt; elements with the class feature-card inside the features-section.
            </li>

            <li>
            For each new feature-card, insert an &lt;img/&gt; element with the respective src attribute pointing to "./images/feature2.png", "./images/feature3.png", and "./images/feature4.png". Make sure each has an alt attribute of "feature".
            </li>

            <li>
            Below each image, add a &lt;p&gt; element containing the feature's description: "Flexible Delivery", "Free Installation", and "After Sales Support".
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Multiplying the .feature-card structure allows us to present multiple features in a consistent and organized way.
            </li>
          </ul>
        </div>

        <div style={currentStep == 74 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 74: Arranging the Features Section 🎛️📐</h2>

          <p>
          We're setting up the .features-section to display its contents in a row, which will be the backbone of our layout here.
          </p>
          <img src={seventyFour} className='demo__image' alt='img_seventyFour' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Target .features-section in your CSS file.
            </li>

            <li>
            Set display: flex; to initiate a flexbox layout which is great for a responsive design.
            </li>

            <li>
            Apply flex-direction: row; to align the feature cards horizontally.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            display: flex; creates a flex container and enables a flexible layout aligned in rows or columns.
            </li>

            <li>
            flex-direction: row; aligns the child elements (feature cards) in a row, from left to right.
            </li>
          </ul>
        </div>

        <div style={currentStep == 75 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 75: Aligning and Spacing the Feature Cards 📏</h2>

          <p>
          Now we're not only arranging the feature cards in a row but also ensuring they have equal space between them and are centered vertically within the .features-section.
          </p>
          <img src={seventyFive} className='demo__image' alt='img_seventyFive' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Stay with .features-section in the CSS.
            </li>

            <li>
            Add align-items: center; to vertically align the feature cards in the middle of the section.
            </li>

            <li>
            Include justify-content: space-between; to place an equal amount of space between each feature card.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            align-items: center; centers the feature cards vertically, which helps maintain a balanced layout especially if the height of cards differs.
            </li>

            <li>
            justify-content: space-between; distributes space evenly between the feature cards, giving the section a harmonious look.
            </li>
          </ul>
        </div>

        <div style={currentStep == 76 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 76: Styling the Features Section for Optimal Display 🌟✨</h2>

          <p>
          With the content of our features section in place, let's add some styling to ensure it's displayed optimally on all devices.
          </p>
          <img src={seventySix} className='demo__image' alt='img_seventySix' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In your style.css, locate the .features-section selector.
            </li>

            <li>
            Confirm display: flex; and flex-direction: row; are set, ensuring a row-based flex layout.
            </li>

            <li>
            Define the width as 100% to utilize the full width of the container.
            </li>

            <li>
            Set a fixed height of 188px for the .features-section to ensure a uniform appearance.
            </li>

            <li>
            Add margin-bottom: 52px; to create some space below the features section before the next content starts.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Margins control the space outside of an element's border. In this case, margin-bottom adds space below the features section.
            </li>
          </ul>
        </div>

        <div style={currentStep == 77 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 77: Aligning Feature Cards within the Features Section 📏🃏</h2>

          <p>
          Now that our features section is styled, we want each feature card to be perfectly aligned within it.
          </p>
          <img src={seventySeven} className='demo__image' alt='img_seventySeven' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Continue in your style.css file, targeting .features-section .feature-card.
            </li>

            <li>
            Set display: flex; to initiate the flexbox layout inside each feature card.
            </li>

            <li>
            Use flex-direction: column; to stack the content of the feature card vertically.
            </li>

            <li>
            Apply align-items: center; to center-align the items within each feature card.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            flex-direction: column; is used here to arrange the feature card's content (image and text) in a vertical stack.
            </li>

            <li>
            align-items: center; ensures that the content inside each feature card is centered horizontally, giving a neat, professional look.
            </li>
          </ul>
        </div>

        <div style={currentStep == 78 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 78: Creating Space Between Feature Images and Text 🖼️➡️📝</h2>

          <p>
          Let's ensure our feature images have breathing room above their descriptions, making them clearer and more appealing.
          </p>
          <img src={seventyEight} className='demo__image' alt='img_seventyEight' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In your CSS, target the images within the .feature-card using .features-section .feature-card img.
            </li>

            <li>
            Add margin-bottom: 18px; to create space between the feature images and the text below them.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Margins are your go-to tool for controlling space around elements. margin-bottom pushes the text away from the image, preventing visual clutter.
            </li>
          </ul>
        </div>

        <div style={currentStep == 79 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 79: Enhancing the Readability of Feature Descriptions 📘💡</h2>

          <p>
          Readable and attention-grabbing feature descriptions are key to user engagement.
          </p>
          <img src={seventyNine} className='demo__image' alt='img_seventyNine' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Stay in the CSS file and find the paragraph within the .feature-card with .features-section .feature-card p.
            </li>

            <li>
            Set the font-size: 18px; to ensure the text is easily legible on various devices.
            </li>

            <li>
            Use font-weight: 500; to give the text a moderate emphasis without making it too bold.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            font-size affects how large text appears on the screen. Larger text is easier to read, especially on mobile devices.
            </li>

            <li>
            font-weight controls the thickness of the text. A weight of 500 strikes a balance between normal and bold, drawing attention while maintaining readability.
            </li>
          </ul>
        </div>

        <div style={currentStep == 80 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 80: Introducing the Sale Section 🏷️</h2>

          <p>
          Let's make a splash with a sale section that catches every visitor's eye! 
          </p>
          <img src={eighty} className='demo__image' alt='img_eighty' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Start by adding a div element with a class sale-section. This div will serve as a container for the sale items on your webpage.
            </li>

            <li>
            Make sure the div is correctly placed within the body of your HTML document.
            </li>

            <li>
            Ensure the class attribute is properly spelled and assigned so that it can be targeted with CSS for styling later on.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            The div element is used as a container for HTML elements and is often used for layout purposes, styled with CSS.
            </li>

            <li>
            A class attribute is used to specify a class for an HTML element, which can be used by CSS and JavaScript to perform certain tasks for elements with the specified class.
            </li>
          </ul>
        </div>

        <div style={currentStep == 81 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 81: Populating the Sale Section with Engaging Content 📝💥</h2>

          <p>
          Content is king in web design. Engaging and well-structured content can drive user interaction and satisfaction.
          </p>
          <img src={eightyOne} className='demo__image' alt='img_eightyOne' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Within the .sale-section container, insert an h1 element with the text "Sale!" to act as the main heading of this section.
            </li>

            <li>
            Follow this with an h2 element containing the text "10% Off On All Products!" to provide more details about the sale.
            </li>

            <li>
            Finally, include a button element with the text "Shop Now" to invite user action.
            </li>

            <li>
            Pay attention to the nesting of these elements to maintain a clean and organized HTML structure.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            The h1 element is used for the main headings of a section, while h2 provides a subheading with additional details.
            </li>

            <li>
            The button element creates a clickable button, which can be used for submitting forms or triggering events on a webpage.
            </li>
          </ul>
        </div>

        <div style={currentStep == 82 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 82: Structuring the Sale Section with Flexbox 📐✨</h2>

          <p>
          Flexbox is a powerful layout tool that provides a more efficient way to lay out, align, and distribute space among items in a container.
          </p>
          <img src={eightyTwo} className='demo__image' alt='img_eightyTwo' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Add CSS properties to your .sale-section to use Flexbox for layout.
            </li>

            <li>
            Set display to flex to enable Flexbox properties for the container.
            </li>

            <li>
            Use flex-direction set to column to arrange your items vertically.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            display: flex initiates Flexbox, which affects the layout of child elements.
            </li>

            <li>
            flex-direction: column stacks the child elements vertically.
            </li>
          </ul>
        </div>

        <div style={currentStep == 83 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 83: Aligning and Centering Content in the Sale Section 🎯🌟</h2>

          <p>
          Alignment and justification in Flexbox are crucial for positioning child elements within the flex container.
          </p>
          <img src={eightyThree} className='demo__image' alt='img_eightyThree' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Add alignment and justification properties to .sale-section to control the positioning of its child elements.
            </li>

            <li>
            Use align-items: flex-start to align children to the start of the cross axis.
            </li>

            <li>
            Set justify-content: center to center children along the main axis.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Using center with justify-content ensures that the items are centered in the flex container. The combination of these properties results in a centered and neatly aligned content layout, providing a harmonious visual structure for users.
            </li>
          </ul>
        </div>

        <div style={currentStep == 84 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 84: Defining Dimensions and Layout of the Sale Section 📏🌟</h2>

          <p>
          Accurate dimensions and spacing are fundamental in crafting a visually appealing layout. It ensures that elements are proportionate and well-positioned within the webpage.
          </p>
          <img src={eightyFour} className='demo__image' alt='img_eightyFour' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Adjust the .sale-section to occupy the full width of its parent container by setting width to 100%. This ensures the section stretches across the full width of the screen or its container element, providing a spacious canvas for your content.
            </li>

            <li>
            Define a fixed height of 320px to give the section a uniform appearance. A consistent height will ensure that the section stands out and provides ample space for content while maintaining a balanced look across different devices or screen sizes.
            </li>

            <li>
            By setting specific width and height properties, you create a predictable and consistent layout that can adapt to various screen sizes and resolutions.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            width and height properties are used to set the size of a box element in CSS.
            </li>

            <li>
            Using percentages for width allows for fluid resizing with the browser window, enhancing responsiveness.
            </li>

            <li>
            A fixed height establishes a consistent dimension for the section, making it easier to manage the layout.
            </li>
          </ul>
        </div>

        <div style={currentStep == 85 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 85: Enhancing Spacing and Structure 🛠️💫</h2>

          <p>
          Spacing around and within elements plays a crucial role in creating a visually comfortable layout. Padding adds space inside an element, enhancing readability, whereas margins control the space outside of an element, affecting its relationship with other elements.
          </p>
          <img src={eightyFive} className='demo__image' alt='img_eightyFive' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Add padding-left: 42px; to the .sale-section. Padding creates space between the content and the border of an element. In this case, it pushes the content away from the left edge, making it more aesthetically pleasing and easier to read.
            </li>

            <li>
            Introduce a margin-bottom: 52px; to the .sale-section. Margins are used to create space around elements, outside of any defined borders. Here, the margin-bottom provides space beneath the section, separating it from subsequent content and maintaining a clear visual hierarchy.
            </li>

            <li>
            These adjustments to padding and margins do not just serve aesthetic purposes but also adhere to principles of design such as alignment, balance, and negative space.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Padding is the space inside the border, between the border and the actual content.
            </li>

            <li>
            Margins are the spaces outside the border, between the border and the neighboring elements.
            </li>

            <li>
            Choosing the right amount of padding and margin can significantly affect the user's visual experience, guiding their attention through the flow of content.
            </li>

            <li>
            Understanding the box model is crucial for web design, as it affects how elements are displayed and interact with each other on the page.
            </li>
          </ul>

          <p>
          With these steps, we've not only created a framework for a visually pleasing section but also incorporated key CSS properties that provide structure and space—crucial elements for user-friendly web design.
          </p>
        </div>

        <div style={currentStep == 86 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 86: A Background Image for Visual Impact 🖼️</h2>

          <p>
          Background images can dramatically enhance the visual appeal of a section, offering both context and aesthetic value to the design.
          </p>
          <img src={eightySix} className='demo__image' alt='img_eightySix' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Incorporate a background image into the .sale-section to create a visually engaging backdrop for your sale content.
            </li>

            <li>
            Use the background-image property to specify the path to your desired image, which in this case is a cactus. This thematic imagery can provide an appealing contrast to your sale items, drawing in the viewer's attention.
            </li>

            <li>
            Apply background-position: center to ensure the image is centered within the section, providing a balanced look no matter the screen size.
            </li>

            <li>
            Set background-repeat to no-repeat to prevent the image from tiling across the container, which can distract from the overall design.
            </li>

            <li>
            Use background-size: cover to ensure the background image covers the entire .sale-section, cropping and scaling the image as needed without distorting its aspect ratio.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            The background-image property sets one or several background images for an element.
            </li>

            <li>
            background-position dictates the initial position of the background image.
            </li>

            <li>
            background-repeat controls how the background image is repeated.
            </li>

            <li>
            background-size: cover ensures the background image fully covers the element's area without stretching.
            </li>

            <li>
            Implementing a background image can serve as a focal point or complement the section's content, contributing to a cohesive and visually rich user experience.
            </li>
          </ul>
        </div>

        <div style={currentStep == 87 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 87: Styling the Section Heading 🎨📝</h2>

          <p>
          The styling of headings is crucial for drawing attention to key sections of content and guiding the user's journey through the website.
          </p>
          <img src={eightySeven} className='demo__image' alt='img_eightySeven' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Style the h1 element within the .sale-section to stand out against the background image and to align with the overall theme of the section.
            </li>

            <li>
            Set the color property to #A71052, a deep and vibrant hue that provides sufficient contrast against a lighter background, ensuring that the heading is legible and prominent.
            </li>

            <li>
            Adjust the font-size to 42px to make the heading large enough to be the focal point of the section, but not so large that it overwhelms the other content.
            </li>

            <li>
            Apply a font-weight of 400 to achieve a balance between boldness and readability, preventing the text from appearing too heavy or too light against the background.
            </li>

            <li>
            By fine-tuning these typographic properties, you create a visual hierarchy that draws the user's attention to the most important information first.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Color contrast is essential for readability, especially when overlaid on background images.
            </li>

            <li>
            The font-size property specifies the size of the font.
            </li>

            <li>
            font-weight controls the thickness of the text, influencing how text stands out on the page.
            </li>
          </ul>
        </div>

        <div style={currentStep == 88 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 88: Making Subheadings Pop with Color and Spacing 🌈📏</h2>

          <p>
          Subheadings are like the helpers of the main heading. They tell us a little more about what the main heading is excited about!
          </p>
          <img src={eightyEight} className='demo__image' alt='img_eightyEight' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Turn the subheading (that's the h2 element in our .sale-section) a cool color - #607D8B. It's like the color of the ocean on a stormy day!
            </li>

            <li>
            Add some space above and below the subheading so it doesn't feel squished. We'll use 12px for both the top and the bottom. Imagine giving the subheading a little cushion to sit on and a bubble to stand in. This space is important so it doesn't bump into the other words!
            </li>
          </ol>
        </div>

        <div style={currentStep == 89 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 89: Styling Our Button to Be Super Clickable 👆</h2>

          <p>
          Let's style our 'Shop Now' button so everyone wants to click it.
          </p>
          <img src={eightyNine} className='demo__image' alt='img_eightyNine' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Draw a line around the button with a 2-pixel solid border that's the same stormy ocean color #607D8B.
            </li>

            <li>
            Make the inside of the button see-through—like a window! We do this by setting the background-color to transparent.
            </li>

            <li>
            Let the words on the button wear the same color as the border so they match and look snazzy.
            </li>

            <li>
            Choose a font weight of 500 to make the words strong but not too bulky.
            </li>

            <li>
            Set the size of the button so it's not too big or too small. 182px wide and 42px tall is just right, like a perfect little pillow.
            </li>

            <li>
            Don't let the button sit too close to the subheading! Give it a top margin of 28px.
            </li>

            <li>
            When you move your mouse over it, the cursor should change to a little hand, telling you, "Yes, you can click this!" We make this happen with cursor: pointer.
            </li>

            <li>
            Finally, let's make the button do a tiny dance when we hover over it. We'll use a transition effect for this. When you set transition: 0.2s all ease-in, it's like telling the button to take a quick, smooth breath in and out when someone pays attention to it.
            </li>
          </ol>

        </div>

        <div style={currentStep == 90 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 90: Button Tricks with Hover 🌟🖱️</h2>

          <p>
          This is our hover animation for the 'Shop Now' button.
          </p>
          <img src={ninety} className='demo__image' alt='img_ninety' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Make the button fill with the color of a stormy sky (#607D8B) when you hover over it. It's like it's getting ready for the rain!
            </li>

            <li>
            Change the text to cloud-white (#ffffff) so you can see the words even when the button is full of our stormy color.
            </li>

            <li>
            Keep the border the same color so it looks like a picture frame around our stormy button.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            The :hover pseudo-class makes something happen when your mouse goes over an element—like a secret button that only shows up when you find it.
            </li>

            <li>
            Changing background-color and text color makes the button more fun and tells visitors, "Hey, click me!"
            </li>
          </ul>
        </div>

        <div style={currentStep == 91 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 91: Starting a New Section for Latest Products 🛍️🆕</h2>

          <p>
          Now, let's make a space for the latest products on our website.
          </p>
          <img src={ninetyOne} className='demo__image' alt='img_ninetyOne' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Write down a new div with the class latest-products-section. 
            </li>

            <li>
            We're not putting anything inside just yet. It's like when you draw a treasure map, but the 'X' is still a secret!
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            A div is like a box or a room where we put our web page stuff. It helps us keep things neat and organized.
            </li>

            <li>
            A class is a name we give to our box so we can find it later when we want to decorate it with CSS.
            </li>
          </ul>
        </div>

        <div style={currentStep == 92 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 92: Announcing Your Latest Wares with a Grand Title 📢🆕</h2>

          <p>
          Now we’re going to make sure everyone knows where to find the newest and coolest stuff!
          </p>
          <img src={ninetyTwo} className='demo__image' alt='img_ninetyTwo' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Inside your latest-products-section, place a big, bold title that says "Our Latest Products". It's like putting up a sign so everyone knows, "Hey, look here for new stuff!"
            </li>

            <li>
            Use an h1 tag for your title because it's important and we want it to stand out as the main headline of this section.
            </li>
          </ol>
        </div>

        <div style={currentStep == 93 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 93: Setting the Stage for Product Showcases 🎭🛍️</h2>

          <p>
          After announcing our latest products, we need a special place to show them off. It’s like setting up a little stage in our shop window!
          </p>
          <img src={ninetyThree} className='demo__image' alt='img_ninetyThree' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Create a new div inside your latest-products-section. Give it the class product-container because it's going to contain all the awesome products you want to show.
            </li>

            <li>
            Leave the div empty for now—think of it as setting up a stage before the actors come out. We'll fill it with products later!
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            A div with a class is like giving a room in your house a specific purpose, like a kitchen or a playroom.
            </li>

            <li>
            We use different div tags to keep different parts of our website organized, just like having different rooms for different activities.
            </li>
          </ul>
        </div>

        <div style={currentStep == 94 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 94: Showcasing Products on Our Stage 🎭🪑</h2>

          <p>
          Now that we have our stage set, let's put the spotlight on our first star product—a wooden rocking chair!
          </p>
          <img src={ninetyFour} className='demo__image' alt='img_ninetyFour' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Within the product-container, create a div with the class product-card. This is like a mini stage for each product.
            </li>

            <li>
            Add an image of your product with img src="./images/product1.jpg".
            </li>

            <li>
            Inside a new div with the class info, we'll give more details. We'll use a p tag for a little hint, like "Armchair", so people know what kind of product it is.
            </li>

            <li>
            Then we shout out what it is with a big h4 tag, "Wooden Rocking Chair"—that's the name of our product!
            </li>

            <li>
            Lastly, we show off how much it costs with a big h3 tag, "$49.00", so everyone knows what a great deal they're getting!
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            div tags are like containers or boxes for each part of your content, keeping it organized.
            </li>

            <li>
            img tags are used to add pictures to your website, making it more colorful and interesting.
            </li>

            <li>
            Using p, h4, and h3 tags give us different sizes of text for different pieces of information, which helps tell our product's story clearly.
            </li>
          </ul>
        </div>

        <div style={currentStep == 95 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 95: Multiplying the Fun with More Product Cards 🃏✨</h2>

          <p>
          Remember our star product card? It’s time to give it some friends on our stage! We’ll add more cards so our audience—our awesome customers—can see all the great products we have.
          </p>
          <img src={ninetyFive} className='demo__image' alt='img_ninetyFive' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            We already have one product-card ready. Now, let's make three more! Each card will show off a different product.
            </li>

            <li>
            Copy and paste the whole div with the class product-card three times inside the product-container. Now, you'll have four cards, each ready for its own product.
            </li>

            <li>
            Change the src in the img tag to show different images for each product. Use product1.jpg, product2.jpg, product3.jpg, and product4.jpg for each card.
            </li>

            <li>
            Remember to change the description and price for each product to match the picture. Each card should tell its own little story about the product.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Copying and pasting elements in HTML lets us quickly create repeats of the same structure, which is super handy when you have lots of similar items to display.
            </li>

            <li>
            Changing the src attribute in the img tags helps us show different images, making each card unique.
            </li>

            <li>
            Having multiple product-card divs makes our product-container look like a showcase of cool stuff, inviting our customers to browse and find something they love.
            </li>
          </ul>
        </div>

        <div style={currentStep == 96 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 96: Giving Our Latest Products Section Some Style 💃🎨</h2>

          <p>
          Now let's make sure our "Latest Products" section really stands out by giving it a touch of style.
          </p>
          <img src={ninetySix} className='demo__image' alt='img_ninetySix' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Use Flexbox by setting display: flex to line up our items in a sweet, neat order.
            </li>

            <li>
            With flex-direction: column, we stack them up like a tower, one on top of the other.
            </li>

            <li>
            Center everything with align-items: center, so our tower of items is in the middle.
            </li>

            <li>
            Stretch the section to full width using width: 100% and make it as tall as it needs to be with height: 100%.
            </li>

            <li>
            Finally, give it some breathing room at the bottom with margin-bottom: 52px.
            </li>
          </ol>
        </div>

        <div style={currentStep == 97 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 97: Making Our Main Headline Pop 🎈🔤</h2>

          <p>
          We want everyone to see our "Latest Products" headline and think, "Wow, I gotta check this out!"
          </p>
          <img src={ninetySeven} className='demo__image' alt='img_ninetySeven' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Splash on some color with color: #607D8B, the same cool, calm color of the stormy sea.
            </li>

            <li>
            Add some space below with margin-bottom: 32px so it doesn't feel too crowded.
            </li>

            <li>
            Make the words big and bold with font-size: 52px and not too thick, not too thin, just right with font-weight: 400.
            </li>
          </ol>

          <p>
          With these steps, our "Latest Products" section is looking sharp and ready to show off our amazing items! 🎉💼
          </p>
        </div>

        <div style={currentStep == 98 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 98: Lining Up Our Products Neatly 📏🛍️</h2>

          <p>
          Let's make sure all our product cards are in a row, looking neat and tidy, ready for customers to admire.
          </p>
          <img src={ninetyEight} className='demo__image' alt='img_ninetyEight' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Use Flexbox magic on the product-container to get all the product cards to sit in a row (flex-direction: row).
            </li>

            <li>
            Keep them centered with align-items: center.
            </li>

            <li>
            Space them out nicely with justify-content: space-between. This means they won’t be bumping elbows; each card has its own space on the shelf.
            </li>

            <li>
            Stretch the product-container to take up the whole width (width: 100%), so there's plenty of room for all the products.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            display: flex and flex-direction: row are like telling your products to line up for a parade.
            </li>

            <li>
            align-items: center and justify-content: space-between keep everything aligned and evenly spaced, like soldiers in that parade.
            </li>

            <li>
            width: 100% means our products will fill the width of the stage, making a grand display.
            </li>
          </ul>
        </div>

        <div style={currentStep == 99 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 99: Creating the Perfect Product Card 🃏</h2>

          <p>
          Each product card is a little stage of its own, so let's make it look perfect!
          </p>
          <img src={ninetyNine} className='demo__image' alt='img_ninetyNine' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Set each product-card to be a Flexbox.
            </li>

            <li>
            Arrange the product details in a column (flex-direction: column), stacking the image, name, and price on top of each other.
            </li>

            <li>
            Align everything to the start (align-items: flex-start) so it looks crisp and neat.
            </li>

            <li>
            Make sure each card is the right size with width: 23%. Not too big, not too small—just right for our products to shine.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            width: 23% ensures that the cards are just the right size to fit neatly across the container.
            </li>
          </ul>
        </div>

        <div style={currentStep == 100 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 100: Making Product Images Shine 🖼️✨</h2>

          <p>
          Every product image needs to look its best. We're going to make sure they fit perfectly in their cards.
          </p>
          <img src={oneHundred} className='demo__image' alt='img_oneHundred' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Grab all the img tags inside the product-card and tell them to be as wide as their card. We do this with width: 100%, which is like telling the image to stretch its arms to fit the space perfectly to its parent.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Setting the width to 100% helps the image adjust to the size of its container, making it super responsive.
            </li>
          </ul>
        </div>

        <div style={currentStep == 101 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 101: Adding Cozy Space to Product Info 🛋️💬</h2>

          <p>
          Now, let's give some cozy space to the text under each product so it's easy to read and looks neat.
          </p>
          <img src={oneHundredOne} className='demo__image' alt='img_oneHundredOne' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            For the info class inside each product-card, add some padding. Padding: 9px is like putting a little cushion around your text.
            </li>

            <li>
            Then, nudge the whole block down just a bit with margin-top: 12px, so it's not bumping into the image above.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Padding adds space inside an element, around the content—like a cushion around you in a chair.
            </li>

            <li>
            Margin-top adds space outside the element, above it—like a little lift to help it stand out from whatever's above.
            </li>
          </ul>

          <p>
          Your products are not only looking good, but they're also spaced out just right, so everything's easy to find and nice to look at! 🌟
          </p>
        </div>

        <div style={currentStep == 102 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 102: Setting Up a Testimonials Section 🌟🗣️</h2>

          <p>
          It's time to show off some kind words from people who love our products. Let's set up a section just for that!
          </p>
          <img src={oneHundredTwo} className='demo__image' alt='img_oneHundredTwo' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Add a new div to your HTML page, and give it a class name testimonials-section. This is like building a stage for your customers to share their happy thoughts.
            </li>

            <li>
            For now, leave the div empty. It's like preparing the stage before the audience arrives.
            </li>
          </ol>
        </div>

        <div style={currentStep == 103 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 103: Adding a Title to the Testimonials Section 📜✍️</h2>

          <p>
          Every special section needs a title so people know what it's about. Let's give our Testimonials section a big, bold title.
          </p>
          <img src={oneHundredThree} className='demo__image' alt='img_oneHundredThree' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Inside your testimonials-section, add an h1 tag with the word "Testimonials". This is like the headline of a newspaper article—it tells everyone what the news is about!
            </li>

            <li>
            Make sure your h1 tag is right at the top, so it's the first thing people will see.
            </li>
          </ol>

          <p>
          Now, everyone will know where to look to see what other customers think about your amazing products! 🎉🛒
          </p>
        </div>

        <div style={currentStep == 104 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 104: Crafting the Testimonials Container 📦✍</h2>

          <p>
          Great stories are shared in the testimonials section. Let’s create a special space for these stories to live on our page.
          </p>
          <img src={oneHundredFour} className='demo__image' alt='img_oneHundredFour' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            First, we need a container to hold all our testimonials. Inside the testimonials-section, add a div and call it testimonial-container.
            </li>

            <li>
            This container is like a bookshelf; every testimonial is like a book full of good things people say about us!
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            By giving the div a class name, we can style it later with CSS to look just how we want.
            </li>
          </ul>
        </div>

        <div style={currentStep == 105 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 105: Adding Our First Glowing Testimonial 🌟📜</h2>

          <p>
          Time to let our happy customers do the talking. We'll add their glowing words right in the testimonials section.
          </p>
          <img src={oneHundredFive} className='demo__image' alt='img_oneHundredFive' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Inside the testimonial-container, add a div with the class testimonial.
            </li>

            <li>
            Write a paragraph (p) with a happy customer quote. It’s like sharing a little story of happiness.
            </li>

            <li>
            Include a div with the class testimonial-profile, and inside it, put an image (img) of the happy customer and their name in an h3 tag. It's like showing off a picture of our happy customer and saying, "This is who's talking!" Use the profile1.png image.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            The paragraph tag (p) is for writing out longer pieces of text like quotes or stories.
            </li>

            <li>
            The image tag (img) lets us add photos to our webpage, which helps people see who's behind the words.
            </li>

            <li>
            Using an h3 for the name makes it stand out because it's a bit bigger and bolder than regular text but not as big as our main title.
            </li>
          </ul>
        </div>

        <div style={currentStep == 106 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 106: Filling Our Testimonials with Happy Customers 🎤👥</h2>

          <p>
          It’s time to share more stories from our happy customers. Let’s fill up the testimonials section with three glowing reviews.
          </p>
          <img src={oneHundredSix} className='demo__image' alt='img_oneHundredSix' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            You’ve got one testimonial ready. Now, let’s duplicate it twice to make three. Each one is like a spotlight on someone’s good experience with your products.
            </li>

            <li>
            For each testimonial, change the image source to show different customers. Use profile1.png, profile2.png, and profile3.png for each.
            </li>

            <li>
            Don’t forget to change the text in the &lt;p&gt; and &lt;h3&gt; tags to match the different stories and names of the customers. Each story should be as unique as the person telling it!
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Copying and modifying elements lets us quickly fill our page with content without starting from scratch each time.
            </li>

            <li>
            Changing the image sources and text for each testimonial keeps each story fresh and personal.
            </li>

            <li>
            Each testimonial acts like a mini-billboard, showcasing real stories from real people, making your products and customer satisfaction shine!
            </li>
          </ul>

          <p>
          Now you have a testimonials section brimming with happy stories, like a garden full of blooming flowers, each with its own color and fragrance! 🌺🌟
          </p>
        </div>

        <div style={currentStep == 107 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 107: Styling the Testimonials Section 🎨</h2>

          <p>
          Let's give our testimonials section the spotlight it deserves with some neat styling.
          </p>
          <img src={oneHundredSeven} className='demo__image' alt='img_oneHundredSeven' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Make the testimonials section a Flexbox container. This will let us arrange the testimonials any way we like.
            </li>

            <li>
            Center everything with align-items: center, because we want our customer's kind words front and center.
            </li>

            <li>
            Space out the testimonials evenly within the section using justify-content: space-between. This gives each review its moment to shine without crowding the others.
            </li>
          </ol>
        </div>

        <div style={currentStep == 108 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 108: Defining the Testimonials Section Size 📏</h2>

          <p>
          We need to make sure our testimonials section has enough room for all the great reviews.
          </p>
          <img src={oneHundredEight} className='demo__image' alt='img_oneHundredEight' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Set the section's width to 100% so it stretches across the full width of its parent element.
            </li>

            <li>
            Give it a fixed height of 420px to provide a uniform and spacious area for all testimonials.
            </li>
          </ol>

        </div>

        <div style={currentStep == 109 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 109: Adding Comfort and Style to Testimonials 🛋️🎨</h2>

          <p>
          We want our testimonials to look good and feel inviting, so let's add some cozy padding and a fresh background.
          </p>
          <img src={oneHundredNine} className='demo__image' alt='img_oneHundredNine' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            First, let's pad the testimonials section with padding: 28px. This is like wrapping our testimonials in a warm blanket, so they're comfy and easy to read.
            </li>

            <li>
            Then, we'll give the section some space at the bottom with margin-bottom: 52px.
            </li>

            <li>
            Finally, let’s paint the background a gentle gray with background-color: #F0F0F0. It’s like a soft light that makes everything easy on the eyes.
            </li>
          </ol>

        </div>

        <div style={currentStep == 110 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 110: Lining Up the Testimonials 📏🎭</h2>

          <p>
          Now we’ll arrange our testimonials next to each other, so they’re easy to browse through.
          </p>
          <img src={oneHundredTen} className='demo__image' alt='img_oneHundredTen' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Target .testimonials-section .testimonial-container and use Flexbox for the testimonial-container by setting display: flex.
            </li>

            <li>
            Line up the testimonials side by side in a row with flex-direction: row.
            </li>

            <li>
            Center them perfectly with align-items: center.
            </li>

            <li>
            Make sure there’s nice even space around each one with justify-content: space-between.
            </li>

            <li>
            Stretch the container to be as wide as the section with width: 100%.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Flex-direction: row places child elements in a horizontal line.
            </li>

            <li>
            Align-items: center vertically centers the testimonials within their container.
            </li>

            <li>
            Justify-content: space-between places equal spacing between the elements, giving a clean and organized look.
            </li>
          </ul>
        </div>

        <div style={currentStep == 111 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 111: Styling Each Individual Testimonial 🖌️🗨️</h2>

          <p>
          Let’s make sure each testimonial looks its best by styling them individually.
          </p>
          <img src={oneHundredEleven} className='demo__image' alt='img_oneHundredEleven' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Set each testimonial to display as a Flexbox (display: flex) to take advantage of its layout powers.
            </li>

            <li>
            Stack the content of each testimonial vertically with flex-direction: column.
            </li>

            <li>
            Center everything inside with align-items: center.
            </li>

            <li>
            Determine the width of each testimonial to be 30% of its container. This gives each story its own space without feeling squished.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            display: flex combined with flex-direction: column helps stack elements vertically.
            </li>

            <li>
            align-items: center ensures that everything inside the testimonial is centered.
            </li>
            
            <li>
            Setting width: 30% allows for three testimonials to fit across the container with some space around them for a balanced look.
            </li>
          </ul>
        </div>

        <div style={currentStep == 112 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 112: Perfecting the Testimonial Text 📖✨</h2>

          <p>
          The words of our customers are important, so let’s make sure they stand out and are easy to read.
          </p>
          <img src={oneHundredTwelve} className='demo__image' alt='img_oneHundredTwelve' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Center-align the text within each testimonial using text-align: center.
            </li>

            <li>
            Set the font size to 20px for readability.
            </li>

            <li>
            Color the text with #607D8B to match our theme.
            </li>

            <li>
            Add a margin-bottom: 28px to create space between the quote and the customer’s name.
            </li>

            <li>
            Use font-style: italic to differentiate the quote from other text and give it a personal touch.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            text-align: center makes the text centered within its container, which is visually appealing for quotes.
            </li>

            <li>
            font-size, color, and margin-bottom ensure the text is not only readable but also aesthetically spaced and colored.
            </li>

            <li>
            Italicizing (font-style: italic) the text often signifies that the words are a direct quote or spoken by someone else, adding a layer of authenticity.
            </li>
          </ul>
        </div>

        <div style={currentStep == 113 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 113: Structuring Testimonial Profiles 🧍🎭</h2>

          <p>
          Now we want to make sure the pictures and names in our testimonials are just as well-presented as the kind words themselves.
          </p>
          <img src={oneHundredThirteen} className='demo__image' alt='img_oneHundredThirteen' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Set the testimonial-profile class to display as a Flexbox so we can easily align the elements inside.
            </li>

            <li>
            Arrange the profile picture and the customer's name side by side using flex-direction: row.
            </li>

            <li>
            Align these items in the center with align-items: center to make sure everything is balanced.
            </li>

            <li>
            Use justify-content: space-between to place the picture and name optimally within each testimonial.
            </li>

            <li>
            Set the width of the testimonial-profile to 70% of the testimonial to give each profile a dedicated space without taking over.
            </li>
          </ol>
        </div>

        <div style={currentStep == 114 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 114: Adding a New Section for Connection 🤝</h2>

          <p>
          Every great site needs a section where visitors can learn how to get in touch or connect further with the business.
          </p>
          <img src={oneHundredFourteen} className='demo__image' alt='img_oneHundredFourteen' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Add a new div to your HTML page right before the closing body tag (&lt;/body&gt;). Give it a class name of connect-section.
            </li>

            <li>
            This will be our dedicated space for contact information, social media links, or any call-to-action to engage our audience.
            </li>
          </ol>
        </div>

        <div style={currentStep == 115 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 115: Inviting Connections with a Headline 🤝🔖</h2>

          <p>
          A warm welcome can make all the difference. Let's invite our visitors to connect with a clear and friendly headline.
          </p>
          <img src={oneHundredFifteen} className='demo__image' alt='img_oneHundredFifteen' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In the connect-section, start with a headline that's a call to action. Add an h2 tag with the text "Connect with us."
            </li>

            <li>
            This is like putting out a welcome sign that's seen from afar, telling visitors exactly where to go to join our community.
            </li>
          </ol>
        </div>

        <div style={currentStep == 116 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 116: Setting Up the Connection Section Structure 🏗️🖇️</h2>

          <p>
          Before we add content, let's ensure the structural integrity of our connect section.
          </p>
          <img src={oneHundredSixteen} className='demo__image' alt='img_oneHundredSixteen' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Set the connect-section to display as a Flexbox to leverage the powers of CSS flex layout.
            </li>

            <li>
            Arrange any content we will add later in a columnar fashion with flex-direction: column.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            display: flex initiates a flex context for all its direct children, which we'll add to the connect-section.
            </li>

            <li>
            flex-direction: column will stack the children of this container vertically, which is ideal for a form or a set of social media links.
            </li>
          </ul>
        </div>

        <div style={currentStep == 117 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 117: Centering the Connect Section Content 🎯</h2>

          <p>
          Let’s ensure everything in the connect section is perfectly centered, making it welcoming and easy to navigate.
          </p>
          <img src={oneHundredSeventeen} className='demo__image' alt='img_oneHundredSeventeen' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Apply display: flex to the .connect-section to start using Flexbox.
            </li>

            <li>
            Use flex-direction: column to stack the content vertically.
            </li>

            <li>
            Center the content horizontally with align-items: center.
            </li>

            <li>
            Center the content vertically within the section using justify-content: center.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Flexbox is a layout model that allows for responsive design with ease.
            </li>

            <li>
            align-items: center and justify-content: center are powerful Flexbox properties that center children in both axis directions.
            </li>
          </ul>
        </div>

        <div style={currentStep == 118 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 118: Defining the Connect Section Dimensions 📐</h2>

          <p>
          It's important to give the connect section enough space to ensure all content is clear and clickable.
          </p>
          <img src={oneHundredEighteen} className='demo__image' alt='img_oneHundredEighteen' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Set the width of the .connect-section to 100% so it uses the full width available.
            </li>

            <li>
            Assign a height of 280px to provide ample space for any form, buttons, or information we might add.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            The width and height properties determine the size of the section, ensuring it is both responsive and sufficiently spacious.
            </li>
          </ul>
        </div>

        <div style={currentStep == 119 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 119: Adding Depth 🌟💭</h2>

          <p>
          Shadows and background images can add depth to your webpage, creating a more dynamic and visually engaging user experience.
          </p>
          <img src={oneHundredNineteen} className='demo__image' alt='img_oneHundredNineteen' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Give your connect-section a subtle box shadow to create depth. The values 0px 4px 4px 0px #00000040 create a small shadow that's not too stark, adding a gentle contrast.
            </li>

            <li>
            Use an inset shadow inset 0 0 1000px rgba(0,0,0,.5) to create a vignette look that draws the eyes towards the center.
            </li>

            <li>
              You can copy the shadow and paste it from the box below.
            </li>
          </ol>

          <CopyBox
          text={"box-shadow: 0px 4px 4px 0px #00000040,inset 0 0 0 1000px rgba(0,0,0,.5);"}
          />

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Box Shadow: Adds depth to your elements, making them pop out from the background.
            </li>
          </ul>
        </div>

        <div style={currentStep == 120 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 120: Setting the Background Stage with an Image 🏞️🎨</h2>

          <p>
          Background images can set the tone for a section, add texture, and create an immersive experience for the user.
          </p>
          <img src={oneHundredTwenty} className='demo__image' alt='img_oneHundredTwenty' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Introduce a background image to the .connect-section using background-image: url(./images/backdrop.png). This will serve as a visual cue for the section's purpose or theme.
            </li>

            <li>
            Position the image in the center with background-position: center, ensuring it aligns perfectly with your section.
            </li>

            <li>
            Prevent the image from repeating with background-repeat: no-repeat.
            </li>

            <li>
            Ensure the background image covers the entire section and responds well to different screen sizes with background-size: cover.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Background Image: Enhances visual storytelling and creates a theme for your section.
            </li>

            <li>
            Background Size 'cover': Ensures the image fully covers the section area without being stretched or pixelated, adapting to different screen sizes.
            </li>
          </ul>
        </div>

        <div style={currentStep == 121 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 121: Stylize the Connect Section Title</h2>

          <p>
          In this step, we focus on making the connect section inviting and prominent. This is where we encourage interaction, so it’s important it catches the eye!
          </p>
          <img src={oneHundredTwentyOne} className='demo__image' alt='img_oneHundredTwentyOne' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Target the &lt;h2&gt; element inside the .connect-section and set its color to pure white (#fff). This contrast is essential to ensure visibility against any background.
            </li>

            <li>
            Set the font size to 28px to make the title large enough to be a focal point, but not too overpowering.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Color contrast is vital for readability and drawing attention.
            </li>

            <li>
            Appropriate font sizing helps maintain a visual hierarchy, ensuring users can follow the flow of information easily.
            </li>
          </ul>
        </div>

        <div style={currentStep == 122 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 122: Introduce the Footer</h2>

          <p>
          Now it's time to cap off your website with a footer, the base of your webpage masterpiece. This will be the go-to place for additional information and links.
          </p>
          <img src={oneHundredTwentyTwo} className='demo__image' alt='img_oneHundredTwentyTwo' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Create a &lt;div&gt; element with the class .footer. Place it right before the closing &lt;/body&gt; tag.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            The footer is a crucial element of web design, acting as the endpoint of your webpage.
            </li>
          </ul>
        </div>

        <div style={currentStep == 123 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 123: Creating the Footer Structure 🏗️</h2>

          <p>
          To establish a footer that remains consistent across different pages of our website, we’ll define a basic structure within the HTML document. This footer will contain copyright information and a note about the website's affiliation.
          </p>
          <img src={oneHundredTwentyThree} className='demo__image' alt='img_oneHundredTwentyThree' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Inside the footer &lt;div&gt;, create a &lt;p&gt; element to hold the copyright notice. 
            </li>

            <li>
            Add an &lt;h3&gt; tag to display the name of the website.
            </li>
          </ol>
        </div>

        <div style={currentStep == 124 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 124: Styling the Footer with Flexbox 🎨</h2>

          <p>
          To style our footer so that its contents are properly aligned and spaced, we will use CSS Flexbox properties.
          </p>
          <img src={oneHundredTwentyFour} className='demo__image' alt='img_oneHundredTwentyFour' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In your style.css file, target the .footer class. Set its display to flex, which enables Flexbox for the container. This step initiates the use of Flexbox properties for alignment and spacing.
            </li>
            <li>
            Add the property flex-direction: row; to keep the footer items in a horizontal line.
            </li>
            <li>
            Apply align-items: center; to vertically align the footer items to the center of the container.
            </li>
            <li>
            Finally, use justify-content: space-between; to place equal space between the footer items.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Flexbox: A CSS3 layout mode that allows for the easy alignment and distribution of space among items within a container, even when their size is unknown or dynamic.
            </li>

            <li>
            align-items: This property aligns items vertically and accepts several values, with center being the one that aligns children to the middle of the container.
            </li>

            <li>
            justify-content: Aligns items horizontally and space-between distributes space evenly between the items.
            </li>
          </ul>
        </div>

        <div style={currentStep == 125 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 125: Enhancing Footer Aesthetics 🖌️</h2>

          <p>
          Next, we'll enhance the footer's design by adding a background color and setting its height.
          </p>
          <img src={oneHundredTwentyFive} className='demo__image' alt='img_oneHundredTwentyFive' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Continue styling the .footer class by setting a background color. Choose a color that complements your site’s design. Here, we use #3A3A3A for a sleek, modern look.
            </li>
            <li>
            Set a fixed height for the footer with height: 160px; to ensure that it has a consistent presence on all pages, regardless of the amount of content.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            background-color: This property sets the background color of an element. Hexadecimal values like #3A3A3A allow for precise color selection.
            </li>
            <li>
            Fixed Height: Setting a fixed height on an element ensures that it will maintain the same size regardless of the device or screen size.
            </li>
          </ul>
        </div>

        <div style={currentStep == 126 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 126: Adding Padding to the Footer 🔳</h2>

          <p>
          Padding inside the footer will provide necessary space between its content and the boundary of the footer itself. This visual space contributes to a better design aesthetic and enhances readability.
          </p>
          <img src={oneHundredTwentySix} className='demo__image' alt='img_oneHundredTwentySix' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Access your .footer class in the style.css file. To add space inside the boundaries of the footer, apply padding to the left and right sides.
            </li>
            <li>
            Set padding-left: 28px; and padding-right: 28px;. This ensures the content within the footer is not flush against the edges, providing a comfortable space for the eye.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Padding: Padding is the space between the content and the border of an element. It is used to create whitespace for aesthetic purposes and to improve the readability of content.
            </li>
          </ul>
        </div>

        <div style={currentStep == 127 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 127: Setting Text Color in the Footer 🔠</h2>

          <p>
          To ensure the text within the footer is legible against the background color, we’ll now adjust the text color.
          </p>
          <img src={oneHundredTwentySeven} className='demo__image' alt='img_oneHundredTwentySeven' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            In your style.css file, target the paragraph within the footer by using .footer p. We want to change the text color specifically for the paragraph element in the footer.
            </li>
            <li>
            Set the color property to a hexadecimal value that contrasts well with your footer's background. For example, color: #fff; will set the text color to white, which should be visible against a darker background.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Color Property: This CSS property defines the color of the text inside an element. The color can be specified with hexadecimal values, RGB, RGBA, HSL, HSLA, or predefined color names.
            </li>
          </ul>
        </div>

        <div style={currentStep == 128 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
          <h2>Step 128: Styling Headings in the Footer 🌈</h2>

          <p>
          With the footer's basic structure and color scheme in place, it’s important to also style the headings within the footer to ensure they match the footer’s overall design and are clearly legible.
          </p>
          <img src={oneHundredTwentyEight} className='demo__image' alt='img_oneHundredTwentyEight' />
          
          <h3>Your Task:</h3>

          <ol>
            <li>
            Create the .footer h3 selector in your style.css file. This selector targets all <code>&lt;h3&gt;</code> elements within the .footer class, allowing you to style these headings separately from other content.
            </li>
            <li>
            Set the color property to #fff (white). This will ensure the heading text stands out against the footer's background and harmonizes with the color set for the paragraph text.
            </li>
          </ol>

          <h3>Concepts to Learn:</h3>

          <ul>
            <li>
            Color Contrast: High contrast between text and its background is crucial for readability, especially in footer content, where text size is often smaller.
            </li>
            <li>
            CSS Selectors: By combining a class selector (.footer) with a type selector (h3), you can specify styles for elements that are only within that class, allowing for more granular design control.
            </li>
          </ul>
        </div>


        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>

    </div>
  )
}

export default ComfyCouchCo