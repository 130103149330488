import React, {useState} from 'react'
import one from './pythonImages/snakeImages/1.png';
import two from './pythonImages/snakeImages/2.png';
import three from './pythonImages/snakeImages/3.png';
import four from './pythonImages/snakeImages/4.png';
import five from './pythonImages/snakeImages/5.png';
import six from './pythonImages/snakeImages/6.png';
import seven from './pythonImages/snakeImages/7.png';
import eight from './pythonImages/snakeImages/8.png';
import nine from './pythonImages/snakeImages/9.png';
import ten from './pythonImages/snakeImages/10.png';
import eleven from './pythonImages/snakeImages/11.png';
import twelve from './pythonImages/snakeImages/12.png';
import thirteen from './pythonImages/snakeImages/13.png';
import fourteen from './pythonImages/snakeImages/14.png';
import fifteen from './pythonImages/snakeImages/15.png';
import sixteen from './pythonImages/snakeImages/16.png';
import seventeen from './pythonImages/snakeImages/17.png';
import eighteen from './pythonImages/snakeImages/18.png';
import nineteen from './pythonImages/snakeImages/19.png';
import twenty from './pythonImages/snakeImages/20.png';
import twentyOne from './pythonImages/snakeImages/21.png';
import twentyTwo from './pythonImages/snakeImages/22.png';
import twentyThree from './pythonImages/snakeImages/23.png';
import twentyFour from './pythonImages/snakeImages/24.png';
import twentyFive from './pythonImages/snakeImages/25.png';
import twentySix from './pythonImages/snakeImages/26.png';
import twentySeven from './pythonImages/snakeImages/27.png';
import twentyEight from './pythonImages/snakeImages/28.png';
import twentyNine from './pythonImages/snakeImages/29.png';
import thirty from './pythonImages/snakeImages/30.png';
import thirtyOne from './pythonImages/snakeImages/31.png';
import thirtyTwo from './pythonImages/snakeImages/32.png';
import thirtyThree from './pythonImages/snakeImages/33.png';
import thirtyFour from './pythonImages/snakeImages/34.png';
import thirtyFive from './pythonImages/snakeImages/35.png';
import thirtySix from './pythonImages/snakeImages/36.png';
import thirtySeven from './pythonImages/snakeImages/37.png';
import thirtyEight from './pythonImages/snakeImages/38.png';
import thirtyNine from './pythonImages/snakeImages/39.png';
import forty from './pythonImages/snakeImages/40.png';
import fortyOne from './pythonImages/snakeImages/41.png';
import fortyTwo from './pythonImages/snakeImages/42.png';
import fortyThree from './pythonImages/snakeImages/43.png';
import fortyFour from './pythonImages/snakeImages/44.png';
import fortyFive from './pythonImages/snakeImages/45.png';
import fortySix from './pythonImages/snakeImages/46.png';
import fortySeven from './pythonImages/snakeImages/47.png';
import fortyEight from './pythonImages/snakeImages/48.png';
import fortyNine from './pythonImages/snakeImages/49.png';
import fifty from './pythonImages/snakeImages/50.png';
import fiftyOne from './pythonImages/snakeImages/51.png';
import fiftyTwo from './pythonImages/snakeImages/52.png';
import fiftyThree from './pythonImages/snakeImages/53.png';
import fiftyFour from './pythonImages/snakeImages/54.png';
import fiftyFive from './pythonImages/snakeImages/55.png';
import fiftySix from './pythonImages/snakeImages/56.png';
import fiftySeven from './pythonImages/snakeImages/57.png';
import fiftyEight from './pythonImages/snakeImages/58.png';
import fiftyNine from './pythonImages/snakeImages/59.png';
import { FaArrowLeft, FaArrowRight, FaMinus, FaPlus } from "react-icons/fa";
import './tutorial.css';

const Snake = () => {
  var [currentStep, setCurrentStep] = useState(1);
  var maxSteps = 47;

  return (
    <div className='pythonGames__container'>
        <h1>
        🌴 Jungle Adventure: Creating Your Own Snake Game! 🌴
        </h1>

        <h3 className='intro'>
        Welcome to the jungle! Are you ready to embark on a coding adventure where we guide our snake through the dense jungle, avoiding obstacles and hunting for food? Let's begin!
        </h3>



        <div className='step__number'>
          <div onClick={()=>setCurrentStep(currentStep === 1 ? currentStep : Number(currentStep) - 1)} className='oper__cont'><FaMinus className='operator' /></div>
          
          <input
            min={1}
            max={maxSteps}
            value={currentStep}
            onChange={(e) => {
              const value = e.target.value;
              const num = parseInt(value, 10);

              // Check if the parsed number is an actual number and within the allowed range
              if (!isNaN(num) && num >= 1 && num <= maxSteps) {
                setCurrentStep(num);
              } else if (value === '') {
                // Allow the user to clear the input
                setCurrentStep('');
              }
            }}
            onBlur={() => {
              // If the user leaves the input and it's not a valid number, reset to previous valid state
              if (currentStep === '' || currentStep < 1 || currentStep > maxSteps) {
                setCurrentStep(1); // Or some other default value
              }
            }}
            type="number"
            placeholder={`Step No. ${currentStep}`}
          />


          <div onClick={()=>setCurrentStep(currentStep === maxSteps ? currentStep : Number(currentStep) + 1)} className='oper__cont'><FaPlus className='operator' /></div>
        </div>




        <div style={currentStep == 1 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🐍 Step 1: Gathering Supplies from the Jungle 🐍
            </h2>

            <img src={one} className='demo__image' alt='img_one' />



            <ul>
                <li>
                import pygame: This is our main tool. pygame is a library that helps us make games in Python. It provides functions to draw shapes, capture key presses, and so much more.
                </li>

                <li>
                import sys: This is our compass. The sys library gives us access to some functionalities related to the Python runtime environment. For our game, it'll help us exit out when we want to end our expedition.
                </li>

                <li>
                import random: This is our surprise element. The jungle is unpredictable, right? The random library will allow our game to have unexpected elements, like where our snake's food might appear next.
                </li>
            </ul>

            <p>
            Why are we using it?
            </p>

            <p>
            By importing these libraries, we're setting the foundation of our game. They will provide us the necessary functionalities to make our jungle adventure come alive!
            </p>

            <h3>
            Syntax Breakdown:
            </h3>
            <ul>
                <li>
                import: This keyword is used to bring in a specific library or module into our Python script.
                </li>

                <li>
                pygame, sys, and random: These are the names of the libraries we're importing.
                </li>
            </ul>

            <h3>
            That's the first step on our jungle adventure! By gathering our supplies, we're now prepared to face the challenges ahead. In the next steps, we'll dive deeper into the jungle and start building our snake game. Get ready, brave adventurer, for the jungle awaits! 🌿🐍🌿
            </h3>
        </div>

        <div style={currentStep == 2 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌳 Step 2: Preparing a Safe Zone for our Snake 🌳
            </h2>

            <p>
            Imagine this: Every time our snake gets hungry in the jungle, we want to provide it with some delicious food. But where should the food be placed? We're using this line of code to begin setting up a method (a set of instructions) that determines a new, random position for the snake's food in our jungle (the game screen).
            </p>
            <img src={two} className='demo__image' alt='img_two' />

            <p>
            This new_food_position is like our food placement expert, ensuring our snake never goes hungry!
            </p>
            <p>
            Why are we using it?
            </p>
            <p>
            By defining this function, we are preparing to create a way for the food in our snake game to appear in different places every time. It keeps the game interesting, much like how the unpredictable jungle always has surprises in store for adventurers!
            </p>
            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                def: Short for "define", this keyword is used to declare a new function. It's like telling our code, "Hey, I'm about to describe a new set of instructions for you!"
                </li>

                <li>
                new_food_position(): This is the name of our function. The parentheses () indicate that it's a function. Later on, if we want to use the instructions inside this function, we'll "call" it by its name.
                </li>
            </ul>

            <h3>
            Alright, young explorer! We've set up a safe zone and are preparing to keep our snake well-fed. As we proceed further into the dense coding jungle, we'll fill up this method with instructions to place food randomly. Tighten your boots; the jungle is getting denser, and the adventure is only beginning! 🌳🍎🐍🌳
            </h3>
        </div>

        <div style={currentStep == 3 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 3: Determining the Food's Coordinates 🍃
            </h2>

            <p>
            Every time our snake craves a snack, this code finds a random position within the jungle (our game screen) for its food. Think of the jungle as a grid, and this code is like spinning a blindfolded jungle explorer around and having them point to a random spot on that grid. That's where the food will appear!
            </p>
            <img src={five} className='demo__image' alt='img_three' />

            <p>
            Let's dive deeper:
            </p>
            <ul>
                <li>
                The return statement is giving back the result of its instructions: the x and y coordinates of the food's position.
                </li>

                <li>
                random.randint(a, b) is a call to the random library, asking it for a random integer between a and b. This helps in ensuring that our food appears in various, unpredictable spots within the jungle.
                </li>

                <li>
                By multiplying with grid_size, we ensure the food lands squarely within the jungle's grid and not in-between grid lines.
                </li>
            </ul>

            <p>
            Why are we using it?
            </p>
            <p>
            We don't want our snake's food to always appear in the same spot - that would be too predictable and not much of a challenge. By finding random positions within our grid, we keep the snake (and the player) always on the lookout, enhancing the game's excitement and unpredictability.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>
            <ul>
                <li>
                return: This keyword is our way of saying, "Here's the result of the function!" It gives back the values or results that come out of our function to wherever it was called from.
                </li>

                <li>
                random.randint(a, b): This is a method from our random tool. It asks for a random whole number (integer) between the numbers a and b.
                </li>

                <li>
                grid_width, grid_height, and grid_size: These are like our jungle's map measurements. They help us understand the size and structure of our jungle, ensuring our food always lands in the right spot.
                </li>
            </ul>


            <h3>
            Brilliant work, adventurer! 🍂 We've not only found a safe spot for our snake's food but also ensured it can pop up anywhere within our jungle. As we dive deeper into this coding quest, we'll see our snake come alive, eagerly hunting for these random treats. But for now, take a moment, breathe in the jungle air, and appreciate the beauty around. Our journey has just begun! 🌳🍏🐍🍃
            </h3>
        </div>

        <div style={currentStep == 4 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌺 Step 4: Mapping Out Our Jungle Restart Point 🌺
            </h2>

            <p>
            The jungle is full of challenges, and sometimes our snake might find itself in a tricky situation. Just like any good adventurer, our snake needs a base camp, a starting point to which it can return and begin anew.
            </p>

            <img src={six} className='demo__image' alt='img_four' />

            <p>
            What does this code do?
            </p>
            <p>
            Imagine after a long day of exploring, our snake accidentally bumps into a tree or perhaps its own tail. Ouch! We'd need a way to let our snake rest and start fresh. This line begins the creation of a function (or set of instructions) named reset_game. When invoked, this function will reset the game to its initial conditions, giving our snake another chance to navigate the jungle.
            </p>

            <p>
            This reset_game is our snake's cozy base camp, ensuring that even after a mishap, our adventure can continue.
            </p>

            <p>
            Why are we using it?
            </p>
            <p>
            Games need a way to start over when a player faces challenges or obstacles. By defining this function, we are laying the groundwork for creating a restart mechanism for our game. It ensures that players can always have another go, increasing the game's replay value and fun factor.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                def: This keyword, short for "define," signifies that we're about to describe a new function or set of instructions.
                </li>

                <li>
                reset_game(): This is the name of our function. The parentheses () denote that it's a function. When we want to use the instructions contained within this function, we'll "call" it by its name.
                </li>
            </ul>

            <h3>
            Marvelous, brave explorer! 🌼 Setting up a base camp is crucial, ensuring that our journey through the coding jungle isn't halted by minor hiccups. As we proceed, we'll set up our camp with all the necessities our snake needs for a fresh start. Remember, the jungle is as forgiving as it is challenging. Every setback is a new opportunity! Onward to the next step of our quest! 🌳🌺🐍🌿
            </h3>
        </div>

        <div style={currentStep == 5 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🐾 Step 5: Remembering Our Jungle Essentials 🐾
            </h2>

            <p>
            Before we can help our snake find its way back to the base camp, we need to recall some of the key elements of our jungle. These will be important factors to remember when we’re resetting the game.
            </p>

            <img src={seven} className='demo__image' alt='img_five' />

            <p>
            What does this code do?
            </p>
            <p>
            This line is our jungle checklist! Just as an explorer would check their supplies, this code is calling out the vital components of our game that we’ll need to refer to when resetting.
            </p>
            <p>
            By declaring them as global, we're saying, "Hey, let’s remember these specific items from our entire jungle inventory." In technical terms, we're telling our function to use the global versions of these variables (which exist outside of this function) rather than creating new local ones within the function itself.
            </p>
            <p>
            Here’s what each item on our checklist stands for:
            </p>

            <ul>
                <li>
                snake: Our brave adventurer, slithering through the dense underbrush.
                </li>

                <li>
                snake_direction: The path our snake is currently following.
                </li>

                <li>
                food: The tasty treats that our snake is looking for.
                </li>

                <li>
                score: A record of how many treats our snake has found.
                </li>
            </ul>

            <p>
            Why are we using it?
            </p>
            <p>
            By specifying these variables as global, we ensure that when we reset the game, we are modifying the original snake, its direction, the food, and the score – not just creating new ones that are confined within our reset_game function. This ensures continuity in our game, much like how a compass points north no matter where you are.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                global: This keyword indicates that we're referring to variables that exist outside of our current function. It’s like if you were in a tent in the jungle, and you used a megaphone to call out to the entire camp, ensuring everyone hears you.
                </li>

                <li>
                snake, snake_direction, food, score: These are the names of our global variables. By listing them after the global keyword, we're telling our reset_game function to remember and use these specific items from our larger jungle inventory.
                </li>
            </ul>

            <h3>
            Great work, intrepid explorer! 🌴 By keeping track of our essentials, we ensure that our snake's journey through the jungle remains consistent and continuous, even when challenges arise. Always remember, in coding and in the jungle, preparation and attention to detail are key. Grab your map and compass; we're diving deeper into the heart of our jungle adventure! 🌳🐾🐍🍂
            </h3>
        </div>

        <div style={currentStep == 6 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌿 Step 6: Setting the Starting Point of Our Snake’s Journey 🌿
            </h2>
            <p>
            With our essentials checklist in hand, it’s time to set the stage for our snake's starting position. Where does our snake begin its exciting journey within this vast jungle? Let's find out!
            </p>

            <img src={nine} className='demo__image' alt='img_six' />

            <p>
            What does this code do?
            </p>
            <p>
            This code defines our snake's initial position. In the heart of our jungle, amidst the tall trees and dense foliage, our snake starts its journey right in the center. The position is represented as a list containing a single coordinate, a tuple with x and y values. This coordinate determines where the head of our snake is located. 
            </p>

            <p>
            Think of this as our snake's treehouse, a central point from which all great adventures begin. Located at the midpoint of our jungle's width (grid_width // 2) and height (grid_height // 2), and multiplied by the grid size to get the exact pixel location, our snake is ready for action.
            </p>
            <p>
            Why are we using it?
            </p>
            <p>
            Starting the game with the snake in the center ensures a balanced gameplay experience. With equal space to move in any direction, players are given a fair and strategic choice right from the get-go. It's a fresh and neutral start for every new journey or after any mishap.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                snake: This is the variable representing our snake, which will eventually be a list of segments or parts of the snake.
                </li>

                <li>
                [...]: Square brackets denote a list in Python. A list can contain multiple items, but at this initial stage, our snake has just one segment: its head. 
                </li>

                <li>
                (grid_width // 2 * grid_size, grid_height // 2 * grid_size): This is a tuple, which is like a fixed list. It contains two values representing the x and y coordinates.
                <ul>
                    <li>
                    grid_width // 2 and grid_height // 2: These calculate half the number of grids horizontally and vertically, respectively, giving us the middle grid of the jungle.
                    </li>

                    <li>
                    * grid_size: By multiplying with grid_size, we convert the grid position to the actual pixel position on the screen.
                    </li>
                </ul>
                </li>
            </ul>

            <h3>
            Fantastic, explorer! 🍂 You've now set up the starting point of our snake's grand jungle journey. From this central treehouse, the entire jungle sprawls out, full of mysteries, challenges, and delicious treats. As we set our sights on the horizon, the adventure beckons! Let’s see where our path takes us next in this coding expedition. 🌳🍃🐍🌼
            </h3>
        </div>

        <div style={currentStep == 7 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 7: Choosing Our Snake's Initial Path 🍃
            </h2>

            <p>
            Every explorer needs a direction, a path that sets them off on their journey. As the sun rises, casting golden hues upon the dense foliage, which direction will our snake choose to begin its adventure?
            </p>

            <img src={ten} className='demo__image' alt='img_seven' />

            <p>
            What does this code do?
            </p>
            <p>
            This line defines the initial direction in which our snake will move. In our jungle adventure, the snake chooses to head east, moving horizontally across the clearing.
            </p>
            <p>
            The direction is represented by a tuple, (grid_size, 0), where the first value indicates the horizontal movement (x-direction) and the second value indicates the vertical movement (y-direction). The snake moves one grid size to the right (east), with no vertical movement.
            </p>

            <p>
            Imagine our snake waking up to the morning sun and deciding to follow the golden rays, which beckon it towards the eastern horizon.
            </p>
            <p>
            Why are we using it?
            </p>
            <p>
            Starting the game with a defined direction ensures that our snake doesn't remain stationary and has a sense of purpose. By heading east, we give our players an intuitive start, as many games have a default rightward or forward movement.
            </p>
            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                snake_direction: This variable denotes the current direction of our snake's movement. It will change based on player inputs, but for now, we set an initial value.
                </li>

                <li>
                (grid_size, 0): This is a tuple representing our snake's direction.
                <ul>
                    <li>
                    grid_size: Indicates that the snake will move the distance of one grid size horizontally, or to the right, during each game update.
                    </li>

                    <li>
                    0: Signifies that there's no vertical movement; our snake is neither moving up nor down.
                    </li>
                </ul>
                </li>
            </ul>

            <h3>
            Bravo, jungle navigator! 🌿 Setting a direction is crucial to begin any journey. With the sun as its guide, our snake embarks eastward, but the entire jungle remains open for exploration. With every twist and turn, the choices you make will shape this adventure. Grab your compass, and let's slither on to the next step! 🌳🌞🐍🍂
            </h3>
        </div>

        <div style={currentStep == 8 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍎 Step 8: Planting the Jungle's First Delight 🍎
            </h2>

            <img src={eleven} className='demo__image' alt='img_eight' />

            <p>
            What does this code do?
            </p>

            <p>
            This line plants a scrumptious morsel in our jungle for our snake to find. It calls the new_food_position() function, which will randomly determine a spot in our jungle terrain to place this delightful treat. Once the position is chosen, the food variable will store its coordinates, marking the spot.
            </p>

            <p>
            Why are we using it?
            </p>
            <p>
            The game's challenge and allure come from the snake's quest for food. By placing food in the jungle, we set a goal for our player, encouraging them to navigate the terrain and grow their snake with each treat they consume. As the snake grows, the game's complexity increases, offering an evolving challenge.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                food: This variable represents the current position of the food treat in the jungle. It holds the x and y coordinates of the food's location.
                </li>

                <li>
                new_food_position(): This is a function call.
                <ul>
                    <li>
                    When we use the name of a function followed by parentheses (), we're asking the Python interpreter to "run" or "execute" that function.
                    </li>

                    <li>
                    new_food_position() is a function we defined at the beginning of our code. Its job is to randomly select a spot within the jungle, ensuring that the food doesn't appear at the very edges. The function then returns this position, and we store it in the food variable.
                    </li>
                </ul>
                </li>
            </ul>

            <h3>
            Well done, intrepid adventurer! 🌿 With the tantalizing promise of nature's bounty, our snake's journey has purpose and challenge. Each time it consumes this delicacy, it will grow, presenting new obstacles and strategic choices. Ready your senses, for the jungle is alive with opportunities and surprises at every bend. Onward, to the next chapter of our story! 🌳🍎🐍🌺
            </h3>
        </div>

        <div style={currentStep == 9 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌟 Step 9: Starting with a Clean Slate 🌟
            </h2>

            <p>
            In the midst of the jungle, with its ever-changing dance of shadows and light, a new explorer must always know where they stand. As our snake embarks on its journey, we need a way to keep track of its achievements. Let's prepare that ledger!
            </p>

            <img src={twelve} className='demo__image' alt='img_nine' />

            <p>
            What does this code do?
            </p>
            <p>
            This line creates a scorecard for our snake. Every journey has milestones and achievements, and in our snake's adventure, the score is its testament of success. The variable score keeps a tally of the number of delicious treats our snake has consumed. Starting at the dawn of its journey, the slate is clean, with a score of 0.
            </p>
            <p>
            Imagine an ancient stone tablet in the heart of the jungle, where legendary explorers etch their names and accomplishments. For our snake, the score is its legacy, waiting to be written with each bite it takes.
            </p>

            <p>
            Why are we using it?
            </p>
            <p>
            A game's thrill often lies in its challenges and rewards. The score provides a quantifiable measure of the player's success. By keeping track of how many treats the snake consumes, the score not only offers a sense of achievement but also introduces a competitive element. Players can strive to beat their own high scores or compete with others, adding replay value to the game.
            </p>

            <h3>
            Syntax Breakdown: 
            </h3>

            <ul>
                <li>
                score: This variable acts as our jungle scorecard. It will be updated throughout the game, increasing by 1 each time the snake eats a piece of food. 
                </li>

                <li>
                0: This is a numeric value, representing the initial score. As our snake is just starting its adventure, it hasn't consumed any treats yet, so the starting value is zero.
                </li>
            </ul>

            <h3>
            Magnificent, jungle historian! 🌿 By keeping a record, we celebrate every little victory and moment of growth. Each point on this score represents a story, a moment of triumph, and a dance of joy in the vast amphitheater of the jungle. With our scorecard ready, our snake's epic tales await inscription. Now, let’s dive deeper into the jungle's heart, where legends are born and stories unfold! 🌳🌟🐍📜
            </h3>
        </div>

        <div style={currentStep == 10 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌌 Step 10: Preparing the Jungle Canvas 🌌
            </h2>

            <p>
            Before embarking on any great adventure, one must prepare the environment. In the vast expanse of our jungle, we're about to lay the foundation, the canvas upon which our snake will dance, and its stories will be painted.
            </p>

            <img src={thirteen} className='demo__image' alt='img_ten' />

            <p>
            What does this code do?
            </p>
            <p>
            This line awakens the magic of Pygame! pygame.init() initializes all the necessary modules in the Pygame library, preparing them for use in our game.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                pygame: This refers to the Pygame library, a powerful toolkit we're using to bring our jungle (and game) to life.
                </li>

                <li>
                init(): This is a method provided by the Pygame library. When attached to pygame with a dot (.), and followed by parentheses (), we're instructing Python to activate the initialization process within Pygame.
                </li>
            </ul>

            <h3>
            Splendid, jungle architect! 🌿 With the stage now set, the backdrop ready, and the magic of Pygame awakened, the heartbeats of the jungle echo with anticipation. Our snake, sensing the energy, flicks its tongue, tasting the adventure in the air. The next steps promise excitement, discovery, and challenges. Let's venture forth and see where the path leads! 🌳🪶🐍🌌
            </h3>
        </div>

        <div style={currentStep == 11 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🖼️ Step 11: Crafting the Jungle Vista 🖼️
            </h2>

            <p>
            Every story requires a stage, and every jungle, its own vast expanse of wonder. Now, we'll create the grand vista, the canvas where our snake's adventure will play out.
            </p>

            <img src={fourteen} className='demo__image' alt='img_eleven' />

            <p>
            What do these lines do?
            </p>

            <p>
            These two lines define the size of our jungle stage, where the saga of our snake will unfold. We're setting up a rectangle, with a width of 640 units and a height of 480 units. Imagine this as the open area in the jungle, surrounded by thick canopies and vines, where our snake slithers, searching for those elusive treats.
            </p>

            <p>
            In other words, we're deciding the size of the game window that players will see on their screens.
            </p>
            <p>
            Why are we using them?
            </p>

            <p>
            Establishing the dimensions of our game window is crucial. It dictates:
            </p>

            <ul>
                <li>
                How much room our snake has to move around.
                </li>

                <li>
                The space available for placing treats.
                </li>

                <li>
                The visual scale of the game, ensuring it's neither too vast nor too cramped for our player. 
                </li>
            </ul>

            <p>
            By setting these values, we're providing boundaries to our game world and ensuring that everything we draw, from the snake to its food, fits within this visual arena.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                screen_width and screen_height: These are variables, acting as containers to store information. In this case, they're holding the dimensions of our game window.
                </li>

                <li>
                640 and 480: These are numeric values representing the size in pixels. On digital screens, images and interfaces are made up of tiny squares called pixels. By setting these values, we're saying our game will be 640 pixels wide and 480 pixels tall.
                </li>
            </ul>


            <h3>
            Bravo, intrepid world-builder! 🌿 By defining the boundaries of our jungle, we've set the stage for countless adventures. Our snake, feeling the expansive grounds beneath, readies itself for the chase. The treats lie ahead, hidden in the foliage, waiting to be discovered. With a clear vista, the next chapters of our snake's quest will come alive in vivid detail. Let the dance of colors, challenges, and celebrations commence! 🌳🎨🐍🖼️
            </h3>
        </div>

        <div style={currentStep == 12 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌙 Step 12: Opening the Jungle's Window to the World 🌙
            </h2>

            <p>
            As the moonlight breaks through the thick jungle canopy, it illuminates a clearing, revealing the path for our snake. We're about to open a portal, a window through which players can witness our snake's escapades.
            </p>

            <img src={fifteen} className='demo__image' alt='img_twelve' />

            <p>
            What does this code do?
            </p>

            <p>
            In the midst of our digital jungle, this line opens a window—a game screen—where all the action will take place. We're using the dimensions defined earlier to dictate the size of this window. The set_mode method of Pygame's display module allows us to set the dimensions of the game window, effectively creating a visual stage for our game.
            </p>

            <p>
            Why are we using it?
            </p>

            <p>
            For players to interact with and experience our game, we need a visual interface—a screen where all the graphics, movements, and events are displayed. This line ensures that we have a dedicated space, tailored to our desired dimensions, where every twist and turn of our snake's journey can be vividly portrayed.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                screen: This variable will act as our main interface for drawing graphics. Once the window is opened, any visual element we wish to display in our game will be drawn or "blitted" onto this screen object.
                </li>

                <li>
                pygame.display: This refers to the display module of Pygame, which contains methods and tools necessary for creating and managing the game window.
                </li>

                <li>
                set_mode: This is a method from the display module that initializes and opens a window or screen for our game.
                </li>

                <li>
                ((screen_width, screen_height)): This is a tuple, essentially a pair of values, which represents the width and height of the screen, respectively. The double parentheses are used because set_mode expects a single argument: a tuple containing the width and height.
                </li>
            </ul>

            <h3>
            With the window to the jungle now unveiled, the dance of pixels, colors, and challenges eagerly awaits its audience. The symphony of the snake's pursuit, set against the backdrop of this vibrant canvas, promises a tale of excitement, strategy, and wonder. Onward, to the next chapter of our digital odyssey! 🌳🌌🐍🪟🎨
            </h3>
        </div>

        <div style={currentStep == 13 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌟 Step 13: Naming Our Jungle Tale 🌟
            </h2>

            <p>
            With the stage set and the window open, it's time to give our epic a title, a name that resonates with the adventures and mysteries that await within.
            </p>
            
            <img src={sixteen} className='demo__image' alt='img_thirteen' />

            <p>
            What does this code do?
            </p>

            <p>
            This line bestows our jungle adventure with a name. Just as every legendary tale has a title that captures its essence, this line sets the title of our game window to "Snake".
            </p>

            <p>
            A title serves several purposes:
            </p>

            <ol>
                <li>
                Identity: It gives players an immediate sense of what the game is about. "Snake" is simple, and direct.
                </li>

                <li>
                Engagement: A game's title can spark interest and intrigue. The classic name "Snake" invokes nostalgia for many and curiosity for newcomers.
                </li>

                <li>
                Practicality: When players have multiple windows open on their computer, the title helps them easily identify the game window.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                pygame.display: This is our gateway to Pygame's display module, which contains functions related to the game window.
                </li>

                <li>
                set_caption: As the name suggests, this method "sets" or assigns a "caption" (another word for title) to our game window.
                </li>

                <li>
                Snake': This is a string—a sequence of characters—that represents the title we want. In programming, strings are typically enclosed in single (' ') or double (" ") quotes.
                </li>
            </ul>


            <h3>
            Magnificent, oh chronicler of tales! 🌿 With a name to its credit, our story is now etched in the annals of jungle legends. As "Snake" shines above our game's entrance, it silently tells of the thrilling chases, clever twists, and vibrant dances that await within its realm. A simple name, yet one that carries with it the weight of countless adventures. What mysteries and joys will our players discover next? The jungle pulsates with anticipation. 🌳🌌🐍📜🌟
            </h3>
        </div>

        <div style={currentStep == 14 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌈 Step 14: Setting the Foundation Stones 🌈
            </h2>

            <p>
            Before we plunge into the heart of the jungle, let's lay the foundation, the core building blocks that will dictate the rhythm of our game.
            </p>

            <img src={seventeen} className='demo__image' alt='img_fourteen' />

            <p>
            What does this code do? 
            </p>
            <p>
            This line determines the size of each square in our grid, setting it to 30 pixels. Think of the grid as the jungle's floor, marked with square tiles. Our snake moves from tile to tile, rather than roaming freely. By defining the grid_size, we're determining how large each of these tiles is, thereby influencing the snake's movement, the placement of food, and more.
            </p>

            <p>
            Several reasons make the concept of a grid crucial:
            </p>
            <ol>
                <li>
                Structured Movement: A grid ensures that the snake moves in fixed increments. This makes the game more challenging and strategic, as players must think ahead and plan their path.
                </li>

                <li>
                Simplicity: By adhering to a grid, we simplify collision detection (e.g., when the snake runs into food or itself) since we only need to check specific grid positions rather than any arbitrary point.
                </li>

                <li>
                Visual Clarity: A grid provides a clear, organized visual structure, making it easier for players to anticipate moves and strategies.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                grid_size: This is a variable, a container that holds the value of our grid's size. By naming it descriptively, we instantly know its purpose whenever we encounter it in the code.
                </li>

                <li>
                30: A numeric value representing the size in pixels of each square in our grid. Remember, pixels are the tiny squares that make up images and displays on screens.
                </li>
            </ul>

            <h3>
            Brilliant, masterful architect! 🌿 By defining the grid's size, you've laid the foundation stones of our jungle realm. It's the pulse, the heartbeat, that will guide our snake's every twist and turn. As the rhythm of the grid resonates through the jungle, our snake feels the beat, preparing to dance its mesmerizing dance of pursuit and strategy. With each square tile acting as a stepping stone, the stage is set for a tale of adventure, challenge, and discovery. Let the symphony of the grid guide our journey! 🌳🎵🐍🌌🌈
            </h3>
        </div>

        <div style={currentStep == 15 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 15: Mapping the Jungle's Canvas 🍃
            </h2>

            <p>
            As we've set the rhythm with our grid, it's time to sketch the vast expanse of our jungle, marking its width and height, so our snake knows the boundaries of its playground.
            </p>
            <img src={eighteen} className='demo__image' alt='img_fifteen' />

            <p>
            What do these lines of code do?
            </p>
            <p>
            In the heart of the jungle, paths and clearings are mapped by nature itself. In our digital realm, these lines set the boundaries of our snake's playground by defining how many grid squares (or tiles) fit across the width and height of our screen.
            </p>

            <ul>
                <li>
                grid_width tells us how many grid squares fit from one side of the screen to the other, horizontally.
                </li>

                <li>
                grid_height reveals the number of grid squares from the top to the bottom of the screen, vertically.
                </li>
            </ul>

            <p>
            Why are we using them?  
            </p>

            <ol>
                <li>
                Space Awareness: Knowing the exact number of grids horizontally and vertically gives our snake and the game's logic a sense of space. It's like knowing the number of steps between two trees in the jungle. 
                </li>

                <li>
                Boundary Detection: These values are crucial when we want to check if the snake has ventured beyond the screen's edge (or the jungle's boundary).
                </li>

                <li>
                Game Mechanics: Placing food items or obstacles becomes systematic, as we now understand the confines of our grid system.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                grid_width & grid_height: These variables store the number of grid squares that span the width and height of our screen, respectively.
                </li>

                <li>
                screen_width & screen_height: Recall our earlier steps; these represent the dimensions of our jungle canvas or game screen.
                </li>

                <li>
                //: This is the floor division operator in Python. It divides the number on its left by the one on its right and then rounds down to the nearest whole number. Here, it ensures we get a whole number of grid squares. For example, if our screen width was 640 pixels and each grid square was 30 pixels wide, screen_width // grid_size would give us 21, telling us that 21 full grid squares fit across our screen's width.
                </li>
            </ul>


            <h3>
            Splendid, illustrious cartographer! 🍃 By mapping out the width and height of our grid-based jungle, you've granted our snake the gift of spatial awareness. As it slithers and turns, it feels the vast expanse and the limitations of its world, ensuring it doesn't lose its way. With the boundaries set, the mysteries within become even more enticing. The canvas is vast, the paths are numerous, and the dance of the snake awaits its next move. Adventure beckons within this grid-based realm! 🌳🗺️🐍🍂🍃
            </h3>
        </div>

        <div style={currentStep == 16 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🐍 Step 16: Let's Begin Anew 🐍
            </h2>

            <p>
            As we delve deeper into the jungle, our snake sometimes needs a fresh start, to respawn and begin its hunt once more. Let's prepare that reset.
            </p>

            <img src={nineteen} className='demo__image' alt='img_sixteen' />

            <p>
            What does this code do?
            </p>
            <p>
            Imagine our snake, having encountered a challenge it couldn't overcome, gets a second chance to start over. This line of code invokes the reset_game function, which reinitializes our snake's position, direction, and the food's location to their starting values.
            </p>

            <p>
            Why are we using it?
            </p>
            <ol>
                <li>
                Fresh Start: Whenever our snake collides with the boundaries or itself, it needs to start over. This line ensures that the game can be restarted, breathing new life into our snake's adventure.
                </li>

                <li>
                Initialization: This also ensures that when the game starts for the very first time, everything is set to their intended initial positions and values.
                </li>

                <li>
                User Engagement: By allowing the game to reset, players get another chance to play, increasing engagement and encouraging them to improve their strategy.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                reset_game(): This is a function call. A function is like a set of instructions bundled under a name. When this name is invoked with parentheses (as we did here), the instructions inside that function are executed. Remember, the reset_game function sets the starting position of the snake, its direction, the location of the food, and the score back to the beginning values.
                </li>
            </ul>

            <h3>
            It's not about how many times you fall, but how many times you get back up. The dance of life in our jungle is an continual one, full of twists, turns, and fresh starts. Let's continue to chart this mesmerizing tale! 🌳🌌🐍🍂🌺
            </h3>
        </div>

        <div style={currentStep == 17 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>  
            <h2>
            🌿 Step 17: Carving the Jungle's Scripts 🌿
            </h2>

            <img src={twenty} className='demo__image' alt='img_seventeen' />

            <p>
            What does this code do?
            </p>

            <p>
            This line of code is a magical incantation that brings forth the tools necessary to write and display text within our digital jungle. Specifically, it sets up a font style and size, enabling us to later write the score and perhaps other tales for our players to see.
            </p>

            <p>
            Why are we using it?
            </p>

            <ol>
                <li>
                Narration & Feedback: Our jungle, while being a playground for the snake, is also a stage where stories unfold. Displaying the score or any other message would be our way of communicating these tales to the player.
                </li>

                <li>
                User Interaction: Scores, game over messages, and other textual elements greatly enhance user interaction, motivating them to improve and engage more deeply with the game.
                </li>

                <li>
                Aesthetics: Text adds an aesthetic element to our game, making it more visually pleasing and complete.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                font: This is the name of our variable where we'll store the specific font style and size details.
                </li>

                <li>
                pygame.font.Font(...): This is a method from the Pygame library specifically used to create a new Font object from a file or a system default font. In our case, we're using the system's default font.
                </li>

                <li>
                pygame.font.get_default_font(): This nested method fetches the name of the default system font. It ensures that our game text is displayed using a universally recognized and readable font, regardless of where the game is played.
                </li>

                <li>
                24: This number represents the size of the font. Specifically, our text will be displayed at a size of 24 pixels, making it large enough to be clearly readable.
                </li>
            </ul>

            <h3>
            Magnificent, eloquent scribe! 🌿 By setting up the tools to write, you've unlocked the power of narration within our jungle. As the game unfolds, these tales will guide, entertain, and challenge our players. From the rustling leaves to the silent monuments, every element of the jungle has a story to tell, and now, so do you. Onward, as the chronicles of the jungle snake continue to be written! 🌳📜🐍🌺🍃
            </h3>
        </div>

        <div style={currentStep == 18 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌌 Step 18: Embarking on the Eternal Dance 🌌
            </h2>

            <p>
            This line initiates an infinite loop. In the realm of our game, this means that the subsequent lines of code inside this loop will be executed over and over again, without end, until explicitly stopped by some other command or action. The actions inside this loop represent the main game mechanics, such as the movement of the snake, checking for collisions, updating the screen, and so on.
            </p>
            <img src={twentyOne} className='demo__image' alt='img_eighteen' />

            <p>
            Why are we using it?
            </p>

            <ol>
                <li>
                Continuous Gameplay: Our snake's quest for food and growth is an ongoing one. To simulate this continuous movement and gameplay, we use an infinite loop.
                </li>

                <li>
                Dynamic Interaction: To make our game feel alive and responsive to player input (like changing the snake's direction), the game needs to be constantly checking for such inputs and reacting accordingly.
                </li>

                <li>
                Game Mechanics: The eternal dance (or loop) is where all the key actions of our game reside. The snake moves, eats, grows, or faces challenges, all within the confines of this loop.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                while: This keyword in Python is used to begin a loop. A loop is a sequence of instructions that is continually repeated until a certain condition is reached.
                </li>

                <li>
                True: In Python, True is a boolean value representing the concept of "truth". When used with the while loop, it creates an infinite loop, because the condition (truth) never changes.
                </li>

                <li>
                📜 Note: Loops, especially infinite ones, are powerful, but they need to be used judiciously. In the case of our game, we want the gameplay to continue indefinitely until the player decides to quit or there's a specific event (like the snake colliding with itself). However, in other scenarios, infinite loops can be problematic if not handled correctly, as they could lead to the program becoming unresponsive.
                </li>
            </ul>

            <h3>
            By setting the game in motion, you've breathed life into our game. The jungle now pulses with energy, its denizens moving and interacting in an endless rhythm. But remember, with great power comes great responsibility. As the guardian of this realm, it's now upon you to ensure the dance is harmonious and the tales within unfold seamlessly. Let's guide our snake through this mesmerizing waltz! 🍃🌳🐍🌺🌙
            </h3>
        </div>

        <div style={currentStep == 19 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌌 Step 19: Attuning to the Jungle's Whispers 🌌
            </h2>

            <p>
            This line of code initiates a loop that goes through each "event" that the player might trigger while playing the game. In the realm of video games, events are actions like pressing a key, clicking the mouse, or even attempting to close the game window. By cycling through each event, the game can appropriately respond to the player's inputs.
            </p>
            <img src={twentyTwo} className='demo__image' alt='img_nineteen' />

            <p>
            Why are we using it?
            </p>

            <ol>
                <li>
                Responsive Gameplay: For the game to be interactive and responsive, it needs to constantly check for player inputs. This line ensures the game listens to the player's actions and makes the snake move or act accordingly.
                </li>

                <li>
                Diverse Interactions: Beyond just moving the snake, there are several actions a player might want to undertake, like pausing the game or restarting after a game over. This loop helps the game acknowledge and act upon all such inputs.
                </li>

                <li>
                Smooth Experience: By constantly checking for events, we ensure that the game responds instantly to player inputs, leading to a smoother gameplay experience.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                for: This keyword in Python is used to begin a loop. Specifically, it's used to loop over a sequence (like a list or, in this case, a list of events).
                </li>

                <li>
                event: This is a temporary variable that represents the current event in the list of events as the loop progresses. Each time the loop iterates, event will contain the details of the next event in the sequence.
                </li>

                <li>
                pygame.event.get(): This method from the Pygame library fetches all the events currently in the event queue. Think of it like the game's "inbox" of player actions; this method lets the game check its inbox and see what the player wants to do.
                </li>
            </ul>

            <h3>
            Mystical guardian of the digital jungle! 🍂 By tuning into the player's desires and commands, you've ensured that the game is a two-way dance between the player and the jungle. Each whisper, each rustle is now a dialogue. And as the snake slithers in response to these commands, remember that this dance is what makes the game alive and the jungle truly responsive. Onward, as we delve deeper into the enigma of this jungle and respond to its many whispers! 🌿🐍🍂🌌🎮
            </h3>
        </div>

        <div style={currentStep == 20 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌌 Step 20: Heeding the Jungle's Decrees 🌌
            </h2>

            <p>
            These lines of code detect and respond to the specific event where the player decides to exit the game. When the player clicks the close button on the game window or uses another method to close the game, this code gracefully shuts down the game environment and exits the program.
            </p>
            <img src={twentyThree} className='demo__image' alt='img_twenty' />

            <p>
            Why are we using it?
            </p>

            <ol>
                <li>
                Respecting Player's Decision: Whenever the player decides it's time to leave the jungle and end their adventure, we need to ensure their wish is granted seamlessly and promptly.
                </li>

                <li>
                Proper Cleanup: Games, like our snake adventure, utilize resources (like memory). Gracefully shutting down the game ensures that these resources are properly freed, and there are no lingering issues.
                </li>

                <li>
                User Experience: Providing a straightforward way for players to exit ensures a positive user experience. It's fundamental in game design to respect the player's choices and provide clear actions for their decisions.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if: This keyword in Python is used for conditional checks. The subsequent block of code (indented under the if statement) will only execute if the condition stated after the if keyword is True.
                </li>

                <li>
                event.type: Here, we're accessing the type attribute of the event object. This attribute tells us what kind of event it is (e.g., a keypress, a mouse click, an attempt to close the window).
                </li>

                <li>
                pygame.QUIT: This is a constant value in Pygame that represents the event of trying to close the game window.
                </li>

                <li>
                pygame.quit(): This method from the Pygame library uninitializes all Pygame modules. It's a way to cleanly shut down the game.
                </li>

                <li>
                sys.exit(): This method from the sys library exits the Python program. It ensures that not only does the game window close, but the entire program stops running.
                </li>
            </ul>

            <h3>
            O noble steward of the jungle's chronicles! 🌳 By understanding the player's decisions and acting upon them, you've ensured that their journey, whether it continues or ends, is always in their hands. As the guardian of this realm, remember always to listen, to respect, and to act with wisdom. The ebb and flow of the jungle are now mirrored in our game, and as we proceed, more tales and secrets await. Let the dance continue, and may our snake's journey be filled with discovery and wonder! 🍃🐍🌺🌙🎮
            </h3>
        </div>

        <div style={currentStep == 21 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌌 Step 21: Responding to the Jungle's Heartbeat 🌌
            </h2>

            <p>
            In the dense expanse of the jungle, the rhythm of life is punctuated by the heartbeat of its inhabitants. As our snake ventures forth, it must heed the heartbeat of the player, responding to their every touch and command. Let's set the stage for the snake to dance to this rhythm.  
            </p>

            <img src={twentyFour} className='demo__image' alt='img_twentyOne' />

            <p>
            What does this code do?
            </p>
            <p>
            This line checks if the current event (the player's action) is a key being pressed down on the keyboard. Every keystroke is like a heartbeat, and this line listens intently for that beat, preparing the game to respond accordingly.
            </p>

            <p>
            Why are we using it?
            </p>

            <ol>
                <li>
                User Interaction: The primary way players will interact with our snake game is through keyboard inputs - making the snake move in various directions. This line identifies when such an interaction begins.
                </li>

                <li>
                Foundation for Response: Before we can react to specific keys being pressed (like arrow keys for movement), we need to ensure that a key has been pressed. This line sets the foundation for subsequent checks and responses.
                </li>

                <li>
                Optimized Performance: By filtering out non-keypress events, we enhance the game's performance. Instead of checking for every possible key when any event occurs, we first ensure it's a keypress event, then delve into specifics.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if: This keyword in Python checks a condition. If the condition is True, the code block under this statement will execute.
                </li>

                <li>
                event.type: This accesses the type attribute of our current event. This attribute indicates the kind of event - in our case, we're interested in a key being pressed down.
                </li>

                <li>
                pygame.KEYDOWN: A constant in Pygame that represents the event of a key being pressed down on the keyboard. If event.type matches this value, it means the player has pressed a key.
                </li>
            </ul>

            <h3>
            By tuning in to the heartbeat of the player, you've paved the way for the game to be truly interactive. Remember, in the dance of the jungle, it's not just about moving; it's about moving in harmony with the world around you. As our snake prepares to groove to the player's commands. Onward, to the next note in our enchanting symphony! 🌿🐍🎹🌛🎮
            </h3>
        </div>

        <div style={currentStep == 22 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌌 Step 22: Answering the Call of the North 🌌
            </h2>
            <p>
            The rustle of leaves, the call of a distant bird, and the whispering winds often guide creatures of the jungle. Our snake, feeling the gentle nudge of the player's will, stands ready to move. Let us help the snake understand the beckoning call that tells it to venture north.
            </p>

            <img src={twentyFive} className='demo__image' alt='img_twentyTwo' />

            <p>
            This line checks two specific conditions:
            </p>

            <ol>
                <li>
                Whether the player has pressed the UP arrow key on the keyboard.
                </li>

                <li>
                Whether the snake is currently moving downwards (south). If it is, the snake should not be allowed to suddenly move in the exact opposite direction (north) to prevent it from colliding with itself.
                </li>
            </ol>

            
            <p>
            If both conditions are met, the subsequent code block (that we'll explore in the next steps) will execute, directing the snake to move north.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>
            
            <ul>
                <li>
                if: The Python keyword used for conditional checks.
                </li>

                <li>
                event.key: This accesses the key attribute of our current event, helping us identify which specific key the player pressed.
                </li>

                <li>
                pygame.K_UP: A constant in Pygame representing the UP arrow key on the keyboard.
                </li>

                <li>
                and: A logical operator in Python used for checking if both conditions on its sides are True. In this case, it ensures both the UP key is pressed and the snake isn't moving south.
                </li>

                <li>
                snake_direction: This variable keeps track of the current movement direction of our snake.
                </li>

                <li>
                !=: This is a Python operator for "not equal to." It checks if two values are not the same. Here, it checks if the snake's direction is not downwards (south).
                </li>

                <li>
                (0, grid_size): Represents the south direction. In our game's coordinate system, moving south means keeping the x-coordinate (horizontal) unchanged (0) and increasing the y-coordinate (vertical) by the size of one grid square (grid_size).
                </li>
            </ul>

            <h3>
            O intrepid navigator of the jungle's pathways! 🌿 By understanding the player's will and the laws of movement, you ensure the snake slithers gracefully without risking danger. As the winds call and the leaves point the way, the snake, under your guidance, embarks on its journey upwards. Every step (or slither) is a dance with destiny, and with your wisdom, it's a dance of joy and exploration. Forward, to the luminous canopy above and the tales that await! 🍃🐍🌠🌌🎮
            </h3>
        </div>

        <div style={currentStep == 23 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌌 Step 23: Ascending to the Canopy 🌌
            </h2>

            <p>
            This line sets the direction of the snake to move upwards (or north). It adjusts the snake's direction by specifying that, for every frame or iteration of the game loop, the snake will maintain its current horizontal position (0 change in x-coordinate) and will move upwards by the length of one grid square (-grid_size change in y-coordinate).
            </p>
            <img src={twentySix} className='demo__image' alt='img_twentyThree' />

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                snake_direction: This variable determines the movement direction of the snake. It's a tuple containing two values: the change in x-coordinate and the change in y-coordinate.
                </li>

                <li>
                =: The assignment operator in Python. It's used to set the value of a variable. Here, it sets a new value for the snake_direction.
                </li>

                <li>
                (0, -grid_size): A tuple representing the new direction for the snake.
                <ul>
                    <li>
                    0: Indicates no change in the x-coordinate (horizontal direction).
                    </li>

                    <li>
                    -grid_size: Indicates a movement upwards by the size of one grid square. The negative sign ensures the snake's y-coordinate decreases, making it move up.
                    </li>
                </ul>
                </li>
            </ul>

            <h3>
            Onward, toward the sunlit adventures that await! 🌞🐍🍂🌌🎮
            </h3>
        </div>

        <div style={currentStep == 24 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌊 Step 24: Descending to the Depths 🌊
            </h2>

            <p>
            This code is responsible for changing the direction of the snake to move downwards (or south) when the "DOWN" arrow key is pressed. But there's a catch! The snake should only be allowed to move downward if it's not currently moving upward. This ensures the snake doesn't end up reversing into itself. 
            </p>
            <img src={twentySeven} className='demo__image' alt='img_twentyFour' />

            <p>
            Why are we using it?
            </p>

            <ol>
                <li>
                Responsive Gameplay: For a fluid and intuitive player experience, the snake should react immediately to arrow key inputs. This chunk of code caters to the "DOWN" arrow key.
                </li>

                <li>
                Game Logic: The snake can't reverse its movement. If it's moving upward and the player presses the "DOWN" key, this action should be ignored to prevent the snake from colliding with itself.
                </li>

                <li>
                Navigating the Depths: Moving downward allows the player to explore the entirety of the game screen, making gameplay more challenging and engaging.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if: A conditional statement that checks whether certain conditions are true.
                </li>

                <li>
                event.key == pygame.K_DOWN: This condition checks if the key pressed is the "DOWN" arrow key.
                </li>

                <li>
                and: A logical operator. Both conditions separated by and must be true for the entire statement to be true.
                </li>

                <li>
                snake_direction != (0, -grid_size): This ensures that the snake isn't currently moving upward. If it's moving up, the direction shouldn't change to down.
                </li>

                <li>
                snake_direction = (0, grid_size): Inside the conditional block, this line sets the direction of the snake to move downwards. It indicates no change in the x-coordinate and a positive change in the y-coordinate, moving the snake down by the size of one grid square.
                </li>
            </ul>

            
            <h3>
            In sync with nature's rhythm, the snake gracefully flows downward, embracing the coolness of the jungle floor. 🌿 The depth of the forest reveals myriad mysteries, each turn promising new experiences. There's a delicate balance between climbing to the canopy and weaving through the undergrowth, and the snake is mastering that balance under your guidance. Let the journey through the dense foliage continue! 🐍🌱🎮🌊🍃
            </h3>
        </div>

        <div style={currentStep == 25 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 25: A Drift to the West 🍃
            </h2>

            <p>
            When the player presses the "LEFT" arrow key, this code instructs the snake to move towards the left (or west). Just as with the previous directional commands, there's an essential safeguard: the snake should only move left if it's not currently heading right. This mechanism ensures the snake doesn't fold back onto itself.
            </p>
            <img src={twentyEight} className='demo__image' alt='img_twentyFive' />

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if: A conditional statement, our trusty tool to check if certain conditions are true.
                </li>

                <li>
                event.key == pygame.K_LEFT: This condition is true when the "LEFT" arrow key has been pressed by the player.
                </li>

                <li>
                and: A logical operator ensuring both conditions (on its left and right) must be true for the entire statement to be true.
                </li>

                <li>
                snake_direction != (grid_size, 0): A check to ensure that the snake isn't currently moving to the right. If it is, the direction shouldn't change to left.
                </li>

                <li>
                snake_direction = (-grid_size, 0): If both the above conditions are met, this line modifies the snake's direction to head left. The negative value for the x-coordinate causes a leftward movement by one grid square, with no change in the y-coordinate.
                </li>
            </ul>

            <h3>
            Embracing the winds from the west, the snake embarks on a new leg of its journey. There's something serene about drifting towards the sunset, every inch covered echoing tales of ancient trees and ancient secrets. Under your guidance, the path remains clear, the horizon ever-inviting. Towards the West we go, awaiting the many adventures that lie hidden in its embrace! 🐍🌅🌳🎮🍂🍃
            </h3>
        </div>

        <div style={currentStep == 26 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌞 Step 26: The Quest to the East 🌞
            </h2>

            <p>
            This segment dictates the snake's movement to the right (or east) when the player presses the "RIGHT" arrow key. Like the other directional commands, it's fortified with a vital check: the snake is only permitted to shift to the right if it isn't currently progressing to the left. This strategic mechanism helps avoid the snake's potential collision with itself.
            </p>
            <img src={twentyNine} className='demo__image' alt='img_twentySix' />

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if: Our go-to conditional statement that verifies if certain conditions hold true.
                </li>

                <li>
                event.key == pygame.K_RIGHT: This condition becomes true when the player hits the "RIGHT" arrow key.
                </li>

                <li>
                and: This logical operator makes sure that for the entire statement to be considered true, both conditions (flanking it) must be met.
                </li>

                <li>
                snake_direction != (-grid_size, 0): This ensures that our snake isn't already heading to the left. If it is, we refrain from altering its direction.
                </li>

                <li>
                snake_direction = (grid_size, 0): On satisfying the conditions, this line sets the snake's course to the right. The positive value in the x-coordinate instigates a movement to the right by a grid square's dimension, maintaining the y-coordinate unaltered.
                </li>
            </ul>

            <h3>
            Guided by the rising sun, the snake gracefully sails towards the east, reveling in the dawn's warmth. Each slither unearths tales from the heart of the jungle, tales painted in hues of gold. The path to the east is laden with surprises, and with you at the helm, the snake's journey promises to be nothing short of extraordinary. Onwards, to where the sun paints stories in golden strokes! 🐍🌄🍂🎮🌿🌞
            </h3>
        </div>

        <div style={currentStep == 27 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌌 Step 27: Cosmic Reset 🌌
            </h2>

            <p>
            With these lines, the player can command the game to restart by merely pressing the "SPACE" bar. This will invoke the reset_game() function, resetting the snake's position, its direction, the food's position, and the score to their initial values.
            </p>
            <img src={thirty} className='demo__image' alt='img_twentySeven' />

            <p>
            Why are we using it?
            </p>


            <ol>
                <li>
                The Power of Second Chances: Every player appreciates the opportunity to try again, especially if they feel they could have done better or just want to enjoy the thrill of the chase again.
                </li>

                <li>
                Keeping the Gameplay Fluid: Instead of having to shut down and restart the game manually, a simple key press ensures the gameplay remains smooth and uninterrupted.
                </li>

                <li>
                Empowering the Player: By giving the player control over when they want to reset, it offers them agency in their gaming experience.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if: Once again, our trusty conditional statement is here to check if specific conditions are true.
                </li>

                <li>
                event.key == pygame.K_SPACE: This condition checks whether the player has pressed the "SPACE" bar.
                </li>

                <li>
                reset_game(): When the "SPACE" bar is pressed, this function call rejuvenates the game, bringing the snake back to the center, placing a new piece of food randomly, and resetting the score.
                </li>
            </ul>

            <h3>
            Just as stars explode to form new galaxies, the snake gets a renewed opportunity to embark on its adventure once more. Amidst the lush green canopy, with twinkling fireflies lighting its path, the game begins anew. A fresh story, waiting to be scripted. A new dance of chase, just a spacebar away. 🌌🐍🪐🎮🌳🌠
            </h3>
        </div>

        <div style={currentStep == 28 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🌲 Step 28: Charting a New Course 🌲
            </h2>

            <p>
            As the snake slithers through the thick undergrowth of our virtual jungle, it is vital that it constantly updates its direction based on the path it's on. Each new destination demands a fresh head start, and our snake is always up for the challenge!
            </p>

            <img src={thirtyTwo} className='demo__image' alt='img_twentyEight' />

            <p>
            What does this code do?
            </p>

            <p>
            This line calculates the snake's next position, or where its head will be in the next game cycle, based on its current direction and position. It essentially determines the snake's next move.
            </p>

            <p>
            This line calculates the snake's next position, or where its head will be in the next game cycle, based on its current direction and position. It essentially determines the snake's next move.
            </p>

            <p>
            Why are we using it?
            </p>

            <ol>
                <li>
                Dynamic Movement: The quintessence of the Snake game is its ability to move, turn, and grow. To simulate this movement, we must determine where the snake should be in the next moment.
                </li>

                <li>
                Tailored Adventures: Based on user input (arrow keys), the snake changes its direction. This line ensures that the snake's position is updated accordingly, crafting a user-defined path.
                </li>

                <li>
                Staying in Bounds: By frequently updating the snake's head position, we can detect if it's about to collide with a wall or itself in subsequent lines of code, helping maintain the game's integrity.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                new_head: This variable represents the upcoming position of the snake's head.
                </li>

                <li>
                snake[0][0] + snake_direction[0]: Break it down:
                <ul>
                    <li>
                    snake[0]: This fetches the current head of the snake (its first segment).
                    </li>

                    <li>
                    [0]: This gets the x-coordinate of the snake's head.
                    </li>

                    <li>
                    snake_direction[0]: Acquires the x-component of the current direction.
                    </li>

                    <li>
                    Together, they calculate the new x-coordinate for the snake's head based on its direction.
                    </li>
                </ul>
                </li>

                <li>
                snake[0][1] + snake_direction[1]: Similar to the x-coordinate, but for the y-coordinate. We determine the y-coordinate of where the snake's head will be next.
                </li>
            </ul>

            <h3>
            As the sun casts elongated shadows, our snake uses its instinct, guidance from the player, and the magnetic pull of its next meal to set its course. The ever-shifting terrain of our jungle board becomes its map, with every twist, turn, and straight path charting an exciting course. But remember, with every new move, a new story unfolds in the heart of the jungle. 🌲🐍🗺️🎮🍃🌅
            </h3>
        </div>

        <div style={currentStep == 29 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 29: The Jungle's Natural Barriers 🍃
            </h2>

            <p>
            Every jungle has its impassable regions: treacherous cliffs, rushing rivers, or perhaps a wall of thick vines. For our snake, these barriers must be identified so it can avoid running into danger.
            </p>

            <img src={thirtyEight} className='demo__image' alt='img_twentyNine' />

            <p>
            What does this code do?
            </p>

            <p>
            This line checks if the snake's new position (new_head) is about to collide with the boundaries of the screen or itself. If any of these conditions are true, the snake has found itself in a problematic situation.
            </p>

            <p>
            Why are we using it?
            </p>

            <ol>
                <li>
                Safety First: In the classic Snake game, the snake should not be able to go beyond the screen boundaries or cross into itself, as these signify game-ending mistakes.
                </li>

                <li>
                Engaging Gameplay: Setting up these boundaries and potential challenges for the player adds an element of risk and strategy, making the game more engaging. 
                </li>

                <li>
                Ensuring Continuity: Without boundaries, our snake would either get lost outside the confines of our screen or get entangled within itself. Boundaries maintain the game's flow and structure.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if (...): This if statement checks multiple conditions to see if any of them are true, using the or operator. If any one of the conditions is met, the code block nested under the if statement will be executed. 
                </li>

                <li>
                new_head[0] &lt; grid_size: Checks if the snake's x-coordinate is moving beyond the left boundary of the screen.
                </li>

                <li>
                new_head[0] &gt;= screen_width - grid_size: Checks if the snake's x-coordinate is nearing or crossing the right boundary.
                </li>

                <li>
                new_head[1] &lt; grid_size: Checks if the snake's y-coordinate is moving beyond the top boundary.
                </li>

                <li>
                new_head[1] &gt;= screen_height - grid_size: Examines if the snake's y-coordinate is nearing or crossing the bottom boundary.
                </li>

                <li>
                new_head in snake: Ensures the snake isn't colliding with itself.
                </li>
            </ul>

            <h3>
            Just as a wise jungle explorer keeps an eye out for looming dangers like quicksand or lurking predators, our snake too must stay vigilant. With every move, it must consider the layout of the jungle, ensuring its safety and survival. This barrier check is the snake's internal compass, guiding it away from danger and ensuring its journey continues smoothly. 🍃🐍🧭🚫🎮🌿🌳
            </h3>
        </div>

        <div style={currentStep == 30 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 30: Navigating Through Jungle Hazards 🍃
            </h2>
            <p>
            As our snake slithers through the jungle, it has to be cautious. The jungle is full of challenges, and sometimes the snake might find itself in a tight spot. When it does, it must find its way back to a safe path.
            </p>

            <img src={thirtyNine} className='demo__image' alt='img_thirty' />

            <p>
            What do these lines of code do?
            </p>

            <ol>
                <li>
                reset_game(): When our snake encounters a hazard (like running into the screen's boundary or colliding with itself), this line calls the reset_game function. As the name suggests, this function resets the game back to its initial state.
                </li>

                <li>
                continue: This keyword is used within loops (like the while loop we're in) to immediately jump to the next iteration of the loop, skipping any code that comes after it inside the loop.
                </li>
            </ol>

            <p>
            Why are we using them?
            </p>


            <ol>
                <li>
                Starting Anew: In games, mistakes are bound to happen. When our snake makes a misstep, instead of ending the game entirely, we give the player another chance by resetting the game.
                </li>

                <li>
                Smooth Gameplay: The continue keyword ensures that after a game reset, the game immediately jumps to the start of the next loop iteration. This prevents any additional logic or code from executing after a reset, ensuring a seamless experience.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                reset_game(): This is a function call. The reset_game function was defined earlier in our code, and when we call it here, we're instructing Python to execute the set of actions defined within that function.
                </li>

                <li>
                continue: This is a Python control flow statement used inside loops. When the interpreter encounters continue, it doesn't process the rest of the code inside the loop's current iteration. Instead, it moves straight to the beginning of the next iteration.
                </li>
            </ul>

            <h3>
            Imagine our snake is an explorer who has gotten lost in the dense foliage of the jungle. Instead of facing dire consequences, the jungle (our game) offers the explorer a magical reset button—a chance to go back in time and start the journey over. The continue statement is like a gentle push, ensuring the explorer doesn't linger in the past but promptly starts the new journey. 🍃🐍🔄🎮🌿🌳
            </h3>
        </div>

        <div style={currentStep == 31 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 31: The Growth of the Jungle Snake 🍃
            </h2>

            <p>
            Just as animals in the jungle grow by eating and adapting, our snake too needs to grow. Each time the snake makes a move, it grows its head in the direction it's moving. This is vital for the gameplay dynamics.
            </p>

            <img src={forty} className='demo__image' alt='img_thirtyOne' />

            <p>
            What does this line of code do?
            </p>
            <p>
            This line of code adds a new segment (or the "head") to the front of the snake. In essence, it's how our snake "moves" in the direction it's headed by adding a new segment at the beginning of its body.
            </p>

            <p>
            Why are we using it?
            </p>
            <p>
            The core mechanic of the snake game is the snake's movement. Each time a move is made, the snake's head moves to a new position, while the tail follows. By adding a new head to the snake list, we're simulating this movement.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                snake: This is our snake, represented as a list of segments or coordinates. Each segment is a pair of (x, y) coordinates on the screen.
                </li>

                <li>
                .insert(0, new_head): The insert method is a built-in Python method for lists. It takes two arguments: the index at which we want to insert the new item, and the item itself. Here, 0 is the index (meaning the beginning of the list), and new_head is the new segment we want to insert. So, this method places the new_head at the very start of our snake list.
                </li>
            </ul>

            <h3>
            Imagine the snake in our jungle game as a conga line of animals, with the leader at the front. Each time the leader moves, a new animal joins the line at the front, becoming the new leader, while the rest follow in step. This code replicates that dynamic. As our jungle snake slithers forward, it's always growing, always moving, and this line ensures it! 🍃🐍🌱🎮🌿🌳
            </h3>
        </div>

        <div style={currentStep == 32 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 32: Jungle Delicacies - Checking for Food 🍃
            </h2>

            <p>
            In the dense jungle, our snake is always on the lookout for food. The thrill of the chase and the reward of a catch makes the journey worthwhile. Let's see how our game handles this critical aspect.
            </p>

            <img src={fortyOne} className='demo__image' alt='img_thirtyTwo' />

            <p>
            What does this line of code do?
            </p>

            <p>
            This line checks if the new position of the snake's head (new_head) coincides with the current position of the food (food).
            </p>

            <p>
            Why are we using it?
            </p>

            <p>
            For our snake to grow, it needs to eat. In the game, the snake "eats" by moving its head to the position where the food is. If the snake's new head position matches the food's position, it implies the snake has successfully caught its prey, and we need to take actions like growing the snake and increasing the player's score.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                if: This is a conditional statement in Python. It checks whether a particular condition is true. If the condition is true, the subsequent block of code (indented under the if) will be executed.
                </li>

                <li>
                new_head: This is the position (a set of x and y coordinates) of the snake's head after it has moved.
                </li>

                <li>
                ==: This is the equality operator in Python. It checks if the value on its left is equal to the value on its right.
                </li>

                <li>
                food: This is the current position of the food in our game.
                </li>
            </ul>

            <h3>
            Visualize the snake as a jungle explorer, and the food as a hidden treasure. As the explorer moves around, they are constantly on the lookout for any signs of this treasure. When they finally stand on the exact spot where the treasure is buried, it's a moment of success! In our game, when the snake's head is on the same spot as the food, it's a win for that moment, and our snake enjoys its jungle delicacy! 🍃🐍🍎🎮🌿🌳
            </h3>
        </div>

        <div style={currentStep == 33 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 33: A Jungle Feast - Rewarding the Snake 🍃
            </h2>

            <p>
            Once our snake captures its prey in the heart of the jungle, it grows longer and more robust, signifying its success. The rich ecosystem also replenishes, providing new opportunities for the predator to hunt. Let's delve deeper into how our snake game mirrors this intricate dance of nature.
            </p>

            <img src={fortyTwo} className='demo__image' alt='img_thirtyThree' />

            <p>
            What do these lines of code do?
            </p>

            <ol>
                <li>
                The first line sets a new position for the food in our game.
                </li>

                <li>
                The second line increases the player's score by one.
                </li>
            </ol>

            <p>
            Why are we using them?
            </p>

            <ol>
                <li>
                After the snake eats the food, it shouldn't remain in the same place. A new food item must appear somewhere else on the game board, giving our snake a new target to chase.
                </li>

                <li>
                Every time the snake consumes food, the player achieves a point. This score system motivates players to keep the snake alive and eating, making the game more engaging.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                food = new_food_position():
                <ul>
                    <li>
                    food: This variable stores the position of the food in our game.
                    </li>

                    <li>
                    =: The assignment operator, used to assign a value to a variable.
                    </li>

                    <li>
                    new_food_position(): This is a function we defined earlier in our code. When called, it returns a random position on our game board, ensuring that the food doesn't spawn too close to the edges.
                    </li>
                </ul>
                </li>

                <li>
                score += 1:

                <ul>
                    <li>
                    score: This is our game's score variable. It keeps track of how many times the snake has eaten food.
                    </li>

                    <li>
                    +=: This is a shorthand assignment operator in Python. It's equivalent to score = score + 1. In simpler terms, it adds the value on its right (in this case, 1) to the variable on its left (score).
                    </li>
                </ul>
                </li>
            </ol>

            <h3>
            Imagine the dense jungle foliage rustling as our triumphant snake enjoys its catch. As one food source is consumed, the rich jungle ecosystem ensures that another soon appears, challenging our snake to continue its hunt. Each successful catch not only increases the snake's size but also the player's pride as their score rises, much like a nature watcher tallying the number of unique species spotted. The cycle of hunt, reward, and renewed challenge keeps the heart of our jungle game beating! 🍃🐍🍏🎮🌿🌳🌺
            </h3>
        </div>

        <div style={currentStep == 34 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 34: The Circle of Jungle Life - Growth and Renewal 🍃
            </h2>

            <img src={fortyThree} className='demo__image' alt='img_thirtyFour' />

            <p>
            What do these lines of code do?
            </p>

            <ol>
                <li>
                The else clause signifies an alternate action if the snake doesn't eat the food.
                </li>

                <li>
                The snake.pop() command removes the last segment of the snake's body.
                </li>
            </ol>

            <p>
            Why are we using them?
            </p>
            <ol>
                <li>
                If the snake doesn't consume food during a game cycle, it shouldn't grow. But since we always add a new segment to the snake's head in every game loop iteration, we must also remove a segment from the tail to maintain its size.
                </li>

                <li>
                The game emulates the snake's movement through the jungle, constantly searching for food. If it finds some, it grows (which we covered in the last step); otherwise, it simply slithers ahead without growing.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                else: This is a conditional statement in Python. It executes the code inside its block if the preceding if condition was not satisfied. In our game context, this block of code runs when the snake's head doesn't coincide with the food's position.
                </li>

                <li>
                snake.pop():
                <ul>
                    <li>
                    snake: This is our game's main character, represented as a list of segments (or coordinates).
                    </li>

                    <li>
                    .pop(): This is a method provided by Python lists. It removes the last item from the list. In our game, this means the tail-end segment of the snake is removed, simulating the snake's movement.
                    </li>
                </ul>
                </li>
            </ul>

            <h3>
            Visualize the snake weaving through the jungle's dense underbrush. When it doesn't find food, it doesn't just stand still. It continues to move, gliding smoothly as its tail follows the path carved by its head. The jungle is ever-evolving, and so is our snake, continually moving forward in its quest for nourishment. 🌿🐍🌺🌳🍂🍃🌼
            </h3>
        </div>

        <div style={currentStep == 35 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 35: Cleaning the Canopy - Preparing the Jungle Canvas 🍃
            </h2>

            <p>
            Every dawn in the jungle, a new canvas is laid out, waiting for the vibrant flora and fauna to paint their stories. Similarly, as our game progresses, we need to refresh the screen to create a visual update for our snake's movement and actions. Let's explore how we set this stage in our game.
            </p>

            <img src={fortyFour} className='demo__image' alt='img_thirtyFive' />

            <p>
            What does this line of code do?
            </p>

            <p>
            This line of code is responsible for filling our game window (the screen) with a specific color, in this case, a shade of gray. It visually "clears" the screen by painting over the previous frame with a consistent background color.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                screen: This is our game window or the stage of our jungle theater. It's where everything in our game will be visualized.
                </li>

                <li>
                .fill(): This is a method provided by Pygame's Surface object. It fills the entire surface with the given color.
                </li>

                <li>
                (50, 50, 50): This tuple represents an RGB (Red, Green, Blue) color value. In this case, the color is a shade of gray. This particular shade creates a dusky canvas reminiscent of the hazy early hours in the jungle, just before dawn breaks.
                </li>
            </ul>

            <h3>
            Picture this as the quiet moments just before the jungle awakens. The sun hasn't risen yet, but the dark sky is starting to get a hint of the impending dawn. The dense forest is hushed, eagerly waiting for the stories of the day to unfold. Our game too, at this juncture, takes a brief pause, wipes the slate clean, and prepares for the next set of actions. The cycle of nature, with its endless loop of night and day, mirrors our game's loop, always moving, always renewing. 🌓🌳🌌🍃🌞🍂🌿🌼
            </h3>
        </div>

        <div style={currentStep == 36 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 36: Sketching Jungle Trails - The Vertical Grid Lines 🍃
            </h2>

            <p>
            Deep in the jungle, ancient trails wind their way between the trees, marked only by subtle hints known to the wild. In our game, we need to define our paths more explicitly. Let's start by creating vertical pathways for our snake to slither upon.
            </p>

            <img src={fortySix} className='demo__image' alt='img_thirtySix' />

            <p>
            What does this line of code do?
            </p>
            <p>
            This block of code draws vertical lines across our game window, creating a series of columns. Each line represents a potential path for the snake, ensuring its structured movement.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                for x in range(grid_size, screen_width - grid_size, grid_size):
                <ul>
                    <li>
                    This is a loop that repeats for each vertical line we want to draw. x is the x-coordinate where the current line will be drawn.
                    </li>

                    <li>
                    The range function parameters dictate that we start drawing from grid_size (so we don't start right at the edge) and stop just before the screen ends (screen_width - grid_size). We then skip by grid_size units for each iteration, ensuring our grid lines are evenly spaced.
                    </li>
                </ul>
                </li>

                <li>
                pygame.draw.line(screen, (100, 100, 100), (x, grid_size), (x, screen_height - grid_size)):
                <ul>
                    <li>
                    This is a Pygame function to draw a line.
                    </li>

                    <li>
                    screen is our drawing surface.
                    </li>

                    <li>
                    (100, 100, 100) is the color of the line, a shade of gray.
                    </li>

                    <li>
                    (x, grid_size) and (x, screen_height - grid_size) are the starting and ending coordinates of the line. They ensure that our line goes from the top to the bottom of our screen, with a gap equal to grid_size at both ends.
                    </li>
                </ul>
                </li>
            </ol>

            <h3>
            Envision the vertical lines as winding trails between towering trees, paths that the jungle's creatures tread with reverence. While these pathways aid our snake's journey, they also add depth and structure to our jungle saga, making every movement a tale of anticipation and strategy. 🌲🐍🌲
            </h3>
        </div>

        <div style={currentStep == 37 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 37: Crafting Jungle Trails - The Horizontal Grid Lines 🍃
            </h2>

            <p>
            As our snake slithers vertically, it also needs horizontal trails to journey across, akin to riverbanks and branches bridging different parts of the dense jungle.
            </p>

            <img src={fortyEight} className='demo__image' alt='img_thirtySeven' />

            <p>
            What does this line of code do?
            </p>

            <p>
            This code segment draws horizontal lines on our game window, creating rows. These lines serve as pathways, allowing our snake to move up or down, defining its horizontal travel.
            </p>

            <p>
            Why are we using it?
            </p>

            <ol>
                <li>
                Expanding the Grid: Just as the vertical lines gave structure to our snake's left and right movement, the horizontal lines provide direction for its up and down movement.
                </li>

                <li>
                Visual Guidance: The horizontal gridlines, combined with the vertical ones, give our game screen a 'grid' appearance, offering players a visual understanding of the snake's movement potential.
                </li>

                <li>
                Jungle Aesthetics: Horizontal paths can be visualized as fallen tree logs or shallow streams, guiding our snake on its quest for food.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                for y in range(grid_size, screen_height - grid_size, grid_size):
                <ul>
                    <li>
                    We employ a loop that will run for each horizontal line we plan to sketch. y becomes the y-coordinate where the current line will be drawn.
                    </li>

                    <li>
                    Using the range function, we start the drawing from grid_size (keeping it away from the edge) and halt just before reaching the screen's base (screen_height - grid_size). By skipping grid_size units in each step, we ensure uniformly spaced horizontal lines.
                    </li>
                </ul>
                </li>

                <li>
                pygame.draw.line(screen, (100, 100, 100), (grid_size, y), (screen_width - grid_size, y)):
                <ul>
                    <li>
                    Another Pygame function, but this time to sketch a horizontal line.
                    </li>

                    <li>
                    screen remains our canvas of choice.
                    </li>

                    <li>
                    (100, 100, 100) delivers a gray shade, matching our earlier vertical lines.
                    </li>

                    <li>
                    The coordinates (grid_size, y) and (screen_width - grid_size, y) set the line's start and end, ensuring it runs from the screen's left to the right edge, with a grid_size space on both ends.
                    </li>
                </ul>
                </li>
            </ol>

            <h3>
            With both vertical and horizontal paths now set, the jungle's maze emerges, rife with opportunities and obstacles. As our snake embarks on its hunt, every turn, every twist, is a choice that will determine its fate in this pixelated rainforest. 🌿🐍🍎
            </h3>
        </div>

        <div style={currentStep == 38 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 38: Rising the Jungle Serpent 🍃
            </h2>

            <p>
            Our jungle is alive with the sounds of birds, the rustling of leaves, and glistening waterways. Yet, the most majestic of all, our slithering protagonist, has yet to make an entrance. This code breathes life into our digital snake.
            </p>

            <img src={fifty} className='demo__image' alt='img_thirtyEight' />

            <p>
            What does this line of code do?
            </p>

            <p>
            This snippet visualizes our snake on the game screen. Represented as a series of rectangles, each segment of the snake's body is painted a vivid green, reminding us of the vibrant scales of a jungle python.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                for segment in snake:
                <ul>
                    <li>
                    Our snake is stored as a list of segments (or coordinates). This loop processes each segment one by one. Every loop iteration deals with a single segment, which is a portion of our snake.
                    </li>
                </ul>
                </li>

                <li>
                pygame.draw.rect(screen, (0, 255, 0), pygame.Rect(segment[0], segment[1], grid_size, grid_size)):

                <ul>
                    <li>
                    Using the Pygame library again, we're drawing a rectangle this time.
                    </li>

                    <li>
                    screen: As always, it's our canvas.
                    </li>

                    <li>
                    (0, 255, 0): This RGB value gives us a fresh jungle green.
                    </li>

                    <li>
                    pygame.Rect(...): This function creates a rectangle. Here's its breakdown: segment[0], segment[1]: The x and y coordinates of the top-left corner of the rectangle. It is the position of the current snake segment. rid_size, grid_size: The width and height of our rectangle. Our snake's segment's size matches that of the grid to smoothly slide across our jungle's trails.
                    </li>
                </ul>
                </li>
            </ol>

            <h3>
            Basking under the pixelated sun, it awaits your guidance on its quest for food, surrounded by the mysteries of our jungle. Stay cautious, for while it is elegant and mesmerizing, this snake must avoid its own tail and the boundaries of its world. 🌳🐍🍎🌳
            </h3>
        </div>

        <div style={currentStep == 39 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 39: The Fruit of the Jungle 🍃
            </h2>

            <img src={fiftyOne} className='demo__image' alt='img_thirtyNine' />

            <p>
            What does this line of code do?
            </p>

            <p>
            This line paints a radiant, red square on our game screen, representing the food for our snake. Each time the snake consumes this piece of food, it grows, and the food spawns at a new location.
            </p>

            <p>
            Why are we using it?
            </p>

            <ol>
                <li>
                Objective & Challenge: The primary aim of the game is for the snake to eat the food. It provides purpose and a challenge to the player.
                </li>

                <li>
                Visual Feedback: By redrawing the food after it has been consumed, we provide players with a dynamic, ever-changing playfield.
                </li>

                <li>
                Jungle Mystique: In the dense thickets of the jungle, the most alluring fruits are often the most elusive. Our snake's hunt for this glowing red fruit adds a layer of enigma to our jungle tale.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                We're using the pygame.draw.rect function from the Pygame library to craft a rectangle.
                </li>

                <li>
                screen: This is the canvas we're drawing on.
                </li>

                <li>
                (255, 0, 0): An RGB value, this combination blesses our rectangle with a fiery, captivating red - the color of our jungle fruit.
                </li>

                <li>
                pygame.Rect(...): Constructs a rectangle. Let's break it down:
                <ul>
                    <li>
                    food[0], food[1]: The x and y coordinates of the top-left corner of our rectangle. This corresponds to the position of the food in our jungle.
                    </li>

                    <li>
                    grid_size, grid_size: Denotes the dimensions of our rectangle. Here, the size of the food mirrors that of our grid, fitting seamlessly into our jungle grid.
                    </li>
                </ul>
                </li>
            </ul>

            <h3>
            With the presence of our glowing fruit, the jungle's allure intensifies. As our snake gets drawn to its mesmerizing hue, remember, each bite not only adds to its length but also poses new challenges. The dance between temptation and risk lies at the heart of our jungle tale. 🌳🍎🐍🌳
            </h3>
        </div>

        <div style={currentStep == 40 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 40: Securing the Jungle's Boundaries 🍃
            </h2>

            <p>
            Our journey takes an intriguing twist as we lay down the boundaries of our jungle. This line creates a pristine, white barrier that ensures the game's challenge remains intact.
            </p>

            <img src={fiftyTwo} className='demo__image' alt='img_forty' />

            <p>
            What does this line of code do?
            </p>

            <p>
            This line is used to illustrate the upper boundary of our game screen. It draws a thin, white rectangle spanning the entire width of the screen at the very top. This boundary serves as a wall, ensuring our snake doesn't venture beyond the confines of our jungle.
            </p>

            <p>
            Why are we using it?
            </p>

            <ol>
                <li>
                Boundary & Challenge: A game without limits or boundaries may become trivial. By establishing this boundary, we ensure that our snake faces obstacles, making the game more challenging.
                </li>

                <li>
                Visual Clarity: This top boundary provides a clear demarcation for players, ensuring they're aware of the game's playing field.
                </li>

                <li>
                Jungle's Edge: Every jungle has its edges, and this represents the northern edge of our digital domain.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                Again, we employ the pygame.draw.rect function from the Pygame library to draw a rectangle.
                </li>

                <li>
                screen: Specifies our drawing canvas.
                </li>

                <li>
                (255, 255, 255): An RGB value representing the color white. This hue provides a sharp contrast to the background, ensuring the boundary is vividly clear.
                </li>

                <li>
                pygame.Rect(...): This function constructs the rectangle for our boundary.
                </li>

                <li>
                0, 0: These are the x and y coordinates of the top-left corner of our rectangle. By setting both values to 0, we ensure our boundary starts at the very top-left corner of our screen.
                </li>

                <li>
                screen_width, grid_size: These values define the dimensions of our rectangle. The width is as wide as our screen, ensuring the boundary spans from one end to the other. The height corresponds to a single grid, providing a thin yet noticeable boundary.
                </li>

                <li>
                The 1 at the end dictates the thickness of our boundary. In this case, it's just one pixel thick.
                </li>
            </ol>

            <h3>
            With the northern boundary in place, we're one step closer to shaping our jungle. The walls might seem restricting, but they form the very essence of the game, pushing our snake to weave its path with caution. 🌳🐍🚫🌳
            </h3>
        </div>

        <div style={currentStep == 41 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 41: Setting the Southern Border 🍃
            </h2>

            <p>
            As we venture deeper into our digital jungle, we find the southern edge, ensuring our snake has a boundary on every side, preserving the challenge of the game.
            </p>
            
            <img src={fiftyThree} className='demo__image' alt='img_fortyOne' />

            <p>
            What does this line of code do?
            </p>

            <p>
            This code sketches a bright, white rectangular line at the bottom of our game's screen. It acts as the southern wall of our virtual jungle, ensuring our snake cannot escape or wander off the provided playing area.
            </p>

            <p>
            Why are we using it?
            </p>

            <ol>
                <li>
                Enclosure: With this line, we've effectively enclosed our snake within the top and bottom boundaries. This is vital for gameplay dynamics, ensuring the player navigates the snake with caution.
                </li>

                <li>
                Visual Indication: Similar to the top boundary, this bottom boundary is crucial for the player to discern the playing area. It's a visual indicator of where the snake should not venture.
                </li>

                <li>
                Consistency: A top border without a corresponding bottom border might feel incomplete or inconsistent. This ensures a balanced and symmetric game design.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                We again utilize the pygame.draw.rect function to craft a rectangle.
                </li>

                <li>
                screen: This is our canvas, where all our visuals take shape.
                </li>

                <li>
                (255, 255, 255): The RGB tuple depicting the color white. This ensures our boundary stands out.
                </li>

                <li>
                pygame.Rect(...): Constructs the rectangle that forms our boundary.
                </li>

                <li>
                0: The x-coordinate, indicating the rectangle starts at the leftmost edge of the screen.
                </li>

                <li>
                screen_height - grid_size: The y-coordinate. Subtracting grid_size from screen_height places our boundary at the bottom, just above the absolute edge.
                </li>

                <li>
                screen_width: The width of our boundary, making sure it spans across the entire screen.
                </li>

                <li>
                grid_size: Sets the height of the boundary to a single grid unit.
                </li>

                <li>
                1: Represents the thickness of our boundary, making it a slender line.
                </li>
            </ul>

            <h3>
            With the southern border in place, our snake now has clear territories to maneuver within. Each boundary we lay down makes the game more challenging and interesting. It's all coming together for our digital explorer! 🌳🐍🌲
            </h3>
        </div>

        <div style={currentStep == 42 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 42: Crafting the Western Boundary 🍃
            </h2>

            <p>
            Just as the East has its sunrise, our virtual jungle has its western edge. Let's ensure our curious snake doesn't drift off westwards into the abyss.
            </p>

            <img src={fiftyFour} className='demo__image' alt='img_fortyTwo' />

            <p>
            What does this line of code do?
            </p>

            <p>
            This line draws a radiant, white rectangle on the left side of our game display. It acts as the western frontier of our digital wilderness, giving our snake clear boundaries on all sides.
            </p>

            <p>
            Why are we using it? 
            </p>

            <ol>
                <li>
                Four-sided Boundary: With the addition of this western wall, we are nearing completion of our game's perimeter. We have defined the north, south, and now the west. This prepares the ground for our snake's adventures, ensuring it remains within the game's space.
                </li>

                <li>
                Visual Guidance: As with our previous borders, this boundary provides the player with a clear visual understanding of the playable area.
                </li>

                <li>
                Complete Enclosure: The symmetry and balance in game design is maintained. Every direction has a boundary, ensuring the snake's movement is both challenging and restricted to the screen.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                pygame.draw.rect(screen, (255, 255, 255), pygame.Rect(0, 0, grid_size, screen_height), 1):

                <ul>
                    <li>
                    We continue with the pygame.draw.rect function to create a rectangle.
                    </li>

                    <li>
                    screen: Our visual canvas, where the action unfolds.
                    </li>

                    <li>
                    (255, 255, 255): The RGB tuple representing the color white. This choice ensures our boundary is clearly visible.
                    </li>

                    <li>
                    pygame.Rect(...): This defines the rectangle which will act as our boundary.
                    </li>

                    <li>
                    0: The x-coordinate, ensuring the rectangle starts from the far-left side of the screen.
                    </li>

                    <li>
                    0: The y-coordinate, starting the rectangle from the very top.
                    </li>

                    <li>
                    grid_size: Sets the width of the boundary. This ensures our boundary remains a single grid unit wide.
                    </li>

                    <li>
                    screen_height: The height of our boundary. It ensures the boundary spans the entire vertical height of the screen.
                    </li>

                    <li>
                    1: Specifies the boundary's thickness as a single-pixel line.
                    </li>
                </ul>
                </li>
            </ol>

            <h3>
            With our western boundary firmly in place, our snake's playground takes clearer shape. As the boundaries close in, the excitement and challenge for our player only heighten! 🌳🌿🐍🍂🌱
            </h3>
        </div>

        <div style={currentStep == 43 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 43: The Eastern Frontier 🍃
            </h2>

            <p>
            Having journeyed through our digital jungle from North to West, it's now time to set up the final cardinal point: the East. Our snake, ever-curious and energetic, needs its boundaries, after all.
            </p>

            <img src={fiftyFive} className='demo__image' alt='img_fortyThree' />

            <p>
            What does this line of code do?
            </p>

            <p>
            This line draws a gleaming white rectangle on the right-most side of our game display, representing the eastern boundary of our digital landscape.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ul>
                <li>
                We use the pygame.draw.rect function once again to define our rectangle.
                </li>

                <li>
                screen: Our canvas for the game's visuals.
                </li>

                <li>
                (255, 255, 255): The RGB tuple for the color white. This ensures our eastern boundary is as radiant as its siblings.
                </li>

                <li>
                pygame.Rect(...): Defines the rectangle that will be our boundary.
                </li>

                <li>
                screen_width - grid_size: The x-coordinate. By subtracting the grid_size from the screen_width, we make sure our boundary is positioned on the far-right side of the screen.
                </li>

                <li>
                0: The y-coordinate. This ensures our boundary begins from the top and descends downwards.
                </li>

                <li>
                grid_size: This sets the width of our boundary, ensuring that, just like the western boundary, it remains one grid unit wide.
                </li>

                <li>
                screen_height: This gives our boundary the required vertical span, allowing it to reach from the top to the bottom of the screen.
                </li>

                <li>
                1: Specifies the boundary as a slender single-pixel line.
                </li>
            </ul>

            <h3>
            With this, we've fenced in our digital wonderland on all sides. The arena is set, the rules are clear, and our snake's thrilling dance through danger and reward is ready to begin! 🌿🐍🍀🌺🍂🌻🌴
            </h3>
        </div>

        <div style={currentStep == 44 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 44: Etching the Scoreboard 🍃
            </h2>

            <p>
            While our slithering serpent journeys through the jungle, finding delicious fruits, we need to keep track of its feats. Every explorer loves a little recognition, and our snake is no different!
            </p>

            <img src={fiftySix} className='demo__image' alt='img_fortyFour' />

            <p>
            What does this line of code do?
            </p>

            <p>
            This line of code crafts a text representation of the current score. This will be displayed prominently on our game screen, allowing both our snake and us to know just how many fruits have been devoured.
            </p>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                score_text = font.render(...):

                <ul>
                    <li>
                    font: The font we initialized earlier.
                    </li>

                    <li>
                    .render(): A method provided by Pygame's Font class, which creates an image of the text we wish to display.
                    </li>
                </ul>
                </li>

                <li>
                f"Score: &#10100;score&#10101;"

                <ul>
                    <li>
                    This is a formatted string in Python. The f before the quotation marks tells Python to treat this string as formatted.
                    </li>

                    <li>
                    Score: is just plain text.
                    </li>

                    <li>
                    &#10100;score&#10101; will be replaced by the actual value of the score variable. So if our snake has eaten 5 fruits, this will become "Score: 5".
                    </li>
                </ul>
                </li>

                <li>
                True: This argument decides if the text will be anti-aliased or not. Anti-aliasing makes the text edges smoother. In the dense jungle, our scoreboard stands out crisply because of this choice.
                </li>

                <li>
                (255, 255, 255): The RGB tuple for white. This ensures our score is displayed in a clear, radiant white, standing out against the darker backdrop of the jungle.
                </li>
            </ol>

            <h3>
            Ahoy, explorer! With our scoreboard in place, every twist, turn, and triumphant gulp of our snake becomes a testament to its jungle prowess. How high will you score in this wild expedition? 🐍🌟🍎🏆🍃
            </h3>

        </div>

        <div style={currentStep == 45 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 45: Displaying Our Accomplishments 🍃
            </h2>

            <p>
            Having etched our score, it's time to display this testament of the snake's jungle journey on the screen for everyone to witness.
            </p>

            <img src={fiftySeven} className='demo__image' alt='img_fortyFive' />

            <p>
            What does this line of code do?
            </p>

            <p>
            This line of code takes the created rendered score text and places it onto our game screen, making it visible to us, the players. In essence, it's like taking our painted canvas (the score_text) and hanging it in the exhibition hall (our screen).
            </p>

            <p>
            Why are we using it?
            </p>

            <ol>
                <li>
                Showcase Achievements: We've kept track of our snake's accomplishments with a score. But unless displayed, these accomplishments remain hidden. This line ensures that the player's efforts are showcased prominently.
                </li>

                <li>
                Real-time Updates: As the game progresses and the snake feasts on more fruits, the score will change. By continually placing the updated score on the screen, players get real-time feedback.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                screen: This is our game screen (or canvas) where all the action is displayed. We've set it up earlier to be the backdrop of our jungle adventure.
                </li>

                <li>
                .blit(): This method allows us to draw one image onto another. In the world of computer graphics, "blitting" refers to transferring data (like our score text) onto a display.
                </li>

                <li>
                score_text: This is the source we want to draw — the image of our score in that pristine white font.
                </li>

                <li>
                (10, 10): These coordinates decide the position where our score text will be placed on the screen. (10, 10) places the text 10 pixels from the left and 10 pixels from the top, akin to a badge of honor prominently worn on a brave explorer's chest.
                </li>
            </ol>

            <h3>
            As we venture deeper into our jungle, every achievement and discovery will be celebrated and showcased. For in the vast wilderness, every little triumph counts! 🌟📜🍃🐍
            </h3>
        </div>

        <div style={currentStep == 46 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 46: A Fresh Perspective 🍃
            </h2>

            <p>
            After all our setup and drawing, it's time to present the entire scene to our eager adventurers. Think of it as unveiling a new chapter in our jungle saga.
            </p>

            <img src={fiftyEight} className='demo__image' alt='img_fortySix' />

            <p>
            What does this line of code do?
            </p>

            <p>
            This line of code updates the entire display. If you picture our game screen as a canvas, all our previous operations (like drawing the snake, the fruit, or updating the score) are like sketching on the backside of that canvas. This command flips that canvas over, revealing the updated image for all to see.
            </p>

            <p>
            Why are we using it?
            </p>

            <ol>
                <li>
                Smooth Gameplay: By waiting to display all our changes at once, we ensure a smoother and more cohesive visual experience for our players. Instead of them seeing every individual change as it happens (which could be jarring), they see one smooth, continuous animation.
                </li>

                <li>
                Efficiency: This method is also more efficient. Redrawing the entire screen for every tiny change would be resource-intensive and could lead to lag. By making all our changes and then presenting them in one go, we optimize our game's performance.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                pygame.display: This is the portion of the Pygame library dedicated to handling display-related tasks, be it setting up our game window or updating its contents.
                </li>

                <li>
                .flip(): This method updates the entire screen. All the behind-the-scenes work we've done since the last time we used .flip() is suddenly unveiled. It's the magic moment when our players see the latest state of their game.
                </li>
            </ol>

            <h3>
            Imagine this step as the unveiling of a curtain after every act in a grand play. The audience (our players) eagerly anticipates what comes next in our snake's jungle adventure. 🎭🌿🐍🍎
            </h3>
        </div>

        <div style={currentStep == 47 ? { display: "flex" } : { display: "none" }} className='fs__step__container'>
            <h2>
            🍃 Step 47: Pause for Effect 🍃
            </h2>

            <p>
            Every good adventure has its moments of suspense, where we wait with bated breath to see what happens next. This line of code introduces a slight delay to our game loop, creating a rhythmic cadence to our snake's journey through the jungle.
            </p>

            <img src={fiftyNine} className='demo__image' alt='img_fortySeven' />

            <p>
            What does this line of code do?
            </p>

            <p>
            This line pauses our game for a brief moment - precisely 100 milliseconds (or a tenth of a second). This slight delay helps control the pace of our game, ensuring that our snake doesn't dart through the jungle too quickly for players to keep up.
            </p>

            <p>
            Why are we using it?
            </p>

            <ol>
                <li>
                Controlling Game Speed: Without this delay, our game would run as fast as the computer could process it. That might sound like a good thing, but it would make the game impossible to play! The snake would move too quickly for human reactions.
                </li>

                <li>
                Reducing CPU Usage: Continuously updating our game without any pause would also use up a lot of CPU resources. By introducing a short delay, we're giving the computer a tiny break, making our game more resource-efficient.
                </li>

                <li>
                Consistent Experience: This delay ensures that the game feels roughly the same speed, regardless of how powerful the player's computer is. Without it, the game could run faster on high-end machines and slower on older ones.
                </li>
            </ol>

            <h3>
            Syntax Breakdown:
            </h3>

            <ol>
                <li>
                pygame.time: This refers to Pygame's time module, which handles all timing-related operations. In our jungle adventure, it's like the rhythm of a drum setting the pace for our story.
                </li>

                <li>
                delay(100): The delay function pauses our game for a given number of milliseconds. In this case, it's 100 milliseconds, or 0.1 seconds. This is the tiny pause between each heartbeat of our game, adding rhythm to our jungle tale.
                </li>
            </ol>

            <h3>
            Picture the drumbeats in a jungle setting. As the snake maneuvers through the dense foliage, every move is met with a slight pause - a heartbeat - allowing our adventurers (players) to anticipate and react to the unfolding story. 🥁🌳🐍🌲🍎
            </h3>
        </div>

        

        <div className='step__selector'>
          <FaArrowLeft onClick={()=>setCurrentStep(currentStep == 1 ? currentStep : currentStep-=1)} className='arrow__icon' size={30} />

          <FaArrowRight onClick={()=>setCurrentStep(currentStep == maxSteps ? currentStep : currentStep+=1)} className='arrow__icon'  size={30} />
        </div>
    </div>
  )
}

export default Snake